/**
 * @generated SignedSource<<15460f21dcd3ede3eb564b7260d71ad4>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Mutation } from 'relay-runtime';
export type UpdateSocialLinksInput = {|
  clientMutationId?: ?string,
  facebookUrl?: ?string,
  instagramUsername?: ?string,
  pinterestUrl?: ?string,
  tiktokUrl?: ?string,
  twitterUsername?: ?string,
  website?: ?string,
  youtubeUrl?: ?string,
|};
export type updateSocialLinksMutation$variables = {|
  input: UpdateSocialLinksInput,
|};
export type updateSocialLinksMutation$data = {|
  +updateSocialLinks: ?{|
    +links: ?{|
      +instagramUsername: ?string,
    |},
  |},
|};
export type updateSocialLinksMutation = {|
  response: updateSocialLinksMutation$data,
  variables: updateSocialLinksMutation$variables,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "instagramUsername",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "updateSocialLinksMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateSocialLinksPayload",
        "kind": "LinkedField",
        "name": "updateSocialLinks",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "UserSocialLinks",
            "kind": "LinkedField",
            "name": "links",
            "plural": false,
            "selections": [
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "ShopshareMutationRoot",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "updateSocialLinksMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateSocialLinksPayload",
        "kind": "LinkedField",
        "name": "updateSocialLinks",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "UserSocialLinks",
            "kind": "LinkedField",
            "name": "links",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "165b9f4c6ec0de9e76a5455f007fee62",
    "id": null,
    "metadata": {},
    "name": "updateSocialLinksMutation",
    "operationKind": "mutation",
    "text": "mutation updateSocialLinksMutation(\n  $input: UpdateSocialLinksInput!\n) {\n  updateSocialLinks(input: $input) {\n    links {\n      instagramUsername\n      id\n    }\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "4b440ad15a8d63102555ec74bacda72b";

module.exports = ((node/*: any*/)/*: Mutation<
  updateSocialLinksMutation$variables,
  updateSocialLinksMutation$data,
>*/);
