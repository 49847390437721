import React from "react"
import UserMenu from "components/dropdowns/user-menu"
import styles from "./header.css"

export default function HeaderClient({ store }) {
	return (
		<>
			<div className={styles.navLeft} />
			<UserMenu store={store} />
		</>
	)
}
