import React from "react"
import { getHexaColorCode } from "helpers/color-helper"

export default function ColorPicker({
	color,
	runDebounce,
	onChange,
	dataTip,
	onChangeComplete,
}) {
	return (
		<input
			type="color"
			data-tooltip-id="tooltip"
			data-tooltip-content={dataTip}
			value={color ? getHexaColorCode(color) : "#000000"}
			onChange={({ target }) => {
				onChange(target.value)
			}}
			onBlur={() => {
				if (onChangeComplete) {
					onChangeComplete(color)
				}
				if (runDebounce) {
					runDebounce(false)
				}
			}}
			onClick={() => {
				if (runDebounce) {
					runDebounce(true)
				}
			}}
		/>
	)
}
