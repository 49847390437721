import { commitMutation, graphql } from "react-relay"
import { ConnectionHandler } from "relay-runtime"
import connections from "../connections"
import { complete } from "../helper"

const mutation = graphql`
	mutation createProductCollectionMutation(
		$input: CreateProductCollectionInput!
	) {
		createProductCollection(input: $input) {
			collection {
				id
				title
				createdAt
				updatedAt
				isDefault
				isACloset
				isPublished
				folder {
					id
					name
				}
				user {
					id
					fullname
				}
				client {
					id
					fullname
				}
				count
				images
			}
		}
	}
`

const commit = (env, data, currentUserId, onError, onCompleted, other) =>
	commitMutation(env, {
		mutation,
		variables: { input: data },
		updater: (storeProxy) => {
			const itemProxy = storeProxy
				.getRootField("createProductCollection")
				.getLinkedRecord("collection")
			const currentUserProxy = storeProxy.get(currentUserId)
			const newCollection = storeProxy.create(
				`ProductCollection:connection:${new Date().getTime()}`,
				"ProductCollection"
			)

			const connectionNoFilter = ConnectionHandler.getConnection(
				currentUserProxy,
				connections.currentUser.productCollectionsNoFilter
			)
			newCollection.setLinkedRecord(itemProxy, "node")

			if (connectionNoFilter)
				ConnectionHandler.insertEdgeAfter(connectionNoFilter, newCollection)

			if (data.isACloset && !other.limitType.includes("closet")) return
			if (!data.isACloset && !other.limitType.includes("collections")) return

			const filters = {
				search: other.search,
				categories: other.categories,
				brands: other.brands,
				clientIds: data.clientId ? [data.clientId] : [],
				collections: [],
				limitType: other.limitType,
				resultTypes: ["collections"],
				teamId: data.teamId,
			}
			if (other.sort !== undefined) filters.sort = other.sort
			if (other.folderId !== undefined) filters.folderId = other.folderId

			const searchItemsProxy = other
				? currentUserProxy.getLinkedRecord("productCollectionSearchItems", filters)
				: null
			if (searchItemsProxy) {
				const connectionSearchItems = ConnectionHandler.getConnection(
					searchItemsProxy,
					connections.currentUser.productCollectionsSearch,
					{
						search: other.collectionSearch,
					}
				)
				if (connectionSearchItems)
					ConnectionHandler.insertEdgeAfter(
						connectionSearchItems,
						newCollection
					)

				const imageListCollectionProxy = searchItemsProxy.getLinkedRecord(
					"collections",
					{
						search: other.collectionSearch,
					}
				)
				if (imageListCollectionProxy) {
					const edges = imageListCollectionProxy.getLinkedRecords("edges")
					if (edges) {
						edges.push(newCollection)
						imageListCollectionProxy.setLinkedRecords(edges, "edges")
					}
				}
			}
		},
		onError: complete(onCompleted, onError),
		onCompleted: complete(onCompleted, onError),
	})

export default { commit }
