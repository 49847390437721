import React, { Fragment, Component } from "react"
import InfiniteScroll from "react-infinite-scroller"
import { createPaginationContainer, graphql } from "react-relay"
import { toast } from "react-toastify"
import Avatar from "components/profile/avatar/avatar"
import DropdownMenu from "components/dropdown-menu/dropdown-menu"
import LoadingDots from "components/loading-dots/loading-dots"
import ConformationPrompt from "components/modals/conformation-prompt/conformation-prompt"
import RemoveTeamMember from "mutations/team/remove-team-member"
import UpdateTeamMember from "mutations/team/update-team-member"
import SendTeamInvite from "mutations/team/send-team-invite"
import ChangeOwnership from "mutations/team/change-ownership"
import gqlHelper from "helpers/gql-helper"
import stylesAvatar from "components/profile/avatar/avatar.css"
import button from "shared/styles/components/buttons.css"
import textfield from "shared/styles/components/textfield.css"
import grids from "shared/styles/components/grids.css"
import { toTitleCase } from "helpers/string-helper"
import styles from "./people-list.css"
import { withRouter } from "../../../route-helpers"


class PeopleListView extends Component {
	constructor(props) {
		super(props)
		this.state = {
			deleteMember: null,
			ownershipMember: null,
		}
	}

	changeRole(memberId, role) {
		const { relay } = this.props
		UpdateTeamMember.commit(
			relay.environment,
			memberId,
			role.toLowerCase(),
			(e) => {
				toast.error(gqlHelper.getError(e) || "Error updating the member", {
					autoClose: 2000,
					closeButton: false,
				})
			},
			() => {
				toast.success(<div>Member updated successfully</div>, {
					autoClose: 2000,
					closeButton: false,
				})
			}
		)
	}

	renderRole(member) {
		if (member.role === "OWNER") return "Owner"
		const { store } = this.props
		const {
			team: { myRole },
		} = store
		if (!["OWNER", "ADMIN"].includes(myRole)) return toTitleCase(member.role)
		return (
			<select
				className={textfield.cozy}
				defaultValue={member.role}
				onChange={(event) => {
					this.changeRole(member.id, event.target.value)
				}}
			>
				<option value="ADMIN">Admin</option>
				<option value="MEMBER">Member</option>
			</select>
		)
	}

	renderMenu(member) {
		const { relay, store } = this.props
		const {
			team: { myRole },
		} = store
		if (member.role === "OWNER") return null
		const listItems = []
		const push = (click, name) => listItems.push({ click, name })
		if (!["OWNER", "ADMIN"].includes(myRole)) {
			if (member.user.id !== store.currentUser.id) return null
			push(() => {
				this.setState({ deleteMember: member })
			}, "Leave Team")
		} else {
			if (!member.isInviteAccepted)
				push(() => {
					SendTeamInvite.commit(
						relay.environment,
						member.id,
						(e) => {
							toast.error(
								gqlHelper.getError(e) ||
										"Error sending the invite to the member",
								{ autoClose: 2000, closeButton: false }
							)
						},
						() => {
							toast.success(<div>Invite sent successfully</div>, {
								autoClose: 2000,
								closeButton: false,
							})
						}
					)
				}, "Send Invitation")
			if (myRole === "OWNER" && !!member.user)
				push(() => {
					this.setState({ ownershipMember: member })
				}, "Transfer Ownership")
			push(() => {
				this.setState({ deleteMember: member })
			}, "Remove From Team")
		}
		return listItems.length ? (
			<DropdownMenu
				items={listItems}
				darkTheme
				contentRight
				triggerClass={button.dropdownIcon}
				triggerClassActive={button.dropdownIconActive}
			/>
		) : null
	}

	render() {
		const {
			store: { currentUser, team },
			relay,
			navigate,
			setTeam
		} = this.props
		const { deleteMember, ownershipMember } = this.state
		const { members } = team || {
			members: {
				edges: [
					{
						node: {
							id: 1,
							fullname: currentUser.fullname,
							email: currentUser.email,
							role: "OWNER",
							isInviteAccepted: true,
							user: {
								profilePhoto: currentUser.profilePhoto,
							},
						},
					},
				],
			},
		}
		return (
			<Fragment>
				<InfiniteScroll
					loadMore={() => relay.loadMore(12, () => {})}
					hasMore={relay.hasMore()}
					loader={
						<div className={grids.loadMore}>
							<LoadingDots />
						</div>
					}
					pageStart={0}
				>
					<div className={styles.table}>
						<div className={styles.thead}>
							<div className={styles.tr}>
								<div className={styles.th}>Name</div>
								<div className={styles.th}>Email</div>
								<div className={styles.th}>Team Role</div>
								<div className={styles.th} />
							</div>
						</div>

						<div className={styles.tbody}>
							{members.edges.map(({ node: member }) => (
								<div className={styles.tr} key={member.id}>
									<div className={styles.td}>
										<div className={styles.nameGroup}>
											<Avatar
												styleName={stylesAvatar.image}
												photoUrl={member.user?.profilePhoto}
												fullname={member.fullname}
												imageSize="100"
												size="40px"
											/>
											<div className={styles.nameEmail}>
												<div className={styles.name}>
													{member.fullname}
												</div>
												{/* <div className={styles.email}>
													{member.email}
												</div> */}
											</div>
										</div>
									</div>
									<div className={styles.td}>{member.email}</div>
									<div className={styles.td}>
										{this.renderRole(member)}
									</div>
									<div className={styles.td}>
										{this.renderMenu(member)}
									</div>
								</div>
							))}
						</div>
					</div>
				</InfiniteScroll>
				<ConformationPrompt
					openModal={!!deleteMember}
					closeModal={() => this.setState({ deleteMember: null })}
					approveEvent={() => {
						RemoveTeamMember.commit(
							relay.environment,
							team.id,
							deleteMember.id,
							(e) => {
								toast.error(
									gqlHelper.getError(e) ||
										"Error removing member to the team.",
									{ autoClose: 2000, closeButton: false }
								)
							},
							() => {
								const {deleteMember: dm} = this.state
								toast.success(<div>Member successfully removed</div>, {
									autoClose: 2000,
									closeButton: false,
								})
								if (dm.user.id === currentUser.id){
									setTeam(null)
									navigate("/content")
								}
								else this.setState({ deleteMember: null })
							}
						)
					}}
					declineEvent={() => this.setState({ deleteMember: null })}
					title={
						deleteMember && deleteMember.user.id === currentUser.id
							? "Leave Team"
							: "Remove From Team"
					}
					approveText="Remove"
					approveButtonStyle={button.primaryRed}
					declineText="Cancel"
				>
					{deleteMember && deleteMember.user.id === currentUser.id
						? `If you go ahead, you'll lose access to everything in this team, including content you created.`
						: `${
								deleteMember && deleteMember.fullname !== "Invited"
									? deleteMember.fullname
									: "User"
						  } will no longer be able to access the team.`}
				</ConformationPrompt>
				<ConformationPrompt
					openModal={!!ownershipMember}
					closeModal={() => this.setState({ ownershipMember: null })}
					approveEvent={() => {
						ChangeOwnership.commit(
							relay.environment,
							ownershipMember.id,
							(e) => {
								toast.error(
									gqlHelper.getError(e) ||
										"Error transferring ownership to the member.",
									{ autoClose: 2000, closeButton: false }
								)
							},
							() => {
								toast.success(
									<div>Ownership transferred successfully</div>,
									{
										autoClose: 2000,
										closeButton: false,
									}
								)
								this.setState({ ownershipMember: null })
							}
						)
					}}
					declineEvent={() => this.setState({ ownershipMember: null })}
					title="Transfer Ownership"
					approveText="Transfer"
					approveButtonStyle={button.primary}
					declineText="Cancel"
				>
					Are you sure you want to transfer ownership to this member?
				</ConformationPrompt>
			</Fragment>
		)
	}
}

const peopleListRecordsQuery = graphql`
	query peopleListRecordsQuery($count: Int, $cursor: String, $teamId: String) {
		store {
			...peopleListRecords_store
				@arguments(count: $count, cursor: $cursor, teamId: $teamId)
		}
	}
`

PeopleListView = createPaginationContainer(
	PeopleListView,
	{
		store: graphql`
			fragment peopleListRecords_store on Store
			@argumentDefinitions(
				count: { type: "Int", defaultValue: 12 }
				cursor: { type: "String" }
				teamId: { type: "String" }
			) {
				team(id: $teamId) {
					id
					name
					myRole
					members(first: $count, after: $cursor)
						@connection(key: "teams_members") {
						edges {
							node {
								id
								fullname
								email
								role
								isInviteAccepted
								user {
									id
									profilePhoto
								}
							}
						}
					}
				}
				currentUser {
					id
					fullname
					email
					profilePhoto
				}
			}
		`,
	},
	{
		direction: "forward",
		getConnectionFromProps(props) {
			const { members } = props.store.team
			return members
		},
		getFragmentVariables(prevVars, totalCount) {
			return {
				...prevVars,
				count: totalCount,
			}
		},
		getVariables(props, { count, cursor }) {
			return {
				count,
				cursor,
				teamId: props.teamId,
			}
		},
		query: peopleListRecordsQuery,
	}
)

export default {
	Component: withRouter(PeopleListView),
	query: peopleListRecordsQuery,
	params: { count: 12, cursor: null, teamId: null },
}
