import { commitMutation, graphql } from "react-relay"
import { complete } from "../helper.js"

const mutation = graphql`
	mutation toggleTemplateHideShowMutation(
		$input: ToggleTemplateHideShowInput!
	) {
		toggleTemplateHideShow(input: $input) {
			output
		}
	}
`

const commit = (env, templateId, status, onSuccess, onError) =>
	commitMutation(env, {
		mutation,
		variables: { input: { templateId, status } },
		onError: () => complete(null, onError),
		updater: (storeProxy, data) => {
			if (data.toggleTemplateHideShow) {
				const templateProxy = storeProxy.get(templateId)
				const isGlobal = templateProxy.getValue("isGlobal")
				templateProxy.setValue(!isGlobal, "isGlobal")
				onSuccess()
			}
		},
	})

export default { commit }
