import React from "react"
import VolumeIcon from "shared/assets/volume-icon.svg"
import MuteIcon from "shared/assets/mute-icon.svg"

// import VolumeLevel from "./volume-level"
import styles from "./volume.css"

export default function Volume({ isMute, toggleMute, size }) {
	let icon = (
		<VolumeIcon
			className={size && size === "sm" ? styles.iconSm : styles.icon}
		/>
	)
	if (isMute === true) {
		icon = (
			<MuteIcon
				className={size && size === "sm" ? styles.iconSm : styles.icon}
			/>
		)
	}

	return (
		<div className={styles.root}>
			<button
				type="button"
				className={styles.button}
				onClick={() => toggleMute()}
			>
				{icon}
			</button>
			{/* <VolumeLevel /> */}
		</div>
	)
}
