import { jwtDecode } from "jwt-decode";
import React, { useContext, useEffect, useState } from "react"
import { commitLocalUpdate } from "relay-runtime"
import UpgradePlanToast from "components/upgrade-plan-toast/upgrade-plan-toast.jsx"
import { toast } from "react-toastify"
import cookieService from "./cookie-service"
import { GlobalContext } from "../global-context"

export const FeatureKeyENUM = {
	TEMPLATES: "templates",
	PUBLISH_SHOPCAST: "publish-shopcasts",
	EARN_COMMISSION: "earn-commission",
	SHOPBOARD_VIDEO: "shopboard-video",
	SHARED_FOLDERS: "shared-folders",
	CLIENT_CRM: "client-crm",
	TEAMS: "teams",
}

export const getFeatureAvalibale = async (key, env, teamId) => {
	let featureArray = []
	if (env) {
		featureArray = await new Promise((resolve, reject) => {
			commitLocalUpdate(env, (store) => {
				try {
					const features = store
						.getRoot()
						.getLinkedRecord("store")
						.getLinkedRecord("currentUser")
						.getValue("features", teamId ? { teamId } : null)
					resolve(features)
				} catch (error) {
					reject(error)
				}
			})
		})
	} else {
		const token = cookieService.getToken()
		const tokenData = jwtDecode(token)
		featureArray = tokenData.features || []
	}

	// console.log("features", featureArray)
	return featureArray.includes(key)
}

export const runIfFeatureAvailable = (func, key, env, teamId) => async (event) => {
	const isAvailable = await getFeatureAvalibale(key, env, teamId)
	if (!isAvailable) {
		toast.info(<UpgradePlanToast />, {
			autoClose: 3000,
			closeButton: false,
		})
		return null
	}
	return func(event)
}

export function FeatureAvailableComponent({ featureKey, env, children }) {
	const [enabled, setEnabled] = useState(false)
	const { team } = useContext(GlobalContext)
	useEffect(() => {
		getFeatureAvalibale(featureKey, env, team).then((result) => {
			setEnabled(result)
		})
	}, [featureKey, env, team])
	if (!enabled) return null
	return children
}
