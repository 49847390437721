import React from "react"
import styles from "./list-of-features.css"

export default function ListOfFeatures({ features }) {
	return (
		<ul className={styles.root}>
			{features.map((feature) => (
				<li className={styles.callout} key={feature.key}>
					<h4 className={styles.headline}>{feature.heading}</h4>
					<p className={styles.body}>{feature.body}</p>
				</li>
			))}
		</ul>
	)
}
