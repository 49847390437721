import React, { PureComponent, Fragment } from "react"
import { createFragmentContainer, graphql } from "react-relay"
import { Link, Navigate } from "react-router-dom"
import stylesAvatar from "components/profile/avatar/avatar.css"
import Avatar from "components/profile/avatar/avatar"
import twoColumn from "shared/styles/layout/two-column.css"
import pageContent from "shared/styles/layout/page-content.css"
import contentCard from "shared/styles/layout/content-card.css"
// import dayjs from "helpers/dayjs-helper"
import { getCommisionMessage } from "helpers/referral-helper"
import styles from "./admin-referral-view-campaign.css"

class AdminReferralViewCampaign extends PureComponent {
	render() {
		const { store } = this.props
		const { campaign } = store.admin
		if (!campaign) return <Navigate to="/admin/campaign" />
		const total = campaign.referrals.reduce(
			(a, b) => ({
				revenue: a.revenue + b.revenue,
				paidCommision: a.paidCommision + b.paidCommision,
			}),
			{ revenue: 0, paidCommision: 0 }
		)
		return (
			<Fragment>
				<div className={twoColumn.header}>
					<h1 className={twoColumn.title}>{campaign.name}</h1>
				</div>
				<div className={pageContent.root}>
					<p>
						{getCommisionMessage(campaign, true)}
						{/* Created{" "}{dayjs(+campaign.createdAt).format("MMMM DD, YYYY")} */}
					</p>
					<div className={styles.row}>
						<div className={styles.col}>
							<div className={contentCard.header}>
								<h4 className={contentCard.heading}>Referrals</h4>
							</div>

							<div className={styles.table}>
								<div className={styles.thead}>
									<div className={styles.tr}>
										<div className={styles.th}>Visitors</div>
										<div className={styles.th}>Leads</div>
										<div className={styles.th}>Conversions</div>
									</div>
									<div className={styles.tr}>
										<div className={styles.td}>
											{campaign.visitors}
										</div>
										<div className={styles.td}>{campaign.leads}</div>
										<div className={styles.td}>
											{campaign.conversions}
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className={styles.col}>
							<div className={contentCard.header}>
								<h4 className={contentCard.heading}>Commisions</h4>
							</div>
							<div className={styles.table}>
								<div className={styles.thead}>
									<div className={styles.tr}>
										<div className={styles.th}>Unpaid</div>
										<div className={styles.th}>Paid</div>
										<div className={styles.th}>Total</div>
										{/* <div className={styles.th}>Revenue</div> */}
									</div>
									<div className={styles.tr}>
										<div className={styles.td}>
											$
											{(total.revenue - total.paidCommision).toFixed(
												2
											)}
										</div>
										<div className={styles.td}>
											${total.paidCommision.toFixed(2)}
										</div>
										<div className={styles.td}>
											${campaign.totalCommision.toFixed(2)}
										</div>
										{/* <div className={styles.td}>
											${campaign.totalRevenue.toFixed(2)}
										</div> */}
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className={styles.rowFull}>
						<div className={styles.col}>
							<div className={contentCard.header}>
								<h4 className={contentCard.heading}>Affiliates</h4>
							</div>
							<div className={styles.table}>
								<div className={styles.thead}>
									<div className={styles.tr}>
										<div className={styles.th}>Affiliate</div>
										<div className={styles.th}>Vistors</div>
										<div className={styles.th}>Leads</div>
										<div className={styles.th}>Conv.</div>
										<div className={styles.th}>Unpaid</div>
										<div className={styles.th}>Paid</div>
										<div className={styles.th}>Total</div>
									</div>
									{campaign.referrals.map((referral) => (
										<div className={styles.tr}>
											<div className={styles.tdTh}>
												<Link
													className={styles.user}
													to={`/admin/user/${referral.user.id}`}
												>
													<Avatar
														styleName={stylesAvatar.image}
														photoUrl={referral.user.profilePhoto}
														fullname={referral.user.fullname}
														imageSize="100"
														size="32px"
													/>
													{referral.user.fullname}
												</Link>
											</div>
											<div className={styles.td}>
												{referral.visitors}
											</div>
											<div className={styles.td}>
												{referral.leads}
											</div>
											<div className={styles.td}>
												{referral.conversions}
											</div>
											<div className={styles.td}>
												$
												{(
													referral.revenue - referral.paidCommision
												).toFixed(2)}
											</div>
											<div className={styles.td}>
												${referral.paidCommision.toFixed(2)}
											</div>
											<div className={styles.td}>
												${referral.revenue.toFixed(2)}
											</div>
										</div>
									))}
								</div>
							</div>
						</div>
					</div>
				</div>
			</Fragment>
		)
	}
}

AdminReferralViewCampaign = createFragmentContainer(AdminReferralViewCampaign, {
	store: graphql`
		fragment adminReferralViewCampaign_store on Store
		@argumentDefinitions(id: { type: "String!" }) {
			id
			admin {
				campaign(id: $id) {
					id
					name
					monthlyCommision
					annualCommision
					type
					createdAt
					isArchived
					totalRevenue
					totalCommision
					visitors
					leads
					conversions
					referrals {
						user {
							id
							fullname
							profilePhoto
						}
						visitors
						leads
						conversions
						revenue
						paidCommision
					}
				}
			}
		}
	`,
})

const viewCampaignQuery = graphql`
	query adminReferralViewCampaignQuery($id: String!) {
		store {
			...adminReferralViewCampaign_store @arguments(id: $id)
		}
	}
`

export default {
	Component: AdminReferralViewCampaign,
	query: viewCampaignQuery,
	params: (rp) => ({ id: rp.params.id }),
}
