/**
 * @generated SignedSource<<6e0c801ab96c1163ef8b3c29f01ad989>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentType } from "relay-runtime";
declare export opaque type clientFolderCard_shopcastFolder$fragmentType: FragmentType;
export type clientFolderCard_shopcastFolder$data = {|
  +id: string,
  +items: ?{|
    +edges: ?$ReadOnlyArray<?{|
      +node: ?({|
        +__typename: "Lookbook",
        +url: ?string,
      |} | {|
        +__typename: "ProductCollection",
        +id: string,
        +images: ?$ReadOnlyArray<?string>,
      |} | {|
        +__typename: "Shopcast",
        +posterUrl: ?string,
      |} | {|
        +__typename: "ShopshareFile",
        +thumbnailUrl: ?string,
        +url: ?string,
      |} | {|
        // This will never be '%other', but we need some
        // value in case none of the concrete values match.
        +__typename: "%other",
      |}),
    |}>,
  |},
  +itemsCount: ?number,
  +name: ?string,
  +$fragmentType: clientFolderCard_shopcastFolder$fragmentType,
|};
export type clientFolderCard_shopcastFolder$key = {
  +$data?: clientFolderCard_shopcastFolder$data,
  +$fragmentSpreads: clientFolderCard_shopcastFolder$fragmentType,
  ...
};
*/

var node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "url",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "clientFolderCard_shopcastFolder",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "itemsCount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "first",
          "value": 4
        }
      ],
      "concreteType": "FolderItemsConnectionConnection",
      "kind": "LinkedField",
      "name": "items",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "FolderItemsConnectionEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": null,
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__typename",
                  "storageKey": null
                },
                {
                  "kind": "InlineFragment",
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "posterUrl",
                      "storageKey": null
                    }
                  ],
                  "type": "Shopcast",
                  "abstractKey": null
                },
                {
                  "kind": "InlineFragment",
                  "selections": [
                    (v1/*: any*/)
                  ],
                  "type": "Lookbook",
                  "abstractKey": null
                },
                {
                  "kind": "InlineFragment",
                  "selections": [
                    (v1/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "thumbnailUrl",
                      "storageKey": null
                    }
                  ],
                  "type": "ShopshareFile",
                  "abstractKey": null
                },
                {
                  "kind": "InlineFragment",
                  "selections": [
                    (v0/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "images",
                      "storageKey": null
                    }
                  ],
                  "type": "ProductCollection",
                  "abstractKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "items(first:4)"
    }
  ],
  "type": "ShopcastFolder",
  "abstractKey": null
};
})();

(node/*: any*/).hash = "848344242b3a78f5794d636f6ed93f69";

module.exports = ((node/*: any*/)/*: Fragment<
  clientFolderCard_shopcastFolder$fragmentType,
  clientFolderCard_shopcastFolder$data,
>*/);
