/**
 * @generated SignedSource<<ab7602b8a8ae858f8d5c124e785e2552>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentType } from "relay-runtime";
declare export opaque type shopcast_store$fragmentType: FragmentType;
export type shopcast_store$data = {|
  +categories: ?$ReadOnlyArray<?{|
    +id: string,
    +name: ?string,
  |}>,
  +currentUser: ?{|
    +adminAccess: ?string,
    +id: string,
    +role: ?string,
    +userClients: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +email: ?string,
          +fullname: ?string,
          +id: string,
        |},
      |}>,
    |},
  |},
  +id: string,
  +shopcast: ?{|
    +category: ?{|
      +id: string,
    |},
    +createdDate: ?string,
    +description: ?string,
    +duration: ?number,
    +hasCurrentUserLikedIt: ?boolean,
    +id: string,
    +isFlagged: ?boolean,
    +isPublished: ?boolean,
    +isRecommended: ?boolean,
    +likes: ?number,
    +mp4Url: ?string,
    +muxedUrl: ?string,
    +posterUrl: ?string,
    +shopcastItems: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +end: ?number,
          +id: string,
          +product: ?{|
            +affiliated_url: ?string,
            +brand: ?string,
            +custom_affiliated_url: ?string,
            +description: ?string,
            +diffbotImageUrl: ?string,
            +error: ?string,
            +hasProductImagesFromDiffbot: ?boolean,
            +id: string,
            +imageUrl: ?string,
            +isOwnedItems: ?boolean,
            +offerPrice: ?string,
            +productCollections: ?$ReadOnlyArray<?{|
              +id: string,
              +isACloset: ?boolean,
              +isPublished: ?boolean,
              +title: ?string,
            |}>,
            +productImages: ?$ReadOnlyArray<?string>,
            +regularPrice: ?string,
            +title: ?string,
            +url: ?string,
          |},
          +start: ?number,
        |},
      |}>,
    |},
    +subtitle: ?string,
    +team: ?{|
      +avatar: ?string,
      +displayAvatar: ?string,
      +displayName: ?string,
      +id: string,
      +name: ?string,
    |},
    +title: ?string,
    +transactions: ?$ReadOnlyArray<?{|
      +advertiserName: ?string,
      +currency: ?string,
      +id: string,
      +network: ?string,
      +orderId: ?string,
      +product: ?{|
        +id: string,
        +title: ?string,
      |},
      +productNames: ?$ReadOnlyArray<?string>,
      +sales: ?number,
      +totalCommission: ?number,
      +transactionDate: ?string,
    |}>,
    +user: ?{|
      +bannerPhoto: ?string,
      +canEdit: ?boolean,
      +customDomain: ?string,
      +fullname: ?string,
      +id: string,
      +isFollowing: ?boolean,
      +productCollections: ?{|
        +edges: ?$ReadOnlyArray<?{|
          +node: ?{|
            +client: ?{|
              +fullname: ?string,
              +id: string,
            |},
            +id: string,
            +isACloset: ?boolean,
            +isDefault: ?boolean,
            +isPublished: ?boolean,
            +title: ?string,
          |},
        |}>,
      |},
      +profilePhoto: ?string,
      +socialLinks: ?{|
        +facebookUrl: ?string,
        +instagramUsername: ?string,
        +pinterestUrl: ?string,
        +twitterUsername: ?string,
        +website: ?string,
        +youtubeUrl: ?string,
      |},
      +username: ?string,
    |},
    +views: ?number,
  |},
  +$fragmentType: shopcast_store$fragmentType,
|};
export type shopcast_store$key = {
  +$data?: shopcast_store$data,
  +$fragmentSpreads: shopcast_store$fragmentType,
  ...
};
*/

var node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "fullname",
  "storageKey": null
},
v5 = [
  {
    "kind": "Variable",
    "name": "teamId",
    "variableName": "teamId"
  }
],
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isACloset",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isPublished",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "clientId"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "id"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "teamId"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": null,
        "cursor": null,
        "direction": "forward",
        "path": [
          "shopcast",
          "shopcastItems"
        ]
      }
    ]
  },
  "name": "shopcast_store",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Category",
      "kind": "LinkedField",
      "name": "categories",
      "plural": true,
      "selections": [
        (v1/*: any*/),
        (v0/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "id",
          "variableName": "id"
        }
      ],
      "concreteType": "Shopcast",
      "kind": "LinkedField",
      "name": "shopcast",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v2/*: any*/),
        (v3/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "views",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "likes",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "hasCurrentUserLikedIt",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "createdDate",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Category",
          "kind": "LinkedField",
          "name": "category",
          "plural": false,
          "selections": [
            (v0/*: any*/)
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "team",
          "kind": "LinkedField",
          "name": "team",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            (v1/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "displayName",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "avatar",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "displayAvatar",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "SiteTransactionObj",
          "kind": "LinkedField",
          "name": "transactions",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "network",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "sales",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "totalCommission",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "currency",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "ShopcastProduct",
              "kind": "LinkedField",
              "name": "product",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                (v2/*: any*/)
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "productNames",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "transactionDate",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "advertiserName",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "orderId",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "User",
          "kind": "LinkedField",
          "name": "user",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "username",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "profilePhoto",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "bannerPhoto",
              "storageKey": null
            },
            (v4/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "canEdit",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "customDomain",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "isFollowing",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "UserSocialLinks",
              "kind": "LinkedField",
              "name": "socialLinks",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "website",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "instagramUsername",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "facebookUrl",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "twitterUsername",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "youtubeUrl",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "pinterestUrl",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": (v5/*: any*/),
              "concreteType": "ProductCollectionConnection",
              "kind": "LinkedField",
              "name": "productCollections",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "ProductCollectionEdge",
                  "kind": "LinkedField",
                  "name": "edges",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "ProductCollection",
                      "kind": "LinkedField",
                      "name": "node",
                      "plural": false,
                      "selections": [
                        (v0/*: any*/),
                        (v2/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "isDefault",
                          "storageKey": null
                        },
                        (v6/*: any*/),
                        (v7/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "Client",
                          "kind": "LinkedField",
                          "name": "client",
                          "plural": false,
                          "selections": [
                            (v0/*: any*/),
                            (v4/*: any*/)
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "duration",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "muxedUrl",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "subtitle",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "mp4Url",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "posterUrl",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isFlagged",
          "storageKey": null
        },
        (v7/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isRecommended",
          "storageKey": null
        },
        {
          "alias": "shopcastItems",
          "args": [
            {
              "kind": "Literal",
              "name": "first",
              "value": 100
            }
          ],
          "concreteType": "ShopcastItemConnection",
          "kind": "LinkedField",
          "name": "__shopcast_shopcastItems_connection",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ShopcastItemEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "shopcastItem",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "ShopcastProduct",
                      "kind": "LinkedField",
                      "name": "product",
                      "plural": false,
                      "selections": [
                        (v0/*: any*/),
                        (v2/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "brand",
                          "storageKey": null
                        },
                        (v3/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "url",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": [
                            {
                              "kind": "Literal",
                              "name": "type",
                              "value": "shopcast"
                            }
                          ],
                          "kind": "ScalarField",
                          "name": "affiliated_url",
                          "storageKey": "affiliated_url(type:\"shopcast\")"
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "custom_affiliated_url",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "imageUrl",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "diffbotImageUrl",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "regularPrice",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "offerPrice",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "error",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "productImages",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "hasProductImagesFromDiffbot",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "ProductCollection",
                          "kind": "LinkedField",
                          "name": "productCollections",
                          "plural": true,
                          "selections": [
                            (v0/*: any*/),
                            {
                              "alias": null,
                              "args": [
                                {
                                  "kind": "Literal",
                                  "name": "ownershipCheck",
                                  "value": true
                                }
                              ],
                              "kind": "ScalarField",
                              "name": "title",
                              "storageKey": "title(ownershipCheck:true)"
                            },
                            (v6/*: any*/),
                            (v7/*: any*/)
                          ],
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": [
                            {
                              "kind": "Variable",
                              "name": "clientId",
                              "variableName": "clientId"
                            }
                          ],
                          "kind": "ScalarField",
                          "name": "isOwnedItems",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "start",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "end",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "__typename",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "cursor",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "PageInfo",
              "kind": "LinkedField",
              "name": "pageInfo",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "endCursor",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "hasNextPage",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": "__shopcast_shopcastItems_connection(first:100)"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "currentUser",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "adminAccess",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "role",
          "storageKey": null
        },
        {
          "alias": null,
          "args": (v5/*: any*/),
          "concreteType": "ClientConnectionConnection",
          "kind": "LinkedField",
          "name": "userClients",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ClientConnectionEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Client",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    (v4/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "email",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Store",
  "abstractKey": null
};
})();

(node/*: any*/).hash = "14987429a5eec238969ac2be4cfd000f";

module.exports = ((node/*: any*/)/*: Fragment<
  shopcast_store$fragmentType,
  shopcast_store$data,
>*/);
