import React, { Fragment } from "react"
import { graphql, createPaginationContainer, QueryRenderer } from "react-relay"
import empty from "shared/styles/components/empty.css"
import grids from "shared/styles/components/grids.css"
import dayjs from "../../helpers/dayjs-helper"
import styles from "./list-of-rakuten-sites.css"

function Comp(props) {
	const { store, loading, showRates } = props
	let sites = null
	if (!loading) {
		sites = store.rakutenSites
	}

	function groupBy(list, keyGetter) {
		const map = {}
		list.forEach((item) => {
			const key = keyGetter(item)
			const collection = map[key]
			if (!collection) {
				map[key] = [item]
			} else {
				collection.push(item)
			}
		})
		return map
	}

	function renderCommissionRate(site) {
		if (!showRates) return false
		let rateText = site.commissionRate
		// If it has a special commission rate, format it and show it
		if (site.hasSpecialRate) {
			const data = dayjs(+site.specialCommissionRateValidUntil)
			const timeFormat =
				data.year() === dayjs().year() ? "MMM DD" : "MMM DD YYYY"
			rateText = `${site.specialCommissionRate} until ${data.format(
				timeFormat
			)}`
		}
		return <span className={styles.listRate}>({rateText})</span>
	}

	function renderNoRetailers() {
		return (
			<div className={grids.span}>
				<div className={empty.simple}>Sorry, no results were found.</div>
			</div>
		)
	}

	const groupedItems = groupBy(
		loading || !sites ? [] : sites.edges,
		(item) => {
			const { displayName } = item.node
			return displayName[0].toLowerCase().match(/[a-z]/i)
				? displayName[0].toUpperCase()
				: "#"
		}
	)

	return (
		<Fragment>
			{sites && !sites.edges.length && renderNoRetailers()}
			{Object.keys(groupedItems)
				.sort()
				.map((key) => (
					<div className={styles.list} key={key}>
						<div className={styles.alphabet}>
							<h2 className={styles.letter}>{key}</h2>
						</div>
						<ul className={styles.retailer}>
							{groupedItems[key].map((value) => (
								<li className={styles.item} key={value.node.id}>
									<a
										href={value.node.displayUrl}
										target="_blank"
										rel="noreferrer"
									>
										<h4 className={styles.listName}>
											{value.node.displayName}
										</h4>
									</a>
									{store.currentUser &&
										!store.currentUser.isRetailer &&
										renderCommissionRate(value.node)}
								</li>
							))}
						</ul>
					</div>
				))}
		</Fragment>
	)
}

const listOfRakutenSitesListQuery = graphql`
	query listOfRakutenSitesListQuery(
		$count: Int!
		$cursor: String
		$category: String
		$sortBy: String
		$region: String
		$network: String
		$searchText: String
		$partner: String
	) {
		store {
			...listOfRakutenSitesList_store
				@arguments(
					count: $count
					cursor: $cursor
					category: $category
					sortBy: $sortBy
					region: $region
					network: $network
					searchText: $searchText
					partner: $partner
				)
		}
	}
`

const ListOfRakutenSites = createPaginationContainer(
	Comp,
	{
		store: graphql`
			fragment listOfRakutenSitesList_store on Store
			@argumentDefinitions(
				count: { type: "Int", defaultValue: 12 }
				cursor: { type: "String" }
				category: { type: "String" }
				sortBy: { type: "String" }
				region: { type: "String" }
				network: { type: "String" }
				searchText: { type: "String" }
				partner: { type: "String" }
			) {
				currentUser {
					isRetailer
				}
				rakutenSites(
					first: $count
					after: $cursor
					category: $category
					sortBy: $sortBy
					region: $region
					network: $network
					searchText: $searchText
					partner: $partner
				) @connection(key: "admin_list_rakutenSites") {
					edges {
						node {
							name
							mid
							urls
							id
							displayName
							description
							countries {
								id
								name
								code
							}
							category {
								id
							}
							commissionRate
							imageUrl
							displayUrl
							logoUrl
							isPublic
							specialCommissionRate
							specialCommissionRateValidUntil
							hasSpecialRate
							networks {
								id
								currency
								name
							}
							partneredNetwork
						}
					}
				}
			}
		`,
	},
	{
		direction: "forward",
		getConnectionFromProps(props) {
			const { store } = props
			return store && store.rakutenSites
		},
		getFragmentVariables(prevVars, totalCount) {
			return {
				...prevVars,
				count: totalCount,
			}
		},
		getVariables(props, { cursor }) {
			return {
				count: props.count,
				cursor,
				category: props.category,
				region: props.region,
				network: props.network,
				searchText: props.searchText,
				sortBy: props.sortBy,
				partner: props.partner,
			}
		},
		query: listOfRakutenSitesListQuery,
	}
)

function variablesToQuery(props) {
	return {
		category: props.category,
		region: props.region,
		network: props.network,
		searchText: props.searchText,
		sortBy: props.sortBy,
		count: props.count,
		partner: props.partner,
	}
}

function Sites(p) {
	const { relay } = p
	return (
		<QueryRenderer
			relay={{ environment: {}, variables: [] }}
			query={listOfRakutenSitesListQuery}
			variables={variablesToQuery(p)}
			environment={relay.environment}
			render={({ props }) =>
				props ? (
					<ListOfRakutenSites
						store={null}
						{...props}
						{...p}
						loading={false}
					/>
				) : (
					<ListOfRakutenSites store={null} {...props} {...p} loading />
				)
			}
		/>
	)
}

export default Sites
