/**
 * @generated SignedSource<<98725723bf91f12ebc90a29d95158815>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Mutation } from 'relay-runtime';
export type CreateUpdateShapesInput = {|
  clientMutationId?: ?string,
  collection: string,
  id?: ?string,
  path: string,
  viewBox: string,
|};
export type updateLookbookShapesMutation$variables = {|
  input: CreateUpdateShapesInput,
|};
export type updateLookbookShapesMutation$data = {|
  +createUpdateShapes: ?{|
    +shape: ?{|
      +collection: ?string,
      +id: string,
      +path: ?string,
      +viewBox: ?string,
    |},
  |},
|};
export type updateLookbookShapesMutation = {|
  response: updateLookbookShapesMutation$data,
  variables: updateLookbookShapesMutation$variables,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "CreateUpdateShapesPayload",
    "kind": "LinkedField",
    "name": "createUpdateShapes",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Shape",
        "kind": "LinkedField",
        "name": "shape",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "viewBox",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "path",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "collection",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "updateLookbookShapesMutation",
    "selections": (v1/*: any*/),
    "type": "ShopshareMutationRoot",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "updateLookbookShapesMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "80779b3064ff02feaa15665713548c56",
    "id": null,
    "metadata": {},
    "name": "updateLookbookShapesMutation",
    "operationKind": "mutation",
    "text": "mutation updateLookbookShapesMutation(\n  $input: CreateUpdateShapesInput!\n) {\n  createUpdateShapes(input: $input) {\n    shape {\n      id\n      viewBox\n      path\n      collection\n    }\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "73be54f7e77f06029fc2a4e68c5a3864";

module.exports = ((node/*: any*/)/*: Mutation<
  updateLookbookShapesMutation$variables,
  updateLookbookShapesMutation$data,
>*/);
