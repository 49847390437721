/**
 * @generated SignedSource<<da6976e1821ebb3419b74d73f4138d8f>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { productCollectionCard_productCollection$fragmentType } from "./../../../components/cards/product-collection-card/__generated__/productCollectionCard_productCollection.graphql";
import type { FragmentType } from "relay-runtime";
declare export opaque type clientViewClosetList_store$fragmentType: FragmentType;
export type clientViewClosetList_store$data = {|
  +currentUser: ?{|
    +closetCollectionsByFolder: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +$fragmentSpreads: productCollectionCard_productCollection$fragmentType,
        |},
      |}>,
    |},
    +id: string,
  |},
  +id: string,
  +$fragmentType: clientViewClosetList_store$fragmentType,
|};
export type clientViewClosetList_store$key = {
  +$data?: clientViewClosetList_store$data,
  +$fragmentSpreads: clientViewClosetList_store$fragmentType,
  ...
};
*/

var node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": 12,
      "kind": "LocalArgument",
      "name": "count"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "cursor"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "folder"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "sort"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "count",
        "cursor": "cursor",
        "direction": "forward",
        "path": [
          "currentUser",
          "closetCollectionsByFolder"
        ]
      }
    ]
  },
  "name": "clientViewClosetList_store",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "currentUser",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": "closetCollectionsByFolder",
          "args": [
            {
              "kind": "Variable",
              "name": "folderId",
              "variableName": "folder"
            },
            {
              "kind": "Variable",
              "name": "sort",
              "variableName": "sort"
            }
          ],
          "concreteType": "ProductCollectionConnection",
          "kind": "LinkedField",
          "name": "__currentUser_closetCollectionsByFolder_connection",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ProductCollectionEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "ProductCollection",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    {
                      "args": null,
                      "kind": "FragmentSpread",
                      "name": "productCollectionCard_productCollection"
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "__typename",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "cursor",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "PageInfo",
              "kind": "LinkedField",
              "name": "pageInfo",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "endCursor",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "hasNextPage",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Store",
  "abstractKey": null
};
})();

(node/*: any*/).hash = "89f33bbe35e3fc966d26a84044b56028";

module.exports = ((node/*: any*/)/*: Fragment<
  clientViewClosetList_store$fragmentType,
  clientViewClosetList_store$data,
>*/);
