import React, { Component } from "react"
import { createFragmentContainer, graphql } from "react-relay"
import FolderIcon from "shared/assets/folder-icon.svg"
import FolderShareIcon from "shared/assets/folder-share-icon.svg"
import FolderLookbookIcon from "shared/assets/folder-lookbook-icon.svg"
import input from "shared/styles/components/input.css"
import transitionStyles from "shared/styles/utilities/transition-opacity.css"
import PinButton from "components/buttons/pin-button"
import { Transition } from "react-transition-group"
import createNewFolderMutation from "mutations/shopcast-manager/create-new-folder"
import renameShopcastFolderMutation from "mutations/shopcast-manager/rename-shopcast-folder"
import moveShopcastItemsMutation from "mutations/shopcast-manager/move-shopcast-items"
import { withDrag, withDrop } from "helpers/dnd-helper"
import { mergeRefs } from "helpers/type-helper"
import { ShowCardControlsComponent } from "helpers/ui-helper"
import Avatar from "components/profile/avatar/avatar"
import FolderMenu from "./folder-menu"
import styles from "./shopcast-folder.css"
import { withRouter } from "../../../route-helpers"

const dropShopcastTarget = {
	drop(props, monitor) {
		const { folder, relay, parentId, reportError, container, team } = props

		const { id, type } = monitor.getItem()
		const contain = container
		if (type === "folder") {
			if (id === folder.id) {
				return
			}
			moveShopcastItemsMutation.commit(
				relay.environment,
				[{ id, type }],
				folder.id,
				team,
				parentId,
				contain,
				reportError("Could not move folder")
			)
		} else {
			moveShopcastItemsMutation.commit(
				relay.environment,
				[{ id, type }],
				folder.id,
				team,
				parentId,
				contain,
				reportError("Could not move shopcast")
			)
		}
	},
}

function dropCollect(monitor) {
	return {
		isOver: monitor.isOver(),
	}
}

function dragCollect(monitor) {
	return {
		isDragging: monitor.isDragging(),
	}
}

class ShopcastFolder extends Component {
	constructor(props) {
		super(props)

		this.handleNameChange = this.handleNameChange.bind(this)
		this.handleNameInputFocusOut = this.handleNameInputFocusOut.bind(this)
		this.handleNameInputKeyPress = this.handleNameInputKeyPress.bind(this)
		this.handleClick = this.handleClick.bind(this)
		this.handleRenameClick = this.handleRenameClick.bind(this)
		this.handleSelectChange = this.handleSelectChange.bind(this)

		this.type = "folder"

		this.state = {
			creating: !!props.creating,
			renaming: false,
			editName: props.creating ? "New Folder" : props.folder.name,
			isDropdownOpen: false,
			// checked: false,
		}
	}

	//
	// ─── LIFE CYCLE ─────────────────────────────────────────────────────────────────
	//

	componentDidMount() {
		const { state } = this
		if (state.creating) {
			this.focusOnTheInput()
		}
	}

	//
	// ─── EVENT HANDLERS ─────────────────────────────────────────────────────────────
	//

	handleNameChange({ target }) {
		this.setState({ editName: target.value })
	}

	handleNameInputFocusOut() {
		const { creating, editName, renaming } = this.state
		const { endEditing } = this.props
		const {
			relay,
			parentId,
			doneCreation,
			reportError,
			container,
			refetch,
		} = this.props
		console.log("Create New", container)
		endEditing()
		if (creating) {
			doneCreation()
			if (editName) {
				createNewFolderMutation.commit(
					relay.environment,
					editName,
					parentId,
					container,
					null,
					() => refetch(),
					reportError("Could not create the folder")
				)
			}
			this.setState({ creating: false })
		} else if (renaming) {
			renameShopcastFolderMutation.commit(
				relay.environment,
				this.folder.id,
				editName,
				false,
				() => {},
				reportError("Could not rename the folder")
			)
			this.setState({ renaming: false })
		}
	}

	handleNameInputKeyPress(e) {
		e.stopPropagation()
		const { key } = e
		if (key === "Enter") {
			this.handleNameInputFocusOut()
		}
	}

	handleClick() {
		const { enableCardSelection, checked } = this.props
		const isClient = this.folder.clients && this.folder.clients.length > 0
		if (enableCardSelection && !isClient) {
			this.handleSelectChange({
				target: { checked: !checked },
			})
			return
		}
		const { renaming, creating } = this.state
		if (renaming || creating) {
			return
		}
		const { navigate } = this.props
		navigate(`/content/${this.folder.id}`)
	}

	handleRenameClick() {
		const { startEditing } = this.props
		this.setState({ renaming: true })
		startEditing()
		setTimeout(this.focusOnTheInput.bind(this), 1)
	}

	handleSelectChange({ target }) {
		const { checked } = target
		// this.setState({ checked })
		const { onSelect, onDeselect, folder } = this.props
		;(checked ? onSelect : onDeselect)({
			id: folder.id,
			type: this.type,
			deselect: () => {
				this.handleSelectChange({ target: { checked: false } })
			},
		})
	}

	//
	// ─── GETTERS ────────────────────────────────────────────────────────────────────
	//

	get folder() {
		const { props } = this
		return props.folder
	}

	//
	// ─── METHODS ────────────────────────────────────────────────────────────────────
	//

	focusOnTheInput() {
		this.nameInput.focus()
		this.nameInput.select()
	}

	//
	// ─── RENDERS ────────────────────────────────────────────────────────────────────
	//

	renderMenu() {
		const {
			relay,
			setClientFolder,
			canStar,
			container,
			confirmDelete,
			requestMove,
			requestCopy,
			parentId,
			onlyClients,
			searchValue,
			checked,
			isAdmin,
			team
		} = this.props
		return (
			<div
				className={styles.menu}
				onClick={(e) => e.stopPropagation()}
				role="menu"
				tabIndex={0}
				onKeyPress={(e) => e.stopPropagation()}
			>
				<FolderMenu
					folder={this.folder}
					canStar={canStar}
					setDropdownOpened={(val) => {
						this.setState({ isDropdownOpen: val })
					}}
					relay={relay}
					container={container}
					setClientFolder={setClientFolder}
					confirmDelete={confirmDelete}
					requestMove={requestMove}
					requestCopy={requestCopy}
					showGoToFolder={!!searchValue}
					onRenameClick={this.handleRenameClick}
					otherProps={{
						folder: parentId,
						onlyClients,
						isFolderConnection: true,
					}}
					onSelectItems={() => {
						this.handleSelectChange({
							target: { checked: !checked },
						})
					}}
					isAdmin={isAdmin}
					team={team}
				/>
			</div>
		)
	}

	renderFolderIcon = () => {
		if (!this.folder.sharableLink)
			return <FolderIcon className={styles.icon} />
		if (!this.folder.isParentAClient)
			return (
				<FolderShareIcon
					className={styles.icon}
					data-tooltip-id="tooltip"
					data-tooltip-content="Shared Folder"
				/>
			)
		return (
			<FolderLookbookIcon
				className={styles.icon}
				data-tooltip-id="tooltip"
				data-tooltip-content="Lookbook"
			/>
		)
	}

	render() {
		const { state } = this
		const {
			dropRef,
			isOver,
			dragRef,
			checked,
			// togglePinned,
			enableCardSelection,
		} = this.props
		const isClient = this.folder.clients && this.folder.clients.length > 0
		const { isDropdownOpen } = state
		return (
			<ShowCardControlsComponent
				className={checked || isOver ? styles.rootActive : styles.root}
				innerRef={
					state.creating || state.renaming
						? null
						: (r) => {
								mergeRefs(dropRef, dragRef)(r)
						  }
				}
				role="button"
				tabIndex={0}
				onClick={this.handleClick}
				onKeyPress={this.handleClick}
			>
				{(isShown) => (
					<>
						<div className={isClient ? styles.media : styles.mediaFill}>
							{!isClient && (
								enableCardSelection ? (
									<input
										type="checkbox"
										className={`${input.checkbox} ${input.lg}`}
										onChange={this.handleSelectChange}
										checked={checked}
										onClick={(e) => e.stopPropagation()}
										onKeyPress={(e) => e.stopPropagation()}
									/>
								) : (
									this.renderFolderIcon()
								)
							)}
							{isClient && (
								<Avatar
									styleName={styles.avatar}
									photoUrl={this.folder.clients[0].avatar}
									fullname={this.folder.clients[0].fullname}
									imageSize="100"
								/>
							)}
						</div>
						<div
							className={styles.content}
							hidden={state.creating || state.renaming}
						>
							<h4 className={styles.name}>
								{isClient
									? this.folder.clients[0].fullname
									: this.folder.name}
							</h4>
							<div className={styles.meta}>
								<span className={styles.count}>
									{this.folder.itemsCount} Items
								</span>
								<PinButton
									// onClick={togglePinned}
									size="sm"
									isPinned={this.folder.isPinned}
								/>
							</div>
						</div>
						<input
							ref={(n) => {
								this.nameInput = n
							}}
							type="text"
							className={styles.input}
							hidden={!state.creating && !state.renaming}
							value={state.editName}
							onChange={this.handleNameChange}
							onBlur={this.handleNameInputFocusOut}
							onKeyPress={this.handleNameInputKeyPress}
						/>
						{!state.creating && !state.renaming && (
							<Transition
								in={(isShown || isDropdownOpen) && !enableCardSelection}
								timeout={200}
							>
								{(st) => (
									<div
										className={`${styles.actions} ${transitionStyles[st]}`}
									>
										{this.renderMenu()}
									</div>
								)}
							</Transition>
						)}
					</>
				)}
			</ShowCardControlsComponent>
		)
	}
}

function ShopcastFolderComp({
	isDraggable,
	isDroppable,
	accept,
	...otherProps
}) {
	const DropTarget = isDroppable
		? withDrop(ShopcastFolder, (props) => ({
				accept: accept || [
					"shopcast-folder",
					"shopcast-card",
					"lookbook-card",
					"shopshare-file-card",
					"product-collection-card",
				],
				drop: (_, monitor) => dropShopcastTarget.drop(props, monitor),
				collect: dropCollect,
		  }))
		: ShopcastFolder
	const Target = isDraggable
		? withDrag(DropTarget, ({ folder }) => ({
				type: "shopcast-folder",
				item: { id: folder.id, type: "folder" },
				collect: dragCollect,
		  }))
		: DropTarget
	return <Target {...otherProps} />
}

export default withRouter(
	createFragmentContainer(ShopcastFolderComp, {
		folder: graphql`
			fragment shopcastFolder_folder on ShopcastFolder {
				id
				name
				parent {
					name
					id
				}
				path {
					value
					name
				}
				avatar
				bannerPhoto
				isArchived
				isPinned
				sharableLink
				description
				isStarred
				inRoot
				count
				itemsCount
				isParentAClient
				clients {
					id
					fullname
					email
					avatar
					isUserAlreadyExist
					folders {
						id
					}
				}
			}
		`,
	})
)