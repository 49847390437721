import React, { Component, Fragment } from "react"
import { FrameIcon, Cross2Icon, CheckIcon } from "@radix-ui/react-icons"
import icon from "shared/styles/components/icons.css"
import ImageIcon from "shared/assets/lb-image-icon.svg"
import PintrestIcon from "shared/assets/lb-pintrest-icon.svg"
import PresIcon from "shared/assets/lb-presentation-icon.svg"
import InstaIcon from "shared/assets/lb-insta-icon.svg"
import Frame2Icon from "shared/assets/lb-frame-icon.svg"
import FacebookIcon from "shared/assets/lb-facebook-icon.svg"
import { createGuid } from "helpers/string-helper"
import LookbookDropdown from "./lookbook-dropdown"
import dropdownStyles from "./lookbook-dropdown.css"
import styles from "./canvas-size-picker.css"

export const CanvasSizeList = [
	{
		id: "shopboard",
		icon: <Frame2Icon className={styles.icon} />,
		title: "Shopboard",
		width: 1080,
		height: 1080,
	},
	{
		id: "channel_banner",
		icon: <Frame2Icon className={styles.icon} />,
		title: "Channel Banner",
		width: 1680,
		height: 352,
	},
	{
		id: "video_thumb",
		icon: <Frame2Icon className={styles.icon} />,
		title: "Video Thumbnail",
		width: 1280,
		height: 720,
	},
	"divider",
	{
		icon: <InstaIcon className={styles.icon} />,
		title: "Instagram story",
		width: 1080,
		height: 1920,
	},
	{
		icon: <FacebookIcon className={styles.icon} />,
		title: "Facebook post",
		width: 1200,
		height: 630,
	},
	{
		icon: <PintrestIcon className={styles.icon} />,
		title: "Pinterest pin",
		width: 1000,
		height: 1500,
	},
	"divider",
	{
		icon: <PresIcon className={styles.icon} />,
		title: "Presentation (16:9)",
		width: 1920,
		height: 1080,
	},
	{
		icon: <PresIcon className={styles.icon} />,
		title: "Presentation (4:3)",
		width: 1024,
		height: 768,
	},
	"divider",
	{
		icon: <ImageIcon className={styles.icon} />,
		title: "Web feed",
		width: 1050,
		height: 1250,
	},
	{
		icon: <ImageIcon className={styles.icon} />,
		title: "App feed",
		width: 576,
		height: 640,
	},
	{
		icon: <ImageIcon className={styles.icon} />,
		title: "Blog",
		width: 960,
		height: 1440,
	},
	{
		icon: <ImageIcon className={styles.icon} />,
		title: "Web photo",
		width: 1280,
		height: 1024,
	},
]

class CanvasSizePicker extends Component {
	constructor(props) {
		super(props)
		this.state = {
			canHeight: 0,
			canWidth: 0,
		}
		this.selected = false
		this.onClickEvent = this.onClickEvent.bind(this)
		this.changeCanvasSize = this.changeCanvasSize.bind(this)
	}

	onClickEvent(key) {
		const { onChange } = this.props
		onChange({
			canvasWidth: CanvasSizeList[key].width,
			canvasHeight: CanvasSizeList[key].height,
		})
		this.setState({ canHeight: 0, canWidth: 0 })
	}

	changeCanvasSize() {
		const { canWidth, canHeight } = this.state
		const { canvasWidth, canvasHeight, onChange } = this.props
		onChange({
			canvasWidth: canWidth > 10 ? canWidth : canvasWidth,
			canvasHeight: canHeight > 10 ? canHeight : canvasHeight,
		})
		this.selected = false
	}

	render() {
		const { canWidth, canHeight } = this.state
		const {
			canvasWidth,
			canvasHeight,
			dataTip,
			label,
			runDebounce,
		} = this.props
		CanvasSizeList.some((x) => {
			if (x.height === canvasHeight && x.width === canvasWidth) {
				this.selected = x
				return true
			}
			this.selected = false
			return false
		})

		const getCanvasLabel = () => {
			if (label) {
				return (
					<>
						{/* eslint-disable-next-line react/no-this-in-sfc */}
						{this.selected ? (
							// eslint-disable-next-line react/no-this-in-sfc
							this.selected.title
						) : (
							<>
								{canvasWidth}
								<div className={styles.join}>
									<Cross2Icon className={icon.icon16} />
								</div>
								{canvasHeight}
							</>
						)}
					</>
				)
			}
			return <FrameIcon className={styles.triggerIcon} />
		}

		return (
			<LookbookDropdown
				label={getCanvasLabel()}
				data-tooltip-content={dataTip}
				data-tooltip-id="tooltip"
				runDebounce={runDebounce}
			>
				<div className={styles.panelCanvasSize}>
					<div className={styles.scroll}>
						{CanvasSizeList.map((x, i) => {
							if (x === "divider") {
								return (
									<div
										className={dropdownStyles.divider}
										key={createGuid(4)}
									/>
								)
							}
							return (
								<div
									key={x.title}
									onClick={() => this.onClickEvent(i)}
									onKeyPress={() => this.onClickEvent(i)}
									tabIndex={-1}
									role="link"
									className={
										this.selected === x
											? styles.itemActive
											: styles.item
									}
								>
									<div className={styles.type}>{x.icon}</div>
									<div className={styles.label}>
										{x.title}{" "}
										<span className={styles.size}>
											{x.width} x {x.height} px
										</span>
									</div>
								</div>
							)
						})}
					</div>
					<div className={styles.range}>
						<input
							id="canWidth"
							type="text"
							className={styles.input}
							defaultValue={
								!this.selected ? canWidth || canvasWidth : ""
							}
							placeholder="Width"
							onChange={({ target }) => {
								this.setState({
									canWidth: parseInt(
										target.value > 0 ? target.value : 0,
										10
									),
								})
							}}
						/>
						<div className={styles.join}>
							<Cross2Icon className={icon.icon16} />
						</div>
						<input
							id="canHeight"
							type="text"
							className={styles.input}
							defaultValue={
								!this.selected ? canHeight || canvasHeight : ""
							}
							placeholder="Height"
							onChange={({ target }) => {
								this.setState({
									canHeight: parseInt(
										target.value > 0 ? target.value : 0,
										10
									),
								})
							}}
						/>
						<button
							className={styles.submit}
							type="button"
							onClick={this.changeCanvasSize}
						>
							<CheckIcon className={icon.icon24} />
						</button>
					</div>
				</div>
			</LookbookDropdown>
		)
	}
}
export default CanvasSizePicker
