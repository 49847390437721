/**
 * @generated SignedSource<<ade18fdec9f18e4401c73bc23951d651>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Mutation } from 'relay-runtime';
export type CampaignCommisionType = "FIXED" | "PERCENTAGE" | "%future added value";
export type CreateCampaignInput = {|
  allUsers?: ?boolean,
  annualCommision?: ?number,
  annualCoupon?: ?string,
  clientMutationId?: ?string,
  cookiePeriod?: ?number,
  description?: ?string,
  monthlyCommision?: ?number,
  monthlyCoupon?: ?string,
  name: string,
  type?: ?CampaignCommisionType,
  users?: ?$ReadOnlyArray<?string>,
|};
export type createCampaignMutation$variables = {|
  input: CreateCampaignInput,
|};
export type createCampaignMutation$data = {|
  +createCampaign: ?{|
    +campaign: ?{|
      +allUsers: ?boolean,
      +annualCommision: ?number,
      +annualCoupon: ?string,
      +conversions: ?number,
      +cookiePeriod: ?number,
      +description: ?string,
      +id: string,
      +isArchived: ?boolean,
      +monthlyCommision: ?number,
      +monthlyCoupon: ?string,
      +name: ?string,
      +totalRevenue: ?number,
      +type: ?CampaignCommisionType,
      +users: ?$ReadOnlyArray<?{|
        +id: string,
        +username: ?string,
      |}>,
    |},
  |},
|};
export type createCampaignMutation = {|
  response: createCampaignMutation$data,
  variables: createCampaignMutation$variables,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "CreateCampaignPayload",
    "kind": "LinkedField",
    "name": "createCampaign",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Campaign",
        "kind": "LinkedField",
        "name": "campaign",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "description",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "monthlyCommision",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "annualCommision",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "type",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "cookiePeriod",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "monthlyCoupon",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "annualCoupon",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "conversions",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isArchived",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "users",
            "plural": true,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "username",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "totalRevenue",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "allUsers",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "createCampaignMutation",
    "selections": (v2/*: any*/),
    "type": "ShopshareMutationRoot",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "createCampaignMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "64cc669d67a660334dde6597344213b3",
    "id": null,
    "metadata": {},
    "name": "createCampaignMutation",
    "operationKind": "mutation",
    "text": "mutation createCampaignMutation(\n  $input: CreateCampaignInput!\n) {\n  createCampaign(input: $input) {\n    campaign {\n      id\n      name\n      description\n      monthlyCommision\n      annualCommision\n      type\n      cookiePeriod\n      monthlyCoupon\n      annualCoupon\n      conversions\n      isArchived\n      users {\n        id\n        username\n      }\n      totalRevenue\n      allUsers\n    }\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "bdd7dded8e726bdaa8c7aab28348be54";

module.exports = ((node/*: any*/)/*: Mutation<
  createCampaignMutation$variables,
  createCampaignMutation$data,
>*/);
