/**
 * @generated SignedSource<<eb56bd88a689e3b544f34967309401f3>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Query } from 'relay-runtime';
import type { itemReferences_store$fragmentType } from "./itemReferences_store.graphql";
export type itemReferencesQuery$variables = {|
  folderId?: ?string,
  id?: ?string,
|};
export type itemReferencesQuery$data = {|
  +store: ?{|
    +$fragmentSpreads: itemReferences_store$fragmentType,
  |},
|};
export type itemReferencesQuery = {|
  response: itemReferencesQuery$data,
  variables: itemReferencesQuery$variables,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "folderId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "id"
},
v2 = {
  "kind": "Variable",
  "name": "folderId",
  "variableName": "folderId"
},
v3 = {
  "kind": "Variable",
  "name": "id",
  "variableName": "id"
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "imageUrl",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "url",
  "storageKey": null
},
v8 = [
  (v2/*: any*/)
],
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "views",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "fullname",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "username",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "profilePhoto",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "canEdit",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "customDomain",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isPublished",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isPinned",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isRecommended",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": (v8/*: any*/),
  "concreteType": "Lookbook",
  "kind": "LinkedField",
  "name": "lookbooks",
  "plural": true,
  "selections": [
    (v4/*: any*/),
    (v5/*: any*/),
    (v9/*: any*/),
    (v7/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "user",
      "plural": false,
      "selections": [
        (v4/*: any*/),
        (v10/*: any*/),
        (v11/*: any*/),
        (v12/*: any*/),
        (v13/*: any*/),
        (v14/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "LookbookBoard",
      "kind": "LinkedField",
      "name": "lookbookBoards",
      "plural": true,
      "selections": [
        (v4/*: any*/),
        (v7/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "videoUrl",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    (v15/*: any*/),
    (v16/*: any*/),
    (v17/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "published_at",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "shownBoardCount",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "itemReferencesQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Store",
        "kind": "LinkedField",
        "name": "store",
        "plural": false,
        "selections": [
          {
            "args": [
              (v2/*: any*/),
              (v3/*: any*/)
            ],
            "kind": "FragmentSpread",
            "name": "itemReferences_store"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "ShopshareQueryRoot",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "itemReferencesQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Store",
        "kind": "LinkedField",
        "name": "store",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "currentUser",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "adminAccess",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": [
              (v3/*: any*/)
            ],
            "concreteType": null,
            "kind": "LinkedField",
            "name": "collectionItem",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "__typename",
                "storageKey": null
              },
              {
                "kind": "InlineFragment",
                "selections": [
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "regularPrice",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "offerPrice",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "affiliated_url",
                    "storageKey": null
                  },
                  (v7/*: any*/),
                  (v18/*: any*/),
                  {
                    "alias": null,
                    "args": (v8/*: any*/),
                    "concreteType": "shopcastItem",
                    "kind": "LinkedField",
                    "name": "shopcastItems",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Shopcast",
                        "kind": "LinkedField",
                        "name": "shopcast",
                        "plural": false,
                        "selections": [
                          (v4/*: any*/),
                          (v5/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "posterUrl",
                            "storageKey": null
                          },
                          (v15/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "publishedDate",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "transcoded",
                            "storageKey": null
                          },
                          (v9/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "likes",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "duration",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "isFlagged",
                            "storageKey": null
                          },
                          (v16/*: any*/),
                          (v17/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "youtubeUploadStatus",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Category",
                            "kind": "LinkedField",
                            "name": "category",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "name",
                                "storageKey": null
                              },
                              (v4/*: any*/)
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": [
                              {
                                "kind": "Literal",
                                "name": "first",
                                "value": 100
                              }
                            ],
                            "concreteType": "ShopcastItemConnection",
                            "kind": "LinkedField",
                            "name": "shopcastItems",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "ShopcastItemEdge",
                                "kind": "LinkedField",
                                "name": "edges",
                                "plural": true,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "shopcastItem",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                      (v4/*: any*/),
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "start",
                                        "storageKey": null
                                      },
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "end",
                                        "storageKey": null
                                      },
                                      {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "ShopcastProduct",
                                        "kind": "LinkedField",
                                        "name": "product",
                                        "plural": false,
                                        "selections": [
                                          (v4/*: any*/),
                                          (v6/*: any*/)
                                        ],
                                        "storageKey": null
                                      }
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": "shopcastItems(first:100)"
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "User",
                            "kind": "LinkedField",
                            "name": "user",
                            "plural": false,
                            "selections": [
                              (v4/*: any*/),
                              (v10/*: any*/),
                              (v11/*: any*/),
                              (v12/*: any*/),
                              (v14/*: any*/),
                              (v13/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      (v4/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "type": "ShopcastProduct",
                "abstractKey": null
              },
              {
                "kind": "InlineFragment",
                "selections": [
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v7/*: any*/),
                  (v18/*: any*/)
                ],
                "type": "lookbookUploads",
                "abstractKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "6c9894aa7893025483029f0633f274ac",
    "id": null,
    "metadata": {},
    "name": "itemReferencesQuery",
    "operationKind": "query",
    "text": "query itemReferencesQuery(\n  $id: String\n  $folderId: String\n) {\n  store {\n    ...itemReferences_store_3BYP2P\n    id\n  }\n}\n\nfragment itemReferences_store_3BYP2P on Store {\n  id\n  currentUser {\n    ...shopcastCard_currentUser\n    ...lookbookCard_currentUser\n    id\n  }\n  collectionItem(id: $id) {\n    __typename\n    ... on ShopcastProduct {\n      id\n      title\n      imageUrl\n      regularPrice\n      offerPrice\n      affiliated_url\n      url\n      lookbooks(folderId: $folderId) {\n        ...lookbookCard_lookbook\n        id\n      }\n      shopcastItems(folderId: $folderId) {\n        shopcast {\n          ...shopcastCard_shopcast\n          id\n        }\n        id\n      }\n    }\n    ... on lookbookUploads {\n      id\n      title\n      url\n      lookbooks(folderId: $folderId) {\n        ...lookbookCard_lookbook\n        id\n      }\n    }\n  }\n}\n\nfragment lookbookCard_currentUser on User {\n  id\n  adminAccess\n}\n\nfragment lookbookCard_lookbook on Lookbook {\n  id\n  title\n  views\n  url\n  user {\n    id\n    fullname\n    username\n    profilePhoto\n    canEdit\n    customDomain\n  }\n  lookbookBoards {\n    id\n    url\n    videoUrl\n  }\n  isPublished\n  isPinned\n  isRecommended\n  published_at\n  shownBoardCount\n}\n\nfragment shopcastCard_currentUser on User {\n  id\n  adminAccess\n}\n\nfragment shopcastCard_shopcast on Shopcast {\n  id\n  title\n  posterUrl\n  isPublished\n  publishedDate\n  transcoded\n  views\n  likes\n  duration\n  isFlagged\n  isPinned\n  isRecommended\n  youtubeUploadStatus\n  category {\n    name\n    id\n  }\n  shopcastItems(first: 100) {\n    edges {\n      node {\n        id\n        start\n        end\n        product {\n          id\n          imageUrl\n        }\n      }\n    }\n  }\n  user {\n    id\n    fullname\n    username\n    profilePhoto\n    customDomain\n    canEdit\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "ea900e177bbab6148c7d39434a259b59";

module.exports = ((node/*: any*/)/*: Query<
  itemReferencesQuery$variables,
  itemReferencesQuery$data,
>*/);
