/**
 * @generated SignedSource<<1cef15cd3f107a8c6c41cf0cef0c2408>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentType } from "relay-runtime";
declare export opaque type moodboards_store$fragmentType: FragmentType;
export type moodboards_store$data = {|
  +currentUser: ?{|
    +id: string,
  |},
  +id: string,
  +$fragmentType: moodboards_store$fragmentType,
|};
export type moodboards_store$key = {
  +$data?: moodboards_store$data,
  +$fragmentSpreads: moodboards_store$fragmentType,
  ...
};
*/

var node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "moodboards_store",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "currentUser",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "type": "Store",
  "abstractKey": null
};
})();

(node/*: any*/).hash = "daf8f4ccf4c03d22323801a91135fffe";

module.exports = ((node/*: any*/)/*: Fragment<
  moodboards_store$fragmentType,
  moodboards_store$data,
>*/);
