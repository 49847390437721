import { commitMutation, graphql } from "react-relay"
import { complete } from "../helper.js"

const mutation = graphql`
	mutation incrementLookbookViewCountMutation($input: IncrementLoobookViewCountInput!) {
		incrementLoobookViewCount(input: $input) {
		  store {
			id
		  }
		}
	  }	  
`

const getConfigs = (storeId) => [
	{
		type: "FIELDS_CHANGE",
		fieldIDs: { store: storeId },
	},
]

const commit = (env, lookbookId, storeId, onCompleted, onError) =>
	commitMutation(env, {
		mutation,
		variables: { input: { lookbookId } },
		configs: getConfigs(storeId),
		onCompleted: complete(onCompleted, onError),
		onError: (e) => complete(null, [e]),
	})

export default { commit }
