import React, { useState, useCallback, Fragment } from "react"
import Modal from "react-modal"
import modal from "shared/styles/components/modal.css"
import button from "shared/styles/components/buttons.css"
import { Cross2Icon } from "@radix-ui/react-icons"
import { toast } from "react-toastify"
import SquareIcon from "shared/assets/size-square-icon.svg"
import PortraitIcon from "shared/assets/size-portrait-icon.svg"
// import LandscapeIcon from "shared/assets/size-landscape-icon.svg"
// import CameraRoundedIcon from "shared/assets/camera-rounded-icon.svg"
// import CameraCircleIcon from "shared/assets/camera-circle-icon.svg"
// import CameraShopshareIcon from "shared/assets/camera-shopshare-icon.svg"
// import CameraPortraitIcon from "shared/assets/camera-portrait-icon.svg"

import SquareFrameBottomRightCircle from "shared/assets/video-layouts/square/framed-bottom-right-circle.png"
import SquareFrameTopRightCircle from "shared/assets/video-layouts/square/framed-top-right-circle.png"
// import SquareFrameBottomRightSquare from "shared/assets/video-layouts/square/framed-bottom-right-square.png"
// import SquareFrameTopRightSquare from "shared/assets/video-layouts/square/framed-top-right-square.png"
// import SquareFrameBottomRightPortrait from "shared/assets/video-layouts/square/framed-bottom-right-portrait.png"
// import SquareFrameTopRightPortrait from "shared/assets/video-layouts/square/framed-top-right-portrait.png"
import SquareFullscreenBottomRightCircle from "shared/assets/video-layouts/square/fullscreen-bottom-right-circle.png"
import SquareFullscreenTopRightCircle from "shared/assets/video-layouts/square/fullscreen-top-right-circle.png"

import PortraitFrameBottomCircle from "shared/assets/video-layouts/portrait/framed-bottom-circle.png"
import PortraitFrameTopCircle from "shared/assets/video-layouts/portrait/framed-top-circle.png"
import PortraitFullscreenBottomCircle from "shared/assets/video-layouts/portrait/fullscreen-bottom-circle.png"
import PortraitFullscreenTopCircle from "shared/assets/video-layouts/portrait/fullscreen-top-circle.png"

import form from "shared/styles/components/form.css"
// import SquarePage from "shared/assets/layout-square-page.svg"
import _ from "lodash"
import downloadLookbookMutation from "../../../mutations/lookbook/download-lookbook"
import ColorPicker from "../../lookbook-dropdown/color-picker"
import PageSelector from "../page-selector/page-selector"
import PageSelectorStyles from "../page-selector/page-selector.css"
import styles from "./download-lookbook.css"

export default function DownloadLookbook({
	lookbook,
	relay,
	closeModal,
	defaultSelectedBoard,
}) {
	const LAYOUTS = {
		squared: [
			{
				id: "SquareFrameBottomRightCircle",
				src: SquareFrameBottomRightCircle,
				position: "bottom-right",
				circular: true,
			},
			{
				id: "SquareFrameTopRightCircle",
				src: SquareFrameTopRightCircle,
				position: "top-right",
				circular: true,
			},
			// {
			// 	id: "SquareFrameBottomRightSquare",
			// 	src: SquareFrameBottomRightSquare,
			// 	position: "bottom-right",
			// 	circular: false,
			// },
			// {
			// 	id: "SquareFrameTopRightSquare",
			// 	src: SquareFrameTopRightSquare,
			// 	position: "top-right",
			// 	circular: false,
			// },
			// {
			// 	id: "SquareFrameBottomRightPortrait",
			// 	src: SquareFrameBottomRightPortrait,
			// 	position: "bottom-right",
			// 	circular: false,
			// 	cropX: 350,
			// 	cropY: 450,
			// },
			// {
			// 	id: "SquareFrameTopRightPortrait",
			// 	src: SquareFrameTopRightPortrait,
			// 	position: "top-right",
			// 	circular: false,
			// 	cropX: 350,
			// 	cropY: 450,
			// },
			{
				id: "SquareFullscreenBottomRightCircle",
				src: SquareFullscreenBottomRightCircle,
				position: "bottom-right",
				circular: true,
				imageX: 1080,
				imageY: 1080,
			},
			{
				id: "SquareFullscreenTopRightCircle",
				src: SquareFullscreenTopRightCircle,
				position: "top-right",
				circular: true,
				imageX: 1080,
				imageY: 1080,
			},
		],
		portrait: [
			{
				id: "PotraitFrameBottomMidCircle",
				src: PortraitFrameBottomCircle,
				position: "bottom-left",
				circular: true,
				padVideoX: "(W-w)/2",
				padVideoY: "145",
				imageX: 900,
				imageY: 900,
				cropX: 600,
				cropY: 600,
				padImageY: "130",
			},
			{
				id: "PotraitFrameTopMidCircle",
				src: PortraitFrameTopCircle,
				position: "top-left",
				circular: true,
				padVideoX: "(W-w)/2",
				padVideoY: "145",
				imageX: 900,
				imageY: 900,
				cropX: 600,
				cropY: 600,
				padImageY: "890",
			},
			{
				id: "PortraitFullscreenBottomCircle",
				src: PortraitFullscreenBottomCircle,
				position: "bottom-left",
				circular: true,
				padVideoX: "(W-w)/2",
				padVideoY: "145",
				imageX: 1080,
				imageY: 1080,
				cropX: 600,
				cropY: 600,
				padImageY: "145",
			},
			{
				id: "PortraitFullscreenTopCircle",
				src: PortraitFullscreenTopCircle,
				position: "top-left",
				circular: true,
				padVideoX: "(W-w)/2",
				padVideoY: "145",
				imageX: 1080,
				imageY: 1080,
				cropX: 600,
				cropY: 600,
				padImageY: "695",
			},
		],
		landscape: [],
	}

	const getInitialSelectedBoard = useCallback(() => {
		if (defaultSelectedBoard && defaultSelectedBoard.length > 0)
			return defaultSelectedBoard
		const videoBoards = lookbook.lookbookBoards.filter(
			(board) => !!board.videoUrl
		)
		return videoBoards.length === 1 ? [videoBoards[0].id] : []
	}, [lookbook, defaultSelectedBoard])

	const [selectedBoards, setSelectedBoards] = useState(
		getInitialSelectedBoard()
	)
	const [type, setType] = useState("squared")
	const [bgColor, setBgColor] = useState("#000000")
	const [selectedLayout, setSelectedLayout] = useState(LAYOUTS.squared[0])

	const downloadVideos = useCallback(() => {
		if (selectedBoards.length === 0) {
			toast.error(<>You must select at least one page</>, {
				autoClose: 2000,
				closeButton: false,
			})
			return
		}
		toast.success(<>An email will be sent once processed</>, {
			autoClose: 2000,
			closeButton: false,
		})
		const data = _.omit(selectedLayout, ["id", "src", "position"])
		downloadLookbookMutation.commit(
			relay.environment,
			{
				lookbookId: lookbook.id,
				boards: selectedBoards,
				type,
				bgColor,
				layout: selectedLayout.position,
				...data,
			},
			() => {
				toast.error(<>Could not download Shopboard</>, {
					autoClose: 2000,
					closeButton: false,
				})
			},
			() => {}
		)
		closeModal()
	}, [lookbook, selectedBoards, type, selectedLayout, bgColor])

	const setupSize = useCallback(
		(value) => {
			setType(value)
			if (!LAYOUTS[value].find((i) => i.id === selectedLayout.id))
				setSelectedLayout(LAYOUTS[value][0])
		},
		[setType, setSelectedLayout, selectedLayout]
	)

	const lookbookVideoBoardTemplate = (children) => (
		<Modal
			className={modal.root}
			overlayClassName={modal.overlay}
			isOpen
			ariaHideApp={false}
		>
			<div
				className={`${modal.dialog} ${modal.dialogCentered} ${modal.dialogFullscreenSmDown} ${modal.dialogLg}`}
			>
				<div className={`${modal.content}`}>
					<div className={modal.header}>
						<h4 className={modal.title}>Download Video</h4>
						<button
							type="button"
							className={button.close}
							onClick={() => {
								closeModal()
							}}
						>
							<Cross2Icon className={button.closeIcon} />
						</button>
					</div>
					{children}
				</div>
			</div>
		</Modal>
	)

	const layoutClassesBySize = useCallback(() => {
		switch (type) {
			case "squared":
				return PageSelectorStyles.pageSquare
			case "portrait":
				return PageSelectorStyles.pagePortrait
			case "landscape":
				return PageSelectorStyles.pageLandscape
			default:
				return PageSelectorStyles.pageSquare
		}
	}, [type])

	if (lookbook.lookbookBoards.filter((board) => !!board.videoUrl).length === 0)
		return (
			<Modal
				className={modal.root}
				overlayClassName={modal.overlay}
				isOpen
				ariaHideApp={false}
			>
				<div className={`${modal.dialog} ${modal.dialogCentered}`}>
					<div className={`${modal.content}`}>
						<div className={styles.body}>
							<h4 className={styles.title}>
								No videos found on this board
							</h4>
							{/* <p className={styles.description}>To add a video simply</p> */}
						</div>
						<div className={styles.footer}>
							<button
								type="button"
								className={button.primary}
								onClick={() => {
									closeModal()
								}}
							>
								OK
							</button>
						</div>
					</div>
				</div>
			</Modal>
		)

	return lookbookVideoBoardTemplate(
		<>
			<div className={modal.body}>
				<div className={form.group}>
					<label className={form.labelBold}>Pages</label>
					<PageSelector
						boards={lookbook.lookbookBoards.filter(
							(board) => !!board.videoUrl
						)}
						value={selectedBoards}
						onChange={(id, event) => {
							setSelectedBoards(
								event.target.checked
									? [...selectedBoards, id]
									: selectedBoards.filter((i) => i !== id)
							)
						}}
						multiple
					/>
				</div>
				<div className={form.row}>
					<div className={form.group}>
						<label className={form.labelBold}>Size</label>
						<div className={styles.buttonGroup}>
							<label
								htmlFor="rSquare"
								className={
									type === "squared"
										? styles.iconButtonActive
										: styles.iconButton
								}
								data-tooltip-id="tooltip"
								data-tooltip-content="Square (1080 x 1080)"
							>
								<input
									id="rSquare"
									type="radio"
									value="squared"
									disabled={LAYOUTS.squared.length === 0}
									checked={type === "squared"}
									onChange={(e) => setupSize(e.target.value)}
								/>
								<SquareIcon className={styles.icon} />
							</label>
							<label
								htmlFor="rPortrait"
								className={
									type === "portrait"
										? styles.iconButtonActive
										: styles.iconButton
								}
								data-tooltip-id="tooltip"
								data-tooltip-content="Portrait (1080 x 1350)"
							>
								<input
									id="rPortrait"
									type="radio"
									value="portrait"
									disabled={LAYOUTS.portrait.length === 0}
									checked={type === "portrait"}
									onChange={(e) => setupSize(e.target.value)}
								/>
								<PortraitIcon className={styles.icon} />
							</label>
							{/* <label
										htmlFor="rLandscape"
										className={
											type === "landscape"
												? styles.iconButtonActive
												: styles.iconButton
										}
										data-tooltip-id="typeTooltip"
										data-tooltip-content="Landscape (1080 x 608)"
									>
										<input
											id="rLandscape"
											type="radio"
											value="landscape"
											disabled={LAYOUTS.landscape.length === 0}
											checked={type === "landscape"}
											onChange={(e) => setupSize(e.target.value)}
										/>
										<LandscapeIcon className={styles.icon} />
									</label> */}
						</div>
					</div>
					<div className={form.group}>
						<label className={form.labelBold}>Background</label>
						<div className={styles.background}>
							<ColorPicker
								disableAlpha
								color={bgColor}
								onChange={(colorValue) => {
									setBgColor(colorValue)
								}}
								onChangeComplete={() => {}}
							/>
							{/* <div className={styles.colour}>
										<CheckIcon className={styles.colourIcon} />
									</div>
									<div className={styles.swatches}>
										<div className={styles.colour}>
											<CheckIcon className={styles.colourIcon} />
										</div>
										<div className={styles.colourActive}>
											<CheckIcon className={styles.colourIcon} />
										</div>
										<div className={styles.colour}>
											<CheckIcon className={styles.colourIcon} />
										</div>
										<div className={styles.colour}>
											<CheckIcon className={styles.colourIcon} />
										</div>
									</div> */}
						</div>
					</div>
				</div>
				<div className={form.group}>
					<label className={form.labelBold}>Layout</label>
					<PageSelector
						mainClass={layoutClassesBySize()}
						boards={LAYOUTS[type].map((layout) => ({
							id: layout.id,
							url: layout.src,
						}))}
						value={selectedLayout.id}
						onChange={(id) => {
							const layout = LAYOUTS[type].find((l) => l.id === id)
							setSelectedLayout(layout)
						}}
					/>
				</div>
			</div>
			<div className={`${modal.footer}`}>
				<button
					type="button"
					className={button.link}
					onClick={() => {
						closeModal()
					}}
				>
					Cancel
				</button>
				<button
					type="submit"
					className={button.primary}
					onClick={() => {
						downloadVideos()
					}}
				>
					Create Video
				</button>
			</div>
		</>
	)
}
