/**
 * @generated SignedSource<<3f9b2d86ab9f139618affe984f143795>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentType } from "relay-runtime";
declare export opaque type collectionSelect_store$fragmentType: FragmentType;
export type collectionSelect_store$data = {|
  +currentUser: ?{|
    +id: string,
    +productCollectionSearchItems: ?{|
      +collections: ?{|
        +edges: ?$ReadOnlyArray<?{|
          +node: ?{|
            +client: ?{|
              +id: string,
            |},
            +folder: ?{|
              +id: string,
              +name: ?string,
            |},
            +id: string,
            +isACloset: ?boolean,
            +isDefault: ?boolean,
            +isPublished: ?boolean,
            +title: ?string,
            +user: ?{|
              +fullname: ?string,
              +id: string,
            |},
          |},
        |}>,
      |},
      +defaultCollection: ?{|
        +client: ?{|
          +id: string,
        |},
        +folder: ?{|
          +id: string,
          +name: ?string,
        |},
        +id: string,
        +isACloset: ?boolean,
        +isDefault: ?boolean,
        +isPublished: ?boolean,
        +title: ?string,
        +user: ?{|
          +fullname: ?string,
          +id: string,
        |},
      |},
    |},
  |},
  +$fragmentType: collectionSelect_store$fragmentType,
|};
export type collectionSelect_store$key = {
  +$data?: collectionSelect_store$data,
  +$fragmentSpreads: collectionSelect_store$fragmentType,
  ...
};
*/

var node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "kind": "Variable",
  "name": "teamId",
  "variableName": "teamId"
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isPublished",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "ShopcastFolder",
  "kind": "LinkedField",
  "name": "folder",
  "plural": false,
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isACloset",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isDefault",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "concreteType": "User",
  "kind": "LinkedField",
  "name": "user",
  "plural": false,
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "fullname",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "concreteType": "Client",
  "kind": "LinkedField",
  "name": "client",
  "plural": false,
  "selections": [
    (v0/*: any*/)
  ],
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "brands"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "categories"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "clients"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "collectionSearch"
    },
    {
      "defaultValue": 12,
      "kind": "LocalArgument",
      "name": "count"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "cursor"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "folderId"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "limitType"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "search"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "teamId"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "count",
        "cursor": "cursor",
        "direction": "forward",
        "path": [
          "currentUser",
          "productCollectionSearchItems",
          "collections"
        ]
      }
    ]
  },
  "name": "collectionSelect_store",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "currentUser",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": [
            {
              "kind": "Variable",
              "name": "brands",
              "variableName": "brands"
            },
            {
              "kind": "Variable",
              "name": "categories",
              "variableName": "categories"
            },
            {
              "kind": "Variable",
              "name": "clientIds",
              "variableName": "clients"
            },
            {
              "kind": "Literal",
              "name": "collections",
              "value": []
            },
            {
              "kind": "Variable",
              "name": "folderId",
              "variableName": "folderId"
            },
            {
              "kind": "Variable",
              "name": "limitType",
              "variableName": "limitType"
            },
            {
              "kind": "Literal",
              "name": "resultTypes",
              "value": [
                "collections"
              ]
            },
            {
              "kind": "Variable",
              "name": "search",
              "variableName": "search"
            },
            (v1/*: any*/)
          ],
          "concreteType": "ProductCollectionProductSearch",
          "kind": "LinkedField",
          "name": "productCollectionSearchItems",
          "plural": false,
          "selections": [
            {
              "alias": "collections",
              "args": [
                {
                  "kind": "Variable",
                  "name": "search",
                  "variableName": "collectionSearch"
                }
              ],
              "concreteType": "ProductCollectionConnection",
              "kind": "LinkedField",
              "name": "__currentUser_productCollectionSearchItems_collections_connection",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "ProductCollectionEdge",
                  "kind": "LinkedField",
                  "name": "edges",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "ProductCollection",
                      "kind": "LinkedField",
                      "name": "node",
                      "plural": false,
                      "selections": [
                        (v0/*: any*/),
                        (v2/*: any*/),
                        (v3/*: any*/),
                        (v4/*: any*/),
                        (v5/*: any*/),
                        (v6/*: any*/),
                        (v7/*: any*/),
                        (v8/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "__typename",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "cursor",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "PageInfo",
                  "kind": "LinkedField",
                  "name": "pageInfo",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "endCursor",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "hasNextPage",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": [
                (v1/*: any*/)
              ],
              "concreteType": "ProductCollection",
              "kind": "LinkedField",
              "name": "defaultCollection",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                (v2/*: any*/),
                (v3/*: any*/),
                (v4/*: any*/),
                (v5/*: any*/),
                (v6/*: any*/),
                (v7/*: any*/),
                (v8/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Store",
  "abstractKey": null
};
})();

(node/*: any*/).hash = "6f4deda09967c4544731902c9688202b";

module.exports = ((node/*: any*/)/*: Fragment<
  collectionSelect_store$fragmentType,
  collectionSelect_store$data,
>*/);
