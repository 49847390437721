/* eslint-disable no-nested-ternary */
import React from "react"
import { QueryRenderer } from "react-relay"
import {
	useLocation,
	useNavigate,
	useParams,
	Navigate,
	useOutletContext,
} from "react-router-dom"
import { jwtDecode } from "jwt-decode";
import lodash from "lodash"
import { FeatureAvailableComponent } from "services/plan-services"
import environment from "./createRelayEnvironment"
import dayjs from "./helpers/dayjs-helper"
import { GlobalContextConsumer } from "./global-context"

const routerContext = React.createContext({})
const compCache = {
	// key: { Component, params, props }
}

export function getCookie(name) {
	const match = document.cookie.match(new RegExp(`(^| )${name}=([^;]+)`))
	if (match) return match[2]
	return null
}

export const withRouter = (Component) => {
	function WithRouter(props) {
		const location = useLocation()
		const navigate = useNavigate()
		const params = useParams()
		return (
			<Component
				{...props}
				location={location}
				navigate={navigate}
				params={params}
			/>
		)
	}
	return WithRouter
}

export const withOutletContext = (Component) => {
	function WithOutletContext(props) {
		const outletContext = useOutletContext()
		return <Component {...props} {...outletContext} />
	}
	return WithOutletContext
}

export function justRender(Component, ...params) {
	let variablesToQuery = () => {}
	if (Component.params) {
		if (typeof Component.params === "function") {
			variablesToQuery = (rp) => Component.params(rp)
		} else {
			variablesToQuery = () => Component.params
		}
	}

	const Comp = params.length
		? Component.Component(...params)
		: Component.Component
	let optionalPropsForTheComponent = {} // includes null props
	if (Component.nullProps && Component.nullProps.length) {
		Component.nullProps.forEach((nullPropName) => {
			optionalPropsForTheComponent[nullPropName] = null
		})
	}
	/*
		Static props

		Components can have props which are directly injected to the Component
		and will not be added to the query parameters
	*/
	if (Component.props) {
		optionalPropsForTheComponent = {
			...optionalPropsForTheComponent,
			...Component.props,
		}
	}
	return function WithRouterProps(routerProps) {
		const location = useLocation()
		const paramsObj = useParams()
		if (Component.noData) {
			return <Comp {...routerProps} />
		}
		const getUserData = () => {
			const token = getCookie("token")
			const tokenData = token ? jwtDecode(token) : null
			return tokenData
		}
		const checkTokenValidity = (tokenData) => {
			try {
				const valid =
					tokenData?.exp && dayjs.unix(tokenData.exp).isAfter(dayjs())
				return valid
			} catch (error) {
				return false
			}
		}
		const userData = getUserData()
		const isTokenValid = checkTokenValidity(userData)

		if (Component.requiredNoAuth && isTokenValid) {
			return <Navigate to="/dashboard" />
		}
		if (Component.needAuth && !isTokenValid) {
			return <Navigate to={`/signin?link=${window.location.href}`} />
		}
		if (!Component.query) {
			return (
				<GlobalContextConsumer>
					{(context) => (
						<Comp {...routerProps} {...optionalPropsForTheComponent} {...context} />
					)}
				</GlobalContextConsumer>
			)
		}
		const QueryRendererComponent = (
			<GlobalContextConsumer>
				{(context) => (
					<QueryRenderer
						relay={{ environment: {}, variables: [] }}
						query={Component.query}
						variables={variablesToQuery({
							...routerProps,
							...context,
							location,
							params: paramsObj,
						})}
						environment={Component.environment || environment}
						render={({ props }) =>
							props ? (
								<Comp
									{...routerProps}
									{...props}
									{...optionalPropsForTheComponent}
									{...context}
									loading={false}
								/>
							) : Component.showLoading ? (
								<Comp
									{...routerProps}
									store={null}
									{...props}
									loading
									{...optionalPropsForTheComponent}
									{...context}
								/>
							) : null
						}
					/>
				)}
			</GlobalContextConsumer>
		)
		return Component.featureKey ? (
			<FeatureAvailableComponent
				featureKey={Component.featureKey}
				env={Component.environment || environment}
			>
				{QueryRendererComponent}
			</FeatureAvailableComponent>
		) : (
			QueryRendererComponent
		)
	}
}

export function getComponent(key, Component, params, props, noInstace = false) {
	const paramsObj = typeof params === "function" ? params({}) : params
	if (
		compCache[key] &&
		lodash.isEqual(compCache[key].params, paramsObj) &&
		lodash.isEqual(compCache[key].props, props)
	) {
		return compCache[key].Component
	}
	const Comp = withRouter(
		justRender({
			...Component,
			params: params || Component.params,
			props: props || Component.props,
		})
	)

	compCache[key] = {
		Component: noInstace ? Comp : <Comp />,
		params: paramsObj,
		props,
	}
	return compCache[key].Component
}

export const RouterProvider = routerContext.Provider
export const RouterConsumer = routerContext.Consumer
