import React from "react"
import Modal from "react-modal"
import LogoIcon from "shared/assets/logo-icon.svg"
import AddToHomeScreenIcon from "shared/assets/add-to-home-screen-icon.svg"
import modal from "shared/styles/components/modal.css"
import buttons from "shared/styles/components/buttons.css"
import { Cross2Icon, Share2Icon, ChevronDownIcon } from "@radix-ui/react-icons"
import styles from "./pwa-install-prompt.css"

export default function PwaInstallPrompt({ closeModal }) {
	return (
		<Modal
			className={modal.root}
			overlayClassName={modal.overlay}
			isOpen
			ariaHideApp={false}
		>
			<div className={`${modal.dialog} ${modal.dialogFooter}`}>
				<div className={styles.content}>
					<div className={modal.header}>
						<div className={styles.titleWithIcon}>
							<div className={styles.appIcon}>
								<LogoIcon className={styles.logo} />
							</div>
							<h4 className={styles.title}>Install ShopShare</h4>
						</div>
						<button
							type="button"
							className={buttons.close}
							onClick={() => {
								closeModal()
							}}
						>
							<Cross2Icon className={buttons.closeIcon} />
						</button>
					</div>
					<div className={styles.body}>
						<ul className={styles.instructions}>
							<li className={styles.item}>
								<Share2Icon className={styles.icon} />
								Tap the Share Icon
							</li>
							<li className={styles.item}>
								<AddToHomeScreenIcon className={styles.icon} />
								Select &quot;Add to Home Screen&quot;
							</li>
						</ul>
					</div>
					<div className={styles.footer}>
						<ChevronDownIcon className={styles.pointer} />
					</div>
				</div>
			</div>
		</Modal>
	)
}
