import React from "react"
import { Navigate } from "react-router-dom"
import { createFragmentContainer, graphql } from "react-relay"
import SharedFolderCardComponent from "components/cards/shared-folder-card/shared-folder-card"
import CollectionIcon from "shared/assets/lb-collections-icon.svg"
import landing from "shared/styles/layout/landing.css"
import empty from "shared/styles/components/empty.css"
import styles from "./wardrobe.css"

function Wardrobe({ store }) {
	const { wardrobe } = store.currentUser

	if (wardrobe.length === 1 && wardrobe[0].folders.length === 1)
		return <Navigate to={`/stylist/${wardrobe[0].folders[0].id}`} />

	return (
		<div className={styles.root}>
			<section className={landing.section}>
				<div className={landing.content}>
					<h1 className={landing.displayTitle}>Stylists</h1>
					{/*	<p className={landing.displayLead}>
						Get personalised advice and discover new and unique brands.
					</p> */}
				</div>
			</section>
			<section className={styles.content}>
				{wardrobe.length > 0 ? (
					<div className={styles.container}>
						<div className={styles.row}>
							{wardrobe.map((section) => (
								<div className={styles.col} key={section.user.id}>
									<SharedFolderCardComponent section={section} />
								</div>
							))}
						</div>
					</div>
				) : (
					<div className={empty.root}>
						<div className={empty.container}>
							<div className={empty.content}>
								<CollectionIcon className={empty.icon} />
								<h4 className={empty.headline}>
									There&apos;s nothing here
								</h4>
								<p className={empty.subline}>
									Stylists haven&apos;t shared anything with you yet.
								</p>
							</div>
						</div>
					</div>
				)}
			</section>
		</div>
	)
}

const WardrobeComponent = createFragmentContainer(Wardrobe, {
	store: graphql`
		fragment wardrobe_store on Store {
			id
			currentUser {
				id
				productCollections {
					edges {
						node {
							id
							title
						}
					}
				}
				wardrobe {
					user {
						id
						fullname
						username
						profilePhoto
						bannerPhoto
					}
					folders {
						id
						...sharedFolderCard_shopcastFolder
					}
				}
			}
		}
	`,
})

const query = graphql`
	query wardrobeQuery {
		store {
			...wardrobe_store
		}
	}
`

export default {
	Component: WardrobeComponent,
	needAuth: true,
	query,
}
