/**
 * @generated SignedSource<<9c12e5a5be1e547df49e985820279a73>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Mutation } from 'relay-runtime';
export type SaveShopcastReportAdminNotesInput = {|
  clientMutationId?: ?string,
  note?: ?string,
  reportId?: ?string,
|};
export type saveShopcastReportAdminNotesMutation$variables = {|
  input: SaveShopcastReportAdminNotesInput,
|};
export type saveShopcastReportAdminNotesMutation$data = {|
  +saveShopcastReportAdminNotes: ?{|
    +report: ?{|
      +id: string,
    |},
  |},
|};
export type saveShopcastReportAdminNotesMutation = {|
  response: saveShopcastReportAdminNotesMutation$data,
  variables: saveShopcastReportAdminNotesMutation$variables,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "SaveShopcastReportAdminNotesPayload",
    "kind": "LinkedField",
    "name": "saveShopcastReportAdminNotes",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ShopcastUserReport",
        "kind": "LinkedField",
        "name": "report",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "saveShopcastReportAdminNotesMutation",
    "selections": (v1/*: any*/),
    "type": "ShopshareMutationRoot",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "saveShopcastReportAdminNotesMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "7c8d9bddf45dd6d25f0030fa644bee80",
    "id": null,
    "metadata": {},
    "name": "saveShopcastReportAdminNotesMutation",
    "operationKind": "mutation",
    "text": "mutation saveShopcastReportAdminNotesMutation(\n  $input: SaveShopcastReportAdminNotesInput!\n) {\n  saveShopcastReportAdminNotes(input: $input) {\n    report {\n      id\n    }\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "c8917c7088473b0050500fe195ee1643";

module.exports = ((node/*: any*/)/*: Mutation<
  saveShopcastReportAdminNotesMutation$variables,
  saveShopcastReportAdminNotesMutation$data,
>*/);
