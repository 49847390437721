/**
 * @generated SignedSource<<148170ee13ddf8a4f98a62dce5e6f151>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentType } from "relay-runtime";
declare export opaque type lookbookCard_currentUser$fragmentType: FragmentType;
export type lookbookCard_currentUser$data = {|
  +adminAccess: ?string,
  +id: string,
  +$fragmentType: lookbookCard_currentUser$fragmentType,
|};
export type lookbookCard_currentUser$key = {
  +$data?: lookbookCard_currentUser$data,
  +$fragmentSpreads: lookbookCard_currentUser$fragmentType,
  ...
};
*/

var node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "lookbookCard_currentUser",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "adminAccess",
      "storageKey": null
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node/*: any*/).hash = "89d2d8cd6950b432640237f3b3e7b17a";

module.exports = ((node/*: any*/)/*: Fragment<
  lookbookCard_currentUser$fragmentType,
  lookbookCard_currentUser$data,
>*/);
