import React, { Component } from "react"
import Modal from "react-modal"
import { Cross2Icon } from "@radix-ui/react-icons"
import button from "shared/styles/components/buttons.css"
import modal from "shared/styles/components/modal.css"
import form from "shared/styles/components/form.css"
import toggle from "shared/styles/components/toogle.css"
import textfield from "shared/styles/components/textfield.css"
import EmojiTextArea from "components/emoji-textarea/emoji-textarea"
import AutoSuggest from "../../auto-suggest-new/auto-suggest"

class CreateEditCampaignModal extends Component {
	constructor(props) {
		super(props)
		this.state = {}
	}

	render() {
		const { closeModal, data, users, onChange, modalSubmit } = this.props
		return (
			<Modal
				className={modal.root}
				overlayClassName={modal.overlay}
				htmlOpenClassName="ReactModal__Html--open"
				isOpen
				shouldCloseOnOverlayClick
				ariaHideApp={false}
			>
				<div
					className={`${modal.dialog} ${modal.dialogCentered} ${modal.dialogFullscreenSmDown}`}
				>
					<div className={modal.content}>
						<div className={`${modal.header}`}>
							<h4 className={modal.title}>
								{data.id ? "Edit Campaign" : "New Campaign"}
							</h4>
							<button
								type="button"
								className={button.close}
								onClick={closeModal}
							>
								<Cross2Icon className={button.closeIcon} />
							</button>
						</div>
						<div className={modal.body}>
							<div className={form.group}>
								<label className={form.label}>Campaign Name</label>
								<div className={form.inputGroup}>
									<input
										className={textfield.normal}
										placeholder="Friends of ShopShare "
										type="text"
										value={data.name}
										onChange={(e) => {
											onChange({ ...data, name: e.target.value })
										}}
									/>
								</div>
							</div>

							<div className={form.group}>
								<label className={form.label}>
									Custom Reward Description
								</label>
								<EmojiTextArea
									value={data.description}
									className={textfield.textarea}
									rows={2}
									onChange={(e) => {
										onChange({ ...data, description: e.target.value })
									}}
									placeholder="Tell affiliates what kind of reward they'll receive."
								/>
								<p className={form.help}>
									Leave blank to use the default description based on
									your commission settings.
								</p>
							</div>
							<div className={form.row}>
								<div className={form.group}>
									<label className={form.label}>Commission Type</label>
									<select
										className={textfield.normal}
										value={data.type}
										onChange={(e) => {
											onChange({ ...data, type: e.target.value })
										}}
									>
										<option value="PERCENTAGE">Percentage</option>
										<option value="FIXED">Fixed</option>
									</select>
								</div>
							</div>

							<div className={form.row}>
								<div className={form.group}>
									<label className={form.label}>
										Monthly Commission
									</label>
									<div className={form.inputGroup}>
										<span className={form.inputGroupText}>$</span>
										<input
											className={textfield.normal}
											type="number"
											value={data.monthlyCommision}
											onChange={(e) => {
												onChange({
													...data,
													monthlyCommision: +e.target.value,
												})
											}}
										/>
									</div>
								</div>
								<div className={form.group}>
									<label className={form.label}>
										Yearly Commission
									</label>
									<div className={form.inputGroup}>
										<span className={form.inputGroupText}>$</span>
										<input
											className={textfield.normal}
											type="number"
											value={data.annualCommision}
											onChange={(e) => {
												onChange({
													...data,
													annualCommision: +e.target.value,
												})
											}}
										/>
									</div>
								</div>
							</div>

							<div className={form.group}>
								<label className={form.label}>Stripe Coupon Code</label>
								<div className={form.row}>
									<input
										className={textfield.normal}
										type="text"
										placeholder="Monthly code"
										value={data.monthlyCoupon}
										onChange={(e) => {
											onChange({
												...data,
												monthlyCoupon: e.target.value,
											})
										}}
									/>

									<input
										className={textfield.normal}
										type="text"
										placeholder="Yearly code"
										value={data.annualCoupon}
										onChange={(e) => {
											onChange({
												...data,
												annualCoupon: e.target.value,
											})
										}}
									/>
								</div>
							</div>

							<div className={form.group}>
								<label className={form.label}>Cookie Window</label>
								<div className={form.inputGroup}>
									<span className={form.inputGroupText}>
										Expires after
									</span>
									<input
										className={textfield.normal}
										type="number"
										value={data.cookiePeriod}
										onChange={(e) => {
											onChange({
												...data,
												cookiePeriod: +e.target.value,
											})
										}}
									/>
									<span className={form.inputGroupText}>days</span>
								</div>
								<p className={form.help}>
									Visitors must convert within this many days after
									visiting your website in order to be attributed to
									the affiliate.
								</p>
							</div>

							<div className={form.group}>
								<label className={form.label}>Users</label>
								<label className={toggle.check}>
									<div className={toggle.switch}>
										<input
											type="checkbox"
											checked={data.allUsers}
											onChange={(e) => {
												onChange({
													...data,
													allUsers: e.target.checked,
												})
											}}
										/>
										<div className={toggle.slider} />
									</div>
									<div className={toggle.label}>All Users</div>
								</label>
							</div>
							{!data.allUsers && (
								<AutoSuggest
									result={data.users.map((i) => ({
										id: i.id,
										value: i.username,
									}))}
									dataList={users.map((i) => ({
										id: i.id,
										value: i.username,
									}))}
									noNew
									onChange={(result) => {
										onChange({
											...data,
											users: result.map((i) => ({
												id: i.id,
												username: i.value,
											})),
										})
									}}
								/>
							)}
						</div>
						<div className={modal.footer}>
							<button
								type="button"
								className={button.link}
								onClick={closeModal}
							>
								Cancel
							</button>
							<button
								type="button"
								className={button.primary}
								onClick={modalSubmit}
							>
								Save
							</button>
						</div>
					</div>
				</div>
			</Modal>
		)
	}
}

export default CreateEditCampaignModal
