/**
 * @generated SignedSource<<029dce3fabdd4d463ebcbddda7723ab0>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentType } from "relay-runtime";
declare export opaque type shopcastManagerRoot_store$fragmentType: FragmentType;
export type shopcastManagerRoot_store$data = {|
  +currentUser: ?{|
    +id: string,
    +lookbookTemplatesCategories: ?$ReadOnlyArray<?string>,
    +role: ?string,
    +shopcastContainer: ?{|
      +archivedItemCount: ?number,
      +id: string,
      +publishedCount: ?number,
      +rootFolders: ?{|
        +edges: ?$ReadOnlyArray<?{|
          +node: ?{|
            +count: ?number,
            +id: string,
            +name: ?string,
          |},
        |}>,
      |},
      +rootItemCount: ?number,
      +starredFolders: ?{|
        +edges: ?$ReadOnlyArray<?{|
          +node: ?{|
            +clients: ?$ReadOnlyArray<?{|
              +avatar: ?string,
              +fullname: ?string,
              +id: string,
            |}>,
            +count: ?number,
            +id: string,
            +isParentAClient: ?boolean,
            +name: ?string,
            +sharableLink: ?string,
          |},
        |}>,
      |},
    |},
  |},
  +id: string,
  +team: ?{|
    +avatar: ?string,
    +id: string,
    +name: ?string,
  |},
  +$fragmentType: shopcastManagerRoot_store$fragmentType,
|};
export type shopcastManagerRoot_store$key = {
  +$data?: shopcastManagerRoot_store$data,
  +$fragmentSpreads: shopcastManagerRoot_store$fragmentType,
  ...
};
*/

var node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "avatar",
  "storageKey": null
},
v3 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 10
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "count",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "cursor",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "concreteType": "PageInfo",
  "kind": "LinkedField",
  "name": "pageInfo",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "endCursor",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasNextPage",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "teamId"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": null,
        "cursor": null,
        "direction": "forward",
        "path": [
          "currentUser",
          "shopcastContainer",
          "starredFolders"
        ]
      },
      {
        "count": null,
        "cursor": null,
        "direction": "forward",
        "path": [
          "currentUser",
          "shopcastContainer",
          "rootFolders"
        ]
      }
    ]
  },
  "name": "shopcastManagerRoot_store",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "id",
          "variableName": "teamId"
        }
      ],
      "concreteType": "team",
      "kind": "LinkedField",
      "name": "team",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        (v2/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "currentUser",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "role",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "lookbookTemplatesCategories",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "ShopcastContainer",
          "kind": "LinkedField",
          "name": "shopcastContainer",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "rootItemCount",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "archivedItemCount",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "publishedCount",
              "storageKey": null
            },
            {
              "alias": "starredFolders",
              "args": (v3/*: any*/),
              "concreteType": "ShopcastContainerShopcastFolderConnectionConnection",
              "kind": "LinkedField",
              "name": "__currentUser_connectionManager_starredFolders_connection",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "ShopcastContainerShopcastFolderConnectionEdge",
                  "kind": "LinkedField",
                  "name": "edges",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "ShopcastFolder",
                      "kind": "LinkedField",
                      "name": "node",
                      "plural": false,
                      "selections": [
                        (v0/*: any*/),
                        (v1/*: any*/),
                        (v4/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "Client",
                          "kind": "LinkedField",
                          "name": "clients",
                          "plural": true,
                          "selections": [
                            (v0/*: any*/),
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "fullname",
                              "storageKey": null
                            },
                            (v2/*: any*/)
                          ],
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "sharableLink",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "isParentAClient",
                          "storageKey": null
                        },
                        (v5/*: any*/)
                      ],
                      "storageKey": null
                    },
                    (v6/*: any*/)
                  ],
                  "storageKey": null
                },
                (v7/*: any*/)
              ],
              "storageKey": "__currentUser_connectionManager_starredFolders_connection(first:10)"
            },
            {
              "alias": "rootFolders",
              "args": (v3/*: any*/),
              "concreteType": "ShopcastContainerShopcastFolderConnectionConnection",
              "kind": "LinkedField",
              "name": "__currentUser_connectionManager_rootFolders_connection",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "ShopcastContainerShopcastFolderConnectionEdge",
                  "kind": "LinkedField",
                  "name": "edges",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "ShopcastFolder",
                      "kind": "LinkedField",
                      "name": "node",
                      "plural": false,
                      "selections": [
                        (v0/*: any*/),
                        (v1/*: any*/),
                        (v4/*: any*/),
                        (v5/*: any*/)
                      ],
                      "storageKey": null
                    },
                    (v6/*: any*/)
                  ],
                  "storageKey": null
                },
                (v7/*: any*/)
              ],
              "storageKey": "__currentUser_connectionManager_rootFolders_connection(first:10)"
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Store",
  "abstractKey": null
};
})();

(node/*: any*/).hash = "7aceb27647d3782956088ba14b9713bf";

module.exports = ((node/*: any*/)/*: Fragment<
  shopcastManagerRoot_store$fragmentType,
  shopcastManagerRoot_store$data,
>*/);
