import { commitMutation, graphql } from "react-relay"
import { ConnectionHandler } from "relay-runtime"
import connections from "../connections.js"
import { complete } from "../helper.js"

const mutation = graphql`
mutation removeRakutenSiteMutation($input: DeleteRakutenSiteInput!) {
    deleteRakutenSite(input: $input) {
      store{
        id
      }
    }
  }
  
`

const commit = (env, site, onCompleted, onError) =>
	commitMutation(env, {
		mutation,
		variables: { input: { id: site } },
		onCompleted: complete(onCompleted, onError),
		onError: (e) => complete(null, [e]),
		updater: (storeProxy, data) => {
			if (data.deleteRakutenSite) {
				const store = storeProxy.get(data.deleteRakutenSite.store.id)
				const connection = ConnectionHandler.getConnection(
					store,
					connections.admin.rakutenSites.name
				)
				ConnectionHandler.deleteNode(connection, site)
			}
		},
	})

export default { commit }
