/**
 * @generated SignedSource<<cf02c233ecb933027b26803f64d5e06c>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Mutation } from 'relay-runtime';
export type UpdateShopcastInput = {|
  category: string,
  clientMutationId?: ?string,
  description?: ?string,
  publicSetting: string,
  shopcastId: string,
  title: string,
|};
export type updateShopcastMutation$variables = {|
  input: UpdateShopcastInput,
|};
export type updateShopcastMutation$data = {|
  +updateShopcast: ?{|
    +output: ?string,
  |},
|};
export type updateShopcastMutation = {|
  response: updateShopcastMutation$data,
  variables: updateShopcastMutation$variables,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UpdateShopcastPayload",
    "kind": "LinkedField",
    "name": "updateShopcast",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "output",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "updateShopcastMutation",
    "selections": (v1/*: any*/),
    "type": "ShopshareMutationRoot",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "updateShopcastMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "d1194e72a87ebaad97d398c33dfde0fb",
    "id": null,
    "metadata": {},
    "name": "updateShopcastMutation",
    "operationKind": "mutation",
    "text": "mutation updateShopcastMutation(\n  $input: UpdateShopcastInput!\n) {\n  updateShopcast(input: $input) {\n    output\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "954caa2964894e7c51b600e59678f6e9";

module.exports = ((node/*: any*/)/*: Mutation<
  updateShopcastMutation$variables,
  updateShopcastMutation$data,
>*/);
