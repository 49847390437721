/**
 * @generated SignedSource<<ead0e6f421955289622f6e876f310a4d>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Query } from 'relay-runtime';
import type { main_store$fragmentType } from "./main_store.graphql";
export type mainQuery$variables = {|
  teamId?: ?string,
|};
export type mainQuery$data = {|
  +store: ?{|
    +$fragmentSpreads: main_store$fragmentType,
  |},
|};
export type mainQuery = {|
  response: mainQuery$data,
  variables: mainQuery$variables,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "teamId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "teamId",
    "variableName": "teamId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "mainQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Store",
        "kind": "LinkedField",
        "name": "store",
        "plural": false,
        "selections": [
          {
            "args": (v1/*: any*/),
            "kind": "FragmentSpread",
            "name": "main_store"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "ShopshareQueryRoot",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "mainQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Store",
        "kind": "LinkedField",
        "name": "store",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "currentUser",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "fullname",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "username",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "email",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "customDomain",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "profilePhoto",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "bannerPhoto",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "adminAccess",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isRetailer",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "type",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "role",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasPendingSharerRequests",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "optOutAffiliate",
                "storageKey": null
              },
              {
                "alias": "personalPlan",
                "args": null,
                "concreteType": "PricingPlan",
                "kind": "LinkedField",
                "name": "userPlan",
                "plural": false,
                "selections": [
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "team",
                "kind": "LinkedField",
                "name": "teams",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "avatar",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isANewUser",
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "PricingPlan",
                "kind": "LinkedField",
                "name": "userPlan",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "amount",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "currency",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "nickname",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "monthly",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v1/*: any*/),
                "kind": "ScalarField",
                "name": "features",
                "storageKey": null
              },
              {
                "alias": "myFeatures",
                "args": null,
                "kind": "ScalarField",
                "name": "features",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "displayName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "bio",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isVerified",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "UserSocialLinks",
                "kind": "LinkedField",
                "name": "socialLinks",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "website",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "instagramUsername",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "facebookUrl",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "twitterUsername",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "youtubeUrl",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "pinterestUrl",
                    "storageKey": null
                  },
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": [
              {
                "kind": "Variable",
                "name": "id",
                "variableName": "teamId"
              }
            ],
            "concreteType": "team",
            "kind": "LinkedField",
            "name": "team",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "85be37ce8c4489792df24897be4f04a9",
    "id": null,
    "metadata": {},
    "name": "mainQuery",
    "operationKind": "query",
    "text": "query mainQuery(\n  $teamId: String\n) {\n  store {\n    ...main_store_1j7Wr3\n    id\n  }\n}\n\nfragment header_store on Store {\n  id\n  ...userMenu_store\n  currentUser {\n    id\n    fullname\n    email\n    username\n    customDomain\n    profilePhoto\n    adminAccess\n    isRetailer\n    type\n    optOutAffiliate\n    hasPendingSharerRequests\n    isANewUser\n  }\n}\n\nfragment main_store_1j7Wr3 on Store {\n  id\n  ...header_store\n  team(id: $teamId) {\n    id\n    name\n  }\n  currentUser {\n    userPlan(teamId: $teamId) {\n      id\n      amount\n      currency\n      nickname\n      monthly\n    }\n    features(teamId: $teamId)\n    myFeatures: features\n    email\n    username\n    type\n    ...updateUsername_currentUser\n    id\n  }\n}\n\nfragment updateUsername_currentUser on User {\n  displayName\n  fullname\n  username\n  bio\n  email\n  isRetailer\n  isVerified\n  socialLinks {\n    website\n    instagramUsername\n    facebookUrl\n    twitterUsername\n    youtubeUrl\n    pinterestUrl\n    id\n  }\n}\n\nfragment userMenu_store on Store {\n  id\n  currentUser {\n    id\n    fullname\n    username\n    email\n    customDomain\n    profilePhoto\n    bannerPhoto\n    adminAccess\n    isRetailer\n    type\n    role\n    hasPendingSharerRequests\n    optOutAffiliate\n    personalPlan: userPlan {\n      id\n    }\n    teams {\n      id\n      name\n      avatar\n    }\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "7b65390e6113dbf15b1a26d3ee52fb2b";

module.exports = ((node/*: any*/)/*: Query<
  mainQuery$variables,
  mainQuery$data,
>*/);
