import { commitMutation, graphql } from "react-relay"
import { complete } from "../helper.js"

const mutation = graphql`
	mutation bannerPhotoMutation($input: BannerPhotoInput!) {
		bannerPhoto(input: $input) {
			store {
				currentUser {
					bannerPhoto
				}
			}
			user {
				bannerPhoto
			}
		}
	}
`

const commit = (env, file, profileId, onCompleted, onError) =>
	commitMutation(env, {
		mutation,
		variables: { input: { imageName: file?.name, profileId } },
		uploadables: {
			file,
		},
		onCompleted: complete(onCompleted, onError),
		onError: (e) => complete(null, [e]),
		updater: (storeProxy, data) => {
			const profileProxy = storeProxy.get(profileId)
			profileProxy.setValue(data.bannerPhoto.user.bannerPhoto, "bannerPhoto")
		},
	})

export default { commit }
