/**
 * @generated SignedSource<<ba72ab4555edd7906cb0458d655da071>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { adminUserReportItem_shopcastUserReport$fragmentType } from "./../../admin-user-report-item/__generated__/adminUserReportItem_shopcastUserReport.graphql";
import type { FragmentType } from "relay-runtime";
declare export opaque type adminUserReports_reports$fragmentType: FragmentType;
export type adminUserReports_reports$data = {|
  +id: string,
  +shopcastUserReports: ?{|
    +edges: ?$ReadOnlyArray<?{|
      +node: ?{|
        +__typename: string,
        +$fragmentSpreads: adminUserReportItem_shopcastUserReport$fragmentType,
      |},
    |}>,
  |},
  +$fragmentType: adminUserReports_reports$fragmentType,
|};
export type adminUserReports_reports$key = {
  +$data?: adminUserReports_reports$data,
  +$fragmentSpreads: adminUserReports_reports$fragmentType,
  ...
};
*/

var node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": null,
        "cursor": null,
        "direction": "forward",
        "path": [
          "shopcastUserReports"
        ]
      }
    ]
  },
  "name": "adminUserReports_reports",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": "shopcastUserReports",
      "args": [
        {
          "kind": "Literal",
          "name": "first",
          "value": 50
        }
      ],
      "concreteType": "AdminShopcastUserReportConnection",
      "kind": "LinkedField",
      "name": "__admin_shopcastUserReports_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "AdminShopcastUserReportEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": null,
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__typename",
                  "storageKey": null
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "adminUserReportItem_shopcastUserReport"
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cursor",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PageInfo",
          "kind": "LinkedField",
          "name": "pageInfo",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endCursor",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasNextPage",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "__admin_shopcastUserReports_connection(first:50)"
    }
  ],
  "type": "Admin",
  "abstractKey": null
};

(node/*: any*/).hash = "693182debf393d9f62c339b875302569";

module.exports = ((node/*: any*/)/*: Fragment<
  adminUserReports_reports$fragmentType,
  adminUserReports_reports$data,
>*/);
