import React, { PureComponent } from "react"
import { createPaginationContainer, graphql } from "react-relay"
import ListOfShopcasts from "../../components/list-of-shopcasts/list-of-shopcasts.jsx"

class TeamShopcasts extends PureComponent {
	render() {
		const { props } = this
		const { team } = props.store
		return (
			<ListOfShopcasts
				enableHeader={false}
				shopcasts={team.shopcasts}
				relay={props.relay}
				currentUser={props.store.currentUser}
				storeId={props.store.id}
				disableSubdomain
			/>
		)
	}
}

const query = graphql`
	query teamShopcastsQuery($count: Int!, $cursor: String, $teamId: String!) {
		store {
			...teamShopcasts_store
				@arguments(
					count: $count
					cursor: $cursor
					teamId: $teamId
				)
		}
	}
`

TeamShopcasts = createPaginationContainer(
	TeamShopcasts,
	{
		store: graphql`
			fragment teamShopcasts_store on Store
			@argumentDefinitions(
				count: { type: "Int", defaultValue: 12 }
				cursor: { type: "String" }
				teamId: { type: "String!" }
			) {
				id
				currentUser {
					id
					...shopcastCard_currentUser
				}
				team(id: $teamId) {
					shopcasts(first: $count, after: $cursor)
						@connection(key: "team_shopcasts") {
						edges {
							node {
								...shopcastCard_shopcast
							}
						}
					}
				}
			}
		`,
	},
	{
		direction: "forward",
		getConnectionFromProps(props) {
			const { team } = props.store
			return team && team.shopcasts
		},
		getFragmentVariables(prevVars, totalCount) {
			return {
				...prevVars,
				count: totalCount,
			}
		},
		getVariables(props, { count, cursor }) {
			return {
				count,
				cursor,
				teamId: props.teamId,
			}
		},
		query,
	}
)

export default {
	Component: TeamShopcasts,
	query,
	params: {
		count: 12,
		cursor: null,
		teamId: null,
	},
}
