/* eslint-disable import/prefer-default-export */
export const getCommisionMessage = (data, ignoreDescription = false) => {
	if (!ignoreDescription && data.description) return data.description
	switch (data?.type) {
		case "PERCENTAGE":
			return `${data.monthlyCommision}% monthly or ${data.annualCommision}% yearly commission on any payments.`
		case "FIXED":
			return `A fixed $${data.monthlyCommision} commission for monthly plans and $${data.annualCommision} for yearly plans on all payments for paying customers.`
		default:
			return ""
	}
}
