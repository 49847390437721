/**
 * @generated SignedSource<<d6c259f8d4bd56fbaf9e9644de12820f>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Mutation } from 'relay-runtime';
export type UpdatePersonalInfoInput = {|
  bio: string,
  clientMutationId?: ?string,
  displayName: string,
  email: string,
  fullname: string,
  paypalEmail: string,
|};
export type updatePersonalInfoMutation$variables = {|
  input: UpdatePersonalInfoInput,
|};
export type updatePersonalInfoMutation$data = {|
  +updatePersonalInfo: ?{|
    +store: ?{|
      +currentUser: ?{|
        +id: string,
        +isVerified: ?boolean,
        +verificationInProgress: ?string,
      |},
      +id: string,
    |},
  |},
|};
export type updatePersonalInfoMutation = {|
  response: updatePersonalInfoMutation$data,
  variables: updatePersonalInfoMutation$variables,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UpdatePersonalInfoPayload",
    "kind": "LinkedField",
    "name": "updatePersonalInfo",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Store",
        "kind": "LinkedField",
        "name": "store",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "currentUser",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isVerified",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "verificationInProgress",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "updatePersonalInfoMutation",
    "selections": (v2/*: any*/),
    "type": "ShopshareMutationRoot",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "updatePersonalInfoMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "00703b3b462bc8c007467ed53ef8295f",
    "id": null,
    "metadata": {},
    "name": "updatePersonalInfoMutation",
    "operationKind": "mutation",
    "text": "mutation updatePersonalInfoMutation(\n  $input: UpdatePersonalInfoInput!\n) {\n  updatePersonalInfo(input: $input) {\n    store {\n      id\n      currentUser {\n        id\n        isVerified\n        verificationInProgress\n      }\n    }\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "d832edb2539c065f597bf695cad88799";

module.exports = ((node/*: any*/)/*: Mutation<
  updatePersonalInfoMutation$variables,
  updatePersonalInfoMutation$data,
>*/);
