/**
 * @generated SignedSource<<06299754275708168c65abebe691082e>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Mutation } from 'relay-runtime';
export type CampaignCommisionType = "FIXED" | "PERCENTAGE" | "%future added value";
export type UpdateCampaignInput = {|
  allUsers?: ?boolean,
  annualCommision?: ?number,
  annualCoupon?: ?string,
  clientMutationId?: ?string,
  cookiePeriod?: ?number,
  description?: ?string,
  id: string,
  monthlyCommision?: ?number,
  monthlyCoupon?: ?string,
  name?: ?string,
  type?: ?CampaignCommisionType,
  users?: ?$ReadOnlyArray<?string>,
|};
export type updateCampaignMutation$variables = {|
  input: UpdateCampaignInput,
|};
export type updateCampaignMutation$data = {|
  +updateCampaign: ?{|
    +store: ?{|
      +id: string,
    |},
  |},
|};
export type updateCampaignMutation = {|
  response: updateCampaignMutation$data,
  variables: updateCampaignMutation$variables,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UpdateCampaignPayload",
    "kind": "LinkedField",
    "name": "updateCampaign",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Store",
        "kind": "LinkedField",
        "name": "store",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "updateCampaignMutation",
    "selections": (v1/*: any*/),
    "type": "ShopshareMutationRoot",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "updateCampaignMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "37ed6106e5c02b7089000c7f78dd57fd",
    "id": null,
    "metadata": {},
    "name": "updateCampaignMutation",
    "operationKind": "mutation",
    "text": "mutation updateCampaignMutation(\n  $input: UpdateCampaignInput!\n) {\n  updateCampaign(input: $input) {\n    store {\n      id\n    }\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "373dd4766872ccec9d86977f021f926f";

module.exports = ((node/*: any*/)/*: Mutation<
  updateCampaignMutation$variables,
  updateCampaignMutation$data,
>*/);
