import React from "react"
import { Workbox } from "workbox-window"
import UpdatePrompt from "components/modals/update-prompt/update-prompt"
import { createRoot } from "react-dom/client"

export function registerServiceWorker() {
	// Check if the serviceWorker Object exists in the navigator object ( means if browser supports SW )
	if ("serviceWorker" in navigator) {
		const wb = new Workbox("/sw.js")

		wb.addEventListener("installed", (event) => {
			if (event.isUpdate) {
				const updateModalElement = window.document.getElementById(
					"update-modal"
				)
				if (updateModalElement) {
					const root = createRoot(updateModalElement);
					root.render(<UpdatePrompt />)
				} else if (
					// eslint-disable-next-line no-alert
					window.confirm(
						`New version of ShopShare has been released! Click OK to refresh`
					)
				) {
					window.location.reload()
				}
			}
		})
		wb.register()
	}
}

export function unregisterServiceWorker() {
	if ("serviceWorker" in navigator) {
		navigator.serviceWorker.getRegistrations().then((registrations) => {
			for (const registration of registrations) {
				registration.unregister()
			}
		})
	}
}
