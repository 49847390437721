import React, { Component } from "react"
import Modal from "react-modal"
import { Link } from "react-router-dom"
import { toast } from "react-toastify"
import {
	FeatureAvailableComponent,
	FeatureKeyENUM,
} from "services/plan-services"

import AutoSuggest from "components/auto-suggest-new/auto-suggest"
import LoadingDots from "components/loading-dots/loading-dots"
import EmojiTextArea from "components/emoji-textarea/emoji-textarea"
// import CanvasSizePicker from "components/lookbook-dropdown/canvas-size-picker"
import modal from "shared/styles/components/modal.css"
import button from "shared/styles/components/buttons.css"
import form from "shared/styles/components/form.css"
import textfield from "shared/styles/components/textfield.css"
import toggle from "shared/styles/components/toogle.css"

class LookbookSaving extends Component {
	constructor(props) {
		super(props)
		this.state = {
			folderList: {},
			categoryArray: [],
		}
		this.onTreeViewToggle = this.onTreeViewToggle.bind(this)
		this.onSelect = this.onSelect.bind(this)
	}

	componentDidMount() {
		const { categorySet } = this.props
		this.setState({ categoryArray: categorySet })
	}

	componentDidUpdate() {
		const { folderList } = this.props
		const { folderList: folderListInState } = this.state
		if (folderList && folderList !== folderListInState) {
			this.setState({ folderList })
		}
	}

	onTreeViewToggle(node, toggled) {
		const { cursor, folderList } = this.state
		const { changeLookbook } = this.props
		if (cursor) {
			this.setState(() => ({ cursor }))
		}
		node.active = true
		if (node.children) {
			node.toggled = toggled
		}
		changeLookbook({ folder: node })
		this.setState(() => ({
			cursor: node,
			folderList: { ...folderList },
		}))
	}

	onSelect(categoryArray) {
		const { changeLookbook } = this.props
		const { categoryArray: newCollection } = this.state
		categoryArray.forEach((collection) => {
			if (collection.id.includes("new_")) {
				newCollection.push(collection)
			}
		})
		this.setState({ categoryArray: newCollection })
		changeLookbook({ categories: categoryArray.map((obj) => obj.value) })
	}

	setError(message) {
		toast.info(message, {
			autoClose: 5000,
			closeButton: false,
		})
	}

	render() {
		const {
			openModal,
			closeModal,
			approveEvent,
			declineEvent,
			title,
			approveText,
			declineText,
			approveButtonStyle,
			loading,
			lookbook,
			changeLookbook,
			isAdmin,
			environment,
			lookbookBoardsCount,
			selectedBoard,
			changeCanvasSize,
			...otherProps
		} = this.props
		const { categoryArray } = this.state
		return (
			<Modal
				className={modal.root}
				overlayClassName={modal.overlay}
				isOpen={openModal}
				{...otherProps}
				ariaHideApp={false}
			>
				<div className={`${modal.dialog} ${modal.dialogCentered}`}>
					<div className={`${modal.content}`}>
						<div className={modal.header}>
							{title && (
								<h4 className={modal.title}>
									{lookbook && lookbook.isTemplate
										? "Create New Template"
										: title}
								</h4>
							)}
						</div>
						<div className={modal.body}>
								{/* {isAdmin && lookbookBoardsCount <= 1 && (
									<FeatureAvailableComponent
										featureKey={FeatureKeyENUM.TEMPLATES}
										env={environment}
									>
										<div className={form.group}>
											<label className={toggle.check}>
												<div className={toggle.switch}>
													<input
														type="checkbox"
														checked={
															lookbook && lookbook.isTemplate
																? lookbook.isTemplate
																: false
														}
														onChange={({ target }) => {
															changeLookbook({
																isTemplate: target.checked,
															})
														}}
														disabled={loading}
													/>
													<div className={toggle.slider} />
												</div>
												<div className={toggle.label}>
													Save as Template
												</div>
											</label>
										</div>
									</FeatureAvailableComponent>
								)} */}
								<div className={form.group}>
									<label className={form.label}>Title</label>
									<input
										id="lookbook_title"
										type="text"
										placeholder="Enter a board title"
										className={textfield.normal}
										maxLength={255}
										value={
											lookbook && lookbook.title
												? lookbook.title
												: ""
										}
										onChange={({ target }) =>
											changeLookbook({ lookbookTitle: target.value })
										}
										disabled={loading}
									/>
								</div>
								{/* {lookbook && !lookbook.id && (
									<div className={form.group}>
										<label className={form.label}>Canvas Size</label>
										<CanvasSizePicker
											label
											canvasHeight={selectedBoard?.height || 0}
											canvasWidth={selectedBoard?.width || 0}
											onChange={({ canvasWidth, canvasHeight }) =>
												changeCanvasSize({
													canvasWidth,
													canvasHeight,
												})
											}
										/>
									</div>
								)} */}
								{lookbook && lookbook.isTemplate ? (
									isAdmin && (
										<FeatureAvailableComponent
											featureKey={FeatureKeyENUM.TEMPLATES}
											env={environment}
										>
											<div className={form.group}>
												<label className={toggle.check}>
													<div className={toggle.switch}>
														<input
															type="checkbox"
															checked={
																lookbook && lookbook.isGlobal
																	? lookbook.isGlobal
																	: false
															}
															onChange={({ target }) =>
																changeLookbook({
																	isGlobal: target.checked,
																})
															}
															disabled={loading}
														/>
														<div className={toggle.slider} />
													</div>
													<div className={toggle.label}>
														Anyone can use Template
													</div>
												</label>
											</div>
											{lookbook && lookbook.isGlobal && (
												<div className={form.group}>
													<label className={form.label}>
														Category
													</label>
													<AutoSuggest
														result={
															categoryArray.filter((cat) =>
																lookbook.categories.includes(
																	cat.value
																)
															) || []
														}
														dataList={categoryArray || []}
														onChange={this.onSelect}
													/>
												</div>
											)}
										</FeatureAvailableComponent>
									)
								) : (
									<div className={form.group}>
										<label className={form.label}>Description</label>
										<EmojiTextArea
											id="lookbook_desc"
											className={textfield.textarea}
											maxLength={2048}
											rows={5}
											placeholder="Add a caption and describe this board"
											defaultValue={
												lookbook && lookbook.description
													? lookbook.description
													: ""
											}
											onBlur={({ target }) =>
												changeLookbook({
													description: target.value,
												})
											}
											disabled={loading}
										/>
									</div>
								)}
						</div>
						<div className={`${modal.footer} ${modal.borderNone}`}>
							{lookbook && !lookbook.id ? (
								<Link className={button.link} to="/content">
									Cancel
								</Link>
							) : (
								<button
									type="button"
									className={button.link}
									onClick={declineEvent}
								>
									{loading ? (
										<LoadingDots color="var(--gray300)" />
									) : (
										"Cancel"
									)}
								</button>
							)}
							<button
								type="button"
								className={approveButtonStyle || button.primary}
								onClick={() => {
									if (
										lookbook.isTemplate &&
										lookbook.isGlobal &&
										(!lookbook.categories ||
											lookbook.categories.length === 0)
									) {
										this.setError("Category cannot be Empty")
										return
									}
									approveEvent()
								}}
							>
								{loading ? (
									<LoadingDots color="var(--primary80)" />
								) : (
									approveText || "Ok"
								)}
							</button>
						</div>
					</div>
				</div>
			</Modal>
		)
	}
}
export default LookbookSaving
