import React, { Component, Fragment } from "react"
import {
	graphql,
	commitLocalUpdate,
	ConnectionHandler,
	createRefetchContainer,
} from "react-relay"
import { Helmet } from "react-helmet"
import {
	ExclamationTriangleIcon,
	CheckIcon,
	Pencil1Icon,
	FrameIcon,
	PlusIcon,
	StarFilledIcon,
	StarIcon,
} from "@radix-ui/react-icons"
import scrollIntoView from "scroll-into-view"
import { toast } from "react-toastify"
import queryString from "query-string"
import io from "socket.io-client"
import Player from "components/player/player"
import LoadingDots from "components/loading-dots/loading-dots"
import RequireLoginToast from "components/require-login-toast/require-login-toast"
import ScrollToTopOnMount from "components/scroll-to-top/scroll-to-top"
import stylesDropdown from "components/dropdown-menu/dropdown-menu-dark.css"
import empty from "shared/styles/components/empty.css"
import buttons from "shared/styles/components/buttons.css"
import ToggleLikesMutation from "mutations/shopcast/toggle-likes"
import trackingService from "services/tracking-service"
import ToggleRecommendedShopcastMutation from "mutations/shopcast/toggle-recommended-shopcast"
import TrackProductViewMutation from "mutations/product/track-product-view"
import AssignProductsToShopcastMutation from "mutations/shopcast/assign-products"
import connections from "mutations/connections"
import ShopcastEdit from "components/modals/shopcast-edit/shopcast-edit"
import PanelShopItems from "components/panel-shop-items/panel-shop-items"
import { baseUrl, publicViewDomain } from "constants"
import AddProducts from "./add-products"
import styles from "./shopcast.css"
import { withRouter } from "../../route-helpers"

class Shopcast extends Component {
	constructor(props) {
		super(props)
		this.state = {
			isPlaying: false,
			currentPlayerTime: 0,
			currentProduct: null,
			goToTime: -1,
			uploadProgressValue: null,
			enableAddProduct: false,
			enableProductPanel: false,
			enableDetailsPanel: false,
		}

		this.initialize = this.initialize.bind(this)
		this.videoPlayTimeChanged = this.videoPlayTimeChanged.bind(this)
		this.videoIsPlaying = this.videoIsPlaying.bind(this)
		this.playProduct = this.playProduct.bind(this)
		this.toggleLikes = this.toggleLikes.bind(this)
		this.toggleLikeSuccess = this.toggleLikeSuccess.bind(this)
		this.toggleLikeFailure = this.toggleLikeFailure.bind(this)
		this.startEdtit = this.startEdtit.bind(this)
		this.endEdit = this.endEdit.bind(this)
		this.toggleRecommended = this.toggleRecommended.bind(this)
		this.updateUploadProgress = this.updateUploadProgress.bind(this)
		this.addSelectedProductToStore = this.addSelectedProductToStore.bind(this)
		this.saveProducts = this.saveProducts.bind(this)
		this.refetchData = this.refetchData.bind(this)
		this.getActionMenu = this.getActionMenu.bind(this)

		this.clientId = queryString.parse(props.location.search)?.clientId
	}

	componentDidMount() {
		this.initialize()
	}

	getActionMenu() {
		const listItems = []
		const { store, navigate } = this.props
		const { shopcast, currentUser } = store || {}

		const push = (click, name, key, icon) =>
			listItems.push({
				click,
				name,
				key,
				icon,
			})
		if (shopcast.user.canEdit === true) {
			push(
				() => {
					this.setState({ enableAddProduct: true })
				},
				"Add/Edit Items",
				"addProduct",
				<PlusIcon className={stylesDropdown.itemIcon} />
			)
			push(
				() => {
					this.startEdtit()
				},
				"Edit Shopcast",
				"edit",
				<Pencil1Icon className={stylesDropdown.itemIcon} />
			)
			listItems.push("divide")
			push(
				() => {
					navigate(`/shopboard/create?shopcast=${shopcast.id}`)
				},
				"Create Board",
				"create",
				<FrameIcon className={stylesDropdown.itemIcon} />
			)
		}

		if (currentUser && currentUser.adminAccess === "HAS") {
			if (shopcast.isRecommended) {
				listItems.push("divide")
				push(
					() => {
						this.toggleRecommended()
					},
					"Remove from Featured",
					"removefeatured",
					<StarFilledIcon className={stylesDropdown.itemIcon} />
				)
			} else {
				listItems.push("divide")
				push(
					() => {
						this.toggleRecommended()
					},
					"Add to Featured",
					"addfeatured",
					<StarIcon className={stylesDropdown.itemIcon} />
				)
			}
		}
		return listItems
	}

	async initialize() {
		const { location } = this.props
		const { store, loading } = this.props
		await new Promise((resolve) => {
			const interval = setInterval(() => {
				if (!loading) {
					clearInterval(interval)
					resolve()
				}
			}, 100)
		})

		if (!store.shopcast) return
		trackingService.track("Viewed Shopcast", {
			ShopcastTitle: store.shopcast.title,
			ShopcastCreator: store.shopcast.user.username,
		})

		const search = queryString.parse(location.search)
		const stateModel = {
			isPlaying: !!store.shopcast.category,
		}
		if (search.play) {
			stateModel.goToTime = search.play
		}
		this.setState(stateModel)

		this.socketInstance = io(baseUrl)
		this.socketInstance.on("shopcast-progress", (value) => {
			this.updateUploadProgress(value)
		})
		this.socketInstance.emit(
			"need-shopcast-progress",
			store.shopcast.id,
			store.shopcast.user.id
		)
		this.updateUploadProgress(null)

		window.addEventListener("message", (event) => {
			if (event.data.productFinished) this.refetchData()
		})
	}

	videoIsPlaying(isPlaying) {
		this.setState({ isPlaying })
	}

	refetchData() {
		const { relay, store } = this.props
		relay.refetch({
			id: store.shopcast.id,
		})
	}

	videoPlayTimeChanged(newTime) {
		this.setState({ currentPlayerTime: newTime })
		const { state, props } = this
		const { store } = props
		const { shopcast } = store
		if (
			!shopcast ||
			!shopcast.shopcastItems ||
			!shopcast.shopcastItems.edges ||
			shopcast.shopcastItems.edges.length === 0
		) {
			return
		}
		const { duration } = this.player.getPlayerState()
		const items = [...shopcast.shopcastItems.edges]
		items.sort((a, b) => a.node.start - b.node.start)

		let currentProductItem = null
		for (let i = 0; i < items.length; i++) {
			if (
				items[i].node.start <= newTime &&
				(i + 1 < items.length ? items[i + 1].node.start : duration) >=
					newTime
			) {
				currentProductItem = items[i]
				break
			}
		}

		if (
			currentProductItem &&
			(!state.currentProduct ||
				currentProductItem.node.product.id !== state.currentProduct.id)
		) {
			const currentProduct = currentProductItem.node.product
			this.setState({ currentProduct })
			scrollIntoView(
				document.getElementById(`product${currentProduct.id}`),
				{
					time: 1500,
					validTarget: (target, parentsScrolled) =>
						parentsScrolled < 2 &&
						(!target.matches ? true : !target.matches(".dontScroll")),
					align: {
						top: 0,
						topOffset: 10,
						left: 0,
					},
				}
			)
		} else if (!currentProductItem) {
			this.setState({ currentProduct: null })
		}
	}

	updateUploadProgress(value) {
		const { store } = this.props
		const { shopcast } = store
		if (value && value === "done") {
			toast.success(<Fragment>Upload completed!</Fragment>, {
				autoClose: 2000,
				closeButton: false,
			})
			this.socketInstance.emit(
				"set-shopcast-progress",
				shopcast.id,
				shopcast.user.id,
				null
			)
		} else if (value > 0) {
			this.setState({ uploadProgressValue: value })
		} else {
			this.setState({ uploadProgressValue: null })
		}
	}

	playProduct(product) {
		const { relay } = this.props
		const {
			store: { shopcast },
		} = this.props
		const shopcastItem = shopcast.shopcastItems.edges.find(
			(item) => item.node.product.id === product.id
		)
		TrackProductViewMutation.commit(relay.environment, product.id)
		this.setState({
			currentPlayerTime: shopcastItem.node.start,
			currentProduct: product,
			isPlaying: true,
			goToTime: shopcastItem.node.start,
		})
	}

	toggleLikeSuccess(res) {
		this.refetchData()
		console.log(res)
	}

	toggleLikeFailure(transaction) {
		console.log(transaction)
	}

	toggleLikes() {
		const { relay, store } = this.props
		const { currentUser } = store
		if (!currentUser || !currentUser.id) {
			toast.info(
				<RequireLoginToast
					heading="Nice Shopcast, right? ❤️"
					message="Please sign in to like this video"
				/>,
				{ autoClose: 5000, closeButton: false }
			)
			return
		}
		ToggleLikesMutation.commit(
			relay.environment,
			store.shopcast.id,
			store.shopcast.hasCurrentUserLikedIt,
			store.shopcast.likes,
			this.toggleLikeSuccess,
			this.toggleLikeFailure
		)
	}

	startEdtit() {
		this.setState({ editing: true })
	}

	endEdit() {
		this.setState({ editing: false })
	}

	toggleRecommended() {
		const { relay, store } = this.props
		const { shopcast } = store
		ToggleRecommendedShopcastMutation.commit(
			relay.environment,
			shopcast.id,
			() => {
				this.refetchData()
			},
			() => {}
		)
	}

	addSelectedProductToStore(product) {
		const {
			relay,
			store: { shopcast },
		} = this.props
		commitLocalUpdate(relay.environment, (storeProxy) => {
			const shopcastProxy = storeProxy.get(shopcast.id)
			const productsItemsConnection = ConnectionHandler.getConnection(
				shopcastProxy,
				connections.shopcast.products.name,
				connections.shopcast.products.data
			)
			const shopcastItemEdge = storeProxy.create(
				`ShopcastItemsConnection:edges:${new Date().getTime()}`,
				"ShopcastItemEdge"
			)
			const id = `shopcastItem:${new Date().getTime()}`
			const shopcastItemNode = storeProxy.create(id, "shopcastItem")
			shopcastItemNode.setValue(id, "id")
			shopcastItemNode.setValue(product.start, "start")
			shopcastItemNode.setValue(product.end, "end")
			shopcastItemNode.setLinkedRecord(storeProxy.get(product.id), "product")
			shopcastItemEdge.setLinkedRecord(shopcastItemNode, "node")
			ConnectionHandler.insertEdgeAfter(
				productsItemsConnection,
				shopcastItemEdge
			)
		})
	}

	saveProducts() {
		const {
			relay,
			store: { shopcast },
		} = this.props
		commitLocalUpdate(relay.environment, (storeProxy) => {
			const shopcastProxy = storeProxy.get(shopcast.id)
			const productsItemsConnection = ConnectionHandler.getConnection(
				shopcastProxy,
				connections.shopcast.products.name,
				connections.shopcast.products.data
			)
			const products = productsItemsConnection
				.getLinkedRecords("edges")
				.map((item) => {
					const node = item.getLinkedRecord("node")
					const id = node.getDataID()
					return {
						id: id.includes("shopcastItem") ? null : id,
						start: Math.floor(node.getValue("start")),
						end: Math.floor(node.getValue("end")),
						productId: node.getLinkedRecord("product").getDataID(),
					}
				})
			AssignProductsToShopcastMutation.commit(
				relay.environment,
				{
					shopcastId: shopcast.id,
					products,
				},
				() => {
					toast.error(<Fragment>Failed to save products</Fragment>, {
						autoClose: 2000,
						closeButton: false,
					})
				},
				() => {
					this.setState({
						enableAddProduct: false,
					})
					this.refetchData()
					toast.success(<Fragment>Products saved successfully</Fragment>, {
						autoClose: 2000,
						closeButton: false,
					})
				}
			)
		})
	}

	renderProducts(shopcast) {
		if (!shopcast) return <div className={styles.aside} />

		const { isPlaying, enableProductPanel, enableDetailsPanel } = this.state
		const { relay, store } = this.props

		return (
			<PanelShopItems
				shopcast={{
					...shopcast,
					products: shopcast.shopcastItems.edges.map(
						(item) => item.node.product
					),
				}}
				relay={relay}
				store={store}
				emptyButton={{
					text: "Add Products",
					onClick: () => {
						this.setState({ enableAddProduct: true })
					},
				}}
				playControls={{
					isPlaying,
					playProduct: this.playProduct,
					pauseVideo: () => this.videoIsPlaying(false),
				}}
				getProductActive={(product) => {
					const { currentProduct } = this.state
					return currentProduct && product.id === currentProduct.id
				}}
				enableProductPanel={enableProductPanel}
				setEnableProductPanel={(value) =>
					this.setState({ enableProductPanel: value })
				}
				enableDetailsPanel={enableDetailsPanel}
				setEnableDetailsPanel={(value) =>
					this.setState({ enableDetailsPanel: value })
				}
				actions={this.getActionMenu()}
				refetchData={this.refetchData}
				clientId={this.clientId}
			/>
		)
	}

	render() {
		const { relay, store, loading, navigate, team } = this.props
		const { uploadProgressValue, enableAddProduct } = this.state
		const { shopcast, categories } = store || {}
		const {
			editing,
			currentPlayerTime,
			isPlaying,
			currentProduct,
			goToTime,
		} = this.state

		// if(!shopcast)
		//   return(<div><video src="https://www.radiantmediaplayer.com/media/bbb-360p.mp4"></video></div>)
		const domain =
			shopcast?.user?.customDomain ||
			`${shopcast?.user?.username}.${publicViewDomain}`
		return (
			<div className={enableAddProduct ? styles.rootEdit : styles.root}>
				{shopcast && (
					<Helmet>
						<title>
							{shopcast.user.fullname} – {shopcast.title}
						</title>
						<meta
							name="description"
							content={
								shopcast.description
									? shopcast.description
									: `Video Shopping with ${shopcast.user.fullname}`
							}
						/>
						<meta itemProp="name" content={shopcast.title} />
						<meta
							itemProp="description"
							content={
								shopcast.description
									? shopcast.description
									: `Video Shopping with ${shopcast.user.fullname}`
							}
						/>
						<meta itemProp="image" content={shopcast.posterUrl} />
						<meta property="og:title" content={shopcast.title} />
						<meta
							property="og:description"
							content={
								shopcast.description
									? shopcast.description
									: `Video Shopping with ${shopcast.user.fullname}`
							}
						/>
						<meta property="og:image" content={shopcast.posterUrl} />
						<meta
							property="og:url"
							content={`https://${domain}/shopcast/${shopcast.id}`}
						/>
						<meta name="twitter:title" content={shopcast.title} />
						<meta
							name="twitter:description"
							content={
								shopcast.description
									? shopcast.description
									: `Video Shopping with ${shopcast.user.fullname}`
							}
						/>
						<meta name="twitter:image" content={shopcast.posterUrl} />
						<meta name="twitter:site" content="@shopshare_tv" />
						{shopcast.user.socialLinks && (
							<meta
								name="twitter:creator"
								content={`@${shopcast.user.socialLinks.twitterUsername}`}
							/>
						)}
						<link
							rel="alternate"
							type="application/json+oembed"
							href={`https://shopshare.tv/api/shopcast/oembed?url=${encodeURIComponent(
								`https://shopshare.tv/shopcast/${shopcast.id}`
							)}&format=json`}
							title={shopcast.title}
						/>
					</Helmet>
				)}
				<ScrollToTopOnMount />
				{shopcast && editing && (
					<ShopcastEdit
						close={this.endEdit}
						shopcast={shopcast}
						relay={relay}
						categories={categories}
					/>
				)}

				<div className={styles.media}>
					{uploadProgressValue > 0 && (
						<div className={styles.progress}>
							<p className={styles.progressStatus}>Uploading Shopcast</p>
							{shopcast && (
								<h4 className={styles.progressTitle}>
									{shopcast.title}
								</h4>
							)}
							<progress
								className={styles.progressBar}
								max={100}
								value={uploadProgressValue}
							/>
						</div>
					)}

					{shopcast &&
						!uploadProgressValue &&
						(shopcast.user.canEdit || !shopcast.isFlagged) && (
							<Player
								setRef={(ref) => {
									this.player = ref
								}}
								shopcast={shopcast}
								currentPlayerTime={currentPlayerTime}
								isPlaying={isPlaying}
								videoPlayTimeChanged={this.videoPlayTimeChanged}
								currentProduct={currentProduct}
								goToTime={goToTime}
								store={store}
								subtitle={shopcast?.subtitle || null}
								videoIsPlaying={this.videoIsPlaying}
								currentUser={store.currentUser}
								relay={relay}
								storeId={store.id}
								enableAddProduct={enableAddProduct}
								triggerProductPanel={() => {
									this.setState((state) => ({
										enableProductPanel: !state.enableProductPanel,
									}))
								}}
								triggerDescriptionPanel={() => {
									this.setState((state) => ({
										enableDetailsPanel: !state.enableDetailsPanel,
									}))
								}}
								extra={{
									getActionMenu: this.getActionMenu,
									goBack: () => navigate(-1),
								}}
							/>
						)}

					{shopcast && !shopcast.user.canEdit && shopcast.isFlagged && (
						<div className={styles.message}>
							<div className={styles.video}>
								This video has been flagged as inappropriate.
							</div>
						</div>
					)}

					{!loading && !shopcast && (
						<div className={styles.notAvaliable}>
							<div className={empty.simple}>
								<ExclamationTriangleIcon className={empty.iconSml} />
								This video is unavailable.
							</div>
						</div>
					)}

					{loading && (
						<div className={styles.notAvaliable}>
							<div className={empty.simple}>
								<LoadingDots />
							</div>
						</div>
					)}
				</div>
				{shopcast && !enableAddProduct && this.renderProducts(shopcast)}
				{enableAddProduct && (
					<AddProducts
						shopcastId={shopcast.id}
						setSelectedProductForEdit={(value) => {
							const { played, duration } = this.player.getPlayerState()
							this.addSelectedProductToStore({
								id: value,
								start: duration * played,
								end: duration * played + (1 / 100) * duration,
							})
						}}
						relay={relay}
						store={store}
						teamId={team}
					/>
				)}
				{enableAddProduct && (
					<div className={styles.footer}>
						<button
							type="button"
							className={buttons.primary}
							onClick={() => {
								this.setState({
									enableAddProduct: false,
								})
								this.refetchData()
							}}
						>
							Cancel
						</button>
						<button
							type="button"
							className={buttons.secondary}
							onClick={() => {
								this.saveProducts()
							}}
						>
							<CheckIcon className={buttons.icon} />
							Finish
						</button>
					</div>
				)}
			</div>
		)
	}
}

const query = graphql`
	query shopcastQuery($id: String, $clientId: String, $teamId: String) {
		store {
			...shopcast_store @arguments(id: $id, clientId: $clientId, teamId: $teamId)
		}
	}
`

Shopcast = createRefetchContainer(
	Shopcast,
	{
		store: graphql`
			fragment shopcast_store on Store
			@argumentDefinitions(
				id: { type: "String" }
				clientId: { type: "String" }
				teamId: { type: "String" }
			) {
				id
				categories {
					name
					id
				}
				shopcast(id: $id) {
					id
					title
					description
					views
					likes
					hasCurrentUserLikedIt
					createdDate
					category {
						id
					}
					team {
						id
						name
						displayName
						avatar
						displayAvatar
					}
					transactions {
						id
						network
						sales
						totalCommission
						currency
						product {
							id
							title
						}
						productNames
						transactionDate
						advertiserName
						orderId
						network
					}
					user {
						id
						username
						profilePhoto
						bannerPhoto
						fullname
						canEdit
						customDomain
						isFollowing
						socialLinks {
							website
							instagramUsername
							facebookUrl
							twitterUsername
							youtubeUrl
							pinterestUrl
						}
						productCollections(teamId: $teamId) {
							edges {
								node {
									id
									title
									isDefault
									isACloset
									isPublished
									client {
										id
										fullname
									}
								}
							}
						}
					}
					duration
					muxedUrl
					subtitle
					mp4Url
					posterUrl
					isFlagged
					isPublished
					isRecommended
					shopcastItems(first: 100)
						@connection(
							key: "shopcast_shopcastItems"
							filters: ["first"]
						) {
						edges {
							node {
								id
								product {
									id
									title
									brand
									description
									url
									affiliated_url(type: "shopcast")
									custom_affiliated_url
									imageUrl
									diffbotImageUrl
									regularPrice
									offerPrice
									error
									productImages
									hasProductImagesFromDiffbot
									productCollections {
										id
										title(ownershipCheck: true)
										isACloset
										isPublished
									}
									isOwnedItems(clientId: $clientId)
								}
								start
								end
							}
						}
					}
				}
				currentUser {
					id
					adminAccess
					role
					userClients(teamId: $teamId) {
						edges {
							node {
								id
								fullname
								email
							}
						}
					}
				}
			}
		`,
	},
	query
)

export default {
	Component: withRouter(Shopcast),
	query,
	params: (rp) => {
		const qs = queryString.parse(rp.location.search)
		return ({ id: rp.params.id, clientId: qs?.clientId, teamId: rp.team })
	},
	showLoading: true,
}
