import React, { Component } from "react"
import { Helmet } from "react-helmet"

import VideoCollectionYoutube from "components/video-collection/video-collection-youtube"
import ScheduleADemo from "components/schedule-a-demo/schedule-a-demo"
import trackingService from "services/tracking-service"
import styles from "./dashboard.css"


class Dashboard extends Component {
	constructor(props) {
		super(props)
		this.state = {}
	}

	componentDidMount() {
		trackingService.track("Viewed Dashboard", {})
	}


	render() {
		return (
			<div className={styles.root}>
				<Helmet>
					<title>Dashboard</title>
				</Helmet>
				<div className={styles.masthead}>
					<div className={styles.inner}>
						<div className={styles.demo}>
							<h1 className={styles.title}>Watch & Learn</h1>
							<p className={styles.lead}>
								In these 1-3 minute videos, we cover everything
								ShopShare! Transforming you from a total beginner into a
								ShopShare pro!
							</p>
							<ScheduleADemo theme="dark" />
						</div>
					</div>
				</div>
				<VideoCollectionYoutube />
			</div>
		)
	}
}

export default { Component: Dashboard, needAuth: true }
