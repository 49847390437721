import React, { useState, Fragment } from "react"
import { toast } from "react-toastify"
import { createFragmentContainer, graphql } from "react-relay"
import Dropzone from "react-dropzone"
import { CameraIcon } from "@radix-ui/react-icons"
import textfield from "shared/styles/components/textfield.css"
import button from "shared/styles/components/buttons.css"
import contentCard from "shared/styles/layout/content-card.css"
import form from "shared/styles/components/form.css"
import stylesAvatar from "components/profile/avatar/avatar.css"
import icons from "shared/styles/components/icons.css"
// import LockIcon from "shared/assets/lock-icon.svg"
import PaypalLogo from "shared/assets/logo-paypal.svg"
import Avatar from "components/profile/avatar/avatar"
import ProfilePhotoMutation from "mutations/user/profile-photo"
import styles from "./update-personal-info.css"
import Loading from "../../loading/loading.jsx"

import UpdatePersonalInfoMutation from "../../../mutations/user/update-personal-info"
import RemoveVerificationRequestMutation from "../../../mutations/user/remove-verification-request"
import { validateForm } from "../../../helpers/ui-helper"
import gql from "../../../helpers/gql-helper"

function UpdatePersonalInfo({ currentUser: user, relay, store }) {
	if (!user) return null

	const [updating, setUpdating] = useState(false)
	const [errors] = useState(null)
	const [, setIsValid] = useState(true)
	const [file, setFile] = useState(user.profilePhoto)
	let formElement = null
	let fullName = null
	let email = null
	let paypalEmail = null

	function onUpdateSuccess() {
		toast.success(<Fragment>Updated successfully.</Fragment>, {
			autoClose: 2000,
			closeButton: false,
		})
		setUpdating(false)
	}

	function onUpdateFailure(e) {
		setUpdating(false)
		toast.error(
			gql.getError(e) || "Error updating account details.",
			{ autoClose: 2000, closeButton: false }
		)
	}

	function commitUpdates(e) {
		e.preventDefault()
		if (!validateForm(formElement)) {
			return setIsValid(false)
		}
		setUpdating(true)
		setIsValid(true)
		const personalInfoPromise = new Promise((resolve, reject) => {
			UpdatePersonalInfoMutation.commit(
				relay.environment,
				fullName.value,
				user.bio || "",
				email.value,
				user.displayName,
				paypalEmail?.value || user.paypalEmail || "",
				store.id,
				resolve,
				reject
			)
		})
		const promises = [personalInfoPromise]
		if (file !== user.profilePhoto) {
			const profilePhotoPromise = new Promise((resolve, reject) => {
				ProfilePhotoMutation.commit(
					relay.environment,
					file,
					user.id,
					false,
					resolve,
					reject
				)
			})
			promises.push(profilePhotoPromise)
		}
		Promise.all(promises).then(onUpdateSuccess).catch(onUpdateFailure)
		email.value = user.email
		return null
	}

	function removeVerificationRequest() {
		RemoveVerificationRequestMutation.commit(
			relay.environment,
			() => {
				toast.success(<Fragment>Verification request removed</Fragment>, {
					autoClose: 2000,
					closeButton: false,
				})
			},
			() => {}
		)
	}

	return (
		<form
			ref={(ref) => {
				formElement = ref
			}}
			name="updatePersonalInfo"
		>
			<div className={contentCard.root}>
				{errors && errors.length && (
					<Fragment>
						{errors.map((e) => (
							<p key={e}>{e}</p>
						))}
					</Fragment>
				)}
				<div className={contentCard.header}>
					<h4 className={contentCard.title}>Profile</h4>
					{file && (
						<button
							type="button"
							className={`${button.primaryRedLink} ${button.small}`}
							onClick={() => {
								setFile(null)
							}}
						>
							Remove
						</button>
					)}
				</div>
				<div className={contentCard.content}>
					{updating && <Loading />}
					<div className={styles.avatar}>
						<Dropzone
							multiple={false}
							onDrop={(acceptedFiles) => setFile(acceptedFiles[0])}
						>
							{({ getRootProps, getInputProps }) => (
								<div className={styles.avatarDrop}>
									<div
										{...getRootProps()}
										className={styles.avatarDropIcon}
									>
										<input {...getInputProps()} />
										<CameraIcon className={icons.icon18} />
									</div>
									<Avatar
										className={styles.avatar}
										styleName={stylesAvatar.image}
										size="200px"
										photoUrl={
											file && typeof file === "object"
												? URL.createObjectURL(file)
												: file
										}
										fullname={user.fullname}
									/>
								</div>
							)}
						</Dropzone>
					</div>
					<p className={styles.bannerRecSize}>
						Recommended size: <b>512x512px</b>
					</p>
					<div className={form.group}>
						<label className={form.label}>
							{user.isRetailer ? "Company Name" : "Name"}
						</label>
						<input
							className={textfield.normal}
							type="text"
							defaultValue={user.fullname}
							required
							ref={(input) => {
								fullName = input
							}}
						/>
						<div className={form.validationFeedback} />
					</div>
					<div className={form.group}>
						<label className={form.label}>
							{user.isRetailer ? "Work Email" : "Email"}
						</label>
						<div className={form.inputGroup}>
							<input
								className={textfield.normal}
								type="email"
								required
								defaultValue={user.email}
								ref={(input) => {
									email = input
								}}
							/>
						</div>

						<div className={form.validationFeedback} />
						{user.verificationInProgress && (
							<div className={styles.verify}>
								<p className={styles.verifyMessage}>
									Check your email to confirm{" "}
									{user.verificationInProgress}
								</p>
								<button
									type="button"
									className={`${button.primaryRedOutline} ${button.small}`}
									onClick={removeVerificationRequest}
								>
									Remove
								</button>
							</div>
						)}
					</div>
					{user.type !== "CLIENT" && (
						<div className={form.group}>
							<label className={form.label}>Paypal Email</label>
							<div className={form.inputGroup}>
								<div className={styles.paypal}>
									<PaypalLogo className={styles.logo} />
								</div>
								<input
									className={textfield.normal}
									type="email"
									defaultValue={user.paypalEmail}
									ref={(input) => {
										paypalEmail = input
									}}
								/>
							</div>
							<p className={form.help}>
								ShopShare uses your PayPal email to pay out any
								commissions or referral fees you earn.
							</p>
							<div className={form.validationFeedback} />
						</div>
					)}
				</div>
				<div className={contentCard.footer}>
					<button
						type="submit"
						className={button.primary}
						onClick={commitUpdates}
					>
						Save
					</button>
				</div>
			</div>
		</form>
	)
}

const UpdatePersonalInfoComp = createFragmentContainer(UpdatePersonalInfo, {
	currentUser: graphql`
		fragment updatePersonalInfo_currentUser on User {
			id
			displayName
			fullname
			username
			profilePhoto
			canEdit
			bio
			email
			paypalEmail
			type
			isRetailer
			isVerified
			socialLinks {
				website
				instagramUsername
				facebookUrl
				twitterUsername
				youtubeUrl
				pinterestUrl
			}
			verificationInProgress
		}
	`,
})

export default UpdatePersonalInfoComp
