import { commitMutation, graphql } from "react-relay"
import { complete } from "../helper.js"

const mutation = graphql`
	mutation deleteLookbookBoardMutation($input: DeleteBoardInput!) {
		deleteBoard(input: $input) {
			status
		}
	}
`

const commit = (env, boardId, onSuccess, onError) =>
	commitMutation(env, {
		mutation,
		variables: { input: { boardId } },
		onCompleted: complete(onSuccess, onError),
		onError: () => complete(null, onError),
	})

export default { commit }
