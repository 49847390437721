import { commitMutation, graphql } from "react-relay"
import { complete } from "../helper.js"

const mutation = graphql`
	mutation updateRakutenSitesMutation($input: UpdateRakutenSitesInput!) {
		updateRakutenSites(input: $input) {
			store {
				...rakutenSites_store
			}
			addedCount
			updatedCount
		}
	}
`

const commit = (env, storeId, onDone, onError) =>
	commitMutation(env, {
		mutation,
		variables: { input: {} },
		onCompleted: complete(() => {}, onError),
		onError: (e) => complete(null, [e]),
		updater: (store, data) => {
			if (data.updateRakutenSites) {
				const root = store.getRootField("updateRakutenSites")
				const resultStore = root.getLinkedRecord("store")
				const s = store.get(storeId)
				s.getLinkedRecord(
					"rakutenSites",
					resultStore.getLinkedRecord("rakutenSites")
				)
				onDone({
					added: root.getValue("addedCount"),
					updated: root.getValue("updatedCount"),
				})
			}
		},
	})

export default { commit }
