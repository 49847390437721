import React, { Component } from "react"
import { toast } from "react-toastify"
import { Tree } from "react-arborist"
import { ChevronRightIcon, Cross2Icon } from "@radix-ui/react-icons"
import FolderIcon from "shared/assets/folder-icon.svg"
import FolderShareIcon from "shared/assets/folder-share-icon.svg"
import FolderLookbookIcon from "shared/assets/folder-lookbook-icon.svg"
import Avatar from "components/profile/avatar/avatar"
import getFolderTreeMutation from "mutations/shopcast-manager/get-folder-tree"
import form from "shared/styles/components/form.css"
import styles from "./move-to-folder.css"

export function Node({ style, node }) {
	const className = node.data.children ? styles.folder : styles.folderNoArrow
	const renderIcon = () => {
		if (node.data.isClient)
			return (
				<Avatar styleName={`${styles.avatar} ${className}`} size="56px" />
			)
		if (!node.data.isShared) return <FolderIcon className={className} />
		if (!node.data.isParentAClient)
			return <FolderShareIcon className={className} />
		return <FolderLookbookIcon className={className} />
	}
	return (
		<div
			role="button"
			onClick={(e) => {
				node.handleClick(e)
				node.data.onTreeViewToggle(node.data.id)
			}}
			style={style}
			aria-hidden
		>
			<div
				className={node.data.isActive ? styles.itemSelected : styles.item}
			>
				{node.data.children && node.data.children.length > 0 ? (
					<div
						aria-hidden
						className={styles.arrow}
						onClick={() => node.toggle()}
					>
						<ChevronRightIcon
							className={node.isOpen ? styles.iconOpen : styles.icon}
						/>
					</div>
				) : (
					<div aria-hidden className={styles.arrow} />
				)}
				<div className={styles.content}>
					{renderIcon()}
					<span className={styles.label}>
						{node.data.name} {node.data.isClient ? "(Client)" : null}
						{node.data.isActive ? (
							<button
								type="button"
								className={styles.clear}
								data-tooltip-id="tooltip"
								data-tooltip-place="left"
								data-tooltip-content="Unselect"
								onClick={(e) => {
									e.stopPropagation()
									node.data.onTreeViewToggle(null)
								}}
							>
								<Cross2Icon className={styles.clearIcon} />
							</button>
						) : null}
					</span>
				</div>
			</div>
		</div>
	)
}

class MoveToFolderModal extends Component {
	constructor(props) {
		super(props)
		this.state = {
			folderTree: null,
		}
	}

	componentDidMount() {
		this.getFolderTreeData()
	}

	componentDidUpdate(prevProps) {
		const { treeViewSelectedFolder, clientId } = this.props
		const { folderTree } = this.state
		if (prevProps.treeViewSelectedFolder !== treeViewSelectedFolder) {
			const item = this.findNested(
				folderTree,
				(data) => data.id === treeViewSelectedFolder
			)
			const prevSelectedItem = this.findNested(
				folderTree,
				(data) => data.isActive
			)
			if (prevSelectedItem) prevSelectedItem.isActive = false
			if (item) item.isActive = true
			this.setState({ folderTree: { ...folderTree } })
		}

		if (prevProps.clientId !== clientId) {
			this.getFolderTreeData()
		}
	}

	getFolderTreeData() {
		const {
			relay,
			skipFrom,
			treeViewSelectedFolder,
			hideRoot,
			onTreeViewToggle,
			clientId,
			showAll,
			team
		} = this.props
		window.setActiveFolder = () => {}
		getFolderTreeMutation.commit(
			relay.environment,
			team,
			(tree) => {
				if (skipFrom) {
					const findNestedAndDelete = (data) => {
						if (!data.children) return
						const changedData = data
						changedData.children = data.children.filter((child) => {
							if (skipFrom.length === 0) return true
							if (skipFrom.includes(child.id)) {
								skipFrom.splice(skipFrom.indexOf(child.id), 1)
								return false
							}
							findNestedAndDelete(child)
							return true
						})
					}
					findNestedAndDelete(tree)
				}
				this.addFlagsToEveryItem(tree, "onTreeViewToggle", onTreeViewToggle)
				const item = this.findNested(
					tree,
					(data) => data.id === treeViewSelectedFolder,
					(data) => {
						data.isOpen = true
					}
				)
				if (item) item.isActive = true
				if (hideRoot) tree.isOpen = true
				if (clientId) {
					const client = tree.children.find(
						(child) => child.clientId === clientId
					)
					client.isOpen = true
					this.setState({ folderTree: client })
					return
				}
				if (!showAll)
					tree.children = tree.children.filter((child) => !child.isClient)
				this.setState({ folderTree: tree })
			},
			() => {
				toast.error(<>Could not get folders</>, {
					autoClose: 2000,
					closeButton: false,
				})
			}
		)
	}

	findNested(data, func, runAdditional = null) {
		if (func(data)) return data
		if (!data.children) return null
		for (let i = 0; i < data.children.length; i++) {
			const child = data.children[i]
			const found = this.findNested(child, func, runAdditional)
			if (found) {
				if (runAdditional) runAdditional(data)
				return found
			}
		}
		return null
	}

	addFlagsToEveryItem(data, key, flag) {
		data[key] = flag
		if (!data.children) return
		for (let i = 0; i < data.children.length; i++) {
			const child = data.children[i]
			this.addFlagsToEveryItem(child, key, flag)
		}
	}

	renderEmpty() {
		if (window.__isReactDndBackendSetUp)
			window.__isReactDndBackendSetUp = false
		return null
	}

	render() {
		const { folderTree } = this.state
		const { hideRoot, title } = this.props
		if (!folderTree) return this.renderEmpty()
		if (hideRoot && folderTree.children.length === 0)
			return this.renderEmpty()
		return (
			<>
				{title && <label className={form.label}>{title}</label>}
				<div className={styles.root}>
					<ul className={styles.actions}>
						<li>
							<button
								type="button"
								className={styles.link}
								onClick={() => {
									this.addFlagsToEveryItem(folderTree, "isOpen", true)
									this.setState({ folderTree: { ...folderTree } })
								}}
							>
								Expand All
							</button>
						</li>
						<li>
							<button
								type="button"
								className={styles.link}
								onClick={() => {
									this.addFlagsToEveryItem(folderTree, "isOpen", false)
									if (hideRoot) folderTree.isOpen = true
									this.setState({ folderTree: { ...folderTree } })
								}}
							>
								Collapse All
							</button>
						</li>
					</ul>
					<Tree
						data={[folderTree]}
						onToggle={(id, isOpen) => {
							const item = this.findNested(
								folderTree,
								(data) => data.id === id
							)
							item.isOpen = isOpen
							this.setState({ folderTree: { ...folderTree } })
						}}
						indent={21}
						rowHeight={32}
						openByDefault={false}
						className={styles.tree}
						hideRoot={hideRoot}
					>
						{Node}
					</Tree>
				</div>
			</>
		)
	}
}

export default MoveToFolderModal
