import React from "react"
import CloseIcon from "shared/assets/close-icon.svg"
import button from "shared/styles/components/buttons.css"
import styles from "./product-tray.css"

export default function ProductTray({ trayObjects, removeFromSideTray }) {
	return trayObjects.length === 0 ? (
		<div className={styles.emptyRoot}>
			<h5 className={styles.emptyMessage}>Add additional products here</h5>
		</div>
	) : (
		<div className={styles.root}>
			{trayObjects.map((x) => (
				<div className={styles.item} key={x.id}>
					<button
						className={styles.remove}
						type="button"
						onClick={() => {
							removeFromSideTray(x.id)
						}}
					>
						<CloseIcon className={button.closeIcon} />
					</button>
					<div className={styles.inner}>
						<img
							className={styles.img}
							src={x.imageUrl}
							alt="Additional Product"
						/>
					</div>
				</div>
			))}
		</div>
	)
}
