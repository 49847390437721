import React, { Component } from "react"
import { createFragmentContainer, graphql } from "react-relay"
import ReCAPTCHA from "react-google-recaptcha"
import { Link } from "react-router-dom"
import { Helmet } from "react-helmet"
import queryString from "query-string"
import textfield from "shared/styles/components/textfield.css"
import form from "shared/styles/components/form.css"
import custom from "shared/styles/components/custom.css"
import Logo from "shared/assets/logo.svg"
import Loading from "components/loading/loading.jsx"
import cookieService from "services/cookie-service"
import trackingService from "services/tracking-service"
import SignupMutation from "mutations/user/signup"
import gqlh from "helpers/gql-helper"
// import { parseQueryStringToDictionary } from "helpers/query-parameters-helper"
import { recaptchaSiteKey, subdomainValidationRegex } from "../../constants"
import auth from "./signup.css"
import { withRouter } from "../../route-helpers"

class Signup extends Component {
	constructor(props) {
		super(props)
		this.signUp = this.signUp.bind(this)
		this.signupFailure = this.signupFailure.bind(this)
		this.signupSuccessful = this.signupSuccessful.bind(this)
		this.setUsername = this.setUsername.bind(this)
		this.qs = queryString.parse(props.location.search)

		this.state = {
			signupError: null,
			showLoading: false,
			agree: false,
			type: this.qs.type || "STYLIST",
		}
	}

	componentDidMount() {
		const { qs } = this
		this.fullname.value = qs.fullname || ""
		this.setUsername()
		trackingService.track("Viewed Create Account", {})
	}

	setUsername() {
		const { type } = this.state
		if (type === "CLIENT") {
			const randomNumber = Math.floor(Math.random() * 100000)
			this.username.value = `client${randomNumber}`
		} else if (this.username.value.startsWith("client")) {
			this.username.value = ""
		}
	}

	sanitizeUsername(e) {
		e.target.value = e.target.value.replace(/ /g, "")
	}

	signupSuccessful(res) {
		this.setState({ showLoading: false })
		const { token } = res.signup.user
		cookieService.setToken(token)
		cookieService.removeRefferal()
		const { navigate, location } = this.props

		if (res.signup) {
			const user = res.signup.store.currentUser
			trackingService.addUserProperties({
				$name: user.fullname,
				$email: user.email,
			})
			trackingService.identify(user.username.toLowerCase())
			trackingService.track("Sign Up Successful", {
				Name: user.fullname,
				Email: user.email,
				Username: user.username,
			})

			if (window.FS) {
				window.FS.identify(user.user_hash, {
					displayName: user.fullname,
					email: user.email,
				})
			}

			if (window.fbq) window.fbq("track", "CompleteRegistration")
			const qs = queryString.parse(location.search)
			if (qs.link) window.location.href = qs.link
			else if (qs.plan) navigate(`/pricing/${qs.plan}`)
			else if (user.type === "CLIENT") navigate("/wardrobe")
			else navigate("/dashboard")
		}
	}

	signupFailure(transaction) {
		this.setState({ showLoading: false })
		const errorMessage = gqlh.getError(
			transaction,
			"We're sorry, but we can't continue your registration to Shopshare right now. Please try again later."
		)
		this.setState({ signupError: errorMessage })
	}

	async signUp(e) {
		const { props } = this
		const { agree } = this.state
		if (
			this.username.validity.valid &&
			this.fullname.validity.valid &&
			this.password.validity.valid &&
			this.country.validity.valid &&
			agree
		) {
			e.preventDefault()
			if (!window.navigator.cookieEnabled) {
				this.setState({
					signupError:
						"Cookies are not enabled on your browser. Please enable your browser Cookie preferences to allow sites to save and read cookie data (recommended).",
				})
				return
			}
			const usernameValidity = subdomainValidationRegex.test(
				this.username.value
			)
			if (!usernameValidity) {
				this.setState({
					signupError:
						"Invalid Username. Only lowercase alphanumeric characters (a-z 0-9), hyphens and underscore are allowed.",
				})
				return
			}
			this.setState({ signupError: null, showLoading: true })
			this.recaptchaRef.reset()
			const recaptchaToken = await this.recaptchaRef.executeAsync()

			SignupMutation.commit(
				props.relay.environment,
				this.username.value,
				this.fullname.value,
				this.password.value,
				this.country.value,
				this.qs.type || this.type.value,
				agree,
				false,
				props.params.code,
				recaptchaToken,
				props.store.id,
				this.signupSuccessful,
				this.signupFailure
			)
		}
	}

	render() {
		const { state, props } = this
		const { type, agree } = this.state
		const isRetailer = type === "RETAILER"
		return (
			<div className={auth.root}>
				{state.showLoading && <Loading />}
				<Helmet>
					<title>Create a ShopShare Account</title>
					<body className="Auth" />
				</Helmet>
				<header className={auth.header}>
					<nav className={auth.navDesktop}>
						<div className={auth.navContainer}>
							<Link to="/">
								<Logo className={auth.logo} alt="logo" />
							</Link>
						</div>
					</nav>
				</header>

				<section className={auth.section}>
					<div className={auth.landing}>
						<div className={auth.content}>
							<div className={auth.card}>
								<div className={auth.cardHeader}>
									<h1 className={auth.title}>Create Your Account</h1>
								</div>

								{state.signupError && (
									<div className={form.error}>{state.signupError}</div>
								)}
								<form
									name="signup"
								>
									{!this.qs.type && (
										<div className={form.group}>
											<label className={form.label}>Type</label>
											<select
												className={textfield.normal}
												name="type"
												ref={(input) => {
													this.type = input
												}}
												onChange={() => {
													this.setState(
														{ type: this.type.value },
														() => this.setUsername()
													)
												}}
												required
											>
												<option value="STYLIST">Stylist</option>
												{/* <option value="CLIENT">Client</option> */}
												<option value="RETAILER">Retailer</option>
											</select>
											<div className={form.validationFeedback} />
										</div>
									)}
									<div className={form.group}>
										<label className={form.label}>
											{!isRetailer ? "Full Name" : "Company Name"}
										</label>
										<input
											type="text"
											name="fullname"
											placeholder="Jane Doe"
											ref={(input) => {
												this.fullname = input
											}}
											className={textfield.normal}
											required
										/>
									</div>
									<div className={form.group}>
										<label className={form.label}>
											{!isRetailer ? "Email" : "Work Email"}
										</label>
										<input
											type="email"
											name="email"
											value={props.store.emailVerificationToken}
											readOnly
											disabled
											className={textfield.normal}
										/>
									</div>
									<div
										className={form.group}
										style={{
											display: type === "CLIENT" ? "none" : "block",
										}}
									>
										<label className={form.label}>Username</label>
										<input
											type="text"
											name="username"
											ref={(input) => {
												this.username = input
											}}
											className={textfield.normal}
											placeholder="janedoe"
											onChange={this.sanitizeUsername}
											required
										/>
									</div>
									<div className={form.group}>
										<label className={form.label}>Password</label>
										<input
											type="password"
											name="password"
											placeholder="Type a password"
											ref={(input) => {
												this.password = input
											}}
											className={textfield.normal}
											required
										/>
									</div>
									<div className={form.group}>
										<label className={form.label}>Country</label>
										<select
											className={textfield.normal}
											name="country"
											ref={(input) => {
												this.country = input
											}}
											required
										>
											<option key="1" value="">
												Select Country
											</option>
											{props.store.countries.edges.map((c) => (
												<option key={c.node.id} value={c.node.id}>
													{c.node.name}
												</option>
											))}
										</select>
										<div className={form.validationFeedback} />
									</div>
									<div className={auth.required}>
										<div className={form.group}>
											<input
												className={custom.checkbox}
												type="checkbox"
												onChange={(event) => {
													this.setState({
														agree: event.target.checked,
													})
												}}
												id="agree"
											/>
											<label
												className={custom.label}
												htmlFor="agree"
											>
												I agree to the{" "}
												<Link to="/terms">T&C&apos;s</Link> and{" "}
												<Link to="/privacy">Privacy Policy</Link>
											</label>
										</div>
									</div>
									<ReCAPTCHA
										ref={(ref) => {
											this.recaptchaRef = ref
										}}
										size="invisible"
										sitekey={recaptchaSiteKey}
									/>
									<button
										type="submit"
										className={auth.btnCreate}
										onClick={this.signUp}
										disabled={!agree}
									>
										Continue
									</button>
								</form>
							</div>
							<div className={auth.footer}>
								<p className={auth.disclaimer}>
									You&apos;re currently logged in as{" "}
									{props.store.emailVerificationToken}. If you
									don&apos;t intend to set up a new account,{" "}
									<Link to="/signin">
										you can log in as a different user.
									</Link>
								</p>
							</div>
						</div>
					</div>
				</section>
			</div>
		)
	}
}

Signup = createFragmentContainer(Signup, {
	store: graphql`
		fragment signup_store on Store
		@argumentDefinitions(code: { type: "String!" }) {
			id
			countries(first: 1000) {
				edges {
					node {
						name
						id
					}
				}
			}
			pricingPlans {
				id
				amount
			}
			currentUser {
				id
				token
			}
			emailVerificationToken(token: $code)
		}
	`,
})

const signupQuery = graphql`
	query signupQuery($code: String!) {
		store {
			...signup_store @arguments(code: $code)
		}
	}
`

export default {
	Component: withRouter(Signup),
	query: signupQuery,
	requiredNoAuth: true,
	params: (rp) => ({ code: rp.params.code }),
}
