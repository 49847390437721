import qstring from "query-string"

export default function parseQueryStringToDictionary({ queryString }) {
	const dictionary = {}

	// remove the '?' from the beginning of the
	// if it exists
	if (queryString.indexOf("?") === 0) {
		queryString.substr(1)
	}

	// Step 1: separate out each key/value pair
	const parts = queryString.split("&amp;")

	for (let i = 0; i < parts.length; i++) {
		const p = parts[i]
		// Step 2: Split Key/Value pair
		const keyValuePair = p.split("=")

		// Step 3: Add Key/Value pair to Dictionary object
		const key = keyValuePair[0]
		let value = keyValuePair[1]

		// decode URI encoded string
		value = decodeURIComponent(value)
		value = value.replace(/\+/g, " ")

		dictionary[key] = value
	}

	// Step 4: Return Dictionary Object
	return dictionary
}

export const addVersioning = (url, additionalParams) => {
	if (url) {
		const splits = url.split("?")
		if (splits.length > 1) {
			const qs = qstring.parse(splits[1])
			if (Object.keys(qs).length > 0) return url
		}
		return `${splits[0]}?${additionalParams || ""}`
	}
	return url
}

export const isValidUrl = (urlString) => {
	let url = ""
	try {
		url = new URL(urlString)
	} catch (_) {
		return false
	}

	return url.protocol === "http:" || url.protocol === "https:"
}
