/**
 * @generated SignedSource<<6dda2c5542d30933795e79237c0944f5>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Mutation } from 'relay-runtime';
export type ToggleShopcastPublishedInput = {|
  clientMutationId?: ?string,
  shopcastId: string,
|};
export type toggleShopcastPublishedMutation$variables = {|
  input: ToggleShopcastPublishedInput,
|};
export type toggleShopcastPublishedMutation$data = {|
  +toggleShopcastPublished: ?{|
    +output: ?string,
  |},
|};
export type toggleShopcastPublishedMutation = {|
  response: toggleShopcastPublishedMutation$data,
  variables: toggleShopcastPublishedMutation$variables,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "ToggleShopcastPublishedPayload",
    "kind": "LinkedField",
    "name": "toggleShopcastPublished",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "output",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "toggleShopcastPublishedMutation",
    "selections": (v1/*: any*/),
    "type": "ShopshareMutationRoot",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "toggleShopcastPublishedMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "899dc29052a69aff2d9766a4ea55c6cf",
    "id": null,
    "metadata": {},
    "name": "toggleShopcastPublishedMutation",
    "operationKind": "mutation",
    "text": "mutation toggleShopcastPublishedMutation(\n  $input: ToggleShopcastPublishedInput!\n) {\n  toggleShopcastPublished(input: $input) {\n    output\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "5c2fd37b2a0e423db908028ae255033d";

module.exports = ((node/*: any*/)/*: Mutation<
  toggleShopcastPublishedMutation$variables,
  toggleShopcastPublishedMutation$data,
>*/);
