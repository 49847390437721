import React, { Component } from "react"
import buttons from "shared/styles/components/buttons.css"
import empty from "shared/styles/components/empty.css"
import ShopCard from "components/cards/shop-card/shop-card"

class ListOfShopItems extends Component {
	getProductElement = (product) => {
		const {
			getProductError,
			getProductActive,
			playControls,
			getProductActions,
			transactions,
			enableStats,
			setShowTransactions
		} = this.props

		const productError = getProductError(product)
		if (productError)
			return <div className={empty.simpleSml}>{productError}</div>

		return (
			<ShopCard
				key={product.id}
				setShowTransactions={setShowTransactions}
				product={product}
				enableStats={enableStats}
				isActive={getProductActive(product)}
				playControls={playControls}
				productActions={getProductActions(product)}
				error={product.error}
				transactions={transactions.filter(
					(t) => t.product.id === product.id
				)}
			/>
		)
	}

	render() {
		const { products, emptyButton } = this.props

		if (!products || products.length === 0)
			return (
				<div className={empty.simpleSml} key="0">
					<p>No Featured Products.</p>
					{emptyButton && (
						<button
							type="button"
							className={buttons.primary}
							onClick={emptyButton.onClick}
						>
							{emptyButton.text}
						</button>
					)}
				</div>
			)

		return products.map((node) => this.getProductElement(node))
	}
}

export default ListOfShopItems
