/**
 * @generated SignedSource<<1af60b63efa462bef14dfe90a520cee1>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentType } from "relay-runtime";
declare export opaque type resetPassword_store$fragmentType: FragmentType;
export type resetPassword_store$data = {|
  +id: string,
  +$fragmentType: resetPassword_store$fragmentType,
|};
export type resetPassword_store$key = {
  +$data?: resetPassword_store$data,
  +$fragmentSpreads: resetPassword_store$fragmentType,
  ...
};
*/

var node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "resetPassword_store",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "type": "Store",
  "abstractKey": null
};

(node/*: any*/).hash = "5ca0afb54f81059f749fa16afd08645b";

module.exports = ((node/*: any*/)/*: Fragment<
  resetPassword_store$fragmentType,
  resetPassword_store$data,
>*/);
