import { commitMutation, graphql } from "react-relay"
import { complete } from "../helper"

const mutation = graphql`
	mutation referralPayoutSpecificUsersMutation(
		$input: AdminUserPayoutCommissionInput!
	) {
		adminUserPayoutCommission(input: $input) {
			store {
				id
			}
		}
	}
`

const commit = (env, userIds, onError, onCompleted) =>
	commitMutation(env, {
		mutation,
		variables: { input: { userIds } },
		onError: complete(onCompleted, onError),
		onCompleted: complete(onCompleted, onError),
	})

export default { commit }
