import React from "react"
import VolumeIcon from "shared/assets/volume-icon.svg"
import MuteIcon from "shared/assets/mute-icon.svg"
import styles from "./volume-button.css"

export default function Volume({ isMute, toggleMute }) {
	let icon = <VolumeIcon className={styles.icon} />
	if (isMute === true) {
		icon = <MuteIcon className={styles.icon} />
	}
	return (
		<button
			type="button"
			className={styles.button}
			onClick={() => toggleMute()}
		>
			{icon}
		</button>
	)
}
