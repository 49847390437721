import React from "react"
import { createFragmentContainer, graphql } from "react-relay"

import nav from "shared/styles/components/nav.css"
import layout from "shared/styles/components/layout.css"

import ListOfRetailers from "components/list-of-retailers/list-of-retailers.jsx"
import { getComponent } from "../../route-helpers.jsx"
import styles from "./retailers.css"

class Retailers extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			selectedCategory: "all",
		}
		this.handleTabClick = this.handleTabClick.bind(this)
	}

	handleTabClick(category) {
		this.setState({ selectedCategory: category.id })
	}

	renderRetailer() {
		const { selectedCategory } = this.state
		const ListOfRetailersComp = getComponent(
			`list-of-retailers`,
			ListOfRetailers,
			selectedCategory === "all" ? { category: null, count: 12 } : { category: selectedCategory, count: 12 },
			selectedCategory === "all" ? { category: null } : { category: selectedCategory }
		)
		return {ListOfRetailersComp}
	}

	render() {
		const { props, state } = this
		const { bestCategories } = props.store
		const { selectedCategory } = state
		return (
			<div className={layout.containerWidth6}>
				<div className={styles.masthead}>
					<div className={styles.inner}>
						<h1 className={styles.title}>Retailers</h1>
					</div>
				</div>
				<div className={`${nav.rootHasBorder} ${nav.rootSticky}`}>
					<nav className={nav.tabs}>
						<div className={nav.tabsFit}>
							<a
								onClick={() => this.handleTabClick({ id: "all" })}
								role="link"
								tabIndex={0}
								onKeyPress={() => this.handleTabClick({ id: "all" })}
								className={
									selectedCategory === "all" ? nav.tabActive : nav.tab
								}
								key="all"
							>
								All&nbsp;
								<span className={nav.count}>
									({props.store.recommendedRetailers.edges.length})
								</span>
							</a>
							{bestCategories.edges.map((c) => (
								<a
									onClick={() => this.handleTabClick(c.node)}
									role="link"
									tabIndex={0}
									onKeyPress={() => this.handleTabClick(c.node)}
									className={
										selectedCategory === c.node.id
											? nav.tabActive
											: nav.tab
									}
									key={c.node.id}
								>
									{c.node.name}
									<span className={nav.count}>({c.node.count})</span>
								</a>
							))}
						</div>
					</nav>
				</div>
				<div className={styles.content}>{this.renderRetailer()}</div>
			</div>
		)
	}
}

Retailers = createFragmentContainer(Retailers, {
	store: graphql`
		fragment retailers_store on Store {
			id
			bestCategories(orderBy: "shopcastCount", isRetailer: true) {
				edges {
					node {
						name
						id
						count
					}
				}
			}
			recommendedRetailers(category: null) {
				edges {
					node {
						id
					}
				}
			}
		}
	`,
})

const retailersQuery = graphql`
	query retailersQuery {
		store {
			...retailers_store
		}
	}
`

export default { Component: Retailers, query: retailersQuery }
