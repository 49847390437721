import React from "react"
import lodash from "lodash"
import currencyData from "helpers/currencies.json"
import { getFormattedCurrency } from "helpers/string-helper"
import styles from "./stats-sales.css"

export default function StatsSales({ transactions, setShowTransactions }) {
	if (transactions.length === 0) return null
	const renderRow = (transaction) => {
		const cSymbol =
			currencyData[transaction.currency] &&
			currencyData[transaction.currency].symbol
		return (
			<div className={styles.tr} key={transaction.currency}>
				<div className={styles.td}>{transaction.currency}</div>
				<div className={styles.td}>{transaction.sales}</div>
				<div className={styles.td}>
					{getFormattedCurrency({
						cSymbol,
						amount: transaction.value || 0,
						selectedCurrency: transaction.currency,
					})}
				</div>
				<div className={styles.td}>
					{getFormattedCurrency({
						cSymbol,
						amount: transaction.commission || 0,
						selectedCurrency: transaction.currency,
					})}
				</div>
			</div>
		)
	}

	const groups = lodash.groupBy(transactions, "currency")
	const orderGroups = lodash.groupBy(
		transactions,
		(transaction) => transaction.orderId || transaction.id
	)
	const orderCount = Object.keys(orderGroups).length
	const tip = `${transactions.length} Sales from ${orderCount} Orders`
	return (
		<div className={styles.root}>
			<div className={styles.table}>
				<div className={styles.thead}>
					<div className={styles.tr}>
						<div className={styles.th}>Currency</div>
						<div className={styles.th}>Sales</div>
						<div className={styles.th}>Value</div>
						<div className={styles.th}>Comm.</div>
					</div>
				</div>
				<div className={styles.tbody}>
					{Object.keys(groups).map((key) =>
						renderRow({
							sales: groups[key].length,
							value: groups[key].reduce(
								(acc, cur) => acc + cur.sales,
								0
							),
							commission: groups[key].reduce(
								(acc, cur) => acc + cur.totalCommission,
								0
							),
							currency: key,
						})
					)}
				</div>
			</div>
			{transactions.length > 0 && (
				<div className={styles.meta}>
					<span className={styles.details}>{tip}</span>
					<a
						className={styles.link}
						onClick={() => setShowTransactions(transactions)}
						onKeyPress={() => setShowTransactions(transactions)}
						tabIndex={0}
						role="link"
					>
						View Transactions
					</a>
				</div>
			)}
		</div>
	)
}
