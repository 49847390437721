/**
 * @generated SignedSource<<e793a7609e545c57516a2e83debe0a5b>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Query } from 'relay-runtime';
import type { shoppableVideos_store$fragmentType } from "./shoppableVideos_store.graphql";
export type shoppableVideosQuery$variables = {||};
export type shoppableVideosQuery$data = {|
  +store: ?{|
    +$fragmentSpreads: shoppableVideos_store$fragmentType,
  |},
|};
export type shoppableVideosQuery = {|
  response: shoppableVideosQuery$data,
  variables: shoppableVideosQuery$variables,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "shoppableVideosQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Store",
        "kind": "LinkedField",
        "name": "store",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "shoppableVideos_store"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "ShopshareQueryRoot",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "shoppableVideosQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Store",
        "kind": "LinkedField",
        "name": "store",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "currentUser",
            "plural": false,
            "selections": [
              (v0/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "7db1d5df325afa571da7bec35b66a744",
    "id": null,
    "metadata": {},
    "name": "shoppableVideosQuery",
    "operationKind": "query",
    "text": "query shoppableVideosQuery {\n  store {\n    ...shoppableVideos_store\n    id\n  }\n}\n\nfragment shoppableVideos_store on Store {\n  id\n  currentUser {\n    id\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "397ed1576a0ae37d2605f6e8b84b27b4";

module.exports = ((node/*: any*/)/*: Query<
  shoppableVideosQuery$variables,
  shoppableVideosQuery$data,
>*/);
