import { commitMutation, graphql } from "react-relay"
import { ConnectionHandler } from "relay-runtime"
import { complete } from "../helper.js"
import connections from "../connections"

const mutation = graphql`
	mutation deleteProductMutation($input: DeleteProductInput!) {
		deleteProduct(input: $input) {
			store {
				id
				shopcast {
					id
				}
			}
		}
	}
`

const commit = (
	environment,
	shopcastId,
	productId,
	storeId,
	onCompleted,
	onError
) =>
	commitMutation(environment, {
		mutation,
		variables: { input: { shopcastId, productId } },
		onCompleted: complete(onCompleted, onError),
		onError: (e) => complete(null, [e]),
		updater: (storeProxy, data) => {
			if (data.deleteProduct) {
				const shopcastProxy = storeProxy.get(shopcastId)
				const productsItemsConnection = ConnectionHandler.getConnection(
					shopcastProxy,
					connections.shopcast.products.name,
					connections.shopcast.products.data
				)
				const recordProxies = productsItemsConnection.getLinkedRecords("edges").map(i=>i.getLinkedRecord("node"))
				const productItemProxy = recordProxies.find(i=>i.getLinkedRecord("product").getDataID()===productId)
				ConnectionHandler.deleteNode(productsItemsConnection, productItemProxy.getDataID())

				const lookbookProductsConnection = ConnectionHandler.getConnection(
					storeProxy
						.getRoot()
						.getLinkedRecord("store")
						.getLinkedRecord("currentUser"),
					"currentUser_lookbookProducts",
					{
						shopcastId,
					}
				)			
				if (lookbookProductsConnection) ConnectionHandler.deleteNode(lookbookProductsConnection, productId)
			}
		},
	})

export default { commit }
