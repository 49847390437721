/**
 * @generated SignedSource<<bf69c1ba6a23a75c71d216606cc9cd80>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Query } from 'relay-runtime';
import type { adminTransactionsReportList_admin$fragmentType } from "./adminTransactionsReportList_admin.graphql";
export type adminTransactionsReportListQuery$variables = {|
  count: number,
  currency?: ?string,
  cursor?: ?string,
  endDate?: ?string,
  network?: ?string,
  startDate?: ?string,
  users?: ?$ReadOnlyArray<?string>,
|};
export type adminTransactionsReportListQuery$data = {|
  +store: ?{|
    +id: string,
    +$fragmentSpreads: adminTransactionsReportList_admin$fragmentType,
  |},
|};
export type adminTransactionsReportListQuery = {|
  response: adminTransactionsReportListQuery$data,
  variables: adminTransactionsReportListQuery$variables,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "count"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "currency"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "cursor"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "endDate"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "network"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "startDate"
},
v6 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "users"
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v8 = {
  "kind": "Variable",
  "name": "currency",
  "variableName": "currency"
},
v9 = {
  "kind": "Variable",
  "name": "endDate",
  "variableName": "endDate"
},
v10 = {
  "kind": "Variable",
  "name": "network",
  "variableName": "network"
},
v11 = {
  "kind": "Variable",
  "name": "startDate",
  "variableName": "startDate"
},
v12 = {
  "kind": "Variable",
  "name": "users",
  "variableName": "users"
},
v13 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "cursor"
  },
  (v8/*: any*/),
  (v9/*: any*/),
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "count"
  },
  (v10/*: any*/),
  (v11/*: any*/),
  (v12/*: any*/)
],
v14 = [
  (v7/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "title",
    "storageKey": null
  }
],
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v16 = [
  (v7/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "adminTransactionsReportListQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Store",
        "kind": "LinkedField",
        "name": "store",
        "plural": false,
        "selections": [
          (v7/*: any*/),
          {
            "args": [
              {
                "kind": "Variable",
                "name": "count",
                "variableName": "count"
              },
              (v8/*: any*/),
              {
                "kind": "Variable",
                "name": "cursor",
                "variableName": "cursor"
              },
              (v9/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/),
              (v12/*: any*/)
            ],
            "kind": "FragmentSpread",
            "name": "adminTransactionsReportList_admin"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "ShopshareQueryRoot",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v2/*: any*/),
      (v4/*: any*/),
      (v1/*: any*/),
      (v5/*: any*/),
      (v3/*: any*/),
      (v6/*: any*/)
    ],
    "kind": "Operation",
    "name": "adminTransactionsReportListQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Store",
        "kind": "LinkedField",
        "name": "store",
        "plural": false,
        "selections": [
          (v7/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Admin",
            "kind": "LinkedField",
            "name": "admin",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v13/*: any*/),
                "concreteType": "SiteTransactionConnection",
                "kind": "LinkedField",
                "name": "siteTransactions",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "SiteTransactionEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "SiteTransactionObj",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v7/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "transactionId",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "RakutenSite",
                            "kind": "LinkedField",
                            "name": "rakutenSite",
                            "plural": false,
                            "selections": [
                              (v7/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "name",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "advertiserName",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "User",
                            "kind": "LinkedField",
                            "name": "user",
                            "plural": false,
                            "selections": [
                              (v7/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "fullname",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "username",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "profilePhoto",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "customDomain",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "ShopcastProduct",
                            "kind": "LinkedField",
                            "name": "product",
                            "plural": false,
                            "selections": (v14/*: any*/),
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": null,
                            "kind": "LinkedField",
                            "name": "item",
                            "plural": false,
                            "selections": [
                              (v15/*: any*/),
                              {
                                "kind": "InlineFragment",
                                "selections": (v14/*: any*/),
                                "type": "Shopcast",
                                "abstractKey": null
                              },
                              {
                                "kind": "InlineFragment",
                                "selections": (v14/*: any*/),
                                "type": "Lookbook",
                                "abstractKey": null
                              },
                              {
                                "kind": "InlineFragment",
                                "selections": (v16/*: any*/),
                                "type": "ProductCollection",
                                "abstractKey": null
                              },
                              {
                                "kind": "InlineFragment",
                                "selections": (v16/*: any*/),
                                "type": "ShopshareFile",
                                "abstractKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "sales",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "totalCommission",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "shopshareCut",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "transactionDate",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "currency",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "productNames",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "network",
                            "storageKey": null
                          },
                          (v15/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "cursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "endCursor",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasNextPage",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v13/*: any*/),
                "filters": [
                  "network",
                  "currency",
                  "startDate",
                  "endDate",
                  "users"
                ],
                "handle": "connection",
                "key": "admin_siteTransactions",
                "kind": "LinkedHandle",
                "name": "siteTransactions"
              },
              (v7/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "36c43786324012b5e6923f88e4ff3776",
    "id": null,
    "metadata": {},
    "name": "adminTransactionsReportListQuery",
    "operationKind": "query",
    "text": "query adminTransactionsReportListQuery(\n  $count: Int!\n  $cursor: String\n  $network: String\n  $currency: String\n  $startDate: String\n  $endDate: String\n  $users: [String]\n) {\n  store {\n    id\n    ...adminTransactionsReportList_admin_4kF5zH\n  }\n}\n\nfragment adminTransactionsReportList_admin_4kF5zH on Store {\n  admin {\n    siteTransactions(first: $count, after: $cursor, network: $network, currency: $currency, startDate: $startDate, endDate: $endDate, users: $users) {\n      edges {\n        node {\n          id\n          transactionId\n          rakutenSite {\n            id\n            name\n          }\n          advertiserName\n          user {\n            id\n            fullname\n            username\n            profilePhoto\n            customDomain\n          }\n          product {\n            id\n            title\n          }\n          item {\n            __typename\n            ... on Shopcast {\n              id\n              title\n            }\n            ... on Lookbook {\n              id\n              title\n            }\n            ... on ProductCollection {\n              id\n            }\n            ... on ShopshareFile {\n              id\n            }\n          }\n          sales\n          totalCommission\n          shopshareCut\n          transactionDate\n          currency\n          productNames\n          network\n          __typename\n        }\n        cursor\n      }\n      pageInfo {\n        endCursor\n        hasNextPage\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "50353660b016785b435c46fa734a3b22";

module.exports = ((node/*: any*/)/*: Query<
  adminTransactionsReportListQuery$variables,
  adminTransactionsReportListQuery$data,
>*/);
