import React, { Component } from "react"
import Modal from "react-modal"
import { Cross2Icon, PlusIcon, MinusIcon } from "@radix-ui/react-icons"
import updateLookbookShapesMutation from "mutations/lookbook/update-lookbook-shape"
import form from "shared/styles/components/form.css"
import textfield from "shared/styles/components/textfield.css"
import modal from "shared/styles/components/modal.css"
import button from "shared/styles/components/buttons.css"
import AutoSuggest from "../../auto-suggest-new/auto-suggest"
import LoadingDots from "../../loading-dots/loading-dots"

class ShapeUpload extends Component {
	constructor(props) {
		super(props)
		this.state = {
			uploading: false,
			subCategorySet: [],
			selected: null,
		}
		this.pathRef = []
		this.closeModal = this.closeModal.bind(this)
		this.upload = this.upload.bind(this)
		this.onSelect = this.onSelect.bind(this)
		this.setPath = this.setPath.bind(this)
	}

	componentDidMount() {
		const { subCategoryList } = this.props
		this.setState({
			subCategorySet:
				subCategoryList?.map((obj) => ({
					id: obj.id,
					value: obj.title,
				})) || [],
		})
	}

	onSelect(productCollectionsArray) {
		const { selected: stateModel, subCategorySet } = this.state
		const { selected: editModel } = this.props
		const selected = {
			id: stateModel?.id || editModel?.id,
			collection: stateModel?.collection || editModel?.collection || [],
			viewBox: stateModel?.viewBox || editModel?.viewbox,
			path: stateModel?.path || editModel?.path || [],
		}
		const newCollection = []
		productCollectionsArray.forEach((collection) => {
			if (collection.id.includes("new_")) {
				newCollection.push(collection)
			}
		})
		this.setState({
			selected: {
				id: selected?.id,
				viewBox: selected?.value,
				path: selected?.path || [],
				collection: productCollectionsArray,
			},
			subCategorySet: [...subCategorySet],
		})
	}

	setPath(value, x) {
		const { selected: stateModel } = this.state
		const { selected: editModel } = this.props
		const selected = {
			id: stateModel?.id || editModel?.id,
			collection: stateModel?.collection || editModel?.collection || [],
			viewBox: stateModel?.viewBox || editModel?.viewbox,
			path: stateModel?.path || editModel?.path || [],
		}
		const pathArray = selected?.path || []
		const exist = pathArray.findIndex((y) => y === value)
		if (exist >= 0) {
			this.pathRefNew.value = ""
			return
		}
		if (x >= 0 && value) {
			pathArray[x] = value
		} else if (x >= 0) {
			pathArray.splice(x, 1)
		} else if (value) {
			pathArray.push(value)
		}
		this.setState({
			selected: {
				id: selected?.id,
				viewBox: selected?.viewBox,
				path: pathArray,
				collection: selected?.collection || [],
			},
		})
		this.pathRefNew.value = ""
	}

	closeModal(count) {
		const { closeModal } = this.props
		this.setState({
			uploading: false,
			selected: null,
		})
		closeModal(count)
	}

	upload() {
		const { selected: stateModel } = this.state
		const { selected: editModel, currentCategory } = this.props
		const collection = stateModel?.collection || editModel?.collection || []
		const selected = {
			id: stateModel?.id || editModel?.id,
			collection: currentCategory
				? [...new Set([...collection, currentCategory])]
				: collection,
			viewBox: this.viewBox.value,
			path: stateModel?.path || editModel?.path || [],
		}
		if (this.pathRefNew.value) selected.path.push(this.pathRefNew.value)
		const { relay } = this.props
		if (
			!selected?.viewBox ||
			!selected?.collection ||
			selected.collection.length === 0 ||
			!selected?.path ||
			selected.path.length === 0
		) {
			return
		}
		this.setState({ uploading: true })

		updateLookbookShapesMutation.commit(
			relay.environment,
			{
				id: selected?.id,
				viewBox: selected.viewBox,
				collection: selected.collection[0].value,
				path: JSON.stringify(selected.path),
			},
			null,
			() => {
				this.closeModal(0)
			},
			() => {
				this.closeModal(1)
			}
		)
	}

	render() {
		const {
			openModal,
			closeModal,
			adminAccess,
			subCategoryList,
			currentCategory,
			...otherProps
		} = this.props
		const { selected: stateModel, uploading } = this.state
		const { selected: editModel } = this.props
		const collection = stateModel?.collection || editModel?.collection || []
		const selected = {
			id: stateModel?.id || editModel?.id,
			collection: currentCategory
				? [...new Set([...collection, currentCategory])]
				: collection,
			viewBox: stateModel?.viewBox || editModel?.viewbox,
			path: stateModel?.path || editModel?.path || [],
		}
		return (
			<Modal
				className={modal.root}
				overlayClassName={modal.overlay}
				isOpen={openModal}
				{...otherProps}
				ariaHideApp={false}
			>
				<div className={`${modal.dialog} ${modal.dialogCentered}`}>
					<div className={`${modal.content}`}>
						<div className={modal.header}>
							<h4 className={modal.title}>
								{editModel ? "Edit" : "Save"} Shape
							</h4>
							<button
								type="button"
								className={button.close}
								onClick={() => this.closeModal(0)}
							>
								<Cross2Icon className={button.closeIcon} />
							</button>
						</div>
						<div className={modal.body}>
							<div className={form.group}>
								<label className={form.label}>Category</label>
								<AutoSuggest
									result={selected?.collection || []}
									dataList={subCategoryList}
									onChange={this.onSelect}
									maxResults={1}
								/>
							</div>
							<div className={form.group}>
								<label className={form.label}>View Box</label>
								<input
									type="text"
									className={textfield.normal}
									ref={(ref) => {
										this.viewBox = ref
									}}
									maxLength={255}
									defaultValue={
										selected && selected?.viewBox
											? selected?.viewBox
											: ""
									}
									disabled={uploading}
								/>
							</div>

							<div className={form.group}>
								<label className={form.label}>Path</label>

								{selected?.path.map((path, x) => (
									<div key={path} className={form.inputGroup}>
										<input
											type="text"
											ref={(ref) => {
												this.pathRef[x] = ref
											}}
											className={textfield.normal}
											defaultValue={path}
											onKeyPress={({ key, target }) => {
												if (key !== "Enter") return
												this.setPath(target.value, x)
											}}
											onChange={(target) =>
												this.setPath(target.value, x)
											}
											disabled={uploading}
										/>
										<button
											className={button.default}
											type="button"
											onClick={() => this.setPath(null, x)}
										>
											<MinusIcon />
										</button>
										<button
											className={button.default}
											type="button"
											onClick={() =>
												this.setPath(this.pathRef[x], x)
											}
										>
											<PlusIcon />
										</button>
									</div>
								))}
								<div className={form.inputGroup}>
									<input
										type="text"
										ref={(ref) => {
											this.pathRefNew = ref
										}}
										className={textfield.normal}
										onKeyPress={({ key, target }) => {
											if (key !== "Enter") return
											this.setPath(target.value, 0)
										}}
										disabled={uploading}
									/>
									<button
										className={button.default}
										type="button"
										onClick={() =>
											this.setPath(this.pathRefNew.value)
										}
									>
										<PlusIcon />
									</button>
								</div>
							</div>
						</div>

						<div className={modal.footer}>
							<button
								type="button"
								className={button.link}
								onClick={() => this.closeModal(0)}
							>
								{uploading ? (
									<LoadingDots color="var(--gray300)" />
								) : (
									"Cancel"
								)}
							</button>
							<button
								type="submit"
								className={button.primary}
								onClick={this.upload}
							>
								{uploading ? (
									<LoadingDots color="var(--primary80)" />
								) : (
									"Save"
								)}
							</button>
						</div>
					</div>
				</div>
			</Modal>
		)
	}
}
export default ShapeUpload
