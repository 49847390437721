/**
 * @generated SignedSource<<164fa54df4da39ab3da0e30741330b32>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Mutation } from 'relay-runtime';
export type ToggleShopcastFolderSharingInput = {|
  clientMutationId?: ?string,
  id: string,
|};
export type toggleShopcastFolderSharingMutation$variables = {|
  input: ToggleShopcastFolderSharingInput,
|};
export type toggleShopcastFolderSharingMutation$data = {|
  +toggleShopcastFolderSharing: ?{|
    +isShared: ?boolean,
    +sharedUrl: ?string,
  |},
|};
export type toggleShopcastFolderSharingMutation = {|
  response: toggleShopcastFolderSharingMutation$data,
  variables: toggleShopcastFolderSharingMutation$variables,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "ToggleShopcastFolderSharingPayload",
    "kind": "LinkedField",
    "name": "toggleShopcastFolderSharing",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "sharedUrl",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "isShared",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "toggleShopcastFolderSharingMutation",
    "selections": (v1/*: any*/),
    "type": "ShopshareMutationRoot",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "toggleShopcastFolderSharingMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "1981478d20252f55267d4c73afcf9f1c",
    "id": null,
    "metadata": {},
    "name": "toggleShopcastFolderSharingMutation",
    "operationKind": "mutation",
    "text": "mutation toggleShopcastFolderSharingMutation(\n  $input: ToggleShopcastFolderSharingInput!\n) {\n  toggleShopcastFolderSharing(input: $input) {\n    sharedUrl\n    isShared\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "410c77dcfb8da4e51ba451dfb950a2e1";

module.exports = ((node/*: any*/)/*: Mutation<
  toggleShopcastFolderSharingMutation$variables,
  toggleShopcastFolderSharingMutation$data,
>*/);
