import { commitMutation, graphql } from "react-relay"
import { ConnectionHandler } from "relay-runtime"
import connections from "../connections"
import { complete } from "../helper.js"

const mutation = graphql`
	mutation createNewFolderMutation($input: CreateNewFolderInput!) {
		createNewFolder(input: $input) {
			createdFolder {
				...shopcastFolder_folder
			}
		}
	}
`

const commit = (env, name, parent, container, team, onSuccess, onError) =>
	commitMutation(env, {
		mutation,
		variables: { input: { parent, name, teamId: team } },
		updater: (storeProxy, data) => {
			if (data.createNewFolder.createdFolder) {
				const currentFolder = storeProxy.get(parent)
				const args = { search: "", teamId: team }
				currentFolder.setValue(
					(currentFolder.getValue("foldersCount", args) || 0) + 1,
					"foldersCount",
					args
				)
				const connection = connections.currentUser.shopcastManager.folders
				const foldersConnection = ConnectionHandler.getConnection(
					currentFolder,
					connection.name,
					{
						...connection.data,
						isShared: null,
					}
				)
				const newFolder = storeProxy.create(
					`ShopcastFolder:connection:${new Date().getTime()}`,
					"newFolder"
				)
				const itemProxy = storeProxy
					.getRootField("createNewFolder")
					.getLinkedRecord("createdFolder")
				newFolder.setLinkedRecord(itemProxy, "node")
				if (foldersConnection)
					ConnectionHandler.insertEdgeAfter(foldersConnection, newFolder)

				const isInRoot = itemProxy.getValue("inRoot")
				if (isInRoot) {
					const containerProxy = storeProxy.get(container)
					const newFolderRoot = storeProxy.create(
						`ShopcastFolder:rootFolders:${new Date().getTime()}`,
						"newFolder"
					)
					newFolderRoot.setLinkedRecord(itemProxy, "node")
					const rootFoldersConnection = ConnectionHandler.getConnection(
						containerProxy,
						connections.currentUser.shopcastManager.rootFolders.name,
						connections.currentUser.shopcastManager.rootFolders.data
					)
					ConnectionHandler.insertEdgeAfter(
						rootFoldersConnection,
						newFolderRoot
					)
				}
				currentFolder.setValue(currentFolder.getValue("count") + 1, "count")
			}
		},
		onCompleted: complete(onSuccess, onError),
		onError: (e) => complete(null, [e]),
	})

export default { commit }
