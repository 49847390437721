/**
 * @generated SignedSource<<3008196513dbe37ca76118f54f0adb3f>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Mutation } from 'relay-runtime';
export type UpdateLongBioInput = {|
  bio: string,
  clientMutationId?: ?string,
|};
export type updateLongBioMutation$variables = {|
  input: UpdateLongBioInput,
|};
export type updateLongBioMutation$data = {|
  +updateLongBio: ?{|
    +store: ?{|
      +currentUser: ?{|
        +id: string,
      |},
      +id: string,
    |},
  |},
|};
export type updateLongBioMutation = {|
  response: updateLongBioMutation$data,
  variables: updateLongBioMutation$variables,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UpdateLongBioPayload",
    "kind": "LinkedField",
    "name": "updateLongBio",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Store",
        "kind": "LinkedField",
        "name": "store",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "currentUser",
            "plural": false,
            "selections": [
              (v1/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "updateLongBioMutation",
    "selections": (v2/*: any*/),
    "type": "ShopshareMutationRoot",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "updateLongBioMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "f3bdfe746c35491075f6622c393d5ddc",
    "id": null,
    "metadata": {},
    "name": "updateLongBioMutation",
    "operationKind": "mutation",
    "text": "mutation updateLongBioMutation(\n  $input: UpdateLongBioInput!\n) {\n  updateLongBio(input: $input) {\n    store {\n      id\n      currentUser {\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "b00e11c75775f9945e2515f834db4e75";

module.exports = ((node/*: any*/)/*: Mutation<
  updateLongBioMutation$variables,
  updateLongBioMutation$data,
>*/);
