/**
 * @generated SignedSource<<c568f41849fa754d1648ec060273fa6b>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Query } from 'relay-runtime';
import type { forNetworkMarketers_store$fragmentType } from "./forNetworkMarketers_store.graphql";
export type forNetworkMarketersQuery$variables = {||};
export type forNetworkMarketersQuery$data = {|
  +store: ?{|
    +$fragmentSpreads: forNetworkMarketers_store$fragmentType,
  |},
|};
export type forNetworkMarketersQuery = {|
  response: forNetworkMarketersQuery$data,
  variables: forNetworkMarketersQuery$variables,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "forNetworkMarketersQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Store",
        "kind": "LinkedField",
        "name": "store",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "forNetworkMarketers_store"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "ShopshareQueryRoot",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "forNetworkMarketersQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Store",
        "kind": "LinkedField",
        "name": "store",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "currentUser",
            "plural": false,
            "selections": [
              (v0/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "7f89d54e5120047a1f66c7398c52f987",
    "id": null,
    "metadata": {},
    "name": "forNetworkMarketersQuery",
    "operationKind": "query",
    "text": "query forNetworkMarketersQuery {\n  store {\n    ...forNetworkMarketers_store\n    id\n  }\n}\n\nfragment forNetworkMarketers_store on Store {\n  id\n  currentUser {\n    id\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "44cfb3f22f26b6a1f1251bc1acf0cbdc";

module.exports = ((node/*: any*/)/*: Query<
  forNetworkMarketersQuery$variables,
  forNetworkMarketersQuery$data,
>*/);
