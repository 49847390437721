import React from "react"
import Avatar from "components/profile/avatar/avatar"
import { addVersioning } from "helpers/query-parameters-helper"
import card from "shared/styles/components/card.css"
import OwnedItemButton from "components/buttons/owned-item-button"
import { publicViewDomain } from "constants"
import styles from "./product-card.css"

export default class ProductCard extends React.Component {
	constructor(props) {
		super(props)
		this.handleLink = this.handleLink.bind(this)
	}

	handleLink() {
		const { product } = this.props
		window.open(
			product.affiliated_url || product.url,
			"_blank",
			"noopener noreferrer"
		)
	}

	renderPlay() {
		const { product } = this.props
		return (
			<a
				role="link"
				tabIndex={0}
				onClick={this.handleLink}
				onKeyPress={this.handleLink}
			>
				<div className={styles.figure} id={product.id}>
					<div className={styles.inner}>
						<img
							className={styles.poster}
							src={addVersioning(product.imageUrl, `d=400`)}
							onError={(e) => {
								if (e.target.fb) {
									return
								}
								e.target.src = product.imageUrl
								e.target.fb = true
							}}
							alt="Poster"
							loading="lazy"
						/>
					</div>
				</div>
			</a>
		)
	}

	render() {
		const { product, enableHeader, onOwnedItems } = this.props
		return (
			<div className={styles.root}>
				{enableHeader && (
					<div className={card.header}>
						<a
							className={card.userLink}
							href={`https://${
								product.user.customDomain ||
								`${product.user.username}.${publicViewDomain}`
							}`}
							target="_blank"
							rel="noreferrer"
						>
							<Avatar
								size="32px"
								photoUrl={product.user.profilePhoto}
								fullname={product.user.fullname}
								imageSize="100"
							/>

							<div className={card.user}>
								<span className={card.fullname}>
									{product.user.fullname}
								</span>
							</div>
						</a>
					</div>
				)}
				<div className={styles.media}>
					{product.site_name && (
						<div className={styles.brandLink}>
							<i>at</i> {product.site_name}
						</div>
					)}
					{this.renderPlay()}
				</div>
				<div className={styles.content}>
					<div className={styles.titleWithIcons}>
						<h4 className={styles.title}>{product.title}</h4>
						{onOwnedItems && (
							<div className={styles.titleIcon}>
								<OwnedItemButton
									isOwned={!product.isOwnedItems}
									onClick={() => onOwnedItems(product.id)}
								/>
							</div>
						)}
					</div>
					{(product.regularPrice || product.offerPrice) && (
						<p className={styles.price}>
							{product.offerPrice && (
								<span className={styles.offerPrice}>
									{product.offerPrice}
								</span>
							)}
							{product.regularPrice && (
								<span
									className={
										product.offerPrice
											? styles.regularPriceStrike
											: styles.regularPrice
									}
								>
									{product.regularPrice}
								</span>
							)}
						</p>
					)}
					{/* <div className={styles.cta}>
							<a
								className={styles.buy}
								href={
									product.affiliated_url
										? product.affiliated_url
										: product.url
								}
								target="_blank"
								rel="noopener noreferrer"
							>
								Shop
							</a>
						</div> */}
				</div>
			</div>
		)
	}
}
