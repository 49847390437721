import React, { Component } from 'react';
import queryString from "query-string"
import { withRouter } from '../../route-helpers';

class TeamAccept extends Component {
    componentDidMount() {
        const { setTeam, location, navigate } = this.props;
        this.qs = queryString.parse(location.search)
        setTeam(this.qs.tId);
        navigate('/content');
    }

    render() { 
        return <div>You accepted the team invite</div>;
    }
}
 
export default {
    Component: withRouter(TeamAccept),
};