import React, { Component } from "react"
import { BottomSheet } from "react-spring-bottom-sheet"
import {
	Cross1Icon,
	// MagicWandIcon,
	MinusIcon,
	Pencil1Icon,
	PlusIcon,
	TableIcon,
	TrashIcon,
} from "@radix-ui/react-icons"
import { toast } from "react-toastify"
import dayjs from "helpers/dayjs-helper"
import { MediaQuery } from "helpers/ui-helper"
import gqlHelper from "helpers/gql-helper"
import { getCollectionTitle } from "helpers/string-helper"
import { addVersioning, isValidUrl } from "helpers/query-parameters-helper"
import ListOfShopItems from "components/list-of-shop-items/list-of-shop-items"
import ProductEditModal from "components/modals/product-edit/product-edit"
import ConformationPrompt from "components/modals/conformation-prompt/conformation-prompt"
import DropdownMenu from "components/dropdown-menu/dropdown-menu.jsx"
import Avatar from "components/profile/avatar/avatar"
import WidgetPlayer from "components/widget-player/widget-player"
import StatsSales from "components/stats-sales/stats-sales"
import bottomSheet from "shared/styles/layout/bottom-sheet.css"
import stylesDropdown from "components/dropdown-menu/dropdown-menu-dark.css"
import pageStyles from "components/lookbook/page.css"
import DeleteProductMutation from "mutations/product/delete-product"
import setClosetOwnedItems from "mutations/product-collection/set-closet-owned-items"
import buttons from "shared/styles/components/buttons.css"
import { baseFrontendUrl, publicViewDomain } from "../../constants"
import styles from "./panel-shop-items.css"
import "react-spring-bottom-sheet/dist/style.css"
import SalesTransactionsModal from "../modals/sales-transaction/sales-transaction"
import { getChipClass } from "../../helpers/ui-helper"
// import ItemReferencesModal from "../item-references/item-references"

class PanelShopItems extends Component {
	constructor(props) {
		super(props)
		this.state = {
			productToDelete: null,
			productToEdit: null,
			transactionsToShow: null,
			enableStats: window.sessionStorage.getItem("enableStats") === "true",
			// itemReferenceId: null,
		}
		this.deleteProductSuccess = this.deleteProductSuccess.bind(this)
		this.deleteProductFailure = this.deleteProductFailure.bind(this)
		this.deleteProduct = this.deleteProduct.bind(this)
	}

	getDisplayData() {
		const { shopcast } = this.props
		if (!shopcast) return null
		if (shopcast.team)
			return {
				title: shopcast.team.displayName || shopcast.team.name,
				avatar: shopcast.team.displayAvatar || shopcast.team.avatar,
				url: `${baseFrontendUrl}/team/${shopcast.team.id}`,
			}
		const domain =
			shopcast.user.customDomain ||
			`${shopcast.user.username}.${publicViewDomain}`
		return {
			title: shopcast.user.username,
			avatar: shopcast.user.profilePhoto,
			url: `https://${domain}`,
		}
	}

	setItemToOwnedItems(itemId) {
		const { relay, clientId } = this.props
		setClosetOwnedItems.commit(
			relay.environment,
			itemId,
			clientId,
			(err) => {
				toast.error(
					gqlHelper.getError(err) || "Error item adding to owned items.",
					{ autoClose: 2000, closeButton: false }
				)
			},
			() => {}
		)
	}

	getProductActions(product) {
		const { setEnableProductPanel, clientId, shopcast } = this.props
		const actions = []
		if (shopcast.user.canEdit) {
			actions.push(
				...[
					{
						click: () => {
							if (product && product.id) {
								this.setState({
									productToEdit: product,
								})
								setEnableProductPanel(false)
							}
						},
						name: "Edit",
						key: "edit",
						icon: <Pencil1Icon className={stylesDropdown.itemIcon} />,
					},
					{
						click: () => {
							this.setState({
								productToDelete: product,
							})
							setEnableProductPanel(false)
						},
						name: "Delete",
						key: "delete",
						icon: <TrashIcon className={stylesDropdown.itemIcon} />,
					},
				]
			)
		}
		if (clientId)
			actions.push({
				click: () => {
					this.setItemToOwnedItems(product.id)
				},
				name: product.isOwnedItems
					? "Remove from Owned items"
					: "Add to Owned items",
				key: "add",
				icon: product.isOwnedItems ? (
					<MinusIcon className={stylesDropdown.itemIcon} />
				) : (
					<PlusIcon className={stylesDropdown.itemIcon} />
				),
			})
		// actions.push("divide")
		// actions.push({
		// 	click: () => {
		// 		this.setState({
		// 			itemReferenceId: product.id,
		// 		})
		// 	},
		// 	name: clientId ? "How To Style" : "Used In",
		// 	key: "references",
		// 	icon: <MagicWandIcon className={stylesDropdown.itemIcon} />,
		// })
		return actions
	}

	deleteProductFailure(transaction) {
		console.log(transaction)
	}

	deleteProductSuccess() {
		const { refetchData } = this.props
		if (refetchData) refetchData()
	}

	deleteProduct(productId) {
		const { relay, shopcast, store } = this.props
		const shopcastId = shopcast.id
		DeleteProductMutation.commit(
			relay.environment,
			shopcastId,
			productId,
			store.id,
			this.deleteProductSuccess,
			this.deleteProductFailure
		)
	}

	renderPlayer(rounded) {
		const { videoData } = this.props
		return (
			<WidgetPlayer
				rounded={rounded}
				autoPlay={false}
				muxedUrl={videoData.videoUrl}
				subtitle={videoData.subtitle}
				poster={videoData.poster}
			/>
		)
	}

	renderActionMenu(
		triggerClass = buttons.dropdownIcon,
		triggerClassActive = buttons.dropdownIconActive
	) {
		const { actions, shopcast } = this.props
		const { enableStats } = this.state
		const allActions = [...actions]
		if (shopcast.user.canEdit) {
			allActions.push("divide")
			allActions.push({
				click: () => {
					this.setState((state) => {
						window.sessionStorage.setItem(
							"enableStats",
							!state.enableStats
						)
						return { enableStats: !state.enableStats }
					})
				},
				name: enableStats ? "Hide Sales" : "Show Sales",
				key: "stats",
				icon: enableStats ? (
					<TableIcon className={stylesDropdown.itemIcon} />
				) : (
					<TableIcon className={stylesDropdown.itemIcon} />
				),
			})
		}
		return (
			allActions &&
			allActions.length > 0 && (
				<DropdownMenu
					items={allActions}
					darkTheme
					contentRight
					triggerClass={triggerClass}
					triggerClassActive={triggerClassActive}
				/>
			)
		)
	}

	render() {
		const {
			productToDelete,
			productToEdit,
			enableStats,
			transactionsToShow,
			// itemReferenceId,
		} = this.state
		const {
			shopcast,
			relay,
			store,
			emptyButton,
			playControls,
			getProductActive,
			enableProductPanel,
			setEnableProductPanel,
			enableVideoPanel,
			setEnableVideoPanel,
			enableBoardsPanel,
			setEnableBoardsPanel,
			enableDetailsPanel,
			setEnableDetailsPanel,
			refetchData,
			videoData,
			selectBoard,
			currentPage,
			// clientId,
		} = this.props
		const productCollections =
			shopcast?.user?.productCollections?.edges.map((i) => i.node) || []
		const { currentUser } = store
		const isAdmin = currentUser && currentUser.adminAccess === "HAS"
		const stats =
			enableStats &&
			currentUser &&
			(currentUser.id === shopcast.user.id || isAdmin)

		const shopcastProductIds = shopcast.products.map((p) => p.id)

		const boardTransactions = shopcast.transactions.filter((i) =>
			shopcastProductIds.includes(i.product.id)
		)
		const displayData = this.getDisplayData()

		const prodComp = (
			<ListOfShopItems
				transactions={shopcast.transactions}
				setShowTransactions={(transactions) => {
					this.setState({ transactionsToShow: transactions })
					setEnableProductPanel(false)
				}}
				enableStats={stats}
				products={shopcast.products}
				emptyButton={shopcast.user.canEdit === true ? emptyButton : null}
				playControls={playControls}
				getProductError={() => {
					if (shopcast.user.canEdit !== true && shopcast.isFlagged)
						return "Content for this has been removed"
					return null
				}}
				getProductActive={getProductActive}
				getProductActions={(product) => this.getProductActions(product)}
			/>
		)
		return (
			<>
				<MediaQuery query={styles["breakpoint-sm-mw"]}>
					<BottomSheet
						open={enableDetailsPanel}
						onDismiss={() => setEnableDetailsPanel(false)}
						header={
							<div className={bottomSheet.header}>
								<h3 className={bottomSheet.title}>Description</h3>
								<button
									type="button"
									className={bottomSheet.close}
									onClick={() => setEnableDetailsPanel(false)}
								>
									<Cross1Icon className={bottomSheet.icon} />
								</button>
							</div>
						}
					>
						<div className={styles.asideDetails}>
							<h1 className={styles.title}>{shopcast.title}</h1>
							<div className={styles.meta}>
								<Avatar
									photoUrl={displayData.avatar}
									fullname={displayData.title}
									imageSize="100"
									size="32px"
								/>
								<dl className={styles.creator}>
									<dt className={styles.by}>
										<a
											className={styles.creatorLink}
											href={displayData.url}
										>
											{displayData.title}
										</a>
									</dt>
									<dd className={styles.date}>
										{dayjs(+shopcast.createdDate).fromNow()}
									</dd>
								</dl>
							</div>
							{shopcast.description &&
								(shopcast.user.canEdit || !shopcast.isFlagged) && (
									<p className={styles.description}>
										{shopcast.description}
									</p>
								)}
							{stats && boardTransactions.length > 0 && (
								<div className={styles.stats}>
									<StatsSales
										transactions={boardTransactions}
										setShowTransactions={(transactions) => {
											this.setState({
												transactionsToShow: transactions,
											})
											setEnableDetailsPanel(false)
										}}
									/>
								</div>
							)}
						</div>
					</BottomSheet>
				</MediaQuery>

				<MediaQuery query={styles["breakpoint-sm-mw"]}>
					<BottomSheet
						open={enableProductPanel}
						onDismiss={() => setEnableProductPanel(false)}
						snapPoints={
							shopcast.products.length > 2
								? ({ maxHeight }) => [maxHeight / 1.5]
								: undefined
						}
						header={
							<div className={bottomSheet.header}>
								<h3 className={bottomSheet.title}>Shop</h3>
								<button
									type="button"
									className={bottomSheet.close}
									onClick={() => setEnableProductPanel(false)}
								>
									<Cross1Icon className={bottomSheet.icon} />
								</button>
							</div>
						}
					>
						<div className={styles.listOfShopItems}>{prodComp}</div>
					</BottomSheet>
				</MediaQuery>

				{videoData && videoData.showVideo && (
					<MediaQuery query={styles["breakpoint-sm-mw"]}>
						<BottomSheet
							open={enableVideoPanel}
							onDismiss={() => setEnableVideoPanel(false)}
							header={
								<div className={bottomSheet.header}>
									<h3 className={bottomSheet.title}>Player</h3>
									<button
										type="button"
										className={bottomSheet.close}
										onClick={() => setEnableVideoPanel(false)}
									>
										<Cross1Icon className={bottomSheet.icon} />
									</button>
								</div>
							}
						>
							{this.renderPlayer(false)}
						</BottomSheet>
					</MediaQuery>
				)}

				{shopcast.lookbookBoards && shopcast.lookbookBoards.length > 0 && (
					<MediaQuery query={styles["breakpoint-sm-mw"]}>
						<BottomSheet
							open={enableBoardsPanel}
							onDismiss={() => setEnableBoardsPanel(false)}
							snapPoints={
								shopcast.lookbookBoards.length > 6
									? ({ maxHeight }) => [maxHeight / 1.5]
									: undefined
							}
							header={
								<div className={bottomSheet.header}>
									<h3 className={bottomSheet.title}>Pages</h3>
									<button
										type="button"
										className={bottomSheet.close}
										onClick={() => setEnableBoardsPanel(false)}
									>
										<Cross1Icon className={bottomSheet.icon} />
									</button>
								</div>
							}
						>
							<div className={styles.boardList}>
								{shopcast.lookbookBoards.map((board) => (
									<div className={pageStyles.root} key={board.id}>
										<div
											className={
												currentPage === board.id
													? pageStyles.pageActive
													: pageStyles.page
											}
											style={{
												cursor: "pointer",
											}}
										>
											<div
												onClick={() => {
													selectBoard(board.id)
													setEnableBoardsPanel(false)
												}}
												role="button"
												tabIndex="0"
												className={pageStyles.inner}
											>
												<img
													className={pageStyles.img}
													src={
														isValidUrl(board.url)
															? addVersioning(board.url, `d=400`)
															: board.url
													}
													alt={`Page ${board.pageNo + 1}`}
												/>
											</div>
										</div>
										<div
											className={
												currentPage === board.id
													? pageStyles.pageNoActive
													: pageStyles.pageNo
											}
										>
											{board.pageNo + 1}
										</div>
									</div>
								))}
							</div>
						</BottomSheet>
					</MediaQuery>
				)}

				<MediaQuery query={styles["breakpoint-sm"]}>
					<div className={styles.aside}>
						<div className={styles.asideContent}>
							<div className={styles.asideDetails}>
								<h1 className={styles.title}>{shopcast.title}</h1>
								<div className={styles.meta}>
									<Avatar
										photoUrl={displayData.avatar}
										fullname={displayData.title}
										imageSize="100"
										size="32px"
									/>
									<dl className={styles.creator}>
										<dt className={styles.by}>
											<a
												className={styles.creatorLink}
												href={displayData.url}
											>
												{displayData.title}
											</a>
										</dt>
										<dd className={styles.date}>
											{dayjs(+shopcast.createdDate).fromNow()}
										</dd>
									</dl>
									{this.renderActionMenu()}
								</div>
								{shopcast.description &&
									(shopcast.user.canEdit || !shopcast.isFlagged) && (
										<p className={styles.description}>
											{shopcast.description}
										</p>
									)}
								{stats && boardTransactions.length > 0 && (
									<div className={styles.stats}>
										<StatsSales
											transactions={boardTransactions}
											setShowTransactions={(transactions) =>
												this.setState({
													transactionsToShow: transactions,
												})
											}
										/>
									</div>
								)}
								{videoData && videoData.showVideo && (
									<div className={styles.media}>
										{this.renderPlayer(true)}
									</div>
								)}
							</div>
							{/* {shopcast.products.length > 0 && (
								<div className={styles.commLinks}>
									Commissionable links
								</div>
							)} */}
							{prodComp}
						</div>
					</div>
				</MediaQuery>
				<ProductEditModal
					store={store}
					refetchData={refetchData || (() => {})}
					product={productToEdit}
					showProductEditModal={!!productToEdit}
					closeProductEditModal={() =>
						this.setState({ productToEdit: null })
					}
					relay={relay}
					enableSavingWithoutCollection
					collectionsKeyValue={productCollections
						.filter((i) => !i.isDefault)
						.sort((a, b) => a.isACloset - b.isACloset)
						.map((i) => ({
							id: i.id,
							value: getCollectionTitle(i),
							className: getChipClass(i).chip,
						}))}
				/>
				<ConformationPrompt
					openModal={!!productToDelete}
					closeModal={() => {
						this.setState({ productToDelete: null })
					}}
					approveEvent={() => {
						this.deleteProduct(productToDelete.id)
						this.setState({ productToDelete: null })
					}}
					declineEvent={() => {
						this.setState({ productToDelete: null })
					}}
					approveText="Delete"
					declineText="Cancel"
					approveButtonStyle={buttons.primaryRed}
					title="Are you sure?"
				>
					Do you want to permanently delete &quot;
					{productToDelete?.title}&quot;? This action cannot be undone.
				</ConformationPrompt>
				<SalesTransactionsModal
					isAdmin={isAdmin}
					transactions={transactionsToShow}
					closeModal={() => this.setState({ transactionsToShow: null })}
				/>
				{/* <ItemReferencesModal
					itemId={itemReferenceId}
					folderId={clientId}
					modalTitle={clientId ? "How to Style" : "Used In"}
					closeModal={() => {
						this.setState({ itemReferenceId: null })
					}}
				/> */}
			</>
		)
	}
}

export default PanelShopItems
