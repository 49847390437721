/**
 * @generated SignedSource<<b14ce02a8f9400985fa857eb64523b23>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Mutation } from 'relay-runtime';
export type CreateLookbookInput = {|
  background?: ?string,
  boardId?: ?string,
  boardTiltle?: ?string,
  categories?: ?$ReadOnlyArray<?string>,
  clientMutationId?: ?string,
  data_json?: ?string,
  description?: ?string,
  folder?: ?string,
  group?: ?string,
  height?: ?number,
  id?: ?string,
  isGlobal?: ?boolean,
  isTemplate?: ?boolean,
  pageNo?: ?number,
  published_at?: ?string,
  screen_width?: ?number,
  showBoard?: ?boolean,
  teamId?: ?string,
  templateId?: ?string,
  title: string,
  url?: ?string,
  usedProducts?: ?$ReadOnlyArray<?string>,
  usedShapes?: ?$ReadOnlyArray<?string>,
  usedTemplate?: ?string,
  width?: ?number,
|};
export type updateLookbookMutation$variables = {|
  input: CreateLookbookInput,
|};
export type updateLookbookMutation$data = {|
  +createLookbook: ?{|
    +lookbook: ?{|
      +id: ?string,
      +lookbookId: string,
      +title: ?string,
      +url: ?string,
    |},
    +template: ?{|
      +canEdit: ?boolean,
      +categories: ?$ReadOnlyArray<?string>,
      +createdCount: ?number,
      +id: string,
      +isFavourite: ?boolean,
      +isGlobal: ?boolean,
    |},
  |},
|};
export type updateLookbookMutation = {|
  response: updateLookbookMutation$data,
  variables: updateLookbookMutation$variables,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "CreateLookbookPayload",
    "kind": "LinkedField",
    "name": "createLookbook",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "LookbookBoard",
        "kind": "LinkedField",
        "name": "lookbook",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "lookbookId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "title",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "url",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "LookbookTemplate",
        "kind": "LinkedField",
        "name": "template",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "categories",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "canEdit",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isFavourite",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "createdCount",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isGlobal",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "updateLookbookMutation",
    "selections": (v2/*: any*/),
    "type": "ShopshareMutationRoot",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "updateLookbookMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "8d6b7ccb1c741737149e51657a5e2dbb",
    "id": null,
    "metadata": {},
    "name": "updateLookbookMutation",
    "operationKind": "mutation",
    "text": "mutation updateLookbookMutation(\n  $input: CreateLookbookInput!\n) {\n  createLookbook(input: $input) {\n    lookbook {\n      id\n      lookbookId\n      title\n      url\n    }\n    template {\n      id\n      categories\n      canEdit\n      isFavourite\n      createdCount\n      isGlobal\n    }\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "6fc14508dadaaf570d88c4ad08b09da9";

module.exports = ((node/*: any*/)/*: Mutation<
  updateLookbookMutation$variables,
  updateLookbookMutation$data,
>*/);
