import { commitMutation, graphql } from "react-relay"
import { complete } from "../helper.js"

const mutation = graphql`
	mutation updatePersonalInfoMutation($input: UpdatePersonalInfoInput!) {
		updatePersonalInfo(input: $input) {
			store {
				id
				currentUser {
					id
					isVerified
					verificationInProgress
				}
			}
		}
	}
`

const getConfigs = (storeId) => [
	{
		type: "FIELDS_CHANGE",
		fieldIDs: { store: storeId },
	},
]

const commit = (
	env,
	fullname,
	bio,
	email,
	displayName,
	paypalEmail,
	storeId,
	onCompleted,
	onError
) =>
	commitMutation(env, {
		mutation,
		variables: {
			input: {
				fullname,
				bio,
				email,
				displayName,
				paypalEmail
			},
		},
		configs: getConfigs(storeId),
		updater: (storeProxy, data) => {
			if (!data.updatePersonalInfo) return
			const {
				id: currentUserId,
				isVerified,
				verificationInProgress
			} = data.updatePersonalInfo.store.currentUser
			const userProxy = storeProxy.get(currentUserId)
			if (!userProxy) return
			userProxy.setValue(fullname, "fullname")
			userProxy.setValue(displayName, "displayName")
			userProxy.setValue(bio, "bio")
			userProxy.setValue(paypalEmail, "paypalEmail")
			userProxy.setValue(isVerified, "isVerified")
			userProxy.setValue(verificationInProgress, "verificationInProgress")
		},
		onCompleted: complete(onCompleted, onError),
		onError: (e) => complete(null, [e]),
	})

export default { commit }
