import React from "react"
import { withDrop } from "helpers/dnd-helper"
import { Link } from "react-router-dom"
import FolderIcon from "shared/assets/folder-icon.svg"
import styles from "./sidebar-item.css"

const shopcastFolderTarget = {
	drop(props, monitor) {
		const item = monitor.getItem()
		props.onDrop(item)
	},
}

function collect(monitor) {
	return {
		isOver: monitor.isOver(),
	}
}

function SidebarItem({
	item,
	path,
	isOver,
	isActive,
	size,
	dropRef,
	Icon = FolderIcon,
}) {
	return (
		<div
			className={`${styles.root} ${isActive ? styles.active : ""}`}
			ref={dropRef}
		>
			{size !== "sm" && (
				<Link className={isOver ? styles.linkHover : styles.link} to={path}>
					<div className={styles.media}>
						<Icon className={styles.icon} />
					</div>
					<div className={styles.content}>
						<div className={styles.name}>{item.name}</div>
					</div>
					{/* <div className={styles.count}>{item.count}</div> */}
				</Link>
			)}
			{size === "sm" && (
				<Link
					className={isOver ? styles.linkSmHover : styles.linkSm}
					to={path}
				>
					<div className={styles.media}>
						<Icon className={styles.icon} />
					</div>
					<div className={styles.content}>
						<div className={styles.nameSm}>{item.name}</div>
					</div>
					{/* <div className={styles.count}>{item.count}</div> */}
				</Link>
			)}
		</div>
	)
}

function SidebarItemDefault(defaultProps) {
	const { onDrop } = defaultProps
	const Target = onDrop
		? withDrop(SidebarItem, (props) => ({
				accept: props.accept || [],
				drop: (_, monitor) => shopcastFolderTarget.drop(props, monitor),
				collect,
		  }))
		: SidebarItem
	return <Target {...defaultProps} />
}

export default SidebarItemDefault
