import React from "react"
import { LineHeightIcon } from "@radix-ui/react-icons"
import { createGuid } from "helpers/string-helper"
import LookbookDropdown from "./lookbook-dropdown"
import styles from "./lookbook-dropdown.css"

export default function LineHeightPicker({
	lineHeight,
	runDebounce,
	dataTip,
	onChange,
}) {
	const listItems = [
		{
			click: () => onChange({ lineHeight: parseFloat(0.5) }),
			name: 0.5,
		},
		{
			click: () => onChange({ lineHeight: parseFloat(0.6) }),
			name: 0.6,
		},
		{
			click: () => onChange({ lineHeight: parseFloat(0.7) }),
			name: 0.7,
		},
		{
			click: () => onChange({ lineHeight: parseFloat(0.8) }),
			name: 0.8,
		},
		{
			click: () => onChange({ lineHeight: parseFloat(0.9) }),
			name: 0.9,
		},
		"divider",
		{
			click: () => onChange({ lineHeight: parseFloat(1.0) }),
			name: 1.0,
		},
		{
			click: () => onChange({ lineHeight: parseFloat(1.1) }),
			name: 1.1,
		},
		{
			click: () => onChange({ lineHeight: parseFloat(1.2) }),
			name: 1.2,
		},
		{
			click: () => onChange({ lineHeight: parseFloat(1.3) }),
			name: 1.3,
		},
		{
			click: () => onChange({ lineHeight: parseFloat(1.4) }),
			name: 1.4,
		},
		{
			click: () => onChange({ lineHeight: parseFloat(1.5) }),
			name: 1.5,
		},
	]
	const activeItem = listItems.find((x) => x.name === lineHeight)
	return (
		<LookbookDropdown
			label={<LineHeightIcon />}
			data-tooltip-content={dataTip}
			data-tooltip-id="tooltip"
			runDebounce={runDebounce}
		>
			<div className={styles.panelLineHeight}>
				<div className={styles.scroll}>
					{listItems.map((x) => {
						if (x === "divider") {
							return (
								<div className={styles.divider} key={createGuid(4)} />
							)
						}
						return (
							<div
								key={x.name}
								onClick={x.click}
								tabIndex={-1}
								role="link"
								className={
									activeItem === x ? styles.itemActive : styles.item
								}
							>
								{x.name}
							</div>
						)
					})}
				</div>
			</div>
		</LookbookDropdown>
	)
}
