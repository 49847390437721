/**
 * @generated SignedSource<<7bf0cef05a8f5332717074c376bd5451>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Mutation } from 'relay-runtime';
export type ToggleArchiveCampaignInput = {|
  clientMutationId?: ?string,
  id: string,
|};
export type toggleArchiveCampaignMutation$variables = {|
  input: ToggleArchiveCampaignInput,
|};
export type toggleArchiveCampaignMutation$data = {|
  +toggleArchiveCampaign: ?{|
    +store: ?{|
      +id: string,
    |},
  |},
|};
export type toggleArchiveCampaignMutation = {|
  response: toggleArchiveCampaignMutation$data,
  variables: toggleArchiveCampaignMutation$variables,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "ToggleArchiveCampaignPayload",
    "kind": "LinkedField",
    "name": "toggleArchiveCampaign",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Store",
        "kind": "LinkedField",
        "name": "store",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "toggleArchiveCampaignMutation",
    "selections": (v1/*: any*/),
    "type": "ShopshareMutationRoot",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "toggleArchiveCampaignMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "32e9e61f2bbe4416e14ed0445f3c49c0",
    "id": null,
    "metadata": {},
    "name": "toggleArchiveCampaignMutation",
    "operationKind": "mutation",
    "text": "mutation toggleArchiveCampaignMutation(\n  $input: ToggleArchiveCampaignInput!\n) {\n  toggleArchiveCampaign(input: $input) {\n    store {\n      id\n    }\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "683d45ef734e9b2b32913c059e637de1";

module.exports = ((node/*: any*/)/*: Mutation<
  toggleArchiveCampaignMutation$variables,
  toggleArchiveCampaignMutation$data,
>*/);
