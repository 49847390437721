import React, { Component, Fragment } from "react"
import { createFragmentContainer, graphql } from "react-relay"
import {
	TrashIcon,
	ResetIcon,
	Cross2Icon,
	PlusIcon,
	PlayIcon,
	FileIcon,
	FrameIcon,
	ExternalLinkIcon,
	AvatarIcon,
	Link2Icon,
	CheckIcon,
	ChevronDownIcon,
	ChevronRightIcon,
	// SlashIcon,
	MagnifyingGlassIcon,
	PaperPlaneIcon,
	CopyIcon,
	// DownloadIcon,
} from "@radix-ui/react-icons"
import { toast } from "react-toastify"
import axios from "axios"
import ClipboardButton from "react-clipboard.js"
import { Link, Navigate } from "react-router-dom"
import FolderIcon from "shared/assets/folder-icon.svg"
import CollectionIcon from "shared/assets/lb-collections-icon.svg"
// import FolderShareIcon from "shared/assets/folder-share-icon.svg"
// import FolderLookbookIcon from "shared/assets/folder-lookbook-icon.svg"
import CrumpledPaperIcon from "shared/assets/crumpled-paper-icon.svg"
// import WorkspaceIcon from "shared/assets/workspace-icon.svg"
import buttons from "shared/styles/components/buttons.css"
import icons from "shared/styles/components/icons.css"
import empty from "shared/styles/components/empty.css"
import actionsBar from "shared/styles/components/actions-bar.css"
import twoColumn from "shared/styles/layout/two-column.css"
import controls from "shared/styles/layout/controls.css"
import pageHeader from "shared/styles/layout/page-header.css"
import pageContent from "shared/styles/layout/page-content.css"
// import pageBreadcrumbs from "shared/styles/layout/page-breadcrumbs.css"
import section from "shared/styles/layout/section.css"
import collapsibleHeader from "shared/styles/layout/collapsible-header.css"
import nav from "shared/styles/components/nav.css"
import Star from "components/star/star.jsx"
import ConformationPrompt from "components/modals/conformation-prompt/conformation-prompt"
import stylesDropdown from "components/dropdown-menu/dropdown-menu-dark.css"
import stylesUserMenuDD from "components/dropdowns/dropdowns.css"
import DropdownMenu from "components/dropdown-menu/dropdown-menu"
import FileUpload from "components/modals/image-upload/file-upload"
import FileEdit from "components/modals/file-edit/file-edit"
import Avatar from "components/profile/avatar/avatar"
import LookbookSavingSimple from "components/modals/lookbook-saving/lookbook-saving-simple"
import MoveToFolderModal from "components/modals/move-to-folder/move-to-folder"
import ProductCollectionCreateEditModal from "components/modals/product-collection-create-edit/product-collection-create-edit"
import ShopcastEdit from "components/modals/shopcast-edit/shopcast-edit"
import CreateFolder from "components/modals/create-folder/create-folder"
import CreateEditClient from "components/modals/create-edit-client/create-edit-client"
import toggleFavoriteShopcastItemMutation from "mutations/shopcast-manager/toggle-favorite-shopcast-item"
import moveShopcastItems from "mutations/shopcast-manager/move-shopcast-items"
import restoreShopcastItemMutation from "mutations/shopcast-manager/restore-shopcast-item"
import archiveShopcastItem from "mutations/shopcast-manager/archive-shopcast-item"
import deleteShopcastFolderMutation from "mutations/shopcast-manager/delete-shopcast-folder"
import deleteShopcastMutation from "mutations/shopcast-manager/delete-shopcast"
import deleteLookbookMutation from "mutations/shopcast-manager/delete-lookbook"
import deleteFileMutation from "mutations/shopcast-manager/delete-file"
import deleteProductCollectionMutation from "mutations/product-collection/delete-product-collection"
import createNewFolderMutation from "mutations/shopcast-manager/create-new-folder"
import sendClientInviteMutation from "mutations/client/send-client-invite.js"
import CopyToMutation from "mutations/shopcast-manager/copy-to"
import {
	FeatureAvailableComponent,
	FeatureKeyENUM,
	runIfFeatureAvailable,
} from "services/plan-services"
import {
	publicViewDomain,
	subdomainValidationRegex,
	ROLES,
	baseFrontendUrl,
} from "constants"
import ListOfFolderItems from "./list-of-folder-items/list-of-folder-items"
import ListOfFolders from "./list-of-folders/list-of-folders"
import FolderMenu from "../shopcast-folder/folder-menu"
import { getComponent, withRouter } from "../../../route-helpers"
import { getBaseUrl } from "../../../constants"

class ShopcastManager extends Component {
	constructor(props) {
		super(props)

		this.confirmDelete = this.confirmDelete.bind(this)
		this.handleNewFolderClick = this.handleNewFolderClick.bind(this)
		this.handleEndFolderCreation = this.handleEndFolderCreation.bind(this)
		this.handleDeleteConfirmationModalCancel = this.handleDeleteConfirmationModalCancel.bind(
			this
		)
		this.handleDeleteConfirmationModalConfirm = this.handleDeleteConfirmationModalConfirm.bind(
			this
		)
		this.toggleFavoriteFolder = this.toggleFavoriteFolder.bind(this)
		this.handleItemSelect = this.handleItemSelect.bind(this)
		this.handleItemDeselect = this.handleItemDeselect.bind(this)
		this.requestMove = this.requestMove.bind(this)
		this.requestCopy = this.requestCopy.bind(this)
		this.onTreeViewToggle = this.onTreeViewToggle.bind(this)
		this.handleMoveShopcastModalConfirm = this.handleMoveShopcastModalConfirm.bind(
			this
		)
		this.handleMoveShopcastModalCancel = this.handleMoveShopcastModalCancel.bind(
			this
		)
		this.handleMoveButtonClick = this.handleMoveButtonClick.bind(this)
		this.handleCopyButtonClick = this.handleCopyButtonClick.bind(this)
		this.handleRestoreClick = this.handleRestoreClick.bind(this)
		this.handleArchiveButtonClick = this.handleArchiveButtonClick.bind(this)
		this.requestEdit = this.requestEdit.bind(this)
		this.endShopcastEdit = this.endShopcastEdit.bind(this)
		this.requestLookbookEdit = this.requestLookbookEdit.bind(this)
		this.handleDeleteButtonClick = this.handleDeleteButtonClick.bind(this)
		this.handleDownloadSeleected = this.handleDownloadSeleected.bind(this)
		this.handleBulkDelete = this.handleBulkDelete.bind(this)
		this.setTabStyle = this.setTabStyle.bind(this)
		this.setFolderRefetch = this.setFolderRefetch.bind(this)
		this.setClientFolder = this.setClientFolder.bind(this)
		this.sendClientInvite = this.sendClientInvite.bind(this)

		const itemsFilter = JSON.parse(localStorage.getItem("itemsFilter")) || {
			type: "",
			sort: "n-to-o",
		}

		this.state = {
			creatingNewFolder: false,
			itemDeleteRequested: null,
			selectedItems: [],
			itemsFilter,
			openUploadFileModal: false,
			editing: null,
			currentTab: "",
			expandedTabs: {
				shopcast: true,
				lookbook: true,
				file: true,
				collection: true,
				folders: true,
				sharedFolders: true,
				clients: true,
			},
			createClientFolder: null,
			tabStyle: {},
			shopcastCopyRequested: null,
			selectedTeam: null,
		}

		const { path } = this.folder
		this.canStar = path.length > 0
		this.tabsRef = {}
		const {
			store: { currentUser },
		} = this.props
		this.isAdmin =
			currentUser && [ROLES.ADMIN, ROLES.TEAM].includes(currentUser.role)
	}

	componentDidMount() {
		const { folder, setCurrentFolderId } = this.props
		const { itemsFilter } = this.state

		if (!folder && itemsFilter.type === "collection") this.setItemsFilter("")
		setCurrentFolderId(this.folder.id)
		this.setTabStyle()
	}

	componentDidUpdate(prevProps) {
		const { folder, setCurrentFolderId } = this.props
		if (prevProps.folder !== folder) {
			setCurrentFolderId(this.folder.id)
		}
	}

	//
	// ─── EVENT HANDLERS ─────────────────────────────────────────────────────────────
	//

	handleItemSelect(item) {
		const { selectedItems } = this.state
		this.setState({ selectedItems: [...selectedItems, item] })
	}

	handleItemDeselect(item) {
		const { selectedItems } = this.state
		const newSelection = []

		selectedItems.forEach((sc) => {
			if (sc.id === item.id) {
				return
			}
			newSelection.push(sc)
		})

		this.setState({ selectedItems: newSelection })
	}

	handleNewFolderClick() {
		this.setState({ creatingNewFolder: true })
	}

	handleEndFolderCreation() {
		this.setState({ creatingNewFolder: false })
	}

	handleDeleteConfirmationModalCancel() {
		this.setState({ itemDeleteRequested: null })
	}

	handleDeleteConfirmationModalConfirm() {
		const { itemDeleteRequested } = this.state
		itemDeleteRequested.callback()
		this.setState({ itemDeleteRequested: null })
	}

	handleMoveShopcastModalCancel() {
		this.setState({
			shopcastMoveRequested: null,
			treeViewSelectedFolder: null,
		})
	}

	handleMoveShopcastModalConfirm() {
		const { shopcastMoveRequested, treeViewSelectedFolder } = this.state
		shopcastMoveRequested.callback(treeViewSelectedFolder)
		this.setState({
			shopcastMoveRequested: null,
			treeViewSelectedFolder: null,
		})
	}

	handleMoveButtonClick() {
		const { relay, team } = this.props
		const { selectedItems } = this.state
		this.requestMove(
			(folder) => {
				moveShopcastItems.commit(
					relay.environment,
					selectedItems,
					folder,
					team,
					this.folder.id,
					this.container.id,
					this.sendErrorsToToast("Could not move shopcasts"),
					(i) => i.deselect()
				)
				this.setState({ selectedItems: [] })
			},
			selectedItems.filter((s) => s.type === "folder").map((s) => s.id)
		)
	}

	handleCopyButtonClick() {
		const { selectedItems } = this.state
		const { relay } = this.props
		this.requestCopy((team) => {
			CopyToMutation.commit(
				relay.environment,
				selectedItems.map((i) => i.id),
				team.id,
				this.sendErrorsToToast("Could not copy items"),
				() => {
					toast.success(<>Successfully copied</>, {
						autoClose: 2000,
						closeButton: false,
					})
				}
			)
			this.setState({ selectedItems: [] })
		})
	}

	handleRestoreClick() {
		const { selectedItems } = this.state
		const { relay } = this.props
		selectedItems.forEach((s) => {
			restoreShopcastItemMutation.commit(
				relay.environment,
				s.id,
				s.type,
				this.folder.id,
				this.sendErrorsToToast("Could not restore"),
				this.container.id,
				() => {
					s.deselect()
				}
			)
		})
		this.setState({ selectedItems: [] })
	}

	handleArchiveButtonClick() {
		const { selectedItems } = this.state
		const { relay } = this.props
		selectedItems.forEach((s) => {
			archiveShopcastItem.commit(
				relay.environment,
				s.id,
				s.type,
				this.folder.id,
				this.sendErrorsToToast("Could not delete"),
				this.container.id,
				() => {
					s.deselect()
				}
			)
		})
		this.setState({ selectedItems: [] })
	}

	handleDeleteButtonClick() {
		this.setState({
			itemDeleteRequested: {
				type: "selected items",
				name: "selected items",
				callback: this.handleBulkDelete,
			},
		})
	}

	async handleDownloadSeleected() {
		const { selectedItems } = this.state
		const shopboards = selectedItems.filter((s) => s.type === "lookbook")
		axios.post(`${getBaseUrl()}/api/users/download`, {
			ids: shopboards.map((s) => s.id),
		}).then(() => {
			this.setState({ selectedItems: [] })
			toast.success(<>An email with download link will be sent after processing</>, {
				autoClose: 2000,
				closeButton: false,
			})
		}).catch(() => {
			toast.error(<>Could not start download</>, {
				autoClose: 2000,
				closeButton: false,
			})
		})
	}

	handleBulkDelete() {
		const { selectedItems } = this.state
		const { relay } = this.props

		selectedItems.forEach((s) => {
			if (s.type === "folder") {
				deleteShopcastFolderMutation.commit(
					relay.environment,
					s.id,
					this.folder.id,
					this.container.id,
					this.sendErrorsToToast("Could not delete folder"),
					() => {
						s.deselect()
					}
				)
			} else if (s.type === "collection") {
				deleteProductCollectionMutation.commit(
					relay.environment,
					s.id,
					this.sendErrorsToToast("Could not delete collection"),
					() => {
						s.deselect()
					},
					{
						parentId: this.folder.id,
						containerId: this.container.id,
					}
				)
			} else if (s.type === "shopcast") {
				deleteShopcastMutation.commit(
					relay.environment,
					s.id,
					this.folder.id,
					s.isArchived ? null : this.container.id,
					this.sendErrorsToToast("Could not delete shopcast"),
					() => {
						s.deselect()
					}
				)
			} else if (s.type === "shopshareFile") {
				deleteFileMutation.commit(
					relay.environment,
					s.id,
					this.folder.id,
					s.isArchived ? null : this.container.id,
					this.sendErrorsToToast("Could not delete shopcast"),
					() => {
						s.deselect()
					}
				)
			} else {
				deleteLookbookMutation.commit(
					relay.environment,
					s.id,
					this.folder.id,
					s.isArchived ? null : this.container.id,
					this.sendErrorsToToast("Could not delete lookbook"),
					() => {
						s.deselect()
						this.handleItemDeselect(s)
					}
				)
			}
		})
		this.setState({ selectedItems: [] })
	}

	onTreeViewToggle(n) {
		this.setState({ treeViewSelectedFolder: n })
	}

	setTabStyle() {
		const { currentTab } = this.state
		this.setState({
			tabStyle: {
				width: this.tabsRef[currentTab]?.offsetWidth || 0,
				transform: `translateX(${this.tabsRef[currentTab]?.offsetLeft || 0
					}px)`,
			},
		})
	}

	//
	// ─── GETTERS ────────────────────────────────────────────────────────────────────
	//

	getActiveTabClass(tabName) {
		const { state } = this
		return state.currentTab === tabName ? nav.linkActive : nav.link
	}

	get container() {
		const { props } = this
		return props.store.currentUser.shopcastContainer
	}

	get folder() {
		const { store } = this.props
		return store.currentUser.shopcastContainer.folder
	}

	get validTeams() {
		const { store: { currentUser }, team } = this.props
		return currentUser.teams
		.filter(
			(t) =>
				t.id !== team &&
				t.features.includes(FeatureKeyENUM.TEAMS)
		)
	}

	//
	// ─── METHODS ────────────────────────────────────────────────────────────────────
	//
	setItemsFilter(value) {
		const { itemsFilter } = this.state
		const obj = {
			...itemsFilter,
			type: value,
		}
		this.setState({ itemsFilter: obj }, () => {
			localStorage.setItem("itemsFilter", JSON.stringify(obj))
		})
	}

	setFolderRefetch(refetch) {
		this.setFolderRefetch = refetch
	}

	setClientFolder(obj) {
		this.setState({ createClientFolder: obj })
	}

	getFolderId() {
		let folder = null
		const { folder: matchFolder } = this.props
		switch (matchFolder || null) {
			case null:
				folder = "root"
				break
			case "public":
				folder = "public"
				break
			case "archived":
				folder = "archived"
				break
			default:
				folder = "any"
				break
		}
		return folder
	}

	getTabs(folder) {
		const { search } = this.props
		const tabs = [
			{
				id: "clients",
				name: "Clients",
				count: this.folder.clientsCount,
				isActive: folder === "root",
				featureKey: FeatureKeyENUM.CLIENT_CRM,
				render: () => (
					this.renderFolders(
						folder,
						"clients",
						this.folder.clientsCount
					)
				),
			},
			{
				id: "folders",
				name: "Folders",
				count:
					folder === "public"
						? 0
						: this.folder.foldersCount + this.folder.sharedFoldersCount,
				isActive: !this.folder.isParentAClient,
				render: () =>
					this.renderFolders(
						folder,
						"folders",
						folder === "public"
							? 0
							: this.folder.foldersCount + this.folder.sharedFoldersCount
					),
			},
			{
				id: "shopcasts",
				name: "Shopcasts",
				count: this.folder.shopcastsCount,
				isActive: true,
				render: () =>
					this.renderItems(
						folder,
						{
							itemType: "shopcast",
							header: "Shopcasts",
							tabKey: "shopcasts",
						},
						this.folder.shopcastsCount
					),
			},
			{
				id: "shopboards",
				name: "Shopboards",
				count: this.folder.lookbooksCount,
				isActive: true,
				render: () =>
					this.renderItems(
						folder,
						{
							itemType: "lookbook",
							header: "Shopboards",
							tabKey: "shopboards",
						},
						this.folder.lookbooksCount
					),
			},
			{
				id: "collections",
				name: "Collections",
				count: this.folder.collectionCount,
				isActive: ["any", "public"].includes(folder) || !!search,
				render: () =>
					this.renderItems(
						folder,
						{
							itemType: "collection",
							header: "Collections",
							tabKey: "collections",
						},
						this.folder.collectionCount
					),
			},
			{
				id: "files",
				name: "Files",
				count: this.folder.filesCount,
				isActive: true,
				render: () =>
					this.renderItems(
						folder,
						{
							itemType: "file",
							header: "Files",
							tabKey: "files",
						},
						this.folder.filesCount
					),
			},
		]
		return tabs
	}

	getFolderLink(folder) {
		const { team } = this.props
		if (folder === "public" && !team) {
			const {
				store: { currentUser },
			} = this.props
			const domain =
				currentUser.customDomain ||
				`${currentUser.username}.${publicViewDomain}`
			return subdomainValidationRegex.test(currentUser.username)
				? `https://${domain}`
				: ""
		}
		if (folder === "public" && team) {
			return `${baseFrontendUrl}/team/${team}`
		}
		const archived = folder === "archived" || this.folder.isArchived
		if (folder !== "any" || archived) return ""
		const isClient = this.folder.clients && this.folder.clients.length > 0
		if (isClient) return `${baseFrontendUrl}/stylist/${this.folder.id}`
		return !this.folder.isParentAClient ? this.folder.sharableLink : ""
	}

	getFolderName(activeTabsItemCount) {
		const { search, teamId } = this.props
		if (search) return `Results (${activeTabsItemCount})`
		if (this.folder.name === "My Channel" && teamId) return "Team Channel"
		return this.folder.name
	}

	activateTab(e, currentTab) {
		e.preventDefault()
		this.setState({
			currentTab,
			tabStyle: {
				width: this.tabsRef[currentTab]?.offsetWidth || 0,
				transform: `translateX(${this.tabsRef[currentTab]?.offsetLeft || 0
					}px)`,
			},
		})
	}

	/**
	 * Opens the delete confirmation dialog
	 * @param {object} item Item to delete
	 */

	confirmDelete(item) {
		this.setState({ itemDeleteRequested: item })
	}

	sendErrorsToToast(message) {
		return () =>
			toast.error(message, {
				autoClose: 2000,
				closeButton: false,
			})
	}

	toggleFavoriteFolder() {
		const { relay } = this.props
		toggleFavoriteShopcastItemMutation.commit(
			relay.environment,
			this.folder.id,
			"folder",
			this.sendErrorsToToast("Could not complete the action"),
			this.container.id
		)
	}

	requestMove(callback, skipFrom) {
		this.setState({ shopcastMoveRequested: { callback, skipFrom } })
	}

	requestCopy(callback) {
		this.setState({ shopcastCopyRequested: { callback } })
	}

	requestEdit(item, type) {
		this.setState({
			editing: {
				type,
				item,
			},
		})
	}

	endShopcastEdit() {
		this.setState({ editing: null })
	}

	requestLookbookEdit({ id }) {
		const { navigate } = this.props
		navigate(`/shopboard/create/${id}`)
	}

	sendClientInvite() {
		const { relay } = this.props
		sendClientInviteMutation.commit(
			relay.environment,
			this.folder.clients[0].id,
			() => {
				toast.success(<>Invite sent successfully</>, {
					autoClose: 2000,
					closeButton: false,
				})
			},
			() => {
				toast.error(<>Could not send client invite</>, {
					autoClose: 2000,
					closeButton: false,
				})
			}
		)
	}

	//
	// ─── RENDERS ────────────────────────────────────────────────────────────────────
	//

	// renderPath() {
	// 	const { path: pathReversed, isArchived } = this.folder
	// 	if (!pathReversed.length) return null
	// 	const ITEM_COLLAPSE_COUNT = 3
	// 	const { history } = this.props
	// 	const interleave = (arr, thing) =>
	// 		[].concat(...arr.map((n, i) => [n, thing(i)])).slice(0, -1)
	// 	const getLink = (key, handler, name) =>
	// 		handler ? (
	// 			<a
	// 				className={pageBreadcrumbs.link}
	// 				key={key}
	// 				onClick={handler}
	// 				tabIndex={-5}
	// 				role="link"
	// 				onKeyPress={handler}
	// 			>
	// 				{name}
	// 			</a>
	// 		) : (
	// 			<span key={key} className={pageBreadcrumbs.linkEnd}>
	// 				{name}
	// 			</span>
	// 		)
	// 	const getClickHandler = (id) => () =>
	// 		history.push(id ? `/content/${id}` : "/content")
	// 	const getMainWrapper = (children) => (
	// 		<div className={pageBreadcrumbs.root}>
	// 			<div className={pageBreadcrumbs.crumbs}>{children}</div>
	// 		</div>
	// 	)
	// 	const path = [...pathReversed].reverse()
	// 	const listItems = [
	// 		getLink(
	// 			"origin1",
	// 			getClickHandler(isArchived ? "archived" : null),
	// 			isArchived ? "Trash" : "Home"
	// 		),
	// 	]
	// 	if (path.length < ITEM_COLLAPSE_COUNT) {
	// 		listItems.push(
	// 			...path.map((p, i) =>
	// 				getLink(
	// 					p.value,
	// 					i < path.length - 1 ? getClickHandler(p.value) : null,
	// 					p.name
	// 				)
	// 			)
	// 		)
	// 		return getMainWrapper(
	// 			interleave(listItems, (key) => (
	// 				<SlashIcon key={key} className={pageBreadcrumbs.arrow} />
	// 			))
	// 		)
	// 	}
	// 	listItems.push(
	// 		getLink(path[0].value, getClickHandler(path[0].value), path[0].name)
	// 	)
	// 	const midItems = []
	// 	path.slice(1, path.length - 1).forEach((p) => {
	// 		midItems.push({
	// 			click: getClickHandler(p.value),
	// 			name: p.name,
	// 		})
	// 	})
	// 	listItems.push(
	// 		<DropdownMenu
	// 			items={midItems}
	// 			key="dropdown"
	// 			context="folder"
	// 			darkTheme
	// 			triggerClass={buttons.subtleIcon}
	// 			triggerClassActive={buttons.subtleIconActive}
	// 			count={path.length}
	// 		/>
	// 	)
	// 	listItems.push(
	// 		getLink(path[path.length - 1].value, null, path[path.length - 1].name)
	// 	)
	// 	return getMainWrapper(
	// 		interleave(listItems, (key) => (
	// 			<SlashIcon key={key} className={pageBreadcrumbs.arrow} />
	// 		))
	// 	)
	// }

	renderContentMenu(routeType) {
		const listItems = []
		const { navigate, relay, team } = this.props
		const push = (click, name, key, icon) =>
			listItems.push({
				click,
				name,
				key,
				icon,
			})
		const icon = (
			<>
				<PlusIcon className={buttons.icon} />
				Add New
			</>
		)
		// push(null, "Add New", "heading-addnew", null)
		if (routeType === "root") {
			push(
				runIfFeatureAvailable(
					() => this.setState({ createClientFolder: {} }),
					FeatureKeyENUM.CLIENT_CRM,
					relay.environment,
					team
				),
				"Client",
				"createClient",
				<AvatarIcon className={stylesDropdown.itemIcon} />
			)
			listItems.push("divide")
		}
		if (!this.folder.isParentAClient) {
			const isClient = this.folder.clients && this.folder.clients.length > 0
			push(
				() => this.handleNewFolderClick(),
				isClient ? "Lookbook" : "Folder",
				"createfolder",
				<FolderIcon className={stylesDropdown.itemIcon} />
			)
			listItems.push("divide")
		}
		push(
			() => {
				navigate(
					`/shopboard/create${
						this.folder.path.length > 0
							? `?folderId=${this.folder.id}`
							: ""
					}`
				)
			},
			"Board",
			"create",
			<FrameIcon className={stylesDropdown.itemIcon} />
		)
		push(
			() => {
				this.requestEdit({}, "shopcast")
			},
			"Video",
			"uploadvideo",
			<PlayIcon className={stylesDropdown.itemIcon} />
		)
		push(
			() => {
				this.setState({ openUploadFileModal: true })
			},
			"File",
			"uploadfiles",
			<FileIcon className={stylesDropdown.itemIcon} />
		)

		return (
			<DropdownMenu
				items={listItems}
				darkTheme
				contentRight
				mainIcon={icon}
				triggerClass={buttons.primary}
				triggerClassActive={buttons.primaryActive}
			/>
		)
	}

	renderItems(routeType, options, count) {
		const { itemType, header, tabKey } = options
		const { folder, search, team } = this.props
		const {
			selectedItems,
			itemsFilter,
			currentTab,
			expandedTabs,
		} = this.state

		const isClient = this.folder.clients && this.folder.clients.length > 0
		const type = itemType || itemsFilter.type
		let ListOfFolderItemsComp = getComponent(
			`list-of-folder-items-${type}`,
			ListOfFolderItems,
			{
				folder,
				count: currentTab ? 12 : 4,
				type,
				sort: itemsFilter.sort,
				search,
				teamId: team,
			},
			{
				folder,
				count: currentTab ? 12 : 4,
				type,
				sort: itemsFilter.sort,
				search,
				teamId: team,
			},
			true
		)

		ListOfFolderItemsComp =
			(currentTab || expandedTabs[itemType]) &&
			ListOfFolderItemsComp

		return (
			<div
				key={tabKey}
				className={
					expandedTabs[itemType] ? section.root : section.rootClosed
				}
			>
				{(!currentTab || search) && (
					<div className={collapsibleHeader.root}>
						<a
							role="link"
							tabIndex={0}
							className={collapsibleHeader.titleLink}
							onClick={() =>
								this.setState((s) => ({
									expandedTabs: {
										...s.expandedTabs,
										[itemType]: !s.expandedTabs[itemType],
									},
								}))
							}
							onKeyPress={() =>
								this.setState((s) => ({
									expandedTabs: {
										...s.expandedTabs,
										[itemType]: !s.expandedTabs[itemType],
									},
								}))
							}
						>
							<h2 className={collapsibleHeader.title}>
								{expandedTabs[itemType] ? (
									<ChevronDownIcon
										className={collapsibleHeader.icon}
									/>
								) : (
									<ChevronRightIcon
										className={collapsibleHeader.icon}
									/>
								)}
								{header || "Content"} {search && <span>({count})</span>}
							</h2>
						</a>
						<div className={collapsibleHeader.actions}>
							<a
								role="link"
								className={collapsibleHeader.link}
								tabIndex="0"
								onClick={(e) => {
									this.activateTab(e, tabKey)
								}}
							>
								See all
							</a>
						</div>
					</div>
				)}
				{ListOfFolderItemsComp && (
					<div className={section.body}>
						<ListOfFolderItemsComp
							details={{
								noLoadMore: !currentTab,
								selectedItems,
								sendErrorsToToast: this.sendErrorsToToast,
								confirmDelete: this.confirmDelete,
								handleItemSelect: this.handleItemSelect,
								handleItemDeselect: this.handleItemDeselect,
								requestMove: this.requestMove,
								requestCopy:
									this.validTeams.length > 0 ? this.requestCopy : null,
								routeType,
								requestShopcastEdit: this.requestEdit,
								requestLookbookEdit: this.requestLookbookEdit,
								isParentAClient:
									isClient || this.folder.isParentAClient,
							}}
						/>
					</div>
				)}
			</div>
		)
	}

	renderFolders(routeType, itemType, count) {
		if (routeType === "public") {
			return false
		}
		const { state } = this
		const {
			creatingNewFolder,
			selectedItems,
			currentTab,
			expandedTabs,
			itemsFilter,
		} = state
		const { folder, search, team } = this.props

		// const archived = folder === "archived" || this.folder.isArchived

		const getFolderName = () => {
			if (itemType === "clients") return "Clients"
			const isClient = this.folder.clients && this.folder.clients.length > 0
			return isClient ? "Lookbooks" : "Folders"
		}

		const sectionHeader = () => (
			<div className={collapsibleHeader.root}>
				<a
					role="link"
					tabIndex={0}
					className={collapsibleHeader.titleLink}
					onClick={() =>
						this.setState((s) => ({
							expandedTabs: {
								...s.expandedTabs,
								[itemType]: !s.expandedTabs[itemType],
							},
						}))
					}
					onKeyPress={() =>
						this.setState((s) => ({
							expandedTabs: {
								...s.expandedTabs,
								[itemType]: !s.expandedTabs[itemType],
							},
						}))
					}
				>
					<h2 className={collapsibleHeader.title}>
						{expandedTabs[itemType] ? (
							<ChevronDownIcon className={collapsibleHeader.icon} />
						) : (
							<ChevronRightIcon className={collapsibleHeader.icon} />
						)}
						{getFolderName()} {search && <span>({count})</span>}
					</h2>
				</a>
				{routeType !== "public" && (
					<div className={collapsibleHeader.actions}>
						<a
							role="link"
							className={collapsibleHeader.link}
							tabIndex="0"
							onClick={(e) => {
								this.activateTab(
									e,
									itemType === "clients" ? "clients" : "folders"
								)
							}}
						>
							See all
						</a>
					</div>
				)}
			</div>
		)

		let ListOfFoldersComp = getComponent(
			`list-of-folders-${itemType}`,
			ListOfFolders,
			{
				folder,
				count: currentTab ? 12 : 4,
				onlyClients: itemType === "clients",
				isShared: null,
				sort: itemsFilter.sort,
				search,
				teamId: team,
			},
			{
				folder,
				count: currentTab ? 12 : 4,
				creatingNewFolder,
				onlyClients: itemType === "clients",
				isShared: null,
				sort: itemsFilter.sort,
				search,
				teamId: team,
			},
			true
		)

		ListOfFoldersComp = (currentTab || expandedTabs[itemType]) && ListOfFoldersComp

		return (
			<div
				key={itemType}
				className={
					expandedTabs[itemType] ? section.root : section.rootClosed
				}
			>
				{(!currentTab || search) && sectionHeader()}
				{ListOfFoldersComp && (
					<div className={section.body}>
						<ListOfFoldersComp
							details={{
								setFolderRefetch: this.setFolderRefetch,
								noLoadMore: !currentTab,
								selectedItems,
								sendErrorsToToast: this.sendErrorsToToast,
								confirmDelete: this.confirmDelete,
								handleItemSelect: this.handleItemSelect,
								handleItemDeselect: this.handleItemDeselect,
								requestMove: this.requestMove,
								requestCopy:
									this.validTeams.length > 0 ? this.requestCopy : null,
								routeType,
								handleEndFolderCreation: this.handleEndFolderCreation,
								isMainFolderClient: this.folder.clients.length > 0,
								setClientFolder: this.setClientFolder,
							}}
						/>
					</div>
				)}
			</div>
		)
	}

	renderTabs(folder) {
		const { currentTab } = this.state
		const tabs = this.getTabs(folder)
		const tab = tabs.find((t) => t.id === currentTab)
		if (tab)
			return tab.count > 0 ? tab.render() : this.renderEmptyState(folder)

		const activeTabs = tabs.filter((i) => i.isActive && i.count > 0)
		if (activeTabs.length > 0) return activeTabs.map((t) => t.render())
		return this.renderEmptyState(folder)
	}

	renderDialogs() {
		const {
			itemDeleteRequested,
			shopcastMoveRequested,
			shopcastCopyRequested,
			treeViewSelectedFolder,
			editing,
			openUploadFileModal,
			creatingNewFolder,
			createClientFolder,
			selectedTeam,
		} = this.state
		const {
			relay,
			store: { currentUser, categories },
			navigate,
			team,
		} = this.props
		const { userClients } = currentUser
		const isClient = this.folder.clients && this.folder.clients.length > 0
		return (
			<div>
				{itemDeleteRequested && (
					<ConformationPrompt
						openModal={!!itemDeleteRequested}
						closeModal={this.handleDeleteConfirmationModalCancel}
						approveEvent={this.handleDeleteConfirmationModalConfirm}
						title="Delete Forever"
						declineEvent={this.handleDeleteConfirmationModalCancel}
						approveText="Delete Forever"
						approveButtonStyle={buttons.primaryRed}
						declineText="Cancel"
					>
						Do you want to delete the {itemDeleteRequested.name}{" "}
						permanently?
						<br />
						<b>This action cannot be undone.</b>
					</ConformationPrompt>
				)}
				{shopcastMoveRequested && (
					<ConformationPrompt
						openModal={!!shopcastMoveRequested}
						closeModal={this.handleMoveShopcastModalCancel}
						approveEvent={this.handleMoveShopcastModalConfirm}
						title="Move to..."
						declineEvent={this.handleMoveShopcastModalCancel}
						approveText="Move Here"
						approveButtonStyle={buttons.primary}
						declineText="Cancel"
					>
						<MoveToFolderModal
							relay={relay}
							skipFrom={shopcastMoveRequested.skipFrom}
							folder={this.folder.id}
							team={team}
							treeViewSelectedFolder={treeViewSelectedFolder}
							onTreeViewToggle={this.onTreeViewToggle}
							showAll
						/>
					</ConformationPrompt>
				)}
				{shopcastCopyRequested && (
					<ConformationPrompt
						openModal={!!shopcastCopyRequested}
						closeModal={() =>
							this.setState({ shopcastCopyRequested: null })
						}
						approveEvent={() => {
							const { selectedTeam: st } = this.state
							shopcastCopyRequested.callback(st)
							this.setState({ shopcastCopyRequested: null })
						}}
						title="Copy to..."
						declineEvent={() =>
							this.setState({ shopcastCopyRequested: null })
						}
						approveText="Copy Here"
						approveButtonStyle={buttons.primary}
						declineText="Cancel"
					>
						{this.validTeams.map((t) => (
							<button
								type="button"
								className={stylesUserMenuDD.itemAvatar}
								disabled={selectedTeam?.id === t.id}
								onClick={() => {
									this.setState({ selectedTeam: t })
								}}
							>
								<span className={stylesUserMenuDD.itemLabel}>
									<Avatar
										size="40px"
										photoUrl={t.avatar}
										fullname={t.name}
									/>
									{t.name}
								</span>
								{selectedTeam?.id === t.id && (
									<CheckIcon className={stylesUserMenuDD.itemIcon} />
								)}
							</button>
						))}
					</ConformationPrompt>
				)}
				<FileUpload
					openModal={openUploadFileModal}
					closeModal={() => {
						this.setState({ openUploadFileModal: false })
					}}
					relay={relay}
					folder={this.folder.id}
					team={team}
				/>

				{!!editing && editing.type === "shopcast" && (
					<ShopcastEdit
						close={this.endShopcastEdit}
						shopcast={editing.item}
						relay={relay}
						categories={categories}
						parentId={this.folder.id}
						team={team}
					/>
				)}
				{!!editing && editing.type === "lookbook" && (
					<LookbookSavingSimple
						closeModal={this.endShopcastEdit}
						lookbook={editing.item}
						relay={relay}
					/>
				)}
				{!!editing && editing.type === "shopshareFile" && (
					<FileEdit
						closeModal={() => {
							this.endShopcastEdit()
						}}
						relay={relay}
						file={editing.item}
					/>
				)}
				{!!editing && editing.type === "collection" && (
					<ProductCollectionCreateEditModal
						collectionToEdit={editing.item}
						relay={relay}
						currentUser={currentUser}
						userClients={userClients?.edges || []}
						other={{
							parentId: this.folder.id,
							containerId: this.container.id,
						}}
						onCancel={() => {
							this.endShopcastEdit()
						}}
					/>
				)}
				{creatingNewFolder && (
					<CreateFolder
						relay={relay}
						onSubmit={(title) => {
							this.handleEndFolderCreation()
							createNewFolderMutation.commit(
								relay.environment,
								title,
								this.folder.id,
								this.container.id,
								team,
								() => {
									if (this.setFolderRefetch) this.setFolderRefetch()
								},
								this.sendErrorsToToast("Could not create the folder")
							)
						}}
						closeModal={this.handleEndFolderCreation}
						isClient={isClient}
					/>
				)}
				{createClientFolder && (
					<CreateEditClient
						relay={relay}
						client={createClientFolder}
						closeModal={(redirect) => {
							if (redirect) navigate("/content")
							this.setState({ createClientFolder: null })
						}}
						folders={[]}
						team={team}
						parentId={this.folder.id}
					/>
				)}
			</div>
		)
	}

	renderEmptyState(routeType) {
		const { search } = this.props
		let data = {}

		if (search)
			data = {
				title: "No results found",
				message: "Please try different criteria.",
				icon: <MagnifyingGlassIcon className={empty.icon} />,
			}
		else {
			switch (routeType) {
				case "archived":
					data = {
						title: "Nothing in Trash",
						message: "Any content you delete will end up here.",
						icon: <CrumpledPaperIcon className={empty.icon} />,
					}
					break
				case "public":
					data = {
						title: "You haven't published anything yet",
						message:
							"Any content you add to your shopping channel will appear here.",
						icon: <AvatarIcon className={empty.icon} />,
						// button: (
						// 	<Link
						// 		className={empty.cta}
						// 		to={{
						// 			pathname: "/edit-preview",
						// 			state: {
						// 				prevUrl: window.location.pathname,
						// 			},
						// 		}}
						// 	>
						// 		Edit My Channel
						// 	</Link>
						// ),
					}
					break
				default:
					data = {
						title: "There's nothing here",
						message: "Any content you create or upload will appear here.",
						icon: <CollectionIcon className={empty.icon} />,
					}
					break
			}
		}
		return (
			<div className={empty.root}>
				<div className={empty.container}>
					<div className={empty.content}>
						{data.icon}
						<h4 className={empty.headline}>{data.title}</h4>
						<p className={empty.subline}>{data.message}</p>
						{data.button}
					</div>
				</div>
			</div>
		)
	}

	renderTabItem(id, name) {
		return (
			<div
				key={id}
				role="button"
				tabIndex="0"
				ref={(ref) => {
					this.tabsRef[id] = ref
				}}
				className={this.getActiveTabClass(id)}
				onClick={(e) => {
					this.activateTab(e, id)
				}}
			>
				{name}
			</div>
		)
	}

	renderSortFilter() {
		const { itemsFilter } = this.state
		return (
			<select
				value={itemsFilter.sort}
				className={controls.select}
				onChange={(e) => {
					const obj = {
						...itemsFilter,
						sort: e.target.value,
					}
					this.setState({ itemsFilter: obj }, () => {
						localStorage.setItem("itemsFilter", JSON.stringify(obj))
					})
				}}
			>
				<optgroup label="Sort by">
					<option value="n-to-o">Newest to Oldest</option>
					<option value="o-to-n">Oldest to Newest</option>
					<option value="name">Name A-Z</option>
					<option value="name-d">Name Z-A</option>
				</optgroup>
			</select>
		)
	}

	// renderFolderIcon() {
	// 	const folderType = this.getFolderId()
	// 	if (folderType === "root")
	// 		return <WorkspaceIcon className={icons.icon48} />
	// 	if (folderType === "public")
	// 		return <AvatarIcon className={icons.icon48} />
	// 	if (folderType === "archived")
	// 		return <CrumpledPaperIcon className={icons.icon48} />
	// 	const client = this.folder.clients[0]
	// 	if (client)
	// 		return (
	// 			<Avatar
	// 				photoUrl={client.avatar || this.folder.avatar}
	// 				styleName={styles.avatarIcon}
	// 				fullname={this.folder.name}
	// 				size="56px"
	// 			/>
	// 		)
	// 	if (!this.folder.sharableLink)
	// 		return <FolderIcon className={icons.icon48} />
	// 	if (!this.folder.isParentAClient)
	// 		return <FolderShareIcon className={icons.icon48} />
	// 	return <FolderLookbookIcon className={icons.icon48} />
	// }

	renderContent(folder) {
		const { selectedItems, tabStyle } = this.state
		const { relay, search, team } = this.props
		const archived = folder === "archived" || this.folder.isArchived
		const publicRoute = folder === "public"
		const isClient = this.folder.clients && this.folder.clients.length > 0
		const tabs = [
			{
				id: "clients",
				name: "Clients",
				count: this.folder.clientsCount,
				isActive: folder === "root",
			},
			{
				id: "folders",
				name: isClient ? "Lookbooks" : "Folders",
				count: this.folder.foldersCount + this.folder.sharedFoldersCount,
				isActive: !publicRoute && !this.folder.isParentAClient,
			},
			{
				id: "shopcasts",
				name: "Shopcasts",
				count: this.folder.shopcastsCount,
				isActive: true,
			},
			{
				id: "shopboards",
				name: "Shopboards",
				count: this.folder.lookbooksCount,
				isActive: true,
			},
			{
				id: "collections",
				name: "Collections",
				count: this.folder.collectionCount,
				isActive: folder === "any" || publicRoute || !!search,
			},
			{
				id: "files",
				name: "Files",
				count: this.folder.filesCount,
				isActive: true,
			},
		]
		const activeTabs = tabs.filter((tab) => tab.isActive && tab.count > 0)
		const activeTabsItemCount = activeTabs.reduce(
			(acc, tab) => acc + tab.count,
			0
		)
		const isClientUserNotExists =
			this.folder.clients &&
			this.folder.clients.length > 0 &&
			!this.folder.clients[0].isUserAlreadyExist

		return (
			<>
				<main className={twoColumn.main}>
					<div className={pageHeader.root}>
						{/*	<div className={pageHeader.crumbs}>
							{this.renderPath()} 
							{!archived && !publicRoute && this.renderContentMenu()}
						</div> */}

						<div className={pageHeader.header}>
							<div className={pageHeader.content}>
								<div className={pageHeader.titleWithAvatar}>
									{/* {this.renderFolderIcon()} */}
									<div className={pageHeader.titleWithAction}>
										<h1 className={pageHeader.title}>
											{this.getFolderName(activeTabsItemCount)}
										</h1>
										{!!this.canStar && (
											<Star
												onAction={this.toggleFavoriteFolder}
												starred={this.folder.isStarred}
											/>
										)}
									</div>
								</div>

								<div className={pageHeader.actions}>
									<FolderMenu
										folder={this.folder}
										relay={relay}
										container={this.container.id}
										setClientFolder={(obj) =>
											this.setState({
												createClientFolder: obj,
											})
										}
										confirmDelete={this.confirmDelete}
										requestMove={this.requestMove}
										requestCopy={
											this.validTeams.length > 0 && this.requestCopy
										}
										redirectAfterDelete
										isAdmin={this.isAdmin}
										team={team}
									/>
									{this.getFolderLink(folder) && (
										<>
											{!isClientUserNotExists && (
												<ClipboardButton
													className={`${buttons.subtleIcon} ${buttons.iconLg}`}
													data-clipboard-text={this.getFolderLink(
														folder
													)}
													data-tooltip-id="tooltip"
													data-tooltip-content="Get Sharable Link"
													onSuccess={() => {
														toast.success(<>Copied</>, {
															autoClose: 2000,
															closeButton: false,
														})
													}}
												>
													<Link2Icon className={icons.icon21} />
												</ClipboardButton>
											)}

											<a
												className={`${buttons.subtleIcon} ${buttons.iconLg}`}
												href={this.getFolderLink(folder)}
												rel="noreferrer"
												target="_blank"
												data-tooltip-id="tooltip"
												data-tooltip-content="View Channel"
											>
												<ExternalLinkIcon
													className={icons.icon21}
												/>
											</a>
											{isClientUserNotExists && (
												<button
													type="button"
													className={`${buttons.subtleIcon} ${buttons.iconLg}`}
													data-tooltip-id="tooltip"
													data-tooltip-content="Send Client Invite"
													onClick={this.sendClientInvite}
												>
													<PaperPlaneIcon
														className={icons.icon21}
													/>
												</button>
											)}
										</>
									)}

									{publicRoute && (
										<Link
											className={buttons.primary}
											to={
												team
													? `/edit-preview/${team}`
													: "/edit-preview"
											}
											state={{ prevUrl: window.location.pathname }}
										>
											Edit Channel
										</Link>
									)}
									{!archived &&
										!publicRoute &&
										this.renderContentMenu(folder)}
								</div>
							</div>
						</div>
					</div>

					{activeTabs.length > 0 && (
						<div className={controls.rootTabFilters}>
							<nav className={nav.tabs}>
								{this.renderTabItem("", "All")}
								{activeTabs.map((tab) =>
									tab.featureKey ? (
										<FeatureAvailableComponent
											featureKey={FeatureKeyENUM.CLIENT_CRM}
											env={relay.environment}
											key={tab.id}
										>
											{}
										</FeatureAvailableComponent>
									) : (
										this.renderTabItem(tab.id, tab.name)
									)
								)}
								<span className={nav.tabUnderline} style={tabStyle} />
							</nav>

							{/* <div className={controls.tabsSelect}>
								<select
									value={currentTab}
									className={controls.select}
									onChange={(e) => {
										this.activateTab(e, e.target.value)
									}}
								>
									<option value="">All</option>
									{activeTabs.map((tab) =>
										tab.featureKey ? (
											<FeatureAvailableComponent
												featureKey={FeatureKeyENUM.CLIENT_CRM}
												env={relay.environment}
												key={tab.id}
											>
												<option key={tab.id} value={tab.id}>
													{tab.name}
												</option>
											</FeatureAvailableComponent>
										) : (
											<option key={tab.id} value={tab.id}>
												{tab.name}
											</option>
										)
									)}
								</select>
							</div> */}
							{this.renderSortFilter()}
						</div>
					)}

					<div className={pageContent.root}>
						{this.renderTabs(folder)}

						{archived && !!selectedItems && selectedItems.length > 0 && (
							<div className={actionsBar.root}>
								<div className={actionsBar.inner}>
									<div className={actionsBar.count}>
										{selectedItems.length} selected
									</div>
									<div className={actionsBar.group}>
										{folder === "archived" && (
											<button
												type="button"
												className={actionsBar.btn}
												data-tooltip-id="tooltip"
												data-tooltip-content="Restore"
												onClick={this.handleRestoreClick}
											>
												<ResetIcon className={icons.icon21} />
											</button>
										)}
										<button
											type="button"
											className={actionsBar.btn}
											data-tooltip-id="tooltip"
											data-tooltip-content="Delete permanently"
											onClick={this.handleDeleteButtonClick}
										>
											<TrashIcon className={icons.icon21} />
										</button>
									</div>
									<button
										type="button"
										className={actionsBar.btnClose}
										data-tooltip-id="tooltip"
										data-tooltip-content="Cancel"
										onClick={() => {
											this.setState({ selectedItems: [] })
										}}
									>
										<Cross2Icon className={icons.icon24} />
									</button>
								</div>
							</div>
						)}

						{!archived && !!selectedItems.length && (
							<div className={actionsBar.root}>
								<div className={actionsBar.inner}>
									<div className={actionsBar.count}>
										{selectedItems.length} selected
									</div>
									<div className={actionsBar.group}>
										{this.validTeams.length > 0 && (
											<button
												type="button"
												className={actionsBar.btn}
												data-tooltip-id="tooltip"
												data-tooltip-content="Copy to..."
												onClick={this.handleCopyButtonClick}
											>
												<CopyIcon className={icons.icon21} />
											</button>
										)}
										<button
											type="button"
											className={actionsBar.btn}
											data-tooltip-id="tooltip"
											data-tooltip-content="Move to..."
											onClick={this.handleMoveButtonClick}
										>
											<FolderIcon className={icons.icon21} />
										</button>
										{folder !== "archived" &&
											selectedItems.filter(
												(item) => item.type === "collection"
											).length === 0 && (
												<button
													type="button"
													className={actionsBar.btn}
													data-tooltip-id="tooltip"
													data-tooltip-content="Move to Trash"
													onClick={this.handleArchiveButtonClick}
												>
													<CrumpledPaperIcon
														className={icons.icon21}
													/>
												</button>
											)}
										<button
											type="button"
											className={actionsBar.btn}
											data-tooltip-id="tooltip"
											data-tooltip-content="Delete permanently"
											onClick={this.handleDeleteButtonClick}
										>
											<TrashIcon className={icons.icon21} />
										</button>
										{/* <button
											type="button"
											className={actionsBar.btn}
											data-tooltip-id="tooltip"
											data-tooltip-content="Download Selected"
											onClick={this.handleDownloadSeleected}
										>
											<DownloadIcon className={icons.icon21} />
										</button> */}
									</div>
									<button
										type="button"
										className={actionsBar.btnClose}
										data-tooltip-id="tooltip"
										data-tooltip-content="Cancel"
										onClick={() => {
											this.setState({ selectedItems: [] })
										}}
									>
										<Cross2Icon className={icons.icon24} />
									</button>
								</div>
							</div>
						)}
					</div>
				</main>
				{this.renderDialogs()}
			</>
		)
	}

	render() {
		const { team } = this.props
		if (
			this.folder.path.length > 0 &&
			(this.folder.team?.id || null) !== team
		) {
			return <Navigate to="/content" />
		}
		const folder = this.getFolderId()
		return this.renderContent(folder)
	}
}

//
// ─── DATA REQUIREMENTS ──────────────────────────────────────────────────────────
//

ShopcastManager = createFragmentContainer(ShopcastManager, {
	store: graphql`
		fragment shopcastManager_store on Store
		@argumentDefinitions(
			folder: { type: "String" }
			search: { type: "String" }
			teamId: { type: "String" }
		) {
			id
			categories {
				name
				id
			}
			currentUser {
				id
				adminAccess
				role
				username
				customDomain
				profilePhoto
				bannerPhoto
				userClients {
					edges {
						node {
							id
							fullname
							email
						}
					}
				}
				teams {
					id
					name
					avatar
					features
				}
				shopcastContainer {
					id
					folder(id: $folder) {
						id
						name
						description
						isStarred
						isArchived
						inRoot
						team {
							id
						}
						path {
							name
							value
						}
						clients {
							id
							email
							fullname
							avatar
							isUserAlreadyExist
						}
						parent {
							id
							name
						}
						isParentAClient
						sharableLink
						avatar
						bannerPhoto
						isPinned
						shopcastsCount(search: $search, teamId: $teamId)
						lookbooksCount(search: $search, teamId: $teamId)
						filesCount(search: $search, teamId: $teamId)
						collectionCount(search: $search, teamId: $teamId)
						foldersCount(search: $search, teamId: $teamId)
						sharedFoldersCount(search: $search, teamId: $teamId)
						clientsCount(search: $search, teamId: $teamId)

						lookbooks(first: 100)
							@connection(
								key: "currentUser_shopcastManager_lookbooks"
								filters: ["first"]
							) {
							edges {
								node {
									id
								}
							}
						}
						shopcasts(first: 100)
							@connection(
								key: "currentUser_shopcastManager_shopcasts"
								filters: ["first"]
							) {
							edges {
								node {
									id
								}
							}
						}
						items(first: 100)
							@connection(
								key: "currentUser_shopcastManager_items"
								filters: ["first"]
							) {
							edges {
								node {
									__typename
								}
							}
						}
						folders(first: 100)
							@connection(
								key: "currentUser_shopcastManager_folders"
								filters: ["first"]
							) {
							edges {
								node {
									id
								}
							}
						}
					}
				}
			}
		}
	`,
})

const query = graphql`
	query shopcastManagerQuery(
		$folder: String
		$search: String
		$teamId: String
	) {
		store {
			...shopcastManager_store
				@arguments(folder: $folder, search: $search, teamId: $teamId)
		}
	}
`

export default {
	Component: withRouter(ShopcastManager),
	query,
	needAuth: true,
	params: { folder: null, search: null, teamId: null },
}
