import React from "react"
import LookbookDropdown from "./lookbook-dropdown"
import styles from "./lookbook-dropdown.css"

const FontSizeList = [
	4,
	6,
	8,
	10,
	12,
	14,
	16,
	18,
	20,
	24,
	28,
	32,
	36,
	40,
	44,
	48,
	56,
	64,
	72,
	80,
]

export default function FontSizePicker({
	fontSize: textSize,
	runDebounce,
	dataTip,
	onChange,
}) {
	const fontSize = parseInt(textSize, 10)
	const getFontSizeList = !FontSizeList.includes(fontSize)
		? [...FontSizeList, fontSize]
		: FontSizeList
	getFontSizeList.sort((a, b) => a - b)
	const listItems = getFontSizeList.map((x) => ({
		click: () => onChange({ fontSize: x }),
		name: x,
	}))
	const activeItem = listItems.find((x) => x.name === fontSize)
	return (
		<LookbookDropdown
			label={fontSize}
			textButton
			data-tooltip-id="tooltip"
			data-tooltip-content={dataTip}
			runDebounce={runDebounce}
		>
			<div className={styles.panelFontSize}>
				<div className={styles.scroll}>
					{listItems.map((x) => (
						<div
							key={x.name}
							onClick={x.click}
							tabIndex={-1}
							role="link"
							className={
								activeItem === x ? styles.itemActive : styles.item
							}
						>
							{x.name}
						</div>
					))}
				</div>
			</div>
		</LookbookDropdown>
	)
}
