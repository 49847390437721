/**
 * @generated SignedSource<<1c1a74670787f4703bc8326cfc1bd2a0>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Mutation } from 'relay-runtime';
export type SaveRakutenSiteInput = {|
  clientMutationId?: ?string,
  id?: ?string,
  values?: ?SaveRakutenSiteInputValue,
|};
export type SaveRakutenSiteInputValue = {|
  category?: ?string,
  commissionRate?: ?string,
  description?: ?string,
  displayName?: ?string,
  displayUrl?: ?string,
  imageName?: ?string,
  isPublic?: ?boolean,
  logoName?: ?string,
  networks?: ?$ReadOnlyArray<?string>,
  specialCommissionRate?: ?string,
  specialCommissionRateValidUntil?: ?string,
  urls?: ?$ReadOnlyArray<?string>,
|};
export type saveRakutenSiteMutation$variables = {|
  input: SaveRakutenSiteInput,
|};
export type saveRakutenSiteMutation$data = {|
  +saveRakutenSite: ?{|
    +retailerCategories: ?$ReadOnlyArray<?{|
      +id: string,
      +name: ?string,
    |}>,
    +site: ?{|
      +category: ?{|
        +id: string,
      |},
      +commissionRate: ?string,
      +description: ?string,
      +displayName: ?string,
      +displayUrl: ?string,
      +hasSpecialRate: ?boolean,
      +imageUrl: ?string,
      +isPublic: ?boolean,
      +logoUrl: ?string,
      +networks: ?$ReadOnlyArray<?{|
        +currency: ?string,
        +id: string,
        +name: ?string,
      |}>,
      +specialCommissionRate: ?string,
      +specialCommissionRateValidUntil: ?string,
      +urls: ?$ReadOnlyArray<?string>,
    |},
  |},
|};
export type saveRakutenSiteMutation = {|
  response: saveRakutenSiteMutation$data,
  variables: saveRakutenSiteMutation$variables,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "urls",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "displayName",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "Category",
  "kind": "LinkedField",
  "name": "category",
  "plural": false,
  "selections": [
    (v5/*: any*/)
  ],
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "commissionRate",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "imageUrl",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "displayUrl",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "logoUrl",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isPublic",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "hasSpecialRate",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "specialCommissionRate",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "specialCommissionRateValidUntil",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "concreteType": "SiteNetwork",
  "kind": "LinkedField",
  "name": "networks",
  "plural": true,
  "selections": [
    (v5/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "currency",
      "storageKey": null
    },
    (v15/*: any*/)
  ],
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "concreteType": "Category",
  "kind": "LinkedField",
  "name": "retailerCategories",
  "plural": true,
  "selections": [
    (v15/*: any*/),
    (v5/*: any*/)
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "saveRakutenSiteMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SaveRakutenSitePayload",
        "kind": "LinkedField",
        "name": "saveRakutenSite",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "RakutenSite",
            "kind": "LinkedField",
            "name": "site",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/),
              (v12/*: any*/),
              (v13/*: any*/),
              (v14/*: any*/),
              (v16/*: any*/)
            ],
            "storageKey": null
          },
          (v17/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "ShopshareMutationRoot",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "saveRakutenSiteMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SaveRakutenSitePayload",
        "kind": "LinkedField",
        "name": "saveRakutenSite",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "RakutenSite",
            "kind": "LinkedField",
            "name": "site",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/),
              (v12/*: any*/),
              (v13/*: any*/),
              (v14/*: any*/),
              (v16/*: any*/),
              (v5/*: any*/)
            ],
            "storageKey": null
          },
          (v17/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "af6dade7f7118183d2c2bce86c009f9d",
    "id": null,
    "metadata": {},
    "name": "saveRakutenSiteMutation",
    "operationKind": "mutation",
    "text": "mutation saveRakutenSiteMutation(\n  $input: SaveRakutenSiteInput!\n) {\n  saveRakutenSite(input: $input) {\n    site {\n      urls\n      displayName\n      description\n      category {\n        id\n      }\n      commissionRate\n      imageUrl\n      displayUrl\n      logoUrl\n      isPublic\n      hasSpecialRate\n      specialCommissionRate\n      specialCommissionRateValidUntil\n      networks {\n        id\n        currency\n        name\n      }\n      id\n    }\n    retailerCategories {\n      name\n      id\n    }\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "46c81ecfb5248bd3a4816ba18c205a1d";

module.exports = ((node/*: any*/)/*: Mutation<
  saveRakutenSiteMutation$variables,
  saveRakutenSiteMutation$data,
>*/);
