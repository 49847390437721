/**
 * @generated SignedSource<<1108d3eb3e56f2672af9130a2ded45ae>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Mutation } from 'relay-runtime';
export type CreateCustomProductInput = {|
  affiliated_url?: ?string,
  brand?: ?string,
  clientMutationId?: ?string,
  collections?: ?$ReadOnlyArray<IdValue>,
  description?: ?string,
  offerPrice?: ?string,
  regularPrice?: ?string,
  title?: ?string,
  uploadId?: ?string,
  url?: ?string,
|};
export type IdValue = {|
  id?: ?string,
  value?: ?string,
|};
export type createCustomProductMutation$variables = {|
  input: CreateCustomProductInput,
|};
export type createCustomProductMutation$data = {|
  +createCustomProduct: ?{|
    +product: ?{|
      +affiliated_url: ?string,
      +brand: ?string,
      +custom_affiliated_url: ?string,
      +description: ?string,
      +diffbotImageUrl: ?string,
      +error: ?string,
      +hasProductImagesFromDiffbot: ?boolean,
      +id: string,
      +imageUrl: ?string,
      +offerPrice: ?string,
      +productCollections: ?$ReadOnlyArray<?{|
        +id: string,
        +title: ?string,
      |}>,
      +productImages: ?$ReadOnlyArray<?string>,
      +regularPrice: ?string,
      +title: ?string,
      +url: ?string,
    |},
    +store: ?{|
      +id: string,
    |},
  |},
|};
export type createCustomProductMutation = {|
  response: createCustomProductMutation$data,
  variables: createCustomProductMutation$variables,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v3 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "CreateCustomProductPayload",
    "kind": "LinkedField",
    "name": "createCustomProduct",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Store",
        "kind": "LinkedField",
        "name": "store",
        "plural": false,
        "selections": [
          (v1/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "ShopcastProduct",
        "kind": "LinkedField",
        "name": "product",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "brand",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "description",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "url",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "affiliated_url",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "custom_affiliated_url",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "imageUrl",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "diffbotImageUrl",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "regularPrice",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "offerPrice",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "error",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "productImages",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "hasProductImagesFromDiffbot",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ProductCollection",
            "kind": "LinkedField",
            "name": "productCollections",
            "plural": true,
            "selections": [
              (v1/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "createCustomProductMutation",
    "selections": (v3/*: any*/),
    "type": "ShopshareMutationRoot",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "createCustomProductMutation",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "49e76870c763137bf7f9692e03328147",
    "id": null,
    "metadata": {},
    "name": "createCustomProductMutation",
    "operationKind": "mutation",
    "text": "mutation createCustomProductMutation(\n  $input: CreateCustomProductInput!\n) {\n  createCustomProduct(input: $input) {\n    store {\n      id\n    }\n    product {\n      id\n      title\n      brand\n      description\n      url\n      affiliated_url\n      custom_affiliated_url\n      imageUrl\n      diffbotImageUrl\n      regularPrice\n      offerPrice\n      error\n      productImages\n      hasProductImagesFromDiffbot\n      productCollections {\n        id\n        title\n      }\n    }\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "b3de22d2dd03d83580d17f7021cea00e";

module.exports = ((node/*: any*/)/*: Mutation<
  createCustomProductMutation$variables,
  createCustomProductMutation$data,
>*/);
