/**
 * @generated SignedSource<<9a21cfd0505f393aa8eb028340d59789>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentType } from "relay-runtime";
declare export opaque type forgotPassword_store$fragmentType: FragmentType;
export type forgotPassword_store$data = {|
  +id: string,
  +$fragmentType: forgotPassword_store$fragmentType,
|};
export type forgotPassword_store$key = {
  +$data?: forgotPassword_store$data,
  +$fragmentSpreads: forgotPassword_store$fragmentType,
  ...
};
*/

var node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "forgotPassword_store",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "type": "Store",
  "abstractKey": null
};

(node/*: any*/).hash = "d4d78a643c35d10fae49c3c9c45bea21";

module.exports = ((node/*: any*/)/*: Fragment<
  forgotPassword_store$fragmentType,
  forgotPassword_store$data,
>*/);
