import React from "react"
import { FileIcon, defaultStyles } from "react-file-icon"
import { DashboardIcon } from "@radix-ui/react-icons"
import { createFragmentContainer, graphql } from "react-relay"
import card from "shared/styles/components/card.css"
import styles from "./client-folder-card.css"

const itemOrders = {
	Shopcast: 1,
	Lookbook: 2,
	ProductCollection: 3,
	ShopshareFile: 4,
}

class ClientFolderCard extends React.PureComponent {
	constructor(props) {
		super(props)
		const { folder } = props
		const items = [...folder.items.edges]
		this.images = items
			.sort(
				(a, b) =>
					itemOrders[a.node.__typename] - itemOrders[b.node.__typename]
			)
			.map((i) => this.getImageLinks(i.node))
			.flat()
	}

	getUrlExtension(url) {
		return url.split(/[#?]/)[0].split(".").pop().trim()
	}

	getImageGridClass(images) {
		if (images.length > 3) return card.imageGrid4
		if (images.length > 2) return card.imageGrid3
		if (images.length > 1) return card.imageGrid2
		return card.imageGrid1
	}

	getImage(url) {
		if (url.startsWith("file-icon:")) {
			const newUrl = url.replace("file-icon:", "")
			return (
				<div className={styles.posterIcon}>
					<FileIcon
						extension={this.getUrlExtension(newUrl)}
						{...defaultStyles[this.getUrlExtension(newUrl)]}
					/>
				</div>
			)
		}
		return (
			<img
				className={styles.poster}
				loading="lazy"
				src={`${url}?d=400`}
				alt="Poster"
			/>
		)
	}

	getImageLinks(node) {
		if (node.__typename === "ProductCollection")
			return node.images.map((x) => `${x}`)
		if (node.__typename === "ShopshareFile")
			return [
				node.thumbnailUrl
					? `${node.thumbnailUrl}`
					: `file-icon:${node.url}`,
			]
		return [`${node.posterUrl || node.url}`]
	}

	render() {
		const { folder, setFolder } = this.props
		return (
			<div className={styles.root}>
				<div
					className={styles.media}
					onClick={() => setFolder(folder)}
					role="link"
					tabIndex={0}
				>
					<div className={styles.figure}>
						<div className={styles.inner}>
							{this.images.length > 0 ? (
								<div className={this.getImageGridClass(this.images)}>
									<div className={card.igItem1}>
										{this.images.length > 0 &&
											this.getImage(this.images[0])}
									</div>
									<div className={card.igItem2}>
										{this.images.length > 1 &&
											this.getImage(this.images[1])}
									</div>
									<div className={card.igItem3}>
										{this.images.length > 2 &&
											this.getImage(this.images[2])}
									</div>
									<div className={card.igItem4}>
										{this.images.length > 3 &&
											this.getImage(this.images[3])}
									</div>
								</div>
							) : (
								<div className={card.imageGridEmpty}>
									<div className={card.igItemEmpty}>
										<DashboardIcon className={card.igItemIcon} />
									</div>
								</div>
							)}
						</div>
					</div>
				</div>
				<div className={styles.content}>
					<h4 className={styles.title}>{folder.name}</h4>
					<div className={styles.subtitle}>{folder.itemsCount} Items</div>
				</div>
			</div>
		)
	}
}

ClientFolderCard = createFragmentContainer(ClientFolderCard, {
	folder: graphql`
		fragment clientFolderCard_shopcastFolder on ShopcastFolder {
			id
			name
			itemsCount
			items(first: 4) {
				edges {
					node {
						__typename
						... on Shopcast {
							posterUrl
						}
						... on Lookbook {
							url
						}
						... on ShopshareFile {
							url
							thumbnailUrl
						}
						... on ProductCollection {
							id
							images
						}
					}
				}
			}
		}
	`,
})

export default ClientFolderCard
