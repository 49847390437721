/**
 * @generated SignedSource<<0aae34b87cb47bfe8867e0b428122559>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentType } from "relay-runtime";
declare export opaque type listOfRakutenSitesList_store$fragmentType: FragmentType;
export type listOfRakutenSitesList_store$data = {|
  +currentUser: ?{|
    +isRetailer: ?boolean,
  |},
  +rakutenSites: ?{|
    +edges: ?$ReadOnlyArray<?{|
      +node: ?{|
        +category: ?{|
          +id: string,
        |},
        +commissionRate: ?string,
        +countries: ?$ReadOnlyArray<?{|
          +code: ?string,
          +id: string,
          +name: ?string,
        |}>,
        +description: ?string,
        +displayName: ?string,
        +displayUrl: ?string,
        +hasSpecialRate: ?boolean,
        +id: string,
        +imageUrl: ?string,
        +isPublic: ?boolean,
        +logoUrl: ?string,
        +mid: ?string,
        +name: ?string,
        +networks: ?$ReadOnlyArray<?{|
          +currency: ?string,
          +id: string,
          +name: ?string,
        |}>,
        +partneredNetwork: ?string,
        +specialCommissionRate: ?string,
        +specialCommissionRateValidUntil: ?string,
        +urls: ?$ReadOnlyArray<?string>,
      |},
    |}>,
  |},
  +$fragmentType: listOfRakutenSitesList_store$fragmentType,
|};
export type listOfRakutenSitesList_store$key = {
  +$data?: listOfRakutenSitesList_store$data,
  +$fragmentSpreads: listOfRakutenSitesList_store$fragmentType,
  ...
};
*/

var node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "category"
    },
    {
      "defaultValue": 12,
      "kind": "LocalArgument",
      "name": "count"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "cursor"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "network"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "partner"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "region"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "searchText"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "sortBy"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "count",
        "cursor": "cursor",
        "direction": "forward",
        "path": [
          "rakutenSites"
        ]
      }
    ]
  },
  "name": "listOfRakutenSitesList_store",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "currentUser",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isRetailer",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": "rakutenSites",
      "args": [
        {
          "kind": "Variable",
          "name": "category",
          "variableName": "category"
        },
        {
          "kind": "Variable",
          "name": "network",
          "variableName": "network"
        },
        {
          "kind": "Variable",
          "name": "partner",
          "variableName": "partner"
        },
        {
          "kind": "Variable",
          "name": "region",
          "variableName": "region"
        },
        {
          "kind": "Variable",
          "name": "searchText",
          "variableName": "searchText"
        },
        {
          "kind": "Variable",
          "name": "sortBy",
          "variableName": "sortBy"
        }
      ],
      "concreteType": "StoreRakutenSiteConnection",
      "kind": "LinkedField",
      "name": "__admin_list_rakutenSites_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "StoreRakutenSiteEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "RakutenSite",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "mid",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "urls",
                  "storageKey": null
                },
                (v1/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "displayName",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "description",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Country",
                  "kind": "LinkedField",
                  "name": "countries",
                  "plural": true,
                  "selections": [
                    (v1/*: any*/),
                    (v0/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "code",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Category",
                  "kind": "LinkedField",
                  "name": "category",
                  "plural": false,
                  "selections": [
                    (v1/*: any*/)
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "commissionRate",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "imageUrl",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "displayUrl",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "logoUrl",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "isPublic",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "specialCommissionRate",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "specialCommissionRateValidUntil",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "hasSpecialRate",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "SiteNetwork",
                  "kind": "LinkedField",
                  "name": "networks",
                  "plural": true,
                  "selections": [
                    (v1/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "currency",
                      "storageKey": null
                    },
                    (v0/*: any*/)
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "partneredNetwork",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__typename",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cursor",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PageInfo",
          "kind": "LinkedField",
          "name": "pageInfo",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endCursor",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasNextPage",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Store",
  "abstractKey": null
};
})();

(node/*: any*/).hash = "32013e63f817348059bc129af258290a";

module.exports = ((node/*: any*/)/*: Fragment<
  listOfRakutenSitesList_store$fragmentType,
  listOfRakutenSitesList_store$data,
>*/);
