/**
 * @generated SignedSource<<5919e138e0660f381a2fcc8a52a82261>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentType } from "relay-runtime";
declare export opaque type lookbookCard_lookbook$fragmentType: FragmentType;
export type lookbookCard_lookbook$data = {|
  +id: string,
  +isPinned: ?boolean,
  +isPublished: ?boolean,
  +isRecommended: ?boolean,
  +lookbookBoards: ?$ReadOnlyArray<?{|
    +id: ?string,
    +url: ?string,
    +videoUrl: ?string,
  |}>,
  +published_at: ?string,
  +shownBoardCount: ?number,
  +title: ?string,
  +url: ?string,
  +user: ?{|
    +canEdit: ?boolean,
    +customDomain: ?string,
    +fullname: ?string,
    +id: string,
    +profilePhoto: ?string,
    +username: ?string,
  |},
  +views: ?number,
  +$fragmentType: lookbookCard_lookbook$fragmentType,
|};
export type lookbookCard_lookbook$key = {
  +$data?: lookbookCard_lookbook$data,
  +$fragmentSpreads: lookbookCard_lookbook$fragmentType,
  ...
};
*/

var node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "url",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "lookbookCard_lookbook",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "views",
      "storageKey": null
    },
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "user",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "fullname",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "username",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "profilePhoto",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "canEdit",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "customDomain",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "LookbookBoard",
      "kind": "LinkedField",
      "name": "lookbookBoards",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "videoUrl",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isPublished",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isPinned",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isRecommended",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "published_at",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "shownBoardCount",
      "storageKey": null
    }
  ],
  "type": "Lookbook",
  "abstractKey": null
};
})();

(node/*: any*/).hash = "08ef0882366fdaa2dd5614113a8473cd";

module.exports = ((node/*: any*/)/*: Fragment<
  lookbookCard_lookbook$fragmentType,
  lookbookCard_lookbook$data,
>*/);
