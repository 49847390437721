import { commitMutation, graphql } from "react-relay"
import { ConnectionHandler } from "relay-runtime"
import connections from "../connections"
import { complete } from "../helper"

const mutation = graphql`
	mutation deleteProductCollectionMutation(
		$input: DeleteProductCollectionInput!
	) {
		deleteProductCollection(input: $input) {
			store {
				id
			}
		}
	}
`

const commit = (env, collectionId, onError, onCompleted, other = {}) =>
	commitMutation(env, {
		mutation,
		variables: {
			input: {
				collectionId,
			},
		},
		updater: (storeProxy) => {
            const currentUserProxy = storeProxy
					.getRoot()
					.getLinkedRecord("store")
					.getLinkedRecord("currentUser")

			const connectionNoFilter = ConnectionHandler.getConnection(
				currentUserProxy,
				connections.currentUser.productCollectionsNoFilter,
			)
			const filters = {
				search: other.search,
				categories: other.categories,
				brands: other.brands,
				clientIds: other.clientId ? [other.clientId] : [],
				collections: [],
				limitType: other.limitType,
				resultTypes: ["collections"],
				teamId: other.teamId,
			}
			if (other.sort !== undefined) filters.sort = other.sort
			if (other.folderId !== undefined) filters.folderId = other.folderId

			const searchItemsProxy =  other ? currentUserProxy.getLinkedRecord("productCollectionSearchItems", filters) : null
			if (searchItemsProxy) {
				const connectionSearchItems = ConnectionHandler.getConnection(
					searchItemsProxy,
					connections.currentUser.productCollectionsSearch,
					{search: other.collectionSearch}
				)
				if(connectionSearchItems) ConnectionHandler.deleteNode(connectionSearchItems, collectionId)

				const imageListCollectionProxy = searchItemsProxy.getLinkedRecord(
					"collections",
					{
						search: other.collectionSearch,
					}
				)
				if (imageListCollectionProxy) {
					const edges = imageListCollectionProxy
						.getLinkedRecords("edges")
						.filter(
							(edge) =>
								edge.getLinkedRecord("node").getValue("id") !==
								collectionId
						)
					if (edges) {
						imageListCollectionProxy.setLinkedRecords(edges, "edges")
					}
				}
			}

			if(connectionNoFilter) ConnectionHandler.deleteNode(connectionNoFilter, collectionId)

			const { parentId, containerId } = other
			if (parentId && containerId) {
				const parentFolder = storeProxy.get(parentId)
				parentFolder.setValue(parentFolder.getValue("collectionCount") - 1, "collectionCount")
				const conn = connections.currentUser.shopcastManager.items
				const shopcastsConnection = ConnectionHandler.getConnection(
					parentFolder,
					conn.name,
					{
						...conn.data,
						type: "collection"
					}
				)
				ConnectionHandler.deleteNode(shopcastsConnection, collectionId)
				if (containerId) {
					const containerProxy = storeProxy.get(containerId)
					containerProxy.setValue(
						containerProxy.getValue("rootItemCount") - 1,
						"rootItemCount"
					)
				}
			}
		},
		onError: complete(onCompleted, onError),
		onCompleted: complete(onCompleted, onError),
	})

export default { commit }
