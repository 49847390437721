import React, { Component } from "react"
import { CategoryENUM, MimeTypeENUM } from "helpers/enum-helper"
import ShopcastList from "components/lookbook/shopcast-list"
import ListItem from "components/lookbook/list-item"
import ProductCollectionCreateEditModal from "components/modals/product-collection-create-edit/product-collection-create-edit"
import { DndProvider } from "react-dnd"
import { HTML5Backend } from "react-dnd-html5-backend"
import nav from "shared/styles/components/nav.css"
// import buttons from "shared/styles/components/buttons.css"
import styles from "./add-products.css"
import { LookbookProvider } from "../lookbook/lookbook"
import { getComponent } from "../../route-helpers"

class AddProducts extends Component {
	constructor(props) {
		super(props)
		this.tabs = {
			collections: "collections",
			shopcasts: "shopcasts",
		}
		this.state = {
			currentTab: this.tabs.collections,
			shopcastListId: props.shopcastId || "",
			collectionToEdit: null,
		}
		this.dropByClick = this.dropByClick.bind(this)
		this.shopcastListOnChange = this.shopcastListOnChange.bind(this)
	}

	dropByClick(value) {
		const { setSelectedProductForEdit } = this.props
		setSelectedProductForEdit(value)
	}

	shopcastListOnChange({ target }) {
		this.setState({ shopcastListId: target.value })
	}

	renderShopcastsTab() {
		const { shopcastListId } = this.state
		const { teamId } = this.props
		const ShopcastListComp = getComponent(
			`shopcast-list`,
			ShopcastList,
			{ ...ShopcastList.params, shopcastListId, teamId },
			{
				shopcastListId,
				teamId,
				onChange: this.shopcastListOnChange,
				favouriteListOpen: shopcastListId === CategoryENUM.FAVOURITE,
				category: CategoryENUM.SHOPCAST,
				drop: this.dropByClick,
			}
		)
		return ShopcastListComp
	}

	renderCollectionsTab() {
		const { relay, store } = this.props
		return (
			<LookbookProvider
				value={{
					editCollection: (collection) =>
						this.setState({ collectionToEdit: collection }),
				}}
			>
				<ListItem
					innerRef={(el) => {
						this.listItems = el
					}}
					type="products"
					category={CategoryENUM.COLLECTION}
					accepted={MimeTypeENUM.ALL_IMAGES}
					searchString=""
					drop={this.dropByClick}
					relay={relay}
					store={store}
				/>
			</LookbookProvider>
		)
	}

	render() {
		const { currentTab, collectionToEdit } = this.state
		const { relay, store } = this.props
		return (
			<DndProvider backend={HTML5Backend}>
				<div className={styles.root}>
					<div className={styles.header}>
						<nav className={nav.tabsFillHasBorder}>
							<div
								role="button"
								tabIndex="0"
								className={
									currentTab === this.tabs.collections
										? nav.underlineActive
										: nav.underline
								}
								onClick={() => {
									this.setState({ currentTab: this.tabs.collections })
								}}
							>
								Collections
							</div>
							<div
								role="button"
								tabIndex="0"
								className={
									currentTab === this.tabs.shopcasts
										? nav.underlineActive
										: nav.underline
								}
								onClick={() => {
									this.setState({ currentTab: this.tabs.shopcasts })
								}}
							>
								Shopcasts
							</div>
						</nav>
					</div>
					<div className={styles.content}>
						{currentTab === this.tabs.collections &&
							this.renderCollectionsTab()}
						{currentTab === this.tabs.shopcasts &&
							this.renderShopcastsTab()}
					</div>
					{!!collectionToEdit && (
						<ProductCollectionCreateEditModal
							collectionToEdit={collectionToEdit}
							relay={relay}
							currentUser={store.currentUser}
							onCancel={() => {
								this.setState({ collectionToEdit: null })
							}}
							onSuccess={(type, data) => {
								if (type === "new" && this.listItems)
									this.listItems.onChange({
										target: { value: data.id },
									})
							}}
						/>
					)}
				</div>
			</DndProvider>
		)
	}
}

export default AddProducts
