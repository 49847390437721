import { commitMutation, ConnectionHandler, graphql } from "react-relay"
import connections from "../connections"
import { complete } from "../helper"

const mutation = graphql`
	mutation updateProductCollectionMutation(
		$input: UpdateProductCollectionInput!
	) {
		updateProductCollection(input: $input) {
			store {
				id
			}
			folder {
				id
				name
			}
			published_at
		}
	}
`

const commit = (env, data, onError, onCompleted, other) =>
	commitMutation(env, {
		mutation,
		variables: { input: data },
		updater: (storeProxy) => {
			const currentUserProxy = storeProxy
					.getRoot()
					.getLinkedRecord("store")
					.getLinkedRecord("currentUser")
			const collectionProxy = storeProxy.get(data.id)
			collectionProxy.setValue(data.title, "title")
			collectionProxy.setValue(data.isACloset, "isACloset")
			if (data.isPublished !== undefined)
				collectionProxy.setValue(data.isPublished, "isPublished")
			const folderProxy = data.folder ? storeProxy.get(data.folder) :  null
			if (folderProxy) collectionProxy.setLinkedRecord(folderProxy, "folder")
			else collectionProxy.setValue(null, "folder")

			const { parentId, containerId } = other || {}
			if (parentId && containerId && data.folder !== parentId) {
				const parentFolder = storeProxy.get(parentId)
				const shopcastsConnection = ConnectionHandler.getConnection(
					parentFolder,
					connections.currentUser.shopcastManager.items.name,
					connections.currentUser.shopcastManager.items.data
				)
				ConnectionHandler.deleteNode(shopcastsConnection, data.id)
				if (containerId) {
					const containerProxy = storeProxy.get(containerId)
					containerProxy.setValue(
						containerProxy.getValue("rootItemCount") - 1,
						"rootItemCount"
					)
				}
			}

			const typekey = data.isACloset ? "closet" : "collections"
			if (data.clientId === undefined || other.clientId === undefined) return
			if(data.clientId === other.clientId && other.limitType.includes(typekey)) return

			const searchItemsProxy =  other ? currentUserProxy.getLinkedRecord("productCollectionSearchItems", {
				search: other.search,
				categories: other.categories,
				brands: other.brands,
				clientIds: other.clientId ? [other.clientId] : [],
				collections: [],
				limitType: other.limitType,
				folderId: other.folderId,
				resultTypes: ["collections"],
				teamId: other.teamId,
			}) : null
			if (searchItemsProxy) {
				const connectionSearchItems = ConnectionHandler.getConnection(
					searchItemsProxy,
					connections.currentUser.productCollectionsSearch,
					{search: other.collectionSearch}
				)
				if(connectionSearchItems) ConnectionHandler.deleteNode(connectionSearchItems, data.id)
			}
		},
		onError: complete(onCompleted, onError),
		onCompleted: complete(onCompleted, onError),
	})

export default { commit }
