import React, { PureComponent } from "react"
import { createPaginationContainer, graphql } from "react-relay"
import ListOfProfiles from "../list-of-profiles/list-of-profiles"

class ListOfRetailers extends PureComponent {
	render() {
		const { relay, store } = this.props
		return (
			<ListOfProfiles
				store={store}
				context="retailer"
				relay={relay}
				currentUser={store.currentUser}
				profiles={store.recommendedRetailers}
				isRetailer
			/>
		)
	}
}
const query = graphql`
	query listOfRetailersQuery(
		$count: Int!
		$cursor: String
		$category: String
	) {
		store {
			...listOfRetailers_store
				@arguments(count: $count, cursor: $cursor, category: $category)
		}
	}
`

ListOfRetailers = createPaginationContainer(
	ListOfRetailers,
	{
		store: graphql`
			fragment listOfRetailers_store on Store
			@argumentDefinitions(
				count: { type: "Int", defaultValue: 12 }
				cursor: { type: "String" }
				category: { type: "String" }
			) {
				id
				currentUser {
					id
					adminAccess
				}
				recommendedRetailers(
					first: $count
					after: $cursor
					category: $category
				) @connection(key: "store_recommendedRetailers") {
					edges {
						cursor
						node {
							id
							fullname
							username
							customDomain
							isFollowing
							profilePhoto
							bannerPhoto
							isRecommended
						}
					}
					pageInfo {
						hasPreviousPage
						hasNextPage
						startCursor
						endCursor
					}
				}
			}
		`,
	},
	{
		direction: "forward",
		getConnectionFromProps(props) {
			const { store } = props
			return store && store.recommendedRetailers
		},
		getFragmentVariables(prevVars, totalCount) {
			return {
				...prevVars,
				count: totalCount,
			}
		},
		getVariables(props, { count, cursor }) {
			return {
				count,
				cursor,
				category: props.category,
			}
		},
		query,
	}
)

export default {
	Component: ListOfRetailers,
	query,
	params: { count: 12, cursor: null, category: null },
}
