/**
 * @generated SignedSource<<7190fc6616d41dbbdaeff3ce83e0cf60>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Mutation } from 'relay-runtime';
export type GetFolderTreeInput = {|
  clientMutationId?: ?string,
  teamId?: ?string,
|};
export type getFolderTreeMutation$variables = {|
  input: GetFolderTreeInput,
|};
export type getFolderTreeMutation$data = {|
  +getFolderTree: ?{|
    +tree: ?string,
  |},
|};
export type getFolderTreeMutation = {|
  response: getFolderTreeMutation$data,
  variables: getFolderTreeMutation$variables,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "GetFolderTreePayload",
    "kind": "LinkedField",
    "name": "getFolderTree",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "tree",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "getFolderTreeMutation",
    "selections": (v1/*: any*/),
    "type": "ShopshareMutationRoot",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "getFolderTreeMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "4905be9a3e6a13c7fecb11eac8891c01",
    "id": null,
    "metadata": {},
    "name": "getFolderTreeMutation",
    "operationKind": "mutation",
    "text": "mutation getFolderTreeMutation(\n  $input: GetFolderTreeInput!\n) {\n  getFolderTree(input: $input) {\n    tree\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "ad5efc1ef8226968619c036961eb1b3b";

module.exports = ((node/*: any*/)/*: Mutation<
  getFolderTreeMutation$variables,
  getFolderTreeMutation$data,
>*/);
