import { commitMutation, graphql } from "react-relay"
import { complete } from "../helper.js"

const mutation = graphql`
	mutation createCampaignMutation($input: CreateCampaignInput!) {
		createCampaign(input: $input) {
			campaign {
				id
				name
				description
				monthlyCommision
				annualCommision
				type
				cookiePeriod
				monthlyCoupon
				annualCoupon
				conversions
				isArchived
				users {
					id
					username
				}
				totalRevenue
				allUsers
			}
		}
	}
`

const commit = (env, data, adminId, onCompleted, onError) =>
	commitMutation(env, {
		mutation,
		variables: {
			input: {
				...data,
				users: data.users.map((i) => i.id),
			},
		},
		onCompleted: complete(onCompleted, onError),
		onError: (e) => complete(null, [e]),
		updater: (store, dataObj) => {
			if (dataObj.createCampaign) {
				const admin = store.get(adminId)
				const campaigns = admin.getLinkedRecords("campaigns")
				const newCampaign = store.get(dataObj.createCampaign.campaign.id)
				admin.setLinkedRecords([newCampaign, ...campaigns], "campaigns")
			}
		},
	})

export default { commit }