import React, { Component, Fragment } from "react"
import { createFragmentContainer, graphql } from "react-relay"
import { Helmet } from "react-helmet"
import { Node } from "slate"
import lodash from "lodash"
import { DashboardIcon } from "@radix-ui/react-icons"
import FontFaceObserver from "fontfaceobserver"
import stylesAvatar from "components/profile/avatar/avatar.css"
import grids from "shared/styles/components/grids.css"
import empty from "shared/styles/components/empty.css"
import banner from "shared/styles/layout/banner.css"
import nav from "shared/styles/components/nav.css"
import textfield from "shared/styles/components/textfield.css"
import controls from "shared/styles/layout/controls.css"
import SocialLink from "components/social-link/social-link"
import TwitterIcon from "shared/assets/twitter.svg"
import InstagramIcon from "shared/assets/instagram.svg"
import FacebookIcon from "shared/assets/facebook.svg"
import YoutubeIcon from "shared/assets/youtube.svg"
import PinterestIcon from "shared/assets/pinterest-icon.svg"
import WebsiteIcon from "shared/assets/website-icon.svg"
import TiktokIcon from "shared/assets/tiktok-icon.svg"
import Avatar from "components/profile/avatar/avatar"
import Loading from "components/loading/loading"
import ReportShopcastModal from "components/modals/report-shopcast/report-shopcast"
import GetInTouchModal from "components/modals/get-in-touch/get-in-touch"
// import SearchInputComponent from "components/search-input/search-input"
import { getComponent } from "../../route-helpers"
import ProfileShopcasts from "./profile-shopcasts/profile-shopcasts"
import ProfileLookbooks from "./profile-lookbooks/profile-lookbooks"
import trackingService from "../../services/tracking-service"
import { publicViewDomain } from "../../constants"
import RichTextEditor from "../../components/richtext-editor/richtext-editor"
import ProfileFiles from "./profile-files/profile-files"
import { getUsername } from "../../services/custom-domain-service"
import ProfileCollections from "./profile-collections/profile-collections"
import styles from "./profile-view.css"
import ProfileCollectionsList from "./profile-collections-list"

const defaultItems = [
	"Shopcasts",
	"Shopboards",
	"Files",
	"Collections",
	"About",
]
class ProfileView extends Component {
	constructor(props) {
		super(props)
		this.showReportModal = this.showReportModal.bind(this)
		this.showGetInTouchModal = this.showGetInTouchModal.bind(this)

		// this.setKeywordCollectionBounce = debounce((val) => {
		// 	const {
		// 		collectionFilter: { sort, collectionId, type },
		// 	} = this.state
		// 	this.setState({
		// 		collectionFilter: {
		// 			sort,
		// 			collectionId,
		// 			searchText: val,
		// 			type,
		// 		},
		// 	})
		// }, 500)

		this.state = {
			userNotFound: false,
			currentTab: null,
			currentShopcast: null,
			showReportModal: false,
			showGetInTouchModal: false,
			collectionFilter: {
				searchText: "",
				sort: "n-to-o",
				collectionId: "",
				type: "",
			},
			tabStyles: {},
		}
		this.tabsRef = {}
		this.messageListner = this.messageListner.bind(this)
		this.setTabStyle = this.setTabStyle.bind(this)
		this.setCollectionId = this.setCollectionId.bind(this)

		window.addEventListener("message", this.messageListner)
	}

	componentDidMount() {
		const { props } = this

		const { profile } = props.store
		if (!profile) {
			this.setState({ userNotFound: true })
		} else {
			this.setState(
				{
					currentTab: this.getTabsData(false),
				},
				() => {
					const font = new FontFaceObserver("Mona Sans")

					font.load().then(() => {
						this.setTabStyle()
					})
				}
			)
			window.addEventListener("resize", this.setTabStyle)
			trackingService.track("Viewed Profile", {
				Username: props.store.profile.username,
			})
		}
	}

	componentWillUnmount() {
		window.removeEventListener("message", this.messageListner)
		window.removeEventListener("resize", this.setTabStyle)
	}

	getTabsData(isFilter) {
		const {
			store: { profile },
		} = this.props
		const { previewConfig } = profile
		const tabs =
			profile.tabsOrder && profile.tabsOrder.length > 0
				? [
						...profile.tabsOrder.filter((item) =>
							defaultItems.includes(item)
						),
						...lodash.difference(defaultItems, profile.tabsOrder),
				  ]
				: defaultItems
		const tabDataMap = {
			Shopcasts: previewConfig.showShopcastsSection,
			Shopboards: previewConfig.showShopboardsSection,
			Files: previewConfig.showFilesSection,
			Collections: previewConfig.showCollectionsSection,
			About: previewConfig.showAboutSection,
		}
		if (isFilter) return tabs.filter((i) => tabDataMap[i])
		return tabs.find((i) => tabDataMap[i])
	}

	setTabStyle() {
		const { currentTab } = this.state
		this.setState({
			tabStyles: {
				width: this.tabsRef[currentTab]?.offsetWidth || 0,
				transform: `translateX(${
					this.tabsRef[currentTab]?.offsetLeft || 0
				}px)`,
			},
		})
	}

	setCollectionId(collectionId) {
		const {
			collectionFilter: { searchText, sort, type },
		} = this.state
		this.setState({
			collectionFilter: {
				searchText,
				sort,
				collectionId,
				type,
			},
		})
	}

	messageListner(event) {
		if (event.data === "shopshare-reload") window.location.reload()
	}

	showGetInTouchModal(show) {
		this.setState({ showGetInTouchModal: show })
	}

	changeTabState(e, toState) {
		e.preventDefault()
		this.setState({
			currentTab: toState,
			tabStyles: {
				width: this.tabsRef[toState].offsetWidth,
				transform: `translateX(${this.tabsRef[toState].offsetLeft}px)`,
			},
		})
	}

	showReportModal(show, shopcast) {
		this.setState({ showReportModal: show, currentShopcast: shopcast })
	}

	renderShopcastsTab() {
		const { props } = this
		const shopcasts = props.store.profile.latestShopcasts
		const { username } = props.store.profile
		if (shopcasts.edges.length) {
			const ProfileShopcastsComp = getComponent(
				`profile-shopcasts`,
				ProfileShopcasts,
				{ ...ProfileShopcasts.params, username },
				{ username }
			)
			return ProfileShopcastsComp
		}
		return (
			<div className={grids.container}>
				<div className={grids.row}>
					<div className={grids.span}>
						<div className={empty.simple}>There&apos;s nothing here</div>
					</div>
				</div>
			</div>
		)
	}

	renderFilesTab() {
		const { props } = this
		const { files } = props.store.profile
		const { username } = props.store.profile
		if (files.edges.length) {
			const ProfileFilesComp = getComponent(
				`profile-files`,
				ProfileFiles,
				{ ...ProfileFiles.params, username },
				{ username }
			)
			return ProfileFilesComp
		}
		return (
			<div className={grids.container}>
				<div className={grids.row}>
					<div className={grids.span}>
						<div className={empty.simple}>There&apos;s nothing here</div>
					</div>
				</div>
			</div>
		)
	}

	renderLooksTab() {
		const { store } = this.props
		const { lookbooks } = store.profile
		const { username } = store.profile
		if (lookbooks.edges.length) {
			const ProfileLookbooksComp = getComponent(
				`profile-lookbooks`,
				ProfileLookbooks,
				{ ...ProfileLookbooks.params, username },
				{ username }
			)
			return ProfileLookbooksComp
		}
		return (
			<div className={grids.span}>
				<div className={empty.simple}>There&apos;s nothing here</div>
			</div>
		)
	}

	renderAboutTab() {
		const { props } = this
		const { profile } = props.store
		const nodes = profile.longBio
			? JSON.parse(profile.longBio)
			: [{ type: "paragraph", children: [{ text: "" }] }]
		const longBio = nodes
			.map((n) => Node.string(n))
			.join("")
			.trim()
		if (!longBio) return null
		return (
			<RichTextEditor readOnly initialScript={nodes} saveScript={() => {}} />
		)
	}

	renderCollectionsList() {
		const { store } = this.props
		const { username } = store.profile
		const ProfileCollectionsListComp = getComponent(
			`profile-collection-list`,
			ProfileCollectionsList,
			{ ...ProfileCollectionsList.params, username },
			{
				...ProfileCollections.props,
				username,
				setCollectionId: this.setCollectionId,
			}
		)
		return ProfileCollectionsListComp
	}

	renderCollectionsTab() {
		const { props, state } = this
		const {
			collectionFilter: { searchText, sort, collectionId, type },
		} = state
		const { username, productCollections } = props.store.profile

		const ProfileCollectionsComp = getComponent(
			`profile-collections`,
			ProfileCollections,
			{
				...ProfileCollections.params,
				username,
				searchText,
				sort,
				collectionId,
				type,
			},
			{
				...ProfileCollections.props,
				username,
				searchText,
				sort,
				collectionId,
				type,
			}
		)
		return (
			<Fragment>
				<div className={controls.root}>
					<div className={controls.search}>
						{/* <div className={controls.group}>
							<label className={form.label}>Type</label>
							<select
								className={textfield.normal}
								onChange={(e) => {
									this.setState({
										collectionFilter: {
											searchText,
											sort,
											collectionId,
											type: e.target.value,
										},
									})
								}}
							>
								<option value="">All</option>
								<option value="uploads">Images</option>
								<option value="products">Products</option>
							</select>
						</div> */}
						<div className={controls.group}>
							<select
								className={textfield.normal}
								value={collectionId}
								onChange={(e) => {
									this.setState({
										collectionFilter: {
											searchText,
											sort,
											collectionId: e.target.value,
											type,
										},
									})
								}}
							>
								<optgroup label="Show">
									<option value="">All Collections</option>
									{productCollections.edges.map(({ node: item }) => (
										<option value={item.id}>{item.title}</option>
									))}
								</optgroup>
							</select>
						</div>
						{/* <SearchInputComponent
							placeholder="Search"
							setSearchText={(value) =>
								this.setKeywordCollectionBounce(value)
							}
						/> */}
					</div>
					<div className={controls.filters}>
						<div className={controls.group}>
							<select
								className={textfield.normal}
								value={sort}
								onChange={(e) => {
									this.setState({
										collectionFilter: {
											searchText,
											collectionId,
											sort: e.target.value,
											type,
										},
									})
								}}
							>
								<optgroup label="Sort by">
									<option value="n-to-o">Newest to Oldest</option>
									<option value="o-to-n">Oldest to Newest</option>
									<option value="name">Name A-Z</option>
									<option value="name-d">Name Z-A</option>
								</optgroup>
							</select>
						</div>
					</div>
				</div>
				{ProfileCollectionsComp}
			</Fragment>
		)
	}

	renderTabsHeader() {
		const { state } = this
		const renderTabs = this.getTabsData(true).map((tab) => (
			<a
				className={state.currentTab === tab ? nav.tabActive : nav.tab}
				onClick={(e) => this.changeTabState(e, tab)}
				onKeyPress={(e) => this.changeTabState(e, tab)}
				tabIndex={0}
				role="link"
				key={tab}
				ref={(ref) => {
					this.tabsRef[tab] = ref
				}}
			>
				{tab}
			</a>
		))

		return renderTabs
	}

	renderTab() {
		const { state } = this
		const {
			collectionFilter: { collectionId },
		} = state
		switch (state.currentTab) {
			case "Shopboards":
				return this.renderLooksTab()
			case "Shopcasts":
				return this.renderShopcastsTab()
			case "About":
				return this.renderAboutTab()
			case "Files":
				return this.renderFilesTab()
			case "Collections":
				return collectionId
					? this.renderCollectionsTab()
					: this.renderCollectionsList()
			default:
				return this.renderEmptyState()
		}
	}

	renderEmptyState() {
		const data = {
			title: "There's nothing here",
			message: "Any content published will appear here.",
			icon: <DashboardIcon className={empty.icon} />,
		}
		return (
			<div className={empty.root}>
				<div className={empty.container}>
					<div className={empty.content}>
						{data.icon}
						<h4 className={empty.headline}>{data.title}</h4>
						<p className={empty.subline}>{data.message}</p>
					</div>
				</div>
			</div>
		)
	}

	renderAvatar(profile) {
		return (
			<Avatar
				className={styles.avatar}
				styleName={stylesAvatar.border}
				photoUrl={profile.displayAvatar}
				fullname={profile.fullname}
			/>
		)
	}

	renderProfile() {
		const { props, state } = this
		const { profile } = props.store
		const { tabStyles } = state
		const social = profile.socialLinks || {}
		const { previewConfig } = profile

		let inlineStyles = {
			// backgroundImage: `url(${PlaceholderMasthead})`
		}

		if (previewConfig.showBanner && profile.bannerPhoto) {
			inlineStyles = {
				backgroundImage: `url(${profile.bannerPhoto})`,
			}
		}

		const showSocialLinks =
			(previewConfig.showInstagramLink && social.instagramUsername) ||
			(previewConfig.showFacebookLink && social.facebookUrl) ||
			(previewConfig.showTwitterLink && social.twitterUsername) ||
			(previewConfig.showYoutubeLink && social.youtubeUrl) ||
			(previewConfig.showPinterestLink && social.pinterestUrl) ||
			(previewConfig.showTiktokLink && social.tiktokUrl) ||
			(previewConfig.showWebsiteLink && social.website)

		let bio = ""
		let i = 1
		if (profile.bio) {
			const b = profile.bio.split("\n")
			if (b.length === 1) {
				bio = [b[0]]
			} else {
				bio = []
				b.forEach((element, ind) => {
					i += 1
					bio.push(<span key={i}>{element}</span>)
					i += 1
					if (b.length !== ind + 1) {
						bio.push(<br key={i} />)
					}
				})
			}
		}
		const domain =
			profile.customDomain || `${profile.username}.${publicViewDomain}`
		return (
			<div className={styles.root}>
				<Helmet>
					<title>
						{profile.fullname} (@
						{profile.username}) – ShopShare
					</title>
					<meta
						name="description"
						content={
							profile.bio
								? profile.bio
								: `${profile.fullname} Channel on ShopShare.tv`
						}
					/>
					<meta
						itemProp="name"
						content={`${profile.fullname} on ShopShare.tv`}
					/>
					<meta
						itemProp="description"
						content={
							profile.bio
								? profile.bio
								: `${profile.fullname} Channel on ShopShare.tv`
						}
					/>
					<meta
						itemProp="image"
						content="https://shopshare.tv/shopshare-opengraph-profile.jpg"
					/>
					<meta
						property="og:title"
						content={`${profile.fullname} on ShopShare.tv`}
					/>
					<meta
						property="og:description"
						content={`@${profile.username}`}
					/>
					<meta
						property="og:image"
						content="https://shopshare.tv/shopshare-opengraph-profile.jpg"
					/>
					<meta property="og:url" content={`https://${domain}`} />
					<meta
						name="twitter:title"
						content={`${profile.fullname} on ShopShare.tv`}
					/>
					<meta
						name="twitter:description"
						content={
							profile.bio
								? profile.bio
								: `${profile.fullname} Channel on ShopShare.tv`
						}
					/>
					<meta
						name="twitter:image"
						content="https://shopshare.tv/shopshare-opengraph-profile.jpg"
					/>
					<meta name="twitter:site" content="@shopshare_tv" />
					{showSocialLinks && (
						<meta
							name="twitter:creator"
							content={`@${profile.socialLinks.twitterUsername}`}
						/>
					)}
				</Helmet>

				{previewConfig.showBanner && (
					<div className={banner.coverSticky} style={inlineStyles} />
				)}

				<div className={styles.header}>
					<div className={styles.inner}>
						{previewConfig.showAvatar && (
							<div
								className={
									previewConfig.showBanner
										? styles.avatar
										: styles.avatarNoBanner
								}
							>
								{this.renderAvatar(profile)}
							</div>
						)}

						{previewConfig.showDisplayName && (
							<h1 className={styles.displayName}>
								{profile.displayName || profile.fullname}
							</h1>
						)}

						{previewConfig.showShortBio && bio && (
							<p className={styles.shortBio}>{bio}</p>
						)}

						{previewConfig.showContact && (
							<div className={styles.cta}>
								<button
									type="button"
									onClick={() => {
										this.showGetInTouchModal(true)
									}}
									className={styles.contact}
								>
									Contact{" "}
									{!profile.isRetailer
										? profile.fullname.split(" ")[0]
										: profile.fullname}
								</button>
							</div>
						)}
						<div className={styles.share}>
							{showSocialLinks && (
								<Fragment>
									{previewConfig.showWebsiteLink && social.website && (
										<SocialLink
											type="button"
											size="lg"
											url={social.website}
											ImageComponent={WebsiteIcon}
											title="Website"
										/>
									)}
									{previewConfig.showInstagramLink &&
										social.instagramUsername && (
											<SocialLink
												type="button"
												size="lg"
												url={`https://www.instagram.com/${social.instagramUsername}`}
												ImageComponent={InstagramIcon}
												title="Instagram"
											/>
										)}
									{previewConfig.showFacebookLink &&
										social.facebookUrl && (
											<SocialLink
												type="button"
												size="lg"
												url={social.facebookUrl}
												ImageComponent={FacebookIcon}
												title="Facebook"
											/>
										)}
									{previewConfig.showTwitterLink &&
										social.twitterUsername && (
											<SocialLink
												type="button"
												size="lg"
												url={`https://www.twitter.com/${social.twitterUsername}`}
												ImageComponent={TwitterIcon}
												title="Twitter"
											/>
										)}
									{previewConfig.showYoutubeLink &&
										social.youtubeUrl && (
											<SocialLink
												type="button"
												size="lg"
												url={social.youtubeUrl}
												ImageComponent={YoutubeIcon}
												title="YouTube"
											/>
										)}
									{previewConfig.showPinterestLink &&
										social.pinterestUrl && (
											<SocialLink
												type="button"
												size="lg"
												url={social.pinterestUrl}
												ImageComponent={PinterestIcon}
												title="Pinterest"
											/>
										)}
									{previewConfig.showTiktokLink &&
										social.tiktokUrl && (
											<SocialLink
												type="button"
												size="lg"
												url={`https://www.tiktok.com/@${social.tiktokUrl}`}
												ImageComponent={TiktokIcon}
												title="TikTok"
											/>
										)}
								</Fragment>
							)}
						</div>
					</div>
				</div>

				{!!state.currentTab && (
					<div
						className={`${nav.rootHasBorder} ${nav.rootStickyNoHeader}`}
					>
						<nav className={nav.tabs}>
							<div className={nav.tabsFit}>
								{this.renderTabsHeader()}
								<span className={nav.tabUnderline} style={tabStyles} />
							</div>
						</nav>
					</div>
				)}

				<div className={styles.content}>
					<div className={styles.contentInner}>{this.renderTab()}</div>
				</div>

				<ReportShopcastModal
					item={state.currentShopcast}
					closeModal={() => {
						this.setState({ showReportModal: false })
					}}
					showEmbedModal={state.showReportModal}
					relay={props.relay}
					storeId={props.store.id}
				/>

				<GetInTouchModal
					to={{
						id: profile.id,
						fullname: profile.fullname,
						profilePhoto: profile.profilePhoto,
						isRetailer: profile.isRetailer,
					}}
					closeModal={() => {
						this.setState({ showGetInTouchModal: false })
					}}
					relay={props.relay}
					showGetInTouch={state.showGetInTouchModal}
				/>
			</div>
		)
	}

	renderUserNotFound() {
		return (
			<div className={styles.notFound}>
				<div className={styles.notFoundContent}>
					<h1 className={styles.notFoundTitle}>Page Not Found</h1>
					<p className={styles.notFoundMessage}>
						The page you are looking for doesn&apos;t exist or has been
						moved.
					</p>
					<a className={styles.notFoundAction} href="https://shopshare.tv">
						Back to ShopShare
					</a>
				</div>
			</div>
		)
	}

	render() {
		const { props, state } = this
		const { userNotFound } = state
		if (userNotFound) {
			return this.renderUserNotFound()
		}
		if (props.store.profile) {
			return this.renderProfile()
		}
		return <Loading />
	}
}

ProfileView = createFragmentContainer(ProfileView, {
	follower: graphql`
		fragment profileView_follower on User {
			id
			fullname
			username
			isFollowing
			profilePhoto
			displayAvatar
			bannerPhoto
		}
	`,
	store: graphql`
		fragment profileView_store on Store
		@argumentDefinitions(name: { type: "String" }) {
			id
			profile(username: $name) {
				id
				fullname
				displayName
				username
				customDomain
				isRetailer
				profilePhoto
				displayAvatar
				bannerPhoto
				bio
				longBio
				createdAt
				canEdit
				isFollowing
				tabsOrder
				shopcasts(first: 10000, isPublished: true) {
					edges {
						node {
							id
						}
					}
				}
				lookbooks(first: 10000, isPublished: true) {
					edges {
						node {
							id
						}
					}
				}
				files(first: 10000, isPublished: true) {
					edges {
						node {
							id
						}
					}
				}
				productCollections(
					first: 10000
					isDefault: false
					isPublished: true
				) {
					edges {
						node {
							id
							title
						}
					}
				}
				products(first: 150) {
					edges {
						node {
							id
						}
					}
				}
				mostLikedShopcasts(first: 5) {
					edges {
						node {
							id
							...shopcastCard_shopcast
						}
					}
				}
				mostViewedShopcasts(first: 10) {
					edges {
						node {
							id
							...shopcastCard_shopcast
						}
					}
				}
				latestShopcasts(first: 20) {
					edges {
						node {
							id
							...shopcastCard_shopcast
						}
					}
				}
				socialLinks {
					website
					instagramUsername
					facebookUrl
					twitterUsername
					youtubeUrl
					pinterestUrl
					tiktokUrl
				}
				previewConfig {
					showBanner
					showAvatar
					showContact
					showDisplayName
					showShortBio
					showWebsiteLink
					showInstagramLink
					showFacebookLink
					showTwitterLink
					showYoutubeLink
					showPinterestLink
					showTiktokLink
					showShopcastsSection
					showShopboardsSection
					showFilesSection
					showCollectionsSection
					showAboutSection
				}
			}
			currentUser {
				id
				email
				fullname
				profilePhoto
				...shopcastCard_currentUser
			}
		}
	`,
})

const query = graphql`
	query profileViewQuery($name: String) {
		store {
			...profileView_store @arguments(name: $name)
		}
	}
`

export default {
	Component: ProfileView,
	query,
	params: () => {
		const username = getUsername(window.location.host)
		return { name: username }
	},
	nullProps: ["follower"],
}
