import React, { useCallback, useState } from "react"
import { Transition } from "react-transition-group"
import {
	Pencil1Icon,
	TrashIcon,
	CopyIcon,
	PersonIcon,
} from "@radix-ui/react-icons"
import { Link } from "react-router-dom"
import { toast } from "react-toastify"
import MenuIcon from "shared/assets/menu-dots-horizontal.svg"
import SharedWithMeIcon from "shared/assets/sharedwithme-icon.svg"
import { CategoryENUM } from "helpers/enum-helper"
import { withDrag } from "helpers/dnd-helper"
import { addVersioning } from "helpers/query-parameters-helper"
import stylesDropdown from "components/dropdown-menu/dropdown-menu-dark.css"
import button from "shared/styles/components/buttons.css"
import DropdownMenu from "components/dropdown-menu/dropdown-menu"
import FavouriteButton from "components/buttons/favourite-button"
import PlusButton from "components/buttons/plus-button"
import ContentEditable from "components/content-editable/content-editable"
import updateLookbookDetails from "mutations/lookbook/update-lookbook-details"
import styles from "./template-card.css"

function TemplateCardInner(props) {
	const [isShown, setIsShown] = useState(false)
	const {
		editImage,
		deleteImage,
		clickAdd,
		showFavouriteButton,
		clickFavourite,
		onDuplicate,
		onShowHide,
		editSame,
		node,
		category,
		dragRef,
		relay
	} = props
	const {
		isFavourite,
		canEdit,
		isGlobal,
		// createdCount,
		id,
		lookbook,
	} = node

	// {canEdit && (
	// 		<ShowHideButton
	// 			show={isGlobal}
	// 			dataTip={isGlobal ? "Hide" : "Show"}
	// 			hideButton={categories.length === 0 || !onShowHide}
	// 			onClick={onShowHide}
	// 		/>
	// 	)}

	const updateLookbookTitle = useCallback((title) => {
		updateLookbookDetails.commit(
			relay.environment,
			{
				title,
				description: lookbook.description,
				id: lookbook.id,
			},
			(e) => {
				console.log(e)
			},
			() => {
				toast.success("Saved", {
					autoClose: 2000,
					closeButton: false,
				})
			}
		)
	}, [])

	const renderMenu = () => {
		const listItems = []
		const push = (click, name, key, icon) =>
			listItems.push({
				click,
				name,
				key,
				icon,
			})
		const icon = <MenuIcon className={stylesDropdown.icon} />
		if (editImage) {
			push(
				() => editImage(),
				"Edit",
				"edit",
				<Pencil1Icon className={stylesDropdown.itemIcon} />
			)
		}
		if (onDuplicate) {
			push(
				() => onDuplicate(),
				"Duplicate",
				"duplicate",
				<CopyIcon className={stylesDropdown.itemIcon} />
			)
		}
		if (deleteImage && !editSame) {
			listItems.push("divide")
			push(
				() => deleteImage(),
				"Delete",
				"delete",
				<TrashIcon className={stylesDropdown.itemIcon} />
			)
		}
		if (onShowHide && canEdit) {
			listItems.push("divide")
			push(
				onShowHide,
				isGlobal ? "Unshare Template" : "Share Template",
				"showhide",
				isGlobal ? (
					<PersonIcon className={stylesDropdown.itemIcon} />
				) : (
					<SharedWithMeIcon className={stylesDropdown.itemIcon} />
				)
			)
		}
		return listItems.length ? (
			<DropdownMenu
				items={listItems}
				mainIcon={icon}
				darkTheme
				contentRight
				triggerClass={button.lightIcon}
				triggerClassActive={button.lightIconActive}
			/>
		) : null
	}

	return (
		<div
			className={styles.root}
			onFocus={() => setIsShown(true)}
			onMouseEnter={() => setIsShown(true)}
			onMouseLeave={() => setIsShown(false)}
		>
			<div className={styles.media}>
				{/* <div className={styles.usage}>{createdCount || 0}</div> */}

				<Transition in={isShown && !!clickAdd} timeout={200}>
					{(state) => (
						<div className={`${styles.addAction} ${styles[state]}`}>
							<PlusButton onClick={clickAdd} />
						</div>
					)}
				</Transition>
				<Transition in={isShown && !!canEdit} timeout={200}>
					{(state) => (
						<div className={`${styles.menu} ${styles[state]}`}>
							{renderMenu()}
						</div>
					)}
				</Transition>

				<div className={styles.figureImage}>
					{!canEdit || dragRef ? (
						<div className={styles.inner} id={id} ref={dragRef}>
							<img
								loading="lazy"
								className={styles.poster}
								src={addVersioning(lookbook.url, `d=400`)}
								crossOrigin="anonymous"
								id={`img_${category}_${id}`}
								alt={lookbook.title}
								data-category={node}
								data-itemid={id}
							/>
						</div>
					) : (
						<Link
							className={styles.inner}
							to={`/shopboard/create?template=${id}`}
						>
							<img
								loading="lazy"
								className={styles.poster}
								src={addVersioning(lookbook.url, `d=400`)}
								crossOrigin="anonymous"
								id={`img_${category}_${id}`}
								alt={lookbook.title}
								data-category={node}
								data-itemid={id}
							/>
						</Link>
					)}
				</div>
			</div>
			<div className={styles.content}>
				<div className={styles.titleWithIcons}>
					<ContentEditable
						className={styles.title}
						title={lookbook.title}
						saveTitle={updateLookbookTitle}
						contentEditable
					/>
					{showFavouriteButton && (
						<div className={styles.favButton}>
							<FavouriteButton
								isFavourite={isFavourite}
								onClick={clickFavourite}
							/>
						</div>
					)}
				</div>
			</div>
		</div>
	)
}

function TemplateCard({
	moveFrom,
	replaceable,
	templateDrag,
	...otherProps
}) {
	const Target = templateDrag ? withDrag(TemplateCardInner, ({ node }) => ({
		type: CategoryENUM.TEMPLATE,
		item: () => {
			templateDrag()
			return {
				id: node.id,
				type: CategoryENUM.TEMPLATE,
				collections: node.collection,
				moveFrom,
				replaceable,
			}
		},
		collect: (monitor) => ({ isDragging: monitor.isDragging() }),
	})) : TemplateCardInner
	return <Target {...otherProps} />
}
export default TemplateCard
