import React, { Component, Fragment } from "react"
import { createFragmentContainer, graphql } from "react-relay"
import { Link } from "react-router-dom"
import { Helmet } from "react-helmet"
import lodash from "lodash"
import { DashboardIcon } from "@radix-ui/react-icons"
import Shopping from "shared/assets/illustrations/shopping.svg"
import SearchInputComponent from "components/search-input/search-input"
import stylesAvatar from "components/profile/avatar/avatar.css"
import grids from "shared/styles/components/grids.css"
import empty from "shared/styles/components/empty.css"
import banner from "shared/styles/layout/banner.css"
import nav from "shared/styles/components/nav.css"
import button from "shared/styles/components/buttons.css"

import textfield from "shared/styles/components/textfield.css"
import controls from "shared/styles/layout/controls.css"

import Avatar from "components/profile/avatar/avatar"
import Loading from "components/loading/loading"
import styles from "./shared-folder.css"
import { getComponent, withRouter } from "../../route-helpers"

import SharedFolderShopcasts from "./shared-folder-shopcasts/shared-folder-shopcasts"
import SharedFolderCollections from "./shared-folder-collections/shared-folder-collections"
import SharedFolderLookbooks from "./shared-folder-lookbooks/shared-folder-lookbooks"

import { debounce } from "../../helpers/ui-helper.js"
import SharedFolderFiles from "./shared-folder-files/shared-folder-files"
import { getUsername } from "../../services/custom-domain-service"
import SharedFolderCollectionsList from "./shared-folder-collections-list"

const defaultItems = [
	"Shopcasts",
	"Shopboards",
	"Files",
	"Collections",
	"About",
]
class SharedFolder extends Component {
	constructor(props) {
		super(props)
		// this.setKeywordProductBounce = debounce((val) => {
		// 	const {
		// 		productFilter: { sort, type },
		// 	} = this.state
		// 	this.setState({
		// 		productFilter: {
		// 			sort,
		// 			type,
		// 			searchText: val,
		// 		},
		// 	})
		// }, 500)

		this.setKeywordCollectionBounce = debounce((val) => {
			const {
				collectionFilter: { sort, collectionId, type },
			} = this.state
			this.setState({
				collectionFilter: {
					sort,
					collectionId,
					searchText: val,
					type,
				},
			})
		}, 500)

		// this.setKeywordClosetBounce = debounce((val) => {
		// 	const {
		// 		closetFilter: { sort, collectionId, type, user },
		// 	} = this.state
		// 	this.setState({
		// 		closetFilter: {
		// 			sort,
		// 			collectionId,
		// 			searchText: val,
		// 			type,
		// 			user,
		// 		},
		// 	})
		// }, 500)

		this.state = {
			currentTab: this.getTabsData(false),
			// productFilter: {
			// 	searchText: "",
			// 	sort: "n-to-o",
			// 	type: "",
			// },
			collectionFilter: {
				searchText: "",
				sort: "n-to-o",
				collectionId: "",
				type: "",
			},
			// closetFilter: {
			// 	searchText: "",
			// 	sort: "n-to-o",
			// 	collectionId: "",
			// 	type: "",
			// 	user: "",
			// },
			tabStyles: {},
		}
		this.tabsRef = {}
		this.messageListner = this.messageListner.bind(this)
		this.setTabStyle = this.setTabStyle.bind(this)
		this.setCollectionId = this.setCollectionId.bind(this)
		window.addEventListener("message", this.messageListner)
	}

	componentDidMount() {
		this.setTabStyle()
		window.addEventListener("resize", this.setTabStyle)
	}

	componentWillUnmount() {
		window.removeEventListener("message", this.messageListner)
		window.removeEventListener("resize", this.setTabStyle)
	}

	setTabStyle() {
		const { currentTab } = this.state
		this.setState({
			tabStyles: {
				width: this.tabsRef[currentTab]?.offsetWidth || 0,
				transform: `translateX(${
					this.tabsRef[currentTab]?.offsetLeft || 0
				}px)`,
			},
		})
	}

	getTabsData(isFilter) {
		const {
			store: { profile },
		} = this.props
		if (!profile || !profile.sharedFolder) return ""
		const { previewConfig } = profile.sharedFolder
		const tabs =
			profile.tabsOrder && profile.tabsOrder.length > 0
				? [
						...profile.tabsOrder.filter((item) =>
							defaultItems.includes(item)
						),
						...lodash.difference(defaultItems, profile.tabsOrder),
				  ]
				: [...defaultItems]
		const tabDataMap = {
			Shopcasts:
				previewConfig.showShopcastsSection &&
				profile.sharedFolderShopcasts.edges.length > 0,
			Shopboards:
				previewConfig.showShopboardsSection &&
				profile.sharedFolderLookbooks.edges.length > 0,
			Files:
				previewConfig.showFilesSection &&
				profile.sharedFolderFiles.edges.length > 0,
			Collections:
				previewConfig.showCollectionsSection &&
				profile.sharedFolderCollectionItems.edges.length > 0,
			About: false,
		}
		if (isFilter) return tabs.filter((i) => tabDataMap[i])
		return tabs.find((i) => tabDataMap[i])
	}

	setCollectionId(collectionId) {
		const {
			collectionFilter: { searchText, sort, type },
		} = this.state
		this.setState({
			collectionFilter: {
				searchText,
				sort,
				collectionId,
				type,
			},
		})
	}

	messageListner(event) {
		if (event.data === "shopshare-reload") window.location.reload()
	}

	changeTabState(e, toState) {
		e.preventDefault()
		this.setState({
			currentTab: toState,
			tabStyles: {
				width: this.tabsRef[toState].offsetWidth,
				transform: `translateX(${this.tabsRef[toState].offsetLeft}px)`,
			},
		})
	}

	pageNotAvailable() {
		return (
			<div className={styles.rootEmpty}>
				<div className={empty.root}>
					<div className={empty.container}>
						<div className={empty.content}>
							<Shopping className={empty.hero} />
							<h4 className={empty.headline}>
								Sorry, this page isn&apos;t available.
							</h4>
							<p className={empty.subline}>
								The link you followed may be broken or the page may have
								been removed.
							</p>
							<div className={styles.cta}>
								<Link className={button.primary} to="/">
									ShopShare Home
								</Link>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}

	renderTabsHeader() {
		const { state } = this
		const renderTabs = this.getTabsData(true).map((tab) => (
			<a
				className={state.currentTab === tab ? nav.tabActive : nav.tab}
				onClick={(e) => this.changeTabState(e, tab)}
				onKeyPress={(e) => this.changeTabState(e, tab)}
				tabIndex={0}
				role="link"
				key={tab}
				ref={(ref) => {
					this.tabsRef[tab] = ref
				}}
			>
				{tab}
			</a>
		))
		return renderTabs
	}

	renderProfile() {
		const { props, state } = this
		const { profile } = props.store
		const { previewConfig } = profile.sharedFolder
		const { tabStyles } = state
		const inlineStyles = {}
		if (
			previewConfig.showBanner === "custom" &&
			profile.sharedFolder.bannerPhoto
		)
			inlineStyles.backgroundImage = `url(${profile.sharedFolder.bannerPhoto})`
		else if (previewConfig.showBanner === "profile" && profile.bannerPhoto)
			inlineStyles.backgroundImage = `url(${profile.bannerPhoto})`

		const showAvatar = previewConfig.showAvatar !== "off"
		const showBanner = previewConfig.showBanner !== "off"
		return (
			<div className={styles.root}>
				<Helmet>
					<title>
						{profile.fullname} – {profile.sharedFolder.name}
					</title>
				</Helmet>

				{showBanner && (
					<div className={banner.coverSticky} style={inlineStyles} />
				)}

				<div className={styles.header}>
					<div className={styles.inner}>
						{showAvatar && (
							<div
								className={
									showBanner
										? styles.avatar
										: styles.avatarNoBanner
								}
							>
								{this.renderAvatar(profile)}
							</div>
						)}

						<h1 className={styles.displayName}>
							{profile.sharedFolder.displayName}
						</h1>
						{previewConfig.showDescription && (
							<p className={styles.bio}>
								{profile.sharedFolder.description}
							</p>
						)}
					</div>
				</div>

				{!!state.currentTab && (
					<div
						className={`${nav.rootHasBorder} ${nav.rootStickyNoHeader}`}
					>
						<nav className={nav.tabs}>
							<div className={nav.tabsFit}>
								{this.renderTabsHeader()}
								<span className={nav.tabUnderline} style={tabStyles} />
							</div>
						</nav>
					</div>
				)}
				<div className={styles.content}>
					<div className={styles.contentInner}>{this.renderTab()}</div>
				</div>
			</div>
		)
	}

	renderAvatar(profile) {
		const { previewConfig } = profile.sharedFolder
		let avatar
		if (previewConfig.showAvatar === "custom")
			avatar = profile.sharedFolder.avatar
		else if (previewConfig.showAvatar === "profile")
			avatar = profile.displayAvatar || profile.profilePhoto
		return (
			<Avatar
				className={styles.avatar}
				styleName={stylesAvatar.border}
				photoUrl={avatar}
				fullname={profile.sharedFolder.name || profile.fullname}
			/>
		)
	}

	renderTab() {
		const { state } = this
		const {
			collectionFilter: { collectionId },
		} = state
		switch (state.currentTab) {
			case "Shopboards":
				return this.renderLookbooksTab()
			case "Shopcasts":
				return this.renderShopcastsTab()
			case "Files":
				return this.renderFilesTab()
			case "Collections":
				return !!collectionId
					? this.renderCollectionsTab()
					: this.renderCollectionsList()
			default:
				return this.renderEmptyState()
		}
	}

	renderEmptyState() {
		const data = {
			title: "There's nothing here",
			message: "Any content shared will appear here.",
			icon: <DashboardIcon className={empty.icon} />,
		}
		return (
			<div className={empty.root}>
				<div className={empty.container}>
					<div className={empty.content}>
						{data.icon}
						<h4 className={empty.headline}>{data.title}</h4>
						<p className={empty.subline}>{data.message}</p>
					</div>
				</div>
			</div>
		)
	}

	renderShopcastsTab() {
		const { props } = this
		const shopcasts = props.store.profile.sharedFolderShopcasts
		const { username } = props.store.profile
		const folder = props.folder || props.params.folder
		if (shopcasts.edges.length) {
			const SharedFolderShopcastsComp = getComponent(
				`shared-folder-shopcasts`,
				SharedFolderShopcasts,
				{
					...SharedFolderShopcasts.params,
					folder,
					username,
				},
				{
					...SharedFolderShopcasts.props,
					folder,
					username,
				}
			)
			return SharedFolderShopcastsComp
		}
		return (
			<div className={grids.container}>
				<div className={grids.row}>
					<div className={grids.span}>
						<div className={empty.simple}>There&apos;s nothing here.</div>
					</div>
				</div>
			</div>
		)
	}

	renderFilesTab() {
		const { props } = this
		const files = props.store.profile.sharedFolderFiles
		const { username } = props.store.profile
		const folder = props.folder || props.params.folder
		if (files.edges.length) {
			const SharedFolderFilesComp = getComponent(
				`shared-folder-files`,
				SharedFolderFiles,
				{
					...SharedFolderFiles.params,
					folder,
					username,
				},
				{
					...SharedFolderFiles.props,
					folder,
					username,
				}
			)
			return SharedFolderFilesComp
		}
		return (
			<div className={grids.container}>
				<div className={grids.row}>
					<div className={grids.span}>
						<div className={empty.simple}>There&apos;s nothing here.</div>
					</div>
				</div>
			</div>
		)
	}

	renderLookbooksTab() {
		const { props } = this
		const lookbooks = props.store.profile.sharedFolderLookbooks
		const { username } = props.store.profile
		const folder = props.folder || props.params.folder
		if (lookbooks.edges.length) {
			const SharedFolderLookbooksComp = getComponent(
				`shared-folder-lookbooks`,
				SharedFolderLookbooks,
				{
					...SharedFolderLookbooks.params,
					folder,
					username,
				},
				{
					...SharedFolderLookbooks.props,
					folder,
					username,
				}
			)
			return SharedFolderLookbooksComp
		}
		return (
			<div className={grids.container}>
				<div className={grids.row}>
					<div className={grids.span}>
						<div className={empty.simple}>There&apos;s nothing here.</div>
					</div>
				</div>
			</div>
		)
	}

	renderCollectionsList() {
		const { params, store } = this.props
		const { folder } = params
		const { username } = store.profile
		const SharedFolderCollectionsListComp = getComponent(
			`shared-folder-collections-list`,
			SharedFolderCollectionsList,
			{
				folder,
				count: 12,
				username,
				removeInfiniteScroll: false,
			},
			{
				folder,
				count: 12,
				username,
				removeInfiniteScroll: false,
				setCollectionId: this.setCollectionId
			}
		)
		return (
			SharedFolderCollectionsListComp
		)
	}

	renderCollectionsTab() {
		const { props, state } = this
		const {
			collectionFilter: { searchText, sort, collectionId, type },
		} = state
		if (!props.store.profile.sharedFolderCollectionItems.edges.length) {
			return (
				<div className={grids.container}>
					<div className={grids.row}>
						<div className={grids.span}>
							<div className={empty.simple}>
								There&apos;s nothing here.
							</div>
						</div>
					</div>
				</div>
			)
		}

		const { username, sharedFolderCollectionsList } = props.store.profile
		const folder = props.folder || props.params.folder

		const SharedFolderCollectionsComp = getComponent(
			`shared-folder-lookbooks`,
			SharedFolderCollections,
			{
				...SharedFolderCollections.params,
				username,
				searchText,
				sort,
				collectionId,
				type,
				folder,
			},
			{
				...SharedFolderCollections.props,
				username,
				searchText,
				sort,
				collectionId,
				type,
				folder,
			}
		)

		return (
			<Fragment>
				<div className={controls.root}>
					<div className={controls.search}>
						<SearchInputComponent
							placeholder="Search"
							setSearchText={(value) =>
								this.setKeywordCollectionBounce(value)
							}
						/>
					</div>

					<div className={controls.filters}>
						{/*	<div className={controls.group}>
							<label className={form.label}>Type</label>
							<select
								className={textfield.normal}
								onChange={(e) => {
									this.setState({
										collectionFilter: {
											searchText,
											sort,
											collectionId,
											type: e.target.value,
										},
									})
								}}
							>
								<option value="">All</option>
								<option value="uploads">Images</option>
								<option value="products">Products</option>
							</select>
						</div> */}
						<div className={controls.group}>
							<select
								className={textfield.normal}
								value={collectionId}
								onChange={(e) => {
									this.setState({
										collectionFilter: {
											searchText,
											sort,
											collectionId: e.target.value,
											type,
										},
									})
								}}
							>
								<optgroup label="Show">
									<option value="">All Collections</option>
									{sharedFolderCollectionsList.edges.map(
										({ node: item }) => (
											<option value={item.id}>{item.title}</option>
										)
									)}
								</optgroup>
							</select>
						</div>
						<div className={controls.group}>
							<select
								className={textfield.normal}
								value={sort}
								onChange={(e) => {
									this.setState({
										collectionFilter: {
											searchText,
											collectionId,
											sort: e.target.value,
											type,
										},
									})
								}}
							>
								<optgroup label="Sort by">
									<option value="n-to-o">Newest to Oldest</option>
									<option value="o-to-n">Oldest to Newest</option>
									<option value="name">Name A-Z</option>
									<option value="name-d">Name Z-A</option>
								</optgroup>
							</select>
						</div>
					</div>
				</div>
				{SharedFolderCollectionsComp}
			</Fragment>
		)
	}

	render() {
		const {
			store: { profile },
		} = this.props
		if (!profile) return <Loading />
		if (!profile.sharedFolder) return this.pageNotAvailable()
		return this.renderProfile()
	}
}

SharedFolder = createFragmentContainer(SharedFolder, {
	store: graphql`
		fragment sharedFolder_store on Store
		@argumentDefinitions(
			name: { type: "String" }
			folder: { type: "String!" }
		) {
			id
			profile(username: $name) {
				id
				fullname
				username
				isRetailer
				profilePhoto
				bannerPhoto
				displayAvatar
				bio
				createdAt
				canEdit
				tabsOrder
				sharedFolder(id: $folder) {
					name
					displayName
					description
					avatar
					bannerPhoto
					previewConfig {
						showBanner
						showAvatar
						showDescription
						showShopcastsSection
						showShopboardsSection
						showFilesSection
						showCollectionsSection
					}
				}
				sharedFolderShopcasts(first: 10000, folderId: $folder) {
					edges {
						node {
							id
						}
					}
				}
				sharedFolderLookbooks(first: 10000, folderId: $folder) {
					edges {
						node {
							id
						}
					}
				}
				sharedFolderProducts(first: 10000, folderId: $folder) {
					edges {
						node {
							id
						}
					}
				}
				sharedFolderFiles(first: 10000, folderId: $folder) {
					edges {
						node {
							id
						}
					}
				}
				sharedFolderCollectionItems(first: 10000, folderId: $folder) {
					edges {
						node {
							... on ShopcastProduct {
								id
							}
							... on lookbookUploads {
								id
							}
						}
					}
				}
				sharedFolderCollectionsList(folderId: $folder) {
					edges {
						node {
							id
							title
						}
					}
				}
				socialLinks {
					website
					instagramUsername
					facebookUrl
					twitterUsername
					youtubeUrl
					pinterestUrl
				}
			}
		}
	`,
})

const query = graphql`
	query sharedFolderQuery($name: String, $folder: String!) {
		store {
			...sharedFolder_store @arguments(name: $name, folder: $folder)
		}
	}
`

export default {
	Component: withRouter(SharedFolder),
	query,
	params: (rp) => {
		const username = getUsername(window.location.host)
		return {
			name: username || rp.params.name,
			folder: rp.params.folder,
		}
	},
	nullProps: ["follower"],
}
