import React, {
	createContext,
	useState,
	createRef,
	useRef,
	useEffect,
	useContext,
	useMemo,
} from "react"
import styles from "./split-pane.css"

const splitPaneContext = createContext()

export default function SplitPane({ children, ...props }) {
	const padding = 112
	const [topHeight, setTopHeight] = useState(null)
	const separatorYPosition = useRef(null)
	const separatorHeight = useRef(null)
	const splitPaneHeight = useRef(null)

	const splitPaneRef = createRef()

	useEffect(() => {
		if (!splitPaneRef.current) {
			return
		}
		splitPaneHeight.current = splitPaneRef.current.clientHeight
	}, [splitPaneRef.current])

	const onMouseDown = (e) => {
		separatorYPosition.current = e.clientY || e.touches[0].clientY
		separatorHeight.current = e.target.offsetHeight
	}

	const onMouseMove = (e) => {
		if (!separatorYPosition.current || !splitPaneRef.current) {
			return
		}

		const clientY = e.clientY || e.touches[0].clientY
		const newTopHeight = topHeight + clientY - separatorYPosition.current
		separatorYPosition.current = clientY
		if (newTopHeight <= 0) {
			return
		}
		splitPaneHeight.current = splitPaneRef.current.clientHeight
		if (
			newTopHeight + separatorHeight.current >=
			splitPaneHeight.current - padding
		) {
			return
		}
		setTopHeight(newTopHeight)
	}

	const onMouseUp = () => {
		separatorYPosition.current = null
	}

	const onResize = () => {
		if (!splitPaneRef.current) {
			return
		}
		const sph = splitPaneRef.current.clientHeight
		const newTopHeight = (topHeight / splitPaneHeight.current) * sph
		const newBottomHeight = sph - newTopHeight - separatorHeight.current
		splitPaneHeight.current = sph
		if (newBottomHeight <= padding) {
			setTopHeight(sph - padding - separatorHeight.current)
			return
		}
		setTopHeight(newTopHeight)
	}

	useEffect(() => {
		document.addEventListener("mousemove", onMouseMove)
		document.addEventListener("mouseup", onMouseUp)
		document.addEventListener("touchmove", onMouseMove)
		document.addEventListener("touchend", onMouseUp)
		window.addEventListener("resize", onResize)

		return () => {
			document.removeEventListener("mousemove", onMouseMove)
			document.removeEventListener("mouseup", onMouseUp)
			document.removeEventListener("touchmove", onMouseMove)
			document.removeEventListener("touchend", onMouseUp)
			window.removeEventListener("resize", onResize)
		}
	})

	const value = useMemo(() => ({ topHeight, setTopHeight }), [topHeight])

	return (
		<div {...props} className={styles.root} ref={splitPaneRef}>
			<splitPaneContext.Provider value={value}>
				{children[0]}
				<div
					className={styles.separator}
					role="separator"
					aria-hidden
					onMouseDown={onMouseDown}
					onTouchStart={onMouseDown}
				/>
				{children[1]}
			</splitPaneContext.Provider>
		</div>
	)
}

SplitPane.Top = function SplitPaneTop(props) {
	const topRef = createRef()
	const { topHeight, setTopHeight } = useContext(splitPaneContext)

	useEffect(() => {
		if (!topHeight) {
			setTopHeight(topRef.current.clientHeight)
			topRef.current.style.flex = "none"
			return
		}

		topRef.current.style.height = `${topHeight}px`
	}, [topHeight])

	return <div {...props} className={styles.top} ref={topRef} />
}

SplitPane.Bottom = function SplitPaneBottom(props) {
	return <div {...props} className={styles.bottom} />
}
