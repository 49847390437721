// import React from 'react'
import { commitMutation, graphql } from "react-relay"
// import { toast } from "react-toastify"
import { complete } from "mutations/helper"
// import { getFeatureAvalibale } from "services/plan-services"
// import UpgradePlanToast from "components/upgrade-plan-toast/upgrade-plan-toast"

const mutation = graphql`
	mutation removeFromPinterestMutation($input: RemoveFromPinterestInput!) {
		removeFromPinterest(input: $input) {
			store {
				id
				currentUser {
					id
				}
			}
			uploadId
		}
	}
`

const commit = async (env, itemId, onCompleted, onError) => {
	commitMutation(env, {
		mutation,
		variables: { input: { id: itemId } },
		onCompleted: complete(onCompleted, onError),
		onError: (e) => complete(null, [e]),		
		updater: (storeProxy, data) => {
			if (!data.removeFromPinterest) return
			const { uploadId } = data.removeFromPinterest
			const itemProxy = storeProxy.get(itemId)
			if (!itemProxy || !uploadId) return
			itemProxy.setValue(null, "pinterestUploadId")
		},
	})
}
export default { commit }
