/**
 * @generated SignedSource<<f63a6613a798a40cfac2636373901695>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentType } from "relay-runtime";
declare export opaque type adminCommisionReport_admin$fragmentType: FragmentType;
export type adminCommisionReport_admin$data = {|
  +adminSalesReport: ?$ReadOnlyArray<?{|
    +commissions: ?$ReadOnlyArray<?{|
      +currency: ?string,
      +paidCommission: ?number,
      +paidCommissionAUS: ?number,
      +pendingCommission: ?number,
      +pendingCommissionAUS: ?number,
      +sales: ?number,
      +shopshareCut: ?number,
      +totalCommission: ?number,
      +totalCommissionAUS: ?number,
      +unpaidCommission: ?number,
      +unpaidCommissionAUS: ?number,
      +unpaidCommissionAfterValidation: ?number,
      +unpaidCommissionAfterValidationAUS: ?number,
    |}>,
    +user: ?{|
      +fullname: ?string,
      +id: string,
      +paypalEmail: ?string,
      +profilePhoto: ?string,
    |},
  |}>,
  +$fragmentType: adminCommisionReport_admin$fragmentType,
|};
export type adminCommisionReport_admin$key = {
  +$data?: adminCommisionReport_admin$data,
  +$fragmentSpreads: adminCommisionReport_admin$fragmentType,
  ...
};
*/

var node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "adminCommisionReport_admin",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "AdminSalesReportObj",
      "kind": "LinkedField",
      "name": "adminSalesReport",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "User",
          "kind": "LinkedField",
          "name": "user",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "id",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "fullname",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "paypalEmail",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "profilePhoto",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "AdminSalesReport",
          "kind": "LinkedField",
          "name": "commissions",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "currency",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "sales",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "totalCommission",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "shopshareCut",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "paidCommission",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "paidCommissionAUS",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "unpaidCommission",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "unpaidCommissionAUS",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "totalCommissionAUS",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "unpaidCommissionAfterValidation",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "unpaidCommissionAfterValidationAUS",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "pendingCommission",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "pendingCommissionAUS",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Admin",
  "abstractKey": null
};

(node/*: any*/).hash = "e3d4123313ef9028eda0e94f7ee874ed";

module.exports = ((node/*: any*/)/*: Fragment<
  adminCommisionReport_admin$fragmentType,
  adminCommisionReport_admin$data,
>*/);
