import { commitMutation, graphql } from "react-relay"
import { complete } from "../helper.js"

const mutation = graphql`
mutation shareLookbookToFacebookMutation($input: ShareLookbookToFacebookInput!) {
    shareLookbookToFacebook(input: $input) {
      store {
        id
      }
      uploadId
    }
  }
  
`


const commit = (env, data, onCompleted, onError) => commitMutation(env, {
	mutation,
	variables: { input: data },
    onError: complete(onCompleted, onError),
    onCompleted: complete(onCompleted, onError),
})

export default { commit } 