import React, { useState } from "react"
import { Transition } from "react-transition-group"
import { CheckIcon, Pencil1Icon, TrashIcon } from "@radix-ui/react-icons"
import MenuIcon from "shared/assets/menu-dots-horizontal.svg"
import stylesDropdown from "components/dropdown-menu/dropdown-menu-dark.css"
import button from "shared/styles/components/buttons.css"
import DropdownMenu from "components/dropdown-menu/dropdown-menu"
import FavouriteButton from "components/buttons/favourite-button"
import styles from "./bg-color-card.css"

function BgImageCard(props) {
	const [isShown, setIsShown] = useState(false)
	const {
		fill,
		category,
		url,
		name,
		scale,
		opacity,
		onClick,
		isSelected,
		canEdit,
		editImage,
		deleteImage,
		isFavourite,
		showFavouriteButton,
		clickFavourite,
	} = props

	const renderMenu = () => {
		const listItems = []
		const push = (click, title, key, icon) =>
			listItems.push({
				click,
				name: title,
				key,
				icon,
			})
		const icon = <MenuIcon className={stylesDropdown.icon} />
		if (editImage) {
			push(
				editImage,
				"Edit",
				"edit",
				<Pencil1Icon className={stylesDropdown.itemIcon} />
			)
		}
		if (deleteImage) {
			listItems.push("divide")
			push(
				deleteImage,
				"Delete",
				"delete",
				<TrashIcon className={stylesDropdown.itemIcon} />
			)
		}
		return listItems.length ? (
			<DropdownMenu
				items={listItems}
				mainIcon={icon}
				darkTheme
				contentRight
				triggerClass={button.lightIcon}
				triggerClassActive={button.lightIconActive}
			/>
		) : null
	}

	return (
		<div
			className={styles.root}
			onMouseEnter={() => setIsShown(true)}
			onMouseLeave={() => setIsShown(false)}
		>
			<Transition in={isShown && canEdit} timeout={200}>
				{(state) => (
					<div className={`${styles.menu} ${styles[state]}`}>
						{renderMenu()}
					</div>
				)}
			</Transition>
			<div className={styles.figure}>
				{isSelected && (
					<div className={styles.tick}>
						<CheckIcon
							className={styles.icon}
							onClick={() =>
								onClick({
									fill,
									opacity,
									fillPatternScale: scale,
									fillPatternImage: null,
									fillurl: null,
								})
							}
						/>
					</div>
				)}
				<div className={styles.inner}>
					<img
						id={`${category}_${name}`}
						loading="lazy"
						role="presentation"
						className={styles.image}
						src={url}
						crossOrigin="anonymous"
						alt={name}
						onClick={({ target }) =>
							onClick({
								fill,
								opacity,
								fillPatternScale: scale,
								fillPatternImage: isSelected ? null : target,
								fillurl: isSelected ? null : target.src,
							})
						}
					/>
				</div>
			</div>
			<div className={styles.content}>
				<div className={styles.titleWithIcons}>
					<h4 className={styles.title}>{name}</h4>
					{showFavouriteButton && (
						<div className={styles.favButton}>
							<FavouriteButton
								isFavourite={isFavourite}
								onClick={clickFavourite}
							/>
						</div>
					)}
				</div>
			</div>
		</div>
	)
}
export default BgImageCard
