import { commitMutation, graphql } from "react-relay"

const mutation = graphql`
	mutation removeShopcastFlagMutation($input: RemoveShopcastFlagInput!) {
		removeShopcastFlag(input: $input) {
			admin {
				shopcastUserReports {
					edges {
						node {
							__typename
							... on ShopcastUserReport {
								id
							}
							... on LookbookUserReport {
								id
							}
						}
					}
				}
			}
		}
	}
`

const commit = (env, reportId, type) =>
	commitMutation(env, {
		mutation,
		variables: { input: { reportId, type } },
		updater: (storeProxy, data) => {
			if (data.removeShopcastFlag) {
				const reportProxy = storeProxy.get(reportId)
				reportProxy.setValue("pending", "status")
			}
		},
	})

export default { commit }
