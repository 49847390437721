import React, { Fragment } from "react"
import { Cross2Icon } from "@radix-ui/react-icons"
import Modal from "react-modal"
import { useFormik } from "formik"
import * as Yup from "yup"
import { toast } from "react-toastify"
import modal from "shared/styles/components/modal.css"
import button from "shared/styles/components/buttons.css"
import form from "shared/styles/components/form.css"
import textfield from "shared/styles/components/textfield.css"
import EmojiTextArea from "components/emoji-textarea/emoji-textarea"
import updateLookbookDetails from "../../../mutations/lookbook/update-lookbook-details"
import gqlHelper from "../../../helpers/gql-helper"

function LookbookSavingSimple({ relay, closeModal, lookbook, ...otherProps }) {
	const formik = useFormik({
		initialValues: {
			title: lookbook?.title || "",
			description: lookbook?.description || "",
		},
		validationSchema: Yup.object().shape({
			title: Yup.string().required("Title is required"),
			description: Yup.string(),
		}),
		onSubmit: (values) => {
			console.log(values)
			updateLookbookDetails.commit(
				relay.environment,
				{
					...values,
					id: lookbook.id,
				},
				(e) => {
					toast.error(
						gqlHelper.getError(e) || "Error updating lookbook",
						{ autoClose: 2000, closeButton: false }
					)
				},
				() => {
					toast.success(<Fragment>Board updated successfully.</Fragment>, {
						autoClose: 2000,
						closeButton: false,
					})
					closeModal()
				}
			)
		},
	})
	return (
		<Modal
			className={modal.root}
			overlayClassName={modal.overlay}
			isOpen
			{...otherProps}
			ariaHideApp={false}
		>
			<div className={`${modal.dialog} ${modal.dialogCentered}`}>
				<div className={`${modal.content}`}>
					<div className={modal.header}>
						<h4 className={modal.title}>Edit Title and Description</h4>
						<button
							type="button"
							className={button.close}
							onClick={closeModal}
						>
							<Cross2Icon className={button.closeIcon} />
						</button>
					</div>
					<div className={modal.body}>
						<div className={form.group}>
							<label className={form.label}>Title</label>
							<input
								type="text"
								className={textfield.normal}
								maxLength={255}
								placeholder="Enter a board title"
								name="title"
								value={formik.values.title}
								onChange={formik.handleChange}
							/>
							{formik.errors?.title && (
								<div className={form.validationFeedback}>
									{formik.errors.title}
								</div>
							)}
						</div>
						<div className={form.group}>
							<label className={form.label}>Description</label>
							<EmojiTextArea
								className={textfield.textarea}
								maxLength={2048}
								rows={5}
								name="description"
								placeholder="Add a caption and describe this board"
								value={formik.values.description}
								onChange={formik.handleChange}
							/>
							{formik.errors?.description && (
								<div className={form.validationFeedback}>
									{formik.errors.description}
								</div>
							)}
						</div>
					</div>
					<div className={`${modal.footer} ${modal.borderNone}`}>
						<button
							type="button"
							className={button.link}
							onClick={closeModal}
						>
							Cancel
						</button>
						<button
							type="button"
							className={button.primary}
							onClick={() => {
								formik.submitForm()
							}}
						>
							Save
						</button>
					</div>
				</div>
			</div>
		</Modal>
	)
}

export default LookbookSavingSimple
