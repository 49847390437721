import { commitMutation, graphql } from "react-relay"
import { ConnectionHandler } from "relay-runtime"
import { complete } from "../helper"

const mutation = graphql`
	mutation removeTeamMemberMutation($input: DeleteTeamMemberInput!) {
		deleteTeamMember(input: $input) {
			store {
				id
			}
		}
	}
`

const commit = (env, teamId, memberId, onError, onCompleted) =>
	commitMutation(env, {
		mutation,
		variables: {
			input: {
				memberId,
			},
		},
		onCompleted: complete(onCompleted, onError),
		onError: (e) => complete(null, [e]),
		updater: (storeProxy) => {
			const team = storeProxy.get(teamId)
			const memberConnection = ConnectionHandler.getConnection(
				team,
				"teams_members"
			)
			ConnectionHandler.deleteNode(memberConnection, memberId)
		},
	})

export default { commit }
