import React, { Component } from "react"
import styles from "./infinite-loading.css"

const defaultMessages = [
	"Hey There!",
	"Giving your video the WOW factor",
	"I like what you picked!",
	"Making your video schmick",
	"High-fiving the server",
	"You make me want to buy this product",
	"Sprinkling 'fairy dust' on your video",
	"Cleaning up spilled pixels",
	"Processing stuff for your video",
	"Hammering square pixels into round holes",
	"You look and sound great!",
	"Your clients will love this!",
	"You're selling this perfectly",
]
export default class InfiniteLoading extends Component {
	constructor(props) {
		super(props)
		this.state = {
			msgList: [],
			isOpen: false,
			processingMessage: null,
		}
		this.setProcessingMessage = this.setProcessingMessage.bind(this)
	}

	componentDidMount() {
		const { messages, isOpen } = this.props
		this.setState(
			{
				msgList: messages || defaultMessages,
				isOpen: isOpen === undefined ? true : isOpen,
			},()=>this.setProcessingMessage())
			
	}

	componentWillUnmount() {
		if (this.interval) {
			clearInterval(this.interval)
		}
		this.setState({ isOpen: false })
	}

	setProcessingMessage() {
		const { msgList, isOpen } = this.state
		if (isOpen) {
			this.setState({
				processingMessage:
					msgList[Math.floor(Math.random() * msgList.length)],
			})
		}
		this.interval = setInterval(() => {
			const { msgList: list } = this.state
			const processingMessage = list[Math.floor(Math.random() * list.length)]
			if (isOpen) {
				this.setState({ processingMessage })
			}
		}, 5000)
	}

	render() {
		const { isOpen, processingMessage } = this.state
		const { type, theme } = this.props
		return (
			isOpen && (
				<div
					className={`${
						type === "simple" ? styles.progressSimple : styles.progress
					} ${theme === "dark" ? styles.dark : styles.light}`}
				>
					<h4 className={styles.progressTitle}>{processingMessage}</h4>
					<div className={styles.indefiniteProgress}>
						<div className={styles.indefiniteProgressBar} />
					</div>
				</div>
			)
		)
	}
}
