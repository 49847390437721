import React, { Component } from "react"
import { toast } from "react-toastify"
import gqlHelper from "helpers/gql-helper"
import { createFragmentContainer, graphql } from "react-relay"
import form from "shared/styles/components/form.css"
import toggle from "shared/styles/components/toogle.css"
import updateDescriptionShopcastFolderMutation from "mutations/shopcast-manager/update-description-shopcast-folder"
import uploadFolderBannerMutation from "mutations/shopcast-manager/upload-folder-banner-photo"
import uploadFolderAvatarMutation from "mutations/shopcast-manager/upload-folder-avatar"
import SetFolderPreviewConfig from "mutations/shopcast-manager/set-folder-preview-config"
import renameShopcastFolderMutation from "mutations/shopcast-manager/rename-shopcast-folder"
import { debounce } from "helpers/ui-helper"
import EditPreviewSharedControls from "./edit-preview-shared-controls"
import styles from "./edit-preview.css"

class EditPreviewFolder extends Component {
	constructor(props) {
		super(props)
		this.state = {}
		this.saveShopcastFolderDescription = this.saveShopcastFolderDescription.bind(
			this
		)
		this.uploadFolderBanner = this.uploadFolderBanner.bind(this)
		this.uploadFolderAvatar = this.uploadFolderAvatar.bind(this)
		this.updateShowControls = this.updateShowControls.bind(this)
		this.saveFolderName = this.saveFolderName.bind(this)

		this.commitUpdatesDebounce = debounce(this.saveFolderName, 1000)
	}

	get folder() {
		const { currentUser } = this.props
		return currentUser.shopcastContainer.folder
	}

	saveShopcastFolderDescription(e) {
		const { relay, reloadIframe } = this.props
		updateDescriptionShopcastFolderMutation.commit(
			relay.environment,
			this.folder.id,
			e.target.value,
			() => {
				toast.success(<>Description updated</>, {
					autoClose: 2000,
					closeButton: false,
				})
				if (reloadIframe) reloadIframe()
			},
			(err) => {
				console.log(err)
			}
		)
	}

	saveFolderName(name) {
		const { relay, reloadIframe } = this.props
		renameShopcastFolderMutation.commit(
			relay.environment,
			this.folder.id,
			name,
			true,
			() => {
				toast.success(<>Description updated</>, {
					autoClose: 2000,
					closeButton: false,
				})
				if (reloadIframe) reloadIframe()
			},
			(err) => {
				console.log(err)
			}
		)
	}

	uploadFolderBanner(acceptedFiles) {
		let file = null
		if (acceptedFiles) [file] = acceptedFiles
		const { relay, reloadIframe } = this.props
		uploadFolderBannerMutation.commit(
			relay.environment,
			file,
			this.folder.id,
			() => {
				toast.success(<>Banner updated</>, {
					autoClose: 2000,
					closeButton: false,
				})
				if (reloadIframe) reloadIframe()
			},
			() => {}
		)
	}

	uploadFolderAvatar(acceptedFiles) {
		let file = null
		if (acceptedFiles) [file] = acceptedFiles
		const { relay, reloadIframe } = this.props
		uploadFolderAvatarMutation.commit(
			relay.environment,
			file,
			this.folder.id,
			() => {
				toast.success(<>Avatar updated</>, {
					autoClose: 2000,
					closeButton: false,
				})
				if (reloadIframe) reloadIframe()
			},
			() => {}
		)
	}

	updateShowControls(id, value) {
		const { relay, reloadIframe } = this.props
		SetFolderPreviewConfig.commit(
			relay.environment,
			this.folder.id,
			[{ id, value }],
			() => {
				toast.success(<>Updated successfully.</>, {
					autoClose: 2000,
					closeButton: false,
				})
				if (reloadIframe) reloadIframe()
			},
			(e) => {
				toast.error(
					gqlHelper.getError(e) || "Error updating controls.",
					{
						autoClose: 2000,
						closeButton: false,
					}
				)
			}
		)
	}

	render() {
		const { previewConfig } = this.folder
		const { currentUser: {
			sharedFolderShopcasts,
			sharedFolderLookbooks,
			sharedFolderFiles,
			sharedFolderCollectionItems
		} } = this.props
		return (
			<>
				<EditPreviewSharedControls
					data={{
						description: this.folder.description,
						bannerPhoto: this.folder.bannerPhoto,
						avatar: this.folder.avatar,
						displayName: this.folder.displayName,
						showBanner: previewConfig.showBanner,
						showAvatar: previewConfig.showAvatar,
						showDescription: previewConfig.showDescription,
						showDisplayName: previewConfig.showDisplayName,
					}}
					saveDescription={this.saveShopcastFolderDescription}
					uploadBanner={this.uploadFolderBanner}
					uploadAvatar={this.uploadFolderAvatar}
					updateShowControls={this.updateShowControls}
					saveDisplayName={this.commitUpdatesDebounce}
					enableBannerAvatarSelect
				/>
				<div className={styles.formRoot}>
					{sharedFolderShopcasts.edges.length > 0 && (
						<div className={form.section}>
							<div className={form.header}>
								<label className={toggle.checkSpaceBetween}>
									<div className={toggle.labelSm}>
										Show Shopcasts Section
									</div>
									<div className={toggle.switchSm}>
										<input
											type="checkbox"
											checked={previewConfig.showShopcastsSection}
											onChange={(e) =>
												this.updateShowControls(
													"showShopcastsSection",
													e.target.checked
												)
											}
										/>
										<div className={toggle.sliderSm} />
									</div>
								</label>
							</div>
						</div>
					)}
					{sharedFolderLookbooks.edges.length > 0 && (
						<div className={form.section}>
							<div className={form.header}>
								<label className={toggle.checkSpaceBetween}>
									<div className={toggle.labelSm}>
										Show Shopboards Section
									</div>
									<div className={toggle.switchSm}>
										<input
											type="checkbox"
											checked={previewConfig.showShopboardsSection}
											onChange={(e) =>
												this.updateShowControls(
													"showShopboardsSection",
													e.target.checked
												)
											}
										/>
										<div className={toggle.sliderSm} />
									</div>
								</label>
							</div>
						</div>
					)}
					{sharedFolderFiles.edges.length > 0 && (
						<div className={form.section}>
							<div className={form.header}>
								<label className={toggle.checkSpaceBetween}>
									<div className={toggle.labelSm}>
										Show Files Section
									</div>
									<div className={toggle.switchSm}>
										<input
											type="checkbox"
											checked={previewConfig.showFilesSection}
											onChange={(e) =>
												this.updateShowControls(
													"showFilesSection",
													e.target.checked
												)
											}
										/>
										<div className={toggle.sliderSm} />
									</div>
								</label>
							</div>
						</div>
					)}
					{sharedFolderCollectionItems.edges.length > 0 && (
						<div className={form.section}>
							<div className={form.header}>
								<label className={toggle.checkSpaceBetween}>
									<div className={toggle.labelSm}>
										Show Collections Section
									</div>
									<div className={toggle.switchSm}>
										<input
											type="checkbox"
											checked={previewConfig.showCollectionsSection}
											onChange={(e) =>
												this.updateShowControls(
													"showCollectionsSection",
													e.target.checked
												)
											}
										/>
										<div className={toggle.sliderSm} />
									</div>
								</label>
							</div>
						</div>
					)}
				</div>
			</>
		)
	}
}

export default createFragmentContainer(EditPreviewFolder, {
	currentUser: graphql`
	fragment editPreviewFolder_currentUser on User
	@argumentDefinitions(folder: { type: "String!" }) {
		shopcastContainer {
			id
			folder(id: $folder) {
				id
				name
				displayName
				description
				isShared
				sharableLink(noCheck: true)
				isParentAClient
				avatar
				bannerPhoto
				clients {
					id
					fullname
					email
					avatar
				}
				previewConfig {
					showBanner
					showAvatar
					showDisplayName
					showDescription
					showLookbookTab
					showClosetTab
					showShopcastsSection
					showShopboardsSection
					showFilesSection
					showCollectionsSection
				}
			}
		}
		sharedFolderShopcasts(first: 1, folderId: $folder) {
			edges {
				node {
					id
				}
			}
		}
		sharedFolderLookbooks(first: 1, folderId: $folder) {
			edges {
				node {
					id
				}
			}
		}
		sharedFolderFiles(first: 1, folderId: $folder) {
			edges {
				node {
					id
				}
			}
		}
		sharedFolderCollectionItems(first: 1, folderId: $folder) {
			edges {
				node {
					... on ShopcastProduct {
						id
					}
					... on lookbookUploads {
						id
					}
				}
			}
		}
	}`
})
