import React from "react"
import { EyeClosedIcon, EyeOpenIcon } from "@radix-ui/react-icons"
import styles from "./buttons.css"

export default function ShowHideButton({
	show,
	dataTip,
	hideButton,
	disabled,
	dark,
	onClick,
}) {
	return (
			<button
				className={dark ? styles.rootDark : styles.root}
				onClick={onClick}
				type="button"
				data-tooltip-id="tooltip"
				data-tooltip-place="bottom"
				data-tooltip-content={dataTip}
				style={{
					display: hideButton && "none",
				}}
				disabled={disabled}
			>
				{show ? (
					<EyeOpenIcon className={styles.icon} />
				) : (
					<EyeClosedIcon className={styles.icon} />
				)}
			</button>
	)
}
