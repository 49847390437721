import React from "react"
import { ResetIcon, ValueNoneIcon } from "@radix-ui/react-icons"
import moveableStyles from "routes/lookbook/moveable.css"

export default function BottomLeftPanel({ undo, redo, openClearConfirmation }) {
	return (
		<div className={moveableStyles.bottomLeft}>
			<button
				type="button"
				onClick={undo}
				className={moveableStyles.toolSubtle}
				data-tooltip-id="tooltip"
				data-tooltip-place="right"
				data-tooltip-content="Undo"
			>
				<ResetIcon className={moveableStyles.icon} />
			</button>
			<button
				type="button"
				onClick={redo}
				className={moveableStyles.toolFlip}
				data-tooltip-id="tooltip"
				data-tooltip-place="right"
				data-tooltip-content="Redo"
			>
				<ResetIcon className={moveableStyles.icon} />
			</button>
			<button
				type="button"
				onClick={openClearConfirmation}
				className={moveableStyles.toolSubtle}
				data-tooltip-id="tooltip"
				data-tooltip-place="right"
				data-tooltip-content="Clear Board"
			>
				<ValueNoneIcon className={moveableStyles.icon} />
			</button>
		</div>
	)
}
