/**
 * @generated SignedSource<<a3b94ba197faa30fb7a1cd6ad21db744>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Mutation } from 'relay-runtime';
export type RemoveVerificationRequestInput = {|
  clientMutationId?: ?string,
|};
export type removeVerificationRequestMutation$variables = {|
  input: RemoveVerificationRequestInput,
|};
export type removeVerificationRequestMutation$data = {|
  +removeVerificationRequest: ?{|
    +store: ?{|
      +currentUser: ?{|
        +id: string,
        +verificationInProgress: ?string,
      |},
      +id: string,
    |},
  |},
|};
export type removeVerificationRequestMutation = {|
  response: removeVerificationRequestMutation$data,
  variables: removeVerificationRequestMutation$variables,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "RemoveVerificationRequestPayload",
    "kind": "LinkedField",
    "name": "removeVerificationRequest",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Store",
        "kind": "LinkedField",
        "name": "store",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "currentUser",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "verificationInProgress",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "removeVerificationRequestMutation",
    "selections": (v2/*: any*/),
    "type": "ShopshareMutationRoot",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "removeVerificationRequestMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "992a0168247c72a5443e234cb4caff04",
    "id": null,
    "metadata": {},
    "name": "removeVerificationRequestMutation",
    "operationKind": "mutation",
    "text": "mutation removeVerificationRequestMutation(\n  $input: RemoveVerificationRequestInput!\n) {\n  removeVerificationRequest(input: $input) {\n    store {\n      id\n      currentUser {\n        id\n        verificationInProgress\n      }\n    }\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "114400541515759ea99bdb3759741680";

module.exports = ((node/*: any*/)/*: Mutation<
  removeVerificationRequestMutation$variables,
  removeVerificationRequestMutation$data,
>*/);
