/* eslint-disable no-alert */
import React from "react"
import { useBlocker } from "react-router-dom"

function Prompt({when, message}) {

	useBlocker(() => {
		if (when) {
			return !window.confirm(message)
		}
		return false
	})

	return <div key={when} />
}

export default Prompt
