import React, { PureComponent } from "react"
import { createPaginationContainer, graphql } from "react-relay"
import ListOfProfiles from "../list-of-profiles/list-of-profiles"

class ListOfLatestSharers extends PureComponent {
	render() {
		const { relay, store } = this.props
		return (
			<ListOfProfiles
				store={store}
				context="shopcast-card"
				relay={relay}
				currentUser={store.currentUser}
				profiles={store.directorySharers}
			/>
		)
	}
}

const query = graphql`
	query listOfLatestSharersQuery(
		$count: Int!
		$cursor: String
		$keyword: String
		$sort: String
	) {
		store {
			...listOfLatestSharers_store
				@arguments(
					count: $count
					cursor: $cursor
					keyword: $keyword
					sort: $sort
				)
		}
	}
`

ListOfLatestSharers = createPaginationContainer(
	ListOfLatestSharers,
	{
		store: graphql`
			fragment listOfLatestSharers_store on Store
			@argumentDefinitions(
				count: { type: "Int", defaultValue: 12 }
				cursor: { type: "String" }
				keyword: { type: "String" }
				sort: { type: "String" }
			) {
				id
				currentUser {
					id
					adminAccess
				}
				directorySharers(
					first: $count
					after: $cursor
					keyword: $keyword
					sort: $sort
				) @connection(key: "store_directorySharers") {
					edges {
						node {
							id
							fullname
							username
							customDomain
							displayName
							isFollowing
							profilePhoto
							bannerPhoto
							isRecommended
						}
					}
				}
			}
		`,
	},
	{
		direction: "forward",
		getConnectionFromProps(props) {
			const { directorySharers } = props.store
			return directorySharers
		},
		getFragmentVariables(prevVars, totalCount) {
			return {
				...prevVars,
				count: totalCount,
			}
		},
		getVariables(props, { count, cursor }) {
			return {
				count,
				cursor,
				keyword: props.keyword,
				sort: props.sort,
			}
		},
		query,
	}
)

export default {
	Component: ListOfLatestSharers,
	query,
	params: { count: 12, cursor: null },
}
