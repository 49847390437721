import React from "react"
import { ExclamationTriangleIcon } from "@radix-ui/react-icons"
import alert from "shared/styles/components/alert.css"
import styles from "./upgrade-plan-toast.css"

export default function UpgradePlanToast({ heading, message }) {
	return (
		<div className={alert.toast}>
			<ExclamationTriangleIcon className={alert.icon} />
			<h3 className={alert.heading}>{heading || "Upgrade your plan"}</h3>
			<p className={alert.message}>
				{message || "This feature is not available on your current plan."}
			</p>
			<a className={styles.action} href="/pricing">
				Update Plan
			</a>
		</div>
	)
}
