import { commitMutation, graphql } from "react-relay"
import { ConnectionHandler } from "relay-runtime"
import { complete } from "../helper.js"
import connections from "../connections"

const mutation = graphql`
	mutation deleteShopcastMutation($input: DeleteShopcastInput!) {
		deleteShopcast(input: $input) {
			store {
				id
				currentUser {
					id
				}
			}
		}
	}
`

const commit = (env, shopcastId, parentId, containerId, onError, onSuccess) =>
	commitMutation(env, {
		mutation,
		variables: { input: { shopcastId } },
		onCompleted: complete(onSuccess, onError),
		onError: () => complete(null, onError),
		updater: (storeProxy, data) => {
			if (data.deleteShopcast) {
				const parentFolder = storeProxy.get(parentId)
				parentFolder.setValue(parentFolder.getValue("shopcastsCount") - 1, "shopcastsCount")
				const conn = connections.currentUser.shopcastManager.items
				const shopcastsConnection = ConnectionHandler.getConnection(
					parentFolder,
					conn.name,
					{
						...conn.data,
						type: "shopcast"
					}
				)
				ConnectionHandler.deleteNode(shopcastsConnection, shopcastId)
				if (containerId) {
					const containerProxy = storeProxy.get(containerId)
					containerProxy.setValue(
						containerProxy.getValue("rootItemCount") - 1,
						"rootItemCount"
					)
				}
			}
		},
	})

export default { commit }
