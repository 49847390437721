import { commitMutation, graphql } from "react-relay"
import { complete } from "../helper.js"

const mutation = graphql`
	mutation toggleBoardShowMutation($input: ToggleBoardShowInput!) {
		toggleBoardShow(input: $input) {
			board {
				id
				lookbookId
				showBoard
			}
		}
	}
`

const commit = (env, boardId, onCompleted, onError) =>
	commitMutation(env, {
		mutation,
		variables: { input: { boardId } },
		onCompleted: complete(onCompleted, onError),
		onError: complete(onCompleted, onError),
		updater: (storeProxy, data) => {
			if (data.toggleBoardShow && data.toggleBoardShow.board) {
				const { id, showBoard } = data.toggleBoardShow.board
				const board = storeProxy.get(id)
				board.setValue(showBoard, "showBoard")
			}
		},
	})

export default { commit }
