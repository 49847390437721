/* eslint-disable no-multi-assign */
import React, { useEffect, useRef, useState } from "react"
import { usePopper } from "react-popper"
import { FaceIcon } from "@radix-ui/react-icons"
import TextareaAutosize from "react-autosize-textarea/lib"
import Picker from "emoji-picker-react"
import { mergeRefs } from "../../helpers/type-helper"
import styles from "./emoji-textarea.css"

function useOutsideAlerter(ref, onClickOutside) {
	useEffect(() => {
		function handleClickOutside(event) {
			if (ref && !ref.contains(event.target)) {
				onClickOutside()
			}
		}
		document.addEventListener("mousedown", handleClickOutside)
		return () => {
			document.removeEventListener("mousedown", handleClickOutside)
		}
	}, [ref])
}

const EmojiTextArea = React.forwardRef((props, ref) => {
	const [show, setShow] = useState(false)
	const textRef = useRef(null)

	const typeInTextarea = (newText) => {
		const el = textRef.current
		const nativeInputValueSetter = Object.getOwnPropertyDescriptor(
			window.HTMLTextAreaElement.prototype,
			"value"
		).set

		const start = el.selectionStart
		const end = el.selectionEnd
		const text = el.value
		const before = text.substring(0, start)
		const after = text.substring(end, text.length)
		nativeInputValueSetter.call(el, before + newText + after)

		window.setTimeout(() => {
			el.selectionStart = el.selectionEnd = start + newText.length
			el.focus()
		}, 0)

		el.dispatchEvent(new Event("input", { bubbles: true }))
	}

	const [referenceElement, setReferenceElement] = useState(null)
	const [popperElement, setPopperElement] = useState(null)
	useOutsideAlerter(popperElement, () => setShow(false))

	const { styles: stylesPopper, attributes } = usePopper(
		referenceElement,
		popperElement,
		{
			placement: "auto-start",
			strategy: "fixed",
			modifiers: [
				{
					name: "offset",
					options: {
						offset: [0, 8],
					},
				},
			],
		}
	)

	return (
		<div className={styles.root}>
			<TextareaAutosize
				{...props}
				ref={(input) => {
					mergeRefs(textRef, ref)(input)
				}}
			/>
			<button
				className={styles.button}
				type="button"
				onClick={() => setShow((val) => !val)}
				ref={setReferenceElement}
			>
				{/* {!show ? "Open Picker" : "Close Picker"} */}
				<FaceIcon className={styles.icon} />
			</button>
			{show && (
				<div
					ref={setPopperElement}
					style={{
						zIndex: 10,
						...stylesPopper.popper,
					}}
					{...attributes.popper}
				>
					<Picker
						onEmojiClick={(emojiObject) => {
							typeInTextarea(emojiObject.emoji)
						}}
						pickerStyle={{
							boxShadow: "var(--shadow-02)",
						}}
					/>
				</div>
			)}
		</div>
	)
})

export default EmojiTextArea
