/**
 * @generated SignedSource<<eea14598d869f48bc84f36535eac4a76>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Query } from 'relay-runtime';
import type { shopRecorder_store$fragmentType } from "./shopRecorder_store.graphql";
export type shopRecorderQuery$variables = {||};
export type shopRecorderQuery$data = {|
  +store: ?{|
    +$fragmentSpreads: shopRecorder_store$fragmentType,
  |},
|};
export type shopRecorderQuery = {|
  response: shopRecorderQuery$data,
  variables: shopRecorderQuery$variables,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "shopRecorderQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Store",
        "kind": "LinkedField",
        "name": "store",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "shopRecorder_store"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "ShopshareQueryRoot",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "shopRecorderQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Store",
        "kind": "LinkedField",
        "name": "store",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "currentUser",
            "plural": false,
            "selections": [
              (v0/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "94ae96906051efda9a35657281c7636a",
    "id": null,
    "metadata": {},
    "name": "shopRecorderQuery",
    "operationKind": "query",
    "text": "query shopRecorderQuery {\n  store {\n    ...shopRecorder_store\n    id\n  }\n}\n\nfragment shopRecorder_store on Store {\n  id\n  currentUser {\n    id\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "ed89710289ebeaa26f66b7d291bffa06";

module.exports = ((node/*: any*/)/*: Query<
  shopRecorderQuery$variables,
  shopRecorderQuery$data,
>*/);
