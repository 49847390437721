/**
 * @generated SignedSource<<fb97bf575b0be5f8dd40623c27f745e3>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Mutation } from 'relay-runtime';
export type PosterUrlInput = {|
  clientMutationId?: ?string,
  imageName: string,
  shopcastId: string,
|};
export type posterUrlMutation$variables = {|
  input: PosterUrlInput,
|};
export type posterUrlMutation$data = {|
  +posterUrl: ?{|
    +shopcast: ?{|
      +posterUrl: ?string,
    |},
  |},
|};
export type posterUrlMutation = {|
  response: posterUrlMutation$data,
  variables: posterUrlMutation$variables,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "posterUrl",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "posterUrlMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "PosterUrlPayload",
        "kind": "LinkedField",
        "name": "posterUrl",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Shopcast",
            "kind": "LinkedField",
            "name": "shopcast",
            "plural": false,
            "selections": [
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "ShopshareMutationRoot",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "posterUrlMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "PosterUrlPayload",
        "kind": "LinkedField",
        "name": "posterUrl",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Shopcast",
            "kind": "LinkedField",
            "name": "shopcast",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "606355ec3b61dbb78b05b09cb0f274db",
    "id": null,
    "metadata": {},
    "name": "posterUrlMutation",
    "operationKind": "mutation",
    "text": "mutation posterUrlMutation(\n  $input: PosterUrlInput!\n) {\n  posterUrl(input: $input) {\n    shopcast {\n      posterUrl\n      id\n    }\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "2ddb7a5c2fab968003056d4470aa6437";

module.exports = ((node/*: any*/)/*: Mutation<
  posterUrlMutation$variables,
  posterUrlMutation$data,
>*/);
