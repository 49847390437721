import React, { Component, Fragment } from "react"
import InfiniteScroll from "react-infinite-scroller"
import { toTitleCase } from "helpers/string-helper"
import LoadingDots from "components/loading-dots/loading-dots"
import FontCard from "components/cards/lookbook/font-card"
import ColorPicker from "components/lookbook-dropdown/color-picker"
import grids from "shared/styles/components/grids.css"
import lookbookAside from "shared/styles/layout/lookbook-aside.css"
import textfield from "shared/styles/components/textfield.css"
import { fontFamilyList } from "./lookbook"

class FontList extends Component {
	constructor(props) {
		super(props)
		this.state = {
			color: null,
		}
	}

	componentDidMount() {
		const { selectedColor } = this.props
		this.setState({ color: selectedColor })
	}

	render() {
		const { color } = this.state
		const { fontCategory, onCategoryChange, onChange, drop } = this.props
		const collectionList = []
		fontFamilyList.map((obj) => collectionList.push(obj.category))
		let fontList = []
		let renderFontFamilyList = fontFamilyList
		if (
			fontCategory &&
			fontFamilyList.find((obj) => obj.category === fontCategory)
		) {
			fontList = fontFamilyList.find(
				(obj) => obj.category === fontCategory
			).list
			renderFontFamilyList = fontFamilyList.filter(
				(obj) => obj.category === fontCategory
			)
		} else {
			fontFamilyList.map((obj) => fontList.push(...obj.list))
		}
		return (
			<Fragment>
				<div className={lookbookAside.header}>
					<select
						className={textfield.normal}
						value={fontCategory}
						onChange={onCategoryChange}
					>
						<option value="">All Categories</option>
						{collectionList &&
							collectionList.map((collection) => (
								<option key={collection} value={collection}>
									{toTitleCase(collection)}
								</option>
							))}
					</select>
				</div>
				<div className={lookbookAside.controls}>
					<ColorPicker
						color={color}
						onChange={(colorValue) =>
							this.setState({
								color: colorValue,
							})
						}
						onChangeComplete={(colorValue) => {
							onChange(colorValue)
							this.setState({
								color: colorValue,
							})
						}}
					/>
				</div>
				<InfiniteScroll
					hasMore={false}
					loader={<LoadingDots key={0} />}
					pageStart={0}
					useWindow={false}
					loadMore={() => {}}
				>
					<div className={lookbookAside.content}>
						{renderFontFamilyList.map((y) => (
							<Fragment key={y.category}>
								<div className={lookbookAside.sectionHeader}>
									{!fontCategory && (
										<h4 className={lookbookAside.heading}>
											{y.category}
										</h4>
									)}
								</div>
								<div className={grids.image}>
									{y.list.map((x) => (
										<FontCard
											key={x}
											drop={drop}
											fontFamily={x}
											color={color}
											showAddButton
										/>
									))}
								</div>
							</Fragment>
						))}
					</div>
				</InfiniteScroll>
			</Fragment>
		)
	}
}

export default FontList
