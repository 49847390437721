import React from "react"
import styles from "./testimonial-image.css"

export default function TestimonialImage({ testimonials }) {
	return (
		<ul className={styles.root}>
			{testimonials.map((testimonial) => (
				<li className={styles.quote} key={testimonial.key}>
					<div
						className={styles.media}
						style={{ backgroundImage: `url(${testimonial.image})` }}
					>
						{/* <div className={styles.figure}>
								<div className={styles.fit}>
									<img className={styles.image} decoding="async" src={testimonial.image} alt={testimonial.name} />
								</div>
							</div> */}
					</div>
					<div className={styles.body}>
						<div className={styles.content}>
							<blockquote className={styles.blockquote}>
								{testimonial.quote}
							</blockquote>
							<p className={styles.caption}>
								<a
									href={testimonial.channelURL}
									target="_blank"
									rel="noreferrer"
								>
									&ndash; {testimonial.name}
									<cite className={styles.cite}>
										{", | "}
										{testimonial.company}
									</cite>
								</a>
							</p>
						</div>
					</div>
				</li>
			))}
		</ul>
	)
}
