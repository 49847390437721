import React, { PureComponent } from "react"
import { createPaginationContainer, graphql } from "react-relay"
import ListOfProducts from "components/list-of-products/list-of-products.jsx"

/**
 * The purpose of this component is to wrap the data
 * requirements for a list of products in this case list of
 * infinity scrolling enabled products
 */
class SearchResultProducts extends PureComponent {
	render() {
		const { relay, store } = this.props
		return (
			<ListOfProducts
				relay={relay}
				products={store.search.products}
				enableHeader
			/>
		)
	}
}

const query = graphql`
	query searchResultProductsQuery(
		$count: Int!
		$cursor: String
		$keyword: String!
	) {
		store {
			...searchResultProducts_store
				@arguments(count: $count, cursor: $cursor, keyword: $keyword)
		}
	}
`

SearchResultProducts = createPaginationContainer(
	SearchResultProducts,
	{
		store: graphql`
			fragment searchResultProducts_store on Store
			@argumentDefinitions(
				count: { type: "Int", defaultValue: 15 }
				cursor: { type: "String" }
				keyword: { type: "String!" }
			) {
				id
				search(keyword: $keyword) {
					products(first: $count, after: $cursor)
						@connection(key: "search_products") {
						edges {
							node {
								id
								title
								imageUrl
								regularPrice
								offerPrice
								url
								affiliated_url
								user {
									id
									fullname
									username
									profilePhoto
									customDomain
								}
								shopcastItems {
									id
									shopcast {
										id
									}
									start
									end
								}

							}
						}
					}
				}
			}
		`,
	},
	{
		direction: "forward",
		getConnectionFromProps(props) {
			const { search } = props.store
			return search && search.products
		},
		getFragmentVariables(prevVars, totalCount) {
			return {
				...prevVars,
				count: totalCount,
			}
		},
		getVariables(props, { count, cursor }) {
			return {
				count,
				cursor,
				keyword: props.keyword,
			}
		},
		query,
	}
)

export default {
	Component: SearchResultProducts,
	query,
	params: { count: 15, cursor: null, keyword: null },
}
