import React from "react"
import input from "shared/styles/components/input.css"
import styles from "./page-selector.css"

function PageSelector({ mainClass, boards, value, onChange, multiple }) {
	const mainStyle = mainClass || styles.pageSquare
	return (
		<div className={styles.carousel}>
			<div className={styles.scrollable}>
				<div className={styles.tray}>
					{boards.map((board) => {
						const selected = multiple
							? value.find((i) => i === board.id)
							: board.id === value
						return (
							<div
								className={
									selected
										? `${mainStyle} ${styles.pageActive}`
										: mainStyle
								}
								key={board.id}
							>
								<label>
									<div className={styles.actions}>
										<input
											type="checkbox"
											className={input.checkbox}
											onChange={(event) => {
												onChange(board.id, event)
											}}
											checked={selected}
										/>
									</div>
									<div className={styles.inner}>
										<img
											className={styles.img}
											src={board.url}
											alt="Board Preview"
										/>
									</div>
								</label>
							</div>
						)
					})}
				</div>
			</div>
		</div>
	)
}

export default PageSelector
