import React from "react"
import { createFragmentContainer, graphql } from "react-relay"
import { withRouter } from "../../route-helpers"

const Comp = (Component) => {
	class AdminComponent extends React.Component {
		componentDidMount() {
			if (this.hasAdminAccess()) {
				return true
			}
			const { navigate } = this.props
			return navigate("/signin")
		}

		hasAdminAccess() {
			const { props } = this
			if (
				props.store &&
				props.store.currentUser &&
				props.store.currentUser.adminAccess === "HAS"
			) {
				return true
			}
			return false
		}

		render() {
			const { props } = this
			const { store } = props
			return <Component admin={store.admin} {...props} filters={{
				networks: store.networks,
				partneredNetworks: store.partneredNetworks
			}} />
		}
	}

	AdminComponent = createFragmentContainer(AdminComponent, {
		store: graphql`
			fragment adminComponent_store on Store {
				currentUser {
					id
					adminAccess
				}
				id
				admin {
					...adminRootPage_admin
				}
				networks {
					edges {
						node {
							id
							currency
							name
						}
					}
				}
				partneredNetworks {
					key
					value
				}
			}
		`,
	})

	return withRouter(AdminComponent)
}

const query = graphql`
	query adminComponentQuery {
		store {
			...adminComponent_store
		}
	}
`

export default { Component: Comp, query, needAuth: true }
