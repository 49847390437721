import React, { useState } from "react"
import { CameraIcon, Cross2Icon } from "@radix-ui/react-icons"
import Modal from "react-modal"
import { useFormik } from "formik"
import * as Yup from "yup"
import modal from "shared/styles/components/modal.css"
import button from "shared/styles/components/buttons.css"
import form from "shared/styles/components/form.css"
import textfield from "shared/styles/components/textfield.css"
import { toast } from "react-toastify"
import Dropzone from "react-dropzone"
import Avatar from "components/profile/avatar/avatar"
import icons from "shared/styles/components/icons.css"
import stylesAvatar from "components/profile/avatar/avatar.css"
import gqlHelper from "helpers/gql-helper"
import CreateTeamMutation from "mutations/team/create-team"
import UpdateTeamMutation from "mutations/team/update-team"
import styles from "./create-edit-team.css"

function CreateEditTeam({
	relay,
	closeModal,
	team,
	...otherProps
}) {
	const [avatarFile, setAvatarFile] = useState(null)
	const formik = useFormik({
		initialValues: {
			name: team?.name || "",
			avatar: team?.avatar || "",
		},
		validationSchema: Yup.object().shape({
			name: Yup.string().required("Name is required"),
			avatar: Yup.string(),
		}),
		onSubmit: (values) => {
			const data = {
				...values,
				file: avatarFile,
			}
			if (!team.id) {
				CreateTeamMutation.commit(
					relay.environment,
					data,
					(e) => {
						toast.info(<>{gqlHelper.getError(e)}</>, {
							autoClose: 5000,
							closeButton: false,
						})
					},
					() => {
						toast.success(<>Team created successfully</>, {
							autoClose: 2000,
							closeButton: false,
						})
						closeModal(true)
					}
				)
			} else {
				UpdateTeamMutation.commit(
					relay.environment,
					{
						...data,
						displayName: data.displayName,
						teamId: team.id,
					},
					(e) => {
						toast.info(<>{gqlHelper.getError(e)}</>, {
							autoClose: 5000,
							closeButton: false,
						})
					},
					() => {
						toast.success(<>Team updated successfully</>, {
							autoClose: 2000,
							closeButton: false,
						})
						closeModal(true)
					}
				)
			}
		},
	})
	return (
		<Modal
			className={modal.root}
			overlayClassName={modal.overlay}
			isOpen
			{...otherProps}
			ariaHideApp={false}
		>
			<div
				className={`${modal.dialog} ${modal.dialogCentered} ${modal.dialogFullscreenSmDown}`}
			>
				<div className={`${modal.content}`}>
					<div className={modal.header}>
						<h4 className={modal.title}>
							{team.id ? "Edit Team" : "New Team"}
						</h4>
						<button
							type="button"
							className={button.close}
							onClick={closeModal}
						>
							<Cross2Icon className={button.closeIcon} />
						</button>
					</div>
					<div className={modal.body}>
						<div className={form.group}>
							<label className={form.label}>Team Name</label>
							<input
								type="text"
								className={textfield.normal}
								name="name"
								placeholder="e.g. Dream Team"
								value={formik.values.name}
								onChange={formik.handleChange}
							/>
							{formik.errors?.name && (
								<div className={form.validationFeedback}>
									{formik.errors.name}
								</div>
							)}
						</div>
						<div className={form.group}>
							<div className={styles.formHeader}>
								<label className={form.label}>
									Logo <i>(Optional)</i>
								</label>
								{formik.values.avatar && (
									<button
										type="button"
										className={`${button.primaryRedLink} ${button.small}`}
										onClick={() => {
											setAvatarFile(null)
											formik.setFieldValue("avatar", "")
										}}
									>
										Remove
									</button>
								)}
							</div>
							<div className={styles.avatar}>
								<Dropzone
									multiple={false}
									onDrop={(acceptedFiles) => {
										if (!acceptedFiles) return
										const [file] = acceptedFiles
										setAvatarFile(file)
									}}
								>
									{({ getRootProps, getInputProps }) => (
										<div className={styles.avatarDrop}>
											<div
												{...getRootProps()}
												className={styles.avatarDropIcon}
											>
												<input {...getInputProps()} />
												<CameraIcon className={icons.icon32} />
											</div>
											<Avatar
												className={styles.avatar}
												styleName={stylesAvatar.border}
												photoUrl={
													avatarFile
														? URL.createObjectURL(avatarFile)
														: formik.values.avatar
												}
												fullname={team.name}
											/>
										</div>
									)}
								</Dropzone>
							</div>
							<p className={styles.bannerRecSize}>
								Recommended Size: <b>512x512px</b>
							</p>
						</div>
					</div>
					<div className={`${modal.footer} ${modal.borderNone}`}>
						<button
							type="button"
							className={button.link}
							onClick={closeModal}
						>
							Cancel
						</button>
						<button
							type="button"
							className={button.primary}
							onClick={() => {
								formik.submitForm()
							}}
						>
							Save
						</button>
					</div>
				</div>
			</div>
		</Modal>
	)
}

export default CreateEditTeam
