import React, { Component, Fragment } from "react"
import InfiniteScroll from "react-infinite-scroller"
import { createPaginationContainer, graphql } from "react-relay"
import { clickFavourite } from "helpers/lookbook-helper"
import { toTitleCase } from "helpers/string-helper"
import { CategoryENUM } from "helpers/enum-helper"
import FavouriteList from "components/lookbook/favourite-list"
import TemplateCard from "components/cards/lookbook/template-card"
import DropdownMenu from "components/dropdown-menu/dropdown-menu"
import LoadingDots from "components/loading-dots/loading-dots"
import ConformationPrompt from "components/modals/conformation-prompt/conformation-prompt"
import deleteTemplateMutation from "mutations/lookbook/delete-template"
import duplicateTemplateMutation from "mutations/lookbook/duplicate-template"
import toggleTemplateHideShowMutation from "mutations/lookbook/toggle-template-hide-show"
import MenuIcon from "shared/assets/menu-dots-horizontal.svg"
import TemplateIcon from "shared/assets/lb-template-icon.svg"
import grids from "shared/styles/components/grids.css"
import textfield from "shared/styles/components/textfield.css"
import lookbookAside from "shared/styles/layout/lookbook-aside.css"
import button from "shared/styles/components/buttons.css"
import stylesDropdown from "components/dropdown-menu/dropdown-menu-dark.css"
import empty from "shared/styles/components/empty.css"
import modal from "shared/styles/components/modal.css"
import { getComponent } from "../../route-helpers"

class TemplateList extends Component {
	constructor(props) {
		super(props)
		this.state = {
			deleteObj: null,
			addObj: null,
		}
		this.userTemplatesCategoryName = props.team ? "Team Templates" : "My Templates"
		this.fetchData = this.fetchData.bind(this)
		this.editImage = this.editImage.bind(this)
		this.closeModal = this.closeModal.bind(this)
		this.templateDrag = this.templateDrag.bind(this)
		this.templateAdd = this.templateAdd.bind(this)
		this.templateEdit = this.templateEdit.bind(this)
		this.templateDelete = this.templateDelete.bind(this)
	}

	componentDidMount() {
		const {
			store: { currentUser },
			applyTemplate,
			id,
		} = this.props
		if (currentUser){
			const template = currentUser.lookbookTemplate
			if (template && !id) {
				applyTemplate({
					...template.lookbook,
					templateId: template.id,
				}, true)
			}
		}
	}

	fetchData() {
		const { relay } = this.props
		relay.loadMore(12)
	}

	editImage(template) {
		window.location.replace(`/shopboard/create/${template.lookbook.id}`)
	}

	closeModal() {
		const { onEditChange, editObj } = this.props
		const { addObj } = this.state
		this.setState({ deleteObj: null, addObj: null })
		if (addObj || editObj) onEditChange(null)
	}

	templateDrag(product) {
		const { category, onCardDrag } = this.props
		return onCardDrag({ type: category, obj: product })
	}

	templateAdd(product) {
		const { haveChanges, applyTemplate } = this.props
		return haveChanges
		? this.setState({ addObj: product })
		: applyTemplate({ ...product.lookbook, templateId: product.id })
	}

	templateEdit(product) {
		window.location.replace(
			`/shopboard/create/${product.lookbook?.id}`
		)
	}

	templateDelete(product) {
		this.setState({ deleteObj: product })
	}

	renderMenu() {
		const { templateCategory, changeLookbook } = this.props
		const listItems = []
		const push = (click, name, key, icon) =>
			listItems.push({
				click,
				name,
				key,
				icon,
			})
		const icon = <MenuIcon className={stylesDropdown.icon} />
		push(
			() => {
				window.location.replace(
					`/shopboard/create?tempCategory=${templateCategory}`
				)
			},
			"New Template",
			"new",
			<TemplateIcon className={stylesDropdown.itemIcon} />
		)
		listItems.push("divide")
		push(
			() => {
				changeLookbook({ isTemplate: true, isGlobal: false })
			},
			"Save Template As...",
			"saveAs",
			<TemplateIcon className={stylesDropdown.itemIcon} />
		)

		return (
			<DropdownMenu
				mainIcon={icon}
				items={listItems}
				contentRight
				darkTheme
				triggerClass={button.dropdownIconLg}
				triggerClassActive={button.dropdownIconActive}
			/>
		)
	}

	renderPropmt(templateList) {
		const { relay, applyTemplate, editObj: editTemp, store } = this.props
		const { deleteObj, addObj } = this.state
		let title = ""
		let approveText = ""
		let message = ""
		if (deleteObj) {
			title = "Delete Template"
			approveText = "Delete"
			message = (
				<>
					<p className={modal.para}>
						Do you want to permanently delete{" "}
						<b>{deleteObj?.lookbook ? deleteObj.lookbook.title : ""}</b>{" "}
						Template?
					</p>
					<p className={modal.paraImportant}>
						This action cannot be undone.
					</p>
				</>
			)
		}
		if (addObj || editTemp) {
			title = "Replace Template?"
			approveText = "Yes, Replace"
			message = `Changes you made so far will not be saved.`
		}
		return (
			<ConformationPrompt
				openModal={!!deleteObj || !!addObj || !!editTemp}
				closeModal={this.closeModal}
				approveEvent={() => {
					const { templateCategory: tc } = this.props
					if (deleteObj) {
						deleteTemplateMutation.commit(
							relay.environment,
							deleteObj.lookbook.id,
							deleteObj.id,
							store?.currentUser.id,
							() => {},
							() => {
								relay.refetchConnection(templateList.length - 1)
							},
							{ category: tc }
						)
					}
					if (addObj || editTemp) {
						applyTemplate({
							...(addObj?.lookbook || editTemp?.lookbook),
							templateId: addObj?.id || editTemp?.id,
						})
					}
					this.closeModal()
				}}
				title={title}
				declineEvent={() => {
					this.closeModal()
				}}
				approveButtonStyle={deleteObj ? button.primaryRed : button.primary}
				approveText={approveText}
				declineText="Cancel"
			>
				{message}
			</ConformationPrompt>
		)
	}

	renderEmpty() {
		const { templateCategory } = this.props
		return (
			<div className={empty.root}>
				<div className={empty.container}>
					<div className={empty.content}>
						<h4 className={empty.headline}>
							No templates found
						</h4>
						<p className={empty.subline}>
							Any templates created will appear here.
						</p>
						<button
							type="button"
							className={empty.cta}
							onClick={()=>{
								window.location.replace(
									`/shopboard/create?tempCategory=${templateCategory}`
								)
							}}
						>
							New Template
						</button>
					</div>
				</div>
			</div>
		)
	}

	render() {
		const {
			templateCategory,
			onChange,
			store,
			relay,
			category,
			loading: mainLoading,
			favouriteListOpen,
			onCardDrag,
			haveChanges,
			applyTemplate,
			isAdmin,
			id,
			team
		} = this.props
		if (mainLoading || !(store && store.currentUser)) {
			return <LoadingDots />
		}
		const templateList = (store.currentUser.lookbookTemplates
			? store.currentUser.lookbookTemplates.edges
			: []
		).map((obj) => obj.node)
		const categoryList = store.currentUser.lookbookTemplatesCategories || []
		const hasfavourites =
			(store.currentUser.lookbookFavourites
				? store.currentUser.lookbookFavourites.edges
				: []
			).map((obj) => obj.node).length > 0 ||
			templateList.filter((obj) => obj.isFavourite === true).length > 0
		const templateGrouped = []
		templateList.forEach((temp) => {
			if (temp.lookbook) {
				if (temp.categories && temp.categories.length > 0) {
					temp.categories.forEach((cat) => {
						if (templateGrouped.find((obj) => obj.category === cat)) {
							templateGrouped
								.find((obj) => obj.category === cat)
								.templates.push(temp)
						} else {
							templateGrouped.push({
								category: cat,
								templates: [temp],
							})
						}
					})
				} else if (
					templateGrouped.find(
						(obj) => obj.category === this.userTemplatesCategoryName
					)
				) {
					templateGrouped
						.find(
							(obj) => obj.category === this.userTemplatesCategoryName
						)
						.templates.push(temp)
				} else {
					templateGrouped.push({
						category: this.userTemplatesCategoryName,
						templates: [temp],
					})
				}
			}
		})
		const FavouriteListComp = getComponent(
			`fav_list_template`,
			FavouriteList,
			{
				...FavouriteList.params,
				category,
				teamId: team,
			},
			{
				favouriteListOpen,
				category,
				templateDrag: this.templateDrag,
				templateAdd: this.templateAdd,
				templateEdit: this.templateEdit,
				templateDelete: this.templateDelete,
			}
		)
		return (
			<Fragment>
				<div className={lookbookAside.header}>
					<div className={lookbookAside.headerActions}>
						<select
							className={textfield.normal}
							value={templateCategory}
							onChange={onChange}
						>
							<option value="">All Templates</option>
							<option disabled>─</option>
							<option value="userTemplates">
								{this.userTemplatesCategoryName}
							</option>
							{hasfavourites && (
								<Fragment>
									<option disabled>─</option>
									<option value={CategoryENUM.FAVOURITE}>
										{" "}
										❤️ My Favourites
									</option>
								</Fragment>
							)}

							{categoryList && categoryList.length > 0 && (
								<Fragment>
									<option disabled>─</option>
									<option disabled>ShopShare Templates</option>
								</Fragment>
							)}
							{categoryList.map((x) => (
								<option key={x} value={x}>
									{toTitleCase(x)}
								</option>
							))}
						</select>
						{this.renderMenu()}
					</div>
				</div>
				{favouriteListOpen && hasfavourites ? (
					FavouriteListComp
				) : (
					<InfiniteScroll
						loadMore={this.fetchData}
						hasMore={relay ? relay.hasMore() : false}
						loader={<LoadingDots key={0} />}
						pageStart={0}
						useWindow={false}
					>
						<div className={lookbookAside.content}>
							{templateCategory &&
								templateCategory === "userTemplates" &&
								templateGrouped.length === 0 && (
									this.renderEmpty()
								)}
							{templateGrouped.map((y) => (
								<Fragment key={y.category}>
									{!templateCategory && (
										<div className={lookbookAside.sectionHeader}>
											<h4 className={lookbookAside.heading}>
												{y.category}
											</h4>
										</div>
									)}
									<div className={grids.template}>
										{y.templates.map((x) => (
											<TemplateCard
												key={x.id}
												relay={relay}
												node={x}
												showFavouriteButton
												isAdmin={isAdmin}
												editSame={id === x.lookbook.id}
												category={category}
												clickFavourite={() =>
													clickFavourite(
														x.id,
														category,
														team,
														relay
													)
												}
												templateDrag={() =>
													onCardDrag({
														type: category,
														obj: x,
													})
												}
												clickAdd={() =>
													haveChanges
														? this.setState({ addObj: x })
														: applyTemplate({
																...x.lookbook,
																templateId: x.id,
														  })
												}
												editImage={() =>
													window.location.replace(
														`/shopboard/create/${x.lookbook?.id}`
													)
												}
												deleteImage={() =>
													this.setState({ deleteObj: x })
												}
												onDuplicate={() =>
													duplicateTemplateMutation.commit(
														relay.environment,
														x.lookbook.id,
														store?.currentUser.id,
														() => {},
														() => {
															relay.refetchConnection(
																templateList.length + 1
															)
														}
													)
												}
												onShowHide={(team || (x.canEdit && x.categories.length > 0)) ? () => {
													toggleTemplateHideShowMutation.commit(
														relay.environment,
														x.id,
														!x.isGlobal,
														() => {},
														() => {}
													)
												} : null}
											/>
										))}
									</div>
								</Fragment>
							))}
						</div>
					</InfiniteScroll>
				)}
				{this.renderPropmt(templateList)}
			</Fragment>
		)
	}
}

const query = graphql`
	query templateListQuery(
		$count: Int!
		$cursor: String
		$templateCategory: String
		$teamId: String
		$templateId: String
	) {
		store {
			...templateList_store
				@arguments(
					count: $count
					cursor: $cursor
					category: $templateCategory
					teamId: $teamId
					templateId: $templateId
				)
		}
	}
`

TemplateList = createPaginationContainer(
	TemplateList,
	{
		store: graphql`
			fragment templateList_store on Store
			@argumentDefinitions(
				count: { type: "Int", defaultValue: 24 }
				cursor: { type: "String" }
				category: { type: "String", defaultValue: "" }
				teamId: { type: "String" }
				templateId: { type: "String" }
			) {
				currentUser {
					id
					lookbookTemplatesCategories
					lookbookTemplate(id: $templateId) {
						id
						categories
						canEdit
						isFavourite
						createdCount
						isGlobal
						lookbook {
							id
							title
							url
							screen_width
							data_json
							width
							height
							background
							group
							shapes {
								edges {
									node {
										id
										viewBox
										path
										collection
									}
								}
							}
						}
					}
					lookbookTemplates(
						first: $count
						after: $cursor
						category: $category
						teamId: $teamId
					) @connection(key: "currentUser_lookbookTemplates") {
						edges {
							node {
								id
								categories
								canEdit
								isFavourite
								createdCount
								isGlobal
								lookbook {
									id
									title
									url
									screen_width
									data_json
									width
									height
									background
									group
									shapes {
										edges {
											node {
												id
												viewBox
												path
												collection
											}
										}
									}
								}
							}
						}
					}
					lookbookFavourites(category: "template", teamId: $teamId) {
						edges {
							node {
								__typename
								... on LookbookTemplate {
									id
									isFavourite
								}
							}
						}
					}
				}
			}
		`,
	},
	{
		direction: "forward",
		getConnectionFromProps(props) {
			const { currentUser } = props.store
			return currentUser && currentUser.lookbookTemplates
		},
		getFragmentVariables(prevVars, totalCount) {
			return {
				...prevVars,
				count: totalCount,
			}
		},
		getVariables(props, { count, cursor }) {
			return {
				count,
				cursor,
				templateCategory: props.templateCategory,
				teamId: props.team,
				templateId: props.templateId
			}
		},
		query,
	}
)
export default {
	Component: TemplateList,
	query,
	params: {
		count: 12,
		cursor: null,
		templateCategory: null,
		templateId: null,
	},
}
