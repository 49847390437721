import React, { useEffect, useRef, useState } from "react"
import { usePopper } from "react-popper"
// import FolderNestedIcon from "shared/assets/folderNested.svg"
// import FolderIcon from "shared/assets/folder-icon.svg"
import { useOutsideAlerter } from "helpers/ui-helper"
import MenuIcon from "shared/assets/menu-dots-horizontal.svg"
import lightStyles from "./dropdown-menu.css"
import darkStyles from "./dropdown-menu-dark.css"

function DropdownMenu({
	items,
	context,
	count,
	mainIcon,
	iconLg,
	triggerClassActive: propsTriggerClassActive,
	triggerClass,
	darkTheme,
	contentRight,
	strategy,
	activeItem,
	iconIsText,
	loading,
	setDropdownOpened,
}) {
	const [dropdownActive, setDropdownStatus] = useState(false)
	const [referenceElement, setReferenceElement] = useState(null)
	const [popperElement, setPopperElement] = useState(null)
	const [rootElement, setRootElement] = useState(false)
	useOutsideAlerter(rootElement, () => setDropdownStatus(false))

	const firstUpdate = useRef(true);
	useEffect(() => {
		if (firstUpdate.current) {
			firstUpdate.current = false
			return
		}
		if (setDropdownOpened) setDropdownOpened(dropdownActive)
	}, [dropdownActive])

	const click = (li) => () => {
		if (!li.disabled) {
			setDropdownStatus(false)
			li.click()
		}
	}
	const styles = darkTheme ? darkStyles : lightStyles

	const triggerClassActive = []

	if (propsTriggerClassActive) triggerClassActive.push(propsTriggerClassActive)
	if (triggerClass) triggerClassActive.push(triggerClass)

	const { styles: stylesPopper, attributes } = usePopper(
		referenceElement,
		popperElement,
		{
			placement: contentRight ? "bottom-end" : "bottom-start",
			strategy: strategy || "fixed",
			modifiers: [
				{
					name: "offset",
					options: {
						offset: [0, 8],
					},
				},
			],
		}
	)
	const getTriggerTextClass = () => {
		if (!context) {
			if (dropdownActive)
				return triggerClassActive.length
					? triggerClassActive.join(" ")
					: styles.trigger
			if (iconIsText) return styles.triggerText
			return triggerClass || styles.trigger
		}
		if (!dropdownActive) {
			return triggerClass || styles.triggerFolder
		}
		return triggerClassActive.length
			? triggerClassActive.join(" ")
			: styles.triggerFolder
	}

	const renderTrigger = () => {
		if (!context)
			return (
				mainIcon || (
					<MenuIcon className={iconLg ? styles.icon32 : styles.icon} />
				)
			)
		return (
			<>
				<MenuIcon className={styles.iconFolder} />
				<div className={styles.count}>{`${count}`}</div>
			</>
		)
	}

	const renderContent = () => {
		if (!context) {
			let dividerId = 1
			return (
				<>
					{items.map((li) => {
						if (li === "divide") {
							return (
								<div
									className={styles.divider}
									key={(() => {
										dividerId += dividerId
										return dividerId
									})()}
								/>
							)
						}
						if (li.key && li.key.startsWith("heading")) {
							return (
								<div className={styles.heading} key={li.key}>
									{li.name}
								</div>
							)
						}
						if (li.click !== null) {
							let className =
								activeItem && activeItem === li
									? styles.itemActive
									: styles.item
							if (li.disabled) className = styles.itemDisabled
							return (
								<a
									className={className}
									onClick={click(li)}
									onKeyPress={click(li)}
									tabIndex={-1}
									role="link"
									key={li.key || li.name}
									style={li.style}
								>
									{li.icon} {li.name}
								</a>
							)
						}

						return (
							<a
								className={styles.item}
								tabIndex={-1}
								role="link"
								key={li.key}
							>
								{li.name}
							</a>
						)
					})}
				</>
			)
		}
		return (
			<>
				{items.map((li) => {
					let className =
						activeItem && activeItem === li
							? styles.itemActive
							: styles.item
					if (li.disabled) className = styles.itemDisabled
					return (
						<a
							className={className}
							onClick={click(li)}
							onKeyPress={click(li)}
							tabIndex={-1}
							role="link"
							key={li.name}
						>
							{li.name}
						</a>
					)
				})}
			</>
		)
	}
	return (
		<div className={styles.root} ref={setRootElement}>
			<button
				ref={setReferenceElement}
				className={getTriggerTextClass()}
				type="button"
				onClick={() => setDropdownStatus(!dropdownActive)}
				disabled={loading}
			>
				{renderTrigger()}
			</button>
			{dropdownActive && (
				<div
					ref={setPopperElement}
					className={styles.content}
					style={{
						...stylesPopper.popper,
					}}
					{...attributes.popper}
				>
					{renderContent()}
				</div>
			)}
		</div>
	)
}
export default DropdownMenu
