/**
 * @generated SignedSource<<b6b12d791ff898ba7ad8c1c61dd6326d>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Query } from 'relay-runtime';
import type { wardrobe_store$fragmentType } from "./wardrobe_store.graphql";
export type wardrobeQuery$variables = {||};
export type wardrobeQuery$data = {|
  +store: ?{|
    +$fragmentSpreads: wardrobe_store$fragmentType,
  |},
|};
export type wardrobeQuery = {|
  response: wardrobeQuery$data,
  variables: wardrobeQuery$variables,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "username",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "wardrobeQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Store",
        "kind": "LinkedField",
        "name": "store",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "wardrobe_store"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "ShopshareQueryRoot",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "wardrobeQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Store",
        "kind": "LinkedField",
        "name": "store",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "currentUser",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "ProductCollectionConnection",
                "kind": "LinkedField",
                "name": "productCollections",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ProductCollectionEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ProductCollection",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v0/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "title",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Wardrobe",
                "kind": "LinkedField",
                "name": "wardrobe",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "user",
                    "plural": false,
                    "selections": [
                      (v0/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "fullname",
                        "storageKey": null
                      },
                      (v1/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "profilePhoto",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "bannerPhoto",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ShopcastFolder",
                    "kind": "LinkedField",
                    "name": "folders",
                    "plural": true,
                    "selections": [
                      (v0/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "User",
                        "kind": "LinkedField",
                        "name": "user",
                        "plural": false,
                        "selections": [
                          (v0/*: any*/),
                          (v1/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "afbab24da95e73d1e1ae1c649aff9852",
    "id": null,
    "metadata": {},
    "name": "wardrobeQuery",
    "operationKind": "query",
    "text": "query wardrobeQuery {\n  store {\n    ...wardrobe_store\n    id\n  }\n}\n\nfragment sharedFolderCard_shopcastFolder on ShopcastFolder {\n  id\n  user {\n    id\n    username\n  }\n}\n\nfragment wardrobe_store on Store {\n  id\n  currentUser {\n    id\n    productCollections {\n      edges {\n        node {\n          id\n          title\n        }\n      }\n    }\n    wardrobe {\n      user {\n        id\n        fullname\n        username\n        profilePhoto\n        bannerPhoto\n      }\n      folders {\n        id\n        ...sharedFolderCard_shopcastFolder\n      }\n    }\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "266d4f8bb9baa1aa08ed459cf21b4ac8";

module.exports = ((node/*: any*/)/*: Query<
  wardrobeQuery$variables,
  wardrobeQuery$data,
>*/);
