/**
 * @generated SignedSource<<f9ce01d787fdbf164f287fceaceba5e2>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Query } from 'relay-runtime';
import type { clientViewFolders_store$fragmentType } from "./clientViewFolders_store.graphql";
export type clientViewFoldersQuery$variables = {|
  count: number,
  cursor?: ?string,
  folder: string,
  sort?: ?string,
|};
export type clientViewFoldersQuery$data = {|
  +store: ?{|
    +$fragmentSpreads: clientViewFolders_store$fragmentType,
  |},
|};
export type clientViewFoldersQuery = {|
  response: clientViewFoldersQuery$data,
  variables: clientViewFoldersQuery$variables,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "count"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "cursor"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "folder"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "sort"
  }
],
v1 = {
  "kind": "Variable",
  "name": "sort",
  "variableName": "sort"
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "cursor"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "count"
  },
  {
    "kind": "Literal",
    "name": "isShared",
    "value": true
  },
  (v1/*: any*/)
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "url",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "clientViewFoldersQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Store",
        "kind": "LinkedField",
        "name": "store",
        "plural": false,
        "selections": [
          {
            "args": [
              {
                "kind": "Variable",
                "name": "count",
                "variableName": "count"
              },
              {
                "kind": "Variable",
                "name": "cursor",
                "variableName": "cursor"
              },
              {
                "kind": "Variable",
                "name": "folder",
                "variableName": "folder"
              },
              (v1/*: any*/)
            ],
            "kind": "FragmentSpread",
            "name": "clientViewFolders_store"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "ShopshareQueryRoot",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "clientViewFoldersQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Store",
        "kind": "LinkedField",
        "name": "store",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "currentUser",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": [
                  {
                    "kind": "Variable",
                    "name": "id",
                    "variableName": "folder"
                  }
                ],
                "concreteType": "ShopcastFolder",
                "kind": "LinkedField",
                "name": "clientFolder",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": (v3/*: any*/),
                    "concreteType": "ShopcastFolderShopcastFolderConnection",
                    "kind": "LinkedField",
                    "name": "folders",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ShopcastFolderShopcastFolderEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "cursor",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "ShopcastFolder",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v2/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "name",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "itemsCount",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": [
                                  {
                                    "kind": "Literal",
                                    "name": "first",
                                    "value": 4
                                  }
                                ],
                                "concreteType": "FolderItemsConnectionConnection",
                                "kind": "LinkedField",
                                "name": "items",
                                "plural": false,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "FolderItemsConnectionEdge",
                                    "kind": "LinkedField",
                                    "name": "edges",
                                    "plural": true,
                                    "selections": [
                                      {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": null,
                                        "kind": "LinkedField",
                                        "name": "node",
                                        "plural": false,
                                        "selections": [
                                          (v4/*: any*/),
                                          {
                                            "kind": "InlineFragment",
                                            "selections": [
                                              {
                                                "alias": null,
                                                "args": null,
                                                "kind": "ScalarField",
                                                "name": "posterUrl",
                                                "storageKey": null
                                              },
                                              (v2/*: any*/)
                                            ],
                                            "type": "Shopcast",
                                            "abstractKey": null
                                          },
                                          {
                                            "kind": "InlineFragment",
                                            "selections": [
                                              (v5/*: any*/),
                                              (v2/*: any*/)
                                            ],
                                            "type": "Lookbook",
                                            "abstractKey": null
                                          },
                                          {
                                            "kind": "InlineFragment",
                                            "selections": [
                                              (v5/*: any*/),
                                              {
                                                "alias": null,
                                                "args": null,
                                                "kind": "ScalarField",
                                                "name": "thumbnailUrl",
                                                "storageKey": null
                                              },
                                              (v2/*: any*/)
                                            ],
                                            "type": "ShopshareFile",
                                            "abstractKey": null
                                          },
                                          {
                                            "kind": "InlineFragment",
                                            "selections": [
                                              (v2/*: any*/),
                                              {
                                                "alias": null,
                                                "args": null,
                                                "kind": "ScalarField",
                                                "name": "images",
                                                "storageKey": null
                                              }
                                            ],
                                            "type": "ProductCollection",
                                            "abstractKey": null
                                          }
                                        ],
                                        "storageKey": null
                                      }
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": "items(first:4)"
                              },
                              (v4/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "PageInfo",
                        "kind": "LinkedField",
                        "name": "pageInfo",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "hasPreviousPage",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "hasNextPage",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "startCursor",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "endCursor",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": (v3/*: any*/),
                    "filters": [],
                    "handle": "connection",
                    "key": "clientFolder_folders",
                    "kind": "LinkedHandle",
                    "name": "folders"
                  }
                ],
                "storageKey": null
              },
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "e8a3271d7736c8bf439b4df5776ad4ea",
    "id": null,
    "metadata": {},
    "name": "clientViewFoldersQuery",
    "operationKind": "query",
    "text": "query clientViewFoldersQuery(\n  $count: Int!\n  $cursor: String\n  $folder: String!\n  $sort: String\n) {\n  store {\n    ...clientViewFolders_store_3D5ATB\n    id\n  }\n}\n\nfragment clientFolderCard_shopcastFolder on ShopcastFolder {\n  id\n  name\n  itemsCount\n  items(first: 4) {\n    edges {\n      node {\n        __typename\n        ... on Shopcast {\n          posterUrl\n          id\n        }\n        ... on Lookbook {\n          url\n          id\n        }\n        ... on ShopshareFile {\n          url\n          thumbnailUrl\n          id\n        }\n        ... on ProductCollection {\n          id\n          images\n        }\n      }\n    }\n  }\n}\n\nfragment clientViewFolders_store_3D5ATB on Store {\n  id\n  currentUser {\n    clientFolder(id: $folder) {\n      id\n      folders(first: $count, after: $cursor, sort: $sort, isShared: true) {\n        edges {\n          cursor\n          node {\n            ...clientFolderCard_shopcastFolder\n            id\n            __typename\n          }\n        }\n        pageInfo {\n          hasPreviousPage\n          hasNextPage\n          startCursor\n          endCursor\n        }\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "6dfeee7ac3c69d9f97fb8914e0efd6f6";

module.exports = ((node/*: any*/)/*: Query<
  clientViewFoldersQuery$variables,
  clientViewFoldersQuery$data,
>*/);
