import axios from "axios"
import React, { Fragment, useEffect, useState } from "react"
import { toast } from "react-toastify"
import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	Legend,
} from "chart.js"
import { Bar, Line } from "react-chartjs-2"
import Dropdown, {
	DropdownTrigger,
	DropdownContent,
} from "react-simple-dropdown"
import contentCard from "shared/styles/layout/content-card.css"
import pageContent from "shared/styles/layout/page-content.css"
import dropdown from "components/dropdown-menu/dropdown-menu-dark.css"
import { apiUrl } from "constants"
import dayjs from "helpers/dayjs-helper"
import styles from "./dashboard.css"

ChartJS.register(
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	Legend
)
const rangeOptions = [
	{
		value: "last-7-days",
		label: "Last 7 Days",
	},
	{
		value: "this-month",
		label: "This Month",
	},
	{
		value: "last-month",
		label: "Last Month",
	},
	{
		value: "this-quarter",
		label: "This Quarter",
	},
	{
		value: "last-quarter",
		label: "Last Quarter",
	},
	{
		value: "this-year",
		label: "This Year",
	},
	{
		value: "last-year",
		label: "Last Year",
	},
	{ value: "all-time", label: "All Time" },
]

const getRange = (value) => {
	switch (value) {
		case "last-7-days": {
			return {
				startDate: dayjs().subtract(7, "days"),
				endDate: dayjs(),
			}
		}
		case "this-month": {
			return {
				startDate: dayjs().startOf("month"),
				endDate: dayjs().endOf("month"),
			}
		}
		case "last-month": {
			return {
				startDate: dayjs().subtract(1, "months").startOf("month"),
				endDate: dayjs().subtract(1, "months").endOf("month"),
			}
		}
		case "this-quarter": {
			return {
				startDate: dayjs().startOf("quarter"),
				endDate: dayjs().endOf("quarter"),
			}
		}
		case "last-quarter": {
			return {
				startDate: dayjs().subtract(1, "Q").startOf("quarter"),
				endDate: dayjs().subtract(1, "Q").endOf("quarter"),
			}
		}
		case "this-year": {
			return {
				startDate: dayjs().startOf("year"),
				endDate: dayjs().endOf("year"),
			}
		}
		case "last-year": {
			return {
				startDate: dayjs().subtract(1, "years").startOf("year"),
				endDate: dayjs().subtract(1, "years").endOf("year"),
			}
		}
		case "all-time": {
			return {
				startDate: dayjs("2020-09-07").startOf("month"),
				endDate: dayjs().endOf("month"),
			}
		}
		default:
			return {
				startDate: dayjs().startOf("month"),
				endDate: dayjs().endOf("month"),
			}
	}
}

function BusinessDashboard() {
	const [data, setData] = useState(null)
	const [range, setRange] = useState(rangeOptions[1])

	const loadData = () => {
		axios
			.get(`${apiUrl}/dashboard?range=${range.value}`)
			.then((res) => {
				setData(res.data)
			})
			.catch(() => {
				toast.error(
					<Fragment>Could not get data. please try again later</Fragment>,
					{
						autoClose: 2000,
						closeButton: false,
					}
				)
			})
	}

	useEffect(() => {
		loadData()
	}, [range])

	const getDateArray = (from, to, interval, unitType = "days") => {
		const ret = []
		let date = from
		while (to > date) {
			ret.push(date)
			date = dayjs(date).add(interval, unitType)
		}
		return ret
	}

	const dateRanges = getRange(range.value)
	const divideByMonths =
		dateRanges.endDate.diff(dateRanges.startDate, "days", true) > 31
	const dateArray = getDateArray(
		dateRanges.startDate,
		dateRanges.endDate,
		1,
		divideByMonths ? "months" : "days"
	)

	const getRandomColor = () => {
		const letters = "0123456789ABCDEF".split("")
		let color = "#"
		for (let i = 0; i < 6; i++) {
			color += letters[Math.floor(Math.random() * 16)]
		}
		return color
	}

	const renderMrrSection = () => (
		<div className={contentCard.root}>
			<div className={contentCard.header}>
				<h2 className={contentCard.title}>MRR</h2>
			</div>
			<div className={contentCard.content}>
				<div className={styles.row}>
					<div className={styles.col}>
						<div className={contentCard.header}>
							<h4 className={contentCard.heading}>Starting MRR</h4>
						</div>
						<div className={contentCard.content}>
							<h2>${data.startMrr.toFixed(2)}</h2>
						</div>
					</div>
					<div className={styles.col}>
						<div className={contentCard.header}>
							<h4 className={contentCard.heading}>Ending MRR</h4>
						</div>
						<div className={contentCard.content}>
							<h2>${data.endMrr.toFixed(2)}</h2>
						</div>
					</div>
					<div className={styles.col}>
						<div className={contentCard.header}>
							<h4 className={contentCard.heading}>ARR</h4>
						</div>
						<div className={contentCard.content}>
							<h2>${data.arr.toFixed(2)}</h2>
						</div>
					</div>
				</div>
			</div>
		</div>
	)

	const renderMrrChangesSection = () => {
		const mrrChangesLineChartData = {
			labels: dateArray.map((i) =>
				i.format(divideByMonths ? "MMM YYYY" : "DD ddd")
			),
			datasets: [
				{
					label: "New MRR",
					backgroundColor: "rgb(155, 224, 192)",
					borderColor: "rgb(155, 224, 192)",
					data: dateArray.map((i) =>
						(
							data.newMrrGrouped[
								i.format(divideByMonths ? "MM-YYYY" : "MM-DD-YYYY")
							] || 0
						).toFixed(2)
					),
				},
				{
					label: "Churned MRR",
					backgroundColor: "rgb(231, 190, 206)",
					borderColor: "rgb(231, 190, 206)",
					data: dateArray.map((i) =>
						(
							data.churnedMrrGrouped[
								i.format(divideByMonths ? "MM-YYYY" : "MM-DD-YYYY")
							] || 0
						).toFixed(2)
					),
				},
				{
					label: "Expansion MRR",
					backgroundColor: "rgb(199, 233, 238)",
					borderColor: "rgb(199, 233, 238)",
					data: dateArray.map((i) =>
						(
							data.expansionMrrGrouped[
								i.format(divideByMonths ? "MM-YYYY" : "MM-DD-YYYY")
							] || 0
						).toFixed(2)
					),
				},
			],
		}
		return (
			<div className={contentCard.root}>
				<div className={contentCard.header}>
					<h2 className={contentCard.title}>MRR Changes</h2>
				</div>
				<div className={contentCard.content}>
					<div className={styles.row}>
						<div className={styles.col}>
							<div className={contentCard.header}>
								<h4 className={contentCard.heading}>New MRR</h4>
							</div>
							<div className={contentCard.content}>
								<h2>${data.newMrr.toFixed(2)}</h2>
							</div>
						</div>
						<div className={styles.col}>
							<div className={contentCard.header}>
								<h4 className={contentCard.heading}>Churned MRR</h4>
							</div>
							<div className={contentCard.content}>
								<h2>${data.churnedMrr.toFixed(2)}</h2>
							</div>
						</div>
						<div className={styles.col}>
							<div className={contentCard.header}>
								<h4 className={contentCard.heading}>Expansion MRR</h4>
							</div>
							<div className={contentCard.content}>
								<h2>${data.expansionMrr.toFixed(2)}</h2>
							</div>
						</div>
					</div>
					<Line data={mrrChangesLineChartData} />
				</div>
			</div>
		)
	}

	const renderNetNewMrrSection = () => {
		const mrrLineChartData = {
			labels: dateArray.map((i) =>
				i.format(divideByMonths ? "MMM YYYY" : "DD ddd")
			),
			datasets: [
				{
					label: "Net New MRR",
					backgroundColor: "rgb(158, 252, 215)",
					borderColor: "rgb(158, 252, 215)",
					data: dateArray.map((i) =>
						(
							(data.newMrrGrouped[
								i.format(divideByMonths ? "MM-YYYY" : "MM-DD-YYYY")
							] || 0) +
							(data.expansionMrrGrouped[
								i.format(divideByMonths ? "MM-YYYY" : "MM-DD-YYYY")
							] || 0) -
							(data.churnedMrrGrouped[
								i.format(divideByMonths ? "MM-YYYY" : "MM-DD-YYYY")
							] || 0)
						).toFixed(2)
					),
				},
			],
		}
		return (
			<div className={contentCard.root}>
				<div className={contentCard.header}>
					<h2 className={contentCard.title}>Net New MRR</h2>
				</div>
				<div className={contentCard.content}>
					<Bar data={mrrLineChartData} />
				</div>
			</div>
		)
	}

	const renderTotalSubscribersSection = () => {
		const chartData = {
			labels: dateArray.map((i) =>
				i.format(divideByMonths ? "MMM YYYY" : "DD ddd")
			),
			datasets: [
				{
					label: "Total Active Subscribers",
					backgroundColor: "rgb(155, 224, 192)",
					borderColor: "rgb(155, 224, 192)",
					data: dateArray.map(
						(i) =>
							data.totalSubscriptionsGroupedValues[
								i.format(divideByMonths ? "MM-YYYY" : "MM-DD-YYYY")
							] || 0
					),
				},
				{
					label: "Oppertunities",
					backgroundColor: "rgb(231, 190, 206)",
					borderColor: "rgb(231, 190, 206)",
					data: dateArray.map(
						(i) =>
							data.totalOppertunitiesGroupedValues[
								i.format(divideByMonths ? "MM-YYYY" : "MM-DD-YYYY")
							] || 0
					),
				},
			],
		}
		return (
			<div className={contentCard.root}>
				<div className={contentCard.header}>
					<h2 className={contentCard.title}>Total Subscribers</h2>
				</div>
				<div className={contentCard.content}>
					<Line data={chartData} />
				</div>
			</div>
		)
	}

	const renderSubscribersSection = () => {
		const chartData = {
			labels: [
				`${dateRanges.startDate.format(
					"MM-DD-YYYY"
				)} - ${dateRanges.endDate.format("MM-DD-YYYY")}`,
			],
			datasets: [
				{
					label: "New Subscribers",
					backgroundColor: "rgb(155, 224, 192)",
					borderColor: "rgb(155, 224, 192)",
					data: [data.newSubscribers],
				},
				{
					label: "Chruned Subscribers",
					backgroundColor: "rgb(213, 136, 166)",
					borderColor: "rgb(213, 136, 166)",
					data: [data.churnedSubscribers],
				},
				{
					label: "Net New Subscribers",
					backgroundColor: "rgb(199, 233, 238)",
					borderColor: "rgb(199, 233, 238)",
					data: [data.newSubscribers - data.churnedSubscribers],
				},
				{
					label: "Trial Subscribers",
					backgroundColor: "rgb(95, 144, 226)",
					borderColor: "rgb(95, 144, 226)",
					data: [data.trialSubscribers],
				},
			],
		}
		return (
			<div className={contentCard.root}>
				<div className={contentCard.header}>
					<h2 className={contentCard.title}>Subscribers</h2>
				</div>
				<div className={contentCard.content}>
					<Bar data={chartData} />
				</div>
			</div>
		)
	}

	const renderNonCancelledSubSection = () => {
		const nonCancelledSubGroupedByDiscount = Object.values(
			data.nonCancelledSubGroupedByDiscount
		)
		const couponKeys = nonCancelledSubGroupedByDiscount
			.flatMap((plan) => Object.keys(plan.data))
			.filter((value, index, self) => self.indexOf(value) === index)
		const datasets = couponKeys.map((couponKey) => {
			const color = getRandomColor()
			return {
				label: nonCancelledSubGroupedByDiscount.find(
					(plan) => plan.data[couponKey]
				).data[couponKey].name,
				backgroundColor: color,
				borderColor: color,
				data: nonCancelledSubGroupedByDiscount.map((plan) =>
					plan.data[couponKey] ? plan.data[couponKey].count : 0
				),
			}
		})
		const chartData = {
			labels: nonCancelledSubGroupedByDiscount.map((i) => i.name),
			datasets,
		}
		return (
			<div className={contentCard.root}>
				<div className={contentCard.header}>
					<h2 className={contentCard.title}>
						Plan And Coupon Breakdown ({dayjs().format("MM-DD-YYYY")})
					</h2>
				</div>
				<div className={contentCard.content}>
					<Bar
						data={chartData}
						options={{
							scales: {
								x: {
									stacked: true,
								},
								y: {
									stacked: true,
								},
							},
						}}
					/>
				</div>
			</div>
		)
	}

	const getLiClassName = (li) =>
		li.value === range.value
			? `${dropdown.select} ${dropdown.selected}`
			: dropdown.select

	return (
		<div className={pageContent.rootMd}>
			<h1>Business Dashboard</h1>
			<div className={styles.controls}>
				<Dropdown className={dropdown.root}>
					<DropdownTrigger className={dropdown.triggerButton}>
						{range.label}
					</DropdownTrigger>
					<DropdownContent className={dropdown.contentSelect}>
						{rangeOptions.map((li) => (
							<a
								className={getLiClassName(li)}
								onClick={() => {
									setRange(li)
								}}
								onKeyPress={() => {
									setRange(li)
								}}
								tabIndex={-1}
								role="link"
								key={li.value}
							>
								{li.label}
							</a>
						))}
					</DropdownContent>
				</Dropdown>
			</div>
			{data && (
				<Fragment>
					{renderMrrSection()}
					{renderMrrChangesSection()}
					{renderNetNewMrrSection()}
					{renderTotalSubscribersSection()}
					{renderSubscribersSection()}
					{renderNonCancelledSubSection()}
				</Fragment>
			)}
		</div>
	)
}

export default BusinessDashboard
