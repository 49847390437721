import React, { useRef } from "react"
import { useDrag, useDrop } from "react-dnd"
import { DragHandleDots2Icon } from "@radix-ui/react-icons"
import toggle from "shared/styles/components/toogle.css"
import icons from "shared/styles/components/icons.css"
import ItemTypes from "./ItemTypes"
import styles from "./sort-list.css"

function Card({ id, text, index, moveCard, dropCard, vertical, updateShowControls, previewConfig }) {
	const ref = useRef(null)
	const [{ handlerId }, drop] = useDrop({
		accept: ItemTypes.CARD,
		collect(monitor) {
			return {
				handlerId: monitor.getHandlerId(),
			}
		},
		hover(item, monitor) {
			if (!ref.current) {
				return
			}
			const dragIndex = item.index
			const hoverIndex = index
			if (dragIndex === hoverIndex) {
				return
			}
			const hoverBoundingRect = ref.current.getBoundingClientRect()
			const clientOffset = monitor.getClientOffset()

			if (vertical) {
				const hoverMiddleY =
					(hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
				const hoverClientY = clientOffset.y - hoverBoundingRect.top
				if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
					return
				}
				if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
					return
				}
			} else {
				const hoverMiddleX =
					(hoverBoundingRect.right - hoverBoundingRect.left) / 2
				const hoverClientX = clientOffset.x - hoverBoundingRect.left
				if (dragIndex < hoverIndex && hoverClientX < hoverMiddleX) {
					return
				}
				if (dragIndex > hoverIndex && hoverClientX > hoverMiddleX) {
					return
				}
			}
			moveCard(dragIndex, hoverIndex)
			item.index = hoverIndex
		},
	})
	const [{ isDragging }, drag] = useDrag({
		item: { id, index },
		type: ItemTypes.CARD,
		collect: (monitor) => ({
			isDragging: monitor.isDragging(),
		}),
		end: () => {
			dropCard()
		},
	})
	const opacity = isDragging ? 0 : 1
	drag(drop(ref))
	const controlKey = `show${text}Section`
	return (
		<div
			ref={ref}
			className={styles.list}
			style={{ opacity }}
			data-handler-id={handlerId}
		>
			<DragHandleDots2Icon className={icons.icon18} />
			<div className={styles.listContent}>
				{text}
				<label className={toggle.check}>
					<div className={toggle.switchSm}>
						<input
							type="checkbox"
							checked={previewConfig[controlKey]}
							onChange={(e) =>
								updateShowControls(controlKey, e.target.checked)
							}
						/>
						<div className={toggle.sliderSm} />
					</div>
				</label>
			</div>
		</div>
	)
}

export default Card
