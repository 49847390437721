import React from "react"
import PlayIcon from "shared/assets/play-icon.svg"
import PauseIcon from "shared/assets/pause-icon.svg"
import styles from "./play-button.css"

export default function PlayButton({ isPlaying, handlePlayPause }) {
	return (
		<button type="button" className={styles.button} onClick={handlePlayPause}>
			{isPlaying ? (
				<PauseIcon className={styles.pause} />
			) : (
				<PlayIcon className={styles.play} />
			)}
		</button>
	)
}
