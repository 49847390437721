import React, { useCallback, useEffect, useState } from "react"
import axios from "axios"
import { baseUrl } from "../constants"
import form from "../shared/styles/components/form.css"
import chip from "../shared/styles/components/chips.css"

class MultiTabPageManager {
	constructor(tabs, setState, state, currentTab) {
		this.tabs = tabs
		this.setState = setState
		this.state = state
		this.currentTab = currentTab
	}

	handleChangedRout(currentPath, newPath) {
		if (currentPath !== newPath) {
			Object.keys(this.tabs).forEach((key) => {
				if (
					newPath.indexOf(this.tabs[key]) === 0 &&
					this.state[this.currentTab] !== key
				) {
					const obj = {}
					obj[this.currentTab] = key
					this.setState(obj)
				}
			})
		}
	}

	processState(currentPath) {
		Object.keys(this.tabs).forEach((key) => {
			if (currentPath.indexOf(this.tabs[key]) === 0) {
				const obj = {}
				obj[this.currentTab] = key
				this.setState(obj)
			}
		})
	}
}

export const validateForm = (formElement) => {
	const split = (className, callback) => {
		className.split(" ").forEach(callback)
	}

	const formLength = formElement.length
	for (let i = 0; i < formLength; i += 1) {
		const elem = formElement[i]
		const formGroup = elem.parentNode
		if (formGroup.getAttribute("data-novalidate") !== "true") {
			const feedback = formGroup.querySelector(`.${form.validationFeedback}`)
			if (formGroup && elem.nodeName.toLowerCase() !== "button") {
				if (!elem.validity.valid) {
					split(form.wasValidated, (c) => {
						formGroup.classList.add(c)
					})
					if (feedback) feedback.textContent = elem.validationMessage
				} else {
					split(form.wasValidated, (c) => {
						if (formGroup.classList.contains(c)) {
							formGroup.classList.remove(c)
						}
					})
					if (feedback) feedback.textContent = ""
				}
			}
		}
	}
	return formElement.checkValidity()
}

export const debounce = (func, wait) => {
	let timeout
	return function executedFunction(...args) {
		const later = () => {
			clearTimeout(timeout)
			func(...args)
		}
		clearTimeout(timeout)
		timeout = setTimeout(later, wait)
	}
}

export const usernameEmailAvailabilityCheck = async (value) => {
	const { data } = await axios.get(
		`${baseUrl}/api/users/usernames/${value}/isAvailable`
	)
	return !!data
}

export const WithContext = (Com, Consumer) => function WithContextInner(props) {
  return <Consumer>{(value) => <Com {...props} contextValue={value} />}</Consumer>
}

export const useOutsideAlerter = (ref, onClickOutside) => {
	useEffect(() => {
		function handleClickOutside(event) {
			if (ref && !ref.contains(event.target)) {
				onClickOutside()
			}
		}
		document.addEventListener("mousedown", handleClickOutside)
		return () => {
			document.removeEventListener("mousedown", handleClickOutside)
		}
	}, [ref])
}

export const popupWindow = (url, w, h) => {
	const y = window.top.outerHeight / 2 + window.top.screenY - h / 2
	const x = window.top.outerWidth / 2 + window.top.screenX - w / 2
	return window.open(
		url,
		"",
		`width=${w},height=${h},top=${y},left=${x},toolbar=0,location=0,menubar=0,scrollbars=1,resizable=1`
	)
}

export const isFirefox = () => navigator.userAgent.indexOf("Firefox") !== -1

export const useMediaQuery = (query) => {
	const [matches, setMatches] = React.useState(false)
	const handleMediaQueryListChange = useCallback((mediaQueryList) => {
		setMatches(mediaQueryList.matches)
	}, [])
	useEffect(() => {
		const mediaQueryList = window.matchMedia(query)
		mediaQueryList.addEventListener("change", handleMediaQueryListChange)
		setMatches(mediaQueryList.matches)
		return () => {
			mediaQueryList.removeEventListener(
				"change",
				handleMediaQueryListChange
			)
		}
	}, [query])
	return matches
}

export function MediaQuery(props) {
	const { query, children } = props
	const matches = useMediaQuery(query)
	return matches ? children : null
}

const getMatchValues = (queries) => {
	const newMatches = {}
	queries.forEach((key) => {
		const mediaQueryList = window.matchMedia(key)
		newMatches[key] = mediaQueryList.matches
	})
	return newMatches
}

export const useMultiMediaQuery = (queries) => {
	const [matches, setMatches] = React.useState(getMatchValues(queries))
	const handleMediaQueryListChange = useCallback((key, mediaQueryList) => {
		setMatches((prevMatches) => ({
			...prevMatches,
			[key]: mediaQueryList.matches,
		}))
	}, [])
	useEffect(() => {
		queries.forEach((key) => {
			const mediaQueryList = window.matchMedia(key)
			mediaQueryList.addEventListener(
				"change",
				handleMediaQueryListChange.bind(null, key)
			)
		})
		return () => {
			Object.keys(queries).forEach((key) => {
				const mediaQueryList = window.matchMedia(key)
				mediaQueryList.removeEventListener(
					"change",
					handleMediaQueryListChange.bind(null, key)
				)
			})
		}
	}, [])
	return matches
}

export const mobileScreenWidth = 920
export const mobileScreenHeight = 784

export function ShowCardControlsComponent({
	children,
	innerRef,
	...otherProps
}) {
	const [isShown, setIsShown] = useState(false)
	useEffect(() => {
		function setIcons() {
			setIsShown(
				window.screen.width < mobileScreenWidth ||
					window.screen.height < mobileScreenHeight
			)
		}
		setIcons()
		window.addEventListener("resize", setIcons)
		return () => {
			window.removeEventListener("resize", setIcons)
		}
	}, [window.screen.width, window.screen.height])
	return (
		<div
			onFocus={() => setIsShown(true)}
			onBlur={() => setIsShown(false)}
			onMouseOver={() => setIsShown(true)}
			onMouseLeave={() => setIsShown(false)}
			ref={innerRef}
			{...otherProps}
		>
			{children(isShown)}
		</div>
	)
}

export const getActiveTabClass = (active, defaultClass, activeClass) =>
	active ? activeClass : defaultClass

export const imageOrVideo = (url) => {
	if (/\.(jpg|jpeg|png|webp|avif|gif|svg)$/i.test(url)) return "image"
	if (/\.(mp4|mov|webm)$/i.test(url)) return "video"
	return null
}

export const mobileCheck = () =>
	/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
		navigator.userAgent
	)

export const getChipClass = (item) => {
	if (item.isACloset)
		return {
			chip: chip.chipCloset,
			dividerVert: chip.dividerVertDark,
		}
	return item.isPublished
		? {
				chip: chip.chipPublished,
				dividerVert: chip.dividerVert,
		  }
		: {
				chip: chip.chip,
				dividerVert: chip.dividerVert,
		  }
}

export default {
	MultiTabPageManager,
	validateForm,
	usernameEmailAvailabilityCheck,
	useOutsideAlerter,
	popupWindow,
}
