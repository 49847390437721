import React, { useState, useEffect } from "react"
import axios from "axios"
import { toast } from "react-toastify"
import { createFragmentContainer, graphql } from "react-relay"
import InfiniteScroll from "react-infinite-scroller"
import textfield from "shared/styles/components/textfield.css"
import form from "shared/styles/components/form.css"
import controls from "shared/styles/layout/controls.css"
import pageContent from "shared/styles/layout/page-content.css"
import empty from "shared/styles/components/empty.css"
import grids from "shared/styles/components/grids.css"
import LoadingDots from "components/loading-dots/loading-dots"
import DatepickerDropdown from "components/datepicker-dropdown/datepicker-dropdown"
import { apiUrl } from "constants"
import currencyData from "helpers/currencies.json"
import styles from "./sales-report.css"

function SalesReportComponent() {
	const [dates, setDates] = useState(null)

	const [sales, setSales] = useState([])
	const [totalDetails, setTotalDetails] = useState({
		totalSales: 0,
		totalCommission: 0,
		paidCommission: 0,
		noOfSales: 0,
		noOfRecords: 0,
		totalAllSales: 0,
		totalAllCommission: 0,
		noOfAllSales: 0,
		unpaidCommission90: 0,
		pendingCommisssion: 0,
	})
	const [isLoading, setIsLoading] = useState(false)
	const [selectedCurrency, setSelectedCurrency] = useState("")
	const [currencies, setCurrencies] = useState([])

	const cSymbol =
		currencyData[selectedCurrency] && currencyData[selectedCurrency].symbol

	useEffect(() => {
		if (isLoading) return
		if (!selectedCurrency) return
		if (!dates) return
		const { startDate: st, endDate: en } = dates
		setIsLoading(true)
		setSales([])
		setTotalDetails({
			totalSales: 0,
			totalCommission: 0,
			paidCommission: 0,
			noOfSales: 0,
			noOfRecords: 0,
			totalAllSales: 0,
			totalAllCommission: 0,
			noOfAllSales: 0,
			unpaidCommission90: 0,
			pendingCommisssion: 0,
		})
		axios
			.get(`${apiUrl}/reporting/sales?s=${st}&e=${en}&c=${selectedCurrency}`)
			.then((res) => {
				setSales(res.data.records)
				setTotalDetails({
					totalSales: res.data.totalSales,
					totalCommission: res.data.totalCommission,
					paidCommission: res.data.paidCommission,
					noOfSales: res.data.noOfSales,
					noOfRecords: res.data.noOfRecords,
					totalAllSales: res.data.totalAllSales,
					totalAllCommission: res.data.totalAllCommission,
					noOfAllSales: res.data.noOfAllSales,
					unpaidCommission90: res.data.unpaidCommission90,
					pendingCommisssion: res.data.pendingCommisssion,
				})
				setIsLoading(false)
			})
			.catch(() => {
				setIsLoading(false)
				toast.error(<>Could not get data. please try again later</>, {
					autoClose: 2000,
					closeButton: false,
				})
			})
	}, [dates, selectedCurrency])

	const loadMore = () => {
		if (!dates) return null
		const { startDate: st, endDate: en } = dates
		return axios
			.get(
				`${apiUrl}/reporting/sales?s=${st}&e=${en}&c=${selectedCurrency}&skip=${sales.length}`
			)
			.then((res) => {
				setSales([...sales, ...res.data.records])
				setIsLoading(false)
			})
			.catch(() => {
				setIsLoading(false)
				toast.error(<>Could not get data. please try again later</>, {
					autoClose: 2000,
					closeButton: false,
				})
			})
	}

	useEffect(() => {
		setIsLoading(true)
		axios.get(`${apiUrl}/reporting/currency`).then((res) => {
			setIsLoading(false)
			setCurrencies(res.data)
			if (res.data.length > 0) setSelectedCurrency(res.data[0])
		})
	}, [])

	function renderEmpty() {
		return (
			<div className={empty.simple}>
				No matches found. Let&apos;s keep digging.
			</div>
		)
	}

	const getFormattedCurrency = (amount) => {
		const formattedAmount = amount
			.toFixed(2)
			.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
		return cSymbol
			? `${cSymbol}${formattedAmount}`
			: `${formattedAmount} ${selectedCurrency}`
	}

	function renderSalesItems() {
		const items = sales.map((s) => (
			<div
				className={styles.tr}
				key={`${s.memberId}_${s.transactionDate}_${s.commission}`}
			>
				<div className={styles.td}>{s.merchant}</div>
				<div className={styles.td}>{s.clickCount}</div>
				<div className={styles.td}>{getFormattedCurrency(s.sales)}</div>
				<div className={styles.td}>
					{getFormattedCurrency(s.commission)}
				</div>
			</div>
		))
		return (
			<InfiniteScroll
				loadMore={() => loadMore()}
				hasMore={totalDetails.noOfRecords > sales.length}
				loader={
					<div className={grids.loadMore}>
						<LoadingDots />
					</div>
				}
				pageStart={0}
			>
				<div className={styles.table}>
					<div className={styles.thead}>
						<div className={styles.tr}>
							<div className={styles.th}>Advertiser</div>
							<div className={styles.th}>Sales</div>
							<div className={styles.th}>Total Sales</div>
							<div className={styles.th}>Total Commission</div>
						</div>
					</div>
					<div className={styles.tbody}>{items}</div>
					<div className={styles.tfoot}>
						<div className={styles.tr}>
							<div className={styles.td}>Totals</div>
							<div className={styles.td}>{totalDetails.noOfSales}</div>
							<div className={styles.td}>
								{getFormattedCurrency(totalDetails.totalSales)}
							</div>
							<div className={styles.td}>
								{getFormattedCurrency(totalDetails.totalCommission)}
							</div>
						</div>
					</div>
				</div>
			</InfiniteScroll>
		)
	}

	return (
		<div className={pageContent.root}>
			{currencies.length > 0 && (
				<div className={styles.controls}>
					<div className={controls.filters}>
						<div className={controls.group}>
							<select
								className={textfield.cozy}
								onChange={(e) => {
									setSelectedCurrency(e.target.value)
								}}
								value={selectedCurrency}
							>
								<optgroup label="Currency">
									{currencies.map((i) => (
										<option value={i} key={i}>
											{i}
										</option>
									))}
								</optgroup>
							</select>
						</div>
					</div>
				</div>
			)}
			<div className={styles.slat}>
				<div className={styles.stats}>
					<div className={styles.col}>
						<h5 className={styles.label}>Total Sales</h5>
						{isLoading && (
							<h2 className={styles.figure}>
								<LoadingDots color="var(--gray300)" />
							</h2>
						)}
						{!isLoading && (
							<h2 className={styles.figure}>
								{totalDetails.noOfAllSales}
							</h2>
						)}
					</div>
					<div className={styles.col}>
						<h5 className={styles.label}>Total Value</h5>
						{isLoading && (
							<h2 className={styles.figure}>
								<LoadingDots color="var(--gray300)" />
							</h2>
						)}
						{!isLoading && (
							<h2 className={styles.figure}>
								{getFormattedCurrency(totalDetails.totalAllSales)}
							</h2>
						)}
					</div>
					<div className={styles.col}>
						<h5 className={styles.label}>Total Commission</h5>
						{isLoading && (
							<h2 className={styles.figure}>
								<LoadingDots color="var(--gray300)" />
							</h2>
						)}
						{!isLoading && (
							<h2 className={styles.figure}>
								{getFormattedCurrency(totalDetails.totalAllCommission)}
							</h2>
						)}
					</div>
					<div className={styles.colPaid}>
						<h5 className={styles.label}>Paid Commission</h5>
						{isLoading && (
							<h2 className={styles.figure}>
								<LoadingDots color="var(--gray300)" />
							</h2>
						)}
						{!isLoading && (
							<h2 className={styles.figure}>
								{getFormattedCurrency(totalDetails.paidCommission)}
							</h2>
						)}
					</div>
					<div className={styles.colPending}>
						<h5 className={styles.label}>Pending Commission</h5>
						{isLoading && (
							<h2 className={styles.figure}>
								<LoadingDots color="var(--gray300)" />
							</h2>
						)}
						{!isLoading && (
							totalDetails.pendingCommisssion < 0 ? (
								<h2
									data-tooltip-id="tooltip"
									data-tooltip-place="bottom"
									data-tooltip-content="A negative pending balance is due to customer returns."
									className={styles.figureTooltip}
								>
									{getFormattedCurrency(
										totalDetails.pendingCommisssion
									)}
								</h2>
							) : (
								<h2 className={styles.figure}>
									{getFormattedCurrency(
										totalDetails.pendingCommisssion
									)}
								</h2>
							)
						)}
					</div>
					<div className={styles.colUnpaid}>
						<h5 className={styles.label}>Available Balance</h5>
						{isLoading && (
							<h2 className={styles.figure}>
								<LoadingDots color="var(--gray500)" />
							</h2>
						)}
						{!isLoading && (
							<h2 className={styles.figure}>
								{getFormattedCurrency(totalDetails.unpaidCommission90)}
							</h2>
						)}
					</div>
				</div>
			</div>

			<div className={styles.controls}>
				<div className={controls.filters}>
					<div className={controls.group}>
						<label className={form.label}>Period</label>
						<DatepickerDropdown
							localStorageKey="sales-report"
							onChange={(sd, ed) => {
								setDates({ startDate: sd, endDate: ed })
							}}
						/>
					</div>
				</div>
			</div>

			{isLoading && <LoadingDots color="var(--gray300)" />}
			{!isLoading && !!sales && !!sales.length && renderSalesItems()}
			{!isLoading && !!sales && !sales.length && renderEmpty()}
		</div>
	)
}

const SalesReport = createFragmentContainer(SalesReportComponent, {
	store: graphql`
		fragment salesReport_store on Store {
			id
		}
	`,
})

const query = graphql`
	query salesReportQuery {
		store {
			...salesReport_store
		}
	}
`

export default { Component: SalesReport, query }
