import React from "react"
import {
	ArrowLeftIcon,
	ArrowRightIcon,
	ReloadIcon,
} from "@radix-ui/react-icons"
import styles from "./browser-mockup.css"

export default function BrowserMockup({ url, children, onBack, onForward, onReload }) {
  return <div className={styles.browser}>
		<div className={styles.searchbar}>
			<div className={styles.dots}>
				<div className={styles.dotRed} />
				<div className={styles.dotYellow} />
				<div className={styles.dotGreen} />
			</div>
			{url && (
				<div className={styles.address} aria-hidden>
					{url}
				</div>
			)}
			<div className={styles.actions}>
				{onReload && (
					<button onClick={onReload} type="button" className={styles.btn}>
						<ReloadIcon className={styles.icon} />
					</button>
				)}
				{onBack && (
					<button onClick={onBack} type="button" className={styles.btn}>
						<ArrowLeftIcon className={styles.icon} />
					</button>
				)}
				{onForward && (
					<button onClick={onForward} type="button" className={styles.btn}>
						<ArrowRightIcon className={styles.icon} />
					</button>
				)}
			</div>
		</div>
		{children}
	</div>
}
