import React, { useState, useCallback } from "react"
import { DndProvider } from "react-dnd"
import { HTML5Backend } from "react-dnd-html5-backend"
import form from "shared/styles/components/form.css"
import toggle from "shared/styles/components/toogle.css"
import Card from "./card.jsx"
import styles from "./sort-list.css"

export function Container({ textArray, dropCard, vertical, updateShowControls, previewConfig }) {
	const [cards, setCards] = useState(
		textArray.map((i, index) => ({
			id: index,
			text: i,
		}))
	)
	const moveCard = useCallback(
		(dragIndex, hoverIndex) => {
			const dragCard = cards[dragIndex]
			const newCards = [...cards]
			newCards.splice(dragIndex, 1)
			newCards.splice(hoverIndex, 0, dragCard)
			setCards(newCards)
		},
		[cards]
	)
	const renderCard = (card, index) => (
		<Card
			key={card.id}
			index={index}
			id={card.id}
			text={card.text}
			moveCard={moveCard}
			dropCard={() => {
				dropCard(cards.map((i) => i.text))
			}}
			vertical={vertical}
			updateShowControls={updateShowControls}
			previewConfig={previewConfig}
		/>
	)
	if (vertical)
		return (
			<div className={styles.nav}>
				{cards.map((card, i) => renderCard(card, i))}
			</div>
		)
	return (
		<div className={styles.nav}>
			<nav className={styles.tabs}>
				<div className={styles.tabsFit}>
					{cards.map((card, i) => renderCard(card, i))}
				</div>
			</nav>
		</div>
	)
}

export default function SortList(props) {
  return <DndProvider backend={HTML5Backend}>
		<div className={styles.root}>
			<div className={form.section}>
				<div className={form.header}>
					<label className={toggle.check}>
						<div className={toggle.labelSm}>Show Sections</div>
					</label>
				</div>
				<div className={form.content}>
					<Container {...props} />
					<p className={styles.help}>Drag to reorder sections</p>
				</div>
			</div>
		</div>
	</DndProvider>
}
