import { commitMutation, graphql } from "react-relay"
import { ConnectionHandler } from "relay-runtime"
import _ from "lodash"
import { complete } from "../helper"
import connections from "../connections"

const mutation = graphql`
	mutation moveShopcastItemsMutation($input: MoveShopcastItemsInput!) {
		moveShopcastItems(input: $input) {
			skipped
		}
	}
`

const commit = (
	env,
	items,
	folder,
	teamId,
	parentFolder,
	containerId,
	onError,
	perItem
) =>
	commitMutation(env, {
		mutation,
		variables: { input: { items: items.map((i) => i.id), folder, teamId } },
		onError: complete(() => {}, onError),
		updater: (storeProxy, dd) => {
			if (dd.moveShopcastItems) {
				const { skipped } = dd.moveShopcastItems
				const shopcasts = items.filter(
					(i) => i.type === "shopcast" && skipped.indexOf(i.id) < 0
				)
				const folders = items.filter(
					(i) => i.type === "folder" && skipped.indexOf(i.id) < 0
				)
				const lookbook = items.filter(
					(i) => i.type === "lookbook" && skipped.indexOf(i.id) < 0
				)
				const shopshareFiles = items.filter(
					(i) => i.type === "shopshareFile" && skipped.indexOf(i.id) < 0
				)
				const collections = items.filter(
					(i) => i.type === "collection" && skipped.indexOf(i.id) < 0
				)
				const parentFolderProxy = storeProxy.get(parentFolder)

				if (folder !== parentFolder) {
					const removeItems = (connection, its) => {
						const { name, data } = connection
						const con = ConnectionHandler.getConnection(
							parentFolderProxy,
							name,
							data
						)
						its.forEach((item) => {
							if (perItem) {
								perItem(item)
							}
							ConnectionHandler.deleteNode(con, item.id)
						})
					}
					let conn = connections.currentUser.shopcastManager.items
					if (shopcasts.length) {
						parentFolderProxy.setValue(parentFolderProxy.getValue("shopcastsCount") - shopcasts.length, "shopcastsCount")
						removeItems(
							{name: conn.name, data: { type: "shopcast" }},
							shopcasts
						)
					}
					if (shopshareFiles.length) {
						parentFolderProxy.setValue(parentFolderProxy.getValue("filesCount") - shopshareFiles.length, "filesCount")
						removeItems(
							{name: conn.name, data: { type: "file" }},
							shopshareFiles
						)
					}
					if (collections.length) {
						parentFolderProxy.setValue(parentFolderProxy.getValue("collectionCount") - collections.length, "collectionCount")
						removeItems(
							{name: conn.name, data: { type: "collection" }},
							collections
						)
					}
					if (lookbook.length) {
						parentFolderProxy.setValue(parentFolderProxy.getValue("lookbooksCount") - lookbook.length, "lookbooksCount")
						removeItems(
							{name: conn.name, data: { type: "lookbook" }},
							lookbook
						)
					}
					if (folders.length) {
						conn = connections.currentUser.shopcastManager.folders
						const grouped = _.groupBy(folders, (x) =>
							(!!storeProxy.get(x.id).getValue("sharableLink")).toString()
						)
						parentFolderProxy.setValue(
							parentFolderProxy.getValue("sharedFoldersCount") -
								(grouped.true?.length || 0),
							"sharedFoldersCount"
						)
						parentFolderProxy.setValue(
							parentFolderProxy.getValue("foldersCount") -
								(grouped.false?.length || 0),
							"foldersCount"
						)
						removeItems(
							{
								name: conn.name,
								data: {
									...conn.data,
									isShared: null,
								},
							},
							grouped.true || []
						)
						removeItems(
							{
								name: conn.name,
								data: {
									...conn.data,
									isShared: null,
								},
							},
							grouped.false || []
						)
					}

					/* change count of the folder if it exists in the current view */
					const folderProxy = storeProxy.get(folder)
					if (folderProxy) {
						folderProxy.setValue(
							folderProxy.getValue("count") +
								items.length -
								skipped.length,
							"count"
						)
					}
				}
			}
		},
	})

export default { commit }
