import { withinShopshareDomain } from "./services/custom-domain-service"

const subdomainIdentifierRegex = /(.*)(\.lvh\.me:4000|\.shopshare\.tv)/
const subdomainValidationRegex = /^([a-z0-9]*)$/

const showFooterPaths = [
	{
		regex: /^\/$/,
		type: withinShopshareDomain(window.location.host) ? "channel" : "website",
	},
	{ regex: /^\/about$/, type: "website" },
	{ regex: /^\/pricing$/, type: "website" },
	{ regex: /^\/faqs$/, type: "website" },
	{ regex: /^\/features$/, type: "website" },
	{ regex: /^\/explore$/, type: "website" },
	{ regex: /^\/home$/, type: "website" },
	{ regex: /^\/screen-recorder$/, type: "website" },
	{ regex: /^\/shoppable-videos$/, type: "website" },
	{ regex: /^\/moodboards$/, type: "website" },
	{ regex: /^\/shared\/[0-9a-zA-Z=/_]*$/, type: "channel" },
	{ regex: /^\/for-virtual-styling$/, type: "website" },
	{ regex: /^\/for-network-marketers$/, type: "website" },
	{ regex: /^\/for-shopify$/, type: "website" },
	{ regex: /^\/for-content-marketing$/, type: "website" },
	{ regex: /^\/explore\/[0-9a-zA-Z=]*$/, type: "website" },
	{ regex: /^\/directory$/, type: "website" },
	{ regex: /^\/stylist\/[0-9a-zA-Z=/_]*$/, type: "channel" },
	{ regex: /^\/client-preview\/[0-9a-zA-Z=/_]*$/, type: "channel" },
	{ regex: /^\/wardrobe$/, type: "channel" },
]

const hideHeaderPaths = [
	/^\/shopboard\/create/,
	/^\/shopboard\/[0-9a-zA-Z=/]*$/,
	/^\/shopcast\/[0-9a-zA-Z=/]*$/,
	/^\/shopcast-embed\/[0-9a-zA-Z=]*$/,
	/^\/shared\/[0-9a-zA-Z=/_]*$/,
	/^\/client-preview\/[0-9a-zA-Z=/_]*$/,
	/^\/edit-preview\/[0-9a-zA-Z=/_]*$/,
	/^\/edit-preview/,
]

const baseUrl =
	process.env.NODE_ENV === "production"
		? "https://shopshare.tv"
		: `https://${window.location.hostname}:5000`

const getBaseUrl = (dynamicLocalHost) => {
	if (process.env.NODE_ENV === "production") return "https://shopshare.tv"
	return dynamicLocalHost
		? `https://${window.location.hostname}:5000`
		: `https://localhost:5000`
}

const publicViewDomain =
	process.env.NODE_ENV === "production" ? "shopshare.tv" : `lvh.me:4000`

const apiUrl = `${baseUrl}/api`

const baseFrontendUrl =
	process.env.NODE_ENV === "production" ? baseUrl : "https://localhost:4000"
const graphqlUrl =
	process.env.NODE_ENV === "production" ? "/graphql" : `${baseUrl}/graphql`

const textStrings = {
	siteName: "SHOPSHARE",
	clientWardrobeName: "Lookbook",
	contentRouteName: "Workspace",
}

const vigLinkTransactionsBaseUrl = "https://viglink.io/transactions"

const googleClientId =
	process.env.NODE_ENV === "production"
		? "490701999993-gatuspui5nbjnb18t9qi3s7783gqrtkq.apps.googleusercontent.com"
		: "679779127442-fpe8roah8c18pd42tuj7sssv96bqhdte.apps.googleusercontent.com"

const stripePublicKey =
	process.env.NODE_ENV === "production"
		? "pk_live_51H0O5ABPybOUA6zyBmvXffgcRqE3wW2H8FR3FLJnOQstwRldq2NlAnIzjh3GyS2cSJSwPQ58XbqhoCozpvcuMDSX00meja7AVj"
		: "pk_test_51H0O5ABPybOUA6zy39CwQPWqEKANrxyVnYjTHjqx8Ur6KLTZ6ahp4gbQw0OnaGmpm3RgVw83pqdwJISMC4oLIF6o001Kj27Scq"

const bugsnagApiKey = "725449ba434fee02275cfc5ebf6c25fb"
const recaptchaSiteKey = "6LdAajEbAAAAAKjDQLZ1YakUFUpGRAXQ0VrvzrpD"

const trialPeriod = 14

const ROLES = {
	ADMIN: "admin",
	TEAM: "team",
	BETA_TESTER: "beta_tester",
	USER: "user",
}

const socialMedia = {
	pinterest: {
		key: "pinterest",
		name: "Pinterest",
		title: "Create Pin",
	},
	youtube: {
		key: "youtube",
		name: "YouTube",
		title: "Upload to YouTube",
		terms: {
			link: "https://www.youtube.com/t/terms",
			text: "YouTube Terms of Service",
		}
	},
	facebook: {
		key: "facebook",
		name: "Facebook",
		title: "Create Post",
	},
	instagram: {
		key: "instagram",
		name: "Instagram",
		title: "Create Post",
	},
}

export {
	showFooterPaths,
	hideHeaderPaths,
	graphqlUrl,
	baseUrl,
	getBaseUrl,
	baseFrontendUrl,
	vigLinkTransactionsBaseUrl,
	apiUrl,
	textStrings,
	googleClientId,
	stripePublicKey,
	bugsnagApiKey,
	subdomainIdentifierRegex,
	subdomainValidationRegex,
	publicViewDomain,
	trialPeriod,
	recaptchaSiteKey,
	ROLES,
	socialMedia,
}
