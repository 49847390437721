import React, { Component } from "react"
// import { Link } from "react-router-dom"
import contentCard from "shared/styles/layout/content-card.css"
import pricing from "routes/pricing/pricing.css"
import ListOfPlans from "components/list-of-plans/list-of-plans"
import ManageLink from "components/manage-link/manage-link"
// import TrialMeter from "components/trial-meter/trial-meter"
import styles from "./subscription.css"

class Subscription extends Component {
	constructor(props) {
		super(props)
		this.state = {
			yearly: false,
		}
	}

	render() {
		const { plans, currentUser, relay } = this.props
		const { yearly } = this.state
		const { userPlan: currentPlan } = currentUser
		return currentPlan ? (
			<div className={contentCard.root}>
				<div className={contentCard.header}>
					<h4 className={contentCard.title}>Billing</h4>
				</div>
				<div className={contentCard.content}>
					<ManageLink relay={relay} />
				</div>
			</div>
		) : (
			<div className={contentCard.root}>
				<div className={contentCard.header}>
					<h4 className={contentCard.title}>Plan</h4>
				</div>
				<div className={styles.plans}>
					<div className={styles.planCard}>
						{(!currentPlan || currentPlan.status === "trialing") && (
							<div className={styles.frequency}>
								<div className={pricing.upgradeToggle}>
									<label className={pricing.label}>
										<input
											className={pricing.input}
											type="checkbox"
											checked={yearly}
											onChange={(e) => {
												this.setState({
													yearly: e.target.checked,
												})
											}}
										/>
										<span
											className={pricing.slider}
											data-billed-monthly="Billed monthly"
											data-billed-yearly="Billed yearly"
										>
											<span>Billed monthly</span>
											<span>Billed yearly</span>
										</span>
									</label>
								</div>
								<p className={pricing.offer}>
									<span className={pricing.savings}>Save 20%</span>
								</p>
							</div>
						)}
						<ListOfPlans
							plans={plans}
							yearly={yearly}
							currentUser={currentUser}
							relay={relay}
						/>
					</div>
				</div>
			</div>
		)
	}
}

export default Subscription
