import React, { useMemo, useState } from "react"

export const GlobalContext = React.createContext()
export function GlobalContextProvider({ children }) {
	const [team, setTeam] = useState(localStorage.getItem("team") || null)
	const globalObj = useMemo(
		() => ({
			team,
			setTeam: (id) => {
				setTeam(id)
				if (id) localStorage.setItem("team", id)
				else localStorage.removeItem("team")
			},
		}),
		[team, setTeam]
	)
	return (
		<GlobalContext.Provider value={globalObj}>
			{children({ team, setTeam })}
		</GlobalContext.Provider>
	)
}
export const GlobalContextConsumer = GlobalContext.Consumer
