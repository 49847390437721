import React, { Component } from "react"
import InfiniteScroll from "react-infinite-scroller"
import grids from "shared/styles/components/grids.css"
import empty from "shared/styles/components/empty.css"
import LoadingDots from "components/loading-dots/loading-dots.jsx"
import ProductCard from "components/cards/product/product-card.jsx"

export default class ListOfProducts extends Component {
	constructor(props) {
		super(props)
		this.handleLoadMore = this.handleLoadMore.bind(this)
	}

	handleLoadMore() {
		const { relay } = this.props
		relay.loadMore(12, () => {})
	}

	render() {
		const { props } = this
		const { products, enableHeader, relay } = props
		if (!products.edges.length) {
			return (
				<div className={grids.span}>
					<div className={empty.simple}>No products found.</div>
				</div>
			)
		}
		return (
			<div className={grids.container}>
				<InfiniteScroll
					loadMore={this.handleLoadMore}
					hasMore={relay.hasMore()}
					loader={
						<div className={grids.loadMore} key="loading">
							<LoadingDots />
						</div>
					}
					pageStart={0}
				>
					<div className={grids.rowMax4}>
						{products.edges.map((edge) => (
							<div className={grids.col4} key={edge.node.id}>
								<ProductCard
									product={edge.node}
									environment={props.relay.environment}
									enableHeader={enableHeader}
								/>
							</div>
						))}
					</div>
				</InfiniteScroll>
			</div>
		)
	}
}
