/**
 * @generated SignedSource<<ff8589d1796dc8b62cdb8100c98c2f9b>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Mutation } from 'relay-runtime';
export type GetInTouchInput = {|
  category: string,
  clientMutationId?: ?string,
  email: string,
  fullName: string,
  message: string,
  to: string,
|};
export type getInTouchMutation$variables = {|
  input: GetInTouchInput,
|};
export type getInTouchMutation$data = {|
  +getInTouch: ?{|
    +store: ?{|
      +id: string,
    |},
  |},
|};
export type getInTouchMutation = {|
  response: getInTouchMutation$data,
  variables: getInTouchMutation$variables,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "GetInTouchPayload",
    "kind": "LinkedField",
    "name": "getInTouch",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Store",
        "kind": "LinkedField",
        "name": "store",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "getInTouchMutation",
    "selections": (v1/*: any*/),
    "type": "ShopshareMutationRoot",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "getInTouchMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "1057149a383c4c1186e0b63fe2654e91",
    "id": null,
    "metadata": {},
    "name": "getInTouchMutation",
    "operationKind": "mutation",
    "text": "mutation getInTouchMutation(\n  $input: GetInTouchInput!\n) {\n  getInTouch(input: $input) {\n    store {\n      id\n    }\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "edf90949ba782979a7d8871766c7e900";

module.exports = ((node/*: any*/)/*: Mutation<
  getInTouchMutation$variables,
  getInTouchMutation$data,
>*/);
