import React, { Component, Fragment } from "react"
import { createFragmentContainer, graphql } from "react-relay"
import { ChevronDownIcon, Cross2Icon } from "@radix-ui/react-icons"
import { Link } from "react-router-dom"
import Logo from "shared/assets/logo.svg"
import SearchInputComponent from "components/search-input/search-input"
import { getActiveTabClass } from "helpers/ui-helper"
import icons from "shared/styles/components/icons.css"
import HeaderLoggedOut from "./header-logged-out"
import HeaderClient from "./header-client"
import HeaderStylist from "./header-stylist"
import styles from "./header.css"
import { withRouter } from "../../route-helpers"
import { GlobalContextConsumer } from "../../global-context"

export class Header extends Component {
	constructor(props) {
		super(props)
		this.state = {
			mobile: false,
			showSearchInput: false,
		}

		this.mobileNavIsOpenClass = "mobileNavIsOpen"
		this.toggleSearchInput = this.toggleSearchInput.bind(this)
		this.toggleState = this.toggleState.bind(this)
		this.closeMenu = this.closeMenu.bind(this)
	}

	getLogoLink() {
		const { store } = this.props
		const user = store.currentUser
		if (!user) return "/"
		if (user.type === "CLIENT") return "/wardrobe"
		return "/dashboard"
	}

	toggleState() {
		try {
			const { state } = this
			const mobile = !state.mobile
			this.setState({ mobile })
			if (mobile) {
				document.body.classList.add(this.mobileNavIsOpenClass)
			} else {
				document.body.classList.remove(this.mobileNavIsOpenClass)
			}
		} catch (e) {
			/* Ignored */
		}
	}

	closeMenu() {
		try {
			const { state } = this
			if (state.mobile) {
				this.setState({ mobile: false })
				document.body.classList.remove(this.mobileNavIsOpenClass)
			}
		} catch (e) {
			/* Ignored */
		}
	}

	triggerSearch(event) {
		const { showSearchInput } = this.state
		const { navigate } = this.props

		if (
			event.key === "Enter" &&
			this.searchInput &&
			this.searchInput.value.trim() !== ""
		) {
			if (showSearchInput) {
				this.toggleSearchInput()
			}
			const keyword = this.searchInput.value
			this.searchInput.value = ""
			navigate(`/search?q=${encodeURIComponent(keyword)}`)
		}
	}

	toggleSearchInput() {
		const { state } = this
		const showSearchInput = !state.showSearchInput
		this.setState({ showSearchInput })
		setTimeout(() => {
			if (this.searchInput) this.searchInput.focus()
		}, 5)
	}

	renderSearch() {
		return (
			<div className={styles.navSearch}>
				<SearchInputComponent
					placeholder="Search for a stylist or product"
					innerRef={(ref) => {
						this.searchInput = ref
					}}
					innerOnKeyUp={(e) => this.triggerSearch(e)}
					setSearchText={() => {}}
				/>
			</div>
		)
	}

	renderMobileSearch(state) {
		return (
			<div className={styles.mobileSearch}>
				<div className={styles.mobileSearchBackdrop} />
				<div className={styles.mobileSearchContent}>
					{this.renderSearch(state)}
					<button
						type="button"
						className={styles.cancel}
						onClick={this.toggleSearchInput}
					>
						<Cross2Icon className={icons.icon24} />
					</button>
				</div>
			</div>
		)
	}

	renderExploreTabs() {
		const { location, store } = this.props
		const isAdmin = store.currentUser?.adminAccess === "HAS"
		return (
			<li className={styles.navItemDropdown}>
				<div
					className={getActiveTabClass(
						location.pathname.includes("/explore"),
						styles.linkDropdown,
						styles.linkActive
					)}
				>
					Explore
					<ChevronDownIcon className={styles.navItemChevron} />
				</div>
				<div className={styles.navItemMenu} aria-label="submenu">
					<div className={styles.ddMenu}>
						<Link
							className={styles.ddLink}
							onClick={this.closeMenu}
							to="/explore/shopcast"
						>
							Shopcasts
						</Link>
						<Link
							className={styles.ddLink}
							onClick={this.closeMenu}
							to="/explore/shopboard"
						>
							Shopboards
						</Link>
						{isAdmin && (
							<Link
								className={styles.ddLink}
								onClick={this.closeMenu}
								to="/explore/directory"
							>
								Stylists
							</Link>
						)}
					</div>
				</div>
			</li>
		)
	}

	renderExpolreTabsMobile() {
		return (
			<>
				<div className={styles.menuItem}>
					<Link
						className={styles.menuLink}
						onClick={this.closeMenu}
						to="/explore/shopcast"
					>
						Shopcasts
					</Link>
				</div>
				<div className={styles.menuItem}>
					<Link
						className={styles.menuLink}
						onClick={this.closeMenu}
						to="/explore/shopboard"
					>
						Shopboards
					</Link>
				</div>
				<div className={styles.menuItem}>
					<Link
						className={styles.menuLink}
						onClick={this.closeMenu}
						to="/explore/directory"
					>
						Stylists
					</Link>
				</div>
				<div className={styles.menuItem}>
					<Link
						className={styles.menuLink}
						onClick={this.closeMenu}
						to="/partners"
					>
						Retail Partners
					</Link>
				</div>
			</>
		)
	}

	renderUserLinks(isMobile) {
		const { store, relay, location } = this.props
		const user = store.currentUser
		if (!user)
			return (
				<HeaderLoggedOut
					isMobile={isMobile}
					exploreTabs={this.renderExploreTabs()}
				/>
			)
		if (user.type === "CLIENT")
			return (
				<HeaderClient
					isMobile={isMobile}
					store={store}
					location={location}
					relay={relay}
				/>
			)
		return (
			<GlobalContextConsumer>
				{(context) => (
					<HeaderStylist
						isMobile={isMobile}
						exploreTabs={this.renderExploreTabs()}
						expolreTabsMobile={this.renderExpolreTabsMobile()}
						toggleSearchInput={this.toggleSearchInput}
						store={store}
						closeMenu={this.closeMenu}
						location={location}
						relay={relay}
						{...context}
					/>
				)}
			</GlobalContextConsumer>
		)
	}

	renderHeaderElement() {
		const { state } = this
		const { store } = this.props
		const user = store.currentUser
		if (user && user.type === "CLIENT")
			return (
				<header className={styles.root}>
					<nav className={state.mobile ? styles.mobile : styles.desktop}>
						<div className={styles.container}>
							<Link to={this.getLogoLink()}>
								<Logo className={styles.logo} />
							</Link>
							{this.renderUserLinks(false)}
						</div>
					</nav>
				</header>
			)
		return (
			<header className={styles.root}>
				{state.showSearchInput && this.renderMobileSearch(state)}
				<nav className={state.mobile ? styles.mobile : styles.desktop}>
					<div className={styles.container}>
						<Link to={this.getLogoLink()}>
							<Logo className={styles.logo} />
						</Link>
						{this.renderUserLinks(false)}
						<div className={styles.navHumburger}>
							<a
								className={styles.menu}
								onClick={this.toggleState}
								onKeyPress={this.toggleState}
								role="link"
								tabIndex={0}
							>
								Menu
							</a>
						</div>
						<div className={styles.mobileContainer}>
							<div className={styles.mobilePanel}>
								{this.renderUserLinks(true)}
							</div>
						</div>
					</div>
				</nav>
			</header>
		)
	}

	render() {
		const { props } = this
		return props.showHeader ? this.renderHeaderElement() : null
	}
}

Header = createFragmentContainer(Header, {
	store: graphql`
		fragment header_store on Store {
			id
			...userMenu_store
			currentUser {
				id
				fullname
				email
				username
				customDomain
				profilePhoto
				adminAccess
				isRetailer
				type
				optOutAffiliate
				hasPendingSharerRequests
				isANewUser
			}
		}
	`,
})

export default withRouter(Header)
