import React, { Component, Fragment } from "react"
import { toast } from "react-toastify"
import queryString from "query-string"
import buttons from "shared/styles/components/buttons.css"
import PriceCard from "components/cards/price/price-card"
import ConformationPrompt from "components/modals/conformation-prompt/conformation-prompt"
import SubToPricingPlanMutation from "mutations/user/sub-to-pricing-plan"
import OpenCustomerPortalMutation from "mutations/user/open-customer-portal"
import { apiUrl } from "../../constants"
import { withRouter } from "../../route-helpers"

class ListOfPlans extends Component {
	constructor(props) {
		super(props)
		this.state = {
			showPlanModalId: null,
		}
		this.selectSubPlan = this.selectSubPlan.bind(this)
		this.approvePlan = this.approvePlan.bind(this)
		this.handleMutationSuccess = this.handleMutationSuccess.bind(this)
		this.onOpenCustomerPortal = this.onOpenCustomerPortal.bind(this)
		this.qs = queryString.parse(props.location.search)
	}

	handleMutationSuccess() {
		const { navigate } = this.props
		toast.success(<Fragment>Subscribed to plan!</Fragment>, {
			autoClose: 2000,
			closeButton: false,
		})
		navigate("/dashboard")
	}

	handleMutationFailed() {
		toast.error(<Fragment>Could not subscribe to plan</Fragment>, {
			autoClose: 2000,
			closeButton: false,
		})
	}

	onOpenCustomerPortal() {
		const { relay } = this.props
		OpenCustomerPortalMutation.commit(
			relay.environment,
			"/pricing",
			(data) => {
				window.location.href = data.openCustomerPortal.url
			},
			(e) => {
				toast.error(
					e[0].message || "Unable to open customer portal",
					{ autoClose: 2000, closeButton: false }
				)
			}
		)
	}

	approvePlan() {
		const { relay, currentUser } = this.props
		const { showPlanModalId } = this.state
		const { id: currentUserId } = currentUser
		SubToPricingPlanMutation.commit(
			relay.environment,
			null,
			showPlanModalId.id,
			null,
			this.handleMutationSuccess,
			this.handleMutationFailed,
			currentUserId
		)
	}

	selectSubPlan(id, isFree = false) {
		const { currentUser, navigate } = this.props
		if (!currentUser) {
			navigate(`/signup?planId=${id}`)
			return
		}
		if (
			currentUser &&
			!currentUser.lastUnpaidInvoice &&
			currentUser.trialUsed &&
			currentUser.userPlan
		) {
			this.onOpenCustomerPortal()
			return
		}
		const { userPlan } = currentUser
		if ((userPlan && userPlan.hasSources) || isFree) {
			this.setState({ showPlanModalId: { id, isFree } })
		} else {
			let url = `${apiUrl}/checkout?planId=${id}`
			if (this.qs.ipCurrency) url += "&ipCurrency=true"
			window.location.href = url
		}
	}

	render() {
		const { plans, currentUser, coupon, yearly } = this.props
		const { showPlanModalId } = this.state
		const userPlan = (currentUser && currentUser.userPlan) || {}
		const freePlan = plans.find((i) => i.amount === 0)
		return (
			<Fragment>
				{freePlan && (
					<PriceCard
						plan={freePlan}
						currentUser={currentUser}
						currentPlan={userPlan}
						selectPlan={() => {
							this.selectSubPlan(freePlan.id, true)
						}}
					/>
				)}
				{plans &&
					plans
						.filter((i) => i.amount > 0 && i.monthly !== yearly)
						.map((i) => (
							<PriceCard
								key={i.id}
								plan={i}
								currentUser={currentUser}
								currentPlan={userPlan}
								coupon={coupon}
								selectPlan={() => {
									this.selectSubPlan(i.id)
								}}
							/>
						))}

				<ConformationPrompt
					openModal={!!showPlanModalId}
					closeModal={() => {
						this.setState({ showPlanModalId: null })
					}}
					approveEvent={() => {
						this.approvePlan()
					}}
					declineEvent={() => {
						this.setState({ showPlanModalId: null })
					}}
					approveText={
						showPlanModalId && showPlanModalId.isFree
							? "Confirm"
							: "Continue"
					}
					declineText="Cancel"
					approveButtonStyle={buttons.secondary}
					title={
						showPlanModalId && showPlanModalId.isFree
							? `Free Plan`
							: "Change Plan"
					}
				>
					{showPlanModalId && showPlanModalId.isFree
						? `By selecting the Free plan, your account will have limited features.`
						: `Are you sure? Switching will limit your account to the features of the new plan.`}
				</ConformationPrompt>
			</Fragment>
		)
	}
}

export default withRouter(ListOfPlans)
