/**
 * @generated SignedSource<<5e057c91bea642e7243c1da0d3a9dfc6>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Mutation } from 'relay-runtime';
export type CreateUpdateBackgroundInput = {|
  background?: ?string,
  category: string,
  clientMutationId?: ?string,
  id?: ?string,
  title: string,
  type: string,
|};
export type updateLookbookBackgroundMutation$variables = {|
  input: CreateUpdateBackgroundInput,
|};
export type updateLookbookBackgroundMutation$data = {|
  +createUpdateBackground: ?{|
    +background: ?{|
      +background: ?string,
      +canEdit: ?boolean,
      +category: ?string,
      +id: string,
      +preSignedUrl: ?{|
        +signedRequest: ?string,
        +url: ?string,
      |},
      +title: ?string,
      +type: ?string,
    |},
  |},
|};
export type updateLookbookBackgroundMutation = {|
  response: updateLookbookBackgroundMutation$data,
  variables: updateLookbookBackgroundMutation$variables,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "CreateUpdateBackgroundPayload",
    "kind": "LinkedField",
    "name": "createUpdateBackground",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Background",
        "kind": "LinkedField",
        "name": "background",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "canEdit",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "title",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "type",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "category",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "background",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PreSignedUrl",
            "kind": "LinkedField",
            "name": "preSignedUrl",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "signedRequest",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "url",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "updateLookbookBackgroundMutation",
    "selections": (v1/*: any*/),
    "type": "ShopshareMutationRoot",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "updateLookbookBackgroundMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "775704ed8ed8b42397bbb65f0f69ce5a",
    "id": null,
    "metadata": {},
    "name": "updateLookbookBackgroundMutation",
    "operationKind": "mutation",
    "text": "mutation updateLookbookBackgroundMutation(\n  $input: CreateUpdateBackgroundInput!\n) {\n  createUpdateBackground(input: $input) {\n    background {\n      id\n      canEdit\n      title\n      type\n      category\n      background\n      preSignedUrl {\n        signedRequest\n        url\n      }\n    }\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "4bf6afd50d0244eff9dabe8dca4dc979";

module.exports = ((node/*: any*/)/*: Mutation<
  updateLookbookBackgroundMutation$variables,
  updateLookbookBackgroundMutation$data,
>*/);
