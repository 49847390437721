/**
 * @generated SignedSource<<3585bb756be8e5a1651065090fd2de9a>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { Fragment, ReaderFragment } from 'relay-runtime';
export type userEnumType = "CLIENT" | "RETAILER" | "STYLIST" | "%future added value";
import type { FragmentType } from "relay-runtime";
declare export opaque type updatePersonalInfo_currentUser$fragmentType: FragmentType;
export type updatePersonalInfo_currentUser$data = {|
  +bio: ?string,
  +canEdit: ?boolean,
  +displayName: ?string,
  +email: ?string,
  +fullname: ?string,
  +id: string,
  +isRetailer: ?boolean,
  +isVerified: ?boolean,
  +paypalEmail: ?string,
  +profilePhoto: ?string,
  +socialLinks: ?{|
    +facebookUrl: ?string,
    +instagramUsername: ?string,
    +pinterestUrl: ?string,
    +twitterUsername: ?string,
    +website: ?string,
    +youtubeUrl: ?string,
  |},
  +type: ?userEnumType,
  +username: ?string,
  +verificationInProgress: ?string,
  +$fragmentType: updatePersonalInfo_currentUser$fragmentType,
|};
export type updatePersonalInfo_currentUser$key = {
  +$data?: updatePersonalInfo_currentUser$data,
  +$fragmentSpreads: updatePersonalInfo_currentUser$fragmentType,
  ...
};
*/

var node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "updatePersonalInfo_currentUser",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "displayName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "fullname",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "username",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "profilePhoto",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "canEdit",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "bio",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "email",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "paypalEmail",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isRetailer",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isVerified",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "UserSocialLinks",
      "kind": "LinkedField",
      "name": "socialLinks",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "website",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "instagramUsername",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "facebookUrl",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "twitterUsername",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "youtubeUrl",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "pinterestUrl",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "verificationInProgress",
      "storageKey": null
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node/*: any*/).hash = "2ab734339cf2e4526e88efc9ee620f1f";

module.exports = ((node/*: any*/)/*: Fragment<
  updatePersonalInfo_currentUser$fragmentType,
  updatePersonalInfo_currentUser$data,
>*/);
