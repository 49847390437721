import React, { Fragment } from "react"
import { createFragmentContainer, graphql } from "react-relay"
import { Transition } from "react-transition-group"
import { FileIcon, defaultStyles } from "react-file-icon"
import { toast } from "react-toastify"
import {
	TrashIcon,
	ResetIcon,
	EnterIcon,
	CrumpledPaperIcon,
	// Pencil1Icon,
	CheckCircledIcon,
	PlusIcon,
	MinusIcon,
	DownloadIcon,
	CopyIcon,
} from "@radix-ui/react-icons"
import FolderIcon from "shared/assets/folder-icon.svg"
import PinIcon from "shared/assets/pin-icon.svg"
import UnPinIcon from "shared/assets/unpin-icon.svg"
import card from "shared/styles/components/card.css"
import label from "shared/styles/components/label.css"
import input from "shared/styles/components/input.css"
import button from "shared/styles/components/buttons.css"
import DropdownMenu from "components/dropdown-menu/dropdown-menu"
import stylesDropdown from "components/dropdown-menu/dropdown-menu-dark.css"
import { withDrag } from "helpers/dnd-helper"
import { ShowCardControlsComponent, imageOrVideo } from "helpers/ui-helper"
import updateFile from "mutations/shopshare-file/update-file"
import ContentEditable from "components/content-editable/content-editable"
import MenuIcon from "shared/assets/menu-dots-horizontal.svg"
import PinButton from "components/buttons/pin-button"
import archiveShopcastItemMutation from "mutations/shopcast-manager/archive-shopcast-item"
import restoreShopcastItemMutation from "mutations/shopcast-manager/restore-shopcast-item"
import deleteFileMutation from "mutations/shopcast-manager/delete-file"
import moveShopcastItemsMutation from "mutations/shopcast-manager/move-shopcast-items"
import toggleFilePublishedState from "mutations/shopshare-file/toggle-file-published"
import CopyToMutation from "mutations/shopcast-manager/copy-to"
import { textStrings } from "constants"
import styles from "./user-file-card.css"
import { withRouter } from "../../../route-helpers"

function collect(monitor) {
	return {
		isDragging: monitor.isDragging(),
	}
}

export class UserFileCard extends React.Component {
	constructor(props) {
		super(props)

		this.type = "shopshareFile"

		this.handleArchiveClick = this.handleArchiveClick.bind(this)
		this.handleDeleteClick = this.handleDeleteClick.bind(this)
		this.handleRestoreClick = this.handleRestoreClick.bind(this)
		this.deleteFile = this.deleteFile.bind(this)
		this.handleCheckboxChange = this.handleCheckboxChange.bind(this)
		this.handleMoveClick = this.handleMoveClick.bind(this)
		this.handleCopyClick = this.handleCopyClick.bind(this)
		this.togglePublishState = this.togglePublishState.bind(this)
		this.updateFileTitle = this.updateFileTitle.bind(this)

		this.state = {
			titleInFocus: false,
			isDropdownOpen: false,
		}
	}

	//
	// ─── EVENT HANDLERS ─────────────────────────────────────────────────────────────
	//

	handleDeleteClick() {
		const { confirmDelete, file } = this.props
		confirmDelete({
			type: this.type,
			name: file.title,
			callback: this.deleteFile,
		})
	}

	handleRestoreClick() {
		const { relay, reportError, parentId, file, containerId } = this.props
		restoreShopcastItemMutation.commit(
			relay.environment,
			file.id,
			this.type,
			parentId,
			reportError("Could not restore file"),
			containerId,
			() => {
				toast.success(
					<>
						Moved to{" "}
						{file.inRoot
							? textStrings.clientWardrobeName
							: [...file.folder.path]
									.reverse()
									.map((i) => i.name)
									.join(" / ")}
					</>,
					{
						autoClose: 2000,
						closeButton: false,
					}
				)
			}
		)
	}

	handleArchiveClick() {
		const { relay, file, parentId, reportError, containerId } = this.props
		archiveShopcastItemMutation.commit(
			relay.environment,
			file.id,
			this.type,
			parentId,
			reportError("Could not archive file"),
			containerId,
			() => {}
		)
	}

	handleCheckboxChange({ target }) {
		const { checked } = target
		const { onSelect, onDeselect, file } = this.props
		;(checked ? onSelect : onDeselect)({
			id: file.id,
			isArchived: file.isArchived,
			type: this.type,
			deselect: () => {
				this.handleCheckboxChange({ target: { checked: false } })
			},
		})
	}

	handleMoveClick() {
		const {
			requestMove,
			relay,
			file,
			parentId,
			reportError,
			containerId,
			team
		} = this.props
		requestMove((folderId) => {
			moveShopcastItemsMutation.commit(
				relay.environment,
				[{ id: file.id, type: this.type }],
				folderId,
				team,
				parentId,
				containerId,
				reportError("Could not move file")
			)
		}, [])
	}

	handleCopyClick() {
		const {
			requestCopy,
			relay,
			file,
			reportError,
		} = this.props
		requestCopy((team) => {
			CopyToMutation.commit(
				relay.environment,
				[file.id],
				team.id,
				reportError("Could not copy file"),
				() => {
					toast.success(<>Successfully copied</>, {
						autoClose: 2000,
						closeButton: false,
					})
				}
			)
		})
	}

	//
	// ─── METHODS ────────────────────────────────────────────────────────────────────
	//

	deleteFile() {
		const { relay, parentId, reportError, file, containerId } = this.props

		deleteFileMutation.commit(
			relay.environment,
			file.id,
			parentId,
			file.isArchived ? null : containerId,
			reportError("Could not delete folder")
		)
	}

	togglePublishState() {
		const {
			file,
			relay,
			reportError,
			containerId,
			parentId,
			routeType,
		} = this.props

		toggleFilePublishedState.commit(
			relay.environment,
			file.id,
			parentId,
			containerId,
			routeType === "public" && file.isPublished,
			reportError(`Couldnt ${file.isPublished ? "un-publish" : "publish"}`)
		)
	}

	updateFileTitle(title) {
		const { file, relay } = this.props
		updateFile.commit(
			relay.environment,
			{
				id: file.id,
				title,
			},
			(e) => {
				console.log(e)
			},
			() => {
				toast.success(<Fragment>File title updated!</Fragment>, {
					autoClose: 2000,
					closeButton: false,
				})
			}
		)
	}

	async downloadFile(file) {
		const fileObj = await fetch(file.url, {
			method: "GET",
			mode: "cors",
			cache: "no-cache",
			headers: {
				Origin: window.location.origin,
			},
		})
		const fileBlob = await fileObj.blob()
		const imageURL = URL.createObjectURL(fileBlob)

		const link = document.createElement("a")
		link.href = imageURL
		link.download = file.url.split("/").pop()
		document.body.appendChild(link)
		link.click()
		document.body.removeChild(link)
	}

	//
	// ─── RENDERS ────────────────────────────────────────────────────────────────────
	//

	renderPlay(shopshareFile) {
		const getUrlExtension = (url) =>
			url.split(/[#?]/)[0].split(".").pop().trim()
		const { enableCardSelection, isSelected, toggleLightbox } = this.props
		const icon = shopshareFile.thumbnailUrl ? (
			<img
				className={styles.poster}
				loading="lazy"
				src={`${shopshareFile.thumbnailUrl}?d=400`}
				alt="Poster"
				draggable={false}
			/>
		) : (
			<div className={styles.posterIcon}>
				<FileIcon
					extension={getUrlExtension(shopshareFile.url)}
					{...defaultStyles[getUrlExtension(shopshareFile.url)]}
				/>
			</div>
		)
		if (enableCardSelection)
			return (
				<div
					className={styles.figure}
					onClick={() => {
						this.handleCheckboxChange({
							target: { checked: !isSelected },
						})
					}}
					role="button"
					tabIndex={0}
				>
					<div className={styles.inner}>{icon}</div>
				</div>
			)
		if (!!imageOrVideo(shopshareFile.url) && toggleLightbox)
			return (
				<div
					className={styles.figure}
					onClick={() => {
						toggleLightbox(shopshareFile.url)
					}}
					role="button"
					tabIndex={0}
				>
					<div className={styles.inner}>{icon}</div>
				</div>
			)

		return (
			<a
				className={styles.figure}
				href={shopshareFile.url}
				rel="noreferrer"
				target="_blank"
			>
				<div className={styles.inner}>{icon}</div>
			</a>
		)
	}

	renderMenu() {
		const {
			file,
			togglePinned,
			isSelected,
			searchValue,
			routeType,
			navigate,
			isParentAClient,
			requestCopy
		} = this.props
		const listItems = []
		const push = (click, name, key, icon) =>
			listItems.push({
				click,
				name,
				key,
				icon,
			})
		const icon = <MenuIcon className={stylesDropdown.icon} />
		if (file.isArchived) {
			if (routeType === "archived")
				push(
					this.handleRestoreClick,
					"Restore",
					"restore",
					<ResetIcon className={stylesDropdown.itemIcon} />
				)
			push(
				this.handleDeleteClick,
				"Delete forever",
				"delete",
				<TrashIcon className={stylesDropdown.itemIcon} />
			)
		} else {
			if (file.folder && searchValue) {
				push(
					() => navigate(`/content/${file.folder.id}`),
					"Go to Folder",
					"gtf",
					<EnterIcon className={`${stylesDropdown.itemIcon}`} />
				)
				listItems.push("divide")
			}
			if (!isParentAClient) {
				const d = file.isPublished
					? {
							key: "private",
							name: "Remove from My Channel",
							icon: <MinusIcon className={stylesDropdown.itemIcon} />,
					  }
					: {
							key: "public",
							name: "Add to My Channel",
							icon: <PlusIcon className={stylesDropdown.itemIcon} />,
					  }
				push(this.togglePublishState, d.name, d.key, d.icon)
				listItems.push("divide")
			}

			push(
				this.handleMoveClick,
				"Move to...",
				"move",
				<FolderIcon className={stylesDropdown.itemIcon} />
			)
			if (requestCopy)
				push(
					this.handleCopyClick,
					"Copy to...",
					"copy",
					<CopyIcon className={`${stylesDropdown.itemIcon}`} />
				)
			listItems.push("divide")
			push(
				() => this.downloadFile(file),
				"Download File",
				"download",
				<DownloadIcon className={stylesDropdown.itemIcon} />
			)
			push(
				togglePinned,
				file.isPinned ? "Unpin File" : "Pin File",
				"pin",
				file.isPinned ? (
					<UnPinIcon className={stylesDropdown.itemIcon} />
				) : (
					<PinIcon className={stylesDropdown.itemIcon} />
				)
			)
			push(
				() => {
					this.handleCheckboxChange({
						target: { checked: !isSelected },
					})
				},
				"Select Items",
				"select",
				<CheckCircledIcon className={stylesDropdown.itemIcon} />
			)
			// push(
			// 	() => requestEdit(file),
			// 	"Edit",
			// 	"edit",
			// 	<Pencil1Icon className={stylesDropdown.itemIcon} />
			// )
			listItems.push("divide")
			push(
				this.handleArchiveClick,
				"Move to Trash",
				"trash",
				<CrumpledPaperIcon className={stylesDropdown.itemIcon} />
			)
			push(
				this.handleDeleteClick,
				"Delete forever",
				"deletePerm",
				<TrashIcon className={stylesDropdown.itemIcon} />
			)
		}
		return (
			<DropdownMenu
				mainIcon={icon}
				items={listItems}
				darkTheme
				contentRight
				triggerClass={button.lightIcon}
				triggerClassActive={button.lightIconActive}
				setDropdownOpened={(val) => {
					this.setState({ isDropdownOpen: val })
				}}
			/>
		)
	}

	renderContent(shopshareFile, isShown) {
		const {
			isSelected,
			togglePinned,
			enableCardSelection,
			routeType,
		} = this.props
		const { isDropdownOpen } = this.state
		return (
			<>
				<div
					className={
						isSelected ? `${styles.media} ${styles.active}` : styles.media
					}
				>
					{this.renderPlay(shopshareFile)}
					<Transition in={isShown || enableCardSelection} timeout={200}>
						{(state) => (
							<div className={`${styles.select} ${styles[state]}`}>
								<input
									type="checkbox"
									checked={isSelected}
									className={`${input.checkbox} ${input.lg}`}
									onChange={this.handleCheckboxChange}
								/>
							</div>
						)}
					</Transition>

					<Transition
						in={(isShown || isDropdownOpen) && !enableCardSelection}
						timeout={200}
					>
						{(state) => (
							<div className={`${styles.menu} ${styles[state]}`}>
								{this.renderMenu()}
							</div>
						)}
					</Transition>

					{routeType !== "public" && shopshareFile.isPublished && (
						<div className={card.badge}>
							<div className={label.published}>Published</div>
						</div>
					)}
				</div>
				<div className={styles.content}>
					<div className={styles.titleWithIcons}>
						<ContentEditable
							className={styles.title}
							title={shopshareFile.title}
							saveTitle={this.updateFileTitle}
							contentEditable={!enableCardSelection}
							setTitleFocus={(val) =>
								this.setState({ titleInFocus: val })
							}
						/>
						{!enableCardSelection && (
							<PinButton
								isPinned={shopshareFile.isPinned}
								onClick={togglePinned}
							/>
						)}
					</div>

					<div className={styles.subtitle}>
						{shopshareFile.url.split(/[#?]/)[0].split(".").pop().trim()}
					</div>
				</div>
			</>
		)
	}

	render() {
		const { file, dragRef, isDragging } = this.props
		const { titleInFocus } = this.state
		return (
			<ShowCardControlsComponent
				innerRef={titleInFocus ? null : dragRef}
				className={styles.root}
				style={{ opacity: isDragging ? 0.5 : 1 }}
			>
				{(isShown) => this.renderContent(file, isShown)}
			</ShowCardControlsComponent>
		)
	}
}

function UserFileCardComp({ isDraggable, ...otherProps }) {
	const Target = isDraggable
		? withDrag(UserFileCard, ({ file }) => ({
				type: "shopshare-file-card",
				item: {
					id: file.id,
					type: "shopshareFile",
					isPublished: file.isPublished,
				},
				collect,
		  }))
		: UserFileCard
	return <Target {...otherProps} />
}

export default withRouter(
	createFragmentContainer(UserFileCardComp, {
		file: graphql`
			fragment userFileCard_shopshareFile on ShopshareFile {
				id
				title
				url
				thumbnailUrl
				isPublished
				isPinned
				isArchived
				inRoot
				user {
					username
				}
				folder {
					name
					id
					path {
						value
						name
					}
				}
			}
		`,
	})
)
