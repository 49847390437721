/**
 * @generated SignedSource<<a22273c8bad5f7e0356cadbe58d4d244>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { sharedFolderCard_shopcastFolder$fragmentType } from "./../../../components/cards/shared-folder-card/__generated__/sharedFolderCard_shopcastFolder.graphql";
import type { FragmentType } from "relay-runtime";
declare export opaque type wardrobe_store$fragmentType: FragmentType;
export type wardrobe_store$data = {|
  +currentUser: ?{|
    +id: string,
    +productCollections: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +title: ?string,
        |},
      |}>,
    |},
    +wardrobe: ?$ReadOnlyArray<?{|
      +folders: ?$ReadOnlyArray<?{|
        +id: string,
        +$fragmentSpreads: sharedFolderCard_shopcastFolder$fragmentType,
      |}>,
      +user: ?{|
        +bannerPhoto: ?string,
        +fullname: ?string,
        +id: string,
        +profilePhoto: ?string,
        +username: ?string,
      |},
    |}>,
  |},
  +id: string,
  +$fragmentType: wardrobe_store$fragmentType,
|};
export type wardrobe_store$key = {
  +$data?: wardrobe_store$data,
  +$fragmentSpreads: wardrobe_store$fragmentType,
  ...
};
*/

var node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "wardrobe_store",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "currentUser",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "ProductCollectionConnection",
          "kind": "LinkedField",
          "name": "productCollections",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ProductCollectionEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "ProductCollection",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "title",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Wardrobe",
          "kind": "LinkedField",
          "name": "wardrobe",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "User",
              "kind": "LinkedField",
              "name": "user",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "fullname",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "username",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "profilePhoto",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "bannerPhoto",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "ShopcastFolder",
              "kind": "LinkedField",
              "name": "folders",
              "plural": true,
              "selections": [
                (v0/*: any*/),
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "sharedFolderCard_shopcastFolder"
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Store",
  "abstractKey": null
};
})();

(node/*: any*/).hash = "5408f1a9c12342540cd45a8d1003f7ee";

module.exports = ((node/*: any*/)/*: Fragment<
  wardrobe_store$fragmentType,
  wardrobe_store$data,
>*/);
