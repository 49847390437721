import React, { Component, Fragment } from "react"
import InfiniteScroll from "react-infinite-scroller"
import { createPaginationContainer, graphql } from "react-relay"
import { toast } from "react-toastify"
import { clickFavourite } from "helpers/lookbook-helper"
import FavouriteList from "components/lookbook/favourite-list"
import ProductEditModal from "components/modals/product-edit/product-edit"
import ConformationPrompt from "components/modals/conformation-prompt/conformation-prompt"
import DeleteProductMutation from "mutations/product/delete-product"
import ImageCard from "components/cards/lookbook/image-card"
import LoadingDots from "components/loading-dots/loading-dots"
import grids from "shared/styles/components/grids.css"
import textfield from "shared/styles/components/textfield.css"
import lookbookAside from "shared/styles/layout/lookbook-aside.css"
import buttons from "shared/styles/components/buttons.css"
import empty from "shared/styles/components/empty.css"
import gqlHelper from "helpers/gql-helper"
import { getComponent } from "../../route-helpers"
import { getChipClass } from "../../helpers/ui-helper"

class ShopcastList extends Component {
	constructor(props) {
		super(props)
		this.state = { productToEdit: null, productToDelete: null }
		this.fetchData = this.fetchData.bind(this)
		this.editProduct = this.editProduct.bind(this)
	}

	fetchData() {
		const { relay } = this.props
		relay.loadMore(12)
	}

	editProduct(product) {
		this.setState({ productToEdit: product })
	}

	loadList(hasfavourites, imageList) {
		if (!imageList || imageList.length === 0)
			return <div className={empty.simpleSml}>No Featured Products</div>
		const {
			shopcastListId,
			category,
			drop,
			favouriteListOpen,
			relay,
			store,
			team
		} = this.props
		if (favouriteListOpen && hasfavourites) {
			const FavouriteListComp = getComponent(
				`fav_list_shopcast`,
				FavouriteList,
				{
					...FavouriteList.params,
					category: "shopcast",
					teamId: team
				},
				{
					favouriteListOpen,
					category: "shopcast",
					drop,
					editProduct: this.editProduct,
				}
			)
			return FavouriteListComp
		}
		
		return (
			<InfiniteScroll
				loadMore={this.fetchData}
				hasMore={relay ? relay.hasMore() : false}
				loader={<LoadingDots key={0} />}
				pageStart={0}
				useWindow={false}
			>
				<div className={lookbookAside.content}>
					<div className={grids.image}>
						{imageList.map((x) => (
							<ImageCard
								key={x.id}
								isDraggable
								node={x}
								price={x.offerPrice || x.regularPrice}
								description={x.title}
								drop={drop}
								showAddButton
								isFavourite={x.isFavourite}
								showFavouriteButton
								clickFavourite={() =>
									clickFavourite(x.id, "shopcast", team, relay)
								}
								canEdit
								editImage={() => this.setState({ productToEdit: x })}
								deleteImage={
									shopcastListId &&
									(() => {
										this.setState({ productToDelete: x })
									})
								}
								category={category}
								contentEditable
								relay={relay}
								store={store}
								showDownloadButtons
							/>
						))}
					</div>
				</div>
			</InfiniteScroll>
		)
	}

	render() {
		const {
			shopcastListId,
			onChange,
			store,
			relay,
			loading: mainLoading,
		} = this.props
		const { productToEdit, productToDelete } = this.state
		if (mainLoading || !(store && store.currentUser)) {
			return <LoadingDots />
		}
		const imageList = (store.currentUser.lookbookProducts
			? store.currentUser.lookbookProducts.edges
			: []
		).map((obj) => obj.node)
		const titleList = (store.currentUser.shopcasts
			? store.currentUser.shopcasts.edges
			: []
		).map((obj) => obj.node)
		const hasfavourites =
			(store.currentUser.lookbookFavourites
				? store.currentUser.lookbookFavourites.edges
				: []
			).map((obj) => obj.node).length > 0 ||
			imageList.filter((obj) => obj.isFavourite === true).length > 0

		const productCollections = store.currentUser.productCollections
			? store.currentUser.productCollections.edges.map(({ node: i }) => ({
					id: i.id,
					title: `${i.title} ${
						store.currentUser.id !== i.user.id
							? `(${i.user.fullname})`
							: ""
					}`,
					isDefault: i.isDefault,
					isACloset: i.isACloset,
					isPublished: i.isPublished,
					owned: store.currentUser.id === i.user.id,
			  }))
			: []
		return (
			<Fragment>
				<div className={lookbookAside.header}>
					<select
						className={textfield.normal}
						value={shopcastListId}
						onChange={onChange}
					>
						<option value="">All Shopcasts</option>
						<option disabled>─</option>
						{hasfavourites && (
							<Fragment>
								<option value="favourites"> ❤️ My Favourites</option>
								<option disabled>─</option>
							</Fragment>
						)}
						{titleList.map((x) => (
							<option key={x.id} value={x.id}>
								{x.title}
							</option>
						))}
					</select>
				</div>
				{this.loadList(hasfavourites, imageList)}
				<ProductEditModal
					store={store}
					product={productToEdit}
					showProductEditModal={!!productToEdit}
					closeProductEditModal={() => {
						this.setState({ productToEdit: null })
					}}
					relay={relay}
					enableSavingWithoutCollection
					collectionsKeyValue={productCollections
						.filter((i) => !i.isDefault || !i.owned)
						.map((i) => ({
							id: i.id,
							value: i.title,
							className: getChipClass(i).chip
						}))}
				/>
				<ConformationPrompt
					openModal={!!productToDelete}
					closeModal={() => {
						this.setState({ productToDelete: null })
					}}
					approveEvent={() => {
						DeleteProductMutation.commit(
							relay.environment,
							shopcastListId,
							productToDelete.id,
							store.id,
							() => {
								toast.success(<>Successfully Deleted!</>, {
									autoClose: 5000,
									closeButton: false,
								})
							},
							(e) => {
								toast.info(<>{gqlHelper.getError(e)}</>, {
									autoClose: 5000,
									closeButton: false,
								})
							}
						)
						this.setState({ productToDelete: null })
					}}
					declineEvent={() => {
						this.setState({ productToDelete: null })
					}}
					approveText="Delete"
					declineText="Cancel"
					approveButtonStyle={buttons.primaryRed}
					title="Are you sure?"
				>
					Do you want to permanently delete &quot;
					{productToDelete?.title}&quot;? This action cannot be undone.
				</ConformationPrompt>
			</Fragment>
		)
	}
}

const query = graphql`
	query shopcastListQuery(
		$count: Int!
		$cursor: String
		$shopcastListId: String
		$teamId: String
	) {
		store {
			...shopcastList_store
				@arguments(
					count: $count
					cursor: $cursor
					shopcastListId: $shopcastListId
					teamId: $teamId
				)
		}
	}
`

ShopcastList = createPaginationContainer(
	ShopcastList,
	{
		store: graphql`
			fragment shopcastList_store on Store
			@argumentDefinitions(
				count: { type: "Int", defaultValue: 24 }
				cursor: { type: "String" }
				shopcastListId: { type: "String", defaultValue: "" }
				teamId: { type: "String" }
			) {
				currentUser {
					id
					productCollections(teamId: $teamId) {
						edges {
							node {
								id
								title
								isDefault
								isACloset
								isPublished
								folder {
									id
									name
								}
								user {
									id
									fullname
								}
							}
						}
					}
					shopcasts(teamId: $teamId) {
						edges {
							node {
								id
								title
							}
						}
					}
					lookbookProducts(
						first: $count
						after: $cursor
						shopcastId: $shopcastListId
						teamId: $teamId
					) @connection(key: "currentUser_lookbookProducts") {
						edges {
							node {
								id
								title
								brand
								description
								url
								affiliated_url
								custom_affiliated_url
								imageUrl
								diffbotImageUrl
								regularPrice
								offerPrice
								error
								productImages
								hasProductImagesFromDiffbot
								productCollections {
									id
									title
									isACloset
									isPublished
								}
								isFavourite
							}
						}
					}
					lookbookFavourites(category: "shopcast", teamId: $teamId) {
						edges {
							node {
								__typename
								... on ShopcastProduct {
									id
								}
							}
						}
					}
				}
			}
		`,
	},
	{
		direction: "forward",
		getConnectionFromProps(props) {
			const { currentUser } = props.store
			return currentUser && currentUser.lookbookProducts
		},
		getFragmentVariables(prevVars, totalCount) {
			return {
				...prevVars,
				count: totalCount,
			}
		},
		getVariables(props, { count, cursor }) {
			return {
				count,
				cursor,
				shopcastListId: props.shopcastListId,
				teamId: props.teamId,
			}
		},
		query,
	}
)
export default {
	Component: ShopcastList,
	query,
	params: {
		count: 12,
		cursor: null,
		shopcastListId: null,
		teamId: null,
	},
	showLoading: true,
}
