import React, { Component } from "react"
import { createFragmentContainer, graphql } from "react-relay"
import {
	DashboardIcon,
	// ArrowLeftIcon,
} from "@radix-ui/react-icons"
import { Outlet } from "react-router-dom"
import pageContent from "shared/styles/layout/page-content.css"
import form from "shared/styles/components/form.css"
// import button from "shared/styles/components/buttons.css"
// import icon from "shared/styles/components/icons.css"
import nav from "shared/styles/components/nav.css"
import controls from "shared/styles/layout/controls.css"
import responsive from "shared/styles/utilities/responsive.css"
import empty from "shared/styles/components/empty.css"
// import SearchInputComponent from "components/search-input/search-input"
import pageHeader from "shared/styles/layout/page-header.css"
import { getActiveTabClass } from "helpers/ui-helper"
import { withOutletContext, withRouter } from "../../route-helpers"

class ClientViewFolder extends Component {
	constructor(props) {
		super(props)
		this.state = {
			sharedFolderCollection: "",
			clientViewFolderSort: "name",

			mainTabStyles: {},
		}

		const {
			sharedFolderShopcastsCount,
			sharedFolderLookbooksCount,
			sharedFolderFilesCount,
			sharedFolderCollectionsListCount,
		} = props.store.currentUser
		this.tabs = [
			{
				id: "",
				name: "All",
				count:
					sharedFolderShopcastsCount +
					sharedFolderLookbooksCount +
					sharedFolderFilesCount +
					sharedFolderCollectionsListCount,
			},
			{
				id: "shopcasts",
				name: "Shopcasts",
				count: sharedFolderShopcastsCount,
			},
			{
				id: "shopboards",
				name: "Shopboards",
				count: sharedFolderLookbooksCount,
			},
			{
				id: "collections",
				name: "Collections",
				count: sharedFolderCollectionsListCount,
			},
			{ id: "files", name: "Files", count: sharedFolderFilesCount },
		].filter((tab) => tab.count > 0)
		this.tabRefs = {}

		this.listBreakpoints = {
			"breakpoint-xl": 4,
			"breakpoint-md": 3,
			"breakpoint-sm": 2,
		}

		this.setMainTabStyles = this.setMainTabStyles.bind(this)
		this.setCollectionId = this.setCollectionId.bind(this)
		this.navigateToRoute = this.navigateToRoute.bind(this)
	}

	componentDidMount() {
		this.setMainTabStyles()
	}

	setMainTabStyles() {
		const { location } = this.props
		const lastRoute = location.pathname.split("/").pop()
		const tabId = this.tabs.find((tab) => tab.id === lastRoute)?.id || ""
		this.setState({
			mainTabStyles: {
				width: this.tabRefs[tabId]?.offsetWidth || 0,
				transform: `translateX(${this.tabRefs[tabId]?.offsetLeft || 0}px)`,
			},
		})
	}

	setCollectionId(collectionId) {
		const { navigate } = this.props
		navigate(`collections`)
		this.setState({
			sharedFolderCollection: collectionId,
			mainTabStyles: {
				width: this.tabRefs.collections.offsetWidth,
				transform: `translateX(${this.tabRefs.collections.offsetLeft}px)`,
			},
		})
	}

	navigateToRoute(tabId) {
		const { navigate } = this.props
		navigate(tabId || "")
		this.setState({
			mainTabStyles: {
				width: this.tabRefs[tabId].offsetWidth,
				transform: `translateX(${this.tabRefs[tabId].offsetLeft}px)`,
			},
		})
	}

	renderMain() {
		const { clientViewFolderSort, sharedFolderCollection } = this.state
		const { clientFolder, store } = this.props
		const {
			sharedFolderShopcastsCount,
			sharedFolderLookbooksCount,
			sharedFolderFilesCount,
			sharedFolderCollectionsListCount,
		} = store.currentUser
		if (this.tabs.length === 0)
			return (
				<div className={empty.root}>
					<div className={empty.container}>
						<div className={empty.content}>
							<DashboardIcon className={empty.icon} />
							<h4 className={empty.headline}>
								There&apos;s nothing here
							</h4>
							<p className={empty.subline}>
								Any content shared will appear here.
							</p>
						</div>
					</div>
				</div>
			)
		return (
			<Outlet
				context={{
					count: 12,
					sort: clientViewFolderSort,
					removeInfiniteScroll: false,
					breakpoints: this.listBreakpoints,
					clientId:
						clientFolder.clients.length > 0
							? clientFolder.clients[0].id
							: null,

					navigateToRoute: this.navigateToRoute,

					collectionId: sharedFolderCollection,
					setCollectionId: this.setCollectionId,

					sharedFolderShopcastsCount,
					sharedFolderLookbooksCount,
					sharedFolderFilesCount,
					sharedFolderCollectionsListCount,
				}}
			/>
		)
	}

	render() {
		const {
			location,
			navigate,
			params: { "*": prefix },
			store: { currentUser },
		} = this.props
		const { clientViewFolderSort, mainTabStyles } = this.state

		const pathnameSplit = location.pathname.split("/")
		const lastRoute = pathnameSplit[pathnameSplit.length - 1]
		const isAll = !this.tabs.map((t) => t.id).includes(lastRoute)

		return (
			<>
				{this.tabs.length > 0 && (
					<>
						<div className={pageHeader.headerClient}>
							<div className={pageHeader.titleWithAction}>
								{/* <button
										type="button"
										className={button.subtleIcon}
										onClick={() => history.push(url.replace(`/${params.tab}`))}
									>
										<ArrowLeftIcon className={icon.icon21} />
									</button> */}
								<h1 className={pageHeader.title}>
									{currentUser?.shopcastContainer?.folder?.name}
								</h1>
							</div>
						</div>
						<div className={controls.rootTabFilters}>
							<nav className={`${nav.tabs} ${responsive.hiddenSmDown}`}>
								{this.tabs.map((tab) => (
									<div
										role="button"
										tabIndex="0"
										className={getActiveTabClass(
											isAll ? !tab.id : tab.id === lastRoute,
											nav.link,
											nav.linkActive
										)}
										ref={(ref) => {
											this.tabRefs[tab.id] = ref
										}}
										onClick={() => {
											this.navigateToRoute(tab.id)
										}}
										key={tab.name}
									>
										{tab.name}
									</div>
								))}
								<span
									className={nav.tabUnderline}
									style={mainTabStyles}
								/>
							</nav>

							<div
								className={`${controls.group} ${responsive.hiddenSmUp}`}
							>
								<label className={form.label}>Show</label>
								<select
									value={location.pathname
										.replace(prefix, "")
										.replace("/", "")}
									className={controls.select}
									onChange={(e) => {
										const tabId = e.target.value
										navigate(tabId ? `${prefix}/${tabId}` : prefix)
									}}
								>
									{this.tabs.map((tab) => (
										<option key={tab.id} value={tab.id}>
											{tab.name}
										</option>
									))}
								</select>
							</div>
							<div className={controls.group}>
								<label
									className={`${form.label} ${responsive.hiddenSmUp}`}
								>
									Sort by
								</label>
								<select
									className={controls.select}
									value={clientViewFolderSort}
									onChange={(e) =>
										this.setState({
											clientViewFolderSort: e.target.value,
										})
									}
								>
									<optgroup label="Sort by">
										<option value="n-to-o">Newest to Oldest</option>
										<option value="o-to-n">Oldest to Newest</option>
										<option value="name">Alphabetical (A-Z)</option>
										<option value="name-d">Alphabetical (Z-A)</option>
									</optgroup>
								</select>
							</div>
						</div>
					</>
				)}
				<div className={pageContent.root}>{this.renderMain()}</div>
			</>
		)
	}
}

const query = graphql`
	query clientViewFolderQuery($folder: String!) {
		store {
			id
			...clientViewFolder_store @arguments(folder: $folder)
		}
	}
`

ClientViewFolder = createFragmentContainer(ClientViewFolder, {
	store: graphql`
		fragment clientViewFolder_store on Store
		@argumentDefinitions(folder: { type: "String!" }) {
			currentUser {
				shopcastContainer {
					folder(id: $folder) {
						id
						name
					}
				}
				sharedFolderShopcastsCount(folderId: $folder)
				sharedFolderLookbooksCount(folderId: $folder)
				sharedFolderFilesCount(folderId: $folder)
				sharedFolderCollectionsListCount(folderId: $folder)
			}
		}
	`,
})

export default {
	Component: withRouter(withOutletContext(ClientViewFolder)),
	query,
	params: (rp) => ({
		folder: rp.params.tab,
	}),
	props: {
		folder: null,
		clientId: null,
	},
}
