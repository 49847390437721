import React, { Component } from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { DashboardIcon } from '@radix-ui/react-icons';
import Shopping from "shared/assets/illustrations/shopping.svg"
import empty from "shared/styles/components/empty.css"
import button from "shared/styles/components/buttons.css"
import banner from "shared/styles/layout/banner.css"
import nav from "shared/styles/components/nav.css"
import grids from "shared/styles/components/grids.css"
import controls from "shared/styles/layout/controls.css"
import textfield from "shared/styles/components/textfield.css"
import stylesAvatar from "components/profile/avatar/avatar.css"
import Avatar from "components/profile/avatar/avatar"
import SearchInputComponent from "components/search-input/search-input"

import TeamFiles from './team-files'
import TeamLookbooks from './team-lookbooks'
import TeamShopcasts from './team-shopcasts';
import TeamCollectionsList from './team-collections-list';

import styles from './team-view.css';
import TeamCollection from './team-collection';
import { debounce } from '../../helpers/ui-helper';
import { getComponent } from '../../route-helpers';

const defaultItems = [
	"Shopcasts",
	"Shopboards",
	"Files",
	"Collections",
	"About",
]
class TeamView extends Component {

	constructor(props) {
		super(props)

		this.state = {
			currentTab: this.getTabsData(false),
			tabStyles: {},
			collectionFilter: {
				searchText: "",
				sort: "n-to-o",
				collectionId: "",
				type: "",
			},
		}
		this.messageListner = this.messageListner.bind(this)
		this.setTabStyle = this.setTabStyle.bind(this)
		this.setCollectionId = this.setCollectionId.bind(this)
		this.setKeywordCollectionBounce = debounce((val) => {
			const {
				collectionFilter: { sort, collectionId, type },
			} = this.state
			this.setState({
				collectionFilter: {
					sort,
					collectionId,
					searchText: val,
					type,
				},
			})
		}, 500)
		this.tabsRef = {}
		window.addEventListener("message", this.messageListner)
	}

	componentDidMount() {
		this.setTabStyle()
		window.addEventListener("resize", this.setTabStyle)
	}

	componentWillUnmount() {
		window.removeEventListener("message", this.messageListner)
		window.removeEventListener("resize", this.setTabStyle)
	}

	setTabStyle() {
		const { currentTab } = this.state
		this.setState({
			tabStyles: {
				width: this.tabsRef[currentTab]?.offsetWidth || 0,
				transform: `translateX(${
					this.tabsRef[currentTab]?.offsetLeft || 0
				}px)`,
			},
		})
	}

	setCollectionId(collectionId) {
		this.setState({
			collectionFilter: {
				searchText: "",
				sort: "n-to-o",
				collectionId,
				type: "",
			},
		})
	}

	getTabsData(isFilter) {
		const {
			store: { team },
		} = this.props
		if (!team) return ""
		const { previewConfig } = team
		const tabs = defaultItems
		const tabDataMap = {
			Shopcasts:
				previewConfig.showShopcastsSection &&
				team.shopcasts.edges.length > 0,
			Shopboards:
				previewConfig.showShopboardsSection &&
				team.lookbooks.edges.length > 0,
			Files:
				previewConfig.showFilesSection &&
				team.files.edges.length > 0,
			Collections:
				previewConfig.showCollectionsSection &&
				team.collections.edges.length > 0,
			About: false,
		}
		if (isFilter) return tabs.filter((i) => tabDataMap[i])
		return tabs.find((i) => tabDataMap[i])
	}

	changeTabState(e, toState) {
		e.preventDefault()
		this.setState({
			currentTab: toState,
			tabStyles: {
				width: this.tabsRef[toState].offsetWidth,
				transform: `translateX(${this.tabsRef[toState].offsetLeft}px)`,
			},
		})
	}

	messageListner(event) {
		if (event.data === "shopshare-reload") window.location.reload()
	}

    pageNotAvailable() {
		return (
			<div className={styles.rootEmpty}>
				<div className={empty.root}>
					<div className={empty.container}>
						<div className={empty.content}>
							<Shopping className={empty.hero} />
							<h4 className={empty.headline}>
								Sorry, this page isn&apos;t available.
							</h4>
							<p className={empty.subline}>
								The link you followed may be broken or the page may have
								been removed.
							</p>
							<div className={styles.cta}>
								<Link className={button.primary} to="/">
									ShopShare Home
								</Link>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}

	renderTabsHeader() {
		const { state } = this
		const renderTabs = this.getTabsData(true).map((tab) => (
			<a
				className={state.currentTab === tab ? nav.tabActive : nav.tab}
				onClick={(e) => this.changeTabState(e, tab)}
				onKeyPress={(e) => this.changeTabState(e, tab)}
				tabIndex={0}
				role="link"
				key={tab}
				ref={(ref) => {
					this.tabsRef[tab] = ref
				}}
			>
				{tab}
			</a>
		))
		return renderTabs
	}

	renderAvatar(team) {
		const { previewConfig } = team
		let avatar
		if (previewConfig.showAvatar === "custom")
			avatar = team.displayAvatar
		else if (previewConfig.showAvatar === "profile")
			avatar = team.user.profilePhoto
		return (
			<Avatar
				className={styles.avatar}
				styleName={stylesAvatar.border}
				photoUrl={avatar}
				fullname={team.name}
			/>
		)
	}

	renderTab() {
		const { state } = this
		const {
			collectionFilter: { collectionId },
		} = state
		switch (state.currentTab) {
			case "Shopboards":
				return this.renderLookbooksTab()
			case "Shopcasts":
				return this.renderShopcastsTab()
			case "Files":
				return this.renderFilesTab()
			case "Collections":
				return !!collectionId
					? this.renderCollectionsTab()
					: this.renderCollectionsList()
			default:
				return this.renderEmptyState()
		}
	}

    renderTeam(team) {
		const { currentTab, tabStyles } = this.state
		const { previewConfig } = team
		const inlineStyles = {}
		if (
			previewConfig.showBanner === "custom" &&
			team.bannerPhoto
		)
			inlineStyles.backgroundImage = `url(${team.bannerPhoto})`
		else if (previewConfig.showBanner === "profile" && team.user.bannerPhoto)
			inlineStyles.backgroundImage = `url(${team.user.bannerPhoto})`
		const showBanner = previewConfig.showBanner !== "off"
		const showAvatar = previewConfig.showAvatar !== "off"
		return (
			<div className={styles.root}>
				<Helmet>
					<title>{team.name} – ShopShare</title>
				</Helmet>
				{showBanner && (
					<div className={banner.coverSticky} style={inlineStyles} />
				)}
				<div className={styles.header}>
					<div className={styles.inner}>
						{showAvatar && (
							<div
								className={
									showBanner ? styles.avatar : styles.avatarNoBanner
								}
							>
								{this.renderAvatar(team)}
							</div>
						)}
						{previewConfig.showDisplayName && (
							<h1 className={styles.displayName}>
								{team.displayName || team.name}
							</h1>
						)}
					</div>
				</div>
				{!!currentTab && (
					<div
						className={`${nav.rootHasBorder} ${nav.rootStickyNoHeader}`}
					>
						<nav className={nav.tabs}>
							<div className={nav.tabsFit}>
								{this.renderTabsHeader()}
								<span className={nav.tabUnderline} style={tabStyles} />
							</div>
						</nav>
					</div>
				)}
				<div className={styles.content}>
					<div className={styles.contentInner}>{this.renderTab()}</div>
				</div>
			</div>
		)
	}

	renderEmptyState() {
		const data = {
			title: "There's nothing here",
			message: "Any content shared will appear here.",
			icon: <DashboardIcon className={empty.icon} />,
		}
		return (
			<div className={empty.root}>
				<div className={empty.container}>
					<div className={empty.content}>
						{data.icon}
						<h4 className={empty.headline}>{data.title}</h4>
						<p className={empty.subline}>{data.message}</p>
					</div>
				</div>
			</div>
		)
	}

	renderShopcastsTab() {
		const { props } = this
		const { id, shopcasts } = props.store.team
		if (shopcasts.edges.length) {
			const TeamShopcastsComp = getComponent(
				`team-shopcasts`,
				TeamShopcasts,
				{
					...TeamShopcasts.params,
					teamId: id,
				},
				{
					...TeamShopcasts.props,
					teamId: id,
				}
			)
			return TeamShopcastsComp
		}
		return (
			<div className={grids.container}>
				<div className={grids.row}>
					<div className={grids.span}>
						<div className={empty.simple}>There&apos;s nothing here.</div>
					</div>
				</div>
			</div>
		)
	}

	renderLookbooksTab() {
		const { props } = this
		const { id, lookbooks } = props.store.team
		if (lookbooks.edges.length) {
			const TeamLookbooksComp = getComponent(
				`team-loobooks`,
				TeamLookbooks,
				{
					...TeamLookbooks.params,
					teamId: id,
				},
				{
					...TeamLookbooks.props,
					teamId: id,
				}
			)
			return TeamLookbooksComp
		}
		return (
			<div className={grids.container}>
				<div className={grids.row}>
					<div className={grids.span}>
						<div className={empty.simple}>There&apos;s nothing here.</div>
					</div>
				</div>
			</div>
		)
	}

	renderFilesTab() {
		const { props } = this
		const { id, files } = props.store.team
		if (files.edges.length) {
			const TeamFilesComp = getComponent(
				`team-files`,
				TeamFiles,
				{
					...TeamFiles.params,
					teamId: id,
				},
				{
					...TeamFiles.props,
					teamId: id,
				}
			)
			return TeamFilesComp
		}
		return (
			<div className={grids.container}>
				<div className={grids.row}>
					<div className={grids.span}>
						<div className={empty.simple}>There&apos;s nothing here.</div>
					</div>
				</div>
			</div>
		)
	}

	renderCollectionsList() {
		const { store } = this.props
		const { id } = store.team
		const TeamCollectionsListComp = getComponent(
			`team-collections-list`,
			TeamCollectionsList,
			{
				count: 12,
				teamId: id,
				removeInfiniteScroll: false,
			},
			{
				count: 12,
				teamId: id,
				removeInfiniteScroll: false,
				setCollectionId: this.setCollectionId,
			}
		)
		return TeamCollectionsListComp
	}

	renderCollectionsTab() {
		const { props, state } = this
		const {
			collectionFilter: { searchText, sort, collectionId, type },
		} = state

		const { id, collections } = props.store.team

		const TeamCollectionComp = getComponent(
			`team-collection`,
			TeamCollection,
			{
				...TeamCollection.params,
				searchText,
				sort,
				collectionId,
				type,
				teamId: id,
			},
			{
				...TeamCollection.props,
				searchText,
				sort,
				collectionId,
				type,
				teamId: id,
			}
		)
		return (
			<>
				<div className={controls.root}>
					<div className={controls.search}>
						<SearchInputComponent
							placeholder="Search"
							setSearchText={(value) =>
								this.setKeywordCollectionBounce(value)
							}
						/>
					</div>

					<div className={controls.filters}>
						<div className={controls.group}>
							<select
								className={textfield.normal}
								value={collectionId}
								onChange={(e) => {
									this.setState({
										collectionFilter: {
											searchText,
											sort,
											collectionId: e.target.value,
											type,
										},
									})
								}}
							>
								<optgroup label="Show">
									<option value="">All Collections</option>
									{collections.edges.map(
										({ node: item }) => (
											<option value={item.id}>{item.title}</option>
										)
									)}
								</optgroup>
							</select>
						</div>
						<div className={controls.group}>
							<select
								className={textfield.normal}
								value={sort}
								onChange={(e) => {
									this.setState({
										collectionFilter: {
											searchText,
											collectionId,
											sort: e.target.value,
											type,
										},
									})
								}}
							>
								<optgroup label="Sort by">
									<option value="n-to-o">Newest to Oldest</option>
									<option value="o-to-n">Oldest to Newest</option>
									<option value="name">Name A-Z</option>
									<option value="name-d">Name Z-A</option>
								</optgroup>
							</select>
						</div>
					</div>
				</div>
				{TeamCollectionComp}
			</>
		)
	}

    render() { 
        const {
			store: { team },
		} = this.props
		if (!team) return this.pageNotAvailable()
		return this.renderTeam(team)
    }
}

TeamView = createFragmentContainer(TeamView, {
	store: graphql`
		fragment teamView_store on Store
		@argumentDefinitions(teamId: { type: "String" }) {
			id
			team(id: $teamId) {
				id
				name
				displayName
				avatar
				displayAvatar
				bannerPhoto
				user {
					profilePhoto
					bannerPhoto
				}
				previewConfig {
					showBanner
					showAvatar
					showDisplayName
					showShopcastsSection
					showShopboardsSection
					showFilesSection
					showCollectionsSection
				}
				shopcasts {
					edges {
						node {
							id
						}
					}
				}
				lookbooks {
					edges {
						node {
							id
						}
					}
				}
				collections {
					edges {
						node {
							id
							title
						}
					}
				}
				files {
					edges {
						node {
							id
						}
					}
				}
			}
		}
	`,
})

const query = graphql`
	query teamViewQuery($teamId: String) {
		store {
			...teamView_store @arguments(teamId: $teamId)
		}
	}
`

export default {
	Component: TeamView,
	query,
	params: (rp) => ({
        teamId: rp.params.teamId,
    }),
}