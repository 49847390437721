/**
 * @generated SignedSource<<84196bc74f23776d783cd689a9fa9010>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { header_store$fragmentType } from "./../../../components/header/__generated__/header_store.graphql";
import type { updateUsername_currentUser$fragmentType } from "./../../../components/profile/account/__generated__/updateUsername_currentUser.graphql";
export type userEnumType = "CLIENT" | "RETAILER" | "STYLIST" | "%future added value";
import type { FragmentType } from "relay-runtime";
declare export opaque type main_store$fragmentType: FragmentType;
export type main_store$data = {|
  +currentUser: ?{|
    +email: ?string,
    +features: ?$ReadOnlyArray<?string>,
    +myFeatures: ?$ReadOnlyArray<?string>,
    +type: ?userEnumType,
    +userPlan: ?{|
      +amount: ?number,
      +currency: ?string,
      +id: ?string,
      +monthly: ?boolean,
      +nickname: ?string,
    |},
    +username: ?string,
    +$fragmentSpreads: updateUsername_currentUser$fragmentType,
  |},
  +id: string,
  +team: ?{|
    +id: string,
    +name: ?string,
  |},
  +$fragmentSpreads: header_store$fragmentType,
  +$fragmentType: main_store$fragmentType,
|};
export type main_store$key = {
  +$data?: main_store$data,
  +$fragmentSpreads: main_store$fragmentType,
  ...
};
*/

var node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  {
    "kind": "Variable",
    "name": "teamId",
    "variableName": "teamId"
  }
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "teamId"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "main_store",
  "selections": [
    (v0/*: any*/),
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "header_store"
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "id",
          "variableName": "teamId"
        }
      ],
      "concreteType": "team",
      "kind": "LinkedField",
      "name": "team",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "currentUser",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": (v1/*: any*/),
          "concreteType": "PricingPlan",
          "kind": "LinkedField",
          "name": "userPlan",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "amount",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "currency",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "nickname",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "monthly",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": (v1/*: any*/),
          "kind": "ScalarField",
          "name": "features",
          "storageKey": null
        },
        {
          "alias": "myFeatures",
          "args": null,
          "kind": "ScalarField",
          "name": "features",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "email",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "username",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "type",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "updateUsername_currentUser"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Store",
  "abstractKey": null
};
})();

(node/*: any*/).hash = "cc2eeb0bf6ef50fb1decd6cb8cda13b6";

module.exports = ((node/*: any*/)/*: Fragment<
  main_store$fragmentType,
  main_store$data,
>*/);
