import React, { Component, Fragment } from "react"
import { toast } from "react-toastify"
import lodash from "lodash"
import gqlHelper from "helpers/gql-helper"
import { createFragmentContainer, graphql } from "react-relay"
import BannerPhotoMutation from "mutations/user/banner-photo"
import ProfilePhotoMutation from "mutations/user/profile-photo"
import UpdateOrderTabsMutation from "mutations/user/update-order-tabs"
import UpdateLongBioMutation from "mutations/user/update-long-bio"
import SetUserPreviewConfig from "mutations/user/set-user-preview-config"
import SortList from "components/sort-list/sort-list"
import RichTextEditor from "components/richtext-editor/richtext-editor"
import ChannelEdit from "components/channel-edit/channel-edit"
import EditPreviewSharedControls from "./edit-preview-shared-controls"
import styles from "./edit-preview.css"

const defaultItems = [
	"Shopcasts",
	"Shopboards",
	"Files",
	"Collections",
	"About",
]

class EditPreviewProfile extends Component {
	constructor(props) {
		super(props)
		this.state = {}
		this.onBannerDrop = this.onBannerDrop.bind(this)
		this.onAvatarDrop = this.onAvatarDrop.bind(this)
		this.updateShowControls = this.updateShowControls.bind(this)
	}

	onBannerDrop(acceptedFiles) {
		let file = null
		if (acceptedFiles) [file] = acceptedFiles
		const { relay, currentUser, reloadIframe } = this.props

		BannerPhotoMutation.commit(
			relay.environment,
			file,
			currentUser.id,
			() => {
				toast.success(<>Banner updated</>, {
					autoClose: 2000,
					closeButton: false,
				})
				if (reloadIframe) reloadIframe()
			},
			() => {}
		)
	}

	onAvatarDrop(acceptedFiles) {
		let file = null
		if (acceptedFiles) [file] = acceptedFiles
		const { relay, currentUser, reloadIframe } = this.props
		ProfilePhotoMutation.commit(
			relay.environment,
			file,
			currentUser.id,
			true,
			() => {
				toast.success(<>Avatar updated</>, {
					autoClose: 2000,
					closeButton: false,
				})
				if (reloadIframe) reloadIframe()
			},
			() => {}
		)
	}

	updateOrderTabs(array) {
		if (array.length === 0) return
		const { relay, storeId, reloadIframe } = this.props
		UpdateOrderTabsMutation.commit(
			relay.environment,
			array,
			storeId,
			() => {
				toast.success(<>Tabs updated</>, {
					autoClose: 2000,
					closeButton: false,
				})
				if (reloadIframe) reloadIframe()
			},
			(e) => {
				toast.error(
					gqlHelper.getError(e) || "Error updating tabs.",
					{ autoClose: 2000, closeButton: false }
				)
			}
		)
	}

	updateLongBio(data) {
		const { relay, storeId, reloadIframe } = this.props
		UpdateLongBioMutation.commit(
			relay.environment,
			JSON.stringify(data),
			storeId,
			() => {
				toast.success(<>Updated successfully.</>, {
					autoClose: 2000,
					closeButton: false,
				})
				if (reloadIframe) reloadIframe()
			},
			(e) => {
				toast.error(gqlHelper.getError(e) || "Error updating bio.", {
					autoClose: 2000,
					closeButton: false,
				})
			}
		)
	}

	updateShowControls(id, value) {
		const { relay, reloadIframe } = this.props
		SetUserPreviewConfig.commit(
			relay.environment,
			[{ id, value }],
			() => {
				toast.success(<>Updated successfully.</>, {
					autoClose: 2000,
					closeButton: false,
				})
				if (reloadIframe) reloadIframe()
			},
			(e) => {
				toast.error(
					gqlHelper.getError(e) || "Error updating controls.",
					{
						autoClose: 2000,
						closeButton: false,
					}
				)
			}
		)
	}

	render() {
		const { currentUser, relay, storeId, reloadIframe } = this.props
		return (
			<>
				<EditPreviewSharedControls
					data={{
						bannerPhoto: currentUser.bannerPhoto,
						avatar: currentUser.displayAvatar,
						showBanner: currentUser.previewConfig.showBanner,
						showAvatar: currentUser.previewConfig.showAvatar,
					}}
					uploadBanner={this.onBannerDrop}
					uploadAvatar={this.onAvatarDrop}
					updateShowControls={this.updateShowControls}
				/>
				<div className={styles.hr} />
				<ChannelEdit
					user={currentUser}
					relay={relay}
					storeId={storeId}
					reloadIframe={reloadIframe}
					updateShowControls={this.updateShowControls}
				/>
				<div className={styles.hr} />
				<SortList
					textArray={
						currentUser.tabsOrder && currentUser.tabsOrder.length > 0
							? [
									...currentUser.tabsOrder.filter((item) =>
										defaultItems.includes(item)
									),
									...lodash.difference(
										defaultItems,
										currentUser.tabsOrder
									),
							  ]
							: defaultItems
					}
					dropCard={(array) => {
						this.updateOrderTabs(array)
					}}
					vertical
					previewConfig={currentUser.previewConfig}
					updateShowControls={this.updateShowControls}
				/>
				<div className={styles.hr} />
				{currentUser.previewConfig.showAboutSection && (
					<RichTextEditor
						readOnly={false}
						initialScript={
							currentUser.longBio
								? JSON.parse(currentUser.longBio)
								: [{ type: "paragraph", children: [{ text: "" }] }]
						}
						saveScript={(data) => this.updateLongBio(data)}
						updateShowControls={this.updateShowControls}
					/>
				)}
			</>
		)
	}
}

export default createFragmentContainer(EditPreviewProfile, {
	currentUser: graphql`
		fragment editPreviewProfile_currentUser on User {
			id
			email
			paypalEmail
			fullname
			displayName
			customDomain
			profilePhoto
			displayAvatar
			username
			bio
			isRetailer
			bannerPhoto
			longBio
			createdAt
			canEdit
			tabsOrder
			socialLinks {
				website
				instagramUsername
				facebookUrl
				twitterUsername
				youtubeUrl
				pinterestUrl
				tiktokUrl
			}
			previewConfig {
				showBanner
				showAvatar
				showContact
				showDisplayName
				showShortBio
				showWebsiteLink
				showInstagramLink
				showFacebookLink
				showTwitterLink
				showYoutubeLink
				showPinterestLink
				showTiktokLink
				showShopcastsSection
				showShopboardsSection
				showFilesSection
				showCollectionsSection
				showAboutSection
			}
		}
	`,
})
