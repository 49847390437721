/**
 * @generated SignedSource<<360e9c7e30b655ca6e2e860f9ff50451>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentType } from "relay-runtime";
declare export opaque type editPreviewClient_currentUser$fragmentType: FragmentType;
export type editPreviewClient_currentUser$data = {|
  +sharedFolderCollectionItems: ?{|
    +edges: ?$ReadOnlyArray<?{|
      +node: ?{|
        +id?: string,
      |},
    |}>,
  |},
  +sharedFolderFiles: ?{|
    +edges: ?$ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
      |},
    |}>,
  |},
  +sharedFolderLookbooks: ?{|
    +edges: ?$ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
      |},
    |}>,
  |},
  +sharedFolderShopcasts: ?{|
    +edges: ?$ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
      |},
    |}>,
  |},
  +shopcastContainer: ?{|
    +folder: ?{|
      +avatar: ?string,
      +bannerPhoto: ?string,
      +clients: ?$ReadOnlyArray<?{|
        +avatar: ?string,
        +email: ?string,
        +fullname: ?string,
        +id: string,
      |}>,
      +description: ?string,
      +displayName: ?string,
      +id: string,
      +isParentAClient: ?boolean,
      +isShared: ?boolean,
      +name: ?string,
      +previewConfig: ?{|
        +showAvatar: ?string,
        +showBanner: ?string,
        +showClosetTab: ?boolean,
        +showCollectionsSection: ?boolean,
        +showDescription: ?boolean,
        +showDisplayName: ?boolean,
        +showFilesSection: ?boolean,
        +showLookbookTab: ?boolean,
        +showShopboardsSection: ?boolean,
        +showShopcastsSection: ?boolean,
      |},
      +sharableLink: ?string,
    |},
    +id: string,
  |},
  +$fragmentType: editPreviewClient_currentUser$fragmentType,
|};
export type editPreviewClient_currentUser$key = {
  +$data?: editPreviewClient_currentUser$data,
  +$fragmentSpreads: editPreviewClient_currentUser$fragmentType,
  ...
};
*/

var node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "avatar",
  "storageKey": null
},
v2 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 1
  },
  {
    "kind": "Variable",
    "name": "folderId",
    "variableName": "folder"
  }
],
v3 = [
  (v0/*: any*/)
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "folder"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "editPreviewClient_currentUser",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ShopcastContainer",
      "kind": "LinkedField",
      "name": "shopcastContainer",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": [
            {
              "kind": "Variable",
              "name": "id",
              "variableName": "folder"
            }
          ],
          "concreteType": "ShopcastFolder",
          "kind": "LinkedField",
          "name": "folder",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "name",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "displayName",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "description",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "isShared",
              "storageKey": null
            },
            {
              "alias": null,
              "args": [
                {
                  "kind": "Literal",
                  "name": "noCheck",
                  "value": true
                }
              ],
              "kind": "ScalarField",
              "name": "sharableLink",
              "storageKey": "sharableLink(noCheck:true)"
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "isParentAClient",
              "storageKey": null
            },
            (v1/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "bannerPhoto",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "Client",
              "kind": "LinkedField",
              "name": "clients",
              "plural": true,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "fullname",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "email",
                  "storageKey": null
                },
                (v1/*: any*/)
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "FolderPreviewConfig",
              "kind": "LinkedField",
              "name": "previewConfig",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "showBanner",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "showAvatar",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "showDisplayName",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "showDescription",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "showLookbookTab",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "showClosetTab",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "showShopcastsSection",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "showShopboardsSection",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "showFilesSection",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "showCollectionsSection",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": (v2/*: any*/),
      "concreteType": "ShopcastConnection",
      "kind": "LinkedField",
      "name": "sharedFolderShopcasts",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ShopcastEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Shopcast",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": (v3/*: any*/),
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": (v2/*: any*/),
      "concreteType": "LookbookConnection",
      "kind": "LinkedField",
      "name": "sharedFolderLookbooks",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "LookbookEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Lookbook",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": (v3/*: any*/),
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": (v2/*: any*/),
      "concreteType": "ShopshareFileConnection",
      "kind": "LinkedField",
      "name": "sharedFolderFiles",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ShopshareFileEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ShopshareFile",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": (v3/*: any*/),
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": (v2/*: any*/),
      "concreteType": "ProductCollectionItemConnection",
      "kind": "LinkedField",
      "name": "sharedFolderCollectionItems",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ProductCollectionItemEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": null,
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "kind": "InlineFragment",
                  "selections": (v3/*: any*/),
                  "type": "ShopcastProduct",
                  "abstractKey": null
                },
                {
                  "kind": "InlineFragment",
                  "selections": (v3/*: any*/),
                  "type": "lookbookUploads",
                  "abstractKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "User",
  "abstractKey": null
};
})();

(node/*: any*/).hash = "7cdc5e27005b30c412c97ac1dad813d7";

module.exports = ((node/*: any*/)/*: Fragment<
  editPreviewClient_currentUser$fragmentType,
  editPreviewClient_currentUser$data,
>*/);
