/**
 * @generated SignedSource<<be28a6541f22964c877e38bd04256f31>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentType } from "relay-runtime";
declare export opaque type imageListCollectionSelect_store$fragmentType: FragmentType;
export type imageListCollectionSelect_store$data = {|
  +currentUser: ?{|
    +id: string,
    +productCollectionSearchItems: ?{|
      +collections: ?{|
        +edges: ?$ReadOnlyArray<?{|
          +node: ?{|
            +folder: ?{|
              +id: string,
              +name: ?string,
            |},
            +id: string,
            +isDefault: ?boolean,
            +title: ?string,
            +user: ?{|
              +fullname: ?string,
              +id: string,
            |},
          |},
        |}>,
      |},
      +defaultCollection: ?{|
        +folder: ?{|
          +id: string,
          +name: ?string,
        |},
        +id: string,
        +isDefault: ?boolean,
        +title: ?string,
        +user: ?{|
          +fullname: ?string,
          +id: string,
        |},
      |},
    |},
  |},
  +id: string,
  +$fragmentType: imageListCollectionSelect_store$fragmentType,
|};
export type imageListCollectionSelect_store$key = {
  +$data?: imageListCollectionSelect_store$data,
  +$fragmentSpreads: imageListCollectionSelect_store$fragmentType,
  ...
};
*/

var node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "kind": "Variable",
  "name": "teamId",
  "variableName": "teamId"
},
v2 = [
  (v0/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "title",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "isDefault",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "ShopcastFolder",
    "kind": "LinkedField",
    "name": "folder",
    "plural": false,
    "selections": [
      (v0/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "User",
    "kind": "LinkedField",
    "name": "user",
    "plural": false,
    "selections": [
      (v0/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "fullname",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": [],
      "kind": "LocalArgument",
      "name": "clients"
    },
    {
      "defaultValue": [
        "collections",
        "closet"
      ],
      "kind": "LocalArgument",
      "name": "limitType"
    },
    {
      "defaultValue": "",
      "kind": "LocalArgument",
      "name": "search"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "teamId"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "imageListCollectionSelect_store",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "currentUser",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": [
            {
              "kind": "Literal",
              "name": "brands",
              "value": []
            },
            {
              "kind": "Literal",
              "name": "categories",
              "value": []
            },
            {
              "kind": "Variable",
              "name": "clientIds",
              "variableName": "clients"
            },
            {
              "kind": "Literal",
              "name": "collections",
              "value": []
            },
            {
              "kind": "Variable",
              "name": "limitType",
              "variableName": "limitType"
            },
            {
              "kind": "Literal",
              "name": "resultTypes",
              "value": [
                "collections"
              ]
            },
            {
              "kind": "Variable",
              "name": "search",
              "variableName": "search"
            },
            {
              "kind": "Literal",
              "name": "sort",
              "value": "n-to-o"
            },
            (v1/*: any*/)
          ],
          "concreteType": "ProductCollectionProductSearch",
          "kind": "LinkedField",
          "name": "productCollectionSearchItems",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": [
                {
                  "kind": "Literal",
                  "name": "search",
                  "value": ""
                }
              ],
              "concreteType": "ProductCollectionConnection",
              "kind": "LinkedField",
              "name": "collections",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "ProductCollectionEdge",
                  "kind": "LinkedField",
                  "name": "edges",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "ProductCollection",
                      "kind": "LinkedField",
                      "name": "node",
                      "plural": false,
                      "selections": (v2/*: any*/),
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": "collections(search:\"\")"
            },
            {
              "alias": null,
              "args": [
                (v1/*: any*/)
              ],
              "concreteType": "ProductCollection",
              "kind": "LinkedField",
              "name": "defaultCollection",
              "plural": false,
              "selections": (v2/*: any*/),
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Store",
  "abstractKey": null
};
})();

(node/*: any*/).hash = "fe2fef0511af443cfefa6a8d0530f6f0";

module.exports = ((node/*: any*/)/*: Fragment<
  imageListCollectionSelect_store$fragmentType,
  imageListCollectionSelect_store$data,
>*/);
