/**
 * @generated SignedSource<<236c1ffbb1d71e9e7d73766d33c237f7>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Mutation } from 'relay-runtime';
export type ToggleRecommendedShopcastInput = {|
  clientMutationId?: ?string,
  shopcastId: string,
|};
export type toggleRecommendedShopcastMutation$variables = {|
  input: ToggleRecommendedShopcastInput,
|};
export type toggleRecommendedShopcastMutation$data = {|
  +toggleRecommendedShopcast: ?{|
    +output: ?string,
  |},
|};
export type toggleRecommendedShopcastMutation = {|
  response: toggleRecommendedShopcastMutation$data,
  variables: toggleRecommendedShopcastMutation$variables,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "ToggleRecommendedShopcastPayload",
    "kind": "LinkedField",
    "name": "toggleRecommendedShopcast",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "output",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "toggleRecommendedShopcastMutation",
    "selections": (v1/*: any*/),
    "type": "ShopshareMutationRoot",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "toggleRecommendedShopcastMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "4fe122a5bf178280d49f64b03f996c27",
    "id": null,
    "metadata": {},
    "name": "toggleRecommendedShopcastMutation",
    "operationKind": "mutation",
    "text": "mutation toggleRecommendedShopcastMutation(\n  $input: ToggleRecommendedShopcastInput!\n) {\n  toggleRecommendedShopcast(input: $input) {\n    output\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "16dc5d2907b4229109f5023dd6df33af";

module.exports = ((node/*: any*/)/*: Mutation<
  toggleRecommendedShopcastMutation$variables,
  toggleRecommendedShopcastMutation$data,
>*/);
