import React from "react"
import { Helmet } from "react-helmet"
import { createFragmentContainer, graphql } from "react-relay"
import LandingActions from "components/landing-actions/landing-actions"
// import TestimonialImage from "components/testimonial-image/testimonial-image"
import PageFooterCard from "components/page-footer-card/page-footer-card"
// import ListOfFeatures from "components/list-of-features/list-of-features"
import landing from "shared/styles/layout/landing.css"
import ScrollToTopOnMount from "components/scroll-to-top/scroll-to-top"
// import TickCircle from "shared/assets/tick-circle-icon.svg"
import Hero from "shared/assets/feature-images/hero-1.png"
import styles from "./shoppable-videos.css"

function ShoppableVideos({ store }) {
	const user = store.currentUser
	return (
		<div className={styles.root}>
			<Helmet>
				<title>ShopShareTV – Shoppable Videos</title>
			</Helmet>
			<ScrollToTopOnMount />

			<section className={landing.section}>
				<div className={landing.content}>
					<h1 className={styles.displayTitle}>Shopcasts</h1>
					<p className={landing.displayLead}>
						The easiest way to record and capture shoppable videos.
					</p>
					{!user ? (
						<div className={landing.actions}>
							<LandingActions />
						</div>
					) : null}
				</div>
			</section>

			<section className={landing.section}>
				<div className={landing.content}>
					<div className={styles.footer}>
						<PageFooterCard
							title="Shoppable videos made easy"
							lead="Start recording shoppable videos for clients to shop virtually."
							image={Hero}
						/>
					</div>
				</div>
			</section>
		</div>
	)
}

// eslint-disable-next-line no-func-assign
ShoppableVideos = createFragmentContainer(ShoppableVideos, {
	store: graphql`
		fragment shoppableVideos_store on Store {
			id
			currentUser {
				id
			}
		}
	`,
})

const query = graphql`
	query shoppableVideosQuery {
		store {
			...shoppableVideos_store
		}
	}
`

export default { Component: ShoppableVideos, query }
