import React from "react"
import { Helmet } from "react-helmet"
import { createFragmentContainer, graphql } from "react-relay"
import LandingActions from "components/landing-actions/landing-actions"
import landing from "shared/styles/layout/landing.css"
import ScrollToTopOnMount from "components/scroll-to-top/scroll-to-top"
import PageFooterCard from "components/page-footer-card/page-footer-card"
import Hero from "shared/assets/feature-images/hero-1.png"
import styles from "./moodboards.css"

function Moodboards({ store }) {
	const user = store.currentUser
	return (
		<div className={styles.root}>
			<Helmet>
				<title>ShopShare – Moodboards</title>
			</Helmet>
			<ScrollToTopOnMount />

			<section className={landing.section}>
				<div className={landing.content}>
					<h1 className={styles.displayTitle}>Shopboards</h1>
					<p className={landing.displayLead}>
						More than just a moodboard, Shopboards are like Canva, but
						shoppable!
					</p>
					{!user ? (
						<div className={landing.actions}>
							<LandingActions />
						</div>
					) : null}
				</div>
			</section>

			<section className={landing.section}>
				<div className={landing.content}>
					<div className={styles.footer}>
						<PageFooterCard
							title="Shoppable boards made easy"
							lead="Start creating shoppable moodboards for clients around the world."
							image={Hero}
						/>
					</div>
				</div>
			</section>
		</div>
	)
}

// eslint-disable-next-line no-func-assign
Moodboards = createFragmentContainer(Moodboards, {
	store: graphql`
		fragment moodboards_store on Store {
			id
			currentUser {
				id
			}
		}
	`,
})

const query = graphql`
	query moodboardsQuery {
		store {
			...moodboards_store
		}
	}
`

export default { Component: Moodboards, query }
