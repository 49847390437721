import { commitMutation, graphql } from "react-relay"
import { complete } from "../helper.js"

const mutation = graphql`
	mutation lookbookVideoPresignedMutation(
		$input: getVideoPresignedUrlMutationInput!
	) {
		getVideoPresignedUrl(input: $input) {
			preSignedUrl {
				signedRequest
                url
                file_name
			}
		}
	}
`

const commit = (env, lookbookId, extention, fileType, onCompleted, onError) => commitMutation(env, {
    mutation,
    variables: {
        input: {
            lookbookId,
            extention,
            fileType
        }
    },
    onCompleted: complete(onCompleted, onError),
    onError: (e) => complete(null, [e]),
})

export default { commit }