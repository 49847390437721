import React, { Component } from "react"
import { toast } from "react-toastify"
import { Cross2Icon, ExternalLinkIcon } from "@radix-ui/react-icons"
import Dropzone from "react-dropzone"
import Modal from "react-modal"
import ClipboardButton from "react-clipboard.js"
// import { isBackgroundRemovedImage } from "helpers/lookbook-helper"
import button from "shared/styles/components/buttons.css"
import icons from "shared/styles/components/icons.css"
import form from "shared/styles/components/form.css"
import file from "shared/styles/components/file.css"
import modal from "shared/styles/components/modal.css"
import textfield from "shared/styles/components/textfield.css"
import ImageCorousel from "components/image-corousel/image-corousel"
import AutoSuggest from "components/auto-suggest-new/auto-suggest"
import LoadingDots from "components/loading-dots/loading-dots"
import UploadFeatureIcon from "shared/assets/feature-icon-upload.svg"
import CopyIcon from "shared/assets/copy-icon.svg"
import UpdateProductMutation from "mutations/product/update-product"
import SetCollectionsForProduct from "mutations/product-collection/set-collections-for-product"
import productImageMutation from "mutations/product/product-image"
import ProductDeleteImageMutation from "mutations/product/delete-product-image"
// import removeImageBackgroundMutation from "mutations/lookbook/remove-image-background"
import styles from "./product-edit.css"
import { getChipClass } from "../../../helpers/ui-helper"

class ProductEdit extends Component {
	constructor(props) {
		super(props)
		this.saveProduct = this.saveProduct.bind(this)
		this.state = {
			productImageUrl: null,
			productImages: [],
			productCollections: [],
		}

		this.productSavedSuccess = this.productSavedSuccess.bind(this)
		this.productSavedFailed = this.productSavedFailed.bind(this)
		this.onPosterDrop = this.onPosterDrop.bind(this)
		this.setAsNewProductImageUrl = this.setAsNewProductImageUrl.bind(this)
		this.deleteProductImage = this.deleteProductImage.bind(this)
		// this.removeBackground = this.removeBackground.bind(this)
	}

	componentDidMount() {
		const { product } = this.props
		if (product) {
			if (product.diffbotImageUrl) {
				this.setState({ productImageUrl: product.diffbotImageUrl })
				this.setState({ productImages: product.productImages })
			}
		}
	}

	componentDidUpdate(prevProps) {
		const { product } = this.props
		if (product && product !== prevProps.product) {
			this.setState({
				productImageUrl: product.diffbotImageUrl,
				productImages: product.productImages,
				productCollections: product.productCollections.map((i) => ({
					id: i.id,
					value: i.title,
					className: getChipClass(i).chip,
				})),
			})
		}

		if (
			product &&
			prevProps.product &&
			product.productCollections !== prevProps.product.productCollections
		) {
			this.setState({
				productCollections: product.productCollections.map((i) => ({
					id: i.id,
					value: i.title,
					className: getChipClass(i).chip,
				})),
			})
		}
	}

	onPosterDrop(acceptedFiles) {
		const { relay, product } = this.props
		const fileObj = acceptedFiles[0]
		if (fileObj) {
			productImageMutation.commit(
				relay.environment,
				fileObj,
				product,
				(images, url) => {
					this.setState({ productImages: images, productImageUrl: url })
				},
				() => {}
			)
		}
	}

	setAsNewProductImageUrl(imgUrl) {
		if (this.productImageUrl) {
			this.productImageUrl.value = null
		}
		this.setState({ productImageUrl: imgUrl })
	}

	clipboardCopySuccess() {
		toast.success(<>Copied</>, {
			autoClose: 2000,
			closeButton: false,
		})
	}

	saveProduct(e) {
		e.preventDefault()
		if (!this.form.reportValidity()) return
		let productImageUrl = null
		const { state, props } = this
		const { productCollections } = state
		const {
			store,
			product,
			relay,
			collectionsKeyValue,
			enableSavingWithoutCollection,
			other,
		} = props
		if (
			collectionsKeyValue &&
			productCollections.length === 0 &&
			!enableSavingWithoutCollection
		) {
			toast.error(<>You are required to have at least one collection.</>, {
				autoClose: 2000,
				closeButton: false,
			})
			return
		}

		if (
			this.productImageUrl &&
			this.productImageUrl.value &&
			this.productImageUrl.value.trim() !== ""
		) {
			productImageUrl = this.productImageUrl.value
		}

		if (!productImageUrl && state.productImageUrl) {
			;({ productImageUrl } = state)
		}

		const productToSave = {
			id: store.id,
			productId: product.id,
			imageUrl: productImageUrl,
			affiliated_url: this.affiliated_url.value,
		}

		if (this.regularPrice) {
			productToSave.regularPrice = this.regularPrice.value
			productToSave.offerPrice = this.offerPrice.value
			productToSave.title = this.title.value
			productToSave.brand = this.brand.value
		}

		this.setState({ saving: true })

		const updateProductPromise = new Promise((resolve, reject) => {
			UpdateProductMutation.commit(
				relay.environment,
				productToSave,
				store.id,
				() => resolve(),
				() => reject()
			)
		})
		const setCollectionsPromise = new Promise((resolve, reject) => {
			SetCollectionsForProduct.commit(
				relay.environment,
				[
					{
						id: product.id,
						value: productCollections.map((i) => ({
							value: i.value,
							id: i.id.includes("new_") ? null : i.id,
						})),
					},
				],
				store.currentUser.id,
				() => reject(),
				() => resolve(),
				other
			)
		})
		const data = [updateProductPromise, setCollectionsPromise]

		Promise.all(data)
			.then(() => {
				this.productSavedSuccess()
			})
			.catch(() => {
				this.productSavedFailed()
			})
	}

	productSavedSuccess() {
		const { closeProductEditModal, refetchData } = this.props
		this.setState({ saving: false })
		closeProductEditModal()
		toast.success(<>Successfully Updated</>, {
			autoClose: 2000,
			closeButton: false,
		})
		if (refetchData) refetchData()
	}

	productSavedFailed() {
		this.setState({ saving: false })
		toast.error(<>Error Updating Product</>, {
			autoClose: 2000,
			closeButton: false,
		})
	}

	deleteProductImage(imgUrl) {
		const { relay, product, refetchData } = this.props
		ProductDeleteImageMutation.commit(
			relay.environment,
			imgUrl,
			product.id,
			(data) => {
				const { images, productImage } = data.deleteProductImage
				toast.success(<>Product Image Deleted</>, {
					autoClose: 2000,
					closeButton: false,
				})
				this.setState({
					productImages: images,
					productImageUrl: productImage,
				})
				if (refetchData) refetchData()
			},
			() => {}
		)
	}

	// removeBackground(imgUrl) {
	// 	const { product, relay } = this.props
	// 	const { productImages } = this.state
	// 	const imageUrl = new URL(imgUrl)
	// 	this.setState({ imageEditing: imgUrl, saving: true })
	// 	const returnFunc = () => {
	// 		this.setState({ imageEditing: false, saving: false })
	// 	}

	// 	const successFunc = (updatedImg) => {
	// 		const { imageEditing } = this.state
	// 		toast.success(
	// 			isBackgroundRemovedImage(imgUrl)
	// 					? "Image Restored"
	// 					: "Background Removed",
	// 			{
	// 				autoClose: 2000,
	// 				closeButton: false,
	// 			}
	// 		)
	// 		const productImagesUpdated = [...productImages]
	// 		const newImageIndex = productImagesUpdated.indexOf(updatedImg)
	// 		if (newImageIndex >= 0) {
	// 			productImagesUpdated.splice(newImageIndex, 1)
	// 		}
	// 		const imageIndex = productImagesUpdated.indexOf(imageEditing)
	// 		if (imageIndex >= 0) {
	// 			productImagesUpdated[imageIndex] = updatedImg
	// 		} else {
	// 			productImagesUpdated.push(updatedImg)
	// 		}
	// 		this.setState({
	// 			productImageUrl: updatedImg,
	// 			productImages: productImagesUpdated,
	// 		})
	// 		returnFunc()
	// 	}

	// 	const errorFunc = () => {
	// 		toast.error(
	// 			isBackgroundRemovedImage(imgUrl)
	// 					? "Image Restore Failed"
	// 					: "Background Remove Failed",
	// 			{
	// 				autoClose: 2000,
	// 				closeButton: false,
	// 			}
	// 		)
	// 		returnFunc()
	// 	}

	// 	removeImageBackgroundMutation.commit(
	// 		relay.environment,
	// 		`${imageUrl.origin}${imageUrl.pathname}`,
	// 		"product",
	// 		product.id,
	// 		isBackgroundRemovedImage(imgUrl),
	// 		async ({ removeImageBackground }) => {
	// 			if (
	// 				removeImageBackground &&
	// 				removeImageBackground.output &&
	// 				removeImageBackground.output.value
	// 			) {
	// 				successFunc(removeImageBackground.output.value)
	// 			} else {
	// 				errorFunc()
	// 			}
	// 		},
	// 		() => errorFunc()
	// 	)
	// }

	renderPosterUpload(shopcast) {
		return (
			<Dropzone
				multiple={false}
				onDrop={(acceptedFiles, rejectedFiles) =>
					this.onPosterDrop(acceptedFiles, rejectedFiles, shopcast)
				}
			>
				{({ getRootProps, getInputProps }) => (
					<div className={file.root}>
						<div {...getRootProps()} className={file.dropRect}>
							<input {...getInputProps()} />
							<UploadFeatureIcon className={file.iconLg} />
							<p className={file.label}>
								<b>Click to upload</b> or drag and drop
							</p>
						</div>
					</div>
				)}
			</Dropzone>
		)
	}

	renderImageCarousel() {
		const { state, props } = this
		const { productImages, imageEditing } = state
		const currentProductUrl =
			state.productImageUrl || props.product.diffbotImageUrl
		if (productImages && productImages.length > 0) {
			return (
				<ImageCorousel
					productImages={productImages}
					currentProductUrl={currentProductUrl}
					diffbotImageUrl={props.product.diffbotImageUrl}
					setDefaultImage={this.setAsNewProductImageUrl}
					deleteImage={this.deleteProductImage}
					// removeBackground={this.removeBackground}
					imageEditing={imageEditing}
				/>
			)
		}
		return false
	}

	// renderAddProductImageUrl() {
	// 	const { product } = this.props
	// 	const { productImages } = this.state
	// 	if (!productImages || productImages.length < 2) {
	// 		return (
	// 			<div className={form.group}>
	// 				<label className={form.label}>Product Image URL</label>
	// 				<input
	// 					type="text"
	// 					className={textfield.normal}
	// 					defaultValue={
	// 						product.diffbotImageUrl ? product.diffbotImageUrl : ""
	// 					}
	// 					ref={(i) => {
	// 						this.productImageUrl = i
	// 					}}
	// 				/>
	// 			</div>
	// 		)
	// 	}
	// 	return false
	// }

	render() {
		const { product, closeProductEditModal, collectionsKeyValue } = this.props
		if (!product) return false

		const { productImages, saving, productCollections } = this.state

		return (
			<Modal
				className={modal.root}
				overlayClassName={modal.overlay}
				htmlOpenClassName="ReactModal__Html--open"
				isOpen
				ariaHideApp={false}
			>
				<div
					className={`${modal.dialog} ${modal.dialogCentered} ${modal.dialogFullscreenSmDown} ${modal.dialogLg}`}
				>
					<div className={modal.content}>
						<div className={modal.header}>
							<h4 className={modal.title}>Edit Product</h4>
							<div className={modal.headerActions}>
								<a
									className={button.subtleIcon}
									href={product.url}
									target="_blank"
									rel="noopener noreferrer"
									data-tooltip-id="tooltip"
									data-tooltip-content="View Product"
								>
									<ExternalLinkIcon className={icons.icon21} />
								</a>
								<button
									type="button"
									className={button.close}
									onClick={closeProductEditModal}
								>
									<Cross2Icon className={button.closeIcon} />
								</button>
							</div>
						</div>

						<div className={modal.body}>
							<div className={styles.row}>
								<div className={styles.col}>
									{(!productImages || !productImages.length) && (
										<div className={form.group}>
											<label className={form.label}>
												Upload Image
											</label>
											<div className={file.root}>
												{this.renderPosterUpload()}
											</div>
										</div>
									)}
									{productImages && productImages.length > 0 && (
										<div className={form.group}>
											<label className={form.label}>
												Hero Image
											</label>
											{this.renderImageCarousel()}
										</div>
									)}
									{productImages && productImages.length > 0 && (
										<div className={form.group}>
											<div className={file.root}>
												{this.renderPosterUpload()}
											</div>
										</div>
									)}
								</div>
								<div className={styles.col}>
									<form
										name="updateproduct"
										ref={(i) => {
											this.form = i
										}}
									>
										<div className={form.group}>
											<label className={form.label}>Title</label>
											<input
												type="text"
												className={textfield.normal}
												defaultValue={product.title}
												required
												ref={(i) => {
													this.title = i
												}}
											/>
										</div>

										<div className={form.group}>
											<label className={form.label}>Brand</label>
											<input
												type="text"
												className={textfield.normal}
												defaultValue={product.brand}
												ref={(i) => {
													this.brand = i
												}}
											/>
										</div>

										<div className={form.row}>
											<div className={form.group}>
												<label className={form.label}>Price</label>
												<input
													type="text"
													className={textfield.normal}
													defaultValue={
														product.regularPrice
															? product.regularPrice
															: ""
													}
													ref={(i) => {
														this.regularPrice = i
													}}
												/>
											</div>

											<div className={form.group}>
												<label className={form.label}>
													Offer Price
												</label>
												<input
													type="text"
													className={textfield.normal}
													defaultValue={
														product.offerPrice
															? product.offerPrice
															: ""
													}
													ref={(i) => {
														this.offerPrice = i
													}}
												/>
											</div>
										</div>

										{product.affiliated_url !== product.url &&
											product.affiliated_url !==
												product.custom_affiliated_url && (
												<div className={form.group}>
													<label className={form.label}>
														ShopShare Affiliate Link
													</label>
													<div className={form.inputGroup}>
														<input
															type="url"
															className={textfield.normal}
															readOnly
															defaultValue={
																product.affiliated_url
															}
														/>
														<ClipboardButton
															className={styles.btnCopy}
															data-clipboard-text={
																product.affiliated_url
															}
															onSuccess={
																this.clipboardCopySuccess
															}
														>
															<CopyIcon
																className={icons.icon18}
															/>
														</ClipboardButton>
													</div>
												</div>
											)}

										<div className={form.group}>
											<label className={form.label}>
												Custom Affiliate Link
											</label>

											<input
												type="url"
												placeholder="Use your own affiliate link"
												className={textfield.normal}
												defaultValue={product.custom_affiliated_url}
												ref={(i) => {
													this.affiliated_url = i
												}}
											/>
										</div>
										{/* {this.renderAddProductImageUrl()} */}
									</form>
									{collectionsKeyValue && (
										<div className={form.group}>
											<label className={form.label}>
												Collections
											</label>
											<AutoSuggest
												result={productCollections}
												dataList={collectionsKeyValue}
												onChange={(result) => {
													this.setState({
														productCollections: result,
													})
												}}
											/>
										</div>
									)}
								</div>
							</div>
						</div>

						<div className={modal.footer}>
							<button
								type="button"
								disabled={saving}
								className={button.link}
								onClick={closeProductEditModal}
							>
								{saving ? (
									<LoadingDots color="var(--gray300)" />
								) : (
									"Cancel"
								)}
							</button>
							<button
								disabled={saving}
								type="submit"
								className={button.primary}
								onClick={this.saveProduct}
							>
								{saving ? (
									<LoadingDots color="var(--primary80)" />
								) : (
									"Save"
								)}
							</button>
						</div>
					</div>
				</div>
			</Modal>
		)
	}
}

ProductEdit.defaultProps = {
	name: null,
}

export default ProductEdit
