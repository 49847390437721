import { commitMutation, graphql } from "react-relay"
import { complete } from "../helper"

const mutation = graphql`
	mutation createShopcastMutation($input: CreateShopcastInput!) {
		createShopcast(input: $input) {
			shopcast {
				id
				...userShopcastCard_shopcast
			}
			store {
				id
			}
		}
	}
`

const commit = (env, data, onError, onCompleted) =>
	commitMutation(env, {
		mutation,
		variables: { input: data },
		onError: complete(onCompleted, onError),
		onCompleted: complete(onCompleted, onError),
	})

export default { commit }
