import React from "react"
import { Link, Navigate, Outlet, useLocation } from "react-router-dom"
import { createFragmentContainer, graphql } from "react-relay"
import nav from "shared/styles/components/nav.css"
import twoColumn from "shared/styles/layout/two-column.css"
// import PayoutsReport from "components/reporting/payouts-report/payouts-report"
// import AnalyticsReport from "components/reporting/analytics-report/analytics-report"
import { getActiveTabClass } from "../../helpers/ui-helper"

function ReportingComponent({ store }) {
	const location = useLocation()
	if (store.currentUser.isRetailer) return <Navigate to="/" />
	return (
		<div className={twoColumn.root}>
			<aside className={twoColumn.side}>
				<div className={twoColumn.fixed}>
					<div className={twoColumn.fillHeight}>
						<div className={twoColumn.scrollPanel}>
							<div className={twoColumn.sideMain}>
								<nav className={nav.pills}>
									<Link
										className={getActiveTabClass(
											location.pathname === "/reporting/sales",
											nav.pill,
											nav.pillActive
										)}
										to="/reporting/sales"
										tabIndex={0}
										role="link"
									>
										Commissions
									</Link>
									{/* <Link
										className={getActiveTabClass(
											location.pathname === "/reporting/payouts",
											nav.pill,
											nav.pillActive
										)}
										to="/reporting/payouts"
										tabIndex={0}
										role="link"
									>
										Referral
									</Link>
									<Link
										className={getActiveTabClass(
											location.pathname === "/reporting/analytics",
											nav.pill,
											nav.pillActive
										)}
										to="/reporting/analytics"
										tabIndex={0}
										role="link"
									>
										Shopcasts
									</Link> */}
								</nav>
							</div>
						</div>
					</div>
				</div>
			</aside>
			<div className={twoColumn.main}>
				<Outlet />
			</div>
		</div>
	)
}

const Reporting = createFragmentContainer(ReportingComponent, {
	store: graphql`
		fragment reporting_store on Store {
			id
			currentUser {
				isRetailer
			}
		}
	`,
})

const query = graphql`
	query reportingQuery {
		store {
			...reporting_store
		}
	}
`
export default { Component: Reporting, query, needAuth: true }
