import React from "react"
import { Transition } from "react-transition-group"
import { createFragmentContainer, graphql } from "react-relay"
import vTime from "video-time"
import { ShowCardControlsComponent } from "helpers/ui-helper"
import card from "shared/styles/components/card.css"
import button from "shared/styles/components/buttons.css"
import Avatar from "components/profile/avatar/avatar.jsx"
import PinButton from "components/buttons/pin-button"
// import Views from "components/views/views.jsx"
import PlayIcon from "shared/assets/play-icon.svg"
import DropdownMenu from "components/dropdown-menu/dropdown-menu.jsx"
import ToggleRecommendedShopcastMutation from "mutations/shopcast/toggle-recommended-shopcast.js"
import { baseFrontendUrl, publicViewDomain } from "constants"
import styles from "./shopcast-card.css"

export class ShopcastCard extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			isDropdownOpen: false,
		}
		this.showReportModal = this.showReportModal.bind(this)
	}

	showReportModal(...args) {
		const { props } = this
		const { showReportModal } = props
		showReportModal(...args)
	}

	toggleRecommended() {
		const { relay, shopcast, refetch } = this.props
		ToggleRecommendedShopcastMutation.commit(
			relay.environment,
			shopcast.id,
			() => {
				if (refetch) {
					refetch(12)
				}
			}
		)
	}

	renderPlay(shopcast) {
		const { disableSubdomain, clientId } = this.props
		const domain = disableSubdomain
			? baseFrontendUrl.replace("https://", "")
			: shopcast.user.customDomain ||
			  `${shopcast.user.username}.${publicViewDomain}`
		const qp = clientId ? `?clientId=${clientId}` : ""
		return (
			<div className={styles.figure}>
				<a href={`https://${domain}/shopcast/${shopcast.id}${qp}`}>
					<div className={styles.inner}>
						<img
							className={styles.poster}
							loading="lazy"
							src={`${shopcast.posterUrl}`}
							alt="Poster"
						/>
						<button className={card.play} type="button">
							<PlayIcon className={card.playIcon} />
						</button>
					</div>
				</a>
			</div>
		)
	}

	renderMenu() {
		const listItems = []
		const { shopcast, currentUser } = this.props
		const push = (click, name) => listItems.push({ click, name })
		if (currentUser && currentUser.adminAccess === "HAS") {
			if (shopcast.isRecommended) {
				push(() => {
					this.toggleRecommended()
				}, "Remove from Featured")
			} else {
				push(() => {
					this.toggleRecommended()
				}, "Add to Featured")
			}
		}
		if (!currentUser || currentUser.id !== shopcast.user.id) {
			push(() => {
				this.showReportModal(true, { ...shopcast, type: "shopcast" })
			}, "Report Shopcast")
		}
		return listItems.length ? (
			<DropdownMenu
				items={listItems}
				darkTheme
				contentRight
				triggerClass={button.lightIcon}
				triggerClassActive={button.lightIconActive}
				setDropdownOpened={(val) => {
					this.setState({ isDropdownOpen: val })
				}}
			/>
		) : null
	}

	render() {
		const { shopcast, enableHeader, noMenu } = this.props
		const { isDropdownOpen } = this.state
		// const isAdmin = currentUser && currentUser.adminAccess === "HAS"
		const domain =
			shopcast.user.customDomain ||
			`${shopcast.user.username}.${publicViewDomain}`
		return (
			<ShowCardControlsComponent className={styles.root}>
				{(isShown) => (
					<>
						{enableHeader && (
							<div className={card.header}>
								<a
									className={card.userLink}
									href={`https://${domain}`}
									target="_blank"
									rel="noreferrer"
								>
									<Avatar
										size="32px"
										photoUrl={shopcast.user.profilePhoto}
										fullname={shopcast.user.fullname}
										imageSize="100"
									/>
									<div className={card.user}>
										<span className={card.fullname}>
											{shopcast.user.fullname}
										</span>
									</div>
								</a>
							</div>
						)}
						<div className={styles.media}>
							<div className={styles.pin}>
								<PinButton isPinned={shopcast.isPinned} />
							</div>
							{!noMenu && (
								<Transition
									in={isShown || isDropdownOpen}
									timeout={200}
								>
									{(state) => (
										<div
											className={`${styles.menu} ${styles[state]}`}
										>
											{this.renderMenu()}
										</div>
									)}
								</Transition>
							)}
							{/* <Transition
								in={isShown || (isDropdownOpen && !isAdmin)}
								timeout={200}
							>
								{(state) => (
									<div className={`${styles.views} ${styles[state]}`}>
										<Views viewsCount={shopcast.views} size="sm" />
									</div>
								)}
							</Transition> */}
							{this.renderPlay(shopcast)}
						</div>
						<div className={styles.content}>
							<h2 className={styles.title}>{shopcast.title}</h2>
							<div className={styles.subtitle}>
								{vTime(shopcast.duration)}
							</div>
						</div>
					</>
				)}
			</ShowCardControlsComponent>
		)
	}
}

ShopcastCard = createFragmentContainer(ShopcastCard, {
	currentUser: graphql`
		fragment shopcastCard_currentUser on User {
			id
			adminAccess
		}
	`,
	shopcast: graphql`
		fragment shopcastCard_shopcast on Shopcast {
			id
			title
			posterUrl
			isPublished
			publishedDate
			transcoded
			views
			likes
			duration
			isFlagged
			isPinned
			isRecommended
			youtubeUploadStatus
			category {
				name
			}
			shopcastItems(first: 100) {
				edges {
					node {
						id
						start
						end
						product {
							id
							imageUrl
						}
					}
				}
			}
			user {
				id
				fullname
				username
				profilePhoto
				customDomain
				canEdit
			}
		}
	`,
})

export default ShopcastCard
