/**
 * @generated SignedSource<<ea5cc4f30fd6f3eba9cdc86759328904>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Mutation } from 'relay-runtime';
export type TrackProductViewInput = {|
  clientMutationId?: ?string,
  productId?: ?string,
|};
export type trackProductViewMutation$variables = {|
  input: TrackProductViewInput,
|};
export type trackProductViewMutation$data = {|
  +trackProductView: ?{|
    +store: ?{|
      +id: string,
    |},
  |},
|};
export type trackProductViewMutation = {|
  response: trackProductViewMutation$data,
  variables: trackProductViewMutation$variables,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "TrackProductViewPayload",
    "kind": "LinkedField",
    "name": "trackProductView",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Store",
        "kind": "LinkedField",
        "name": "store",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "trackProductViewMutation",
    "selections": (v1/*: any*/),
    "type": "ShopshareMutationRoot",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "trackProductViewMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "01547cf0e7fb138e0985f72042bae339",
    "id": null,
    "metadata": {},
    "name": "trackProductViewMutation",
    "operationKind": "mutation",
    "text": "mutation trackProductViewMutation(\n  $input: TrackProductViewInput!\n) {\n  trackProductView(input: $input) {\n    store {\n      id\n    }\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "cdcf38c0ed55a70e85ad44bf7c4d767d";

module.exports = ((node/*: any*/)/*: Mutation<
  trackProductViewMutation$variables,
  trackProductViewMutation$data,
>*/);
