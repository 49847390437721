/**
 * @generated SignedSource<<e9d371293a95aed06943dfe9c8372ac5>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentType } from "relay-runtime";
declare export opaque type clientViewCollections_store$fragmentType: FragmentType;
export type clientViewCollections_store$data = {|
  +currentUser: ?{|
    +id: string,
    +sharedFolderCollectionItems: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?({|
          +__typename: "ShopcastProduct",
          +affiliated_url: ?string,
          +brand: ?string,
          +description: ?string,
          +diffbotImageUrl: ?string,
          +id: string,
          +imageUrl: ?string,
          +isOwnedItems: ?boolean,
          +offerPrice: ?string,
          +productCollections: ?$ReadOnlyArray<?{|
            +id: string,
            +title: ?string,
          |}>,
          +regularPrice: ?string,
          +shopcastItems: ?$ReadOnlyArray<?{|
            +end: ?number,
            +id: string,
            +shopcast: ?{|
              +id: string,
              +title: ?string,
            |},
            +start: ?number,
          |}>,
          +title: ?string,
          +url: ?string,
          +user: ?{|
            +customDomain: ?string,
            +fullname: ?string,
            +id: string,
            +profilePhoto: ?string,
            +username: ?string,
          |},
        |} | {|
          +__typename: "lookbookUploads",
          +adminAccess: ?boolean,
          +id: string,
          +productCollections: ?$ReadOnlyArray<?{|
            +id: string,
            +title: ?string,
          |}>,
          +title: ?string,
          +url: ?string,
        |} | {|
          // This will never be '%other', but we need some
          // value in case none of the concrete values match.
          +__typename: "%other",
        |}),
      |}>,
    |},
    +sharedFolderCollectionsList: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +title: ?string,
        |},
      |}>,
    |},
  |},
  +id: string,
  +$fragmentType: clientViewCollections_store$fragmentType,
|};
export type clientViewCollections_store$key = {
  +$data?: clientViewCollections_store$data,
  +$fragmentSpreads: clientViewCollections_store$fragmentType,
  ...
};
*/

var node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "kind": "Variable",
  "name": "folderId",
  "variableName": "folder"
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v3 = [
  (v0/*: any*/),
  (v2/*: any*/)
],
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "ProductCollection",
  "kind": "LinkedField",
  "name": "productCollections",
  "plural": true,
  "selections": (v3/*: any*/),
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "url",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "clientId"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "collectionId"
    },
    {
      "defaultValue": 12,
      "kind": "LocalArgument",
      "name": "count"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "cursor"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "folder"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "searchText"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "sort"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "count",
        "cursor": "cursor",
        "direction": "forward",
        "path": [
          "currentUser",
          "sharedFolderCollectionItems"
        ]
      }
    ]
  },
  "name": "clientViewCollections_store",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "currentUser",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": [
            (v1/*: any*/)
          ],
          "concreteType": "ProductCollectionConnection",
          "kind": "LinkedField",
          "name": "sharedFolderCollectionsList",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ProductCollectionEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "ProductCollection",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": (v3/*: any*/),
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": "sharedFolderCollectionItems",
          "args": [
            {
              "kind": "Variable",
              "name": "collectionId",
              "variableName": "collectionId"
            },
            (v1/*: any*/),
            {
              "kind": "Variable",
              "name": "searchText",
              "variableName": "searchText"
            },
            {
              "kind": "Variable",
              "name": "sort",
              "variableName": "sort"
            }
          ],
          "concreteType": "ProductCollectionItemConnection",
          "kind": "LinkedField",
          "name": "__currentUser_sharedFolderCollectionItems_connection",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ProductCollectionItemEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": null,
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "__typename",
                      "storageKey": null
                    },
                    {
                      "kind": "InlineFragment",
                      "selections": [
                        (v0/*: any*/),
                        (v2/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "brand",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "description",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "imageUrl",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "diffbotImageUrl",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "regularPrice",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "offerPrice",
                          "storageKey": null
                        },
                        (v4/*: any*/),
                        (v5/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "affiliated_url",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "User",
                          "kind": "LinkedField",
                          "name": "user",
                          "plural": false,
                          "selections": [
                            (v0/*: any*/),
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "fullname",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "username",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "profilePhoto",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "customDomain",
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "shopcastItem",
                          "kind": "LinkedField",
                          "name": "shopcastItems",
                          "plural": true,
                          "selections": [
                            (v0/*: any*/),
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "Shopcast",
                              "kind": "LinkedField",
                              "name": "shopcast",
                              "plural": false,
                              "selections": (v3/*: any*/),
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "start",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "end",
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": [
                            {
                              "kind": "Variable",
                              "name": "clientId",
                              "variableName": "clientId"
                            }
                          ],
                          "kind": "ScalarField",
                          "name": "isOwnedItems",
                          "storageKey": null
                        }
                      ],
                      "type": "ShopcastProduct",
                      "abstractKey": null
                    },
                    {
                      "kind": "InlineFragment",
                      "selections": [
                        (v0/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "adminAccess",
                          "storageKey": null
                        },
                        (v2/*: any*/),
                        (v4/*: any*/),
                        (v5/*: any*/)
                      ],
                      "type": "lookbookUploads",
                      "abstractKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "cursor",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "PageInfo",
              "kind": "LinkedField",
              "name": "pageInfo",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "endCursor",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "hasNextPage",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Store",
  "abstractKey": null
};
})();

(node/*: any*/).hash = "022aa6e0e39f3ddd6d655dee08ef1b45";

module.exports = ((node/*: any*/)/*: Fragment<
  clientViewCollections_store$fragmentType,
  clientViewCollections_store$data,
>*/);
