/**
 * @generated SignedSource<<8b0f6306f8b484a626bd2529d19d0020>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Mutation } from 'relay-runtime';
export type UpdateProductInput = {|
  affiliated_url?: ?string,
  brand?: ?string,
  clientMutationId?: ?string,
  imageUrl?: ?string,
  offerPrice?: ?string,
  productId?: ?string,
  regularPrice?: ?string,
  title?: ?string,
|};
export type updateProductMutation$variables = {|
  input: UpdateProductInput,
|};
export type updateProductMutation$data = {|
  +updateProduct: ?{|
    +product: ?{|
      +affiliated_url: ?string,
      +brand: ?string,
      +custom_affiliated_url: ?string,
      +diffbotImageUrl: ?string,
      +imageUrl: ?string,
      +offerPrice: ?string,
      +productImages: ?$ReadOnlyArray<?string>,
      +regularPrice: ?string,
      +title: ?string,
    |},
    +store: ?{|
      +id: string,
      +shopcast: ?{|
        +id: string,
      |},
    |},
  |},
|};
export type updateProductMutation = {|
  response: updateProductMutation$data,
  variables: updateProductMutation$variables,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "Store",
  "kind": "LinkedField",
  "name": "store",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Shopcast",
      "kind": "LinkedField",
      "name": "shopcast",
      "plural": false,
      "selections": [
        (v2/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "regularPrice",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "offerPrice",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "custom_affiliated_url",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "affiliated_url",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "diffbotImageUrl",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "imageUrl",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "productImages",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "brand",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "updateProductMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateProductPayload",
        "kind": "LinkedField",
        "name": "updateProduct",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "ShopcastProduct",
            "kind": "LinkedField",
            "name": "product",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/),
              (v12/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "ShopshareMutationRoot",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "updateProductMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateProductPayload",
        "kind": "LinkedField",
        "name": "updateProduct",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "ShopcastProduct",
            "kind": "LinkedField",
            "name": "product",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/),
              (v12/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "48334811b56cf15c74008606362b8e4d",
    "id": null,
    "metadata": {},
    "name": "updateProductMutation",
    "operationKind": "mutation",
    "text": "mutation updateProductMutation(\n  $input: UpdateProductInput!\n) {\n  updateProduct(input: $input) {\n    store {\n      id\n      shopcast {\n        id\n      }\n    }\n    product {\n      title\n      regularPrice\n      offerPrice\n      custom_affiliated_url\n      affiliated_url\n      diffbotImageUrl\n      imageUrl\n      productImages\n      brand\n      id\n    }\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "c6baffc920485f4113c0305f93275055";

module.exports = ((node/*: any*/)/*: Mutation<
  updateProductMutation$variables,
  updateProductMutation$data,
>*/);
