/**
 * @generated SignedSource<<d86b2b890c64f627bd4ba4fee5905857>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Mutation } from 'relay-runtime';
export type DeleteLookbookVideoInput = {|
  boardId: string,
  clientMutationId?: ?string,
|};
export type deleteLookbookVideoMutation$variables = {|
  input: DeleteLookbookVideoInput,
|};
export type deleteLookbookVideoMutation$data = {|
  +deleteLookbookVideo: ?{|
    +lookbookBoard: ?{|
      +id: ?string,
    |},
  |},
|};
export type deleteLookbookVideoMutation = {|
  response: deleteLookbookVideoMutation$data,
  variables: deleteLookbookVideoMutation$variables,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "DeleteLookbookVideoPayload",
    "kind": "LinkedField",
    "name": "deleteLookbookVideo",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "LookbookBoard",
        "kind": "LinkedField",
        "name": "lookbookBoard",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "deleteLookbookVideoMutation",
    "selections": (v1/*: any*/),
    "type": "ShopshareMutationRoot",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "deleteLookbookVideoMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "8298cfdbb2eecd797e129f324ebcc089",
    "id": null,
    "metadata": {},
    "name": "deleteLookbookVideoMutation",
    "operationKind": "mutation",
    "text": "mutation deleteLookbookVideoMutation(\n  $input: DeleteLookbookVideoInput!\n) {\n  deleteLookbookVideo(input: $input) {\n    lookbookBoard {\n      id\n    }\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "2008ae8a6b8b123122dc1d08538b90c4";

module.exports = ((node/*: any*/)/*: Mutation<
  deleteLookbookVideoMutation$variables,
  deleteLookbookVideoMutation$data,
>*/);
