import React from "react"
// import { MagicWandIcon } from "@radix-ui/react-icons"
import StyleMeIcon from "shared/assets/style-me-icon.svg"
import styles from "./style-magic-button.css"

function StyleMagicButton({ onClick }) {
	return (
		<button className={styles.root} type="button" onClick={onClick}>
			<StyleMeIcon className={styles.icon} />
		</button>
	)
}

export default StyleMagicButton
