/**
 * @generated SignedSource<<0ec73dec17bf2512728546ede1ff987b>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentType } from "relay-runtime";
declare export opaque type retailers_store$fragmentType: FragmentType;
export type retailers_store$data = {|
  +bestCategories: ?{|
    +edges: ?$ReadOnlyArray<?{|
      +node: ?{|
        +count: ?number,
        +id: string,
        +name: ?string,
      |},
    |}>,
  |},
  +id: string,
  +recommendedRetailers: ?{|
    +edges: ?$ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
      |},
    |}>,
  |},
  +$fragmentType: retailers_store$fragmentType,
|};
export type retailers_store$key = {
  +$data?: retailers_store$data,
  +$fragmentSpreads: retailers_store$fragmentType,
  ...
};
*/

var node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "retailers_store",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "isRetailer",
          "value": true
        },
        {
          "kind": "Literal",
          "name": "orderBy",
          "value": "shopcastCount"
        }
      ],
      "concreteType": "StoreCategoriesConnectionConnection",
      "kind": "LinkedField",
      "name": "bestCategories",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "StoreCategoriesConnectionEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Category",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "name",
                  "storageKey": null
                },
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "count",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "bestCategories(isRetailer:true,orderBy:\"shopcastCount\")"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "RetailerConnection",
      "kind": "LinkedField",
      "name": "recommendedRetailers",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "RetailerEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "User",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Store",
  "abstractKey": null
};
})();

(node/*: any*/).hash = "adb63f07d59f49a98dcf5455f0e25190";

module.exports = ((node/*: any*/)/*: Fragment<
  retailers_store$fragmentType,
  retailers_store$data,
>*/);
