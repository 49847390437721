import { commitMutation, graphql } from "react-relay"
import { complete } from "../helper.js"

const mutation = graphql`
	mutation uploadTeamAvatarMutation($input: TeamDisplayAvatarInput!) {
		teamDisplayAvatar(input: $input) {
			store {
				id
			}
			team {
				id
				displayAvatar
			}
		}
	}
`

const commit = (env, file, teamId, onCompleted, onError) =>
	commitMutation(env, {
		mutation,
		variables: { input: { teamId } },
		uploadables: { file },
		onCompleted: complete(onCompleted, onError),
		onError: (e) => complete(null, [e]),
		updater: (storeProxy, data) => {
			const teamProxy = storeProxy.get(teamId)
			teamProxy.setValue(
				data.teamDisplayAvatar.team.displayAvatar,
				"displayAvatar"
			)
		},
	})

export default { commit }
