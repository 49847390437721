import { commitMutation, graphql } from "react-relay"
import cookieService from "../../services/cookie-service.js"
import { complete } from "../helper.js"

const mutation = graphql`
	mutation signupMutation($input: SignupInput!) {
		signup(input: $input) {
			user {
				token
			}
			store {
				id
				currentUser {
					id
					username
					fullname
					email
					user_hash
					type
				}
			}
		}
	}
`

const getConfigs = (storeId) => [
	{
		type: "REQUIRED_CHILDREN",
		children: [],
	},
	{
		type: "FIELDS_CHANGE",
		fieldIDs: { store: storeId },
	},
]

const commit = (
	env,
	username,
	fullname,
	password,
	country,
	type,
	isAgreedToTAndCAndPrivacyPolicy,
	isSubscribeToEmailUpdates,
	code,
	recaptchaToken,
	storeId,
	onCompleted,
	onError
) =>
	commitMutation(env, {
		mutation,
		variables: {
			input: {
				username,
				password,
				fullname,
				type,
				country,
				isAgreedToTAndCAndPrivacyPolicy,
				isSubscribeToEmailUpdates,
				referral: cookieService.getRefferal(),
				code,
				recaptchaToken
			},
		},
		configs: getConfigs(storeId),
		onCompleted: complete(onCompleted, onError),
		onError: (e) => complete(null, [e]),
	})

export default { commit }
