import React, { PureComponent } from "react"
import {
	ExternalLinkIcon,
	GearIcon,
	MagnifyingGlassIcon,
	QuestionMarkCircledIcon,
} from "@radix-ui/react-icons"
// import {
// 	FeatureAvailableComponent,
// 	FeatureKeyENUM,
// } from "services/plan-services"
import { Link } from "react-router-dom"
import UserMenu from "components/dropdowns/user-menu"
import Avatar from "components/profile/avatar/avatar"
import {
	textStrings,
	publicViewDomain,
	subdomainValidationRegex,
} from "constants"
import { getActiveTabClass } from "helpers/ui-helper"
import Logout from "./logout"
import styles from "./header.css"

class HeaderStylist extends PureComponent {
	renderDesktop() {
		const {
			exploreTabs,
			store,
			// relay,
			toggleSearchInput,
			location,
			team,
			setTeam,
		} = this.props
		const isAdmin = store.currentUser.adminAccess === "HAS"
		return (
			<>
				<div className={styles.navLeft}>
					<div className={styles.navItem}>
						<Link
							className={getActiveTabClass(
								location.pathname.includes("/content"),
								styles.link,
								styles.linkActive
							)}
							to="/content"
						>
							{textStrings.contentRouteName}
						</Link>
					</div>
					<div className={styles.navItem}>
						<Link
							className={getActiveTabClass(
								location.pathname.includes("/collections"),
								styles.link,
								styles.linkActive
							)}
							to="/collections"
						>
							Collections
						</Link>
					</div>
					{/* <FeatureAvailableComponent
						featureKey={FeatureKeyENUM.CLIENT_CRM}
						env={relay.environment}
					>
						<div className={styles.navItem}>
							<Link
								className={getActiveTabClass(
									location.pathname.includes("/clients"),
									styles.link,
									styles.linkActive
								)}
								to="/clients"
							>
								Clients
							</Link>
						</div>
					</FeatureAvailableComponent> */}
					<ul className={styles.navItem}>{exploreTabs}</ul>
				</div>
				{isAdmin && (
					<button
						className={styles.navSearchBtn}
						type="button"
						tabIndex={0}
						onKeyPress={toggleSearchInput}
						onClick={toggleSearchInput}
					>
						<MagnifyingGlassIcon className={styles.navSearchIcon} />
					</button>
				)}
				<div className={styles.navUser}>
					<ul className={styles.navItem}>
						<li className={styles.navItemDropdown}>
							<div className={styles.iconLink}>
								<QuestionMarkCircledIcon className={styles.navIcon} />
							</div>
							<div
								className={styles.navItemMenuRight}
								aria-label="submenu"
							>
								<div className={styles.ddMenu}>
									<Link className={styles.ddLink} to="/dashboard">
										Video Tutorials
									</Link>
									<a
										className={styles.ddLink}
										href="https://team.shopshare.tv"
										rel="noopener noreferrer"
										target="_blank"
									>
										Tips & Tricks{" "}
										<ExternalLinkIcon className={styles.labelIcon} />
									</a>
									<a
										className={styles.ddLink}
										href="mailto:emma@shopshare.tv?subject=ShopShare Help Enquiry"
									>
										Contact Support
									</a>
								</div>
							</div>
						</li>
						<li className={styles.navItem}>
							<Link className={styles.iconLink} to="/settings/account">
								<GearIcon className={styles.navIcon} />
							</Link>
						</li>
					</ul>
					<UserMenu store={store} team={team} setTeam={setTeam} />
				</div>
			</>
		)
	}

	renderMobile() {
		const { store, isAdmin, relay, expolreTabsMobile, closeMenu, setTeam } =
			this.props
		const user = store.currentUser
		return (
			<>
				<div className={styles.mobilePanelBar}>
					<div className={styles.header}>
						<div className={styles.avatar}>
							<Avatar
								size="40px"
								photoUrl={user.profilePhoto}
								fullname={user.fullname}
							/>
						</div>
						<div className={styles.userDetails}>
							<h4 className={styles.name}>{user.username}</h4>
							{/* <p className={styles.email}>{user.email}</p> */}
						</div>
					</div>
				</div>
				<div className={styles.mobilePanelContent}>
					<div className={styles.menuNav}>
						<div className={styles.menuItem}>
							<Link
								className={styles.menuLink}
								onClick={closeMenu}
								to="/content"
							>
								{textStrings.contentRouteName}
							</Link>
						</div>
						<div className={styles.menuItem}>
							<Link
								className={styles.menuLink}
								to="/collections"
								onClick={closeMenu}
							>
								Collections
							</Link>
						</div>
						{/* <div className={styles.menuItem}>
							<Link
								className={styles.menuLink}
								to="/clients"
								onClick={this.closeMenu}
							>
								Clients
							</Link>
						</div> */}
					</div>
					<div className={styles.menuNav}>
						<h4 className={styles.menuHeader}>Channel</h4>
						{subdomainValidationRegex.test(user.username) && (
							<div className={styles.menuItem}>
								<a
									href={`https://${
										user.customDomain ||
										`${user.username}.${publicViewDomain}`
									}`}
									rel="noreferrer"
									className={styles.menuLink}
									target="_blank"
								>
									View Channel
									<ExternalLinkIcon className={styles.labelIcon} />
								</a>
							</div>
						)}
					</div>
					<div className={styles.menuNav}>
						<h4 className={styles.menuHeader}>Settings</h4>
						<div className={styles.menuItem}>
							<Link
								className={styles.menuLink}
								onClick={closeMenu}
								to="/settings/account"
							>
								Your Account
							</Link>
						</div>
						<div className={styles.menuItem}>
							<Link
								className={styles.menuLink}
								onClick={closeMenu}
								to="/settings/billing"
							>
								Plan & Billing
							</Link>
						</div>
						<div className={styles.menuItem}>
							<Link
								className={styles.menuLink}
								onClick={closeMenu}
								to="/settings/integrations"
							>
								Social Connections
							</Link>
						</div>
						<div className={styles.menuItem}>
							<Link
								className={styles.menuLink}
								onClick={closeMenu}
								to="/reporting"
							>
								Reporting
							</Link>
						</div>
						{/* <div className={styles.menuItem}>
							<Link
								className={styles.menuLink}
								onClick={closeMenu}
								to="/install-extension"
							>
								Install Recorder
							</Link>
						</div> */}
						{isAdmin && (
							<div className={styles.menuItem}>
								<Link
									className={styles.menuLink}
									onClick={closeMenu}
									to="/admin"
								>
									Admin
								</Link>
							</div>
						)}
					</div>
					<div className={styles.menuNav}>
						<h4 className={styles.menuHeader}>Explore</h4>
						{expolreTabsMobile}
					</div>
					<div className={styles.menuNav}>
						<h4 className={styles.menuHeader}>Help</h4>
						<div className={styles.menuItem}>
							<Link
								className={styles.menuLink}
								onClick={closeMenu}
								to="/dashboard"
							>
								Video Tutorials
							</Link>
						</div>
						<div className={styles.menuItem}>
							<a
								className={styles.menuLink}
								href="https://team.shopshare.tv"
								rel="noopener noreferrer"
								target="_blank"
								onClick={closeMenu}
							>
								Tips & Tricks{" "}
								<ExternalLinkIcon className={styles.labelIcon} />
							</a>
						</div>
						<div className={styles.menuItem}>
							<a
								className={styles.menuLink}
								href="mailto:emma@shopshare.tv?subject=ShopShare Help Enquiry"
							>
								Contact Support
							</a>
						</div>
					</div>
					<div className={styles.menuFooter}>
						<Logout
							logoutClass={styles.menuLogout}
							store={store}
							setTeam={setTeam}
							closeMenu={closeMenu}
							relay={relay}
						/>
					</div>
				</div>
			</>
		)
	}

	render() {
		const { isMobile } = this.props
		return isMobile ? this.renderMobile() : this.renderDesktop()
	}
}

export default HeaderStylist
