import React from "react"
import TickCircle from "shared/assets/tick-circle-icon.svg"
import styles from "./list-of-bullets.css"

export default function ListOfBullets({ bullets }) {
	return (
		<ul className={styles.root}>
			{bullets.map((bullet) => (
				<li className={styles.item} key={bullet.key}>
					<TickCircle className={styles.bullet} />
					<span className={styles.label}>{bullet.label}</span>
				</li>
			))}
		</ul>
	)
}
