import React, { Component } from "react"
import mixpanel from "mixpanel-browser"
import SignoutMutation from "../../mutations/user/signout"
import cookieService from "../../services/cookie-service"
import gqlh from "../../helpers/gql-helper"
import { withRouter } from "../../route-helpers"

class Logout extends Component {
	constructor(props) {
		super(props)
		this.logout = this.logout.bind(this)
		this.logoutSuccessful = this.logoutSuccessful.bind(this)
		this.logoutFailure = this.logoutFailure.bind(this)
	}

	logout(e) {
		e.preventDefault()
		const { props } = this
		const { navigate } = props
		cookieService.removeToken()
		if(props.setTeam) props.setTeam(null)
		window.sessionStorage.removeItem("collection-filters")
		navigate("/signin")
		SignoutMutation.commit(
			props.relay.environment,
			props.store.currentUser.id,
			props.store.id,
			this.logoutSuccessful,
			this.logoutFailure
		)
	}

	logoutSuccessful() {
		const { props } = this
		cookieService.removeToken()
		mixpanel.reset()
		if (props.closeMenu) {
			props.closeMenu()
		}
	}

	logoutFailure(errors) {
		const errorMessage = gqlh.getError(errors, "")
		console.log(errorMessage)
	}

	render() {
		const { props } = this
		return (
			<a
				className={props.logoutClass}
				role="link"
				tabIndex={0}
				onKeyPress={this.logout}
				onClick={this.logout}
			>
				Sign Out
			</a>
		)
	}
}

export default withRouter(Logout)
