/**
 * @generated SignedSource<<d9a407fc0acbf9a505cec0cdb770a4c1>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { userFileCard_shopshareFile$fragmentType } from "./../../../../cards/user-file/__generated__/userFileCard_shopshareFile.graphql";
import type { userLookbookCard_lookbook$fragmentType } from "./../../../../cards/user-lookbook/__generated__/userLookbookCard_lookbook.graphql";
import type { userProductCollectionCard_productCollection$fragmentType } from "./../../../../cards/user-product-collection-card/__generated__/userProductCollectionCard_productCollection.graphql";
import type { userShopcastCard_shopcast$fragmentType } from "./../../../../cards/user-shopcast/__generated__/userShopcastCard_shopcast.graphql";
import type { FragmentType } from "relay-runtime";
declare export opaque type listOfFolderItems_store$fragmentType: FragmentType;
export type listOfFolderItems_store$data = {|
  +currentUser: ?{|
    +adminAccess: ?string,
    +facebookPages: ?$ReadOnlyArray<?{|
      +accessToken: ?string,
      +category: ?string,
      +id: ?string,
      +imageUrl: ?string,
      +instagramBusinessAccount: ?string,
      +name: ?string,
    |}>,
    +id: string,
    +role: ?string,
    +shopcastContainer: ?{|
      +folder: ?{|
        +id: string,
        +items: ?{|
          +edges: ?$ReadOnlyArray<?{|
            +cursor: string,
            +node: ?({|
              +__typename: "Lookbook",
              +id: string,
              +$fragmentSpreads: userLookbookCard_lookbook$fragmentType,
            |} | {|
              +__typename: "ProductCollection",
              +id: string,
              +$fragmentSpreads: userProductCollectionCard_productCollection$fragmentType,
            |} | {|
              +__typename: "Shopcast",
              +id: string,
              +$fragmentSpreads: userShopcastCard_shopcast$fragmentType,
            |} | {|
              +__typename: "ShopshareFile",
              +id: string,
              +url: ?string,
              +$fragmentSpreads: userFileCard_shopshareFile$fragmentType,
            |} | {|
              // This will never be '%other', but we need some
              // value in case none of the concrete values match.
              +__typename: "%other",
            |}),
          |}>,
          +pageInfo: {|
            +endCursor: ?string,
            +hasNextPage: boolean,
            +hasPreviousPage: boolean,
            +startCursor: ?string,
          |},
        |},
      |},
      +id: string,
    |},
    +socialMedia: ?$ReadOnlyArray<?{|
      +isConnected: ?boolean,
      +platform: ?string,
    |}>,
    +userPlan: ?{|
      +metadata: ?$ReadOnlyArray<?{|
        +name: ?string,
        +value: ?string,
      |}>,
    |},
  |},
  +id: string,
  +$fragmentType: listOfFolderItems_store$fragmentType,
|};
export type listOfFolderItems_store$key = {
  +$data?: listOfFolderItems_store$data,
  +$fragmentSpreads: listOfFolderItems_store$fragmentType,
  ...
};
*/

var node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": 12,
      "kind": "LocalArgument",
      "name": "count"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "cursor"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "folder"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "search"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "sort"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "teamId"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "type"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "count",
        "cursor": "cursor",
        "direction": "forward",
        "path": [
          "currentUser",
          "shopcastContainer",
          "folder",
          "items"
        ]
      }
    ]
  },
  "name": "listOfFolderItems_store",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "currentUser",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "UserSocialMedia",
          "kind": "LinkedField",
          "name": "socialMedia",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "platform",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "isConnected",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": [
            {
              "kind": "Variable",
              "name": "teamId",
              "variableName": "teamId"
            }
          ],
          "concreteType": "PricingPlan",
          "kind": "LinkedField",
          "name": "userPlan",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "NameValue",
              "kind": "LinkedField",
              "name": "metadata",
              "plural": true,
              "selections": [
                (v1/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "value",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "FacebookPageObj",
          "kind": "LinkedField",
          "name": "facebookPages",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            (v1/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "category",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "imageUrl",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "accessToken",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "instagramBusinessAccount",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "role",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "adminAccess",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "ShopcastContainer",
          "kind": "LinkedField",
          "name": "shopcastContainer",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": [
                {
                  "kind": "Variable",
                  "name": "id",
                  "variableName": "folder"
                }
              ],
              "concreteType": "ShopcastFolder",
              "kind": "LinkedField",
              "name": "folder",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": "items",
                  "args": [
                    {
                      "kind": "Variable",
                      "name": "type",
                      "variableName": "type"
                    }
                  ],
                  "concreteType": "FolderItemsConnectionConnection",
                  "kind": "LinkedField",
                  "name": "__currentUser_listOfFolderItems_items_connection",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "FolderItemsConnectionEdge",
                      "kind": "LinkedField",
                      "name": "edges",
                      "plural": true,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "cursor",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": null,
                          "kind": "LinkedField",
                          "name": "node",
                          "plural": false,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "__typename",
                              "storageKey": null
                            },
                            {
                              "kind": "InlineFragment",
                              "selections": [
                                (v0/*: any*/),
                                {
                                  "args": null,
                                  "kind": "FragmentSpread",
                                  "name": "userShopcastCard_shopcast"
                                }
                              ],
                              "type": "Shopcast",
                              "abstractKey": null
                            },
                            {
                              "kind": "InlineFragment",
                              "selections": [
                                (v0/*: any*/),
                                {
                                  "args": null,
                                  "kind": "FragmentSpread",
                                  "name": "userLookbookCard_lookbook"
                                }
                              ],
                              "type": "Lookbook",
                              "abstractKey": null
                            },
                            {
                              "kind": "InlineFragment",
                              "selections": [
                                (v0/*: any*/),
                                {
                                  "alias": null,
                                  "args": null,
                                  "kind": "ScalarField",
                                  "name": "url",
                                  "storageKey": null
                                },
                                {
                                  "args": null,
                                  "kind": "FragmentSpread",
                                  "name": "userFileCard_shopshareFile"
                                }
                              ],
                              "type": "ShopshareFile",
                              "abstractKey": null
                            },
                            {
                              "kind": "InlineFragment",
                              "selections": [
                                (v0/*: any*/),
                                {
                                  "args": null,
                                  "kind": "FragmentSpread",
                                  "name": "userProductCollectionCard_productCollection"
                                }
                              ],
                              "type": "ProductCollection",
                              "abstractKey": null
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "PageInfo",
                      "kind": "LinkedField",
                      "name": "pageInfo",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "hasPreviousPage",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "hasNextPage",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "startCursor",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "endCursor",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Store",
  "abstractKey": null
};
})();

(node/*: any*/).hash = "34e008ec26332d0779464602415f3a3e";

module.exports = ((node/*: any*/)/*: Fragment<
  listOfFolderItems_store$fragmentType,
  listOfFolderItems_store$data,
>*/);
