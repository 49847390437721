import { commitMutation, graphql } from "react-relay"
import { complete } from "../helper"

const mutation = graphql`
	mutation updateDescriptionShopcastFolderMutation(
		$input: UpdateDescriptionShopcastFolderInput!
	) {
		updateDescriptionShopcastFolder(input: $input) {
			description
		}
	}
`

const optimisticResponse = (description) => ({
	updateDescriptionShopcastFolder: {
		description,
	},
})

const commit = (env, folder, description, success, error) =>
	commitMutation(env, {
		mutation,
		variables: { input: { folder, description } },
		updater: (storeProxy, data) => {
			if (data.updateDescriptionShopcastFolder) {
				const folderProxy = storeProxy.get(folder)
				folderProxy.setValue(
					data.updateDescriptionShopcastFolder.description,
					"description"
				)
			}
		},
		optimisticResponse: optimisticResponse(description),
		onCompleted: complete(success, error),
		onError: complete(() => {}, error),
	})

export default { commit }
