import React, { Component } from "react"
import { createPaginationContainer, graphql } from "react-relay"
import grids from "shared/styles/components/grids.css"
import empty from "shared/styles/components/empty.css"
import pageContent from "shared/styles/layout/page-content.css"
import InfiniteScroll from "react-infinite-scroller"
import LoadingDots from "components/loading-dots/loading-dots"
import ClientFolderCard from "components/cards/client-folder-card/client-folder-card"
import { DashboardIcon } from "@radix-ui/react-icons"
import { withRouter } from "../../route-helpers"

class ClientViewFolders extends Component {
	constructor() {
		super()
		this.handleLoadMore = this.handleLoadMore.bind(this)
		this.setFolder = this.setFolder.bind(this)
	}

	handleLoadMore() {
		const { relay, count } = this.props
		relay.loadMore(count, () => {}, {
			force: true,
		})
	}

	setFolder(folder) {
		const {
			navigate,
		} = this.props
		navigate(folder.id)
	}

	render() {
		const { store, relay } = this.props
		const {
			currentUser: {
				clientFolder: { folders },
			},
		} = store
		if (folders.edges.length === 0)
			return (
				<div className={pageContent.root}>
					<div className={empty.root}>
						<div className={empty.container}>
							<div className={empty.content}>
								<DashboardIcon className={empty.icon} />
								<h4 className={empty.headline}>
									There&apos;s nothing here
								</h4>
								<p className={empty.subline}>
									Any lookbooks created for you will appear here.
								</p>
							</div>
						</div>
					</div>
				</div>
			)
		const getListItems = () => (
			<div className={grids.rowMax4}>
				{folders.edges.map(({ node }) => (
					<ClientFolderCard
						key={node.__id}
						folder={node}
						setFolder={this.setFolder}
					/>
				))}
			</div>
		)

		return (
			<div className={pageContent.root}>
				<div className={grids.container}>
					<InfiniteScroll
						loadMore={this.handleLoadMore}
						hasMore={relay.hasMore()}
						loader={
							<div key="cvf-loading" className={grids.loadMore}>
								<LoadingDots />
							</div>
						}
						pageStart={0}
					>
						{getListItems()}
					</InfiniteScroll>
				</div>
			</div>
		)
	}
}

const query = graphql`
	query clientViewFoldersQuery(
		$count: Int!
		$cursor: String
		$folder: String!
		$sort: String
	) {
		store {
			...clientViewFolders_store
				@arguments(
					count: $count
					cursor: $cursor
					folder: $folder
					sort: $sort
				)
		}
	}
`

ClientViewFolders = createPaginationContainer(
	ClientViewFolders,
	{
		store: graphql`
			fragment clientViewFolders_store on Store
			@argumentDefinitions(
				count: { type: "Int", defaultValue: 12 }
				cursor: { type: "String" }
				folder: { type: "String!" }
				sort: { type: "String" }
			) {
				id
				currentUser {
					clientFolder(id: $folder) {
						id
						folders(
							first: $count
							after: $cursor
							sort: $sort
							isShared: true
						) @connection(key: "clientFolder_folders", filters: []) {
							edges {
								cursor
								node {
									...clientFolderCard_shopcastFolder
								}
							}
							pageInfo {
								hasPreviousPage
								hasNextPage
								startCursor
								endCursor
							}
						}
					}
				}
			}
		`,
	},
	{
		direction: "forward",
		getConnectionFromProps(props) {
			const { folders } = props.store.currentUser.clientFolder
			return folders && folders
		},
		getFragmentVariables(prevVars, totalCount) {
			return {
				...prevVars,
				count: totalCount,
			}
		},
		getVariables(props, { cursor, count }) {
			return {
				count: count || 12,
				folder: props.folder,
				sort: props.sort,
				cursor,
			}
		},
		query,
	}
)

export default {
	Component: withRouter(ClientViewFolders),
	query,
	params: (rp) => ({ count: 12, cursor: null, folder: rp.params.id, sort: null }),
}
