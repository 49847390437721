/**
 * @generated SignedSource<<a1c069b51d22ee612d19bf880676f3a3>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { lookbookCard_currentUser$fragmentType } from "./../../../../components/cards/lookbook-card/__generated__/lookbookCard_currentUser.graphql";
import type { lookbookCard_lookbook$fragmentType } from "./../../../../components/cards/lookbook-card/__generated__/lookbookCard_lookbook.graphql";
import type { FragmentType } from "relay-runtime";
declare export opaque type sharedFolderLookbooks_store$fragmentType: FragmentType;
export type sharedFolderLookbooks_store$data = {|
  +currentUser: ?{|
    +id: string,
    +$fragmentSpreads: lookbookCard_currentUser$fragmentType,
  |},
  +id: string,
  +profile: ?{|
    +sharedFolderLookbooks: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +$fragmentSpreads: lookbookCard_lookbook$fragmentType,
        |},
      |}>,
    |},
  |},
  +$fragmentType: sharedFolderLookbooks_store$fragmentType,
|};
export type sharedFolderLookbooks_store$key = {
  +$data?: sharedFolderLookbooks_store$data,
  +$fragmentSpreads: sharedFolderLookbooks_store$fragmentType,
  ...
};
*/

var node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": 12,
      "kind": "LocalArgument",
      "name": "count"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "cursor"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "folder"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "username"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "count",
        "cursor": "cursor",
        "direction": "forward",
        "path": [
          "profile",
          "sharedFolderLookbooks"
        ]
      }
    ]
  },
  "name": "sharedFolderLookbooks_store",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "currentUser",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "lookbookCard_currentUser"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "username",
          "variableName": "username"
        }
      ],
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "profile",
      "plural": false,
      "selections": [
        {
          "alias": "sharedFolderLookbooks",
          "args": [
            {
              "kind": "Variable",
              "name": "folderId",
              "variableName": "folder"
            }
          ],
          "concreteType": "LookbookConnection",
          "kind": "LinkedField",
          "name": "__profile_sharedFolderLookbooks_connection",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "LookbookEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Lookbook",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    {
                      "args": null,
                      "kind": "FragmentSpread",
                      "name": "lookbookCard_lookbook"
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "__typename",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "cursor",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "PageInfo",
              "kind": "LinkedField",
              "name": "pageInfo",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "endCursor",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "hasNextPage",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Store",
  "abstractKey": null
};
})();

(node/*: any*/).hash = "3dd5b43e857255f1810eeb5d46517084";

module.exports = ((node/*: any*/)/*: Fragment<
  sharedFolderLookbooks_store$fragmentType,
  sharedFolderLookbooks_store$data,
>*/);
