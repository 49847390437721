/**
 * @generated SignedSource<<193f707ca1cf9bf9b374e2cb72352138>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Query } from 'relay-runtime';
import type { rakutenSites_store$fragmentType } from "./rakutenSites_store.graphql";
export type rakutenSitesQuery$variables = {||};
export type rakutenSitesQuery$data = {|
  +store: ?{|
    +$fragmentSpreads: rakutenSites_store$fragmentType,
  |},
|};
export type rakutenSitesQuery = {|
  response: rakutenSitesQuery$data,
  variables: rakutenSitesQuery$variables,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  (v0/*: any*/),
  (v1/*: any*/)
],
v3 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "StoreCountryEdge",
    "kind": "LinkedField",
    "name": "edges",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Country",
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": (v2/*: any*/),
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "rakutenSitesQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Store",
        "kind": "LinkedField",
        "name": "store",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "rakutenSites_store"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "ShopshareQueryRoot",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "rakutenSitesQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Store",
        "kind": "LinkedField",
        "name": "store",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "currentUserIsAnAdminOrASharer",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "StoreCountryConnection",
            "kind": "LinkedField",
            "name": "rakutenSiteRegions",
            "plural": false,
            "selections": (v3/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "StoreNetworkConnection",
            "kind": "LinkedField",
            "name": "rakutenSiteNetworks",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "StoreNetworkEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "SiteNetwork",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": (v2/*: any*/),
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": [
              {
                "kind": "Literal",
                "name": "first",
                "value": 1000
              }
            ],
            "concreteType": "StoreCountryConnection",
            "kind": "LinkedField",
            "name": "countries",
            "plural": false,
            "selections": (v3/*: any*/),
            "storageKey": "countries(first:1000)"
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "StoreNetworkConnection",
            "kind": "LinkedField",
            "name": "networks",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "StoreNetworkEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "SiteNetwork",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v1/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "currency",
                        "storageKey": null
                      },
                      (v0/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "KeyValue",
            "kind": "LinkedField",
            "name": "partneredNetworks",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "key",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "value",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Category",
            "kind": "LinkedField",
            "name": "retailerCategories",
            "plural": true,
            "selections": (v2/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Category",
            "kind": "LinkedField",
            "name": "categories",
            "plural": true,
            "selections": (v2/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "currentUser",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "adminAccess",
                "storageKey": null
              },
              (v1/*: any*/)
            ],
            "storageKey": null
          },
          (v1/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "1ed07ea40cb88be0e492657067d66253",
    "id": null,
    "metadata": {},
    "name": "rakutenSitesQuery",
    "operationKind": "query",
    "text": "query rakutenSitesQuery {\n  store {\n    ...rakutenSites_store\n    id\n  }\n}\n\nfragment rakutenSites_store on Store {\n  currentUserIsAnAdminOrASharer\n  rakutenSiteRegions {\n    edges {\n      node {\n        name\n        id\n      }\n    }\n  }\n  rakutenSiteNetworks {\n    edges {\n      node {\n        name\n        id\n      }\n    }\n  }\n  countries(first: 1000) {\n    edges {\n      node {\n        name\n        id\n      }\n    }\n  }\n  networks {\n    edges {\n      node {\n        id\n        currency\n        name\n      }\n    }\n  }\n  partneredNetworks {\n    key\n    value\n  }\n  retailerCategories {\n    name\n    id\n  }\n  categories {\n    name\n    id\n  }\n  currentUser {\n    adminAccess\n    id\n  }\n  id\n}\n"
  }
};
})();

(node/*: any*/).hash = "22e3a682f75a05070ce5cdf5e15fa70b";

module.exports = ((node/*: any*/)/*: Query<
  rakutenSitesQuery$variables,
  rakutenSitesQuery$data,
>*/);
