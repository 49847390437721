import React, { Fragment } from "react"
import { createFragmentContainer, graphql } from "react-relay"
import twoColumn from "shared/styles/layout/two-column.css"
import pageContent from "shared/styles/layout/page-content.css"
import table from "shared/styles/components/table.css"
import dayjs from "helpers/dayjs-helper"
import styles from "./admin-referral-payment.css"
import { withOutletContext } from "../../../route-helpers"

function AdminReferralPayment({admin}) {
	return (
		<Fragment>
			<div className={twoColumn.header}>
				<h1 className={twoColumn.title}>Referral Payments</h1>
			</div>
			<div className={pageContent.root}>
				<div className={`${styles.table} ${table.stripped}`}>
					<div className={table.thead}>
						<div className={table.tr}>
							<div className={table.th}>User</div>
							<div className={table.th}>Campaign</div>
							<div className={table.th}>Amount</div>
							<div className={table.th}>Payout ID</div>
							<div className={table.th}>Created At</div>
						</div>
						{admin.referralPayments.map((payment) => (
							<div className={table.tr} key={payment.id}>
								<div className={table.tdTh}>
									{payment.referral.user.fullname}
								</div>
								<div className={table.td}>
									{payment.referral.campaign.name}
								</div>
								<div className={table.td}>
									${payment.amount.toFixed(2)}
								</div>
								<div className={table.td}>{payment.payoutId}</div>
								<div className={table.td}>
									{dayjs(+payment.createdAt).format("MMMM DD, YYYY")}
								</div>
							</div>
						))}
					</div>
				</div>
			</div>
		</Fragment>
	)
}

const AdminReferralPaymentComp = createFragmentContainer(AdminReferralPayment, {
	admin: graphql`
		fragment adminReferralPayment_admin on Admin {
			referralPayments {
				id
				amount
				payoutId
				referral {
					user {
						fullname
					}
					campaign {
						name
					}
				}
				createdAt
			}
		}
	`,
})

export default withOutletContext(AdminReferralPaymentComp)
