import React from "react"
import { DrawingPinFilledIcon, DrawingPinIcon } from "@radix-ui/react-icons"
import styles from "./pin-button.css"

export default function PinButton({ onClick, isPinned, size }) {
	if (onClick)
		return (
			<button
				className={styles.root}
				type="button"
				onClick={onClick}
				data-tooltip-id="tooltip"
				data-tooltip-content={isPinned ? "Unpin" : "Pin"}
			>
				{isPinned ? (
					<DrawingPinFilledIcon className={styles.iconActive} />
				) : (
					<DrawingPinIcon className={styles.icon} />
				)}
			</button>
		)
	return isPinned ? (
		<div
			className={
				size && size === "sm" ? styles.rootPinnedSm : styles.rootPinned
			}
			data-tooltip-id="tooltip"
			data-tooltip-content="Pinned"
		>
			<DrawingPinFilledIcon className={styles.iconActive} />
		</div>
	) : null
}
