import React, { Fragment, useEffect, useState } from "react"
import axios from "axios"
import { toast } from "react-toastify"
import { Bar, Line } from "react-chartjs-2"
import Dropdown, {
	DropdownTrigger,
	DropdownContent,
} from "react-simple-dropdown"
import contentCard from "shared/styles/layout/content-card.css"
import pageContent from "shared/styles/layout/page-content.css"
import dropdown from "components/dropdown-menu/dropdown-menu-dark.css"
import table from "shared/styles/components/table.css"
import dayjs from "helpers/dayjs-helper"
import _ from "lodash"
import { apiUrl } from "constants"
import styles from "./dashboard.css"

const rangeOptions = [
	{
		value: "last-7-days",
		label: "Last 7 Days",
	},
	{
		value: "this-month",
		label: "This Month",
	},
	{
		value: "last-month",
		label: "Last Month",
	},
	{
		value: "this-quarter",
		label: "This Quarter",
	},
	{
		value: "last-quarter",
		label: "Last Quarter",
	},
	{
		value: "this-year",
		label: "This Year",
	},
	{
		value: "last-year",
		label: "Last Year",
	},
	{ value: "all-time", label: "All Time" },
]

const getRange = (value) => {
	switch (value) {
		case "last-7-days": {
			return {
				startDate: dayjs().subtract(7, "days"),
				endDate: dayjs(),
			}
		}
		case "this-month": {
			return {
				startDate: dayjs().startOf("month"),
				endDate: dayjs().endOf("month"),
			}
		}
		case "last-month": {
			return {
				startDate: dayjs().subtract(1, "months").startOf("month"),
				endDate: dayjs().subtract(1, "months").endOf("month"),
			}
		}
		case "this-quarter": {
			return {
				startDate: dayjs().startOf("quarter"),
				endDate: dayjs().endOf("quarter"),
			}
		}
		case "last-quarter": {
			return {
				startDate: dayjs().subtract(1, "Q").startOf("quarter"),
				endDate: dayjs().subtract(1, "Q").endOf("quarter"),
			}
		}
		case "this-year": {
			return {
				startDate: dayjs().startOf("year"),
				endDate: dayjs().endOf("year"),
			}
		}
		case "last-year": {
			return {
				startDate: dayjs().subtract(1, "years").startOf("year"),
				endDate: dayjs().subtract(1, "years").endOf("year"),
			}
		}
		case "all-time": {
			return {
				startDate: dayjs("2017-05-30").startOf("month"),
				endDate: dayjs().endOf("month"),
			}
		}
		default:
			return {
				startDate: dayjs().startOf("month"),
				endDate: dayjs().endOf("month"),
			}
	}
}

function ProductDashboard() {
	const [data, setData] = useState(null)
	const [range, setRange] = useState(rangeOptions[1])

	const loadData = () => {
		axios
			.get(`${apiUrl}/dashboard/products?range=${range.value}`)
			.then((res) => {
				setData(res.data)
			})
			.catch(() => {
				toast.error(
					<Fragment>Could not get data. please try again later</Fragment>,
					{
						autoClose: 2000,
						closeButton: false,
					}
				)
			})
	}

	useEffect(() => {
		loadData()
	}, [range])

	const getDateArray = (from, to, interval, unitType = "days") => {
		const ret = []
		let date = from
		while (to > date) {
			ret.push(date)
			date = dayjs(date).add(interval, unitType)
		}
		return ret
	}

	const dateRanges = getRange(range.value)
	const divideByMonths =
		dateRanges.endDate.diff(dateRanges.startDate, "days", true) > 31
	const dateArray = getDateArray(
		dateRanges.startDate,
		dateRanges.endDate,
		1,
		divideByMonths ? "months" : "days"
	)

	const renderTotalActiveSubSection = () => {
		const totalActiveSubsLineChartData = {
			labels: dateArray.map((i) =>
				i.format(divideByMonths ? "MMM YYYY" : "DD ddd")
			),
			datasets: [
				{
					label: "Total Active Subscribers",
					backgroundColor: "rgb(155, 224, 192)",
					borderColor: "rgb(155, 224, 192)",
					data: dateArray.map((i) =>
						(
							data.activeSubs[
								i.format(divideByMonths ? "MM-YYYY" : "MM-DD-YYYY")
							] || 0
						).toFixed(2)
					),
				},
			],
		}
		return (
			<div className={contentCard.root}>
				<div className={contentCard.header}>
					<h2 className={contentCard.title}>Total Active Subscribers</h2>
				</div>
				<div className={contentCard.content}>
					<Line data={totalActiveSubsLineChartData} />
				</div>
			</div>
		)
	}

	const renderTotalItemsSection = () => {
		const totalItemsLineChartData = {
			labels: dateArray.map((i) =>
				i.format(divideByMonths ? "MMM YYYY" : "DD ddd")
			),
			datasets: [
				{
					label: "Total Items",
					backgroundColor: "rgb(155, 224, 192)",
					borderColor: "rgb(155, 224, 192)",
					data: dateArray.map((i) =>
						(
							data.totalItems[
								i.format(divideByMonths ? "MM-YYYY" : "MM-DD-YYYY")
							] || 0
						).toFixed(2)
					),
				},
			],
		}
		return (
			<div className={contentCard.root}>
				<div className={contentCard.header}>
					<h2 className={contentCard.title}>Total Items</h2>
				</div>
				<div className={contentCard.content}>
					<Line data={totalItemsLineChartData} />
				</div>
			</div>
		)
	}

	const renderCountriesSection = () => (
		<div className={contentCard.root}>
			<div className={contentCard.header}>
				<h2 className={contentCard.title}>Subscribers By Countries</h2>
			</div>
			<div className={contentCard.content}>
				<div className={table.bordered}>
					<div className={table.thead}>
						<div className={table.tr}>
							<div className={table.th}>Country</div>
							<div className={table.th}>Subscribers</div>
							<div className={table.th}>Shopcast Created</div>
							<div className={table.th}>Lookbooks Created</div>
						</div>
						{data.countries.map((row) => (
							<div className={table.tr} key={row.id || 0}>
								<div className={table.td}>
									{row.name || "Not Assigned"}
								</div>
								<div className={table.td}>{row.usercount}</div>
								<div className={table.td}>
									<b>{row.shopcastcount}</b>
								</div>
								<div className={table.td}>
									<b>{row.lookbookcount}</b>
								</div>
							</div>
						))}
					</div>
				</div>
			</div>
		</div>
	)

	const renderAvgItemsSection = () => {
		const users = [
			...Object.entries(data.itemsGrouped.shopcasts),
			...Object.entries(data.itemsGrouped.lookbooks),
		].map((entry) => ({
			id: entry[0],
			name: entry[1].name,
		}))
		const getAvg = (item) => {
			if (!item) return 0
			return _.sum(Object.values(item.values)) / Math.ceil(item.activePeriod)
		}
		const distinctUsers = _.uniqBy(users, (i) => i.id).map((i) => {
			const shopcastAvg = getAvg(data.itemsGrouped.shopcasts[i.id])
			const lookbookAvg = getAvg(data.itemsGrouped.lookbooks[i.id])
			return {
				...i,
				shopcastAvg,
				lookbookAvg,
				overallAvg: shopcastAvg + lookbookAvg,
			}
		})
		distinctUsers.sort((a, b) => b.overallAvg - a.overallAvg)
		const filteredUsers = distinctUsers.slice(0, 20)
		const avgItemsLineChartData = {
			labels: filteredUsers.map((i) => i.name),
			datasets: [
				{
					label: "Shopcasts",
					backgroundColor: "rgb(155, 224, 192)",
					borderColor: "rgb(155, 224, 192)",
					data: filteredUsers.map((i) => i.shopcastAvg),
				},
				{
					label: "Lookbooks",
					backgroundColor: "rgb(231, 190, 206)",
					borderColor: "rgb(231, 190, 206)",
					data: filteredUsers.map((i) => i.lookbookAvg),
				},
			],
		}
		return (
			<div className={contentCard.root}>
				<div className={contentCard.header}>
					<h2 className={contentCard.title}>Average Items By Users</h2>
				</div>
				<div className={contentCard.content}>
					<Bar
						data={avgItemsLineChartData}
						options={{
							scales: {
								x: {
									stacked: true,
								},
								y: {
									stacked: true,
								},
							},
						}}
					/>
				</div>
			</div>
		)
	}

	const renderSubEngagementSection = () => {
		const subEngDateArray = getDateArray(
			range.value === "all-time"
				? dayjs("2020-09-07").startOf("month")
				: dateRanges.startDate,
			dateRanges.endDate,
			1,
			divideByMonths ? "months" : "days"
		)
		const subEngLineChartData = {
			labels: subEngDateArray.map((i) =>
				i.format(divideByMonths ? "MMM YYYY" : "DD ddd")
			),
			datasets: [
				{
					label: "Active",
					backgroundColor: "rgb(155, 224, 192)",
					borderColor: "rgb(155, 224, 192)",
					fill: true,
					data: subEngDateArray.map((i) => {
						const obj =
							data.totalSubscriptionsGroupedValues[
								i.format(divideByMonths ? "MM-YYYY" : "MM-DD-YYYY")
							]
						if (!obj || (obj.total === 0 && obj.active === 0)) return 0
						const percentage =
							(obj.active / Math.max(obj.total, obj.active)) * 100
						return (percentage || 0).toFixed(2)
					}),
				},
			],
		}
		return (
			<div className={contentCard.root}>
				<div className={contentCard.header}>
					<h2 className={contentCard.title}>Subscribers Engagement</h2>
				</div>
				<div className={contentCard.content}>
					<Line data={subEngLineChartData} />
				</div>
			</div>
		)
	}

	const getLiClassName = (li) =>
		li.value === range.value
			? `${dropdown.select} ${dropdown.selected}`
			: dropdown.select

	return (
		<div className={pageContent.rootMd}>
			<h1>Product Dashboard</h1>
			<div className={styles.controls}>
				<Dropdown className={dropdown.root}>
					<DropdownTrigger className={dropdown.triggerButton}>
						{range.label}
					</DropdownTrigger>
					<DropdownContent className={dropdown.contentSelect}>
						{rangeOptions.map((li) => (
							<a
								className={getLiClassName(li)}
								onClick={() => {
									setRange(li)
								}}
								onKeyPress={() => {
									setRange(li)
								}}
								tabIndex={-1}
								role="link"
								key={li.value}
							>
								{li.label}
							</a>
						))}
					</DropdownContent>
				</Dropdown>
			</div>
			{data && (
				<Fragment>
					{renderTotalActiveSubSection()}
					{renderTotalItemsSection()}
					{renderCountriesSection()}
					{renderAvgItemsSection()}
					{renderSubEngagementSection()}
				</Fragment>
			)}
		</div>
	)
}

export default ProductDashboard
