import { commitMutation, graphql } from "react-relay"
import { complete } from "../helper"

const mutation = graphql`
	mutation setClosetOwnedItemsMutation(
		$input: SetClosetOwnedItemsInput!
	) {
		setClosetOwnedItems(input: $input) {
            store {
                id
            }
        }
	}
`

const commit = (env, itemId, clientId, onError, onCompleted) =>
	commitMutation(env, {
		mutation,
		variables: {
			input: {
				itemId,
				clientId,
			},
		},
		onError: complete(onCompleted, onError),
		onCompleted: complete(onCompleted, onError),
		updater: (storeProxy) => {
			const item = storeProxy.get(itemId)
			const isOwnedItems = item.getValue("isOwnedItems", { clientId })
			item.setValue(!isOwnedItems, "isOwnedItems", { clientId })
		},
	})

export default { commit }
