import React, { useCallback, useEffect, useRef, useState } from "react"
import axios from "axios"
import Modal from "react-modal"
import ReactPlayer from "react-player/youtube"
import { toast } from "react-toastify"
import { Cross2Icon } from "@radix-ui/react-icons"
import grids from "shared/styles/components/grids.css"
import nav from "shared/styles/components/nav.css"
import button from "shared/styles/components/buttons.css"
import card from "shared/styles/components/card.css"
import embed from "shared/styles/components/embed.css"
import modal from "shared/styles/components/modal.css"
import PlayIcon from "shared/assets/play-icon.svg"
import { apiUrl } from "constants"
import styles from "./video-collection-youtube.css"

function VideoCollectionYoutube() {
	const tabsRef = useRef([]);
	const [dashboardData, setDashboardData] = useState([])
	const [selected, setSelected] = useState(null)
	const [currentTabData, setCurrentTabData] = useState({
		currentTab: "",
		tabStyles: {},
	})

	const changeTabState = useCallback((e, toState) => {
		if (e) e.preventDefault()
		setCurrentTabData({
			currentTab: toState,
			tabStyles: {
				width: tabsRef.current[toState].offsetWidth,
				transform: `translateX(${tabsRef.current[toState].offsetLeft}px)`,
			},
		})
	}, [])

	useEffect(() => {
		if (dashboardData.length > 0) 
			changeTabState(null, dashboardData[0].id)
	}, [dashboardData])

	useEffect(() => {
		axios
			.get(`${apiUrl}/shopcastguides`)
			.then((res) => {
				setDashboardData(res.data)
			})
			.catch(() => {
				toast.error(<>Could not get data. please try again later</>, {
					autoClose: 2000,
					closeButton: false,
				})
			})
	}, [])

	const renderCard = (data) => (
		<div className={styles.root} key={data.id}>
			<div className={styles.media}>
				<a
					role="button"
					tabIndex="0"
					key={data.id}
					onClick={() => {
						setSelected(data)
					}}
				>
					<div className={styles.figure}>
						<div className={styles.inner}>
							<img
								className={styles.poster}
								src={data.posterUrl}
								loading="lazy"
								alt="Poster"
							/>
							<button className={card.play} type="button">
								<PlayIcon className={card.playIcon} />
							</button>
						</div>
					</div>
				</a>
			</div>
			<div className={styles.cardContent}>
				<h4 className={styles.title}>{data.title}</h4>
				<div className={styles.subtitle}>
						{data.duration}
					</div>
			</div>
		</div>
	)

	const selectedPlaylist = dashboardData.find(
		(playlist) => playlist.id === currentTabData.currentTab
	)

	return (
		<>
			<div className={`${nav.rootHasBorder} ${nav.rootSticky}`}>
				<nav className={nav.tabs}>
					<div className={nav.tabsFit}>
						{dashboardData.map((playlist) => (
							<a
								role="button"
								tabIndex="0"
								className={
									currentTabData.currentTab === playlist.id
										? nav.tabActive
										: nav.tab
								}
								key={playlist.id}
								ref={(ref) => {
									tabsRef.current[playlist.id] = ref
								}}
								onClick={(e) => changeTabState(e, playlist.id)}
								onKeyPress={(e) => changeTabState(e, playlist.id)}
							>
								{playlist.title}
							</a>
						))}
						<span
							className={nav.tabUnderline}
							style={currentTabData.tabStyles}
						/>
					</div>
				</nav>
			</div>
			<div className={styles.content}>
				{selectedPlaylist && (
					<div className={styles.section} key={selectedPlaylist.id}>
						<div className={styles.grid}>
							<div className={grids.youtube}>
								{selectedPlaylist.videos
									.filter(
										(video) =>
											video.thumbnails.maxres ||
											video.thumbnails.default
									)
									.map((video) =>
										renderCard({
											id: video.id,
											title: video.title,
											posterUrl: (
												video.thumbnails.maxres ||
												video.thumbnails.default
											).url,
											duration: video.duration,
										})
									)}
							</div>
						</div>
					</div>
				)}
			</div>
			<Modal
				className={modal.root}
				overlayClassName={modal.overlay}
				isOpen={!!selected}
				onRequestClose={() => {
					setSelected(null)
				}}
				ariaHideApp={false}
			>
				<div className={styles.dialog}>
					<div className={styles.video}>
						<button
							type="button"
							className={styles.close}
							onClick={() => {
								setSelected(null)
							}}
						>
							<Cross2Icon className={button.closeIcon} />
						</button>
						{selected && (
							<div className={embed.ratio16by9}>
								<ReactPlayer
									controls
									width="100%"
									height="100%"
									className={embed.video}
									url={`https://www.youtube.com/watch?v=${selected.id}`}
								/>
							</div>
						)}
					</div>
				</div>
			</Modal>
		</>
	)
}

export default VideoCollectionYoutube
