import { ConnectionHandler, commitMutation, graphql } from "react-relay"
import { complete } from "../helper.js"
import connections from "../connections.js"

const mutation = graphql`
	mutation setPinnedItemsMutation($input: SetPinnedItemsInput!) {
        setPinnedItems(input: $input) {
            store {
                id
            }
        }
    }
`

const commit = (env, id, onSuccess, onError, other) =>
	commitMutation(env, {
		mutation,
		variables: { input: { id } },
		updater: (storeProxy) => {
            const item = storeProxy.get(id)
			const currentVal = item.getValue("isPinned")
            item.setValue(!currentVal, "isPinned")
			if (other?.folder) {
				const parentFolder = storeProxy.get(other.folder)
				let connection
				if (other.isFolderConnection) {
					const conn = connections.currentUser.shopcastManager.folders
					connection = ConnectionHandler.getConnection(
						parentFolder,
						conn.name,
						{
							onlyClients: other.onlyClients,
							isShared: null,
						}
					)
				} else {
					const conn = connections.currentUser.shopcastManager.items
					connection = ConnectionHandler.getConnection(
						parentFolder,
						conn.name,
						{
							...conn.data,
							type: other.type,
						}
					)
				}
				ConnectionHandler.deleteNode(connection, id)
				const newEdge = ConnectionHandler.createEdge(
					storeProxy,
					connection,
					item,
					other.isFolderConnection
						? "ShopcastFolderShopcastFolderEdge"
						: "FolderItemsConnectionEdge"
				)
				if (!currentVal) {
					ConnectionHandler.insertEdgeBefore(connection, newEdge)
				} else {
					ConnectionHandler.insertEdgeAfter(connection, newEdge)
				}
			}
		},
		onCompleted: complete(onSuccess, onError),
		onError: (e) => complete(null, [e]),
	})

export default { commit }
