/**
 * @generated SignedSource<<af13d7ef34c639135d2e2be689983825>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Mutation } from 'relay-runtime';
export type ProductImageInput = {|
  clientMutationId?: ?string,
  imageName: string,
  productId: string,
|};
export type productImageMutation$variables = {|
  input: ProductImageInput,
|};
export type productImageMutation$data = {|
  +productImage: ?{|
    +url: ?string,
  |},
|};
export type productImageMutation = {|
  response: productImageMutation$data,
  variables: productImageMutation$variables,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "ProductImagePayload",
    "kind": "LinkedField",
    "name": "productImage",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "url",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "productImageMutation",
    "selections": (v1/*: any*/),
    "type": "ShopshareMutationRoot",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "productImageMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "dcbb28376f5a65e8de88ed6ad2488ef3",
    "id": null,
    "metadata": {},
    "name": "productImageMutation",
    "operationKind": "mutation",
    "text": "mutation productImageMutation(\n  $input: ProductImageInput!\n) {\n  productImage(input: $input) {\n    url\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "81a752d2e7c3b93f781bdda8ad38679e";

module.exports = ((node/*: any*/)/*: Mutation<
  productImageMutation$variables,
  productImageMutation$data,
>*/);
