/**
 * @generated SignedSource<<384331d888194cf27447b7ec715f0008>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Mutation } from 'relay-runtime';
export type DeleteProductCollectionItemInput = {|
  clientMutationId?: ?string,
  collectionId?: ?string,
  productId: string,
|};
export type deleteProductCollectionItemMutation$variables = {|
  input: DeleteProductCollectionItemInput,
|};
export type deleteProductCollectionItemMutation$data = {|
  +deleteProductCollectionItem: ?{|
    +store: ?{|
      +id: string,
    |},
  |},
|};
export type deleteProductCollectionItemMutation = {|
  response: deleteProductCollectionItemMutation$data,
  variables: deleteProductCollectionItemMutation$variables,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "DeleteProductCollectionItemPayload",
    "kind": "LinkedField",
    "name": "deleteProductCollectionItem",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Store",
        "kind": "LinkedField",
        "name": "store",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "deleteProductCollectionItemMutation",
    "selections": (v1/*: any*/),
    "type": "ShopshareMutationRoot",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "deleteProductCollectionItemMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "54de94ddf932a0e320e50a1118556da7",
    "id": null,
    "metadata": {},
    "name": "deleteProductCollectionItemMutation",
    "operationKind": "mutation",
    "text": "mutation deleteProductCollectionItemMutation(\n  $input: DeleteProductCollectionItemInput!\n) {\n  deleteProductCollectionItem(input: $input) {\n    store {\n      id\n    }\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "d15cc2e8152d42ac6ef974743fd5d2c8";

module.exports = ((node/*: any*/)/*: Mutation<
  deleteProductCollectionItemMutation$variables,
  deleteProductCollectionItemMutation$data,
>*/);
