import React, { useEffect, useState } from "react"
import { Cross2Icon } from "@radix-ui/react-icons"
import Modal from "react-modal"
import { useFormik } from "formik"
import * as Yup from "yup"
import { fetchQuery, graphql } from "react-relay"
import modal from "shared/styles/components/modal.css"
import button from "shared/styles/components/buttons.css"
import form from "shared/styles/components/form.css"
import textfield from "shared/styles/components/textfield.css"
import EmojiTextArea from "components/emoji-textarea/emoji-textarea"
import UploadToYoutubeMutation from "mutations/shopcast/upload-to-youtube"
import LoadingDots from "components/loading-dots/loading-dots"
import { socialMedia } from "constants"
import styles from "./upload-youtube.css"

function YoutubeUpload({
	relay,
	closeModal,
	youtubeData,
	storeId,
	team,
	...otherProps
}) {
	const [shopcastData, setShopcastData] = useState(null)
	const formik = useFormik({
		initialValues: {
			title: youtubeData?.title || "",
			description: shopcastData?.youtubeDescription || "",
			privacyStatus: youtubeData?.privacyStatus || "private",
		},
		validationSchema: Yup.object().shape({
			title: Yup.string().required("Title is required").max(100),
			description: Yup.string().max(5000),
			privacyStatus: Yup.string()
				.required("Privacy status is required")
				.oneOf(["public", "private", "unlisted"]),
		}),
		enableReinitialize: true,
		onSubmit: () => {
			closeModal()
			UploadToYoutubeMutation.commit(
				relay.environment,
				{
					title: formik.values.title,
					description: formik.values.description,
					privacyStatus: formik.values.privacyStatus,
					shopcast: {
						id: youtubeData.shopcast.id,
						title: youtubeData.shopcast.title,
					},
					teamId: team
				},
				storeId,
				youtubeData.success,
				youtubeData.failure
			)
		},
	})

	useEffect(() => {
		fetchQuery(
			relay.environment,
			graphql`
				query uploadYoutubeQuery($id: String) {
					store {
						id
						shopcast(id: $id) {
							id
							youtubeDescription
						}
					}
				}
			`,
			{ id: youtubeData.shopcast.id }
		)
			.toPromise()
			.then((data) => {
				setShopcastData(data.store.shopcast)
			})
	}, [youtubeData])

	if (!shopcastData) {
		return (
			<Modal
				className={modal.root}
				overlayClassName={modal.overlay}
				isOpen
				{...otherProps}
				ariaHideApp={false}
			>
				<div
					className={`${modal.dialog} ${modal.dialogCentered} ${modal.dialogFullscreenSmDown}`}
				>
					<LoadingDots />
				</div>
			</Modal>
		)
	}
	const { terms } = socialMedia.youtube
	return (
		<Modal
			className={modal.root}
			overlayClassName={modal.overlay}
			isOpen
			{...otherProps}
			ariaHideApp={false}
		>
			<div
				className={`${modal.dialog} ${modal.dialogCentered} ${modal.dialogFullscreenSmDown}`}
			>
				<div className={`${modal.content}`}>
					<div className={modal.header}>
						<h4 className={modal.title}>Upload to YouTube</h4>
						<button
							type="button"
							className={button.close}
							onClick={closeModal}
						>
							<Cross2Icon className={button.closeIcon} />
						</button>
					</div>
					<div className={modal.body}>
						<div className={form.group}>
							<label className={form.label}>Title</label>
							<input
								type="text"
								className={textfield.normal}
								name="title"
								value={formik.values.title}
								onChange={formik.handleChange}
							/>
							{formik.errors?.title && (
								<div className={form.validationFeedback}>
									{formik.errors.title}
								</div>
							)}
							<div className={styles.help}>
								{formik.values.title.length}/<b>100</b>
							</div>
						</div>
						<div className={form.group}>
							<label className={form.label}>Description</label>
							<EmojiTextArea
								value={formik.values.description}
								name="description"
								className={textfield.textarea}
								rows={10}
								maxLength={5000}
								onChange={formik.handleChange}
							/>
							{formik.errors?.description && (
								<div className={form.validationFeedback}>
									{formik.errors.description}
								</div>
							)}
							<div className={styles.help}>
								{formik.values.description.length}/<b>5000</b>
							</div>
						</div>
						<div className={form.group}>
							<label className={form.label}>Visibility</label>
							<select
								className={textfield.normal}
								name="privacyStatus"
								value={formik.values.privacyStatus}
								onChange={formik.handleChange}
							>
								<option value="public">Public</option>
								<option value="private">Private</option>
								<option value="unlisted">Unlisted</option>
							</select>
							{formik.errors?.privacyStatus && (
								<div className={form.validationFeedback}>
									{formik.errors.privacyStatus}
								</div>
							)}
						</div>
					</div>
					<div className={`${modal.footer} ${modal.borderNone}`}>
						{terms && (
							<a
								className={styles.footerLink}
								href={terms.link}
								target="_blank"
								rel="noreferrer"
							>
								{terms.text}
							</a>
						)}
						<button
							type="button"
							className={button.link}
							onClick={closeModal}
						>
							Cancel
						</button>
						<button
							type="button"
							className={button.primary}
							onClick={() => {
								formik.submitForm()
							}}
						>
							Upload
						</button>
					</div>
				</div>
			</div>
		</Modal>
	)
}

export default YoutubeUpload
