import React, { Component, Fragment } from "react"

export default class CountDown extends Component {
   constructor(props) {
      super(props)
      this.state = {
         displayTime: props.time,
      }
      this.renderCountdown = this.renderCountdown.bind(this)
      this.interval = null
   }

   componentDidMount() {
      this.renderCountdown()
   }

   componentWillUnmount() {
      window.clearInterval(this.interval)
   }

   renderCountdown() {
      const { time, onEnd } = this.props
      this.setState({ displayTime: time })
      this.interval = setInterval(() => {
         const { displayTime: c } = this.state
         this.setState({ displayTime: c - 1 })
         if (c === 1) {
            onEnd()
            return
         }
         if (c === 0) {
            window.clearInterval(this.interval)
         }
      }, 1000)
   }

   render() {
      const { show } = this.props
      const { displayTime } = this.state
      if (show) {
         return <Fragment>{displayTime}</Fragment>
      }
      return ""
   }
}
