/**
 * @generated SignedSource<<961bd924a076e3ed05abad82ccb2bb9c>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { Fragment, ReaderFragment } from 'relay-runtime';
export type CampaignCommisionType = "FIXED" | "PERCENTAGE" | "%future added value";
import type { FragmentType } from "relay-runtime";
declare export opaque type adminReferralCampaign_admin$fragmentType: FragmentType;
export type adminReferralCampaign_admin$data = {|
  +campaigns: ?$ReadOnlyArray<?{|
    +allUsers: ?boolean,
    +annualCommision: ?number,
    +annualCoupon: ?string,
    +conversions: ?number,
    +cookiePeriod: ?number,
    +description: ?string,
    +id: string,
    +isArchived: ?boolean,
    +monthlyCommision: ?number,
    +monthlyCoupon: ?string,
    +name: ?string,
    +referrals: ?$ReadOnlyArray<?{|
      +paidCommision: ?number,
      +revenue: ?number,
    |}>,
    +totalRevenue: ?number,
    +type: ?CampaignCommisionType,
    +users: ?$ReadOnlyArray<?{|
      +id: string,
      +username: ?string,
    |}>,
  |}>,
  +getUsersWithPlan: ?$ReadOnlyArray<?{|
    +id: string,
    +username: ?string,
  |}>,
  +id: string,
  +$fragmentType: adminReferralCampaign_admin$fragmentType,
|};
export type adminReferralCampaign_admin$key = {
  +$data?: adminReferralCampaign_admin$data,
  +$fragmentSpreads: adminReferralCampaign_admin$fragmentType,
  ...
};
*/

var node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  (v0/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "username",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "adminReferralCampaign_admin",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "getUsersWithPlan",
      "plural": true,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Campaign",
      "kind": "LinkedField",
      "name": "campaigns",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "description",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "monthlyCommision",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "annualCommision",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "type",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "cookiePeriod",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "monthlyCoupon",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "annualCoupon",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "conversions",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isArchived",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "User",
          "kind": "LinkedField",
          "name": "users",
          "plural": true,
          "selections": (v1/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "totalRevenue",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Referral",
          "kind": "LinkedField",
          "name": "referrals",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "revenue",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "paidCommision",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "allUsers",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Admin",
  "abstractKey": null
};
})();

(node/*: any*/).hash = "29d83efa8ebac5974c7d3180a9e2b93a";

module.exports = ((node/*: any*/)/*: Fragment<
  adminReferralCampaign_admin$fragmentType,
  adminReferralCampaign_admin$data,
>*/);
