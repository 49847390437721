import React, { Component } from "react"
import Modal from "react-modal"
import { toast } from "react-toastify"
import modal from "shared/styles/components/modal.css"
import button from "shared/styles/components/buttons.css"
import form from "shared/styles/components/form.css"
import CloseIcon from "shared/assets/close-icon.svg"
import _ from "lodash"
import AutoSuggest from "../../auto-suggest-new/auto-suggest"
import SetCollectionsForProduct from "../../../mutations/product-collection/set-collections-for-product"
import SetCollectionsForImage from "../../../mutations/product-collection/set-collections-for-image"
import { getChipClass } from "../../../helpers/ui-helper"

class MultiItemCollectionEdit extends Component {
	constructor(props) {
		super(props)
		this.intersectedCollections = props.selectedItems
			.map((i) => i.collections)
			.reduce((p, c) => p.filter((e) => c.map((i) => i.id).includes(e.id)))
		this.state = {
			productCollections: this.intersectedCollections.map((i) => ({
				id: i.id,
				value: i.title,
				className: getChipClass(i).chip
			})),
			isExtra: !!props.selectedItems.find(
				(i) => i.collections.length > this.intersectedCollections.length
			),
		}
	}

	saveCollections() {
		const {
			relay,
			selectedItems,
			store,
			onCancel,
			onSave,
			other,
		} = this.props
		const { productCollections } = this.state
		const intersectedCollectionIds = this.intersectedCollections.map(
			(i) => i.id
		)
		const data = selectedItems.map((item) => ({
			id: item.id,
			type: item.type,
			collections: [
				...item.collections
					.filter((i) => !intersectedCollectionIds.includes(i.id))
					.map((i) => ({
						id: i.id,
						value: i.title,
					})),
				...productCollections.map((i) => ({
					id: i.id,
					value: i.value,
				})),
			],
		}))
		if (!!data.find((i) => i.collections.length === 0)) {
			toast.error(
				<div>
					There are items with no collections selected. Please select a
					collection for all items.
				</div>,
				{
					autoClose: 2000,
					closeButton: false,
				}
			)
			return
		}
		const dataGrouped = _.groupBy(data, "type")
		const promises = []
		if (dataGrouped.ShopcastProduct && dataGrouped.ShopcastProduct.length > 0) {
			const promise = new Promise((resolve, reject) => {
				SetCollectionsForProduct.commit(
					relay.environment,
					dataGrouped.ShopcastProduct.map((item) => ({
						id: item.id,
						value: _.uniqBy(item.collections, (i) => i.id).map(
							(i) => ({
								value: i.value,
								id: i.id.includes("new_") ? null : i.id,
							})
						),
					})),
					store.currentUser.id,
					() => reject(),
					() => resolve(),
					other
				)
			})
			promises.push(promise)
		}
		if (dataGrouped.lookbookUploads && dataGrouped.lookbookUploads.length > 0) {
			const promise = new Promise((resolve, reject) => {
				SetCollectionsForImage.commit(
					relay.environment,
					dataGrouped.lookbookUploads.map((item) => ({
						id: item.id,
						value: _.uniqBy(item.collections, (i) => i.id).map(
							(i) => ({
								value: i.value,
								id: i.id.includes("new_") ? null : i.id,
							})
						),
					})),
					store.currentUser.id,
					() => reject(),
					() => resolve(),
					other
				)
			})
			promises.push(promise)
		}
		Promise.all(promises)
			.then(() => {
				toast.success(<div>Products Updated</div>, {
					autoClose: 2000,
					closeButton: false,
				})
				onSave()
				onCancel()
			})
			.catch(() => {
				toast.error(<div>Error Updating Products</div>, {
					autoClose: 2000,
					closeButton: false,
				})
			})
	}

	render() {
		const { collectionsKeyValue, onCancel } = this.props
		const { productCollections, isExtra } = this.state
		return (
			<Modal
				className={modal.root}
				overlayClassName={modal.overlay}
				htmlOpenClassName="ReactModal__Html--open"
				isOpen
				shouldCloseOnOverlayClick
				ariaHideApp={false}
			>
				<div className={`${modal.dialog} ${modal.dialogCentered}`}>
					<div className={modal.content}>
						<div className={`${modal.header}`}>
							<h4 className={modal.title}>Add to Collection</h4>
							<button
								type="button"
								className={button.close}
								onClick={onCancel}
							>
								<CloseIcon className={button.closeIcon} />
							</button>
						</div>
						<div className={modal.body}>
							<div className={form.group}>
								<label className={form.label}>Collections</label>
								<AutoSuggest
									result={productCollections}
									dataList={collectionsKeyValue}
									onChange={(result) => {
										this.setState({
											productCollections: result,
										})
									}}
									isExtra={isExtra}
								/>
							</div>
						</div>
						<div className={modal.footer}>
							<button
								type="button"
								className={button.link}
								onClick={onCancel}
							>
								Cancel
							</button>
							<button
								type="button"
								className={button.primary}
								onClick={() => {
									this.saveCollections()
								}}
							>
								Save
							</button>
						</div>
					</div>
				</div>
			</Modal>
		)
	}
}

export default MultiItemCollectionEdit
