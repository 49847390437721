/**
 * @generated SignedSource<<35949471df2ae769e923739d034e2bcc>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Mutation } from 'relay-runtime';
export type IncrementReferralVisitorsInput = {|
  clientMutationId?: ?string,
  link?: ?string,
|};
export type incrementReferralVisitorsMutation$variables = {|
  input: IncrementReferralVisitorsInput,
|};
export type incrementReferralVisitorsMutation$data = {|
  +incrementReferralVisitors: ?{|
    +referral: ?{|
      +campaign: ?{|
        +annualCoupon: ?string,
        +cookiePeriod: ?number,
        +monthlyCoupon: ?string,
      |},
    |},
    +store: ?{|
      +id: string,
    |},
  |},
|};
export type incrementReferralVisitorsMutation = {|
  response: incrementReferralVisitorsMutation$data,
  variables: incrementReferralVisitorsMutation$variables,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "Store",
  "kind": "LinkedField",
  "name": "store",
  "plural": false,
  "selections": [
    (v2/*: any*/)
  ],
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "cookiePeriod",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "monthlyCoupon",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "annualCoupon",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "incrementReferralVisitorsMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "IncrementReferralVisitorsPayload",
        "kind": "LinkedField",
        "name": "incrementReferralVisitors",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Referral",
            "kind": "LinkedField",
            "name": "referral",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Campaign",
                "kind": "LinkedField",
                "name": "campaign",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "ShopshareMutationRoot",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "incrementReferralVisitorsMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "IncrementReferralVisitorsPayload",
        "kind": "LinkedField",
        "name": "incrementReferralVisitors",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Referral",
            "kind": "LinkedField",
            "name": "referral",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Campaign",
                "kind": "LinkedField",
                "name": "campaign",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "9b352b6f1f36a017c873d5053c2cd2f1",
    "id": null,
    "metadata": {},
    "name": "incrementReferralVisitorsMutation",
    "operationKind": "mutation",
    "text": "mutation incrementReferralVisitorsMutation(\n  $input: IncrementReferralVisitorsInput!\n) {\n  incrementReferralVisitors(input: $input) {\n    store {\n      id\n    }\n    referral {\n      campaign {\n        cookiePeriod\n        monthlyCoupon\n        annualCoupon\n        id\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "eea5e1bd6da4ea30918703cdccc1cb71";

module.exports = ((node/*: any*/)/*: Mutation<
  incrementReferralVisitorsMutation$variables,
  incrementReferralVisitorsMutation$data,
>*/);
