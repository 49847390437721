import React, { Component } from "react"
import { createFragmentContainer } from "react-relay"
import { Link } from "react-router-dom"
import { graphql } from "relay-runtime"
import { toast } from "react-toastify"
import stylesAvatar from "components/profile/avatar/avatar.css"
import Avatar from "components/profile/avatar/avatar"
import AdminUserDeleteModal from "components/modals/admin-delete-user/admin-delete-user"
import PayoutButton from "components/payout-button/payout-button"
import ReferralPayoutSpecificUsersMutation from "mutations/admin/referral-payout-specific-users"
import CommissionPayoutSpecificUsersMutation from "mutations/admin/payout-commission"
import gqlHelper from "helpers/gql-helper"
import currencyData from "helpers/currencies.json"
import dayjs from "helpers/dayjs-helper"
import twoColumn from "shared/styles/layout/two-column.css"
import section from "shared/styles/layout/section.css"
import contentCard from "shared/styles/layout/content-card.css"
import styles from "./admin-user-profile.css"

const PAYOUT_TYPE = {
	COMMISSION: "commission",
	REFERRAL: "referral",
}

class AdminUserProfile extends Component {
	getOverallReferralPayoutMessage() {
		const { store } = this.props
		const { user } = store.admin
		return user.referralsForAdmin
			.map((referral) => ({
				name: referral.campaign.name,
				message: `${referral.campaign.name}: $${(
					referral.revenue - referral.paidCommision
				).toFixed(2)}`,
				value: referral.revenue - referral.paidCommision,
			}))
			.filter((i) => i.value > 0)
	}

	getOverallCommissionPayoutMessage() {
		const { store } = this.props
		const { user } = store.admin
		return user.commissions
			.map((i) => {
				const cSymbol =
					currencyData[i.currency] && currencyData[i.currency].symbol
				return {
					name: i.currency,
					originalValue: cSymbol
						? `${cSymbol}${(
								i.unpaidCommissionAfterValidation || 0
						  ).toFixed(2)}`
						: `${(i.unpaidCommissionAfterValidation || 0).toFixed(2)} ${
								i.currency
						  }`,
					message: `${i.currency}: $${(
						i.unpaidCommissionAfterValidationAUS || 0
					).toFixed(2)}`,
					value: i.unpaidCommissionAfterValidationAUS,
				}
			})
			.filter((i) => i.value > 0)
	}

	confirmPayout(payoutType) {
		const { relay, store } = this.props
		const { user } = store.admin

		if (payoutType === PAYOUT_TYPE.COMMISSION) {
			CommissionPayoutSpecificUsersMutation.commit(
				relay.environment,
				[user.id],
				(e) => {
					toast.error(
						gqlHelper.getError(e) || "Error processing payout.",
						{ autoClose: 2000, closeButton: false }
					)
				},
				() => {
					toast.success(<>Payouts processed successfully</>, {
						autoClose: 2000,
						closeButton: false,
					})
				}
			)
		} else if (payoutType === PAYOUT_TYPE.REFERRAL) {
			ReferralPayoutSpecificUsersMutation.commit(
				relay.environment,
				[user.id],
				(e) => {
					toast.error(
						gqlHelper.getError(e) || "Error processing payout.",
						{ autoClose: 2000, closeButton: false }
					)
				},
				() => {
					toast.success(<>Payouts processed successfully</>, {
						autoClose: 2000,
						closeButton: false,
					})
				}
			)
		}
	}

	render() {
		const { store, relay } = this.props
		const { user } = store.admin
		const referralSum = user.referralsForAdmin.reduce(
			(a, b) => ({
				visitors: a.visitors + b.visitors,
				leads: a.leads + b.leads,
				conversions: a.conversions + b.conversions,
				revenue: a.revenue + b.revenue,
				revenueAUS: a.revenueAUS + b.revenueAUS,
				paidCommision: a.paidCommision + b.paidCommision,
				paidCommisionAUS: a.paidCommisionAUS + b.paidCommisionAUS,
			}),
			{
				visitors: 0,
				leads: 0,
				conversions: 0,
				revenue: 0,
				revenueAUS: 0,
				paidCommision: 0,
				paidCommisionAUS: 0,
			}
		)

		return (
			<>
				<div className={section.root}>
					<div className={styles.header}>
						<div className={styles.avatar}>
							<Avatar
								styleName={stylesAvatar.border}
								photoUrl={user.profilePhoto}
								fullname={user.fullname}
								imageSize="400"
								size="140px"
							/>
						</div>
						<div className={styles.headerContent}>
							<h1 className={styles.title}>
								<a href={`mailto:${user.email}`}>{user.fullname}</a>
							</h1>
							<dl className={styles.meta}>
								<dd className={styles.metaItem}>{user.username}</dd>
							</dl>
						</div>
						<AdminUserDeleteModal
							relay={relay}
							user={user.id}
						/>
					</div>
				</div>

				<div className={section.root}>
					<div className={styles.heading}>
						<h4 className={twoColumn.title}>Referrals</h4>
						<div className={twoColumn.actions}>
							<PayoutButton
								disabled={
									!user.paypalEmail ||
									referralSum.revenue - referralSum.paidCommision === 0
								}
								confirmPayout={() =>
									this.confirmPayout(PAYOUT_TYPE.REFERRAL)
								}
								tableData={{
									headings: ["Campaign", "Amount"],
									rows: this.getOverallReferralPayoutMessage().map(
										(data) => [data.name, `$${data.value}`]
									),
									total: this.getOverallReferralPayoutMessage().reduce(
										(acc, next) => acc + next.value,
										0
									),
								}}
							/>
						</div>
					</div>

					<div className={styles.table}>
						<div className={styles.thead}>
							<div className={styles.tr}>
								<div className={styles.th}>Vistors</div>
								<div className={styles.th}>Trials</div>
								<div className={styles.th}>Conversions</div>
								<div className={styles.th}>Total</div>
								<div className={styles.th}>Paid</div>
								<div className={styles.th}>Unpaid</div>
								<div className={styles.th}>Unpaid (AUD)</div>
							</div>
						</div>
						<div className={styles.tbody}>
							<div className={styles.td}>{referralSum.visitors}</div>
							<div className={styles.td}>{referralSum.leads}</div>
							<div className={styles.td}>{referralSum.conversions}</div>
							<div className={styles.td}>
								${referralSum.revenue.toFixed(2)}
							</div>
							<div className={styles.td}>
								${referralSum.paidCommision.toFixed(2)}
							</div>
							<div className={styles.td}>
								$
								{(
									referralSum.revenue - referralSum.paidCommision
								).toFixed(2)}
							</div>
							<div className={styles.td}>
								$
								{(
									referralSum.revenueAUS - referralSum.paidCommisionAUS
								).toFixed(2)}
							</div>
						</div>
					</div>

					<div className={styles.subheading}>
						<h4 className={contentCard.heading}>Conversions</h4>
					</div>

					<div className={styles.table}>
						<div className={styles.thead}>
							<div className={styles.tr}>
								<div className={styles.th}>Name</div>
								<div className={styles.th}>Converted at</div>
								<div className={styles.th}>Plan</div>
								<div className={styles.th}>Frequency</div>
							</div>
						</div>
						<div className={styles.tbody}>
							{user.referralsForAdmin.map((referral) =>
								referral.convertedUsers.map((userObj) => (
									<div className={styles.tr} key={userObj.id}>
										<div className={styles.td}>
											{userObj.fullname}
										</div>
										<div className={styles.td}>
											{dayjs(+userObj.convertedAt).format(
												"MMMM DD, YYYY"
											)}
										</div>
										<div className={styles.td}>
											{userObj.userPlan?.nickname ||
												"Plan is not readable"}
										</div>
										<div className={styles.td}>
											{userObj.convertedMonthly
												? "Monthly"
												: "Yearly"}
										</div>
									</div>
								))
							)}
						</div>
					</div>

					<div className={styles.subheading}>
						<h4 className={contentCard.heading}>Payments</h4>
					</div>

					<div className={styles.table}>
						<div className={styles.thead}>
							<div className={styles.tr}>
								<div className={styles.th}>Campaign</div>
								<div className={styles.th}>Amount</div>
								<div className={styles.th}>Payout ID</div>
								<div className={styles.th}>Created at</div>
							</div>
						</div>
						<div className={styles.tbody}>
							{user.referralsForAdmin.map((referral) =>
								referral.payments.map((payment) => (
									<div className={styles.tr}>
										<div className={styles.td}>
											{referral.campaign.name}
										</div>
										<div className={styles.td}>{payment.amount}</div>
										<div className={styles.td}>
											{payment.payoutId}
										</div>
										<div className={styles.td}>
											{dayjs(+payment.createdAt).format(
												"MMMM DD, YYYY"
											)}
										</div>
									</div>
								))
							)}
						</div>
					</div>
				</div>

				<div className={section.root}>
					<div className={styles.heading}>
						<h4 className={twoColumn.title}>Commissions</h4>
						<div className={twoColumn.actions}>
							<PayoutButton
								disabled={
									!user.paypalEmail ||
									user.commissions.filter(
										(i) => i.unpaidCommission > 0
									).length === 0
								}
								confirmPayout={() =>
									this.confirmPayout(PAYOUT_TYPE.COMMISSION)
								}
								tableData={{
									headings: ["Currency", "Amount", "Amount (AUD)"],
									rows: this.getOverallCommissionPayoutMessage().map(
										(data) => [
											data.name,
											data.originalValue,
											`$${data.value}`,
										]
									),
									total: this.getOverallCommissionPayoutMessage().reduce(
										(acc, next) => acc + next.value,
										0
									),
								}}
							/>
						</div>
					</div>

					<div className={styles.table}>
						<div className={styles.thead}>
							<div className={styles.tr}>
								<div className={styles.th}>Currency</div>
								<div className={styles.th}>Sales</div>
								<div className={styles.th}>Total</div>
								<div className={styles.th}>Cut</div>
								<div className={styles.th}>Paid</div>
								<div className={styles.th}>Pending</div>
								<div className={styles.th}>Pending (AUD)</div>
								<div className={styles.th}>Unpaid (90 days)</div>
								<div className={styles.th}>Unpaid (90 days) (AUD)</div>
							</div>
						</div>
						<div className={styles.tbody}>
							{user.commissions.map((i) => {
								const cSymbol =
									currencyData[i.currency] &&
									currencyData[i.currency].symbol
								return (
									<div
										className={styles.tr}
										key={`${user.id}_${i.currency}`}
									>
										<div className={styles.td}>{i.currency}</div>
										<div className={styles.td}>
											{cSymbol
												? `${cSymbol}${(i.sales || 0).toFixed(2)}`
												: `${(i.sales || 0).toFixed(2)} ${
														i.currency
												  }`}
										</div>
										<div className={styles.td}>
											{cSymbol
												? `${cSymbol}${(
														i.totalCommission || 0
												  ).toFixed(2)}`
												: `${(i.totalCommission || 0).toFixed(2)} ${
														i.currency
												  }`}
										</div>
										<div className={styles.td}>
											{cSymbol
												? `${cSymbol}${(
														i.shopshareCut || 0
												  ).toFixed(2)}`
												: `${(i.shopshareCut || 0).toFixed(2)} ${
														i.currency
												  }`}
										</div>
										<div className={styles.td}>
											{cSymbol
												? `${cSymbol}${(
														i.paidCommission || 0
												  ).toFixed(2)}`
												: `${(i.paidCommission || 0).toFixed(2)} ${
														i.currency
												  }`}
										</div>
										<div className={styles.td}>
											{cSymbol
												? `${cSymbol}${(
														i.pendingCommission || 0
												  ).toFixed(2)}`
												: `${(i.pendingCommission || 0).toFixed(
														2
												  )} ${i.currency}`}
										</div>
										<div className={styles.td}>
											${(i.pendingCommissionAUS || 0).toFixed(2)}
										</div>
										<div className={styles.td}>
											{cSymbol
												? `${cSymbol}${(
														i.unpaidCommissionAfterValidation || 0
												  ).toFixed(2)}`
												: `${(
														i.unpaidCommissionAfterValidation || 0
												  ).toFixed(2)} ${i.currency}`}
										</div>
										<div className={styles.td}>
											$
											{(
												i.unpaidCommissionAfterValidationAUS || 0
											).toFixed(2)}
										</div>
									</div>
								)
							})}
						</div>
					</div>

					<div className={styles.subheading}>
						<h4 className={contentCard.heading}>Payments</h4>
					</div>

					<div className={styles.table}>
						<div className={styles.thead}>
							<div className={styles.tr}>
								<div className={styles.th}>Currency</div>
								<div className={styles.th}>Amount</div>
								<div className={styles.th}>Payout id</div>
								<div className={styles.th}>Created at</div>
							</div>
						</div>
						<div className={styles.tbody}>
							{user.commissionPayments.map((payment) => {
								const cSymbol =
									currencyData[payment.currency] &&
									currencyData[payment.currency].symbol
								return (
									<div className={styles.tr}>
										<div className={styles.td}>
											{payment.currency}
										</div>
										<div className={styles.td}>
											{cSymbol
												? `${cSymbol}${(
														payment.amount || 0
												  ).toFixed(2)}`
												: `${(payment.amount || 0).toFixed(2)} ${
														payment.currency
												  }`}
										</div>
										<div className={styles.td}>
											{payment.payoutId}
										</div>
										<div className={styles.td}>
											{dayjs(+payment.createdAt).format(
												"MMMM DD, YYYY"
											)}
										</div>
									</div>
								)
							})}
						</div>
					</div>
				</div>

				<div className={section.root}>
					<div className={styles.heading}>
						<h4 className={twoColumn.title}>Shopcasts</h4>
					</div>

					<div className={styles.table}>
						<div className={styles.thead}>
							<div className={styles.tr}>
								<div className={styles.th}>Title</div>
								<div className={styles.th}>Views</div>
								<div className={styles.th}>Product Count</div>
								<div className={styles.th}>Sales</div>
								<div className={styles.th}>Total Sales (AUD)</div>
								<div className={styles.th}>Total Commission (AUD)</div>
							</div>
						</div>
						<div className={styles.tbody}>
							{user.shopcastCommissions.map((row) => (
								<div className={styles.tr}>
									<div className={styles.td}>
										{row.item ? (
											<Link to={`/shopcast/${row.item.id}`}>
												{row.item.title}
											</Link>
										) : (
											"Deleted Shopcast"
										)}
									</div>
									<div className={styles.td}>
										{row.item?.views || 0}
									</div>
									<div className={styles.td}>
										{row.item?.shopcastItems.edges.length || 0}
									</div>
									<div className={styles.td}>{row.sales}</div>
									<div className={styles.td}>
										${row.totalSales.toFixed(2)}
									</div>
									<div className={styles.td}>
										${row.totalCommission.toFixed(2)}
									</div>
								</div>
							))}
						</div>
					</div>
				</div>

				<div className={section.root}>
					<div className={styles.heading}>
						<h4 className={twoColumn.title}>Lookbooks</h4>
					</div>

					<div className={styles.table}>
						<div className={styles.thead}>
							<div className={styles.tr}>
								<div className={styles.th}>Title</div>
								<div className={styles.th}>Views</div>
								<div className={styles.th}>Product Count</div>
								<div className={styles.th}>Sales</div>
								<div className={styles.th}>Total Sales (AUD)</div>
								<div className={styles.th}>Total Commission (AUD)</div>
							</div>
						</div>
						<div className={styles.tbody}>
							{user.lookbookCommissions.map((row) => (
								<div className={styles.tr}>
									<div className={styles.td}>
										{row.item ? (
											<Link to={`/shopboard/${row.item.id}`}>
												{row.item.title}
											</Link>
										) : (
											"Deleted Shopboard"
										)}
									</div>
									<div className={styles.td}>
										{row.item?.views || 0}
									</div>
									<div className={styles.td}>
										{row.item?.products.edges.length || 0}
									</div>
									<div className={styles.td}>{row.sales}</div>
									<div className={styles.td}>
										${row.totalSales.toFixed(2)}
									</div>
									<div className={styles.td}>
										${row.totalCommission.toFixed(2)}
									</div>
								</div>
							))}
						</div>
					</div>
				</div>
			</>
		)
	}
}

AdminUserProfile = createFragmentContainer(AdminUserProfile, {
	store: graphql`
		fragment adminUserProfile_store on Store
		@argumentDefinitions(id: { type: "String!" }) {
			id
			admin {
				user(id: $id) {
					id
					fullname
					email
					paypalEmail
					profilePhoto
					username
					referralsForAdmin {
						visitors
						leads
						conversions
						revenue
						revenueAUS
						paidCommision
						paidCommisionAUS
						campaign {
							name
						}
						convertedUsers {
							id
							fullname
							convertedAt
							convertedMonthly
							userPlan {
								nickname
							}
						}
						payments {
							id
							amount
							payoutId
							createdAt
						}
					}
					commissions {
						currency
						sales
						totalCommission
						shopshareCut
						paidCommission
						unpaidCommission
						unpaidCommissionAUS
						totalCommissionAUS
						unpaidCommissionAfterValidation
						unpaidCommissionAfterValidationAUS
						pendingCommission
						pendingCommissionAUS
					}
					commissionPayments {
						currency
						amount
						payoutId
						createdAt
					}
					shopcastCommissions {
						item {
							... on Shopcast {
								id
								title
								views
								shopcastItems {
									edges {
										node {
											id
										}
									}
								}
							}
						}
						sales
						totalSales
						totalCommission
					}
					lookbookCommissions {
						item {
							... on Lookbook {
								id
								title
								views
								products {
									edges {
										node {
											id
										}
									}
								}
							}
						}
						sales
						totalSales
						totalCommission
					}
				}
			}
		}
	`,
})

const adminUserProfileQuery = graphql`
	query adminUserProfileQuery($id: String!) {
		store {
			...adminUserProfile_store @arguments(id: $id)
		}
	}
`

export default {
	Component: AdminUserProfile,
	query: adminUserProfileQuery,
	params: (rp) => ({ id: rp.params.id }),
}
