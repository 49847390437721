/* eslint-disable import/prefer-default-export */
export const keyValueToObj = (array) => {
	const data = {}
	array.forEach((element) => {
		data[element.name] = element.value
	})
	return data
}

export function mergeRefs(...refs) {
	const filteredRefs = refs.filter(Boolean)
	if (!filteredRefs.length) return null
	if (filteredRefs.length === 0) return filteredRefs[0]
	return (inst) => {
		for (const ref of filteredRefs) {
			if (typeof ref === "function") {
				ref(inst)
			} else if (ref) {
				ref.current = inst
			}
		}
	}
}

export function isAudio(MediaUrl) {
	const extensions = MediaUrl.split(".")
	let extension = ""
	if (extensions && extensions.length > 0) {
		extension = extensions[extensions.length - 1]
	}
	return ["weba", "mp3", "wav"].includes(extension)
}
