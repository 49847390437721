import React, { PureComponent } from "react"
import { Link } from "react-router-dom"
import InfiniteScroll from "react-infinite-scroller"
import { createPaginationContainer, graphql } from "react-relay"
import Avatar from "components/profile/avatar/avatar"
import LoadingDots from "components/loading-dots/loading-dots"
import stylesAvatar from "components/profile/avatar/avatar.css"
import grids from "shared/styles/components/grids.css"
import dayjs from "helpers/dayjs-helper"
import styles from "./admin-user-list-view.css"

class AdminUserListRecords extends PureComponent {
	render() {
		const { store, relay } = this.props
		const { users } = store.admin
		return (
			<InfiniteScroll
				loadMore={() => relay.loadMore(12, () => {})}
				hasMore={relay.hasMore()}
				loader={
					<div className={grids.loadMore} key="loading">
						<LoadingDots />
					</div>
				}
				pageStart={0}
			>
				<div className={styles.table}>
					<div className={styles.thead}>
						<div className={styles.tr}>
							<div className={styles.th}>Full name</div>
							<div className={styles.th}>Username</div>
							<div className={styles.th}>Email</div>
							<div className={styles.th}>Created date</div>
						</div>
					</div>

					<div className={styles.tbody}>
						{users.edges.map(({ node: user }) => (
							<div className={styles.tr} key={user.id}>
								<div className={styles.td}>
									<Link
										className={styles.user}
										to={`/admin/user/${user.id}`}
									>
										<Avatar
											styleName={stylesAvatar.image}
											photoUrl={user.profilePhoto}
											fullname={user.fullname}
											imageSize="100"
											size="32px"
										/>
										{user.fullname}
									</Link>
								</div>
								<div className={styles.td}>{user.username}</div>
								<div className={styles.td}>{user.email}</div>
								<div className={styles.td}>
									{dayjs(+user.createdAtOriginal).format(
										"MMMM DD, YYYY"
									)}
								</div>
							</div>
						))}
					</div>
				</div>
			</InfiniteScroll>
		)
	}
}

const adminUserListRecordsQuery = graphql`
	query adminUserListRecordsQuery(
		$count: Int
		$cursor: String
		$search: String
	) {
		store {
			...adminUserListRecords_store
				@arguments(count: $count, cursor: $cursor, search: $search)
		}
	}
`

AdminUserListRecords = createPaginationContainer(
	AdminUserListRecords,
	{
		store: graphql`
			fragment adminUserListRecords_store on Store
			@argumentDefinitions(
				count: { type: "Int", defaultValue: 12 }
				cursor: { type: "String" }
				search: { type: "String" }
			) {
				admin {
					users(first: $count, after: $cursor, search: $search)
						@connection(key: "storeAdmin_users") {
						edges {
							node {
								id
								fullname
								username
								profilePhoto
								email
								createdAtOriginal
							}
						}
					}
				}
			}
		`,
	},
	{
		direction: "forward",
		getConnectionFromProps(props) {
			const { users } = props.store.admin
			return users
		},
		getFragmentVariables(prevVars, totalCount) {
			return {
				...prevVars,
				count: totalCount,
			}
		},
		getVariables(props, { count, cursor }) {
			return {
				count,
				cursor,
				search: props.search,
			}
		},
		query: adminUserListRecordsQuery,
	}
)

export default {
	Component: AdminUserListRecords,
	query: adminUserListRecordsQuery,
	params: { count: 12, cursor: null, search: "" },
}
