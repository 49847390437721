// import React from "react"
// import { Link } from "react-router-dom"
// import ScheduleADemo from "components/schedule-a-demo/schedule-a-demo"
// import styles from "./landing-actions.css"

// export default function LandingActions({ theme, noDemo }) {
// 	return (
// 		<div className={styles.root}>
// 			<div className={styles.actions}>
// 				{!noDemo && <ScheduleADemo theme={theme} />}
// 				<Link className={styles.trial} to="/signup">
// 					Get Started
// 				</Link>
// 			</div>
// 		</div>
// 	)
// }

export default function LandingActions() {
	return null
}
