/**
 * @generated SignedSource<<0562028653272f02cda5c24af13c98d4>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Mutation } from 'relay-runtime';
export type BackgroundThumbInput = {|
  clientMutationId?: ?string,
  uploadId: string,
  url?: ?string,
|};
export type setLookbookBackgroundThumbUrlMutation$variables = {|
  input: BackgroundThumbInput,
|};
export type setLookbookBackgroundThumbUrlMutation$data = {|
  +backgroundThumb: ?{|
    +url: ?boolean,
  |},
|};
export type setLookbookBackgroundThumbUrlMutation = {|
  response: setLookbookBackgroundThumbUrlMutation$data,
  variables: setLookbookBackgroundThumbUrlMutation$variables,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "BackgroundThumbPayload",
    "kind": "LinkedField",
    "name": "backgroundThumb",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "url",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "setLookbookBackgroundThumbUrlMutation",
    "selections": (v1/*: any*/),
    "type": "ShopshareMutationRoot",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "setLookbookBackgroundThumbUrlMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "1d620e6d4f07ecab7e8e7da6eb3b2c86",
    "id": null,
    "metadata": {},
    "name": "setLookbookBackgroundThumbUrlMutation",
    "operationKind": "mutation",
    "text": "mutation setLookbookBackgroundThumbUrlMutation(\n  $input: BackgroundThumbInput!\n) {\n  backgroundThumb(input: $input) {\n    url\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "a5648a421e5ebd5470f22a58066d5ea6";

module.exports = ((node/*: any*/)/*: Mutation<
  setLookbookBackgroundThumbUrlMutation$variables,
  setLookbookBackgroundThumbUrlMutation$data,
>*/);
