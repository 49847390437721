import React, { PureComponent } from "react"
import InfiniteScroll from "react-infinite-scroller"
import { createPaginationContainer, graphql } from "react-relay"
import empty from "shared/styles/components/empty.css"
import grids from "shared/styles/components/grids.css"
import LoadingDots from "components/loading-dots/loading-dots"
import ImageCard from "components/cards/lookbook/image-card"

class SharedFolderCollections extends PureComponent {
	constructor(props) {
		super(props)
		this.handleLoadMore = this.handleLoadMore.bind(this)
	}

	handleLoadMore() {
		const { relay } = this.props
		relay.loadMore(25, () => {})
	}

	loadCard(x) {
		return (
			<ImageCard
				key={x?.node.id}
				node={x?.node}
				noTrans
				price={x?.node?.offerPrice || x?.node?.regularPrice}
				description={x?.node?.title}
				noFooter
			/>
		)
	}

	render() {
		const { props } = this
		const { store, relay } = props
		const { sharedFolderCollectionItems: items } = store.profile
		if (!items || items.edges.length <= 0)
			return <div className={empty.simple}>There&apos;s nothing here</div>
		return (
			<div className={grids.container}>
				<InfiniteScroll
					loadMore={this.handleLoadMore}
					hasMore={relay.hasMore()}
					loader={
						<div className={grids.loadMore}>
							<LoadingDots />
						</div>
					}
					pageStart={0}
				>
					<div className={grids.imageMd}>
						{items.edges.map((x) => this.loadCard(x))}
					</div>
				</InfiniteScroll>
			</div>
		)
	}
}

const query = graphql`
	query sharedFolderCollectionsItemsQuery(
		$count: Int!
		$cursor: String
		$username: String!
		$folder: String!
		$searchText: String
		$sort: String
		$collectionId: String
		$type: String
	) {
		store {
			...sharedFolderCollectionsItems_store
				@arguments(
					count: $count
					cursor: $cursor
					username: $username
					folder: $folder
					searchText: $searchText
					sort: $sort
					collectionId: $collectionId
					type: $type
				)
		}
	}
`

SharedFolderCollections = createPaginationContainer(
	SharedFolderCollections,
	{
		store: graphql`
			fragment sharedFolderCollectionsItems_store on Store
			@argumentDefinitions(
				count: { type: "Int", defaultValue: 12 }
				cursor: { type: "String" }
				username: { type: "String!" }
				folder: { type: "String!" }
				searchText: { type: "String" }
				sort: { type: "String" }
				collectionId: { type: "String" }
				type: { type: "String" }
			) {
				id
				currentUser {
					id
					...shopcastCard_currentUser
				}
				profile(username: $username) {
					sharedFolderCollectionItems(
						first: $count
						after: $cursor
						folderId: $folder
						searchText: $searchText
						sort: $sort
						collectionId: $collectionId
						type: $type
					) @connection(key: "profile_sharedFolderCollectionItems") {
						edges {
							node {
								__typename
								... on ShopcastProduct {
									id
									title
									brand
									description
									imageUrl
									diffbotImageUrl
									regularPrice
									offerPrice
									productCollections {
										id
										title
									}
									url
									affiliated_url
									user {
										id
										fullname
										username
										profilePhoto
										customDomain
									}
									shopcastItems {
										id
										shopcast {
											id
											title
										}
										start
										end
									}
								}
								... on lookbookUploads {
									id
									adminAccess
									title
									productCollections {
										id
										title
									}
									url
								}
							}
						}
					}
				}
			}
		`,
	},
	{
		direction: "forward",
		getConnectionFromProps(props) {
			const { profile } = props.store
			return profile && profile.sharedFolderCollectionItems
		},
		getFragmentVariables(prevVars, totalCount) {
			return {
				...prevVars,
				count: totalCount,
			}
		},
		getVariables(props, { count, cursor }) {
			return {
				count,
				cursor,
				username: props.username,
				folder: props.folder,
				searchText: props.searchText,
				sort: props.sort,
				collectionId: props.collectionId,
				type: props.type,
			}
		},
		query,
	}
)

export default {
	Component: SharedFolderCollections,
	query,
	params: {
		count: 12,
		cursor: null,
		username: null,
		folder: null,
		searchText: null,
		sort: null,
		collectionId: null,
		type: null,
	},
}
