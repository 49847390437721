import React from "react"
// import { SizeIcon } from "@radix-ui/react-icons"
import FullScreenEnterIcon from "shared/assets/fullscreen-enter-icon.svg"
import FullScreenExitIcon from "shared/assets/fullscreen-exit-icon.svg"
import styles from "./fullscreen.css"

export default function Fullscreen({ isFullscreen, toggleFullscreen }) {
	return (
		<button
			type="button"
			className={styles.button}
			onClick={toggleFullscreen}
		>
			{isFullscreen === true ? (
				<FullScreenExitIcon className={styles.icon} />
			) : (
				<FullScreenEnterIcon className={styles.icon} />
			)}
		</button>
	)
}
