import React from "react"
import { LayersIcon, Crosshair2Icon, TrashIcon } from "@radix-ui/react-icons"
import { getColor } from "helpers/lookbook-helper"
import ShowHideButton from "components/buttons/show-hide-button"
import LookbookDropdown from "./lookbook-dropdown"
import styles from "./layer-dropdown.css"

export default function LayerDropdown({
	objList,
	selected,
	container,
	imageRef,
	runDebounce,
	onChange,
	onCenter,
	onSelect,
	onDelete,
}) {
	const getLayerIcon = (x) => {
		if (x.imageUrl)
			return <img src={x.imageUrl} className={styles.image} alt="layer" />
		if (x.shapeProp && x.shapeProp.borderAllowed)
			return (
				<svg
					className={styles.imageIcon}
					viewBox={`${-x.shapeProp.borderWidth} ${-x.shapeProp.borderWidth} ${x.shapeProp.viewBoxWidth + (2 * x.shapeProp.borderWidth)} ${x.shapeProp.viewBoxHeight + (2 * x.shapeProp.borderWidth)}`}
					preserveAspectRatio="xMidYMid meet"
				>
					{x.shapeProp.path.map((pathData, i) => (
						<path
							// eslint-disable-next-line react/no-array-index-key
							key={i}
							fillRule="evenodd"
							clipRule="evenodd"
							fill={getColor(x.shapeProp.fill, x.shapeProp.fillTransparentLevel)}
							d={pathData}
							stroke={x.shapeProp.borderColor}
							strokeWidth={x.shapeProp.borderWidth}
						/>
					))}
				</svg>
			)
		if (x.shapeProp)
			return (
				<img
					src={x.image.toDataURL()}
					className={styles.imageIcon}
					alt="layer"
				/>
			)
		return (
			<div
				className={styles.textIcon}
				style={{
					fontFamily: x.textProp.fontFamily,
				}}
			>
				T
			</div>
		)
	}
	return (
		<LookbookDropdown
			contentRight
			label={<LayersIcon className={styles.triggerIcon} />}
			dataTip="Layers"
			runDebounce={runDebounce}
		>
			<div className={styles.root}>
				<div className={styles.scroll}>
					{objList.map(
						(x) =>
							!x.onTray && (
								<div
									key={x.id}
									tabIndex={-1}
									role="link"
									className={
										selected === x.id
											? styles.itemActive
											: styles.item
									}
								>
									<div className={styles.eye}>
										<ShowHideButton
											show={x.visible}
											dark
											onClick={() =>
												onChange({
													...x,
													visible: !x.visible,
												})
											}
										/>
									</div>
									<div className={styles.preview}>
										<div className={styles.figure}>
											<div className={styles.inner}>
												{getLayerIcon(x)}
											</div>
										</div>
									</div>
									<div
										role="button"
										tabIndex="0"
										className={styles.label}
										onClick={() =>
											onSelect({
												target: imageRef[x.id],
												evt: { shiftKey: false },
											})
										}
									>
										<span className={styles.title}>{x.title}</span>
									</div>
									<div className={styles.actions}>
										<button
											type="button"
											className={styles.naked}
											onClick={() => {
												onSelect({
													target: imageRef[x.id],
													evt: { shiftKey: false },
												})
												onDelete()
											}}
										>
											<TrashIcon className={styles.icon} />
										</button>
										<button
											className={styles.naked}
											type="button"
											onClick={() => {
												onSelect({
													target: imageRef[x.id],
													evt: { shiftKey: false },
												})
												onCenter({
													...x,
													x: container.width / 2,
													y: container.height / 2,
												})
											}}
										>
											<Crosshair2Icon className={styles.icon} />
										</button>
									</div>
								</div>
							)
					)}
				</div>
			</div>
		</LookbookDropdown>
	)
}
