/**
 * @generated SignedSource<<9d07cf5ed1c8cf9200d72ddefec90af6>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentType } from "relay-runtime";
declare export opaque type userLookbookCard_lookbook$fragmentType: FragmentType;
export type userLookbookCard_lookbook$data = {|
  +description: ?string,
  +folder: ?{|
    +id: string,
    +name: ?string,
    +path: ?$ReadOnlyArray<?{|
      +name: ?string,
      +value: ?string,
    |}>,
  |},
  +id: string,
  +inRoot: ?boolean,
  +isArchived: ?boolean,
  +isFlagged: ?boolean,
  +isPinned: ?boolean,
  +isPublished: ?boolean,
  +isStarred: ?boolean,
  +lookbookBoards: ?$ReadOnlyArray<?{|
    +dataJson: ?string,
    +height: ?number,
    +id: ?string,
    +url: ?string,
    +videoUrl: ?string,
    +width: ?number,
  |}>,
  +pinterestUploadId: ?string,
  +shownBoardCount: ?number,
  +title: ?string,
  +transactions: ?$ReadOnlyArray<?{|
    +id: string,
    +orderId: ?string,
  |}>,
  +url: ?string,
  +user: ?{|
    +customDomain: ?string,
    +id: string,
    +username: ?string,
  |},
  +views: ?number,
  +$fragmentType: userLookbookCard_lookbook$fragmentType,
|};
export type userLookbookCard_lookbook$key = {
  +$data?: userLookbookCard_lookbook$data,
  +$fragmentSpreads: userLookbookCard_lookbook$fragmentType,
  ...
};
*/

var node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "url",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "userLookbookCard_lookbook",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "description",
      "storageKey": null
    },
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isPublished",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isPinned",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isArchived",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isStarred",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isFlagged",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "inRoot",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "views",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "shownBoardCount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "user",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "username",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "customDomain",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "SiteTransactionObj",
      "kind": "LinkedField",
      "name": "transactions",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "orderId",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "LookbookBoard",
      "kind": "LinkedField",
      "name": "lookbookBoards",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "videoUrl",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "width",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "height",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "dataJson",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ShopcastFolder",
      "kind": "LinkedField",
      "name": "folder",
      "plural": false,
      "selections": [
        (v2/*: any*/),
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "NameValue",
          "kind": "LinkedField",
          "name": "path",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "value",
              "storageKey": null
            },
            (v2/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "pinterestUploadId",
      "storageKey": null
    }
  ],
  "type": "Lookbook",
  "abstractKey": null
};
})();

(node/*: any*/).hash = "66ead51f27bd5a0e9746a8fb637a7b9f";

module.exports = ((node/*: any*/)/*: Fragment<
  userLookbookCard_lookbook$fragmentType,
  userLookbookCard_lookbook$data,
>*/);
