import React from "react"

import { Link, Outlet, useLocation } from "react-router-dom"
import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	PointElement,
	BarElement,
	LineElement,
} from "chart.js"
import pageContent from "shared/styles/layout/page-content.css"
import nav from "shared/styles/components/nav.css"
import { getActiveTabClass } from "../../../helpers/ui-helper"

ChartJS.register(
	CategoryScale,
	LinearScale,
	PointElement,
	BarElement,
	LineElement
)

function Dashboard() {
	const location = useLocation()
	return (
		<div className={pageContent.rootMd}>
			<div className={`${nav.rootHasBorder} ${nav.rootSticky}`}>
				<nav className={nav.tabs}>
					<Link
						role="button"
						tabIndex="0"
						to="/admin/dashboard/business"
						className={getActiveTabClass(
							location.pathname === "/admin/dashboard/business",
							nav.tab,
							nav.tabActive
						)}
					>
						Business
					</Link>
					<Link
						role="button"
						tabIndex="0"
						to="/admin/dashboard/products"
						className={getActiveTabClass(
							location.pathname === "/admin/dashboard/products",
							nav.tab,
							nav.tabActive
						)}
					>
						Product
					</Link>
				</nav>
				<Outlet />
			</div>
		</div>
	)
}

export default Dashboard
