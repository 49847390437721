import React from "react"
import styles from "./badge-page-count.css"

export default function BadgePageCount({ pageCount }) {
	return (
		<div className={styles.root}>
			<div className={styles.page}>
				<span className={styles.number}>{pageCount}</span>
			</div>
		</div>
	)
}
