/**
 * @generated SignedSource<<618066b27e9196ffe7e479c71d78e007>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Query } from 'relay-runtime';
export type facebookSharingQuery$variables = {||};
export type facebookSharingQuery$data = {|
  +store: ?{|
    +currentUser: ?{|
      +facebookPages: ?$ReadOnlyArray<?{|
        +accessToken: ?string,
        +category: ?string,
        +id: ?string,
        +imageUrl: ?string,
        +instagramBusinessAccount: ?string,
        +name: ?string,
      |}>,
      +id: string,
      +socialMedia: ?$ReadOnlyArray<?{|
        +isConnected: ?boolean,
        +platform: ?string,
      |}>,
    |},
    +id: string,
  |},
|};
export type facebookSharingQuery = {|
  response: facebookSharingQuery$data,
  variables: facebookSharingQuery$variables,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "platform",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isConnected",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "FacebookPageObj",
  "kind": "LinkedField",
  "name": "facebookPages",
  "plural": true,
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "category",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "imageUrl",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "accessToken",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "instagramBusinessAccount",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "facebookSharingQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Store",
        "kind": "LinkedField",
        "name": "store",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "currentUser",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "UserSocialMedia",
                "kind": "LinkedField",
                "name": "socialMedia",
                "plural": true,
                "selections": [
                  (v1/*: any*/),
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "ShopshareQueryRoot",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "facebookSharingQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Store",
        "kind": "LinkedField",
        "name": "store",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "currentUser",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "UserSocialMedia",
                "kind": "LinkedField",
                "name": "socialMedia",
                "plural": true,
                "selections": [
                  (v1/*: any*/),
                  (v2/*: any*/),
                  (v0/*: any*/)
                ],
                "storageKey": null
              },
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "0f665f0893ce186a39716e390b62869c",
    "id": null,
    "metadata": {},
    "name": "facebookSharingQuery",
    "operationKind": "query",
    "text": "query facebookSharingQuery {\n  store {\n    id\n    currentUser {\n      id\n      socialMedia {\n        platform\n        isConnected\n        id\n      }\n      facebookPages {\n        id\n        name\n        category\n        imageUrl\n        accessToken\n        instagramBusinessAccount\n      }\n    }\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "4e349d6e55a3833e40329221e3d48f03";

module.exports = ((node/*: any*/)/*: Query<
  facebookSharingQuery$variables,
  facebookSharingQuery$data,
>*/);
