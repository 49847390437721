import React, { Component, Fragment } from "react"
import Modal from "react-modal"
import { toast } from "react-toastify"
import { Cross2Icon } from "@radix-ui/react-icons"
import button from "shared/styles/components/buttons.css"
import form from "shared/styles/components/form.css"
import modal from "shared/styles/components/modal.css"
import textfield from "shared/styles/components/textfield.css"
import createProductForCollection from "mutations/product-collection/create-product-for-collection"
import LoadingDots from "components/loading-dots/loading-dots"

class LinkScraper extends Component {
	constructor(props) {
		super(props)
		this.state = {
			saving: false,
		}
		this.saveProduct = this.saveProduct.bind(this)
	}

	saveProduct() {
		const { relay, onSave, collectionIds, other, team } = this.props
		this.setState({ saving: true })
		createProductForCollection.commit(
			relay.environment,
			this.urlText.value,
			collectionIds,
			team,
			() => {
				toast.error(<Fragment>Product could not be clipped</Fragment>, {
					autoClose: 3000,
					closeButton: false,
				})
				this.setState({ saving: false })
			},
			() => {
				toast.success(
					<Fragment>Product is added to this collection</Fragment>,
					{ autoClose: 3000, closeButton: false }
				)
				onSave()
				this.setState({ saving: false })
			},
			other
		)
	}

	render() {
		const { closeModal, openModal } = this.props
		const { saving } = this.state

		return (
			<Modal
				className={modal.root}
				overlayClassName={modal.overlay}
				htmlOpenClassName="ReactModal__Html--open"
				isOpen={openModal}
				ariaHideApp={false}
			>
				<div
					className={`${modal.dialog} ${modal.dialogCentered} ${modal.dialogFullscreenSmDown} ${modal.dialogLg}`}
				>
					<div className={modal.content}>
						<div className={`${modal.header}`}>
							<h4 className={modal.title}>
								Upload Directly From a Site
							</h4>
							<button
								type="button"
								className={button.close}
								onClick={closeModal}
							>
								<Cross2Icon className={button.closeIcon} />
							</button>
						</div>
						<div className={modal.body}>
							<div className={form.group}>
								<label className={form.label}>Website URL</label>
								<input
									type="text"
									placeholder="http://example.com"
									className={textfield.normal}
									ref={(i) => {
										this.urlText = i
									}}
								/>
								{/* <p className={form.help}>Copy..</p> */}
							</div>
						</div>
						<div className={modal.footer}>
							<button
								type="button"
								className={button.link}
								onClick={closeModal}
							>
								{saving ? (
									<LoadingDots color="var(--gray300)" />
								) : (
									"Cancel"
								)}
							</button>
							<button
								disabled={saving}
								type="submit"
								className={button.primary}
								onClick={this.saveProduct}
							>
								{saving ? (
									<LoadingDots color="var(--primary80)" />
								) : (
									"Save"
								)}
							</button>
						</div>
					</div>
				</div>
			</Modal>
		)
	}
}

export default LinkScraper
