import { commitMutation, graphql } from "react-relay"
import { ConnectionHandler } from "relay-runtime"
import { complete } from "../helper.js"
import connections from "../connections"

const mutation = graphql`
	mutation deleteLookbookMutation(
		$input: DeleteLookbookInput!
	) {
		deleteLookbook(input: $input) {
			store {
				id
				currentUser {
					id
				}
			}  
		}
	}
`

const commit = (env, lookbookId, parentId, containerId, onError, onSuccess) => commitMutation(env, {
	mutation,
	variables: { input: { lookbookId } },
	onCompleted: complete(onSuccess, onError),
	onError: () => complete(null, onError),
	updater: (storeProxy, data) => {
		if (data.deleteLookbook) {
			const parentFolder = storeProxy.get(parentId)
			parentFolder.setValue(parentFolder.getValue("lookbooksCount") - 1, "lookbooksCount")
			const conn = connections.currentUser.shopcastManager.items
			const lookbooksConnection = ConnectionHandler.getConnection(parentFolder,
				conn.name,
				{
					...conn.data,
					type: "lookbook"
				})
			ConnectionHandler.deleteNode(lookbooksConnection, lookbookId)
			if (containerId) {
				const containerProxy = storeProxy.get(containerId)
				containerProxy.setValue(containerProxy.getValue("rootItemCount") - 1, "rootItemCount")
			}
		}
	},
})

export default { commit }
