import React from "react"
import Modal from "react-modal"
import modal from "shared/styles/components/modal.css"
import button from "shared/styles/components/buttons.css"
import { Cross2Icon } from "@radix-ui/react-icons"
import ConnectBg from "shared/assets/connect-bg.svg"
import styles from "./connect-to-social.css"

function ConnectToSocial({
	buttonPrompt,
	closeModal,
	description,
	heading,
	Icon,
	onConnect,
	subHeading,
	iconColor,
	title,
	terms,
}) {
	return (
		<Modal
			className={modal.root}
			overlayClassName={modal.overlay}
			isOpen
			ariaHideApp={false}
		>
			<div className={`${modal.dialog} ${modal.dialogCentered}`}>
				<div className={`${modal.content}`}>
					<div className={modal.header}>
						<div className={modal.titleWithIcon}>
							<Icon
								className={modal.icon}
								style={{ color: iconColor }}
							/>
							<h4 className={modal.title}>{title}</h4>
						</div>
						<button
							type="button"
							className={button.close}
							onClick={() => {
								closeModal()
							}}
						>
							<Cross2Icon className={button.closeIcon} />
						</button>
					</div>
					<div className={modal.body}>
						<div className={styles.content}>
							{heading && <h4 className={styles.heading}>{heading}</h4>}
							{Icon && (
								<div className={styles.figure}>
									<ConnectBg
										className={styles.bgImage}
										style={{ color: iconColor }}
									/>
									<div className={styles.center}>
										<Icon
											className={styles.icon}
											style={{ color: iconColor }}
										/>
									</div>
								</div>
							)}
							{subHeading && (
								<p className={styles.subHeading}>{subHeading}</p>
							)}
							{description && (
								<p className={styles.description}>{description}</p>
							)}
							<div className={styles.cta}>
								<button
									type="button"
									className={button.primary}
									onClick={() => {
										onConnect()
									}}
								>
									{buttonPrompt}
								</button>
							</div>
						</div>
					</div>
					{terms && (
						<div className={`${modal.footerCentered} ${modal.whiteBg}`}>
							<a href={terms.link}>{terms.text}</a>{" "}
						</div>
					)}
				</div>
			</div>
		</Modal>
	)
}

export default ConnectToSocial
