import React, { Component } from "react"
import { toast } from "react-toastify"
import button from "shared/styles/components/buttons.css"
import contentCard from "shared/styles/layout/content-card.css"
import YoutubeIcon from "shared/assets/youtube.svg"
import PinterestIcon from "shared/assets/pinterest-icon.svg"
import FacebookIcon from "shared/assets/facebook-circle-icon.svg"
// import InstagramIcon from "shared/assets/instagram-color.svg"
// import TwitterIcon from "shared/assets/twitter.svg"
import ConformationPrompt from "components/modals/conformation-prompt/conformation-prompt"
import gql from "../../../helpers/gql-helper"
import {
	connectToSocial,
	fetchSocialData,
} from "../../../services/social-service"
import { socialMedia as socialMediaData } from "../../../constants"
import styles from "./social-connections.css"

class SocialConnections extends Component {
	constructor(props) {
		super(props)
		this.state = {
			connectedToYouTube: false,
			connectedToPinterest: false,
			connectedToFacebook: false,
			connectedToInstagram: false,
			disconnectModal: null,
		}
		this.connectToSocialMediaPlatform = this.connectToSocialMediaPlatform.bind(
			this
		)
		this.setupConnectedState = this.setupConnectedState.bind(this)
	}

	componentDidMount() {
		this.setupConnectedState()
	}

	onConnectDisconnectClick(platform) {
		const {
			connectedToYouTube,
			connectedToPinterest,
			connectedToFacebook,
		} = this.state
		let status = null
		switch (platform) {
			case socialMediaData.youtube.key:
				status = !connectedToYouTube
				break
			case socialMediaData.pinterest.key:
				status = !connectedToPinterest
				break
			case socialMediaData.facebook.key:
				status = !connectedToFacebook
				break
			default:
				status = null
		}
		if (status) {
			this.connectToSocialMediaPlatform(platform)
		} else {
			this.setState({
				disconnectModal: {
					platform,
					disconnect: () => {
						this.connectToSocialMediaPlatform(platform)
						this.setState({ disconnectModal: null })
					},
				},
			})
		}
	}

	setupConnectedState() {
		const { socialMedia } = this.props
		const socialMediaObj = socialMedia.reduce(
			(acc, cur) => ({ ...acc, [cur.platform]: cur.isConnected }),
			{}
		)
		this.setState({
			connectedToYouTube: socialMediaObj[socialMediaData.youtube.key],
			connectedToPinterest: socialMediaObj[socialMediaData.pinterest.key],
			connectedToFacebook: socialMediaObj[socialMediaData.facebook.key],
			connectedToInstagram: socialMediaObj[socialMediaData.instagram.key],
		})
	}

	connectToSocialMediaPlatform(platform) {
		const { relay, storeId } = this.props
		const {
			connectedToYouTube,
			connectedToPinterest,
			connectedToFacebook,
			connectedToInstagram,
		} = this.state
		let status = null
		switch (platform) {
			case socialMediaData.youtube.key:
				status = !connectedToYouTube
				break
			case socialMediaData.pinterest.key:
				status = !connectedToPinterest
				break
			case socialMediaData.facebook.key:
				status = !connectedToFacebook
				break
			case socialMediaData.instagram.key:
				status = !connectedToInstagram
				break
			default:
				status = null
		}
		connectToSocial(
			relay,
			storeId,
			platform,
			status,
			() => {
				if (status) {
					fetchSocialData(relay)
						.then(() => {
							this.setupConnectedState()
						})
						.catch((e) => {
							this.errorMessage(e)
						})
				}
				let data = null
				switch (platform) {
					case socialMediaData.youtube.key:
						data = { connectedToYouTube: status }
						break
					case socialMediaData.pinterest.key:
						data = { connectedToPinterest: status }
						break
					case socialMediaData.facebook.key:
						data = { connectedToFacebook: status }
						break
					case socialMediaData.instagram.key:
						data = { connectedToInstagram: status }
						break
					default:
						status = null
				}
				if (data) this.setState(data)
			},
			(e) => this.errorMessage(e)
		)
	}

	errorMessage(e) {
		toast.info(<>{gql.getError(e)}</>, {
			autoClose: 5000,
			closeButton: false,
		})
	}

	render() {
		const {
			connectedToYouTube,
			connectedToPinterest,
			connectedToFacebook,
			disconnectModal,
		} = this.state
		return (
			<div className={contentCard.root}>
				<div className={contentCard.header}>
					<h4 className={contentCard.title}>Connected Social Accounts</h4>
				</div>
				<div className={contentCard.content}>
					<div className={styles.account}>
						<div className={styles.social}>
							<YoutubeIcon className={styles.iconYoutube} />
						</div>
						<div className={styles.meta}>
							<h4 className={styles.title}>YouTube</h4>
							<p className={styles.description}>
								Export Shopcasts with shoppable links
							</p>
						</div>
						<div className={styles.action}>
							<button
								className={
									connectedToYouTube ? button.subtle : button.primary
								}
								type="button"
								onClick={() =>
									this.onConnectDisconnectClick(
										socialMediaData.youtube.key
									)
								}
							>
								{connectedToYouTube ? "Disconnect" : "Connect"}
							</button>
						</div>
					</div>

					<div className={styles.account}>
						<div className={styles.social}>
							<PinterestIcon className={styles.iconPinterest} />
						</div>
						<div className={styles.meta}>
							<h4 className={styles.title}>Pinterest</h4>
							<p className={styles.description}>
								Share Shopboards to Pins
							</p>
						</div>
						<div className={styles.action}>
							<button
								className={
									connectedToPinterest ? button.subtle : button.primary
								}
								type="button"
								onClick={() => {
									this.onConnectDisconnectClick(
										socialMediaData.pinterest.key
									)
								}}
							>
								{connectedToPinterest ? "Disconnect" : "Connect"}
							</button>
						</div>
					</div>
					<div className={styles.account}>
						<div className={styles.social}>
							<FacebookIcon className={styles.iconFacebook} />
						</div>
						<div className={styles.meta}>
							<h4 className={styles.title}>Facebook</h4>
							<p className={styles.description}>
								Share Shopcasts & Shopboards to Facebook
							</p>
						</div>
						<div className={styles.action}>
							<button
								className={
									connectedToFacebook ? button.default : button.primary
								}
								type="button"
								onClick={() => {
									this.onConnectDisconnectClick(
										socialMediaData.facebook.key
									)
								}}
							>
								{connectedToFacebook ? "Disconnect" : "Connect"}
							</button>
						</div>
					</div>
				</div>
				<ConformationPrompt
					openModal={!!disconnectModal}
					closeModal={() => this.setState({ disconnectModal: null })}
					approveEvent={() => {
						disconnectModal.disconnect()
					}}
					declineEvent={() => this.setState({ disconnectModal: null })}
					title={`Disconnect from ${
						socialMediaData[disconnectModal && disconnectModal.platform]
							?.name
					}`}
					approveText="Disconnect"
					approveButtonStyle={button.primaryRed}
					declineText="Cancel"
				>
					Are you sure you want to disconnect the{" "}
					{
						socialMediaData[disconnectModal && disconnectModal.platform]
							?.name
					}{" "}
					account?
				</ConformationPrompt>
			</div>
		)
	}
}

export default SocialConnections
