import { commitMutation, graphql } from "react-relay"
import { complete } from "../helper.js"

const mutation = graphql`
	mutation toggleLookbookFavouriteMutation(
		$input: ToggleLookbookFavouriteInput!
	) {
		toggleLookbookFavourite(input: $input) {
			isFavourite
		}
	}
`

const commit = (env, category, itemId, teamId, onComplete, onError) =>
	commitMutation(env, {
		mutation,
		variables: { input: { category, itemId, teamId } },
		onComplete: () => complete(null, onComplete),
		onError: () => complete(null, onError),
		updater: (storeProxy, data) => {
			if (data.toggleLookbookFavourite) {
				const cardProxy = storeProxy.get(itemId)
				cardProxy.setValue(
					data.toggleLookbookFavourite.isFavourite,
					"isFavourite",
				)
				cardProxy.setValue(
					data.toggleLookbookFavourite.isFavourite,
					"isFavourite",
					{ teamId }
				)
			}
		},
	})

export default { commit }
