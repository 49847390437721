import React, { useState } from "react"
import { Transition } from "react-transition-group"
import { withDrag } from "helpers/dnd-helper"
import { CategoryENUM } from "helpers/enum-helper"
import PlusButton from "components/buttons/plus-button"
import styles from "./font-card.css"

function FontCardInner(props) {
	const [isShown, setIsShown] = useState(false)
	const { drop, showAddButton, dragRef, color, fontFamily } = props
	return (
		<div
			className={styles.root}
			onMouseEnter={() => setIsShown(true)}
			onMouseLeave={() => setIsShown(false)}
		>
			<div className={styles.media}>
				<Transition in={isShown && showAddButton} timeout={200}>
					{(state) => (
						<div className={`${styles.addAction} ${styles[state]}`}>
							<PlusButton onClick={() => drop(fontFamily)} />
						</div>
					)}
				</Transition>

				<div className={styles.figureImage}>
					<div className={styles.inner} ref={dragRef}>
						<h1
							className={styles.font}
							style={{ fontFamily, color }}
							id={fontFamily}
							data-fill={color}
						>
							AbCd
						</h1>
					</div>
				</div>
			</div>
			<div className={styles.content}>
				<h4 className={styles.title}>{fontFamily}</h4>
			</div>
		</div>
	)
}

function FontCard({ moveFrom, replaceable, ...otherProps }) {
	const Target = withDrag(FontCardInner, ({ fontFamily }) => ({
		type: CategoryENUM.FONT,
		item: {
			id: fontFamily,
			moveFrom,
			replaceable,
		},
		collect: (monitor) => ({ isDragging: monitor.isDragging() }),
	}))
	return <Target {...otherProps} />
}
export default FontCard
