import mixpanel from "mixpanel-browser"

const mixpanelService = {
	track: (eventName, eventProperties) => {
		mixpanel.track(eventName, eventProperties)
	},
	identify: (username) => {
		mixpanel.identify(username)
	},
	addUserProperties: (props) => {
		mixpanel.people.set(props)
	},
}

export default mixpanelService
