import React from 'react'
import { commitMutation, graphql } from "react-relay"
import { ConnectionHandler } from "relay-runtime"
import { toast } from "react-toastify"
import { complete } from "../helper.js"
import connections from "../connections"
import { getFeatureAvalibale } from "../../services/plan-services.js"
import UpgradePlanToast from "../../components/upgrade-plan-toast/upgrade-plan-toast.jsx"

const mutation = graphql`
	mutation toggleShopcastPublishedMutation(
		$input: ToggleShopcastPublishedInput!
	) {
		toggleShopcastPublished(input: $input) {
			output
		}
	}
`

const commit = async (env, shopcastId, parentId, containerId, remove, onError, team) => {
	if(!await getFeatureAvalibale("publish-shopcasts", env, team)){
		toast.info(<UpgradePlanToast />, { autoClose: 3000, closeButton: false })
		return null
	}
	return commitMutation(env, {
		mutation,
		variables: { input: { shopcastId } },
		optimisticResponse: { toggleShopcastPublished: { output: "" } },
		onError: () => complete(null, onError),
		updater: (storeProxy, data) => {
			if (data.toggleShopcastPublished) {
				const shopcastProxy = storeProxy.get(shopcastId)
				const wasPublished = shopcastProxy.getValue("isPublished")
				shopcastProxy.setValue(!wasPublished, "isPublished")
				const containerProxy = storeProxy.get(containerId)
				containerProxy.setValue(
					containerProxy.getValue("publishedCount") +
						(wasPublished ? -1 : 1),
					"publishedCount"
				)

				if (remove) {
					const folderProxy = storeProxy.get(parentId)
					const connection =
						connections.currentUser.shopcastManager.items
					const connectionProxy = ConnectionHandler.getConnection(
						folderProxy,
						connection.name,
						{
							...connection.data,
							type: "shopcast"
						}
					)
					ConnectionHandler.deleteNode(connectionProxy, shopcastId)
				}
			}
		},
	})
}

export default { commit }
