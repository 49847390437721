import React, { PureComponent } from "react"
import { createPaginationContainer, graphql } from "react-relay"
import ListOfProfiles from "../../../components/list-of-profiles/list-of-profiles.jsx"

/**
 * The purpose of this component is to wrap the data
 * requirements for a list of profiles in this case list of
 * infinity scrolling enabled profiles
 */

class SearchResultSharers extends PureComponent {
	render() {
		const { relay, store } = this.props
		return (
			<ListOfProfiles
				relay={relay}
				store={store}
				currentUser={store.currentUser}
				profiles={store.search.users}
				isRetailer={false}
			/>
		)
	}
}

const query = graphql`
	query searchResultSharersQuery(
		$count: Int!
		$cursor: String
		$keyword: String!
	) {
		store {
			...searchResultSharers_store
				@arguments(count: $count, cursor: $cursor, keyword: $keyword)
		}
	}
`

SearchResultSharers = createPaginationContainer(
	SearchResultSharers,
	{
		store: graphql`
			fragment searchResultSharers_store on Store
			@argumentDefinitions(
				count: { type: "Int", defaultValue: 12 }
				cursor: { type: "String" }
				keyword: { type: "String!" }
			) {
				id
				currentUser {
					id
					adminAccess
				}
				search(keyword: $keyword) {
					users(first: $count, after: $cursor)
						@connection(key: "search_users") {
						edges {
							node {
								id
								fullname
								displayName
								username
								customDomain
								isFollowing
								profilePhoto
								bannerPhoto
								isRecommended
							}
						}
					}
				}
			}
		`,
	},
	{
		direction: "forward",
		getConnectionFromProps(props) {
			const { search } = props.store
			return search && search.users
		},
		getFragmentVariables(prevVars, totalCount) {
			return {
				...prevVars,
				count: totalCount,
			}
		},
		getVariables(props, { count, cursor }) {
			return {
				count,
				cursor,
				keyword: props.keyword,
			}
		},
		query,
	}
)

export default {
	Component: SearchResultSharers,
	query,
	params: { count: 12, cursor: null, keyword: null },
}
