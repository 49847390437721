import React, { Component, Fragment } from "react"
import ReactPlayer from "react-player"
import { SpeakerOffIcon, TrashIcon } from "@radix-ui/react-icons"
import vTime from "video-time"
import { addVersioning } from "helpers/query-parameters-helper"
import { MediaQuery } from "helpers/ui-helper"
import { commitLocalUpdate, ConnectionHandler } from "relay-runtime"
import icons from "shared/styles/components/icons.css"
import PlayIcon from "shared/assets/play-icon.svg"
// import ArrowBackIcon from "shared/assets/back-icon.svg"
import ShoppingBagIcon from "shared/assets/shopping-bag-icon.svg"
import DescriptionIcon from "shared/assets/description-icon.svg"
import PauseIcon from "shared/assets/pause-icon.svg"
import TrackViewedMutation from "mutations/shopcast/track-viewed"
import {
	doFullscreen,
	doExitFullscreen,
	isFullscreen,
	listenToFullscreenChange,
	removeFullscreenChangeListener,
} from "services/fullscreen-service"
import ReportShopcastModal from "components/modals/report-shopcast/report-shopcast.jsx"
import connections from "mutations/connections"
import Play from "./play/play"
import Volume from "./volume/volume"
import Fullscreen from "./fullscreen/fullscreen"
import CaptionButton from "./caption/caption"
import styles from "./player.css"
import MobileOverlay from "../mobile-overlay/mobile-overlay"
import { publicViewDomain } from "../../constants"

class Player extends Component {
	constructor(props) {
		super(props)
		this.playButtonClicked = this.playButtonClicked.bind(this)
		this.toggleFullScreen = this.toggleFullScreen.bind(this)
		this.state = {
			muted: false,
			played: 0,
			playedSeconds: 0,
			loaded: 0,
			seeking: false,
			duration: 0,
			showReportModal: false,
			tapToUnmute: false,
			isFullscreen: false,
			mouseOver: false,
			isViewedTracked: false,
			subtitleOn: false,
			selectedProductForScrubbing: null,
		}
		this.saveSelectedProductToStore = this.saveSelectedProductToStore.bind(
			this
		)
		props.setRef({
			getPlayerState: () => this.state,
		})
		this.urls = [
			{ src: props.shopcast.mp4Url, type: "application/x-mpegURL" },
			{ src: props.shopcast.muxedUrl, type: "video/webm" },
		]
	}

	componentDidMount() {
		listenToFullscreenChange(this.fullscreenChangeHandler.bind(this))
	}

	componentDidUpdate(prevProps) {
		const { props, state } = this

		if (props.goToTime !== prevProps.goToTime && state.duration > 0) {
			this.player.seekTo(parseFloat(props.goToTime) + 0.1, "seconds")
		}
	}

	componentWillUnmount() {
		removeFullscreenChangeListener(this.fullscreenChangeHandler.bind(this))
	}

	getBottomButtons() {
		const {
			shopcast,
			triggerProductPanel,
			triggerDescriptionPanel,
		} = this.props
		return [
			{
				key: "description",
				onClick: () => triggerDescriptionPanel(),
				icon: <DescriptionIcon className={styles.actionItemIcon} />,
				actionItemClass: styles.actionItemButton,
			},
			{
				key: "products",
				onClick: () => triggerProductPanel(),
				icon: <ShoppingBagIcon className={styles.actionItemIcon} />,
				label: shopcast?.shopcastItems?.edges.length || 0,
			},
		]
	}

	getProductElement = (shopcastItem) => {
		const { props } = this
		const { product } = shopcastItem
		const xPosition =
			(shopcastItem.start * 100) / props.shopcast.duration.toFixed(2)
		const style = { left: `${xPosition}%` }
		return (
			<div key={product.id} style={style} className={styles.productItem} />
		)
	}

	getProductElementScrubber = (shopcastItems) => {
		const { props } = this
		const { played } = this.state
		const items = [...shopcastItems].sort(
			(a, b) => a.node.start - b.node.start
		)
		const playedDuration = played * props.shopcast.duration
		const shopcastItem = items.find(
			(item, index) =>
				item.node.start <= playedDuration &&
				(shopcastItems.length > index + 1
					? items[index + 1].node.start
					: props.shopcast.duration) >= playedDuration
		)
		const product = shopcastItem ? shopcastItem.node.product : null
		const xPosition = played * 100
		const rootBounds = this.shopcastPlayer?.getBoundingClientRect()
		const productTipBounds = this.productTipRef?.getBoundingClientRect()
		const seekInputBounds = this.seekInput?.getBoundingClientRect()

		const PADDING = 10

		const getLeftVal = () => {
			if (!rootBounds || !productTipBounds || !seekInputBounds) return 0
			const playedPx = seekInputBounds.left + seekInputBounds.width * played
			if (playedPx + productTipBounds.width / 2 > rootBounds.width - PADDING)
				return `${
					rootBounds.width -
					productTipBounds.width / 2 -
					seekInputBounds.left -
					PADDING
				}px`
			if (playedPx - productTipBounds.width / 2 < PADDING)
				return `${Math.abs(
					productTipBounds.width / 2 - seekInputBounds.left + PADDING
				)}px`
			return `${xPosition}%`
		}

		return (
			<div
				style={{
					left: getLeftVal(),
				}}
				className={styles.productItem}
			>
				<div
					className={styles.productTip}
					ref={(x) => {
						this.productTipRef = x
					}}
				>
					{product && (
						<div className={styles.productTipFigure}>
							<img
								className={styles.productImage}
								src={addVersioning(product.imageUrl, `d=400`)}
								onError={(e) => {
									if (e.target.fb) {
										return
									}
									e.target.src = product.imageUrl
									e.target.fb = true
								}}
								alt={product.title ? product.title : "No title"}
							/>
						</div>
					)}
					<div className={styles.productTimecode}>
						{vTime(played * props.shopcast.duration)}
					</div>
				</div>
			</div>
		)
	}

	getEditableProductElement = (shopcastItem) => {
		const { shopcast, enableAddProduct } = this.props
		const { selectedProductForScrubbing, played } = this.state
		const { product } = shopcastItem
		const inputOptions = {
			thumbSize: 32,
		}
		const style = {
			left:
				selectedProductForScrubbing === product.id
					? `calc((100% - ${inputOptions.thumbSize}px) * ${played})`
					: `calc((100% - ${inputOptions.thumbSize}px) * ${
							shopcastItem.start / shopcast.duration.toFixed(2)
					  })`,
		}
		const minMarker = (shopcastItem.start / shopcast.duration) * 100
		return (
			<Fragment key={product.id}>
				<input
					className={styles.seekProduct}
					style={{
						"--product-thumb-size": `${inputOptions.thumbSize}px`,
						"--product-image": `url(${product.imageUrl})`,
					}}
					type="range"
					defaultValue={minMarker}
					min="0"
					max="100"
					step="any"
					autoComplete="off"
					onMouseDown={() => {
						this.setState({ seeking: true })
					}}
					onFocus={() => {
						this.setState({ seeking: true })
					}}
					onTouchStart={() => {
						this.setState({ seeking: true })
					}}
					onChange={(e) => {
						this.setState({
							played: parseFloat(e.target.value / 100),
							selectedProductForScrubbing: product.id,
						})
						this.player.seekTo(parseFloat(e.target.value) / 100)
					}}
					onMouseUp={(e) => {
						this.saveSelectedProductToStore({
							nodeId: shopcastItem.id,
							start: parseFloat(
								(e.target.value / 100) * shopcast.duration
							),
							end: parseFloat(
								((e.target.value + 1) / 100) * shopcast.duration
							),
						})
					}}
					onTouchEnd={(e) => {
						this.saveSelectedProductToStore({
							nodeId: shopcastItem.id,
							start: parseFloat(
								(e.target.value / 100) * shopcast.duration
							),
							end: parseFloat(
								((e.target.value + 1) / 100) * shopcast.duration
							),
						})
					}}
					onBlur={(e) => {
						this.saveSelectedProductToStore({
							nodeId: shopcastItem.id,
							start: parseFloat(
								(e.target.value / 100) * shopcast.duration
							),
							end: parseFloat(
								((e.target.value + 1) / 100) * shopcast.duration
							),
						})
					}}
				/>
				<div style={style} className={styles.productItem}>
					{enableAddProduct && (
						<button
							style={{
								"--btn-size": `${inputOptions.thumbSize}px`,
							}}
							type="button"
							className={styles.deleteBtn}
							onClick={() => {
								this.deleteProductFromStore(shopcastItem.id)
							}}
						>
							<TrashIcon className={icons.icon18} />
						</button>
					)}
				</div>
			</Fragment>
		)
	}

	saveSelectedProductToStore(product) {
		const { relay } = this.props
		this.setState({
			seeking: false,
			selectedProductForScrubbing: null,
		})
		commitLocalUpdate(relay.environment, (storeProxy) => {
			const itemProxy = storeProxy.get(product.nodeId)
			itemProxy.setValue(product.start, "start")
			itemProxy.setValue(product.end, "end")
		})
	}

	deleteProductFromStore(nodeId) {
		const { relay, shopcast } = this.props
		commitLocalUpdate(relay.environment, (storeProxy) => {
			const shopcastProxy = storeProxy.get(shopcast.id)
			const productsItemsConnection = ConnectionHandler.getConnection(
				shopcastProxy,
				connections.shopcast.products.name,
				connections.shopcast.products.data
			)
			ConnectionHandler.deleteNode(productsItemsConnection, nodeId)
		})
	}

	trackControls() {
		const { played, loaded, seeking } = this.state
		const { shopcast, enableAddProduct } = this.props
		const shopcastItems = shopcast.shopcastItems.edges || []
		return (
			<div className={styles.trackControls}>
				<div className={styles.progress}>
					<input
						ref={(input) => {
							this.seekInput = input
						}}
						className={styles.seek}
						type="range"
						style={{ "--seek-value": `${played * 100}%` }}
						min="0"
						max="100"
						step="any"
						value={played * 100}
						autoComplete="off"
						onMouseDown={() => {
							this.setState({ seeking: true })
						}}
						onFocus={() => {
							this.setState({ seeking: true })
						}}
						onTouchStart={() => {
							this.setState({ seeking: true })
						}}
						onChange={(e) => {
							this.setState({ played: parseFloat(e.target.value / 100) })
							this.player.seekTo(parseFloat(e.target.value) / 100)
						}}
						onMouseUp={() => {
							this.setState({ seeking: false })
						}}
						onBlur={() => {
							this.setState({ seeking: false })
						}}
						onTouchEnd={() => {
							this.setState({ seeking: false })
						}}
					/>
					<progress
						className={styles.played}
						max={100}
						value={played * 100}
					/>
					<progress
						className={styles.buffer}
						max={100}
						value={loaded * 100}
					/>
					{shopcastItems.map(({ node }) =>
						enableAddProduct
							? this.getEditableProductElement(node)
							: this.getProductElement(node)
					)}
					{!enableAddProduct &&
						seeking &&
						this.getProductElementScrubber(shopcastItems)}
				</div>
			</div>
		)
	}

	fullscreenChangeHandler() {
		if (isFullscreen() === false) {
			this.setState({ isFullscreen: false })
		}
	}

	// showReportModal(showHide) {
	// 	this.setState({ showReportModal: showHide })
	// }

	playButtonClicked() {
		const { videoIsPlaying, isPlaying } = this.props
		videoIsPlaying(!isPlaying)
	}

	dispatchPlayerCurrentTimeChanged(data) {
		const { props, state } = this

		if (!this.player) return
		if (!state.isViewedTracked && props.isPlaying) {
			TrackViewedMutation.commit(
				props.relay.environment,
				props.store.shopcast.id,
				props.store.id,
				() => {},
				() => {}
			)
			this.setState({ isViewedTracked: true })
		}

		if (state.playedSeconds !== data.playedSeconds)
			props.videoPlayTimeChanged(data.playedSeconds)
		this.setState(data)
	}

	toggleFullScreen() {
		if (isFullscreen() === true) {
			doExitFullscreen()
			this.setState({ isFullscreen: false })
		} else {
			doFullscreen(this.shopcastPlayer, this.video)
			this.setState({ isFullscreen: true })
		}
	}

	desktopControls() {
		const { props, state } = this
		const { subtitleOn } = state
		const { subtitle } = props
		return (
			<div className={styles.desktopControls}>
				<div className={styles.playback}>
					<Play
						clicked={this.playButtonClicked}
						isPlaying={props.isPlaying}
					/>
					<Volume
						isMute={state.muted}
						toggleMute={() => {
							this.setState({ muted: !state.muted })
						}}
					/>
					<div className={styles.timestamp}>
						<span className={styles.timeCurrent}>
							{vTime(state.played * props.shopcast.duration)}
						</span>
						<span className={styles.timeDivider}>/</span>
						<span className={styles.timeDuration}>
							{vTime(
								state.duration > 0
									? state.duration
									: props.shopcast.duration
							)}
						</span>
					</div>
				</div>
				<div className={styles.settings}>
					{subtitle && (
						<CaptionButton
							isCaption={subtitleOn}
							toggleCaptions={() =>
								this.setState({ subtitleOn: !subtitleOn }, () => {
									const { subtitleOn: stateSubtitleOn } = this.state
									const tracks = this.player.getInternalPlayer()
										?.textTracks
									if (tracks && tracks.length > 0) {
										tracks[0].mode = stateSubtitleOn
											? "showing"
											: "hidden"
									}
								})
							}
						/>
					)}
					<Fullscreen
						toggleFullscreen={this.toggleFullScreen}
						isFullscreen={state.isFullscreen}
					/>
					{/* {props.shopcast.user.canEdit !== true && <Flagged shopcast={props.shopcast} size="lg" currentUser={props.currentUser} showReportModal={this.showReportModal} />} */}
				</div>
			</div>
		)
	}

	// renderHeader() {
	// 	const { state } = this
	// 	const {
	// 		extra: { renderActionMenu },
	// 		enableAddProduct,
	// 	} = this.props
	// 	if (enableAddProduct) return null
	// 	return (
	// 		<div className={styles.header}>
	// 			<div className={styles.back}>
	// 				<ArrowBackIcon className={styles.backIcon} />
	// 			</div>
	// 			<Volume
	// 				isMute={state.muted}
	// 				size="sm"
	// 				toggleMute={() => {
	// 					this.setState({ muted: !state.muted })
	// 				}}
	// 			/>
	// 			{renderActionMenu(styles.menu, styles.menuActive)}
	// 		</div>
	// 	)
	// }

	render() {
		const { props, state } = this
		const { muted, subtitleOn, seeking, mouseOver } = state
		const { subtitle, enableAddProduct } = props

		return (
			<div
				ref={(i) => {
					this.shopcastPlayer = i
				}}
				className={styles.root}
				onMouseOver={() => {
					this.setState({ mouseOver: true })
				}}
				onFocus={() => {
					this.setState({ mouseOver: true })
				}}
				onMouseOut={() => {
					this.setState({ mouseOver: false })
				}}
				onBlur={() => {
					this.setState({ mouseOver: false })
				}}
			>
				<div className={styles.videoWrapper}>
					{/* <div className={styles.topGradient} /> */}
					{seeking && <div className={styles.backdrop} />}
					<div
						className={styles.videoOverlay}
						onClick={this.playButtonClicked}
						onKeyPress={this.playButtonClicked}
						role="button"
						tabIndex={0}
					>
						{props.isPlaying ? (
							<button type="button" className={styles.playLarge}>
								{" "}
								<PauseIcon className={styles.playLargePauseIcon} />{" "}
							</button>
						) : (
							<button type="button" className={styles.pauseLarge}>
								{" "}
								<PlayIcon className={styles.playLargeIcon} />
							</button>
						)}
					</div>
					{state.tapToUnmute && (
						<button
							type="button"
							className={styles.tapToUnmute}
							onClick={() => {
								this.setState({ muted: false, tapToUnmute: false })
							}}
							onKeyPress={() => {
								this.setState({ muted: false, tapToUnmute: false })
							}}
							tabIndex={0}
						>
							<SpeakerOffIcon className={styles.tapToUnmuteIcon} /> Tap
							to Unmute
						</button>
					)}

					<div className={styles.videoPlayer}>
						<ReactPlayer
							ref={(ref) => {
								this.player = ref
							}}
							url={this.urls}
							playing={props.isPlaying}
							muted={muted}
							className={styles.source}
							width="100%"
							playsinline
							height="100%"
							progressInterval={24}
							onStart={() => {
								const { goToTime } = this.props
								const { played } = this.state
								if (goToTime && played === 0)
									this.player.seekTo(
										parseFloat(goToTime) + 0.1,
										"seconds"
									)
							}}
							onProgress={(playerState) => {
								const { seeking: s } = this.state
								if (!s)
									this.dispatchPlayerCurrentTimeChanged(playerState)
							}}
							onDuration={(durationValue) => {
								if (durationValue && durationValue !== Infinity)
									this.setState({ duration: durationValue })
							}}
							onEnded={() => {
								props.videoIsPlaying(false)
								if (isFullscreen()) this.toggleFullScreen()
							}}
							onError={() => {
								if (props.isPlaying) {
									props.videoIsPlaying(false)
									this.setState(
										{ muted: true, tapToUnmute: true },
										() => {
											props.videoIsPlaying(true)
										}
									)
								}
							}}
							config={
								subtitle
									? {
											file: {
												attributes: {
													crossOrigin: "anonymous",
													preload: "auto",
												},
												tracks: [
													{
														kind: "subtitles",
														src: subtitle,
														srcLang: "en",
														default: false,
														mode: subtitleOn
															? "showing"
															: "hidden",
													},
												],
											},
									  }
									: {}
							}
						/>
					</div>
					{props.shopcast && (
						<div className={styles.videoControls}>
							{/* {!seeking && this.renderHeader()} */}
							{!seeking && !enableAddProduct && (
								<MobileOverlay
									actions={props.extra.getActionMenu()}
									item={{
										title: props.shopcast.title,
										user: {
											profilePhoto: props.shopcast.user.profilePhoto,
											fullname: props.shopcast.user.username,
											domain:
												props.shopcast.user.customDomain ||
												`${props.shopcast.user.username}.${publicViewDomain}`,
										},
									}}
									bottomButtons={this.getBottomButtons()}
									topButtons={
										<Volume
											isMute={state.muted}
											size="sm"
											toggleMute={() => {
												this.setState({ muted: !state.muted })
											}}
										/>
									}
									backOnClick={() => props.extra.goBack()}
									cssClasses={{
										header: styles.header,
										mainInner: styles.content,
										channel: styles.channel,
										channelTitle: styles.channelTitle,
									}}
								/>
							)}
							<div
								className={`${styles.playerControls} ${
									!mouseOver &&
									props.isPlaying &&
									styles.playerControlsOut
								}`}
							>
								{this.trackControls()}
								{enableAddProduct ? (
									this.desktopControls()
								) : (
									<MediaQuery query={styles["breakpoint-sm"]}>
										{this.desktopControls()}
									</MediaQuery>
								)}
							</div>
						</div>
					)}
				</div>

				{props.shopcast && (
					<ReportShopcastModal
						shopcast={props.shopcast}
						closeModal={() => {
							this.setState({ showReportModal: false })
						}}
						showEmbedModal={state.showReportModal}
						relay={props.relay}
						storeId={props.storeId}
					/>
				)}
			</div>
		)
	}
}

export default Player
