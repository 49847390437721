/**
 * @generated SignedSource<<8006568841627b50cdd6bdfa5c4ab778>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Mutation } from 'relay-runtime';
export type UploadDownloadedLookbookToFacebookMutationInput = {|
  accessToken: string,
  clientMutationId?: ?string,
  description?: ?string,
  lookbookId: string,
  pageId: string,
  platform: string,
  s3Key: string,
|};
export type uploadDownloadedLookbookFacebookMutation$variables = {|
  input: UploadDownloadedLookbookToFacebookMutationInput,
|};
export type uploadDownloadedLookbookFacebookMutation$data = {|
  +uploadDownloadedLookbookToFacebook: ?{|
    +facebookId: ?string,
    +store: ?{|
      +id: string,
    |},
  |},
|};
export type uploadDownloadedLookbookFacebookMutation = {|
  response: uploadDownloadedLookbookFacebookMutation$data,
  variables: uploadDownloadedLookbookFacebookMutation$variables,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UploadDownloadedLookbookToFacebookMutationPayload",
    "kind": "LinkedField",
    "name": "uploadDownloadedLookbookToFacebook",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Store",
        "kind": "LinkedField",
        "name": "store",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "facebookId",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "uploadDownloadedLookbookFacebookMutation",
    "selections": (v1/*: any*/),
    "type": "ShopshareMutationRoot",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "uploadDownloadedLookbookFacebookMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "7625eef73912f75d79e08268fa82ef98",
    "id": null,
    "metadata": {},
    "name": "uploadDownloadedLookbookFacebookMutation",
    "operationKind": "mutation",
    "text": "mutation uploadDownloadedLookbookFacebookMutation(\n  $input: UploadDownloadedLookbookToFacebookMutationInput!\n) {\n  uploadDownloadedLookbookToFacebook(input: $input) {\n    store {\n      id\n    }\n    facebookId\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "a6d7567cf968c34f3095215d3abb2b76";

module.exports = ((node/*: any*/)/*: Mutation<
  uploadDownloadedLookbookFacebookMutation$variables,
  uploadDownloadedLookbookFacebookMutation$data,
>*/);
