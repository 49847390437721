import React, { Component } from "react"
import Modal from "react-modal"
import { Cross2Icon, UploadIcon, InputIcon } from "@radix-ui/react-icons"
// import UploadFeatureIcon from "shared/assets/feature-icon-upload.svg"
import modal from "shared/styles/components/modal.css"
import button from "shared/styles/components/buttons.css"
import styles from "./upload-select.css"

class UploadSelect extends Component {
	constructor(props) {
		super(props)
		this.state = {}
	}

	render() {
		const {
			closeModal,
			openModal,
			onImageUploadClick,
			onClipsClick,
		} = this.props
		return (
			<Modal
				className={modal.root}
				overlayClassName={modal.overlay}
				htmlOpenClassName="ReactModal__Html--open"
				isOpen={openModal}
				ariaHideApp={false}
			>
				<div className={`${modal.dialog} ${modal.dialogCentered}`}>
					<div className={modal.content}>
						<div className={modal.body}>
							<button
								type="button"
								className={button.closeTopRight}
								onClick={closeModal}
							>
								<Cross2Icon className={button.closeIcon} />
							</button>
							<h4 className={styles.title}>
								How would you like to add images?
							</h4>
							<div className={styles.group}>
								<button
									type="button"
									className={styles.button}
									onClick={onImageUploadClick}
								>
									<div className={styles.icon}>
										{/* <UploadFeatureIcon className={styles.svg} /> */}
										<UploadIcon className={styles.svg} />
									</div>
									<div className={styles.label}>From your device</div>
								</button>

								<button
									type="button"
									className={styles.button}
									onClick={onClipsClick}
								>
									<div className={styles.icon}>
										{/* <UploadFeatureIcon className={styles.svg} /> */}
										<InputIcon className={styles.svg} />
									</div>
									<div className={styles.label}>Using a URL</div>
								</button>
							</div>
						</div>
					</div>
				</div>
			</Modal>
		)
	}
}

export default UploadSelect
