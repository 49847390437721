/**
 * @generated SignedSource<<c0ebec579345d014e3628fd8b4a1ef5e>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Query } from 'relay-runtime';
import type { signup_store$fragmentType } from "./signup_store.graphql";
export type signupQuery$variables = {|
  code: string,
|};
export type signupQuery$data = {|
  +store: ?{|
    +$fragmentSpreads: signup_store$fragmentType,
  |},
|};
export type signupQuery = {|
  response: signupQuery$data,
  variables: signupQuery$variables,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "code"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "signupQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Store",
        "kind": "LinkedField",
        "name": "store",
        "plural": false,
        "selections": [
          {
            "args": [
              {
                "kind": "Variable",
                "name": "code",
                "variableName": "code"
              }
            ],
            "kind": "FragmentSpread",
            "name": "signup_store"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "ShopshareQueryRoot",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "signupQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Store",
        "kind": "LinkedField",
        "name": "store",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": [
              {
                "kind": "Literal",
                "name": "first",
                "value": 1000
              }
            ],
            "concreteType": "StoreCountryConnection",
            "kind": "LinkedField",
            "name": "countries",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "StoreCountryEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Country",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "name",
                        "storageKey": null
                      },
                      (v1/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": "countries(first:1000)"
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PricingPlan",
            "kind": "LinkedField",
            "name": "pricingPlans",
            "plural": true,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "amount",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "currentUser",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "token",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": [
              {
                "kind": "Variable",
                "name": "token",
                "variableName": "code"
              }
            ],
            "kind": "ScalarField",
            "name": "emailVerificationToken",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "84e22c2d52849fcbd4cc89b9b739c7e2",
    "id": null,
    "metadata": {},
    "name": "signupQuery",
    "operationKind": "query",
    "text": "query signupQuery(\n  $code: String!\n) {\n  store {\n    ...signup_store_4d58ER\n    id\n  }\n}\n\nfragment signup_store_4d58ER on Store {\n  id\n  countries(first: 1000) {\n    edges {\n      node {\n        name\n        id\n      }\n    }\n  }\n  pricingPlans {\n    id\n    amount\n  }\n  currentUser {\n    id\n    token\n  }\n  emailVerificationToken(token: $code)\n}\n"
  }
};
})();

(node/*: any*/).hash = "b873c6241687b6149973cd34b8dd2131";

module.exports = ((node/*: any*/)/*: Query<
  signupQuery$variables,
  signupQuery$data,
>*/);
