/**
 * @generated SignedSource<<7c10eb53a7cbc526348a1fcec597598f>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Mutation } from 'relay-runtime';
export type TeamMemberRoleType = "ADMIN" | "MEMBER" | "OWNER" | "%future added value";
export type AddTeamMemberInput = {|
  clientMutationId?: ?string,
  email: string,
  name?: ?string,
  role?: ?string,
  teamId?: ?string,
|};
export type addTeamMemberMutation$variables = {|
  input: AddTeamMemberInput,
|};
export type addTeamMemberMutation$data = {|
  +addTeamMember: ?{|
    +addedMember: ?{|
      +email: ?string,
      +fullname: ?string,
      +id: string,
      +isInviteAccepted: ?boolean,
      +role: ?TeamMemberRoleType,
    |},
    +addedTeam: ?{|
      +id: string,
      +name: ?string,
    |},
    +store: ?{|
      +id: string,
    |},
  |},
|};
export type addTeamMemberMutation = {|
  response: addTeamMemberMutation$data,
  variables: addTeamMemberMutation$variables,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "AddTeamMemberPayload",
    "kind": "LinkedField",
    "name": "addTeamMember",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Store",
        "kind": "LinkedField",
        "name": "store",
        "plural": false,
        "selections": [
          (v1/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "teamMember",
        "kind": "LinkedField",
        "name": "addedMember",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "fullname",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "email",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "role",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isInviteAccepted",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "team",
        "kind": "LinkedField",
        "name": "addedTeam",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "addTeamMemberMutation",
    "selections": (v2/*: any*/),
    "type": "ShopshareMutationRoot",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "addTeamMemberMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "3793df1e1bc10d8b482b0cc9f9855246",
    "id": null,
    "metadata": {},
    "name": "addTeamMemberMutation",
    "operationKind": "mutation",
    "text": "mutation addTeamMemberMutation(\n  $input: AddTeamMemberInput!\n) {\n  addTeamMember(input: $input) {\n    store {\n      id\n    }\n    addedMember {\n      id\n      fullname\n      email\n      role\n      isInviteAccepted\n    }\n    addedTeam {\n      id\n      name\n    }\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "f468c1421043091b1d0e5a10e175ad87";

module.exports = ((node/*: any*/)/*: Mutation<
  addTeamMemberMutation$variables,
  addTeamMemberMutation$data,
>*/);
