/**
 * @generated SignedSource<<32032b1382116c3f21a74056d51b3dbe>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Mutation } from 'relay-runtime';
export type SubToPricingPlanInput = {|
  clientMutationId?: ?string,
  couponCode?: ?string,
  planId?: ?string,
  setupIntent?: ?string,
|};
export type subToPricingPlanMutation$variables = {|
  input: SubToPricingPlanInput,
|};
export type subToPricingPlanMutation$data = {|
  +subToPricingPlan: ?{|
    +plan: ?{|
      +amount: ?number,
      +currency: ?string,
      +id: ?string,
      +nickname: ?string,
    |},
    +store: ?{|
      +currentUser: ?{|
        +features: ?$ReadOnlyArray<?string>,
        +trialEnd: ?string,
        +trialStart: ?string,
        +userPlan: ?{|
          +amount: ?number,
          +id: ?string,
          +nickname: ?string,
        |},
      |},
    |},
  |},
|};
export type subToPricingPlanMutation = {|
  response: subToPricingPlanMutation$data,
  variables: subToPricingPlanMutation$variables,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "amount",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "nickname",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "PricingPlan",
  "kind": "LinkedField",
  "name": "plan",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    (v3/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "currency",
      "storageKey": null
    },
    (v4/*: any*/)
  ],
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "PricingPlan",
  "kind": "LinkedField",
  "name": "userPlan",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    (v3/*: any*/),
    (v4/*: any*/)
  ],
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "features",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "trialStart",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "trialEnd",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "subToPricingPlanMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SubToPricingPlanPayload",
        "kind": "LinkedField",
        "name": "subToPricingPlan",
        "plural": false,
        "selections": [
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Store",
            "kind": "LinkedField",
            "name": "store",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "User",
                "kind": "LinkedField",
                "name": "currentUser",
                "plural": false,
                "selections": [
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "ShopshareMutationRoot",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "subToPricingPlanMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SubToPricingPlanPayload",
        "kind": "LinkedField",
        "name": "subToPricingPlan",
        "plural": false,
        "selections": [
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Store",
            "kind": "LinkedField",
            "name": "store",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "User",
                "kind": "LinkedField",
                "name": "currentUser",
                "plural": false,
                "selections": [
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "33de456a80a5b1e97f43997d1d3eb12c",
    "id": null,
    "metadata": {},
    "name": "subToPricingPlanMutation",
    "operationKind": "mutation",
    "text": "mutation subToPricingPlanMutation(\n  $input: SubToPricingPlanInput!\n) {\n  subToPricingPlan(input: $input) {\n    plan {\n      id\n      amount\n      currency\n      nickname\n    }\n    store {\n      currentUser {\n        userPlan {\n          id\n          amount\n          nickname\n        }\n        features\n        trialStart\n        trialEnd\n        id\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "00e4017308ce62bb7a3187d906f28d49";

module.exports = ((node/*: any*/)/*: Mutation<
  subToPricingPlanMutation$variables,
  subToPricingPlanMutation$data,
>*/);
