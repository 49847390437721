/**
 * @generated SignedSource<<d9c31b5da6596c782c6268618bf01264>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Query } from 'relay-runtime';
import type { clientViewFolder_store$fragmentType } from "./clientViewFolder_store.graphql";
export type clientViewFolderQuery$variables = {|
  folder: string,
|};
export type clientViewFolderQuery$data = {|
  +store: ?{|
    +id: string,
    +$fragmentSpreads: clientViewFolder_store$fragmentType,
  |},
|};
export type clientViewFolderQuery = {|
  response: clientViewFolderQuery$data,
  variables: clientViewFolderQuery$variables,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "folder"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "kind": "Variable",
    "name": "folderId",
    "variableName": "folder"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "clientViewFolderQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Store",
        "kind": "LinkedField",
        "name": "store",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "args": [
              {
                "kind": "Variable",
                "name": "folder",
                "variableName": "folder"
              }
            ],
            "kind": "FragmentSpread",
            "name": "clientViewFolder_store"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "ShopshareQueryRoot",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "clientViewFolderQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Store",
        "kind": "LinkedField",
        "name": "store",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "currentUser",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ShopcastContainer",
                "kind": "LinkedField",
                "name": "shopcastContainer",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": [
                      {
                        "kind": "Variable",
                        "name": "id",
                        "variableName": "folder"
                      }
                    ],
                    "concreteType": "ShopcastFolder",
                    "kind": "LinkedField",
                    "name": "folder",
                    "plural": false,
                    "selections": [
                      (v1/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "name",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v1/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v2/*: any*/),
                "kind": "ScalarField",
                "name": "sharedFolderShopcastsCount",
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v2/*: any*/),
                "kind": "ScalarField",
                "name": "sharedFolderLookbooksCount",
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v2/*: any*/),
                "kind": "ScalarField",
                "name": "sharedFolderFilesCount",
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v2/*: any*/),
                "kind": "ScalarField",
                "name": "sharedFolderCollectionsListCount",
                "storageKey": null
              },
              (v1/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "f0a8c2b12379601f05a72963019131b2",
    "id": null,
    "metadata": {},
    "name": "clientViewFolderQuery",
    "operationKind": "query",
    "text": "query clientViewFolderQuery(\n  $folder: String!\n) {\n  store {\n    id\n    ...clientViewFolder_store_2iBWCY\n  }\n}\n\nfragment clientViewFolder_store_2iBWCY on Store {\n  currentUser {\n    shopcastContainer {\n      folder(id: $folder) {\n        id\n        name\n      }\n      id\n    }\n    sharedFolderShopcastsCount(folderId: $folder)\n    sharedFolderLookbooksCount(folderId: $folder)\n    sharedFolderFilesCount(folderId: $folder)\n    sharedFolderCollectionsListCount(folderId: $folder)\n    id\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "042d8bebcba837b8fbba2a3013bdbed8";

module.exports = ((node/*: any*/)/*: Query<
  clientViewFolderQuery$variables,
  clientViewFolderQuery$data,
>*/);
