/**
 * @generated SignedSource<<fb4b7569219c67fd55f6d5cf83430186>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { Fragment, ReaderFragment } from 'relay-runtime';
export type userEnumType = "CLIENT" | "RETAILER" | "STYLIST" | "%future added value";
import type { FragmentType } from "relay-runtime";
declare export opaque type userMenu_store$fragmentType: FragmentType;
export type userMenu_store$data = {|
  +currentUser: ?{|
    +adminAccess: ?string,
    +bannerPhoto: ?string,
    +customDomain: ?string,
    +email: ?string,
    +fullname: ?string,
    +hasPendingSharerRequests: ?number,
    +id: string,
    +isRetailer: ?boolean,
    +optOutAffiliate: ?boolean,
    +personalPlan: ?{|
      +id: ?string,
    |},
    +profilePhoto: ?string,
    +role: ?string,
    +teams: ?$ReadOnlyArray<?{|
      +avatar: ?string,
      +id: string,
      +name: ?string,
    |}>,
    +type: ?userEnumType,
    +username: ?string,
  |},
  +id: string,
  +$fragmentType: userMenu_store$fragmentType,
|};
export type userMenu_store$key = {
  +$data?: userMenu_store$data,
  +$fragmentSpreads: userMenu_store$fragmentType,
  ...
};
*/

var node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "userMenu_store",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "currentUser",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "fullname",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "username",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "email",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "customDomain",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "profilePhoto",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "bannerPhoto",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "adminAccess",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isRetailer",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "type",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "role",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "hasPendingSharerRequests",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "optOutAffiliate",
          "storageKey": null
        },
        {
          "alias": "personalPlan",
          "args": null,
          "concreteType": "PricingPlan",
          "kind": "LinkedField",
          "name": "userPlan",
          "plural": false,
          "selections": [
            (v0/*: any*/)
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "team",
          "kind": "LinkedField",
          "name": "teams",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "name",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "avatar",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Store",
  "abstractKey": null
};
})();

(node/*: any*/).hash = "780117da40b7974acfd0576e64606545";

module.exports = ((node/*: any*/)/*: Fragment<
  userMenu_store$fragmentType,
  userMenu_store$data,
>*/);
