import { commitMutation, graphql } from "react-relay"
import { complete } from "../helper.js"

const mutation = graphql`
	mutation updateBoardNoMutation($input: UpdateBoardNoInput!) {
		updateBoardNo(input: $input) {
			lookbookBoards {
				id
				lookbookId
				pageNo
			}
		}
	}
`

const commit = (env, boardList, lookbookId, onCompleted, onError) =>
	commitMutation(env, {
		mutation,
		variables: { input: { lookbookId, boardList } },
		onCompleted: complete(onCompleted, onError),
		onError: complete(onCompleted, onError),
		updater: (storeProxy, data) => {
			if (data.updateBoardNo && data.updateBoardNo.lookbookBoards) {
				const { lookbookBoards } = data.updateBoardNo
				lookbookBoards.forEach(({ id, pageNo }) => {
					const board = storeProxy.get(id)
					if (board) board.setValue("pageNo", parseInt(pageNo, 10))
				})
			}
		},
	})

export default { commit }
