/**
 * @generated SignedSource<<8da1077815daef1ad24f08a400d3c329>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Mutation } from 'relay-runtime';
import type { userLookbookCard_lookbook$fragmentType } from "./../../../components/cards/user-lookbook/__generated__/userLookbookCard_lookbook.graphql";
export type DuplicateLookbookInput = {|
  clientMutationId?: ?string,
  lookbookId: string,
|};
export type duplicateLookbookMutation$variables = {|
  input: DuplicateLookbookInput,
|};
export type duplicateLookbookMutation$data = {|
  +duplicateLookbook: ?{|
    +lookbook: ?{|
      +$fragmentSpreads: userLookbookCard_lookbook$fragmentType,
    |},
  |},
|};
export type duplicateLookbookMutation = {|
  response: duplicateLookbookMutation$data,
  variables: duplicateLookbookMutation$variables,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "url",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "duplicateLookbookMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "DuplicateLookbookPayload",
        "kind": "LinkedField",
        "name": "duplicateLookbook",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Lookbook",
            "kind": "LinkedField",
            "name": "lookbook",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "userLookbookCard_lookbook"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "ShopshareMutationRoot",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "duplicateLookbookMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "DuplicateLookbookPayload",
        "kind": "LinkedField",
        "name": "duplicateLookbook",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Lookbook",
            "kind": "LinkedField",
            "name": "lookbook",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "title",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "description",
                "storageKey": null
              },
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isPublished",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isPinned",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isArchived",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isStarred",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isFlagged",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "inRoot",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "views",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "shownBoardCount",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "User",
                "kind": "LinkedField",
                "name": "user",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "username",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "customDomain",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "SiteTransactionObj",
                "kind": "LinkedField",
                "name": "transactions",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "orderId",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "LookbookBoard",
                "kind": "LinkedField",
                "name": "lookbookBoards",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "videoUrl",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "width",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "height",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "dataJson",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "ShopcastFolder",
                "kind": "LinkedField",
                "name": "folder",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "NameValue",
                    "kind": "LinkedField",
                    "name": "path",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "value",
                        "storageKey": null
                      },
                      (v4/*: any*/),
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "pinterestUploadId",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "5e61b3355f7cd9f6c05a67ddae2e7487",
    "id": null,
    "metadata": {},
    "name": "duplicateLookbookMutation",
    "operationKind": "mutation",
    "text": "mutation duplicateLookbookMutation(\n  $input: DuplicateLookbookInput!\n) {\n  duplicateLookbook(input: $input) {\n    lookbook {\n      ...userLookbookCard_lookbook\n      id\n    }\n  }\n}\n\nfragment userLookbookCard_lookbook on Lookbook {\n  id\n  title\n  description\n  url\n  isPublished\n  isPinned\n  isArchived\n  isStarred\n  isFlagged\n  inRoot\n  views\n  shownBoardCount\n  user {\n    id\n    username\n    customDomain\n  }\n  transactions {\n    id\n    orderId\n  }\n  lookbookBoards {\n    id\n    url\n    videoUrl\n    width\n    height\n    dataJson\n  }\n  folder {\n    name\n    id\n    path {\n      value\n      name\n      id\n    }\n  }\n  pinterestUploadId\n}\n"
  }
};
})();

(node/*: any*/).hash = "14638142ed5710955c3ab5360248a37c";

module.exports = ((node/*: any*/)/*: Mutation<
  duplicateLookbookMutation$variables,
  duplicateLookbookMutation$data,
>*/);
