/**
 * @generated SignedSource<<df80e6af81a7d5e35fa13cc258aeb49c>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Query } from 'relay-runtime';
import type { forgotPassword_store$fragmentType } from "./forgotPassword_store.graphql";
export type forgotPasswordQuery$variables = {||};
export type forgotPasswordQuery$data = {|
  +store: ?{|
    +$fragmentSpreads: forgotPassword_store$fragmentType,
  |},
|};
export type forgotPasswordQuery = {|
  response: forgotPasswordQuery$data,
  variables: forgotPasswordQuery$variables,
|};
*/

var node/*: ConcreteRequest*/ = {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "forgotPasswordQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Store",
        "kind": "LinkedField",
        "name": "store",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "forgotPassword_store"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "ShopshareQueryRoot",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "forgotPasswordQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Store",
        "kind": "LinkedField",
        "name": "store",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "0e7a3470b76d7a3474c14899be9f6cb8",
    "id": null,
    "metadata": {},
    "name": "forgotPasswordQuery",
    "operationKind": "query",
    "text": "query forgotPasswordQuery {\n  store {\n    ...forgotPassword_store\n    id\n  }\n}\n\nfragment forgotPassword_store on Store {\n  id\n}\n"
  }
};

(node/*: any*/).hash = "102cd876d28e79262f05b7174578eb56";

module.exports = ((node/*: any*/)/*: Query<
  forgotPasswordQuery$variables,
  forgotPasswordQuery$data,
>*/);
