import React, { Component } from "react"
import Modal from "react-modal"
import { Cross2Icon } from "@radix-ui/react-icons"
import ColorPicker from "components/lookbook-dropdown/color-picker"
import form from "shared/styles/components/form.css"
import textfield from "shared/styles/components/textfield.css"
import modal from "shared/styles/components/modal.css"
import button from "shared/styles/components/buttons.css"
import AutoSuggest from "../../auto-suggest-new/auto-suggest"
import updateLookbookBackgroundMutation from "../../../mutations/lookbook/update-lookbook-background"
import styles from "./background-color-upload.css"
import LoadingDots from "../../loading-dots/loading-dots"

class BackgroundColorUpload extends Component {
	constructor(props) {
		super(props)
		this.state = {
			uploading: false,
			subCategorySet: [],
		}
		this.upload = this.upload.bind(this)
		this.onSelect = this.onSelect.bind(this)
	}

	componentDidMount() {
		const { subCategoryList } = this.props
		this.setState({
			subCategorySet: subCategoryList.map((obj) => ({
				id: obj.id,
				value: obj.value,
			})),
		})
	}

	onSelect(productCollectionsArray) {
		const { selected: stateModel, subCategorySet } = this.state
		const { selected: editModel } = this.props
		const selected = {
			id: stateModel?.id || editModel?.id,
			collection: stateModel?.collection || editModel?.category || [],
			bgColor: this.bgColor.value,
		}
		const newCollection = []
		productCollectionsArray.forEach((collection) => {
			if (collection.id.includes("new_")) {
				newCollection.push(collection)
			}
		})
		this.setState({
			selected: {
				id: selected?.id,
				bgColor: selected?.bgColor,
				collection: productCollectionsArray,
			},
			subCategorySet: [...subCategorySet],
		})
	}

	closeModal(count) {
		const { closeModal } = this.props
		this.setState({
			uploading: false,
			selected: null,
		})
		closeModal(count)
	}

	upload() {
		const { selected: stateModel } = this.state
		const { selected: editModel } = this.props
		const selected = {
			id: stateModel?.id || editModel?.id,
			collection: stateModel?.collection || editModel?.category || [],
			bgColor: this.bgColor.value,
		}
		const { relay } = this.props
		if (
			!selected?.bgColor ||
			!selected?.collection ||
			selected.collection.length === 0
		) {
			return
		}
		this.setState({ uploading: true })
		updateLookbookBackgroundMutation.commit(
			relay.environment,
			{
				id: selected?.id,
				type: "color",
				title: selected.bgColor,
				background: selected.bgColor,
				category: selected.collection[0].value,
			},
			null,
			() => {
				this.closeModal(0)
			},
			() => {
				this.closeModal(1)
			}
		)
	}

	render() {
		const {
			openModal,
			closeModal,
			adminAccess,
			subCategoryList,
			...otherProps
		} = this.props
		const { selected: stateModel, uploading } = this.state
		const { selected: editModel } = this.props
		const selected = {
			id: stateModel?.id || editModel?.id,
			collection: stateModel?.collection || editModel?.category || [],
			bgColor: stateModel?.bgColor || editModel?.url,
		}
		return (
			<Modal
				className={modal.root}
				overlayClassName={modal.overlay}
				isOpen={openModal}
				{...otherProps}
				ariaHideApp={false}
			>
				<div className={`${modal.dialog} ${modal.dialogCentered}`}>
					<div className={`${modal.content}`}>
						<div className={modal.header}>
							<h4 className={modal.title}>
								{editModel ? "Edit" : "New"} Colour
							</h4>
							<button
								type="button"
								className={button.close}
								onClick={() => this.closeModal(0)}
							>
								<Cross2Icon className={button.closeIcon} />
							</button>
						</div>
						<div className={modal.body}>
							<div className={form.group}>
								<label className={form.label}>Colour Category</label>
								<AutoSuggest
									result={selected?.collection || []}
									dataList={subCategoryList}
									onChange={this.onSelect}
									placeholder="Add category"
									maxResults={2}
								/>
							</div>
							<div className={form.group}>
								<label className={form.label}>Colour</label>
								<div className={form.inputGroup}>
									<div className={styles.append}>
										<div className={styles.colorPicker}>
											<ColorPicker
												color={selected?.bgColor}
												onChange={(colorValue) => {
													this.setState({
														selected: {
															id: selected.id,
															collection: selected?.collection,
															bgColor: colorValue,
														},
													})
												}}
											/>
										</div>
									</div>
									<input
										type="text"
										ref={(ref) => {
											this.bgColor = ref
										}}
										className={textfield.normal}
										maxLength={7}
										defaultValue={
											selected && selected?.bgColor
												? selected?.bgColor.toUpperCase()
												: ""
										}
										disabled={uploading}
										onChange={({ target }) =>
											this.setState({
												selected: {
													id: selected.id,
													collection: selected?.collection,
													bgColor: target.value.toUpperCase(),
												},
											})
										}
										placeholder="#0000000"
									/>
								</div>
							</div>
						</div>

						<div className={modal.footer}>
							<button
								type="button"
								className={button.link}
								onClick={() => this.closeModal(0)}
							>
								{uploading ? (
									<LoadingDots color="var(--gray300)" />
								) : (
									"Cancel"
								)}
							</button>
							<button
								type="submit"
								className={button.primary}
								onClick={this.upload}
							>
								{uploading ? (
									<LoadingDots color="var(--primary80)" />
								) : (
									"Save"
								)}
							</button>
						</div>
					</div>
				</div>
			</Modal>
		)
	}
}
export default BackgroundColorUpload
