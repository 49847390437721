import { commitMutation, graphql } from "react-relay"
import { complete } from "../helper.js"

const mutation = graphql`
mutation removeVerificationRequestMutation($input: RemoveVerificationRequestInput!) {
    removeVerificationRequest(input: $input) {
      store {
        id
        currentUser {
          id
          verificationInProgress
        }
      }
    }
  }
`

const commit = (env, onCompleted, onError) =>
	commitMutation(env, {
		mutation,
		updater: (storeProxy, data) => {
            const {
				id: currentUserId,
				verificationInProgress
			} = data.removeVerificationRequest.store.currentUser
			const userProxy = storeProxy.get(currentUserId)
            userProxy.setValue(verificationInProgress, "verificationInProgress")
		},
		variables: { input: {} },
		onCompleted: complete(onCompleted, onError),
		onError: (e) => complete(null, [e]),
	})

export default { commit }
