/* eslint-disable react/jsx-pascal-case */
import React, { useRef, useState } from "react"
import { ChevronDownIcon, ChevronRightIcon } from "@radix-ui/react-icons"
import collapsibleHeader from "shared/styles/layout/collapsible-header.css"
import section from "shared/styles/layout/section.css"
import { justRender } from "../../route-helpers"
import clientViewShopcasts from "./client-view-shopcasts"
import clientViewLookbooks from "./client-view-lookbooks"
import clientViewFiles from "./client-view-files"
import clientViewCollectionList from "./client-view-collection-list"

function ClientViewAll(props) {
	const {
		sharedFolderShopcastsCount,
		sharedFolderLookbooksCount,
		sharedFolderFilesCount,
		sharedFolderCollectionsListCount,
        navigateToRoute,
		...other
	} = props
	const [tabState, setTabState] = useState({
		expandedShopcastTab: true,
		expandedShopboardTab: true,
		expandedCollectionTab: true,
		expandedFileTab: true,
	})
	const ClientViewShopcasts = useRef(
		justRender({
			...clientViewShopcasts,
			props: {
				removeInfiniteScroll: true,
			},
		})
	)
	const ClientViewLookbooks = useRef(justRender({
		...clientViewLookbooks,
		props: {
			removeInfiniteScroll: true,
		}
	}))
	const ClientViewFiles = useRef(justRender({
		...clientViewFiles,
		props: {
			removeInfiniteScroll: true,
		}
	}))
	const ClientViewCollections = useRef(justRender({
		...clientViewCollectionList,
		props: {
			removeInfiniteScroll: true,
		}
	}))

	const renderTab = (header, route, tabKey, children) => (
		<div className={tabState[tabKey] ? section.root : section.rootClosed}>
			<div className={collapsibleHeader.root}>
				<a
					role="link"
					tabIndex={0}
					className={collapsibleHeader.titleLink}
					onClick={() => {
						setTabState((s) => ({
							...s,
							[tabKey]: !s[tabKey],
						}))
					}}
				>
					<h2 className={collapsibleHeader.title}>
						{tabState[tabKey] ? (
							<ChevronDownIcon className={collapsibleHeader.icon} />
						) : (
							<ChevronRightIcon className={collapsibleHeader.icon} />
						)}
						{header || "Content"}
					</h2>
				</a>
				<div className={collapsibleHeader.actions}>
					<a
						role="link"
						tabIndex="0"
						className={collapsibleHeader.link}
						onClick={() => {
							navigateToRoute(route)
						}}
					>
						See all
					</a>
				</div>
			</div>
			{tabState[tabKey] && <div className={section.body}>{children}</div>}
		</div>
	)
	return (
		<>
			{sharedFolderShopcastsCount > 0 &&
				renderTab(
					"Shopcasts",
					`shopcasts`,
					"expandedShopcastTab",
					<ClientViewShopcasts.current {...other} />
				)}
			{sharedFolderLookbooksCount > 0 &&
				renderTab(
					"Shopboards",
					`shopboards`,
					"expandedShopboardTab",
					<ClientViewLookbooks.current {...other} />
				)}
			{sharedFolderFilesCount > 0 &&
				renderTab(
					"Files",
					`files`,
					"expandedFileTab",
					<ClientViewFiles.current {...other} />
				)}
			{sharedFolderCollectionsListCount > 0 &&
				renderTab(
					"Collections",
					`collections`,
					"expandedCollectionTab",
					<ClientViewCollections.current {...other} />
				)}
		</>
	)
}

export default ClientViewAll
