import React, { Fragment, useState } from "react"
import { Transition } from "react-transition-group"
import Slider from "react-slick"
import { isBackgroundRemovedImage } from "helpers/lookbook-helper"
import { addVersioning } from "helpers/query-parameters-helper"
import {
	// ResetIcon,
	TrashIcon,
	ChevronRightIcon,
	ChevronLeftIcon,
} from "@radix-ui/react-icons"
// import RemoveBgIcon from "shared/assets/lb-removebg-icon.svg"
import LoadingDots from "components/loading-dots/loading-dots"
import TickIcon from "shared/assets/tick-icon.svg"
// import NextIcon from "shared/assets/next-icon.svg"
// import PrevIcon from "shared/assets/prev-icon.svg"
import button from "shared/styles/components/buttons.css"
import icons from "shared/styles/components/icons.css"
import styles from "./image-corousel.css"

function NextArrow({ onClick }) {
	return (
		<button type="button" className={styles.next} onClick={onClick}>
			<ChevronLeftIcon className={styles.arrow} />
		</button>
	)
}

function PrevArrow({ onClick }) {
	return (
		<button type="button" className={styles.prev} onClick={onClick}>
			<ChevronRightIcon className={styles.arrow} />
		</button>
	)
}

function ImageCorousel({
	productImages,
	currentProductUrl,
	diffbotImageUrl,
	setDefaultImage,
	deleteImage,
	// removeBackground,
	imageEditing,
}) {
	const [seletcedImage, setSelectedImage] = useState(null)
	if (!productImages || productImages.length === 0) {
		return false
	}
	let initialSlide = 0
	if (productImages && productImages.length) {
		initialSlide = productImages.indexOf(diffbotImageUrl)
	}
	const sliderSettings = {
		dots: true,
		speed: 500,
		arrows: true,
		initialSlide,
		className: "slider",
		nextArrow: imageEditing ? null : <NextArrow />,
		prevArrow: imageEditing ? null : <PrevArrow />,
	}

	return (
		<Slider {...sliderSettings}>
			{productImages.map((img) => (
				<div
					className={
						isBackgroundRemovedImage(img)
							? styles.productTrans
							: styles.product
					}
					key={img}
				>
					<div className={styles.actions}>
						{/* {removeBackground && (
							<button
								type="button"
								className={button.lightIcon}
								onClick={() => removeBackground(img)}
								disabled={!!imageEditing}
								data-tooltip-id="tooltip"
								data-tooltip-place="left"
								data-tooltip-content={
									isBackgroundRemovedImage(img)
										? "Restore Background"
										: "Remove Background"
								}
							>
								{isBackgroundRemovedImage(img) ? (
									<ResetIcon className={icons.icon18} />
								) : (
									<RemoveBgIcon className={icons.icon18} />
								)}
							</button>
						)} */}
						{deleteImage && productImages.length > 1 && (
							<button
								type="button"
								className={button.lightIcon}
								onClick={() => setSelectedImage(img)}
								data-tooltip-id="tooltip"
								data-tooltip-place="left"
								data-tooltip-content="Delete"
							>
								<TrashIcon className={icons.icon21} />
							</button>
						)}
					</div>
					<img
						className={styles.image}
						src={addVersioning(img, `d=400`)}
						alt="Product"
					/>
					<Transition
						in={imageEditing === img}
						appear={imageEditing === img}
						timeout={300}
					>
						{(state) => (
							<div className={`${styles.loading} ${styles[state]}`}>
								<LoadingDots color="var(--primary50)" />
							</div>
						)}
					</Transition>
					{seletcedImage === img ? (
						<div className={styles.buttonGroup}>
							<button
								type="button"
								className={button.subtle}
								onClick={() => setSelectedImage(null)}
							>
								Cancel
							</button>
							<button
								type="button"
								className={button.primaryRed}
								onClick={() => {
									deleteImage(img)
									setSelectedImage(null)
								}}
							>
								Delete
							</button>
						</div>
					) : (
						<div className={styles.buttonGroup}>
							<button
								type="button"
								className={
									currentProductUrl === img
										? styles.selected
										: styles.select
								}
								onClick={() => setDefaultImage(img)}
							>
								{currentProductUrl !== img && <>Use this Image</>}
								{currentProductUrl === img && (
									<TickIcon className={icons.icon24} />
								)}
							</button>
						</div>
					)}
				</div>
			))}
		</Slider>
	)
}
export default ImageCorousel
