/**
 * @generated SignedSource<<875f4b8161fa63c7201cc7fe4077390e>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentType } from "relay-runtime";
declare export opaque type editPreviewTeam_team$fragmentType: FragmentType;
export type editPreviewTeam_team$data = {|
  +avatar: ?string,
  +bannerPhoto: ?string,
  +collections: ?{|
    +edges: ?$ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
      |},
    |}>,
  |},
  +displayAvatar: ?string,
  +displayName: ?string,
  +files: ?{|
    +edges: ?$ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
      |},
    |}>,
  |},
  +id: string,
  +lookbooks: ?{|
    +edges: ?$ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
      |},
    |}>,
  |},
  +name: ?string,
  +previewConfig: ?{|
    +showAvatar: ?string,
    +showBanner: ?string,
    +showCollectionsSection: ?boolean,
    +showDisplayName: ?boolean,
    +showFilesSection: ?boolean,
    +showShopboardsSection: ?boolean,
    +showShopcastsSection: ?boolean,
  |},
  +shopcasts: ?{|
    +edges: ?$ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
      |},
    |}>,
  |},
  +$fragmentType: editPreviewTeam_team$fragmentType,
|};
export type editPreviewTeam_team$key = {
  +$data?: editPreviewTeam_team$data,
  +$fragmentSpreads: editPreviewTeam_team$fragmentType,
  ...
};
*/

var node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 1
  }
],
v2 = [
  (v0/*: any*/)
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "editPreviewTeam_team",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "displayName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "avatar",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "displayAvatar",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "bannerPhoto",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "TeamPreviewConfig",
      "kind": "LinkedField",
      "name": "previewConfig",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "showBanner",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "showAvatar",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "showDisplayName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "showShopcastsSection",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "showShopboardsSection",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "showFilesSection",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "showCollectionsSection",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": (v1/*: any*/),
      "concreteType": "ShopcastFolderShopcastConnection",
      "kind": "LinkedField",
      "name": "shopcasts",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ShopcastFolderShopcastEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Shopcast",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": (v2/*: any*/),
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "shopcasts(first:1)"
    },
    {
      "alias": null,
      "args": (v1/*: any*/),
      "concreteType": "LookbookConnection",
      "kind": "LinkedField",
      "name": "lookbooks",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "LookbookEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Lookbook",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": (v2/*: any*/),
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "lookbooks(first:1)"
    },
    {
      "alias": null,
      "args": (v1/*: any*/),
      "concreteType": "ProductCollectionConnection",
      "kind": "LinkedField",
      "name": "collections",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ProductCollectionEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ProductCollection",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": (v2/*: any*/),
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "collections(first:1)"
    },
    {
      "alias": null,
      "args": (v1/*: any*/),
      "concreteType": "ShopshareFileConnection",
      "kind": "LinkedField",
      "name": "files",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ShopshareFileEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ShopshareFile",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": (v2/*: any*/),
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "files(first:1)"
    }
  ],
  "type": "team",
  "abstractKey": null
};
})();

(node/*: any*/).hash = "d672630d9d570c5c8c5bf87d0f554f22";

module.exports = ((node/*: any*/)/*: Fragment<
  editPreviewTeam_team$fragmentType,
  editPreviewTeam_team$data,
>*/);
