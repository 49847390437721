import React, { Component, createRef, Fragment } from "react"
import Konva from "konva"
import {
	Stage,
	Layer,
	Image,
	Transformer,
	Text,
	Rect,
	Group,
	Path,
} from "react-konva"
import { toast } from "react-toastify"
import "gifler"
import { DashboardIcon, CardStackIcon } from "@radix-ui/react-icons"
import { debounce, includes, unset, clone } from "lodash"
import FontFaceObserver from "fontfaceobserver"
import gql from "helpers/gql-helper"
import {
	getColor,
	getGuides,
	getImageByURL,
	getObjectSnappingEdges,
	getImageBySVG,
	replaceItems,
	windowResize,
	cropImage,
	changeTransparency,
	getDropCoordinates,
	getBestScales,
	getPixelData,
	stageToBlob,
	downloadBlob,
	setStageContainer,
	exportExt,
	canvasMarging,
	isBackgroundRemovedImage,
	GIF,
	getImage,
} from "helpers/lookbook-helper"
import { createGuid } from "helpers/string-helper"
import { CategoryENUM, ClipTypeENUM } from "helpers/enum-helper"
import { addVersioning } from "helpers/query-parameters-helper"
import ShapeFill from "components/lookbook-dropdown/shape-fill"
import ShapeStroke from "components/lookbook-dropdown/shape-stroke"
import ImageContollers from "components/lookbook/image-controllers"
import { Item, ShapeProps, TextProps } from "components/lookbook/lookbook"
import {
	ToolBox,
	ImageTools,
	TemplateTools,
	RightSideTools,
	RightDefaultTools,
} from "components/lookbook/tool-box"
import BottomLeftPanel from "components/lookbook/bottom-left-panel"
import ProductTray from "components/lookbook/product-tray"
import CropImage from "components/modals/crop-image/crop-image"
import LoadingDots from "components/loading-dots/loading-dots"
import ConformationPrompt from "components/modals/conformation-prompt/conformation-prompt"
import BackgroundRemover from "components/lookbook-dropdown/background-remover"
import VideoThumb from "components/modals/video-thumb/video-thumb"
import LayerDropdown from "components/lookbook-dropdown/layer-dropdown"
import CanvasSizePicker, {
	CanvasSizeList,
} from "components/lookbook-dropdown/canvas-size-picker"
import Pagination from "components/pagination/pagination"
import BoardsTray from "components/lookbook/boards-tray"

import updateLookbookMutation from "mutations/lookbook/update-lookbook"
import bannerPhotoMutation from "mutations/user/banner-photo"
import posterUrlMutation from "mutations/shopcast/poster-url"
import toggleBoardShowMutation from "mutations/lookbook/toggle-board-show"
import updateBoardNoMutation from "mutations/lookbook/update-board-no"
import removeImageBackgroundMutation from "mutations/lookbook/remove-image-background"
import button from "shared/styles/components/buttons.css"
import { CHANGES_FROM } from "./lookbook"
import styles from "./moveable.css"
import DropElement from "./drop-element"
import Prompt from "./changes-prompt"
import { withRouter } from "../../route-helpers"

const MoveableContext = React.createContext({})
export const MoveableProvider = MoveableContext.Provider
export const MoveableConsumer = MoveableContext.Consumer

class CanvasBoard extends Component {
	constructor(props) {
		super(props)
		this.state = {
			color: "#000000",
			container: { element: null, width: 0, height: 0 },
			contextMenu: null,
			draw: false,
			editText: false,
			fontFamily: "",
			fontSize: 0,
			fullScreen: false,
			loading: false,
			lineHeight: 1,
			objList: [],
			openCropModal: false,
			openClearConfirmModal: false,
			position: 0,
			selected: null,
			selectFill: "rgba(0,0,255,0.3)",
			selectOffsetX: 0,
			selectOffsetY: 0,
			selectRect: false,
			selectWidth: 0,
			selectHeight: 0,
			selectDeg: 0,
			selectX: 0,
			selectY: 0,
			textAlign: "left",
			textEdit: null,
			textBold: false,
			textItalic: false,
			transparentLevel: null,
			sideTray: localStorage.getItem("board-footer") || false,
			// textTrasform: false,
			imageTransparentLevel: 100,
			VideoThumbnail: false,
			ChannelBanner: false,
			videoThumbSaving: false,
			shopcastModalOpen: false,
			boardLoading: true,
			shadowColor: "#000000",
			shadowBlur: 0,
			shadowOffset: { x: 0, y: 0 },
			shadowOpacity: 0,
			replacingImage: null,
			clipType: ClipTypeENUM.FIT,
			templateMode: false,
			altPressed: false,
			shiftPressed: false,
			selectBoard: false,
		}
		this.defaultBackground = "#FFFFFF"
		this.dragDistance = 1
		this.imageRef = []
		this.shapeRef = []
		this.imageGroupRef = []
		this.positionArray = []
		this.selectedObjArray = []
		this.groups = []
		this.loadedFonts = []
		this.snapAngles = [
			0,
			15,
			30,
			45,
			60,
			75,
			90,
			105,
			120,
			135,
			150,
			165,
			180,
			195,
			210,
			225,
			240,
			255,
			270,
			285,
			300,
			315,
			330,
			345,
		]
		this.tarRef = null
		this.runDebounceLoop = null
		this.debounceTime = 3 // 3 seconds
		this.textEditingIds = [
			"lookbook_title",
			"lookbook_desc",
			"image_transparent",
			"canWidth",
			"canHeight",
		]
		this.transLayer = createRef()
		this.bindTransformer = this.bindTransformer.bind(this)
		this.changeCanvasSize = this.changeCanvasSize.bind(this)
		this.checkAllInGroup = this.checkAllInGroup.bind(this)
		this.checkCanvas = this.checkCanvas.bind(this)
		this.checkDeselect = this.checkDeselect.bind(this)
		this.checkInGroup = this.checkInGroup.bind(this)
		this.clearBoard = this.clearBoard.bind(this)
		this.closeModal = this.closeModal.bind(this)
		this.createBlob = this.createBlob.bind(this)
		this.downloadImage = this.downloadImage.bind(this)
		this.drawGuides = this.drawGuides.bind(this)
		this.dragEndItem = debounce(
			this.dragEndItem.bind(this),
			this.debounceTime * 1000
		)
		this.dragItem = this.dragItem.bind(this)
		this.dragOver = this.dragOver.bind(this)
		this.drawMultipleSelectBox = this.drawMultipleSelectBox.bind(this)
		this.drawSelectRect = this.drawSelectRect.bind(this)
		this.drop = this.drop.bind(this)
		this.dropByClick = this.dropByClick.bind(this)
		this.dropImage = this.dropImage.bind(this)
		this.dropShape = this.dropShape.bind(this)
		this.dropText = this.dropText.bind(this)
		this.duplicate = this.duplicate.bind(this)
		this.endDraw = this.endDraw.bind(this)
		this.endEdit = this.endEdit.bind(this)
		this.exitContextMenu = this.exitContextMenu.bind(this)
		this.flip = this.flip.bind(this)
		this.getLineGuideStops = this.getLineGuideStops.bind(this)
		this.getSelectedObjList = this.getSelectedObjList.bind(this)
		this.group = this.group.bind(this)
		this.handleTransparent = this.handleTransparent.bind(this)
		this.handleImageTransparent = this.handleImageTransparent.bind(this)
		this.handleShadow = this.handleShadow.bind(this)
		this.keyEvents = this.keyEvents.bind(this)
		this.layerDragStart = this.layerDragStart.bind(this)
		this.layerDragEnd = this.layerDragEnd.bind(this)
		this.layerDragMove = this.layerDragMove.bind(this)
		this.lockedElementInSelected = this.lockedElementInSelected.bind(this)
		this.lockItem = this.lockItem.bind(this)
		this.moveItem = this.moveItem.bind(this)
		this.onChange = this.onChange.bind(this)
		this.onContextMenu = this.onContextMenu.bind(this)
		this.onEdit = this.onEdit.bind(this)
		this.onSelect = this.onSelect.bind(this)
		this.onWindowResize = this.onWindowResize.bind(this)
		this.redo = this.redo.bind(this)
		this.removeItem = this.removeItem.bind(this)
		this.saveLookbook = this.saveLookbook.bind(this)
		this.setChannelBanner = this.setChannelBanner.bind(this)
		this.setPosition = this.setPosition.bind(this)
		this.setStageContainer = this.setStageContainer.bind(this)
		this.undo = this.undo.bind(this)
		this.unGroup = this.unGroup.bind(this)
		this.updateVideoThumb = this.updateVideoThumb.bind(this)
		this.loadingFinished = this.loadingFinished.bind(this)
		this.makeAsTemplate = this.makeAsTemplate.bind(this)
		this.drawBoard = this.drawBoard.bind(this)
		this.renderPropmt = this.renderPropmt.bind(this)
		this.renderCanvas = this.renderCanvas.bind(this)
		this.clipType = this.clipType.bind(this)
		this.keyUp = this.keyUp.bind(this)
		this.selectBoard = this.selectBoard.bind(this)
		this.duplicateBoard = this.duplicateBoard.bind(this)
		this.showBoard = this.showBoard.bind(this)
		this.deselect = this.deselect.bind(this)
		this.onLeavePage = this.onLeavePage.bind(this)
		this.runDebounce = this.runDebounce.bind(this)
		this.renderFooter = this.renderFooter.bind(this)
		this.updateServer = debounce(this.updateServer.bind(this), 2000)
		this.updateBoard = debounce(this.updateBoard.bind(this), 500)
		this.removeBackground = this.removeBackground.bind(this)
		this.renderImageProcessing = this.renderImageProcessing.bind(this)
		// this.onTransform = this.onTransform.bind(this)
		props.innerRef(this)
	}

	componentDidMount() {
		this.setStageContainer()
		window.addEventListener("resize", this.onWindowResize)
		window.addEventListener("keydown", this.keyEvents)
		window.addEventListener("keyup", this.keyUp)
		window.addEventListener("click", this.checkDeselect)
		window.addEventListener("select", this.checkDeselect)
		window.addEventListener("beforeunload", this.onLeavePage)
		this.debounceSave = debounce(() => {
			const { id } = this.props
			if (id) this.saveLookbook()
		}, this.debounceTime * 1000)
		this.updateShape = debounce(async (img, shapeList) => {
			img.image = await getImageBySVG(img, shapeList)
			this.onChange(img)
		}, 1000)
		this.positionArray[0] = []
		this.setState({ position: 1 })
	}

	componentDidUpdate() {
		const {
			boardLoading,
			shopcastList: stateShopcastList,
			objList,
			templateMode,
		} = this.state
		const { selectedBoard, shopcastList, lookbook, saving } = this.props
		if (shopcastList !== stateShopcastList) {
			this.setState({ shopcastList })
		}
		if (objList.length === 0 && boardLoading) {
			this.setState(
				{
					boardLoading: false,
					loading: true,
					templateMode: !!selectedBoard?.isTemplate,
				},
				async () => {
					await this.updateBoard()
					if (
						selectedBoard.boardId.indexOf("newBoard_") > -1 &&
						lookbook.id &&
						!saving
					) {
						this.saveLookbook(true)
					}
				}
			)
		}
		if (lookbook && lookbook.isTemplate !== templateMode) {
			this.setState({ templateMode: lookbook.isTemplate })
		}
	}

	componentWillUnmount() {
		const { textEdit } = this.state
		window.removeEventListener("resize", this.onWindowResize)
		window.removeEventListener("keydown", this.keyEvents)
		window.removeEventListener("keyup", this.keyUp)
		window.removeEventListener("click", this.checkDeselect)
		window.removeEventListener("select", this.checkDeselect)
		window.removeEventListener("beforeunload", this.onLeavePage)
		if (textEdit) document.body.removeChild(document.getElementById(textEdit))
		this.setState({ textEdit: null })
	}

	handleTransparent({ target }) {
		const { objList, selected } = this.state
		const newAttr = objList.find((x) => x.id === selected)
		this.setState({
			transparentLevel: target.value,
		})
		changeTransparency(target.value, newAttr)
	}

	handleImageTransparent({ target }) {
		const { objList } = this.state
		const objects = objList.filter((x) =>
			this.selectedObjArray.map((obj) => obj.id).includes(x.id)
		)
		const value = parseFloat(parseFloat(target.value)) || 0
		this.setState({
			imageTransparentLevel: value,
		})
		objects.forEach((newAttr) => {
			newAttr.opacity = value / 100
			this.onChange(newAttr)
		})
		this.setPosition()
	}

	handleShadow(shadow) {
		const { shadowColor, shadowBlur, shadowOffset, shadowOpacity } = shadow
		const { objList } = this.state
		const objects = objList.filter((x) =>
			this.selectedObjArray.map((obj) => obj.id).includes(x.id)
		)
		objects.forEach((newAttr) => {
			if (shadowColor !== undefined) {
				newAttr.shadowColor = shadowColor
			}
			if (shadowBlur !== undefined) {
				newAttr.shadowBlur = shadowBlur
			}
			if (shadowOffset !== undefined) {
				newAttr.shadowOffset = shadowOffset
			}
			if (shadowOpacity !== undefined) {
				newAttr.shadowOpacity = shadowOpacity
			}
			this.onChange(newAttr)
		})
		this.setState({ ...shadow })
		this.setPosition()
	}

	// onTransform({ target }) {
	// 	// this.imageGroupRef[target.id()]
	// 	// this.imageGroupRef[target.id()].clip({
	// 	// 	x: target.x() - target.scaleX() * target.offsetX(),
	// 	// 	y: target.y() - target.scaleY() * target.offsetY(),
	// 	// 	width: target.width() * target.scaleX(),
	// 	// 	height: target.height() * target.scaleY(),
	// 	// })
	// }

	onChange(newAttr) {
		// const { objList } = this.state
		const { doChanges } = this.props
		doChanges(CHANGES_FROM.CANVAS)
		// newAttr.x = newAttr.x < (newAttr.width * newAttr.scaleX / 2) ? (newAttr.width * newAttr.scaleX / 2) : newAttr.x > container.element.offsetWidth - (newAttr.width * newAttr.scaleX / 2) ? container.element.offsetWidth - (newAttr.width * newAttr.scaleX / 2) : newAttr.x
		// newAttr.y = newAttr.y < (newAttr.height * newAttr.scaleX / 2) ? (newAttr.height * newAttr.scaleX / 2) : newAttr.y > container.element.offsetHeight - (newAttr.height * newAttr.scaleX / 2) ? container.element.offsetHeight - (newAttr.height * newAttr.scaleX / 2) : newAttr.y
		this.setState((state) => ({
			objList: replaceItems(state.objList, [newAttr]),
		}))
	}

	onContextMenu({ target, evt }) {
		const { contextMenu } = this
		if (target !== this.stage && target !== this.background) {
			evt.preventDefault()
			contextMenu.style.display = "initial"
			contextMenu.style.left = `${
				evt.clientX - this.canvasBoard.getBoundingClientRect().x
			}px`
			contextMenu.style.top = `${
				(evt.clientY >= window.innerHeight - (contextMenu.clientHeight + 60)
					? window.innerHeight - (contextMenu.clientHeight + 60)
					: evt.clientY) - this.canvasBoard.getBoundingClientRect().y
			}px`
			this.setState({ contextMenu: target.attrs.id })
			this.runDebounce(true)
		} else {
			this.setState({ contextMenu: null })
			this.runDebounce(false)
		}
	}

	onEdit() {
		if (this.selectedObjArray.length === 1) {
			const { selected, textEdit, container } = this.state
			if (textEdit) this.endEdit(textEdit)
			const textNode = this.imageRef[selected]
			if (textNode.attrs?.textProp) {
				this.runDebounce(true)
				textNode.hide()
				this.tarRef.hide()
				const boundingRect = container.element.getBoundingClientRect()
				const areaPosition = {
					x:
						textNode.x() +
						(container.x + boundingRect.x) -
						textNode.offsetX() -
						textNode.padding(),
					y:
						textNode.y() +
						(container.y + boundingRect.y) -
						textNode.offsetY() -
						textNode.padding(),
				}
				const fontStyles = textNode.fontStyle().split(" ")
				const fontFamily = textNode
					.fontFamily()
					.replace(/"/g, "")
					.replace(/'/g, "")
				const rotation = textNode.rotation()
				const textarea = document.createElement("div")
				textarea.setAttribute("contenteditable", true)
				textarea.innerText = textNode.text()
				document.body.appendChild(textarea)
				textarea.style.width = `${textNode.width()}px`
				textarea.style.height = "auto"
				textarea.style.position = "absolute"
				textarea.style.top = `${areaPosition.y}px`
				textarea.style.left = `${areaPosition.x}px`
				textarea.id = `text_${textNode.id()}`
				textarea.style.fontWeight = fontStyles.find((x) => x === "bold")
					? "bold"
					: ""
				textarea.style.fontStyle = fontStyles.find((x) => x === "italic")
					? "italic"
					: ""
				textarea.style.fontSize = `${textNode.fontSize()}px`
				textarea.style.lineHeight = parseFloat(textNode.lineHeight())
				textarea.style.fontFamily = fontFamily
				textarea.style.textAlign = textNode.align()
				textarea.style.color = textNode.fill()
				textarea.style.border = "none"
				textarea.style.padding = `${textNode.padding()}px`
				textarea.style.overflow = "hidden"
				textarea.style.background = "none"
				textarea.style.outline = "none"
				textarea.style.resize = "none"
				textarea.style.transformOrigin = "center"
				textarea.rotation = `${rotation}deg`
				let transform = ""
				if (rotation) {
					transform += `rotateZ(${rotation}deg)`
				}
				textarea.style.transform = transform

				textarea.focus()
				this.setState({
					selected: null,
					textEdit: textarea.id,
					color: textNode.fill(),
					textAlign: textNode.align(),
					lineHeight: textNode.lineHeight(),
					fontFamily,
					fontSize: textNode.fontSize(),
					textBold: !!fontStyles.find((x) => x === "bold"),
					textItalic: !!fontStyles.find((x) => x === "italic"),
				})
				this.selectedObjArray = []
			}
		}
	}

	onLeavePage(e) {
		const { haveChanges } = this.props
		if (!haveChanges) return null
		this.saveLookbook()
		e.preventDefault()
		const dialogText = "Changes that you made may not be saved."
		e.returnValue = dialogText
		return dialogText
	}

	onSelect({ target, evt }) {
		const { objList, textEdit } = this.state
		if (
			this.selectedObjArray
				.map((obj) => this.imageRef[obj.id])
				.includes(target)
		) {
			return
		}
		const selObj = objList.find((x) => x.id === target.id())
		if (evt.shiftKey && this.selectedObjArray.length > 0) {
			if (this.selectedObjArray.find((x) => x.id === target.id())) {
				this.selectedObjArray.splice(
					this.selectedObjArray.findIndex((x) => x.id === target.id()),
					1
				)
			} else {
				this.selectedObjArray.push(selObj)
			}
		} else {
			if (textEdit) this.endEdit(textEdit)
			this.selectedObjArray = [objList.find((x) => x.id === target.id())]
		}
		const transparentLevel = parseInt(target.attrs.transparentLevel, 10)
		const imageTransparentLevel = selObj.opacity
			? parseFloat(selObj.opacity * 100)
			: 100
		const shadowBlur = target.shadowBlur() ? target.shadowBlur() : 0
		const shadowOpacity = target.shadowOpacity() ? target.shadowOpacity() : 0
		const shadowOffset = {
			x: !isNaN(target.shadowOffsetX()) ? target.shadowOffsetX() : 0,
			y: !isNaN(target.shadowOffsetY()) ? target.shadowOffsetY() : 0,
		}
		const shadowColor = target.shadowColor()
			? target.shadowColor()
			: "#000000"
		const includeGroupId = this.checkInGroup()
		if (includeGroupId != null) {
			const groupObj = objList.filter((obj) =>
				this.groups[includeGroupId].includes(obj.id)
			)
			this.selectedObjArray = [
				...this.selectedObjArray,
				...groupObj.filter((x) => !this.selectedObjArray.includes(x)),
			]
		}
		const fontStyles = target.attrs?.textProp?.fontStyle?.split(" ")
		let targetProps = {}
		if (target.attrs.imageUrl) {
			targetProps = { transparentLevel }
		} else if (target.attrs.shapeProp) {
			targetProps = {
				color: target.attrs.shapeProp.fill,
				fillTransparentLevel:
					target.attrs.shapeProp.fillTransparentLevel !== undefined
						? target.attrs.shapeProp.fillTransparentLevel
						: 100,
				borderColor: target.attrs.shapeProp.borderColor || "#000000",
				borderWidth: target.attrs.shapeProp.borderWidth || 0,
			}
		} else if (target.attrs.textProp) {
			targetProps = {
				color: target.attrs.textProp.fill,
				textAlign: target.attrs.textProp.align,
				lineHeight: target.attrs.textProp.lineHeight,
				fontFamily: target.attrs.textProp.fontFamily,
				fontSize: parseInt(target.attrs.textProp.fontSize, 10),
				textBold: !!fontStyles.find((x) => x === "bold"),
				textItalic: !!fontStyles.find((x) => x === "italic"),
			}
		}
		if (target.attrs.isDroppableItem) {
			targetProps.clipType = target.attrs.clipProp?.type || ClipTypeENUM.FIT
		}
		this.setState(
			{
				selected: target.attrs.id,
				imageTransparentLevel,
				shadowBlur,
				shadowColor,
				shadowOffset,
				shadowOpacity,
				...targetProps,
			},
			() => this.drawMultipleSelectBox()
		)
	}

	onWindowResize() {
		const { loading, container, objList } = this.state
		if (loading) {
			return
		}
		const newContainer = this.canvasBoard
		const scale = Math.min(
			((newContainer?.clientWidth || 0) - canvasMarging) / (container?.width || 1),
			((newContainer?.clientHeight || 0) - canvasMarging) / (container?.height || 1)
		)
		const objListNew = windowResize(objList, scale)
		this.setStageContainer()
		this.exitContextMenu()
		this.setState({ objList: objListNew })
		if (this.selectedObjArray.length > 0) this.drawMultipleSelectBox()
	}

	setPosition(newAttrs) {
		const { objList, position } = this.state
		this.positionArray = [
			...this.positionArray.slice(0, position),
			replaceItems(objList, newAttrs),
		]
		this.setState({
			position: position + 1,
		})
	}

	async setChannelBanner() {
		const { lookbook, profileID, relay } = this.props
		const file = await this.createBlob(lookbook.title)
		const newFile = new File([file], `${lookbook.title}.${exportExt}`)
		bannerPhotoMutation.commit(
			relay.environment,
			newFile,
			profileID,
			() => {
				toast.success(<Fragment>Channel banner updated.</Fragment>, {
					autoClose: 5000,
					closeButton: false,
				})
			},
			(e) => {
				toast.info(<Fragment>{gql.getError(e)}</Fragment>, {
					autoClose: 5000,
					closeButton: false,
				})
			}
		)
	}

	setStageContainer() {
		const { selectedBoard } = this.props
		const { width: canvasWidth, height: canvasHeight } = selectedBoard
		const container = this.canvasBoard
		if (container) {
			const { width, height } = setStageContainer(
				container.clientWidth,
				container.clientHeight,
				canvasWidth,
				canvasHeight
			)
			this.setState({
				container: {
					element: this.canvaStage.children[0],
					x: (container.clientWidth - width) / 2,
					y: (container.clientHeight - height) / 2,
					width,
					height,
				},
			})
			return { width, height }
		}
		return { width: canvasWidth, height: canvasHeight }
	}

	getLineGuideStops() {
		const vertical = [0, this.stage.width() / 2, this.stage.width()]
		const horizontal = [0, this.stage.height() / 2, this.stage.height()]

		this.parentLayer.children.forEach((guideItem) => {
			if (
				!(
					guideItem.attrs.image ||
					guideItem.attrs.data ||
					guideItem.attrs.text
				)
			)
				return
			if (
				!guideItem.id() ||
				this.selectedObjArray.map((obj) => obj.id).includes(guideItem.id())
			)
				return
			const box = guideItem.getClientRect()
			vertical.push([box.x, box.x + box.width, box.x + box.width / 2])
			horizontal.push([box.y, box.y + box.height, box.y + box.height / 2])
		})
		return {
			vertical: vertical.flat(),
			horizontal: horizontal.flat(),
		}
	}

	getSelectedObjList() {
		const { objList } = this.state
		return objList.filter((obj) => {
			if (this.selectedObjArray.map((x) => x.id).includes(obj.id)) {
				return obj
			}
			return false
		})
	}

	async updateBoard() {
		const { selectedBoard } = this.props
		const textArray = []
		const promises = selectedBoard.dataJson.map(async (x, i) => {
			const onTray = x?.onTray
			x.title = x.title || `Item ${i > 0 ? i : ""}`
			if (onTray || x.image) {
				return
			}
			if (x.imageUrl) {
				try {
					x.cropPath = x.cropPath || []
					const img = await getImageByURL(x)
					x.image = cropImage({
						...x,
						image: img,
					})
				} catch (err) {
					return
				}
				x.pixel = await getPixelData(x)
				changeTransparency(x.transparentLevel, x)
			} else if (x.shapeProp) {
				x.image = getImageBySVG(x, selectedBoard.shapes)
				x.shapeProp.borderWidth = parseFloat(x.shapeProp.borderWidth) || 0
				x.shapeProp.borderColor = x.shapeProp.borderColor || "#000000"
			} else if (x.textProp) {
				textArray.push({
					fontFamily: `${x.textProp.fontFamily.replace(/"/g, "")}`,
					fontWeight:
						x.textProp.fontStyle.search("bold") >= 0 ? "bold" : 400,
				})
			}
		})
		await Promise.all(promises)
		if (
			textArray.length > 0 &&
			textArray.find((text) => !this.loadedFonts.includes(text.fontFamily))
		) {
			const promise = textArray.map(
				(font) =>
					new Promise((resolve) => {
						const fontObserver = new FontFaceObserver(font.fontFamily, {
							weight: 400,
						})
						const boldFontObserver = new FontFaceObserver(
							font.fontFamily,
							{ weight: "bold" }
						)
						fontObserver.load(font, 5000)
						boldFontObserver.load(font, 5000)
						this.loadedFonts.push(font.fontFamily)
						resolve()
					})
			)
			await Promise.all(promise)
			setTimeout(() => this.loadingFinished(selectedBoard), 1000)
		} else {
			this.loadingFinished(selectedBoard)
		}
		this.groups = selectedBoard.group
		this.checkCanvas(selectedBoard.width, selectedBoard.height)
	}

	runDebounce(status) {
		if (status && !this.runDebounceLoop) {
			this.debounceSave()
			this.runDebounceLoop = setInterval(
				() => this.debounceSave(),
				(this.debounceTime - 1) * 1000
			)
		} else if (!status) {
			clearInterval(this.runDebounceLoop)
			this.runDebounceLoop = null
		}
	}

	loadingFinished(lookbook) {
		this.setState(
			{
				loading: undefined,
			},
			async () => {
				const objs =
					lookbook.dataJson?.map((obj) => ({
						...obj,
						textProp: clone(obj.textProp),
						// shapeProp: clone(obj.shapeProp),
						// cropPath: clone(obj.cropPath)
					})) || []
				const container = this.setStageContainer(lookbook)
				const scale = (container?.width || 0) / lookbook.screenWidth
				const objListNew = windowResize(objs, scale)
				this.setState({
					objList: objListNew,
					loading: false,
				})
				this.positionArray[0] = objListNew
			}
		)
	}

	flip(axis) {
		const { objList, selected, editText } = this.state
		if (this.selectedObjArray.length !== 1 || editText) {
			this.exitContextMenu()
			return
		}
		const newAttr = objList.find((x) => x.id === selected)

		if (axis === "x") {
			newAttr.image = getImage(newAttr, true)
			newAttr.imgScaleX *= -1
		}
		if (axis === "y") {
			newAttr.scaleY *= -1
		}
		this.exitContextMenu()
		this.onChange(newAttr)
		this.setPosition()
	}

	group() {
		const { editText } = this.state
		if (this.selectedObjArray.length < 2 || editText) {
			this.exitContextMenu()
			return
		}
		const includeGroupId = this.checkInGroup()
		if (includeGroupId === null) {
			this.groups.push(this.selectedObjArray.map((obj) => obj.id))
		} else {
			this.groups[includeGroupId] = this.selectedObjArray.map(
				(obj) => obj.id
			)
		}
		this.exitContextMenu()
	}

	keyEvents(e) {
		const { editText } = this.state
		if (e.srcElement.attributes["data-ce-title"]) return
		const validKeyPressed = () => {
			if (!editText) e.preventDefault()
		}
		const { keyCode, ctrlKey, metaKey, shiftKey, altKey } = e
		switch (keyCode) {
			case 46: {
				validKeyPressed()
				this.removeItem()
				break
			}
			case 8: {
				validKeyPressed()
				this.removeItem()
				break
			}
			case 37: {
				validKeyPressed()
				this.dragItem("x", -1 * this.dragDistance)
				this.dragEndItem("x", -1 * this.dragDistance)
				break
			}
			case 38: {
				validKeyPressed()
				this.dragItem("y", -1 * this.dragDistance)
				this.dragEndItem("y", -1 * this.dragDistance)
				break
			}
			case 39: {
				validKeyPressed()
				this.dragItem("x", this.dragDistance)
				this.dragEndItem("x", this.dragDistance)
				break
			}
			case 40: {
				validKeyPressed()
				this.dragItem("y", this.dragDistance)
				this.dragEndItem("y", this.dragDistance)
				break
			}
			default:
				break
		}
		if (!window.IOS && ctrlKey) {
			switch (keyCode) {
				case 71: {
					validKeyPressed()
					this.group()
					break
				}
				case 89: {
					validKeyPressed()
					this.redo()
					break
				}
				case 90: {
					validKeyPressed()
					this.undo()
					break
				}
				case 68: {
					validKeyPressed()
					this.duplicate()
					break
				}
				case 219: {
					validKeyPressed()
					this.moveItem(-1)
					break
				}
				case 221: {
					validKeyPressed()
					this.moveItem(+1)
					break
				}
				case 72: {
					validKeyPressed()
					this.flip("x")
					break
				}
				case 86: {
					validKeyPressed()
					this.flip("y")
					break
				}
				default:
					break
			}
			if (shiftKey) {
				switch (keyCode) {
					case 71: {
						validKeyPressed()
						this.unGroup()
						break
					}
					case 219: {
						validKeyPressed()
						this.moveItem(0)
						break
					}
					case 221: {
						validKeyPressed()
						this.moveItem()
						break
					}
					default:
						break
				}
			}
		}
		if (window.IOS && metaKey) {
			switch (keyCode) {
				case 71: {
					validKeyPressed()
					this.group()
					break
				}
				case 89: {
					validKeyPressed()
					this.redo()
					break
				}
				case 90: {
					validKeyPressed()
					this.undo()
					break
				}
				case 68: {
					validKeyPressed()
					this.duplicate()
					break
				}
				case 219: {
					validKeyPressed()
					this.moveItem(-1)
					break
				}
				case 221: {
					validKeyPressed()
					this.moveItem(+1)
					break
				}
				case 72: {
					validKeyPressed()
					this.flip("x")
					break
				}
				case 86: {
					validKeyPressed()
					this.flip("y")
					break
				}
				default:
					break
			}
			if (shiftKey) {
				switch (keyCode) {
					case 71: {
						validKeyPressed()
						this.unGroup()
						break
					}
					case 219: {
						validKeyPressed()
						this.moveItem(0)
						break
					}
					case 221: {
						validKeyPressed()
						this.moveItem()
						break
					}
					default:
						break
				}
			}
		}
		if (altKey) {
			this.setState({ altPressed: true })
		}
		if (shiftKey) {
			this.setState({ shiftPressed: true })
		}
	}

	keyUp() {
		const { altPressed, shiftPressed } = this.state
		if (altPressed) {
			this.setState({ altPressed: false })
		}
		if (shiftPressed) {
			this.setState({ shiftPressed: false })
		}
	}

	layerDragStart({ evt }) {
		if (evt) {
			const { altKey } = evt
			const { dragStart } = this.state
			if (altKey) {
				this.setState({ dragStart: true })
			}
			if (altKey && !dragStart) {
				this.duplicate(true)
			}
		}
	}

	layerDragEnd() {
		this.transLayer.find(".guid-line").map((obj) => obj.destroy())
		this.transLayer.batchDraw()
		this.setState({ dragStart: false })
	}

	layerDragMove(e) {
		const { target } = e
		if (!(target.attrs.image || target.attrs.data || target.attrs.text))
			return
		const selTarget =
			this.selectedObjArray.length > 1 ? this.selectRect : target
		this.transLayer.find(".guid-line").map((obj) => obj.destroy())
		const lineGuideStops = this.getLineGuideStops()
		const itemBounds = getObjectSnappingEdges(selTarget)
		const guides = getGuides(lineGuideStops, itemBounds)
		if (!guides.length) return

		this.drawGuides(guides)

		const absPos = selTarget.absolutePosition()
		guides.forEach((lg) => {
			switch (lg.snap) {
				case "start": {
					switch (lg.orientation) {
						case "V": {
							absPos.x = lg.lineGuide + lg.offset
							break
						}
						case "H": {
							absPos.y = lg.lineGuide + lg.offset
							break
						}
						default:
							break
					}
					break
				}
				case "center": {
					switch (lg.orientation) {
						case "V": {
							absPos.x = lg.lineGuide + lg.offset
							break
						}
						case "H": {
							absPos.y = lg.lineGuide + lg.offset
							break
						}
						default:
							break
					}
					break
				}
				case "end": {
					switch (lg.orientation) {
						case "V": {
							absPos.x = lg.lineGuide + lg.offset
							break
						}
						case "H": {
							absPos.y = lg.lineGuide + lg.offset
							break
						}
						default:
							break
					}
					break
				}
				default:
					break
			}
		})
		selTarget.absolutePosition(absPos)
	}

	lockedElementInSelected() {
		return this.selectedObjArray
			.map((obj) => (obj.lock ? obj.id : false))
			.filter((x) => x)
	}

	lockItem(status = true) {
		const { objList } = this.state
		const selectedObjects = this.getSelectedObjList()
		selectedObjects.forEach((obj) => {
			obj.lock = status
		})
		this.setState({ objList: replaceItems(objList, selectedObjects) })
	}

	moveItem(index = null) {
		const { objList, editText } = this.state
		const { doChanges } = this.props
		const objects = objList
		if (this.selectedObjArray.length === 0 || editText) {
			this.exitContextMenu()
			return
		}
		doChanges()
		const selectedIds = this.selectedObjArray.map((x) => ({ id: x.id }))
		this.selectedObjArray = []
		const selectedObjs = []
		selectedIds.forEach((obj) => {
			const selected = obj.id
			const oldIndex = clone(objects.findIndex((x) => x.id === selected))
			const newAttr = clone(objects.find((x) => x.id === selected))
			selectedObjs.push({ index: oldIndex, obj: newAttr })
			objects.splice(oldIndex, 1)
			this.selectedObjArray.push(newAttr)
		})
		let newObjs = []
		if (index === null) {
			objects.push(...selectedObjs.map((x) => x.obj))
			newObjs = objects
		} else if (index === 0) {
			newObjs = selectedObjs.map((x) => x.obj)
			newObjs.push(...objects)
		} else if (index > 0) {
			let max = Math.max(...selectedObjs.map((x) => x.index))
			max =
				!isNaN(max) && max + 1 < objects.length ? max + 1 : objects.length
			objects.splice(max, 0, ...selectedObjs.map((x) => x.obj))
			newObjs = objects
		} else if (index < 0) {
			let min = Math.min(...selectedObjs.map((x) => x.index))
			min = !isNaN(min) && min - 1 > 0 ? min - 1 : 0
			objects.splice(min, 0, ...selectedObjs.map((x) => x.obj))
			newObjs = objects
		}
		this.setState(
			{
				objList: newObjs,
				selected: this.selectedObjArray[this.selectedObjArray.length - 1]
					.id,
			},
			() => {
				this.bindTransformer()
				this.setPosition()
			}
		)
		this.exitContextMenu()
	}

	redo() {
		const { position, selected, editText } = this.state
		const { doChanges } = this.props
		if (editText) {
			this.exitContextMenu()
			return
		}
		doChanges()
		if (this.positionArray.length > 0) {
			const pos =
				position === this.positionArray.length
					? this.positionArray.length
					: position + 1
			this.setState(
				{
					objList: this.positionArray[pos - 1],
					position: pos,
				},
				() => selected && this.bindTransformer()
			)
		}
	}

	removeItem() {
		const { objList, editText } = this.state
		const { doChanges } = this.props
		if (this.selectedObjArray.length === 0 || editText) {
			this.exitContextMenu()
			return
		}
		const objects = objList
		this.selectedObjArray.forEach((obj) => {
			const index = objects.findIndex((x) => x.id === obj.id)
			objects.splice(index, 1)
		})
		this.selectedObjArray = []
		this.setState(
			{ objList: objects, selected: null, selectRect: null },
			() => this.setPosition(objects)
		)
		this.exitContextMenu()
		doChanges(CHANGES_FROM.CANVAS)
	}

	bindTransformer() {
		const { objList } = this.state
		if (
			this.selectedObjArray.length > 0 &&
			objList.length > 0 &&
			includes(
				objList.map((x) => x.id),
				...this.selectedObjArray.map((x) => x.id)
			)
		) {
			this.tarRef.show()
			if (this.selectedObjArray.length > 0) {
				this.tarRef.nodes([
					...this.selectedObjArray.map((obj) => this.imageRef[obj.id]),
				])
			}
			if (
				this.selectedObjArray.length === 1 &&
				this.selectedObjArray[0].textProp
			) {
				this.tarRef.enabledAnchors([
					"middle-right",
					"middle-left",
					"top-left",
					"top-right",
					"bottom-left",
					"bottom-right",
				])
			} else {
				this.tarRef.enabledAnchors([
					"top-left",
					"top-center",
					"top-right",
					"middle-right",
					"middle-left",
					"bottom-left",
					"bottom-center",
					"bottom-right",
				])
			}
			if (this.selectedObjArray.length > 1) {
				this.tarRef.nodes([...this.tarRef.nodes()])
			} else {
				this.selectRect.hide()
			}
			this.tarRef.rotateAnchorOffset(25)
			this.tarRef.getLayer().batchDraw()
		} else if (this.tarRef) {
			this.tarRef.hide()
		}
	}

	changeCanvasSize(canvas) {
		const { objList, container } = this.state
		const { changeLookbook, doChanges } = this.props
		const { canvasWidth, canvasHeight } = canvas
		doChanges(CHANGES_FROM.CANVAS)
		changeLookbook({
			dataJson: objList,
			width: canvasWidth,
			height: canvasHeight,
			screenWidth: container.width,
		})
		this.drawBoard()
	}

	checkAllInGroup() {
		const includeGroupId = this.checkInGroup()
		let result = true
		if (includeGroupId === null) {
			result = false
		} else {
			this.selectedObjArray.forEach((obj) => {
				if (!this.groups[includeGroupId].includes(obj.id)) {
					result = false
				}
			})
		}
		return result
	}

	checkCanvas(width, height) {
		const selectedCanvas = CanvasSizeList.find(
			(x) => x.width === width && x.height === height
		)
		if (selectedCanvas?.id === "video_thumb") {
			this.setState({ VideoThumbnail: true, ChannelBanner: false })
		} else if (selectedCanvas?.id === "channel_banner") {
			this.setState({ VideoThumbnail: false, ChannelBanner: true })
		} else {
			this.setState({ VideoThumbnail: false, ChannelBanner: false })
		}
	}

	checkDeselect({ target, evt }) {
		const { textEdit } = this.state
		if (target === this.background || target === this.greyBackground) {
			if (textEdit) {
				this.endEdit(textEdit)
			} else {
				this.deselect(evt)
			}
		}
		if (
			this.textEditingIds.includes(target.id) ||
			target?.tagName?.toLowerCase() === "input" ||
			textEdit
		) {
			this.setState({ editText: true })
		} else {
			this.setState({ editText: false })
		}
		this.contextMenu.style.display = "none"
		this.setState({ contextMenu: null })
	}

	checkInGroup() {
		let includeGroupId = null
		this.selectedObjArray.forEach((obj) => {
			this.groups.forEach((group, i) => {
				if (group.includes(obj.id)) {
					includeGroupId = i
				}
			})
		})
		return includeGroupId
	}

	clearBoard() {
		const { textEdit } = this.state
		const { clearBoard } = this.props
		if (textEdit) document.body.removeChild(document.getElementById(textEdit))
		this.setState(
			{
				objList: [],
				selected: null,
				selectRect: false,
				textEdit: null,
			},
			() => this.setPosition()
		)
		this.selectedObjArray = []
		clearBoard()
		this.closeModal()
	}

	drawBoard() {
		const { boardLoading, loading } = this.state
		if (!boardLoading && !loading) {
			this.setState({ objList: [], boardLoading: true })
		}
	}

	closeModal() {
		this.setState({
			openCropModal: false,
			openClearConfirmModal: false,
			openDeleteBoardConfirmModal: false,
		})
		this.runDebounce(false)
	}

	async createBlob(fileName = "temp", scale = 1) {
		const { container, selected } = this.state
		const { selectedBoard } = this.props
		const selectedBC = selected
		const selObjArrayBC = this.selectedObjArray
		this.deselect()
		const blob = stageToBlob(
			this.stage,
			container?.width,
			container?.height,
			selectedBoard.width,
			fileName,
			scale,
			container?.x,
			container?.y
		)
		this.selectedObjArray = selObjArrayBC
		this.setState({ selected: selectedBC })
		this.bindTransformer()
		return blob
	}

	async downloadImage() {
		const { textEdit } = this.state
		const { lookbook } = this.props
		if (textEdit) this.endEdit(textEdit)
		const blob = await this.createBlob()
		downloadBlob(blob, lookbook.title)
		if (this.tarRef) this.tarRef.show()
	}

	drawGuides(guides) {
		guides.forEach((lg) => {
			if (lg.orientation === "H") {
				const line = new Konva.Line({
					points: [-6000, 0, 6000, 0],
					stroke: "rgb(0, 161, 255)",
					strokeWidth: 1,
					name: "guid-line",
					dash: [4, 6],
				})
				this.transLayer.add(line)
				line.absolutePosition({
					x: 0,
					y: lg.lineGuide,
				})
				this.transLayer.batchDraw()
			} else if (lg.orientation === "V") {
				const line = new Konva.Line({
					points: [0, -6000, 0, 6000],
					stroke: "rgb(0, 161, 255)",
					strokeWidth: 1,
					name: "guid-line",
					dash: [4, 6],
				})
				this.transLayer.add(line)
				line.absolutePosition({
					x: lg.lineGuide,
					y: 0,
				})
				this.transLayer.batchDraw()
			}
		})
	}

	dragEndItem(axis, dist) {
		if (this.lockedElementInSelected().length === 0) {
			this.runDebounce(false)
			const { objList } = this.state
			const objListNew = objList.map((value) => clone(value))
			objListNew.forEach((obj) => {
				if (this.selectedObjArray.map((x) => x.id).includes(obj.id)) {
					let distance = 0
					if (axis === "x") {
						distance = dist
					} else if (axis === "y") {
						distance = dist
					}
					obj.x = this.imageRef[obj.id].x() + distance
					obj.y = this.imageRef[obj.id].y() + distance
				}
			})
			this.setState(
				{
					objList: replaceItems(objList, objListNew),
				},
				() => this.setPosition()
			)
		}
	}

	dragItem(axis, dist) {
		if (this.lockedElementInSelected().length === 0) {
			const { editText } = this.state
			const { doChanges, haveChanges } = this.props
			if (!haveChanges) doChanges(CHANGES_FROM.CANVAS)
			this.runDebounce(true)
			if (this.selectedObjArray.length === 0 || editText) {
				this.exitContextMenu()
				return
			}
			this.selectedObjArray.forEach((obj) => {
				if (axis === "x") {
					const newX = this.imageRef[obj.id].x() + dist
					this.imageRef[obj.id].x(newX)
					if(obj.shapeProp && obj.shapeProp.borderAllowed){
						obj.shapeProp.path.map(path=>this.shapeRef[obj.id][path].x(newX))
					}
				} else if (axis === "y") {
					const newY = this.imageRef[obj.id].y() + dist
					this.imageRef[obj.id].y(newY)
					if(obj.shapeProp && obj.shapeProp.borderAllowed){
						obj.shapeProp.path.map(path=>this.shapeRef[obj.id][path].y(newY))
					}
				}
			})
			if (axis === "x") {
				this.selectRect.x(this.selectRect.x() + dist)
			} else if (axis === "y") {
				this.selectRect.y(this.selectRect.y() + dist)
			}
		}
	}

	drawMultipleSelectBox() {
		const { container } = this.state
		let minX = 0
		let minY = 0
		let maxX = 0
		let maxY = 0
		const deg = 0
		if (this.selectedObjArray.length > 0) {
			minX = container?.width
			minY = container?.height
		}
		this.selectedObjArray.forEach((obj) => {
			const boundry = this.imageRef[obj.id].getClientRect({
				relativeTo: this.parentLayer,
			})
			minX = Math.min(minX, boundry.x)
			minY = Math.min(minY, boundry.y)
			maxX = Math.max(maxX, boundry.x + boundry.width)
			maxY = Math.max(maxY, boundry.y + boundry.height)
		})
		this.setState(
			{
				selectX: minX + (maxX - minX) / 2,
				selectY: minY + (maxY - minY) / 2,
				selectOffsetX: (maxX - minX) / 2,
				selectOffsetY: (maxY - minY) / 2,
				selectWidth: maxX - minX,
				selectHeight: maxY - minY,
				selectDeg: deg,
				draw: false,
				selectFill: "rgba(0,0,0,0)",
			},
			() => {
				this.bindTransformer()
				this.selectRect.moveToBottom()
			}
		)
	}

	drawSelectRect({ evt }) {
		const { selectX, selectY, selectRect, container, draw } = this.state
		if (selectRect && draw) {
			this.runDebounce(true)
			this.setState(
				{
					selectWidth:
						evt.x -
						(container.element.getBoundingClientRect().x + container.x) -
						selectX,
					selectHeight:
						evt.y -
						(container.element.getBoundingClientRect().y + container.y) -
						selectY,
					selectFill: "rgba(0,0,255,0.3)",
				},
				() => {
					this.selectRect.show()
					this.selectRect.moveToTop()
				}
			)
		}
	}

	drop(e) {
		const { doChanges, chageDrawingStatus, dropCard } = this.props
		const { clientX, clientY, id: cardId, type } = e
		doChanges(CHANGES_FROM.CANVAS)
		if (type === CategoryENUM.TEMPLATE) {
			dropCard()
		} else {
			chageDrawingStatus(true)
			const child = document.getElementById(cardId)
			const card = child?.cloneNode()
			const tag = card.tagName.toLowerCase()
			switch (tag) {
				case "img":
					this.dropImage(card, clientX, e.clientY)
					break
				case "h1":
					this.dropText(card, clientX, clientY)
					break
				case "svg":
					this.dropShape(child, clientX, clientY)
					break
				default:
					break
			}
		}
	}

	dropByClick(cardId) {
		const { container } = this.state
		const { doChanges, chageDrawingStatus } = this.props
		doChanges(CHANGES_FROM.CANVAS)
		chageDrawingStatus(true)
		const child = document.getElementById(cardId)
		const clientX =
			((container?.width || 0) / 2 || 540) +
			(container?.element.getBoundingClientRect().x || 0) +
			(container?.x || 0)
		const clientY =
			((container?.height || 0) / 2 || 540) +
			(container?.element.getBoundingClientRect().y || 0) +
			(container?.y || 0)
		const card = child?.cloneNode()
		const tag = card.tagName.toLowerCase()
		switch (tag) {
			case "img":
				this.dropImage(card, clientX, clientY)
				break
			case "h1":
				this.dropText(card, clientX, clientY)
				break
			case "svg":
				this.dropShape(child, clientX, clientY)
				break
			default:
				break
		}
	}

	async dropImage(card, clientX, clientY) {
		const { container, objList, replacingImage } = this.state
		const { chageDrawingStatus } = this.props
		const id = card.id.concat("_", createGuid(4))
		const url = new URL(card.src)
		const src = addVersioning(`${url.origin}${url.pathname}`, `d=400`)
		const img = new Item()
		img.imageUrl = src
		img.image = await getImageByURL(img)
		const width = img.image.width || (container?.width || 0) / 4
		const height = img.image.height || container.height / 4
		const scaleX = (container?.width || 0) / (4 * width)
		const x =
			clientX - (container.x + container.element.getBoundingClientRect().x)
		const y =
			clientY - (container.y + container.element.getBoundingClientRect().y)
		img.id = id
		const category = card.getAttribute("data-category")
		img.category = category
		img.shopcastProductID = card.getAttribute("data-itemid")
			? card.getAttribute("data-itemid")
			: null
		img.lookbookUploadID = card.getAttribute("data-uploadid")
			? card.getAttribute("data-uploadid")
			: null
		const imageCount = objList.filter((obj) => obj?.id === id).length
		img.title = `${card.alt} ${imageCount > 0 ? imageCount : ""}`
		img.x = x
		img.y = y
		img.width = width
		img.height = height
		img.scaleX = scaleX
		img.scaleY = scaleX
		const { X, Y } = getDropCoordinates(img, container)
		img.x = X
		img.y = Y
		img.offsetX = width / 2
		img.offsetY = height / 2
		img.pixel = await getPixelData(img)
		img.cropPath = [{ x: 0, y: 0, width, height }]
		img.onTray = false
		// const color = img.pixel
		// 	? Math.min(
		// 			...img.pixel.slice(0, 100),
		// 			...img.pixel.slice(img.pixel.length - 100, img.pixel.length - 1)
		// 	  )
		// 	: 255
		// img.transparentLevel =
		//    256 - (color === 0 ? 255 : color) > 100
		//       ? 100
		//       : 256 - (color === 0 ? 255 : color)

		// changeTransparency(img.transparentLevel, img)
		if (replacingImage) {
			img.id = replacingImage.id
			img.x = replacingImage.x
			img.y = replacingImage.y
			img.rotation = replacingImage.rotation
			img.lock = replacingImage.lock
			img.shadowColor = replacingImage.shadowColor
			img.shadowBlur = replacingImage.shadowBlur
			img.shadowOffset = replacingImage.shadowOffset
			img.shadowOpacity = replacingImage.shadowOpacity
			img.isDroppableItem = replacingImage.isDroppableItem
			if (
				replacingImage.clipProp &&
				replacingImage.clipProp.type === ClipTypeENUM.FILL
			) {
				const { maxScaleX, maxScaleY } = getBestScales(replacingImage, img)
				img.scaleX = maxScaleX
				img.scaleY = maxScaleY
				const displayWidth =
					(replacingImage.width * Math.abs(replacingImage.scaleX)) /
					img.scaleX
				const displayHeight =
					(replacingImage.height * Math.abs(replacingImage.scaleY)) /
					img.scaleY
				img.cropPath.push({
					x: ((img.width - displayWidth) * img.scaleX) / 2,
					y: ((img.height - displayHeight) * img.scaleY) / 2,
					width: displayWidth,
					height: displayHeight,
				})
				img.offsetX = displayWidth / 2
				img.offsetY = displayHeight / 2
				img.clipProp = replacingImage.clipProp
				img.image = cropImage(img)
				img.pixel = await getPixelData(img)
			} else {
				const { minScaleX, minScaleY } = getBestScales(replacingImage, img)
				img.scaleX = minScaleX
				img.scaleY = minScaleY
				img.clipProp = {
					width: replacingImage.width,
					height: replacingImage.height,
					type: ClipTypeENUM.FIT,
				}
			}
			this.imageRef[replacingImage.id].strokeWidth(0)
			this.setState(
				{
					replacingImage: null,
					objList: replaceItems(objList, [img]),
					clipType: img.clipProp.type,
				},
				() => this.setPosition()
			)
		} else {
			this.setState(
				{
					objList: [...objList, img],
				},
				() => this.setPosition()
			)
		}
		const src1000 = addVersioning(`${url.origin}${url.pathname}`, `d=1000`)
		img.imageUrl = src1000
		getImageByURL(img).then(async (image) => {
			this.setState(
				state => {
					const img1000 = state.objList.find(p => p.id === img.id)
					if (!img1000) return state
					img1000.image = image
					const width1000 = img1000.image.width || (container?.width || 0) / 4
					const height1000 = img1000.image.height || container.height / 4
					const scaleX1000 = img1000.width * img1000.scaleX / width1000
					img1000.width = width1000
					img1000.height = height1000
					img1000.scaleX = scaleX1000
					img1000.scaleY = scaleX1000
					img1000.offsetX = width1000 / 2
					img1000.offsetY = height1000 / 2
					img1000.pixel = getPixelData(img1000)
					img1000.cropPath = [{ x: 0, y: 0, width: width1000, height: height1000 }]
					return ({
						objList: replaceItems(state.objList, [img1000]),
					})
				},
				() => this.setPosition()
			)
		})
		chageDrawingStatus(false)
	}

	dropShape(card, clientX, clientY) {
		const { container, objList } = this.state
		const { chageDrawingStatus } = this.props
		const { viewBox } = card
		const width = 200
		const height = ((viewBox?.baseVal.height || 0) / (viewBox?.baseVal.width || 1)) * 200
		const scaleX = width / 200
		const x =
			clientX - (container.x + container.element.getBoundingClientRect().x)
		const y =
			clientY - (container.y + container.element.getBoundingClientRect().y)
		const id = card.id.concat("_", createGuid(4))

		const img = new Item()
		const shapeProp = new ShapeProps()
		const textCount = objList.filter((obj) => obj?.shapeProp).length
		img.title = `Shape ${textCount > 0 ? textCount : ""}`
		img.shapeID = isNaN(card.getAttribute("data-itemid"))
			? card.getAttribute("data-itemid")
			: null
		img.id = id
		img.category = card.getAttribute("data-category")
		shapeProp.svg = card.id
		shapeProp.fill = card.getAttribute("data-fill")
		shapeProp.viewBoxWidth = viewBox?.baseVal.width || 256
		shapeProp.viewBoxHeight = viewBox?.baseVal.height || 256
		img.x = x
		img.y = y
		img.width = width
		img.height = height
		img.scaleX = scaleX
		img.scaleY = scaleX
		const { X, Y } = getDropCoordinates(img, container)
		img.x = X
		img.y = Y
		img.offsetX = width / 2
		img.offsetY = height / 2
		img.shapeProp = shapeProp
		img.cropPath = [{ x: 0, y: 0, width, height }]
		img.image = getImageBySVG(img)
		img.onTray = false
		this.setState(
			{
				objList: [...objList, img],
			},
			() => this.setPosition()
		)
		chageDrawingStatus(false)
	}

	dropText(card, clientX, clientY) {
		const { container, objList } = this.state
		const { chageDrawingStatus } = this.props
		const item = new Item()
		const textProp = new TextProps()
		const width = (container?.width || 0) / 4
		textProp.fontSize = parseInt(
			(textProp.fontSize * (container?.width || 0)) / (262 * 4),
			10
		)
		const height = textProp.fontSize
		const fontFamily = card.style.fontFamily?.replace(/"/g, "")
		textProp.fontFamily = card.style.fontFamily?.replace(/"/g, "")
		textProp.fill = card.getAttribute("data-fill")
		item.id = createGuid(8)
		const textCount = objList.filter(
			(x) => x?.textProp?.fontFamily === fontFamily
		).length
		item.title = `${fontFamily} ${textCount > 0 ? textCount : ""}`
		item.width = width
		item.x =
			clientX - (container.x + container.element.getBoundingClientRect().x)
		item.y =
			clientY - (container.y + container.element.getBoundingClientRect().y)
		item.offsetX = width / 2
		item.offsetY = height / 2
		item.textProp = textProp
		item.onTray = false
		this.setState(
			{
				objList: [...objList, item],
				lineHeight: textProp.lineHeight,
			},
			() => this.setPosition()
		)
		chageDrawingStatus(false)
	}

	duplicate(inDrag = false) {
		const { objList, container, editText } = this.state
		const { doChanges } = this.props
		if (this.selectedObjArray.length === 0 || editText) {
			this.exitContextMenu()
			return
		}
		const objects = []
		this.selectedObjArray.forEach((obj) => {
			const selected = obj.id
			if (inDrag) this.imageRef[selected].stopDrag()
			const newAttr = clone(objList.find((x) => x.id === selected))
			newAttr.id = newAttr.id.concat("_", createGuid(4))
			newAttr.shapeProp = clone(newAttr.shapeProp)
			if (!inDrag) {
				newAttr.x += 20
				newAttr.y += 20
				const { X, Y } = getDropCoordinates(newAttr, container)
				newAttr.x = X
				newAttr.y = Y
			}
			objects.push(newAttr)
		})
		if (objects.length > 1 && this.checkInGroup() !== null) {
			this.groups.push(objects.map((obj) => obj.id))
		}
		this.selectedObjArray = objects
		doChanges()
		this.setState(
			{
				objList: [...objList, ...objects],
				selected: objects[objects.length - 1].id,
			},
			() => {
				if (inDrag) {
					objects.forEach((obj) => this.imageRef[obj.id]?.startDrag())
					this.setState({ dragStart: true })
				}
				this.bindTransformer()
			}
		)
		this.exitContextMenu()
		this.setPosition([...objList, ...objects])
	}

	editText({
		fontWeight,
		fontStyle,
		fontFamily,
		color,
		fontSize,
		align,
		lineHeight,
	}) {
		const { objList, textEdit, selected, textBold, textItalic } = this.state
		if (textEdit) {
			const textarea = document.getElementById(textEdit)
			if (fontWeight)
				textarea.style.fontWeight =
					textarea.style.fontWeight === "bold" ? "" : fontWeight
			if (fontStyle)
				textarea.style.fontStyle =
					textarea.style.fontStyle === "italic" ? "" : fontStyle
			if (fontFamily) textarea.style.fontFamily = fontFamily
			if (color) textarea.style.color = color
			if (fontSize) textarea.style.fontSize = `${fontSize}px`
			if (align) textarea.style.textAlign = align
			if (lineHeight) textarea.style.lineHeight = lineHeight

			if (fontWeight)
				this.setState({
					textBold: textarea.style.fontWeight === "bold",
				})
			if (fontStyle)
				this.setState({
					textItalic: textarea.style.fontStyle === "italic",
				})
		} else {
			const node = objList.find((x) => x.id === selected)
			let fontWeight2 = ""
			let fontStyle2 = ""
			if (fontWeight) {
				fontWeight2 +=
					node.textProp.fontStyle.search("bold") >= 0 ? "" : "bold"
			} else {
				fontWeight2 +=
					node.textProp.fontStyle.search("bold") >= 0 ? "bold" : ""
			}
			if (fontStyle) {
				fontStyle2 +=
					node.textProp.fontStyle.search("italic") >= 0 ? "" : "italic"
			} else {
				fontStyle2 +=
					node.textProp.fontStyle.search("italic") >= 0 ? "italic" : ""
			}
			const fontStyle3 = `${fontWeight2} ${fontStyle2}`
			this.onChange({
				...node,
				title: fontFamily || node.textProp.fontFamily,
				...node.textProp,
				textProp: {
					fontStyle: fontStyle3,
					fontFamily: fontFamily || node.textProp.fontFamily,
					fill: color || node.textProp.fill,
					fontSize: fontSize || node.textProp.fontSize,
					align: align || node.textProp.align,
					lineHeight: lineHeight || node.textProp.lineHeight,
				},
			})
			this.setState({
				textBold: fontWeight ? !textBold : textBold,
				textItalic: fontStyle ? !textItalic : textItalic,
			})
		}
		if (color) this.setState({ color })
		if (align) this.setState({ textAlign: align })
		if (lineHeight) this.setState({ lineHeight })
		if (fontSize) this.setState({ fontSize })
		if (fontFamily) this.setState({ fontFamily })
	}

	endDraw({ evt }) {
		const {
			selectX,
			selectY,
			selectRect,
			draw,
			container,
			objList,
		} = this.state
		if (selectRect && draw) {
			this.runDebounce(false)
			const endX =
				evt.x - (container.element.getBoundingClientRect().x + container.x)
			const endY =
				evt.y - (container.element.getBoundingClientRect().y + container.y)
			objList.forEach((x) => {
				if (
					((x.x >= selectX && x.x <= endX) ||
						(x.x <= selectX && x.x >= endX)) &&
					((x.y >= selectY && x.y <= endY) ||
						(x.y <= selectY && x.y >= endY)) &&
					!x.lock
				) {
					this.selectedObjArray.push(x)
				}
			})
			const includeGroupId = this.checkInGroup()
			if (includeGroupId != null) {
				const groupObj = objList.filter((obj) =>
					this.groups[includeGroupId].includes(obj.id)
				)
				this.selectedObjArray = [
					...this.selectedObjArray,
					...groupObj.filter((x) => !this.selectedObjArray.includes(x)),
				]
			}
			this.drawMultipleSelectBox()
		}
	}

	endEdit(id) {
		this.runDebounce(false)
		const { objList } = this.state
		const target = document.getElementById(id)
		const width = Math.ceil(target.offsetWidth)
		const height = Math.ceil(target.offsetHeight)
		const textNodeId = target.id.replace("text_", "")
		const textNode = this.imageRef[textNodeId]
		textNode.show()
		const textProp = { ...textNode.attrs.textProp }
		textProp.text = target.innerText
		textProp.padding = parseInt(target.style.padding.replace("px", ""), 10)
		textProp.fontStyle = `${target.style.fontStyle} ${target.style.fontWeight}`
		textProp.fill = target.style.color
		textProp.fontSize = parseInt(target.style.fontSize.replace("px", ""), 10)
		textProp.fontFamily = target.style.fontFamily
		textProp.align = target.style.textAlign
		textProp.lineHeight = parseFloat(target.style.lineHeight)
		const newAttr = objList.find((x) => x.id === textNodeId)
		newAttr.width = width
		newAttr.title = target.style.fontFamily
		newAttr.offsetX = width / 2
		newAttr.offsetY = height / 2
		newAttr.x = textNode.x()
		newAttr.y = textNode.y()
		newAttr.textProp = textProp
		document.body.removeChild(target)
		this.selectedObjArray.push(newAttr)
		this.setState(
			{
				textEdit: null,
				selected: textNode.id(),
			},
			() => this.bindTransformer()
		)
		this.onChange(newAttr)
		this.setPosition()
	}

	exitContextMenu() {
		this.contextMenu.style.display = "none"
		this.setState({ contextMenu: null })
	}

	async saveLookbook(forceSave) {
		const {
			id,
			lookbook,
			selectedBoard,
			lookbookBoards,
			relay,
			saving,
			savingChange,
			savingComplete,
			haveChanges,
			doChanges,
			defaultLookbookTitle,
			defaultTemplateTitle,
			folderId,
			changeLookbook,
			team,
			templateCategory
		} = this.props
		const { objList, container, textEdit, loading, selectBoard } = this.state
		if (loading) {
			if (haveChanges) {
				doChanges(CHANGES_FROM.CANVAS)
			}
			return { boardId: selectedBoard.boardId }
		}
		if (!(haveChanges || forceSave) || (saving && !forceSave)) {
			return { boardId: selectedBoard.boardId }
		}
		if (textEdit) {
			this.endEdit(textEdit)
		}
		const {
			publishedAt,
			id: lookbookId,
			title: lookbookTitle,
			description,
			templateId,
			isGlobal,
			isTemplate,
			categories,
		} = lookbook
		const {
			background,
			height,
			width,
			boardId,
			title: boardTiltle,
			usedTemplate,
			pageNo,
			showBoard,
		} = selectedBoard
		if (!saving) savingChange(true)
		const data = objList.map((value) => clone(value))
		const bg = clone(background)
		data.forEach((x) => {
			unset(x, "pixel")
			unset(x, "image")
		})
		unset(bg, "fillPatternImage")
		const boards = [...new Set(lookbookBoards)]
		const selectedIndex = boards.findIndex(
			(board) => board.boardId === selectedBoard.boardId
		)
		boards[selectedIndex].dataJson = objList
		const dataJsons = []
		boards.forEach((board) => {
			if (board.dataJson) dataJsons.push(...board.dataJson)
		})
		const usedProducts = [
			...new Set(
				dataJsons.map((obj) => obj.shopcastProductID).filter((x) => x)
			),
		]
		const usedShapes = [
			...new Set(dataJsons.map((obj) => obj.shapeID).filter((x) => x)),
		]
		const lookbookData = {
			id: lookbookId !== undefined ? lookbookId : id,
			title:
				lookbookTitle ||
				(lookbook.isTemplate ? defaultTemplateTitle : defaultLookbookTitle),
			description,
			published_at: publishedAt,
			boardId,
			boardTiltle,
			screen_width: container?.width,
			data_json: JSON.stringify(data),
			usedShapes,
			usedProducts,
			width,
			height,
			background: bg ? JSON.stringify(bg) : null,
			folder: lookbook.folder?.id || folderId || null,
			group: JSON.stringify(this.groups),
			templateId,
			isGlobal,
			isTemplate,
			categories: categories || [],
			usedTemplate,
			pageNo,
			showBoard,
			teamId: team
		}
		const thumbImage = await this.createBlob(lookbook.title, 2)
		const newFile = new File([thumbImage], `${lookbook.title}.${exportExt}`)
		const response = await new Promise((res, rej) => {
			if (selectBoard) {
				res({ boardId })
			}
			updateLookbookMutation.commit(
				relay.environment,
				lookbookData,
				newFile,
				(e) => {
					toast.info(<Fragment>{gql.getError(e)}</Fragment>, {
						autoClose: 5000,
						closeButton: false,
					})
					rej()
				},
				async ({ createLookbook }) => {
					const Id = createLookbook.lookbook.id
					const returnData = {}
					if (!id) {
						returnData.lookbookId = createLookbook.lookbook.lookbookId
						window.history.pushState(
							{ lookbookId },
							"",
							`${window.location.origin}/shopboard/create/${createLookbook.lookbook.lookbookId}`
						)
					}
					if (Id !== lookbookData.boardId) {
						returnData.boardId = Id
					}
					const changesBoard = {
						...returnData,
						group: this.groups,
						dataJson: objList,
						screenWidth: container?.width,
						url: createLookbook.lookbook.url,
					}
					const selecting = {
						...selectedBoard,
						...changesBoard,
					}
					changeLookbook(changesBoard, undefined, selecting.boardId)
					savingComplete()
					savingChange(false)
					res(returnData)
				},
				{
					category: templateCategory,
					teamId: team
				}
			)
		})
		return response
	}

	deselect(evt) {
		const { container } = this.state
		let state = {
			selected: null,
			draw: false,
			selectRect: true,
			selectX: evt
				? evt.x -
				  (container.element.getBoundingClientRect().x + container.x)
				: 0,
			selectY: evt
				? evt.y -
				  (container.element.getBoundingClientRect().y + container.y)
				: 0,
			selectWidth: 0,
			selectHeight: 0,
			selectOffsetX: 0,
			selectOffsetY: 0,
		}
		this.selectedObjArray = []
		if (evt) {
			state = {
				...state,
				draw: true,
			}
		}
		if (this.selectRect) {
			this.selectRect.scaleX(1)
			this.selectRect.scaleY(1)
			this.selectRect.rotation(0)
		}
		this.setState(state)
		if (this.tarRef) {
			this.tarRef.nodes([])
		}
		if (this.runDebounceLoop) {
			this.runDebounce(false)
		}
	}

	undo() {
		const { position, selected, editText } = this.state
		const { doChanges } = this.props
		if (editText) {
			this.exitContextMenu()
			return
		}
		doChanges()
		const pos = position ? position - 1 : 0
		this.setState(
			{
				objList: pos ? this.positionArray[pos - 1] : this.positionArray[0],
				position: pos,
			},
			() => selected && this.bindTransformer()
		)
	}

	unGroup() {
		const includeGroupId = this.checkInGroup()
		if (includeGroupId != null) {
			this.selectedObjArray = [this.selectedObjArray[0]]
			this.groups = []
		}
		this.exitContextMenu()
		this.bindTransformer()
	}

	async updateVideoThumb(shopcastId) {
		const { lookbook, relay } = this.props
		const { shopcastList } = this.state
		const selected = shopcastList
			? shopcastList.find((x) => x.id === shopcastId)
			: null
		if (selected) {
			this.setState({ videoThumbSaving: true })
			const file = await this.createBlob(lookbook.title)
			const newFile = new File([file], `${lookbook.title}.${exportExt}`)
			posterUrlMutation.commit(
				relay.environment,
				newFile,
				shopcastId,
				null,
				() => {
					this.setState({
						videoThumbSaving: false,
						shopcastModalOpen: false,
					})
					toast.success(
						<Fragment>
							Video cover on {selected?.title} is updated.
						</Fragment>,
						{
							autoClose: 5000,
							closeButton: false,
						}
					)
				},
				(e) => {
					toast.info(<Fragment>{gql.getError(e)}</Fragment>, {
						autoClose: 5000,
						closeButton: false,
					})
				}
			)
		}
	}

	dragOver(e) {
		const { objList, container, replacingImage } = this.state
		e.preventDefault()
		let dropObj = null
		if (e.dataTransfer.effectAllowed === "move") {
			dropObj = [...objList].reverse().find((obj) => {
				if (!obj.onTray) {
					const clientRect = this.imageRef[obj.id].getClientRect()
					const boundingClientRect = container.element.getBoundingClientRect()
					return obj.image &&
						obj.isDroppableItem &&
						clientRect.x + boundingClientRect.x < e.clientX &&
						clientRect.x + boundingClientRect.x + obj.width * obj.scaleX >
							e.clientX &&
						clientRect.y + boundingClientRect.y < e.clientY &&
						clientRect.y +
							boundingClientRect.y +
							obj.height * obj.scaleY >
							e.clientY
						? obj
						: false
				}
				return false
			})
			if (replacingImage) {
				this.imageRef[replacingImage.id].strokeWidth(0)
			}
			if (dropObj) {
				if (dropObj.shapeProp && dropObj.shapeProp.borderAllowed){
					this.imageRef[dropObj.id].stroke("rgba(154,136,251,1)")
					this.imageRef[dropObj.id].strokeWidth(4)
				}else{
					this.imageRef[dropObj.id].stroke("rgba(154,136,251,1)")
					this.imageRef[dropObj.id].strokeWidth(8)
				}
			}
		}
		if (replacingImage !== dropObj) {
			this.setState({ replacingImage: dropObj })
		}
	}

	makeAsTemplate(status) {
		const selectedObjects = this.getSelectedObjList()
		selectedObjects.forEach((obj) => {
			obj.isDroppableItem = status
			if (obj.shapeProp && status) {
				obj.clipProp = {
					width: obj.width,
					height: obj.height,
					type: ClipTypeENUM.FIT,
				}
				obj.image = getImageBySVG(obj)
				this.setState({
					clipType: ClipTypeENUM.FIT,
				})
			}
		})
		this.setPosition(selectedObjects)
	}

	clipType(clipType) {
		const { selected, objList } = this.state
		const newAttr = objList.find((obj) => obj.id === selected)
		newAttr.clipProp = {
			width: newAttr.width,
			height: newAttr.height,
			type: clipType,
		}
		this.setPosition([newAttr])
		this.setState({ clipType })
	}

	async selectBoard(boardId) {
		const { selectedBoard } = this.props
		const { boardId: selectedBoardId } = selectedBoard
		if (selectedBoardId !== boardId) {
			this.positionArray = []
			this.setState({ selectBoard: true, position: 1 }, () => {
				const {
					changeLookbook,
					lookbookBoards,
					savingComplete,
				} = this.props
				const board = lookbookBoards.find((obj) => boardId === obj.boardId)
				this.saveLookbook().then((data) => {
					this.setState({ selectBoard: false })
					if (data.boardId || boardId) {
						changeLookbook({}, board || null)
						this.deselect()
						savingComplete()
					}
				})
			})
		}
	}

	updateServer(lookbookBoards, needUpdate = true) {
		const { relay, id: lookbookId, updateIds } = this.props
		updateBoardNoMutation.commit(
			relay.environment,
			lookbookBoards.map(({ boardId, pageNo }) => ({
				id: boardId,
				value: `${pageNo}`,
			})),
			lookbookId,
			async ({ updateBoardNo }) => {
				if (needUpdate)
					updateIds(
						updateBoardNo.lookbookBoards.map(({ id, pageNo }) => ({
							boardId: id,
							pageNo,
						}))
					)
			},
			(e) => {
				toast.info(<>{gql.getError(e)}</>, {
					autoClose: 5000,
					closeButton: false,
				})
			}
		)
	}

	duplicateBoard(selBoardId, clearBoard = false) {
		const {
			selectedBoard: selBoard,
			changeLookbook,
			lookbookBoards,
			doChanges,
			updatePageNo,
			newEmptyBorad,
		} = this.props
		const selectedBoard = lookbookBoards.find(
			({ boardId }) => selBoardId === boardId
		)
		const isCurrentBoard = selBoardId === selBoard.boardId
		this.saveLookbook().then((data) => {
			const { objList, container } = this.state
			let board = {}
			if (clearBoard) {
				board = newEmptyBorad()
			} else {
				board = {
					...selectedBoard,
					screenWidth: isCurrentBoard
						? container?.width
						: selectedBoard.screenWidth,
					dataJson: (isCurrentBoard
						? objList
						: selectedBoard.dataJson
					).map((obj) => ({
						...obj,
						cropPath: clone(obj.cropPath),
						textProp: clone(obj.textProp),
						shapeProp: clone(obj.shapeProp),
						// pixel: clone(obj.pixel),
					})),
					mp4Url: null,
					videoUrl: null,
					showVideo: false,
				}
			}
			if (isCurrentBoard && data && !clearBoard) {
				board = { ...board, ...data }
			}
			board = {
				...board,
				boardId: `newBoard_${createGuid(6)}`,
				pageNo: selectedBoard.pageNo + 1,
			}
			changeLookbook(
				{ screenWidth: container?.width, dataJson: objList },
				board || null,
				null,
				true,
				() => {
					setTimeout(() => {
						const updatedBoards = [
							...lookbookBoards.slice(0, selectedBoard.pageNo + 1),
							board,
							...lookbookBoards.slice(selectedBoard.pageNo + 1),
						]
						updatePageNo(
							updatedBoards.map(({ boardId }, i) => ({ boardId, pageNo: i }))
						)
						this.updateServer(
							[
								...lookbookBoards.slice(0, selectedBoard.pageNo + 1),
								...lookbookBoards
									.slice(selectedBoard.pageNo + 1)
									.map((obj) => ({ ...obj, pageNo: obj.pageNo + 1 })),
							],
							false
						)
						doChanges(CHANGES_FROM.CANVAS)
					}, 100)
				}
			)
		})
	}

	showBoard(boardId, showBoard) {
		const { relay, changeLookbook, savingComplete } = this.props
		changeLookbook({ showBoard }, undefined, boardId)
		if (boardId)
			toggleBoardShowMutation.commit(
				relay.environment,
				boardId,
				() => {
					savingComplete()
				},
				(e) => {
					toast.info(<Fragment>{gql.getError(e)}</Fragment>, {
						autoClose: 5000,
						closeButton: false,
					})
				}
			)
	}

	removeBackground(selected) {
		const { objList } = this.state
		const { relay } = this.props
		const returnFunc = () => {
			this.setState({ processingImage: false })
			if (this.imageRef[selected]) {
				this.imageRef[selected].clearCache()
				this.imageRef[selected].filters([])
			}
			this.runDebounce(false)
		}
		const errorFunc = () => {
			toast.error(<Fragment>Error on Remove Background.</Fragment>, {
				autoClose: 2000,
				closeButton: false,
			})
			returnFunc()
		}
		this.runDebounce(true)
		const image = objList.find((x) => x.id === selected)
		if (!image || isBackgroundRemovedImage(image.imageUrl)) {
			returnFunc()
			return
		}
		this.setState({ processingImage: selected })
		this.imageRef[selected].cache()
		this.imageRef[selected].filters([Konva.Filters.Grayscale])
		const imageUrl = new URL(image.imageUrl)
		let category = ""
		if (image.shopcastProductID) {
			category = "product"
		}
		if (image.lookbookUploadID) {
			category = "upload"
		}
		removeImageBackgroundMutation.commit(
			relay.environment,
			`${imageUrl.origin}${imageUrl.pathname}`,
			category,
			image.shopcastProductID || image.lookbookUploadID,
			false,
			async ({ removeImageBackground }) => {
				if (removeImageBackground && removeImageBackground.output) {
					const newAttrs = { ...image }
					try {
						const { width, height, scaleX, scaleY, cropPath } = image
						const {
							width: initWidth,
							height: initHeight,
						} = image.cropPath[0]
						const transImage = addVersioning(
							removeImageBackground.output.value,
							`d=1000`
						)
						const img = await getImageByURL({ imageUrl: transImage })
						const imgScaleX = img.width / initWidth
						const imgScaleY = img.height / initHeight
						newAttrs.image = img
						newAttrs.imageUrl = transImage
						newAttrs.width = width * imgScaleX
						newAttrs.height = height * imgScaleY
						newAttrs.pixel = await getPixelData(newAttrs)
						newAttrs.offsetX = newAttrs.width / 2
						newAttrs.offsetY = newAttrs.height / 2
						newAttrs.scaleX = scaleX * (initWidth / img.width)
						newAttrs.scaleY = scaleY * (initHeight / img.height)
						const cropPathNew = [...cropPath]
						newAttrs.cropPath = cropPathNew.map((path) => ({
							x: path.x * imgScaleX,
							y: path.y * imgScaleY,
							width: path.width * imgScaleX,
							height: path.height * imgScaleY,
						}))
						newAttrs.img = cropImage(newAttrs)
					} catch (err) {
						return
					}
					this.onChange(newAttrs)
					this.setPosition()
					returnFunc()
				} else {
					errorFunc()
				}
			},
			() => errorFunc()
		)
	}

	renderPropmt() {
		const { haveChanges } = this.props
		return (
			<Prompt
				when={!!haveChanges}
				message="Changes that you made may not be saved."
			/>
		)
	}

	renderImage(img) {
		const { processingImage } = this.state
		const { selectedBoard } = this.props
		const { shapes } = selectedBoard
		const locked = this.lockedElementInSelected().length > 0
		if (img.shapeProp && img.shapeProp.borderAllowed) {
			this.shapeRef[img.id] = []
			const [initCrop] = img.cropPath
			const scaleX =
				(img.scaleX * initCrop.width) / img.shapeProp.viewBoxWidth
			const scaleY =
				(img.scaleY * initCrop.height) / img.shapeProp.viewBoxHeight
			return (
				<Fragment>
					{img.shapeProp.path.map((path) => (
						<Path
							key={path}
							ref={(ref) => {
								this.shapeRef[img.id][path] = ref
							}}
							data={path}
							x={img.x}
							y={img.y}
							width={img.shapeProp.viewBoxWidth}
							height={img.shapeProp.viewBoxHeight}
							offsetX={img.shapeProp.viewBoxWidth / 2}
							offsetY={img.shapeProp.viewBoxHeight / 2}
							scaleX={scaleX * img.imgScaleX}
							scaleY={scaleY}
							rotation={img.rotation}
							shadowOffset={{
								x: (img.shadowOffset?.x || 0) / scaleX,
								y: (img.shadowOffset?.y || 0) / scaleY,
							}}
							shadowForStrokeEnabled={false}
							shadowBlur={img.shadowBlur}
							shadowColor={img.shadowColor}
							shadowOpacity={img.shadowOpacity}
							fill={getColor(
								img.shapeProp.fill,
								img.shapeProp.fillTransparentLevel
							)}
							stroke={img.shapeProp.borderColor || "#000000"}
							strokeWidth={img.shapeProp.borderWidth || 0}
							centeredScaling
							strokeScaleEnabled={false}
							visible={img.visible}
							opacity={img.opacity}
						/>
					))}
					<Rect
						ref={(ref) => {
							this.imageRef[img.id] = ref
						}}
						onClick={this.onSelect}
						onTap={this.onSelect}
						{...img}
						draggable={!(locked || processingImage === img.id)}
						centeredScaling
						onDragStart={(e) => {
							this.runDebounce(true)
							this.onSelect(e)
						}}
						onDragEnd={({ target }) => {
							this.runDebounce(false)
							this.onChange({
								...img,
								x: target.x(),
								y: target.y(),
							})
						}}
						onTransformEnd={() => {
							this.runDebounce(false)
							const node = this.imageRef[img.id]
							const deg = node.rotation()
							let tarProps = {
								...img,
								scaleX: node.scaleX(),
								scaleY: node.scaleY(),
								x: node.x(),
								y: node.y(),
								height: node.height(),
								width: node.width(),
								rotation: deg,
								offsetX: node.width() / 2,
								offsetY: node.height() / 2,
							}
							tarProps = img.shapeProp
								? {
										...tarProps,
										image: getImageBySVG(tarProps, shapes),
								  }
								: tarProps
							this.onChange(tarProps)
						}}
						onTransformStart={() => this.runDebounce(true)}
						onDragMove={({ target }) => {
							img.shapeProp.path.forEach((path) => {
								this.shapeRef[img.id][path].x(target.x())
								this.shapeRef[img.id][path].y(target.y())
							})
						}}
						onTransform={({ target }) => {
							img.shapeProp.path.forEach((path) => {
								this.shapeRef[img.id][path].x(target.x())
								this.shapeRef[img.id][path].y(target.y())
								this.shapeRef[img.id][path].scaleX(
									(target.scaleX() * img.width * img.imgScaleX) /
										img.shapeProp.viewBoxWidth
								)
								this.shapeRef[img.id][path].scaleY(
									(target.scaleY() * img.height) /
										img.shapeProp.viewBoxHeight
								)
								this.shapeRef[img.id][path].rotation(target.rotation())
								this.shapeRef[img.id][path].shadowOffset({
									x:
										((img.shadowOffset?.x || 0) *
											img.shapeProp.viewBoxWidth) /
										(target.scaleX() * img.width),
									y:
										((img.shadowOffset?.y || 0) *
											img.shapeProp.viewBoxHeight) /
										(target.scaleY() * img.height),
								})
							})
						}}
						onContextMenu={(event) => {
							this.onSelect(event)
							this.onContextMenu(event)
						}}
						strokeScaleEnabled={false}
						opacity={100}
					/>
				</Fragment>
			)
		}
		return (
			<Image
				ref={(ref) => {
					this.imageRef[img.id] = ref
				}}
				onClick={this.onSelect}
				onTap={this.onSelect}
				{...img}
				shadowOffset={{
					x: (img.shadowOffset?.x || 0) / img.scaleX,
					y: (img.shadowOffset?.y || 0) / img.scaleY,
				}}
				draggable={!(locked || processingImage === img.id)}
				centeredScaling
				onDragStart={(e) => {
					this.runDebounce(true)
					this.onSelect(e)
				}}
				onDragEnd={({ target }) => {
					this.runDebounce(false)
					this.onChange({
						...img,
						x: target.x(),
						y: target.y(),
					})
				}}
				onTransformEnd={() => {
					this.runDebounce(false)
					const node = this.imageRef[img.id]
					const scaleX = node.scaleX()
					const scaleY = node.scaleY()
					const deg = node.rotation()
					let tarProps = {
						...img,
						scaleX,
						scaleY,
						x: node.x(),
						y: node.y(),
						height: node.height(),
						width: node.width(),
						rotation: deg,
						offsetX: node.width() / 2,
						offsetY: node.height() / 2,
					}
					tarProps = img.shapeProp
						? {
								...tarProps,
								image: getImageBySVG(tarProps, shapes),
						  }
						: tarProps
					this.onChange(tarProps)
				}}
				onTransformStart={() => this.runDebounce(true)}
				// onDragMove={this.onTransform}
				// onTransform={this.onTransform}
				onContextMenu={(event) => {
					this.onSelect(event)
					this.onContextMenu(event)
				}}
				strokeScaleEnabled={false}
			/>
		)
	}

	renderCanvas() {
		const {
			objList,
			container,
			selected,
			selectRect,
			selectX,
			selectY,
			selectOffsetY,
			selectOffsetX,
			selectWidth,
			selectHeight,
			selectFill,
			selectDeg,
			shiftPressed,
			selectBoard,
			processingImage,
		} = this.state
		const { selectedBoard } = this.props
		const { background } = selectedBoard
		const locked = this.lockedElementInSelected().length > 0
		const fixedSize =
			this.selectedObjArray.filter(
				(x) =>
					objList
						.map((obj) => (obj.lock ? obj.id : false))
						.filter((y) => y)
						.includes(x.id) && !x.textProp
			).length > 0
		const bgWidth =
			background?.fillPatternImage?.naturalWidth ||
			background?.fillPatternImage?.width
		const bgHeight =
			background?.fillPatternImage?.naturalHeight ||
			background?.fillPatternImage?.height
		const bgScale = Math.max(
			(container?.width || 0) / bgWidth,
			(container?.height || 0) / bgHeight
		)

		return (
			<DropElement drop={this.drop}>
				<Stage
					ref={(ref) => {
						this.stage = ref
					}}
					listening={!selectBoard}
					width={this.canvasBoard?.clientWidth}
					height={this.canvasBoard?.clientHeight}
					className={styles.stage}
					onMouseDown={this.checkDeselect}
					onTouchStart={this.checkDeselect}
					onMouseMove={this.drawSelectRect}
					onTouchMove={this.drawSelectRect}
					onMouseUp={this.endDraw}
					onTouchEnd={this.endDraw}
					onMouseLeave={this.endDraw}
					onTouchLeave={this.endDraw}
				>
					<Layer>
						<Rect
							ref={(ref) => {
								this.greyBackground = ref
							}}
							x={0}
							y={0}
							width={this.canvasBoard?.clientWidth}
							height={this.canvasBoard?.clientHeight}
							draggable={false}
							fill="#e4e6e7"
						/>
						<Rect
							x={container?.x}
							y={container?.y}
							width={container.width}
							height={container.height}
							draggable={false}
							fill={background?.fill}
						/>
						{background?.fillPatternImage && (
							<Rect
								x={container?.x}
								y={container?.y}
								width={container?.width}
								height={container.height}
								draggable={false}
								fillPatternImage={background?.fillPatternImage}
								fillPatternScale={{
									x: (background?.fillPatternScale || 0) * bgScale,
									y: (background?.fillPatternScale || 0) * bgScale,
								}}
								opacity={background?.opacity}
							/>
						)}
						<Rect
							ref={(ref) => {
								this.background = ref
							}}
							x={container?.x}
							y={container?.y}
							width={container?.width}
							height={container.height}
							draggable={false}
							fill={this.defaultBackground}
							opacity={0}
						/>
					</Layer>
					<Layer
						ref={(ref) => {
							this.transLayer = ref
						}}
						onDragStart={this.layerDragStart}
						onDragMove={this.layerDragMove}
						onDragEnd={this.layerDragEnd}
					>
						<Group
							x={container?.x}
							y={container?.y}
							ref={(ref) => {
								this.parentLayer = ref
							}}
							clip={{
								x: 0,
								y: 0,
								width: container.width,
								height: container.height,
							}}
						>
							{objList.map((img) => (
								<Fragment key={img.id}>
									{img.image && (
										<Group
											ref={(ref) => {
												this.imageGroupRef[img.id] = ref
											}}
											// rotation={img.rotation}
											// clip={{
											// 	x: img.x - img.offsetX * img.scaleX,
											// 	y: img.y - img.offsetY * img.scaleY,
											// 	width: img.width * img.scaleX,
											// 	height: img.height * img.scaleY,
											// }}
										>
											{img.image && this.renderImage(img)}
										</Group>
									)}
									{img.textProp && (
										<Text
											ref={(ref) => {
												this.imageRef[img.id] = ref
											}}
											onClick={this.onSelect}
											onTap={this.onSelect}
											onDblClick={this.onEdit}
											{...img}
											{...img.textProp}
											fontFamily={img.textProp.fontFamily}
											draggable={
												!(locked || processingImage === img.id)
											}
											onDragStart={(e) => {
												this.runDebounce(true)
												this.onSelect(e)
											}}
											onDragEnd={({ target }) => {
												this.runDebounce(false)
												this.onChange({
													...img,
													x: target.x(),
													y: target.y(),
												})
											}}
											onTransformStart={() => {
												this.runDebounce(true)
												this.setState({
													// textTrasform: true,
													fontSize: img.textProp.fontSize,
												})
											}}
											onTransformEnd={() => this.runDebounce(false)}
											onTransform={() => {
												const node = this.imageRef[img.id]
												const width = node.scaleX() * node.width()
												const deg = node.rotation()
												let height = node.height()
												const fontsize =
													img.textProp.fontSize * node.scaleY()
												this.setState({
													fontSize: fontsize,
												})
												let textData = {
													...img,
													x: node.x(),
													y: node.y(),
													width,
													offsetX: width / 2,
													offsetY: height / 2,
													rotation: deg,
													textProp: {
														...img.textProp,
														fontSize: fontsize,
													},
												}
												if (this.selectedObjArray.length > 1) {
													height = node.scaleY() * node.height()
													textData = {
														...textData,
														offsetY: height / 2,
													}
												}
												this.onChange(textData)
												node.scaleX(1)
												node.scaleY(1)
											}}
											onContextMenu={(event) => {
												this.onSelect(event)
												this.onContextMenu(event)
											}}
										/>
									)}
								</Fragment>
							))}
						</Group>
						<Rect
							ref={(ref) => {
								this.selectRect = ref
							}}
							x={selectX + (container?.x || 0)}
							y={selectY + (container?.y || 0)}
							scaleX={1}
							scaleY={1}
							offsetX={selectOffsetX}
							offsetY={selectOffsetY}
							width={selectWidth}
							height={selectHeight}
							fill={selectFill}
							rotation={selectDeg}
							draggable={!locked}
							centeredScaling
							onDragEnd={() => {}}
						/>
						{(selected || selectRect) && (
							<Transformer
								ref={(ref) => {
									this.tarRef = ref
								}}
								centeredScaling={false}
								borderStrokeWidth={2}
								anchorStrokeWidth={2}
								rotationSnaps={shiftPressed ? this.snapAngles : []}
								boundBoxFunc={(oldBox, newBox) => {
									if (newBox.width < 20 || newBox.height < 20) {
										return oldBox
									}
									return newBox
								}}
								dragBoundFunc={(pos) => pos}
								resizeEnabled={
									!(fixedSize || processingImage === selected)
								}
								rotateEnabled={
									!(locked || processingImage === selected)
								}
								onDragEnd={() => this.setPosition()}
								onTransformEnd={() => this.setPosition()}
							/>
						)}
						{processingImage && this.renderImageProcessing()}
					</Layer>
				</Stage>
			</DropElement>
		)
	}

	renderImageProcessing() {
		const { processingImage, objList, container } = this.state
		const image = objList.find((img) => img.id === processingImage)
		if (processingImage && image) {
			return <GIF x={image.x + container.x} y={image.y + container.y} />
		}
		return null
	}

	renderFooter() {
		const { loading, objList, imageDrawing, sideTray } = this.state
		const {
			lookbookBoards,
			selectedBoard,
			saving,
			doChanges,
			disabled,
			updatePageNo,
			updateIds,
			relay,
			id,
			haveChanges,
		} = this.props
		const loadingBoard = loading || imageDrawing || saving

		const trayType = {
			PRODUCT: "PRODUCT",
			BOARD: "BOARD",
		}

		const openFooter = (footer) => {
			localStorage.setItem("board-footer", footer)
			if (footer === trayType.BOARD) {
				this.saveLookbook()
			}
			this.setState({ sideTray: footer || false }, () =>
				this.onWindowResize()
			)
		}

		const removeFromSideTray = (prodId) => {
			const { objList: list } = this.state
			doChanges(CHANGES_FROM.CANVAS)
			const index = list.findIndex((x) => x.id === prodId)
			list.splice(index, 1)
			this.setState(
				{
					objList: list,
				},
				() => this.setPosition()
			)
		}

		const dropSideTray = (e) => {
			const { objList: list } = this.state
			doChanges(CHANGES_FROM.CANVAS)
			const { id: cardId } = e
			const card = document.getElementById(cardId)?.cloneNode()
			const tag = card.tagName.toLowerCase()
			const category = card.getAttribute("data-category")
			const shopcastProductID = card.getAttribute("data-itemid")
			const isProduct =
				category === "shopcast" ||
				(category === "collections" && shopcastProductID)
			const index = list.findIndex((x) => x.id === card.id)
			if (tag === "img" && !!isProduct && index < 0) {
				const img = {
					id: card.id,
					category,
					imageUrl: card.src,
					shopcastProductID:
						category === "shopcast" || category === "collections"
							? shopcastProductID
							: null,
					onTray: true,
				}
				this.setState(
					{
						objList: [...list, img],
					},
					() => this.setPosition()
				)
			}
		}

		return (
			<div className={styles.footer}>
				{sideTray === trayType.PRODUCT && (
					<DropElement className={styles.tray} drop={dropSideTray}>
						<ProductTray
							trayObjects={objList.filter((x) => x.onTray)}
							removeFromSideTray={removeFromSideTray}
						/>
					</DropElement>
				)}
				{sideTray === trayType.BOARD && (
					<div className={styles.tray}>
						<BoardsTray
							lookbookId={id}
							lookbookBoards={lookbookBoards}
							selectedBoard={selectedBoard}
							updatePageNo={updatePageNo}
							updateIds={updateIds}
							relay={relay}
							saveLookbook={this.saveLookbook}
							loading={loadingBoard || disabled}
							saving={saving}
							updateServer={this.updateServer}
							haveChanges={haveChanges}
						/>
					</div>
				)}
				<div className={styles.controlsBottom}>
					<div className={styles.controlsLeft} />
					<div className={styles.controlsRight}>
						{lookbookBoards.length > 1 && (
							<Pagination
								currentPage={selectedBoard.boardId}
								pages={lookbookBoards
									.sort((a, b) => a.pageNo - b.pageNo)
									.map((board) => ({ key: board.boardId }))}
								onChange={this.selectBoard}
								loading={loadingBoard || disabled}
							/>
						)}
						<button
							type="button"
							onClick={() =>
								openFooter(
									sideTray === trayType.BOARD ? false : trayType.BOARD
								)
							}
							data-tooltip-id="tooltip"
							data-tooltip-content="Pages"
							className={
								sideTray === trayType.BOARD
									? styles.toolActive
									: styles.tool
							}
						>
							<CardStackIcon className={styles.icon} />
						</button>
						<div className={styles.dividerVert} />
						<button
							type="button"
							onClick={() =>
								openFooter(
									sideTray === trayType.PRODUCT
										? false
										: trayType.PRODUCT
								)
							}
							data-tooltip-id="tooltip"
							data-tooltip-content="Additional Products"
							className={
								sideTray === trayType.PRODUCT
									? styles.toolActive
									: styles.tool
							}
						>
							<DashboardIcon className={styles.icon} />
						</button>
					</div>
				</div>
			</div>
		)
	}

	render() {
		const {
			objList,
			container,
			selected,
			contextMenu,
			transparentLevel,
			lineHeight,
			openCropModal,
			openClearConfirmModal,
			openDeleteBoardConfirmModal,
			textEdit,
			textBold,
			textItalic,
			color,
			fontFamily,
			fontSize,
			textAlign,
			fullScreen,
			imageTransparentLevel,
			VideoThumbnail,
			ChannelBanner,
			shopcastList,
			videoThumbSaving,
			shopcastModalOpen,
			loading,
			shadowColor,
			shadowBlur,
			shadowOffset,
			shadowOpacity,
			dragStart,
			altPressed,
			clipType,
			templateMode,
			processingImage,
			fillTransparentLevel,
			borderColor,
			borderWidth,
		} = this.state
		const {
			deleteBoard,
			lookbook,
			shapeList,
			relay,
			imageDrawing,
			selectedBoard,
		} = this.props

		return (
			<Fragment>
				<MoveableProvider
					value={{
						runDebounce: this.runDebounce,
						selectBoard: this.selectBoard,
						duplicateBoard: (selBoardId, clearBoard) =>
							setTimeout(
								() => this.duplicateBoard(selBoardId, clearBoard),
								0
							),
						deleteBoard: (boardId) =>
							this.setState({ openDeleteBoardConfirmModal: boardId }),
						showBoard: this.showBoard,
					}}
				>
					<div className={`${fullScreen ? styles.rootFull : styles.root}`}>
						<div className={styles.controls}>
							<div className={styles.controlsLeft}>
								<CanvasSizePicker
									canvasHeight={selectedBoard.height}
									canvasWidth={selectedBoard.width}
									onChange={this.changeCanvasSize}
									runDebounce={this.runDebounce}
									dataTip="Resize Canvas"
								/>
								<div
									className={styles.controlsImage}
									style={
										this.selectedObjArray.length > 0 &&
										objList.find((x) => x.id === selected)?.image
											? { display: "grid" }
											: { display: "none" }
									}
								>
									{this.selectedObjArray.length === 1 &&
										objList.find((x) => x.id === selected)?.image && (
											<Fragment>
												<ImageTools
													isSVG={
														objList.find((x) => x.id === selected)
															?.shapeProp?.borderAllowed
													}
													flip={this.flip}
													openCropModal={() => {
														this.setState({ openCropModal: true })
														this.runDebounce(true)
													}}
												/>
												{this.selectedObjArray.length === 1 &&
													objList.find((x) => x.id === selected)
														?.shapeProp && (
														<Fragment>
															<div
																className={styles.dividerVert}
															/>
															<ShapeFill
																fill={color}
																fillTransparentLevel={
																	fillTransparentLevel
																}
																dataTip="Fill"
																runDebounce={this.runDebounce}
																changeShapeProp={async ({
																	fill,
																	fillTransparentLevel: FTL,
																}) => {
																	const stateJson = {}
																	const img = objList.find(
																		(x) => x.id === selected
																	)
																	if (fill !== undefined) {
																		stateJson.color = fill
																		img.shapeProp.fill = fill
																	}
																	if (FTL !== undefined) {
																		stateJson.fillTransparentLevel = FTL
																		img.shapeProp.fillTransparentLevel = FTL
																	}
																	if (
																		img.shapeProp
																			.borderAllowed
																	) {
																		img.shapeProp.path.forEach(
																			(path) => {
																				this.shapeRef[
																					img.id
																				][path].fill(
																					getColor(
																						img.shapeProp
																							.fill,
																						img.shapeProp
																							.fillTransparentLevel
																					)
																				)
																			}
																		)
																		this.updateShape(
																			img,
																			shapeList
																		)
																	} else {
																		img.image = await getImageBySVG(
																			img,
																			shapeList
																		)
																		this.onChange(img)
																	}
																	this.setState(stateJson)
																}}
															/>
															{objList.find(
																(x) => x.id === selected
															)?.shapeProp?.borderAllowed && (
																<ShapeStroke
																	borderColor={borderColor}
																	borderWidth={borderWidth}
																	dataTip="Border Style"
																	runDebounce={
																		this.runDebounce
																	}
																	changeShapeProp={async ({
																		borderColor: BC,
																		borderWidth: BW,
																	}) => {
																		const stateJson = {}
																		const img = objList.find(
																			(x) =>
																				x.id === selected
																		)
																		if (BC !== undefined) {
																			stateJson.borderColor = BC
																			img.shapeProp.borderColor = BC
																		}
																		if (BW !== undefined) {
																			stateJson.borderWidth = BW
																			img.shapeProp.borderWidth = BW
																		}
																		img.shapeProp.path.forEach(
																			(path) => {
																				this.shapeRef[
																					img.id
																				][path].stroke(
																					img.shapeProp
																						.borderColor
																				)
																				this.shapeRef[
																					img.id
																				][path].strokeWidth(
																					img.shapeProp
																						.borderWidth
																				)
																			}
																		)
																		this.updateShape(
																			img,
																			shapeList
																		)
																		this.setState(stateJson)
																	}}
																/>
															)}
														</Fragment>
													)}

												{this.selectedObjArray.length === 1 &&
													transparentLevel >= 0 &&
													objList.find((x) => x.id === selected)
														?.imageUrl && (
														<Fragment>
															<div
																className={styles.dividerVert}
															/>
															<BackgroundRemover
																dataTip="Remove Background"
																transparentLevel={
																	transparentLevel
																}
																runDebounce={this.runDebounce}
																processingImage={
																	processingImage
																}
																handleTransparent={
																	this.handleTransparent
																}
																openBgRemove={() =>
																	this.removeBackground(
																		selected
																	)
																}
																canBgRemoveOpen={(() => {
																	const selObj = objList.find(
																		(x) => x.id === selected
																	)
																	return (
																		(selObj?.shopcastProductID ||
																			selObj?.lookbookUploadID) &&
																		!isBackgroundRemovedImage(
																			selObj?.imageUrl
																		)
																	)
																})()}
															/>
														</Fragment>
													)}
											</Fragment>
										)}
									{templateMode &&
										this.selectedObjArray.length === 1 &&
										objList.find((x) => x.id === selected)
											?.isDroppableItem && (
											<TemplateTools
												clipType={clipType}
												changeClipType={this.clipType}
											/>
										)}
								</div>
								<ToolBox
									isShow={
										textEdit ||
										(this.selectedObjArray.length === 1 &&
											this.selectedObjArray[0].textProp)
									}
									editText={(result) => this.editText(result)}
									fontFamily={fontFamily}
									fontSize={fontSize}
									lineHeight={lineHeight}
									color={color}
									textAlign={textAlign}
									isBold={textBold}
									isItalic={textItalic}
									runDebounce={this.runDebounce}
								/>
							</div>
							<div className={styles.controlsRight}>
								{this.selectedObjArray.length > 0 && (
									<RightSideTools
										shadowColor={shadowColor}
										shadowBlur={shadowBlur}
										shadowOffset={shadowOffset}
										shadowOpacity={shadowOpacity}
										imageTransparentLevel={imageTransparentLevel}
										handleShadow={this.handleShadow}
										handleImageTransparent={
											this.handleImageTransparent
										}
										onDuplicate={() => this.duplicate()}
										onRemoveItem={this.removeItem}
										runDebounce={this.runDebounce}
									/>
								)}
								{objList.filter((x) => !x.onTray).length > 0 &&
									textEdit == null && (
										<LayerDropdown
											objList={objList}
											selected={selected}
											imageRef={this.imageRef}
											onChange={(result) => {
												this.onChange(result)
												this.setPosition()
											}}
											onCenter={(result) => {
												this.moveItem()
												this.onChange(result)
											}}
											onSelect={(result) => this.onSelect(result)}
											onDelete={this.removeItem}
											container={container}
											runDebounce={this.runDebounce}
										/>
									)}
								<RightDefaultTools
									loading={loading}
									VideoThumbnail={VideoThumbnail}
									ChannelBanner={ChannelBanner}
									fullScreen={fullScreen}
									setChannelBanner={this.setChannelBanner}
									shopcastModalOpen={() =>
										this.setState({
											shopcastModalOpen: true,
										})
									}
									downloadImage={this.downloadImage}
									changeFullScreen={() =>
										this.setState({ fullScreen: !fullScreen }, () =>
											this.onWindowResize()
										)
									}
									runDebounce={this.runDebounce}
								/>
							</div>
						</div>
						{/* END CONTROLS */}

						{!lookbook.isTemplate && this.renderFooter()}

						<div
							className={
								templateMode ? styles.contentEdit : styles.content
							}
							ref={(ref) => {
								this.canvasBoard = ref
							}}
						>
							{imageDrawing && <LoadingDots color="var(--gray400)" />}

							<BottomLeftPanel
								undo={this.undo}
								redo={this.redo}
								openClearConfirmation={() =>
									this.setState({ openClearConfirmModal: true })
								}
							/>
							<div
								className={
									dragStart || altPressed
										? styles.canvasDuplicate
										: styles.canvas
								}
								onDragOver={this.dragOver}
								ref={(ref) => {
									this.canvaStage = ref
								}}
							>
								{loading || !lookbook || !selectedBoard ? (
									<LoadingDots color="var(--gray400)" />
								) : (
									this.renderCanvas()
								)}
							</div>
						</div>
						{/* END CONTENT */}

						<div
							ref={(ref) => {
								this.contextMenu = ref
							}}
							className={styles.contextMenu}
						>
							<ImageContollers
								objList={objList}
								selected={contextMenu}
								moveItem={this.moveItem}
								removeItem={this.removeItem}
								flip={this.flip}
								duplicate={this.duplicate}
								canGroup={
									this.selectedObjArray.length > 1 &&
									!this.checkAllInGroup()
								}
								canUnGroup={
									this.selectedObjArray.length > 1 &&
									this.checkAllInGroup()
								}
								group={this.group}
								unGroup={this.unGroup}
								selectedObjArray={this.selectedObjArray}
								lockItem={this.lockItem}
								lockedItems={this.lockedElementInSelected()}
								makeAsTemplate={this.makeAsTemplate}
								environment={relay.environment}
								templateMode={templateMode}
								openBgRemove={this.removeBackground}
								onClose={() => this.runDebounce(false)}
							/>
						</div>
					</div>
				</MoveableProvider>
				<CropImage
					openModal={openCropModal}
					closeModal={this.closeModal}
					image={objList.find((x) => x.id === selected)}
					onChange={(newAttr) => {
						this.onChange(newAttr)
						this.drawMultipleSelectBox()
						this.setPosition()
					}}
				/>
				<VideoThumb
					shopcastList={shopcastList}
					openModal={shopcastModalOpen}
					closeModal={() => this.setState({ shopcastModalOpen: false })}
					approveEvent={this.updateVideoThumb}
					declineEvent={() => this.setState({ shopcastModalOpen: false })}
					title="Video Cover"
					approveText="Save"
					loading={videoThumbSaving}
				/>
				<ConformationPrompt
					openModal={openClearConfirmModal}
					closeModal={this.closeModal}
					approveEvent={this.clearBoard}
					declineEvent={this.closeModal}
					approveText="Yes"
					declineText="Cancel"
					title="Clear board?"
				>
					Do you want to clear the Board? Changes that you made may not be
					saved.
				</ConformationPrompt>
				<ConformationPrompt
					openModal={!!openDeleteBoardConfirmModal}
					closeModal={this.closeModal}
					approveEvent={() => {
						deleteBoard(openDeleteBoardConfirmModal)
						this.closeModal()
					}}
					declineEvent={this.closeModal}
					approveText="Delete"
					approveButtonStyle={button.primaryRed}
					declineText="Cancel"
					title="Delete board?"
				>
					Deleting this Board is a permanent action, and it cannot be
					undone.
				</ConformationPrompt>
				{this.renderPropmt()}
			</Fragment>
		)
	}
}

export default withRouter(CanvasBoard)
