import { commitMutation, graphql } from "react-relay"
import { complete } from "../helper.js"

const mutation = graphql`
	mutation sendClientInviteMutation(
		$input: SendClientInvitationEmailInput!
	) {
		sendClientInvitationEmail(input: $input) {
			store {
				id
			}
		}
	}
`

const commit = (env, clientId, onCompleted, onError) =>
	commitMutation(env, {
		mutation,
		variables: { input: { id: clientId } },
		onCompleted: complete(onCompleted, onError),
		onError: (e) => complete(null, [e]),
	})

export default { commit }
