/**
 * @generated SignedSource<<fbd531a8532dc4d25b5cb8f769467af1>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Query } from 'relay-runtime';
import type { personal_store$fragmentType } from "./../../../components/profile/account/__generated__/personal_store.graphql";
export type settingsPersonalQuery$variables = {||};
export type settingsPersonalQuery$data = {|
  +store: ?{|
    +$fragmentSpreads: personal_store$fragmentType,
  |},
|};
export type settingsPersonalQuery = {|
  response: settingsPersonalQuery$data,
  variables: settingsPersonalQuery$variables,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "settingsPersonalQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Store",
        "kind": "LinkedField",
        "name": "store",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "personal_store"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "ShopshareQueryRoot",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "settingsPersonalQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Store",
        "kind": "LinkedField",
        "name": "store",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "currentUser",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "displayName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "fullname",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "username",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "profilePhoto",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "canEdit",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "bio",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "email",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "paypalEmail",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "type",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isRetailer",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isVerified",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "UserSocialLinks",
                "kind": "LinkedField",
                "name": "socialLinks",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "website",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "instagramUsername",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "facebookUrl",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "twitterUsername",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "youtubeUrl",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "pinterestUrl",
                    "storageKey": null
                  },
                  (v0/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "verificationInProgress",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "customDomain",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "optOutAffiliate",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PricingPlan",
            "kind": "LinkedField",
            "name": "pricingPlans",
            "plural": true,
            "selections": [
              (v0/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "amount",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "currency",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "nickname",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "monthly",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "descriptionList",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "NameValue",
                "kind": "LinkedField",
                "name": "metadata",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "name",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "value",
                    "storageKey": null
                  },
                  (v0/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "bc767981a34ca0e96ae5fa3f9c15a1b7",
    "id": null,
    "metadata": {},
    "name": "settingsPersonalQuery",
    "operationKind": "query",
    "text": "query settingsPersonalQuery {\n  store {\n    ...personal_store\n    id\n  }\n}\n\nfragment personal_store on Store {\n  id\n  currentUser {\n    ...updatePersonalInfo_currentUser\n    ...updateUsername_currentUser\n    ...updateCustomDomain_currentUser\n    type\n    optOutAffiliate\n    id\n  }\n  pricingPlans {\n    id\n    amount\n    currency\n    nickname\n    monthly\n    descriptionList\n    metadata {\n      name\n      value\n      id\n    }\n  }\n}\n\nfragment updateCustomDomain_currentUser on User {\n  customDomain\n}\n\nfragment updatePersonalInfo_currentUser on User {\n  id\n  displayName\n  fullname\n  username\n  profilePhoto\n  canEdit\n  bio\n  email\n  paypalEmail\n  type\n  isRetailer\n  isVerified\n  socialLinks {\n    website\n    instagramUsername\n    facebookUrl\n    twitterUsername\n    youtubeUrl\n    pinterestUrl\n    id\n  }\n  verificationInProgress\n}\n\nfragment updateUsername_currentUser on User {\n  displayName\n  fullname\n  username\n  bio\n  email\n  isRetailer\n  isVerified\n  socialLinks {\n    website\n    instagramUsername\n    facebookUrl\n    twitterUsername\n    youtubeUrl\n    pinterestUrl\n    id\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "eaf3b67a465882f4fdf58d66eed257d4";

module.exports = ((node/*: any*/)/*: Query<
  settingsPersonalQuery$variables,
  settingsPersonalQuery$data,
>*/);
