import { commitMutation, graphql, ConnectionHandler } from "react-relay"
import { complete } from "../helper"

const mutation = graphql`
	mutation changeOwnershipMutation($input: ChangeTeamOwnershipInput!) {
		changeTeamOwnership(input: $input) {
			store {
				id
			}
            teamId
		}
	}
`

const commit = (env, memberId, onError, onCompleted) =>
	commitMutation(env, {
		mutation,
		variables: {
			input: {
				id: memberId,
			},
		},
		onCompleted: complete(onCompleted, onError),
		onError: (e) => complete(null, [e]),
		updater: (storeProxy, dd) => {
			const team = storeProxy.get(dd.changeTeamOwnership.teamId)
			team.setValue("ADMIN", "myRole")

            const members = ConnectionHandler.getConnection(team, "teams_members")
			const edges = members.getLinkedRecords("edges")
			const prevOwner = edges.find(
				(m) => m.getLinkedRecord("node").getValue("role") === "OWNER"
			)
			if (prevOwner) prevOwner.getLinkedRecord("node").setValue("ADMIN", "role")

			const member = storeProxy.get(memberId)
            member.setValue("OWNER", "role")
		},
	})

export default { commit }
