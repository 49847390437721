/**
 * @generated SignedSource<<57a1b55e75957ebe43159d54c7c607ef>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Query } from 'relay-runtime';
import type { signin_store$fragmentType } from "./signin_store.graphql";
export type signinQuery$variables = {||};
export type signinQuery$data = {|
  +store: ?{|
    +$fragmentSpreads: signin_store$fragmentType,
  |},
|};
export type signinQuery = {|
  response: signinQuery$data,
  variables: signinQuery$variables,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "signinQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Store",
        "kind": "LinkedField",
        "name": "store",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "signin_store"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "ShopshareQueryRoot",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "signinQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Store",
        "kind": "LinkedField",
        "name": "store",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "currentUser",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "token",
                "storageKey": null
              },
              (v0/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "d44ec78465f0e92c196bd885e676db68",
    "id": null,
    "metadata": {},
    "name": "signinQuery",
    "operationKind": "query",
    "text": "query signinQuery {\n  store {\n    ...signin_store\n    id\n  }\n}\n\nfragment signin_store on Store {\n  id\n  currentUser {\n    token\n    id\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "4ee31cf87ce2b28067cf309352a5ebfb";

module.exports = ((node/*: any*/)/*: Query<
  signinQuery$variables,
  signinQuery$data,
>*/);
