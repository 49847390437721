import React, { Component } from "react"
import { createPaginationContainer, graphql } from "react-relay"
import InfiniteScroll from "react-infinite-scroller"
import Lightbox from "yet-another-react-lightbox"
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen"
import Download from "yet-another-react-lightbox/plugins/download"
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails"
import Video from "yet-another-react-lightbox/plugins/video"
import Zoom from "yet-another-react-lightbox/plugins/zoom"
import grids from "shared/styles/components/grids.css"
import empty from "shared/styles/components/empty.css"
import LoadingDots from "components/loading-dots/loading-dots"
import FileCard from "components/cards/file-card/file-card"
import { imageOrVideo } from "helpers/ui-helper"

class TeamFiles extends Component {
	constructor(props) {
		super(props)
		this.handleLoadMore = this.handleLoadMore.bind(this)
		this.state = {
			lightboxIndex: null,
			lightBoxItems: this.getLightBoxItems(),
		}
	}

	componentDidUpdate(prevProps) {
		const { files: prevItems } = prevProps.store.team
		const { store, relay } = this.props
		const { lightboxIndex } = this.state
		const { files: items } = store.team
		if (prevItems.edges.length !== items.edges.length) {
			const lbItems = this.getLightBoxItems()
			if (lightboxIndex === lbItems.length - 1 && relay.hasMore())
				this.handleInfiniteLoad()
			else this.setState({ lightBoxItems: lbItems })
		}
	}

	handleLoadMore() {
		const { relay } = this.props
		relay.loadMore(12, () => {})
	}

	getLightBoxItems() {
		const { store } = this.props
		const { files } = store.team
		const lightBoxItems = files.edges
			.filter(({ node }) => !!imageOrVideo(node.url))
			.map(({ node }) => {
				const type = imageOrVideo(node.url)
				if (type === "image")
					return {
						src: node.url,
						type: "image",
						download: true,
					}
				return {
					sources: [
						{
							src: node.url,
						},
					],
					src: node.url,
					type: "video",
					download: node.url,
				}
			})
		return lightBoxItems
	}

	renderFiles(files) {
		if (!files || files.edges.length === 0) {
			return (
				<div className={grids.span}>
					<div className={empty.simple}>No Files found.</div>
				</div>
			)
		}
		return files.edges.map((edge) => (
			<div className={grids.col3} key={edge.node.__id}>
				<FileCard
					file={edge.node}
					toggleLightbox={(url) => {
						const { lightBoxItems: lbItems } = this.state
						const index = lbItems.findIndex((i) => i.src === url)
						this.setState({ lightboxIndex: index })
					}}
				/>
			</div>
		))
	}

	render() {
		const { props } = this
		const { lightboxIndex, lightBoxItems } = this.state
		const { team } = props.store
		return (
			<div className={grids.container}>
				<InfiniteScroll
					loadMore={this.handleLoadMore}
					hasMore={props.relay.hasMore()}
					loader={
						<div className={grids.loadMore}>
							<LoadingDots />
						</div>
					}
					pageStart={0}
				>
					<div className={grids.rowMax4}>
						{this.renderFiles(team.files)}
					</div>
				</InfiniteScroll>
				{lightBoxItems.length > 0 && (
					<Lightbox
						open={lightboxIndex !== null}
						close={() => this.setState({ lightboxIndex: null })}
						slides={lightBoxItems}
						index={lightboxIndex}
						carousel={{
							finite: true,
						}}
						on={{
							view: ({ index }) => {
								this.setState({ lightboxIndex: index })
								const { lightBoxItems: lbItems } = this.state
								if (
									index === lbItems.length - 1 &&
									props.relay.hasMore()
								) {
									this.handleInfiniteLoad()
								}
							},
						}}
						plugins={[Fullscreen, Thumbnails, Video, Zoom, Download]}
					/>
				)}
			</div>
		)
	}
}

const query = graphql`
	query teamFilesQuery(
		$count: Int!
		$cursor: String
		$teamId: String!
	) {
		store {
			...teamFiles_store
				@arguments(
					count: $count
					cursor: $cursor
					teamId: $teamId
				)
		}
	}
`

TeamFiles = createPaginationContainer(
	TeamFiles,
	{
		store: graphql`
			fragment teamFiles_store on Store
			@argumentDefinitions(
				count: { type: "Int", defaultValue: 12 }
				cursor: { type: "String" }
				teamId: { type: "String!" }
			) {
				id
				currentUser {
					id
				}
                team(id: $teamId) {
					files(first: $count, after: $cursor)
						@connection(key: "team_files") {
						edges {
							node {
								...fileCard_shopshareFile
							}
						}
					}
				}
			}
		`,
	},
	{
		direction: "forward",
		getConnectionFromProps(props) {
			const { team } = props.store
			return team && team.files
		},
		getFragmentVariables(prevVars, totalCount) {
			return {
				...prevVars,
				count: totalCount,
			}
		},
		getVariables(props, { count, cursor }) {
			return {
				count,
				cursor,
				teamId: props.teamId,
			}
		},
		query,
	}
)

export default {
	Component: TeamFiles,
	query,
	params: {
		count: 12,
		cursor: null,
		teamId: null,
	},
}
