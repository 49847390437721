/* eslint-disable no-unused-expressions */
import React, { Component, Fragment, createRef } from "react"
import { Helmet } from "react-helmet"
import { Link } from "react-router-dom"
import {
	ChevronLeftIcon,
	FileIcon,
	QuestionMarkCircledIcon,
} from "@radix-ui/react-icons"
import queryString from "query-string"
import { graphql, createFragmentContainer } from "react-relay"
import { toast } from "react-toastify"
import { io } from "socket.io-client"
import { ROLES, baseUrl } from "constants"
import deleteLookbookBoardMutation from "mutations/lookbook/delete-lookbook-board"
import ListItem from "components/lookbook/list-item"
import ShopcastList from "components/lookbook/shopcast-list"
import FontList from "components/lookbook/font-list"
import CategorizeImageList from "components/lookbook/categorize-image-list"
import ShapeList from "components/lookbook/shape-list"
import BackgroundList from "components/lookbook/background-list"
import MenuContainer from "components/lookbook/menu-container"
import TemplateList from "components/lookbook/template-list"
import LookbookVideoContainer from "components/lookbook/lookbook-video-container"
import LookbookSaving from "components/modals/lookbook-saving/lookbook-saving"
import ConformationPrompt from "components/modals/conformation-prompt/conformation-prompt"
import ProductCollectionCreateEditModal from "components/modals/product-collection-create-edit/product-collection-create-edit"
import { getFeatureAvalibale, FeatureKeyENUM } from "services/plan-services"
import {
	getImageByURL,
	newEmptyLookBook,
	newEmptyBorad,
	defaultLookbookTitle,
	defaultTemplateTitle,
	defaultBackground,
} from "helpers/lookbook-helper"
import { createGuid, toTitleCase } from "helpers/string-helper"
import { CategoryENUM, MimeTypeENUM } from "helpers/enum-helper"
import gql from "helpers/gql-helper"
import CanvasBoard from "routes/lookbook/moveable"
import nav from "shared/styles/components/nav.css"
import button from "shared/styles/components/buttons.css"
import styles from "routes/lookbook/lookbook.css"
import { DndProvider } from "react-dnd"
import { HTML5Backend } from "react-dnd-html5-backend"
import { getComponent, withRouter } from "../../route-helpers"

const LookbookContext = React.createContext({})
export const LookbookProvider = LookbookContext.Provider
export const LookbookConsumer = LookbookContext.Consumer
export const CHANGES_FROM = {
	CANVAS: "CANVAS",
	LOOKBOOK: "LOOKBOOK",
}

class Lookbook extends Component {
	constructor(props) {
		super(props)
		const qs = queryString.parse(props.location.search)
		this.guid = createGuid()

		let selectedTab = CategoryENUM.SHOPCAST
		this.socketConnections = []
		if ("collection" in qs) selectedTab = CategoryENUM.COLLECTION
		else if ("shopcast" in qs) selectedTab = CategoryENUM.SHOPCAST
		else if ("template" in qs) selectedTab = CategoryENUM.TEMPLATE
		else if (localStorage.getItem("lookbook-menu"))
			selectedTab = localStorage.getItem("lookbook-menu")
		if (selectedTab === CategoryENUM.RECORDER && !props.params.id) {
			selectedTab = CategoryENUM.SHOPCAST
		}
		this.state = {
			shopcastListId: qs.shopcast || "",
			categorizeImageKeyword: null,
			backgroundKeyword: "",
			id: null,
			lookbook: {
				id: null,
				title: defaultLookbookTitle,
				description: null,
				needSave: false,
				publishedAt: null,
				screenWidth: null,
				dataJson: [],
			},
			saving: false,
			selected: selectedTab,
			backgroundColor: defaultBackground.fill,
			selectedBackgroundColor: "#d1c5af",
			selectedSVGColor: "#000000",
			selectedTextColor: "#000000",
			sideTray: true,
			categories: [],
			openSaving: false,
			haveChanges: false,
			folderList: null,
			bgType: "color",
			fontCategory: "",
			favouriteListOpen: false,
			imageDrawing: false,
			templateCategory: "",
			draggedObj: null,
			editObj: null,
			disabled: false,
			transcoding: [],
			changesWhenSaving: false,
		}
		this.socketInstance = io(baseUrl)
		this.canvas = createRef()
		this.acceptSaving = this.acceptSaving.bind(this)
		this.changeLookbook = this.changeLookbook.bind(this)
		this.createFolderList = this.createFolderList.bind(this)
		this.renderMenu = this.renderMenu.bind(this)
		this.renderNav = this.renderNav.bind(this)
		this.chageDrawingStatus = this.chageDrawingStatus.bind(this)
		this.setBackgroundValue = this.setBackgroundValue.bind(this)
		this.applyTemplate = this.applyTemplate.bind(this)
		this.dropCard = this.dropCard.bind(this)
		this.loadBoard = this.loadBoard.bind(this)
		this.deleteBoard = this.deleteBoard.bind(this)
		this.dropByClick = this.dropByClick.bind(this)
		this.updatePageNo = this.updatePageNo.bind(this)
		this.updateIds = this.updateIds.bind(this)
		this.renderVideoContainer = this.renderVideoContainer.bind(this)
		this.changeTranscodingStatus = this.changeTranscodingStatus.bind(this)
		this.doChanges = this.doChanges.bind(this)

		this.shopcastListOnChange = this.shopcastListOnChange.bind(this)
		this.categorizeImageListOnChange = this.categorizeImageListOnChange.bind(
			this
		)
		this.backgroundImageListTypeChange = this.backgroundImageListTypeChange.bind(
			this
		)
		this.backgroundImageListOnChange = this.backgroundImageListOnChange.bind(
			this
		)
		this.backgroundImageListOnClick = this.backgroundImageListOnClick.bind(
			this
		)
		this.backgroundImageOnKeywordChange = this.backgroundImageOnKeywordChange.bind(
			this
		)
		this.shapeListOnChange = this.shapeListOnChange.bind(this)
		this.shapeListOnKeywordChange = this.shapeListOnKeywordChange.bind(this)
		this.templateListOnChange = this.templateListOnChange.bind(this)
		this.templateListOnEditChange = this.templateListOnEditChange.bind(this)
		this.templateListOnCardDrag = this.templateListOnCardDrag.bind(this)
		this.templateListChangeLookbook = this.templateListChangeLookbook.bind(
			this
		)
	}

	async componentDidMount() {
		const { store, params, location, relay, team } = this.props
		const urlParams = new URLSearchParams(location.search)
		const tempCategory = urlParams.get("tempCategory")
		const page = urlParams.get("page")
		const stateJson = {
			id: params.id,
			background: defaultBackground,
		}
		if (store && store.currentUser) {
			const { currentUser } = store
			const {
				lookbookUploadsCategoryList,
				shopcastContainer,
				shopcasts,
			} = currentUser
			stateJson.categories = lookbookUploadsCategoryList
			stateJson.folderList = this.createFolderList(
				shopcastContainer.folder || null
			)
			stateJson.shopcastList = (shopcasts.edges || []).map((x) => ({
				id: x.node.id,
				title: x.node.title,
			}))
		}
		stateJson.lookbookBoards = []
		if (store && store.lookbook && store.lookbook.lookbookBoards) {
			const { lookbook } = store
			const {
				lookbookBoards,
				user,
				id,
				folder,
				title,
				description,
				isTemplate,
				lookbookTemplate,
				published_at: publishedAt,
			} = lookbook
			if (lookbookBoards && lookbookBoards.length > 0) {
				const pr = lookbookBoards.map(async (board) => ({
					...board,
					boardId: board.id,
					background: await this.setBackgroundValue(board.background),
					title: board.title || defaultLookbookTitle,
					screenWidth: parseFloat(board.screenWidth || 0),
					width: board.width,
					height: board.height,
					dataJson: [
						...(board.dataJson ? JSON.parse(board.dataJson) : []),
					],
					group: [...(board.group ? JSON.parse(board.group) : [])],
					shapes: board.shapes
						? board.shapes.edges.map((x) => x.node)
						: [],
				}))
				stateJson.lookbookBoards = await Promise.all(pr)
			}
			stateJson.lookbook = {
				id,
				user,
				title,
				description,
				publishedAt,
				folder,
				isTemplate,
				templateId: lookbookTemplate?.id || null,
				isGlobal: lookbookTemplate?.isGlobal || null,
				categories: lookbookTemplate?.categories || [],
			}
		} else {
			stateJson.lookbook = newEmptyLookBook()
		}
		if (
			!(store && store.lookbook) &&
			tempCategory !== null &&
			getFeatureAvalibale(FeatureKeyENUM.TEMPLATES, relay.environment, team)
		) {
			stateJson.lookbook = {
				id: null,
				user: store?.currentUser || null,
				folder: null,
				title: defaultTemplateTitle,
				isTemplate: true,
				isGlobal: !!(tempCategory && tempCategory !== "userTemplates"),
				categories:
					tempCategory && tempCategory !== "userTemplates"
						? [toTitleCase(tempCategory)]
						: [],
			}
		}
		if (stateJson.lookbookBoards && stateJson.lookbookBoards.length === 0) {
			stateJson.lookbookBoards.push(newEmptyBorad())
		}
		let selectedBoard = null
		if (page)
			selectedBoard = stateJson.lookbookBoards.find(
				(board) => board.id === page
			)
		if (!selectedBoard) [selectedBoard] = stateJson.lookbookBoards
		stateJson.selectedBoard = selectedBoard
		stateJson.background = selectedBoard.background
		stateJson.backgroundColor = selectedBoard.background.fill
		stateJson.backgroundImage = selectedBoard.background.fillurl
		if (!stateJson.id) {
			stateJson.openSaving = true
		}
		this.setState(stateJson)
	}

	componentWillUnmount() {
		this.socketInstance.disconnect()
	}

	async setBackgroundValue(backgroundJson) {
		const { backgroundColor } = this.state
		let background = {}
		try {
			background = JSON.parse(backgroundJson)
			background = background.fillurl
				? {
						fill: background.fill,
						fillurl: background.fillurl,
						fillPatternScale: background.fillPatternScale,
						fillPatternImage: await getImageByURL({
							imageUrl: background.fillurl,
						}),
						opacity: background.opacity || 1,
				  }
				: background
		} catch (e) {
			background = {
				fill: backgroundJson?.fillurl || backgroundJson || backgroundColor,
			}
		}
		return background
	}

	acceptSaving() {
		this.setState({ saving: true })
		this.canvas?.runDebounce(false)
		setTimeout(() => {
			this.canvas.saveLookbook(true)
		}, 10)
	}

	changeLookbook(data, loadBoard, selBoardId, triggerChanges = true, callback = null) {
		const { selectedBoard, id, lookbook, lookbookBoards } = this.state
		const selectedBoardId = selBoardId || selectedBoard.boardId
		const { store } = this.props
		const {
			lookbookId,
			lookbookTitle,
			title,
			description,
			publishedAt,
			folder,
			screenWidth,
			dataJson,
			width,
			height,
			group,
			shapes,
			background,
			isGlobal,
			isTemplate,
			categories,
			usedTemplate,
			pageNo,
			boardId,
			showBoard,
			showVideo,
			videoUrl,
			mp4Url,
			url,
		} = data
		const boardvalue = {}
		const value = {}
		if (boardId !== undefined) {
			boardvalue.boardId = boardId
		}
		if (lookbookId !== undefined) {
			value.id = lookbookId
			this.setState({ id: lookbookId })
		}
		if (lookbookTitle !== undefined) {
			value.title = lookbookTitle.trimStart()
		}
		if (title !== undefined) {
			boardvalue.title = title.trimStart()
		}
		if (description !== undefined) {
			value.description = description
		}
		if (publishedAt === false) {
			value.publishedAt = null
		}
		if (publishedAt) {
			value.publishedAt = new Date(Date.now())
		}
		if (folder) {
			value.folder = folder
		}
		if (screenWidth) {
			boardvalue.screenWidth = screenWidth
		}
		if (dataJson) {
			boardvalue.dataJson = dataJson
		}
		if (width) {
			boardvalue.width = width
		}
		if (height) {
			boardvalue.height = height
		}
		if (group) {
			boardvalue.group = group
		}
		if (shapes) {
			boardvalue.shapes = shapes
		}
		if (background) {
			boardvalue.background = background
		}
		if (showBoard !== undefined) {
			boardvalue.showBoard = showBoard
		}
		if (isGlobal !== undefined) {
			value.isGlobal = isGlobal
		}
		if (isTemplate !== undefined) {
			value.isTemplate = isTemplate
			if (isTemplate && !store?.lookbook?.isTemplate) {
				value.id = null
			}
			if (isTemplate && store?.lookbook?.isTemplate) {
				value.id = id
			}
			if (!isTemplate && !store?.lookbook?.isTemplate) {
				value.id = id
			}
			if (!isTemplate && store?.lookbook?.isTemplate) {
				value.id = null
				value.templateId = null
			}
			if (isTemplate && lookbook.title === defaultLookbookTitle) {
				value.title = defaultTemplateTitle
			}
			if (!isTemplate && lookbook.title === defaultTemplateTitle) {
				value.title = defaultLookbookTitle
			}
		}
		if (categories !== undefined) {
			value.categories = categories
		}
		if (usedTemplate !== undefined) {
			boardvalue.usedTemplate = usedTemplate
		}
		if (videoUrl !== undefined) {
			boardvalue.videoUrl = videoUrl
		}
		if (mp4Url !== undefined) {
			boardvalue.mp4Url = mp4Url
		}
		if (showVideo !== undefined) {
			boardvalue.showVideo = showVideo
		}
		if (pageNo !== undefined) {
			boardvalue.pageNo = pageNo
		}
		if (url !== undefined) {
			boardvalue.url = url
		}
		let selectedBoardIndex = lookbookBoards.findIndex(
			(board) => board.boardId === selectedBoardId
		)
		if (selectedBoardIndex < 0) {
			selectedBoardIndex = lookbookBoards.findIndex(
				(board) => board.boardId.indexOf("newBoard_") > -1
			)
		}
		const boards = [...lookbookBoards]
		const updatedBoard = {
			...boards[selectedBoardIndex],
			...boardvalue,
		}
		boards[selectedBoardIndex] = updatedBoard
		if (triggerChanges)
			this.doChanges(CHANGES_FROM.LOOKBOOK)
		this.setState(
			{
				lookbook: {
					...lookbook,
					...value,
				},
				selectedBoard:
					selBoardId &&
					selBoardId !== selectedBoard.boardId &&
					selectedBoard.boardId.indexOf("newBoard_") === -1
						? selectedBoard
						: updatedBoard,
				lookbookBoards: boards,
			},
			() => {
				if (loadBoard !== undefined) {
					this.loadBoard(loadBoard)
				}
				if (callback) {
					callback()
				}
			}
		)
	}

	createFolderList(folder) {
		const { store } = this.props
		if (folder && store.lookbook && store.lookbook.folder) {
			const lookbookFolder = store.lookbook.folder
			const hasChildern =
				folder &&
				folder.folders &&
				folder.folders.edges &&
				folder.folders.edges.length > 0
			return {
				id: folder.id,
				name: folder.name,
				toggled: false,
				children: hasChildern
					? folder.folders.edges.map((child) =>
							this.createFolderList(child.node)
					  )
					: null,
				active: folder.id === lookbookFolder.id,
			}
		}
		return null
	}

	chageDrawingStatus(status) {
		this.setState({ imageDrawing: status })
	}

	async applyTemplate(data, onLoad) {
		const {
			screen_width: screenWidth,
			data_json: dataJson,
			width,
			height,
			background,
			group,
			shapes,
			templateId,
		} = data
		const bg = await this.setBackgroundValue(background)
		const newLookbook = {
			screenWidth: parseFloat(screenWidth || 0),
			dataJson: [...(dataJson ? JSON.parse(dataJson) : [])],
			group: [...(group ? JSON.parse(group) : [])],
			width,
			height,
			background: bg,
			shapes: shapes?.edges.map((edge) => edge.node) || [],
			usedTemplate: templateId,
		}
		this.changeLookbook(newLookbook, undefined, undefined, !onLoad)
		this.setState({
			background: bg,
			backgroundColor: bg.fill,
			backgroundImage: bg.fillurl,
		})
		this.canvas.drawBoard()
	}

	dropCard() {
		const { draggedObj, haveChanges } = this.state
		if (draggedObj) {
			switch (draggedObj.type) {
				case CategoryENUM.TEMPLATE:
					if (haveChanges)
						this.setState({ editObj: draggedObj.obj, draggedObj: null })
					else
						this.applyTemplate({
							...draggedObj.obj?.lookbook,
							templateId: draggedObj.obj?.id,
						})
					break
				default:
			}
		}
	}

	loadBoard(board) {
		const { lookbookBoards } = this.state
		let newBoard = board
		if (!board) {
			this.doChanges(CHANGES_FROM.LOOKBOOK)
			newBoard = newEmptyBorad()
			newBoard.pageNo =
				lookbookBoards.length === 0
					? 0
					: lookbookBoards[lookbookBoards.length - 1].pageNo + 1
		}
		const selectedIndex = lookbookBoards.findIndex(
			(obj) => obj.boardId === newBoard.boardId
		)
		if (selectedIndex < 0) {
			lookbookBoards.push(newBoard)
		}
		this.setState(
			{
				lookbookBoards,
				selectedBoard: newBoard,
				background: newBoard.background,
				backgroundImage: newBoard.background?.fillurl,
				backgroundColor: newBoard.background?.fill,
				// haveChanges: false,
			},
			() => {
				if (this.canvas) this.canvas.drawBoard()
			}
		)
	}

	deleteBoard(boardId) {
		const { selectedBoard, lookbookBoards } = this.state
		const { relay } = this.props
		const selectedBoardId = boardId || selectedBoard.boardId
		deleteLookbookBoardMutation.commit(
			relay.environment,
			selectedBoardId,
			() => {},
			(e) => {
				toast.info(<Fragment>{gql.getError(e)}</Fragment>, {
					autoClose: 5000,
					closeButton: false,
				})
			}
		)
		const selectedIndex = lookbookBoards.findIndex(
			(board) => board.boardId === selectedBoardId
		)
		let newSelected = lookbookBoards[selectedIndex]
		if (selectedIndex >= 0) {
			lookbookBoards.splice(selectedIndex, 1)
			if (lookbookBoards.length > 0) {
				newSelected =
					lookbookBoards[
						selectedIndex === lookbookBoards.length
							? selectedIndex - 1
							: selectedIndex
					]
			} else {
				newSelected = newEmptyBorad()
			}
		}
		if (selectedIndex === 0) {
			newSelected.boardId = lookbookBoards[selectedIndex].boardId
			newSelected.pageNo = lookbookBoards[selectedIndex].pageNo
		}
		this.setState({ selectedBoard: newSelected })
		this.loadBoard(newSelected)
	}

	dropByClick(e) {
		this.canvas.dropByClick(e)
	}

	updatePageNo(boardList) {
		const { lookbookBoards, selectedBoard } = this.state
		const updatedBoard = []
		boardList.forEach(({ boardId, pageNo }) => {
			const selBoard = lookbookBoards.find(
				(board) => board.boardId === boardId
			)
			updatedBoard.push({ ...selBoard, pageNo })
			if (selBoard.boardId === selectedBoard.boardId) {
				selectedBoard.pageNo = pageNo
			}
		})
		this.setState({
			lookbookBoards: updatedBoard,
			selectedBoard,
		})
	}

	updateIds(boardList) {
		const { lookbookBoards, selectedBoard } = this.state
		const updatedBoard = []
		boardList.forEach(({ boardId, pageNo }) => {
			const selBoard = lookbookBoards.find(
				(board) => board.pageNo === pageNo
			)
			updatedBoard.push({ ...selBoard, boardId })
			if (selBoard.pageNo === selectedBoard.pageNo) {
				selectedBoard.boardId = boardId
			}
		})
		this.setState({
			lookbookBoards: updatedBoard,
			selectedBoard,
		})
	}

	changeTranscodingStatus(jobId, value) {
		const { transcoding } = this.state
		const index = transcoding.indexOf(jobId)
		if (value && index < 0) {
			this.setState({ transcoding: [...transcoding, jobId] })
		} else if (!value && index >= 0) {
			this.setState({
				transcoding: transcoding.filter((obj) => obj !== jobId),
			})
		}
	}

	shopcastListOnChange({ target }) {
		this.setState({
			favouriteListOpen: target.value === CategoryENUM.FAVOURITE,
			shopcastListId: target.value,
		})
	}

	categorizeImageListOnChange({ target }) {
		this.setState({
			favouriteListOpen: target.value === CategoryENUM.FAVOURITE,
			categorizeImageKeyword: target.value,
		})
	}

	backgroundImageListTypeChange(newType) {
		this.setState({
			bgType: newType,
			backgroundKeyword: "",
			favouriteListOpen: false,
		})
	}

	backgroundImageListOnChange(color) {
		this.setState({
			selectedBackgroundColor: color,
		})
	}

	backgroundImageListOnClick(bg) {
		this.setState({
			backgroundColor: bg.fill,
			backgroundImage: bg.fillurl,
			background: bg,
		})
		this.changeLookbook({ background: bg })
	}

	backgroundImageOnKeywordChange({ target }) {
		this.setState({
			favouriteListOpen: target.value === CategoryENUM.FAVOURITE,
			backgroundKeyword: target.value,
		})
	}

	shapeListOnChange({ color }) {
		this.setState({ selectedSVGColor: color })
	}

	shapeListOnKeywordChange({ target }) {
		this.setState({
			favouriteListOpen: target.value === CategoryENUM.FAVOURITE,
			shapeKeyword: target.value,
		})
	}

	templateListOnChange({ target }) {
		this.setState({
			favouriteListOpen: target.value === CategoryENUM.FAVOURITE,
			templateCategory: target.value,
		})
	}

	templateListOnEditChange(obj) {
		this.setState({ editObj: obj })
	}

	templateListOnCardDrag(draggedObj) {
		return setTimeout(() => this.setState({ draggedObj }), 100)
	}

	templateListChangeLookbook(data) {
		this.changeLookbook(data)
		this.setState({ openSaving: true })
		this.canvas?.runDebounce(true)
	}

	doChanges(from) {
		const { saving } = this.state
		const state = { haveChanges: true }
		if (saving && from === CHANGES_FROM.CANVAS) {
			state.changesWhenSaving = true
		}
		this.setState(state)
		if (this.canvas && this.canvas.debounceSave) {
			this.canvas.debounceSave()
		}
	}

	renderVideoContainer() {
		const {
			transcoding: stateTranscoding,
			selectedBoard,
			id,
			selected,
		} = this.state
		const { relay } = this.props
		const { boardId, showVideo, videoUrl, mp4Url, subtitle } = selectedBoard
		this.socketInstance.on("progress", (jobId, status, url, newMp4Url) => {
			const { transcoding } = this.state
			// console.log(jobId, status, url, newMp4Url)
			if (
				(status === "running" || status === "started") &&
				!transcoding.includes(jobId)
			) {
				this.changeTranscodingStatus(jobId, true)
			} else if (status === "completed" && transcoding.includes(jobId)) {
				this.changeTranscodingStatus(jobId, false)
				toast.success(<div>Transcoding successfully</div>, {
					autoClose: 2000,
					closeButton: false,
				})
				this.changeLookbook(
					{ videoUrl: url, mp4Url: newMp4Url, showVideo: true },
					undefined,
					jobId
				)
				// this.setState({ haveChanges: false })
			} else {
				this.changeTranscodingStatus(jobId, false)
			}
		})
		if (!this.socketConnections.includes(boardId)) {
			this.socketInstance.emit("request-progress", boardId)
			this.socketConnections.push(boardId)
		}
		if (selected === CategoryENUM.RECORDER)
			return (
				<LookbookVideoContainer
					lookbookId={id}
					boardId={selectedBoard.boardId}
					relay={relay}
					showVideo={showVideo || false}
					changeLookbook={this.changeLookbook}
					videoUrl={videoUrl || null}
					mp4Url={mp4Url || null}
					subtitle={subtitle || null}
					savingComplete={() => this.setState({ haveChanges: false })}
					menuDisability={(status) => this.setState({ disabled: status })}
					transcoding={stateTranscoding.includes(selectedBoard.boardId)}
					changeTranscodingStatus={this.changeTranscodingStatus}
				/>
			)
		return ""
	}

	renderMenu() {
		const {
			id,
			shopcastListId,
			categorizeImageKeyword,
			shapeKeyword,
			backgroundKeyword,
			selected,
			backgroundColor,
			selectedBackgroundColor,
			backgroundImage,
			background,
			selectedSVGColor,
			selectedTextColor,
			sideTray,
			categories,
			bgType,
			fontCategory,
			favouriteListOpen,
			templateCategory,
			editObj,
			haveChanges,
			selectedBoard,
			disabled,
		} = this.state
		const { location, store, relay, team } = this.props
		const isAdmin =
			store.currentUser &&
			[ROLES.ADMIN, ROLES.TEAM].includes(store.currentUser.role)

		const ShopcastListComp = getComponent(
			`shopcast-list`,
			ShopcastList,
			{ ...ShopcastList.params, shopcastListId, teamId: team },
			{
				shopcastListId,
				teamId: team,
				guid: this.guid,
				onChange: this.shopcastListOnChange,
				favouriteListOpen,
				category: CategoryENUM.SHOPCAST,
				drop: this.dropByClick,
			}
		)

		const CategorizeImageListComp = getComponent(
			`list-of-recommended-items`,
			CategorizeImageList,
			{
				...CategorizeImageList.params,
				sub_category: categorizeImageKeyword,
				category_id: selected,
				teamId: team,
			},
			{
				accepted: MimeTypeENUM.ALL_IMAGES,
				sub_category: categorizeImageKeyword,
				guid: this.guid,
				onChange: this.categorizeImageListOnChange,
				favouriteListOpen,
				category_id: selected,
				drop: this.dropByClick,
			}
		)

		const BackgroundListComp = getComponent(
			`background-list`,
			BackgroundList,
			{
				...BackgroundList.params,
				sub_category: backgroundKeyword,
				bgType,
				teamId: team,
			},
			{
				accepted: MimeTypeENUM.ALL_IMAGES,
				sub_category: backgroundKeyword,
				guid: this.guid,
				category_id: selected,
				selectedColor: selectedBackgroundColor,
				category: bgType,
				typeChange: this.backgroundImageListTypeChange,
				onChange: this.backgroundImageListOnChange,
				onClick: this.backgroundImageListOnClick,
				onKeywordChange: this.backgroundImageOnKeywordChange,
				favouriteListOpen,
			},
			true
		)

		const ShapeListComp = getComponent(
			`shape-list`,
			ShapeList,
			{
				...ShapeList.params,
				shapeKeyword,
				teamId: team,
			},
			{
				selectedColor: selectedSVGColor,
				shapeKeyword,
				guid: this.guid,
				category: CategoryENUM.SHAPE,
				onChange: this.shapeListOnChange,
				onKeywordChange: this.shapeListOnKeywordChange,
				favouriteListOpen,
				drop: this.dropByClick,
			}
		)

		const qs = queryString.parse(location.search)
		const TemplateListComp = getComponent(
			`template-list`,
			TemplateList,
			{
				...TemplateList.params,
				templateCategory,
				teamId: team,
				templateId: qs.template
			},
			{
				id,
				templateCategory,
				guid: this.guid,
				onChange: this.templateListOnChange,
				applyTemplate: this.applyTemplate,
				isAdmin,
				favouriteListOpen,
				category: CategoryENUM.TEMPLATE,
				onEditChange: this.templateListOnEditChange,
				onCardDrag: this.templateListOnCardDrag,
				changeLookbook: this.templateListChangeLookbook,
				templateId: qs.template,
			},
			true
		)

		if (!selectedBoard) {
			return ""
		}
		return (
			<MenuContainer
				isOpen={sideTray}
				selected={selected}
				onSelect={(result) => {
					localStorage.setItem("lookbook-menu", result)
					this.setState({
						selected: result,
						favouriteListOpen: false,
					})
				}}
				onOpenClick={() => {
					this.setState({ sideTray: !sideTray })
					this.canvas.onWindowResize()
				}}
				categories={categories}
				isAdmin={isAdmin}
				environment={relay.environment}
				id={
					selectedBoard.boardId.indexOf("newBoard_") >= 0
						? null
						: selectedBoard.boardId
				}
				needSave={() => this.setState({ needSave: true })}
				disabled={disabled}
				team={team}
			>
				{selected === CategoryENUM.RECORDER && this.renderVideoContainer()}
				{selected === CategoryENUM.FONT && (
					<FontList
						selectedColor={selectedTextColor}
						onChange={(color) =>
							this.setState({ selectedTextColor: color })
						}
						fontCategory={fontCategory}
						onCategoryChange={({ target }) =>
							this.setState({ fontCategory: target.value })
						}
						category={CategoryENUM.FONT}
						drop={this.dropByClick}
					/>
				)}
				{selected === CategoryENUM.SHOPCAST && ShopcastListComp}
				{selected === CategoryENUM.TEMPLATE && (
					<TemplateListComp editObj={editObj} haveChanges={haveChanges} />
				)}
				{selected === CategoryENUM.COLLECTION && (
					<ListItem
						innerRef={(el) => {
							this.listItems = el
						}}
						category={CategoryENUM.COLLECTION}
						accepted={MimeTypeENUM.ALL_IMAGES}
						searchString={location.search}
						drop={this.dropByClick}
						relay={relay}
						store={store}
					/>
				)}

				{categories &&
					categories.map((category) => {
						CategorizeImageList.props = {
							...CategorizeImageList.props,
							category,
						}
						return selected === category.id && CategorizeImageListComp
					})}

				{selected === CategoryENUM.SHAPE && ShapeListComp}
				{selected === CategoryENUM.BACKGROUND && (
					<Fragment>
						{this.renderNav()}
						<BackgroundListComp
							background={background}
							backgroundColor={backgroundColor}
							backgroundImage={backgroundImage}
						/>
					</Fragment>
				)}
			</MenuContainer>
		)
	}

	renderNav() {
		const { bgType } = this.state
		return (
			<nav className={nav.tabsFillHasBorder}>
				<button
					type="button"
					className={
						bgType === "color" ? nav.underlineActive : nav.underline
					}
					onClick={() =>
						this.setState({
							bgType: "color",
							backgroundKeyword: "",
							favouriteListOpen: false,
						})
					}
				>
					Colours
				</button>
				<button
					type="button"
					className={
						bgType === "image" ? nav.underlineActive : nav.underline
					}
					onClick={() =>
						this.setState({
							bgType: "image",
							backgroundKeyword: "",
							favouriteListOpen: false,
						})
					}
				>
					Images
				</button>
			</nav>
		)
	}

	renderCanvas() {
		const {
			id,
			lookbook,
			saving,
			openSaving,
			haveChanges,
			shopcastList,
			imageDrawing,
			selectedBoard,
			lookbookBoards,
			disabled,
			templateCategory
		} = this.state
		const { location, relay, store, team } = this.props
		const currentUserId = store.currentUser.id
		if (selectedBoard) {
			return (
				<CanvasBoard
					innerRef={(el) => {
						this.canvas = el
					}}
					selectedBoard={selectedBoard}
					lookbook={lookbook}
					lookbookBoards={lookbookBoards}
					id={id}
					relay={relay}
					folderId={queryString.parse(location.search).folderId}
					haveChanges={haveChanges}
					saving={saving}
					profileID={store.currentUser.id}
					shopcastList={shopcastList}
					currentUserId={currentUserId}
					imageDrawing={imageDrawing}
					updatePageNo={this.updatePageNo}
					updateIds={this.updateIds}
					savingChange={(status) =>
						this.setState({
							saving: status,
							openSaving: openSaving ? !openSaving : openSaving,
							// haveChanges: false,
						})
					}
					savingComplete={() => {
						const { changesWhenSaving } = this.state
						this.setState({
							haveChanges: changesWhenSaving,
							changesWhenSaving: false,
						})
					}}
					doChanges={this.doChanges}
					chageDrawingStatus={this.chageDrawingStatus}
					changeLookbook={this.changeLookbook}
					clearBoard={() =>
						this.setState({
							backgroundColor: "#FFFFFF",
							backgroundImage: null,
							background: null,
						})
					}
					dropCard={this.dropCard}
					defaultLookbookTitle={defaultLookbookTitle}
					defaultTemplateTitle={defaultTemplateTitle}
					newEmptyBorad={newEmptyBorad}
					deleteBoard={this.deleteBoard}
					disabled={disabled}
					team={team}
					templateCategory={templateCategory}
				/>
			)
		}
		return ""
	}

	render() {
		const {
			id,
			lookbook,
			saving,
			sideTray,
			openSaving,
			haveChanges,
			folderList,
			lookbookBoards,
			needSave,
			selectedBoard,
			collectionToEdit,
		} = this.state
		const { navigate, relay, store, team } = this.props
		const isAdmin =
			store.currentUser &&
			[ROLES.ADMIN, ROLES.TEAM].includes(store.currentUser.role)
		return (
			<LookbookProvider
				value={{
					editCollection: (collection) =>
						this.setState({ collectionToEdit: collection }),
				}}
			>
				<div className={styles.root}>
					<Helmet>
						<title>ShopBoard</title>
						<body className="ShopBoards" />
					</Helmet>
					<div
						className={sideTray ? styles.content : styles.contentClosed}
					>
						<header className={styles.header}>
							<nav className={styles.navDesktop}>
								<div className={styles.navContainer}>
									<div className={styles.navLeft}>
										<button
											type="button"
											className={styles.iconBtn}
											data-tooltip-id="tooltip"
											data-tooltip-content="Back"
											onClick={() => {
												navigate(-1)
											}}
										>
											<ChevronLeftIcon className={styles.icon} />
										</button>
										<button
											className={styles.iconBtn}
											data-tooltip-id="tooltip"
											data-tooltip-content="Create New Board"
											onClick={() => {
												if (id || haveChanges) {
													window.location.replace(
														`${window.location.origin}/shopboard/create`
													)
												}
												navigate(`/shopboard/create`)
											}}
											type="button"
										>
											<FileIcon className={styles.icon} />
										</button>
										<div className={styles.dividerVert} />
										<h1 className={styles.title}>{lookbook.title}</h1>
									</div>
									<div className={styles.navRight}>
										<div className={styles.saveControls}>
											{saving ? (
												<span className={styles.statusSaving}>
													Saving changes...
												</span>
											) : (
												<>
													{haveChanges && (
														<span
															className={styles.statusUnsaved}
														>
															Unsaved changes
														</span>
													)}
													{!haveChanges && lookbook.id && (
														<span
															className={styles.statusSaved}
															data-tooltip-id="tooltip"
															data-tooltip-content="Every change you make is automatically saved"
														>
															All changes saved
														</span>
													)}
												</>
											)}
										</div>
										<Link
											className={styles.iconBtn}
											data-tooltip-id="tooltip"
											data-tooltip-content="Help"
											to="/dashboard"
										>
											<QuestionMarkCircledIcon
												className={styles.icon}
											/>
										</Link>
									</div>
								</div>
							</nav>
						</header>
						<DndProvider backend={HTML5Backend}>
							<div className={styles.board}>{this.renderCanvas()}</div>
							<div className={styles.aside} id="scrollableTarget">
								{this.renderMenu()}
							</div>
						</DndProvider>
					</div>
				</div>
				<LookbookSaving
					openModal={openSaving}
					closeModal={() => {
						this.setState({ openSaving: false })
						this.canvas?.runDebounce(false)
					}}
					approveEvent={this.acceptSaving}
					declineEvent={() => {
						this.setState({ openSaving: false })
						this.canvas?.runDebounce(false)
					}}
					title="Create New Board"
					approveText="Create"
					approveButtonStyle={button.primary}
					loading={saving}
					lookbook={lookbook}
					selectedBoard={selectedBoard}
					folderList={folderList}
					lookbookBoardsCount={lookbookBoards?.length || 0}
					changeLookbook={this.changeLookbook}
					isAdmin={isAdmin && !team}
					environment={relay.environment}
					categorySet={
						store.currentUser.lookbookTemplatesCategories.map((cat) => ({
							id: `db_${createGuid(4)}`,
							value: cat,
						})) || []
					}
					changeCanvasSize={(canvas) => {
						this.canvas.changeCanvasSize(canvas)
					}}
				/>
				<ConformationPrompt
					openModal={needSave}
					closeModal={() => this.setState({ needSave: false })}
					approveEvent={() =>
						this.setState({ openSaving: true, needSave: false })
					}
					declineEvent={() => this.setState({ needSave: false })}
					approveText="Ok"
					approveButtonStyle={button.primary}
					declineText="Cancel"
					title="Save Board"
				>
					You need to save board before recordings.
				</ConformationPrompt>
				{!!collectionToEdit && (
					<ProductCollectionCreateEditModal
						collectionToEdit={collectionToEdit}
						relay={relay}
						currentUser={store.currentUser}
						hideClosetSetting
						onCancel={() => {
							this.setState({ collectionToEdit: null })
						}}
						onSuccess={(type, data) => {
							if (type === "new" && this.listItems)
								this.listItems.onChange({ target: { value: data.id } })
						}}
						team={team}
						other={{
							search: "",
							categories: [],
							brands: [],
							collections: [],
							sort: "n-to-o",
							clientIds: [],
							limitType: ["collections","closet"],
							resultTypes: ["collections"],
							teamId: team,
							collectionSearch: ""
						}}
					/>
				)}
			</LookbookProvider>
		)
	}
}

Lookbook = createFragmentContainer(Lookbook, {
	store: graphql`
		fragment lookbook_store on Store
		@argumentDefinitions(
			id: { type: "String" }
			teamId: { type: "String" }
		) {
			currentUser {
				id
				adminAccess
				role
				userClients(teamId: $teamId) {
					edges {
						node {
							id
							fullname
							email
						}
					}
				}
				shopcasts {
					edges {
						node {
							id
							title
						}
					}
				}
				shopcastContainer {
					folder {
						id
						name
						folders {
							edges {
								node {
									id
									name
								}
							}
						}
					}
				}
				lookbookUploadsCategoryList {
					id
					title
					user {
						id
					}
					is_default
				}
				shapesCollections
				shapes {
					edges {
						node {
							id
							viewBox
							path
							collection
						}
					}
				}
				lookbookTemplatesCategories
			}
			lookbook(id: $id) {
				id
				title
				description
				published_at
				lookbookBoards {
					id
					lookbookId
					title
					url
					dataJson
					screenWidth
					width
					height
					background
					pageNo
					showBoard
					showVideo
					videoUrl
					mp4Url
					subtitle
					shapes {
						edges {
							node {
								id
								viewBox
								path
								collection
							}
						}
					}
				}
				folder {
					id
					name
				}
				user {
					fullname
				}
				isTemplate
				lookbookTemplate {
					id
					isGlobal
					categories
				}
			}
		}
	`,
})

const query = graphql`
	query lookbookQuery($id: String, $teamId: String) {
		store {
			...lookbook_store @arguments(id: $id, teamId: $teamId)
		}
	}
`

export default {
	Component: withRouter(Lookbook),
	query,
	needAuth: true,
	params: (rp) => ({ 
		id: rp.params.id || "",
		teamId: rp.team
	}),
}
