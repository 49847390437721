import React, { useLayoutEffect, useRef } from "react"
import { keyValueToObj } from "helpers/type-helper"
import TickCircle from "shared/assets/tick-circle-sm-icon.svg"
import styles from "./price-card.css"

export default function PriceCard({
	plan,
	currentPlan,
	selectPlan,
	currentUser,
	coupon,
}) {
	const ref = useRef(null)

	const metadata = keyValueToObj(plan.metadata || [])
	const canCouponBeApplied =
		coupon && (!coupon.products || coupon.products.includes(plan.product))

	const getAmount = (val) => {
		if (!coupon || !canCouponBeApplied) return val
		if (coupon.amountOff) return val - coupon.amountOff
		if (coupon.percentOff) return val - val * (coupon.percentOff / 100)
		return val
	}

	const getPlanButtonLabel = () => {
		if (!currentUser) return `Start Trial`
		if (!currentUser.trialUsed) return `Subscribe`
		if (currentUser.trialUsed && !currentUser.userPlan) return `Subscribe`
		return `${plan.nickname !== currentPlan.nickname && "Update Plan"}`
	}

	useLayoutEffect(() => {
		if (!PriceCard.paragraphElements) PriceCard.paragraphElements = []
		PriceCard.paragraphElements = [...PriceCard.paragraphElements, ref]
		const height = Math.max(
			...PriceCard.paragraphElements.map((el) => el.current.clientHeight)
		)
		PriceCard.paragraphElements.forEach((element) => {
			element.current.style.minHeight = `${height}px`
		})
		return () => {
			const index = PriceCard.paragraphElements.indexOf(ref)
			if (index > -1) PriceCard.paragraphElements.splice(index, 1)
		}
	}, [ref])

	return (
		<div className={styles.root}>
			<div
				className={
					metadata.mostPopular === "true" ? styles.cardActive : styles.card
				}
			>
				<div className={styles.header}>
					{metadata.mostPopular === "true" && (
						<div className={styles.badge}>Most Popular</div>
					)}
					{canCouponBeApplied &&
						(plan.monthly ? (
							<div className={styles.couponBadge}>
								🎉 50% off for 3 months
							</div>
						) : (
							<div className={styles.couponBadge}>🎉 25% Off</div>
						))}
				</div>
				<div className={styles.content}>
					<div className={styles.about}>
						<h2 className={styles.title}>{plan.nickname}</h2>
						<div className={styles.description} ref={ref}>
							{plan.description}
						</div>
					</div>
					<div className={styles.priceAction}>
						<div className={styles.price}>
							{plan.amount > 0 ? (
								<>
									<p className={styles.value}>
										{plan.currency.toUpperCase()}{" "}
										{(getAmount(plan.amount) / 100).toFixed(2)}
										<span className={styles.month}>
											{plan.monthly ? "/mo" : "/yr"}
										</span>
									</p>
									{!plan.monthly && (
										<p className={styles.billingCycle}>
											{plan.currency.toUpperCase()}{" "}
											{(getAmount(plan.amount) / 12 / 100).toFixed(
												2
											)}{" "}
											per month
										</p>
									)}
								</>
							) : (
								<p className={styles.value}>US$0</p>
							)}
						</div>

						{plan.nickname !== currentPlan.nickname ? (
							<button
								className={
									canCouponBeApplied ? styles.special : styles.button
								}
								onClick={() => {
									selectPlan()
								}}
								type="submit"
							>
								{getPlanButtonLabel()}
							</button>
						) : (
							<button
								type="button"
								className={styles.currentPlan}
								disabled
							>
								Current Plan
							</button>
						)}
					</div>
				</div>
				{plan.descriptionList.length > 0 && (
					<div className={styles.featureList}>
						<p className={styles.featurelead}>This includes:</p>
						<ul className={styles.features}>
							{plan.descriptionList.map((i) => (
								<li className={styles.feature} key={i}>
									<TickCircle className={styles.featureIcon} />{" "}
									<span className={styles.featurelabel}>{i}</span>
								</li>
							))}
						</ul>
					</div>
				)}

				{/* <div className={styles.footer}>
					<div className={styles.more}>
						<a
							href="#featureTable"
							tabIndex={0}
							className={styles.moreLink}
						>
							See more features
						</a>
					</div>
				</div> */}
			</div>
		</div>
	)
}
