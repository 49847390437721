/**
 * @generated SignedSource<<aa5b92daa7cbd2f1ba0a8b6d194177a6>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentType } from "relay-runtime";
declare export opaque type clients_store$fragmentType: FragmentType;
export type clients_store$data = {|
  +currentUser: ?{|
    +id: string,
    +shopcastContainer: ?{|
      +folder: ?{|
        +folders: ?{|
          +edges: ?$ReadOnlyArray<?{|
            +node: ?{|
              +avatar: ?string,
              +id: string,
              +name: ?string,
            |},
          |}>,
        |},
        +id: string,
        +name: ?string,
      |},
    |},
  |},
  +id: string,
  +$fragmentType: clients_store$fragmentType,
|};
export type clients_store$key = {
  +$data?: clients_store$data,
  +$fragmentSpreads: clients_store$fragmentType,
  ...
};
*/

var node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "clients_store",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "currentUser",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "ShopcastContainer",
          "kind": "LinkedField",
          "name": "shopcastContainer",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": [
                {
                  "kind": "Literal",
                  "name": "id",
                  "value": ""
                }
              ],
              "concreteType": "ShopcastFolder",
              "kind": "LinkedField",
              "name": "folder",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                (v1/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "ShopcastFolderShopcastFolderConnection",
                  "kind": "LinkedField",
                  "name": "folders",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "ShopcastFolderShopcastFolderEdge",
                      "kind": "LinkedField",
                      "name": "edges",
                      "plural": true,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "ShopcastFolder",
                          "kind": "LinkedField",
                          "name": "node",
                          "plural": false,
                          "selections": [
                            (v0/*: any*/),
                            (v1/*: any*/),
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "avatar",
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": "folder(id:\"\")"
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Store",
  "abstractKey": null
};
})();

(node/*: any*/).hash = "6272c2125b65ca19c7db6c3e3f4b09de";

module.exports = ((node/*: any*/)/*: Fragment<
  clients_store$fragmentType,
  clients_store$data,
>*/);
