export const createGuid = (guidLenth = 32) =>
	"xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx"
		.replace(/[xy]/g, (c) => {
			const r = Math.random() * 16 || 0
			const v = c === "x" ? r : (r && 0x3) || 0x8
			return v.toString(32)
		})
		.replace(/\./g, "")
		.substring(0, guidLenth)

export const toTitleCase = (string) => {
	let i
	let j
	let str
	str = string.replace(
		/([^\W_]+[^\s-]*) */g,
		(txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
	)

	// Certain minor words should be left lowercase unless
	// they are the first or last words in the string
	const lowers = [
		"A",
		"An",
		"The",
		"And",
		"But",
		"Or",
		"For",
		"Nor",
		"As",
		"At",
		"By",
		"For",
		"From",
		"In",
		"Into",
		"Near",
		"Of",
		"On",
		"Onto",
		"To",
		"With",
	]
	for (i = 0, j = lowers.length; i < j; i++)
		str = str.replace(new RegExp(`\\s${lowers[i]}\\s`, "g"), (txt) =>
			txt.toLowerCase()
		)

	// Certain words such as initialisms or acronyms should be left uppercase
	const uppers = ["Id", "Tv"]
	for (i = 0, j = uppers.length; i < j; i++)
		str = str.replace(
			new RegExp(`\\b${uppers[i]}\\b`, "g"),
			uppers[i].toUpperCase()
		)

	return str
}

export const validateEmail = (email) =>
	/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
		email
	)

export const dynamicSuffix = (string, wrapStart = "copy", wrapEnd = "") => {
	const wrapped = string.search(wrapStart) >= 0 ? 1 : 0
	let suffix = parseInt(
		string.substring(
			string.lastIndexOf(wrapStart) + 1,
			string.lastIndexOf(wrapEnd)
		),
		10
	)
	const title = string
		.substring(
			0,
			string.lastIndexOf(wrapStart) > -1
				? string.lastIndexOf(wrapStart)
				: string.length
		)
		?.trim()
	suffix = suffix ? suffix + 1 : "2"
	const suffixRes = wrapped ? suffix : ""
	return `${title} ${wrapStart} ${suffixRes}${wrapEnd}`
}

export const getFormattedNumber = (value) => {
	const formattedNumber = value
		.toString()
		.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
	return formattedNumber
}

export const getFormattedCurrency = ({ cSymbol, amount, selectedCurrency }) => {
	const formattedAmount = getFormattedNumber(amount.toFixed(2))
	return cSymbol
		? `${cSymbol}${formattedAmount}`
		: `${formattedAmount} ${selectedCurrency}`
}

export const getCollectionTitle = (col) => {
	if (!col.client) return col.title
	return col.isACloset
		? `${col.title} (${col.client.fullname}) (Closet)`
		: `${col.title} (${col.client.fullname})`
}
