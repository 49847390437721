import React, { Fragment } from "react"
import landing from "shared/styles/layout/landing.css"
import ListOfFaqs from "components/list-of-faqs/list-of-faqs"
import { Helmet } from "react-helmet"

import styles from "./faqs.css"

function Faqs() {
	return (
		<div className={styles.root}>
			<Helmet>
				<body className="Faqs" />
			</Helmet>
			<div className={styles.masthead}>
				<div className={styles.inner}>
					<h1 className={styles.title}>FAQs</h1>
					<p className={styles.lead}>
						With so many features built into our platform, it’s likely
						you’ll have some questions. Below are the ones we’re asked
						most often.
					</p>
				</div>
			</div>
			<section className={`${landing.section} ${landing.gray100}`}>
				<div className={landing.content}>
					<div className={styles.questions}>
						<ListOfFaqs
							question="How secure is my personal information?"
							answer={
								<Fragment>
									<p>
										We use AWS user management service to manage our
										users and their attributes. It is handled with
										secure access. However, should a user need our
										support with editing a video, the user can request
										our support to help and we can assist them.
									</p>
									<p>
										For further details please see our Privacy Policy.
									</p>
								</Fragment>
							}
						/>
						<ListOfFaqs
							question="How long can I record for?"
							answer={<p>For now your video file can be up to 500MB.</p>}
						/>
						<p className={styles.contact}>
							Still have a question about ShopShareTV? &nbsp;
							<a href="mailto:support@shopshare.tv">Get in touch</a>
						</p>
					</div>
				</div>
			</section>
		</div>
	)
}

export default { Component: Faqs }
