import { commitMutation, graphql } from "react-relay"
import { complete } from "../helper.js"

const mutation = graphql`
	mutation updateOptOutAffiliateMutation($input: updateOptOutAffiliateInput!) {
		updateOptOutAffiliate(input: $input) {
			store {
				id
				currentUser {
					id
					optOutAffiliate
				}
			}
		}
	}
`

const getConfigs = (storeId) => [
	{
		type: "FIELDS_CHANGE",
		fieldIDs: { store: storeId },
	},
]

const commit = (env, optOutAffiliate, storeId, onCompleted, onError) =>
	commitMutation(env, {
		mutation,
		variables: { input: { optOutAffiliate } },
		configs: getConfigs(storeId),
		onCompleted: complete(onCompleted, onError),
		onError: (e) => complete(null, [e]),
	})

export default { commit }
