import { commitMutation, graphql } from "react-relay"
import { complete } from "../helper.js"

const mutation = graphql`
	mutation toggleArchiveCampaignMutation($input: ToggleArchiveCampaignInput!) {
		toggleArchiveCampaign(input: $input) {
			store {
				id
			}
		}
	}
`

const commit = (env, data, onCompleted, onError) =>
	commitMutation(env, {
		mutation,
		variables: {
			input: { id: data.id },
		},
		onCompleted: complete(onCompleted, onError),
		onError: (e) => complete(null, [e]),
		updater: (store, gdata) => {
			if (gdata.toggleArchiveCampaign) {
				const campaign = store.get(data.id)
				campaign.setValue(!data.isArchived, "isArchived")
			}
		},
	})

export default { commit }
