/**
 * @generated SignedSource<<ba41b623300a0197d2d03398e8c8fa6b>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Mutation } from 'relay-runtime';
export type UpdateBoardNoInput = {|
  boardList?: ?$ReadOnlyArray<?IdValue>,
  clientMutationId?: ?string,
  lookbookId: string,
|};
export type IdValue = {|
  id?: ?string,
  value?: ?string,
|};
export type updateBoardNoMutation$variables = {|
  input: UpdateBoardNoInput,
|};
export type updateBoardNoMutation$data = {|
  +updateBoardNo: ?{|
    +lookbookBoards: ?$ReadOnlyArray<?{|
      +id: ?string,
      +lookbookId: string,
      +pageNo: ?number,
    |}>,
  |},
|};
export type updateBoardNoMutation = {|
  response: updateBoardNoMutation$data,
  variables: updateBoardNoMutation$variables,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UpdateBoardNoPayload",
    "kind": "LinkedField",
    "name": "updateBoardNo",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "LookbookBoard",
        "kind": "LinkedField",
        "name": "lookbookBoards",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "lookbookId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "pageNo",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "updateBoardNoMutation",
    "selections": (v1/*: any*/),
    "type": "ShopshareMutationRoot",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "updateBoardNoMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "1abc6e00c779630e2b4e3313b58c3066",
    "id": null,
    "metadata": {},
    "name": "updateBoardNoMutation",
    "operationKind": "mutation",
    "text": "mutation updateBoardNoMutation(\n  $input: UpdateBoardNoInput!\n) {\n  updateBoardNo(input: $input) {\n    lookbookBoards {\n      id\n      lookbookId\n      pageNo\n    }\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "96f410763d5ca705785768f72e255f02";

module.exports = ((node/*: any*/)/*: Mutation<
  updateBoardNoMutation$variables,
  updateBoardNoMutation$data,
>*/);
