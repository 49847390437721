/**
 * @generated SignedSource<<61ad5320001fee506509dd0300b79c08>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Mutation } from 'relay-runtime';
export type CreateProductCollectionInput = {|
  clientId?: ?string,
  clientMutationId?: ?string,
  folder?: ?string,
  isACloset?: ?boolean,
  isPublished?: ?boolean,
  teamId?: ?string,
  title: string,
|};
export type createProductCollectionMutation$variables = {|
  input: CreateProductCollectionInput,
|};
export type createProductCollectionMutation$data = {|
  +createProductCollection: ?{|
    +collection: ?{|
      +client: ?{|
        +fullname: ?string,
        +id: string,
      |},
      +count: ?number,
      +createdAt: ?string,
      +folder: ?{|
        +id: string,
        +name: ?string,
      |},
      +id: string,
      +images: ?$ReadOnlyArray<?string>,
      +isACloset: ?boolean,
      +isDefault: ?boolean,
      +isPublished: ?boolean,
      +title: ?string,
      +updatedAt: ?string,
      +user: ?{|
        +fullname: ?string,
        +id: string,
      |},
    |},
  |},
|};
export type createProductCollectionMutation = {|
  response: createProductCollectionMutation$data,
  variables: createProductCollectionMutation$variables,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  (v1/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "fullname",
    "storageKey": null
  }
],
v3 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "CreateProductCollectionPayload",
    "kind": "LinkedField",
    "name": "createProductCollection",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ProductCollection",
        "kind": "LinkedField",
        "name": "collection",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "title",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "createdAt",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "updatedAt",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isDefault",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isACloset",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isPublished",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ShopcastFolder",
            "kind": "LinkedField",
            "name": "folder",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": (v2/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Client",
            "kind": "LinkedField",
            "name": "client",
            "plural": false,
            "selections": (v2/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "count",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "images",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "createProductCollectionMutation",
    "selections": (v3/*: any*/),
    "type": "ShopshareMutationRoot",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "createProductCollectionMutation",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "d79aaff07dca9f2dffa28ad333aff18e",
    "id": null,
    "metadata": {},
    "name": "createProductCollectionMutation",
    "operationKind": "mutation",
    "text": "mutation createProductCollectionMutation(\n  $input: CreateProductCollectionInput!\n) {\n  createProductCollection(input: $input) {\n    collection {\n      id\n      title\n      createdAt\n      updatedAt\n      isDefault\n      isACloset\n      isPublished\n      folder {\n        id\n        name\n      }\n      user {\n        id\n        fullname\n      }\n      client {\n        id\n        fullname\n      }\n      count\n      images\n    }\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "7096b9e0bd2cface00abf5e25d51bedd";

module.exports = ((node/*: any*/)/*: Mutation<
  createProductCollectionMutation$variables,
  createProductCollectionMutation$data,
>*/);
