import React from "react"
import Modal from "react-modal"
import modal from "shared/styles/components/modal.css"
import buttons from "shared/styles/components/buttons.css"
import { Cross2Icon } from "@radix-ui/react-icons"
import { defaultStyles, FileIcon } from "react-file-icon"
import { toast } from "react-toastify"
import styles from "./file-edit.css"
import EditFileContent from "../image-upload/edit-file-content"
import gqlHelper from "../../../helpers/gql-helper"
import updateFile from "../../../mutations/shopshare-file/update-file"

export default function FileEdit({ file, closeModal, relay }) {
	const getUrlExtension = (url) => url.split(/[#?]/)[0].split(".").pop().trim()
	return (
		<Modal
			className={modal.root}
			overlayClassName={modal.overlay}
			isOpen
			ariaHideApp={false}
		>
			<div
				className={`${modal.dialog} ${modal.dialogCentered} ${modal.dialogFullscreenSmDown} ${modal.dialogLg}`}
			>
				<div className={`${modal.content}`}>
					<div className={modal.header}>
						<h4 className={modal.title}>Edit File</h4>
						<button
							type="button"
							className={buttons.close}
							onClick={() => {
								closeModal()
							}}
						>
							<Cross2Icon className={buttons.closeIcon} />
						</button>
					</div>
					<EditFileContent
						selectedFile={{
							title: file.title || "",
							tempIcon: !file.thumbnailUrl ? (
								<FileIcon
									extension={getUrlExtension(file.url)}
									{...defaultStyles[getUrlExtension(file.url)]}
								/>
							) : (
								<img
									className={styles.fileThumbnail}
									src={file.thumbnailUrl}
									alt="file thumbnail"
								/>
							),
						}}
						closeModal={() => {
							closeModal()
						}}
						save={(values) => {
							updateFile.commit(
								relay.environment,
								{
									id: file.id,
									title: values.title,
								},
								(e) => {
									toast.info(<p>{gqlHelper.getError(e)}</p>, {
										autoClose: 5000,
										closeButton: false,
									})
								},
								() => {
									closeModal()
								}
							)
						}}
					/>
				</div>
			</div>
		</Modal>
	)
}
