import React from "react"
import Modal from "react-modal"
import { useFormik } from "formik"
import * as Yup from "yup"
import { toast } from "react-toastify"
import modal from "shared/styles/components/modal.css"
import button from "shared/styles/components/buttons.css"
import contentCard from "shared/styles/layout/content-card.css"
import form from "shared/styles/components/form.css"
import textfield from "shared/styles/components/textfield.css"
import Shopping from "shared/assets/illustrations/shopping.svg"
import AddTeamMember from "mutations/team/add-team-member"
import gqlHelper from "helpers/gql-helper"
import styles from "./invite-member.css"

export default function InviteMember({ showModal, closeModal, relay, teamId }) {
	const formik = useFormik({
		initialValues: {
			email: "",
		},
		validationSchema: Yup.object().shape({
			email: Yup.string()
				.email("Invalid email")
				.required("Email is required"),
		}),
		onSubmit: (values) => {
			AddTeamMember.commit(
				relay.environment,
				{
					teamId,
					email: values.email,
					role: "member",
				},
				(e) => {
					toast.error(
						gqlHelper.getError(e) ||
								"Error adding member to the team.",
						{ autoClose: 2000, closeButton: false }
					)
				},
				() => {
					toast.success(<div>Member added successfully</div>, {
						autoClose: 2000,
						closeButton: false,
					})
					closeModal()
				}
			)
		},
	})
	return (
		<Modal
			className={modal.root}
			overlayClassName={modal.overlay}
			htmlOpenClassName="ReactModal__Html--open"
			isOpen={showModal}
			shouldCloseOnOverlayClick
			ariaHideApp={false}
		>
			<div
				className={`${modal.dialog} ${modal.dialogCentered} ${modal.dialogLg}`}
			>
				<div className={`${modal.content} ${modal.whiteBg}`}>
					<div className={modal.body}>
						<div className={styles.root}>
							<div className={styles.aside}>
								<Shopping className={styles.image} />
							</div>
							<div className={styles.content}>
								<h1 className={styles.title}>
									Add people to your team
								</h1>
								<p className={styles.body}>
									Bring your whole team to collaborate and unlock the
									full power of SHOPSHARE together.
								</p>
								<div className={contentCard.content}>
									<div className={form.group}>
										<label className={form.label}>Email</label>
										<div className={styles.textfield}>
											<input
												className={
													formik.errors.email &&
													formik.touched.email
														? textfield.isError
														: textfield.normal
												}
												name="email"
												type="email"
												value={formik.values.email}
												onChange={formik.handleChange}
												onBlur={formik.handleBlur}
											/>
											{formik.errors.email &&
												formik.touched.email && (
													<div className={form.validationFeedback}>
														{formik.errors.email}
													</div>
												)}
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className={`${modal.footer}`}>
						<button
							type="button"
							className={button.default}
							onClick={() => {
								closeModal()
							}}
						>
							Cancel
						</button>
						<button
							type="submit"
							className={button.primary}
							onClick={() => {
								formik.handleSubmit()
							}}
						>
							Send Invite
						</button>
					</div>
				</div>
			</div>
		</Modal>
	)
}
