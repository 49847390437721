import React, { PureComponent } from "react"
import { createPaginationContainer, graphql } from "react-relay"
import ListOfShopcasts from "components/list-of-shopcasts/list-of-shopcasts"
import { withRouter } from "../../route-helpers"

class ClientViewShopcasts extends PureComponent {
	render() {
		const { props } = this
		const { currentUser } = props.store
		return (
			<ListOfShopcasts
				enableHeader={false}
				disableSubdomain
				shopcasts={currentUser.sharedFolderShopcasts}
				relay={props.relay}
				currentUser={props.store.currentUser}
				storeId={props.store.id}
				removeInfiniteScroll={props.removeInfiniteScroll}
				clientId={props.clientId}
				breakpoints={props.breakpoints}
			/>
		)
	}
}

const query = graphql`
	query clientViewShopcastsQuery(
		$count: Int!
		$cursor: String
		$folder: String!
		$sort: String
	) {
		store {
			...clientViewShopcasts_store
				@arguments(count: $count, cursor: $cursor, folder: $folder, sort: $sort)
		}
	}
`

ClientViewShopcasts = createPaginationContainer(
	ClientViewShopcasts,
	{
		store: graphql`
			fragment clientViewShopcasts_store on Store
			@argumentDefinitions(
				count: { type: "Int", defaultValue: 12 }
				cursor: { type: "String" }
				folder: { type: "String!" }
				sort: { type: "String" }
			) {
				id
				currentUser {
					id
					...shopcastCard_currentUser
					sharedFolderShopcasts(
						first: $count
						after: $cursor
						folderId: $folder
						sort: $sort
					) @connection(key: "currentUser_sharedFolderShopcasts") {
						edges {
							node {
								...shopcastCard_shopcast
							}
						}
					}
				}
			}
		`,
	},
	{
		direction: "forward",
		getConnectionFromProps(props) {
			const { currentUser } = props.store
			return currentUser && currentUser.sharedFolderShopcasts
		},
		getFragmentVariables(prevVars, totalCount) {
			return {
				...prevVars,
				count: totalCount,
			}
		},
		getVariables(props, { count, cursor }) {
			return {
				count,
				cursor,
				folder: props.params.tab,
				sort: props.sort,
			}
		},
		query,
	}
)

export default {
	Component: withRouter(ClientViewShopcasts),
	query,
	params: (rp) => ({
		count: rp.count,
		cursor: null,
		folder: rp.params.tab,
		sort: rp.sort,
	}),
}
