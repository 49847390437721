import { commitMutation, graphql } from "react-relay"
import { complete } from "../helper"

const mutation = graphql`
	mutation renameShopcastFolderMutation($input: RenameShopcastFolderInput!) {
		renameShopcastFolder(input: $input) {
			newName
		}
	}
`

const optimisticResponse = (newName) => ({
	renameShopcastFolder: {
		newName,
	},
})

const commit = (env, folder, newName, forDisplay, success, error) =>
	commitMutation(env, {
		mutation,
		variables: { input: { folder, newName, forDisplay } },
		updater: (storeProxy, data) => {
			if (data.renameShopcastFolder) {
				const folderProxy = storeProxy.get(folder)
				folderProxy.setValue(
					data.renameShopcastFolder.newName,
					forDisplay ? "displayName" : "name"
				)
			}
		},
		optimisticResponse: optimisticResponse(newName),
		onCompleted: complete(success, error),
		onError: complete(() => {}, error),
	})

export default { commit }
