/**
 * @generated SignedSource<<2fd3cafc47f3ee4dc4e45f9fc2eca073>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Query } from 'relay-runtime';
import type { lookbookDownload_store$fragmentType } from "./lookbookDownload_store.graphql";
export type lookbookDownloadQuery$variables = {||};
export type lookbookDownloadQuery$data = {|
  +store: ?{|
    +$fragmentSpreads: lookbookDownload_store$fragmentType,
  |},
|};
export type lookbookDownloadQuery = {|
  response: lookbookDownloadQuery$data,
  variables: lookbookDownloadQuery$variables,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "lookbookDownloadQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Store",
        "kind": "LinkedField",
        "name": "store",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "lookbookDownload_store"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "ShopshareQueryRoot",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "lookbookDownloadQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Store",
        "kind": "LinkedField",
        "name": "store",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "currentUser",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "UserSocialMedia",
                "kind": "LinkedField",
                "name": "socialMedia",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "platform",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "isConnected",
                    "storageKey": null
                  },
                  (v0/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "adminAccess",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "role",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "FacebookPageObj",
                "kind": "LinkedField",
                "name": "facebookPages",
                "plural": true,
                "selections": [
                  (v0/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "name",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "category",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "imageUrl",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "accessToken",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "instagramBusinessAccount",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "246db4591e69fe2963f3c83e5917c3e0",
    "id": null,
    "metadata": {},
    "name": "lookbookDownloadQuery",
    "operationKind": "query",
    "text": "query lookbookDownloadQuery {\n  store {\n    ...lookbookDownload_store\n    id\n  }\n}\n\nfragment lookbookDownload_store on Store {\n  id\n  currentUser {\n    id\n    socialMedia {\n      platform\n      isConnected\n      id\n    }\n    adminAccess\n    role\n    facebookPages {\n      id\n      name\n      category\n      imageUrl\n      accessToken\n      instagramBusinessAccount\n    }\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "1778b1d3ee2285160f08736e72e63db1";

module.exports = ((node/*: any*/)/*: Query<
  lookbookDownloadQuery$variables,
  lookbookDownloadQuery$data,
>*/);
