import React, { useRef } from "react"
import { useDrag, useDrop } from "react-dnd"
import {
	TrashIcon,
	PlusIcon,
	CopyIcon,
	EyeClosedIcon,
	EyeOpenIcon,
} from "@radix-ui/react-icons"
import { addVersioning, isValidUrl } from "helpers/query-parameters-helper"
import { MoveableConsumer } from "routes/lookbook/moveable"
import LoadingDots from "components/loading-dots/loading-dots"
import icon from "shared/styles/components/icons.css"
import DropdownMenu from "components/dropdown-menu/dropdown-menu"
import stylesDropdown from "components/dropdown-menu/dropdown-menu-dark.css"
import styles from "./page.css"

function Page({
	url,
	no,
	boardId,
	changeBoard,
	loading,
	updateServer,
	active,
	boardsCount,
	haveChanges,
	boardShowStaus,
	enbledShowHide,
}) {
	const ref = useRef(null)
	const [, drop] = useDrop({
		accept: "board",
		collect(monitor) {
			return {
				handlerId: monitor.getHandlerId(),
			}
		},
		hover(item, monitor) {
			if (!ref.current) {
				return
			}
			const dragIndex = item.index
			const hoverIndex = no
			if (dragIndex === hoverIndex) {
				return
			}
			const clientOffset = monitor.getClientOffset()
			const hoverBoundingRect = ref.current.getBoundingClientRect()
			const hoverMiddleY =
				(hoverBoundingRect.left - hoverBoundingRect.right) / 2
			const hoverClientY = clientOffset.x - hoverBoundingRect.right
			if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
				return
			}
			if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
				return
			}
			changeBoard(dragIndex, hoverIndex)
			item.index = hoverIndex
		},
	})
	const [{ isDragging }, drag] = useDrag({
		item: { boardId, no, index: no },
		type: "board",
		collect: (monitor) => ({
			isDragging: monitor.isDragging(),
		}),
		end: () => {
			updateServer()
		},
		canDrag: () => !loading && !haveChanges,
	})
	const opacity = isDragging ? 0 : 1
	drag(drop(ref))
	return (
		<MoveableConsumer>
			{({ selectBoard, duplicateBoard, deleteBoard, showBoard }) => {
				const items = [
					{
						click: () => duplicateBoard(boardId, true),
						name: "Add Board",
						icon: <PlusIcon className={`${stylesDropdown.itemIcon}`} />,
						disabled: active && haveChanges,
					},
					{
						click: () => duplicateBoard(boardId),
						name: "Duplicate Board",
						icon: <CopyIcon className={`${stylesDropdown.itemIcon}`} />,
						disabled: active && haveChanges,
					},
					{
						click: () => deleteBoard(boardId),
						name: "Delete Board",
						icon: <TrashIcon className={`${stylesDropdown.itemIcon}`} />,
						disabled:
							boardId.indexOf("newBoard_") > -1 ||
							boardsCount === 1 ||
							(boardsCount === 2 && !enbledShowHide),
					},
					"divide",
					{
						click: () => showBoard(boardId, !boardShowStaus),
						name: boardShowStaus ? "Hide Board" : "Show Board",
						icon: boardShowStaus ? (
							<EyeOpenIcon className={`${stylesDropdown.itemIcon}`} />
						) : (
							<EyeClosedIcon className={`${stylesDropdown.itemIcon}`} />
						),
						disabled: (active && haveChanges) || !enbledShowHide,
					},
				]
				return (
					<div className={styles.root}>
						<div
							className={active ? styles.pageActive : styles.page}
							ref={ref}
							style={{
								opacity,
								cursor: loading ? "not-allowed" : "pointer",
							}}
						>
							<div className={styles.actions}>
								<DropdownMenu
									items={items}
									darkTheme
									loading={loading}
									triggerClass={styles.dropdownMenu}
									triggerClassActive={styles.dropdownMenuActive}
								/>
							</div>
							{url && !loading ? (
								<div
									onClick={() => {
										if (!loading) {
											selectBoard(boardId)
										}
									}}
									onDragStart={() => {
										if (!loading) {
											selectBoard(boardId)
										}
									}}
									role="button"
									tabIndex="0"
									className={styles.inner}
								>
									<img
										className={styles.img}
										src={
											isValidUrl(url)
												? addVersioning(url, `d=400`)
												: url
										}
										alt={`Page ${no + 1}`}
										draggable={!loading}
									/>
								</div>
							) : (
								<div className={styles.inner}>
									<LoadingDots color="var(--gray200)" />
								</div>
							)}
						</div>
						<div className={active ? styles.pageNoActive : styles.pageNo}>
							{no + 1}
							{!boardShowStaus && (
								<div
									className={styles.hiddenIcon}
									data-tooltip-id="tooltip"
									data-tooltip-content="Hidden Board"
								>
									<EyeClosedIcon className={icon.icon16} />
								</div>
							)}
						</div>
					</div>
				)
			}}
		</MoveableConsumer>
	)
}

export default Page
