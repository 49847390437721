import { commitMutation, graphql } from "react-relay"
import { complete } from "../helper.js"

const mutation = graphql`
	mutation updateLongBioMutation($input: UpdateLongBioInput!) {
		updateLongBio(input: $input) {
			store {
				id
				currentUser {
					id
				}
			}
		}
	}
`

const getConfigs = (storeId) => [
	{
		type: "FIELDS_CHANGE",
		fieldIDs: { store: storeId },
	},
]

const commit = (env, bio, storeId, onCompleted, onError) =>
	commitMutation(env, {
		mutation,
		variables: { input: { bio } },
		configs: getConfigs(storeId),
		updater: (storeProxy, data) => {
			const userProxy = storeProxy.get(
				data.updateLongBio.store.currentUser.id
			)
			if (!userProxy) return
			userProxy.setValue(bio, "longBio")
		},
		onCompleted: complete(onCompleted, onError),
		onError: (e) => complete(null, [e]),
	})

export default { commit }
