import React, { useState } from "react"
import { MixerHorizontalIcon } from "@radix-ui/react-icons"
import LookbookDropdown from "components/lookbook-dropdown/lookbook-dropdown"
import FilterDropdown from "components/lookbook-dropdown/filter-dropdown"
import DropShadowFilter from "components/lookbook-dropdown/drop-shadow-filter"
import dropdownStlyes from "./lookbook-dropdown.css"
import styles from "./konva-filters.css"

export default function KonvaFilters({
	shadowColor,
	shadowBlur,
	shadowOffset,
	shadowOpacity,
	handleShadow,
	runDebounce,
	dataTip,
}) {
	const [openedFilter, setOpenedFilter] = useState("shadow")
	return (
		<LookbookDropdown
			label={<MixerHorizontalIcon className={styles.icon} />}
			data-tooltip-id="tooltip"
			data-tooltip-content={dataTip}
			runDebounce={runDebounce}
		>
			<div className={dropdownStlyes.panelFilters}>
				<div className={dropdownStlyes.scroll}>
					<FilterDropdown
						title="shadow"
						isOpen={openedFilter === "shadow"}
						openFilter={() =>
							setOpenedFilter(
								openedFilter === "shadow" ? null : "shadow"
							)
						}
					>
						<DropShadowFilter
							shadowColor={shadowColor}
							shadowBlur={shadowBlur}
							shadowOffset={shadowOffset}
							shadowOpacity={shadowOpacity}
							handleShadow={handleShadow}
						/>
					</FilterDropdown>
				</div>
			</div>
		</LookbookDropdown>
	)
}
