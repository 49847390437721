import React, { Fragment, useState } from "react"
import buttons from "shared/styles/components/buttons.css"
import ConformationPrompt from "../modals/conformation-prompt/conformation-prompt"
import styles from "./payout-button.css"

export default function PayoutButton({ disabled, confirmPayout, tableData }) {
	const [showPayoutModal, setShowPayoutModal] = useState(false)
	return (
		<Fragment>
			<button
				type="button"
				className={buttons.primary}
				disabled={disabled}
				onClick={() => {
					setShowPayoutModal(true)
				}}
			>
				Payout
			</button>
			<ConformationPrompt
				openModal={!!showPayoutModal}
				closeModal={() => {
					setShowPayoutModal(false)
				}}
				approveEvent={() => {
					confirmPayout()
					setShowPayoutModal(false)
				}}
				declineEvent={() => {
					setShowPayoutModal(false)
				}}
				approveText="Pay"
				declineText="Cancel"
				approveButtonStyle={buttons.primary}
				title="Confirm Payout 💸"
			>
				<div className={styles.table}>
					<div className={styles.thead}>
						<div className={styles.tr}>
							{tableData.headings.map((i) => (
								<div key={i} className={styles.th}>
									{i}
								</div>
							))}
						</div>
					</div>
					<div className={styles.tbody}>
						{tableData.rows.map((row) => (
							<div key={`${row[0]}_${row[1]}`} className={styles.tr}>
								{row.map((value, index) => (
									<div key={value} className={index > 0 ? styles.td : styles.tdTh}>
										{value}
									</div>
								))}
							</div>
						))}
					</div>
					<div className={styles.tfoot}>
						<div className={styles.tr}>
							<div className={styles.td}>Total</div>
							{tableData.headings.length > 2 &&
								new Array(tableData.headings.length - 2).fill(
									<div className={styles.td} />
								)}
							<div className={styles.td}>${tableData.total}</div>
						</div>
					</div>
				</div>
			</ConformationPrompt>
		</Fragment>
	)
}
