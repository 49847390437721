import { commitMutation, graphql } from "react-relay"

const mutation = graphql`
	mutation trackProductViewMutation($input: TrackProductViewInput!) {
		trackProductView(input: $input) {
			store {
				id
			}
		}
	}
`

const commit = (environment, productId) =>
	commitMutation(environment, {
		mutation,
		variables: { input: { productId } },
	})

export default { commit }
