/**
 * @generated SignedSource<<b85e36896b6473f9aab3aff8343fd654>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Mutation } from 'relay-runtime';
export type CreateProductForCollectionInput = {|
  clientMutationId?: ?string,
  collectionIds?: ?$ReadOnlyArray<?string>,
  stylist?: ?string,
  teamId?: ?string,
  url: string,
|};
export type createProductForCollectionMutation$variables = {|
  input: CreateProductForCollectionInput,
|};
export type createProductForCollectionMutation$data = {|
  +createProductForCollection: ?{|
    +collections: ?$ReadOnlyArray<?{|
      +id: string,
      +title: ?string,
    |}>,
    +product: ?{|
      +affiliated_url: ?string,
      +brand: ?string,
      +custom_affiliated_url: ?string,
      +description: ?string,
      +diffbotImageUrl: ?string,
      +error: ?string,
      +hasProductImagesFromDiffbot: ?boolean,
      +id: string,
      +imageUrl: ?string,
      +offerPrice: ?string,
      +productCollections: ?$ReadOnlyArray<?{|
        +id: string,
        +title: ?string,
      |}>,
      +productImages: ?$ReadOnlyArray<?string>,
      +regularPrice: ?string,
      +title: ?string,
      +url: ?string,
      +user: ?{|
        +id: string,
      |},
    |},
    +store: ?{|
      +id: string,
    |},
  |},
|};
export type createProductForCollectionMutation = {|
  response: createProductForCollectionMutation$data,
  variables: createProductForCollectionMutation$variables,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  (v1/*: any*/)
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v4 = [
  (v1/*: any*/),
  (v3/*: any*/)
],
v5 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "CreateProductForCollectionPayload",
    "kind": "LinkedField",
    "name": "createProductForCollection",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Store",
        "kind": "LinkedField",
        "name": "store",
        "plural": false,
        "selections": (v2/*: any*/),
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "ShopcastProduct",
        "kind": "LinkedField",
        "name": "product",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "brand",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "description",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "url",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "affiliated_url",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "custom_affiliated_url",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "imageUrl",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "diffbotImageUrl",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "regularPrice",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "offerPrice",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "error",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "productImages",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "hasProductImagesFromDiffbot",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ProductCollection",
            "kind": "LinkedField",
            "name": "productCollections",
            "plural": true,
            "selections": (v4/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": (v2/*: any*/),
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "ProductCollection",
        "kind": "LinkedField",
        "name": "collections",
        "plural": true,
        "selections": (v4/*: any*/),
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "createProductForCollectionMutation",
    "selections": (v5/*: any*/),
    "type": "ShopshareMutationRoot",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "createProductForCollectionMutation",
    "selections": (v5/*: any*/)
  },
  "params": {
    "cacheID": "6b735b85835ac916a6227afde2949788",
    "id": null,
    "metadata": {},
    "name": "createProductForCollectionMutation",
    "operationKind": "mutation",
    "text": "mutation createProductForCollectionMutation(\n  $input: CreateProductForCollectionInput!\n) {\n  createProductForCollection(input: $input) {\n    store {\n      id\n    }\n    product {\n      id\n      title\n      brand\n      description\n      url\n      affiliated_url\n      custom_affiliated_url\n      imageUrl\n      diffbotImageUrl\n      regularPrice\n      offerPrice\n      error\n      productImages\n      hasProductImagesFromDiffbot\n      productCollections {\n        id\n        title\n      }\n      user {\n        id\n      }\n    }\n    collections {\n      id\n      title\n    }\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "d268741d314a5f45e538f6c0090ab3ca";

module.exports = ((node/*: any*/)/*: Mutation<
  createProductForCollectionMutation$variables,
  createProductForCollectionMutation$data,
>*/);
