import React, { Component } from "react"
import { Helmet } from "react-helmet"
import { createFragmentContainer, graphql } from "react-relay"
import FontFaceObserver from "fontfaceobserver"
import layout from "shared/styles/components/layout.css"
import LandingActions from "components/landing-actions/landing-actions"
import nav from "shared/styles/components/nav.css"
import landing from "shared/styles/layout/landing.css"
import ListOfRecommendedItems from "components/list-of-recommended-items/list-of-recommended-items.jsx"
import ListOfLatestShopcasts from "components/list-of-latest-shopcasts/list-of-latest-shopcasts"
import ListofLatestLookbooks from "components/list-of-latest-lookbooks/list-of-latest-lookbooks"
import PageFooter from "components/page-footer/page-footer"
// import TrendingProducts from "./trending-products/trending-products"
// import SectionHeader from "components/section-header/section-header"
import styles from "./featured.css"
import { getComponent, withRouter } from "../../route-helpers.jsx"

class Featured extends Component {
	constructor(props) {
		super(props)
		this.state = {
			currentTab: "featured",
			currentPage: this.getCurrentPage(props).main,
			tabStyles: {},
		}
		this.tabsRef = {}
		this.activateTab = this.activateTab.bind(this)
		this.getActiveTabClass = this.getActiveTabClass.bind(this)
	}

	componentDidMount() {
		const font = new FontFaceObserver("Mona Sans")
		font.load().then(() => {
			this.setTabStyle()
		})
	}

	componentDidUpdate(prevProps) {
		const { params } = this.props
		const { currentTab } = this.state
		if (prevProps.params.type !== params.type)
			this.setState({
				currentPage: this.getCurrentPage(this.props).main,
				currentTab:
					currentTab !== "featured"
						? this.getCurrentPage(this.props).sub
						: "featured",
			})
	}

	setTabStyle() {
		const { currentTab } = this.state
		this.setState({
			tabStyles: {
				width: this.tabsRef[currentTab]?.offsetWidth || 0,
				transform: `translateX(${
					this.tabsRef[currentTab]?.offsetLeft || 0
				}px)`,
			},
		})
	}

	getCurrentPage(props) {
		const type = props.params.type.replace("/")
		switch (type) {
			case "shopcast":
				return { main: "shopcast", sub: "shopcasts" }
			case "shopboard":
				return { main: "lookbook", sub: "lookbooks" }
			default:
				return { main: "" }
		}
	}

	getActiveTabClass(tabName) {
		const { currentTab } = this.state
		if (currentTab === tabName) {
			return nav.tabActive
		}
		return nav.tab
	}

	activateTab(e, currentTab) {
		e.preventDefault()
		this.setState({
			currentTab,
			tabStyles: {
				width: this.tabsRef[currentTab]?.offsetWidth || 0,
				transform: `translateX(${
					this.tabsRef[currentTab]?.offsetLeft || 0
				}px)`,
			},
		})
	}

	renderRecommendedAll() {
		const { currentPage } = this.state

		const ListOfRecommendedItemsComp = getComponent(
			`list-of-recommended-items`,
			ListOfRecommendedItems,
			{ count: 8, type: currentPage },
			{ count: 8, type: currentPage }
		)

		return (
			<div className={styles.content}>
				<div className={styles.recommended}>
					{ListOfRecommendedItemsComp}
				</div>
			</div>
		)
	}

	renderLatestShopcasts() {
		const ListOfLatestShopcastsComp = getComponent(
			`list-of-latest-shopcasts`,
			ListOfLatestShopcasts,
			{ count: 8 },
			{ count: 8 }
		)

		return (
			<div className={styles.content}>
				<div className={styles.recommended}>
					{ListOfLatestShopcastsComp}
				</div>
			</div>
		)
	}

	renderLatestLookbooks() {
		const ListOfLatestLookbooksComp = getComponent(
			`list-of-latest-lookbooks`,
			ListofLatestLookbooks,
			{ count: 8 },
			{ count: 8 }
		)
		return (
			<div className={styles.content}>
				<div className={styles.recommended}>
					{ListOfLatestLookbooksComp}
				</div>
			</div>
		)
	}

	renderShopcasts() {
		const { currentTab } = this.state
		switch (currentTab) {
			case "featured":
				return this.renderRecommendedAll()
			case "shopcasts":
				return this.renderLatestShopcasts()
			case "lookbooks":
				return this.renderLatestLookbooks()
			default:
				return this.renderRecommendedShopcasts()
		}
	}

	render() {
		const { store } = this.props
		const { currentPage, tabStyles } = this.state
		const user = store.currentUser
		const isAdmin = user && user.adminAccess === "HAS"
		return (
			<div className={`${layout.containerWidth5} ${layout.offset}`}>
				<Helmet>
					{currentPage === "shopcast" && (
						<title>Explore videos on ShopShareTV</title>
					)}
					{currentPage === "lookbook" && (
						<title>Explore moodboards on ShopShareTV</title>
					)}
				</Helmet>

				<section className={landing.section}>
					<div className={landing.content}>
						{currentPage === "shopcast" && (
							<h1 className={styles.displayTitle}>Shopcasts</h1>
						)}
						{currentPage === "lookbook" && (
							<h1 className={styles.displayTitle}>Shopboards</h1>
						)}

						{currentPage === "shopcast" && (
							<p className={landing.displayLead}>
								Watch featured videos curated by the ShopShare
								community.
							</p>
						)}
						{currentPage === "lookbook" && (
							<p className={landing.displayLead}>
								Explore featured moodboards curated by the ShopShare
								community.
							</p>
						)}
						{!user && (
							<div className={landing.actions}>
								<LandingActions />
							</div>
						)}
					</div>
				</section>

				{isAdmin && (
					<div className={`${nav.rootHasBorder} ${nav.rootSticky}`}>
						<nav className={nav.tabs}>
							<div className={nav.tabsFit}>
								<a
									role="button"
									tabIndex="0"
									className={this.getActiveTabClass("featured")}
									onKeyDown={(e) => this.activateTab(e, "featured")}
									onClick={(e) => this.activateTab(e, "featured")}
									ref={(ref) => {
										this.tabsRef.featured = ref
									}}
								>
									Featured
								</a>
								{currentPage === "shopcast" && (
									<a
										role="button"
										tabIndex="0"
										className={this.getActiveTabClass("shopcasts")}
										onKeyDown={(e) =>
											this.activateTab(e, "shopcasts")
										}
										onClick={(e) => this.activateTab(e, "shopcasts")}
										ref={(ref) => {
											this.tabsRef.shopcasts = ref
										}}
									>
										Latest
									</a>
								)}
								{currentPage === "lookbook" && (
									<a
										role="button"
										tabIndex="0"
										className={this.getActiveTabClass("lookbooks")}
										onKeyDown={(e) =>
											this.activateTab(e, "lookbooks")
										}
										onClick={(e) => this.activateTab(e, "lookbooks")}
										ref={(ref) => {
											this.tabsRef.lookbooks = ref
										}}
									>
										Latest
									</a>
								)}
								{/* {bestCategories.edges.map((c) => (
								<Link to={`/explore/${c.node.name.toLowerCase()}`} className={nav.tab} key={c.node.id}>
									{c.node.name}
								</Link>
							))} */}
								<span className={nav.tabUnderline} style={tabStyles} />
							</div>
						</nav>
					</div>
				)}
				{this.renderShopcasts()}

				{!user && (
					<section className={landing.section}>
						<div className={landing.contentPageFooter}>
							<PageFooter
								title="Use ShopShare for Virtual Styling"
								lead="Wow your clients and stand out with ShopShare Videos."
								currentUser={user}
							/>
						</div>
					</section>
				)}
			</div>
		)
	}
}

Featured = createFragmentContainer(Featured, {
	store: graphql`
		fragment featured_store on Store {
			id
			bestCategories(orderBy: "shopcastCount") {
				edges {
					node {
						name
						id
					}
				}
			}
			currentUser {
				id
				adminAccess
				hasPendingSharerRequests
				isASharer
				isANewUser
			}
		}
	`,
})

const query = graphql`
	query featuredQuery {
		store {
			...featured_store
		}
	}
`

export default { Component: withRouter(Featured), query }
