import React from "react"
import { PopupButton } from "react-calendly"
import styles from "./schedule-a-demo.css"

export default function ScheduleADemo({ theme }) {
	return (
		<PopupButton
			url="https://calendly.com/shopshare"
			className={theme === "dark" ? styles.demoDark : styles.demo}
			rootElement={document.getElementById("app")}
			text="Schedule a Demo"
			pageSettings={{
				backgroundColor: "FFFFFF",
				hideEventTypeDetails: false,
				hideLandingPageDetails: false,
				primaryColor: "7859F8",
				textColor: "212121",
			}}
		/>
	)
}
