import React, { Component } from "react"
import { createFragmentContainer, graphql } from "react-relay"
import { Link } from "react-router-dom"
import { toast } from "react-toastify"
import SaveShopcastReportAdminNotesMutation from "mutations/admin/save-shopcast-report-admin-notes"
import FlagShopcastMutation from "mutations/admin/flag-shopcast"
import RemoveShopcastFlagMutation from "mutations/admin/remove-shopcast-flag"
import RemoveShopcastReportMutation from "mutations/admin/remove-shopcast-report"
import dayjs from "../../../helpers/dayjs-helper"
import styles from "./admin-user-report-item.css"
import buttons from "../../../shared/styles/components/buttons.css"
import Avatar from "../../profile/avatar/avatar.jsx"
import EditShopcastUserReportAdminNotes from "../edit-shopcast-user-report-admin-notes-toast/edit-shopcast-user-report-admin-notes-toast.jsx"

class AdminUserReportItem extends Component {
	constructor(props) {
		super(props)
		this.saveNotes = this.saveNotes.bind(this)
		this.handleFlagShopcastClick = this.handleFlagShopcastClick.bind(this)
		this.handleNoteEditButtonClick = this.handleNoteEditButtonClick.bind(this)
		this.handleRemoveFlagClick = this.handleRemoveFlagClick.bind(this)
		this.handleDeleteReportClick = this.handleDeleteReportClick.bind(this)

		this.state = {
			deleted: false,
		}
	}

	handleNoteEditButtonClick() {
		const { props } = this
		toast.info(
			<EditShopcastUserReportAdminNotes
				report={props.shopcastUserReport}
				relay={props.relay}
			/>,
			{ closeButton: true }
		)
	}

	handleFlagShopcastClick() {
		const { props } = this
		FlagShopcastMutation.commit(
			props.relay.environment,
			props.shopcastUserReport.id,
			props.shopcastUserReport.targetShopcast ? "shopcast" : "lookbook"
		)
	}

	handleRemoveFlagClick() {
		const { props } = this
		RemoveShopcastFlagMutation.commit(
			props.relay.environment,
			props.shopcastUserReport.id,
			props.shopcastUserReport.targetShopcast ? "shopcast" : "lookbook"
		)
	}

	handleDeleteReportClick() {
		const { props } = this
		RemoveShopcastReportMutation.commit(
			props.relay.environment,
			props.shopcastUserReport.id,
			props.adminId,
			props.shopcastUserReport.targetShopcast ? "shopcast" : "lookbook"
		)
		this.setState({ deleted: true })
	}

	saveNotes(report, note) {
		const { props } = this
		if (report && note && report.id) {
			SaveShopcastReportAdminNotesMutation.commit(
				props.relay.environment,
				report.id,
				note
			)
		}
	}

	render() {
		const { props, state } = this
		if (state.deleted) {
			return null
		}
		const report = props.shopcastUserReport
		const createdAtDayjs = dayjs(+report.createdAt)

		const target = report.targetShopcast || report.targetLookbook

		return (
			<div className={styles.root} key={report.id}>
				<div className={styles.header}>
					<div className={styles.avatar}>
						<Avatar
							size="40px"
							photoUrl={
								report.createdBy ? report.createdBy.profilePhoto : null
							}
							fullname={
								report.createdBy
									? report.createdBy.fullname
									: "Anonymous"
							}
						/>
					</div>
					<div className={styles.content}>
						<p className={styles.reporter}>
							{report.createdBy ? (
								<Link to={`/channel/${report.createdBy.username}`}>
									{report.createdBy.fullname}
								</Link>
							) : (
								<a>Anonymous</a>
							)}
							<time
								className={styles.timestamp}
								title={createdAtDayjs.format("YYYY/MM/DD HH:mm")}
								dateTime={createdAtDayjs.format()}
							>
								{createdAtDayjs.fromNow()}
							</time>
						</p>
						<p className={styles.reportType}>
							reported this{" "}
							{report.targetShopcast ? "video" : "shopboard"}:{" "}
							<span className={styles.type}>{report.reportType}</span>
						</p>
						<div
							className={
								report.status === "flagged"
									? styles.videoFlagged
									: styles.video
							}
						>
							<div className={styles.owner}>
								<div className={styles.ownerAvatar}>
									<Avatar
										size="24px"
										photoUrl={target.user.profilePhoto}
										fullname={target.user.fullname}
									/>
								</div>
								<p className={styles.username}>
									<Link to={`/channel/${target.user.username}`}>
										@{target.user.username}
									</Link>
								</p>
							</div>
							<p className={styles.shopcastTitle}>
								<Link to={report.targetShopcast ? `/shopcast/${target.id}` : `/shopboard/${target.id}`}>
									{target.title}
								</Link>
							</p>
							<Link to={report.targetShopcast ? `/shopcast/${target.id}` : `/shopboard/${target.id}`}>
								<img
									src={`${target.posterUrl || target.url}`}
									alt="Poster"
									className={styles.poster}
								/>
							</Link>
							<div className={styles.actions}>
								{report.status === "flagged" && (
									<button
										type="button"
										className={buttons.default}
										onClick={this.handleRemoveFlagClick}
									>
										Remove
									</button>
								)}
								{report.status === "pending" && (
									<button
										type="button"
										className={buttons.primaryRed}
										onClick={this.handleFlagShopcastClick}
									>
										Flag
									</button>
								)}
								{target && report.status === "pending" && (
									<button
										type="button"
										className={buttons.default}
										onClick={this.handleDeleteReportClick}
									>
										Ignore
									</button>
								)}
							</div>
						</div>
						{report.description && (
							<blockquote className={styles.message}>
								<q>{report.description}</q>
							</blockquote>
						)}
					</div>
				</div>
			</div>
		)
	}
}

AdminUserReportItem = createFragmentContainer(AdminUserReportItem, {
	shopcastUserReport: graphql`
		fragment adminUserReportItem_shopcastUserReport on UnionReportType {
			... on ShopcastUserReport {
				id
				createdBy {
					fullname
					username
					profilePhoto
				}
				targetShopcast {
					title
					id
					posterUrl
					user {
						id
						fullname
						username
						profilePhoto
					}
				}
				description
				reportType
				createdAt
				status
				adminNotes
			}
			... on LookbookUserReport {
				id
				createdBy {
					fullname
					username
					profilePhoto
				}
				targetLookbook {
					title
					id
					url
					user {
						id
						fullname
						username
						profilePhoto
					}
				}
				description
				reportType
				createdAt
				status
				adminNotes
			}
		}
	`,
})

export default AdminUserReportItem
