import React from "react"
// import { DashboardIcon, HamburgerMenuIcon } from "@radix-ui/react-icons"
import { createFragmentContainer, graphql } from "react-relay"
import { toast } from "react-toastify"
import { FeatureKeyENUM } from "services/plan-services"
import pageHeader from "shared/styles/layout/page-header.css"
// import masthead from "shared/styles/components/masthead.css"
import section from "shared/styles/layout/section.css"
// import icons from "shared/styles/components/icons.css"
import textfield from "shared/styles/components/textfield.css"
import buttons from "shared/styles/components/buttons.css"
import controls from "shared/styles/layout/controls.css"
import SearchInputComponent from "components/search-input/search-input"
import CreateEditClient from "components/modals/create-edit-client/create-edit-client"
import ConformationPrompt from "components/modals/conformation-prompt/conformation-prompt"
import { debounce } from "helpers/ui-helper"
import deleteClientMutation from "mutations/client/delete-client"
import gqlHelper from "helpers/gql-helper"
import sendClientInviteMutation from "mutations/client/send-client-invite.js"
import ClientList from "./client-list"
import styles from "./client.css"
import { getComponent } from "../../route-helpers"

class Clients extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			search: "",
			selectedClient: null,
			deleteSelectedClient: null,
			currentCount: 0,
		}
		this.setKeywordBounce = debounce((val) => {
			this.setState({ search: val })
		}, 500)
		this.setCurrentCount = this.setCurrentCount.bind(this)
		this.editClient = this.editClient.bind(this)
		this.deleteClient = this.deleteClient.bind(this)
		this.sendInvite = this.sendInvite.bind(this)
	}

	setCurrentCount(count) {
		this.setState({ currentCount: count })
	}

	editClient(client) {
		this.setState({ selectedClient: client })
	}

	deleteClient(client) {
		this.setState({ deleteSelectedClient: client })
	}

	sendInvite(client) {
		const { relay } = this.props
		sendClientInviteMutation.commit(
			relay.environment,
			client.id,
			() => {
				toast.success(<>Invite sent successfully</>, {
					autoClose: 2000,
					closeButton: false,
				})
				this.setState({ deleteSelectedClient: null })
			},
			(e) => {
				toast.error(<>{gqlHelper.getError(e)}</>, {
					autoClose: 5000,
					closeButton: false,
				})
			}
		)
	}

	renderClientRecords() {
		const { search } = this.state
		const ClientListComp = getComponent(
			`client-list`,
			ClientList,
			{
				count: 12,
				search,
			},
			{
				count: 12,
				search,
				setCurrentCount: this.setCurrentCount,
				editClient: this.editClient,
				deleteClient: this.deleteClient,
				sendInvite: this.sendInvite,
			}
		)
		return ClientListComp
	}

	render() {
		const { relay, store } = this.props
		const {
			selectedClient,
			deleteSelectedClient,
			search,
			currentCount,
		} = this.state
		const noItems = !search && currentCount === 0
		return (
			<div className={styles.root}>
				<div className={styles.main}>
					{/* <div className={masthead.root}>
					<div className={masthead.inner}>
						<h1 className={masthead.title}>Clients</h1>
						<div className={masthead.lead}>
							<button
								type="button"
								className={buttons.primary}
								onClick={() => {
									this.setState({ selectedClient: {} })
								}}
							>
								New Client
							</button>
						</div>
					</div>
				</div> */}
					<div className={pageHeader.root}>
						<div className={pageHeader.content}>
							<h2 className={pageHeader.title}>Clients</h2>
							<div className={pageHeader.actions}>
								<button
									type="button"
									className={buttons.primary}
									onClick={() => {
										this.setState({ selectedClient: {} })
									}}
								>
									New Client
								</button>
							</div>
						</div>
					</div>
					{!noItems && (
						<div className={controls.root}>
							<div className={controls.search}>
								<SearchInputComponent
									placeholder="Search"
									setSearchText={(value) =>
										this.setKeywordBounce(value)
									}
								/>
							</div>
							<div className={controls.filters}>
								<div className={controls.group}>
									<select className={textfield.normal}>
										<optgroup label="Sort by">
											<option value="n-to-o">
												Newest to Oldest
											</option>
											<option value="o-to-n">
												Oldest to Newest
											</option>
											<option value="name">
												Alphabetical (A-Z)
											</option>
											<option value="name-d">
												Alphabetical (Z-A)
											</option>
										</optgroup>
									</select>
								</div>
								{/* <div className={controls.group}>
									<div className={buttons.group}>
										<button
											className={`${buttons.defaultIconActive} ${buttons.iconLg}`}
											data-tooltip-id="tooltip"
											data-tooltip-content="Grid View"
											type="button"
										>
											<DashboardIcon className={icons.icon21} />
										</button>
										<button
											className={`${buttons.defaultIcon} ${buttons.iconLg}`}
											data-tooltip-id="tooltip"
											data-tooltip-content="list View"
											type="button"
										>
											<HamburgerMenuIcon className={icons.icon21} />
										</button>
									</div>
								</div> */}
							</div>
						</div>
					)}
					<div className={section.root}>{this.renderClientRecords()}</div>
					{selectedClient && (
						<CreateEditClient
							relay={relay}
							client={selectedClient}
							closeModal={() => {
								this.setState({ selectedClient: null })
							}}
							folders={store.currentUser.shopcastContainer.folder.folders.edges.map(
								({ node }) => ({
									id: node.id,
									name: node.name,
									avatar: node.avatar,
								})
							)}
							other={{ search }}
						/>
					)}
					<ConformationPrompt
						openModal={!!deleteSelectedClient}
						closeModal={() =>
							this.setState({ deleteSelectedClient: null })
						}
						approveEvent={() => {
							const { search: deleteSearch } = this.state
							deleteClientMutation.commit(
								relay.environment,
								deleteSelectedClient.id,
								(e) => {
									toast.info(<>{gqlHelper.getError(e)}</>, {
										autoClose: 5000,
										closeButton: false,
									})
								},
								() => {
									toast.success(<>Client deleted successfully</>, {
										autoClose: 2000,
										closeButton: false,
									})
									this.setState({ deleteSelectedClient: null })
								},
								{ search: deleteSearch }
							)
						}}
						declineEvent={() =>
							this.setState({ deleteSelectedClient: null })
						}
						title="Delete Client"
						approveText="Delete"
						approveButtonStyle={buttons.primaryRed}
						declineText="Cancel"
					>
						Are you sure? Deleting this client is a permanent action that
						cannot be undone.
					</ConformationPrompt>
				</div>
			</div>
		)
	}
}

Clients = createFragmentContainer(Clients, {
	store: graphql`
		fragment clients_store on Store {
			id
			currentUser {
				id
				shopcastContainer {
					folder(id: "") {
						id
						name
						folders {
							edges {
								node {
									id
									name
									avatar
								}
							}
						}
					}
				}
			}
		}
	`,
})

const clientsQuery = graphql`
	query clientsQuery {
		store {
			...clients_store
		}
	}
`

export default {
	Component: Clients,
	query: clientsQuery,
	featureKey: FeatureKeyENUM.CLIENT_CRM,
}
