import React, { Component } from "react"
import DropdownMenu from "components/dropdown-menu/dropdown-menu.jsx"
import Avatar from "components/profile/avatar/avatar"
import ArrowBackIcon from "shared/assets/back-icon.svg"
import styles from "./mobile-overlay.css"

class MobileOverlay extends Component {
	renderActionMenu(triggerClass, triggerClassActive) {
		const { actions } = this.props
		return actions && actions.length ? (
			<DropdownMenu
				items={actions}
				darkTheme
				contentRight
				triggerClass={triggerClass}
				triggerClassActive={triggerClassActive}
			/>
		) : null
	}

	renderHeader() {
		const { topButtons, backOnClick, cssClasses } = this.props
		return (
			<div className={cssClasses.header || styles.header}>
				<button
					className={cssClasses.back || styles.back}
					type="button"
					onClick={() => backOnClick()}
				>
					<ArrowBackIcon className={styles.backIcon} />
				</button>
				{topButtons}
				{this.renderActionMenu(
					cssClasses.dropdownMenu || styles.menu,
					cssClasses.dropdownMenuActive || styles.menuActive
				)}
			</div>
		)
	}

	renderMain() {
		const { item, bottomButtons, cssClasses } = this.props

		return (
			<div className={cssClasses.main || styles.main}>
				<div className={cssClasses.mainInner || styles.mainInner}>
					{item && (
						<div className={styles.metadata}>
							<h3 className={styles.title}>{item.title}</h3>
							<a
								className={cssClasses.channel || styles.channel}
								href={`https://${item.user.domain}`}
							>
								<Avatar
									styleName={styles.channelAvatar}
									size="24px"
									imageSize="100"
									photoUrl={item.user.profilePhoto}
									fullname={item.user.fullname}
								/>
								<h4
									className={
										cssClasses.channelTitle || styles.channelTitle
									}
								>
									{item.user.fullname}
								</h4>
							</a>
						</div>
					)}
					<div className={styles.actions}>
						{bottomButtons.map((button) => (
							<div className={styles.actionItem} key={button.key}>
								<label className={styles.actionItemWithLabel}>
									<button
										type="button"
										className={
											button.actionItemClass ||
											styles.actionItemButtonPrimary
										}
										onClick={() => button.onClick()}
									>
										{button.icon}
									</button>
									{button.label && (
										<div className={styles.actionItemLabel}>
											{button.label}
										</div>
									)}
								</label>
							</div>
						))}
					</div>
				</div>
			</div>
		)
	}

	render() {
		return (
			<>
				{this.renderHeader()}
				{this.renderMain()}
			</>
		)
	}
}

export default MobileOverlay
