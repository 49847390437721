import React, { Component, Fragment } from "react"
import { createFragmentContainer, fetchQuery, graphql } from "react-relay"
import { Link } from "react-router-dom"
import { Helmet } from "react-helmet"
import queryString from "query-string"
// import ListOfFaqs from "components/list-of-faqs/list-of-faqs"
import layout from "shared/styles/components/layout.css"
import landing from "shared/styles/layout/landing.css"
import buttons from "shared/styles/components/buttons.css"
// import CheckIcon from "shared/assets/tick-icon.svg"
// import InfoIcon from "shared/assets/info-icon.svg"
// import ManageLink from "components/manage-link/manage-link"
import ListOfPlans from "../../components/list-of-plans/list-of-plans"

import trackingService from "../../services/tracking-service"
import styles from "./pricing.css"
import { trialPeriod } from "../../constants"
import cookieService from "../../services/cookie-service"

const couponQuery = graphql`
	query pricingCouponQuery($code: String!) {
		store {
			id
			coupon(code: $code) {
				id
				amountOff
				percentOff
				name
				products
			}
		}
	}
`
const referralQuery = graphql`
	query pricingReferralQuery($code: String!) {
		store {
			id
			referral(code: $code) {
				campaign {
					name
					monthlyCoupon
					annualCoupon
				}
			}
		}
	}
`
class Pricing extends Component {
	constructor(props) {
		super(props)
		this.state = {
			yearly: false,
			coupon: null,
		}
	}

	componentDidMount() {
		trackingService.track("Viewed Pricing", {})
		this.getCouponData()
	}

	async getCouponData() {
		const {
			relay,
			store: { currentUser },
		} = this.props
		const { yearly } = this.state
		let referral
		const referralCookie = cookieService.getRefferal()
		if (currentUser && currentUser.referredBy) {
			referral = currentUser.referredBy
		} else if (!currentUser && referralCookie) {
			const referralData = await fetchQuery(
				relay.environment,
				referralQuery,
				{
					code: referralCookie,
				}
			).toPromise()
			referral = referralData.store.referral
		}
		if (!referral) return
		const {
			campaign: { monthlyCoupon, annualCoupon },
		} = referral
		const coupon = yearly ? annualCoupon : monthlyCoupon
		if (!coupon) {
			this.setState({ coupon: null })
			return
		}
		const data = await fetchQuery(relay.environment, couponQuery, {
			code: coupon,
		}).toPromise()
		this.setState({ coupon: data.store.coupon })
	}

	pricingHeader() {
		const {
			store: { currentUser, team },
		} = this.props
		// User logout
		if (!currentUser)
			return (
				<div className={styles.masthead}>
					<div className={styles.inner}>
						<h1 className={styles.title}>Pricing</h1>
						<p className={styles.lead}>
							{trialPeriod}-day free trial. Cancel anytime.
						</p>
					</div>
				</div>
			)

		if (team && team.user.id !== currentUser.id) {
			return (
				<div className={styles.masthead}>
					<div className={styles.inner}>
						<h1 className={styles.title}>Managed Profile</h1>
						<p className={styles.lead}>
							This account ({currentUser.email}) is managed by
							{team.user.email}
						</p>
					</div>
				</div>
			)
		}
		// User has unpaid invoice
		if (currentUser.lastUnpaidInvoice)
			return (
				<div className={styles.masthead}>
					<div className={styles.inner}>
						<h1 className={styles.title}>Outstanding Invoice</h1>
						<p className={styles.lead}>
							A recent credit card payment we attempted on the card we
							have registered for you failed. Click on the &quot;Pay
							Now&quot; button to view your outstanding fee and to make a
							payment.
						</p>
					</div>
				</div>
			)
		// User trial has ended
		if (currentUser.trialUsed && !currentUser.userPlan)
			return (
				<div className={styles.masthead}>
					<div className={styles.inner}>
						<h1 className={styles.title}>Trial Expired</h1>
					</div>
				</div>
			)
		// User that has a plan
		if (currentUser.trialUsed && currentUser.userPlan)
			return (
				<div className={styles.masthead}>
					<div className={styles.inner}>
						<h1 className={styles.title}>Choose your plan</h1>
						<p className={styles.lead}>
							Switch plans or cancel anytime in{" "}
							<Link className={styles.link} to="/settings/billing">
								settings.
							</Link>
						</p>
					</div>
				</div>
			)
		// User just signed up
		return (
			<div className={styles.masthead}>
				<div className={styles.inner}>
					<h1 className={styles.title}>Choose your plan</h1>
					<p className={styles.lead}>
						{trialPeriod}-day free trial. Cancel anytime.
					</p>
				</div>
			</div>
		)
	}

	render() {
		const { store, relay } = this.props
		const { yearly, coupon } = this.state
		const { pricingPlans: plans, currentUser, team } = store
		if (team && team.user.id !== currentUser.id)
			return (
				<div className={`${layout.containerWidth5} ${layout.offset}`}>
					<Helmet>
						<title>ShopShareTV Pricing</title>
						<body className="Pricing" />
					</Helmet>
					{this.pricingHeader()}
				</div>
			)
		return (
			<div className={`${layout.containerWidth5} ${layout.offset}`}>
				<Helmet>
					<title>ShopShareTV Pricing</title>
					<body className="Pricing" />
				</Helmet>
				{this.pricingHeader()}
				<section className={styles.section}>
					<div className={landing.contentNoTopPadding}>
						{(!currentUser || !currentUser.lastUnpaidInvoice) && (
							<Fragment>
								<div className={styles.frequency}>
									<div className={styles.upgradeToggle}>
										<label className={styles.label}>
											<input
												className={styles.input}
												type="checkbox"
												checked={yearly}
												onChange={(e) => {
													this.setState(
														{
															yearly: e.target.checked,
														},
														() => {
															this.getCouponData()
														}
													)
												}}
											/>
											<span
												className={styles.slider}
												data-billed-monthly="Monthly"
												data-billed-yearly="Yearly"
											>
												<span>Monthly</span>
												<span>Yearly</span>
											</span>
										</label>
									</div>
									<p className={styles.offer}>
										<span className={styles.savings}>Save 20%</span>
									</p>
								</div>
								<div className={styles.plans}>
									<ListOfPlans
										relay={relay}
										plans={plans}
										yearly={yearly}
										coupon={coupon}
										currentUser={currentUser}
									/>
								</div>
							</Fragment>
						)}
						{currentUser && !!currentUser.lastUnpaidInvoice && (
							<div className={styles.cta}>
								{/* <ManageLink
									relay={relay}
									type="link"
									label="Manage Billing"
								/> */}
								<a
									className={buttons.primary}
									href={currentUser.lastUnpaidInvoice}
								>
									Pay Now
								</a>
							</div>
						)}
						{/* {(!currentUser || !currentUser.lastUnpaidInvoice) && (
							<div className={styles.enterprise}>
								<h4 className={styles.planTitle}>Enterprise</h4>
								<p className={styles.planDescription}>
									Tech Customisation and White-Label solution
									available.
								</p>
								<a
									className={buttons.default}
									href="mailto:support@shopshare.tv?subject=SHOPSHARE – Enterprise Pricing Enquiry"
								>
									Talk to Sales
								</a>
							</div>
						)} */}
					</div>
				</section>
				{/* <section className={landing.section}>
					<div className={landing.content}>
						<div className={landing.header}>
							<h2 className={landing.title}>
								Compare ShopShare Features
							</h2>
							<p className={landing.lead}>
								Choose the right plan for you
							</p>
						</div>
						<div className={styles.features}>
							<div className={styles.featuresHeader}>
								<div className={styles.rowLabelSpacer} />
								<div className={styles.rowThirds}>
									<div className={styles.featuresTh}>Starter</div>
									<div className={styles.featuresTh}>Essential</div>
									<div className={styles.featuresTh}>Growth</div>
								</div>
							</div>
							<div className={styles.featuresSection}>
								<div className={styles.row}>
									<div className={styles.featuresHeading}>
										<h4 className={styles.featuresTitle}>
											Monetize Online Recommendations
										</h4>
									</div>
								</div>

								<div className={styles.row}>
									<div className={styles.rowLabel}>
										<div className={styles.featureLabel}>
											Unlimited Videos
											<InfoIcon
												className={styles.featuresInfo}
												data-tooltip-content="Upload your video & subtitles are automagially generated"
											/>
										</div>
									</div>
									<div className={styles.rowThirds}>
										<CheckIcon className={styles.featuresTick} />
										<CheckIcon className={styles.featuresTick} />
										<CheckIcon className={styles.featuresTick} />
									</div>
								</div>

								<div className={styles.row}>
									<div className={styles.rowLabel}>
										<div className={styles.featureLabel}>
											Video Length
											<InfoIcon
												className={styles.featuresInfo}
												data-tooltip-content="Upload your video & subtitles are automagially generated"
											/>
										</div>
									</div>
									<div className={styles.rowThirds}>
										<div>15 Mins</div>
										<div>45 Mins</div>
										<div>Unlimited</div>
									</div>
								</div>

								<div className={styles.row}>
									<div className={styles.rowLabel}>
										<div className={styles.featureLabel}>
											Video Quality
											<InfoIcon
												className={styles.featuresInfo}
												data-tooltip-content="Upload your video & subtitles are automagially generated"
											/>
										</div>
									</div>
									<div className={styles.rowThirds}>
										<div>HD Video</div>
										<div>Up to Full HD Videos</div>
										<div>Up to Full HD Videos</div>
									</div>
								</div>

								<div className={styles.row}>
									<div className={styles.rowLabel}>
										<div className={styles.featureLabel}>
											Support
											<InfoIcon
												className={styles.featuresInfo}
												data-tooltip-content="Upload your video & subtitles are automagially generated"
											/>
										</div>
									</div>
									<div className={styles.rowThirds}>
										<div>Email & Chat</div>
										<div>Email & Chat</div>
										<div>Priority</div>
									</div>
								</div>
							</div>

							<div className={styles.row}>
								<div className={styles.featuresHeading}>
									<h4 className={styles.featuresTitle}>
										Advanced features
									</h4>
								</div>
							</div>

							<div className={styles.row}>
								<div className={styles.rowLabel}>
									<div className={styles.featureLabel}>
										Earn Commission
										<InfoIcon
											className={styles.featuresInfo}
											data-tooltip-content="Upload your video & subtitles are automagially generated"
										/>
									</div>
								</div>
								<div className={styles.rowThirds}>
									<div>&nbsp;</div>
									<CheckIcon className={styles.featuresTick} />
									<CheckIcon className={styles.featuresTick} />
								</div>
							</div>

							<div className={styles.row}>
								<div className={styles.rowLabelSpacer} />
								<div className={styles.rowThirds}>
									<button className={styles.ctaOutline} type="submit">
										Sign up
									</button>
									<button className={styles.ctaSolid} type="submit">
										Sign up
									</button>
									<button className={styles.ctaOutline} type="submit">
										Sign up
									</button>
								</div>
							</div>
						</div>
					</div>
				</section>
				<section className={`${landing.section} ${landing.gray100}`}>
					<div className={landing.content}>
						<div className={landing.header}>
							<h2 className={landing.title}>
								Any doubts? We&apos;re here to help
							</h2>
						</div>
						<div className={styles.faqs}>
							<ListOfFaqs
								question="Do you offer a association discount?"
								answer={
									<Fragment>
										<p>
											We use AWS user management service to manage
											our users and their attributes. It is handled
											with secure access. However, should a user need
											our support with editing a video, the user can
											request our support to help and we can assist
											them.
										</p>
										<p>
											For further details please see our Privacy
											Policy.
										</p>
									</Fragment>
								}
							/>
							<ListOfFaqs
								question="How long can I record for?"
								answer={
									<p>For now your video file can be up to 500MB.</p>
								}
							/>
							<p className={styles.contact}>
								Still have a question about our plans?&nbsp;{" "}
								<a href="mailto:support@shopshare.tv">Get in touch</a>
							</p>
						</div>
					</div>
				</section> */}
			</div>
		)
	}
}
Pricing = createFragmentContainer(Pricing, {
	store: graphql`
		fragment pricing_store on Store
		@argumentDefinitions(
			ipCurrency: { type: "Boolean" }
			teamId: { type: "String" }
		) {
			id
			pricingPlans(ipCurrency: $ipCurrency) {
				id
				amount
				currency
				nickname
				monthly
				description
				descriptionList
				metadata {
					name
					value
				}
				product
			}
			team(id: $teamId) {
				id
				user {
					id
					fullname
					email
				}
			}
			currentUser {
				id
				fullname
				email
				userPlan {
					id
					amount
					currency
					nickname
					monthly
					descriptionList
					status
					hasSources
				}
				trialUsed
				lastUnpaidInvoice
				referredBy {
					campaign {
						name
						monthlyCoupon
						annualCoupon
					}
				}
			}
		}
	`,
})
const pricingQuery = graphql`
	query pricingQuery($ipCurrency: Boolean, $teamId: String) {
		store {
			...pricing_store @arguments(ipCurrency: $ipCurrency, teamId: $teamId)
		}
	}
`

export default {
	Component: Pricing,
	query: pricingQuery,
	params: (rp) => {
		const qs = rp.location.search ? queryString.parse(rp.location.search) : {}
		return {
			ipCurrency: !!qs.ipCurrency,
			teamId: rp.team,
		}
	},
}
