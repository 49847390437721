import React, { Component, Fragment } from "react"
import textfield from "shared/styles/components/textfield.css"
import form from "shared/styles/components/form.css"
import toggle from "shared/styles/components/toogle.css"
import { toast } from "react-toastify"
import { validateForm, debounce } from "helpers/ui-helper"
import gqlHelper from "helpers/gql-helper"
import UpdatePersonalInfoMutation from "mutations/user/update-personal-info"
import UpdateSocialLinksMutation from "mutations/user/update-social-links"
import EmojiTextArea from "components/emoji-textarea/emoji-textarea"
import styles from "./channel-edit.css"

class ChannelEdit extends Component {
	constructor(props) {
		super(props)
		this.state = {
			bioLength: (props.user.bio || "").slice(0, 160).length,
		}
		this.commitUpdatesDebounce = debounce(this.commitUpdates.bind(this), 1000)
		this.onUpdateSuccess = this.onUpdateSuccess.bind(this)
	}

	onUpdateSuccess() {
		const { reloadIframe } = this.props
		if (reloadIframe) reloadIframe()
		toast.success(<Fragment>Updated successfully.</Fragment>, {
			autoClose: 2000,
			closeButton: false,
		})
	}

	onUpdateFailure(e) {
		toast.error(
			gqlHelper.getError(e) || "Error updating account details.",
			{ autoClose: 2000, closeButton: false }
		)
	}

	commitUpdates() {
		const { props } = this
		const { user, storeId } = props
		if (!validateForm(this.formElement)) {
			return
		}
		UpdatePersonalInfoMutation.commit(
			props.relay.environment,
			user.fullname,
			this.bio?.value || user.bio || "",
			user.email,
			this.displayName?.value || user.displayName,
			user.paypalEmail || "",
			storeId,
			this.onUpdateSuccess,
			this.onUpdateFailure
		)
		const socialLinks = {}

		if (this.website) socialLinks.website = this.website.value
		if (this.instagram) socialLinks.instagramUsername = this.instagram.value
		if (this.facebook) socialLinks.facebookUrl = this.facebook.value
		if (this.twitter) socialLinks.twitterUsername = this.twitter.value
		if (this.youtube) socialLinks.youtubeUrl = this.youtube.value
		if (this.pinterest) socialLinks.pinterestUrl = this.pinterest.value
		if (this.tiktok) socialLinks.tiktokUrl = this.tiktok.value

		UpdateSocialLinksMutation.commit(
			props.relay.environment,
			user.id,
			socialLinks,
			() => {},
			this.onUpdateFailure
		)
	}

	render() {
		const { user, updateShowControls } = this.props
		const { previewConfig } = user
		const { bioLength } = this.state
		const socialLinks = user.socialLinks || {}
		return (
			<div className={styles.root}>
				<form
					ref={(ref) => {
						this.formElement = ref
					}}
					name="updatePersonalInfo"
				>
					<div className={form.section}>
						<div className={form.header}>
							<label className={toggle.checkSpaceBetween}>
								<div className={toggle.labelSm}>Show Contact Form</div>
								<div className={toggle.switchSm}>
									<input
										type="checkbox"
										checked={previewConfig.showContact}
										onChange={(e) =>
											updateShowControls(
												"showContact",
												e.target.checked
											)
										}
									/>
									<div className={toggle.sliderSm} />
								</div>
							</label>
						</div>
					</div>
					<div className={form.section} data-novalidate="true">
						<div className={form.header}>
							<label className={toggle.checkSpaceBetween}>
								<div className={toggle.labelSm}>Show Display Name</div>
								<div className={toggle.switchSm}>
									<input
										type="checkbox"
										checked={previewConfig.showDisplayName}
										onChange={(e) =>
											updateShowControls(
												"showDisplayName",
												e.target.checked
											)
										}
									/>
									<div className={toggle.sliderSm} />
								</div>
							</label>
						</div>
						{previewConfig.showDisplayName && (
							<div className={form.content}>
								<input
									className={textfield.normal}
									type="text"
									defaultValue={user.displayName || user.fullname}
									ref={(input) => {
										this.displayName = input
									}}
									onChange={this.commitUpdatesDebounce}
								/>
							</div>
						)}
					</div>

					<div className={form.section} data-novalidate="true">
						<div className={form.header}>
							<label className={toggle.checkSpaceBetween}>
								<div className={toggle.labelSm}>Show Short Bio</div>
								<div className={toggle.switchSm}>
									<input
										type="checkbox"
										checked={previewConfig.showShortBio}
										onChange={(e) =>
											updateShowControls(
												"showShortBio",
												e.target.checked
											)
										}
									/>
									<div className={toggle.sliderSm} />
								</div>
							</label>
						</div>
						{previewConfig.showShortBio && (
							<div className={form.content}>
								<EmojiTextArea
									defaultValue={(user.bio || "").slice(0, 160)}
									className={textfield.textarea}
									rows={3}
									maxLength={160}
									onChange={(e) => {
										this.setState(
											{ bioLength: e.target.value.length },
											this.commitUpdatesDebounce
										)
									}}
									ref={(input) => {
										this.bio = input
									}}
								/>
								<div className={styles.help}>
									{bioLength}/<b>160</b>
								</div>
							</div>
						)}
					</div>

					<div className={form.section}>
						<div className={form.header}>
							<label className={toggle.checkSpaceBetween}>
								<div className={toggle.labelSm}>Show Website Link</div>
								<div className={toggle.switchSm}>
									<input
										type="checkbox"
										checked={previewConfig.showWebsiteLink}
										onChange={(e) =>
											updateShowControls(
												"showWebsiteLink",
												e.target.checked
											)
										}
									/>
									<div className={toggle.sliderSm} />
								</div>
							</label>
						</div>
						{previewConfig.showWebsiteLink && (
							<div className={form.content}>
								<input
									className={textfield.normal}
									type="text"
									placeholder="http://www.example.com"
									defaultValue={socialLinks.website}
									pattern="https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)"
									ref={(input) => {
										this.website = input
									}}
									onChange={this.commitUpdatesDebounce}
								/>
								<div className={form.validationFeedback} />
							</div>
						)}
					</div>

					<div className={form.section}>
						<div className={form.header}>
							<label className={toggle.checkSpaceBetween}>
								<div className={toggle.labelSm}>
									Show Instagram Link
								</div>
								<div className={toggle.switchSm}>
									<input
										type="checkbox"
										checked={previewConfig.showInstagramLink}
										onChange={(e) =>
											updateShowControls(
												"showInstagramLink",
												e.target.checked
											)
										}
									/>
									<div className={toggle.sliderSm} />
								</div>
							</label>
						</div>
						{previewConfig.showInstagramLink && (
							<div className={form.content}>
								<div className={form.inputGroup}>
									<div className={form.inputGroupText}>@</div>
									<input
										className={textfield.normal}
										type="text"
										defaultValue={socialLinks.instagramUsername}
										pattern="^([A-Za-z0-9._](?:(?:[A-Za-z0-9._]|(?:\.(?!\.))){2,28}(?:[A-Za-z0-9._]))?)$"
										placeholder="Username"
										ref={(input) => {
											this.instagram = input
										}}
										onChange={this.commitUpdatesDebounce}
									/>
								</div>
								<div className={form.validationFeedback} />
							</div>
						)}
					</div>

					<div className={form.section}>
						<div className={form.header}>
							<label className={toggle.checkSpaceBetween}>
								<div className={toggle.labelSm}>Show Facebook Link</div>
								<div className={toggle.switchSm}>
									<input
										type="checkbox"
										checked={previewConfig.showFacebookLink}
										onChange={(e) =>
											updateShowControls(
												"showFacebookLink",
												e.target.checked
											)
										}
									/>
									<div className={toggle.sliderSm} />
								</div>
							</label>
						</div>
						{previewConfig.showFacebookLink && (
							<div className={form.content}>
								<input
									className={textfield.normal}
									type="text"
									defaultValue={socialLinks.facebookUrl}
									placeholder="https://www.facebook.com/username"
									pattern="(?:(?:http|https):\/\/)?(?:www.)?facebook.com\/(?:(?:\w\.)*#!\/)?(?:pages\/)?(?:[\w\-\.]*\/)*([\w\-\.]*)"
									ref={(input) => {
										this.facebook = input
									}}
									onChange={this.commitUpdatesDebounce}
								/>
								<div className={form.validationFeedback} />
							</div>
						)}
					</div>

					<div className={form.section}>
						<div className={form.header}>
							<label className={toggle.checkSpaceBetween}>
								<div className={toggle.labelSm}>Show Twitter Link</div>
								<div className={toggle.switchSm}>
									<input
										type="checkbox"
										checked={previewConfig.showTwitterLink}
										onChange={(e) =>
											updateShowControls(
												"showTwitterLink",
												e.target.checked
											)
										}
									/>
									<div className={toggle.sliderSm} />
								</div>
							</label>
						</div>
						{previewConfig.showTwitterLink && (
							<div className={form.content}>
								<div className={form.inputGroup}>
									<div className={form.inputGroupText}>@</div>
									<input
										className={textfield.normal}
										type="text"
										defaultValue={socialLinks.twitterUsername}
										pattern="^[a-zA-Z0-9_]{1,15}$"
										placeholder="Username"
										ref={(input) => {
											this.twitter = input
										}}
										onChange={this.commitUpdatesDebounce}
									/>
								</div>
								<div className={form.validationFeedback} />
							</div>
						)}
					</div>

					<div className={form.section}>
						<div className={form.header}>
							<label className={toggle.checkSpaceBetween}>
								<div className={toggle.labelSm}>Show YouTube Link</div>
								<div className={toggle.switchSm}>
									<input
										type="checkbox"
										checked={previewConfig.showYoutubeLink}
										onChange={(e) =>
											updateShowControls(
												"showYoutubeLink",
												e.target.checked
											)
										}
									/>
									<div className={toggle.sliderSm} />
								</div>
							</label>
						</div>
						{previewConfig.showYoutubeLink && (
							<div className={form.content}>
								<input
									className={textfield.normal}
									type="text"
									defaultValue={socialLinks.youtubeUrl}
									pattern="(?:(?:http|https):\/\/)?(?:www.)?youtube.com\/(channel\/|user\/|c\/)[a-zA-Z0-9\-_\\\/]{1,}"
									placeholder="https://www.youtube.com/channel/username"
									ref={(input) => {
										this.youtube = input
									}}
									onChange={this.commitUpdatesDebounce}
								/>
								<div className={form.validationFeedback} />
							</div>
						)}
					</div>

					<div className={form.section}>
						<div className={form.header}>
							<label className={toggle.checkSpaceBetween}>
								<div className={toggle.labelSm}>
									Show Pinterest Link
								</div>
								<div className={toggle.switchSm}>
									<input
										type="checkbox"
										checked={previewConfig.showPinterestLink}
										onChange={(e) =>
											updateShowControls(
												"showPinterestLink",
												e.target.checked
											)
										}
									/>
									<div className={toggle.sliderSm} />
								</div>
							</label>
						</div>
						{previewConfig.showPinterestLink && (
							<div className={form.content}>
								<input
									className={textfield.normal}
									type="text"
									defaultValue={socialLinks.pinterestUrl}
									pattern="(?:(?:http|https):\/\/)?(?:www.)?pinterest\.([a-zA-Z\.]*)\/(.*)?$"
									placeholder="https://www.pinterest.com/username/"
									ref={(input) => {
										this.pinterest = input
									}}
									onChange={this.commitUpdatesDebounce}
								/>
								<div className={form.validationFeedback} />
							</div>
						)}
					</div>

					<div className={form.section}>
						<div className={form.header}>
							<label className={toggle.checkSpaceBetween}>
								<div className={toggle.labelSm}>Show TikTok Link</div>
								<div className={toggle.switchSm}>
									<input
										type="checkbox"
										checked={previewConfig.showTiktokLink}
										onChange={(e) =>
											updateShowControls(
												"showTiktokLink",
												e.target.checked
											)
										}
									/>
									<div className={toggle.sliderSm} />
								</div>
							</label>
						</div>
						{previewConfig.showTiktokLink && (
							<div className={form.content}>
								<div className={form.inputGroup}>
									<div className={form.inputGroupText}>@</div>
									<input
										className={textfield.normal}
										type="text"
										defaultValue={socialLinks.tiktokUrl}
										pattern="^[a-zA-Z0-9_]{1,15}$"
										placeholder="Username"
										ref={(input) => {
											this.tiktok = input
										}}
										onChange={this.commitUpdatesDebounce}
									/>
								</div>

								<div className={form.validationFeedback} />
							</div>
						)}
					</div>
				</form>
			</div>
		)
	}
}

export default ChannelEdit
