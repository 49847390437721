import React from "react"
import { Pencil1Icon, TrashIcon } from "@radix-ui/react-icons"
import ContentEditable from "components/content-editable/content-editable"
import styles from "./upload-card.css"

export default function UploadCard({ file, deleteFile, editFile, updateFile }) {
	return (
		<div className={styles.root}>
			<div className={styles.media}>
				<div className={styles.figure}>
					<div className={styles.inner}>
						{file.tempUrl && (
							<img
								className={styles.poster}
								src={file.tempUrl}
								alt="Temporary upload"
							/>
						)}
						{file.tempIcon && (
							<div className={styles.fileIcon}>{file.tempIcon}</div>
						)}
					</div>
				</div>
				{(deleteFile || editFile) && (
					<div className={styles.menu}>
						<div className={styles.actions}>
							{deleteFile && (
								<button
									type="button"
									onClick={() => deleteFile(file)}
									className={styles.tool}
								>
									<TrashIcon className={styles.icon} />
								</button>
							)}
							{editFile && (
								<button
									type="button"
									onClick={() => editFile(file)}
									className={styles.toolEnd}
								>
									<Pencil1Icon className={styles.icon} />
								</button>
							)}
						</div>
					</div>
				)}
			</div>
			<div className={styles.content}>
				<ContentEditable
					className={styles.title}
					title={file.title}
					saveTitle={(title) => updateFile("title", title)}
					contentEditable
					setTitleFocus={(val) => updateFile("titleInFocus", val)}
				/>
			</div>
		</div>
	)
}
