import { commitMutation, graphql } from "react-relay"
import { complete } from "../helper.js"

const mutation = graphql`
	mutation updateUsernameMutation($input: UpdateUsernameInput!) {
		updateUsername(input: $input) {
			store {
				id
				currentUser {
					id
				}
			}
		}
	}
`

const getConfigs = (storeId) => [
	{
		type: "FIELDS_CHANGE",
		fieldIDs: { store: storeId },
	},
]

const commit = (env, username, storeId, onCompleted, onError) =>
	commitMutation(env, {
		mutation,
		variables: {
			input: { username },
		},
		configs: getConfigs(storeId),
		updater: (storeProxy, data) => {
			const { id: currentUserId } = data.updateUsername.store.currentUser
			const userProxy = storeProxy.get(currentUserId)
			if (!userProxy) return
			userProxy.setValue(username, "username")
		},
		onCompleted: complete(onCompleted, onError),
		onError: (e) => complete(null, [e]),
	})

export default { commit }
