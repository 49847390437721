import { commitMutation, graphql } from "react-relay"
import { ConnectionHandler } from "relay-runtime"
import { complete } from "../helper"
import connections from "../connections"

const mutation = graphql`
	mutation deleteShopcastFolderMutation($input: DeleteShopcastFolderInput!) {
		deleteShopcastFolder(input: $input) {
			starredSubFolders
		}
	}
`

const commit = (env, id, parentId, container, onError, onSuccess) =>
	commitMutation(env, {
		mutation,
		variables: { input: { id } },
		optimisticResponse: {
			deleteShopcastFolder: {
				starredSubFolders: []
			},
		},
		onCompleted: complete(onSuccess, onError),
		onError: complete(null, onError),
		updater: (storeProxy, dd) => {
			if (dd.deleteShopcastFolder) {
				const containerProxy = storeProxy.get(container)
				const parentFolder = storeProxy.get(parentId)
				parentFolder.setValue(parentFolder.getValue("foldersCount") - 1, "foldersCount")
				const itemProxy = storeProxy.get(id)
				const isInRoot = itemProxy.getValue("inRoot")
				const connection = connections.currentUser.shopcastManager.folders
				const foldersConnection = ConnectionHandler.getConnection(
					parentFolder,
					connection.name,
					{
						...connection.data,
						isShared: null,
					}
				)
				ConnectionHandler.deleteNode(foldersConnection, id)
				
				const starredConnection = ConnectionHandler.getConnection(
					containerProxy,
					connections.currentUser.shopcastManager.starredFolders.name,
					connections.currentUser.shopcastManager.starredFolders.data
				)
				dd.deleteShopcastFolder.starredSubFolders.forEach(i=> {
					ConnectionHandler.deleteNode(starredConnection, i)
				})

				if (isInRoot) {
					const {
						name,
						data,
					} = connections.currentUser.shopcastManager.rootFolders
					const rootConnection = ConnectionHandler.getConnection(
						containerProxy,
						name,
						data
					)
					ConnectionHandler.deleteNode(rootConnection, id)
				}
			}
		},
	})

export default { commit }
