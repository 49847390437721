/**
 * @generated SignedSource<<890b21f816a9b70636f0cc48a85982a3>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Query } from 'relay-runtime';
import type { forVirtualStyling_store$fragmentType } from "./forVirtualStyling_store.graphql";
export type forVirtualStylingQuery$variables = {||};
export type forVirtualStylingQuery$data = {|
  +store: ?{|
    +$fragmentSpreads: forVirtualStyling_store$fragmentType,
  |},
|};
export type forVirtualStylingQuery = {|
  response: forVirtualStylingQuery$data,
  variables: forVirtualStylingQuery$variables,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "forVirtualStylingQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Store",
        "kind": "LinkedField",
        "name": "store",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "forVirtualStyling_store"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "ShopshareQueryRoot",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "forVirtualStylingQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Store",
        "kind": "LinkedField",
        "name": "store",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "currentUser",
            "plural": false,
            "selections": [
              (v0/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "f36fd6f56c1d01c2fa27ba7c93b71f68",
    "id": null,
    "metadata": {},
    "name": "forVirtualStylingQuery",
    "operationKind": "query",
    "text": "query forVirtualStylingQuery {\n  store {\n    ...forVirtualStyling_store\n    id\n  }\n}\n\nfragment forVirtualStyling_store on Store {\n  id\n  currentUser {\n    id\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "da881dcb30f2d72a7629c195eb4732ab";

module.exports = ((node/*: any*/)/*: Query<
  forVirtualStylingQuery$variables,
  forVirtualStylingQuery$data,
>*/);
