import { commitMutation, graphql } from "react-relay"
import { ConnectionHandler } from "relay-runtime"
import { complete } from "../helper"

const mutation = graphql`
	mutation setCollectionsForProductMutation(
		$input: SetCollectionsForProductInput!
	) {
		setCollectionsForProduct(input: $input) {
			store {
				id
				currentUser {
					id
				}
			}
			createdCollections {
				id
				title
				createdAt
				updatedAt
				isDefault
				count
			}
		}
	}
`

const commit = (
	env,
	items,
	currentUserId,
	onError,
	onCompleted,
	other
) =>
	commitMutation(env, {
		mutation,
		variables: {
			input: {
				items
			},
		},
		onError: complete(onCompleted, onError),
		onCompleted: complete(onCompleted, onError),
		updater: (storeProxy) => {
			items.forEach((item) => {
				const { id: productId, value: collections } = item
				const currentUserProxy = storeProxy.get(currentUserId)
				const connection = ConnectionHandler.getConnection(
					currentUserProxy,
					"currentUser_noFilter_productCollections"
				)
				const productProxy = storeProxy.get(productId)
				const currentCollectionIds = productProxy
					.getLinkedRecords("productCollections")
					.map((lr) => lr.getValue("id"))
				const newCollectionIds = collections
					.map((col) => col.id)
					.filter((i) => i)

				const deleted = currentCollectionIds.filter(
					(i) => !newCollectionIds.includes(i)
				)
				const added = newCollectionIds.filter(
					(i) => !currentCollectionIds.includes(i)
				)

				deleted.forEach((colId) => {
					const collectionProxy = storeProxy.get(colId)
					if (!collectionProxy) return
					collectionProxy.setValue(
						collectionProxy.getValue("count") - 1,
						"count"
					)
					const itemsConnection = ConnectionHandler.getConnection(
						collectionProxy,
						"currentUser_productCollection_items"
					)
					if (!itemsConnection) return
					ConnectionHandler.deleteNode(itemsConnection, productId)
				})
				added.forEach((colId) => {
					const collectionProxy = storeProxy.get(colId)
					if (!collectionProxy) return
					collectionProxy.setValue(
						collectionProxy.getValue("count") + 1,
						"count"
					)
					const itemsConnection = ConnectionHandler.getConnection(
						collectionProxy,
						"currentUser_productCollection_items"
					)
					if (!itemsConnection) return
					const newEdge = ConnectionHandler.createEdge(
						storeProxy,
						itemsConnection,
						productProxy,
						"ProductCollectionItemEdge"
					)
					ConnectionHandler.insertEdgeAfter(itemsConnection, newEdge)
				})

				const collectionProxy = newCollectionIds.map((colId) =>
					storeProxy.get(colId)
				)
				collectionProxy.forEach((cp) => {
					const value = collections.find(
						(c) => c.id === cp.getValue("id")
					)?.value
					cp.setValue(value, "title", { ownershipCheck: true })
				})

				const newRecords = storeProxy
					.getRootField("setCollectionsForProduct")
					?.getLinkedRecords("createdCollections")
				if (connection && newRecords) {
					newRecords.forEach((record) => {
						record.setValue(record.getValue("title"), "title", {
							ownershipCheck: true,
						})
						const newEdge = ConnectionHandler.createEdge(
							storeProxy,
							connection,
							record,
							"ProductCollectionEdge"
						)
						ConnectionHandler.insertEdgeAfter(connection, newEdge)
					})
				}

				productProxy.setLinkedRecords(
					[...collectionProxy, ...newRecords],
					"productCollections"
				)
				const stillExists = () =>
					[newCollectionIds, other.collections].reduce((p, c) =>
						p.filter((e) => c.includes(e))
					)
				if (
					other &&
					other.collections.length > 0 &&
					stillExists().length === 0
				) {
					const connectionFilterProxy = other
						? ConnectionHandler.getConnection(
								currentUserProxy.getLinkedRecord(
									"productCollectionSearchItems",
									{
										categories: other.categories,
										brands: other.brands,
										clientIds: other.clientId ? [other.clientId] : [],
										collections: other.collections,
										limitType: other.limitType,
										search: other.search,
										sort: other.sort,
										type: other.type,
										teamId: other.teamId,
									}
								),
								"currentUser_productCollectionSearchItems_data"
						  )
						: null

					if (connectionFilterProxy)
						ConnectionHandler.deleteNode(connectionFilterProxy, productId)
				}
			})
		},
	})

export default { commit }
