export const CategoryENUM = {
	SHOPCAST: "shopcast",
	SHAPE: "element",
	FAVOURITE: "favourites",
	TEMPLATE: "template",
	FONT: "fontList",
	COLLECTION:"collections",
	BACKGROUND:"backgrounds",
	RECORDER: "lookbookVideo",
	IMAGE: "image",
}
export const MimeTypeENUM = {
	ALL_IMAGES: "image/*",
}
export const ClipTypeENUM = {
	FIT: "FIT",
	FILL: "FILL",
}