/**
 * @generated SignedSource<<514888fef0b0a67aea8664f477913147>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { lookbookCard_currentUser$fragmentType } from "./../../cards/lookbook-card/__generated__/lookbookCard_currentUser.graphql";
import type { lookbookCard_lookbook$fragmentType } from "./../../cards/lookbook-card/__generated__/lookbookCard_lookbook.graphql";
import type { shopcastCard_currentUser$fragmentType } from "./../../cards/shopcast-card/__generated__/shopcastCard_currentUser.graphql";
import type { shopcastCard_shopcast$fragmentType } from "./../../cards/shopcast-card/__generated__/shopcastCard_shopcast.graphql";
import type { FragmentType } from "relay-runtime";
declare export opaque type listOfRecommendedItems_store$fragmentType: FragmentType;
export type listOfRecommendedItems_store$data = {|
  +currentUser: ?{|
    +$fragmentSpreads: lookbookCard_currentUser$fragmentType & shopcastCard_currentUser$fragmentType,
  |},
  +id: string,
  +recommendedAll: ?{|
    +edges: ?$ReadOnlyArray<?{|
      +cursor: string,
      +node: ?({|
        +__typename: "Lookbook",
        +id: string,
        +$fragmentSpreads: lookbookCard_lookbook$fragmentType,
      |} | {|
        +__typename: "Shopcast",
        +id: string,
        +$fragmentSpreads: shopcastCard_shopcast$fragmentType,
      |} | {|
        // This will never be '%other', but we need some
        // value in case none of the concrete values match.
        +__typename: "%other",
      |}),
    |}>,
    +pageInfo: {|
      +endCursor: ?string,
      +hasNextPage: boolean,
      +hasPreviousPage: boolean,
      +startCursor: ?string,
    |},
  |},
  +$fragmentType: listOfRecommendedItems_store$fragmentType,
|};
export type listOfRecommendedItems_store$key = {
  +$data?: listOfRecommendedItems_store$data,
  +$fragmentSpreads: listOfRecommendedItems_store$fragmentType,
  ...
};
*/

var node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": 12,
      "kind": "LocalArgument",
      "name": "count"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "cursor"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "type"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "count",
        "cursor": "cursor",
        "direction": "forward",
        "path": [
          "recommendedAll"
        ]
      }
    ]
  },
  "name": "listOfRecommendedItems_store",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "currentUser",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "lookbookCard_currentUser"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "shopcastCard_currentUser"
        }
      ],
      "storageKey": null
    },
    {
      "alias": "recommendedAll",
      "args": [
        {
          "kind": "Variable",
          "name": "type",
          "variableName": "type"
        }
      ],
      "concreteType": "RecommendedAllConnection",
      "kind": "LinkedField",
      "name": "__store_recommendedAll_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "RecommendedAllEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cursor",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": null,
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__typename",
                  "storageKey": null
                },
                {
                  "kind": "InlineFragment",
                  "selections": [
                    (v0/*: any*/),
                    {
                      "args": null,
                      "kind": "FragmentSpread",
                      "name": "shopcastCard_shopcast"
                    }
                  ],
                  "type": "Shopcast",
                  "abstractKey": null
                },
                {
                  "kind": "InlineFragment",
                  "selections": [
                    (v0/*: any*/),
                    {
                      "args": null,
                      "kind": "FragmentSpread",
                      "name": "lookbookCard_lookbook"
                    }
                  ],
                  "type": "Lookbook",
                  "abstractKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PageInfo",
          "kind": "LinkedField",
          "name": "pageInfo",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasPreviousPage",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasNextPage",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "startCursor",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endCursor",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Store",
  "abstractKey": null
};
})();

(node/*: any*/).hash = "39f0570fb03f772882fb7a65b8b8b718";

module.exports = ((node/*: any*/)/*: Fragment<
  listOfRecommendedItems_store$fragmentType,
  listOfRecommendedItems_store$data,
>*/);
