/**
 * @generated SignedSource<<1f8c786bfeb0c00f57051b08133dc8bd>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Query } from 'relay-runtime';
import type { shopcastManagerRoot_store$fragmentType } from "./shopcastManagerRoot_store.graphql";
export type shopcastManagerRootQuery$variables = {|
  teamId?: ?string,
|};
export type shopcastManagerRootQuery$data = {|
  +store: ?{|
    +$fragmentSpreads: shopcastManagerRoot_store$fragmentType,
  |},
|};
export type shopcastManagerRootQuery = {|
  response: shopcastManagerRootQuery$data,
  variables: shopcastManagerRootQuery$variables,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "teamId"
  }
],
v1 = {
  "kind": "Variable",
  "name": "teamId",
  "variableName": "teamId"
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "avatar",
  "storageKey": null
},
v5 = {
  "kind": "Literal",
  "name": "first",
  "value": 10
},
v6 = [
  (v5/*: any*/),
  (v1/*: any*/)
],
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "count",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "cursor",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "concreteType": "PageInfo",
  "kind": "LinkedField",
  "name": "pageInfo",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "endCursor",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasNextPage",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v11 = [
  "first"
],
v12 = [
  (v5/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "shopcastManagerRootQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Store",
        "kind": "LinkedField",
        "name": "store",
        "plural": false,
        "selections": [
          {
            "args": [
              (v1/*: any*/)
            ],
            "kind": "FragmentSpread",
            "name": "shopcastManagerRoot_store"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "ShopshareQueryRoot",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "shopcastManagerRootQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Store",
        "kind": "LinkedField",
        "name": "store",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": [
              {
                "kind": "Variable",
                "name": "id",
                "variableName": "teamId"
              }
            ],
            "concreteType": "team",
            "kind": "LinkedField",
            "name": "team",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "currentUser",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "role",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "lookbookTemplatesCategories",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "ShopcastContainer",
                "kind": "LinkedField",
                "name": "shopcastContainer",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "rootItemCount",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "archivedItemCount",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "publishedCount",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": (v6/*: any*/),
                    "concreteType": "ShopcastContainerShopcastFolderConnectionConnection",
                    "kind": "LinkedField",
                    "name": "starredFolders",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ShopcastContainerShopcastFolderConnectionEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "ShopcastFolder",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v2/*: any*/),
                              (v3/*: any*/),
                              (v7/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "Client",
                                "kind": "LinkedField",
                                "name": "clients",
                                "plural": true,
                                "selections": [
                                  (v2/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "fullname",
                                    "storageKey": null
                                  },
                                  (v4/*: any*/)
                                ],
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "sharableLink",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "isParentAClient",
                                "storageKey": null
                              },
                              (v8/*: any*/)
                            ],
                            "storageKey": null
                          },
                          (v9/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v10/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": (v6/*: any*/),
                    "filters": (v11/*: any*/),
                    "handle": "connection",
                    "key": "currentUser_connectionManager_starredFolders",
                    "kind": "LinkedHandle",
                    "name": "starredFolders"
                  },
                  {
                    "alias": null,
                    "args": (v12/*: any*/),
                    "concreteType": "ShopcastContainerShopcastFolderConnectionConnection",
                    "kind": "LinkedField",
                    "name": "rootFolders",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ShopcastContainerShopcastFolderConnectionEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "ShopcastFolder",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v2/*: any*/),
                              (v3/*: any*/),
                              (v7/*: any*/),
                              (v8/*: any*/)
                            ],
                            "storageKey": null
                          },
                          (v9/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v10/*: any*/)
                    ],
                    "storageKey": "rootFolders(first:10)"
                  },
                  {
                    "alias": null,
                    "args": (v12/*: any*/),
                    "filters": (v11/*: any*/),
                    "handle": "connection",
                    "key": "currentUser_connectionManager_rootFolders",
                    "kind": "LinkedHandle",
                    "name": "rootFolders"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "945d75ce6de1a3c67143247d30ea3211",
    "id": null,
    "metadata": {},
    "name": "shopcastManagerRootQuery",
    "operationKind": "query",
    "text": "query shopcastManagerRootQuery(\n  $teamId: String\n) {\n  store {\n    ...shopcastManagerRoot_store_1j7Wr3\n    id\n  }\n}\n\nfragment shopcastManagerRoot_store_1j7Wr3 on Store {\n  id\n  team(id: $teamId) {\n    id\n    name\n    avatar\n  }\n  currentUser {\n    id\n    role\n    lookbookTemplatesCategories\n    shopcastContainer {\n      id\n      rootItemCount\n      archivedItemCount\n      publishedCount\n      starredFolders(first: 10, teamId: $teamId) {\n        edges {\n          node {\n            id\n            name\n            count\n            clients {\n              id\n              fullname\n              avatar\n            }\n            sharableLink\n            isParentAClient\n            __typename\n          }\n          cursor\n        }\n        pageInfo {\n          endCursor\n          hasNextPage\n        }\n      }\n      rootFolders(first: 10) {\n        edges {\n          node {\n            id\n            name\n            count\n            __typename\n          }\n          cursor\n        }\n        pageInfo {\n          endCursor\n          hasNextPage\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "d551c6bbff2b35e31123a7d2d409d19f";

module.exports = ((node/*: any*/)/*: Query<
  shopcastManagerRootQuery$variables,
  shopcastManagerRootQuery$data,
>*/);
