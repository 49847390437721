import React, { Fragment, useEffect } from "react"
import GroupIcon from "shared/assets/group-icon.svg"
import UngroupIcon from "shared/assets/ungroup-icon.svg"

import styles from "routes/lookbook/moveable.css"
import {
	TrashIcon,
	ArrowUpIcon,
	ArrowDownIcon,
	RowSpacingIcon,
	ColumnSpacingIcon,
	CopyIcon,
	PinTopIcon,
	PinBottomIcon,
	LockClosedIcon,
	LockOpen1Icon,
	Pencil2Icon,
} from "@radix-ui/react-icons"
import { isBackgroundRemovedImage } from "helpers/lookbook-helper"
import {
	FeatureAvailableComponent,
	FeatureKeyENUM,
} from "services/plan-services"

function ImageContollers(props) {
	const {
		objList,
		selected,
		moveItem,
		removeItem,
		flip,
		duplicate,
		group,
		unGroup,
		canGroup,
		canUnGroup,
		selectedObjArray,
		lockItem,
		lockedItems,
		makeAsTemplate,
		environment,
		templateMode,
		openBgRemove,
		onClose,
	} = props

	const setTemplateItems = () =>
		selectedObjArray.filter((obj) => obj.image && obj.isDroppableItem)
			.length > 0
	const unsetTemplateItems = () =>
		selectedObjArray.filter((obj) => obj.image && !obj.isDroppableItem)
			.length > 0

	useEffect(() => {
		if (!selected) onClose()
	}, [selected])

	const renderForSelectedItem = () => {
		const selectedItem = objList.find((obj) => obj.id === selected)
		return (
			<Fragment>
				{selectedItem?.image && (
					<Fragment>
						<div
							className={styles.item}
							role="button"
							tabIndex="0"
							onClick={() => flip("x")}
						>
							<span className={styles.itemInner}>
								<ColumnSpacingIcon className={styles.iconMenu} /> Flip
								Horizontally
							</span>
							<kbd className={styles.shortcut}>
								{window.IOS ? "⌘" : "CTRL"}H
							</kbd>
						</div>
						<div
							className={styles.item}
							role="button"
							tabIndex="0"
							onClick={() => flip("y")}
						>
							<span className={styles.itemInner}>
								<RowSpacingIcon className={styles.iconMenu} /> Flip
								Vertically
							</span>
							<kbd className={styles.shortcut}>
								{window.IOS ? "⌘" : "CTRL"}V
							</kbd>
						</div>
						<div className={styles.divider} />
					</Fragment>
				)}
				{(selectedItem?.shopcastProductID ||
					selectedItem?.lookbookUploadID) &&
					!isBackgroundRemovedImage(selectedItem?.imageUrl) && (
						<Fragment>
							<div
								className={styles.item}
								role="button"
								tabIndex="0"
								onClick={() => openBgRemove(selected)}
							>
								<span className={styles.itemInner}>
									<ColumnSpacingIcon className={styles.iconMenu} />{" "}
									Remove Background
								</span>
							</div>
							<div className={styles.divider} />
						</Fragment>
					)}
			</Fragment>
		)
	}
	return (
		<Fragment>
			<div
				className={styles.item}
				role="button"
				tabIndex="0"
				onClick={removeItem}
			>
				<span className={styles.itemInner}>
					<TrashIcon className={styles.iconMenu} /> Delete
				</span>{" "}
				<kbd className={styles.shortcut}>DEL</kbd>
			</div>
			<div
				className={styles.item}
				role="button"
				tabIndex="0"
				onClick={() => duplicate()}
			>
				<span className={styles.itemInner}>
					<CopyIcon className={styles.iconMenu} /> Duplicate
				</span>
				<kbd className={styles.shortcut}>{window.IOS ? "⌘" : "CTRL"}D</kbd>
			</div>
			<div className={styles.divider} />
			{selectedObjArray.length === 1 && renderForSelectedItem()}

			{selectedObjArray.length > 1 && (
				<Fragment>
					{canGroup && (
						<div
							className={styles.item}
							role="button"
							tabIndex="0"
							onClick={group}
						>
							<span className={styles.itemInner}>
								<GroupIcon className={styles.iconMenu} /> Group
							</span>
							<kbd className={styles.shortcut}>
								{window.IOS ? "⌘" : "CTRL"}G
							</kbd>
						</div>
					)}
					{canUnGroup && (
						<div
							className={styles.item}
							role="button"
							tabIndex="0"
							onClick={unGroup}
						>
							<span className={styles.itemInner}>
								<UngroupIcon className={styles.iconMenu} /> Ungroup
							</span>
							<kbd className={styles.shortcut}>
								⌥{window.IOS ? "⌘" : "CTRL"}H
							</kbd>
						</div>
					)}
					<div className={styles.divider} />
				</Fragment>
			)}
			{selectedObjArray.filter((x) => !lockedItems.includes(x.id)).length >
				0 && (
				<Fragment>
					<div
						className={styles.item}
						role="button"
						tabIndex="0"
						onClick={() => lockItem(true)}
					>
						<span className={styles.itemInner}>
							<LockOpen1Icon className={styles.iconMenu} /> Lock
						</span>
						<kbd className={styles.shortcut}>
							{window.IOS ? "⌘" : "CTRL"}L
						</kbd>
					</div>
					<div className={styles.divider} />
				</Fragment>
			)}
			{selectedObjArray.filter((x) => lockedItems.includes(x.id)).length >
				0 && (
				<Fragment>
					<div
						className={styles.item}
						role="button"
						tabIndex="0"
						onClick={() => lockItem(false)}
					>
						<span className={styles.itemInner}>
							<LockClosedIcon className={styles.iconMenu} /> Unlock
						</span>
						<kbd className={styles.shortcut}>
							⌥{window.IOS ? "⌘" : "CTRL"}L
						</kbd>
					</div>
					<div className={styles.divider} />
				</Fragment>
			)}
			{templateMode && (
				<FeatureAvailableComponent
					featureKey={FeatureKeyENUM.TEMPLATES}
					env={environment}
				>
					{setTemplateItems() && (
						<div
							className={styles.item}
							role="button"
							tabIndex="0"
							onClick={() => makeAsTemplate(false)}
						>
							<span className={styles.itemInner}>
								<Pencil2Icon className={styles.iconMenu} /> Unset drop
								placeholder
							</span>
							{/* <kbd className={styles.shortcut}>{window.IOS ? "⌘" : "CTRL"}G</kbd> */}
						</div>
					)}
					{unsetTemplateItems() && (
						<div
							className={styles.item}
							role="button"
							tabIndex="0"
							onClick={() => makeAsTemplate(true)}
						>
							<span className={styles.itemInner}>
								<Pencil2Icon className={styles.iconMenu} /> Set as drop
								placeholder
							</span>
							{/* <kbd className={styles.shortcut}>{window.IOS ? "⌘" : "CTRL"}G</kbd> */}
						</div>
					)}
					<div className={styles.divider} />
				</FeatureAvailableComponent>
			)}

			<div
				className={styles.item}
				role="button"
				tabIndex="0"
				onClick={() => moveItem(-1)}
			>
				<span className={styles.itemInner}>
					<ArrowDownIcon className={styles.iconMenu} />
					Send Backward
				</span>
				<kbd className={styles.shortcut}>{window.IOS ? "⌘" : "CTRL"}[</kbd>
			</div>
			<div
				className={styles.item}
				role="button"
				tabIndex="0"
				onClick={() => moveItem(0)}
			>
				<span className={styles.itemInner}>
					<PinBottomIcon className={styles.iconMenu} /> Send to Back
				</span>
				<kbd className={styles.shortcut}>⌥{window.IOS ? "⌘" : "CTRL"}[</kbd>
			</div>
			<div
				className={styles.item}
				role="button"
				tabIndex="0"
				onClick={() => moveItem(1)}
			>
				<span className={styles.itemInner}>
					<ArrowUpIcon className={styles.iconMenu} /> Bring Forward
				</span>
				<kbd className={styles.shortcut}>{window.IOS ? "⌘" : "CTRL"}]</kbd>
			</div>
			<div
				className={styles.item}
				role="button"
				tabIndex="0"
				onClick={() => moveItem()}
			>
				<span className={styles.itemInner}>
					<PinTopIcon className={styles.iconMenu} /> Bring Front
				</span>
				<kbd className={styles.shortcut}>⌥{window.IOS ? "⌘" : "CTRL"}]</kbd>
			</div>
		</Fragment>
	)
}

export default ImageContollers
