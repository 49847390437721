import React, { Component } from "react"
import { DownloadIcon } from "@radix-ui/react-icons"
import { Helmet } from "react-helmet"
import ReactPlayer from "react-player/lazy"
import { createFragmentContainer, graphql } from "react-relay"
import landing from "shared/styles/layout/landing.css"
import button from "shared/styles/components/buttons.css"
import layout from "shared/styles/components/layout.css"
import icons from "shared/styles/components/icons.css"
import FacebookIcon from "shared/assets/facebook-circle-icon.svg"
import InstagramIcon from "shared/assets/instagram-icon.svg"
import { toast } from "react-toastify"
import qs from "query-string"

import UploadDownloadedLookbookFacebookMutation from "mutations/lookbook/upload-downloaded-lookbook-facebook"
import FacebookSharing from "components/modals/facebook-sharing/facebook-sharing"
import { getBaseUrl, ROLES, socialMedia } from "../../constants"
import styles from "./lookbook-download.css"
import { withRouter } from "../../route-helpers"

class LookbookDownload extends Component {
	constructor(props) {
		super(props)
		this.data = qs.parse(window.location.search)
		this.state = {
			loading: false,
			facebookSharingModal: null,
		}
	}

	componentDidMount() {
		const {
			store: { currentUser },
			navigate,
			location,
		} = this.props
		if (!currentUser)
			navigate(`/signin`, {
				state: {
					nextPathname: `${location.pathname}${location.search}`,
				}
			})
	}

	render() {
		const { relay, store } = this.props
		const { loading, facebookSharingModal } = this.state
		const isAdmin =
			store?.currentUser &&
			[ROLES.ADMIN, ROLES.TEAM].includes(store?.currentUser.role)
		const socialMediaObj = (store?.currentUser?.socialMedia || []).reduce(
			(acc, cur) => ({ ...acc, [cur.platform]: cur.isConnected }),
			{}
		)
		return (
			<div className={`${layout.containerWidth5} ${layout.offset}`}>
				<Helmet>
					<title>ShopShare – Shopboard Video Download</title>
				</Helmet>
				<section className={landing.section}>
					<div className={landing.content}>
						<h1 className={landing.displayTitle}>Download</h1>
						<p className={landing.displayLead}>
							Download or share on your socials.
						</p>
						<div className={styles.actions}>
							<a
								className={`${button.primaryIcon} ${button.iconLg}`}
								data-tooltip-id="tooltip"
								data-tooltip-place="bottom"
								data-tooltip-content="Download Video"
								href={`${getBaseUrl(
									false
								)}/api/shopshare-files/download?url=${this.data.url}`}
							>
								<DownloadIcon className={icons.icon21} />
							</a>
							{isAdmin && (
								<>
									<button
										type="button"
										className={`${button.subtleIcon} ${button.iconLg}`}
										data-tooltip-id="tooltip"
										data-tooltip-place="bottom"
										data-tooltip-content="Share on Facebook"
										disabled={loading}
										onClick={() => {
											this.setState({
												facebookSharingModal: "facebook",
											})
										}}
									>
										<FacebookIcon className={icons.icon21} />
									</button>
									<button
										type="button"
										className={`${button.subtleIcon} ${button.iconLg}`}
										data-tooltip-id="tooltip"
										data-tooltip-place="bottom"
										data-tooltip-content="Share on Instagram"
										disabled={loading}
										onClick={() => {
											this.setState({
												facebookSharingModal: "instagram",
											})
										}}
									>
										<InstagramIcon className={icons.icon21} />
									</button>
								</>
							)}
						</div>
					</div>
				</section>
				<section className={landing.section}>
					<div className={landing.contentNoTopPadding}>
						<div className={styles.content}>
							<div className={styles.video}>
								<ReactPlayer
									url={this.data.url}
									width="100%"
									playsinline
									className={styles.player}
									controls
									playing
									muted
									height="100%"
								/>
							</div>
						</div>
					</div>
				</section>

				{!!facebookSharingModal && (
					<FacebookSharing
						closeModal={() =>
							this.setState({ facebookSharingModal: null })
						}
						obj={{
							lookbookBoards: [],
							description: "",
						}}
						connectedToFacebook={!!socialMediaObj.facebook}
						relay={relay}
						storeId={store.id}
						fbPages={store.currentUser.facebookPages}
						platform={facebookSharingModal}
						uploadAs={[{ id: "video", name: "Video" }]}
						onSharing={async (pageObj) => {
							const { facebookSharingModal: fsm } = this.state
							this.setState({
								loading: true,
							})
							await new Promise((resolve, reject) => {
								UploadDownloadedLookbookFacebookMutation.commit(
									relay.environment,
									{
										lookbookId: this.data.lookbookId,
										description: pageObj.description,
										s3Key: this.data.url,
										pageId:
											fsm === "facebook"
												? pageObj.fbPage.id
												: pageObj.fbPage.instagramBusinessAccount,
										accessToken: pageObj.fbPage.accessToken,
										platform: fsm,
									},
									resolve,
									reject
								)
							})
								.then(() => {
									this.setState({
										loading: false,
										facebookSharingModal: null,
									})
									toast.success(
										<>
											Video has been successfully uploaded to{" "}
											{socialMedia[fsm].name}
										</>,
										{
											autoClose: 3000,
											closeButton: false,
											position: "top-center",
										}
									)
								})
								.catch(() => {
									toast.error(<>Upload Error</>, {
										autoClose: 3000,
										closeButton: false,
										position: "top-center",
									})
								})
						}}
					/>
				)}
			</div>
		)
	}
}

LookbookDownload = createFragmentContainer(LookbookDownload, {
	store: graphql`
		fragment lookbookDownload_store on Store {
			id
			currentUser {
				id
				socialMedia {
					platform
					isConnected
				}
				adminAccess
				role
				facebookPages {
					id
					name
					category
					imageUrl
					accessToken
					instagramBusinessAccount
				}
			}
		}
	`,
})

const query = graphql`
	query lookbookDownloadQuery {
		store {
			...lookbookDownload_store
		}
	}
`

export default {
	Component: withRouter(LookbookDownload),
	query,
	needAuth: true,
}
