import { commitMutation, graphql } from "react-relay"
import { complete } from "../helper.js"

const mutation = graphql`
	mutation openCustomerPortalMutation($input: OpenCustomerPortalInput!) {
		openCustomerPortal(input: $input) {
			url
		}
	}
`

const commit = (env, backUrl, onCompleted, onError) =>
	commitMutation(env, {
		mutation,
		variables: { input: {
			backUrl,
		} },
		onCompleted: complete(onCompleted, onError),
		onError: (e) => complete(null, [e]),
	})

export default { commit }
