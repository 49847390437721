import React, { Component } from "react"
import buttons from "../../../shared/styles/components/buttons.css"
import styles from "./edit-shopcast-user-report-admin-notes-toast.css"
import SaveShopcastReportAdminNotesMutation from "../../../mutations/admin/save-shopcast-report-admin-notes"

class EditShopcastUserReportAdminNotes extends Component {
	constructor(props) {
		super(props)
		this.note = null
	}

	componentDidMount() {
		if (this.noteEdit) {
			const { props } = this
			this.noteEdit.value = props.report.adminNotes
		}
	}

	handleSave() {
		const { props } = this
		const { report } = props
		if (report && this.note && report.id) {
			SaveShopcastReportAdminNotesMutation.commit(
				props.relay.environment,
				report.id,
				this.note,
				() => props.closeToast(),
				() => props.closeToast()
			)
		}
	}

	render() {
		return (
			<div className={styles.root}>
				<textarea
					ref={(r) => {
						this.noteEdit = r
					}}
					onChange={(e) => {
						this.note = e.target.value
					}}
				/>
				<button
					type="button"
					className={buttons.whiteBlue}
					onClick={this.handleSave}
				>
					Save
				</button>
			</div>
		)
	}
}

export default EditShopcastUserReportAdminNotes
