import { commitMutation, graphql } from "react-relay"
import { ConnectionHandler } from "relay-runtime"
import { complete } from "../helper"

const mutation = graphql`
	mutation createProductForCollectionMutation(
		$input: CreateProductForCollectionInput!
	) {
		createProductForCollection(input: $input) {
			store {
				id
			}
			product {
				id
				title
				brand
				description
				url
				affiliated_url(type: null)
				custom_affiliated_url
				imageUrl
				diffbotImageUrl
				regularPrice
				offerPrice
				error
				productImages
				hasProductImagesFromDiffbot
				productCollections {
					id
					title
				}
				user {
					id
				}
			}
			collections {
				id
				title
			}
		}
	}
`

const commit = (env, url, collectionIds, team, onError, onCompleted, other) =>
	commitMutation(env, {
		mutation,
		variables: {
			input: {
				url,
				collectionIds,
				teamId: team,
			},
		},
		updater: (storeProxy) => {
			try {
				const rootField = storeProxy.getRootField(
					"createProductForCollection"
				)
				if (!rootField) return
				const itemProxy = rootField.getLinkedRecord("product")

				const newCollectionItem = storeProxy.create(
					`ShopcastProduct:connection:${new Date().getTime()}`,
					"ShopcastProduct"
				)

				newCollectionItem.setLinkedRecord(itemProxy, "node")
				if (collectionIds.length > 0) {
					collectionIds.forEach((collectionId) => {
						const collectionProxy = storeProxy.get(collectionId)
						collectionProxy.setValue(
							collectionProxy.getValue("count") + 1,
							"count"
						)
					})
				}
				if(other) {
					const searchConnection = ConnectionHandler.getConnection(
						storeProxy
							.getRoot()
							.getLinkedRecord("store")
							.getLinkedRecord("currentUser")
							.getLinkedRecord("productCollectionSearchItems", {
								search: other.search,
								categories: other.categories,
								brands: other.brands,
								clientIds: other.clientId ? [other.clientId] : [],
								collections: other.collections,
								limitType: other.limitType,
								sort: other.sort,
								type: other.type,
								teamId: team,
							}),
						"currentUser_productCollectionSearchItems_data",
					)
					ConnectionHandler.insertEdgeBefore(searchConnection, newCollectionItem)
				}

			} catch (error) {
				console.error(error)
			}
		},
		onError: complete(onCompleted, onError),
		onCompleted: complete(onCompleted, onError),
	})

export default { commit }
