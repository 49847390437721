import { commitMutation, graphql } from "react-relay"
import { complete } from "../helper.js"

const mutation = graphql`
	mutation uploadTeamBannerMutation($input: TeamBannerPhotoInput!) {
		teamBannerPhoto(input: $input) {
			store {
				id
			}
			team {
				id
				bannerPhoto
			}
		}
	}
`

const commit = (env, file, teamId, onCompleted, onError) =>
	commitMutation(env, {
		mutation,
		variables: { input: { imageName: file ? file.name : null, teamId } },
		uploadables: { file },
		onCompleted: complete(onCompleted, onError),
		onError: (e) => complete(null, [e]),
		updater: (storeProxy, data) => {
			const teamProxy = storeProxy.get(teamId)
			teamProxy.setValue(
				data.teamBannerPhoto.team.bannerPhoto,
				"bannerPhoto"
			)
		},
	})

export default { commit }
