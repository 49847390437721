/**
 * @generated SignedSource<<6a9dfae147e153cd5ea11a2ab3be111a>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Query } from 'relay-runtime';
import type { forContentMarketing_store$fragmentType } from "./forContentMarketing_store.graphql";
export type forContentMarketingQuery$variables = {||};
export type forContentMarketingQuery$data = {|
  +store: ?{|
    +$fragmentSpreads: forContentMarketing_store$fragmentType,
  |},
|};
export type forContentMarketingQuery = {|
  response: forContentMarketingQuery$data,
  variables: forContentMarketingQuery$variables,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "forContentMarketingQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Store",
        "kind": "LinkedField",
        "name": "store",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "forContentMarketing_store"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "ShopshareQueryRoot",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "forContentMarketingQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Store",
        "kind": "LinkedField",
        "name": "store",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "currentUser",
            "plural": false,
            "selections": [
              (v0/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "69727f5850b9c45b9607b1b96f33cbe1",
    "id": null,
    "metadata": {},
    "name": "forContentMarketingQuery",
    "operationKind": "query",
    "text": "query forContentMarketingQuery {\n  store {\n    ...forContentMarketing_store\n    id\n  }\n}\n\nfragment forContentMarketing_store on Store {\n  id\n  currentUser {\n    id\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "3feae7122c6020bfcf4386fd59095746";

module.exports = ((node/*: any*/)/*: Query<
  forContentMarketingQuery$variables,
  forContentMarketingQuery$data,
>*/);
