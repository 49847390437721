import { commitMutation, graphql } from "react-relay"
import { complete } from "../helper"

const mutation = graphql`
	mutation updateShopcastMutation($input: UpdateShopcastInput!) {
		updateShopcast(input: $input) {
			output
		}
	}
`

const commit = (env, data, onError, onCompleted) =>
	commitMutation(env, {
		mutation,
		variables: { input: data },
		onError: complete(onCompleted, onError),
		onCompleted: complete(onCompleted, onError),
		updater: (storeProxy, dd) => {
			if (dd.updateShopcast) {
				const shopcastProxy = storeProxy.get(data.shopcastId)
				const category = storeProxy.get(data.category)
				shopcastProxy.setValue(data.title, "title")
				shopcastProxy.setValue(data.description, "description")
				if (category) shopcastProxy.setLinkedRecord(category, "category")
				else shopcastProxy.setValue(null, "category")
				shopcastProxy.setValue(
					data.publicSetting !== "private",
					"isPublished"
				)
			}
		},
	})

export default { commit }
