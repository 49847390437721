import { useEffect } from "react"
import styles from "shared/styles/components/breakpoints.css"
import { useMultiMediaQuery } from "helpers/ui-helper"

const defaultBreakpoints = {
	"breakpoint-xxl": 5,
	"breakpoint-xl": 4,
	"breakpoint-lg": 3,
	"breakpoint-md": 2,
	"breakpoint-sm": 2,
	"breakpoint-xs": 1,
}

function getColumnCount(breakpoints) {
	let bps = breakpoints || defaultBreakpoints
	bps = Object.keys(bps).reduce((acc, key) => {
		acc[styles[key]] = bps[key]
		return acc
	}, {})
	const matches = useMultiMediaQuery(Object.keys(bps))
	const breakpoint = Object.keys(bps).find((key) => matches[key])
	const colCount = bps[breakpoint] || 1
	return colCount
}

function QueryLoadMore({ data, hasMore, loadMore, children, breakpoints }) {
	const colCount = getColumnCount(breakpoints)
	const count = colCount * 2
	useEffect(() => {
		if (data.length < count && hasMore) loadMore(count - data.length)
	}, [data, count])
	const mod = data.length % count
	const slicedData =
		data.length > mod && hasMore ? data.slice(0, data.length - mod) : data
	return children(slicedData, colCount)
}

export function QueryLoadMoreSkeleton({ children, breakpoints }) {
	const colCount = getColumnCount(breakpoints)
	return children(colCount)
}

export default QueryLoadMore
