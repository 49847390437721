/**
 * @generated SignedSource<<0624385cbb66c7d2d50e7b0de7f349f1>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Query } from 'relay-runtime';
import type { clientViewCollections_store$fragmentType } from "./clientViewCollections_store.graphql";
export type clientViewCollectionsQuery$variables = {|
  clientId?: ?string,
  collectionId?: ?string,
  count: number,
  cursor?: ?string,
  folder: string,
  searchText?: ?string,
  sort?: ?string,
|};
export type clientViewCollectionsQuery$data = {|
  +store: ?{|
    +$fragmentSpreads: clientViewCollections_store$fragmentType,
  |},
|};
export type clientViewCollectionsQuery = {|
  response: clientViewCollectionsQuery$data,
  variables: clientViewCollectionsQuery$variables,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "clientId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "collectionId"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "count"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "cursor"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "folder"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "searchText"
},
v6 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "sort"
},
v7 = {
  "kind": "Variable",
  "name": "clientId",
  "variableName": "clientId"
},
v8 = {
  "kind": "Variable",
  "name": "collectionId",
  "variableName": "collectionId"
},
v9 = {
  "kind": "Variable",
  "name": "searchText",
  "variableName": "searchText"
},
v10 = {
  "kind": "Variable",
  "name": "sort",
  "variableName": "sort"
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v12 = {
  "kind": "Variable",
  "name": "folderId",
  "variableName": "folder"
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v14 = [
  (v11/*: any*/),
  (v13/*: any*/)
],
v15 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "cursor"
  },
  (v8/*: any*/),
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "count"
  },
  (v12/*: any*/),
  (v9/*: any*/),
  (v10/*: any*/)
],
v16 = {
  "alias": null,
  "args": null,
  "concreteType": "ProductCollection",
  "kind": "LinkedField",
  "name": "productCollections",
  "plural": true,
  "selections": (v14/*: any*/),
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "url",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "clientViewCollectionsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Store",
        "kind": "LinkedField",
        "name": "store",
        "plural": false,
        "selections": [
          {
            "args": [
              (v7/*: any*/),
              (v8/*: any*/),
              {
                "kind": "Variable",
                "name": "count",
                "variableName": "count"
              },
              {
                "kind": "Variable",
                "name": "cursor",
                "variableName": "cursor"
              },
              {
                "kind": "Variable",
                "name": "folder",
                "variableName": "folder"
              },
              (v9/*: any*/),
              (v10/*: any*/)
            ],
            "kind": "FragmentSpread",
            "name": "clientViewCollections_store"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "ShopshareQueryRoot",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v1/*: any*/),
      (v0/*: any*/),
      (v6/*: any*/)
    ],
    "kind": "Operation",
    "name": "clientViewCollectionsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Store",
        "kind": "LinkedField",
        "name": "store",
        "plural": false,
        "selections": [
          (v11/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "currentUser",
            "plural": false,
            "selections": [
              (v11/*: any*/),
              {
                "alias": null,
                "args": [
                  (v12/*: any*/)
                ],
                "concreteType": "ProductCollectionConnection",
                "kind": "LinkedField",
                "name": "sharedFolderCollectionsList",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ProductCollectionEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ProductCollection",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": (v14/*: any*/),
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v15/*: any*/),
                "concreteType": "ProductCollectionItemConnection",
                "kind": "LinkedField",
                "name": "sharedFolderCollectionItems",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ProductCollectionItemEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "__typename",
                            "storageKey": null
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              (v11/*: any*/),
                              (v13/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "brand",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "description",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "imageUrl",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "diffbotImageUrl",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "regularPrice",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "offerPrice",
                                "storageKey": null
                              },
                              (v16/*: any*/),
                              (v17/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "affiliated_url",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "User",
                                "kind": "LinkedField",
                                "name": "user",
                                "plural": false,
                                "selections": [
                                  (v11/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "fullname",
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "username",
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "profilePhoto",
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "customDomain",
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "shopcastItem",
                                "kind": "LinkedField",
                                "name": "shopcastItems",
                                "plural": true,
                                "selections": [
                                  (v11/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "Shopcast",
                                    "kind": "LinkedField",
                                    "name": "shopcast",
                                    "plural": false,
                                    "selections": (v14/*: any*/),
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "start",
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "end",
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": [
                                  (v7/*: any*/)
                                ],
                                "kind": "ScalarField",
                                "name": "isOwnedItems",
                                "storageKey": null
                              }
                            ],
                            "type": "ShopcastProduct",
                            "abstractKey": null
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              (v11/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "adminAccess",
                                "storageKey": null
                              },
                              (v13/*: any*/),
                              (v16/*: any*/),
                              (v17/*: any*/)
                            ],
                            "type": "lookbookUploads",
                            "abstractKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "cursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "endCursor",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasNextPage",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v15/*: any*/),
                "filters": [
                  "folderId",
                  "collectionId",
                  "searchText",
                  "sort"
                ],
                "handle": "connection",
                "key": "currentUser_sharedFolderCollectionItems",
                "kind": "LinkedHandle",
                "name": "sharedFolderCollectionItems"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "60d7af979ed0a5300fb0ebbd0b8cc085",
    "id": null,
    "metadata": {},
    "name": "clientViewCollectionsQuery",
    "operationKind": "query",
    "text": "query clientViewCollectionsQuery(\n  $count: Int!\n  $cursor: String\n  $folder: String!\n  $searchText: String\n  $collectionId: String\n  $clientId: String\n  $sort: String\n) {\n  store {\n    ...clientViewCollections_store_1mEEfn\n    id\n  }\n}\n\nfragment clientViewCollections_store_1mEEfn on Store {\n  id\n  currentUser {\n    id\n    sharedFolderCollectionsList(folderId: $folder) {\n      edges {\n        node {\n          id\n          title\n        }\n      }\n    }\n    sharedFolderCollectionItems(first: $count, after: $cursor, folderId: $folder, collectionId: $collectionId, searchText: $searchText, sort: $sort) {\n      edges {\n        node {\n          __typename\n          ... on ShopcastProduct {\n            id\n            title\n            brand\n            description\n            imageUrl\n            diffbotImageUrl\n            regularPrice\n            offerPrice\n            productCollections {\n              id\n              title\n            }\n            url\n            affiliated_url\n            user {\n              id\n              fullname\n              username\n              profilePhoto\n              customDomain\n            }\n            shopcastItems {\n              id\n              shopcast {\n                id\n                title\n              }\n              start\n              end\n            }\n            isOwnedItems(clientId: $clientId)\n          }\n          ... on lookbookUploads {\n            id\n            adminAccess\n            title\n            productCollections {\n              id\n              title\n            }\n            url\n          }\n        }\n        cursor\n      }\n      pageInfo {\n        endCursor\n        hasNextPage\n      }\n    }\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "e857121b9528f6bae94b702e3095de41";

module.exports = ((node/*: any*/)/*: Query<
  clientViewCollectionsQuery$variables,
  clientViewCollectionsQuery$data,
>*/);
