/* eslint-disable react/no-unstable-nested-components */

import React, { Component, Fragment } from "react"
import { createFragmentContainer, graphql } from "react-relay"
import { AvatarIcon, CrumpledPaperIcon, HomeIcon, PlusIcon } from "@radix-ui/react-icons"
import TemplateIcon from "shared/assets/lb-template-icon.svg"
import FolderIcon from "shared/assets/folder-icon.svg"
import FolderShareIcon from "shared/assets/folder-share-icon.svg"
import FolderLookbookIcon from "shared/assets/folder-lookbook-icon.svg"
import { toast } from "react-toastify"
import { DndProvider } from "react-dnd"
import { HTML5Backend } from "react-dnd-html5-backend"
import { debounce } from "lodash"
import { Link } from "react-router-dom"
// import AddBoardIcon from "shared/assets/add-board-icon.svg"
// import FolderAddIcon from "shared/assets/folder-add-icon.svg"
import twoColumn from "shared/styles/layout/two-column.css"
import controls from "shared/styles/layout/controls.css"
import pageHeader from "shared/styles/layout/page-header.css"
import buttons from "shared/styles/components/buttons.css"
import { toTitleCase } from "helpers/string-helper"
import Divider from "components/divider/divider"
import Avatar from "components/profile/avatar/avatar"
import SearchInputComponent from "components/search-input/search-input"
import moveShopcastItems from "mutations/shopcast-manager/move-shopcast-items"
import toggleShopcastPublishedState from "mutations/shopcast/toggle-shopcast-published"
import toggleFilePublishedState from "mutations/shopshare-file/toggle-file-published"
import toggleLookbookPublishedState from "mutations/lookbook/toggle-lookbook-published"
import archiveShopcastItem from "mutations/shopcast-manager/archive-shopcast-item"
import SidebarItem from "./sidebar-item/sidebar-item"
import shopcastManager from "./shopcast-manager"
import { getComponent, withRouter } from "../../../route-helpers"
import styles from "./shopcast-manager.css"
import ListOfTemplates from "./list-of-templates/list-of-templates"

class ShopcastManagerRoot extends Component {
	constructor(props) {
		super(props)
		const startSearchObj = this.getFilters()
		const isAdmin = props.store.currentUser.role === "admin"
		this.state = {
			folderId: null,
			search: startSearchObj.search || "",
			templateFilter: isAdmin ? "" : "userTemplates",
			templateSort: "",
		}
		this.setFolderId = this.setFolderId.bind(this)
		this.debouncedSearch = debounce((key, value) => {
			this.setState({ [key]: value })
			this.setFiltersPersistent(key, value)
			const { location, navigate } = this.props
			if (
				["/content/archived", "/content/public", "/content"].includes(
					location.pathname
				)
			)
				return
			if (!value) return
			navigate("/content")
		}, 300)
	}

	componentDidUpdate(prevProps) {
		const { location } = this.props
		const { location: prevLocation } = prevProps
		if (
			prevLocation.pathname !== location.pathname &&
			!["/content/archived", "/content/public", "/content"].includes(
				location.pathname
			)
		) {
			this.searchRef.clear()
		}
	}

	//
	// ─── GETTERS ────────────────────────────────────────────────────────────────────
	//

	get container() {
		const { props } = this
		return props.store.currentUser.shopcastContainer
	}

	get folder() {
		const { folderId } = this.state
		return {
			id: folderId,
		}
	}

	//
	// ─── METHODS ────────────────────────────────────────────────────────────────────
	//

	getFilters() {
		const collectionFiltersStr =
			window.sessionStorage.getItem("library-filters")
		const searchObj = collectionFiltersStr
			? JSON.parse(collectionFiltersStr)
			: {}
		return searchObj
	}

	setFiltersPersistent(key, value) {
		const searchObj = this.getFilters()
		if (!value) delete searchObj[key]
		else searchObj[key] = value
		window.sessionStorage.setItem(
			"library-filters",
			JSON.stringify(searchObj)
		)
	}

	setFolderId(folderId) {
		this.setState({ folderId })
	}

	sendErrorsToToast(message) {
		return () =>
			toast.error(message, {
				autoClose: 2000,
				closeButton: false,
			})
	}

	//
	// ─── RENDERS ────────────────────────────────────────────────────────────────────
	//

	renderStarredList() {
		const { relay, location, team } = this.props
		if (!this.container.starredFolders.edges.length) {
			return null
		}
		const getFolderIcon = (folder) => {
			const client = folder.clients[0]
			if (client)
				return function AvatarCompIcon() {
					return (
						<Avatar
							styleName={styles.avatar}
							photoUrl={client.avatar || folder.avatar}
							fullname={folder.name}
							size="24px"
						/>
					)
				}
			if (!folder.sharableLink) return FolderIcon
			if (!folder.isParentAClient) return FolderShareIcon
			return FolderLookbookIcon
		}
		const list = [
			<div className={twoColumn.sideHeader} key="1">
				<div className={twoColumn.sideHeaderTitle}>Starred</div>
			</div>,
		]
		const accept = [
			"shopcast-card",
			"lookbook-card",
			"shopshare-file-card",
			"product-collection-card",
		]
		this.container.starredFolders.edges.forEach(({ node }) =>
			list.push(
				<SidebarItem
					// size="sm"
					key={node.id}
					onDrop={({ id, type }) => {
						const parent = this.folder
						if (node.id === id || parent.id === node.id) return
						moveShopcastItems.commit(
							relay.environment,
							[{ id, type }],
							node.id,
							team,
							parent.id,
							this.container.id,
							this.sendErrorsToToast(`Couldnt move ${type}`)
						)
					}}
					item={
						node.clients && node.clients.length > 0
							? {
									...node,
									name: node.clients[0].fullname,
							  }
							: node
					}
					path={`/content/${node.id}`}
					isActive={location.pathname === `/content/${node.id}`}
					accept={
						node.isParentAClient ? accept : ["shopcast-folder", ...accept]
					}
					Icon={getFolderIcon(node)}
				/>
			)
		)
		return list
	}

	renderCategoryFilter() {
		const { store, category, team } = this.props
		const categoryList = store.currentUser.lookbookTemplatesCategories || []
		const userTemplatesCategoryName = team ? "Team Templates" : "My Templates"

		return (
			<div className={controls.search}>
				<select
					className={controls.select}
					value={category}
					onChange={(e) => {
						this.setState({ templateFilter: e.target.value })
					}}
				>
					<option value="">All Templates</option>
					<option disabled="">─</option>
					<option value="userTemplates">
						{userTemplatesCategoryName}
					</option>
					{categoryList && categoryList.length > 0 && (
						<Fragment>
							<option disabled>─</option>
							<option disabled>ShopShare Templates</option>
						</Fragment>
					)}
					{categoryList.map((x) => (
						<option key={x} value={x}>
							{toTitleCase(x)}
						</option>
					))}
				</select>
			</div>
		)
	}

	renderSortFilter() {
		const { templateSort } = this.props
		return (
			<div className={controls.sortFilter}>
				<select
					className={controls.select}
					value={templateSort}
					onChange={(e) => {
						this.setState({ templateSort: e.target.value })
					}}
				>
					<optgroup label="Sort by">
						<option value="n-to-o">Newest to Oldest</option>
						<option value="o-to-n">Oldest to Newest</option>
						<option value="name">Name A-Z</option>
						<option value="name-d">Name Z-A</option>
					</optgroup>
				</select>
			</div>
		)
	}

	renderTemplates() {
		const { team, store } = this.props
		const { search, templateFilter, templateSort } = this.state
		const isAdmin = store.currentUser.role === "admin"
		const templateListComp = getComponent(
			"list-of-templates",
			ListOfTemplates,
			{
				count: 12,
				search,
				teamId: team,
				category: templateFilter,
				sort: templateSort,
			},
			{
				count: 12,
				search,
				teamId: team,
				category: templateFilter,
				sort: templateSort,
			}
		)
		return (
			<main className={twoColumn.main}>
				<div className={pageHeader.root}>
					<div className={pageHeader.header}>
						<div className={pageHeader.contentNoWrap}>
							<div className={pageHeader.titleWithAvatar}>
								<div className={pageHeader.titleWithAction}>
									<h1 className={pageHeader.title}>Templates</h1>
								</div>
							</div>
							<div className={pageHeader.actions}>
								<Link
									className={buttons.primary}
									to={`/shopboard/create?tempCategory=${templateFilter}`}
								>
									<PlusIcon className={buttons.icon} />
									Add New
								</Link>
							</div>
						</div>
					</div>
				</div>
				<div className={controls.rootTabFilters}>
					{isAdmin && this.renderCategoryFilter()}
					{this.renderSortFilter()}
				</div>
				{templateListComp}
			</main>
		)
	}

	renderContent(folder) {
		const { container } = this
		const { relay, params, team, store } = this.props
		const { search } = this.state
		const { team: currentUserTeam } = store
		const ShopcastManagerComp =
			params.folderId === "template"
				? this.renderTemplates()
				: getComponent(
						"shopcast-manager",
						shopcastManager,
						{ folder: params.folderId, search, teamId: team },
						{
							folder: params.folderId,
							setCurrentFolderId: this.setFolderId,
							search,
							teamId: team,
						}
				  )

		return (
			<div className={twoColumn.root}>
				<aside className={twoColumn.side}>
					<div className={twoColumn.fixed}>
						<div className={twoColumn.fillHeight}>
							<div className={twoColumn.sideMain}>
								<div className={twoColumn.sideNav}>
									<div className={controls.group}>
										<SearchInputComponent
											placeholder="Search"
											ref={(ref) => {
												this.searchRef = ref
											}}
											defaultValue={search}
											setSearchText={(value) =>
												this.debouncedSearch("search", value)
											}
										/>
									</div>
									<Divider />
									<SidebarItem
										onDrop={({ id, type }) => {
											if (folder === "root") return
											moveShopcastItems.commit(
												relay.environment,
												[{ id, type }],
												null,
												team,
												this.folder.id,
												container.id,
												this.sendErrorsToToast(
													"Couldn't move to the root"
												)
											)
										}}
										item={{
											name: "Home",
											count: container.rootItemCount,
										}}
										path="/content"
										isActive={folder === "root"}
										accept={[
											"shopcast-card",
											"shopcast-folder",
											"lookbook-card",
											"shopshare-file-card",
										]}
										Icon={HomeIcon}
									/>
									<SidebarItem
										onDrop={({ id, isPublished, type }) => {
											if (folder === "public") return
											if (isPublished) return
											const getMutation = () => {
												if (type === "shopcast")
													return toggleShopcastPublishedState
												if (type === "shopshareFile")
													return toggleFilePublishedState
												if (type === "lookbook")
													return toggleLookbookPublishedState
												return null
											}
											getMutation()?.commit(
												relay.environment,
												id,
												this.folder.id,
												container.id,
												false,
												this.sendErrorsToToast(
													`Couldn't publish the ${type}`
												),
												team
											)
										}}
										item={{
											name: team ? "Team Channel" : "My Channel",
											count: container.publishedCount,
										}}
										path="/content/public"
										isActive={folder === "public"}
										accept={[
											"shopcast-card",
											"lookbook-card",
											"shopshare-file-card",
										]}
										Icon={
											team
												? () => (
														<Avatar
															styleName={styles.avatar}
															photoUrl={currentUserTeam.avatar}
															fullname={currentUserTeam.name}
															size="24px"
														/>
												  )
												: AvatarIcon
										}
									/>
									<SidebarItem
										onDrop={() => {}}
										item={{
											name: "Templates",
											count: 0,
										}}
										path="/content/template"
										isActive={folder === "template"}
										accept={[]}
										Icon={TemplateIcon}
									/>
								</div>
								{/*	{this.container.starredFolders.edges.length > 0 && (
									<Divider />
								)} */}
								<div className={twoColumn.scrollPanel}>
									<div className={twoColumn.sideContent}>
										{this.renderStarredList()}
									</div>
								</div>
								<div className={twoColumn.sideFooter}>
									<Divider />
									<SidebarItem
										onDrop={({ id, type }) => {
											if (folder === "archived") return
											archiveShopcastItem.commit(
												relay.environment,
												id,
												type,
												this.folder.id,
												this.sendErrorsToToast(
													"Couldn't remove folder"
												),
												container.id,
												() => {}
											)
										}}
										item={{
											name: "Trash",
											count: container.archivedItemCount,
										}}
										path="/content/archived"
										isActive={folder === "archived"}
										accept={[
											"shopcast-card",
											"shopcast-folder",
											"lookbook-card",
											"shopshare-file-card",
										]}
										Icon={CrumpledPaperIcon}
									/>
								</div>
							</div>
						</div>
					</div>
				</aside>
				{ShopcastManagerComp}
			</div>
		)
	}

	render() {
		let folder = null
		const { params } = this.props
		switch (params.folderId || null) {
			case null:
				folder = "root"
				break
			case "public":
				folder = "public"
				break
			case "archived":
				folder = "archived"
				break
			case "template":
				folder = "template"
				break
			default:
				folder = "any"
				break
		}
		return (
			<DndProvider backend={HTML5Backend}>
				{this.renderContent(folder)}
			</DndProvider>
		)
	}
}

//
// ─── DATA REQUIREMENTS ──────────────────────────────────────────────────────────
//

ShopcastManagerRoot = createFragmentContainer(ShopcastManagerRoot, {
	store: graphql`
		fragment shopcastManagerRoot_store on Store
		@argumentDefinitions(
			teamId: { type: "String" }
		) {
			id
			team(id: $teamId) {
				id
				name
				avatar
			}
			currentUser {
				id
				role
				lookbookTemplatesCategories
				shopcastContainer {
					id
					rootItemCount
					archivedItemCount
					publishedCount
					starredFolders(first: 10, teamId: $teamId)
						@connection(
							key: "currentUser_connectionManager_starredFolders"
							filters: ["first"]
						) {
						edges {
							node {
								id
								name
								count
								clients {
									id
									fullname
									avatar
								}
								sharableLink
								isParentAClient
							}
						}
					}
					rootFolders(first: 10)
						@connection(
							key: "currentUser_connectionManager_rootFolders"
							filters: ["first"]
						) {
						edges {
							node {
								id
								name
								count
							}
						}
					}
				}
			}
		}
	`,
})

const query = graphql`
	query shopcastManagerRootQuery($teamId: String) {
		store {
			...shopcastManagerRoot_store @arguments(teamId: $teamId)
		}
	}
`

export default {
	Component: withRouter(ShopcastManagerRoot),
	query,
	needAuth: true,
	params: (rp) => ({ teamId: rp.team }),
}
