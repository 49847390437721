/**
 * @generated SignedSource<<44abb571254f44fe6ef705bb532a03db>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentType } from "relay-runtime";
declare export opaque type updateUsername_currentUser$fragmentType: FragmentType;
export type updateUsername_currentUser$data = {|
  +bio: ?string,
  +displayName: ?string,
  +email: ?string,
  +fullname: ?string,
  +isRetailer: ?boolean,
  +isVerified: ?boolean,
  +socialLinks: ?{|
    +facebookUrl: ?string,
    +instagramUsername: ?string,
    +pinterestUrl: ?string,
    +twitterUsername: ?string,
    +website: ?string,
    +youtubeUrl: ?string,
  |},
  +username: ?string,
  +$fragmentType: updateUsername_currentUser$fragmentType,
|};
export type updateUsername_currentUser$key = {
  +$data?: updateUsername_currentUser$data,
  +$fragmentSpreads: updateUsername_currentUser$fragmentType,
  ...
};
*/

var node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "updateUsername_currentUser",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "displayName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "fullname",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "username",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "bio",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "email",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isRetailer",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isVerified",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "UserSocialLinks",
      "kind": "LinkedField",
      "name": "socialLinks",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "website",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "instagramUsername",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "facebookUrl",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "twitterUsername",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "youtubeUrl",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "pinterestUrl",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node/*: any*/).hash = "0bc108947aba7ed272ef5027be720122";

module.exports = ((node/*: any*/)/*: Fragment<
  updateUsername_currentUser$fragmentType,
  updateUsername_currentUser$data,
>*/);
