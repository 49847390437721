import { commitMutation, graphql } from "react-relay"
import { complete } from "../helper.js"

const mutation = graphql`
	mutation profilePhotoMutation($input: ProfilePhotoInput!) {
		profilePhoto(input: $input) {
			store {
				currentUser {
					profilePhoto
					displayAvatar
				}
			}
			user {
				profilePhoto
				displayAvatar
			}
		}
	}
`

const commit = (env, file, profileId, isDisplayAvatar, onCompleted, onError) =>
	commitMutation(env, {
		mutation,
		variables: {
			input: { imageName: file?.name, profileId, isDisplayAvatar },
		},
		uploadables: { file },
		onCompleted: complete(onCompleted, onError),
		onError: (e) => complete(null, [e]),
		updater: (storeProxy, data) => {
			const profileProxy = storeProxy.get(profileId)
			profileProxy.setValue(
				data.profilePhoto.user.profilePhoto,
				"profilePhoto"
			)
			profileProxy.setValue(
				data.profilePhoto.user.displayAvatar,
				"displayAvatar"
			)
		},
	})

export default { commit }
