import React, { Component, Fragment } from "react"
import Modal from "react-modal"
import { Cross2Icon } from "@radix-ui/react-icons"
import TextareaAutosize from "react-autosize-textarea"
import ClipboardButton from "react-clipboard.js"
import { toast } from "react-toastify"
import modal from "shared/styles/components/modal.css"
import button from "shared/styles/components/buttons.css"
import nav from "shared/styles/components/nav.css"
import textfield from "shared/styles/components/textfield.css"
import form from "shared/styles/components/form.css"
import styles from "./embed-shopcast.css"
import { baseFrontendUrl, publicViewDomain } from "../../../constants"

class EmbedShopcast extends Component {
	constructor(props) {
		super(props)

		this.state = {
			currentTab: "Embed",
		}

		this.clipboardCopySuccess = this.clipboardCopySuccess.bind(this)
	}

	componentDidMount() {
		const { props } = this
		const { shopcast, type } = props
		const domain =
			shopcast.user.customDomain ||
			`${shopcast.user.username}.${publicViewDomain}`

		if (shopcast && type === "shopcast") {
			this.permalink = `https://${domain}/shopcast/${shopcast.id}`
			// this.shortcode = `[shopshare code="${shopcast.id}"]`
			// this.downloadPluginUrl = `${baseUrl}/shopshare-shortcode.zip`
			this.embedCode = `<iframe src="${baseFrontendUrl}/watch/shopcast/${shopcast.id}" style="display:block;margin:auto;background:transparent;width:100%;max-width:1024px;border:0;" class="shopcastIframe"></iframe><script>window.ssFrmHeightAdjustFunc||(window.ssFrmHeightAdjustFunc=function(e){if(e.data.height)for(var t,n=document.getElementsByClassName("shopcastIframe"),s=0;s<n.length;s++)if(((t=n[s]).contentWindow||t.contentDocument.defaultView)===e.source){t.style.height=e.data.height+"px";break}},window.addEventListener("message",window.ssFrmHeightAdjustFunc));</script>`
		}
		if (shopcast && type === "lookbook") {
			this.permalink = `https://${domain}/shopboard/${shopcast.id}`
			// this.shortcode = `[shopshare code="${shopcast.id}"]`
			// this.downloadPluginUrl = `${baseUrl}/shopboard-shortcode.zip`
			this.embedCode = `<iframe src="${baseFrontendUrl}/watch/shopboard/${shopcast.id}" style="display:block;margin:auto;background:transparent;width:100%;max-width:1024px;border:0;" class="shopcastIframe"></iframe><script>window.ssFrmHeightAdjustFunc||(window.ssFrmHeightAdjustFunc=function(e){if(e.data.height)for(var t,n=document.getElementsByClassName("shopcastIframe"),s=0;s<n.length;s++)if(((t=n[s]).contentWindow||t.contentDocument.defaultView)===e.source){t.style.height=e.data.height+"px";break}},window.addEventListener("message",window.ssFrmHeightAdjustFunc));</script>`
		}
	}

	changeTabState(e, toState) {
		e.preventDefault()
		this.setState({ currentTab: toState })
	}

	clipboardCopySuccess() {
		toast.success(<Fragment>Copied</Fragment>, {
			autoClose: 2000,
			closeButton: false,
		})
	}

	renderTab() {
		const { state } = this
		if (state.currentTab === "Permalink") {
			return this.renderPermalinkTab()
		}
		return this.renderEmbedTab()

		/* if (state.currentTab === "Embed") {
			return this.renderEmbedTab()
		}
		return this.renderWordpressTab() */
	}

	// renderWordpressTab() {
	// 	return (
	// 		<div className={styles.content}>
	// 			<p>
	// 				<b>Step 1:</b> Download and install the ShopShare Wordpress
	// 				plugin by <a href="">following these instructions.</a>
	// 			</p>
	// 			<a
	// 				className={button.subtle}
	// 				href={this.downloadPluginUrl}
	// 				target="_self"
	// 			>
	// 				Download Wordpress Plugin
	// 			</a>
	// 			<p>
	// 				<b>Step 2:</b> Copy and paste the Shortcode to embed it in your
	// 				post.
	// 			</p>

	// 			<div className={form.group}>
	// 				<label className={form.label}>Shortcode</label>
	// 				<div className={form.inputGroup}>
	// 					<input
	// 						id="d"
	// 						className={textfield.normal}
	// 						type="text"
	// 						value={this.shortcode}
	// 						readOnly
	// 					/>
	// 					<ClipboardButton
	// 						className={styles.btnPermalink}
	// 						data-clipboard-text={this.shortcode}
	// 						onSuccess={this.clipboardCopySuccess}
	// 					>
	// 						Copy
	// 					</ClipboardButton>
	// 				</div>
	// 			</div>
	// 		</div>
	// 	)
	// }

	renderEmbedTab() {
		return (
			<div className={styles.content}>
				<div className={form.group} id="embed">
					<p>Copy this code and paste it into your website:</p>
					<TextareaAutosize
						className={textfield.textarea}
						rows={7}
						value={this.embedCode}
						readOnly
					/>
					<ClipboardButton
						className={styles.btnEmbed}
						data-clipboard-text={this.embedCode}
						onSuccess={this.clipboardCopySuccess}
					>
						Copy
					</ClipboardButton>
				</div>
			</div>
		)
	}

	renderPermalinkTab() {
		return (
			<div className={styles.content}>
				<div className={form.group}>
					<label className={form.label}>Permalink</label>
					<div className={form.inputGroup}>
						<input
							className={textfield.normal}
							type="text"
							value={this.permalink}
							readOnly
						/>
						<ClipboardButton
							className={styles.btnPermalink}
							data-clipboard-text={this.permalink}
							onSuccess={this.clipboardCopySuccess}
						>
							Copy
						</ClipboardButton>
					</div>
				</div>
			</div>
		)
	}

	render() {
		const { state, props } = this
		if (!props.shopcast) return false
		return (
			<Modal
				className={modal.root}
				overlayClassName={modal.overlay}
				htmlOpenClassName="ReactModal__Html--open"
				isOpen={props.showEmbedModal}
				ariaHideApp={false}
			>
				<div
					className={`${modal.dialog} ${modal.dialogCentered} ${modal.dialogFullscreenSmDown} ${modal.dialogLg}`}
				>
					<div className={modal.content}>
						<div className={`${modal.header}`}>
							<h4 className={modal.title}>Embed</h4>
							<button
								type="button"
								className={button.close}
								onClick={props.closeEmbedModal}
							>
								<Cross2Icon className={button.closeIcon} />
							</button>
						</div>
						<div className={modal.bodyp0}>
							<div className={`${nav.rootHasBorder}`}>
								<nav className={nav.tabs}>
									<div className={nav.tabsFit}>
										{/* <a
											className={
												state.currentTab === "Wordpress"
													? nav.tabActive
													: nav.tab
											}
											onClick={(e) =>
												this.changeTabState(e, "Wordpress")
											}
											onKeyPress={(e) =>
												this.changeTabState(e, "Wordpress")
											}
											role="link"
											tabIndex={0}
										>
											Wordpress
										</a> */}
										<a
											className={
												state.currentTab === "Embed"
													? nav.tabActive
													: nav.tab
											}
											onClick={(e) =>
												this.changeTabState(e, "Embed")
											}
											onKeyPress={(e) =>
												this.changeTabState(e, "Permalink")
											}
											role="link"
											tabIndex={0}
										>
											Embed
										</a>
										<a
											className={
												state.currentTab === "Permalink"
													? nav.tabActive
													: nav.tab
											}
											onClick={(e) =>
												this.changeTabState(e, "Permalink")
											}
											onKeyPress={(e) =>
												this.changeTabState(e, "Permalink")
											}
											role="link"
											tabIndex={0}
										>
											Permalink
										</a>
									</div>
								</nav>
							</div>

							{this.renderTab()}
						</div>

						{/* <div className={modal.footerCentered}>
							<button
								type="button"
								className={buttons.primary}
								onClick={props.closeEmbedModal}
							>
								Done
							</button>
						</div> */}
					</div>
				</div>
			</Modal>
		)
	}
}

export default EmbedShopcast
