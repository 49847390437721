
import { commitMutation, graphql } from "react-relay"
import { complete } from "../helper"

const mutation = graphql`
	mutation updateLookbookShapesMutation(
		$input: CreateUpdateShapesInput!,
	) {
		createUpdateShapes(input: $input) {
			shape{
        id
        viewBox
        path
        collection
      }
		}
	}
`

const commit = (env, data, args, onError, onCompleted) => commitMutation(env, {
  mutation,
  variables: { input: { id: data?.id, viewBox: data.viewBox, collection: data.collection, path: data.path } },
  onError: complete(onCompleted, onError),
  onCompleted: complete(onCompleted, onError),
})

export default { commit }
