import { commitMutation, graphql } from "react-relay"
import { complete } from "../helper.js"

const mutation = graphql`
	mutation getInTouchMutation($input: GetInTouchInput!) {
		getInTouch(input: $input) {
			store {
				id
			}
		}
	}
`

const commit = (env, data, onCompleted, onError) =>
	commitMutation(env, {
		mutation,
		variables: { input: data },
		onCompleted: complete(onCompleted, onError),
		onError: (e) => complete(null, [e]),
	})

export default { commit }
