import React from "react"
import { createFragmentContainer, graphql } from "react-relay"
import { Helmet } from "react-helmet"
import PageFooter from "components/page-footer/page-footer"
import LandingActions from "components/landing-actions/landing-actions"
import landing from "shared/styles/layout/landing.css"
import ScrollToTopOnMount from "components/scroll-to-top/scroll-to-top"
import styles from "./for-content-marketing.css"

function ForContentMarketing({ store }) {
	const user = store.currentUser
	return (
		<div className={styles.root}>
			<Helmet>
				<title>ShopShare for Brands</title>
			</Helmet>
			<ScrollToTopOnMount />

			<section className={`${landing.section} ${landing.gray100}`}>
				<div className={landing.content}>
					<h1 className={styles.displayTitle}>
						Virtual Shopping Experiences
					</h1>
					<p className={landing.displayLead}>
						Tell stories, launch new products and provide trusted
						recommendations
					</p>
					{!user && (
						<div className={landing.actions}>
							<LandingActions />
						</div>
					)}
				</div>
			</section>

			<section className={landing.section}>
				<div className={landing.contentPageFooter}>
					<PageFooter
						title="Use ShopShare for Ecommerce"
						lead="Emulate the in-store feel for your customers shopping at home!"
						currentUser={user}
					/>
				</div>
			</section>
		</div>
	)
}

// eslint-disable-next-line no-func-assign
ForContentMarketing = createFragmentContainer(ForContentMarketing, {
	store: graphql`
		fragment forContentMarketing_store on Store {
			id
			currentUser {
				id
			}
		}
	`,
})

const query = graphql`
	query forContentMarketingQuery {
		store {
			...forContentMarketing_store
		}
	}
`

export default { Component: ForContentMarketing, query }
