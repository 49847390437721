/* eslint-disable react/jsx-pascal-case */
import React, { useRef } from "react"
import clientViewCollections from "./client-view-collections"
import clientViewCollectionList from "./client-view-collection-list"
import { justRender } from "../../route-helpers"

function ClientViewCollectionView(props) {
    const {collectionId} = props
	const ClientViewCollectionsList = useRef(
		justRender(clientViewCollectionList)
	)
	const ClientViewCollection = useRef(
		justRender(clientViewCollections)
	)
	return collectionId ? (
		<ClientViewCollection.current {...props} />
	) : (
		<ClientViewCollectionsList.current {...props} />
	)
}

export default ClientViewCollectionView
