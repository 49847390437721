/**
 * @generated SignedSource<<c534a88da615a2971274c5ac30500d25>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Mutation } from 'relay-runtime';
export type RemoveCountryFromRakutenSiteInput = {|
  clientMutationId?: ?string,
  country?: ?string,
  site?: ?string,
|};
export type removeCountryFromRakutenSiteMutation$variables = {|
  input: RemoveCountryFromRakutenSiteInput,
|};
export type removeCountryFromRakutenSiteMutation$data = {|
  +removeCountryFromRakutenSite: ?{|
    +countries: ?$ReadOnlyArray<?{|
      +id: string,
      +name: ?string,
    |}>,
    +store: ?{|
      +rakutenSiteRegions: ?{|
        +edges: ?$ReadOnlyArray<?{|
          +node: ?{|
            +id: string,
            +name: ?string,
          |},
        |}>,
      |},
    |},
  |},
|};
export type removeCountryFromRakutenSiteMutation = {|
  response: removeCountryFromRakutenSiteMutation$data,
  variables: removeCountryFromRakutenSiteMutation$variables,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "name",
    "storageKey": null
  },
  (v2/*: any*/)
],
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "Country",
  "kind": "LinkedField",
  "name": "countries",
  "plural": true,
  "selections": (v3/*: any*/),
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "StoreCountryConnection",
  "kind": "LinkedField",
  "name": "rakutenSiteRegions",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "StoreCountryEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Country",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": (v3/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "removeCountryFromRakutenSiteMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "RemoveCountryFromRakutenSitePayload",
        "kind": "LinkedField",
        "name": "removeCountryFromRakutenSite",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Store",
            "kind": "LinkedField",
            "name": "store",
            "plural": false,
            "selections": [
              (v5/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "ShopshareMutationRoot",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "removeCountryFromRakutenSiteMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "RemoveCountryFromRakutenSitePayload",
        "kind": "LinkedField",
        "name": "removeCountryFromRakutenSite",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Store",
            "kind": "LinkedField",
            "name": "store",
            "plural": false,
            "selections": [
              (v5/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "9a799ebbaa5c9d8c29047116e9d83cfc",
    "id": null,
    "metadata": {},
    "name": "removeCountryFromRakutenSiteMutation",
    "operationKind": "mutation",
    "text": "mutation removeCountryFromRakutenSiteMutation(\n  $input: RemoveCountryFromRakutenSiteInput!\n) {\n  removeCountryFromRakutenSite(input: $input) {\n    countries {\n      name\n      id\n    }\n    store {\n      rakutenSiteRegions {\n        edges {\n          node {\n            name\n            id\n          }\n        }\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "b259ac0cab89d9d2366800aa96a75763";

module.exports = ((node/*: any*/)/*: Mutation<
  removeCountryFromRakutenSiteMutation$variables,
  removeCountryFromRakutenSiteMutation$data,
>*/);
