/**
 * @generated SignedSource<<0f6788fd670c1dc2599976a59ed692a1>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Query } from 'relay-runtime';
import type { home_store$fragmentType } from "./home_store.graphql";
export type homeQuery$variables = {||};
export type homeQuery$data = {|
  +store: ?{|
    +$fragmentSpreads: home_store$fragmentType,
  |},
|};
export type homeQuery = {|
  response: homeQuery$data,
  variables: homeQuery$variables,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "homeQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Store",
        "kind": "LinkedField",
        "name": "store",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "home_store"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "ShopshareQueryRoot",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "homeQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Store",
        "kind": "LinkedField",
        "name": "store",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "currentUser",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasPendingSharerRequests",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isANewUser",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "88bc98646bc4c8d9186dcf1916388d6d",
    "id": null,
    "metadata": {},
    "name": "homeQuery",
    "operationKind": "query",
    "text": "query homeQuery {\n  store {\n    ...home_store\n    id\n  }\n}\n\nfragment home_store on Store {\n  id\n  currentUser {\n    id\n    hasPendingSharerRequests\n    isANewUser\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "d38d0c1e32f085b29b3c858cacf775db";

module.exports = ((node/*: any*/)/*: Query<
  homeQuery$variables,
  homeQuery$data,
>*/);
