import React, { Component } from "react"
import { createPaginationContainer, graphql } from "react-relay"
import InfiniteScroll from "react-infinite-scroller"
import LoadingDots from "components/loading-dots/loading-dots"
import ImageCard from "components/cards/lookbook/image-card"
import grids from "shared/styles/components/grids.css"
import empty from "shared/styles/components/empty.css"
import { toast } from "react-toastify"
import setClosetOwnedItems from "mutations/product-collection/set-closet-owned-items"
import gqlHelper from "helpers/gql-helper"
import { withRouter } from "../../route-helpers"
import ItemReferencesModal from "../../components/item-references/item-references"

class ClientViewCollections extends Component {
	constructor(props) {
		super(props)
		this.state = {
			itemReferenceId: null,
		}
		this.handleLoadMore = this.handleLoadMore.bind(this)
		if (props.setCollectionList)
			props.setCollectionList(
				props.store?.currentUser?.sharedFolderCollectionsList.edges.map(
					({ node }) => node
				) || []
			)
	}

	handleLoadMore() {
		const { relay } = this.props
		relay.loadMore(12, () => {})
	}

	setItemToOwnedItems(itemId) {
		const { relay, clientId } = this.props
		setClosetOwnedItems.commit(
			relay.environment,
			itemId,
			clientId,
			(err) => {
				toast.error(
					gqlHelper.getError(err) || "Error item adding to owned items.",
					{ autoClose: 2000, closeButton: false }
				)
			},
			() => {}
		)
	}

	loadCard(x) {
		return (
			<ImageCard
				key={x.node.id || x.node.__id}
				node={x?.node}
				price={x?.node?.offerPrice || x?.node?.regularPrice}
				description={x?.node?.title}
				canEdit
				onOwnedItems={
					x?.node?.__typename === "ShopcastProduct"
						? (id) => this.setItemToOwnedItems(id)
						: null
				}
				showReferenceTitle="How to Style"
				showReferences={() => {
					this.setState({ itemReferenceId: x.node.id })
				}}
			/>
		)
	}

	render() {
		const { props, state } = this
		const { store, relay, removeInfiniteScroll } = props
		const { sharedFolderCollectionItems: items } = store.currentUser
		if (!items || items.edges.length <= 0)
			return <div className={empty.simple}>There&apos;s nothing here</div>
		return (
			<div className={grids.container}>
				{!removeInfiniteScroll && (
					<InfiniteScroll
						loadMore={this.handleLoadMore}
						hasMore={relay.hasMore()}
						loader={
							<div className={grids.loadMore} key="cvc-loading">
								<LoadingDots />
							</div>
						}
						pageStart={0}
					>
						<div className={grids.imageMd}>
							{items.edges.map((x) => this.loadCard(x))}
						</div>
					</InfiniteScroll>
				)}
				{removeInfiniteScroll && (
					<>
						<div className={grids.imageMd}>
							{items.edges.map((x) => this.loadCard(x))}
						</div>
						{relay.hasMore() && (
							<div className={grids.loadMore}>
								<button
									type="button"
									className={grids.loadMoreBtn}
									onClick={this.handleLoadMore}
								>
									Show more
								</button>
							</div>
						)}
					</>
				)}
				<ItemReferencesModal
					itemId={state.itemReferenceId}
					folderId={props.clientId}
					modalTitle="How to Style"
					closeModal={() => {
						this.setState({ itemReferenceId: null })
					}}
				/>
			</div>
		)
	}
}

const query = graphql`
	query clientViewCollectionsQuery(
		$count: Int!
		$cursor: String
		$folder: String!
		$searchText: String
		$collectionId: String
		$clientId: String
		$sort: String
	) {
		store {
			...clientViewCollections_store
				@arguments(
					count: $count
					cursor: $cursor
					folder: $folder
					searchText: $searchText
					collectionId: $collectionId
					clientId: $clientId
					sort: $sort
				)
		}
	}
`

ClientViewCollections = createPaginationContainer(
	ClientViewCollections,
	{
		store: graphql`
			fragment clientViewCollections_store on Store
			@argumentDefinitions(
				count: { type: "Int", defaultValue: 12 }
				cursor: { type: "String" }
				searchText: { type: "String" }
				collectionId: { type: "String" }
				folder: { type: "String!" }
				clientId: { type: "String", defaultValue: null }
				sort: { type: "String" }
			) {
				id
				currentUser {
					id
					sharedFolderCollectionsList(folderId: $folder) {
						edges {
							node {
								id
								title
							}
						}
					}
					sharedFolderCollectionItems(
						first: $count
						after: $cursor
						folderId: $folder
						collectionId: $collectionId
						searchText: $searchText
						sort: $sort
					) @connection(key: "currentUser_sharedFolderCollectionItems") {
						edges {
							node {
								__typename
								... on ShopcastProduct {
									id
									title
									brand
									description
									imageUrl
									diffbotImageUrl
									regularPrice
									offerPrice
									productCollections {
										id
										title
									}
									url
									affiliated_url
									user {
										id
										fullname
										username
										profilePhoto
										customDomain
									}
									shopcastItems {
										id
										shopcast {
											id
											title
										}
										start
										end
									}
									isOwnedItems(clientId: $clientId)
								}
								... on lookbookUploads {
									id
									adminAccess
									title
									productCollections {
										id
										title
									}
									url
								}
							}
						}
					}
				}
			}
		`,
	},
	{
		direction: "forward",
		getConnectionFromProps(props) {
			const { currentUser } = props.store
			return currentUser && currentUser.sharedFolderCollectionItems
		},
		getFragmentVariables(prevVars, totalCount) {
			return {
				...prevVars,
				count: totalCount,
			}
		},
		getVariables(props, { count, cursor }) {
			return {
				count,
				cursor,
				folder: props.params.tab,
				searchText: props.collectionSearchText,
				collectionId: props.collectionId,
				clientId: props.clientId,
				sort: props.sort,
			}
		},
		query,
	}
)

export default {
	Component: withRouter(ClientViewCollections),
	query,
	params: (rp) => ({
		count: rp.count,
		cursor: null,
		folder: rp.params.tab,
		searchText: rp.collectionSearchText,
		collectionId: rp.collectionId,
		clientId: rp.clientId,
		sort: rp.sort,
	}),
}
