import React, { Component } from "react"
import PlayIcon from "shared/assets/play-icon.svg"
import PauseIcon from "shared/assets/pause-icon.svg"
import DropdownMenu from "components/dropdown-menu/dropdown-menu"
import StatsSales from "components/stats-sales/stats-sales"
import MenuIcon from "shared/assets/menu-dots-horizontal.svg"
import stylesDropdown from "components/dropdown-menu/dropdown-menu-dark.css"
import { addVersioning } from "helpers/query-parameters-helper"
import buttons from "shared/styles/components/buttons.css"
import styles from "./shop-card.css"

class ShopCard extends Component {
	getActionButton = (product) => {
		const { playControls, isActive } = this.props
		if (!playControls) return null
		const active = isActive && playControls.isPlaying
		return (
			<button
				type="button"
				className={styles.play}
				onClick={() =>
					active
						? playControls.pauseVideo()
						: playControls.playProduct(product)
				}
			>
				{active ? (
					<PauseIcon className={styles.pauseIcon} />
				) : (
					<PlayIcon className={styles.playIcon} />
				)}
			</button>
		)
	}

	renderMenu() {
		const { productActions } = this.props
		if (!productActions || productActions.length === 0) return null
		const icon = <MenuIcon className={stylesDropdown.icon} />
		return (
			<DropdownMenu
				mainIcon={icon}
				items={productActions}
				contentRight
				darkTheme
				triggerClass={buttons.dropdownIcon}
				triggerClassActive={buttons.dropdownIconActive}
			/>
		)
	}

	renderStats() {
		const {
			// product,
			transactions,
			enableStats,
			setShowTransactions,
		} = this.props
		if (!enableStats) return null
		// if (!!product.custom_affiliated_url)
		// 	return <div className={styles.empty}>Custom Affiliated URL</div>
		// if (product.url === product.affiliated_url)
		// 	return <div className={styles.empty}>No Affiliated URL</div>
		return (
			<StatsSales
				transactions={transactions}
				setShowTransactions={setShowTransactions}
			/>
		)
	}

	render() {
		const { product, isActive, error } = this.props
		return (
			<div
				className={isActive ? styles.rootActive : styles.root}
				id={`product${product.id}`}
			>
				<div className={styles.card}>
					{!error && (
						<div className={isActive ? styles.mediaActive : styles.media}>
							<div className={styles.figure}>
								<div className={styles.inner}>
									{this.getActionButton(product)}
									<img
										className={styles.poster}
										src={addVersioning(product.imageUrl, `d=400`)}
										onError={(e) => {
											if (e.target.fb) {
												return
											}
											e.target.src = product.imageUrl
											e.target.fb = true
										}}
										alt="Product"
									/>
								</div>
							</div>
						</div>
					)}
					<div className={styles.content}>
						<div className={styles.details}>
							<h4 className={styles.title}>
								{product.title ? product.title : "No title"}
							</h4>
							{!error && (product.regularPrice || product.offerPrice) && (
								<p className={styles.price}>
									{product.offerPrice && (
										<span className={styles.offerPrice}>
											{product.offerPrice}
										</span>
									)}
									{product.regularPrice && (
										<span
											className={
												product.offerPrice
													? styles.regularPriceStrike
													: styles.regularPrice
											}
										>
											{product.regularPrice}
										</span>
									)}
								</p>
							)}
						</div>
						<div className={styles.actions}>
							<a
								className={styles.buy}
								href={
									product.affiliated_url
										? product.affiliated_url
										: product.url
								}
								target="_blank"
								rel="noopener noreferrer"
							>
								Shop
							</a>

							{this.renderMenu()}
						</div>
					</div>
				</div>
				{this.renderStats()}
			</div>
		)
	}
}

export default ShopCard
