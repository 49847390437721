/**
 * @generated SignedSource<<d4cb77bb38959e34dac3563cd8ca2c4a>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { lookbookCard_currentUser$fragmentType } from "./../../../components/cards/lookbook-card/__generated__/lookbookCard_currentUser.graphql";
import type { lookbookCard_lookbook$fragmentType } from "./../../../components/cards/lookbook-card/__generated__/lookbookCard_lookbook.graphql";
import type { FragmentType } from "relay-runtime";
declare export opaque type clientViewLookbooks_store$fragmentType: FragmentType;
export type clientViewLookbooks_store$data = {|
  +currentUser: ?{|
    +id: string,
    +sharedFolderLookbooks: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +$fragmentSpreads: lookbookCard_lookbook$fragmentType,
        |},
      |}>,
    |},
    +$fragmentSpreads: lookbookCard_currentUser$fragmentType,
  |},
  +id: string,
  +$fragmentType: clientViewLookbooks_store$fragmentType,
|};
export type clientViewLookbooks_store$key = {
  +$data?: clientViewLookbooks_store$data,
  +$fragmentSpreads: clientViewLookbooks_store$fragmentType,
  ...
};
*/

var node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": 12,
      "kind": "LocalArgument",
      "name": "count"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "cursor"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "folder"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "sort"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "count",
        "cursor": "cursor",
        "direction": "forward",
        "path": [
          "currentUser",
          "sharedFolderLookbooks"
        ]
      }
    ]
  },
  "name": "clientViewLookbooks_store",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "currentUser",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "lookbookCard_currentUser"
        },
        {
          "alias": "sharedFolderLookbooks",
          "args": [
            {
              "kind": "Variable",
              "name": "folderId",
              "variableName": "folder"
            },
            {
              "kind": "Variable",
              "name": "sort",
              "variableName": "sort"
            }
          ],
          "concreteType": "LookbookConnection",
          "kind": "LinkedField",
          "name": "__currentUser_sharedFolderLookbooks_connection",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "LookbookEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Lookbook",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    {
                      "args": null,
                      "kind": "FragmentSpread",
                      "name": "lookbookCard_lookbook"
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "__typename",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "cursor",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "PageInfo",
              "kind": "LinkedField",
              "name": "pageInfo",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "endCursor",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "hasNextPage",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Store",
  "abstractKey": null
};
})();

(node/*: any*/).hash = "d9a6fb071344f2d65a019400bb108d48";

module.exports = ((node/*: any*/)/*: Fragment<
  clientViewLookbooks_store$fragmentType,
  clientViewLookbooks_store$data,
>*/);
