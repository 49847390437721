import React, { Component } from "react"
import { toast } from "react-toastify"
import toggle from "shared/styles/components/toogle.css"
import contentCard from "shared/styles/layout/content-card.css"
import UpdateOptOutAffiliate from "mutations/user/update-opt-out-affiliate"
import gqlHelper from "helpers/gql-helper"
import styles from "./payments.css"

class Payments extends Component {
	constructor(props) {
		super(props)
		this.state = {
			optOutAffiliate: props.store.currentUser.optOutAffiliate,
		}
		this.onUpdateSuccess = this.onUpdateSuccess.bind(this)
	}

	onUpdateSuccess() {
		const { store } = this.props
		const { optOutAffiliate } = store.currentUser
		this.setState({ optOutAffiliate })
		toast.success(<>Updated successfully.</>, {
			autoClose: 2000,
			closeButton: false,
		})
	}

	onUpdateFailure(e) {
		toast.error(
			gqlHelper.getError(e) || "Error updating account details.",
			{ autoClose: 2000, closeButton: false }
		)
	}

	updateOptOutAffiliate() {
		const { props } = this
		const { optOutAffiliate } = this.state
		UpdateOptOutAffiliate.commit(
			props.relay.environment,
			!optOutAffiliate,
			props.store.id,
			this.onUpdateSuccess,
			this.onUpdateFailure
		)
	}

	render() {
		const { optOutAffiliate } = this.state
		return (
			<div className={contentCard.root}>
				<div className={contentCard.header}>
					<h4 className={contentCard.title}>Payments</h4>
				</div>
				<div className={contentCard.content}>
					<div className={styles.account}>
						<div className={styles.meta}>
							<h4 className={styles.title}>
								Opt-out of the affiliate program
							</h4>
							<p className={styles.description}>
								Your shopcasts will not have affliated Links
							</p>
						</div>
						<div className={styles.action}>
							<label className={toggle.check}>
								<div className={toggle.switch}>
									<input
										type="checkbox"
										checked={optOutAffiliate}
										onChange={() => {
											this.updateOptOutAffiliate()
										}}
									/>
									<div className={toggle.slider} />
								</div>
							</label>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

export default Payments
