import React, { Component } from "react"
import { createFragmentContainer, graphql } from "react-relay"
import { Transition } from "react-transition-group"
import { Link } from "react-router-dom"
import {
	Pencil1Icon,
	TrashIcon,
	CheckCircledIcon,
	EnterIcon,
	// MinusIcon,
} from "@radix-ui/react-icons"
import { toast } from "react-toastify"
import { withDrag } from "helpers/dnd-helper"
import PinIcon from "shared/assets/pin-icon.svg"
import UnPinIcon from "shared/assets/unpin-icon.svg"
import PinButton from "components/buttons/pin-button"
import DeleteProductCollectionMutation from "mutations/product-collection/delete-product-collection"
// import RemoveCollectionFromFolderMutation from "mutations/product-collection/remove-collection-from-folder"
import UpdateProductCollectionMutation from "mutations/product-collection/update-product-collection"
import DropdownMenu from "components/dropdown-menu/dropdown-menu"
import { ShowCardControlsComponent } from "helpers/ui-helper"
import ContentEditable from "components/content-editable/content-editable"
import MenuIcon from "shared/assets/menu-dots-horizontal.svg"
import stylesDropdown from "components/dropdown-menu/dropdown-menu-dark.css"
import button from "shared/styles/components/buttons.css"
import card from "shared/styles/components/card.css"
import input from "shared/styles/components/input.css"
import styles from "./user-product-collection-card.css"
import { withRouter } from "../../../route-helpers"

class ProductCollectionCard extends Component {
	constructor(props) {
		super(props)
		this.state = {
			titleInFocus: false,
			isDropdownOpen: false,
		}
		this.renderMenu = this.renderMenu.bind(this)
		this.deleteCollection = this.deleteCollection.bind(this)
		this.handleCheckboxChange = this.handleCheckboxChange.bind(this)
		this.updateCollectionTitle = this.updateCollectionTitle.bind(this)

		this.type = "collection"
	}

	handleCheckboxChange({ target }) {
		const { checked } = target
		const { onSelect, onDeselect, collection } = this.props
		;(checked ? onSelect : onDeselect)({
			id: collection.id,
			type: this.type,
			deselect: () => {
				this.handleCheckboxChange({ target: { checked: false } })
			},
		})
	}

	getImageGridClass(images) {
		if (images.length > 3) return card.imageGrid4
		if (images.length > 2) return card.imageGrid3
		if (images.length > 1) return card.imageGrid2
		return card.imageGrid1
	}

	deleteCollection() {
		const { relay, collection, parentId, containerId } = this.props
		DeleteProductCollectionMutation.commit(
			relay.environment,
			collection.id,
			() => {},
			() => {
				toast.success(<>Collection deleted successfully!</>, {
					autoClose: 2000,
					closeButton: false,
				})
			},
			{
				parentId,
				containerId,
			}
		)
	}

	// removeCollectionFromFolder() {
	// 	const { relay, collection, parentId, containerId } = this.props
	// 	RemoveCollectionFromFolderMutation.commit(
	// 		relay.environment,
	// 		collection.id,
	// 		() => {},
	// 		() => {
	// 			toast.success(<>Collection removed successfully!</>, {
	// 				autoClose: 2000,
	// 				closeButton: false,
	// 			})
	// 		},
	// 		{
	// 			parentId,
	// 			containerId,
	// 		}
	// 	)
	// }

	updateCollectionTitle(title) {
		const { relay, collection, parentId, containerId } = this.props
		UpdateProductCollectionMutation.commit(
			relay.environment,
			{
				id: collection.id,
				title,
				folder: collection.folder?.id,
				clientId: collection.client?.id,
				isPublished: collection.isPublished,
			},
			(error) => {
				console.log(error)
			},
			() => {
				toast.success(<>Collection title updated!</>, {
					autoClose: 2000,
					closeButton: false,
				})
			},
			{
				parentId,
				containerId,
			}
		)
	}

	renderMenu(c) {
		const {
			requestEdit,
			confirmDelete,
			togglePinned,
			collection,
			isSelected,
			searchValue,
			navigate,
		} = this.props
		const listItems = []
		const push = (click, name, key, icon) =>
			listItems.push({
				click,
				name,
				key,
				icon,
			})
		const icon = <MenuIcon className={stylesDropdown.icon} />
		// if (requestEdit && confirmDelete)
		// 	push(
		// 		() => this.removeCollectionFromFolder(),
		// 		"Remove",
		// 		"remove",
		// 		<MinusIcon className={`${stylesDropdown.itemIcon}`} />
		// 	)
		// listItems.push("divide")
		if (c.folder && searchValue) {
			push(
				() => navigate(`/content/${c.folder.id}`),
				"Go to Folder",
				"gtf",
				<EnterIcon className={`${stylesDropdown.itemIcon}`} />
			)
			listItems.push("divide")
		}
		if (requestEdit)
			push(
				() => requestEdit({ ...c, client: c.client?.id }),
				"Edit Collection",
				"edit",
				<Pencil1Icon className={`${stylesDropdown.itemIcon}`} />
			)
		push(
			togglePinned,
			collection.isPinned ? "Unpin Collection" : "Pin Collection",
			"pin",
			collection.isPinned ? (
				<UnPinIcon className={stylesDropdown.itemIcon} />
			) : (
				<PinIcon className={stylesDropdown.itemIcon} />
			)
		)
		push(
			() => {
				this.handleCheckboxChange({
					target: { checked: !isSelected },
				})
			},
			"Select Items",
			"select",
			<CheckCircledIcon className={stylesDropdown.itemIcon} />
		)
		listItems.push("divide")
		if (confirmDelete)
			push(
				() => confirmDelete(c, this.deleteCollection),
				"Delete Forever",
				"deletePerm",
				<TrashIcon className={`${stylesDropdown.itemIcon}`} />
			)
		return (
			<DropdownMenu
				mainIcon={icon}
				items={listItems}
				contentRight
				darkTheme
				triggerClass={button.lightIcon}
				triggerClassActive={button.lightIconActive}
				setDropdownOpened={(val) => {
					this.setState({ isDropdownOpen: val })
				}}
			/>
		)
	}

	render() {
		const {
			collection,
			enableCheckBox,
			dragRef,
			isSelected,
			enableCardSelection,
			togglePinned,
		} = this.props
		const { titleInFocus, isDropdownOpen } = this.state
		const figure = (
			<div className={styles.figure}>
				<div className={styles.inner}>
					<div className={this.getImageGridClass(collection.images)}>
						<div className={card.igItem1}>
							{collection.images.length > 0 && (
								<img
									className={styles.poster}
									loading="lazy"
									src={collection.images[0]}
									alt="Poster"
								/>
							)}
						</div>
						<div className={card.igItem2}>
							{collection.images.length > 1 && (
								<img
									className={styles.poster}
									loading="lazy"
									src={collection.images[1]}
									alt="Poster"
								/>
							)}
						</div>
						<div className={card.igItem3}>
							{collection.images.length > 2 && (
								<img
									className={styles.poster}
									loading="lazy"
									src={collection.images[2]}
									alt="Poster"
								/>
							)}
						</div>
						<div className={card.igItem4}>
							{collection.images.length > 3 && (
								<img
									className={styles.poster}
									loading="lazy"
									src={collection.images[3]}
									alt="Poster"
								/>
							)}
						</div>
					</div>
				</div>
			</div>
		)
		return (
			<ShowCardControlsComponent
				innerRef={titleInFocus ? null : dragRef}
				className={styles.root}
				key={collection.id}
			>
				{(isShown) => (
					<>
						<div
							className={
								isSelected
									? `${styles.media} ${styles.active}`
									: styles.media
							}
						>
							<Transition
								in={isShown || enableCardSelection}
								timeout={200}
							>
								{(state) =>
									enableCheckBox && (
										<div
											className={`${styles.select} ${styles[state]}`}
										>
											<input
												type="checkbox"
												checked={isSelected}
												className={`${input.checkbox} ${input.lg}`}
												onChange={this.handleCheckboxChange}
											/>
										</div>
									)
								}
							</Transition>
							<Transition
								in={(isShown || isDropdownOpen) && !enableCardSelection}
								timeout={200}
							>
								{(state) => (
									<div className={`${styles.menu} ${styles[state]}`}>
										{this.renderMenu(collection)}
									</div>
								)}
							</Transition>
							{enableCardSelection ? (
								<div
									className={card.link}
									onClick={() => {
										this.handleCheckboxChange({
											target: { checked: !isSelected },
										})
									}}
									role="button"
									tabIndex={0}
								>
									{figure}
								</div>
							) : (
								<Link
									className={card.link}
									to="/collections"
									onClick={() => {
										window.sessionStorage.setItem(
											"collection-filters",
											JSON.stringify({
												client: collection.client?.id,
												collections: [
													{
														id: collection.id,
														title: collection.title,
														user: collection.user,
														client: collection.client?.id,
														isACloset: collection.isACloset,
														folder: {
															id: collection.folder?.id,
														},
														isPublished: collection.isPublished,
													},
												],
											})
										)
									}}
								>
									{figure}
								</Link>
							)}
						</div>
						<div className={styles.content}>
							<div className={styles.titleWithIcons}>
								<ContentEditable
									className={styles.title}
									title={collection.title}
									saveTitle={this.updateCollectionTitle}
									contentEditable={!enableCardSelection}
									setTitleFocus={(val) =>
										this.setState({ titleInFocus: val })
									}
								/>
								{!enableCardSelection && (
									<PinButton
										isPinned={collection.isPinned}
										onClick={togglePinned}
									/>
								)}
							</div>

							<div className={styles.subtitle}>
								{collection.count} Items
							</div>
						</div>
					</>
				)}
			</ShowCardControlsComponent>
		)
	}
}

function collect(monitor) {
	return {
		isDragging: monitor.isDragging(),
	}
}

function ProductCollectionCardComp({ isDraggable, ...otherProps }) {
	const Target = isDraggable
		? withDrag(ProductCollectionCard, ({ collection }) => ({
				type: "product-collection-card",
				item: {
					id: collection.id,
					type: "collection",
				},
				collect,
		  }))
		: ProductCollectionCard
	return <Target {...otherProps} />
}

export default withRouter(
	createFragmentContainer(ProductCollectionCardComp, {
		collection: graphql`
			fragment userProductCollectionCard_productCollection on ProductCollection {
				id
				title
				createdAt
				updatedAt
				isDefault
				isPublished
				isPinned
				folder {
					id
					name
				}
				user {
					id
					fullname
				}
				count
				images
				isACloset
				client {
					id
					fullname
				}
			}
		`,
	})
)
