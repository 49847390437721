import React, { PureComponent } from "react"
import { createPaginationContainer, graphql } from "react-relay"
import ListOfShopcasts from "../../../components/list-of-shopcasts/list-of-shopcasts.jsx"

class ProfileShopcasts extends PureComponent {
	render() {
		const { props } = this
		const { profile } = props.store
		return (
			<ListOfShopcasts
				enableHeader={false}
				shopcasts={profile.latestShopcasts}
				relay={props.relay}
				currentUser={props.store.currentUser}
				storeId={props.store.id}
			/>
		)
	}
}

const query = graphql`
	query profileShopcastsQuery(
		$count: Int!
		$cursor: String
		$username: String!
	) {
		store {
			...profileShopcasts_store
				@arguments(count: $count, cursor: $cursor, username: $username)
		}
	}
`

ProfileShopcasts = createPaginationContainer(
	ProfileShopcasts,
	{
		store: graphql`
			fragment profileShopcasts_store on Store
			@argumentDefinitions(
				count: { type: "Int", defaultValue: 12 }
				cursor: { type: "String" }
				username: { type: "String!" }
			) {
				id
				currentUser {
					id
					...shopcastCard_currentUser
				}
				profile(username: $username) {
					latestShopcasts(first: $count, after: $cursor)
						@connection(key: "profile_latestShopcasts") {
						edges {
							node {
								...shopcastCard_shopcast
							}
						}
					}
				}
			}
		`,
	},
	{
		direction: "forward",
		getConnectionFromProps(props) {
			const { profile } = props.store
			return profile && profile.latestShopcasts
		},
		getFragmentVariables(prevVars, totalCount) {
			return {
				...prevVars,
				count: totalCount,
			}
		},
		getVariables(props, { count, cursor }) {
			return {
				count,
				cursor,
				username: props.username,
			}
		},
		query,
	}
)

export default {
	Component: ProfileShopcasts,
	query,
	params: { count: 12, cursor: null, username: null },
}
