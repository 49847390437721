/**
 * @generated SignedSource<<247ca2950445ef648e6d11c4384dfa5a>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Mutation } from 'relay-runtime';
export type CreateFileInput = {|
  clientMutationId?: ?string,
  folder?: ?string,
  teamId?: ?string,
  title: string,
  url: string,
|};
export type createShopshareFileMutation$variables = {|
  input: CreateFileInput,
|};
export type createShopshareFileMutation$data = {|
  +createFile: ?{|
    +file: ?{|
      +folder: ?{|
        +id: string,
      |},
      +id: string,
      +thumbnailUrl: ?string,
      +title: ?string,
      +url: ?string,
      +user: ?{|
        +id: string,
      |},
    |},
  |},
|};
export type createShopshareFileMutation = {|
  response: createShopshareFileMutation$data,
  variables: createShopshareFileMutation$variables,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  (v1/*: any*/)
],
v3 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "CreateFilePayload",
    "kind": "LinkedField",
    "name": "createFile",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ShopshareFile",
        "kind": "LinkedField",
        "name": "file",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "title",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "url",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "thumbnailUrl",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": (v2/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ShopcastFolder",
            "kind": "LinkedField",
            "name": "folder",
            "plural": false,
            "selections": (v2/*: any*/),
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "createShopshareFileMutation",
    "selections": (v3/*: any*/),
    "type": "ShopshareMutationRoot",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "createShopshareFileMutation",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "a850776e78b9a34e00fb45f52f9e0b38",
    "id": null,
    "metadata": {},
    "name": "createShopshareFileMutation",
    "operationKind": "mutation",
    "text": "mutation createShopshareFileMutation(\n  $input: CreateFileInput!\n) {\n  createFile(input: $input) {\n    file {\n      id\n      title\n      url\n      thumbnailUrl\n      user {\n        id\n      }\n      folder {\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "ed7daf31aa8c186f795b482f16427447";

module.exports = ((node/*: any*/)/*: Mutation<
  createShopshareFileMutation$variables,
  createShopshareFileMutation$data,
>*/);
