import React, { useState, Fragment } from "react"
import { toast } from "react-toastify"
import { createFragmentContainer, graphql } from "react-relay"
import button from "shared/styles/components/buttons.css"
import form from "shared/styles/components/form.css"
import contentCard from "shared/styles/layout/content-card.css"
import textfield from "shared/styles/components/textfield.css"
// import LockIcon from "shared/assets/lock-icon.svg"
import styles from "./update-username.css"
import Loading from "../../loading/loading.jsx"
import UpdateUsernameMutation from "../../../mutations/user/update-username"
import { subdomainValidationRegex } from "../../../constants"
import {
	validateForm,
	usernameEmailAvailabilityCheck,
} from "../../../helpers/ui-helper"
import gql from "../../../helpers/gql-helper"

function UpdateUsername(props) {
	const { currentUser, simpleView } = props
	const user = currentUser
	if (!user) return null

	const usernameValidity = subdomainValidationRegex.test(user.username)
	const [updating, setUpdating] = useState(false)
	const [errors] = useState(null)
	const [username, setUsername] = useState({
		status: usernameValidity ? "valid" : "invalid",
		value: user.username,
	})
	const [usernameInputClass, setUsernameInputClass] = useState(
		usernameValidity ? textfield.normal : textfield.isError
	)
	const [, setIsValid] = useState(true)
	let formElement = null

	async function handleUsernameChanges({ target }) {
		const { value } = target
		if (!value || !subdomainValidationRegex.test(value)) {
			setUsername({ ...username, status: "invalid", value })
			setUsernameInputClass(textfield.isError)
		} else {
			setUsername({ ...username, status: "loading", value })
			usernameEmailAvailabilityCheck(value).then((data) => {
				const valid = data ? "valid" : "invalid"
				setUsername({ ...username, status: valid, value })
				setUsernameInputClass(textfield.normal)
			})
		}
	}

	function onUpdateSuccess() {
		toast.success(<Fragment>Updated successfully.</Fragment>, {
			autoClose: 2000,
			closeButton: false,
		})
		setUpdating(false)
	}

	function onUpdateFailure(e) {
		setUpdating(false)
		toast.error(
			gql.getError(e) || "Error updating account details.",
			{ autoClose: 2000, closeButton: false }
		)
	}

	function commitUpdates(e) {
		e.preventDefault()
		if (!validateForm(formElement) || username.status !== "valid") {
			return setIsValid(false)
		}
		setUpdating(true)
		setIsValid(true)
		const { relay, store } = props
		UpdateUsernameMutation.commit(
			relay.environment,
			username.value,
			store.id,
			onUpdateSuccess,
			onUpdateFailure
		)
		return null
	}

	function validationMessage(usernameVal) {
		if (!subdomainValidationRegex.test(usernameVal)) {
			return "Only lowercase alphanumeric characters (a-z 0-9), no spaces."
		}
		if (usernameVal) {
			return "Username not available"
		}
		return "Username is required"
	}

	return (
		<div className={simpleView ? contentCard.rootSimple : contentCard.root}>
			<form
				ref={(ref) => {
					formElement = ref
				}}
				name="updateUsername"
			>
				{errors && errors.length && (
					<div className={styles.errorMessage}>
						{errors.map((e) => (
							<p key={e}>{e}</p>
						))}
					</div>
				)}
				{!simpleView && (
					<div className={contentCard.header}>
						<h4 className={contentCard.title}>Username</h4>
					</div>
				)}
				<div className={contentCard.content}>
					{updating && <Loading />}

					<div className={form.group}>
						<label className={form.label}>Username</label>

						<div className={styles.textfield} data-novalidate="true">
							{username.status === "loading" && (
								<div className={styles.spinner}>
									<div className={styles.spinnerIcon} />
								</div>
							)}
							<input
								className={usernameInputClass}
								type="text"
								defaultValue={username.value}
								required
								onChange={handleUsernameChanges}
							/>
						</div>
						{username.status === "invalid" && (
							<div className={form.validationFeedback}>
								{validationMessage(username.value)}
							</div>
						)}
					</div>
					{/* {!props.simpleView && (
						<p className={styles.url}>
							<b>Your Shopping Channel Link:</b> {`${username.value}.${publicViewDomain}`}
						</p>
					)} */}
				</div>
				<div className={contentCard.footer}>
					<button
						type="submit"
						disabled={username.status === "loading"}
						className={button.primary}
						onClick={commitUpdates}
					>
						Save
					</button>
				</div>
			</form>
		</div>
	)
}

const UpdateUsernameComp = createFragmentContainer(UpdateUsername, {
	currentUser: graphql`
		fragment updateUsername_currentUser on User {
			displayName
			fullname
			username
			bio
			email
			isRetailer
			isVerified
			socialLinks {
				website
				instagramUsername
				facebookUrl
				twitterUsername
				youtubeUrl
				pinterestUrl
			}
		}
	`,
})

export default UpdateUsernameComp
