import React from "react"
import Avatar from "components/profile/avatar/avatar"
import stylesAvatar from "components/profile/avatar/avatar.css"
import { addVersioning } from "helpers/query-parameters-helper"
import { createFragmentContainer, graphql } from "react-relay"
import { Link } from "react-router-dom"
import styles from "./shared-folder-card.css"

function SharedFolderCard({ section }) {
	return (
		<div className={styles.root}>
			<div
				className={styles.masthead}
				style={
					section.user.bannerPhoto
						? {
								backgroundImage: `url(${addVersioning(
									section.user.bannerPhoto,
									`d=1000`
								)})`,
						  }
						: null
				}
			/>
			<div className={styles.content}>
				<div className={styles.avatar}>
					<Avatar
						styleName={stylesAvatar.border}
						photoUrl={section.user.profilePhoto}
						fullname={section.user.fullname}
						imageSize="400"
					/>
				</div>
				<h3 className={styles.user}>
					<Link
						className={styles.link}
						to={`/stylist/${section.folders[0].id}`}
					>
						{section.user.fullname}
					</Link>
				</h3>
				<p className={styles.username}>@{section.user.username}</p>
			</div>
		</div>
	)
}

const SharedFolderCardComponent = createFragmentContainer(SharedFolderCard, {
	folder: graphql`
		fragment sharedFolderCard_shopcastFolder on ShopcastFolder {
			id
			user {
				id
				username
			}
		}
	`,
})

export default SharedFolderCardComponent
