import React, {
	forwardRef,
	useEffect,
	useImperativeHandle,
	useState,
} from "react"
import { Cross2Icon, MagnifyingGlassIcon } from "@radix-ui/react-icons"
import styles from "./search-input.css"

function SearchInputComponent(
	{
		innerRef,
		innerOnKeyUp,
		defaultValue = "",
		placeholder,
		setSearchText,
		naked,
	},
	ref
) {
	const [text, setText] = useState(defaultValue)
	useImperativeHandle(
		ref,
		() => ({
			clear() {
				setSearchText("")
				setText("")
			},
		}),
		[]
	)
	useEffect(() => {
		if (defaultValue) setSearchText(defaultValue)
	}, [])
	return (
		<div className={styles.root}>
			<MagnifyingGlassIcon className={styles.icon} />
			<input
				ref={innerRef}
				className={naked ? styles.inputNaked : styles.input}
				type="text"
				value={text}
				placeholder={placeholder}
				autoComplete="false"
				onChange={(i) => {
					setSearchText(i.target.value)
					setText(i.target.value)
				}}
				onKeyUp={innerOnKeyUp}
			/>
			{text && (
				<button
					className={styles.clear}
					type="button"
					aria-label="clear search"
					onClick={() => {
						setSearchText("")
						setText("")
					}}
				>
					<Cross2Icon className={styles.clearIcon} />
				</button>
			)}
		</div>
	)
}

export default forwardRef(SearchInputComponent)
