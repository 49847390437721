import React from "react"
import Modal from "react-modal"
import modal from "shared/styles/components/modal.css"
import button from "shared/styles/components/buttons.css"
import styles from "./update-prompt.css"

export default function UpdatePrompt() {
	return (
		<Modal
			className={modal.root}
			overlayClassName={modal.overlay}
			htmlOpenClassName="ReactModal__Html--open"
			isOpen
			ariaHideApp={false}
		>
			<div
				className={`${modal.dialog} ${modal.dialogCentered} ${modal.dialogLg}`}
			>
				<div className={modal.content}>
					<div className={styles.body}>
						<h4 className={styles.title}>
							A new version of ShopShare has been released!
						</h4>
						<p className={styles.description}>
							We are constantly working on improving our service for you.
							We&apos;ve just released the new version of ShopShare.
							Please use the button below to load the latest update.
						</p>
					</div>
					<div className={styles.footer}>
						<button
							type="button"
							className={button.primary}
							onClick={() => {
								window.location.reload()
							}}
						>
							Update
						</button>
					</div>
				</div>
			</div>
		</Modal>
	)
}
