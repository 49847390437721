import { commitMutation, graphql } from "react-relay"
import { complete } from "../helper.js"

const mutation = graphql`
	mutation deleteLookbookVideoMutation(
		$input: DeleteLookbookVideoInput!
	) {
		deleteLookbookVideo(input: $input) {
			lookbookBoard{
				id
			}
		}
	}
`

const commit = (env, boardId, onCompleted, onError) =>
	commitMutation(env, {
		mutation,
		variables: {
			input: {
				boardId,
			},
		},
		onCompleted: complete(onCompleted, onError),
		onError: (e) => complete(null, [e]),
		updater: (storeProxy, data) => {
			if (
				data.deleteLookbookVideo &&
				data.deleteLookbookVideo.lookbookBoard &&
				data.deleteLookbookVideo.lookbookBoard.id
			) {
				const lookbookProxy = storeProxy.get(boardId)
				lookbookProxy.setValue(
					null,
					"videoUrl"
				)
				lookbookProxy.setValue(
					false,
					"showVideo"
				)
			}
		},
	})

export default { commit }
