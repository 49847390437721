/**
 * @generated SignedSource<<760e8eaf73f4dce6aa4e79474c072f1c>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentType } from "relay-runtime";
declare export opaque type favouriteList_store$fragmentType: FragmentType;
export type favouriteList_store$data = {|
  +currentUser: ?{|
    +lookbookFavourites: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?({|
          +__typename: "Background",
          +bgCategory: ?string,
          +canEdit: ?boolean,
          +id: string,
          +isFavourite: ?boolean,
          +title: ?string,
          +type: ?string,
          +url: ?string,
        |} | {|
          +__typename: "LookbookTemplate",
          +canEdit: ?boolean,
          +categories: ?$ReadOnlyArray<?string>,
          +createdCount: ?number,
          +id: string,
          +isFavourite: ?boolean,
          +lookbook: ?{|
            +background: ?string,
            +data_json: ?string,
            +group: ?string,
            +height: ?number,
            +id: string,
            +screen_width: ?string,
            +shapes: ?{|
              +edges: ?$ReadOnlyArray<?{|
                +node: ?{|
                  +collection: ?string,
                  +id: string,
                  +path: ?string,
                  +viewBox: ?string,
                |},
              |}>,
            |},
            +title: ?string,
            +url: ?string,
            +width: ?number,
          |},
        |} | {|
          +__typename: "Shape",
          +canDelete: ?boolean,
          +canEdit: ?boolean,
          +collection: ?string,
          +id: string,
          +isFavourite: ?boolean,
          +path: ?string,
          +viewBox: ?string,
        |} | {|
          +__typename: "ShopcastProduct",
          +affiliated_url: ?string,
          +brand: ?string,
          +custom_affiliated_url: ?string,
          +description: ?string,
          +diffbotImageUrl: ?string,
          +error: ?string,
          +hasProductImagesFromDiffbot: ?boolean,
          +id: string,
          +imageUrl: ?string,
          +isFavourite: ?boolean,
          +offerPrice: ?string,
          +productCollections: ?$ReadOnlyArray<?{|
            +id: string,
            +title: ?string,
          |}>,
          +productImages: ?$ReadOnlyArray<?string>,
          +regularPrice: ?string,
          +title: ?string,
          +url: ?string,
        |} | {|
          +__typename: "lookbookUploads",
          +adminAccess: ?boolean,
          +canEdit: ?boolean,
          +category: ?{|
            +title: ?string,
          |},
          +id: string,
          +isFavourite: ?boolean,
          +productCollections: ?$ReadOnlyArray<?{|
            +id: string,
            +title: ?string,
          |}>,
          +subCategoryList: ?$ReadOnlyArray<?{|
            +category: ?{|
              +title: ?string,
            |},
            +id: string,
            +title: ?string,
          |}>,
          +title: ?string,
          +url: ?string,
        |} | {|
          // This will never be '%other', but we need some
          // value in case none of the concrete values match.
          +__typename: "%other",
        |}),
      |}>,
    |},
  |},
  +$fragmentType: favouriteList_store$fragmentType,
|};
export type favouriteList_store$key = {
  +$data?: favouriteList_store$data,
  +$fragmentSpreads: favouriteList_store$fragmentType,
  ...
};
*/

var node/*: ReaderFragment*/ = (function(){
var v0 = {
  "kind": "Variable",
  "name": "teamId",
  "variableName": "teamId"
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "url",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "ProductCollection",
  "kind": "LinkedField",
  "name": "productCollections",
  "plural": true,
  "selections": [
    (v1/*: any*/),
    (v2/*: any*/)
  ],
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isFavourite",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "canEdit",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "viewBox",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "path",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "collection",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "concreteType": "UploadsCategory",
  "kind": "LinkedField",
  "name": "category",
  "plural": false,
  "selections": [
    (v2/*: any*/)
  ],
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "category"
    },
    {
      "defaultValue": 24,
      "kind": "LocalArgument",
      "name": "count"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "cursor"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "teamId"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "count",
        "cursor": "cursor",
        "direction": "forward",
        "path": [
          "currentUser",
          "lookbookFavourites"
        ]
      }
    ]
  },
  "name": "favouriteList_store",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "currentUser",
      "plural": false,
      "selections": [
        {
          "alias": "lookbookFavourites",
          "args": [
            {
              "kind": "Variable",
              "name": "category",
              "variableName": "category"
            },
            (v0/*: any*/)
          ],
          "concreteType": "LookbookFavouritesConnectionConnection",
          "kind": "LinkedField",
          "name": "__currentUser_lookbookFavourites_connection",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "LookbookFavouritesConnectionEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": null,
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "__typename",
                      "storageKey": null
                    },
                    {
                      "kind": "InlineFragment",
                      "selections": [
                        (v1/*: any*/),
                        (v2/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "brand",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "description",
                          "storageKey": null
                        },
                        (v3/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "affiliated_url",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "custom_affiliated_url",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "imageUrl",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "diffbotImageUrl",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "regularPrice",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "offerPrice",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "error",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "productImages",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "hasProductImagesFromDiffbot",
                          "storageKey": null
                        },
                        (v4/*: any*/),
                        (v5/*: any*/)
                      ],
                      "type": "ShopcastProduct",
                      "abstractKey": null
                    },
                    {
                      "kind": "InlineFragment",
                      "selections": [
                        (v1/*: any*/),
                        (v6/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "canDelete",
                          "storageKey": null
                        },
                        (v7/*: any*/),
                        (v8/*: any*/),
                        (v9/*: any*/),
                        (v5/*: any*/)
                      ],
                      "type": "Shape",
                      "abstractKey": null
                    },
                    {
                      "kind": "InlineFragment",
                      "selections": [
                        (v1/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "adminAccess",
                          "storageKey": null
                        },
                        (v6/*: any*/),
                        (v2/*: any*/),
                        (v3/*: any*/),
                        (v10/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "UploadsSubCategory",
                          "kind": "LinkedField",
                          "name": "subCategoryList",
                          "plural": true,
                          "selections": [
                            (v1/*: any*/),
                            (v10/*: any*/),
                            (v2/*: any*/)
                          ],
                          "storageKey": null
                        },
                        (v4/*: any*/),
                        {
                          "alias": null,
                          "args": [
                            (v0/*: any*/)
                          ],
                          "kind": "ScalarField",
                          "name": "isFavourite",
                          "storageKey": null
                        }
                      ],
                      "type": "lookbookUploads",
                      "abstractKey": null
                    },
                    {
                      "kind": "InlineFragment",
                      "selections": [
                        (v1/*: any*/),
                        (v6/*: any*/),
                        (v2/*: any*/),
                        {
                          "alias": "bgCategory",
                          "args": null,
                          "kind": "ScalarField",
                          "name": "category",
                          "storageKey": null
                        },
                        {
                          "alias": "url",
                          "args": null,
                          "kind": "ScalarField",
                          "name": "background",
                          "storageKey": null
                        },
                        (v5/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "type",
                          "storageKey": null
                        }
                      ],
                      "type": "Background",
                      "abstractKey": null
                    },
                    {
                      "kind": "InlineFragment",
                      "selections": [
                        (v1/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "categories",
                          "storageKey": null
                        },
                        (v6/*: any*/),
                        (v5/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "createdCount",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "Lookbook",
                          "kind": "LinkedField",
                          "name": "lookbook",
                          "plural": false,
                          "selections": [
                            (v1/*: any*/),
                            (v2/*: any*/),
                            (v3/*: any*/),
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "screen_width",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "data_json",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "width",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "height",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "background",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "group",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "ShapeConnectionConnection",
                              "kind": "LinkedField",
                              "name": "shapes",
                              "plural": false,
                              "selections": [
                                {
                                  "alias": null,
                                  "args": null,
                                  "concreteType": "ShapeConnectionEdge",
                                  "kind": "LinkedField",
                                  "name": "edges",
                                  "plural": true,
                                  "selections": [
                                    {
                                      "alias": null,
                                      "args": null,
                                      "concreteType": "Shape",
                                      "kind": "LinkedField",
                                      "name": "node",
                                      "plural": false,
                                      "selections": [
                                        (v1/*: any*/),
                                        (v7/*: any*/),
                                        (v8/*: any*/),
                                        (v9/*: any*/)
                                      ],
                                      "storageKey": null
                                    }
                                  ],
                                  "storageKey": null
                                }
                              ],
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "type": "LookbookTemplate",
                      "abstractKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "cursor",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "PageInfo",
              "kind": "LinkedField",
              "name": "pageInfo",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "endCursor",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "hasNextPage",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Store",
  "abstractKey": null
};
})();

(node/*: any*/).hash = "17f118e54c4b9ab868b5fc0901810cce";

module.exports = ((node/*: any*/)/*: Fragment<
  favouriteList_store$fragmentType,
  favouriteList_store$data,
>*/);
