import React from "react"
import { toTitleCase } from "helpers/string-helper"
import { CategoryENUM } from "helpers/enum-helper"
import { runIfFeatureAvailable, FeatureKeyENUM } from "services/plan-services"
import ShopcastIcon from "shared/assets/lb-shopcast-icon.svg"
import TextIcon from "shared/assets/lb-text-icon.svg"
import TemplateIcon from "shared/assets/lb-template-icon.svg"
import BackgroundIcon from "shared/assets/lb-background-icon.svg"
import CollectionIcon from "shared/assets/lb-collections-icon.svg"
import ShapeIcon from "shared/assets/lb-shapes-icon.svg"
import ImageIcon from "shared/assets/lb-image-icon.svg"
import VideoIcon from "shared/assets/lb-recorder-icon.svg"
import icon from "shared/styles/components/icons.css"
import styles from "./menu-container.css"

export default function MenuContainer({
	onSelect,
	children,
	selected,
	categories,
	environment,
	disabled,
	team,
}) {
	return (
		<div className={styles.root}>
			<div className={styles.nav}>
				<div className={styles.navItems}>
					<button
						type="button"
						data-tooltip-id="tooltip"
						data-tooltip-place="left"
						data-tooltip-content="Shopcasts"
						onClick={() => onSelect(CategoryENUM.SHOPCAST)}
						className={
							selected === CategoryENUM.SHOPCAST
								? styles.btnActive
								: styles.btn
						}
						disabled={disabled}
					>
						<ShopcastIcon className={icon.icon24} />
					</button>
					<button
						type="button"
						data-tooltip-id="tooltip"
						data-tooltip-place="left"
						data-tooltip-content="Collections"
						onClick={() => onSelect(CategoryENUM.COLLECTION)}
						className={
							selected === CategoryENUM.COLLECTION
								? styles.btnActive
								: styles.btn
						}
						disabled={disabled}
					>
						<CollectionIcon className={icon.icon24} />
					</button>

					<div className={styles.divider} />
					<button
						type="button"
						data-tooltip-id="tooltip"
						data-tooltip-place="left"
						data-tooltip-content="Templates"
						onClick={runIfFeatureAvailable(
							() => onSelect(CategoryENUM.TEMPLATE),
							FeatureKeyENUM.TEMPLATES,
							environment,
							team
						)}
						className={
							selected === CategoryENUM.TEMPLATE
								? styles.btnActive
								: styles.btn
						}
						disabled={disabled}
					>
						<TemplateIcon className={icon.icon24} />
					</button>
					<div className={styles.divider} />
					<button
						type="button"
						data-tooltip-id="tooltip"
						data-tooltip-place="left"
						data-tooltip-content="Recorder"
						onClick={runIfFeatureAvailable(
							() => onSelect(CategoryENUM.RECORDER),
							FeatureKeyENUM.SHOPBOARD_VIDEO,
							environment,
							team
						)}
						className={
							selected === CategoryENUM.RECORDER
								? styles.btnActive
								: styles.btn
						}
						disabled={disabled}
					>
						<VideoIcon className={icon.icon24} />
					</button>
					<div className={styles.divider} />
					<button
						type="button"
						data-tooltip-id="tooltip"
						data-tooltip-place="left"
						data-tooltip-content="Text"
						onClick={() => onSelect(CategoryENUM.FONT)}
						className={
							selected === CategoryENUM.FONT
								? styles.btnActive
								: styles.btn
						}
						disabled={disabled}
					>
						<TextIcon className={icon.icon24} />
					</button>
					<button
						type="button"
						data-tooltip-id="tooltip"
						data-tooltip-place="left"
						data-tooltip-content="Shapes"
						onClick={() => onSelect(CategoryENUM.SHAPE)}
						className={
							selected === CategoryENUM.SHAPE
								? styles.btnActive
								: styles.btn
						}
						disabled={disabled}
					>
						<ShapeIcon className={icon.icon24} />
					</button>
					<button
						type="button"
						data-tooltip-id="tooltip"
						data-tooltip-place="left"
						data-tooltip-content="Background"
						onClick={() => onSelect(CategoryENUM.BACKGROUND)}
						className={
							selected === CategoryENUM.BACKGROUND
								? styles.btnActive
								: styles.btn
						}
						disabled={disabled}
					>
						<BackgroundIcon className={icon.icon24} />
					</button>
					{categories &&
						categories.map((category) => (
							<button
								type="button"
								data-tooltip-id="tooltip"
								data-tooltip-place="left"
								data-tooltip-content={toTitleCase(category.title)}
								onClick={() => onSelect(category.id)}
								key={category.id}
								className={
									selected === category.id
										? styles.btnActive
										: styles.btn
								}
								disabled={disabled}
							>
								<ImageIcon className={icon.icon24} />
							</button>
						))}
				</div>
				{/* <button
               type="button"
               data-tooltip-id="tooltip"
               data-tooltip-place="left"
               data-tooltip-content="Side Panel"
               onClick={onToggleMenu}
               className={styles.toolSubtle}
            >
               {isOpen ? (
                  <ChevronRightIcon className={icon.icon21} />
               ) : (
                  <ChevronLeftIcon className={icon.icon21} />
               )}
            </button> */}
			</div>
			<div className={styles.content} id="scroll-div">
				{children}
			</div>
		</div>
	)
}
