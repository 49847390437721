/**
 * @generated SignedSource<<19c89132f931ac1f09230b0b0bc93c2f>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentType } from "relay-runtime";
declare export opaque type salesReport_store$fragmentType: FragmentType;
export type salesReport_store$data = {|
  +id: string,
  +$fragmentType: salesReport_store$fragmentType,
|};
export type salesReport_store$key = {
  +$data?: salesReport_store$data,
  +$fragmentSpreads: salesReport_store$fragmentType,
  ...
};
*/

var node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "salesReport_store",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "type": "Store",
  "abstractKey": null
};

(node/*: any*/).hash = "064bd2bb703ddb3be4931c3156e56979";

module.exports = ((node/*: any*/)/*: Fragment<
  salesReport_store$fragmentType,
  salesReport_store$data,
>*/);
