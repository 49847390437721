import React from "react"
import { createFragmentContainer, graphql } from "react-relay"
import PinButton from "components/buttons/pin-button"
// import CollectionIcon from "shared/assets/lb-collections-icon.svg"
import ClosetIcon from "shared/assets/closet-icon.svg"
import { addVersioning } from "helpers/query-parameters-helper"
import card from "shared/styles/components/card.css"
import styles from "./product-collection-card.css"

class ProductCollectionCard extends React.PureComponent {
	getImageGridClass(images) {
		if (images.length > 3) return card.imageGrid4
		if (images.length > 2) return card.imageGrid3
		if (images.length > 1) return card.imageGrid2
		return card.imageGrid1
	}

	render() {
		const { collection, setCollection } = this.props
		return (
			<div className={styles.root}>
				<div
					className={styles.media}
					onClick={() => setCollection(collection)}
					role="link"
					tabIndex={0}
				>
					<div className={styles.pin}>
						<PinButton isPinned={collection.isPinned} />
					</div>
					<div className={styles.figure}>
						<div className={styles.inner}>
							{collection.images.length > 0 ? (
								<div
									className={this.getImageGridClass(collection.images)}
								>
									<div className={card.igItem1}>
										{collection.images.length > 0 && (
											<img
												className={styles.poster}
												loading="lazy"
												src={addVersioning(
													collection.images[0],
													`d=400`
												)}
												alt="Poster"
											/>
										)}
									</div>
									<div className={card.igItem2}>
										{collection.images.length > 1 && (
											<img
												className={styles.poster}
												loading="lazy"
												src={addVersioning(
													collection.images[1],
													`d=400`
												)}
												alt="Poster"
											/>
										)}
									</div>
									<div className={card.igItem3}>
										{collection.images.length > 2 && (
											<img
												className={styles.poster}
												loading="lazy"
												src={addVersioning(
													collection.images[2],
													`d=400`
												)}
												alt="Poster"
											/>
										)}
									</div>
									<div className={card.igItem4}>
										{collection.images.length > 3 && (
											<img
												className={styles.poster}
												loading="lazy"
												src={addVersioning(
													collection.images[3],
													`d=400`
												)}
												alt="Poster"
											/>
										)}
									</div>
								</div>
							) : (
								<div className={card.imageGridEmpty}>
									<div className={card.igItemEmpty}>
										<ClosetIcon className={card.igItemIcon} />
									</div>
								</div>
							)}
						</div>
					</div>
				</div>
				<div className={styles.content}>
					<h4 className={styles.title}>{collection.title}</h4>
					<div className={styles.subtitle}>{collection.count} Items</div>
				</div>
			</div>
		)
	}
}

ProductCollectionCard = createFragmentContainer(ProductCollectionCard, {
	collection: graphql`
		fragment productCollectionCard_productCollection on ProductCollection {
			id
			title
			createdAt
			updatedAt
			isDefault
			isPublished
			isPinned
			folder {
				id
				name
			}
			count
			images
			isACloset
			client {
				id
			}
		}
	`,
})

export default ProductCollectionCard
