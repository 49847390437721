import React from "react"
import Modal from "react-modal"
import dayjs from "dayjs"
import { Cross2Icon } from "@radix-ui/react-icons"
import { getFormattedCurrency } from "helpers/string-helper"
import currencyData from "helpers/currencies.json"
import modal from "shared/styles/components/modal.css"
import button from "shared/styles/components/buttons.css"
import styles from "./sales-transaction.css"

function SalesTransactionsModal({ closeModal, transactions, isAdmin }) {
	const renderDetailsRow = (transaction) => {
		const cSymbol =
			currencyData[transaction.currency] &&
			currencyData[transaction.currency].symbol
		const productNames = transaction.productNames.filter((i) => !!i)
		return (
			<div className={styles.tr} key={transaction.id}>
				{isAdmin && (
					<div className={styles.tblTd}>{transaction.orderId}</div>
				)}
				<div className={styles.tblTd}>
					{productNames.length > 0 ? (
						productNames.map((name) => <span key={name}>{name}</span>)
					) : (
						<span>{transaction.product.title}</span>
					)}
				</div>
				<div className={styles.tblTd}>{transaction.advertiserName}</div>
				<div className={styles.tblTd}>{transaction.currency}</div>
				<div className={styles.tblTd}>
					{getFormattedCurrency({
						cSymbol,
						amount: transaction.sales || 0,
						selectedCurrency: transaction.currency,
					})}
				</div>
				<div className={styles.tblTd}>
					{getFormattedCurrency({
						cSymbol,
						amount: transaction.totalCommission || 0,
						selectedCurrency: transaction.currency,
					})}
				</div>
				<div className={styles.tblTd}>
					{dayjs(+transaction.transactionDate).format("DD MMM YYYY")}
				</div>
				{isAdmin && (
					<div className={styles.tblTdNetwork}>{transaction.network}</div>
				)}
			</div>
		)
	}

	return (
		<Modal
			className={modal.root}
			overlayClassName={modal.overlay}
			htmlOpenClassName="ReactModal__Html--open"
			isOpen={!!transactions && transactions.length > 0}
			shouldCloseOnOverlayClick
			ariaHideApp={false}
		>
			<div
				className={`${modal.dialog} ${modal.dialogCentered} ${modal.dialogScrollable} ${modal.dialogFullscreenSmDown} ${modal.dialogXl}`}
			>
				<div className={modal.content}>
					<div className={modal.headerNoBorder}>
						<h4 className={modal.title}>Sales Report</h4>
						<button
							type="button"
							className={button.close}
							onClick={() => closeModal()}
						>
							<Cross2Icon className={button.closeIcon} />
						</button>
					</div>
					<div className={modal.bodypt0}>
						<div className={styles.tblTransactions}>
							<div className={styles.thead}>
								<div className={styles.tr}>
									{isAdmin && (
										<div className={styles.tblTh}>Order ID</div>
									)}
									<div className={styles.tblTh}>Product</div>
									<div className={styles.tblTh}>Retailer</div>
									<div className={styles.tblTh}>Currency</div>
									<div className={styles.tblTh}>Value</div>
									<div className={styles.tblTh}>Commission</div>
									<div className={styles.tblTh}>Date</div>
									{isAdmin && (
										<div className={styles.tblTh}>Network</div>
									)}
								</div>
							</div>
							<div className={styles.tbody}>
								{transactions &&
									transactions.map((transaction) =>
										renderDetailsRow(transaction)
									)}
							</div>
						</div>
					</div>
				</div>
			</div>
		</Modal>
	)
}

export default SalesTransactionsModal
