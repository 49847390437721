import React from "react"
import { TrashIcon } from "@radix-ui/react-icons"
import styles from "./buttons.css"

export default function DeleteButton({
	dark,
	dataTip,
	disabled,
	hideButton,
	onClick,
}) {
	return (
		<button
			className={dark ? styles.rootDark : styles.root}
			onClick={onClick}
			type="button"
			data-tooltip-id="tooltip"
			data-tooltip-place="bottom"
			data-tooltip-content={dataTip}
			style={{
				display: hideButton && "none",
			}}
			disabled={disabled}
		>
			<TrashIcon className={styles.icon} />
		</button>
	)
}
