export default {
	currentUser: {
		publishedShopcasts: "currentUser_publishedShopcasts",
		unpublishedShopcasts: "currentUser_unpublishedShopcasts",
		productCollections: "currentUser_productCollections",
		productCollectionsNoFilter: "currentUser_noFilter_productCollections",
		productCollectionsSearch: "currentUser_productCollectionSearchItems_collections",
		productCollectionItems: "currentUser_productCollection_items",
		productCollectionsItems: "currentUser_productCollectionItems",
		lookbookTemplates: {
			name: "currentUser_lookbookTemplates",
			data: { category: "" },
		},
		shopcastManager: {
			shopcasts: {
				name: "currentUser_listOfRecentUpload_shopcasts",
				data: {},
			},
			lookbooks: {
				name: "currentUser_listOfLookbook_lookbooks",
				data: {},
			},
			items: {
				name: "currentUser_listOfFolderItems_items",
				data: {},
			},
			folders: {
				name: "currentUser_listOfFolder_folders",
				data: {
					onlyClients: false,
				},
			},
			starredFolders: {
				name: "currentUser_connectionManager_starredFolders",
				data: {
					first: 10,
				},
			},
			rootFolders: {
				name: "currentUser_connectionManager_rootFolders",
				data: {
					first: 10,
				},
			},
		},
	},
	shopcast: {
		products: {
			name: "shopcast_shopcastItems",
			data: {
				first: 100,
			},
		},
	},
	profile: {
		followers: {
			name: "profile_followers",
			data: {
				first: 10000,
			},
		},
	},
	store: {
		recommendedShopcasts: {
			name: "store_recommendedShopcasts",
			data: {
				first: 12,
				after: null,
			},
		},
		retailerCategories: {
			name: "store_retailerCategories",
			data: {
				first: 1000,
			},
		},
	},
	admin: {
		rakutenSites: {
			name: "admin_rakutenSites",
		},
		sharerRequests: {
			name: "admin_sharerRequests",
			data: {
				first: 50,
				status: "pending",
			},
		},
		shopcastReports: {
			name: "admin_shopcastUserReports",
			data: {
				first: 50,
			},
		},
	},
}
