import React from "react"
import { PlusIcon } from "@radix-ui/react-icons"
import { MoveableConsumer } from "routes/lookbook/moveable"
import Page from "./page"
import styles from "./boards-tray.css"

export default function BoardsTray({
	updateServer,
	lookbookBoards,
	selectedBoard,
	updatePageNo,
	saving,
	haveChanges,
}) {
	function changeBoard(dragIndex, hoverIndex) {
		if (dragIndex !== undefined && hoverIndex !== undefined) {
			const selBoard = lookbookBoards[dragIndex]
			const boards = [
				...lookbookBoards.slice(0, dragIndex),
				...lookbookBoards.slice(dragIndex + 1),
			]
			const updatedBoards = [
				...boards.slice(0, hoverIndex),
				selBoard,
				...boards.slice(hoverIndex),
			].map((x, i) => ({ ...x, pageNo: i }))
			updatePageNo(updatedBoards)
		}
	}

	return (
		<div className={styles.root}>
			{lookbookBoards
				.sort((a, b) => a.pageNo - b.pageNo)
				.map((board, i) => (
					<Page
						key={board.boardId}
						boardId={board.boardId}
						url={board.url}
						no={i}
						active={board.boardId === selectedBoard.boardId}
						changeBoard={(dragIndex, hoverIndex) =>
							changeBoard(dragIndex, hoverIndex)
						}
						loading={saving}
						updateServer={() => updateServer(lookbookBoards)}
						boardsCount={lookbookBoards.length}
						haveChanges={haveChanges}
						boardShowStaus={board.showBoard}
						enbledShowHide={
							lookbookBoards.filter((obj) => obj.showBoard).length > 1 ||
							!board.showBoard
						}
					/>
				))}
			<MoveableConsumer>
				{({ duplicateBoard }) => (
					<button
						className={styles.addPage}
						type="button"
						onClick={() =>
							duplicateBoard(
								lookbookBoards[lookbookBoards.length - 1].boardId,
								true
							)
						}
						disabled={saving}
					>
						<PlusIcon className={styles.icon} />
					</button>
				)}
			</MoveableConsumer>
		</div>
	)
}
