import React from "react"
import { TransparencyGridIcon } from "@radix-ui/react-icons"
import { getColor } from "helpers/lookbook-helper"
import Slider from "components/lookbook-dropdown/slider"
import LookbookDropdown from "./lookbook-dropdown"
import ColorPicker from "./color-picker"
import dropdownStyles from "./lookbook-dropdown.css"
import styles from "./konva-filters.css"

function TransparencyIcon() {
  return <>
		<TransparencyGridIcon className={styles.icon} />
		<span className={styles.strike} />
	</>
}
function ColorLabel({ fill, fillTransparentLevel, ...otherProps }) {
	if (fillTransparentLevel) {
		const color = getColor(fill, fillTransparentLevel)
		return (
			<div
				{...otherProps}
				style={{
					backgroundColor: color,
					border: "1px solid var(--gray300)",
				}}
			/>
		)
	}
	return <TransparencyIcon />
}
function ShapeFill({
	fill,
	fillTransparentLevel,
	dataTip,
	runDebounce,
	changeShapeProp,
}) {
	const intTransparentLevel= parseInt(fillTransparentLevel, 10)
	return (
		<LookbookDropdown
			label={
				<ColorLabel
					className={dropdownStyles.icon}
					fill={fill}
					fillTransparentLevel={intTransparentLevel}
				/>
			}
			dataTip={dataTip}
			runDebounce={runDebounce}
		>
			<div className={dropdownStyles.panelFilters}>
				<div className={styles.content}>
					<div className={styles.item}>
						<h4 className={styles.title}>Fill Colour</h4>
						<div className={styles.itemContent}>
							<button
								type="button"
								data-tooltip-id="tooltip"
								data-tooltip-content="No Colour"
								className={styles.toolDark}
								onClick={() =>
									changeShapeProp({
										fillTransparentLevel: intTransparentLevel ? 0 : 100,
									})
								}
							>
								<TransparencyIcon />
							</button>
							<ColorPicker
								color={fill}
								onChange={(colorValue) =>
									changeShapeProp({ fill: colorValue })
								}
							/>
						</div>
					</div>
					<div className={dropdownStyles.divider} />
					<div className={styles.item}>
						<Slider
							title="Fill Opacity"
							min={0}
							max={100}
							step={1}
							isDark
							value={intTransparentLevel}
							onSlide={({ target }) => {
								changeShapeProp({
									fillTransparentLevel: parseFloat(target.value),
								})
							}}
						/>
					</div>
				</div>
			</div>
		</LookbookDropdown>
	)
}
export default ShapeFill
