/* eslint-disable default-case */
import React from "react"
import { createFragmentContainer, graphql } from "react-relay"
import { useParams } from "react-router-dom"
import twoColumn from "shared/styles/layout/two-column.css"
import pageContent from "shared/styles/layout/page-content.css"
import { ROLES } from "constants"
import SocialConnections from "./social-connections"
import Subscription from "./subscription"
import Referrals from "./referrals"
import People from "./people"
import Personal from "./personal"
import Teams from "./teams"
// import UpdateCustomDomain from "./update-custom-domain"

function Account({ store, relay, team, setTeam }) {
	const params = useParams()
	const getRouteTab = (key) => {
		switch (key) {
			case "account":
				return "personal"
			case "billing":
				return "subscriptions"
			case "integrations":
				return "connections"
			case "referrals":
				return "referrals"
			case "teams":
				return "teams"
			case "people":
				return "people"
			default:
				return "personal"
		}
	}
	const { platform } = params

	let getKey = ""
	switch (platform) {
		case "youtube":
			getKey = "code"
			break
	}
	const authCode = new URLSearchParams(window.location.search).get(getKey)
	const sectionPath = getRouteTab(params.key)
	const socialMedia = store.currentUser?.socialMedia

	return (
		<>
			{sectionPath === "personal" && (
				<div className={pageContent.rootSm}>
					<Personal store={store} relay={relay} />
				</div>
			)}
			{store.currentUser && (
				<>
					{sectionPath === "subscriptions" && (
						<div className={pageContent.rootSm}>
							<Subscription
								currentUser={store.currentUser}
								plans={store.pricingPlans}
								relay={relay}
							/>
						</div>
					)}
					{sectionPath === "connections" && (
						<div className={pageContent.rootSm}>
							<SocialConnections
								platform={platform}
								authCode={authCode}
								relay={relay}
								adminAccess={
									store.currentUser &&
									[ROLES.ADMIN, ROLES.TEAM].includes(
										store.currentUser.role
									)
								}
								socialMedia={socialMedia}
								team={team}
							/>
						</div>
					)}
					{sectionPath === "referrals" && (
						<div className={pageContent.rootSm}>
							<Referrals currentUser={store.currentUser} />
						</div>
					)}
					{sectionPath === "teams" &&
						[ROLES.ADMIN].includes(store.currentUser.role) && (
							<div className={twoColumn.sectionMd}>
								<Teams
									relay={relay}
									currentUser={store.currentUser}
									team={{
										id: team,
										setTeam,
									}}
								/>
							</div>
						)}
					{sectionPath === "people" && (
						<div className={twoColumn.sectionMd}>
							<People relay={relay} team={store.team} />
						</div>
					)}
				</>
			)}
		</>
	)
}

const query = graphql`
	query accountQuery($teamId: String) {
		store {
			...account_store @arguments(teamId: $teamId)
		}
	}
`

const AccountStore = createFragmentContainer(Account, {
	store: graphql`
		fragment account_store on Store
		@argumentDefinitions(teamId: { type: "String" }) {
			id
			...personal_store
			team(id: $teamId) {
				id
				name
				avatar
				myRole
				user {
					id
					fullname
					email
				}
			}
			currentUser {
				id
				email
				fullname
				username
				adminAccess
				role
				profilePhoto
				optOutAffiliate
				type
				userPlan {
					id
					amount
					currency
					nickname
					metadata {
						name
						value
					}
					status
				}
				trialUsed
				trialStart
				trialEnd
				referrals {
					id
					link
					visitors
					leads
					conversions
					revenue
					paidCommision
					isArchived
					campaign {
						name
						description
						monthlyCommision
						annualCommision
						type
					}
				}
				socialMedia {
					platform
					isConnected
				}
				teams {
					id
					name
					displayName
					avatar
					myRole
					members {
						edges {
							node {
								id
							}
						}
					}
				}
				...updatePersonalInfo_currentUser
				...updateUsername_currentUser
				...updateCustomDomain_currentUser
			}
			pricingPlans {
				id
				amount
				currency
				nickname
				monthly
				descriptionList
				metadata {
					name
					value
				}
			}
		}
	`,
})

export default {
	Component: AccountStore,
	query,
	params: (rp) => ({ teamId: rp.team }),
}
