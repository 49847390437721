import React, { Component } from "react"
import { toast } from "react-toastify"
import gqlHelper from "helpers/gql-helper"
import { createFragmentContainer, graphql } from "react-relay"
import form from "shared/styles/components/form.css"
import toggle from "shared/styles/components/toogle.css"
import uploadFolderBannerMutation from "mutations/shopcast-manager/upload-folder-banner-photo"
import uploadFolderAvatarMutation from "mutations/shopcast-manager/upload-folder-avatar"
import SetFolderPreviewConfig from "mutations/shopcast-manager/set-folder-preview-config"
import updateDescriptionShopcastFolderMutation from "mutations/shopcast-manager/update-description-shopcast-folder"
import renameShopcastFolderMutation from "mutations/shopcast-manager/rename-shopcast-folder"
import { debounce } from "helpers/ui-helper"
import EditPreviewSharedControls from "./edit-preview-shared-controls"
import styles from "./edit-preview.css"

class EditPreviewClient extends Component {
	constructor(props) {
		super(props)
		this.state = {}
		this.uploadFolderAvatar = this.uploadFolderAvatar.bind(this)
		this.uploadFolderBanner = this.uploadFolderBanner.bind(this)
		this.saveShopcastFolderDescription = this.saveShopcastFolderDescription.bind(
			this
		)
		this.updateShowControls = this.updateShowControls.bind(this)
		this.saveFolderName = this.saveFolderName.bind(this)

		this.commitUpdatesDebounce = debounce(this.saveFolderName, 1000)
	}

	get folder() {
		const { currentUser } = this.props
		return currentUser.shopcastContainer.folder
	}

	uploadFolderAvatar(acceptedFiles) {
		let file = null
		if (acceptedFiles) [file] = acceptedFiles
		const { relay, reloadIframe } = this.props
		uploadFolderAvatarMutation.commit(
			relay.environment,
			file,
			this.folder.id,
			() => {
				toast.success(<>Avatar updated</>, {
					autoClose: 2000,
					closeButton: false,
				})
				if (reloadIframe) reloadIframe()
			},
			() => {}
		)
	}

	uploadFolderBanner(acceptedFiles) {
		let file = null
		if (acceptedFiles) [file] = acceptedFiles
		const { relay, reloadIframe } = this.props
		uploadFolderBannerMutation.commit(
			relay.environment,
			file,
			this.folder.id,
			() => {
				toast.success(<>Banner updated</>, {
					autoClose: 2000,
					closeButton: false,
				})
				if (reloadIframe) reloadIframe()
			},
			() => {}
		)
	}

	saveShopcastFolderDescription(e) {
		const { relay, reloadIframe } = this.props
		updateDescriptionShopcastFolderMutation.commit(
			relay.environment,
			this.folder.id,
			e.target.value,
			() => {
				toast.success(<>Description updated</>, {
					autoClose: 2000,
					closeButton: false,
				})
				if (reloadIframe) reloadIframe()
			},
			(err) => {
				console.log(err)
			}
		)
	}

	saveFolderName(name) {
		const { relay, reloadIframe } = this.props
		renameShopcastFolderMutation.commit(
			relay.environment,
			this.folder.id,
			name,
			true,
			() => {
				toast.success(<>Description updated</>, {
					autoClose: 2000,
					closeButton: false,
				})
				if (reloadIframe) reloadIframe()
			},
			(err) => {
				console.log(err)
			}
		)
	}

	updateShowControls(id, value) {
		const { relay, reloadIframe } = this.props
		SetFolderPreviewConfig.commit(
			relay.environment,
			this.folder.id,
			[{ id, value }],
			() => {
				toast.success(<>Updated successfully.</>, {
					autoClose: 2000,
					closeButton: false,
				})
				if (reloadIframe) reloadIframe()
			},
			(e) => {
				toast.error(
					gqlHelper.getError(e) || "Error updating controls.",
					{
						autoClose: 2000,
						closeButton: false,
					}
				)
			}
		)
	}

	render() {
		const { previewConfig } = this.folder
		return (
			<>
				<EditPreviewSharedControls
					data={{
						avatar: this.folder.avatar,
						bannerPhoto: this.folder.bannerPhoto,
						description: this.folder.description,
						displayName: this.folder.displayName,
						showAvatar: previewConfig.showAvatar,
						showBanner: previewConfig.showBanner,
						showDescription: previewConfig.showDescription,
						showDisplayName: previewConfig.showDisplayName,
					}}
					saveDescription={this.saveShopcastFolderDescription}
					uploadAvatar={this.uploadFolderAvatar}
					uploadBanner={this.uploadFolderBanner}
					updateShowControls={this.updateShowControls}
					saveDisplayName={this.commitUpdatesDebounce}
					enableBannerAvatarSelect
				/>
				<div className={styles.formRoot}>
					<div className={form.section}>
						<div className={form.header}>
							<label className={toggle.checkSpaceBetween}>
								<div className={toggle.labelSm}>Show Lookbooks Tab</div>
								<div className={toggle.switchSm}>
									<input
										type="checkbox"
										checked={previewConfig.showLookbookTab}
										onChange={(e) =>
											this.updateShowControls(
												"showLookbookTab",
												e.target.checked
											)
										}
									/>
									<div className={toggle.sliderSm} />
								</div>
							</label>
						</div>
					</div>
					<div className={form.section}>
						<div className={form.header}>
							<label className={toggle.checkSpaceBetween}>
								<div className={toggle.labelSm}>Show Closet Tab</div>
								<div className={toggle.switchSm}>
									<input
										type="checkbox"
										checked={previewConfig.showClosetTab}
										onChange={(e) =>
											this.updateShowControls(
												"showClosetTab",
												e.target.checked
											)
										}
									/>
									<div className={toggle.sliderSm} />
								</div>
							</label>
						</div>
					</div>
				</div>
			</>
		)
	}
}

export default createFragmentContainer(EditPreviewClient, {
	currentUser: graphql`
	fragment editPreviewClient_currentUser on User
	@argumentDefinitions(folder: { type: "String!" }) {
		shopcastContainer {
			id
			folder(id: $folder) {
				id
				name
				displayName
				description
				isShared
				sharableLink(noCheck: true)
				isParentAClient
				avatar
				bannerPhoto
				clients {
					id
					fullname
					email
					avatar
				}
				previewConfig {
					showBanner
					showAvatar
					showDisplayName
					showDescription
					showLookbookTab
					showClosetTab
					showShopcastsSection
					showShopboardsSection
					showFilesSection
					showCollectionsSection
				}
			}
		}
		sharedFolderShopcasts(first: 1, folderId: $folder) {
			edges {
				node {
					id
				}
			}
		}
		sharedFolderLookbooks(first: 1, folderId: $folder) {
			edges {
				node {
					id
				}
			}
		}
		sharedFolderFiles(first: 1, folderId: $folder) {
			edges {
				node {
					id
				}
			}
		}
		sharedFolderCollectionItems(first: 1, folderId: $folder) {
			edges {
				node {
					... on ShopcastProduct {
						id
					}
					... on lookbookUploads {
						id
					}
				}
			}
		}
	}`
})
