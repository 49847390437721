import { commitMutation, graphql, ConnectionHandler } from "react-relay"
import { complete } from "../helper"

const mutation = graphql`
	mutation updateLookbookMutation($input: CreateLookbookInput!) {
		createLookbook(input: $input) {
			lookbook {
				id
				lookbookId
				title
				url
			}
			template {
				id
				categories
				canEdit
				isFavourite
				createdCount
				isGlobal
			}
		}
	}
`

const commit = (env, data, file, onError, onCompleted, other) =>
	commitMutation(env, {
		mutation,
		variables: { input: data },
		uploadables: {
			file,
		},
		onCompleted: complete(onCompleted, onError),
		onError: (e) => complete(null, [e]),
		updater: (storeProxy, output) => {
			if (!data.isTemplate || !output.createLookbook.template || !other) return
			const currentUser = storeProxy
				.getRoot()
				.getLinkedRecord("store")
				.getLinkedRecord("currentUser")
			const edge = storeProxy.create(
				`LookbookTemplates:connection:${new Date().getTime()}`,
				"LookbookTemplatesEdge"
			)
			const itemProxy = storeProxy.get(output.createLookbook.template.id)
			edge.setLinkedRecord(itemProxy, "node")
			const lookbookProxy = storeProxy.get(output.createLookbook.lookbook.id)
			itemProxy.setLinkedRecord(lookbookProxy, "lookbook")

			const connection = ConnectionHandler.getConnection(
				currentUser,
				"currentUser_lookbookTemplates",
				other
			)
			if (connection) ConnectionHandler.insertEdgeBefore(connection, edge)
		}
	})

export default { commit }
