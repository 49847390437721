import React from "react"
import { createFragmentContainer, graphql } from "react-relay"
import AdminUserReportItem from "../admin-user-report-item/admin-user-report-item.jsx"
import { withOutletContext } from "../../../route-helpers.jsx"

function AdminUserReports(props) {
	const {
		admin: reports
	} = props
	return reports.shopcastUserReports.edges.map((r) => (
		<AdminUserReportItem
			shopcastUserReport={r.node}
			adminId={props.admin.id}
			key={r.cursor}
			store={props.store}
		/>
	))
}

const AdminUserReportsComp = createFragmentContainer(AdminUserReports, {
	admin: graphql`
		fragment adminUserReports_reports on Admin {
			id
			shopcastUserReports(first: 50)
				@connection(key: "admin_shopcastUserReports", filters: ["first"]) {
				edges {
					node {
						__typename
						...adminUserReportItem_shopcastUserReport
					}
				}
			}
		}
	`,
})

export default withOutletContext(AdminUserReportsComp)
