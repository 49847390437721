import React, { PureComponent } from "react"
import { createPaginationContainer, graphql } from "react-relay"
import ListOfLookbooks from "components/list-of-lookbooks/list-of-lookbooks"

class SearchResultLookbooks extends PureComponent {
	render() {
		const { store, relay } = this.props
		return (
			<ListOfLookbooks
				enableHeader
				lookbooks={store.search.lookbooks}
				relay={relay}
				currentUser={store.currentUser}
				storeId={store.id}
			/>
		)
	}
}

const query = graphql`
	query searchResultLookbooksQuery(
		$count: Int!
		$cursor: String
		$keyword: String!
	) {
		store {
			...searchResultLookbooks_store
				@arguments(count: $count, cursor: $cursor, keyword: $keyword)
		}
	}
`

SearchResultLookbooks = createPaginationContainer(
	SearchResultLookbooks,
	{
		store: graphql`
			fragment searchResultLookbooks_store on Store
			@argumentDefinitions(
				count: { type: "Int", defaultValue: 12 }
				cursor: { type: "String" }
				keyword: { type: "String!" }
			) {
				id
				currentUser {
					...lookbookCard_currentUser
				}
				search(keyword: $keyword) {
					lookbooks(first: $count, after: $cursor)
						@connection(key: "search_lookbooks") {
						edges {
							node {
								...lookbookCard_lookbook
							}
						}
					}
				}
			}
		`,
	},
	{
		direction: "forward",
		getConnectionFromProps(props) {
			const { search } = props.store
			return search && search.lookbooks
		},
		getFragmentVariables(prevVars, totalCount) {
			return {
				...prevVars,
				count: totalCount,
			}
		},
		getVariables(props, { count, cursor }) {
			return {
				count,
				cursor,
				keyword: props.keyword,
			}
		},
		query,
	}
)

export default {
	Component: SearchResultLookbooks,
	query,
	params: { count: 12, cursor: null, keyword: null },
}
