/**
 * @generated SignedSource<<0a00908d7c530c5ae9b0c3c3d034c813>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Mutation } from 'relay-runtime';
export type DuplicateTemplateInput = {|
  clientMutationId?: ?string,
  lookbookId: string,
|};
export type duplicateTemplateMutation$variables = {|
  input: DuplicateTemplateInput,
|};
export type duplicateTemplateMutation$data = {|
  +duplicateTemplate: ?{|
    +lookbookTemplate: ?{|
      +canEdit: ?boolean,
      +categories: ?$ReadOnlyArray<?string>,
      +createdCount: ?number,
      +id: string,
      +isFavourite: ?boolean,
      +lookbook: ?{|
        +background: ?string,
        +data_json: ?string,
        +group: ?string,
        +height: ?number,
        +id: string,
        +screen_width: ?string,
        +shapes: ?{|
          +edges: ?$ReadOnlyArray<?{|
            +node: ?{|
              +collection: ?string,
              +id: string,
              +path: ?string,
              +viewBox: ?string,
            |},
          |}>,
        |},
        +title: ?string,
        +url: ?string,
        +width: ?number,
      |},
    |},
  |},
|};
export type duplicateTemplateMutation = {|
  response: duplicateTemplateMutation$data,
  variables: duplicateTemplateMutation$variables,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "DuplicateTemplatePayload",
    "kind": "LinkedField",
    "name": "duplicateTemplate",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "LookbookTemplate",
        "kind": "LinkedField",
        "name": "lookbookTemplate",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "categories",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "canEdit",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isFavourite",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "createdCount",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Lookbook",
            "kind": "LinkedField",
            "name": "lookbook",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "title",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "url",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "screen_width",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "data_json",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "width",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "height",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "background",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "group",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "ShapeConnectionConnection",
                "kind": "LinkedField",
                "name": "shapes",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ShapeConnectionEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Shape",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v1/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "viewBox",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "path",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "collection",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "duplicateTemplateMutation",
    "selections": (v2/*: any*/),
    "type": "ShopshareMutationRoot",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "duplicateTemplateMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "bd46de03e8e6cde4ed98861ac0ae8e90",
    "id": null,
    "metadata": {},
    "name": "duplicateTemplateMutation",
    "operationKind": "mutation",
    "text": "mutation duplicateTemplateMutation(\n  $input: DuplicateTemplateInput!\n) {\n  duplicateTemplate(input: $input) {\n    lookbookTemplate {\n      id\n      categories\n      canEdit\n      isFavourite\n      createdCount\n      lookbook {\n        id\n        title\n        url\n        screen_width\n        data_json\n        width\n        height\n        background\n        group\n        shapes {\n          edges {\n            node {\n              id\n              viewBox\n              path\n              collection\n            }\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "c618871111d842deafae6aab272f8567";

module.exports = ((node/*: any*/)/*: Mutation<
  duplicateTemplateMutation$variables,
  duplicateTemplateMutation$data,
>*/);
