import React, { Component, forwardRef } from "react"
import { createPaginationContainer, graphql } from "react-relay"
import ContentLoader from "react-content-loader"
import { MagnifyingGlassIcon } from "@radix-ui/react-icons"
import { VirtuosoGrid } from "react-virtuoso"
import CollectionIcon from "shared/assets/lb-collections-icon.svg"
import grids from "shared/styles/components/grids.css"
import empty from "shared/styles/components/empty.css"
import ImageCard from "components/cards/lookbook/image-card"
import { clickFavourite } from "helpers/lookbook-helper"
import LoadingDots from "components/loading-dots/loading-dots"
import { mobileScreenHeight, mobileScreenWidth } from "../../helpers/ui-helper"
import ItemReferencesModal from "../item-references/item-references"

const gridComponents = {
	List: forwardRef(({ style, children, ...props }, ref) => (
		<div ref={ref} {...props} style={style} className={grids.imageMd}>
			{children}
		</div>
	)),
}

class ListOfProductCollectionItems extends Component {
	constructor(props) {
		super(props)
		this.handleLoadMore = this.handleLoadMore.bind(this)
		this.handleCheckboxChange = this.handleCheckboxChange.bind(this)
		this.isItemLoaded = this.isItemLoaded.bind(this)
		this.state = {
			isLoadingMore: false,
			itemReferenceId: null,
		}
		props.setListData({
			isLoadingData: props.loading,
		})
	}

	componentDidUpdate(prevProps) {
		const { props } = this
		const data = {}
		if (prevProps.loading && !props.loading)
			data.total =
				props.store?.currentUser?.productCollectionSearchItems?.total || 0
		if (prevProps.loading !== props.loading)
			data.isLoadingData = props.loading

		if (Object.keys(data).length > 0) props.setListData(data)
	}

	handleLoadMore() {
		const { relay } = this.props
		this.setState({ isLoadingMore: true })
		relay.loadMore(25, () => {
			this.setState({ isLoadingMore: false })
		})
	}

	handleCheckboxChange({ node, target }) {
		const { checked } = target
		const { onSelect, onDeselect } = this.props
		;(checked ? onSelect : onDeselect)({
			id: node.id,
			type: node.__typename,
			collections: node.productCollections,
			deselect: () => {
				this.handleCheckboxChange({
					node,
					target: { checked: false },
				})
			},
		})
	}

	isItemLoaded(index) {
		const { props } = this
		const { store, relay } = props
		const { productCollectionSearchItems: collection } = store.currentUser
		const items = collection.data.edges
		return !relay.hasMore() || index < items.length
	}

	renderSkeleton(i) {
		return (
			<ContentLoader
				key={i}
				viewBox="0 0 290 353"
				style={{ width: "100%", height: "100%" }}
				speed={0}
				animate={false}
				primarycolor="#EDEDED"
				preserveAspectRatio="xMidYMid meet"
				secondarycolor="#E6E6E6"
			>
				<rect width="290" height="290" rx="4" />
				<rect width="60" height="16" y="322" rx="2" />
				<rect width="200" height="16" y="302" rx="2" />
			</ContentLoader>
		)
	}

	renderImageCard(index) {
		const { props } = this
		const { store, relay, team } = props
		const { productCollectionSearchItems: collection } = store.currentUser
		const items = collection.data.edges
		const x = items[index]
		if (!x) return null
		const selectedItems = Object.values(props.selectedItems).filter(
			(i) => !!i
		)
		return (
			<ImageCard
				key={x?.node.id}
				isDraggable={
					selectedItems.length === 0 &&
					window.screen.width > mobileScreenWidth &&
					window.screen.height > mobileScreenHeight
				}
				node={x?.node}
				price={x?.node?.offerPrice || x?.node?.regularPrice}
				description={x?.node?.title}
				canEdit
				showAddButton={false}
				enableCardSelection={selectedItems.length > 0}
				editImage={() => {
					props.editImage(x)
				}}
				deleteImage={() => {
					props.deleteImage(x)
				}}
				isSelected={x?.node?.id && !!props.selectedItems[x?.node?.id]}
				isFavourite={x?.node?.isFavourite}
				showFavouriteButton
				clickFavourite={() =>
					clickFavourite(x?.node?.id, x?.node?.__typename, team, relay)
				}
				handleCheckboxChange={this.handleCheckboxChange}
				contentEditable
				relay={relay}
				store={store}
				showDownloadButtons
				showReferences={() => {
					this.setState({ itemReferenceId: x?.node?.id })
				}}
			/>
		)
	}

	renderLoadMoreDots() {
		return (
			<div key="loading" className={grids.loadMore}>
				<LoadingDots />
			</div>
		)
	}

	render() {
		const {
			props,
			state: { isLoadingMore, itemReferenceId },
		} = this
		const { store, relay, loading, search } = props
		if (loading)
			return (
				<div className={grids.container}>
					<div className={grids.imageMd}>
						{Array(30)
							.fill(0)
							.map((_, i) => this.renderSkeleton(i))}
					</div>
				</div>
			)

		const { productCollectionSearchItems: collection } = store.currentUser
		if (!collection || collection.data.edges.length <= 0)
			return (
				<div className={empty.root}>
					<div className={empty.container}>
						{search ? (
							<div className={empty.content}>
								<MagnifyingGlassIcon className={empty.icon} />
								<h4 className={empty.headline}>No results found</h4>
								<p className={empty.subline}>
									Please try different criteria.
								</p>
							</div>
						) : (
							<div className={empty.content}>
								<CollectionIcon className={empty.icon} />
								<h4 className={empty.headline}>
									There&apos;s nothing here
								</h4>
								<p className={empty.subline}>
									Any content you add will appear here.
								</p>
							</div>
						)}
					</div>
				</div>
			)

		const items = collection.data.edges
		const itemCount = relay.hasMore() ? items.length + 1 : items.length
		const loadMoreItems = isLoadingMore ? () => {} : this.handleLoadMore

		return (
			<>
				<VirtuosoGrid
					useWindowScroll
					totalCount={itemCount}
					components={{
						List: gridComponents.List,
						Footer: isLoadingMore ? this.renderLoadMoreDots : null,
					}}
					increaseViewportBy={200}
					itemContent={(index) => this.renderImageCard(index)}
					endReached={loadMoreItems}
				/>
				<ItemReferencesModal
					itemId={itemReferenceId}
					closeModal={() => {
						this.setState({ itemReferenceId: null })
					}}
				/>
			</>
		)
	}
}

const query = graphql`
	query listOfProdCollectionItemsQuery(
		$search: String
		$categories: [String]
		$brands: [String]
		$collections: [String]
		$count: Int!
		$cursor: String
		$sort: String
		$type: String
		$clients: [String]
		$teamId: String
	) {
		store {
			...listOfProdCollectionItems_store
				@arguments(
					count: $count
					cursor: $cursor
					search: $search
					categories: $categories
					brands: $brands
					collections: $collections
					sort: $sort
					type: $type
					clients: $clients
					teamId: $teamId
				)
		}
	}
`

ListOfProductCollectionItems = createPaginationContainer(
	ListOfProductCollectionItems,
	{
		store: graphql`
			fragment listOfProdCollectionItems_store on Store
			@argumentDefinitions(
				count: { type: "Int", defaultValue: 12 }
				cursor: { type: "String" }
				search: { type: "String" }
				categories: { type: "[String]" }
				brands: { type: "[String]" }
				collections: { type: "[String]" }
				sort: { type: "String" }
				type: { type: "String" }
				clients: { type: "[String]" }
				teamId: { type: "String" }
			) {
				currentUser {
					id
					productCollectionSearchItems(
						search: $search
						categories: $categories
						brands: $brands
						collections: $collections
						sort: $sort
						type: $type
						clientIds: $clients
						limitType: ["collections", "closet"]
						teamId: $teamId
					) {
						total
						data(first: $count, after: $cursor)
							@connection(
								key: "currentUser_productCollectionSearchItems_data"
							) {
							edges {
								node {
									__typename
									... on ShopcastProduct {
										id
										title
										brand
										description
										url
										affiliated_url(type: null)
										custom_affiliated_url
										imageUrl
										diffbotImageUrl
										regularPrice
										offerPrice
										error
										productImages
										hasProductImagesFromDiffbot
										isFavourite(teamId: $teamId)
										productCollections {
											id
											title(ownershipCheck: true)
											isACloset
											isPublished
										}
										user {
											id
											fullname
											profilePhoto
											client {
												fullname
												avatar
											}
										}
									}
									... on lookbookUploads {
										id
										adminAccess
										canEdit
										title
										isFavourite(teamId: $teamId)
										productCollections {
											id
											title(ownershipCheck: true)
											isACloset
											isPublished
										}
										user {
											id
											fullname
											profilePhoto
											client {
												fullname
												avatar
											}
										}
										url
									}
								}
							}
						}
					}
				}
			}
		`,
	},
	{
		direction: "forward",
		getConnectionFromProps(props) {
			const { data } = props.store.currentUser.productCollectionSearchItems
			return data && data
		},
		getFragmentVariables(prevVars, totalCount) {
			return {
				...prevVars,
				count: totalCount,
			}
		},
		getVariables(props, { cursor }) {
			return {
				count: props.count,
				cursor,
				search: props.search,
				categories: props.categories,
				brands: props.brands,
				collections: props.collections,
				sort: props.sort,
				type: props.type,
				clients: props.clients,
				teamId: props.teamId,
			}
		},
		query,
	}
)

export default {
	Component: ListOfProductCollectionItems,
	query,
	params: {
		count: 12,
		cursor: null,
		search: null,
		categories: [],
		brands: [],
		collections: [],
		sort: "n-to-o",
		type: "",
		clients: [],
		teamId: null,
	},
	props: {
		count: 12,
		search: null,
		categories: [],
		brands: [],
		collections: [],
		sort: "n-to-o",
		type: "",
		clients: [],
		teamId: null,
	},
	showLoading: true,
}
