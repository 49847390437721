/**
 * @generated SignedSource<<f57d4c3d09d22c1b2fb5570512e7d8c4>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Mutation } from 'relay-runtime';
export type userEnumType = "CLIENT" | "RETAILER" | "STYLIST" | "%future added value";
export type SignupInput = {|
  clientMutationId?: ?string,
  code: string,
  country: string,
  fullname: string,
  isAgreedToTAndCAndPrivacyPolicy: boolean,
  isSubscribeToEmailUpdates: boolean,
  password: string,
  recaptchaToken: string,
  referral?: ?string,
  type: userEnumType,
  username: string,
|};
export type signupMutation$variables = {|
  input: SignupInput,
|};
export type signupMutation$data = {|
  +signup: ?{|
    +store: ?{|
      +currentUser: ?{|
        +email: ?string,
        +fullname: ?string,
        +id: string,
        +type: ?userEnumType,
        +user_hash: ?string,
        +username: ?string,
      |},
      +id: string,
    |},
    +user: ?{|
      +token: ?string,
    |},
  |},
|};
export type signupMutation = {|
  response: signupMutation$data,
  variables: signupMutation$variables,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "token",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "Store",
  "kind": "LinkedField",
  "name": "store",
  "plural": false,
  "selections": [
    (v3/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "currentUser",
      "plural": false,
      "selections": [
        (v3/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "username",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "fullname",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "email",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "user_hash",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "type",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "signupMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SignupPayload",
        "kind": "LinkedField",
        "name": "signup",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "ShopshareMutationRoot",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "signupMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SignupPayload",
        "kind": "LinkedField",
        "name": "signup",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/)
            ],
            "storageKey": null
          },
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "6c3a0d6a5e30b80bda64f0f520482332",
    "id": null,
    "metadata": {},
    "name": "signupMutation",
    "operationKind": "mutation",
    "text": "mutation signupMutation(\n  $input: SignupInput!\n) {\n  signup(input: $input) {\n    user {\n      token\n      id\n    }\n    store {\n      id\n      currentUser {\n        id\n        username\n        fullname\n        email\n        user_hash\n        type\n      }\n    }\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "c6299aeea65588b03606495e49dfd61b";

module.exports = ((node/*: any*/)/*: Mutation<
  signupMutation$variables,
  signupMutation$data,
>*/);
