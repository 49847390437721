import { commitMutation, graphql } from "react-relay"
import { complete } from "../helper.js"

const mutation = graphql`
mutation uploadFolderAvatarMutation($input: UploadShopcastFolderAvatarInput!) {
    uploadShopcastFolderAvatar(input: $input) {
      store {
        currentUser {
          profilePhoto
        }
      }
      folder {
        id
        avatar
      }
    }
  }
`

const commit = (env, file, folderId, onCompleted, onError) =>
	commitMutation(env, {
		mutation,
		variables: { input: { imageName: file ? file.name : null, folderId } },
		uploadables: { file },
		onCompleted: complete(onCompleted, onError),
		onError: (e) => complete(null, [e]),
		updater: (storeProxy, data) => {
			const folderProxy = storeProxy.get(folderId)
			folderProxy.setValue(data.uploadShopcastFolderAvatar.folder.avatar, "avatar")
		},
	})

export default { commit }
