import { commitMutation, graphql } from "react-relay"
import { complete } from "../helper.js"

const mutation = graphql`
	mutation copyToMutation($input: CopyShopcastItemInput!) {
		copyShopcastItem(input: $input) {
			store {
				id
			}
		}
	}
`

const commit = (env, items, teamId, onError, onSuccess) =>
	commitMutation(env, {
		mutation,
		variables: { input: { ids: items, teamId } },
		onCompleted: complete(onSuccess, onError),
		onError: () => complete(null, onError),
	})

export default { commit }
