import { commitMutation, graphql } from "react-relay"
import { complete } from "../helper.js"

const mutation = graphql`
	mutation updateProductMutation($input: UpdateProductInput!) {
		updateProduct(input: $input) {
			store {
				id
				shopcast {
					id
				}
			}
			product {
				title
				regularPrice
				offerPrice
				custom_affiliated_url
				affiliated_url
				diffbotImageUrl
				imageUrl
				productImages
				brand
			}
		}
	}
`

const getConfig = (storeId) => [
	{
		type: "FIELDS_CHANGE",
		fieldIDs: { store: storeId },
	},
]

const getVariables = (input) => ({
	productId: input.productId,
	title: input.title,
	regularPrice: input.regularPrice,
	offerPrice: input.offerPrice,
	imageUrl: input.imageUrl,
	brand: input.brand,
	affiliated_url: input.affiliated_url,
})

const commit = (env, input, storeId, onCompleted) =>
	commitMutation(env, {
		mutation,
		variables: {
			input: getVariables(input),
		},
		configs: getConfig(storeId),
		onError: (e) => complete(null, [e]),
		updater: (storeProxy, data) => {
			if (data.updateProduct) {
				const productProxy = storeProxy.get(input.productId)
				const set = (val, name, required) => {
					if (required) {
						if (val && productProxy) {
							productProxy.setValue(val, name)
						}
					} else if (productProxy) productProxy.setValue(val, name)
				}
				set(input.title, "title", true)
				set(input.regularPrice, "regularPrice", false)
				set(input.offerPrice, "offerPrice", false)
				set(input.affiliated_url, "custom_affiliated_url", false)
				set(
					data.updateProduct?.product?.diffbotImageUrl || input.imageUrl,
					"diffbotImageUrl",
					true
				)
				set(
					data.updateProduct?.product?.imageUrl || input.imageUrl,
					"imageUrl",
					true
				)
				set(
					data.updateProduct?.product?.affiliated_url,
					"affiliated_url",
					true
				)
				if (data.updateProduct?.product?.productImages)
					set(
						data.updateProduct?.product?.productImages,
						"productImages",
						true
					)
				set(input.brand, "brand", false)
			}
			onCompleted()
		},
	})

export default { commit }
