/**
 * @generated SignedSource<<9f5b968b938d4dd04880ef228c275325>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Mutation } from 'relay-runtime';
export type ToggleFilePublishedInput = {|
  clientMutationId?: ?string,
  fileId: string,
|};
export type toggleFilePublishedMutation$variables = {|
  input: ToggleFilePublishedInput,
|};
export type toggleFilePublishedMutation$data = {|
  +toggleFilePublished: ?{|
    +output: ?string,
  |},
|};
export type toggleFilePublishedMutation = {|
  response: toggleFilePublishedMutation$data,
  variables: toggleFilePublishedMutation$variables,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "ToggleFilePublishedPayload",
    "kind": "LinkedField",
    "name": "toggleFilePublished",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "output",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "toggleFilePublishedMutation",
    "selections": (v1/*: any*/),
    "type": "ShopshareMutationRoot",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "toggleFilePublishedMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "177fb7ba83767deee4f04dba211a0e27",
    "id": null,
    "metadata": {},
    "name": "toggleFilePublishedMutation",
    "operationKind": "mutation",
    "text": "mutation toggleFilePublishedMutation(\n  $input: ToggleFilePublishedInput!\n) {\n  toggleFilePublished(input: $input) {\n    output\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "bbcdc86f39de10153dc084763ccd0f69";

module.exports = ((node/*: any*/)/*: Mutation<
  toggleFilePublishedMutation$variables,
  toggleFilePublishedMutation$data,
>*/);
