/**
 * @generated SignedSource<<37fbd357d44301777ad08021b1cfb246>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Mutation } from 'relay-runtime';
export type ProfilePhotoInput = {|
  clientMutationId?: ?string,
  imageName?: ?string,
  isDisplayAvatar?: ?boolean,
  profileId?: ?string,
|};
export type profilePhotoMutation$variables = {|
  input: ProfilePhotoInput,
|};
export type profilePhotoMutation$data = {|
  +profilePhoto: ?{|
    +store: ?{|
      +currentUser: ?{|
        +displayAvatar: ?string,
        +profilePhoto: ?string,
      |},
    |},
    +user: ?{|
      +displayAvatar: ?string,
      +profilePhoto: ?string,
    |},
  |},
|};
export type profilePhotoMutation = {|
  response: profilePhotoMutation$data,
  variables: profilePhotoMutation$variables,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "profilePhoto",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "displayAvatar",
  "storageKey": null
},
v4 = [
  (v2/*: any*/),
  (v3/*: any*/)
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v6 = [
  (v2/*: any*/),
  (v3/*: any*/),
  (v5/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "profilePhotoMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ProfilePhotoPayload",
        "kind": "LinkedField",
        "name": "profilePhoto",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Store",
            "kind": "LinkedField",
            "name": "store",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "User",
                "kind": "LinkedField",
                "name": "currentUser",
                "plural": false,
                "selections": (v4/*: any*/),
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": (v4/*: any*/),
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "ShopshareMutationRoot",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "profilePhotoMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ProfilePhotoPayload",
        "kind": "LinkedField",
        "name": "profilePhoto",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Store",
            "kind": "LinkedField",
            "name": "store",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "User",
                "kind": "LinkedField",
                "name": "currentUser",
                "plural": false,
                "selections": (v6/*: any*/),
                "storageKey": null
              },
              (v5/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": (v6/*: any*/),
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "4d98facb22620a342f59bf1259d0f2ea",
    "id": null,
    "metadata": {},
    "name": "profilePhotoMutation",
    "operationKind": "mutation",
    "text": "mutation profilePhotoMutation(\n  $input: ProfilePhotoInput!\n) {\n  profilePhoto(input: $input) {\n    store {\n      currentUser {\n        profilePhoto\n        displayAvatar\n        id\n      }\n      id\n    }\n    user {\n      profilePhoto\n      displayAvatar\n      id\n    }\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "5e9de59cf3ada014f9d4fe547249cfff";

module.exports = ((node/*: any*/)/*: Mutation<
  profilePhotoMutation$variables,
  profilePhotoMutation$data,
>*/);
