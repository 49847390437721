import React, { Fragment } from "react"
import { Link } from "react-router-dom"
import { createFragmentContainer, graphql } from "react-relay"
import { Helmet } from "react-helmet"
import { toast } from "react-toastify"
import queryString from "query-string"
import auth from "routes/signup/signup.css"
// import ShopShareIcon from "shared/assets/shopshare-icon.svg"
import textfield from "../../shared/styles/components/textfield.css"
import form from "../../shared/styles/components/form.css"

import Logo from "../../shared/assets/logo.svg"
import Loading from "../../components/loading/loading.jsx"
import ResetPasswordMutation from "../../mutations/user/reset-password"
import gqlh from "../../helpers/gql-helper"
import { withRouter } from "../../route-helpers.jsx"

class ResetPassword extends React.Component {
	constructor(props) {
		super(props)
		this.resetPassword = this.resetPassword.bind(this)
		this.handlePasswordChange = this.handlePasswordChange.bind(this)
		this.handleMutationSuccess = this.handleMutationSuccess.bind(this)
		this.handleMutationFailed = this.handleMutationFailed.bind(this)

		this.state = {
			password: "",
			loading: false,
		}
		this.token = ""
	}

	componentDidMount() {
		const { navigate, location } = this.props
		if (
			!location.search ||
			!queryString.parse(location.search).t
		) {
			navigate("/signin")
		} else {
			this.token = queryString.parse(location.search).t
		}
	}

	handlePasswordChange({ target }) {
		this.setState({ password: target.value })
	}

	handleMutationSuccess() {
		const { navigate } = this.props

		toast.success(<Fragment>Password changed successfully.</Fragment>, {
			autoClose: 2000,
			closeButton: false,
		})
		this.setState({ loading: false })
		navigate("/signin")
	}

	handleMutationFailed(transaction) {
		toast.error(
			<div>{gqlh.getError(transaction, "Could not change password")}</div>,
			{ autoClose: 2000, closeButton: false }
		)
		this.setState({ loading: false })
	}

	resetPassword() {
		const { props } = this
		this.setState({ loading: true })
		const { state } = this

		ResetPasswordMutation.commit(
			props.relay.environment,
			state.password,
			this.token,
			this.handleMutationSuccess,
			this.handleMutationFailed
		)
	}

	render() {
		const { state } = this
		return (
			<div className={auth.root}>
				<Helmet>
					<title>Reset your password – ShopShare</title>
					<body className="Auth" />
				</Helmet>
				{state.loading && <Loading />}
				{!state.loading && (
					<Fragment>
						<header className={auth.header}>
							<nav className={auth.navDesktop}>
								<div className={auth.navContainer}>
									<Link to="/">
										<Logo className={auth.logo} />
									</Link>
								</div>
							</nav>
						</header>
						<section className={auth.section}>
							<div className={auth.landing}>
								<div className={auth.content}>
									<div className={auth.card}>
										<div className={auth.cardHeader}>
											<h1 className={auth.title}>
												Reset your password?
											</h1>
										</div>
										<form>
											<div className={form.group}>
												<label className={form.label}>
													New password
												</label>
												<input
													className={textfield.normal}
													value={state.password}
													type="password"
													placeholder="Type a password"
													onChange={this.handlePasswordChange}
												/>
											</div>
											<button
												type="button"
												disabled={!state.password}
												className={auth.btn}
												onClick={this.resetPassword}
											>
												Continue
											</button>
										</form>
									</div>
								</div>
							</div>
						</section>
					</Fragment>
				)}
			</div>
		)
	}
}

ResetPassword = createFragmentContainer(ResetPassword, {
	store: graphql`
		fragment resetPassword_store on Store {
			id
		}
	`,
})

const query = graphql`
	query resetPasswordQuery {
		store {
			...resetPassword_store
		}
	}
`

export default { Component: withRouter(ResetPassword), query }
