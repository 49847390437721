/**
 * @generated SignedSource<<455d2c61b78f71ece8670811cb30eb33>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentType } from "relay-runtime";
declare export opaque type forNetworkMarketers_store$fragmentType: FragmentType;
export type forNetworkMarketers_store$data = {|
  +currentUser: ?{|
    +id: string,
  |},
  +id: string,
  +$fragmentType: forNetworkMarketers_store$fragmentType,
|};
export type forNetworkMarketers_store$key = {
  +$data?: forNetworkMarketers_store$data,
  +$fragmentSpreads: forNetworkMarketers_store$fragmentType,
  ...
};
*/

var node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "forNetworkMarketers_store",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "currentUser",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "type": "Store",
  "abstractKey": null
};
})();

(node/*: any*/).hash = "9745308b1617d64a08d4f4bcbfac4a70";

module.exports = ((node/*: any*/)/*: Fragment<
  forNetworkMarketers_store$fragmentType,
  forNetworkMarketers_store$data,
>*/);
