/**
 * @generated SignedSource<<87c234a8a164fdf26982c9582bc3a2a8>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Query } from 'relay-runtime';
import type { moodboards_store$fragmentType } from "./moodboards_store.graphql";
export type moodboardsQuery$variables = {||};
export type moodboardsQuery$data = {|
  +store: ?{|
    +$fragmentSpreads: moodboards_store$fragmentType,
  |},
|};
export type moodboardsQuery = {|
  response: moodboardsQuery$data,
  variables: moodboardsQuery$variables,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "moodboardsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Store",
        "kind": "LinkedField",
        "name": "store",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "moodboards_store"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "ShopshareQueryRoot",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "moodboardsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Store",
        "kind": "LinkedField",
        "name": "store",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "currentUser",
            "plural": false,
            "selections": [
              (v0/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "4e8b317fd305c83a52d013850fcebd64",
    "id": null,
    "metadata": {},
    "name": "moodboardsQuery",
    "operationKind": "query",
    "text": "query moodboardsQuery {\n  store {\n    ...moodboards_store\n    id\n  }\n}\n\nfragment moodboards_store on Store {\n  id\n  currentUser {\n    id\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "46d06dc11e4f346267d1fd2589dbec54";

module.exports = ((node/*: any*/)/*: Query<
  moodboardsQuery$variables,
  moodboardsQuery$data,
>*/);
