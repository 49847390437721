/**
 * @generated SignedSource<<1e6d22a209c544b5219ae8137f345188>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Query } from 'relay-runtime';
import type { featured_store$fragmentType } from "./featured_store.graphql";
export type featuredQuery$variables = {||};
export type featuredQuery$data = {|
  +store: ?{|
    +$fragmentSpreads: featured_store$fragmentType,
  |},
|};
export type featuredQuery = {|
  response: featuredQuery$data,
  variables: featuredQuery$variables,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "featuredQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Store",
        "kind": "LinkedField",
        "name": "store",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "featured_store"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "ShopshareQueryRoot",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "featuredQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Store",
        "kind": "LinkedField",
        "name": "store",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": [
              {
                "kind": "Literal",
                "name": "orderBy",
                "value": "shopcastCount"
              }
            ],
            "concreteType": "StoreCategoriesConnectionConnection",
            "kind": "LinkedField",
            "name": "bestCategories",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "StoreCategoriesConnectionEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Category",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "name",
                        "storageKey": null
                      },
                      (v0/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": "bestCategories(orderBy:\"shopcastCount\")"
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "currentUser",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "adminAccess",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasPendingSharerRequests",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isASharer",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isANewUser",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "2fb9176d15aa9e0dc79f85467b48788d",
    "id": null,
    "metadata": {},
    "name": "featuredQuery",
    "operationKind": "query",
    "text": "query featuredQuery {\n  store {\n    ...featured_store\n    id\n  }\n}\n\nfragment featured_store on Store {\n  id\n  bestCategories(orderBy: \"shopcastCount\") {\n    edges {\n      node {\n        name\n        id\n      }\n    }\n  }\n  currentUser {\n    id\n    adminAccess\n    hasPendingSharerRequests\n    isASharer\n    isANewUser\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "ef68767aec6c9435a7f38c2f8bd12898";

module.exports = ((node/*: any*/)/*: Query<
  featuredQuery$variables,
  featuredQuery$data,
>*/);
