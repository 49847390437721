import { commitMutation, graphql } from "react-relay"
import { complete } from "../helper.js"

const mutation = graphql`
	mutation connectSocialMediaMutation($input:  ConnectSocialMediaInput!) {
		connectSocialMedia(input: $input) {
			redirectUrl 
		}
	}
`

const commit = (env, id, platform, status, onCompleted, onError) =>
	commitMutation(env, {
		mutation,
		variables: { input: { platform, status } },
		onCompleted: complete(onCompleted, onError),
		onError: (e) => complete(null, [e]),
	})

export default { commit }
