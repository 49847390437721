import { commitMutation, graphql } from "react-relay"
import { complete } from "../helper.js"

const mutation = graphql`
	mutation updateSocialLinksMutation($input: UpdateSocialLinksInput!) {
		updateSocialLinks(input: $input) {
			links {
				instagramUsername
			}
		}
	}
`

const commit = (env, id, links, onCompleted, onError) =>
	commitMutation(env, {
		mutation,
		variables: { input: links },
		updater: (storeProxy) => {
			const userProxy = storeProxy.get(id)
			const socialProxy = userProxy.getLinkedRecord("socialLinks")
			if (!socialProxy) return
			if (links.website !== undefined)
				socialProxy.setValue(links.website, "website")
			if (links.instagramUsername !== undefined)
				socialProxy.setValue(links.instagramUsername, "instagramUsername")
			if (links.facebookUrl !== undefined)
				socialProxy.setValue(links.facebookUrl, "facebookUrl")
			if (links.twitterUsername !== undefined)
				socialProxy.setValue(links.twitterUsername, "twitterUsername")
			if (links.youtubeUrl !== undefined)
				socialProxy.setValue(links.youtubeUrl, "youtubeUrl")
			if (links.pinterestUrl !== undefined)
				socialProxy.setValue(links.pinterestUrl, "pinterestUrl")
			if (links.tiktokUrl !== undefined)
				socialProxy.setValue(links.tiktokUrl, "tiktokUrl")
		},
		onCompleted: complete(onCompleted, onError),
		onError: (e) => complete(null, [e]),
	})

export default { commit }
