import { commitMutation, graphql } from "react-relay"
import { complete } from "../helper"

const mutation = graphql`
	mutation leaveTeamMutation($input: LeaveTeamInput!) {
		leaveTeam(input: $input) {
			store {
                id
            }
		}
	}
`

const commit = (env, id, onError, onCompleted) =>
	commitMutation(env, {
		mutation,
		variables: { input: { teamId: id } },
		onCompleted: complete(onCompleted, onError),
		onError: (e) => complete(null, [e]),
		updater: (storeProxy) => {
			const currentUser = storeProxy
				.getRoot()
				.getLinkedRecord("store")
				.getLinkedRecord("currentUser")
			const teams = currentUser.getLinkedRecords("teams")
			currentUser.setLinkedRecords(
				teams.filter((x) => x.getValue("id") !== id),
				"teams"
			)
		},
	})

export default { commit }
