/**
 * @generated SignedSource<<d8aeb71a13d81522272618b6f28e74c8>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentType } from "relay-runtime";
declare export opaque type shopcastManager_store$fragmentType: FragmentType;
export type shopcastManager_store$data = {|
  +categories: ?$ReadOnlyArray<?{|
    +id: string,
    +name: ?string,
  |}>,
  +currentUser: ?{|
    +adminAccess: ?string,
    +bannerPhoto: ?string,
    +customDomain: ?string,
    +id: string,
    +profilePhoto: ?string,
    +role: ?string,
    +shopcastContainer: ?{|
      +folder: ?{|
        +avatar: ?string,
        +bannerPhoto: ?string,
        +clients: ?$ReadOnlyArray<?{|
          +avatar: ?string,
          +email: ?string,
          +fullname: ?string,
          +id: string,
          +isUserAlreadyExist: ?boolean,
        |}>,
        +clientsCount: ?number,
        +collectionCount: ?number,
        +description: ?string,
        +filesCount: ?number,
        +folders: ?{|
          +edges: ?$ReadOnlyArray<?{|
            +node: ?{|
              +id: string,
            |},
          |}>,
        |},
        +foldersCount: ?number,
        +id: string,
        +inRoot: ?boolean,
        +isArchived: ?boolean,
        +isParentAClient: ?boolean,
        +isPinned: ?boolean,
        +isStarred: ?boolean,
        +items: ?{|
          +edges: ?$ReadOnlyArray<?{|
            +node: ?{|
              +__typename: string,
            |},
          |}>,
        |},
        +lookbooks: ?{|
          +edges: ?$ReadOnlyArray<?{|
            +node: ?{|
              +id: string,
            |},
          |}>,
        |},
        +lookbooksCount: ?number,
        +name: ?string,
        +parent: ?{|
          +id: string,
          +name: ?string,
        |},
        +path: ?$ReadOnlyArray<?{|
          +name: ?string,
          +value: ?string,
        |}>,
        +sharableLink: ?string,
        +sharedFoldersCount: ?number,
        +shopcasts: ?{|
          +edges: ?$ReadOnlyArray<?{|
            +node: ?{|
              +id: string,
            |},
          |}>,
        |},
        +shopcastsCount: ?number,
        +team: ?{|
          +id: string,
        |},
      |},
      +id: string,
    |},
    +teams: ?$ReadOnlyArray<?{|
      +avatar: ?string,
      +features: ?$ReadOnlyArray<?string>,
      +id: string,
      +name: ?string,
    |}>,
    +userClients: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +email: ?string,
          +fullname: ?string,
          +id: string,
        |},
      |}>,
    |},
    +username: ?string,
  |},
  +id: string,
  +$fragmentType: shopcastManager_store$fragmentType,
|};
export type shopcastManager_store$key = {
  +$data?: shopcastManager_store$data,
  +$fragmentSpreads: shopcastManager_store$fragmentType,
  ...
};
*/

var node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "bannerPhoto",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "fullname",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "avatar",
  "storageKey": null
},
v6 = [
  {
    "kind": "Variable",
    "name": "search",
    "variableName": "search"
  },
  {
    "kind": "Variable",
    "name": "teamId",
    "variableName": "teamId"
  }
],
v7 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 100
  }
],
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v9 = [
  (v0/*: any*/),
  (v8/*: any*/)
],
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "cursor",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "concreteType": "PageInfo",
  "kind": "LinkedField",
  "name": "pageInfo",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "endCursor",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasNextPage",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "folder"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "search"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "teamId"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": null,
        "cursor": null,
        "direction": "forward",
        "path": [
          "currentUser",
          "shopcastContainer",
          "folder",
          "lookbooks"
        ]
      },
      {
        "count": null,
        "cursor": null,
        "direction": "forward",
        "path": [
          "currentUser",
          "shopcastContainer",
          "folder",
          "shopcasts"
        ]
      },
      {
        "count": null,
        "cursor": null,
        "direction": "forward",
        "path": [
          "currentUser",
          "shopcastContainer",
          "folder",
          "items"
        ]
      },
      {
        "count": null,
        "cursor": null,
        "direction": "forward",
        "path": [
          "currentUser",
          "shopcastContainer",
          "folder",
          "folders"
        ]
      }
    ]
  },
  "name": "shopcastManager_store",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Category",
      "kind": "LinkedField",
      "name": "categories",
      "plural": true,
      "selections": [
        (v1/*: any*/),
        (v0/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "currentUser",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "adminAccess",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "role",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "username",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "customDomain",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "profilePhoto",
          "storageKey": null
        },
        (v2/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "ClientConnectionConnection",
          "kind": "LinkedField",
          "name": "userClients",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ClientConnectionEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Client",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    (v3/*: any*/),
                    (v4/*: any*/)
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "team",
          "kind": "LinkedField",
          "name": "teams",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            (v1/*: any*/),
            (v5/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "features",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "ShopcastContainer",
          "kind": "LinkedField",
          "name": "shopcastContainer",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": [
                {
                  "kind": "Variable",
                  "name": "id",
                  "variableName": "folder"
                }
              ],
              "concreteType": "ShopcastFolder",
              "kind": "LinkedField",
              "name": "folder",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                (v1/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "description",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "isStarred",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "isArchived",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "inRoot",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "team",
                  "kind": "LinkedField",
                  "name": "team",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/)
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "NameValue",
                  "kind": "LinkedField",
                  "name": "path",
                  "plural": true,
                  "selections": [
                    (v1/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "value",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Client",
                  "kind": "LinkedField",
                  "name": "clients",
                  "plural": true,
                  "selections": [
                    (v0/*: any*/),
                    (v4/*: any*/),
                    (v3/*: any*/),
                    (v5/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "isUserAlreadyExist",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "ShopcastFolder",
                  "kind": "LinkedField",
                  "name": "parent",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    (v1/*: any*/)
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "isParentAClient",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "sharableLink",
                  "storageKey": null
                },
                (v5/*: any*/),
                (v2/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "isPinned",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": (v6/*: any*/),
                  "kind": "ScalarField",
                  "name": "shopcastsCount",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": (v6/*: any*/),
                  "kind": "ScalarField",
                  "name": "lookbooksCount",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": (v6/*: any*/),
                  "kind": "ScalarField",
                  "name": "filesCount",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": (v6/*: any*/),
                  "kind": "ScalarField",
                  "name": "collectionCount",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": (v6/*: any*/),
                  "kind": "ScalarField",
                  "name": "foldersCount",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": (v6/*: any*/),
                  "kind": "ScalarField",
                  "name": "sharedFoldersCount",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": (v6/*: any*/),
                  "kind": "ScalarField",
                  "name": "clientsCount",
                  "storageKey": null
                },
                {
                  "alias": "lookbooks",
                  "args": (v7/*: any*/),
                  "concreteType": "LookbookConnection",
                  "kind": "LinkedField",
                  "name": "__currentUser_shopcastManager_lookbooks_connection",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "LookbookEdge",
                      "kind": "LinkedField",
                      "name": "edges",
                      "plural": true,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "Lookbook",
                          "kind": "LinkedField",
                          "name": "node",
                          "plural": false,
                          "selections": (v9/*: any*/),
                          "storageKey": null
                        },
                        (v10/*: any*/)
                      ],
                      "storageKey": null
                    },
                    (v11/*: any*/)
                  ],
                  "storageKey": "__currentUser_shopcastManager_lookbooks_connection(first:100)"
                },
                {
                  "alias": "shopcasts",
                  "args": (v7/*: any*/),
                  "concreteType": "ShopcastFolderShopcastConnection",
                  "kind": "LinkedField",
                  "name": "__currentUser_shopcastManager_shopcasts_connection",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "ShopcastFolderShopcastEdge",
                      "kind": "LinkedField",
                      "name": "edges",
                      "plural": true,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "Shopcast",
                          "kind": "LinkedField",
                          "name": "node",
                          "plural": false,
                          "selections": (v9/*: any*/),
                          "storageKey": null
                        },
                        (v10/*: any*/)
                      ],
                      "storageKey": null
                    },
                    (v11/*: any*/)
                  ],
                  "storageKey": "__currentUser_shopcastManager_shopcasts_connection(first:100)"
                },
                {
                  "alias": "items",
                  "args": (v7/*: any*/),
                  "concreteType": "FolderItemsConnectionConnection",
                  "kind": "LinkedField",
                  "name": "__currentUser_shopcastManager_items_connection",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "FolderItemsConnectionEdge",
                      "kind": "LinkedField",
                      "name": "edges",
                      "plural": true,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": null,
                          "kind": "LinkedField",
                          "name": "node",
                          "plural": false,
                          "selections": [
                            (v8/*: any*/)
                          ],
                          "storageKey": null
                        },
                        (v10/*: any*/)
                      ],
                      "storageKey": null
                    },
                    (v11/*: any*/)
                  ],
                  "storageKey": "__currentUser_shopcastManager_items_connection(first:100)"
                },
                {
                  "alias": "folders",
                  "args": (v7/*: any*/),
                  "concreteType": "ShopcastFolderShopcastFolderConnection",
                  "kind": "LinkedField",
                  "name": "__currentUser_shopcastManager_folders_connection",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "ShopcastFolderShopcastFolderEdge",
                      "kind": "LinkedField",
                      "name": "edges",
                      "plural": true,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "ShopcastFolder",
                          "kind": "LinkedField",
                          "name": "node",
                          "plural": false,
                          "selections": (v9/*: any*/),
                          "storageKey": null
                        },
                        (v10/*: any*/)
                      ],
                      "storageKey": null
                    },
                    (v11/*: any*/)
                  ],
                  "storageKey": "__currentUser_shopcastManager_folders_connection(first:100)"
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Store",
  "abstractKey": null
};
})();

(node/*: any*/).hash = "6475ae0d50de6f6a8fcb6dc69f599137";

module.exports = ((node/*: any*/)/*: Fragment<
  shopcastManager_store$fragmentType,
  shopcastManager_store$data,
>*/);
