import { Environment, Network, RecordSource, Store } from "relay-runtime"
import axios from "axios"
import { graphqlUrl } from "./constants"

axios.defaults.withCredentials = true

const fetchQuery = (operation, variables, cacheConfig, uploadable) => {
	if (uploadable) {
		const formData = new FormData()
		formData.append("query", operation.text)
		formData.append("variables", JSON.stringify(variables))
		Object.keys(uploadable).forEach((key) => {
			if (Object.prototype.hasOwnProperty.call(uploadable, key)) {
				formData.append(key, uploadable[key])
			}
		})
		return axios.post(graphqlUrl, formData).then((response) => response.data) // .then(cacheResponse);
	}

	return axios
		.post(graphqlUrl, {
			query: operation.text,
			variables,
		})
		.then((response) => response.data) // .then(cacheResponse);
}

const network = Network.create(fetchQuery)
const store = new Store(new RecordSource())

export default new Environment({
	network,
	store,
})
