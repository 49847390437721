import React, { Component, Fragment } from "react"
import { toast } from "react-toastify"
import ClipboardButton from "react-clipboard.js"
import form from "shared/styles/components/form.css"
import contentCard from "shared/styles/layout/content-card.css"
import table from "shared/styles/components/table.css"
import styles from "./referrals.css"
import { getCommisionMessage } from "../../../helpers/referral-helper"

class Referrals extends Component {
	constructor(props) {
		super(props)
		this.state = {}
	}

	render() {
		const {
			currentUser: { referrals },
		} = this.props
		// const totalDetails = referrals.reduce(
		// 	(a, b) => ({
		// 		revenue: a.revenue + b.revenue,
		// 		paidCommision: a.paidCommision + b.paidCommision,
		// 	}),
		// 	{ revenue: 0, paidCommision: 0 }
		// )
		return (
			<Fragment>
				{referrals.map((referral) => (
					<div className={contentCard.root} key={referral.id}>
						<div className={contentCard.header}>
							<h2 className={contentCard.title}>
								{referral.campaign
									? referral.campaign.name
									: "Refer Friends"}
							</h2>
							{referral.isArchived && (
								<span className={styles.badge}>Archived</span>
							)}
						</div>

						<div className={contentCard.content}>
							<p>{getCommisionMessage(referral.campaign)}</p>

							{!referral.isArchived && (
								<div className={styles.row}>
									<div className={form.group}>
										<label className={form.label}>
											Your Referral Link
										</label>
										<div className={styles.referraLinkInput}>
											<div className={styles.inputGroup}>
												<input
													className={styles.input}
													type="text"
													readOnly
													defaultValue={referral.link}
												/>
												<ClipboardButton
													className={styles.copy}
													data-clipboard-text={referral.link}
													data-tooltip-id="tooltip"
													data-tooltip-content="Get referral link"
													onSuccess={() => {
														toast.success(
															<Fragment>Copied</Fragment>,
															{
																autoClose: 2000,
																closeButton: false,
															}
														)
													}}
												>
													Copy
												</ClipboardButton>
											</div>
										</div>
										<div className={form.validationFeedback} />
									</div>
								</div>
							)}

							<div className={styles.row}>
								<div className={styles.col}>
									<div className={contentCard.header}>
										<h4 className={contentCard.heading}>Referrals</h4>
									</div>
									<div className={table.bordered}>
										<div className={table.thead}>
											<div className={table.tr}>
												<div className={table.th}>
													<span
														data-tooltip-id="tooltip"
														data-tooltip-content="No. of times the link has been visited"
													>
														Visitors
													</span>
													{/* <InfoCircledIcon
													className={table.help}
													data-tooltip-content="No. of times the link has been visited"
												/> */}
												</div>
												<div className={table.th}>
													<span
														data-tooltip-id="tooltip"
														data-tooltip-content="No. of users in trial"
													>
														Leads
													</span>
												</div>
												<div className={table.th}>
													<span
														data-tooltip-id="tooltip"
														data-tooltip-content="No. of payments"
													>
														Conversions
													</span>
												</div>
											</div>
											<div className={table.tr}>
												<div className={table.td}>
													{referral.visitors}
												</div>
												<div className={table.td}>
													{referral.leads}
												</div>
												<div className={table.td}>
													<b>{referral.conversions}</b>
												</div>
											</div>
										</div>
									</div>
								</div>

								<div className={styles.col}>
									<div className={contentCard.header}>
										<h4 className={contentCard.heading}>
											Commissions
										</h4>
									</div>
									<div className={table.bordered}>
										<div className={table.thead}>
											<div className={table.tr}>
												<div className={table.th}>
													<span
														data-tooltip-id="tooltip"
														data-tooltip-content="Total unpaid commissions"
													>
														Unpaid
													</span>
												</div>
												<div className={table.th}>
													<span
														data-tooltip-id="tooltip"
														data-tooltip-content="Total paid commissions"
													>
														Paid
													</span>
												</div>
												<div className={table.th}>
													<span
														data-tooltip-id="tooltip"
														data-tooltip-content="Total commissions"
													>
														Total
													</span>
												</div>
											</div>
											<div className={table.tr}>
												<div className={table.td}>
													$
													{(
														referral.revenue -
														referral.paidCommision
													).toFixed(2)}
												</div>
												<div className={table.td}>
													${referral.paidCommision.toFixed(2)}
												</div>
												<div className={table.td}>
													<b>${referral.revenue.toFixed(2)}</b>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				))}

				{/*	<div className={contentCard.header}>
					<h4 className={contentCard.title}>Referrals</h4>
				</div>

				<div className={table.table}>
					<div className={table.thead}>
						<div className={table.tr}>
							<div className={table.th}>Vistors</div>
							<div className={table.th}>Leads</div>
							<div className={table.th}>Conversions</div>
						</div>
						<div className={table.tr}>
							<div className={table.td}>0</div>
							<div className={table.td}>0</div>
							<div className={table.td}>0</div>
						</div>
					</div>
				</div>

				<div className={contentCard.header}>
					<h4 className={contentCard.title}>Commissions</h4>
				</div>

				<div className={table.table}>
					<div className={table.thead}>
						<div className={table.tr}>
							<div className={table.th}>Unpaid Commision</div>
							<div className={table.th}>Paid Commision</div>
							<div className={table.th}>Total Commision</div>
						</div>
						<div className={table.tr}>
							<div className={table.td}>
								$
								{(
									totalDetails.revenue - totalDetails.paidCommision
								).toFixed(2)}
							</div>
							<div className={table.td}>
								${totalDetails.paidCommision.toFixed(2)}
							</div>
							<div className={table.td}>
								${totalDetails.revenue.toFixed(2)}
							</div>
						</div>
					</div>
				</div> */}
			</Fragment>
		)
	}
}

export default Referrals
