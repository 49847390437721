/**
 * @generated SignedSource<<b10665af8773562fe289c1ff51892fc0>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Mutation } from 'relay-runtime';
export type PayoutReferralInput = {|
  clientMutationId?: ?string,
|};
export type payoutUsersMutation$variables = {|
  input: PayoutReferralInput,
|};
export type payoutUsersMutation$data = {|
  +payoutReferral: ?{|
    +store: ?{|
      +id: string,
    |},
  |},
|};
export type payoutUsersMutation = {|
  response: payoutUsersMutation$data,
  variables: payoutUsersMutation$variables,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "PayoutReferralPayload",
    "kind": "LinkedField",
    "name": "payoutReferral",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Store",
        "kind": "LinkedField",
        "name": "store",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "payoutUsersMutation",
    "selections": (v1/*: any*/),
    "type": "ShopshareMutationRoot",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "payoutUsersMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "ce811fc4b8dffe65a6f0593f8bf853d7",
    "id": null,
    "metadata": {},
    "name": "payoutUsersMutation",
    "operationKind": "mutation",
    "text": "mutation payoutUsersMutation(\n  $input: PayoutReferralInput!\n) {\n  payoutReferral(input: $input) {\n    store {\n      id\n    }\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "7c27e43563393a3549948f360928937a";

module.exports = ((node/*: any*/)/*: Mutation<
  payoutUsersMutation$variables,
  payoutUsersMutation$data,
>*/);
