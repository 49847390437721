import React from "react"
import { Transition } from "react-transition-group"
import { createFragmentContainer, graphql } from "react-relay"
import { publicViewDomain, baseFrontendUrl } from "constants"
import ToggleRecommendedLookbookMutation from "mutations/lookbook/toggle-recommended-lookbook.js"
import { addVersioning } from "helpers/query-parameters-helper"
import { ShowCardControlsComponent } from "helpers/ui-helper"
import card from "shared/styles/components/card.css"
import button from "shared/styles/components/buttons.css"
import Avatar from "components/profile/avatar/avatar"
import DropdownMenu from "components/dropdown-menu/dropdown-menu"
import PinButton from "components/buttons/pin-button"
import BadgePageCount from "components/badge-page-count/badge-page-count"
import BadgeVideo from "components/badge-video/badge-video"
// import Views from "components/views/views"
import styles from "./lookbook-card.css"

export class LookbookCard extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			isDropdownOpen: false,
		}
		this.showReportModal = this.showReportModal.bind(this)
	}

	showReportModal(...args) {
		const { props } = this
		const { showReportModal } = props
		showReportModal(...args)
	}

	toggleRecommended() {
		const { relay, lookbook, refetch } = this.props
		ToggleRecommendedLookbookMutation.commit(
			relay.environment,
			lookbook.id,
			() => {
				if (refetch) {
					refetch(12)
				}
			}
		)
	}

	renderPlay(lookbook) {
		const { disableSubdomain, clientId } = this.props
		const domain = disableSubdomain
			? baseFrontendUrl.replace("https://", "")
			: lookbook.user.customDomain ||
			  `${lookbook.user.username}.${publicViewDomain}`
		const videoExists =
			lookbook.lookbookBoards.filter((i) => !!i.videoUrl).length > 0
		const qp = clientId ? `?clientId=${clientId}` : ""
		return (
			<div className={styles.figure}>
				{videoExists && <BadgeVideo />}
				<a
					className={styles.inner}
					href={`https://${domain}/shopboard/${lookbook.id}${qp}`}
				>
					<img
						className={styles.poster}
						loading="lazy"
						src={addVersioning(lookbook.url, `d=400`)}
						alt="Poster"
					/>
				</a>
			</div>
		)
	}

	renderMenu() {
		const listItems = []
		const { lookbook, currentUser } = this.props
		const push = (click, name) => listItems.push({ click, name })
		if (currentUser && currentUser.adminAccess === "HAS") {
			if (lookbook.isRecommended) {
				push(() => {
					this.toggleRecommended()
				}, "Remove from Featured")
			} else {
				push(() => {
					this.toggleRecommended()
				}, "Add to Featured")
			}
		}
		if (!currentUser || currentUser.id !== lookbook.user.id) {
			push(() => {
				this.showReportModal(true, { ...lookbook, type: "lookbook" })
			}, "Report Shopboard")
		}
		return listItems.length ? (
			<DropdownMenu
				items={listItems}
				darkTheme
				contentRight
				triggerClass={button.lightIcon}
				triggerClassActive={button.lightIconActive}
				setDropdownOpened={(val) => {
					this.setState({ isDropdownOpen: val })
				}}
			/>
		) : null
	}

	render() {
		const {
			lookbook,
			enableHeader,
			// currentUser,
			disableSubdomain,
			noMenu
		} = this.props
		const { isDropdownOpen } = this.state
		// const isAdmin = currentUser && currentUser.adminAccess === "HAS"
		const domain = disableSubdomain
			? baseFrontendUrl.replace("https://", "")
			: lookbook.user.customDomain ||
			  `${lookbook.user.username}.${publicViewDomain}`
		return (
			<ShowCardControlsComponent className={styles.root}>
				{(isShown) => (
					<>
						{enableHeader && (
							<div className={card.header}>
								<a
									className={card.userLink}
									href={`https://${domain}`}
									target="_blank"
									rel="noreferrer"
								>
									<Avatar
										size="32px"
										photoUrl={lookbook.user.profilePhoto}
										fullname={lookbook.user.fullname}
										imageSize="100"
									/>
									<div className={card.user}>
										<span className={card.fullname}>
											{lookbook.user.fullname}
										</span>
									</div>
								</a>
							</div>
						)}

						<div className={styles.media}>
							<div className={styles.pin}>
								<PinButton isPinned={lookbook.isPinned} />
							</div>
							{lookbook.shownBoardCount > 1 && (
								<BadgePageCount
									pageCount={`1 of ${lookbook.shownBoardCount}`}
								/>
							)}
							{!noMenu && (
								<Transition
									in={isShown || isDropdownOpen}
									timeout={200}
								>
									{(state) => (
										<div
											className={`${styles.menu} ${styles[state]}`}
										>
											{this.renderMenu()}
										</div>
									)}
								</Transition>
							)}
							{/* <Transition
								in={isShown || (isDropdownOpen && !isAdmin)}
								timeout={200}
							>
								{(state) => (
									<div className={`${styles.views} ${styles[state]}`}>
										<Views
											viewsCount={lookbook.views || 0}
											size="sm"
										/>
									</div>
								)}
							</Transition> */}
							{this.renderPlay(lookbook)}
						</div>
						<div className={styles.content}>
							<h4 className={styles.title}>{lookbook.title}</h4>
						</div>
					</>
				)}
			</ShowCardControlsComponent>
		)
	}
}

LookbookCard = createFragmentContainer(LookbookCard, {
	currentUser: graphql`
		fragment lookbookCard_currentUser on User {
			id
			adminAccess
		}
	`,
	lookbook: graphql`
		fragment lookbookCard_lookbook on Lookbook {
			id
			title
			views
			url
			user {
				id
				fullname
				username
				profilePhoto
				canEdit
				customDomain
			}
			lookbookBoards {
				id
				url
				videoUrl
			}
			isPublished
			isPinned
			isRecommended
			published_at
			shownBoardCount
		}
	`,
})

export default LookbookCard
