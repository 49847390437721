/**
 * @generated SignedSource<<fef02f5a4a4a6d2991818914a874a53f>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Mutation } from 'relay-runtime';
export type DeleteLookbookUploadInput = {|
  category?: ?string,
  clientMutationId?: ?string,
  collectionId?: ?string,
  uploadId: string,
|};
export type deleteLookbookUploadMutation$variables = {|
  input: DeleteLookbookUploadInput,
|};
export type deleteLookbookUploadMutation$data = {|
  +deleteLookbookUpload: ?{|
    +lookbookUploads: ?{|
      +id: string,
      +title: ?string,
    |},
  |},
|};
export type deleteLookbookUploadMutation = {|
  response: deleteLookbookUploadMutation$data,
  variables: deleteLookbookUploadMutation$variables,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "DeleteLookbookUploadPayload",
    "kind": "LinkedField",
    "name": "deleteLookbookUpload",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "lookbookUploads",
        "kind": "LinkedField",
        "name": "lookbookUploads",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "title",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "deleteLookbookUploadMutation",
    "selections": (v1/*: any*/),
    "type": "ShopshareMutationRoot",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "deleteLookbookUploadMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "8ef37c7348c645d33661a2d202c4e828",
    "id": null,
    "metadata": {},
    "name": "deleteLookbookUploadMutation",
    "operationKind": "mutation",
    "text": "mutation deleteLookbookUploadMutation(\n  $input: DeleteLookbookUploadInput!\n) {\n  deleteLookbookUpload(input: $input) {\n    lookbookUploads {\n      id\n      title\n    }\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "9b6d8ef31bbf84ed2491c7baee4388c8";

module.exports = ((node/*: any*/)/*: Mutation<
  deleteLookbookUploadMutation$variables,
  deleteLookbookUploadMutation$data,
>*/);
