import React, { Component, Fragment } from "react"
import { createFragmentContainer, graphql } from "react-relay"
import { toast } from "react-toastify"
import gqlHelper from "helpers/gql-helper"
import form from "shared/styles/components/form.css"
import toggle from "shared/styles/components/toogle.css"
import SetTeamPreviewConfig from "mutations/team/set-team-preview-config"
import UploadTeamAvatarMutation from "mutations/team/upload-team-avatar"
import UploadTeamBannerMutation from "mutations/team/upload-team-banner"
import UpdateTeamMutation from "mutations/team/update-team"
import { debounce } from "helpers/ui-helper"
import EditPreviewSharedControls from "./edit-preview-shared-controls"
import styles from "./edit-preview.css"

class EditPreviewTeam extends Component {
	constructor(props) {
		super(props)
		this.state = {}
		this.updateShowControls = this.updateShowControls.bind(this)
		this.uploadTeamAvatar = this.uploadTeamAvatar.bind(this)
		this.uploadTeamBanner = this.uploadTeamBanner.bind(this)
		this.saveTeamName = this.saveTeamName.bind(this)

		this.commitUpdatesDebounce = debounce(this.saveTeamName, 1000)
	}

	uploadTeamAvatar(acceptedFiles) {
		let file = null
		if (acceptedFiles) [file] = acceptedFiles
		const { relay, reloadIframe, team } = this.props
		UploadTeamAvatarMutation.commit(
			relay.environment,
			file,
			team.id,
			() => {
				toast.success(<>Avatar updated</>, {
					autoClose: 2000,
					closeButton: false,
				})
				if (reloadIframe) reloadIframe()
			},
			() => {}
		)
	}

	uploadTeamBanner(acceptedFiles) {
		let file = null
		if (acceptedFiles) [file] = acceptedFiles
		const { relay, reloadIframe, team } = this.props
		UploadTeamBannerMutation.commit(
			relay.environment,
			file,
			team.id,
			() => {
				toast.success(<>Banner updated</>, {
					autoClose: 2000,
					closeButton: false,
				})
				if (reloadIframe) reloadIframe()
			},
			() => {}
		)
	}

	updateShowControls(id, value) {
		const { relay, reloadIframe, team } = this.props
		SetTeamPreviewConfig.commit(
			relay.environment,
			team.id,
			[{ id, value }],
			() => {
				toast.success(<>Updated successfully.</>, {
					autoClose: 2000,
					closeButton: false,
				})
				if (reloadIframe) reloadIframe()
			},
			(e) => {
				toast.error(
					gqlHelper.getError(e) || "Error updating controls.",
					{
						autoClose: 2000,
						closeButton: false,
					}
				)
			}
		)
	}

	saveTeamName(name) {
		const { relay, reloadIframe, team } = this.props
		UpdateTeamMutation.commit(
			relay.environment,
			{
				avatar: team.avatar,
				displayName: name,
				name: team.name,
				teamId: team.id,
			},
			(e) => {
				toast.info(<>{gqlHelper.getError(e)}</>, {
					autoClose: 5000,
					closeButton: false,
				})
			},
			() => {
				toast.success(<>Team updated successfully</>, {
					autoClose: 2000,
					closeButton: false,
				})
				if (reloadIframe) reloadIframe()
			}
		)
	}

	renderTabToggle(key, title) {
		const { team } = this.props
		const { previewConfig } = team
		return (
			<div className={form.section}>
				<div className={form.header}>
					<label className={toggle.checkSpaceBetween}>
						<div className={toggle.labelSm}>{title}</div>
						<div className={toggle.switchSm}>
							<input
								type="checkbox"
								checked={previewConfig[key]}
								onChange={(e) =>
									this.updateShowControls(key, e.target.checked)
								}
							/>
							<div className={toggle.sliderSm} />
						</div>
					</label>
				</div>
			</div>
		)
	}

	render() {
		const { team } = this.props
		const { previewConfig } = team
		return (
			<>
				<EditPreviewSharedControls
					data={{
						avatar: team.displayAvatar,
						bannerPhoto: team.bannerPhoto,
						displayName: team.displayName,
						showAvatar: previewConfig.showAvatar,
						showBanner: previewConfig.showBanner,
						showDisplayName: previewConfig.showDisplayName,
					}}
					uploadAvatar={this.uploadTeamAvatar}
					uploadBanner={this.uploadTeamBanner}
					updateShowControls={this.updateShowControls}
					saveDisplayName={this.commitUpdatesDebounce}
					enableBannerAvatarSelect
				/>
				<div className={styles.formRoot}>
					{team.shopcasts.edges.length > 0 &&
						this.renderTabToggle(
							"showShopcastsSection",
							"Show Shopcasts Section"
						)}
					{team.lookbooks.edges.length > 0 &&
						this.renderTabToggle(
							"showShopboardsSection",
							"Show Shopboards Section"
						)}
					{team.files.edges.length > 0 &&
						this.renderTabToggle(
							"showFilesSection",
							"Show Files Section"
						)}
					{team.collections.edges.length > 0 &&
						this.renderTabToggle(
							"showCollectionsSection",
							"Show Collections Section"
						)}
				</div>
			</>
		)
	}
}

export default createFragmentContainer(EditPreviewTeam, {
	team: graphql`
		fragment editPreviewTeam_team on team {
			id
			name
			displayName
			avatar
			displayAvatar
			bannerPhoto
			previewConfig {
				showBanner
				showAvatar
				showDisplayName
				showShopcastsSection
				showShopboardsSection
				showFilesSection
				showCollectionsSection
			}
			shopcasts(first: 1) {
				edges {
					node {
						id
					}
				}
			}
			lookbooks(first: 1) {
				edges {
					node {
						id
					}
				}
			}
			collections(first: 1) {
				edges {
					node {
						id
					}
				}
			}
			files(first: 1) {
				edges {
					node {
						id
					}
				}
			}
		}
	`,
})
