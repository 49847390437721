/**
 * @generated SignedSource<<c61512d368b5ab709d06c0f0642e70cf>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Mutation } from 'relay-runtime';
export type UpdateProductCollectionInput = {|
  clientId?: ?string,
  clientMutationId?: ?string,
  folder?: ?string,
  id: string,
  isACloset?: ?boolean,
  isPublished?: ?boolean,
  title: string,
|};
export type updateProductCollectionMutation$variables = {|
  input: UpdateProductCollectionInput,
|};
export type updateProductCollectionMutation$data = {|
  +updateProductCollection: ?{|
    +folder: ?{|
      +id: string,
      +name: ?string,
    |},
    +published_at: ?boolean,
    +store: ?{|
      +id: string,
    |},
  |},
|};
export type updateProductCollectionMutation = {|
  response: updateProductCollectionMutation$data,
  variables: updateProductCollectionMutation$variables,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UpdateProductCollectionPayload",
    "kind": "LinkedField",
    "name": "updateProductCollection",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Store",
        "kind": "LinkedField",
        "name": "store",
        "plural": false,
        "selections": [
          (v1/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "ShopcastFolder",
        "kind": "LinkedField",
        "name": "folder",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "published_at",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "updateProductCollectionMutation",
    "selections": (v2/*: any*/),
    "type": "ShopshareMutationRoot",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "updateProductCollectionMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "83a536a2a60a325cabcfbbb5740f552a",
    "id": null,
    "metadata": {},
    "name": "updateProductCollectionMutation",
    "operationKind": "mutation",
    "text": "mutation updateProductCollectionMutation(\n  $input: UpdateProductCollectionInput!\n) {\n  updateProductCollection(input: $input) {\n    store {\n      id\n    }\n    folder {\n      id\n      name\n    }\n    published_at\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "ef178db93727ccb57133d852115e62a5";

module.exports = ((node/*: any*/)/*: Mutation<
  updateProductCollectionMutation$variables,
  updateProductCollectionMutation$data,
>*/);
