/**
 * @generated SignedSource<<9e5cd689b8b171ae63d29a236ee792c6>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Query } from 'relay-runtime';
import type { imageListCollectionSelect_store$fragmentType } from "./imageListCollectionSelect_store.graphql";
export type imageListCollectionSelectQuery$variables = {|
  clients?: ?$ReadOnlyArray<?string>,
  limitType?: ?$ReadOnlyArray<?string>,
  search?: ?string,
  teamId?: ?string,
|};
export type imageListCollectionSelectQuery$data = {|
  +store: ?{|
    +$fragmentSpreads: imageListCollectionSelect_store$fragmentType,
  |},
|};
export type imageListCollectionSelectQuery = {|
  response: imageListCollectionSelectQuery$data,
  variables: imageListCollectionSelectQuery$variables,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "clients"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "limitType"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "search"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "teamId"
},
v4 = {
  "kind": "Variable",
  "name": "limitType",
  "variableName": "limitType"
},
v5 = {
  "kind": "Variable",
  "name": "search",
  "variableName": "search"
},
v6 = {
  "kind": "Variable",
  "name": "teamId",
  "variableName": "teamId"
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v8 = [
  (v7/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "title",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "isDefault",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "ShopcastFolder",
    "kind": "LinkedField",
    "name": "folder",
    "plural": false,
    "selections": [
      (v7/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "User",
    "kind": "LinkedField",
    "name": "user",
    "plural": false,
    "selections": [
      (v7/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "fullname",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "imageListCollectionSelectQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Store",
        "kind": "LinkedField",
        "name": "store",
        "plural": false,
        "selections": [
          {
            "args": [
              {
                "kind": "Variable",
                "name": "clients",
                "variableName": "clients"
              },
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/)
            ],
            "kind": "FragmentSpread",
            "name": "imageListCollectionSelect_store"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "ShopshareQueryRoot",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Operation",
    "name": "imageListCollectionSelectQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Store",
        "kind": "LinkedField",
        "name": "store",
        "plural": false,
        "selections": [
          (v7/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "currentUser",
            "plural": false,
            "selections": [
              (v7/*: any*/),
              {
                "alias": null,
                "args": [
                  {
                    "kind": "Literal",
                    "name": "brands",
                    "value": []
                  },
                  {
                    "kind": "Literal",
                    "name": "categories",
                    "value": []
                  },
                  {
                    "kind": "Variable",
                    "name": "clientIds",
                    "variableName": "clients"
                  },
                  {
                    "kind": "Literal",
                    "name": "collections",
                    "value": []
                  },
                  (v4/*: any*/),
                  {
                    "kind": "Literal",
                    "name": "resultTypes",
                    "value": [
                      "collections"
                    ]
                  },
                  (v5/*: any*/),
                  {
                    "kind": "Literal",
                    "name": "sort",
                    "value": "n-to-o"
                  },
                  (v6/*: any*/)
                ],
                "concreteType": "ProductCollectionProductSearch",
                "kind": "LinkedField",
                "name": "productCollectionSearchItems",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": [
                      {
                        "kind": "Literal",
                        "name": "search",
                        "value": ""
                      }
                    ],
                    "concreteType": "ProductCollectionConnection",
                    "kind": "LinkedField",
                    "name": "collections",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ProductCollectionEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "ProductCollection",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": (v8/*: any*/),
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": "collections(search:\"\")"
                  },
                  {
                    "alias": null,
                    "args": [
                      (v6/*: any*/)
                    ],
                    "concreteType": "ProductCollection",
                    "kind": "LinkedField",
                    "name": "defaultCollection",
                    "plural": false,
                    "selections": (v8/*: any*/),
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "2cdd8b80d881aa988e11d6e60e859a2f",
    "id": null,
    "metadata": {},
    "name": "imageListCollectionSelectQuery",
    "operationKind": "query",
    "text": "query imageListCollectionSelectQuery(\n  $search: String\n  $clients: [String]\n  $limitType: [String]\n  $teamId: String\n) {\n  store {\n    ...imageListCollectionSelect_store_2dgaze\n    id\n  }\n}\n\nfragment imageListCollectionSelect_store_2dgaze on Store {\n  id\n  currentUser {\n    id\n    productCollectionSearchItems(search: $search, categories: [], brands: [], collections: [], sort: \"n-to-o\", clientIds: $clients, limitType: $limitType, resultTypes: [\"collections\"], teamId: $teamId) {\n      collections(search: \"\") {\n        edges {\n          node {\n            id\n            title\n            isDefault\n            folder {\n              id\n              name\n            }\n            user {\n              id\n              fullname\n            }\n          }\n        }\n      }\n      defaultCollection(teamId: $teamId) {\n        id\n        title\n        isDefault\n        folder {\n          id\n          name\n        }\n        user {\n          id\n          fullname\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "34bcacda027f2c52d8586b37f97a89be";

module.exports = ((node/*: any*/)/*: Query<
  imageListCollectionSelectQuery$variables,
  imageListCollectionSelectQuery$data,
>*/);
