import React from "react"
// import { PlayIcon, PauseIcon } from "@radix-ui/react-icons"
import PlayIcon from "shared/assets/play-icon.svg"
import PauseIcon from "shared/assets/pause-icon.svg"
import styles from "./play-button-large.css"

export default function PlayLarge({ isPlaying, handlePlayPause }) {
	return isPlaying ? (
		<div className={styles.root} onClick={handlePlayPause} aria-hidden="true">
			<div className={styles.play}>
				<PauseIcon className={styles.pauseIcon} />
			</div>
		</div>
	) : (
		<div className={styles.root} onClick={handlePlayPause} aria-hidden="true">
			<div className={styles.pause}>
				<PlayIcon className={styles.playIcon} />
			</div>
		</div>
	)
}
