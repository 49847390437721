import { commitMutation, graphql } from "react-relay"
import { complete } from "../helper"

const mutation = graphql`
	mutation createTeamMutation($input: CreateNewTeamInput!) {
		createNewTeam(input: $input) {
			team {
				id
				name
				avatar
				myRole
				members {
					edges {
						node {
							id
						}
					}
				}
			}
		}
	}
`

const commit = (env, { file, ...data }, onError, onCompleted) =>
	commitMutation(env, {
		mutation,
		variables: { input: data },
		uploadables: { file },
		onCompleted: complete(onCompleted, onError),
		onError: (e) => complete(null, [e]),
		updater: (storeProxy) => {
			const currentUser = storeProxy
				.getRoot()
				.getLinkedRecord("store")
				.getLinkedRecord("currentUser")
			const payload = storeProxy.getRootField("createNewTeam")
			const newTeam = payload.getLinkedRecord("team")
			const teams = currentUser.getLinkedRecords("teams")
			currentUser.setLinkedRecords([...teams, newTeam], "teams")
		},
	})

export default { commit }
