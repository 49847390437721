import React, { Component } from "react"
import { Transition } from "react-transition-group"
import { ShowCardControlsComponent } from "helpers/ui-helper"
import stylesAvatar from "components/profile/avatar/avatar.css"
import Avatar from "components/profile/avatar/avatar"
import DropdownMenu from "components/dropdown-menu/dropdown-menu"
import { addVersioning } from "helpers/query-parameters-helper"
import button from "shared/styles/components/buttons.css"
import ToggleRecommendedSharerMutation from "mutations/user/toggle-recommended-sharer.js"
import { publicViewDomain } from "constants"
import styles from "./profile-card.css"

class ProfileCard extends Component {
	constructor(props) {
		super(props)

		this.state = {
			isDropdownOpen: false,
		}
	}

	toggleRecommended() {
		const { relay, profile, refetch } = this.props
		ToggleRecommendedSharerMutation.commit(
			relay.environment,
			profile.id,
			() => {
				if (refetch) {
					refetch(12)
				}
			}
		)
	}

	renderMenu() {
		const listItems = []
		const { profile, currentUser } = this.props
		const push = (click, name) => listItems.push({ click, name })
		if (currentUser && currentUser.adminAccess === "HAS") {
			if (profile.isRecommended) {
				push(() => {
					this.toggleRecommended()
				}, "Remove from Featured")
			} else {
				push(() => {
					this.toggleRecommended()
				}, "Add to Featured")
			}
		}
		return listItems.length ? (
			<DropdownMenu
				items={listItems}
				darkTheme
				contentRight
				triggerClass={button.lightIcon}
				triggerClassActive={button.lightIconActive}
				setDropdownOpened={(val) => {
					this.setState({ isDropdownOpen: val })
				}}
			/>
		) : null
	}

	render() {
		const { profile, currentUser } = this.props
		const { isDropdownOpen } = this.state
		const isAdmin = currentUser && currentUser.adminAccess === "HAS"
		if (profile) {
			let inlineStyles = {
				// backgroundImage: `url(${PlaceholderMasthead})`
			}
			if (profile.bannerPhoto) {
				inlineStyles = {
					backgroundImage: `url(${addVersioning(
						profile.bannerPhoto,
						`d=1000`
					)})`,
				}
			}
			const domain =
				profile.customDomain || `${profile.username}.${publicViewDomain}`
			return (
				<ShowCardControlsComponent className={styles.root}>
					{(isShown) => (
						<>
							<div className={styles.masthead} style={inlineStyles} />
							<Transition
								in={(isShown || isDropdownOpen) && isAdmin}
								timeout={200}
							>
								{(state) => (
									<div className={`${styles.menu} ${styles[state]}`}>
										{this.renderMenu()}
									</div>
								)}
							</Transition>
							<div className={styles.content}>
								<div className={styles.avatar}>
									<Avatar
										styleName={stylesAvatar.border}
										photoUrl={profile.profilePhoto}
										fullname={profile.fullname}
										imageSize="100"
									/>
								</div>
								<h3 className={styles.user}>
									<a
										className={styles.link}
										href={`https://${domain}`}
									>
										{profile.displayName || profile.fullname}
									</a>
								</h3>
								<p className={styles.username}>@{profile.username}</p>
							</div>
						</>
					)}
				</ShowCardControlsComponent>
			)
		}
		return false
	}
}

export default ProfileCard
