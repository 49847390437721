import React from "react"
import { PlusIcon } from "@radix-ui/react-icons"
import styles from "./plus-button.css"

function PlusButton({ onClick }) {
  return <button className={styles.root} type="button" onClick={onClick}>
		<PlusIcon className={styles.icon} />
	</button>
}

export default PlusButton
