/**
 * @generated SignedSource<<cbf1fbf0e62cfddc650dd123d2440731>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Mutation } from 'relay-runtime';
export type ToggleRecommendedSharerInput = {|
  clientMutationId?: ?string,
  userId: string,
|};
export type toggleRecommendedSharerMutation$variables = {|
  input: ToggleRecommendedSharerInput,
|};
export type toggleRecommendedSharerMutation$data = {|
  +toggleRecommendedSharerMutation: ?{|
    +output: ?string,
  |},
|};
export type toggleRecommendedSharerMutation = {|
  response: toggleRecommendedSharerMutation$data,
  variables: toggleRecommendedSharerMutation$variables,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "ToggleRecommendedSharerPayload",
    "kind": "LinkedField",
    "name": "toggleRecommendedSharerMutation",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "output",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "toggleRecommendedSharerMutation",
    "selections": (v1/*: any*/),
    "type": "ShopshareMutationRoot",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "toggleRecommendedSharerMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "f20fbed100e8f43bf88c188d0fe6f475",
    "id": null,
    "metadata": {},
    "name": "toggleRecommendedSharerMutation",
    "operationKind": "mutation",
    "text": "mutation toggleRecommendedSharerMutation(\n  $input: ToggleRecommendedSharerInput!\n) {\n  toggleRecommendedSharerMutation(input: $input) {\n    output\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "bb5ae8c91dc37b922adfd7d96d6f2821";

module.exports = ((node/*: any*/)/*: Mutation<
  toggleRecommendedSharerMutation$variables,
  toggleRecommendedSharerMutation$data,
>*/);
