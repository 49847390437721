/**
 * @generated SignedSource<<992d14cc07716e38c4d882b9ea235464>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentType } from "relay-runtime";
declare export opaque type shopboard_store$fragmentType: FragmentType;
export type shopboard_store$data = {|
  +currentUser: ?{|
    +adminAccess: ?string,
    +id: string,
    +role: ?string,
    +userClients: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +email: ?string,
          +fullname: ?string,
          +id: string,
        |},
      |}>,
    |},
  |},
  +id: string,
  +lookbook: ?{|
    +created_at: ?string,
    +description: ?string,
    +id: string,
    +isFlagged: ?boolean,
    +isPublished: ?boolean,
    +lookbookBoards: ?$ReadOnlyArray<?{|
      +background: ?string,
      +dataJson: ?string,
      +height: ?number,
      +id: ?string,
      +lookbookId: string,
      +mp4Url: ?string,
      +pageNo: ?number,
      +poster: ?string,
      +screenWidth: ?string,
      +shapes: ?{|
        +edges: ?$ReadOnlyArray<?{|
          +node: ?{|
            +collection: ?string,
            +id: string,
            +path: ?string,
            +viewBox: ?string,
          |},
        |}>,
      |},
      +showBoard: ?boolean,
      +showVideo: ?boolean,
      +subtitle: ?string,
      +title: ?string,
      +url: ?string,
      +videoUrl: ?string,
      +width: ?number,
    |}>,
    +products: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +affiliated_url: ?string,
          +brand: ?string,
          +custom_affiliated_url: ?string,
          +description: ?string,
          +diffbotImageUrl: ?string,
          +error: ?string,
          +hasProductImagesFromDiffbot: ?boolean,
          +id: string,
          +imageUrl: ?string,
          +isOwnedItems: ?boolean,
          +offerPrice: ?string,
          +productCollections: ?$ReadOnlyArray<?{|
            +id: string,
            +isACloset: ?boolean,
            +isPublished: ?boolean,
            +title: ?string,
          |}>,
          +productImages: ?$ReadOnlyArray<?string>,
          +regularPrice: ?string,
          +title: ?string,
          +url: ?string,
          +user: ?{|
            +id: string,
          |},
        |},
      |}>,
    |},
    +published_at: ?string,
    +team: ?{|
      +avatar: ?string,
      +displayAvatar: ?string,
      +displayName: ?string,
      +id: string,
      +name: ?string,
    |},
    +title: ?string,
    +transactions: ?$ReadOnlyArray<?{|
      +advertiserName: ?string,
      +currency: ?string,
      +id: string,
      +network: ?string,
      +orderId: ?string,
      +product: ?{|
        +id: string,
        +title: ?string,
      |},
      +productNames: ?$ReadOnlyArray<?string>,
      +sales: ?number,
      +totalCommission: ?number,
      +transactionDate: ?string,
    |}>,
    +url: ?string,
    +user: ?{|
      +canEdit: ?boolean,
      +customDomain: ?string,
      +fullname: ?string,
      +id: string,
      +productCollections: ?{|
        +edges: ?$ReadOnlyArray<?{|
          +node: ?{|
            +client: ?{|
              +fullname: ?string,
              +id: string,
            |},
            +id: string,
            +isACloset: ?boolean,
            +isDefault: ?boolean,
            +isPublished: ?boolean,
            +title: ?string,
            +user: ?{|
              +fullname: ?string,
              +id: string,
            |},
          |},
        |}>,
      |},
      +profilePhoto: ?string,
      +socialLinks: ?{|
        +twitterUsername: ?string,
      |},
      +username: ?string,
    |},
  |},
  +$fragmentType: shopboard_store$fragmentType,
|};
export type shopboard_store$key = {
  +$data?: shopboard_store$data,
  +$fragmentSpreads: shopboard_store$fragmentType,
  ...
};
*/

var node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  {
    "kind": "Variable",
    "name": "teamId",
    "variableName": "teamId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "fullname",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "url",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isPublished",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isACloset",
  "storageKey": null
},
v8 = [
  (v0/*: any*/),
  (v2/*: any*/)
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "clientId"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "id"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "teamId"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "shopboard_store",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "currentUser",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "adminAccess",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "role",
          "storageKey": null
        },
        {
          "alias": null,
          "args": (v1/*: any*/),
          "concreteType": "ClientConnectionConnection",
          "kind": "LinkedField",
          "name": "userClients",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ClientConnectionEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Client",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    (v2/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "email",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "id",
          "variableName": "id"
        }
      ],
      "concreteType": "Lookbook",
      "kind": "LinkedField",
      "name": "lookbook",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v3/*: any*/),
        (v4/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "published_at",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "created_at",
          "storageKey": null
        },
        (v5/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isFlagged",
          "storageKey": null
        },
        (v6/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "team",
          "kind": "LinkedField",
          "name": "team",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "name",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "displayName",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "avatar",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "displayAvatar",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "LookbookBoard",
          "kind": "LinkedField",
          "name": "lookbookBoards",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "lookbookId",
              "storageKey": null
            },
            (v3/*: any*/),
            (v5/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "dataJson",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "screenWidth",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "width",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "height",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "background",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "pageNo",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "showBoard",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "videoUrl",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "mp4Url",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "poster",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "subtitle",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "showVideo",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "ShapeConnectionConnection",
              "kind": "LinkedField",
              "name": "shapes",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "ShapeConnectionEdge",
                  "kind": "LinkedField",
                  "name": "edges",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "Shape",
                      "kind": "LinkedField",
                      "name": "node",
                      "plural": false,
                      "selections": [
                        (v0/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "viewBox",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "path",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "collection",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "SiteTransactionObj",
          "kind": "LinkedField",
          "name": "transactions",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "network",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "sales",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "totalCommission",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "currency",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "ShopcastProduct",
              "kind": "LinkedField",
              "name": "product",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                (v3/*: any*/)
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "productNames",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "transactionDate",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "advertiserName",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "orderId",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "ProductConnection",
          "kind": "LinkedField",
          "name": "products",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ProductEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "ShopcastProduct",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    (v3/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "brand",
                      "storageKey": null
                    },
                    (v4/*: any*/),
                    (v5/*: any*/),
                    {
                      "alias": null,
                      "args": [
                        {
                          "kind": "Literal",
                          "name": "type",
                          "value": "lookbook"
                        }
                      ],
                      "kind": "ScalarField",
                      "name": "affiliated_url",
                      "storageKey": "affiliated_url(type:\"lookbook\")"
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "custom_affiliated_url",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "imageUrl",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "diffbotImageUrl",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "regularPrice",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "offerPrice",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "error",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "productImages",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "hasProductImagesFromDiffbot",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "ProductCollection",
                      "kind": "LinkedField",
                      "name": "productCollections",
                      "plural": true,
                      "selections": [
                        (v0/*: any*/),
                        {
                          "alias": null,
                          "args": [
                            {
                              "kind": "Literal",
                              "name": "ownershipCheck",
                              "value": true
                            }
                          ],
                          "kind": "ScalarField",
                          "name": "title",
                          "storageKey": "title(ownershipCheck:true)"
                        },
                        (v7/*: any*/),
                        (v6/*: any*/)
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "User",
                      "kind": "LinkedField",
                      "name": "user",
                      "plural": false,
                      "selections": [
                        (v0/*: any*/)
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": [
                        {
                          "kind": "Variable",
                          "name": "clientId",
                          "variableName": "clientId"
                        }
                      ],
                      "kind": "ScalarField",
                      "name": "isOwnedItems",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "User",
          "kind": "LinkedField",
          "name": "user",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "username",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "profilePhoto",
              "storageKey": null
            },
            (v2/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "canEdit",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "customDomain",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "UserSocialLinks",
              "kind": "LinkedField",
              "name": "socialLinks",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "twitterUsername",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": (v1/*: any*/),
              "concreteType": "ProductCollectionConnection",
              "kind": "LinkedField",
              "name": "productCollections",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "ProductCollectionEdge",
                  "kind": "LinkedField",
                  "name": "edges",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "ProductCollection",
                      "kind": "LinkedField",
                      "name": "node",
                      "plural": false,
                      "selections": [
                        (v0/*: any*/),
                        (v3/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "isDefault",
                          "storageKey": null
                        },
                        (v7/*: any*/),
                        (v6/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "Client",
                          "kind": "LinkedField",
                          "name": "client",
                          "plural": false,
                          "selections": (v8/*: any*/),
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "User",
                          "kind": "LinkedField",
                          "name": "user",
                          "plural": false,
                          "selections": (v8/*: any*/),
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Store",
  "abstractKey": null
};
})();

(node/*: any*/).hash = "ce883f3cb8c83ec86de338bb2e5d528f";

module.exports = ((node/*: any*/)/*: Fragment<
  shopboard_store$fragmentType,
  shopboard_store$data,
>*/);
