import React from "react"
import styles from "./list-of-faqs.css"

export default function ListOfFaqs({ question, answer, border }) {
	return (
		<details className={border ? styles.border : styles.root}>
			<summary className={styles.summary}>{question}</summary>
			{answer}
		</details>
	)
}
