import React, { Component } from "react"
import styles from "./progress-bar.css"

export default class ProgressBar extends Component {
	constructor(props) {
		super(props)
		this.state = {
			isOpen: false,
		}
	}

	componentDidMount() {
		const { isOpen } = this.props
		this.setState({
			isOpen: isOpen === undefined ? true : isOpen,
		})
	}

	componentWillUnmount() {
		this.setState({ isOpen: false })
	}

	render() {
		const { isOpen } = this.state
		const { text, progress, type, theme } = this.props
		return (
			isOpen &&
			progress && (
				<div
					className={`${
						type === "simple" ? styles.progressSimple : styles.progress
					} ${theme === "dark" ? styles.dark : styles.light}`}
				>
					<h4 className={styles.progressTitle}>{text}</h4>
					<p className={styles.progressStatus}>
						{parseFloat(progress).toFixed(0)}%
					</p>
					<progress
						className={styles.progressBar}
						max={100}
						value={progress}
					/>
				</div>
			)
		)
	}
}
