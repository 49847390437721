/**
 * @generated SignedSource<<54ca694dfd27a83e6c2a2e88a738feb6>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentType } from "relay-runtime";
declare export opaque type reporting_store$fragmentType: FragmentType;
export type reporting_store$data = {|
  +currentUser: ?{|
    +isRetailer: ?boolean,
  |},
  +id: string,
  +$fragmentType: reporting_store$fragmentType,
|};
export type reporting_store$key = {
  +$data?: reporting_store$data,
  +$fragmentSpreads: reporting_store$fragmentType,
  ...
};
*/

var node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "reporting_store",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "currentUser",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isRetailer",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Store",
  "abstractKey": null
};

(node/*: any*/).hash = "fb498f34e6a94ebaf8257a67136dbf14";

module.exports = ((node/*: any*/)/*: Fragment<
  reporting_store$fragmentType,
  reporting_store$data,
>*/);
