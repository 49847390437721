import React, {
	Fragment,
	useCallback,
	useContext,
	useEffect,
	useState,
} from "react"
import {
	Route,
	Navigate,
	RouterProvider as ReactRouterProvider,
	Outlet,
	createRoutesFromElements,
	createBrowserRouter,
} from "react-router-dom"
import { Elements } from "@stripe/react-stripe-js"
import { loadStripe } from "@stripe/stripe-js"
import { QueryRenderer } from "react-relay"
import { ToastContainer } from "react-toastify"
import { jwtDecode } from "jwt-decode"
import environment from "./createRelayEnvironment"
import Home from "./routes/home/home.jsx"
import Main from "./routes/main/main.jsx"
import Featured from "./routes/featured/featured.jsx"
import Products from "./routes/products/products.jsx"
import Signin from "./routes/signin/signin.jsx"
import Signup from "./routes/signup/signup.jsx"
import Search from "./routes/search/search.jsx"
import ForNetworkMarketers from "./routes/for-network-marketers/for-network-marketers.jsx"
import ForShopify from "./routes/for-shopify/for-shopify.jsx"
import ForContentMarketing from "./routes/for-content-marketing/for-content-marketing.jsx"
import ForVirtualStyling from "./routes/for-virtual-styling/for-virtual-styling.jsx"
import ShopRecorder from "./routes/shop-recorder/shop-recorder.jsx"
import Moodboards from "./routes/moodboards/moodboards.jsx"
import ShoppableVideos from "./routes/shoppable-videos/shoppable-videos.jsx"
import Faqs from "./routes/faqs/faqs.jsx"
import SharersPage from "./routes/sharers-page/sharers-page.jsx"
import About from "./routes/about/about.jsx"
import Features from "./routes/features/features.jsx"
import Pricing from "./routes/pricing/pricing.jsx"
// import InstallExtension from "./routes/install-extension/install-extension.jsx"
import Privacy from "./routes/privacy/privacy.jsx"
import ForgotPassword from "./routes/forgot-password/forgot-password.jsx"
import ResetPassword from "./routes/reset-password/reset-password.jsx"
import Terms from "./routes/terms/terms.jsx"
import Settings from "./routes/settings/settings.jsx"
import Shopcast from "./routes/shopcast/shopcast.jsx"
import AdminComponent from "./routes/admin/admin-component"
import AdminRootPage from "./routes/admin/admin-root-page.jsx"
import Retailers from "./routes/retailers/retailers.jsx"
import Reporting from "./routes/reporting/reporting"
import {
	justRender,
	RouterProvider,
	withOutletContext,
} from "./route-helpers.jsx"
import RakutenSites from "./routes/rakuten-sites/rakuten-sites"
import SharedFolder from "./routes/shared-folder/shared-folder"
import { stripePublicKey } from "./constants"
import ProfileView from "./routes/profile/profile-view.jsx"
import Lookbook from "./routes/lookbook/lookbook"
import ShopBoard from "./routes/shopboard/shopboard"
import Dashboard from "./routes/dashboard/dashboard"
import Verify from "./routes/verify/verify"
import ProductCollections from "./routes/product-collections/product-collections"
import { getUsername } from "./services/custom-domain-service"
import cookieService from "./services/cookie-service"
import Wardrobe from "./routes/wardrobe/wardrobe"
import Clients from "./routes/clients/clients"
import LookbookDownload from "./routes/lookbook-download/lookbook-download"
import ClientView from "./routes/client-view/client-view"
import ShopcastManagerRoot from "./components/profile/shopcast-manager/shopcast-manager-root"
import EditPreview from "./routes/edit-preview/edit-preview"
import PwaInstallPrompt from "./components/modals/pwa-install-prompt/pwa-install-prompt"
import { mobileCheck } from "./helpers/ui-helper"
import { GlobalContext, GlobalContextProvider } from "./global-context"
import TeamView from "./routes/team/team-view.jsx"
import TeamAccept from "./routes/team/team-accept.jsx"

import ProfileAccount from "./components/profile/account/account"
import SalesReport from "./components/reporting/sales-report/sales-report"

import AdminUserProfile from "./components/admin/admin-user-profile/admin-user-profile"
import AdminReferralViewCampaign from "./components/admin/admin-referral-view-campaign/admin-referral-view-campaign"
import AdminUserListView from "./components/admin/admin-user-list-view/admin-user-list-view"
import AdminDashboard from "./components/admin/dashboard/dashboard"
import AdminUserReports from "./components/admin/user-reports/admin-user-reports"
import AdminReferralCampaign from "./components/admin/admin-referral-campaign/admin-referral-campaign"
import AdminReferralPayment from "./components/admin/admin-referral-payment/admin-referral-payment"
import AdminCommisionReport from "./components/admin/admin-commision-report/admin-commision-report"
import AdminTransactionsReport from "./components/admin/admin-transactions-report/admin-transactions-report"
import AdminBusinessDashboard from "./components/admin/dashboard/business-dashboard"
import AdminProductDashboard from "./components/admin/dashboard/product-dashboard"
import ClientViewFolders from "./routes/client-view/client-view-folders.jsx"
import ClientViewFolder from "./routes/client-view/client-view-folder.jsx"
import clientViewCloset from "./routes/client-view/client-view-closet.jsx"
import clientViewClosetList from "./routes/client-view/client-view-closet-list.jsx"
import clientViewShopcasts from "./routes/client-view/client-view-shopcasts.jsx"
import clientViewLookbooks from "./routes/client-view/client-view-lookbooks.jsx"
import clientViewFiles from "./routes/client-view/client-view-files.jsx"
import ClientViewAll from "./routes/client-view/client-view-all.jsx"
import ClientViewCollectionView from "./routes/client-view/client-view-collection-view.jsx"

const stripePromise = loadStripe(stripePublicKey)

const username = getUsername(window.location.host)

const isValid =
	mobileCheck() && !window.matchMedia("(display-mode: standalone)").matches

const ClientViewRoutes = (
	<>
		<Route path="lookbooks" exact Component={justRender(ClientViewFolders)} />
		<Route path="lookbooks/:tab" Component={justRender(ClientViewFolder)}>
			<Route
				path="shopcasts"
				Component={withOutletContext(justRender(clientViewShopcasts))}
			/>
			<Route
				path="shopboards"
				Component={withOutletContext(justRender(clientViewLookbooks))}
			/>
			<Route
				path="files"
				Component={withOutletContext(justRender(clientViewFiles))}
			/>
			<Route
				path="collections"
				Component={withOutletContext(ClientViewCollectionView)}
			/>
			<Route path="" Component={withOutletContext(ClientViewAll)} />
		</Route>
		<Route
			path="closet/:collectionId"
			Component={withOutletContext(justRender(clientViewCloset))}
		/>
		<Route path="closet" exact Component={justRender(clientViewClosetList)} />
		<Route path="" element={<Navigate to="lookbooks" />} />
	</>
)
const clientRouter = createBrowserRouter(
	createRoutesFromElements(
		<Route
			path=""
			Component={(routerProps) => (
				<RouterProvider value={routerProps}>
					<QueryRenderer
						query={Main.mainQuery}
						environment={environment}
						variables={{
							teamId: null,
						}}
						relay={{ environment: {}, variables: [] }}
						render={({ props }) =>
							props ? (
								<Main.Main {...props} {...routerProps}>
									<Outlet />
								</Main.Main>
							) : null
						}
					/>
				</RouterProvider>
			)}
		>
			<Route path="/about" Component={justRender(About)} />
			<Route path="/dashboard" Component={justRender(Dashboard)} />
			<Route path="/wardrobe" exact Component={justRender(Wardrobe)} />
			<Route path="/search" Component={justRender(Search)} />
			<Route
				path="/explore/directory"
				exact
				Component={justRender(SharersPage)}
			/>
			<Route path="/explore/:type" Component={justRender(Featured)} />
			<Route path="/privacy" Component={justRender(Privacy)} />
			<Route path="/terms" Component={justRender(Terms)} />
			<Route path="/shopcast/:id" Component={justRender(Shopcast)} />
			<Route path="/shopboard/:id?" Component={justRender(ShopBoard)} />
			<Route path="/stylist/:id" Component={justRender(ClientView)}>
				{ClientViewRoutes}
			</Route>
			<Route path="/settings" Component={justRender(Settings)}>
				<Route
					path=":key?/:platform?"
					Component={justRender(ProfileAccount)}
				/>
			</Route>
			<Route path="/" exact Component={justRender(Wardrobe)} />
			<Route path="*" element={<Navigate to="/" />} />
		</Route>
	)
)

const subDomainRouter = createBrowserRouter(
	createRoutesFromElements(
		<Route
			path=""
			Component={(routerProps) => (
				<RouterProvider value={routerProps}>
					<QueryRenderer
						query={Main.mainQuery}
						environment={environment}
						variables={{}}
						relay={{ environment: {}, variables: [] }}
						render={({ props }) =>
							props ? (
								<Main.Main {...props} {...routerProps}>
									<Outlet />
								</Main.Main>
							) : null
						}
					/>
				</RouterProvider>
			)}
		>
			<Route path="/shopcast/:id" Component={justRender(Shopcast)} />
			<Route path="/" exact Component={justRender(ProfileView)} />
			<Route path="/shared/:folder" Component={justRender(SharedFolder)} />
			<Route path="/shopboard/:id?" Component={justRender(ShopBoard)} />
			<Route path="*" element={<Navigate to="/" />} />
		</Route>
	)
)

const mainRouter = createBrowserRouter(
	createRoutesFromElements(
		<>
			<Route path="/signin" Component={justRender(Signin)} />
			{/* <Route path="/signup" Component={justRender(Signin)} /> */}
			<Route path="/create-user/:code" Component={justRender(Signup)} />
			<Route path="/forgot" Component={justRender(ForgotPassword)} />
			<Route path="/reset-password" Component={justRender(ResetPassword)} />
			<Route path="/team/:teamId" Component={justRender(TeamView)} />
			<Route
				path=""
				Component={(routerProps) => {
					const { team } = useContext(GlobalContext)
					return (
						<RouterProvider value={routerProps}>
							<Elements stripe={stripePromise}>
								<QueryRenderer
									query={Main.mainQuery}
									environment={environment}
									variables={{
										teamId: team,
									}}
									relay={{ environment: {}, variables: [] }}
									render={({ props }) =>
										props ? (
											<Main.Main {...props} {...routerProps}>
												<Outlet />
											</Main.Main>
										) : null
									}
								/>
							</Elements>
						</RouterProvider>
					)
				}}
			>
				<Route path="/about" Component={justRender(About)} />
				<Route path="/verify" Component={justRender(Verify)} />
				<Route path="/faqs" Component={justRender(Faqs)} />
				<Route
					path="/explore/directory"
					exact
					Component={justRender(SharersPage)}
				/>
				<Route path="/explore/:type" Component={justRender(Featured)} />
				<Route path="/features" Component={justRender(Features)} />
				<Route path="/products" Component={justRender(Products)} />
				<Route path="/pricing" exact Component={justRender(Pricing)} />
				<Route path="/search" Component={justRender(Search)} />
				<Route path="/retailers" Component={justRender(Retailers)} />
				<Route path="/settings" Component={justRender(Settings)}>
					<Route
						path=":key?/:platform?"
						Component={justRender(ProfileAccount)}
					/>
				</Route>
				{/* <Route
					path="/install-extension"
					Component={justRender(InstallExtension)}
				/> */}
				<Route path="/privacy" Component={justRender(Privacy)} />
				<Route path="/terms" Component={justRender(Terms)} />
				<Route
					path="/content/:folderId?"
					Component={justRender(ShopcastManagerRoot)}
				/>
				<Route path="/shopcast/:id" Component={justRender(Shopcast)} />
				<Route
					path="/admin"
					Component={justRender(AdminComponent, AdminRootPage)}
				>
					<Route
						path="campaign/:id"
						Component={justRender(AdminReferralViewCampaign)}
					/>
					<Route
						path="user"
						exact
						Component={justRender(AdminUserListView)}
					/>
					<Route
						path="user/:id"
						Component={justRender(AdminUserProfile)}
					/>
					<Route
						path="dashboard"
						Component={justRender({
							Component: AdminDashboard,
						})}
					>
						<Route
							path="business"
							exact
							Component={justRender({
								Component: AdminBusinessDashboard,
							})}
						/>
						<Route
							path="products"
							exact
							Component={justRender({
								Component: AdminProductDashboard,
							})}
						/>
						<Route
							path=""
							element={<Navigate to="/admin/dashboard/business" />}
						/>
					</Route>
					<Route
						path="flagged"
						exact
						Component={justRender({
							Component: AdminUserReports,
						})}
					/>
					<Route
						path="campaign"
						exact
						Component={justRender({
							Component: AdminReferralCampaign,
						})}
					/>
					<Route
						path="referralpayment"
						exact
						Component={justRender({
							Component: AdminReferralPayment,
						})}
					/>
					<Route
						path="commision"
						exact
						Component={justRender({
							Component: AdminCommisionReport,
						})}
					/>
					<Route
						path="transactions"
						Component={justRender({
							Component: AdminTransactionsReport,
						})}
					/>
					<Route
						path=""
						element={<Navigate to="/admin/dashboard/business" />}
					/>
				</Route>
				<Route path="/reporting" Component={justRender(Reporting)}>
					<Route path="sales" Component={justRender(SalesReport)} />
					{/* <Route
															path="payouts"
															Component={justRender(PayoutsReport)}
														/>
														<Route
															path="analytics"
															Component={justRender(AnalyticsReport)}
														/> */}
					<Route path="" element={<Navigate to="/reporting/sales" />} />
				</Route>
				<Route path="/partners" Component={justRender(RakutenSites)} />
				<Route
					path="/shared/:name/:folder"
					Component={justRender(SharedFolder)}
				/>
				<Route
					path="/shopboard/create/:id?"
					Component={justRender(Lookbook)}
				/>
				<Route path="/shopboard/:id?" Component={justRender(ShopBoard)} />
				<Route
					path="/for-network-marketers"
					Component={justRender(ForNetworkMarketers)}
				/>
				<Route
					path="/for-content-marketing"
					Component={justRender(ForContentMarketing)}
				/>
				<Route
					path="/for-virtual-styling"
					Component={justRender(ForVirtualStyling)}
				/>
				<Route path="/for-shopify" Component={justRender(ForShopify)} />
				<Route
					path="/screen-recorder"
					Component={justRender(ShopRecorder)}
				/>
				<Route path="/moodboards" Component={justRender(Moodboards)} />
				<Route
					path="/shoppable-videos"
					Component={justRender(ShoppableVideos)}
				/>
				<Route path="/dashboard" Component={justRender(Dashboard)} />
				<Route
					path="/collections"
					Component={justRender(ProductCollections)}
				/>
				<Route path="/clients" Component={justRender(Clients)} />
				<Route
					path="/client-preview/:id"
					Component={justRender(ClientView)}
				>
					{ClientViewRoutes}
				</Route>
				<Route path="/stylist/:id" Component={justRender(ClientView)}>
					{ClientViewRoutes}
				</Route>
				<Route
					path="/edit-preview/:id"
					Component={justRender(EditPreview)}
				/>
				<Route path="/edit-preview" Component={justRender(EditPreview)} />
				<Route
					path="/lookbook-download"
					Component={justRender(LookbookDownload)}
				/>
				<Route path="/teamAccept" Component={justRender(TeamAccept)} />
				<Route path="/" Component={justRender(Home)} />
				<Route path="*" element={<Navigate to="/" />} />
			</Route>
			<Route
				path=""
				Component={(routerProps) => (
					<RouterProvider value={routerProps}>
						<QueryRenderer
							query={Main.mainQuery}
							environment={environment}
							relay={{ environment: {}, variables: [] }}
							render={({ props }) =>
								props ? (
									<Main.Main {...props} {...routerProps}>
										<Outlet />
									</Main.Main>
								) : null
							}
						/>
					</RouterProvider>
				)}
			>
				<Route path="" Component={justRender(Home)} />
				<Route path="home" Component={justRender(Home)} />
				<Route path="*" element={<Navigate to="/" />} />
			</Route>
		</>
	)
)

function RouterSetup() {
	const getUserData = () => {
		const tokenCookie = cookieService.getToken()
		const tokenData = tokenCookie ? jwtDecode(tokenCookie) : null
		return tokenData
	}
	const [data, setData] = useState(getUserData())

	const [showInstallPrompt, setShowInstallPrompt] = useState(
		data &&
			data.type === "client" &&
			localStorage.getItem("pwaInstallPrompt") !== "true" &&
			isValid
	)

	useEffect(() => {
		cookieService.tokenChange.push(() => setData(getUserData()))
	}, [])

	useEffect(() => {
		setShowInstallPrompt(
			data &&
				data.type === "client" &&
				localStorage.getItem("pwaInstallPrompt") !== "true" &&
				isValid
		)
		const beforeinstallprompt = (e) => {
			e.preventDefault()
			setShowInstallPrompt(true)
		}
		const appinstalled = () => {
			localStorage.setItem("pwaInstallPrompt", "true")
			setShowInstallPrompt(false)
		}
		if (isValid && data && data.type === "client") {
			window.addEventListener("beforeinstallprompt", beforeinstallprompt)
			window.addEventListener("appinstalled", appinstalled)
		}
		return () => {
			window.removeEventListener("beforeinstallprompt", beforeinstallprompt)
			window.removeEventListener("appinstalled", appinstalled)
		}
	}, [data])
	const getSwitch = useCallback(() => {
		if (data && data.type === "client")
			return (
				<GlobalContextProvider>
					{() => <ReactRouterProvider router={clientRouter} />}
				</GlobalContextProvider>
			)
		if (username) return <ReactRouterProvider router={subDomainRouter} />
		return (
			<GlobalContextProvider>
				{() => <ReactRouterProvider router={mainRouter} />}
			</GlobalContextProvider>
		)
	}, [data])

	return (
		<>
			{showInstallPrompt && (
				<PwaInstallPrompt
					data={data}
					closeModal={() => {
						setShowInstallPrompt(false)
						localStorage.setItem("pwaInstallPrompt", "true")
					}}
				/>
			)}
			{getSwitch()}
		</>
	)
}

export default {
	routes: (
		<Fragment>
			<RouterSetup />
			<ToastContainer
				theme="colored"
				position="top-center"
				icon={false}
				hideProgressBar
			/>
		</Fragment>
	),
}
