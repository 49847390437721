import React, { Component, Fragment } from "react"
import awsService from "services/aws-service"
import { Cross2Icon } from "@radix-ui/react-icons"
import Modal from "react-modal"
import { FileIcon, defaultStyles } from "react-file-icon"
import Dropzone from "react-dropzone"

import createShopshareFile from "mutations/shopshare-file/create-shopshare-file"
import modal from "shared/styles/components/modal.css"
import button from "shared/styles/components/buttons.css"
import file from "shared/styles/components/file.css"
import progress from "shared/styles/components/progress.css"
import UploadFeatureIcon from "shared/assets/feature-icon-upload.svg"
import grids from "shared/styles/components/grids.css"
import { toast } from "react-toastify"
import UploadCard from "./upload-card"
import EditFileContent from "./edit-file-content"
import styles from "./image-upload.css"

class FileUpload extends Component {
	constructor(props) {
		super(props)
		this.state = {
			selectedFile: null,
			files: [],
			uploadPercentage: 0,
		}
		this.upload = this.upload.bind(this)
	}

	upload() {
		const waitUntilTitleBlur = () => {
			const { files } = this.state
			const focused = files.find((x) => x.titleInFocus)
			if (focused) setTimeout(() => waitUntilTitleBlur(), 100)
		}
		waitUntilTitleBlur()
		const { files } = this.state
		const { closeModal, relay, folder, username, team } = this.props
		const percentages = new Array(files.length).fill(0)
		const filePromises = files.map((fileData, index) =>
			awsService
				.uploadFileService(fileData.file, (completed) => {
					percentages[index] = completed
					this.setState({
						uploadPercentage: percentages.reduce(
							(acc, cur) => acc + cur,
							0
						),
					})
				})
				.then(
					(res) =>
						new Promise((resolve, reject) => {
							createShopshareFile.commit(
								relay.environment,
								{
									title: fileData.title,
									url: res.url,
									folder,
									teamId: team,
								},
								(err) => {
									reject(err)
								},
								(data) => {
									resolve(data)
								},
								{
									username,
								}
							)
						})
				)
		)
		Promise.all(filePromises)
			.then(() => {
				toast.success("Successfully Uploaded", {
					autoClose: 2000,
					closeButton: false,
				})
				this.setState({ uploadPercentage: 0, files: [] })
				closeModal()
			})
			.catch(() => {
				toast.error("Error Uploading Files.")
				this.setState({ uploadPercentage: 0 })
			})
	}

	renderModalContent() {
		const { closeModal } = this.props
		const { selectedFile, files, uploadPercentage } = this.state
		return (
			<div
				className={`${modal.dialog} ${modal.dialogCentered} ${modal.dialogFullscreenSmDown} ${modal.dialogLg}`}
			>
				<div className={`${modal.content}`}>
					{uploadPercentage > 0 && (
						<div className={styles.progress}>
							<progress
								className={progress.bar}
								max={100}
								value={
									files.length > 0
										? uploadPercentage / files.length
										: 0
								}
							/>
							<h4 className={progress.status}>
								Uploading {files.length}{" "}
								{files.length > 1 ? "files" : "file"}
							</h4>
						</div>
					)}
					<div className={modal.header}>
						<h4 className={modal.title}>
							{selectedFile ? "Edit File" : "Upload Files"}
						</h4>
						<button
							type="button"
							className={button.close}
							onClick={() => {
								closeModal()
							}}
						>
							<Cross2Icon className={button.closeIcon} />
						</button>
					</div>
					{selectedFile ? this.renderEditFile() : this.renderList()}
				</div>
			</div>
		)
	}

	renderEditFile() {
		const { selectedFile, files } = this.state
		return (
			<EditFileContent
				selectedFile={selectedFile}
				closeModal={() => {
					this.setState({ selectedFile: null })
				}}
				save={(values) => {
					const index = files.findIndex((i) => i === selectedFile)
					const editedFiles = [...files]
					editedFiles[index].title = values.title
					this.setState({
						files: editedFiles,
						selectedFile: null,
					})
				}}
			/>
		)
	}

	renderList() {
		const { closeModal } = this.props
		const { files } = this.state
		return (
			<Fragment>
				<div className={modal.body}>
					<Dropzone
						onDrop={(acceptedFiles) => {
							this.setState({
								files: [
									...files,
									...acceptedFiles.map((i) => {
										const extension = i.name.substring(
											i.name.lastIndexOf(".") + 1
										)
										return {
											title: i.name,
											file: i,
											tempIcon: (
												<FileIcon
													extension={extension}
													{...defaultStyles[extension]}
												/>
											),
										}
									}),
								],
							})
						}}
						noKeyboard
					>
						{({ getRootProps, getInputProps }) => (
							<div className="container">
								<div className={file.root}>
									<div {...getRootProps()} className={file.dropRect}>
										<input {...getInputProps()} />
										<UploadFeatureIcon className={file.iconLg} />
										<p className={file.label}>
											<b>Click to upload</b> or drag and drop
										</p>
									</div>
								</div>
								{files.length > 0 && (
									<div className={styles.content}>
										<div className={grids.image}>
											{files.map((fileObj) => (
												<UploadCard
													key={fileObj.file.name}
													file={fileObj}
													deleteFile={() => {
														this.setState({
															files: files.filter(
																(i) => i !== fileObj
															),
														})
													}}
													editFile={() => {
														this.setState({
															selectedFile: fileObj,
														})
													}}
													updateFile={(key, value) => {
														const {
															files: stateFiles,
														} = this.state
														const index = stateFiles.findIndex(
															(i) => i === fileObj
														)
														const editedFiles = [...files]
														editedFiles[index][key] = value
														this.setState({
															files: editedFiles,
														})
													}}
												/>
											))}
										</div>
									</div>
								)}
							</div>
						)}
					</Dropzone>
				</div>
				<div className={modal.footer}>
					<button
						type="button"
						className={button.link}
						onClick={() => {
							closeModal()
						}}
					>
						Cancel
					</button>
					<button
						type="submit"
						className={button.primary}
						onClick={this.upload}
					>
						Upload
					</button>
				</div>
			</Fragment>
		)
	}

	render() {
		const { openModal, ...otherProps } = this.props
		return (
			<Modal
				className={modal.root}
				overlayClassName={modal.overlay}
				isOpen={openModal}
				{...otherProps}
				ariaHideApp={false}
			>
				{this.renderModalContent()}
			</Modal>
		)
	}
}

export default FileUpload
