import { commitMutation, graphql } from "react-relay"
import { complete } from "../helper"

const mutation = graphql`
	mutation payoutCommissionMutation($input: PayoutCommissionInput!) {
		payoutCommission(input: $input) {
			store {
				id
				admin {
					adminSalesReport {
						commissions {
							currency
							sales
							totalCommission
							paidCommission
							unpaidCommission
							unpaidCommissionAUS
							totalCommissionAUS
						}
					}
				}
			}
		}
	}
`

const commit = (env, userIds, onError, onCompleted) =>
	commitMutation(env, {
		mutation,
		variables: { input: { userIds } },
		onError: complete(onCompleted, onError),
		onCompleted: complete(onCompleted, onError),
	})

export default { commit }
