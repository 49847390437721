import React from "react"
import {
	FontBoldIcon,
	FontItalicIcon,
	TextAlignLeftIcon,
	TextAlignRightIcon,
	TextAlignCenterIcon,
	ColumnSpacingIcon,
	RowSpacingIcon,
	CropIcon,
	PaddingIcon,
	MarginIcon,
	TrashIcon,
	CopyIcon,
	MagicWandIcon,
	DownloadIcon,
	EnterFullScreenIcon,
	ExitFullScreenIcon,
} from "@radix-ui/react-icons"
import FontFamilyPicker from "components/lookbook-dropdown/font-family-picker"
import FontSizePicker from "components/lookbook-dropdown/font-size-picker"
import LineHeightPicker from "components/lookbook-dropdown/line-height-picker"
import ColorPicker from "components/lookbook-dropdown/color-picker"
import KonvaFilters from "components/lookbook-dropdown/konva-filters"
import TransparentHandler from "components/lookbook-dropdown/transparent-handler"
import { ClipTypeENUM } from "helpers/enum-helper"
import styles from "routes/lookbook/moveable.css"

function ToolBox({
	textAlign,
	editText,
	color,
	lineHeight,
	fontSize,
	fontFamily,
	isShow,
	isBold,
	isItalic,
	runDebounce,
}) {
	return (
		<div
			className={styles.controlsType}
			style={{ display: isShow ? "grid" : "none" }}
		>
			<div className={styles.dividerVert} />
			<FontFamilyPicker
				dataTip="Font Family"
				fontFamily={fontFamily}
				onChange={(result) => editText(result)}
				runDebounce={runDebounce}
			/>
			<FontSizePicker
				dataTip="Font Size"
				fontSize={fontSize}
				onChange={(result) => editText(result)}
				runDebounce={runDebounce}
			/>
			<ColorPicker
				dataTip="Text Colour"
				color={color}
				onChange={(colorValue) => editText({ color: colorValue })}
				runDebounce={runDebounce}
			/>
			<button
				type="button"
				data-tooltip-id="tooltip"
				data-tooltip-content="Bold"
				onClick={() => editText({ fontWeight: "bold" })}
				className={isBold ? styles.toolActive : styles.tool}
			>
				<FontBoldIcon className={styles.icon} />
			</button>
			<button
				type="button"
				data-tooltip-id="tooltip"
				data-tooltip-content="Italic"
				onClick={() => editText({ fontStyle: "italic" })}
				className={isItalic ? styles.toolActive : styles.tool}
			>
				<FontItalicIcon className={styles.icon} />
			</button>
			<div className={styles.dividerVert} />
			<div data-tooltip-id="tooltip" data-tooltip-content="Alignment">
				{textAlign === "left" && (
					<button
						type="button"
						onClick={() => editText({ align: "right" })}
						className={styles.tool}
					>
						<TextAlignLeftIcon className={styles.icon} />
					</button>
				)}
				{textAlign === "right" && (
					<button
						type="button"
						onClick={() => editText({ align: "center" })}
						className={styles.tool}
					>
						<TextAlignRightIcon className={styles.icon} />
					</button>
				)}
				{textAlign === "center" && (
					<button
						type="button"
						onClick={() => editText({ align: "left" })}
						className={styles.tool}
					>
						<TextAlignCenterIcon className={styles.icon} />
					</button>
				)}
			</div>
			<div className={styles.dividerVert} />
			<LineHeightPicker
				dataTip="Spacing"
				lineHeight={lineHeight}
				onChange={(result) => editText(result)}
				runDebounce={runDebounce}
			/>
		</div>
	)
}

function ImageTools({ isSVG, flip, openCropModal }) {
  return <>
		<div className={styles.dividerVert} />
		{!isSVG && (
			<>
				<button
					type="button"
					data-tooltip-id="tooltip"
					data-tooltip-content="Crop"
					onClick={openCropModal}
					className={styles.tool}
				>
					<CropIcon className={styles.icon} />
				</button>
				<div className={styles.dividerVert} />
			</>
		)}
		<button
			type="button"
			data-tooltip-id="tooltip"
			data-tooltip-content="Flip Horizontally"
			onClick={() => flip("x", true)}
			className={styles.tool}
		>
			<ColumnSpacingIcon className={styles.icon} />
		</button>
		<button
			type="button"
			data-tooltip-id="tooltip"
			data-tooltip-content="Flip Vertically"
			onClick={() => flip("y")}
			className={styles.tool}
		>
			<RowSpacingIcon className={styles.icon} />
		</button>
	</>
}

function TemplateTools({ clipType, changeClipType }) {
  return <>
		<div className={styles.dividerVert} />
		<button
			type="button"
			data-tooltip-id="tooltip"
			data-tooltip-content={clipType === ClipTypeENUM.FILL ? "Fill" : "Fit"}
			onClick={() =>
				changeClipType(
					clipType === ClipTypeENUM.FILL
						? ClipTypeENUM.FIT
						: ClipTypeENUM.FILL
				)
			}
			className={styles.tool}
		>
			{clipType === ClipTypeENUM.FILL ? (
				<PaddingIcon className={styles.icon} />
			) : (
				<MarginIcon className={styles.icon} />
			)}
		</button>
	</>
}

function RightSideTools({
	shadowColor,
	shadowBlur,
	shadowOffset,
	shadowOpacity,
	imageTransparentLevel,
	handleImageTransparent,
	handleShadow,
	onDuplicate,
	onRemoveItem,
	runDebounce,
}) {
  return <>
		<KonvaFilters
			shadowColor={shadowColor}
			shadowBlur={shadowBlur}
			runDebounce={runDebounce}
			shadowOffset={shadowOffset}
			shadowOpacity={shadowOpacity}
			handleShadow={handleShadow}
			dataTip="Effects"
		/>
		<TransparentHandler
			imageTransparentLevel={imageTransparentLevel}
			handleImageTransparent={handleImageTransparent}
			runDebounce={runDebounce}
			dataTip="Transparency"
		/>
		<div className={styles.dividerVert} />
		<button
			type="button"
			data-tooltip-id="tooltip"
			data-tooltip-content="Duplicate"
			onClick={onDuplicate}
			className={styles.tool}
		>
			<CopyIcon className={styles.icon} />
		</button>
		<button
			type="button"
			data-tooltip-id="tooltip"
			data-tooltip-content="Delete"
			onClick={onRemoveItem}
			className={styles.tool}
		>
			<TrashIcon className={styles.icon} />
		</button>
		<div className={styles.dividerVert} />
	</>
}

function RightDefaultTools({
	loading,
	VideoThumbnail,
	ChannelBanner,
	fullScreen,
	setChannelBanner,
	shopcastModalOpen,
	downloadImage,
	changeFullScreen,
}) {
  return <>
		<div className={styles.dividerVert} />
		<button
			type="button"
			onClick={(e) => {
				if (VideoThumbnail) shopcastModalOpen()
				if (ChannelBanner) setChannelBanner(e)
			}}
			style={
				VideoThumbnail || ChannelBanner
					? { display: "block" }
					: { display: "none" }
			}
			data-tooltip-id="tooltip"
			data-tooltip-content={`${VideoThumbnail ? "Set Video Cover" : ""}
									${ChannelBanner ? "Set Channel Banner" : ""}`}
			className={styles.tool}
		>
			<MagicWandIcon className={styles.icon} />
		</button>
		<button
			type="button"
			onClick={(e) => {
				if (!loading) downloadImage(e)
			}}
			className={styles.tool}
			data-tooltip-id="tooltip"
			data-tooltip-content={loading ? "Loading" : "Download"}
		>
			<DownloadIcon className={styles.icon} />
		</button>
		<button
			type="button"
			onClick={changeFullScreen}
			data-tooltip-id="tooltip"
			data-tooltip-content="Fullscreen"
			className={styles.tool}
		>
			{!fullScreen ? (
				<EnterFullScreenIcon className={styles.icon} />
			) : (
				<ExitFullScreenIcon className={styles.icon} />
			)}
		</button>
	</>
}

export { ToolBox, ImageTools, TemplateTools, RightSideTools, RightDefaultTools }
