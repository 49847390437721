import React, { Component, Fragment } from "react"
import twoColumn from "shared/styles/layout/two-column.css"
import pageHeader from "shared/styles/layout/page-header.css"
import buttons from "shared/styles/components/buttons.css"
import empty from "shared/styles/components/empty.css"
import { getComponent } from "../../../route-helpers"
import PeopleListView from "./people-list"
import InviteMember from "../../modals/invite-member/invite-member"

class PeopleView extends Component {
	constructor(props) {
		super(props)
		this.state = {
			showInviteModal: false,
		}
	}

	renderNoTeam() {
		return (
			<div className={empty.simpleSml}>No team selected</div>
		)
	}

	renderUserRecords() {
		const {
			team
		} = this.props
		const PeopleListViewComp = getComponent(
			`people-list-views`,
			PeopleListView,
			{
				count: 12,
				teamId: team?.id,
			},
			{
				count: 12,
				teamId: team?.id,
			}
		)
		return PeopleListViewComp
	}

	render() {
		const { relay, team } = this.props
		if (!team) return this.renderNoTeam()
		const { showInviteModal } = this.state
		const myRole = team?.myRole
		return (
			<Fragment>
				<div className={pageHeader.header}>
					<div className={pageHeader.content}>
						<h2 className={pageHeader.title}>People</h2>
						{["OWNER", "ADMIN"].includes(myRole) && (
							<div className={pageHeader.actions}>
								<button
									type="button"
									className={buttons.primary}
									onClick={() => {
										this.setState({
											showInviteModal: true,
										})
									}}
								>
									Invite People
								</button>
							</div>
						)}
					</div>
				</div>
				<div className={twoColumn.section}>{this.renderUserRecords()}</div>
				{showInviteModal && (
					<InviteMember
						showModal
						teamId={team?.id}
						relay={relay}
						closeModal={() => {
							this.setState({ showInviteModal: false })
						}}
					/>
				)}
			</Fragment>
		)
	}
}

export default PeopleView
