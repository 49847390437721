/**
 * @generated SignedSource<<0e53a8a7565495a3ba99e248db5a16e9>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentType } from "relay-runtime";
declare export opaque type home_store$fragmentType: FragmentType;
export type home_store$data = {|
  +currentUser: ?{|
    +hasPendingSharerRequests: ?number,
    +id: string,
    +isANewUser: ?boolean,
  |},
  +id: string,
  +$fragmentType: home_store$fragmentType,
|};
export type home_store$key = {
  +$data?: home_store$data,
  +$fragmentSpreads: home_store$fragmentType,
  ...
};
*/

var node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "home_store",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "currentUser",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "hasPendingSharerRequests",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isANewUser",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Store",
  "abstractKey": null
};
})();

(node/*: any*/).hash = "983fbfc7fe83fcb44e4a1114a4a937ad";

module.exports = ((node/*: any*/)/*: Fragment<
  home_store$fragmentType,
  home_store$data,
>*/);
