/**
 * @generated SignedSource<<b1a95eb9b5d9b7b63a63eb4f863a4ab7>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Mutation } from 'relay-runtime';
export type ToggleLikesInput = {|
  clientMutationId?: ?string,
  shopcastId: string,
|};
export type toggleLikesMutation$variables = {|
  input: ToggleLikesInput,
|};
export type toggleLikesMutation$data = {|
  +toggleLikes: ?{|
    +store: ?{|
      +id: string,
      +shopcast: ?{|
        +id: string,
      |},
    |},
  |},
|};
export type toggleLikesMutation = {|
  response: toggleLikesMutation$data,
  variables: toggleLikesMutation$variables,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "ToggleLikesPayload",
    "kind": "LinkedField",
    "name": "toggleLikes",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Store",
        "kind": "LinkedField",
        "name": "store",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Shopcast",
            "kind": "LinkedField",
            "name": "shopcast",
            "plural": false,
            "selections": [
              (v1/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "toggleLikesMutation",
    "selections": (v2/*: any*/),
    "type": "ShopshareMutationRoot",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "toggleLikesMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "46ec5b0d0c8c6adfab2613e0b07e56a7",
    "id": null,
    "metadata": {},
    "name": "toggleLikesMutation",
    "operationKind": "mutation",
    "text": "mutation toggleLikesMutation(\n  $input: ToggleLikesInput!\n) {\n  toggleLikes(input: $input) {\n    store {\n      id\n      shopcast {\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "aef73bc87a8987bbbfbe390e8ba6458f";

module.exports = ((node/*: any*/)/*: Mutation<
  toggleLikesMutation$variables,
  toggleLikesMutation$data,
>*/);
