import React from "react"
import { Helmet } from "react-helmet"
import landing from "shared/styles/layout/landing.css"
import styles from "./privacy.css"

class Privacy extends React.PureComponent {
	render() {
		return (
			<div className={styles.root}>
				<Helmet>
					<title>Privacy Policy</title>
				</Helmet>
				<section className={landing.section}>
					<div className={landing.content}>
						<h1 className={landing.displayTitle}>Privacy</h1>
					</div>
				</section>
				<div className={styles.content}>
					<p className={styles.youtube} style={{ textAlign: "center" }}>
						All users are agreeing to be bound with{" "}
						<a
							href="https://www.youtube.com/t/terms"
							title="YouTube's Terms of Service"
						>
							YouTube&apos;s Terms of Service
						</a>
					</p>
				</div>
			</div>
		)
	}
}

export default { Component: Privacy }
