/* eslint-disable no-underscore-dangle */
import React, { useCallback, useState } from "react"
import { Transition } from "react-transition-group"
import axios from "axios"
import { toast } from "react-toastify"
import {
	TrashIcon,
	Pencil1Icon,
	DownloadIcon,
	MinusIcon,
} from "@radix-ui/react-icons"
import { apiUrl } from "constants"
import MenuIcon from "shared/assets/menu-dots-horizontal.svg"
import { isBackgroundRemovedImage } from "helpers/lookbook-helper"
import { withDrag } from "helpers/dnd-helper"
import { CategoryENUM } from "helpers/enum-helper"
import { addVersioning } from "helpers/query-parameters-helper"
import { ShowCardControlsComponent } from "helpers/ui-helper"
import ContentEditable from "components/content-editable/content-editable"
import input from "shared/styles/components/input.css"
import stylesDropdown from "components/dropdown-menu/dropdown-menu-dark.css"
import DropdownMenu from "components/dropdown-menu/dropdown-menu"
import OwnedItemButton from "components/buttons/owned-item-button"
import StyleMeIcon from "shared/assets/style-me-icon.svg"
import button from "shared/styles/components/buttons.css"
import updateCategorizeLookbookUploadMutation from "mutations/lookbook/update-categorize-lookbook-upload"
import UpdateProductMutation from "mutations/product/update-product"
import PlusButton from "components/buttons/plus-button"
import StyleMagicButton from "components/buttons/style-magic-button"
import FavouriteButton from "components/buttons/favourite-button"
import progress from "shared/styles/components/progress.css"
import styles from "./image-card.css"

function ImageCardInner(props) {
	const [isDropdownOpen, setIsDropdownOpen] = useState(false)
	const [isTitleInFocus, setIsTitleInFocus] = useState(false)
	const {
		canEdit,
		category,
		clickFavourite,
		deleteImage,
		description,
		dragRef,
		drop,
		editImage,
		handleCheckboxChange,
		isFavourite,
		isSelected,
		node,
		noFooter,
		noTrans,
		price,
		showAddButton,
		showFavouriteButton,
		contentEditable,
		relay,
		store,
		enableCardSelection,
		deleteBtnLabel,
		onOwnedItems,
		removeEditImageOption,
		showDownloadButtons,
		showReferences,
		showReferenceTitle,
	} = props
	const dragStart = ({ dataTransfer }) => {
		dataTransfer.effectAllowed = "move"
	}
	const url =
		node?.__typename === "ShopcastProduct" ? node?.imageUrl : node?.url
	const versionedUrl = url ? addVersioning(url, `d=400`) : null

	const getDomain = () => {
		try {
			return new URL(node.url).hostname.replace("www.", "")
		} catch (error) {
			return ""
		}
	}

	const loader = (
		<svg
			width="24"
			height="24"
			viewBox="0 0 38 38"
			xmlns="http://www.w3.org/2000/svg"
		>
			<defs>
				<linearGradient
					x1="8.042%"
					y1="0%"
					x2="65.682%"
					y2="23.865%"
					id="a"
				>
					<stop stopColor="#fff" stopOpacity="0" offset="0%" />
					<stop stopColor="#fff" stopOpacity=".631" offset="63.146%" />
					<stop stopColor="#fff" offset="100%" />
				</linearGradient>
			</defs>
			<g fill="none" fillRule="evenodd">
				<g transform="translate(1 1)">
					<path
						d="M36 18c0-9.94-8.06-18-18-18"
						id="Oval-2"
						stroke="url(#a)"
						strokeWidth="2"
					>
						<animateTransform
							attributeName="transform"
							type="rotate"
							from="0 18 18"
							to="360 18 18"
							dur="0.9s"
							repeatCount="indefinite"
						/>
					</path>
					<circle fill="#fff" cx="36" cy="18" r="1">
						<animateTransform
							attributeName="transform"
							type="rotate"
							from="0 18 18"
							to="360 18 18"
							dur="0.9s"
							repeatCount="indefinite"
						/>
					</circle>
				</g>
			</g>
		</svg>
	)

	const downloadImage = (type) => {
		const id = toast.loading(
			type === "transparent" ? (
				<div className={styles.toastMessage}>
					{loader} Generating File...
				</div>
			) : (
				"Downloading..."
			),
			{
				autoClose: false,
				type: "success",
				closeButton: false,
			}
		)
		axios
			.get(`${apiUrl}/product/download/${node.id}/${type}`, {
				responseType: "blob",
				onDownloadProgress: (progressEvent) => {
					const percentCompleted = Math.round(
						(progressEvent.loaded * 100) / progressEvent.total
					)
					toast.update(id, {
						render: `Downloading... ${percentCompleted}%`,
						autoClose: false,
						transition: false,
						type: "success",
						closeButton: false,
					})
				},
			})
			.then((response) => {
				const mainfilename = response.headers["content-disposition"]
					.split("filename=")[1]
					.split(";")[0]
					.replace(/^"(.*)"$/, "$1")
				const urlString = window.URL.createObjectURL(
					new Blob([response.data]),
					{ type: response.headers["content-type"] }
				)
				const link = document.createElement("a")
				link.href = urlString
				const mIOS =
					(window.navigator.userAgent.indexOf("iPhone") !== -1 ||
						window.navigator.userAgent.indexOf("iPad") !== -1) &&
					window.navigator.userAgent.indexOf("Safari") !== -1
				if (!mIOS) {
					link.setAttribute("download", mainfilename)
				}
				document.body.appendChild(link)
				link.click()
				link.remove()
				toast.update(id, {
					render: `Done`,
					type: "success",
					isLoading: false,
					autoClose: 2000,
					transition: false,
					closeButton: false,
				})
			})
			.catch((error) => {
				console.log(error)
				toast.update(id, {
					render: `Download Failed`,
					type: "error",
					isLoading: false,
					autoClose: 2000,
					closeButton: false,
				})
			})
	}

	const renderMenu = () => {
		const listItems = []
		const push = (click, name, key, icon) =>
			listItems.push({
				click,
				name,
				key,
				icon,
			})
		const icon = <MenuIcon className={stylesDropdown.icon} />

		if (showReferences) {
			push(
				() => showReferences(node),
				showReferenceTitle || "Used In",
				"references",
				<StyleMeIcon className={stylesDropdown.itemIcon} />
			)
			listItems.push("divide")
		}

		if (editImage && !removeEditImageOption) {
			push(
				() => editImage(node),
				"Edit",
				"edit",
				<Pencil1Icon className={stylesDropdown.itemIcon} />
			)
		}
		if (deleteImage) {
			push(
				() => deleteImage(node),
				deleteBtnLabel || "Delete",
				"delete",
				deleteBtnLabel && deleteBtnLabel !== "Delete" ? (
					<MinusIcon className={stylesDropdown.itemIcon} />
				) : (
					<TrashIcon className={stylesDropdown.itemIcon} />
				)
			)
		}
		if (showDownloadButtons) {
			listItems.push("divide")
			push(
				() => downloadImage("original"),
				"Original File",
				"original",
				<DownloadIcon className={stylesDropdown.itemIcon} />
			)
			push(
				() => downloadImage("transparent"),
				"Transparent (PNG)",
				"transparent",
				<DownloadIcon className={stylesDropdown.itemIcon} />
			)
		}
		return listItems.length ? (
			<DropdownMenu
				items={listItems}
				mainIcon={icon}
				darkTheme
				contentRight
				triggerClass={button.lightIcon}
				triggerClassActive={button.lightIconActive}
				setDropdownOpened={(val) => {
					setIsDropdownOpen(val)
				}}
			/>
		) : null
	}

	const saveTitle = useCallback((title) => {
		if (node?.__typename === "lookbookUploads")
			updateCategorizeLookbookUploadMutation.commit(
				relay.environment,
				{ id: node.id, title },
				null,
				(e) => console.log(e),
				() =>
					toast.success("Saved", {
						autoClose: 2000,
						closeButton: false,
					}),
				null
			)
		if (node?.__typename === "ShopcastProduct")
			UpdateProductMutation.commit(
				relay.environment,
				{
					productId: node.id,
					title,
					brand: node.brand,
					regularPrice: node.regularPrice,
					offerPrice: node.offerPrice,
				},
				store.id,
				() =>
					toast.success("Saved", {
						autoClose: 2000,
						closeButton: false,
					}),
				(e) => console.log(e)
			)
	}, [])

	const domain = getDomain()
	return (
		<ShowCardControlsComponent
			className={styles.root}
			innerRef={isTitleInFocus ? null : dragRef}
			onDragStart={dragStart}
		>
			{(isShown) => (
				<>
					<div
						className={
							isSelected
								? `${styles.media} ${styles.active}`
								: styles.media
						}
					>
						{node?.__typename === "ShopcastProduct" &&
							!enableCardSelection &&
							domain && (
								<div className={styles.brandLink}>
									<i>at</i> {domain}
								</div>
							)}
						<Transition in={isShown || enableCardSelection} timeout={200}>
							{(state) =>
								handleCheckboxChange && (
									<div className={`${styles.select} ${styles[state]}`}>
										<input
											type="checkbox"
											className={`${input.checkbox} ${input.lg}`}
											checked={isSelected}
											onChange={(event) => {
												handleCheckboxChange({
													node,
													target: event.target,
												})
											}}
										/>
									</div>
								)
							}
						</Transition>
						<Transition in={isShown && showAddButton} timeout={200}>
							{(state) => (
								<div className={`${styles.addAction} ${styles[state]}`}>
									<PlusButton onClick={() => drop(node?.id)} />
								</div>
							)}
						</Transition>
						<Transition
							in={
								(isShown || isDropdownOpen) &&
								canEdit &&
								!noFooter &&
								!enableCardSelection
							}
							timeout={200}
						>
							{(state) => (
								<div className={`${styles.menu} ${styles[state]}`}>
									{renderMenu()}
								</div>
							)}
						</Transition>

						{showReferences && <div
							className={styles.styleBtn}
							data-tooltip-id="tooltip"
							data-tooltip-content={showReferenceTitle || "Used In"}
						>
							<StyleMagicButton onClick={() => showReferences(node)} />
						</div>}

						<div
							className={
								!noTrans && isBackgroundRemovedImage(versionedUrl)
									? styles.figureTrans
									: styles.figureImage
							}
						>
							<div
								className={styles.inner}
								onClick={() => {
									if (enableCardSelection)
										handleCheckboxChange({
											node,
											target: {
												checked: !isSelected,
											},
										})
									else if (editImage) editImage(node)
									else
										window.open(
											node.affiliated_url || node.url,
											"_blank",
											"noopener noreferrer"
										)
								}}
								role="button"
								tabIndex={0}
							>
								{versionedUrl ? (
									<img
										loading="lazy"
										crossOrigin="anonymous"
										className={styles.poster}
										src={versionedUrl}
										id={node?.id}
										alt={node?.title}
										data-category={category}
										data-itemid={
											node.__typename === "ShopcastProduct"
												? node.id
												: null
										}
										data-uploadid={
											node.__typename === "lookbookUploads"
												? node.id
												: null
										}
										draggable={false}
									/>
								) : (
									<div className={progress.indefinite}>
										<div className={progress.indefiniteBar} />
									</div>
								)}
							</div>
						</div>
					</div>
					<div className={styles.content}>
						<div className={styles.titleWithIcons}>
							<ContentEditable
								className={styles.title}
								title={description}
								saveTitle={saveTitle}
								contentEditable={
									contentEditable && !enableCardSelection
								}
								setTitleFocus={setIsTitleInFocus}
							/>
							{showFavouriteButton && (
								<div
									className={
										enableCardSelection
											? styles.favButtonHide
											: styles.favButton
									}
								>
									<FavouriteButton
										isFavourite={isFavourite}
										onClick={clickFavourite}
									/>
								</div>
							)}
							{onOwnedItems && (
								<div className={styles.titleIcon}>
									<OwnedItemButton
										isOwned={!node.isOwnedItems}
										onClick={() => onOwnedItems(node.id)}
									/>
								</div>
							)}
						</div>
						{price && (
							<p className={styles.price}>
								<span className={styles.regularPrice}>{price}</span>
							</p>
						)}
					</div>
				</>
			)}
		</ShowCardControlsComponent>
	)
}

function collect(monitor) {
	return {
		isDragging: monitor.isDragging(),
	}
}

function ImageCard({ isDraggable, moveFrom, replaceable, ...otherProps }) {
	const Target = isDraggable
		? withDrag(ImageCardInner, ({ node }) => ({
				type: CategoryENUM.IMAGE,
				item: {
					id: node.id,
					type: node.__typename,
					collections: node.productCollections,
					moveFrom,
					replaceable,
				},
				collect,
		  }))
		: ImageCardInner
	return <Target {...otherProps} />
}

export default ImageCard
