/**
 * @generated SignedSource<<6dd7a063279da5ae2e575adda6b65748>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentType } from "relay-runtime";
declare export opaque type forShopify_store$fragmentType: FragmentType;
export type forShopify_store$data = {|
  +currentUser: ?{|
    +id: string,
  |},
  +id: string,
  +$fragmentType: forShopify_store$fragmentType,
|};
export type forShopify_store$key = {
  +$data?: forShopify_store$data,
  +$fragmentSpreads: forShopify_store$fragmentType,
  ...
};
*/

var node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "forShopify_store",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "currentUser",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "type": "Store",
  "abstractKey": null
};
})();

(node/*: any*/).hash = "81081c6b90139858b149182ace9fa3f1";

module.exports = ((node/*: any*/)/*: Fragment<
  forShopify_store$fragmentType,
  forShopify_store$data,
>*/);
