import React, { PureComponent } from "react"
import { createPaginationContainer, graphql } from "react-relay"
import ListOfShopcasts from "../../../components/list-of-shopcasts/list-of-shopcasts.jsx"

class SharedFolderShopcasts extends PureComponent {
	render() {
		const { props } = this
		const { profile } = props.store
		return (
			<ListOfShopcasts
				enableHeader={false}
				shopcasts={profile.sharedFolderShopcasts}
				relay={props.relay}
				currentUser={props.store.currentUser}
				storeId={props.store.id}
			/>
		)
	}
}

const query = graphql`
	query sharedFolderShopcastsQuery(
		$count: Int!
		$cursor: String
		$username: String!
		$folder: String!
	) {
		store {
			...sharedFolderShopcasts_store
				@arguments(
					count: $count
					cursor: $cursor
					username: $username
					folder: $folder
				)
		}
	}
`

SharedFolderShopcasts = createPaginationContainer(
	SharedFolderShopcasts,
	{
		store: graphql`
			fragment sharedFolderShopcasts_store on Store
			@argumentDefinitions(
				count: { type: "Int", defaultValue: 12 }
				cursor: { type: "String" }
				username: { type: "String!" }
				folder: { type: "String!" }
			) {
				id
				currentUser {
					id
					...shopcastCard_currentUser
				}
				profile(username: $username) {
					sharedFolderShopcasts(
						first: $count
						after: $cursor
						folderId: $folder
					) @connection(key: "profile_sharedFolderShopcasts") {
						edges {
							node {
								...shopcastCard_shopcast
							}
						}
					}
				}
			}
		`,
	},
	{
		direction: "forward",
		getConnectionFromProps(props) {
			const { profile } = props.store
			return profile && profile.sharedFolderShopcasts
		},
		getFragmentVariables(prevVars, totalCount) {
			return {
				...prevVars,
				count: totalCount,
			}
		},
		getVariables(props, { count, cursor }) {
			return {
				count,
				cursor,
				username: props.username,
				folder: props.folder,
			}
		},
		query,
	}
)

export default {
	Component: SharedFolderShopcasts,
	query,
	params: {
		count: 12,
		cursor: null,
		username: null,
		folder: null,
	},
}
