/**
 * @generated SignedSource<<0f8f8c8f1b003bd4e359422749579990>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Mutation } from 'relay-runtime';
export type SetPublishedCollectionInput = {|
  clientMutationId?: ?string,
  id: string,
  isPublished?: ?boolean,
|};
export type setPublishedCollectionMutation$variables = {|
  input: SetPublishedCollectionInput,
|};
export type setPublishedCollectionMutation$data = {|
  +setPublishedCollection: ?{|
    +published_at: ?boolean,
    +store: ?{|
      +id: string,
    |},
  |},
|};
export type setPublishedCollectionMutation = {|
  response: setPublishedCollectionMutation$data,
  variables: setPublishedCollectionMutation$variables,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "SetPublishedCollectionPayload",
    "kind": "LinkedField",
    "name": "setPublishedCollection",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Store",
        "kind": "LinkedField",
        "name": "store",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "published_at",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "setPublishedCollectionMutation",
    "selections": (v1/*: any*/),
    "type": "ShopshareMutationRoot",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "setPublishedCollectionMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "fc6875211bfec6c392f5832ef0569e1e",
    "id": null,
    "metadata": {},
    "name": "setPublishedCollectionMutation",
    "operationKind": "mutation",
    "text": "mutation setPublishedCollectionMutation(\n  $input: SetPublishedCollectionInput!\n) {\n  setPublishedCollection(input: $input) {\n    store {\n      id\n    }\n    published_at\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "19984697044bb33e4d01817f08c554a2";

module.exports = ((node/*: any*/)/*: Mutation<
  setPublishedCollectionMutation$variables,
  setPublishedCollectionMutation$data,
>*/);
