import React from "react"
import { createFragmentContainer, graphql } from "react-relay"
import { Helmet } from "react-helmet"
import PageFooter from "components/page-footer/page-footer"
import ListOfBullets from "components/list-of-bullets/list-of-bullets"
import ListOfFeatures from "components/list-of-features/list-of-features"
// import LandingActions from "components/landing-actions/landing-actions"
import landing from "shared/styles/layout/landing.css"
import feature from "shared/styles/layout/feature.css"
import ScrollToTopOnMount from "components/scroll-to-top/scroll-to-top"
import Virtual1 from "shared/assets/feature-images/virtual-1.jpg"
import Virtual2 from "shared/assets/feature-images/virtual-2.jpg"
import Virtual3 from "shared/assets/feature-images/virtual-3.jpg"
import styles from "./for-shopify.css"

function ForShopify({ store }) {
	const user = store.currentUser
	return (
		<div className={styles.root}>
			<Helmet>
				<title>ShopShare for Shopify</title>
			</Helmet>
			<ScrollToTopOnMount />

			<section className={`${landing.section} ${landing.gray100}`}>
				<div className={landing.content}>
					<h1 className={styles.displayTitle}>ShopCast Sales Videos</h1>
					<p className={landing.displayLead}>
						Build trust, spark emotions and encourage your shoppers to
						buy!
					</p>
					{/* {!user && (
						<div className={landing.actions}>
							<LandingActions />
						</div>
					)} */}
				</div>
			</section>

			<section className={landing.sectionFullWidth}>
				<div className={landing.content}>
					<h2 className={landing.title}>
						Why do Shopify stores use ShopCast Sales Videos?
					</h2>
					<div className={landing.listOfBullets}>
						<ListOfBullets
							bullets={[
								{
									key: "b1",
									label: "Increase Conversions",
								},
								{
									key: "b2",
									label: "Personal Connection",
								},
								{
									key: "b3",
									label: "Ease of Automation",
								},
							]}
						/>
					</div>
				</div>
			</section>

			<section className={landing.sectionFullWidth}>
				<div
					className={`${landing.contentFullWidth} ${landing.primary100}`}
				>
					<h2 className={landing.title}>The Human Touch</h2>
					<div className={landing.listOfFeatures}>
						<ListOfFeatures
							features={[
								{
									key: "f1",
									heading: "Video that’s Easy",
									body:
										"Create engaging sales videos in minutes with our simple-to-use recorder and publish directly to your product pages!",
								},
								{
									key: "f2",
									heading: "Video that Converts",
									body:
										"ShopCast is like an in-store salesperson that builds trust, sparks emotions and encourages shoppers to buy.",
								},
								{
									key: "f3",
									heading: "Video that’s Personal",
									body:
										"Bring your online store to life with the power of the ‘human touch’ and sell your products like you do in real life!",
								},
							]}
						/>
					</div>
				</div>
			</section>

			<section className={landing.section}>
				<div className={landing.content}>
					<div className={feature.root}>
						<div className={feature.mediaFrame}>
							<img
								className={feature.img}
								src={Virtual1}
								alt="Record from your web browser"
							/>
						</div>
						<div className={feature.content}>
							<div className={feature.inner}>
								<h2 className={feature.heading}>
									Record from your web browser
								</h2>
								<p className={feature.body}>
									The simple-to-use recorder requires no videographer
									or setup, just you and your products. Record from
									your browser then publish directly to your product
									pages.
								</p>
							</div>
						</div>
					</div>
				</div>
			</section>

			<section className={landing.section}>
				<div className={landing.content}>
					<div className={feature.rootFlip}>
						<div className={feature.media}>
							<img
								className={feature.img}
								src={Virtual2}
								alt="Customised (and fun!)."
							/>
						</div>
						<div className={feature.content}>
							<div className={feature.inner}>
								<h2 className={feature.heading}>
									Customised (and fun!)
								</h2>
								<p className={feature.body}>
									The ShopCast video play button can be customised to
									match perfectly with your store design. Choose a
									theme, colour and text for your button.
								</p>
							</div>
						</div>
					</div>
				</div>
			</section>

			<section className={landing.section}>
				<div className={landing.content}>
					<div className={feature.root}>
						<div className={feature.mediaFrame}>
							<img
								className={feature.img}
								src={Virtual3}
								alt="Seamless Integration"
							/>
						</div>
						<div className={feature.content}>
							<div className={feature.inner}>
								<h2 className={feature.heading}>
									Seamless Integration
								</h2>
								<p className={feature.body}>
									Choose where you want the ShopCast video play button
									to be positioned. You can move it around anywhere on
									your product page, and change it anytime.
								</p>
							</div>
						</div>
					</div>
				</div>
			</section>

			<section className={landing.section}>
				<div className={landing.contentPageFooter}>
					<PageFooter
						title="Use ShopShare for Shopify"
						lead="Add the ‘human touch’ to your store with ShopShare sales
						videos."
						currentUser={user}
					/>
				</div>
			</section>
		</div>
	)
}

// eslint-disable-next-line no-func-assign
ForShopify = createFragmentContainer(ForShopify, {
	store: graphql`
		fragment forShopify_store on Store {
			id
			currentUser {
				id
			}
		}
	`,
})

const query = graphql`
	query forShopifyQuery {
		store {
			...forShopify_store
		}
	}
`

export default { Component: ForShopify, query }
