/**
 * @generated SignedSource<<24446d4b1b1f9af0bf7b9069fc1f1c33>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentType } from "relay-runtime";
declare export opaque type productCollectionCard_productCollection$fragmentType: FragmentType;
export type productCollectionCard_productCollection$data = {|
  +client: ?{|
    +id: string,
  |},
  +count: ?number,
  +createdAt: ?string,
  +folder: ?{|
    +id: string,
    +name: ?string,
  |},
  +id: string,
  +images: ?$ReadOnlyArray<?string>,
  +isACloset: ?boolean,
  +isDefault: ?boolean,
  +isPinned: ?boolean,
  +isPublished: ?boolean,
  +title: ?string,
  +updatedAt: ?string,
  +$fragmentType: productCollectionCard_productCollection$fragmentType,
|};
export type productCollectionCard_productCollection$key = {
  +$data?: productCollectionCard_productCollection$data,
  +$fragmentSpreads: productCollectionCard_productCollection$fragmentType,
  ...
};
*/

var node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "productCollectionCard_productCollection",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "createdAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "updatedAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isDefault",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isPublished",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isPinned",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ShopcastFolder",
      "kind": "LinkedField",
      "name": "folder",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "count",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "images",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isACloset",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Client",
      "kind": "LinkedField",
      "name": "client",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "type": "ProductCollection",
  "abstractKey": null
};
})();

(node/*: any*/).hash = "d79447b162ef09a4e789af744d08990d";

module.exports = ((node/*: any*/)/*: Fragment<
  productCollectionCard_productCollection$fragmentType,
  productCollectionCard_productCollection$data,
>*/);
