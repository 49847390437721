import { commitMutation, graphql } from "react-relay"
import { complete } from "../helper.js"

const mutation = graphql`
	mutation posterUrlMutation($input: PosterUrlInput!) {
		posterUrl(input: $input) {
			shopcast {
				posterUrl
			}
		}
	}
`

const commit = (env, file, shopcastId, storeId, onCompleted, onError) =>
	commitMutation(env, {
		mutation,
		variables: { input: { imageName: file.name, shopcastId } },
		uploadables: {
			file,
		},
		updater: (storeProxy, data) => {
			const shopcastProxy = storeProxy.get(shopcastId)
			shopcastProxy.setValue(data.posterUrl.shopcast.posterUrl, "posterUrl")
		},
		onCompleted: complete(onCompleted, onError),
		onError: (e) => complete(onCompleted, [e]),
	})

export default { commit }
