import { commitMutation, graphql } from "react-relay"
import { complete } from "../helper.js"

const mutation = graphql`
	mutation toggleRecommendedLookbookMutation (
		$input: ToggleRecommendedLookbookInput!
	) {
		toggleRecommendedLookbook(input: $input) {
			output
		}
	}
`

const commit = (env, lookbookId, onSuccess, onError) => commitMutation(env, {
	mutation,
	variables: { input: { lookbookId } },
	optimisticResponse: { toggleRecommendedLookbook: { output: "" } },
	onError: () => complete(null, onError),
	updater: (storeProxy, data) => {
		if (data.toggleRecommendedLookbook) {
			const lookbookProxy = storeProxy.get(lookbookId)
			const wasRecommended = lookbookProxy.getValue("isRecommended")
			lookbookProxy.setValue(!wasRecommended, "isRecommended")
			onSuccess()
		}
	},
})

export default { commit }
