/**
 * @generated SignedSource<<40efe81a3de4230c075904a9ee0cb75b>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Mutation } from 'relay-runtime';
export type ResetPasswordInput = {|
  clientMutationId?: ?string,
  password: string,
  token: string,
|};
export type resetPasswordMutation$variables = {|
  input: ResetPasswordInput,
|};
export type resetPasswordMutation$data = {|
  +resetPassword: ?{|
    +name: ?string,
  |},
|};
export type resetPasswordMutation = {|
  response: resetPasswordMutation$data,
  variables: resetPasswordMutation$variables,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "ResetPasswordPayload",
    "kind": "LinkedField",
    "name": "resetPassword",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "resetPasswordMutation",
    "selections": (v1/*: any*/),
    "type": "ShopshareMutationRoot",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "resetPasswordMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "86e973b2e526e8454a550e58381d983d",
    "id": null,
    "metadata": {},
    "name": "resetPasswordMutation",
    "operationKind": "mutation",
    "text": "mutation resetPasswordMutation(\n  $input: ResetPasswordInput!\n) {\n  resetPassword(input: $input) {\n    name\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "125164991eea11e607e198abda67abcb";

module.exports = ((node/*: any*/)/*: Mutation<
  resetPasswordMutation$variables,
  resetPasswordMutation$data,
>*/);
