import React, { Component } from "react"
import InfiniteScroll from "react-infinite-scroller"
import LookbookCard from "components/cards/lookbook-card/lookbook-card"
import ReportShopcastModal from "components/modals/report-shopcast/report-shopcast"
import LoadingDots from "components/loading-dots/loading-dots"
import grids from "shared/styles/components/grids.css"
import empty from "shared/styles/components/empty.css"
import QueryLoadMore from "../profile/query-load-more/query-load-more"

export default class ListOfLookbooks extends Component {
	constructor(props) {
		super(props)
		this.showReportModal = this.showReportModal.bind(this)
		this.handleLoadMore = this.handleLoadMore.bind(this)
		this.handleInfiniteLoad = this.handleInfiniteLoad.bind(this)

		this.state = {
			currentLookbook: null,
			showReportModal: false,
			loading: false,
		}
	}

	handleLoadMore(itemCount) {
		const { relay } = this.props
		this.setState({ loading: true })
		relay.loadMore(
			itemCount || 12,
			() => {
				this.setState({ loading: false })
			},
			{
				force: true,
			}
		)
	}

	handleInfiniteLoad() {
		const { relay } = this.props
		relay.loadMore(12)
	}

	showReportModal(show, lookbook) {
		this.setState({ showReportModal: show, currentLookbook: lookbook })
	}

	renderLookbooks(edges) {
		const {
			currentUser,
			enableHeader,
			relay,
			disableSubdomain,
			clientId,
		} = this.props
		return edges.map((edge) => (
			<div className={grids.col3} key={edge.node.__id}>
				<LookbookCard
					lookbook={edge.node}
					disableSubdomain={disableSubdomain}
					showActions={false}
					currentUser={currentUser}
					enableHeader={enableHeader}
					showReportModal={this.showReportModal}
					refetch={relay.refetchConnection}
					clientId={clientId}
				/>
			</div>
		))
	}

	renderContent() {
		const { props, state } = this
		const { lookbooks, relay, removeInfiniteScroll, breakpoints } = props
		const { loading } = state
		if (!lookbooks || lookbooks.edges.length === 0) {
			return (
				<div className={grids.span}>
					<div className={empty.simple}>No Shopboards found.</div>
				</div>
			)
		}
		return (
			<div className={grids.container}>
				{!removeInfiniteScroll ? (
					<InfiniteScroll
						loadMore={this.handleInfiniteLoad}
						hasMore={relay.hasMore()}
						loader={
							<div className={grids.loadMore} key="loading">
								<LoadingDots />
							</div>
						}
						pageStart={0}
					>
						<div className={grids.rowMax4}>
							{this.renderLookbooks(lookbooks.edges)}
						</div>
					</InfiniteScroll>
				) : (
					<QueryLoadMore
						data={lookbooks.edges}
						hasMore={relay.hasMore()}
						loadMore={this.handleLoadMore}
						breakpoints={breakpoints}
					>
						{(data, itemCount) => (
							<>
								<div
									style={{
										"--grid-column-count": itemCount,
									}}
									className={grids.rowQueryLoad}
								>
									{this.renderLookbooks(data)}
								</div>
								{relay.hasMore() && (
									<div className={grids.loadMore}>
										<button
											type="button"
											className={grids.loadMoreBtn}
											disabled={loading}
											onClick={() =>
												this.handleLoadMore(itemCount * 2)
											}
										>
											{loading ? (
												<LoadingDots color="var(--gray300)" />
											) : (
												"Show more"
											)}
										</button>
									</div>
								)}
							</>
						)}
					</QueryLoadMore>
				)}
			</div>
		)
	}

	render() {
		const { state, props } = this
		return (
			<>
				{this.renderContent()}
				<ReportShopcastModal
					item={state.currentLookbook}
					closeModal={() => {
						this.setState({ showReportModal: false })
					}}
					showEmbedModal={state.showReportModal}
					relay={props.relay}
					storeId={props.storeId}
				/>
			</>
		)
	}
}
