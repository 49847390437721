/**
 * @generated SignedSource<<82420b9521af303d1465a6cdc2343e21>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentType } from "relay-runtime";
declare export opaque type lookbook_store$fragmentType: FragmentType;
export type lookbook_store$data = {|
  +currentUser: ?{|
    +adminAccess: ?string,
    +id: string,
    +lookbookTemplatesCategories: ?$ReadOnlyArray<?string>,
    +lookbookUploadsCategoryList: ?$ReadOnlyArray<?{|
      +id: string,
      +is_default: ?boolean,
      +title: ?string,
      +user: ?{|
        +id: string,
      |},
    |}>,
    +role: ?string,
    +shapes: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +collection: ?string,
          +id: string,
          +path: ?string,
          +viewBox: ?string,
        |},
      |}>,
    |},
    +shapesCollections: ?$ReadOnlyArray<?string>,
    +shopcastContainer: ?{|
      +folder: ?{|
        +folders: ?{|
          +edges: ?$ReadOnlyArray<?{|
            +node: ?{|
              +id: string,
              +name: ?string,
            |},
          |}>,
        |},
        +id: string,
        +name: ?string,
      |},
    |},
    +shopcasts: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +title: ?string,
        |},
      |}>,
    |},
    +userClients: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +email: ?string,
          +fullname: ?string,
          +id: string,
        |},
      |}>,
    |},
  |},
  +lookbook: ?{|
    +description: ?string,
    +folder: ?{|
      +id: string,
      +name: ?string,
    |},
    +id: string,
    +isTemplate: ?boolean,
    +lookbookBoards: ?$ReadOnlyArray<?{|
      +background: ?string,
      +dataJson: ?string,
      +height: ?number,
      +id: ?string,
      +lookbookId: string,
      +mp4Url: ?string,
      +pageNo: ?number,
      +screenWidth: ?string,
      +shapes: ?{|
        +edges: ?$ReadOnlyArray<?{|
          +node: ?{|
            +collection: ?string,
            +id: string,
            +path: ?string,
            +viewBox: ?string,
          |},
        |}>,
      |},
      +showBoard: ?boolean,
      +showVideo: ?boolean,
      +subtitle: ?string,
      +title: ?string,
      +url: ?string,
      +videoUrl: ?string,
      +width: ?number,
    |}>,
    +lookbookTemplate: ?{|
      +categories: ?$ReadOnlyArray<?string>,
      +id: string,
      +isGlobal: ?boolean,
    |},
    +published_at: ?string,
    +title: ?string,
    +user: ?{|
      +fullname: ?string,
    |},
  |},
  +$fragmentType: lookbook_store$fragmentType,
|};
export type lookbook_store$key = {
  +$data?: lookbook_store$data,
  +$fragmentSpreads: lookbook_store$fragmentType,
  ...
};
*/

var node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "fullname",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = [
  (v0/*: any*/),
  (v3/*: any*/)
],
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "ShapeConnectionConnection",
  "kind": "LinkedField",
  "name": "shapes",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ShapeConnectionEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Shape",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "viewBox",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "path",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "collection",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "id"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "teamId"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "lookbook_store",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "currentUser",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "adminAccess",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "role",
          "storageKey": null
        },
        {
          "alias": null,
          "args": [
            {
              "kind": "Variable",
              "name": "teamId",
              "variableName": "teamId"
            }
          ],
          "concreteType": "ClientConnectionConnection",
          "kind": "LinkedField",
          "name": "userClients",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ClientConnectionEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Client",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    (v1/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "email",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "ShopcastConnection",
          "kind": "LinkedField",
          "name": "shopcasts",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ShopcastEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Shopcast",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    (v2/*: any*/)
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "ShopcastContainer",
          "kind": "LinkedField",
          "name": "shopcastContainer",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ShopcastFolder",
              "kind": "LinkedField",
              "name": "folder",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                (v3/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "ShopcastFolderShopcastFolderConnection",
                  "kind": "LinkedField",
                  "name": "folders",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "ShopcastFolderShopcastFolderEdge",
                      "kind": "LinkedField",
                      "name": "edges",
                      "plural": true,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "ShopcastFolder",
                          "kind": "LinkedField",
                          "name": "node",
                          "plural": false,
                          "selections": (v4/*: any*/),
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "UploadsCategory",
          "kind": "LinkedField",
          "name": "lookbookUploadsCategoryList",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            (v2/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "User",
              "kind": "LinkedField",
              "name": "user",
              "plural": false,
              "selections": [
                (v0/*: any*/)
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "is_default",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "shapesCollections",
          "storageKey": null
        },
        (v5/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "lookbookTemplatesCategories",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "id",
          "variableName": "id"
        }
      ],
      "concreteType": "Lookbook",
      "kind": "LinkedField",
      "name": "lookbook",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v2/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "description",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "published_at",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "LookbookBoard",
          "kind": "LinkedField",
          "name": "lookbookBoards",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "lookbookId",
              "storageKey": null
            },
            (v2/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "url",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "dataJson",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "screenWidth",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "width",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "height",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "background",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "pageNo",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "showBoard",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "showVideo",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "videoUrl",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "mp4Url",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "subtitle",
              "storageKey": null
            },
            (v5/*: any*/)
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "ShopcastFolder",
          "kind": "LinkedField",
          "name": "folder",
          "plural": false,
          "selections": (v4/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "User",
          "kind": "LinkedField",
          "name": "user",
          "plural": false,
          "selections": [
            (v1/*: any*/)
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isTemplate",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "LookbookTemplate",
          "kind": "LinkedField",
          "name": "lookbookTemplate",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "isGlobal",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "categories",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Store",
  "abstractKey": null
};
})();

(node/*: any*/).hash = "916841a9edd1564855de74c719511601";

module.exports = ((node/*: any*/)/*: Fragment<
  lookbook_store$fragmentType,
  lookbook_store$data,
>*/);
