import React from "react"
import Logo from "shared/assets/logo.svg"
import styles from "./footer-channel.css"

export default function FooterChannel() {
	return (
		<footer className={styles.root}>
			<div className={styles.content}>
				<hr className={styles.hr} />
				<div className={styles.meta}>
					<a className={styles.poweredby} href="https://shopshare.tv">
						<Logo className={styles.logo} />
					</a>
					<p className={styles.disclaimer}>
						Affiliate commissions may be receive on clicks and/or
						purchases made through links on this site.
					</p>
				</div>
			</div>
		</footer>
	)
}
