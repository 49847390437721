/**
 * @generated SignedSource<<947ecbb895bb28d2b3bfb4d6080ba1cf>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Mutation } from 'relay-runtime';
export type userEnumType = "CLIENT" | "RETAILER" | "STYLIST" | "%future added value";
export type SigninInput = {|
  clientMutationId?: ?string,
  password: string,
  username: string,
|};
export type signinMutation$variables = {|
  input: SigninInput,
|};
export type signinMutation$data = {|
  +signin: ?{|
    +user: ?{|
      +email: ?string,
      +fullname: ?string,
      +id: string,
      +teams: ?$ReadOnlyArray<?{|
        +id: string,
      |}>,
      +token: ?string,
      +type: ?userEnumType,
      +userPlan: ?{|
        +id: ?string,
      |},
      +user_hash: ?string,
      +username: ?string,
    |},
  |},
|};
export type signinMutation = {|
  response: signinMutation$data,
  variables: signinMutation$variables,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  (v1/*: any*/)
],
v3 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "SigninPayload",
    "kind": "LinkedField",
    "name": "signin",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "user",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "token",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "username",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "fullname",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "email",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "user_hash",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "type",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PricingPlan",
            "kind": "LinkedField",
            "name": "userPlan",
            "plural": false,
            "selections": (v2/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "team",
            "kind": "LinkedField",
            "name": "teams",
            "plural": true,
            "selections": (v2/*: any*/),
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "signinMutation",
    "selections": (v3/*: any*/),
    "type": "ShopshareMutationRoot",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "signinMutation",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "49bb9414a347a2b1851cca0854088c20",
    "id": null,
    "metadata": {},
    "name": "signinMutation",
    "operationKind": "mutation",
    "text": "mutation signinMutation(\n  $input: SigninInput!\n) {\n  signin(input: $input) {\n    user {\n      id\n      token\n      username\n      fullname\n      email\n      user_hash\n      type\n      userPlan {\n        id\n      }\n      teams {\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "19041aba467d1972e59ae97485af6a28";

module.exports = ((node/*: any*/)/*: Mutation<
  signinMutation$variables,
  signinMutation$data,
>*/);
