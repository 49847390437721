/**
 * @generated SignedSource<<1895c068cb97c86d5fd3e7dd3925e512>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { shopcastCard_currentUser$fragmentType } from "./../../../components/cards/shopcast-card/__generated__/shopcastCard_currentUser.graphql";
import type { shopcastCard_shopcast$fragmentType } from "./../../../components/cards/shopcast-card/__generated__/shopcastCard_shopcast.graphql";
import type { FragmentType } from "relay-runtime";
declare export opaque type profileView_store$fragmentType: FragmentType;
export type profileView_store$data = {|
  +currentUser: ?{|
    +email: ?string,
    +fullname: ?string,
    +id: string,
    +profilePhoto: ?string,
    +$fragmentSpreads: shopcastCard_currentUser$fragmentType,
  |},
  +id: string,
  +profile: ?{|
    +bannerPhoto: ?string,
    +bio: ?string,
    +canEdit: ?boolean,
    +createdAt: ?string,
    +customDomain: ?string,
    +displayAvatar: ?string,
    +displayName: ?string,
    +files: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
        |},
      |}>,
    |},
    +fullname: ?string,
    +id: string,
    +isFollowing: ?boolean,
    +isRetailer: ?boolean,
    +latestShopcasts: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +$fragmentSpreads: shopcastCard_shopcast$fragmentType,
        |},
      |}>,
    |},
    +longBio: ?string,
    +lookbooks: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
        |},
      |}>,
    |},
    +mostLikedShopcasts: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +$fragmentSpreads: shopcastCard_shopcast$fragmentType,
        |},
      |}>,
    |},
    +mostViewedShopcasts: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +$fragmentSpreads: shopcastCard_shopcast$fragmentType,
        |},
      |}>,
    |},
    +previewConfig: ?{|
      +showAboutSection: ?boolean,
      +showAvatar: ?boolean,
      +showBanner: ?boolean,
      +showCollectionsSection: ?boolean,
      +showContact: ?boolean,
      +showDisplayName: ?boolean,
      +showFacebookLink: ?boolean,
      +showFilesSection: ?boolean,
      +showInstagramLink: ?boolean,
      +showPinterestLink: ?boolean,
      +showShopboardsSection: ?boolean,
      +showShopcastsSection: ?boolean,
      +showShortBio: ?boolean,
      +showTiktokLink: ?boolean,
      +showTwitterLink: ?boolean,
      +showWebsiteLink: ?boolean,
      +showYoutubeLink: ?boolean,
    |},
    +productCollections: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +title: ?string,
        |},
      |}>,
    |},
    +products: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
        |},
      |}>,
    |},
    +profilePhoto: ?string,
    +shopcasts: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
        |},
      |}>,
    |},
    +socialLinks: ?{|
      +facebookUrl: ?string,
      +instagramUsername: ?string,
      +pinterestUrl: ?string,
      +tiktokUrl: ?string,
      +twitterUsername: ?string,
      +website: ?string,
      +youtubeUrl: ?string,
    |},
    +tabsOrder: ?$ReadOnlyArray<?string>,
    +username: ?string,
  |},
  +$fragmentType: profileView_store$fragmentType,
|};
export type profileView_store$key = {
  +$data?: profileView_store$data,
  +$fragmentSpreads: profileView_store$fragmentType,
  ...
};
*/

var node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "fullname",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "profilePhoto",
  "storageKey": null
},
v3 = {
  "kind": "Literal",
  "name": "first",
  "value": 10000
},
v4 = {
  "kind": "Literal",
  "name": "isPublished",
  "value": true
},
v5 = [
  (v3/*: any*/),
  (v4/*: any*/)
],
v6 = [
  (v0/*: any*/)
],
v7 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "ShopcastEdge",
    "kind": "LinkedField",
    "name": "edges",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Shopcast",
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "shopcastCard_shopcast"
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "name"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "profileView_store",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "username",
          "variableName": "name"
        }
      ],
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "profile",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "displayName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "username",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "customDomain",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isRetailer",
          "storageKey": null
        },
        (v2/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "displayAvatar",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "bannerPhoto",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "bio",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "longBio",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "createdAt",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "canEdit",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isFollowing",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "tabsOrder",
          "storageKey": null
        },
        {
          "alias": null,
          "args": (v5/*: any*/),
          "concreteType": "ShopcastConnection",
          "kind": "LinkedField",
          "name": "shopcasts",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ShopcastEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Shopcast",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": (v6/*: any*/),
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": "shopcasts(first:10000,isPublished:true)"
        },
        {
          "alias": null,
          "args": (v5/*: any*/),
          "concreteType": "LookbookConnection",
          "kind": "LinkedField",
          "name": "lookbooks",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "LookbookEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Lookbook",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": (v6/*: any*/),
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": "lookbooks(first:10000,isPublished:true)"
        },
        {
          "alias": null,
          "args": (v5/*: any*/),
          "concreteType": "ShopshareFileConnection",
          "kind": "LinkedField",
          "name": "files",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ShopshareFileEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "ShopshareFile",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": (v6/*: any*/),
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": "files(first:10000,isPublished:true)"
        },
        {
          "alias": null,
          "args": [
            (v3/*: any*/),
            {
              "kind": "Literal",
              "name": "isDefault",
              "value": false
            },
            (v4/*: any*/)
          ],
          "concreteType": "ProductCollectionConnection",
          "kind": "LinkedField",
          "name": "productCollections",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ProductCollectionEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "ProductCollection",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "title",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": "productCollections(first:10000,isDefault:false,isPublished:true)"
        },
        {
          "alias": null,
          "args": [
            {
              "kind": "Literal",
              "name": "first",
              "value": 150
            }
          ],
          "concreteType": "ProductConnection",
          "kind": "LinkedField",
          "name": "products",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ProductEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "ShopcastProduct",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": (v6/*: any*/),
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": "products(first:150)"
        },
        {
          "alias": null,
          "args": [
            {
              "kind": "Literal",
              "name": "first",
              "value": 5
            }
          ],
          "concreteType": "ShopcastConnection",
          "kind": "LinkedField",
          "name": "mostLikedShopcasts",
          "plural": false,
          "selections": (v7/*: any*/),
          "storageKey": "mostLikedShopcasts(first:5)"
        },
        {
          "alias": null,
          "args": [
            {
              "kind": "Literal",
              "name": "first",
              "value": 10
            }
          ],
          "concreteType": "ShopcastConnection",
          "kind": "LinkedField",
          "name": "mostViewedShopcasts",
          "plural": false,
          "selections": (v7/*: any*/),
          "storageKey": "mostViewedShopcasts(first:10)"
        },
        {
          "alias": null,
          "args": [
            {
              "kind": "Literal",
              "name": "first",
              "value": 20
            }
          ],
          "concreteType": "ShopcastConnection",
          "kind": "LinkedField",
          "name": "latestShopcasts",
          "plural": false,
          "selections": (v7/*: any*/),
          "storageKey": "latestShopcasts(first:20)"
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "UserSocialLinks",
          "kind": "LinkedField",
          "name": "socialLinks",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "website",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "instagramUsername",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "facebookUrl",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "twitterUsername",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "youtubeUrl",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "pinterestUrl",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "tiktokUrl",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "UserPreviewConfig",
          "kind": "LinkedField",
          "name": "previewConfig",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "showBanner",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "showAvatar",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "showContact",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "showDisplayName",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "showShortBio",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "showWebsiteLink",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "showInstagramLink",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "showFacebookLink",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "showTwitterLink",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "showYoutubeLink",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "showPinterestLink",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "showTiktokLink",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "showShopcastsSection",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "showShopboardsSection",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "showFilesSection",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "showCollectionsSection",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "showAboutSection",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "currentUser",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "email",
          "storageKey": null
        },
        (v1/*: any*/),
        (v2/*: any*/),
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "shopcastCard_currentUser"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Store",
  "abstractKey": null
};
})();

(node/*: any*/).hash = "66958d226faeed78e9e41b9d79923600";

module.exports = ((node/*: any*/)/*: Fragment<
  profileView_store$fragmentType,
  profileView_store$data,
>*/);
