
import { commitMutation, graphql } from "react-relay"
import { complete } from "../helper"

const mutation = graphql`
	mutation updateLookbookBackgroundMutation(
		$input: CreateUpdateBackgroundInput!,
	) {
		createUpdateBackground(input: $input) {
			background{
        id
        canEdit
        title
        type
        category
        background	
        preSignedUrl{
          signedRequest,
          url
        }
      }
		}
	}
`

const commit = (env, data, args, onError, onCompleted) => commitMutation(env, {
  mutation,
  variables: { input: { id: data?.id, title: data.title, type: data.type, category: data.category, background: data.background } },
  onError: complete(onCompleted, onError),
  onCompleted: complete(onCompleted, onError),
})

export default { commit }
