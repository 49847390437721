/**
 * @generated SignedSource<<8e3356f63fe8f3dfe4c4d1bee9ca91f4>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Mutation } from 'relay-runtime';
export type AddCountryToRakutenSiteInput = {|
  clientMutationId?: ?string,
  country?: ?string,
  site?: ?string,
|};
export type addCountryToRakutenSiteMutation$variables = {|
  input: AddCountryToRakutenSiteInput,
|};
export type addCountryToRakutenSiteMutation$data = {|
  +addCountryToRakutenSite: ?{|
    +countries: ?$ReadOnlyArray<?{|
      +code: ?string,
      +id: string,
      +name: ?string,
    |}>,
    +store: ?{|
      +rakutenSiteRegions: ?{|
        +edges: ?$ReadOnlyArray<?{|
          +node: ?{|
            +id: string,
            +name: ?string,
          |},
        |}>,
      |},
    |},
  |},
|};
export type addCountryToRakutenSiteMutation = {|
  response: addCountryToRakutenSiteMutation$data,
  variables: addCountryToRakutenSiteMutation$variables,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "Country",
  "kind": "LinkedField",
  "name": "countries",
  "plural": true,
  "selections": [
    (v2/*: any*/),
    (v3/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "code",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "StoreCountryConnection",
  "kind": "LinkedField",
  "name": "rakutenSiteRegions",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "StoreCountryEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Country",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v2/*: any*/),
            (v3/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "addCountryToRakutenSiteMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "AddCountryToRakutenSitePayload",
        "kind": "LinkedField",
        "name": "addCountryToRakutenSite",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Store",
            "kind": "LinkedField",
            "name": "store",
            "plural": false,
            "selections": [
              (v5/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "ShopshareMutationRoot",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "addCountryToRakutenSiteMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "AddCountryToRakutenSitePayload",
        "kind": "LinkedField",
        "name": "addCountryToRakutenSite",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Store",
            "kind": "LinkedField",
            "name": "store",
            "plural": false,
            "selections": [
              (v5/*: any*/),
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "f0448b2d8f07f956602ec0fcce6e3328",
    "id": null,
    "metadata": {},
    "name": "addCountryToRakutenSiteMutation",
    "operationKind": "mutation",
    "text": "mutation addCountryToRakutenSiteMutation(\n  $input: AddCountryToRakutenSiteInput!\n) {\n  addCountryToRakutenSite(input: $input) {\n    countries {\n      name\n      id\n      code\n    }\n    store {\n      rakutenSiteRegions {\n        edges {\n          node {\n            name\n            id\n          }\n        }\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "bad953f6ef44c63141f3af0a6faa5380";

module.exports = ((node/*: any*/)/*: Mutation<
  addCountryToRakutenSiteMutation$variables,
  addCountryToRakutenSiteMutation$data,
>*/);
