/* eslint-disable import/no-webpack-loader-syntax */
/* eslint-disable import/no-unresolved */

import React from "react"
import { createRoot } from 'react-dom/client'; 	
import TagManager from "react-gtm-module"
import Bugsnag from "@bugsnag/js"
import BugsnagPluginReact from "@bugsnag/plugin-react"
import mixpanel from "mixpanel-browser"
import { jwtDecode } from "jwt-decode";
import routes from "./routes.jsx"
import {
	registerServiceWorker,
	unregisterServiceWorker,
} from "./serviceWorkerRegistration"

// eslint-disable-next-line import/no-webpack-loader-syntax
import "!style-loader!css-loader!react-toastify/dist/ReactToastify.min.css"
import "!style-loader!css-loader!slick-carousel/slick/slick.css"
import "!style-loader!css-loader!slick-carousel/slick/slick-theme.css"
import "!style-loader!css-loader!react-datepicker/dist/react-datepicker.css"
import "!style-loader!css-loader!react-datepicker/dist/react-datepicker-cssmodules.css"
import "!style-loader!css-loader!react-tooltip/dist/react-tooltip.css"
import "!style-loader!css-loader!yet-another-react-lightbox/styles.css";
import "!style-loader!css-loader!yet-another-react-lightbox/plugins/thumbnails.css";

import "./shared/styles/global.css"
import { bugsnagApiKey } from "./constants.js"
import cookieService from "./services/cookie-service.js"
import { getUsername } from "./services/custom-domain-service.js"

window.environment = process.env.NODE_ENV
window.IOS = window.navigator.appVersion.indexOf("Mac") !== -1

Bugsnag.start({
	apiKey: bugsnagApiKey,
	plugins: [new BugsnagPluginReact()],
	onError: () => process.env.NODE_ENV === "production",
})

const ErrorBoundary = Bugsnag.getPlugin("react").createErrorBoundary(React)

window.getUserId = () => {
	const tokenCookie = cookieService.getToken()
	const tokenData = tokenCookie ? jwtDecode(tokenCookie) : null
	return tokenData?.userId || 0
}

const tagManagerArgs = {
	gtmId: "GTM-KSZX47C",
	auth:
		process.env.NODE_ENV === "production"
			? "N3yFDnpsDGBVcgswRASqRw"
			: "jW8s4mI12qGLVef7nMxNdg",
	preview: process.env.NODE_ENV === "production" ? "env-2" : "env-18",
}
TagManager.initialize(tagManagerArgs)

mixpanel.init(
	process.env.NODE_ENV === "production"
		? "58be6532aa4f9a2ee0f8c70e26784c24"
		: "acc1cefaaed1a7e1089ad50871b6310d"
)

const mountNode = document.getElementById("app")
const root = createRoot(mountNode);
root.render(<ErrorBoundary>{routes.routes}</ErrorBoundary>);

const username = getUsername(window.location.host)
const isProduction = process.env.NODE_ENV === "production"
if (isProduction && !username) registerServiceWorker()
else unregisterServiceWorker()
