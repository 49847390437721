// eslint-disable-next-line max-classes-per-file
export class Lookbook {
	id = null

	lookbookId = null

	title = null

	url = null

	dataJson = []

	screenWidth = 0

	width = 0

	height = 0

	background = null

	pageNo = 0

	showBoard = false
}

export class Item {
	id = ""

	title = ""

	transparentLevel = 0

	imgScaleX = 1

	x = 0

	y = 0

	width = 0

	offsetX = 0

	offsetY = 0

	rotation = 0

	scaleX = 1

	scaleY = 1

	pixel = []

	cropPath = []

	image = null

	imageUrl = null

	textProp = null

	shapeProp = null

	category = null

	visible = true

	shopcastProductID = null

	url = null

	lock = false

	opacity = 1

	isDroppableItem = false
}

export class TextProps {
	fontSize = 36

	text = "Add Text Here."

	lineHeight = 1.2

	fontStyle = "normal"

	fill = "#000000"

	fontFamily = "Inter"

	align = "left"
}

export class ShapeProps {
	svg = ""

	viewBoxWidth = 256

	viewBoxHeight = 256

	borderColor = "#000000"
	
	borderWidth = 0

	fill = "#000000"

	fillTransparentLevel = 100
}

export const fontFamilyList = [
	{
		category: "San Serif",
		list: [
			"Alegreya Sans",
			"Archivo Narrow",
			"Cabin",
			"Chivo",
			"Fira Sans",
			"Galaxie Polaris",
			"Inter",
			"Lato",
			"Libre Franklin",
			"Manrope",
			"Montserrat",
			"Open Sans",
			"Oswald",
			"Oxygen",
			"Poppins",
			"Proza Libre",
			"PT Sans",
			"Raleway",
			"Roboto",
			"Source Sans Pro",
			"Space Grotesk",
			"Work Sans",
		],
	},
	{
		category: "Serif",
		list: [
			"Alegreya",
			"Cardo",
			"Cinzel",
			"Cormorant",
			"Eczar",
			"Inknut Antiqua",
			"Libre Baskerville",
			"Lora",
			"Merriweather",
			"Neuton",
			"Orelega One",
			"Playfair Display",
			"PT Serif",
			"Roboto Slab",
			"Source Serif Pro",
			"Spectral",
		],
	},
	{
		category: "Display",
		list: ["Bebas Neue", "BioRhyme", "Lobster"],
	},
	{
		category: "Handwriting",
		list: [
			"Amalfi Coast",
			"Amatic SC",
			"Hurricane",
			"Just Lovely",
			"Sacramento",
			"Shadows Into Light",
			"Yellowtail",
		],
	},
	{
		category: "Monospace",
		list: ["Anonymous Pro", "Inconsolata", "Space Mono"],
	},
]
