import React from "react"
import { PlusIcon, MinusIcon } from "@radix-ui/react-icons"
import styles from "./owned-item-button.css"

function OwnedItemButton({ onClick, isOwned }) {
  return <button
		className={styles.root}
		type="button"
		data-tooltip-id="tooltip"
		data-tooltip-content={isOwned ? "Add to Closet" : "Remove from Closet"}
		onClick={onClick}
	>
		{isOwned ? (
			<PlusIcon className={styles.icon} />
		) : (
			<MinusIcon className={styles.icon} />
		)}
	</button>
}

export default OwnedItemButton
