/**
 * @generated SignedSource<<d09a9eeffc94e593d5a544bc71e80b2a>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Query } from 'relay-runtime';
export type socialServiceQuery$variables = {||};
export type socialServiceQuery$data = {|
  +store: ?{|
    +currentUser: ?{|
      +id: string,
      +socialMedia: ?$ReadOnlyArray<?{|
        +isConnected: ?boolean,
        +platform: ?string,
      |}>,
    |},
    +id: string,
  |},
|};
export type socialServiceQuery = {|
  response: socialServiceQuery$data,
  variables: socialServiceQuery$variables,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "platform",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isConnected",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "socialServiceQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Store",
        "kind": "LinkedField",
        "name": "store",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "currentUser",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "UserSocialMedia",
                "kind": "LinkedField",
                "name": "socialMedia",
                "plural": true,
                "selections": [
                  (v1/*: any*/),
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "ShopshareQueryRoot",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "socialServiceQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Store",
        "kind": "LinkedField",
        "name": "store",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "currentUser",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "UserSocialMedia",
                "kind": "LinkedField",
                "name": "socialMedia",
                "plural": true,
                "selections": [
                  (v1/*: any*/),
                  (v2/*: any*/),
                  (v0/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "34674b59b8e616225ba019edbb4c71dd",
    "id": null,
    "metadata": {},
    "name": "socialServiceQuery",
    "operationKind": "query",
    "text": "query socialServiceQuery {\n  store {\n    id\n    currentUser {\n      id\n      socialMedia {\n        platform\n        isConnected\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "0e929a9bab4a25e062b13a91351bce60";

module.exports = ((node/*: any*/)/*: Query<
  socialServiceQuery$variables,
  socialServiceQuery$data,
>*/);
