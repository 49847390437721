/**
 * @generated SignedSource<<241f8c7b14541c51c7154cddfbd19fb7>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Mutation } from 'relay-runtime';
export type ShareShopcastToFacebookInput = {|
  accessToken: string,
  clientMutationId?: ?string,
  description?: ?string,
  id: string,
  page: string,
|};
export type shareShopcastToFacebookMutation$variables = {|
  input: ShareShopcastToFacebookInput,
|};
export type shareShopcastToFacebookMutation$data = {|
  +shareShopcastToFacebook: ?{|
    +store: ?{|
      +id: string,
    |},
    +uploadId: ?string,
  |},
|};
export type shareShopcastToFacebookMutation = {|
  response: shareShopcastToFacebookMutation$data,
  variables: shareShopcastToFacebookMutation$variables,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "ShareShopcastToFacebookPayload",
    "kind": "LinkedField",
    "name": "shareShopcastToFacebook",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Store",
        "kind": "LinkedField",
        "name": "store",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "uploadId",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "shareShopcastToFacebookMutation",
    "selections": (v1/*: any*/),
    "type": "ShopshareMutationRoot",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "shareShopcastToFacebookMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "9d4ff37a85bffc106ac7233d6b67f93a",
    "id": null,
    "metadata": {},
    "name": "shareShopcastToFacebookMutation",
    "operationKind": "mutation",
    "text": "mutation shareShopcastToFacebookMutation(\n  $input: ShareShopcastToFacebookInput!\n) {\n  shareShopcastToFacebook(input: $input) {\n    store {\n      id\n    }\n    uploadId\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "c16edc854438b21b61847c630effae6e";

module.exports = ((node/*: any*/)/*: Mutation<
  shareShopcastToFacebookMutation$variables,
  shareShopcastToFacebookMutation$data,
>*/);
