/**
 * @generated SignedSource<<9cd52c06b707fd28a4465d76c5b4bd5b>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Mutation } from 'relay-runtime';
export type AdminDeleteUserInput = {|
  clientMutationId?: ?string,
  userId: string,
|};
export type adminDeleteUserMutation$variables = {|
  input: AdminDeleteUserInput,
|};
export type adminDeleteUserMutation$data = {|
  +adminDeleteUser: ?{|
    +store: ?{|
      +id: string,
    |},
  |},
|};
export type adminDeleteUserMutation = {|
  response: adminDeleteUserMutation$data,
  variables: adminDeleteUserMutation$variables,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "AdminDeleteUserPayload",
    "kind": "LinkedField",
    "name": "adminDeleteUser",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Store",
        "kind": "LinkedField",
        "name": "store",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "adminDeleteUserMutation",
    "selections": (v1/*: any*/),
    "type": "ShopshareMutationRoot",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "adminDeleteUserMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "46466d25dcb70428f2b5560b8b1595e2",
    "id": null,
    "metadata": {},
    "name": "adminDeleteUserMutation",
    "operationKind": "mutation",
    "text": "mutation adminDeleteUserMutation(\n  $input: AdminDeleteUserInput!\n) {\n  adminDeleteUser(input: $input) {\n    store {\n      id\n    }\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "599f80af4b366b84e74045d670ea7248";

module.exports = ((node/*: any*/)/*: Mutation<
  adminDeleteUserMutation$variables,
  adminDeleteUserMutation$data,
>*/);
