import { commitMutation, graphql } from "react-relay"
import { complete } from "../helper"

const mutation = graphql`
	mutation setPublishedCollectionMutation(
		$input: SetPublishedCollectionInput!
	) {
		setPublishedCollection(input: $input) {
			store {
				id
			}
			published_at
		}
	}
`

const commit = (env, data, onError, onCompleted) =>
	commitMutation(env, {
		mutation,
		variables: { input: data },
		updater: (storeProxy) => {
			const collectionProxy = storeProxy.get(data.id)
			if (data.isPublished !== undefined)
				collectionProxy.setValue(data.isPublished, "isPublished")
		},
		onError: complete(onCompleted, onError),
		onCompleted: complete(onCompleted, onError),
	})

export default { commit }
