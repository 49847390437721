import { commitMutation, graphql } from "react-relay"
import { complete } from "../helper"

const mutation = graphql`
	mutation updateClientMutation($input: UpdateClientInput!) {
		updateClient(input: $input) {
			store {
                id
            }
		}
	}
`

const commit = (env, { file, ...data }, onError, onCompleted) =>
	commitMutation(env, {
		mutation,
		variables: { input: data },
		uploadables: { file },
		onCompleted: complete(onCompleted, onError),
		onError: (e) => complete(null, [e]),
		updater: (storeProxy) => {
			const clientProxy = storeProxy.get(data.id)
			clientProxy.setValue(file ? URL.createObjectURL(file) : data.avatar, "avatar")
			clientProxy.setValue(data.fullname, "fullname")
			clientProxy.setValue(data.email, "email")
		},
	})

export default { commit }
