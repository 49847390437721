import React, { Component } from "react"
import { Link } from "react-router-dom"
import styles from "./header.css"

class HeaderLoggedOut extends Component {
	renderDesktop() {
		const { exploreTabs } = this.props
		return (
			<>
				<ul className={styles.navLeft}>
					{exploreTabs}
					{/* <li className={styles.navItem}>
						<Link
							className={styles.link}
							onClick={this.closeMenu}
							to="/pricing"
						>
							Pricing
						</Link>
					</li> */}
				</ul>
				<div className={styles.navActions}>
					<Link
						className={styles.signIn}
						onClick={this.closeMenu}
						to="/signin"
					>
						Sign In
					</Link>
					{/* <Link
						className={styles.signUp}
						onClick={this.closeMenu}
						to="/signup"
					>
						Get Started
					</Link> */}
				</div>
			</>
		)
	}

	renderMobile() {
		return (
			<>
				<div className={styles.mobilePanelBar} />
				<div className={styles.mobilePanelContent}>
					<div className={styles.menuNav}>
						<div className={styles.menuItem}>
							<Link
								className={styles.menuLink}
								onClick={this.closeMenu}
								to="/home"
							>
								Home
							</Link>
						</div>
						<div className={styles.menuItem}>
							<Link
								className={styles.menuLink}
								onClick={this.closeMenu}
								to="/about"
							>
								About
							</Link>
						</div>
						{/* <div className={styles.menuItem}>
							<Link
								className={styles.menuLink}
								onClick={this.closeMenu}
								to="/pricing"
							>
								Pricing
							</Link>
						</div> */}
						<div className={styles.menuItem}>
							<a
								className={styles.menuLink}
								href="mailto:emma@shopshare.tv?subject=ShopShare enquiry"
							>
								Contact
							</a>
						</div>
					</div>
					<div className={styles.menuFooter}>
						{/* <Link
							className={styles.menuSignUp}
							onClick={this.closeMenu}
							to="/signup"
						>
							Get Started
						</Link> */}
						<Link
							className={styles.menuSignIn}
							onClick={this.closeMenu}
							to="/signin"
						>
							Sign In
						</Link>
					</div>
				</div>
			</>
		)
	}

	render() {
		const { isMobile } = this.props
		return isMobile ? this.renderMobile() : this.renderDesktop()
	}
}

export default HeaderLoggedOut
