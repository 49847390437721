/**
 * @generated SignedSource<<a4de3226c6a9099e892df3ce807a2a2a>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Query } from 'relay-runtime';
import type { pricing_store$fragmentType } from "./pricing_store.graphql";
export type pricingQuery$variables = {|
  ipCurrency?: ?boolean,
  teamId?: ?string,
|};
export type pricingQuery$data = {|
  +store: ?{|
    +$fragmentSpreads: pricing_store$fragmentType,
  |},
|};
export type pricingQuery = {|
  response: pricingQuery$data,
  variables: pricingQuery$variables,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "ipCurrency"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "teamId"
  }
],
v1 = {
  "kind": "Variable",
  "name": "ipCurrency",
  "variableName": "ipCurrency"
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "amount",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "currency",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "nickname",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "monthly",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "descriptionList",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "fullname",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "pricingQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Store",
        "kind": "LinkedField",
        "name": "store",
        "plural": false,
        "selections": [
          {
            "args": [
              (v1/*: any*/),
              {
                "kind": "Variable",
                "name": "teamId",
                "variableName": "teamId"
              }
            ],
            "kind": "FragmentSpread",
            "name": "pricing_store"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "ShopshareQueryRoot",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "pricingQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Store",
        "kind": "LinkedField",
        "name": "store",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": [
              (v1/*: any*/)
            ],
            "concreteType": "PricingPlan",
            "kind": "LinkedField",
            "name": "pricingPlans",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "description",
                "storageKey": null
              },
              (v7/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "NameValue",
                "kind": "LinkedField",
                "name": "metadata",
                "plural": true,
                "selections": [
                  (v8/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "value",
                    "storageKey": null
                  },
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "product",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": [
              {
                "kind": "Variable",
                "name": "id",
                "variableName": "teamId"
              }
            ],
            "concreteType": "team",
            "kind": "LinkedField",
            "name": "team",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "User",
                "kind": "LinkedField",
                "name": "user",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "currentUser",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "PricingPlan",
                "kind": "LinkedField",
                "name": "userPlan",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "status",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hasSources",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "trialUsed",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "lastUnpaidInvoice",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Referral",
                "kind": "LinkedField",
                "name": "referredBy",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Campaign",
                    "kind": "LinkedField",
                    "name": "campaign",
                    "plural": false,
                    "selections": [
                      (v8/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "monthlyCoupon",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "annualCoupon",
                        "storageKey": null
                      },
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "ddf679df59e77cbd769c58c638a7e259",
    "id": null,
    "metadata": {},
    "name": "pricingQuery",
    "operationKind": "query",
    "text": "query pricingQuery(\n  $ipCurrency: Boolean\n  $teamId: String\n) {\n  store {\n    ...pricing_store_3T6mAC\n    id\n  }\n}\n\nfragment pricing_store_3T6mAC on Store {\n  id\n  pricingPlans(ipCurrency: $ipCurrency) {\n    id\n    amount\n    currency\n    nickname\n    monthly\n    description\n    descriptionList\n    metadata {\n      name\n      value\n      id\n    }\n    product\n  }\n  team(id: $teamId) {\n    id\n    user {\n      id\n      fullname\n      email\n    }\n  }\n  currentUser {\n    id\n    fullname\n    email\n    userPlan {\n      id\n      amount\n      currency\n      nickname\n      monthly\n      descriptionList\n      status\n      hasSources\n    }\n    trialUsed\n    lastUnpaidInvoice\n    referredBy {\n      campaign {\n        name\n        monthlyCoupon\n        annualCoupon\n        id\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "f73d5dd92a55d37c1166f1da73e04108";

module.exports = ((node/*: any*/)/*: Query<
  pricingQuery$variables,
  pricingQuery$data,
>*/);
