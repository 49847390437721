import { commitMutation, graphql } from "react-relay"
import { complete } from "../helper.js"

const mutation = graphql`
	mutation setFolderPreviewConfigMutation($input: SetFolderPreviewConfigInput!) {
        setFolderPreviewConfig(input: $input) {
            store {
                id
            }
        }
    }
`

const commit = (env, id, configs, onSuccess, onError) =>
	commitMutation(env, {
		mutation,
		variables: {
			input: {
				id,
				boolConfigs: configs.filter((i) => typeof i.value === "boolean"),
				stringConfigs: configs.filter((i) => typeof i.value === "string"),
			},
		},
		updater: (storeProxy) => {
			const folder = storeProxy.get(id)
			const config = folder.getLinkedRecord("previewConfig")
			configs.forEach((c) => {
				config.setValue(c.value, c.id)
			})
		},
		onCompleted: complete(onSuccess, onError),
		onError: (e) => complete(null, [e]),
	})

export default { commit }
