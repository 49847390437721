import React, { Component } from "react"
import { withDrop } from "helpers/dnd-helper"
import { CategoryENUM } from "helpers/enum-helper"

const collectionSelectTarget = {
	drop(props, monitor) {
		const { x: clientX, y: clientY } = monitor.getClientOffset()
		const { id, type, replaceable, moveFrom } = monitor.getItem()
		props.drop({ clientX, clientY, id, type, replaceable, moveFrom })
	},
}

class DropElement extends Component {
	render() {
		const { dropRef, children, className } = this.props
		return (
			<div className={className} ref={dropRef}>
				{children}
			</div>
		)
	}
}

export default withDrop(DropElement, (props) => ({
	accept: [
		CategoryENUM.IMAGE,
		CategoryENUM.SHAPE,
		CategoryENUM.FONT,
		CategoryENUM.TEMPLATE,
	],
	drop: (_, monitor) => collectionSelectTarget.drop(props, monitor),
	collect: (monitor) => ({
		isOver: monitor.isOver(),
	}),
}))
