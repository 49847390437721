import { commitMutation, graphql } from "react-relay"
import { ConnectionHandler } from "relay-runtime"
import { complete } from "../helper"

const mutation = graphql`
	mutation setCollectionsForImageMutation(
		$input: SetCollectionsForImageInput!
	) {
		setCollectionsForImage(input: $input) {
			store {
				id
			}
			createdCollections {
				id
				title
				createdAt
				updatedAt
				isDefault
				count
			}
		}
	}
`

const commit = (env, items, currentUserId, onError, onCompleted, other) =>
	commitMutation(env, {
		mutation,
		variables: {
			input: {
				items,
			},
		},
		updater: (storeProxy, data) => {
			items.forEach((item) => {
				const { id: imageId, value: collections } = item
				const currentUserProxy = storeProxy.get(currentUserId)
				const stillExists = () =>
					[collections.map((i) => i.id), other.collections].reduce(
						(p, c) => p.filter((e) => c.includes(e))
					)
				if (
					other &&
					other.collections.length > 0 &&
					stillExists().length === 0
				) {
					const connectionFilterProxy = other
						? ConnectionHandler.getConnection(
								currentUserProxy.getLinkedRecord(
									"productCollectionSearchItems",
									{
										categories: other.categories,
										brands: other.brands,
										clientIds: other.clientId ? [other.clientId] : [],
										collections: other.collections,
										limitType: other.limitType,
										search: other.search,
										sort: other.sort,
										type: other.type,
										teamId: other.teamId,
									}
								),
								"currentUser_productCollectionSearchItems_data"
						  )
						: null

					if (connectionFilterProxy)
						ConnectionHandler.deleteNode(connectionFilterProxy, imageId)
				}
				const imageProxy = storeProxy.get(imageId)
				const collectionsProxies = collections
					.filter((i) => i.id)
					.map((i) => storeProxy.get(i.id))
				collectionsProxies.forEach((cp) => {
					const value = collections.find(
						(c) => c.id === cp.getValue("id")
					)?.value
					cp.setValue(value, "title", { ownershipCheck: true })
				})

				const linkedCollections = [
					...collectionsProxies,
					...data.setCollectionsForImage.createdCollections.map((i) =>
						storeProxy.get(i.id)
					),
				]
				imageProxy.setLinkedRecords(linkedCollections, "productCollections")
			})
		},
		onError: complete(onCompleted, onError),
		onCompleted: complete(onCompleted, onError),
	})

export default { commit }
