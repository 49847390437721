/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-param-reassign */
import React from "react"
import { Image } from "react-konva"
import watermarkjs from "watermarkjs"
import svgList from "components/lookbook/shape-svgs"
import { CanvasSizeList } from "components/lookbook-dropdown/canvas-size-picker"
import toggleLookbookFavouriteMutation from "mutations/lookbook/toggle-lookbook-favourite"
import { createGuid } from "helpers/string-helper"
import LoadingGIF from "shared/assets/loading-square.gif"
import { unset } from "lodash"

export const canvasMarging = 34
const exportMimeType = "image/jpeg"
const exportExt = "jpeg"
const defaultLookbookTitle = "Untitled Shopboard"
const defaultTemplateTitle = "Untitled Template"
const defaultBackground = {
	fill: "#FFFFFF",
	fillurl: null,
	fillPatternScale: 1,
	fillPatternImage: null,
	opacity: 1,
}
const videoFormats = [
	{ mimeType: "application/x-mpegURL", ext: "m3u8" },
	{ mimeType: "video/webm", ext: "webm" },
	{ mimeType: "video/mp4", ext: "mp4" },
	{ mimeType: "video/mpeg", ext: "mpeg" },
]
const audioFormats = [
	{ mimeType: "audio/mpeg", ext: "mp3" },
	{ mimeType: "audio/webm", ext: "weba" },
	{ mimeType: "audio/wav", ext: "wav" },
]
const borderAllowedShapes = [
	"Paper",
	"Arrows",
	"Basic",
]

const newEmptyLookBook = () => ({
	id: null,
	user: null,
	title: defaultLookbookTitle,
	description: null,
	publishedAt: null,
	folder: null,
	isTemplate: false,
	templateId: null,
	isGlobal: false,
	categories: [],
})

const newEmptyBorad = () => ({
	boardId: `newBoard_${createGuid(6)}`,
	background: defaultBackground,
	title: defaultLookbookTitle,
	screenWidth: 0,
	width:
		CanvasSizeList && CanvasSizeList.length > 0
			? CanvasSizeList[0].width
			: 1080,
	height:
		CanvasSizeList && CanvasSizeList.length > 0
			? CanvasSizeList[0].height
			: 1080,
	pageNo: 0,
	dataJson: [],
	group: [],
	shapes: [],
	showBoard: true,
})

const cropImage = (newAttr) => {
	newAttr.cropPath.forEach((obj) => {
		const canvas = document.createElement("canvas")
		canvas.style.display = "block"
		const ctx = canvas.getContext("2d")
		let width = newAttr.shapeProp
			? obj.width * Math.abs(newAttr.scaleX)
			: obj.width
		let height = newAttr.shapeProp
			? obj.height * Math.abs(newAttr.scaleY)
			: obj.height
		width = width > newAttr.image.width ? newAttr.image.width : width
		height = height > newAttr.image.height ? newAttr.image.height : height
		let x = newAttr.shapeProp ? obj.x * Math.abs(newAttr.scaleX) : obj.x
		let y = newAttr.shapeProp ? obj.y * Math.abs(newAttr.scaleY) : obj.y
		x = x < 0 ? 0 : x
		y = y < 0 ? 0 : y
		canvas.width = width
		canvas.height = height
		ctx.drawImage(newAttr.image, x, y, width, height, 0, 0, width, height)
		canvas.isCroped = true
		newAttr.width = obj.width
		newAttr.height = obj.height
		newAttr.image = canvas
	})
	return newAttr.image
}

const getGuides = (lineGuideStops, itemBounds) => {
	const resultV = []
	const resultH = []
	const GUIDELINE_OFFSET = 5

	lineGuideStops.vertical.forEach((lineGuide) => {
		itemBounds.vertical.forEach((itemBound) => {
			const diff = Math.abs(lineGuide - itemBound.guide)
			if (diff < GUIDELINE_OFFSET) {
				resultV.push({
					lineGuide,
					diff,
					snap: itemBound.snap,
					offset: itemBound.offset,
				})
			}
		})
	})

	lineGuideStops.horizontal.forEach((lineGuide) => {
		itemBounds.horizontal.forEach((itemBound) => {
			const diff = Math.abs(lineGuide - itemBound.guide)
			if (diff < GUIDELINE_OFFSET) {
				resultH.push({
					lineGuide,
					diff,
					snap: itemBound.snap,
					offset: itemBound.offset,
				})
			}
		})
	})
	const guides = []

	const minV = resultV.sort((a, b) => a.diff - b.diff)[0]
	const minH = resultH.sort((a, b) => a.diff - b.diff)[0]
	if (minV) {
		guides.push({
			lineGuide: minV.lineGuide,
			offset: minV.offset,
			orientation: "V",
			snap: minV.snap,
		})
	}
	if (minH) {
		guides.push({
			lineGuide: minH.lineGuide,
			offset: minH.offset,
			orientation: "H",
			snap: minH.snap,
		})
	}
	return guides
}

const getColor = (fill, transparentLevel = 100) => {
	const fillTransparentLevel =
		transparentLevel !== undefined
			? parseInt(Number(transparentLevel * (255 / 100)), 10).toString(16)
			: "FF"
	return `${fill}${fillTransparentLevel.length === 1
		? `0${fillTransparentLevel}`
		: fillTransparentLevel
		}`
}

const getImage = (newAttr, flip = false) => {
	const card = newAttr.image
	const canvas = document.createElement("canvas")
	const { width, height } = card
	canvas.width = `${width}`
	canvas.height = `${height}`
	canvas.style.display = "block"
	const ctx = canvas.getContext("2d")
	const scaleX = flip ? -1 : parseInt(newAttr.imgScaleX, 10) || 1
	ctx.scale(scaleX, 1)
	ctx.drawImage(card, scaleX > 0 ? 0 : -1 * width, 0)
	canvas.style.display = "none"
	return canvas
}

const getImageByURL = async (newAttr) => {
	const { imageUrl, imgScaleX } = newAttr
	const canvas = document.createElement("canvas")
	canvas.style.display = "block"
	const image = document.createElement("img")
	image.src = imageUrl
	image.crossOrigin = "anonymous"
	const result = await new Promise((resolve, reject) => {
		try {
			image.addEventListener("load", () => {
				const ctx = canvas.getContext("2d")
				canvas.isCroped = false
				if (!image) {
					reject(canvas)
				}
				const drawingScale =
					parseInt(
						(image.naturalWidth * image.naturalHeight) / 16777216,
						10
					) + 1
				canvas.width = image.naturalWidth / drawingScale
				canvas.height = image.naturalHeight / drawingScale
				ctx.scale((imgScaleX || 1) / drawingScale, 1 / drawingScale)
				ctx.drawImage(
					image,
					(imgScaleX || 1) > 0 ? 0 : -1 * canvas.width,
					0
				)
				resolve(canvas)
			})
			image.addEventListener("error", (err) => {
				reject(err)
			})
		} catch (err) {
			reject(err)
		}
	})
	return result
}

const getImageBySVG = (newAttr, shapes) => {
	const svgs = []
	svgList.forEach((node) =>
		svgs.push(
			...node.svg.map((obj) => ({
				...obj,
				collection: { id: obj.id, value: node.category },
			}))
		)
	)
	if (shapes) {
		shapes.forEach((node) => {
			svgs.push({
				...node,
				path: JSON.parse(node.path),
				collection: { id: node.id, value: node.collection },
			})
			const cat = svgList.find((obj) => obj.category === node.collection)
			if (cat) {
				cat.svg.push({ ...node, path: JSON.parse(node.path) })
			} else {
				svgList.push({
					category: node.collection,
					svg: [{ ...node, path: JSON.parse(node.path) }],
				})
			}
		})
	}
	const svg = svgs.find(
		(obj) => `${obj.id}` === `${newAttr.shapeProp.svg.replace("shape_", "")}`
	)
	newAttr.shapeProp.borderAllowed = borderAllowedShapes.includes(
		svg?.collection?.value
	)
	const [, , VBWidth, VBHeight] = (svg.viewBox || "").split(" ")
	newAttr.shapeProp.path = svg.path || []
	newAttr.shapeProp.viewBoxWidth = parseInt(VBWidth, 10) ||
		newAttr.shapeProp.viewBoxWidth || newAttr.shapeProp.viewBowWidth || 256
	newAttr.shapeProp.viewBoxHeight = parseInt(VBHeight, 10) ||
		newAttr.shapeProp.viewBoxHeight || newAttr.shapeProp.viewBowHeight || 256
	unset(newAttr.shapeProp, "viewBowWidth")
	unset(newAttr.shapeProp, "viewBowHeight")
	const { viewBoxWidth, viewBoxHeight } = newAttr.shapeProp
	const canvas = document.createElement("canvas")
	const width = 200
	const height = (viewBoxHeight / viewBoxWidth) * 200
	const borderWidth = newAttr.shapeProp.borderWidth || 0
	canvas.width = (width + 2 * borderWidth) * Math.abs(newAttr.scaleX)
	canvas.height = (height + 2 * borderWidth) * Math.abs(newAttr.scaleY)
	canvas.style.display = "block"
	const ctx = canvas.getContext("2d")
	ctx.strokeStyle = newAttr.shapeProp.borderColor
	ctx.lineWidth = borderWidth
	const fill = getColor(
		newAttr.shapeProp.fill,
		newAttr.shapeProp.fillTransparentLevel
	)
	ctx.fillStyle = fill
	ctx.transform(
		Math.abs(newAttr.scaleX) / (viewBoxWidth / width),
		0,
		0,
		Math.abs(newAttr.scaleY) / (viewBoxHeight / height),
		0,
		0
	)
	if (svg)
		svg.path.forEach((path) => {
			const p = new Path2D(path)
			if (borderWidth) ctx.translate(borderWidth, borderWidth)
			ctx.fill(p, "evenodd")
			if (borderWidth) ctx.stroke(p)
		})
	// if (!newAttr.shapeProp.borderAllowed) {
	newAttr.image = canvas
	newAttr.image = cropImage(newAttr)
	// get flipped image
	newAttr.image = getImage(newAttr)
	// }
	return newAttr.image
}

const getObjectSnappingEdges = (node) => {
	const box = node.getClientRect()
	const absPos = node.absolutePosition()

	return {
		vertical: [
			{
				guide: Math.round(box.x),
				offset: Math.round(absPos.x - box.x),
				snap: "start",
			},
			{
				guide: Math.round(box.x + box.width / 2),
				offset: Math.round(absPos.x - box.x - box.width / 2),
				snap: "center",
			},
			{
				guide: Math.round(box.x + box.width),
				offset: Math.round(absPos.x - box.x - box.width),
				snap: "end",
			},
		],
		horizontal: [
			{
				guide: Math.round(box.y),
				offset: Math.round(absPos.y - box.y),
				snap: "start",
			},
			{
				guide: Math.round(box.y + box.height / 2),
				offset: Math.round(absPos.y - box.y - box.height / 2),
				snap: "center",
			},
			{
				guide: Math.round(box.y + box.height),
				offset: Math.round(absPos.y - box.y - box.height),
				snap: "end",
			},
		],
	}
}

const getThumbJpeg = (dataurl) => {
	const splitDataURI = dataurl.split(",")
	const byteString =
		splitDataURI[0].indexOf("base64") >= 0
			? // eslint-disable-next-line no-undef
			atob(splitDataURI[1])
			: decodeURI(splitDataURI[1])
	const mimeString = splitDataURI[0].split(":")[1].split(";")[0]
	const ia = new Uint8Array(byteString.length)
	// eslint-disable-next-line no-plusplus
	for (let i = 0; i < byteString.length; ++i) ia[i] = byteString.charCodeAt(i)

	return new Blob([ia], { type: mimeString })
}

const replaceItems = (objList, newAttrs) => {
	const objects = objList.slice()
	if (newAttrs)
		newAttrs.forEach((newAttr) => {
			const i = objects.findIndex((x) => x.id === newAttr.id)
			objects[i] = newAttr
		})
	return objects
}

const windowResize = (objList, scaleX) => {
	if (scaleX)
		return objList.map((x) => {
			const obj = { ...x }
			obj.x *= scaleX
			obj.y *= scaleX
			if (obj.image || obj.shapeProp) {
				obj.scaleX *= scaleX
				obj.scaleY *= scaleX
			} else if (x.textProp) {
				obj.textProp.fontSize *= scaleX
				obj.width = obj.width !== "auto" ? obj.width * scaleX : obj.width
				obj.offsetX = obj.offsetX ? obj.offsetX * scaleX : obj.offsetX
				obj.offsetY = obj.offsetY ? obj.offsetY * scaleX : obj.offsetY
			}
			return obj
		})
	return objList
}

const getWatermark = async (container, title, userFullname, scale) => {
	const padding = scale * 16
	const fontSize = scale * 12
	const logo = await getImageByURL({
		imageUrl: `https://shopshare.tv/poweredby-logo.svg`,
	})
	const logoScale = scale * 0.6
	const watermark = [
		{
			id: "shopboardTitle",
			x: padding,
			y: container.height - padding - fontSize,
			width: "auto",
			textProp: {
				text: `${title} by ${userFullname}`,
				fontFamily: "GT America",
				fontSize,
				opacity: 0.6,
			},
		},
		{
			id: "logo",
			x: container ? container.width - padding - logo.width * logoScale : 0,
			y: container.height - padding - logo.height * logoScale,
			image: logo,
			scaleX: logoScale,
			scaleY: logoScale,
			opacity: 0.6,
		},
	]
	return watermark
}

const setWatermark = (imageUrl, title, userFullname) =>
	watermarkjs([imageUrl, "https://shopshare.tv/poweredby-logo.svg"], {
		init(img) {
			img.crossOrigin = "anonymous"
		},
	})
		.image(
			watermarkjs.image.atPos(
				(coffee, logo) => coffee.width - logo.width - 20,
				(coffee, logo) => coffee.height - logo.height - 20,
				0.6
			)
		)
		.render()
		.image(
			watermarkjs.text.atPos(
				() => 20,
				(coffee) => coffee.height - 20,
				`${title} by ${userFullname}`,
				"20px GT America"
			)
		)
		.then((img) => {
			const mIOS =
				(window.navigator.userAgent.indexOf("iPhone") !== -1 ||
					window.navigator.userAgent.indexOf("iPad") !== -1) &&
				window.navigator.userAgent.indexOf("Safari") !== -1
			const link = document.createElement("a")
			if (!mIOS) {
				link.download = `${title}.png`
			}
			link.href = img.src
			document.body.appendChild(link)
			link.click()
			document.body.removeChild(link)
		})


const changeTransparency = (transparentLevel, newAttr) => {
	try {
		if (newAttr.width && newAttr.height) {
			const image = getImage(newAttr)
			newAttr.image = image

			const ctx = image.getContext("2d")
			const imgd = ctx.getImageData(0, 0, newAttr.width, newAttr.height)
			const pix = imgd.data
			const { pixel } = newAttr
			for (let i = 0; i < pixel.length; i += 4) {
				const colorMatched =
					pixel[i] >= 256 - transparentLevel &&
					pixel[i + 1] >= 256 - transparentLevel &&
					pixel[i + 2] >= 256 - transparentLevel

				if (colorMatched && pixel[i + 3] > 0) {
					pix[i + 3] = 0
				} else {
					pix[i] = pixel[i]
					pix[i + 1] = pixel[i + 1]
					pix[i + 2] = pixel[i + 2]
					pix[i + 3] = pixel[i + 3]
				}
			}
			newAttr.transparentLevel = transparentLevel
			ctx.putImageData(imgd, 0, 0)
		}
	} catch (error) {
		console.error(error)
	}
}

const isBackgroundRemovedImage = (imageUrl) =>
	imageUrl &&
	(imageUrl.search("_sstr.png") >= 0 || imageUrl.search("_temp_sstr.png") >= 0)

const clickFavourite = (itemId, category, teamId, relay) => {
	toggleLookbookFavouriteMutation.commit(
		relay.environment,
		category,
		itemId,
		teamId,
		() => { },
		(err) => console.error(err)
	)
}

const getDropCoordinates = ({ x, y, width, height, scaleX }, container) => {
	//
	let X = x
	if (x < (width * scaleX) / 2) {
		X = (width * scaleX) / 2
	} else if (x > container.element.offsetWidth - (width * scaleX) / 2) {
		X = container.element.offsetWidth - (width * scaleX) / 2
	}
	let Y = y
	if (y < (height * scaleX) / 2) {
		Y = (height * scaleX) / 2
	} else if (y > container.element.offsetHeight - (height * scaleX) / 2) {
		Y = container.element.offsetHeight - (height * scaleX) / 2
	}
	return { X, Y }
}

const getBestScales = (
	{ width, height, scaleX, scaleY },
	{ width: imgWidth, height: imgHeight }
) => {
	const minScale = Math.min(
		(width * scaleX) / imgWidth,
		(height * scaleY) / imgHeight
	)
	const maxScale = Math.max(
		(width * scaleX) / imgWidth,
		(height * scaleY) / imgHeight
	)
	return {
		minScaleX: minScale,
		minScaleY: minScale,
		maxScaleX: maxScale,
		maxScaleY: maxScale,
	}
}

const getVideoRecordFormat = () =>
	videoFormats.find(({ mimeType }) => MediaRecorder.isTypeSupported(mimeType))

const getAudioRecordFormat = () =>
	audioFormats.find(({ mimeType }) => MediaRecorder.isTypeSupported(mimeType))

const resolveExtension = (url) => {
	const extensions = url.split(".")
	if (extensions && extensions.length > 0) {
		return extensions[extensions.length - 1]
	}
	return ""
}

const checkIsAudio = (muxedUrl) => {
	let isAudio = false
	function checkUrl(url) {
		const ext = resolveExtension(url)
		const format = audioFormats.find((obj) => obj.ext === ext)
		if (format) {
			return true
		}
		return false
	}
	if (muxedUrl && Array.isArray(muxedUrl) && muxedUrl.length > 0) {
		const [url] = muxedUrl
		isAudio = checkUrl(url.src)
	} else if (muxedUrl) {
		isAudio = checkUrl(muxedUrl)
	}
	return isAudio
}

const getMediaSrc = (url) => {
	const ext = resolveExtension(url)
	if (ext) {
		const format = videoFormats
			.concat(audioFormats)
			.find((obj) => obj.ext === ext)
		if (format) {
			return { src: url, type: format.mimeType }
		}
	}
	return {}
}

const getPixelData = (newAttr) => {
	try {
		if (newAttr.width && newAttr.height) {
			const canvas = newAttr.image
			const ctx = canvas.getContext("2d")
			const imgd = ctx.getImageData(0, 0, newAttr.width, newAttr.height)
			return imgd.data
		}
		return []
	} catch (error) {
		console.error(error)
		return []
	}
}

const stageToBlob = async (
	stage,
	containerWidth,
	containerHeight,
	boardWith,
	fileName = "temp",
	scale = 1,
	x = 0,
	y = 0
) => {
	const canvas = stage.toCanvas({
		x,
		y,
		width: containerWidth,
		height: containerHeight,
		pixelRatio: (scale * boardWith) / containerWidth,
	})
	const blob = await new Promise((resolve) => {
		canvas.toBlob(
			(theBlob) => {
				theBlob.lastModifiedDate = new Date()
				theBlob.name = `${fileName}.${exportExt}`
				resolve(theBlob)
			},
			exportMimeType,
			0.9
		)
	})
	return blob
}

const downloadBlob = (blob, title) => {
	const uri = URL.createObjectURL(blob)
	const mIOS =
		(window.navigator.userAgent.indexOf("iPhone") !== -1 ||
			window.navigator.userAgent.indexOf("iPad") !== -1) &&
		window.navigator.userAgent.indexOf("Safari") !== -1
	const link = document.createElement("a")
	link.href = uri
	if (!mIOS) {
		link.download = `${title}.${exportExt}`
	}
	link.href = uri
	document.body.appendChild(link)
	link.click()
}

const setStageContainer = (
	containerWidth,
	containerHeight,
	canvasWidth,
	canvasHeight
) => {
	const minScale = Math.min(
		(containerWidth - canvasMarging) / canvasWidth,
		(containerHeight - canvasMarging) / canvasHeight
	)
	const width = minScale * canvasWidth
	const height = minScale * canvasHeight
	return { width, height }
}

function GIF({ x, y, width, height }) {
	const imageRef = React.useRef(null)
	const canvas = React.useMemo(() => {
		const node = document.createElement("canvas")
		return node
	}, [])
	React.useEffect(() => {
		// save animation instance to stop it on unmount
		let anim
		window.gifler(LoadingGIF).get((a) => {
			anim = a
			anim.animateInCanvas(canvas)
			anim.onDrawFrame = (ctx, frame) => {
				ctx.drawImage(frame.buffer, frame.x, frame.y)
				if (imageRef?.current) imageRef.current.getLayer().draw()
			}
		})
		return () => anim.stop()
	}, [canvas, imageRef])
	const loadingWidth = width || 48
	const loadingHeight = height || 48
	return (
		<Image
			ref={imageRef}
			image={canvas}
			x={x - loadingWidth / 2}
			y={y - loadingHeight / 2}
			width={loadingWidth}
			height={loadingHeight}
		/>
	)
}

export {
	newEmptyLookBook,
	newEmptyBorad,
	cropImage,
	getGuides,
	getColor,
	getImage,
	getImageByURL,
	getImageBySVG,
	getObjectSnappingEdges,
	getThumbJpeg,
	replaceItems,
	windowResize,
	getWatermark,
	changeTransparency,
	isBackgroundRemovedImage,
	clickFavourite,
	getDropCoordinates,
	getBestScales,
	getVideoRecordFormat,
	getAudioRecordFormat,
	resolveExtension,
	getMediaSrc,
	checkIsAudio,
	getPixelData,
	stageToBlob,
	downloadBlob,
	setStageContainer,
	defaultLookbookTitle,
	defaultTemplateTitle,
	defaultBackground,
	exportExt,
	GIF,
	setWatermark,
}
