import React, { Component, Fragment } from "react"
import { createFragmentContainer, graphql } from "react-relay"
import { toast } from "react-toastify"
import { Link } from "react-router-dom"
import stylesAvatar from "components/profile/avatar/avatar.css"
import input from "shared/styles/components/input.css"
import controls from "shared/styles/layout/controls.css"
import Avatar from "components/profile/avatar/avatar"
import PayoutButton from "components/payout-button/payout-button"
import twoColumn from "shared/styles/layout/two-column.css"
import pageContent from "shared/styles/layout/page-content.css"
import currencyData from "helpers/currencies.json"
import gqlHelper from "helpers/gql-helper"
import { getFormattedCurrency } from "helpers/string-helper"
import PayoutCommissionMutation from "mutations/admin/payout-commission"
import styles from "./admin-commision-report.css"
import { withOutletContext } from "../../../route-helpers"

class AdminCommisionReport extends Component {
	constructor(props) {
		super(props)
		this.state = {
			selectedUsers: [],
		}
	}

	getOverallPayoutMessage() {
		const { admin } = this.props
		const { selectedUsers } = this.state
		return admin.adminSalesReport.filter((i) =>
			selectedUsers.includes(i.user?.id)
		)
	}

	confirmPayout() {
		const { relay } = this.props
		const { selectedUsers } = this.state
		PayoutCommissionMutation.commit(
			relay.environment,
			selectedUsers,
			(e) => {
				toast.error(
					gqlHelper.getError(e) || "Error processing payout.",
					{ autoClose: 2000, closeButton: false }
				)
			},
			() => {
				toast.success(<div>Payouts processed successfully</div>, {
					autoClose: 2000,
					closeButton: false,
				})
				this.setState({ selectedUsers: [] })
			}
		)
	}

	render() {
		const { admin } = this.props
		const { selectedUsers } = this.state
		return (
			<Fragment>
				<div className={twoColumn.header}>
					<h1 className={twoColumn.title}>Commission Report</h1>
				</div>
				<div className={pageContent.root}>
					<div className={controls.root}>
						<div className={controls.results}>
							<b>{selectedUsers.length}</b> users selected
						</div>
						<PayoutButton
							disabled={!selectedUsers.length > 0}
							confirmPayout={() => this.confirmPayout()}
							tableData={{
								headings: ["User", "Amount"],
								rows: this.getOverallPayoutMessage().map((data) => [
									data.user.fullname,
									`$${data.commissions
										.reduce(
											(acc, next) =>
												acc +
												next.unpaidCommissionAfterValidationAUS,
											0
										)
										.toFixed(2)}`,
								]),
								total: this.getOverallPayoutMessage()
									.map((i) => i.commissions)
									.flat()
									.map((i) => i.unpaidCommissionAfterValidationAUS)
									.reduce((a, b) => a + b, 0)
									.toFixed(2),
							}}
						/>
					</div>
					<div className={styles.table}>
						<div className={styles.thead}>
							<div className={styles.tr}>
								<div className={styles.th} />
								<div className={styles.th}>User</div>
								<div className={`${styles.th} ${styles.hiddenXsDown}`}>
									Currency
								</div>
								{/* <div className={styles.th}>Sales</div>
								<div className={styles.th}>Total</div> */}
								<div className={`${styles.th} ${styles.hiddenXsDown}`}>
									Cut
								</div>
								<div className={`${styles.th} ${styles.hiddenXsDown}`}>
									Paid
								</div>
								<div className={styles.th}>Paid (AUD)</div>
								<div className={styles.th}>Pending</div>
								<div className={styles.th}>Pending (AUD)</div>
								<div className={styles.th}>Unpaid</div>
								<div className={styles.th}>Unpaid (AUD)</div>
							</div>
						</div>
						<div className={styles.tbody}>
							{admin.adminSalesReport.map((section) =>
								section.commissions.map((i, index) => {
									const cSymbol =
										currencyData[i.currency] &&
										currencyData[i.currency].symbol
									const selectedItem = !!selectedUsers.find(
										(x) => x === section.user?.id
									)
									return (
										<div
											className={
												selectedItem ? styles.trSelected : styles.tr
											}
											key={`${section.user?.id}_${i.currency}`}
										>
											<div className={styles.td}>
												{section.user && index === 0 && (
													<div className={styles.checkbox}>
														<input
															type="checkbox"
															className={`${input.checkbox} ${input.lg}`}
															onChange={(event) => {
																this.setState({
																	selectedUsers: event.target
																		.checked
																		? [
																				...selectedUsers,
																				section.user?.id,
																		  ]
																		: selectedUsers.filter(
																				(x) =>
																					x !==
																					section.user?.id
																		  ),
																})
															}}
															checked={selectedItem}
															disabled={
																!section.user?.paypalEmail ||
																!section.commissions.find(
																	(com) =>
																		com.unpaidCommissionAfterValidation >
																		0
																)
															}
														/>
													</div>
												)}
											</div>

											<div className={styles.tdTh}>
												{section.user && index === 0 ? (
													<Link
														className={styles.user}
														to={`/admin/user/${section.user.id}`}
													>
														<Avatar
															styleName={stylesAvatar.image}
															photoUrl={
																section.user.profilePhoto
															}
															fullname={section.user.fullname}
															imageSize="100"
															size="40px"
														/>
														{section.user.fullname}
													</Link>
												) : null}
											</div>

											<div
												className={`${styles.td} ${styles.hiddenXsDown}`}
											>
												{i.currency}
											</div>
											{/* <div className={styles.td}>
												{cSymbol
													? `${cSymbol}${(i.sales || 0).toFixed(
															2
													  )}`
													: `${(i.sales || 0).toFixed(2)} ${
															i.currency
													  }`}
											</div>
											<div className={styles.td}>
												{cSymbol
													? `${cSymbol}${(
															i.totalCommission || 0
													  ).toFixed(2)}`
													: `${(i.totalCommission || 0).toFixed(
															2
													  )} ${i.currency}`}
											</div> */}
											<div
												className={`${styles.td} ${styles.hiddenXsDown}`}
											>
												{getFormattedCurrency({
													cSymbol,
													amount: i.shopshareCut || 0,
													selectedCurrency: i.currency,
												})}
											</div>
											<div
												className={`${styles.td} ${styles.hiddenXsDown}`}
											>
												{getFormattedCurrency({
													cSymbol,
													amount: i.paidCommission || 0,
													selectedCurrency: i.currency,
												})}
											</div>
											<div className={styles.td}>
												{getFormattedCurrency({
													cSymbol: "$",
													amount: i.paidCommissionAUS || 0,
												})}
											</div>
											<div
												className={
													i.pendingCommission < 0
														? styles.tdNegative
														: styles.td
												}
											>
												{getFormattedCurrency({
													cSymbol,
													amount: i.pendingCommission || 0,
													selectedCurrency: i.currency,
												})}
											</div>
											<div
												className={
													i.pendingCommissionAUS < 0
														? styles.tdNegative
														: styles.td
												}
											>
												{getFormattedCurrency({
													cSymbol: "$",
													amount: i.pendingCommissionAUS || 0,
												})}
											</div>
											<div className={styles.td}>
												{getFormattedCurrency({
													cSymbol,
													amount:
														i.unpaidCommissionAfterValidation ||
														0,
													selectedCurrency: i.currency,
												})}
											</div>
											<div className={styles.td}>
												{getFormattedCurrency({
													cSymbol: "$",
													amount:
														i.unpaidCommissionAfterValidationAUS ||
														0,
												})}
											</div>
										</div>
									)
								})
							)}
						</div>
						<div className={styles.tfoot}>
							<div className={styles.tr}>
								<div className={styles.td} />
								<div className={styles.td}>Totals (AUD)</div>
								<div className={styles.td} />
								{/* <div className={styles.td}/>
								<div className={styles.td} /> */}
								<div className={styles.td} />
								<div className={styles.td} />
								<div className={styles.td}>
									{getFormattedCurrency({
										cSymbol: "$",
										amount: admin.adminSalesReport
											.map((i) => i.commissions)
											.flat()
											.map((i) => i.paidCommissionAUS)
											.reduce((a, b) => a + b),
									})}
								</div>
								<div
									className={`${styles.td} ${styles.hiddenXsDown}`}
								/>
								<div className={styles.td}>
									{getFormattedCurrency({
										cSymbol: "$",
										amount: admin.adminSalesReport
											.map((i) => i.commissions)
											.flat()
											.map((i) => i.pendingCommissionAUS)
											.reduce((a, b) => a + b),
									})}
								</div>
								<div
									className={`${styles.td} ${styles.hiddenXsDown}`}
								/>
								<div className={styles.td}>
									{getFormattedCurrency({
										cSymbol: "$",
										amount: admin.adminSalesReport
											.map((i) => i.commissions)
											.flat()
											.map(
												(i) => i.unpaidCommissionAfterValidationAUS
											)
											.reduce((a, b) => a + b),
									})}
								</div>
							</div>
							<div className={styles.tr}>
								<div className={styles.td} />
								<div className={styles.td}>Total Commission (AUD)</div>
								<div className={styles.td} />
								<div className={styles.td} />
								{/* <div className={styles.td}></div>
								<div className={styles.td}></div> */}
								<div
									className={`${styles.td} ${styles.hiddenXsDown}`}
								/>
								<div
									className={`${styles.td} ${styles.hiddenXsDown}`}
								/>
								<div
									className={`${styles.td} ${styles.hiddenXsDown}`}
								/>
								<div
									className={`${styles.td} ${styles.hiddenXsDown}`}
								/>
								<div className={styles.td} />
								<div className={styles.td}>
									{getFormattedCurrency({
										cSymbol: "$",
										amount: admin.adminSalesReport
											.map((i) => i.commissions)
											.flat()
											.map((i) => i.totalCommissionAUS)
											.reduce((a, b) => a + b),
									})}
								</div>
							</div>
						</div>
					</div>
				</div>
			</Fragment>
		)
	}
}

AdminCommisionReport = createFragmentContainer(AdminCommisionReport, {
	admin: graphql`
		fragment adminCommisionReport_admin on Admin {
			adminSalesReport {
				user {
					id
					fullname
					paypalEmail
					profilePhoto
				}
				commissions {
					currency
					sales
					totalCommission
					shopshareCut
					paidCommission
					paidCommissionAUS
					unpaidCommission
					unpaidCommissionAUS
					totalCommissionAUS
					unpaidCommissionAfterValidation
					unpaidCommissionAfterValidationAUS
					pendingCommission
					pendingCommissionAUS
				}
			}
		}
	`,
})

AdminCommisionReport = withOutletContext(AdminCommisionReport)

export default AdminCommisionReport
