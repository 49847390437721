import React, { Component } from "react"
import queryString from "query-string"
import { debounce } from "lodash"
import { createGuid } from "helpers/string-helper"
import ImageList from "components/lookbook/image-list"
import lookbookAside from "shared/styles/layout/lookbook-aside.css"
import textfield from "shared/styles/components/textfield.css"
import { getComponent } from "../../route-helpers"
import SearchInputComponent from "../search-input/search-input"

class ListItem extends Component {
	constructor(props) {
		super(props)
		this.guid = createGuid()
		const qs = queryString.parse(props.searchString)
		this.state = {
			collectionId: qs.collection || null,
			clientId: "",
			collectionSearch: "",
		}
		this.debouncedSearch = debounce((key, value) => {
			this.setState({ [key]: value })
		}, 300)
		this.onChange = this.onChange.bind(this)
		this.imageListOnDebouncedSearch =
			this.imageListOnDebouncedSearch.bind(this)
		this.onChangeUserClient = this.onChangeUserClient.bind(this)
		props.innerRef(this)
	}

	onChange({ target }) {
		this.setState({
			collectionId: target.value,
		})
	}

	onChangeUserClient(e) {
		this.setState({
			clientId: e.target.value,
			collectionId: "",
		})
	}

	imageListOnDebouncedSearch(value) {
		this.debouncedSearch("collectionSearch", value)
	}

	render() {
		const { collectionId, collectionSearch, clientId } = this.state
		const { accepted, category, drop, hideMenu, type, store } =
			this.props
		const favouriteListOpen = collectionId === "favourites"
		const ImageListComp = getComponent(
			`image_list_item`,
			ImageList,
			(rp) => ({
				...ImageList.params,
				collectionId,
				type,
				collections:
					collectionId && !favouriteListOpen ? [collectionId] : [],
				search: collectionSearch,
				sort: favouriteListOpen ? "most-f" : "n-to-o",
				clients: clientId ? [clientId] : [],
				teamId: rp.team,
			}),
			{
				accepted,
				collectionId,
				type,
				collections:
					collectionId && !favouriteListOpen ? [collectionId] : [],
				sort: favouriteListOpen ? "most-f" : "n-to-o",
				category,
				guid: this.guid,
				search: collectionSearch,
				clients: clientId ? [clientId] : [],
				onChange: this.onChange,
				drop,
				hideMenu,
			}
		)
		return (
			<div className={lookbookAside.root}>
				<div className={lookbookAside.header}>
					<SearchInputComponent
						placeholder="Search Collections"
						setSearchText={(value) =>
							this.imageListOnDebouncedSearch(value)
						}
					/>
					<div className={lookbookAside.headerActions}>
						{(store?.currentUser?.userClients?.edges || []).length >
							0 && (
							<select
								className={textfield.normal}
								value={clientId}
								onChange={this.onChangeUserClient}
							>
								<option value="">My Collections</option>
								<optgroup label="Client Collections">
									{(store?.currentUser?.userClients?.edges || []).map(
										({ node }) => (
											<option key={node.id} value={node.id}>
												{node.fullname}
											</option>
										)
									)}
								</optgroup>
							</select>
						)}
					</div>
				</div>
				{ImageListComp}
			</div>
		)
	}
}
export default ListItem
