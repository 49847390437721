import React from "react"
import { createFragmentContainer, graphql } from "react-relay"
import { Helmet } from "react-helmet"
import PageFooter from "components/page-footer/page-footer"
import LandingActions from "components/landing-actions/landing-actions"
import landing from "shared/styles/layout/landing.css"
import ScrollToTopOnMount from "components/scroll-to-top/scroll-to-top"
import styles from "./for-network-marketers.css"

function ForNetworkMarketers({ store }) {
	const user = store.currentUser
	return (
		<div className={styles.root}>
			<Helmet>
				<title>ShopShare for Network Marketers</title>
			</Helmet>
			<ScrollToTopOnMount />

			<section className={`${landing.section} ${landing.gray100}`}>
				<div className={landing.content}>
					<h1 className={styles.displayTitle}>
						Explode your earning potential
					</h1>
					<p className={landing.displayLead}>
						Join thousands of direct selling entrepreneurs using ShopShare
						to explode their earning potential!
					</p>
					{!user && (
						<div className={landing.actions}>
							<LandingActions />
						</div>
					)}
				</div>
			</section>

			<section className={landing.section}>
				<div className={landing.contentPageFooter}>
					<PageFooter
						title="Use ShopShare for Network Marketing"
						lead="Join thousands of direct selling entrepreneurs using ShopShare to explode their earning potential!"
						currentUser={user}
					/>
				</div>
			</section>
		</div>
	)
}

// eslint-disable-next-line no-func-assign
ForNetworkMarketers = createFragmentContainer(ForNetworkMarketers, {
	store: graphql`
		fragment forNetworkMarketers_store on Store {
			id
			currentUser {
				id
			}
		}
	`,
})

const query = graphql`
	query forNetworkMarketersQuery {
		store {
			...forNetworkMarketers_store
		}
	}
`

export default { Component: ForNetworkMarketers, query }
