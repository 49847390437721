import React, { Component, Fragment } from "react"
import { toast } from "react-toastify"
import { Pencil1Icon } from "@radix-ui/react-icons"
import { Link } from "react-router-dom"
import { createFragmentContainer, graphql } from "react-relay"
import buttons from "shared/styles/components/buttons.css"
import twoColumn from "shared/styles/layout/two-column.css"
import pageContent from "shared/styles/layout/page-content.css"
import toggle from "shared/styles/components/toogle.css"
import icon from "shared/styles/components/icons.css"
import PayoutButton from "components/payout-button/payout-button"
import CreateEditCampaignModal from "components/modals/create-edit-campaign/create-edit-campaign"
import CreateCampaignMutation from "mutations/referral/create-campaign"
import UpdateCampaignMutation from "mutations/referral/update-campaign"
import ToggleArchiveCampaignMutation from "mutations/referral/toggle-archive-campaign"
import PayoutUsersMutation from "mutations/admin/payout-users"
import gqlHelper from "helpers/gql-helper"
// import { getCommisionMessage } from "helpers/referral-helper"
import styles from "./admin-referral-campaign.css"
import { withOutletContext } from "../../../route-helpers"

class AdminReferralCampaign extends Component {
	constructor(props) {
		super(props)
		this.state = {
			campaignModelData: null,
		}
		this.saveCampaign = this.saveCampaign.bind(this)
	}

	getOverallPayoutMessage() {
		const { admin } = this.props
		return admin.campaigns
			.map((campaign) => {
				const total = campaign.referrals.reduce(
					(a, b) => ({
						revenue: a.revenue + b.revenue,
						paidCommision: a.paidCommision + b.paidCommision,
					}),
					{ revenue: 0, paidCommision: 0 }
				)
				return {
					name: campaign.name,
					message: `${campaign.name}: $${(
						total.revenue - total.paidCommision
					).toFixed(2)}`,
					value: total.revenue - total.paidCommision,
				}
			})
			.filter((i) => i.value > 0)
	}

	confirmPayout() {
		const { relay } = this.props
		PayoutUsersMutation.commit(
			relay.environment,
			(e) => {
				toast.error(
					gqlHelper.getError(e) || "Error processing payout.",
					{ autoClose: 2000, closeButton: false }
				)
			},
			() => {
				toast.success(<Fragment>Payouts processed successfully</Fragment>, {
					autoClose: 2000,
					closeButton: false,
				})
			}
		)
	}

	archiveCampaign(data) {
		const { relay } = this.props
		ToggleArchiveCampaignMutation.commit(
			relay.environment,
			data,
			() => {},
			() => {}
		)
	}

	saveCampaign() {
		const { campaignModelData } = this.state
		const { relay, admin } = this.props
		if (!campaignModelData.id) {
			CreateCampaignMutation.commit(
				relay.environment,
				campaignModelData,
				admin.id,
				() => {
					toast.success(<div>Campaign created successfully</div>, {
						autoClose: 2000,
						closeButton: false,
					})
					this.setState({ campaignModelData: null })
				},
				(e) => {
					toast.error(
						gqlHelper.getError(e) || "Error creating campaign.",
						{ autoClose: 2000, closeButton: false }
					)
				}
			)
		} else {
			UpdateCampaignMutation.commit(
				relay.environment,
				campaignModelData,
				admin.id,
				() => {
					toast.success(
						<Fragment>Campaign updated successfully.</Fragment>,
						{
							autoClose: 2000,
							closeButton: false,
						}
					)
					this.setState({ campaignModelData: null })
				},
				(e) => {
					toast.error(
						gqlHelper.getError(e) || "Error updating campaign.",
						{ autoClose: 2000, closeButton: false }
					)
				}
			)
		}
	}

	render() {
		const { admin } = this.props
		const { campaignModelData } = this.state
		return (
			<Fragment>
				<div className={twoColumn.header}>
					<h1 className={twoColumn.title}>Campaigns</h1>
					<div className={twoColumn.actions}>
						<PayoutButton
							confirmPayout={() => this.confirmPayout()}
							tableData={{
								headings: ["Campaign", "Amount"],
								rows: this.getOverallPayoutMessage().map((data) => [
									data.name,
									`$${data.value}`,
								]),
								total: this.getOverallPayoutMessage().reduce(
									(acc, next) => acc + next.value,
									0
								),
							}}
						/>
						<button
							type="button"
							className={buttons.primary}
							onClick={() => {
								this.setState({
									campaignModelData: {
										name: "",
										type: "FIXED",
										monthlyCommision: 0,
										cookiePeriod: 60,
										annualCommision: 0,
										users: [],
										allUsers: false,
									},
								})
							}}
						>
							New Campaign
						</button>
					</div>
				</div>
				<div className={pageContent.root}>
					<div className={styles.table}>
						<div className={styles.thead}>
							<div className={styles.tr}>
								<div className={styles.th}>Campaign</div>
								<div className={styles.th}>Affiliates</div>
								<div className={styles.th}>Net Revenue</div>
								<div className={styles.th}>Active</div>
								<div className={styles.th} />
							</div>
							{admin.campaigns.map((i) => (
								<div className={styles.tr} key={i.id}>
									<div className={styles.tdTh}>
										<Link to={`/admin/campaign/${i.id}`}>
											{i.name}
										</Link>
										{/* <p className={styles.description}>
											{getCommisionMessage(i, true)}
										</p> */}
									</div>

									<div className={styles.td}>
										{i.users.length || 0}
									</div>
									<div className={styles.td}>
										${(i.totalRevenue || 0).toFixed(2)}
									</div>
									<div className={styles.td}>
										<div className={styles.toggle}>
											<label className={toggle.check}>
												<div className={toggle.switch}>
													<input
														type="checkbox"
														checked={!i.isArchived}
														onChange={() =>
															this.archiveCampaign(i)
														}
													/>
													<div className={toggle.slider} />
												</div>
											</label>
										</div>
									</div>
									<div className={styles.td}>
										<button
											type="button"
											className={buttons.circle}
											onClick={() => {
												this.setState({
													campaignModelData: i,
												})
											}}
										>
											<Pencil1Icon className={icon.icon18} />
										</button>
									</div>
								</div>
							))}
						</div>
					</div>
				</div>

				{campaignModelData && (
					<CreateEditCampaignModal
						data={campaignModelData}
						users={admin.getUsersWithPlan}
						closeModal={() => {
							this.setState({ campaignModelData: null })
						}}
						onChange={(changedData) => {
							this.setState({ campaignModelData: changedData })
						}}
						modalSubmit={this.saveCampaign}
					/>
				)}
			</Fragment>
		)
	}
}

AdminReferralCampaign = createFragmentContainer(AdminReferralCampaign, {
	admin: graphql`
		fragment adminReferralCampaign_admin on Admin {
			id
			getUsersWithPlan {
				id
				username
			}
			campaigns {
				id
				name
				description
				monthlyCommision
				annualCommision
				type
				cookiePeriod
				monthlyCoupon
				annualCoupon
				conversions
				isArchived
				users {
					id
					username
				}
				totalRevenue
				referrals {
					revenue
					paidCommision
				}
				allUsers
			}
		}
	`,
})

AdminReferralCampaign = withOutletContext(AdminReferralCampaign)

export default AdminReferralCampaign
