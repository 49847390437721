import { commitMutation, graphql } from "react-relay"
import { complete } from "../helper"

const mutation = graphql`
	mutation getFolderTreeMutation($input: GetFolderTreeInput!) {
		getFolderTree(input: $input) {
			tree
		}
	}
`

const commit = (env, teamId, callback, onError) =>
	commitMutation(env, {
		mutation,
		variables: { input: { teamId } },
		onCompleted: complete(
			(d) => callback(JSON.parse(d.getFolderTree.tree)),
			onError
		),
		onError: complete(null, onError),
	})

export default { commit }
