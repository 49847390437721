import React from "react"
import { createFragmentContainer, graphql } from "react-relay"
import { Transition } from "react-transition-group"
import { FileIcon, defaultStyles } from "react-file-icon"
import { DownloadIcon } from "@radix-ui/react-icons"
import PinButton from "components/buttons/pin-button"
import { ShowCardControlsComponent, imageOrVideo } from "helpers/ui-helper"
import MenuIcon from "shared/assets/menu-dots-horizontal.svg"
import DropdownMenu from "components/dropdown-menu/dropdown-menu"
import stylesDropdown from "components/dropdown-menu/dropdown-menu-dark.css"
import button from "shared/styles/components/buttons.css"
import { getBaseUrl } from "constants"
import styles from "./file-card.css"

class FileCard extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			isDropdownOpen: false,
		}
	}

	getUrlExtension(url) {
		return url.split(/[#?]/)[0].split(".").pop().trim()
	}

	renderPlay(file) {
		const { toggleLightbox } = this.props
		const icon = file.thumbnailUrl ? (
			<img
				className={styles.poster}
				loading="lazy"
				src={`${file.thumbnailUrl}?d=400`}
				alt="Poster"
			/>
		) : (
			<div className={styles.posterIcon}>
				<FileIcon
					color="white"
					extension={this.getUrlExtension(file.url)}
					{...defaultStyles[this.getUrlExtension(file.url)]}
				/>
			</div>
		)
		if (!!imageOrVideo(file.url) && toggleLightbox) {
			return (
				<div className={styles.figure}>
					<div
						className={styles.inner}
						onClick={() => {
							toggleLightbox(file.url)
						}}
						role="button"
						tabIndex={0}
					>
						{icon}
					</div>
				</div>
			)
		}
		return (
			<div className={styles.figure}>
				<a
					className={styles.inner}
					href={file.url}
					rel="noreferrer"
					target="_blank"
				>
					{icon}
				</a>
			</div>
		)
	}

	renderMenu() {
		const { file } = this.props
		const listItems = []
		const push = (click, name, key, icon) =>
			listItems.push({
				click,
				name,
				key,
				icon,
			})
		const icon = <MenuIcon className={stylesDropdown.icon} />
		push(
			() =>
				window.open(
					`${getBaseUrl(false)}/api/shopshare-files/download?url=${
						file.url
					}`,
					"_blank"
				),
			"Download",
			"download",
			<DownloadIcon className={stylesDropdown.itemIcon} />
		)
		return (
			<DropdownMenu
				mainIcon={icon}
				items={listItems}
				darkTheme
				triggerClass={button.lightIcon}
				triggerClassActive={button.lightIconActive}
				setDropdownOpened={(val) => {
					this.setState({ isDropdownOpen: val })
				}}
			/>
		)
	}

	render() {
		const { file } = this.props
		const { isDropdownOpen } = this.state
		return (
			<ShowCardControlsComponent className={styles.root}>
				{(isShown) => (
					<>
						<div className={styles.media}>
							<div className={styles.pin}>
								<PinButton isPinned={file.isPinned} />
							</div>
							<Transition in={isShown || isDropdownOpen} timeout={200}>
								{(state) => (
									<div className={`${styles.menu} ${styles[state]}`}>
										{this.renderMenu()}
									</div>
								)}
							</Transition>
							{this.renderPlay(file)}
						</div>
						<div className={styles.content}>
							<div className={styles.titleWithIcons}>
								<h4 className={styles.title}>{file.title}</h4>
							</div>
						</div>
					</>
				)}
			</ShowCardControlsComponent>
		)
	}
}

FileCard = createFragmentContainer(FileCard, {
	file: graphql`
		fragment fileCard_shopshareFile on ShopshareFile {
			id
			title
			url
			thumbnailUrl
			isPublished
			isArchived
			isPinned
			inRoot
			user {
				id
				fullname
				username
				profilePhoto
				canEdit
			}
		}
	`,
})

export default FileCard
