import React from "react"
import styles from "./loading-dots.css"

export default function LoadingDots({ color }) {
	const style = { "--dot-color": color }
	return (
		<div className={styles.root}>
			<div className={styles.bounce1} style={style} />
			<div className={styles.bounce2} style={style} />
			<div className={styles.bounce3} style={style} />
		</div>
	)
}
