import React from "react"
import { Helmet } from "react-helmet"
import layout from "shared/styles/components/layout.css"
import landing from "shared/styles/layout/landing.css"
import InstagramIcon from "shared/assets/instagram.svg"
import LinkedinIcon from "shared/assets/linkedin.svg"
import EmmaInteman from "shared/assets/team-images/emma-inteman.jpg"
import JoelInteman from "shared/assets/team-images/joel-inteman.jpg"
// import Skalata from "shared/assets/skalata-ventures.png"
import styles from "./about.css"

function About() {
	function getSocialLink(url, name, ImageComponent, title) {
		return (
			<a
				className={styles.shareBtn}
				title={title}
				rel="noopener noreferrer"
				target="_blank"
				href={url}
			>
				{ImageComponent && <ImageComponent className={styles.shareIcon} />}
			</a>
		)
	}
	return (
		<div className={`${layout.containerWidth5} ${layout.offset}`}>
			<Helmet>
				<title>ShopShareTV – About</title>
			</Helmet>

			<section className={landing.section}>
				<div className={landing.content}>
					<h1 className={landing.displayTitle}>What is ShopShare?</h1>
					<p className={styles.displayLead}>
						ShopShare reintroduces the in-store feel to online shopping.
						Shopping experts use ShopShare to connect with their customers
						in a personal and scalable way.
					</p>
					<p className={styles.displayLead}>
						Engage your customers through Shopcast shoppable videos,
						interactive Shopboards and custom Shopping Channels. Share
						content privately or market across social media with easy and
						fast integrations. The creative possibilities are endless!
					</p>
				</div>
			</section>

			<section className={landing.section}>
				<div className={landing.content}>
					<div className={landing.header}>
						<h2 className={landing.title}>Our Founders</h2>
					</div>
					<div className={styles.list} role="list">
						<div className={styles.listItem} role="listitem">
							<div className={styles.teamCard}>
								<a href="https://www.linkedin.com/">
									<img
										className={styles.teamImage}
										src={EmmaInteman}
										alt="Emma Inteman"
									/>
								</a>
								<div className={styles.teamContent}>
									<div className={styles.teamMember}>
										<h3 className={styles.teamName}>Emma Inteman</h3>
										<p className={styles.teamRole}>Founder & CEO</p>
										<p>
											Emma is a lifelong fashionista with a degree in
											online shopping mastery! With a background in
											sales and as a natural people person, Emma
											dreamt up ShopShare to combine the power of
											e-commerce with the personal world of fashion
											styling.
										</p>
									</div>
									<div className={styles.social}>
										{getSocialLink(
											`https://www.instagram.com/emmainteman`,
											"instagram",
											InstagramIcon,
											"Instagram"
										)}
										{getSocialLink(
											`https://www.linkedin.com/in/emma-inteman-b765a392`,
											"linkedin",
											LinkedinIcon,
											"LinkedIn"
										)}
									</div>
								</div>
							</div>
						</div>
						<div className={styles.listItem} role="listitem">
							<div className={styles.teamCard}>
								<a href="https://www.linkedin.com/">
									<img
										className={styles.teamImage}
										src={JoelInteman}
										alt="Joel Inteman"
									/>
								</a>
								<div className={styles.teamContent}>
									<div className={styles.teamMember}>
										<h3 className={styles.teamName}>Joel Inteman</h3>
										<p className={styles.teamRole}>Founder & CDO</p>
										<p>
											As a technology Wizz and a UX expert, Joel has
											created seamless and timeless digital solutions
											for some of Australia’s most well-known
											startups. He gave Emma’s vision digital life,
											leaving no stone unturned in building the most
											exquisite experience for ShopShare creators and
											audiences.
										</p>
									</div>
									<div className={styles.social}>
										{getSocialLink(
											`https://www.linkedin.com/in/joelinteman`,
											"linkedin",
											LinkedinIcon,
											"LinkedIn"
										)}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* <section className={`${landing.section} ${landing.gray100}`}>
				<div className={landing.content}>
					<div className={landing.header}>
						<h2 className={landing.title}>Our Investors</h2>
						<p className={landing.lead}>
							We&apos;re fortunate to have been funded by
						</p>
					</div>
					<div className={styles.partners}>
						<a
							className={styles.website}
							href="https://www.skalata.co/"
							rel="noreferrer"
							target="_blank"
						>
							<img
								className={styles.logo}
								src={Skalata}
								alt="Skalata Ventures"
							/>
						</a>
					</div>
				</div>
			</section> */}
		</div>
	)
}

export default { Component: About }
