/**
 * @generated SignedSource<<37387113619871d7cc1fc86bdbc2acb1>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Mutation } from 'relay-runtime';
export type SaveLookbookVideoMutationInput = {|
  boardId: string,
  clientMutationId?: ?string,
  isAudio?: ?boolean,
  show_video?: ?boolean,
  video_url?: ?string,
|};
export type setLookbookVideoUrlMutation$variables = {|
  input: SaveLookbookVideoMutationInput,
|};
export type setLookbookVideoUrlMutation$data = {|
  +saveLookbookVideo: ?{|
    +jobId: ?string,
    +mp4_url: ?string,
    +show_video: ?boolean,
    +subtitle: ?string,
    +video_url: ?string,
  |},
|};
export type setLookbookVideoUrlMutation = {|
  response: setLookbookVideoUrlMutation$data,
  variables: setLookbookVideoUrlMutation$variables,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "SaveLookbookVideoMutationPayload",
    "kind": "LinkedField",
    "name": "saveLookbookVideo",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "video_url",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "mp4_url",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "subtitle",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "show_video",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "jobId",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "setLookbookVideoUrlMutation",
    "selections": (v1/*: any*/),
    "type": "ShopshareMutationRoot",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "setLookbookVideoUrlMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "b2328954d518834b7288aee4ba50ba68",
    "id": null,
    "metadata": {},
    "name": "setLookbookVideoUrlMutation",
    "operationKind": "mutation",
    "text": "mutation setLookbookVideoUrlMutation(\n  $input: SaveLookbookVideoMutationInput!\n) {\n  saveLookbookVideo(input: $input) {\n    video_url\n    mp4_url\n    subtitle\n    show_video\n    jobId\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "8ac4e585a3f0e5cc3997b033ba01690d";

module.exports = ((node/*: any*/)/*: Mutation<
  setLookbookVideoUrlMutation$variables,
  setLookbookVideoUrlMutation$data,
>*/);
