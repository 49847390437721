import React, { useState, Fragment } from "react"
import Modal from "react-modal"
import { toast } from "react-toastify"
import { Cross2Icon, TrashIcon } from "@radix-ui/react-icons"
import dayjs from "dayjs"
import Dropzone from "react-dropzone"
import TextareaAutosize from "react-autosize-textarea"
import modal from "shared/styles/components/modal.css"
import form from "shared/styles/components/form.css"
import toggle from "shared/styles/components/toogle.css"
import textfield from "shared/styles/components/textfield.css"
import button from "shared/styles/components/buttons.css"
import icons from "shared/styles/components/icons.css"
import CameraIcon from "shared/assets/camera-icon.svg"
import DatePicker from "react-datepicker"
import SaveRakutenSiteMutation from "../../../mutations/admin/save-rakuten-site"
import AddCountryToRakutenSiteMutation from "../../../mutations/admin/add-country-to-rakuten-site"
import RemoveCountryFromRakutenSiteMutation from "../../../mutations/admin/remove-country-from-rakuten-site"
import RemoveRakutenSiteMutation from "../../../mutations/admin/remove-rakuten-site"
import gqlh from "../../../helpers/gql-helper"
import AutoSuggest from "../../auto-suggest-new/auto-suggest"
import styles from "./rakuten-site-modal.css"

let uploadedFile = null
let uploadedLogo = null

export default function RakutenSiteModal(props) {
	const { site, relay, misc, closeModal } = props
	const [urls, setUrls] = useState(site.urls)
	const [displayName, setDisplayName] = useState(site.displayName)
	const [description, setDescription] = useState(site.description || "")
	const [commissionRate, setCommissionRate] = useState(site.commissionRate)
	const [countries, setCountries] = useState(
		site.countries ? site.countries : []
	)
	const [networks, setNetworks] = useState(site.networks ? site.networks : [])
	const [category, setCategory] = useState(
		site.category ? site.category.id : 0
	)
	const [imageUrl, setImageUrl] = useState(site.imageUrl)
	const [logoUrl, setLogoUrl] = useState(site.logoUrl)
	const [isPublic, setIsPublic] = useState(site.isPublic)
	const [specialCommissionRate, setSpecialCommissionRate] = useState(
		site.specialCommissionRate
	)
	const [
		specialCommissionRateValidUntil,
		setSpecialCommissionRateValidUntil,
	] = useState(
		site.specialCommissionRateValidUntil
			? dayjs(+site.specialCommissionRateValidUntil).toDate()
			: null
	)

	const [displayUrl, setDisplayUrl] = useState(site.displayUrl)
	const [isDeleted, setIsDelete] = useState(false)

	function getSiteItem() {
		let k = 0

		const urlItems = urls.map((u, i) => {
			function changeUrl(e) {
				const ur = [...urls]
				ur[i] = e.target.value
				setUrls(ur)
			}
			function removeUrl() {
				const ur = [...urls]
				ur.splice(i, 1)
				setUrls(ur)
			}
			k += 1
			return (
				<div key={k} className={styles.url}>
					<div className={form.inputGroup}>
						<input
							className={textfield.normal}
							type="text"
							required
							value={u}
							onChange={changeUrl}
						/>
						<button
							className={button.default}
							type="button"
							onClick={removeUrl}
						>
							Remove
						</button>
					</div>
				</div>
			)
		})

		function addUrl() {
			const ur = [...urls]
			ur.push("")
			setUrls(ur)
		}

		return (
			<div className={styles.domain}>
				{urlItems}
				<button className={button.subtle} type="button" onClick={addUrl}>
					Add
				</button>
			</div>
		)
	}

	function save() {
		SaveRakutenSiteMutation.commit(
			relay.environment,
			site.id,
			{
				imageName: uploadedFile ? uploadedFile.name : null,
				logoName: uploadedLogo ? uploadedLogo.name : null,
				urls,
				displayName,
				description,
				commissionRate,
				category,
				displayUrl,
				isPublic,
				specialCommissionRate,
				specialCommissionRateValidUntil,
				networks: networks.map((i) => i.id),
			},
			uploadedFile,
			uploadedLogo,
			(e) => {
				uploadedFile = null
				uploadedLogo = null
				toast.error(
					<span>{gqlh.getError(e, "Could not save site")}</span>,
					{ closeButton: false }
				)
			},
			() => {
				uploadedFile = null
				uploadedLogo = null
				closeModal()
			}
		)
	}

	function removeCountry(countryId) {
		RemoveCountryFromRakutenSiteMutation.commit(
			relay.environment,
			site.id,
			countryId,
			(c) => {
				setCountries(c)
			},
			() =>
				toast.error(<span>Could not remove the country</span>, {
					closeButton: false,
				})
		)
	}

	function addCountry(countryId) {
		AddCountryToRakutenSiteMutation.commit(
			relay.environment,
			site.id,
			countryId,
			(c) => {
				setCountries(c)
			},
			() =>
				toast.error(
					<span>
						Could not add the country. make sure the country is not there
						in the list.
					</span>,
					{ closeButton: false }
				)
		)
	}

	function renderNetworks() {
		const selectedNetworks = networks.map((i) => ({
			id: i.id,
			value: i.name,
		}))
		const networkList = misc.networks.edges.map((i) => ({
			id: i.node.id,
			value: i.node.name,
		}))
		return (
			<AutoSuggest
				result={selectedNetworks}
				dataList={networkList}
				noNew
				onChange={(result) => {
					setNetworks(
						result.map((i) => ({
							id: i.id,
							name: i.value,
						}))
					)
				}}
			/>
		)
	}

	function renderCountries() {
		const selectedCountries = countries.map((i) => ({
			id: i.id,
			value: i.name,
		}))
		const countryList = misc.countries.edges.map((i) => ({
			id: i.node.id,
			value: i.node.name,
		}))
		return (
			<AutoSuggest
				result={selectedCountries}
				dataList={countryList}
				noNew
				onChange={(result) => {
					const countryIds = selectedCountries.map((i) => i.id)
					for (const country of result) {
						if (!countryIds.includes(country.id)) {
							addCountry(country.id)
							return
						}
					}
					const resultIds = result.map((i) => i.id)
					for (const country of selectedCountries) {
						if (!resultIds.includes(country.id)) {
							removeCountry(country.id)
							return
						}
					}
				}}
			/>
		)
	}

	function renderCategorySelect() {
		return (
			<select
				className={textfield.normal}
				name="category"
				value={category}
				onChange={(e) => setCategory(e.target.value)}
			>
				<option key="1" value="">
					Category
				</option>
				{misc.categories.map((c) => (
					<option key={c.id} value={c.id}>
						{c.name}
					</option>
				))}
			</select>
		)
	}

	function onImageDrop(acceptedFiles) {
		;[uploadedFile] = acceptedFiles
		// eslint-disable-next-line no-undef
		const reader = new FileReader()

		reader.addEventListener(
			"load",
			() => {
				setImageUrl(reader.result)
			},
			false
		)

		if (uploadedFile) {
			reader.readAsDataURL(uploadedFile)
		}
	}

	function onLogoDrop(acceptedFiles) {
		;[uploadedLogo] = acceptedFiles
		// eslint-disable-next-line no-undef
		const reader = new FileReader()

		reader.addEventListener(
			"load",
			() => {
				setLogoUrl(reader.result)
			},
			false
		)

		if (uploadedLogo) {
			reader.readAsDataURL(uploadedLogo)
		}
	}

	function renderImage() {
		return (
			<Dropzone
				className={styles.imageDrop}
				multiple={false}
				onDrop={(files) => onImageDrop(files)}
			>
				{({ getRootProps, getInputProps }) => (
					<Fragment>
						<div {...getRootProps()} className={styles.imageDropIcon}>
							<input {...getInputProps()} />
							<CameraIcon className={icons.icon32} />
						</div>
						<img
							className={styles.image}
							src={imageUrl}
							alt="Advertiser Tile"
						/>
					</Fragment>
				)}
			</Dropzone>
		)
	}

	function renderLogo() {
		return (
			<Dropzone
				className={styles.logoDrop}
				multiple={false}
				onDrop={(files) => onLogoDrop(files)}
			>
				{({ getRootProps, getInputProps }) => (
					<Fragment>
						<div {...getRootProps()} className={styles.imageDropIcon}>
							<input {...getInputProps()} />
							<CameraIcon className={icons.icon32} />
						</div>
						<img className={styles.image} src={logoUrl} alt="Logo Tile" />
					</Fragment>
				)}
			</Dropzone>
		)
	}

	function deleteSite() {
		RemoveRakutenSiteMutation.commit(
			relay.environment,
			site.id,
			() => {
				toast.success(<span>Site deleted successfully</span>, {
					closeButton: false,
				})
				closeModal()
			},
			(e) => {
				toast.error(
					<span>{gqlh.getError(e, "Could not delete site")}</span>,
					{ closeButton: false }
				)
			}
		)
	}

	return (
		<Modal
			className={modal.root}
			overlayClassName={modal.overlay}
			htmlOpenClassName="ReactModal__Html--open"
			isOpen
			ariaHideApp={false}
		>
			<div
				className={`${modal.dialog} ${modal.dialogCentered} ${modal.dialogScrollable} ${modal.dialogFullscreenSmDown} ${modal.dialogLg}`}
			>
				<div className={`${modal.content} ${modal.whiteBg}`}>
					<div className={`${modal.header}`}>
						<h4 className={modal.title}>{`Edit ${site.name}`}</h4>
						<div className={styles.badge}>
							{site.partneredNetwork.toUpperCase()}
						</div>
						<button
							type="button"
							className={button.close}
							onClick={() => {
								closeModal()
							}}
						>
							<Cross2Icon className={button.closeIcon} />
						</button>
					</div>
					<div className={modal.body}>
						<div className={form.group}>
							<label className={toggle.check}>
								<div className={toggle.switch}>
									<input
										type="checkbox"
										checked={isPublic}
										onChange={(e) => {
											setIsPublic(e.target.checked)
										}}
									/>
									<div className={toggle.slider} />
								</div>
								<div className={toggle.label}>Make Public</div>
							</label>
						</div>
						<div className={form.group}>
							<label className={form.label}>Display Name</label>
							<input
								className={textfield.normal}
								type="text"
								defaultValue={displayName}
								onChange={(input) => {
									setDisplayName(input.target.value)
								}}
							/>
							<div className={form.validationFeedback} />
						</div>
						<div className={form.group}>
							<label className={form.label}>URL</label>
							<input
								className={textfield.normal}
								type="text"
								defaultValue={displayUrl}
								onChange={(input) => {
									setDisplayUrl(input.target.value)
								}}
							/>
							<div className={form.validationFeedback} />
						</div>
						<div className={form.group}>
							<label className={form.label}>Hostname(s)</label>
							{getSiteItem()}
							<div className={form.validationFeedback} />
						</div>
						<div className={form.group}>
							<label className={form.label}>
								Description (optional)
							</label>
							<TextareaAutosize
								defaultValue={description}
								className={textfield.textarea}
								rows={3}
								onChange={(e) => {
									setDescription(e.target.value)
								}}
							/>
							<div className={form.validationFeedback} />
						</div>
						<div className={styles.commisions}>
							<div className={form.group}>
								<label className={form.label}>
									Base Commission Rate
								</label>
								<input
									className={textfield.normal}
									type="text"
									defaultValue={commissionRate}
									onChange={(input) => {
										setCommissionRate(input.target.value)
									}}
								/>
								<div className={form.validationFeedback} />
							</div>
							<div className={form.group}>
								<label className={form.label}>Special Rate</label>
								<input
									className={textfield.normal}
									type="text"
									defaultValue={specialCommissionRate}
									onChange={(input) => {
										setSpecialCommissionRate(input.target.value)
									}}
								/>
								<div className={form.validationFeedback} />
							</div>
							<div className={form.group}>
								<label className={form.label}>Valid Until</label>
								<DatePicker
									className={textfield.normal}
									selected={specialCommissionRateValidUntil}
									onChange={setSpecialCommissionRateValidUntil}
									disabled={!specialCommissionRate}
								/>
								<div className={form.validationFeedback} />
							</div>
						</div>
						<div className={form.row}>
							<div className={form.group}>
								<label className={form.label}>Category</label>
								{renderCategorySelect()}
								<div className={form.validationFeedback} />
							</div>
							<div className={form.group}>
								<label className={form.label}>Countries</label>
								{renderCountries()}
								<div className={form.validationFeedback} />
							</div>
						</div>

						<div className={form.group}>
							<label className={form.label}>Networks</label>
							{renderNetworks()}
							<div className={form.validationFeedback} />
						</div>

						<div className={form.row}>
							<div className={form.group}>
								<label className={form.label}>Image (optional)</label>
								{renderImage()}
							</div>
							<div className={form.group}>
								<label className={form.label}>Logo (optional)</label>
								<div className={styles.logo}>{renderLogo()}</div>
							</div>
						</div>
					</div>
					{isDeleted ? (
						<div className={`${modal.footer} ${modal.borderNone}`}>
							<div className={styles.message}>
								Are you sure you want to delete this site?
							</div>
							<button
								type="button"
								className={button.link}
								onClick={() => {
									setIsDelete(false)
								}}
							>
								Cancel
							</button>
							<button
								type="button"
								className={button.primaryRed}
								onClick={deleteSite}
							>
								Delete
							</button>
						</div>
					) : (
						<div className={`${modal.footer} ${modal.borderNone}`}>
							<button
								type="button"
								className={styles.btnDelete}
								onClick={() => {
									setIsDelete(true)
								}}
							>
								<TrashIcon className={icons.icon21} />
							</button>
							<button
								type="button"
								className={button.link}
								onClick={() => {
									closeModal()
								}}
							>
								Cancel
							</button>
							<button
								type="button"
								className={button.primary}
								onClick={save}
							>
								Save
							</button>
						</div>
					)}
				</div>
			</div>
		</Modal>
	)
}
