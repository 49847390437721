/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { Component } from "react"
import { Cross2Icon } from "@radix-ui/react-icons"
import { createGuid, toTitleCase } from "helpers/string-helper"
import chip from "shared/styles/components/chips.css"
import styles from "./auto-suggest.css"

class AutoSuggest extends Component {
	constructor(props) {
		super(props)
		this.state = {
			text: "",
		}
		this.onKeyPress = this.onKeyPress.bind(this)
		this.setupCase = this.setupCase.bind(this)
	}

	onKeyPress({ key }) {
		if (key !== "Enter") return
		const { text } = this.state
		const trimmedText = text.trim()
		const { dataList, noNew } = this.props
		if (!trimmedText) return
		const item = dataList.find(
			(i) => i.value.toLowerCase() === trimmedText.toLowerCase()
		)
		if (item) this.addSubCategory(item)
		else if (!noNew)
			this.addSubCategory({
				id: `new_${createGuid(10)}`,
				value: this.setupCase(trimmedText),
			})
	}

	setupCase(text) {
		const { textcase } = this.props
		switch (textcase) {
			case "lowercase":
				return text.toLowerCase()
			case "uppercase":
				return text.toUpperCase()
			case "nocase":
				return text
			case "titlecase":
			default:
				return toTitleCase(text || "")
		}
	}

	getSuggestions() {
		const { text } = this.state
		const { dataList, result, noNew } = this.props
		const trimmedText = text.trim()
		if (!trimmedText) return []
		const resultIds = result.map((i) => i.id)
		let filterList = dataList.filter((i) =>
			i.value.toLowerCase().includes(trimmedText.toLowerCase())
		)
		filterList = filterList.filter((i) => !resultIds.includes(i.id))
		const alreadyInResult = result.find(
			(i) =>
				i.id.includes("new_") &&
				i.value.toLowerCase() === trimmedText.toLowerCase()
		)
		const sameNameExist = dataList.find(
			(i) => i.value.toLowerCase() === trimmedText.toLowerCase()
		)
		const suggestions =
			alreadyInResult || sameNameExist || noNew
				? filterList
				: [
						...filterList,
						{
							id: `new_${createGuid(10)}`,
							value: this.setupCase(trimmedText),
						},
				  ]
		return suggestions
	}

	addSubCategory(obj) {
		const { onChange, result } = this.props
		const alreadyInResult = result.find(
			(i) =>
				obj.id === i.id ||
				(i.id.includes("new_") &&
					i.value.toLowerCase() === obj.value.toLowerCase())
		)
		if (!alreadyInResult) onChange([...result, obj])
		this.setState({ text: "" })
	}

	removeSelected(obj) {
		const { onChange, result } = this.props
		onChange(result.filter((i) => i.id !== obj.id))
	}

	render() {
		const { text } = this.state
		const { result, placeholder, maxResults, isExtra } = this.props
		const suggestions = this.getSuggestions()
		const maxReached = maxResults && maxResults === result.length
		return (
			<div className={styles.root}>
				<ul className={styles.tags}>
					{isExtra && <li className={styles.chip}>...</li>}
					{result.map((x) => (
						<li
							className={
								x.className
									? `${styles.chip} ${x.className}`
									: styles.chip
							}
							key={x.id}
						>
							{this.setupCase(x.value)}
							<div className={chip.actions}>
								<button
									type="button"
									className={chip.btn}
									onClick={() => this.removeSelected(x)}
								>
									<Cross2Icon />
								</button>
							</div>
						</li>
					))}
					<li className={styles.typeahead}>
						<input
							className={styles.input}
							value={text}
							onChange={(e) => {
								this.setState({ text: e.target.value })
							}}
							placeholder={
								maxReached
									? `Allow ${maxResults} ${
											maxResults === 1 ? "selection" : "selections"
									  } only`
									: placeholder
							}
							onKeyPress={this.onKeyPress}
							disabled={maxReached}
						/>
						{suggestions.length > 0 && (
							<div className={styles.suggestions}>
								<ul className={styles.results}>
									{suggestions.map((x) => (
										<li
											className={styles.item}
											key={x.id}
											onClick={() => {
												this.addSubCategory(x)
											}}
										>
											{this.setupCase(x.value)}
										</li>
									))}
								</ul>
							</div>
						)}
					</li>
				</ul>
			</div>
		)
	}
}

export default AutoSuggest
