import React from "react"
import Modal from "react-modal"
import modal from "shared/styles/components/modal.css"
import button from "shared/styles/components/buttons.css"
import form from "shared/styles/components/form.css"
import { Cross2Icon } from "@radix-ui/react-icons"
import { useFormik } from "formik"
import * as Yup from "yup"
import EmojiTextArea from "components/emoji-textarea/emoji-textarea"
import textfield from "shared/styles/components/textfield.css"
import { fetchQuery, graphql } from "react-relay"
// import FacebookIcon from "shared/assets/facebook-icon.svg"
// import InstagramIcon from "shared/assets/instagram-icon.svg"
import FacebookColorIcon from "shared/assets/facebook-color-icon.svg"
import InstagramColorIcon from "shared/assets/instagram-color-icon.svg"
import ConnectToSocialModal from "../connect-to-social/connect-to-social"
import { connectToSocial } from "../../../services/social-service"
import PageSelector from "../page-selector/page-selector"
import styles from "../connect-to-social/connect-to-social.css"
import { socialMedia } from "../../../constants"
import LoadingDots from "../../loading-dots/loading-dots"

export default function FacebookSharing({
	relay,
	platform,
	uploadAs,
	storeId,
	closeModal,
	connectedToFacebook,
	obj,
	fbPages,
	onSharing,
}) {
	const boards =
		obj.lookbookBoards && obj.lookbookBoards.length > 0
			? obj.lookbookBoards
			: []
	const fbPagesFiltered =
		platform === "facebook"
			? fbPages || []
			: (fbPages || []).filter((page) => page.instagramBusinessAccount)
	const formik = useFormik({
		enableReinitialize: true,
		initialValues: {
			uploadAs: uploadAs.length > 0 ? uploadAs[0].id : "",
			description: obj.description || "",
			fbPage:
				(fbPagesFiltered || []).length > 0 ? fbPagesFiltered[0].id : "",
			lookbookPage: boards.length > 0 ? boards[0].id : "",
		},
		validationSchema: Yup.object().shape({
			uploadAs: Yup.string(),
			description: Yup.string(),
			fbPage: Yup.string().required("Facebook Page is required"),
			lookbookPage:
				boards.length > 0
					? Yup.string().required("Lookbook Page is required")
					: Yup.string(),
		}),
		onSubmit: async (values) => {
			const fbPage = fbPagesFiltered.find(
				(page) => page.id === values.fbPage
			)
			await onSharing({
				...values,
				fbPage,
				platform,
			})
		},
	})

	const connectToFacebook = React.useCallback(() => {
		connectToSocial(
			relay,
			storeId,
			"facebook",
			true,
			() => {
				fetchQuery(
					relay.environment,
					graphql`
						query facebookSharingQuery {
							store {
								id
								currentUser {
									id
									socialMedia {
										platform
										isConnected
									}
									facebookPages {
										id
										name
										category
										imageUrl
										accessToken
										instagramBusinessAccount
									}
								}
							}
						}
					`,
					{}
				)
					.toPromise()
					.then((data) => {
						console.log(data)
					})
					.catch(() => {})
			},
			(e) => {
				console.error(e)
			}
		)
	}, [])

	if (platform === "facebook" && !connectedToFacebook)
		return (
			<ConnectToSocialModal
				title={socialMedia[platform].title}
				Icon={FacebookColorIcon}
				iconColor="var(--facebook500)"
				heading="Publish straight to Facebook Pages!"
				subHeading="Connected to Instagram Business?"
				description="Be sure to authorise all Pages you want to use in ShopShare, including Facebook Pages linked to your Instagram accounts."
				buttonPrompt="Connect Facebook"
				closeModal={() => {
					closeModal()
				}}
				onConnect={connectToFacebook}
			/>
		)

	if (
		platform === "instagram" &&
		(!connectedToFacebook || fbPagesFiltered.length === 0)
	)
		return (
			<Modal
				className={modal.root}
				overlayClassName={modal.overlay}
				isOpen
				ariaHideApp={false}
			>
				<div className={`${modal.dialog} ${modal.dialogCentered}`}>
					<div className={`${modal.content}`}>
						<div className={modal.header}>
							<div className={modal.titleWithIcon}>
								{platform === "facebook" ? (
									<FacebookColorIcon className={modal.icon} />
								) : (
									<InstagramColorIcon className={modal.icon} />
								)}
								<h4 className={modal.title}>
									{platform === "facebook"
										? "Facebook Page"
										: "Instagram Business"}
								</h4>
							</div>
							<button
								type="button"
								className={button.close}
								onClick={() => {
									closeModal()
								}}
							>
								<Cross2Icon className={button.closeIcon} />
							</button>
						</div>
						<div className={modal.body}>
							<ol className={styles.help}>
								<li className={styles.helpItem}>
									Convert your Instagram Personal or Creator account to
									an <b>Instagram Business account</b>.{" "}
									<a
										href="https://help.instagram.com/502981923235522"
										target="_blank"
										rel="noreferrer"
									>
										Learn how.
									</a>
								</li>
								<li className={styles.helpItem}>
									Link it to a facebook page{" "}
									<a
										href="https://help.instagram.com/570895513091465"
										target="_blank"
										rel="noreferrer"
									>
										Learn how.
									</a>
								</li>
								<li className={styles.helpItem}>
									Connect via Facebook.
								</li>
							</ol>
							<button
								type="button"
								className={button.primary}
								onClick={() => {
									connectToFacebook()
								}}
							>
								Connect via Facebook
							</button>
						</div>
					</div>
				</div>
			</Modal>
		)

	return (
		<Modal
			className={modal.root}
			overlayClassName={modal.overlay}
			isOpen
			ariaHideApp={false}
		>
			<div className={`${modal.dialog} ${modal.dialogCentered}`}>
				<div className={`${modal.content}`}>
					<div className={modal.header}>
						<div className={modal.titleWithIcon}>
							{platform === "facebook" ? (
								<FacebookColorIcon className={modal.icon} />
							) : (
								<InstagramColorIcon className={modal.icon} />
							)}
							<h4 className={modal.title}>Create Post</h4>
						</div>
						<button
							type="button"
							className={button.close}
							onClick={() => {
								closeModal()
							}}
						>
							<Cross2Icon className={button.closeIcon} />
						</button>
					</div>
					<div className={modal.body}>
						{boards.length > 1 && (
							<div className={form.group}>
								<label className={form.label}>Select Page</label>
								<PageSelector
									boards={boards}
									value={formik.values.lookbookPage}
									onChange={(value) => {
										formik.setFieldValue("lookbookPage", value)
									}}
								/>
							</div>
						)}

						{fbPagesFiltered.length > 1 && (
							<div className={form.group}>
								<label className={form.label}>
									{platform === "facebook"
										? "Choose a Facebook Page"
										: "Choose an Account"}
								</label>
								<div className={form.inputGroup}>
									<select
										className={textfield.normal}
										name="fbPage"
										onChange={formik.handleChange}
										value={formik.values.fbPage}
									>
										{fbPagesFiltered.map((page) => (
											<option value={page.id}>{page.name}</option>
										))}
									</select>
								</div>
							</div>
						)}

						{uploadAs.length > 1 && (
							<div className={form.group}>
								<label className={form.label}>Post Type</label>
								<div className={form.inputGroup}>
									<select
										className={textfield.normal}
										name="uploadAs"
										onChange={formik.handleChange}
										value={formik.values.uploadAs}
									>
										{uploadAs.map((item) => (
											<option key={item.id} value={item.id}>
												{item.name}
											</option>
										))}
									</select>
								</div>
							</div>
						)}
						<div className={form.group}>
							<label className={form.label}>Description</label>
							<EmojiTextArea
								className={textfield.textarea}
								maxLength={2048}
								rows={5}
								name="description"
								placeholder="Write something..."
								value={formik.values.description}
								onChange={formik.handleChange}
							/>
							{formik.errors?.description && (
								<div className={form.validationFeedback}>
									{formik.errors.description}
								</div>
							)}
						</div>
					</div>
					<div className={`${modal.footer}`}>
						<button
							type="button"
							className={button.link}
							onClick={() => {
								closeModal()
							}}
						>
							Cancel
						</button>
						<button
							type="submit"
							className={button.primary}
							disabled={formik.isSubmitting}
							onClick={() => {
								formik.submitForm()
							}}
						>
							{(() => {
								if (formik.isSubmitting)
									return <LoadingDots color="var(--primary80)" />
								if (platform === "facebook") return "Post"
								return "Share"
							})()}
						</button>
					</div>
				</div>
			</div>
		</Modal>
	)
}
