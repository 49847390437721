/* eslint-disable react/no-unstable-nested-components */
import React, { Component } from "react"
import {
	ArrowLeftIcon,
	ExternalLinkIcon,
	Link2Icon,
} from "@radix-ui/react-icons"
import { createRefetchContainer, graphql } from "react-relay"
import { toast } from "react-toastify"
import ClipboardButton from "react-clipboard.js"
import icons from "shared/styles/components/icons.css"
import BrowserMockup from "components/browser-mockup/browser-mockup"
import button from "shared/styles/components/buttons.css"
// import FolderIcon from "shared/assets/folder-icon.svg"
// import FolderShareIcon from "shared/assets/folder-share-icon.svg"
import toggleShopcastFolderSharingMutation from "mutations/shopcast-manager/toggle-shopcast-folder-sharing"
import { baseFrontendUrl, publicViewDomain } from "constants"
import EditPreviewFolder from "./edit-preview-folder"
import EditPreviewClient from "./edit-preview-client"
import EditPreviewProfile from "./edit-preview-profile"
import styles from "./edit-preview.css"
import { withRouter } from "../../route-helpers"
import EditPreviewTeam from "./edit-preview-team"

class EditPreview extends Component {
	constructor(props) {
		super(props)
		this.state = {}
		this.postMessageIframe = this.postMessageIframe.bind(this)
		this.getSharedFolderControls = this.getSharedFolderControls.bind(this)
		this.configObj = this.getConfig()

		this.prevUrl = props.location.state?.prevUrl
	}

	get team() {
		const { store } = this.props
		return store.team
	}

	get currentUser() {
		const { store } = this.props
		return store.currentUser
	}

	get folder() {
		return this.currentUser.shopcastContainer.folder
	}

	getSharedFolderControls() {
		const { relay } = this.props
		return (
			<button
				type="button"
				className={
					!!this.folder.isShared ? button.primaryRed : button.primary
				}
				onClick={() => {
					toggleShopcastFolderSharingMutation.commit(
						relay.environment,
						this.folder.id,
						() => { },
						(err) => {
							console.log(err)
						}
					)
				}}
			>
				{!!this.folder.isShared ? "Unshare Folder" : "Share Folder"}
			</button>
		)
	}

	getConfig() {
		const { relay, store } = this.props
		const getAttributes = () => ({
			currentUser: this.currentUser,
			team: this.team,
			relay,
			reloadIframe: () => this.postMessageIframe("shopshare-reload"),
		})
		if (this.team)
			return {
				title: `Edit Team Channel`,
				tooltip: `View Team Channel`,
				url: `${baseFrontendUrl}/team/${this.team.id}`,
				iframeUrl: `${baseFrontendUrl}/team/${this.team.id}`,
				sidePanel: () => <EditPreviewTeam {...getAttributes()} />,
			}
		const isClient = this.folder.clients && this.folder.clients.length > 0
		if (isClient)
			return {
				title: `Edit Client Channel`,
				tooltip: `View Client Channel`,
				url: `${baseFrontendUrl}/stylist/${this.folder.id}`,
				iframeUrl: `${baseFrontendUrl}/client-preview/${this.folder.id}`,
				sidePanel: () => <EditPreviewClient {...getAttributes()} />,
			}
		if (this.folder.sharableLink)
			return {
				title: `Edit Share Folder`,
				tooltip: `View Share Folder`,
				url: this.folder.sharableLink,
				sidePanel: () => <EditPreviewFolder {...getAttributes()} />,
				iframeUrl: `${baseFrontendUrl}/shared/${this.currentUser.username}/${this.folder.id}`,
				controls: this.getSharedFolderControls,
			}
		return {
			title: `Edit My Channel`,
			tooltip: `View Channel`,
			url: `https://${this.currentUser.username}.${publicViewDomain}`,
			sidePanel: () => (
				<EditPreviewProfile {...getAttributes()} storeId={store.id} />
			),
		}
	}

	postMessageIframe(message) {
		if (!this.iframeRef) return
		this.iframeRef.contentWindow.postMessage(message, "*")
	}

	renderHeaderContent() {
		return (
			<>
				<h1 className={styles.title}>{this.configObj.title}</h1>
				<div className={styles.actions}>
					<ClipboardButton
						className={`${button.subtleIcon} ${button.iconLg}`}
						data-clipboard-text={this.configObj.url}
						data-tooltip-id="tooltip"
						data-tooltip-content="Get Sharable Link"
						onSuccess={() => {
							toast.success(<>Copied to Clipboard</>, {
								autoClose: 2000,
								closeButton: false,
							})
						}}
					>
						<Link2Icon className={icons.icon21} />
					</ClipboardButton>
					<a
						className={`${button.subtleIcon} ${button.iconLg}`}
						href={this.configObj.url}
						rel="noreferrer"
						target="_blank"
						data-tooltip-id="tooltip"
						data-tooltip-content={this.configObj.tooltip}
					>
						<ExternalLinkIcon className={icons.icon21} />
					</a>
					{this.configObj.controls && this.configObj.controls()}
				</div>
			</>
		)
	}

	render() {
		const { navigate } = this.props
		return (
			<div className={styles.root}>
				<aside className={styles.side}>
					<div className={styles.fixed}>
						<div className={styles.scrollPanel}>
							<div className={styles.sideHeader}>
								<button
									type="button"
									className={`${button.subtleIcon} ${button.iconLg}`}
									onClick={() => {
										if (this.prevUrl) navigate(this.prevUrl)
										else navigate(`/content`)
									}}
								>
									<ArrowLeftIcon className={icons.icon24} />
								</button>
								<div className={styles.mobileHeaderContent}>
									{this.renderHeaderContent()}
								</div>
							</div>
							{this.configObj.sidePanel()}
						</div>
					</div>
				</aside>
				<main className={styles.main}>
					<header className={styles.header}>
						<div className={styles.content}>
							{this.renderHeaderContent()}
						</div>
					</header>
					<BrowserMockup
						url={this.configObj.url}
						onReload={() => this.postMessageIframe("shopshare-reload")}
					>
						<iframe
							ref={(ref) => {
								this.iframeRef = ref
							}}
							className={styles.iframe}
							src={this.configObj.iframeUrl || this.configObj.url}
							title="Iframe"
						/>
					</BrowserMockup>
				</main>
			</div>
		)
	}
}

const query = graphql`
	query editPreviewQuery(
		$folder: String!
		$isFolder: Boolean!
		$isTeam: Boolean!
		$isProfile: Boolean!
	) {
		store {
			id
			...editPreview_currentUser
				@arguments(
					folder: $folder
					isFolder: $isFolder
					isTeam: $isTeam
					isProfile: $isProfile
				)
		}
	}
`

EditPreview = createRefetchContainer(
	EditPreview,
	{
		store: graphql`
			fragment editPreview_currentUser on Store
			@argumentDefinitions(
				folder: { type: "String!" }
				isFolder: { type: "Boolean!" }
				isTeam: { type: "Boolean!" }
				isProfile: { type: "Boolean!" }
			) {
				team(id: $folder) {
					id
					name
					...editPreviewTeam_team @include(if: $isTeam)
				}
				currentUser {
					id
					username
					shopcastContainer {
						id
						folder(id: $folder) {
							id
							sharableLink(noCheck: true)
							isShared
							clients {
								id
							}
						}
					}
					...editPreviewProfile_currentUser @include(if: $isProfile)
					...editPreviewFolder_currentUser @arguments(folder: $folder) @include(if: $isFolder)
					...editPreviewClient_currentUser @arguments(folder: $folder) @include(if: $isFolder)
				}
			}
		`,
	},
	query
)

export default {
	Component: withRouter(EditPreview),
	query,
	params: (rp) => {
		const folder = rp.params.id || ""
		const parsed = folder ? atob(folder) : ""
		return {
			folder,
			isFolder: parsed.startsWith("ShopcastFolder:"),
			isTeam: parsed.startsWith("TeamId:"),
			isProfile: !folder
		}
	},
}
