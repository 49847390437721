/**
 * @generated SignedSource<<c5435e24d8afc4d749cf02ee171578c7>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Mutation } from 'relay-runtime';
export type SetCollectionsForProductInput = {|
  clientMutationId?: ?string,
  items?: ?$ReadOnlyArray<?SetCollectionsInput>,
|};
export type SetCollectionsInput = {|
  id?: ?string,
  value?: ?$ReadOnlyArray<IdValue>,
|};
export type IdValue = {|
  id?: ?string,
  value?: ?string,
|};
export type setCollectionsForProductMutation$variables = {|
  input: SetCollectionsForProductInput,
|};
export type setCollectionsForProductMutation$data = {|
  +setCollectionsForProduct: ?{|
    +createdCollections: ?$ReadOnlyArray<?{|
      +count: ?number,
      +createdAt: ?string,
      +id: string,
      +isDefault: ?boolean,
      +title: ?string,
      +updatedAt: ?string,
    |}>,
    +store: ?{|
      +currentUser: ?{|
        +id: string,
      |},
      +id: string,
    |},
  |},
|};
export type setCollectionsForProductMutation = {|
  response: setCollectionsForProductMutation$data,
  variables: setCollectionsForProductMutation$variables,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "SetCollectionsForProductPayload",
    "kind": "LinkedField",
    "name": "setCollectionsForProduct",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Store",
        "kind": "LinkedField",
        "name": "store",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "currentUser",
            "plural": false,
            "selections": [
              (v1/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "ProductCollection",
        "kind": "LinkedField",
        "name": "createdCollections",
        "plural": true,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "title",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "createdAt",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "updatedAt",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isDefault",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "count",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "setCollectionsForProductMutation",
    "selections": (v2/*: any*/),
    "type": "ShopshareMutationRoot",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "setCollectionsForProductMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "9cb05bdf429bb39f833849f8f9019b98",
    "id": null,
    "metadata": {},
    "name": "setCollectionsForProductMutation",
    "operationKind": "mutation",
    "text": "mutation setCollectionsForProductMutation(\n  $input: SetCollectionsForProductInput!\n) {\n  setCollectionsForProduct(input: $input) {\n    store {\n      id\n      currentUser {\n        id\n      }\n    }\n    createdCollections {\n      id\n      title\n      createdAt\n      updatedAt\n      isDefault\n      count\n    }\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "6fa3152895387bee8f3daa62f5d02aef";

module.exports = ((node/*: any*/)/*: Mutation<
  setCollectionsForProductMutation$variables,
  setCollectionsForProductMutation$data,
>*/);
