import React, { Component } from "react"
import Modal from "react-modal"
// import Relay from 'react-relay';
import { toast } from "react-toastify"

import textfield from "shared/styles/components/textfield.css"
import button from "shared/styles/components/buttons.css"
import form from "shared/styles/components/form.css"
import margin from "shared/styles/components/margin.css"
import alert from "shared/styles/components/alert.css"
import modal from "shared/styles/components/modal.css"

import ReportShopcastMutation from "mutations/shopcast/report-shopcast"
import ReportLookbookMutation from "mutations/lookbook/report-lookbook"
import RequireLoginToast from "components/require-login-toast/require-login-toast.jsx"

export default class ReportShopcastModal extends Component {
	constructor(props) {
		super(props)
		this.comment = null
		this.handleCheckBoxCheck = this.handleCheckBoxCheck.bind(this)
		this.handleSubmitClick = this.handleSubmitClick.bind(this)
		this.state = {
			selectedType: "inappropriate",
		}
	}

	handleCheckBoxCheck(e) {
		this.setState({ selectedType: e.target.value })
	}

	handleSubmitClick() {
		const { props, state } = this

		this.saveReport(state.selectedType)
			.then(() => {
				props.closeModal()
				toast.info(
					<div className={alert.toast}>
						<h3 className={alert.heading}>
							Now we know something&#39;s up, thanks!
						</h3>
						<p className={alert.message}>
							Videos that violate polices are removed from ShopShare.
						</p>
					</div>,
					{ autoClose: 5000, closeButton: false }
				)
			})
			.catch((e) => {
				console.log(e)
				if (e.indexOf("Not logged in")) {
					props.closeModal()
					toast.info(
						<RequireLoginToast message="Please sign in to report a problem." />,
						{ autoClose: 3000, closeButton: false }
					)
				} else {
					toast.error(
						<div>An error occurred while trying to send the report.</div>,
						{ autoClose: 3000, closeButton: false }
					)
				}
			})
	}

	saveReport(reportType) {
		const { props } = this
		return new Promise((resolve, reject) => {
			if (!(this.props && props.item && props.item.id)) {
				resolve(false)
				return
			}
			if (props.item.type === "lookbook"){
				ReportLookbookMutation.commit(
					props.relay.environment,
					props.item.id,
					this.comment || null,
					reportType,
					props.storeId,
					() => resolve(true),
					(transaction) => reject(transaction.getError().toString())
				)
				return
			}
			ReportShopcastMutation.commit(
				props.relay.environment,
				props.item.id,
				this.comment || null,
				reportType,
				props.storeId,
				() => resolve(true),
				(transaction) => reject(transaction.getError().toString())
			)
		})
	}

	render() {
		const { props, state } = this

		return (
			<Modal
				className={modal.root}
				overlayClassName={modal.overlay}
				htmlOpenClassName="ReactModal__Html--open"
				isOpen={props.showEmbedModal}
				shouldCloseOnOverlayClick
				ariaHideApp={false}
			>
				<div
					className={`${modal.dialog} ${modal.dialogCentered} ${modal.dialogSm}`}
				>
					<div className={`${modal.content} ${modal.whiteBg}`}>
						<div className={`${modal.header}`}>
							{props.item && props.item.type === "shopcast" && (
								<h4 className={modal.title}>Report Shopcast</h4>
							)}
							{props.item && props.item.type === "lookbook" && (
								<h4 className={modal.title}>Report Shopboard</h4>
							)}
						</div>
						<div className={modal.body}>
							<div className={form.radio}>
								<input
									type="radio"
									id="spamRadio"
									checked={state.selectedType === "spam"}
									value="spam"
									onChange={this.handleCheckBoxCheck}
								/>
								<label htmlFor="spamRadio">Spam</label>
							</div>
							<div className={form.radio}>
								<input
									type="radio"
									id="inappropriateRadio"
									checked={state.selectedType === "inappropriate"}
									value="inappropriate"
									onChange={this.handleCheckBoxCheck}
								/>
								<label htmlFor="inappropriateRadio">
									Inappropriate
								</label>
							</div>
							<div className={form.radio}>
								<input
									type="radio"
									id="illegalRadio"
									checked={state.selectedType === "illegal"}
									value="illegal"
									onChange={this.handleCheckBoxCheck}
								/>
								<label htmlFor="illegalRadio">Illegal</label>
							</div>
							<div className={form.radio}>
								<input
									type="radio"
									id="brokenRadio"
									checked={state.selectedType === "broken"}
									value="broken"
									onChange={this.handleCheckBoxCheck}
								/>
								<label htmlFor="brokenRadio">
									Broken link or video
								</label>
							</div>
							<div className={form.radio}>
								<input
									type="radio"
									id="otherRadio"
									checked={state.selectedType === "other"}
									value="other"
									onChange={this.handleCheckBoxCheck}
								/>
								<label htmlFor="otherRadio">Something else</label>
							</div>
							<div className={`${margin.mt2} ${margin.mb1}`}>
								<textarea
									className={textfield.textarea}
									rows={1}
									placeholder="Additional information"
									onChange={(e) => {
										this.comment = e.target.value
									}}
								/>
							</div>
						</div>
						<div className={`${modal.footer} ${modal.borderNone}`}>
							<button
								type="button"
								className={button.link}
								onClick={() => {
									props.closeModal()
								}}
							>
								Cancel
							</button>
							<button
								type="button"
								className={button.primary}
								onClick={this.handleSubmitClick}
							>
								Submit
							</button>
						</div>
					</div>
				</div>
			</Modal>
		)
	}
}
