import React, { Component } from "react"
import Modal from "react-modal"
import { Cross2Icon } from "@radix-ui/react-icons"
import modal from "shared/styles/components/modal.css"
import button from "shared/styles/components/buttons.css"
import form from "shared/styles/components/form.css"
import textfield from "shared/styles/components/textfield.css"
import LoadingDots from "../../loading-dots/loading-dots"

class VideoThumb extends Component {
   constructor(props) {
      super(props)
      this.state = {
         shopcastId: null,
         shopcastList: [],
      }
   }

   componentDidUpdate() {
      const { shopcastList } = this.props
      const { shopcastList: shopcastListInState } = this.state
      if (shopcastList && shopcastList !== shopcastListInState) {
         this.setState({ shopcastList })
      }
   }

   render() {
      const {
         openModal,
         closeModal,
         approveEvent,
         declineEvent,
         title,
         approveText,
         declineText,
         approveButtonStyle,
         loading,
         onChange,
         ...otherProps
      } = this.props
      const { shopcastList, shopcastId } = this.state
      return (
         <Modal
            className={modal.root}
            overlayClassName={modal.overlay}
            isOpen={openModal}
            {...otherProps}
            ariaHideApp={false}
         >
            <div className={`${modal.dialog} ${modal.dialogCentered}`}>
               <div className={`${modal.content}`}>
                  <div className={modal.header}>
                     {title && <h4 className={modal.title}>{title}</h4>}
                     <button
                        type="button"
                        className={button.close}
                        onClick={closeModal}
                     >
                        <Cross2Icon className={button.closeIcon} />
                     </button>
                  </div>
                  <div className={modal.body}>
                     <div className={form.group}>
                        <label className={form.label}>
                           Set Video Cover For
                        </label>
                        <select
                           className={textfield.normal}
                           onChange={({ target }) =>
                              this.setState({ shopcastId: target.value })
                           }
                        >
                           <option hidden value="">
                              Select Shopcast
                           </option>
                           {shopcastList.map((data) => (
                              <option key={data.id} value={data.id}>
                                 {" "}
                                 {data.title}
                              </option>
                           ))}
                        </select>
                        <p className={form.help}>
                           Note: Replacing the Video Cover will remove any
                           existing covers you have uploaded.
                        </p>
                     </div>
                  </div>
                  <div className={`${modal.footer} ${modal.borderNone}`}>
                     <button
                        type="button"
                        className={button.link}
                        onClick={declineEvent}
                     >
                        {loading ? (
                           <LoadingDots color="var(--gray300)" />
                        ) : (
                           declineText || "Cancel"
                        )}
                     </button>
                     <button
                        type="button"
                        className={approveButtonStyle || button.primary}
                        onClick={() => approveEvent(shopcastId)}
                     >
                        {loading ? (
                           <LoadingDots color="var(--primary80)" />
                        ) : (
                           approveText || "Ok"
                        )}
                     </button>
                  </div>
               </div>
            </div>
         </Modal>
      )
   }
}
export default VideoThumb
