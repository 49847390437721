/* eslint-disable no-await-in-loop */
import React, { Component } from "react"
import Modal from "react-modal"
import Dropzone from "react-dropzone"
import { Cross2Icon } from "@radix-ui/react-icons"
import { toast } from "react-toastify"
// import { isBackgroundRemovedImage } from "helpers/lookbook-helper"
import grids from "shared/styles/components/grids.css"
import form from "shared/styles/components/form.css"
import file from "shared/styles/components/file.css"
import modal from "shared/styles/components/modal.css"
import button from "shared/styles/components/buttons.css"
import progress from "shared/styles/components/progress.css"
import _, { unset } from "lodash"
import gql from "helpers/gql-helper"
import awsService from "services/aws-service"
import LoadingDots from "components/loading-dots/loading-dots"
import UploadFeatureIcon from "shared/assets/feature-icon-upload.svg"
import productImageMutation from "mutations/product/product-image"
import createCustomProductMutation from "mutations/product/create-custom-product"
import setLookbookUploadThumbUrlMutation from "mutations/lookbook/set-lookbook-upload-thumb-url"
import updateCategorizeLookbookUploadMutation from "mutations/lookbook/update-categorize-lookbook-upload"
// import removeImageBackgroundMutation from "mutations/lookbook/remove-image-background"
import UploadCard from "./upload-card"
import MakeProductModalContent from "./make-product-modal-content"
import EditModalContent from "./edit-modal-content"
import styles from "./image-upload.css"
import { getCollectionTitle } from "../../../helpers/string-helper"
import { getChipClass } from "../../../helpers/ui-helper"

async function promiseAllInBatches(task, items, batchSize) {
	let position = 0
	let results = []
	while (position < items.length) {
		const itemsForBatch = items.slice(position, position + batchSize)
		const pos = position
		results = [
			...results,
			...(await Promise.all(
				itemsForBatch.map((item, index) => task(item, pos + index))
			)),
		]
		position += batchSize
	}
	return results
}

class ImageUpload extends Component {
	constructor(props) {
		super(props)
		this.state = {
			files: [],
			edit: null,
			uploading: false,
			editMode: false,
			errors: [],
			saving: false,
			uploadPercentage: 0,
		}
		this.closeEdit = this.closeEdit.bind(this)
		this.upload = this.upload.bind(this)
		this.saveEdit = this.saveEdit.bind(this)
		// this.removeBackground = this.removeBackground.bind(this)
	}

	componentDidUpdate() {
		const { edit, editMode } = this.state
		const { selected, adminAccess } = this.props
		if (selected && edit !== selected && !editMode) {
			this.setState({
				edit: {
					id: selected.id,
					adminAccess: selected.adminAccess
						? selected.adminAccess
						: adminAccess,
					title: selected.title,
					collections: selected.productCollections.map((obj) => ({
						id: obj.id,
						value: obj.title,
						className: getChipClass(obj).chip,
					})),
					url: selected.url,
				},
				errors: [],
				editMode: true,
			})
		}
	}

	getErrors(obj, autoSelect) {
		const errors = []
		if (!obj.title) errors.push("Title is required")
		if (obj.isProduct && !obj.siteUrl) errors.push("URL is required")
		if (
			obj.isProduct &&
			obj.affiliatedUrl &&
			!/https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\\+~#=]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_\\+.~#?&//=]*)/.test(
				obj.affiliatedUrl
			)
		)
			errors.push("Invalid affiliated URL")
		if (!autoSelect && obj.collections.length === 0)
			errors.push("You are required to have at least one collection.")
		return errors
	}

	closeEdit(count) {
		const { editMode } = this.state
		const { closeModal } = this.props
		this.setState({ edit: null, uploading: false, errors: [] })
		if (editMode) {
			closeModal(count || 0)
			this.setState({ editMode: false })
		}
	}

	async upload() {
		const {
			relay,
			closeModal,
			category,
			other,
			autoSelect,
			stylistId,
			team,
		} = this.props
		const waitUntilTitleBlur = () => {
			const { files } = this.state
			const focused = files.find((x) => x.titleInFocus)
			if (focused) setTimeout(() => waitUntilTitleBlur(), 100)
		}
		waitUntilTitleBlur()
		const { files } = this.state
		const data = _.cloneDeep(files)
		const errors = []
		for (let i = 0; i < data.length; i++) {
			const obj = data[i]
			const objErrors = this.getErrors(obj, autoSelect)
			errors.push(...objErrors.map((x) => `${x} (Image ${i + 1})`))
		}
		if (errors.length > 0) {
			this.setState({ errors })
			return
		}
		this.setState({ uploading: true })
		const percentages = new Array(files.length).fill(0)
		await promiseAllInBatches(
			async (dataItem, index) => {
				const x = dataItem
				unset(x, "titleInFocus")
				unset(x, "tempUrl")
				const thumbImage = x.file
				const args = {
					fileType: thumbImage.type,
					ext: thumbImage.name.substring(
						thumbImage.name.lastIndexOf(".") + 1
					),
				}
				unset(x, "file")
				x.collections = x.collections.map((sub) => ({
					id: sub.id.includes("new_") ? null : sub.id,
					value: sub.value.toLowerCase(),
				}))
				if (x.isProduct) {
					await this.createProductFromImage(x, thumbImage)
				} else {
					const mutationRes = await new Promise((resolve, reject) => {
						updateCategorizeLookbookUploadMutation.commit(
							relay.environment,
							category
								? { category, ...x }
								: { ...x, stylist: stylistId, teamId: team },
							args,
							(e) => {
								reject(e)
							},
							(result) => {
								resolve(result)
							},
							other
						)
					})
					const innerData =
						mutationRes?.createCategorizeLookbookUploads ||
						mutationRes?.createLookbookUploads

					const Id = x.id || innerData?.lookbookUploads.id
					const preSignedUrl = innerData?.lookbookUploads?.preSignedUrl

					await awsService.uploadImageAWS(
						preSignedUrl?.signedRequest,
						thumbImage,
						(completed) => {
							percentages[index] = completed
							this.setState({
								uploadPercentage: percentages.reduce(
									(acc, cur) => acc + cur,
									0
								),
							})
						}
					)
					const urlData = {
						uploadId: Id,
						url: preSignedUrl?.url,
					}
					await new Promise((resolve, reject) => {
						setLookbookUploadThumbUrlMutation.commit(
							relay.environment,
							urlData,
							(e) => {
								reject(e)
							},
							(res) => {
								resolve(res)
							}
						)
					})
				}
			},
			data,
			5
		)
		this.setState({ files: [], uploading: false, uploadPercentage: 0 })
		closeModal(data.length)
	}

	async saveEdit() {
		const { edit, editMode, files } = this.state
		const { relay, category, other, team } = this.props
		if (editMode) {
			const data = _.cloneDeep(edit)
			this.setState({ uploading: true })
			unset(data, "canEdit")
			unset(data, "__typename")
			data.collections = data.collections.map((sub) => ({
				id: sub.id.includes("new_") ? null : sub.id,
				value: sub.value.toLowerCase(),
			}))
			data.teamId = team
			const errors = this.getErrors(data)
			if (errors.length > 0) {
				this.setState({ errors })
				return
			}
			if (data.isProduct) {
				await this.createProductFromImage(data, null)
			} else {
				await new Promise((resolve, reject) => {
					updateCategorizeLookbookUploadMutation.commit(
						relay.environment,
						category ? { category, ...data } : data,
						null,
						(e) => {
							toast.info(<>{gql.getError(e)}</>, {
								autoClose: 5000,
								closeButton: false,
							})
							reject(e)
						},
						(result) => {
							toast.success(<>Successfully Updated</>, {
								autoClose: 5000,
								closeButton: false,
							})
							resolve(result)
						},
						other
					)
				})
			}
			this.setState({
				files: [],
				editMode: false,
				uploading: false,
				errors: [],
			})
			this.closeEdit(data.isProduct ? 1 : 0)
		} else {
			const dataFile = files.map((x) =>
				x.tempUrl === edit.tempUrl ? edit : x
			)
			this.setState({ files: dataFile })
			this.closeEdit()
		}
	}

	async createProductFromImage(args, fileObj) {
		const { relay, other } = this.props
		const dataObj = { ...args }
		dataObj.url = dataObj.siteUrl
		dataObj.affiliated_url = dataObj.affiliatedUrl
		dataObj.uploadId = dataObj.id
		unset(dataObj, "id")
		unset(dataObj, "adminAccess")
		unset(dataObj, "isProduct")
		unset(dataObj, "siteUrl")
		unset(dataObj, "affiliatedUrl")
		const data = await new Promise((resolve, reject) => {
			createCustomProductMutation.commit(
				relay.environment,
				dataObj,
				reject,
				resolve,
				other
			)
		})
		if (!fileObj) return
		const { product } = data.createCustomProduct
		await new Promise((resolve, reject) => {
			productImageMutation.commit(
				relay.environment,
				fileObj,
				product,
				resolve,
				reject
			)
		})
	}

	// removeBackground(imgUrl) {
	// 	const { relay } = this.props
	// 	const { edit } = this.state
	// 	const imageUrl = new URL(imgUrl)
	// 	this.setState({ imageEditing: imgUrl, saving: true })
	// 	const returnFunc = () => {
	// 		this.setState({ imageEditing: false, saving: false })
	// 	}

	// 	const successFunc = (updatedImg) => {
	// 		const { edit: editS } = this.state
	// 		toast.success(
	// 			isBackgroundRemovedImage(imgUrl)
	// 					? "Image Restored"
	// 					: "Background Removed",
	// 			{
	// 				autoClose: 2000,
	// 				closeButton: false,
	// 			}
	// 		)
	// 		const updatedEdit = { ...editS, url: updatedImg }
	// 		this.setState({ edit: updatedEdit })
	// 		returnFunc()
	// 	}

	// 	const errorFunc = () => {
	// 		toast.error(
	// 			isBackgroundRemovedImage(imgUrl)
	// 					? "Image Restore Failed"
	// 					: "Background Remove Failed",
	// 			{
	// 				autoClose: 2000,
	// 				closeButton: false,
	// 			}
	// 		)
	// 		returnFunc()
	// 	}

	// 	removeImageBackgroundMutation.commit(
	// 		relay.environment,
	// 		`${imageUrl.origin}${imageUrl.pathname}`,
	// 		"upload",
	// 		edit.id,
	// 		isBackgroundRemovedImage(imgUrl),
	// 		async ({ removeImageBackground }) => {
	// 			if (
	// 				removeImageBackground &&
	// 				removeImageBackground.output &&
	// 				removeImageBackground.output.value
	// 			) {
	// 				successFunc(removeImageBackground.output.value)
	// 			} else {
	// 				errorFunc()
	// 			}
	// 		},
	// 		() => errorFunc()
	// 	)
	// }

	renderEditModalContent() {
		const {
			closeModal,
			adminAccess,
			autoSelect,
			subCategoryList,
			removeImageShoppable,
		} = this.props
		const { edit, errors, saving, imageEditing } = this.state
		return (
			<EditModalContent
				adminAccess={adminAccess}
				edit={edit}
				errors={errors}
				subCategorySet={subCategoryList.map((obj) => ({
					id: obj.id,
					value: getCollectionTitle(obj),
					className: getChipClass(obj).chip,
				}))}
				setData={(data) => this.setState(data)}
				closeModal={(isHeader) => {
					if (isHeader) {
						this.setState({
							files: [],
							editMode: false,
							uploading: false,
							edit: null,
							errors: [],
						})
						closeModal(false)
						return
					}
					this.closeEdit()
				}}
				noCollectionSelect={autoSelect}
				save={this.saveEdit}
				// removeBackground={this.removeBackground}
				imageEditing={imageEditing}
				saving={saving}
				removeImageShoppable={removeImageShoppable}
			/>
		)
	}

	renderMakeProductModalContent() {
		const { closeModal, subCategoryList } = this.props
		const { edit, errors, saving, imageEditing } = this.state
		if (!edit) return null
		return (
			<MakeProductModalContent
				edit={edit}
				errors={errors}
				subCategorySet={subCategoryList.map((obj) => ({
					id: obj.id,
					value: getCollectionTitle(obj),
					className: getChipClass(obj).chip,
				}))}
				setData={(data) => this.setState(data)}
				closeModal={(isHeader) => {
					if (isHeader) {
						this.setState({
							files: [],
							editMode: false,
							uploading: false,
							edit: null,
							errors: [],
						})
						closeModal(false)
						return
					}
					this.closeEdit()
				}}
				save={this.saveEdit}
				// removeBackground={this.removeBackground}
				imageEditing={imageEditing}
				saving={saving}
			/>
		)
	}

	renderListModalContent() {
		const {
			accepted,
			closeModal,
			adminAccess,
			currentCollections,
			autoSelect,
		} = this.props
		const { files, uploading, errors, uploadPercentage } = this.state
		return (
			<div
				className={`${modal.dialog} ${modal.dialogCentered} ${modal.dialogScrollable} ${modal.dialogFullscreenSmDown} ${modal.dialogLg}`}
			>
				<div className={`${modal.content}`}>
					{uploadPercentage > 0 && (
						<div className={styles.progress}>
							<progress
								className={progress.bar}
								max={100}
								value={
									files.length > 0
										? uploadPercentage / files.length
										: 0
								}
							/>
							<h4 className={progress.status}>
								Uploading {files.length}{" "}
								{files.length > 1 ? "files" : "file"}
							</h4>
						</div>
					)}
					<div className={modal.header}>
						<h4 className={modal.title}>Upload Image</h4>
						<button
							type="button"
							className={button.close}
							onClick={() => {
								this.setState({
									files: [],
									editMode: false,
									uploading: false,
									edit: null,
									errors: [],
								})
								closeModal(false)
							}}
						>
							<Cross2Icon className={button.closeIcon} />
						</button>
					</div>
					<div className={modal.body}>
						{errors.length > 0 && (
							<div className={form.group}>
								<div className={form.error}>
									{errors.map((i) => (
										<div key={i}>{i}</div>
									))}
								</div>
							</div>
						)}
						<Dropzone
							onDrop={(acceptedFiles) => {
								this.setState({
									files: [
										...files,
										...acceptedFiles.map((fileObj) => ({
											title: fileObj.name.split(".")[0],
											file: fileObj,
											collections: currentCollections || [],
											adminAccess,
											url: null,
											tempUrl: URL.createObjectURL(fileObj),
										})),
									],
								})
							}}
							noKeyboard
							accept={accepted}
						>
							{({ getRootProps, getInputProps }) => (
								<div className="container">
									<div className={file.root}>
										<div
											{...getRootProps()}
											className={file.dropRect}
										>
											<input {...getInputProps()} />
											<UploadFeatureIcon className={file.iconLg} />
											<p className={file.label}>
												<b>Click to upload</b> or drag and drop
											</p>
										</div>
									</div>
									{files.length > 0 && (
										<div className={styles.content}>
											<div className={grids.image}>
												{files.map((fileObj) => (
													<UploadCard
														key={fileObj.tempUrl}
														file={fileObj}
														deleteFile={() => {
															this.setState({
																files: files.filter(
																	(i) => i !== fileObj
																),
															})
														}}
														editFile={
															!autoSelect &&
															(() =>
																this.setState({
																	edit: fileObj,
																}))
														}
														updateFile={(key, value) => {
															const { files: stateFiles } =
																this.state
															const index = stateFiles.findIndex(
																(i) => i === fileObj
															)
															const editedFiles = [...files]
															editedFiles[index][key] = value
															this.setState({
																files: editedFiles,
															})
														}}
													/>
												))}
											</div>
										</div>
									)}
								</div>
							)}
						</Dropzone>
					</div>
					<div className={modal.footer}>
						<button
							type="button"
							className={button.link}
							onClick={() => {
								this.setState({
									files: [],
									editMode: false,
									uploading: false,
									edit: null,
									errors: [],
								})
								closeModal(false)
							}}
						>
							{uploading ? (
								<LoadingDots color="var(--gray300)" />
							) : (
								"Cancel"
							)}
						</button>
						<button
							type="submit"
							className={button.primary}
							onClick={this.upload}
						>
							{uploading ? (
								<LoadingDots color="var(--primary80)" />
							) : (
								"Upload"
							)}
						</button>
					</div>
				</div>
			</div>
		)
	}

	renderModalContent() {
		const { edit } = this.state
		if (edit && edit.isProduct) return this.renderMakeProductModalContent()
		if (edit && !edit.isProduct) return this.renderEditModalContent()
		return this.renderListModalContent()
	}

	render() {
		const { openModal, ...otherProps } = this.props
		return (
			<Modal
				className={modal.root}
				overlayClassName={modal.overlay}
				isOpen={openModal}
				{...otherProps}
				ariaHideApp={false}
				onAfterOpen={this.initailCanvas}
			>
				{this.renderModalContent()}
			</Modal>
		)
	}
}

export default ImageUpload
