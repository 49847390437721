/**
 * @generated SignedSource<<b0b65e2a7e8cd65296fbce64fc983f74>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Query } from 'relay-runtime';
export type pricingCouponQuery$variables = {|
  code: string,
|};
export type pricingCouponQuery$data = {|
  +store: ?{|
    +coupon: ?{|
      +amountOff: ?number,
      +id: ?string,
      +name: ?string,
      +percentOff: ?number,
      +products: ?$ReadOnlyArray<?string>,
    |},
    +id: string,
  |},
|};
export type pricingCouponQuery = {|
  response: pricingCouponQuery$data,
  variables: pricingCouponQuery$variables,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "code"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "Store",
    "kind": "LinkedField",
    "name": "store",
    "plural": false,
    "selections": [
      (v1/*: any*/),
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "code",
            "variableName": "code"
          }
        ],
        "concreteType": "Coupon",
        "kind": "LinkedField",
        "name": "coupon",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "amountOff",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "percentOff",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "products",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "pricingCouponQuery",
    "selections": (v2/*: any*/),
    "type": "ShopshareQueryRoot",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "pricingCouponQuery",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "6815c9b3ecad1649e36f8de29aa2396a",
    "id": null,
    "metadata": {},
    "name": "pricingCouponQuery",
    "operationKind": "query",
    "text": "query pricingCouponQuery(\n  $code: String!\n) {\n  store {\n    id\n    coupon(code: $code) {\n      id\n      amountOff\n      percentOff\n      name\n      products\n    }\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "48b44a7fc7bee973b6d6b61f059c227e";

module.exports = ((node/*: any*/)/*: Query<
  pricingCouponQuery$variables,
  pricingCouponQuery$data,
>*/);
