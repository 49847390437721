import React from "react"
import LandingActions from "components/landing-actions/landing-actions"
import styles from "./page-footer-card.css"

export default function PageFooterCard({ title, lead, image, currentUser }) {
	return (
		<div className={styles.root}>
			<div className={styles.content}>
				<h2 className={styles.headline}>{title}</h2>
				<p className={styles.body}>{lead}</p>
				{!currentUser && <LandingActions theme="dark" noDemo />}
			</div>
			<div className={styles.media}>
				<img
					className={styles.image}
					alt="Hero"
					src={image}
				/>
			</div>
		</div>
	)
}
