import React from "react"
import { ValueNoneIcon, BorderSolidIcon } from "@radix-ui/react-icons"
import StrokeIcon from "shared/assets/lb-stroke-icon.svg"
import Slider from "components/lookbook-dropdown/slider"
import ColorPicker from "components/lookbook-dropdown/color-picker"
import LookbookDropdown from "components/lookbook-dropdown/lookbook-dropdown"
import dropdownStyles from "components/lookbook-dropdown/lookbook-dropdown.css"
import styles from "./konva-filters.css"

function ShapeStroke({
	borderColor,
	borderWidth,
	dataTip,
	runDebounce,
	changeShapeProp,
}) {
	const style = {}
	if (borderWidth) style.border = `2px solid ${borderColor}`
	return (
		<LookbookDropdown
			label={<StrokeIcon className={dropdownStyles.icon} style={style} />}
			dataTip={dataTip}
			runDebounce={runDebounce}
		>
			<div className={dropdownStyles.panelFilters}>
				<div className={styles.content}>
					<div className={styles.item}>
						<h4 className={styles.title}>Border Style</h4>
						<div className={styles.itemContent}>
							<div className={styles.itemButtonGroup}>
								<button
									type="button"
									data-tooltip-id="tooltip"
									data-tooltip-content="None"
									className={
										borderWidth
											? styles.toolDark
											: styles.toolDarkActive
									}
									onClick={() =>
										changeShapeProp({
											borderWidth: 0,
										})
									}
								>
									<ValueNoneIcon className={styles.icon} />
								</button>
								<button
									type="button"
									data-tooltip-id="tooltip"
									data-tooltip-content="Solid"
									className={
										borderWidth
											? styles.toolDarkActive
											: styles.toolDark
									}
									onClick={() =>
										changeShapeProp({
											borderWidth:
												parseFloat(localStorage.getItem("konvaBorderWidth") ||
													1),
										})
									}
								>
									<BorderSolidIcon className={styles.icon} />
								</button>
							</div>
						</div>
					</div>
					<div className={dropdownStyles.divider} />
					<div className={styles.item}>
						<h4 className={styles.title}>Border Colour</h4>
						<div className={styles.itemContent}>
							<ColorPicker
								color={borderColor}
								onChange={(colorValue) =>
									changeShapeProp({ borderColor: colorValue })
								}
							/>
						</div>
					</div>
					<div className={dropdownStyles.divider} />
					<div className={styles.item}>
						<Slider
							title="Border Weight"
							min={0}
							max={100}
							step={1}
							isDark
							value={borderWidth}
							onSlide={({ target }) => {
								const val = parseFloat(target.value)
								changeShapeProp({
									borderWidth: val,
								})
								localStorage.setItem("konvaBorderWidth", val)
							}}
						/>
					</div>
				</div>
			</div>
		</LookbookDropdown>
	)
}
export default ShapeStroke
