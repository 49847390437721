/**
 * @generated SignedSource<<78c3a79ef97523d54c1c360b47f3c1f9>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Mutation } from 'relay-runtime';
export type SetClosetOwnedItemsInput = {|
  clientId: string,
  clientMutationId?: ?string,
  itemId: string,
|};
export type setClosetOwnedItemsMutation$variables = {|
  input: SetClosetOwnedItemsInput,
|};
export type setClosetOwnedItemsMutation$data = {|
  +setClosetOwnedItems: ?{|
    +store: ?{|
      +id: string,
    |},
  |},
|};
export type setClosetOwnedItemsMutation = {|
  response: setClosetOwnedItemsMutation$data,
  variables: setClosetOwnedItemsMutation$variables,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "SetClosetOwnedItemsPayload",
    "kind": "LinkedField",
    "name": "setClosetOwnedItems",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Store",
        "kind": "LinkedField",
        "name": "store",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "setClosetOwnedItemsMutation",
    "selections": (v1/*: any*/),
    "type": "ShopshareMutationRoot",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "setClosetOwnedItemsMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "3976a4a9c0df28e79f7f9818f2e435dd",
    "id": null,
    "metadata": {},
    "name": "setClosetOwnedItemsMutation",
    "operationKind": "mutation",
    "text": "mutation setClosetOwnedItemsMutation(\n  $input: SetClosetOwnedItemsInput!\n) {\n  setClosetOwnedItems(input: $input) {\n    store {\n      id\n    }\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "c84a6f3c194320798e8daa89d6a1e268";

module.exports = ((node/*: any*/)/*: Mutation<
  setClosetOwnedItemsMutation$variables,
  setClosetOwnedItemsMutation$data,
>*/);
