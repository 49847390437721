import { Cross2Icon } from "@radix-ui/react-icons"
import React from "react"
import { isBackgroundRemovedImage } from "helpers/lookbook-helper"
import { addVersioning } from "helpers/query-parameters-helper"
// import RemoveBgIcon from "shared/assets/lb-removebg-icon.svg"
import AutoSuggest from "components/auto-suggest-new/auto-suggest"
import modal from "shared/styles/components/modal.css"
import form from "shared/styles/components/form.css"
import button from "shared/styles/components/buttons.css"
import textfield from "shared/styles/components/textfield.css"
import toggle from "shared/styles/components/toogle.css"
// import icons from "shared/styles/components/icons.css"
import styles from "./image-upload.css"

export default function MakeProductModalContent({
	edit,
	errors,
	subCategorySet,
	setData,
	closeModal,
	save,
	// removeBackground,
	// imageEditing,
}) {
	const onSelect = (productCollectionsArray) => {
		const newCollection = []
		productCollectionsArray.forEach((collection) => {
			if (collection.id.includes("new_")) {
				newCollection.push(collection)
			}
		})
		setData({
			edit: { ...edit, collections: productCollectionsArray },
			subCategorySet: [...subCategorySet, ...newCollection],
			errors: [],
		})
	}

	return (
		<div
			className={`${modal.dialog} ${modal.dialogCentered} ${modal.dialogFullscreenSmDown} ${modal.dialogLg}`}
		>
			<div className={`${modal.content}`}>
				<div className={modal.header}>
					<h4 className={modal.title}>Edit Image</h4>
					<button
						type="button"
						className={button.close}
						onClick={() => closeModal(true)}
					>
						<Cross2Icon className={button.closeIcon} />
					</button>
				</div>
				<div className={modal.body}>
					<div className={form.row}>
						<div className={styles.col}>
							<div className={form.group}>
								<label className={form.label}>Image</label>
								<div
									className={
										edit.url && isBackgroundRemovedImage(edit.url)
											? styles.productTrans
											: styles.product
									}
								>
									{/* <div className={styles.actions}>
										{edit.url && removeBackground && (
											<button
												type="button"
												className={button.circle}
												onClick={() => removeBackground(edit.url)}
												disabled={!!imageEditing}
											>
												{isBackgroundRemovedImage(edit.url) ? (
													<ResetIcon className={icons.icon18} />
												) : (
													<RemoveBgIcon className={icons.icon18} />
												)}
											</button>
										)}
									</div> */}
									<img
										src={
											edit.url
												? addVersioning(edit.url, `d=400`)
												: edit.tempUrl
										}
										alt={edit.title}
										className={styles.image}
									/>
								</div>
							</div>
						</div>
						<div className={styles.col}>
							<div className={form.group}>
								{errors.length > 0 && (
									<div className={form.error}>
										{errors.map((i) => (
											<div key={i}>{i}</div>
										))}
									</div>
								)}
							</div>
							<div className={form.group}>
								<label className={form.label}>Title</label>
								<input
									className={textfield.normal}
									defaultValue={edit.title}
									onChange={({ target }) => {
										setData({
											edit: {
												...edit,
												title: target.value,
											},
											errors: [],
										})
									}}
								/>
							</div>
							<div className={form.group}>
								<label className={form.label}>Brand</label>
								<input
									type="text"
									className={textfield.normal}
									defaultValue={edit.brand}
									onChange={({ target }) => {
										setData({
											edit: {
												...edit,
												brand: target.value,
											},
										})
									}}
								/>
							</div>

							<div className={form.row}>
								<div className={form.group}>
									<label className={form.label}>Price</label>
									<input
										type="text"
										className={textfield.normal}
										defaultValue={edit.regularPrice}
										onChange={({ target }) => {
											setData({
												edit: {
													...edit,
													regularPrice: target.value,
												},
											})
										}}
									/>
								</div>

								<div className={form.group}>
									<label className={form.label}>Offer Price</label>
									<input
										type="text"
										className={textfield.normal}
										defaultValue={edit.offerPrice}
										onChange={({ target }) => {
											setData({
												edit: {
													...edit,
													offerPrice: target.value,
												},
											})
										}}
									/>
								</div>
							</div>

							<div className={form.group}>
								<label className={form.label}>Product Link</label>
								<input
									type="text"
									placeholder="Type or paste the product URL"
									className={textfield.normal}
									defaultValue={edit.siteUrl}
									onChange={({ target }) => {
										setData({
											edit: {
												...edit,
												siteUrl: target.value,
											},
											errors: [],
										})
									}}
								/>
							</div>

							<div className={form.group}>
								<label className={form.label}>
									Custom Affiliate Link
								</label>
								<input
									type="url"
									className={textfield.normal}
									placeholder="Use your own Affiliate Link"
									defaultValue={edit.affiliatedUrl}
									onChange={({ target }) => {
										setData({
											edit: {
												...edit,
												affiliatedUrl: target.value,
											},
											errors: [],
										})
									}}
								/>
							</div>

							<div className={form.group}>
								<label className={form.label}>Collections</label>
								<AutoSuggest
									result={edit.collections}
									dataList={subCategorySet}
									onChange={onSelect}
								/>
							</div>
						</div>
					</div>
				</div>
				<div className={modal.footer}>
					<div className={styles.toggle}>
						<label className={toggle.check}>
							<div className={toggle.switch}>
								<input
									type="checkbox"
									checked={edit.isProduct}
									onChange={() =>
										setData({
											edit: {
												...edit,
												isProduct: false,
											},
											errors: [],
										})
									}
								/>
								<div className={toggle.slider} />
							</div>
							<div className={toggle.label}>Make Image Shoppable</div>
						</label>
					</div>
					<button
						type="button"
						className={button.link}
						onClick={() => closeModal(false)}
					>
						Cancel
					</button>
					<button
						type="submit"
						className={button.primary}
						onClick={() => save()}
					>
						Save
					</button>
				</div>
			</div>
		</div>
	)
}
