import React, { Component, Fragment } from "react"
import twoColumn from "shared/styles/layout/two-column.css"
import controls from "shared/styles/layout/controls.css"
import textfield from "shared/styles/components/textfield.css"
import form from "shared/styles/components/form.css"
import pageContent from "shared/styles/layout/page-content.css"
import { uniqBy } from "lodash"
import AutoSuggest from "components/auto-suggest-new/auto-suggest"
import DatepickerDropdown from "components/datepicker-dropdown/datepicker-dropdown"
import AdminTransactionsReportList from "./admin-transactions-report-list"
import { getComponent, withOutletContext } from "../../../route-helpers"

class AdminTransactionsReport extends Component {
	constructor(props) {
		super(props)
		this.state = {
			dates: null,
			currency: "",
			network: "",
			users: [],
		}
	}

	renderList() {
		const { dates, currency, network, users } = this.state
		const userIds = users.map((i) => i.id)
		const AdminTransactionsReportListComp = getComponent(
			`admin-transactions-report-list`,
			AdminTransactionsReportList,
			{
				count: 12,
				currency,
				network,
				...dates,
				users: userIds,
			},
			{
				count: 12,
				currency,
				network,
				...dates,
				users: userIds,
			}
		)
		return AdminTransactionsReportListComp
	}

	render() {
		const {
			filters: { networks, partneredNetworks },
			admin: { users: allUsers },
		} = this.props
		const { currency, network, users } = this.state
		return (
			<Fragment>
				<div className={twoColumn.header}>
					<h1 className={twoColumn.title}>Commission Transactions</h1>
				</div>
				<div className={pageContent.root}>
					<div className={controls.root}>
						<div className={controls.search}>
							<div className={controls.group}>
								<label className={form.label}>Users</label>
								<AutoSuggest
									result={users}
									dataList={allUsers.edges.map(({ node }) => ({
										id: node.id,
										value: node.fullname,
									}))}
									onChange={(result) => {
										this.setState({
											users: result,
										})
									}}
									noNew
								/>
							</div>
						</div>
						<div className={controls.filters}>
							<div className={controls.group}>
								<label className={form.label}>Period</label>
								<DatepickerDropdown
									localStorageKey="admin-transaction-report"
									onChange={(sd, ed) => {
										this.setState({
											dates: { startDate: sd, endDate: ed },
										})
									}}
								/>
							</div>
							<div className={controls.group}>
								<label className={form.label}>Currency</label>
								<select
									className={textfield.cozy}
									value={currency}
									onChange={(e) => {
										this.setState({ currency: e.target.value })
									}}
								>
									<option value="">All</option>
									{uniqBy(
										networks.edges,
										({ node }) => node.currency
									).map(({ node }) => (
										<option value={node.currency} key={node.currency}>
											{node.currency}
										</option>
									))}
								</select>
							</div>

							<div className={controls.group}>
								<label className={form.label}>Partner</label>
								<select
									className={textfield.cozy}
									value={network}
									onChange={(e) => {
										this.setState({ network: e.target.value })
									}}
								>
									<option value="">All</option>
									{partneredNetworks.map((i) => (
										<option value={i.key} key={i.key}>
											{i.value}
										</option>
									))}
								</select>
							</div>
						</div>
					</div>
					{this.renderList()}
				</div>
			</Fragment>
		)
	}
}

export default withOutletContext(AdminTransactionsReport)
