import React, { useCallback } from "react"
import { toast } from "react-toastify"
import DropdownMenu from "components/dropdown-menu/dropdown-menu"
import stylesDropdown from "components/dropdown-menu/dropdown-menu-dark.css"
import MenuIcon from "shared/assets/menu-dots-horizontal.svg"
// import ShareIcon from "shared/assets/share-icon.svg"
import buttons from "shared/styles/components/buttons.css"
import icons from "shared/styles/components/icons.css"
import {
	CheckCircledIcon,
	CrumpledPaperIcon,
	// EyeOpenIcon,
	InputIcon,
	// Link1Icon,
	MinusIcon,
	PlusIcon,
	EnterIcon,
	PaperPlaneIcon,
	Pencil1Icon,
	PersonIcon,
	ResetIcon,
	StarFilledIcon,
	StarIcon,
	TrashIcon,
	CopyIcon,
} from "@radix-ui/react-icons"
import PinIcon from "shared/assets/pin-icon.svg"
import UnPinIcon from "shared/assets/unpin-icon.svg"
import FolderIcon from "shared/assets/folder-icon.svg"
import FolderShareIcon from "shared/assets/folder-share-icon.svg"
import toggleFavoriteShopcastItemMutation from "mutations/shopcast-manager/toggle-favorite-shopcast-item"
import toggleShopcastFolderSharingMutation from "mutations/shopcast-manager/toggle-shopcast-folder-sharing"
import restoreShopcastItemMutation from "mutations/shopcast-manager/restore-shopcast-item"
import archiveShopcastItemMutation from "mutations/shopcast-manager/archive-shopcast-item"
import deleteShopcastFolderMutation from "mutations/shopcast-manager/delete-shopcast-folder"
import moveShopcastItemsMutation from "mutations/shopcast-manager/move-shopcast-items"
import setPinnedItemsMutation from "mutations/shopcast-manager/set-pinned-items"
import deleteClientMutation from "mutations/client/delete-client"
import sendClientInviteMutation from "mutations/client/send-client-invite.js"
import CopyToMutation from "mutations/shopcast-manager/copy-to"
import { FeatureKeyENUM, runIfFeatureAvailable } from "services/plan-services"
import { withRouter } from "../../../route-helpers"

function FolderMenu({
	folder,
	navigate,
	location,
	setDropdownOpened,
	canStar,
	relay,
	container,
	setClientFolder,
	confirmDelete,
	requestMove,
	requestCopy,
	redirectAfterDelete,
	showGoToFolder,
	onRenameClick,
	onSelectItems,
	team,
	otherProps,
}) {
	if (folder.path.length === 0) return null

	const toggleFavoriteFolder = useCallback(() => {
		toggleFavoriteShopcastItemMutation.commit(
			relay.environment,
			folder.id,
			"folder",
			() => {
				toast.error(<>Couldnt complete action</>, {
					autoClose: 2000,
					closeButton: false,
				})
			},
			container
		)
	}, [folder, relay, container])

	const handleRestoreClick = useCallback(() => {
		restoreShopcastItemMutation.commit(
			relay.environment,
			folder.id,
			"folder",
			otherProps?.folder || folder.parent.id,
			() => {
				toast.error(<>Could not restore folder</>, {
					autoClose: 2000,
					closeButton: false,
				})
			},
			container,
			() => {
				let parents = []
				if (!folder.inRoot) {
					parents = [...folder.path]
					parents.shift()
				}
				const movedTo = folder.inRoot
					? folder.parent.name
					: parents
							.reverse()
							.map((i) => i.name)
							.join(" / ")
				toast.success(<div>Moved to {movedTo}</div>, {
					autoClose: 2000,
					closeButton: false,
				})
			}
		)
	}, [folder, relay, container])

	const handleArchiveClick = useCallback(() => {
		archiveShopcastItemMutation.commit(
			relay.environment,
			folder.id,
			"folder",
			otherProps?.folder || folder.parent.id,
			() => {
				toast.error(<>Could not archive folder</>, {
					autoClose: 2000,
					closeButton: false,
				})
			},
			container,
			() => {}
		)
	}, [folder, relay, container])

	const sendClientInvite = useCallback(() => {
		sendClientInviteMutation.commit(
			relay.environment,
			folder.clients[0].id,
			() => {
				toast.success(<>Invite sent successfully</>, {
					autoClose: 2000,
					closeButton: false,
				})
			},
			() => {
				toast.error(<>Could not send client invite</>, {
					autoClose: 2000,
					closeButton: false,
				})
			}
		)
	}, [folder, relay])

	const copyRequest = useCallback(() => {
		requestCopy((t) => {
			CopyToMutation.commit(
				relay.environment,
				[folder.id],
				t.id,
				() => {
					toast.error(<>Could not copy folder</>, {
						autoClose: 2000,
						closeButton: false,
					})
				},
				() => {
					toast.success(<>Successfully copied</>, {
						autoClose: 2000,
						closeButton: false,
					})
				}
			)
		})
	}, [folder, relay])

	const getListItems = useCallback(() => {
		const isClient = folder.clients && folder.clients.length > 0
		const listItems = []
		const push = (click, name, key, icon) =>
			listItems.push({
				click,
				name,
				key,
				icon,
			})
		if (folder.isArchived) {
			push(
				() => {
					handleRestoreClick()
				},
				"Restore",
				"restore",
				<ResetIcon className={stylesDropdown.itemIcon} />
			)
		} else {
			if (!folder.inRoot && showGoToFolder) {
				push(
					() => navigate(`/content/${folder.parent.id}`),
					"Go to Folder",
					"gtf",
					<EnterIcon className={`${stylesDropdown.itemIcon}`} />
				)
				listItems.push("divide")
			}
			if (!folder.isParentAClient) {
				push(
					() => {
						if (!isClient && !folder.sharableLink)
							toggleShopcastFolderSharingMutation.commit(
								relay.environment,
								folder.id,
								() => {
									navigate(`/edit-preview/${folder.id}`, {
										state: { prevUrl: location.pathname },
									})
								},
								(err) => {
									console.log(err)
								}
							)
						else
							navigate(`/edit-preview/${folder.id}`, {
								state: { prevUrl: location.pathname },
							})
					},
					isClient ? "Edit Client Channel" : "Edit Share Folder",
					"editCC",
					isClient ? (
						<Pencil1Icon className={stylesDropdown.itemIcon} />
					) : (
						<FolderShareIcon className={stylesDropdown.itemIcon} />
					)
				)
				listItems.push("divide")
			}

			if (!!folder.isParentAClient) {
				push(
					() => {
						toggleShopcastFolderSharingMutation.commit(
							relay.environment,
							folder.id,
							() => {},
							(err) => {
								console.log(err)
							}
						)
					},
					!!folder.sharableLink
						? `Remove from Client Channel`
						: `Add to Client Channel`,
					"share",
					!!folder.sharableLink ? (
						<MinusIcon className={stylesDropdown.itemIcon} />
					) : (
						<PlusIcon className={stylesDropdown.itemIcon} />
					)
				)
				listItems.push("divide")
			}
			// if (!isClient && !folder.isParentAClient && folder.sharableLink) {
			// 	push(
			// 		() => {
			// 			navigator.clipboard.writeText(folder.sharableLink)
			// 			toast.success(<>Copied</>, {
			// 				autoClose: 2000,
			// 				closeButton: false,
			// 			})
			// 		},
			// 		"Get Shareable Link",
			// 		"clipboard",
			// 		<Link1Icon className={stylesDropdown.itemIcon} />
			// 	)
			// 	push(
			// 		() => window.open(folder.sharableLink, "_blank"),
			// 		"View Folder Channel",
			// 		"view",
			// 		<EyeOpenIcon className={stylesDropdown.itemIcon} />
			// 	)
			// 	listItems.push("divide")
			// }
			if (isClient) {
				if (!folder.clients[0].isUserAlreadyExist)
					push(
						sendClientInvite,
						"Send Client Invite",
						"sci",
						<PaperPlaneIcon className={stylesDropdown.itemIcon} />
					)
				push(
					() => setClientFolder(folder.clients[0]),
					"Edit Client Details",
					"edit",
					<PersonIcon className={stylesDropdown.itemIcon} />
				)
				listItems.push("divide")
			}
			if (!isClient && onRenameClick)
				push(
					onRenameClick,
					"Rename",
					"rename",
					<InputIcon className={stylesDropdown.itemIcon} />
				)
			if (!!canStar)
				push(
					toggleFavoriteFolder,
					folder.isStarred ? "Unstar Folder" : "Star Folder",
					"star",
					folder.isStarred ? (
						<StarFilledIcon className={stylesDropdown.itemIcon} />
					) : (
						<StarIcon className={stylesDropdown.itemIcon} />
					)
				)
			push(
				() => {
					setPinnedItemsMutation.commit(
						relay.environment,
						folder.id,
						() => {},
						() => {
							toast.error("Something went wrong", {
								autoClose: 5000,
								closeButton: false,
							})
						},
						otherProps
					)
				},
				folder.isPinned ? "Unpin Folder" : "Pin to Folder",
				"pin",
				folder.isPinned ? (
					<UnPinIcon className={stylesDropdown.itemIcon} />
				) : (
					<PinIcon className={stylesDropdown.itemIcon} />
				)
			)
			if (isClient) {
				if (requestCopy) {
					listItems.push("divide")
					push(
						copyRequest,
						"Copy to...",
						"copy",
						<CopyIcon className={stylesDropdown.itemIcon} />
					)
				}
				listItems.push("divide")
			}
			if (!isClient && !folder.isParentAClient) {
				listItems.push("divide")
				push(
					runIfFeatureAvailable(
						() =>
							setClientFolder({
								folder: folder.id,
								fullname: folder.name,
								avatar: folder.avatar,
							}),
						FeatureKeyENUM.CLIENT_CRM,
						relay.environment,
						team
					),
					"Convert to Client",
					"cnc",
					<PersonIcon className={stylesDropdown.itemIcon} />
				)
			}
			if (!isClient) {
				listItems.push("divide")
				push(
					() => {
						requestMove(
							(folderId) => {
								moveShopcastItemsMutation.commit(
									relay.environment,
									[{ id: folder.id, type: "folder" }],
									folderId,
									otherProps?.folder || folder.parent.id,
									container,
									() => {
										toast.error(<>Could not move folder</>, {
											autoClose: 2000,
											closeButton: false,
										})
									}
								)
							},
							[folder.id]
						)
					},
					"Move to...",
					"move",
					<FolderIcon className={stylesDropdown.itemIcon} />
				)
				if (requestCopy)
					push(
						copyRequest,
						"Copy to...",
						"copy",
						<CopyIcon className={stylesDropdown.itemIcon} />
					)
				listItems.push("divide")
			}
			if (!isClient && onSelectItems)
				push(
					onSelectItems,
					"Select Items",
					"select",
					<CheckCircledIcon className={stylesDropdown.itemIcon} />
				)
			if (!isClient) {
				listItems.push("divide")
				push(
					handleArchiveClick,
					"Move to Trash",
					"trash",
					<CrumpledPaperIcon className={stylesDropdown.itemIcon} />
				)
			}
		}
		push(
			() =>
				isClient
					? confirmDelete({
							type: "client",
							name: folder.clients[0].fullname,
							callback: () =>
								deleteClientMutation.commit(
									relay.environment,
									folder.clients[0].id,
									() => {
										toast.error(<>Could not delete folder</>, {
											autoClose: 2000,
											closeButton: false,
										})
									},
									() => {
										toast.success(<>Client deleted successfully</>, {
											autoClose: 2000,
											closeButton: false,
										})
										if (redirectAfterDelete) navigate("/content")
									}
								),
					  })
					: confirmDelete({
							type: "folder",
							name: folder.name,
							callback: () =>
								deleteShopcastFolderMutation.commit(
									relay.environment,
									folder.id,
									otherProps?.folder || folder.parent.id,
									container,
									() => {
										toast.error(<>Could not delete folder</>, {
											autoClose: 2000,
											closeButton: false,
										})
									},
									() => {
										if (redirectAfterDelete) navigate("/content")
									}
								),
					  }),
			"Delete Forever",
			"delete",
			<TrashIcon className={stylesDropdown.itemIcon} />
		)
		return listItems
	}, [folder])

	return (
		<DropdownMenu
			items={getListItems()}
			darkTheme
			contentRight
			mainIcon={<MenuIcon className={icons.icon24} />}
			triggerClass={`${buttons.subtleIcon} ${buttons.iconLg}`}
			triggerClassActive={buttons.subtleIconActive}
			setDropdownOpened={setDropdownOpened}
		/>
	)
}

export default withRouter(FolderMenu)
