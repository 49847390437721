/**
 * @generated SignedSource<<c9971a8de11ffafcf2c68d74671d1fab>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Mutation } from 'relay-runtime';
export type ReportShopcastInput = {|
  clientMutationId?: ?string,
  description?: ?string,
  reportType: string,
  shopcastId: string,
|};
export type reportShopcastMutation$variables = {|
  input: ReportShopcastInput,
|};
export type reportShopcastMutation$data = {|
  +reportShopcast: ?{|
    +store: ?{|
      +id: string,
      +shopcast: ?{|
        +id: string,
      |},
    |},
  |},
|};
export type reportShopcastMutation = {|
  response: reportShopcastMutation$data,
  variables: reportShopcastMutation$variables,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "ReportShopcastPayload",
    "kind": "LinkedField",
    "name": "reportShopcast",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Store",
        "kind": "LinkedField",
        "name": "store",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Shopcast",
            "kind": "LinkedField",
            "name": "shopcast",
            "plural": false,
            "selections": [
              (v1/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "reportShopcastMutation",
    "selections": (v2/*: any*/),
    "type": "ShopshareMutationRoot",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "reportShopcastMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "70f537ff7e0baa048e416acdd6b62121",
    "id": null,
    "metadata": {},
    "name": "reportShopcastMutation",
    "operationKind": "mutation",
    "text": "mutation reportShopcastMutation(\n  $input: ReportShopcastInput!\n) {\n  reportShopcast(input: $input) {\n    store {\n      id\n      shopcast {\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "bd3bc6a7f269552aa69af3015b31a3c1";

module.exports = ((node/*: any*/)/*: Mutation<
  reportShopcastMutation$variables,
  reportShopcastMutation$data,
>*/);
