/**
 * @generated SignedSource<<16e5d78e91e89d380fe05bfaa47b884f>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Mutation } from 'relay-runtime';
export type UpdateClientInput = {|
  avatar?: ?string,
  clientMutationId?: ?string,
  email: string,
  fullname: string,
  id: string,
|};
export type updateClientMutation$variables = {|
  input: UpdateClientInput,
|};
export type updateClientMutation$data = {|
  +updateClient: ?{|
    +store: ?{|
      +id: string,
    |},
  |},
|};
export type updateClientMutation = {|
  response: updateClientMutation$data,
  variables: updateClientMutation$variables,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UpdateClientPayload",
    "kind": "LinkedField",
    "name": "updateClient",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Store",
        "kind": "LinkedField",
        "name": "store",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "updateClientMutation",
    "selections": (v1/*: any*/),
    "type": "ShopshareMutationRoot",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "updateClientMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "a5dd9838cd06a5727c8768ce9cd83603",
    "id": null,
    "metadata": {},
    "name": "updateClientMutation",
    "operationKind": "mutation",
    "text": "mutation updateClientMutation(\n  $input: UpdateClientInput!\n) {\n  updateClient(input: $input) {\n    store {\n      id\n    }\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "d6695f628634a6ce8965db88cccadc2e";

module.exports = ((node/*: any*/)/*: Mutation<
  updateClientMutation$variables,
  updateClientMutation$data,
>*/);
