import { commitMutation, graphql } from "react-relay"
import { ConnectionHandler } from "relay-runtime"
import { complete } from "../helper"
import connections from "../connections"

const mutation = graphql`
	mutation restoreShopcastItemMutation($input: RestoreShopcastItemInput!) {
		restoreShopcastItem(input: $input) {
			parentFolderId
		}
	}
`

const commit = (env, id, type, folder, onError, containerId, success) =>
	commitMutation(env, {
		mutation,
		variables: { input: { id, type } },
		onError: complete(null, onError),
		updater: (storeProxy, dd) => {
			if (dd.restoreShopcastItem) {
				success()
				const folderProxy = storeProxy.get(folder)
				let connection = null
				switch (type) {
					case "folder":
						connection = connections.currentUser.shopcastManager.folders
						break
					case "shopcast":
					case "lookbook":
					case "shopshareFile":
						connection = connections.currentUser.shopcastManager.items
						connection.data = {...connection.data, type: type === "shopshareFile" ? "file" : type}
						break
					default:
						break
				}
				const connectionProxy = ConnectionHandler.getConnection(
					folderProxy,
					connection.name,
					{...connection.data, isShared: null}
				)
				if (connectionProxy)
					ConnectionHandler.deleteNode(connectionProxy, id)

				const containerProxy = storeProxy.get(containerId)
				
				const itemProxy = storeProxy.get(id)
				const isInRoot = itemProxy.getValue("inRoot")

				if (type === "shopcast" || type === "lookbook" || type === "shopshareFile") {
					containerProxy.setValue(
						containerProxy.getValue("archivedItemCount") - 1,
						"archivedItemCount"
					)
					containerProxy.setValue(
						containerProxy.getValue("rootItemCount") + 1,
						"rootItemCount"
					)
				}
				if (type === "folder" && containerId) {
					containerProxy.setValue(
						containerProxy.getValue("archivedItemCount") - itemProxy.getValue("itemsCount"),
						"archivedItemCount"
					)
					containerProxy.setValue(
						containerProxy.getValue("rootItemCount") + itemProxy.getValue("itemsCount"),
						"rootItemCount"
					)
					if (itemProxy.getValue("isStarred")) {
						const {
							name,
							data,
						} = connections.currentUser.shopcastManager.starredFolders
						const starredConnection = ConnectionHandler.getConnection(
							containerProxy,
							name,
							data
						)

						const newFolder = storeProxy.create(
							`ShopcastContainer:starredFolders:${new Date().getTime()}`,
							"newFolder"
						)
						newFolder.setLinkedRecord(itemProxy, "node")
						ConnectionHandler.insertEdgeAfter(
							starredConnection,
							newFolder
						)
					}

					if (isInRoot) {
						const {
							name,
							data,
						} = connections.currentUser.shopcastManager.rootFolders
						const rootConnection = ConnectionHandler.getConnection(
							containerProxy,
							name,
							data
						)

						const newFolder = storeProxy.create(
							`ShopcastContainer:rootFolders:${new Date().getTime()}`,
							"newFolder"
						)
						newFolder.setLinkedRecord(itemProxy, "node")
						ConnectionHandler.insertEdgeAfter(rootConnection, newFolder)
					} else {
						const parentProxy = storeProxy.get(
							dd.restoreShopcastItem.parentFolderId
						)
						if (parentProxy) {
							parentProxy.setValue(
								parentProxy.getValue("count") + 1,
								"count"
							)
						}
					}
					itemProxy.setValue(!itemProxy.getValue("isArchived"), "isArchived")
				}
			}
		},
	})

export default { commit }
