import React from "react"
import { createFragmentContainer, graphql } from "react-relay"
import { ShowCardControlsComponent, debounce } from "helpers/ui-helper"
import { Link } from "react-router-dom"
import vTime from "video-time"
import ClipboardButton from "react-clipboard.js"
import { toast } from "react-toastify"
import {
	TrashIcon,
	ResetIcon,
	VideoIcon,
	Link1Icon,
	CrumpledPaperIcon,
	CheckCircledIcon,
	EnterIcon,
	DownloadIcon,
	Pencil1Icon,
	CodeIcon,
	PlusIcon,
	MinusIcon,
	FrameIcon,
	CopyIcon,
} from "@radix-ui/react-icons"
import PinIcon from "shared/assets/pin-icon.svg"
import UnPinIcon from "shared/assets/unpin-icon.svg"
import { Transition } from "react-transition-group"
import { addVersioning } from "helpers/query-parameters-helper"
import FolderIcon from "shared/assets/folder-icon.svg"
import card from "shared/styles/components/card.css"
import icons from "shared/styles/components/icons.css"
import label from "shared/styles/components/label.css"
import input from "shared/styles/components/input.css"
import PinButton from "components/buttons/pin-button"
import alert from "shared/styles/components/alert.css"
import progress from "shared/styles/components/progress.css"
import button from "shared/styles/components/buttons.css"
import DropdownMenu from "components/dropdown-menu/dropdown-menu"
import stylesDropdown from "components/dropdown-menu/dropdown-menu-dark.css"
import MenuIcon from "shared/assets/menu-dots-horizontal.svg"
import PlayIcon from "shared/assets/play-icon.svg"
import YoutubeIcon from "shared/assets/youtube.svg"
import ShareIcon from "shared/assets/share-icon.svg"
import FacebookIcon from "shared/assets/facebook-circle-icon.svg"
import EmbedShopcast from "components/modals/embed-shopcast/embed-shopcast"
import LoadingDots from "components/loading-dots/loading-dots"
import SalesCount from "components/sales-count/sales-count"
// import Views from "components/views/views"
// import Likes from "components/likes/likes"
import archiveShopcastItemMutation from "mutations/shopcast-manager/archive-shopcast-item"
import restoreShopcastItemMutation from "mutations/shopcast-manager/restore-shopcast-item"
import deleteShopcastMutation from "mutations/shopcast-manager/delete-shopcast"
import moveShopcastItemsMutation from "mutations/shopcast-manager/move-shopcast-items"
import toggleShopcastPublishedState from "mutations/shopcast/toggle-shopcast-published"
import ProcessMp4OnDemandMutation from "mutations/shopcast/process-mp4-on-demand"
import ShareShopcastToFacebookMutation from "mutations/shopcast/share-shopcast-to-facebook"
import UpdateShopcastMutation from "mutations/shopcast/update-shopcast"
import CopyToMutation from "mutations/shopcast-manager/copy-to"
import ContentEditable from "components/content-editable/content-editable"
import io from "socket.io-client"
import {
	textStrings,
	baseUrl,
	publicViewDomain,
	subdomainValidationRegex,
} from "constants"
import trackingService from "services/tracking-service"
import { withDrag } from "helpers/dnd-helper"
import styles from "./user-shopcast-card.css"
import { withRouter } from "../../../route-helpers"

function collect(monitor) {
	return {
		isDragging: monitor.isDragging(),
	}
}

class UserShopcastCard extends React.Component {
	constructor(props) {
		super(props)

		this.handleArchiveClick = this.handleArchiveClick.bind(this)
		this.handleDeleteClick = this.handleDeleteClick.bind(this)
		this.handleRestoreClick = this.handleRestoreClick.bind(this)
		this.deleteShopcast = this.deleteShopcast.bind(this)
		this.handleCheckboxChange = this.handleCheckboxChange.bind(this)
		this.handleMoveClick = this.handleMoveClick.bind(this)
		this.handleCopyClick = this.handleCopyClick.bind(this)
		this.togglePublishState = this.togglePublishState.bind(this)
		this.handleEmbedClick = this.handleEmbedClick.bind(this)
		this.closeEmbedModal = this.closeEmbedModal.bind(this)
		this.handleCopySuccess = this.handleCopySuccess.bind(this)
		this.updateUploadProgress = this.updateUploadProgress.bind(this)
		this.uploadToYoutubeSuccess = this.uploadToYoutubeSuccess.bind(this)
		this.uploadToYoutubeFailure = this.uploadToYoutubeFailure.bind(this)
		this.scheduleUploadToYouTube = this.scheduleUploadToYouTube.bind(this)
		this.processMp4OnDemand = this.processMp4OnDemand.bind(this)
		this.shareOnFacebook = this.shareOnFacebook.bind(this)
		this.updateShopcastTitle = this.updateShopcastTitle.bind(this)

		this.type = "shopcast"

		this.messages = [
			"Hey There!",
			"Giving your video the WOW factor",
			"I like what you picked!",
			"Making your video schmick",
			"High-fiving the server",
			"You make me want to buy this product",
			"Sprinkling 'fairy dust' on your video",
			"Cleaning up spilled pixels",
			"Processing stuff for your video",
			"Hammering square pixels into round holes",
			"You look and sound great!",
			"Your clients will love this!",
			"You're selling this perfectly",
		]

		this.state = {
			showEmbedModal: false,
			loading: true,
			youtubeLink: props.shopcast.youtubeUploadStatus,
			isProcessing: false,
			transcoding: false,
			processingMessage: "",
			titleInFocus: false,
			isDropdownOpen: false,
		}

		this.setDropdownOpenDebounce = debounce((val) => {
			this.setState({ isDropdownOpen: val })
		}, 50)
	}

	componentDidMount() {
		const { shopcast, userId } = this.props

		const setIntervalImmediately = (func, interval) => {
			func()
			return setInterval(func, interval)
		}

		this.socketInstance = io(baseUrl)
		this.socketInstance.on("shopcast-progress", (value) => {
			this.updateUploadProgress(value)
		})
		this.socketInstance.on("transcode-progress", (value) => {
			this.setState(
				{ transcoding: value === "started" || value === "running" },
				() => {
					const { transcoding } = this.state
					if (transcoding) {
						this.setState({
							processingMessage:
								this.messages[
									Math.floor(Math.random() * this.messages.length)
								],
						})
						this.interval = setIntervalImmediately(() => {
							const message =
								this.messages[
									Math.floor(Math.random() * this.messages.length)
								]
							this.setState({ processingMessage: message })
						}, 6000)
					} else if (this.interval) {
						clearInterval(this.interval)
					}
				}
			)
		})
		this.socketInstance.emit("need-shopcast-progress", shopcast.id, userId)
		this.socketInstance.emit("need-transcode-progress", shopcast.id, userId)
		this.updateUploadProgress(null)
	}

	componentWillUnmount() {
		this.socketInstance.disconnect()
		if (this.interval) clearInterval(this.interval)
	}

	//
	// ─── EVENT HANDLERS ─────────────────────────────────────────────────────────────
	//

	handleDeleteClick() {
		const { confirmDelete, shopcast } = this.props
		confirmDelete({
			type: this.type,
			name: shopcast.title,
			callback: this.deleteShopcast,
		})
	}

	handleRestoreClick() {
		const { relay, reportError, parentId, shopcast, containerId } = this.props
		restoreShopcastItemMutation.commit(
			relay.environment,
			shopcast.id,
			this.type,
			parentId,
			reportError("Could not restore shopcast"),
			containerId,
			() => {
				toast.success(
					<>
						Moved to{" "}
						{shopcast.inRoot
							? textStrings.clientWardrobeName
							: [...shopcast.folder.path]
									.reverse()
									.map((i) => i.name)
									.join(" / ")}
					</>,
					{
						autoClose: 2000,
						closeButton: false,
					}
				)
			}
		)
	}

	handleArchiveClick() {
		const { relay, shopcast, parentId, reportError, containerId } = this.props
		archiveShopcastItemMutation.commit(
			relay.environment,
			shopcast.id,
			this.type,
			parentId,
			reportError("Could not archive shopcast"),
			containerId,
			() => {}
		)
	}

	handleCheckboxChange({ target }) {
		const { checked } = target
		const { onSelect, onDeselect, shopcast } = this.props
		;(checked ? onSelect : onDeselect)({
			id: shopcast.id,
			isArchived: shopcast.isArchived,
			type: this.type,
			deselect: () => {
				this.handleCheckboxChange({ target: { checked: false } })
			},
		})
	}

	handleMoveClick() {
		const {
			requestMove,
			relay,
			shopcast,
			parentId,
			reportError,
			containerId,
			team,
		} = this.props
		requestMove((folderId) => {
			moveShopcastItemsMutation.commit(
				relay.environment,
				[{ id: shopcast.id, type: this.type }],
				folderId,
				team,
				parentId,
				containerId,
				reportError("Could not move shopcast")
			)
		}, [])
	}

	handleCopyClick() {
		const { requestCopy, relay, shopcast, reportError } = this.props
		requestCopy((team) => {
			CopyToMutation.commit(
				relay.environment,
				[shopcast.id],
				team.id,
				reportError("Could not copy shopcast"),
				() => {
					toast.success(<>Successfully copied</>, {
						autoClose: 2000,
						closeButton: false,
					})
				}
			)
		})
	}

	handleEmbedClick() {
		this.setState({ showEmbedModal: true })
	}

	handleCopySuccess() {
		toast.success(<>Copied</>, {
			autoClose: 2000,
			closeButton: false,
		})
	}

	//
	// ─── METHODS ────────────────────────────────────────────────────────────────────
	//

	deleteShopcast() {
		const { relay, parentId, reportError, shopcast, containerId } = this.props

		deleteShopcastMutation.commit(
			relay.environment,
			shopcast.id,
			parentId,
			shopcast.isArchived ? null : containerId,
			reportError("Could not delete folder")
		)
	}

	togglePublishState() {
		const {
			shopcast,
			relay,
			reportError,
			containerId,
			parentId,
			routeType,
			team,
		} = this.props

		toggleShopcastPublishedState.commit(
			relay.environment,
			shopcast.id,
			parentId,
			containerId,
			routeType === "public" && shopcast.isPublished,
			reportError(
				`Couldnt ${shopcast.isPublished ? "un-publish" : "publish"}`
			),
			team
		)
	}

	closeEmbedModal() {
		this.setState({ showEmbedModal: false })
	}

	updateUploadProgress(snapshot) {
		const { loading } = this.state
		const { shopcast, userId } = this.props
		if (loading) {
			this.setState({ loading: false })
		}
		const uploadProgressValue = snapshot
		if (uploadProgressValue && uploadProgressValue === "done") {
			toast.success(<>Upload completed!</>, {
				autoClose: 2000,
				closeButton: false,
			})
			this.socketInstance.emit(
				"set-shopcast-progress",
				shopcast.id,
				userId,
				null
			)
		} else if (uploadProgressValue > 0) {
			this.setState({ uploadProgressValue })
		} else if (uploadProgressValue === "youtubeUploading") {
			this.setState({
				transcoding: false,
				isProcessing: true,
			})
		} else if (uploadProgressValue === "youtubeError") {
			this.socketInstance.emit(
				"set-shopcast-progress",
				shopcast.id,
				userId,
				null
			)
			this.uploadToYoutubeFailure()
		} else if (uploadProgressValue) {
			this.setState({
				youtubeLink: uploadProgressValue,
				transcoding: false,
				isProcessing: false,
			})
			this.socketInstance.emit(
				"set-shopcast-progress",
				shopcast.id,
				userId,
				null
			)
		} else {
			this.setState({ uploadProgressValue: null })
		}
	}

	uploadToYoutubeSuccess(res) {
		this.setState({
			youtubeLink: res.uploadToYoutube.youtubeVideoId,
			isProcessing: false,
		})
		toast.success(<>Video has been successfully uploaded to YouTube</>, {
			autoClose: 3000,
			closeButton: false,
			position: "top-center",
		})
	}

	uploadToYoutubeFailure(transaction) {
		const { isProcessing } = this.state
		if (!isProcessing) {
			return
		}
		this.setState({ isProcessing: false })
		if (transaction.code === "upgrade") return
		toast.error(<>Upload Error</>, {
			autoClose: 3000,
			closeButton: false,
			position: "top-center",
		})
	}

	scheduleUploadToYouTube() {
		const {
			shopcast,
			navigate,
			connectedToYouTube,
			fullname,
			connectToSocial,
			onYoutubeUpload,
		} = this.props
		if (connectedToYouTube) {
			onYoutubeUpload({
				title: shopcast.title,
				description: shopcast.youtubeDescription,
				privacyStatus: "private",
				shopcast: {
					id: shopcast.id,
					title: shopcast.title,
				},
				success: this.uploadToYoutubeSuccess,
				failure: this.uploadToYoutubeFailure,
			})
			this.setState({ isProcessing: true })
			trackingService.track("YouTube Upload Started", {
				Name: fullname,
				Shopcast: shopcast.title,
			})
		} else if (connectToSocial) {
			connectToSocial({
				platform: "youtube",
				icon: YoutubeIcon,
				iconColor: "var(--youtube500)",
			})
		} else {
			navigate("/settings/integrations")
		}
	}

	processMp4OnDemand() {
		const {
			relay: { environment },
			shopcast,
			storeId,
			team,
		} = this.props
		ProcessMp4OnDemandMutation.commit(
			environment,
			shopcast.id,
			storeId,
			team,
			(data) => {
				if (data.processMp4OnDemand.url) {
					const link = document.createElement("a")
					link.href = data.processMp4OnDemand.url
					link.setAttribute("download", `${shopcast.id}.mp4`)
					document.body.appendChild(link)
					link.click()
					document.body.removeChild(link)
				} else {
					toast.success(
						<div className={alert.toast}>
							<h3 className={alert.heading}>
								Your video is being processed.
							</h3>
							<p className={alert.message}>
								We will send you an email when it&apos;s ready.
							</p>
						</div>,
						{
							autoClose: 4000,
							closeButton: false,
							position: "top-center",
						}
					)
				}
			},
			([err]) => {
				toast.error(err.message, {
					autoClose: 3000,
					closeButton: false,
					position: "top-center",
				})
			}
		)
	}

	shareOnFacebook(pageObj) {
		const { relay, reportError, shopcast } = this.props
		return new Promise((resolve, reject) => {
			ShareShopcastToFacebookMutation.commit(
				relay.environment,
				{
					id: shopcast.id,
					description: pageObj.description,
					page: pageObj.fbPage.id,
					accessToken: pageObj.fbPage.accessToken,
				},
				resolve,
				() => {
					reportError("Could not share Shopcast to Facebook")()
					reject()
				}
			)
		}).then(() => {
			toast.success(<>Shopcast shared to Facebook</>, {
				autoClose: 5000,
				closeButton: false,
			})
		})
	}

	updateShopcastTitle(title) {
		const { relay, shopcast } = this.props
		UpdateShopcastMutation.commit(
			relay.environment,
			{
				shopcastId: shopcast.id,
				title,
				description: shopcast.description,
				category: shopcast.category ? shopcast.category.id : "",
				publicSetting: shopcast.isPublished ? "published" : "private",
			},
			(e) => {
				console.log(e)
			},
			() => {
				toast.success("Saved", {
					autoClose: 2000,
					closeButton: false,
				})
			}
		)
	}

	//
	// ─── RENDERS ────────────────────────────────────────────────────────────────────
	//

	renderMenu() {
		const {
			shopcast,
			requestEdit,
			navigate,
			togglePinned,
			isSelected,
			searchValue,
			isParentAClient,
			requestCopy,
		} = this.props
		const listItems = []
		const { routeType } = this.props
		const push = (click, name, key, icon) =>
			listItems.push({
				click,
				name,
				key,
				icon,
			})
		const icon = <MenuIcon className={stylesDropdown.icon} />
		if (shopcast.isArchived) {
			if (routeType === "archived")
				push(
					this.handleRestoreClick,
					"Restore",
					"restore",
					<ResetIcon className={`${stylesDropdown.itemIcon}`} />
				)
			push(
				this.handleDeleteClick,
				"Delete Forever",
				"delete",
				<TrashIcon className={`${stylesDropdown.itemIcon}`} />
			)
		} else {
			if (shopcast.folder && searchValue) {
				push(
					() => navigate(`/content/${shopcast.folder.id}`),
					"Go to Folder",
					"gtf",
					<EnterIcon className={stylesDropdown.itemIcon} />
				)
				listItems.push("divide")
			}
			if (!isParentAClient && !shopcast.isFlagged) {
				const d = shopcast.isPublished
					? {
							key: "private",
							name: "Remove from My Channel",
							icon: <MinusIcon className={stylesDropdown.itemIcon} />,
					  }
					: {
							key: "public",
							name: "Add to My Channel",
							icon: <PlusIcon className={stylesDropdown.itemIcon} />,
					  }
				push(this.togglePublishState, d.name, d.key, d.icon)
				listItems.push("divide")
			}

			push(
				() => requestEdit(shopcast),
				"Edit Shopcast",
				"edit",
				<Pencil1Icon className={`${stylesDropdown.itemIcon}`} />
			)
			push(
				togglePinned,
				shopcast.isPinned ? "Unpin File" : "Pin File",
				"pin",
				shopcast.isPinned ? (
					<UnPinIcon className={stylesDropdown.itemIcon} />
				) : (
					<PinIcon className={stylesDropdown.itemIcon} />
				)
			)
			listItems.push("divide")
			push(
				() => {
					navigate(`/shopboard/create?shopcast=${shopcast.id}`)
				},
				"Create Board",
				"create",
				<FrameIcon className={`${stylesDropdown.itemIcon}`} />
			)
			listItems.push("divide")
			push(
				this.handleMoveClick,
				"Move to...",
				"move",
				<FolderIcon className={`${stylesDropdown.itemIcon}`} />
			)
			if (requestCopy)
				push(
					this.handleCopyClick,
					"Copy to...",
					"copy",
					<CopyIcon className={`${stylesDropdown.itemIcon}`} />
				)
			listItems.push("divide")
			push(
				() => {
					this.handleCheckboxChange({
						target: { checked: !isSelected },
					})
				},
				"Select Items",
				"select",
				<CheckCircledIcon className={stylesDropdown.itemIcon} />
			)
			listItems.push("divide")
			push(
				this.handleArchiveClick,
				"Move to Trash",
				"trash",
				<CrumpledPaperIcon className={`${stylesDropdown.itemIcon}`} />
			)
			push(
				this.handleDeleteClick,
				"Delete Forever",
				"deletePerm",
				<TrashIcon className={`${stylesDropdown.itemIcon}`} />
			)
		}
		return (
			<DropdownMenu
				mainIcon={icon}
				items={listItems}
				darkTheme
				contentRight
				triggerClass={`${button.lightIcon} ${
					!shopcast.isArchived && button.iconEnd
				}`}
				triggerClassActive={button.lightIconActive}
				setDropdownOpened={this.setDropdownOpenDebounce}
			/>
		)
	}

	renderShareMenu() {
		const { shopcast, shareOnFacebook } = this.props
		const { youtubeLink } = this.state
		if (shopcast.isArchived) return null
		const listItems = []
		const push = (click, name, key, icon) =>
			listItems.push({
				click,
				name,
				key,
				icon,
			})
		const icon = <ShareIcon className={stylesDropdown.icon} />
		const domain =
			shopcast.user.customDomain ||
			`${shopcast.user.username}.${publicViewDomain}`
		const youtubeLinkURL = `https://youtu.be/${youtubeLink}`

		if (subdomainValidationRegex.test(shopcast.user.username))
			push(
				() => {
					navigator.clipboard.writeText(
						`https://${domain}/shopcast/${shopcast.id}`
					)
					this.handleCopySuccess()
				},
				"Get Shareable Link",
				"clipboard",
				<Link1Icon className={stylesDropdown.itemIcon} />
			)
		push(
			() => {
				this.handleEmbedClick()
			},
			"Embed",
			"embed",
			<CodeIcon className={stylesDropdown.itemIcon} />
		)
		push(
			() => this.processMp4OnDemand(),
			"Download Video",
			"download",
			<DownloadIcon className={stylesDropdown.itemIcon} />
		)
		listItems.push("divide")
		push(
			() => this.scheduleUploadToYouTube(),
			"Upload to YouTube",
			"youtube",
			<VideoIcon className={stylesDropdown.itemIcon} />
		)
		if (youtubeLink) {
			push(
				null,
				<ClipboardButton
					className={stylesDropdown.item}
					data-clipboard-text={youtubeLinkURL}
					onSuccess={this.handleCopySuccess}
				>
					<>
						<Link1Icon className={stylesDropdown.itemIcon} /> Get YouTube
						Link
					</>
				</ClipboardButton>,
				"youtube-clipboard"
			)
		}
		listItems.push("divide")
		push(
			() => {
				shareOnFacebook({
					platform: "facebook",
					type: this.type,
					obj: shopcast,
					uploadAs: [{ id: "link", name: "Link" }],
					callback: this.shareOnFacebook,
				})
			},
			"Create Post",
			"uploadFacebook",
			<FacebookIcon className={stylesDropdown.itemIcon} />
		)
		return (
			<DropdownMenu
				mainIcon={icon}
				items={listItems}
				darkTheme
				contentRight
				triggerClass={`${button.lightIcon} ${button.iconStart}`}
				triggerClassActive={button.lightIconActive}
				setDropdownOpened={this.setDropdownOpenDebounce}
			/>
		)
	}

	renderProgress() {
		const { uploadProgressValue } = this.state
		return (
			<div className={progress.root}>
				<h4 className={progress.status}>Uploading Shopcast</h4>
				<progress
					className={progress.barDark}
					max={100}
					value={uploadProgressValue}
				/>
			</div>
		)
	}

	renderCorrupt() {
		return (
			<div className={progress.root}>
				<h4 className={progress.title}>Upload Incomplete</h4>

				<button
					type="button"
					className={button.primaryRed}
					onClick={() => {
						this.handleDeleteClick()
					}}
				>
					Delete
				</button>
			</div>
		)
	}

	renderProcessing() {
		const { processingMessage, transcoding } = this.state
		return (
			<div className={progress.root}>
				{transcoding && (
					<h4 className={progress.status}>{processingMessage}</h4>
				)}
				<div className={progress.indefiniteDark}>
					<div className={progress.indefiniteBar} />
				</div>
			</div>
		)
	}

	renderLoading() {
		return (
			<div className={progress.root}>
				<LoadingDots color="var(--primary50)" />
			</div>
		)
	}

	renderPlay(shopcast, isOverlayEnabled) {
		const { enableCardSelection, isSelected } = this.props
		const comp = (
			<div className={styles.inner}>
				{!isOverlayEnabled && <img
					className={styles.poster}
					loading="lazy"
					src={addVersioning(shopcast.posterUrl, `d=400`)}
					alt="Poster"
					draggable={false}
				/>}
				<button className={card.play} type="button">
					<PlayIcon className={card.playIcon} />
				</button>
			</div>
		)
		if (enableCardSelection)
			return (
				<div
					className={styles.figure}
					onClick={() => {
						this.handleCheckboxChange({
							target: { checked: !isSelected },
						})
					}}
					role="button"
					tabIndex={0}
				>
					{comp}
				</div>
			)
		return (
			<div className={styles.figure}>
				<Link to={`/shopcast/${shopcast.id}`}>{comp}</Link>
			</div>
		)
	}

	// renderMediaClasses(shopcast) {
	// 	const { isSelected } = this.props

	// 	if (isSelected) return `${styles.media} ${styles.active}`
	// 	if (shopcast.isFlagged) return styles.mediaFlagged
	// 	if (shopcast.isPublished) return styles.mediaPublished
	// 	return styles.media
	// }

	renderContent(shopcast, isShown) {
		const {
			showEmbedModal,
			transcoding,
			isProcessing,
			youtubeLink,
			isDropdownOpen,
		} = this.state
		const { isSelected, enableCardSelection, togglePinned, routeType } =
			this.props
		const { uploadProgressValue, loading } = this.state
		let contentOverlay = null
		if (loading) {
			contentOverlay = this.renderLoading()
		} else if (uploadProgressValue) {
			contentOverlay = this.renderProgress(shopcast)
		} else if (transcoding) {
			contentOverlay = this.renderProcessing(shopcast, "Processing Shopcast")
		} else if (isProcessing) {
			contentOverlay = this.renderProcessing(
				shopcast,
				"Uploading to Youtube"
			)
		} else if (shopcast.transcoded === "CORRUPTED") {
			contentOverlay = this.renderCorrupt(shopcast)
		}
		return (
			<>
				<div
					className={
						isSelected ? `${styles.media} ${styles.active}` : styles.media
					}
				>
					{contentOverlay}
					{this.renderPlay(shopcast, contentOverlay)}

					<Transition
						in={isShown || isDropdownOpen || enableCardSelection}
						timeout={200}
					>
						{(state) => (
							<div className={`${styles.select} ${styles[state]}`}>
								<input
									type="checkbox"
									checked={isSelected}
									className={`${input.checkbox} ${input.lg}`}
									onChange={this.handleCheckboxChange}
								/>
							</div>
						)}
					</Transition>

					<Transition
						in={(isShown || isDropdownOpen) && !enableCardSelection}
						timeout={200}
					>
						{(state) => (
							<div className={`${styles.menu} ${styles[state]}`}>
								<div className={styles.actions}>
									{this.renderShareMenu()}
									{this.renderMenu()}
								</div>
							</div>
						)}
					</Transition>

					{youtubeLink && (
						<div className={styles.youtubeIcon}>
							<YoutubeIcon
								className={`${icons.icon24} ${styles.youtube}`}
							/>
						</div>
					)}

					{shopcast.isFlagged && (
						<div className={card.badge}>
							<div className={label.flagged}>Flagged</div>
						</div>
					)}

					{routeType !== "public" && shopcast.isPublished && (
						<div className={card.badge}>
							<div className={label.published}>Published</div>
						</div>
					)}
				</div>

				<div className={styles.content}>
					<div className={styles.titleWithIcons}>
						<ContentEditable
							className={styles.title}
							title={shopcast.title}
							saveTitle={this.updateShopcastTitle}
							contentEditable={!enableCardSelection}
							setTitleFocus={(val) =>
								this.setState({ titleInFocus: val })
							}
						/>
						<div className={styles.titleIcon}>
							{shopcast.transactions.length > 0 && (
								<SalesCount transactions={shopcast.transactions} />
							)}
							<PinButton
								isPinned={shopcast.isPinned}
								onClick={togglePinned}
							/>
						</div>
					</div>

					<div className={styles.subtitle}>
						{vTime(shopcast.duration)}
						{/* •{" "}{shopcast.shopcastItems.edges.length} */}
					</div>
				</div>

				{/* <Views viewsCount={shopcast.views} size="sm" />
					<Likes className={card.metaLikes} likesCount={shopcast.likes} size="sm" />
				 */}

				<EmbedShopcast
					shopcast={shopcast}
					type="shopcast"
					showEmbedModal={showEmbedModal}
					closeEmbedModal={this.closeEmbedModal}
				/>
			</>
		)
	}

	render() {
		const { titleInFocus } = this.state
		const { shopcast, dragRef } = this.props
		return (
			<ShowCardControlsComponent
				className={styles.root}
				innerRef={titleInFocus ? null : dragRef}
			>
				{(isShown) => this.renderContent(shopcast, isShown)}
			</ShowCardControlsComponent>
		)
	}
}

function UserShopcastCardComp({ isDraggable, ...otherProps }) {
	const Target = isDraggable
		? withDrag(UserShopcastCard, ({ shopcast }) => ({
				type: "shopcast-card",
				item: {
					id: shopcast.id,
					type: "shopcast",
					isPublished: shopcast.isPublished,
				},
				collect,
		  }))
		: UserShopcastCard
	return <Target {...otherProps} />
}

export default withRouter(
	createFragmentContainer(UserShopcastCardComp, {
		shopcast: graphql`
			fragment userShopcastCard_shopcast on Shopcast {
				id
				title
				description
				posterUrl
				isPublished
				isPinned
				transcoded
				duration
				isFlagged
				views
				likes
				isArchived
				isStarred
				inRoot
				youtubeUploadStatus
				category {
					id
					name
				}
				transactions {
					id
					orderId
				}
				user {
					id
					username
					customDomain
				}
				folder {
					name
					id
					path {
						value
						name
					}
				}
				shopcastItems(first: 100) {
					edges {
						node {
							id
							start
							end
							product {
								id
								imageUrl
							}
						}
					}
				}
			}
		`,
	})
)
