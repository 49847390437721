import { commitMutation, graphql } from "react-relay"
import { complete } from "../helper.js"

const mutation = graphql`
	mutation setLookbookVideoUrlMutation(
		$input: SaveLookbookVideoMutationInput!
	) {
		saveLookbookVideo(input: $input) {
			video_url
			mp4_url
			subtitle
			show_video
			jobId
		}
	}
`

const commit = (env, boardId, videoUrl, showVideo, onCompleted, onError, isAudio) =>
	commitMutation(env, {
		mutation,
		variables: {
			input: {
				boardId,
				video_url: videoUrl,
				show_video: showVideo,
				isAudio
			},
		},
		onCompleted: complete(onCompleted, onError),
		onError: (e) => complete(null, [e]),
		updater: (storeProxy, data) => {
			const lookbookProxy = storeProxy.get(boardId)
			if (
				lookbookProxy &&
				data.saveLookbookVideo &&
				data.saveLookbookVideo.video_url &&
				data.saveLookbookVideo.subtitle &&
				data.saveLookbookVideo.show_video !== undefined
			) {
				lookbookProxy.setValue(data.saveLookbookVideo.video_url, "videoUrl")
				lookbookProxy.setValue(data.saveLookbookVideo.subtitle, "subtitle")
				lookbookProxy.setValue(
					data.saveLookbookVideo.show_video,
					"showVideo"
				)
				lookbookProxy.setValue(data.saveLookbookVideo.mp4_url, "mp4Url")
			} else if (
				lookbookProxy &&
				data.saveLookbookVideo &&
				data.saveLookbookVideo.show_video !== undefined
			) {
				lookbookProxy.setValue(
					data.saveLookbookVideo.show_video,
					"showVideo"
				)
			}
		},
	})

export default { commit }
