/**
 * @generated SignedSource<<947d79e93d4046633cfe0c4e620b986a>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentType } from "relay-runtime";
declare export opaque type categorizeImageList_store$fragmentType: FragmentType;
export type categorizeImageList_store$data = {|
  +currentUser: ?{|
    +adminAccess: ?string,
    +id: string,
    +lookbookFavourites: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?({|
          +__typename: "lookbookUploads",
          +id: string,
          +isFavourite: ?boolean,
        |} | {|
          // This will never be '%other', but we need some
          // value in case none of the concrete values match.
          +__typename: "%other",
        |}),
      |}>,
    |},
    +lookbookUploads: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +canEdit: ?boolean,
          +category: ?{|
            +title: ?string,
          |},
          +id: string,
          +isFavourite: ?boolean,
          +subCategoryList: ?$ReadOnlyArray<?{|
            +category: ?{|
              +title: ?string,
            |},
            +id: string,
            +title: ?string,
          |}>,
          +subCategoryTitle: ?string,
          +title: ?string,
          +url: ?string,
        |},
      |}>,
    |},
    +lookbookUploadsSubCategoryList: ?$ReadOnlyArray<?{|
      +id: string,
      +title: ?string,
    |}>,
    +role: ?string,
  |},
  +$fragmentType: categorizeImageList_store$fragmentType,
|};
export type categorizeImageList_store$key = {
  +$data?: categorizeImageList_store$data,
  +$fragmentSpreads: categorizeImageList_store$fragmentType,
  ...
};
*/

var node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "kind": "Variable",
  "name": "category",
  "variableName": "category_id"
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v3 = {
  "kind": "Variable",
  "name": "teamId",
  "variableName": "teamId"
},
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "UploadsCategory",
  "kind": "LinkedField",
  "name": "category",
  "plural": false,
  "selections": [
    (v2/*: any*/)
  ],
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": [
    (v3/*: any*/)
  ],
  "kind": "ScalarField",
  "name": "isFavourite",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "category_id"
    },
    {
      "defaultValue": 24,
      "kind": "LocalArgument",
      "name": "count"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "cursor"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "sub_category"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "teamId"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "count",
        "cursor": "cursor",
        "direction": "forward",
        "path": [
          "currentUser",
          "lookbookUploads"
        ]
      }
    ]
  },
  "name": "categorizeImageList_store",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "currentUser",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "adminAccess",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "role",
          "storageKey": null
        },
        {
          "alias": null,
          "args": [
            (v1/*: any*/)
          ],
          "concreteType": "UploadsSubCategory",
          "kind": "LinkedField",
          "name": "lookbookUploadsSubCategoryList",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            (v2/*: any*/)
          ],
          "storageKey": null
        },
        {
          "alias": "lookbookUploads",
          "args": [
            (v1/*: any*/),
            {
              "kind": "Variable",
              "name": "sub_category",
              "variableName": "sub_category"
            },
            (v3/*: any*/)
          ],
          "concreteType": "LookbookUploadConnectionConnection",
          "kind": "LinkedField",
          "name": "__currentUser_lookbookUploads_connection",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "LookbookUploadConnectionEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "lookbookUploads",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    (v2/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "url",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "canEdit",
                      "storageKey": null
                    },
                    (v4/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "UploadsSubCategory",
                      "kind": "LinkedField",
                      "name": "subCategoryList",
                      "plural": true,
                      "selections": [
                        (v0/*: any*/),
                        (v4/*: any*/),
                        (v2/*: any*/)
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "subCategoryTitle",
                      "storageKey": null
                    },
                    (v5/*: any*/),
                    (v6/*: any*/)
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "cursor",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "PageInfo",
              "kind": "LinkedField",
              "name": "pageInfo",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "endCursor",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "hasNextPage",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": [
            (v1/*: any*/),
            (v3/*: any*/)
          ],
          "concreteType": "LookbookFavouritesConnectionConnection",
          "kind": "LinkedField",
          "name": "lookbookFavourites",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "LookbookFavouritesConnectionEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": null,
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    (v6/*: any*/),
                    {
                      "kind": "InlineFragment",
                      "selections": [
                        (v0/*: any*/),
                        (v5/*: any*/)
                      ],
                      "type": "lookbookUploads",
                      "abstractKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Store",
  "abstractKey": null
};
})();

(node/*: any*/).hash = "9b8b0236ad21c23f71ed79fa89d4c3fd";

module.exports = ((node/*: any*/)/*: Fragment<
  categorizeImageList_store$fragmentType,
  categorizeImageList_store$data,
>*/);
