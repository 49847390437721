import React from "react"
// import { MagicWandIcon } from "@radix-ui/react-icons"
import LookbookDropdown from "components/lookbook-dropdown/lookbook-dropdown"
import lookbookDropdownStyles from "components/lookbook-dropdown/lookbook-dropdown.css"
import Slider from "components/lookbook-dropdown/slider"
// import LoadingDots from "components/loading-dots/loading-dots"
import RemoveBgIcon from "shared/assets/lb-removebg-icon.svg"
import styles from "./background-remover.css"

export default function BackgroundRemover({
	transparentLevel,
	// processingImage,
	runDebounce,
	handleTransparent,
	// canBgRemoveOpen,
	// openBgRemove,
	dataTip,
}) {
	return (
		<LookbookDropdown
			label={<RemoveBgIcon className={styles.icon} />}
			data-tooltip-id="tooltip"
			data-tooltip-content={dataTip}
			runDebounce={runDebounce}
		>
			<div className={styles.root}>
				<div className={lookbookDropdownStyles.scroll}>
					<div className={styles.quick}>
						<h4 className={styles.heading}>Simple</h4>
						<Slider
							min={0}
							max={200}
							step={1}
							isDark
							value={parseInt(`${transparentLevel}`, 10)}
							onSlide={handleTransparent}
							noInput
						/>
					</div>
					{/* {canBgRemoveOpen && <div className={styles.divider} />}
					{canBgRemoveOpen && (
						<div className={styles.advanced}>
							<h4 className={styles.heading}>Advanced</h4>
							<p className={styles.para}>
								Automatically remove backgrounds for the toughest
								images.
							</p>
							<button
								type="button"
								className={styles.button}
								onClick={() => {
									if (!processingImage) {
										openBgRemove()
									}
								}}
							>
								{processingImage ? (
									<LoadingDots color="var(--primary60)" />
								) : (
									"Auto Remove"
								)}
							</button>
						</div>
					)} */}
				</div>
			</div>
		</LookbookDropdown>
	)
}
