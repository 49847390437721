import React from "react"
import landing from "shared/styles/layout/landing.css"
import styles from "./terms.css"

function Terms() {
	return (
		<div className={styles.root}>
			<section className={landing.section}>
				<div className={landing.content}>
					<h1 className={landing.displayTitle}>Terms</h1>
				</div>
			</section>
			<div className={styles.content}>
				<h3>1. Terms of Use</h3>
				<p>
					By accessing the website at{" "}
					<a href="http://shopshare.tv">http://shopshare.tv</a>, you are
					agreeing to be bound by these terms of service, all applicable
					laws and regulations, and agree that you are responsible for
					compliance with any applicable local laws. If you do not agree
					with any of these terms, you are prohibited from using or
					accessing this site. The materials contained in this website are
					protected by applicable copyright and trademark law.
				</p>
				<h3>2. Use License</h3>
				<ol type="a">
					<li>
						Permission is granted to temporarily download one copy of the
						materials (information or software) on ShopShare&#39;s website
						for personal, non-commercial transitory viewing only. This is
						the grant of a license, not a transfer of title, and under
						this license you may not:
						<ol type="i">
							<li>modify or copy the materials;</li>
							<li>
								use the materials for any commercial purpose, or for any
								public display (commercial or non-commercial);
							</li>
							<li>
								attempt to decompile or reverse engineer any software
								contained on ShopShare&#39;s website;
							</li>
							<li>
								remove any copyright or other proprietary notations from
								the materials; or
							</li>
							<li>
								transfer the materials to another person or
								&#39;mirror&#39; the materials on any other server.
							</li>
						</ol>
					</li>
					<li>
						This license shall automatically terminate if you violate any
						of these restrictions and may be terminated by ShopShare at
						any time. Upon terminating your viewing of these materials or
						upon the termination of this license, you must destroy any
						downloaded materials in your possession whether in electronic
						or printed format.
					</li>
				</ol>
				<h3>3. Disclaimer</h3>
				<ol type="a">
					<li>
						The materials on ShopShare&#39;s website are provided on an
						&#39;as is&#39; basis. ShopShare makes no warranties,
						expressed or implied, and hereby disclaims and negates all
						other warranties including, without limitation, implied
						warranties or conditions of merchantability, fitness for a
						particular purpose, or non-infringement of intellectual
						property or other violation of rights.
					</li>
					<li>
						Further, ShopShare does not warrant or make any
						representations concerning the accuracy, likely results, or
						reliability of the use of the materials on its website or
						otherwise relating to such materials or on any sites linked to
						this site.
					</li>
				</ol>
				<h3>4. Limitations</h3>
				<p>
					In no event shall ShopShare or its suppliers be liable for any
					damages (including, without limitation, damages for loss of data
					or profit, or due to business interruption) arising out of the
					use or inability to use the materials on ShopShare&#39;s website,
					even if ShopShare or a ShopShare authorized representative has
					been notified orally or in writing of the possibility of such
					damage. Because some jurisdictions do not allow limitations on
					implied warranties, or limitations of liability for consequential
					or incidental damages, these limitations may not apply to you.
				</p>
				<h3>5. Accuracy of materials</h3>
				<p>
					The materials appearing on ShopShare&#39;s website could include
					technical, typographical, or photographic errors. ShopShare does
					not warrant that any of the materials on its website are
					accurate, complete or current. ShopShare may make changes to the
					materials contained on its website at any time without notice.
					However ShopShare does not make any commitment to update the
					materials.
				</p>
				<h3>6. Links</h3>
				<p>
					ShopShare has not reviewed all of the sites linked to its website
					and is not responsible for the contents of any such linked site.
					The inclusion of any link does not imply endorsement by ShopShare
					of the site. Use of any such linked website is at the user&#39;s
					own risk.
				</p>
				<h3>7. Modifications</h3>
				<p>
					ShopShare may revise these terms of service for its website at
					any time without notice. By using this website you are agreeing
					to be bound by the then current version of these terms of
					service.
				</p>
				<h3>8. Governing Law</h3>
				<p>
					These terms and conditions are governed by and construed in
					accordance with the laws of Melbourne and you irrevocably submit
					to the exclusive jurisdiction of the courts in that State or
					location.
				</p>
			</div>
		</div>
	)
}

export default { Component: Terms }
