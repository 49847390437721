import { commitMutation, graphql } from "react-relay"
import { complete } from "../helper"

const mutation = graphql`
	mutation updateTeamMemberMutation($input: UpdateTeamMemberInput!) {
		updateTeamMember(input: $input) {
			store {
				id
			}
		}
	}
`

const commit = (env, memberId, role, onError, onCompleted) =>
	commitMutation(env, {
		mutation,
		variables: {
			input: {
				teamMemberId: memberId,
                role,
			},
		},
		onCompleted: complete(onCompleted, onError),
		onError: (e) => complete(null, [e]),
		updater: (storeProxy) => {
			const teamMember = storeProxy.get(memberId)
			teamMember.setValue(role.toUpperCase(), "role")
		},
	})

export default { commit }
