/**
 * @generated SignedSource<<53dfe9345357f4632f2fca1dfbffd47b>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Query } from 'relay-runtime';
import type { categorizeImageList_store$fragmentType } from "./categorizeImageList_store.graphql";
export type categorizeImageListQuery$variables = {|
  category_id: string,
  count: number,
  cursor?: ?string,
  sub_category?: ?string,
  teamId?: ?string,
|};
export type categorizeImageListQuery$data = {|
  +store: ?{|
    +$fragmentSpreads: categorizeImageList_store$fragmentType,
  |},
|};
export type categorizeImageListQuery = {|
  response: categorizeImageListQuery$data,
  variables: categorizeImageListQuery$variables,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "category_id"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "count"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "cursor"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "sub_category"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "teamId"
},
v5 = {
  "kind": "Variable",
  "name": "sub_category",
  "variableName": "sub_category"
},
v6 = {
  "kind": "Variable",
  "name": "teamId",
  "variableName": "teamId"
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v8 = {
  "kind": "Variable",
  "name": "category",
  "variableName": "category_id"
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v10 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "cursor"
  },
  (v8/*: any*/),
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "count"
  },
  (v5/*: any*/),
  (v6/*: any*/)
],
v11 = {
  "alias": null,
  "args": null,
  "concreteType": "UploadsCategory",
  "kind": "LinkedField",
  "name": "category",
  "plural": false,
  "selections": [
    (v9/*: any*/),
    (v7/*: any*/)
  ],
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": [
    (v6/*: any*/)
  ],
  "kind": "ScalarField",
  "name": "isFavourite",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v14 = [
  (v7/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "categorizeImageListQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Store",
        "kind": "LinkedField",
        "name": "store",
        "plural": false,
        "selections": [
          {
            "args": [
              {
                "kind": "Variable",
                "name": "category_id",
                "variableName": "category_id"
              },
              {
                "kind": "Variable",
                "name": "count",
                "variableName": "count"
              },
              {
                "kind": "Variable",
                "name": "cursor",
                "variableName": "cursor"
              },
              (v5/*: any*/),
              (v6/*: any*/)
            ],
            "kind": "FragmentSpread",
            "name": "categorizeImageList_store"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "ShopshareQueryRoot",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v2/*: any*/),
      (v0/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Operation",
    "name": "categorizeImageListQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Store",
        "kind": "LinkedField",
        "name": "store",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "currentUser",
            "plural": false,
            "selections": [
              (v7/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "adminAccess",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "role",
                "storageKey": null
              },
              {
                "alias": null,
                "args": [
                  (v8/*: any*/)
                ],
                "concreteType": "UploadsSubCategory",
                "kind": "LinkedField",
                "name": "lookbookUploadsSubCategoryList",
                "plural": true,
                "selections": [
                  (v7/*: any*/),
                  (v9/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v10/*: any*/),
                "concreteType": "LookbookUploadConnectionConnection",
                "kind": "LinkedField",
                "name": "lookbookUploads",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "LookbookUploadConnectionEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "lookbookUploads",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v7/*: any*/),
                          (v9/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "url",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "canEdit",
                            "storageKey": null
                          },
                          (v11/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "UploadsSubCategory",
                            "kind": "LinkedField",
                            "name": "subCategoryList",
                            "plural": true,
                            "selections": [
                              (v7/*: any*/),
                              (v11/*: any*/),
                              (v9/*: any*/)
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "subCategoryTitle",
                            "storageKey": null
                          },
                          (v12/*: any*/),
                          (v13/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "cursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "endCursor",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasNextPage",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v10/*: any*/),
                "filters": [
                  "category",
                  "sub_category",
                  "teamId"
                ],
                "handle": "connection",
                "key": "currentUser_lookbookUploads",
                "kind": "LinkedHandle",
                "name": "lookbookUploads"
              },
              {
                "alias": null,
                "args": [
                  (v8/*: any*/),
                  (v6/*: any*/)
                ],
                "concreteType": "LookbookFavouritesConnectionConnection",
                "kind": "LinkedField",
                "name": "lookbookFavourites",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "LookbookFavouritesConnectionEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v13/*: any*/),
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              (v7/*: any*/),
                              (v12/*: any*/)
                            ],
                            "type": "lookbookUploads",
                            "abstractKey": null
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": (v14/*: any*/),
                            "type": "Background",
                            "abstractKey": null
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": (v14/*: any*/),
                            "type": "LookbookTemplate",
                            "abstractKey": null
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": (v14/*: any*/),
                            "type": "Shape",
                            "abstractKey": null
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": (v14/*: any*/),
                            "type": "ShopcastProduct",
                            "abstractKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v7/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "f419e574060af17e03abfe915ac8f789",
    "id": null,
    "metadata": {},
    "name": "categorizeImageListQuery",
    "operationKind": "query",
    "text": "query categorizeImageListQuery(\n  $count: Int!\n  $cursor: String\n  $category_id: String!\n  $sub_category: String\n  $teamId: String\n) {\n  store {\n    ...categorizeImageList_store_TYFRs\n    id\n  }\n}\n\nfragment categorizeImageList_store_TYFRs on Store {\n  currentUser {\n    id\n    adminAccess\n    role\n    lookbookUploadsSubCategoryList(category: $category_id) {\n      id\n      title\n    }\n    lookbookUploads(category: $category_id, sub_category: $sub_category, first: $count, after: $cursor, teamId: $teamId) {\n      edges {\n        node {\n          id\n          title\n          url\n          canEdit\n          category {\n            title\n            id\n          }\n          subCategoryList {\n            id\n            category {\n              title\n              id\n            }\n            title\n          }\n          subCategoryTitle\n          isFavourite(teamId: $teamId)\n          __typename\n        }\n        cursor\n      }\n      pageInfo {\n        endCursor\n        hasNextPage\n      }\n    }\n    lookbookFavourites(category: $category_id, teamId: $teamId) {\n      edges {\n        node {\n          __typename\n          ... on lookbookUploads {\n            id\n            isFavourite(teamId: $teamId)\n          }\n          ... on Background {\n            id\n          }\n          ... on LookbookTemplate {\n            id\n          }\n          ... on Shape {\n            id\n          }\n          ... on ShopcastProduct {\n            id\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "9708393e92d0b7e9e383c1859e43a804";

module.exports = ((node/*: any*/)/*: Query<
  categorizeImageListQuery$variables,
  categorizeImageListQuery$data,
>*/);
