/**
 * @generated SignedSource<<5414783d4fa70fb68641178650644f44>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Mutation } from 'relay-runtime';
export type ToggleTemplateHideShowInput = {|
  clientMutationId?: ?string,
  status?: ?boolean,
  templateId: string,
|};
export type toggleTemplateHideShowMutation$variables = {|
  input: ToggleTemplateHideShowInput,
|};
export type toggleTemplateHideShowMutation$data = {|
  +toggleTemplateHideShow: ?{|
    +output: ?boolean,
  |},
|};
export type toggleTemplateHideShowMutation = {|
  response: toggleTemplateHideShowMutation$data,
  variables: toggleTemplateHideShowMutation$variables,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "ToggleTemplateHideShowPayload",
    "kind": "LinkedField",
    "name": "toggleTemplateHideShow",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "output",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "toggleTemplateHideShowMutation",
    "selections": (v1/*: any*/),
    "type": "ShopshareMutationRoot",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "toggleTemplateHideShowMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "17f88a65d2faadcd4bc7f8f1112ab417",
    "id": null,
    "metadata": {},
    "name": "toggleTemplateHideShowMutation",
    "operationKind": "mutation",
    "text": "mutation toggleTemplateHideShowMutation(\n  $input: ToggleTemplateHideShowInput!\n) {\n  toggleTemplateHideShow(input: $input) {\n    output\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "938e25cc93146a0890069f801b9a77fa";

module.exports = ((node/*: any*/)/*: Mutation<
  toggleTemplateHideShowMutation$variables,
  toggleTemplateHideShowMutation$data,
>*/);
