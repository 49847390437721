/**
 * @generated SignedSource<<e150a1704fce06fee425a5288fac6fe4>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Query } from 'relay-runtime';
import type { listOfRakutenSitesList_store$fragmentType } from "./listOfRakutenSitesList_store.graphql";
export type listOfRakutenSitesListQuery$variables = {|
  category?: ?string,
  count: number,
  cursor?: ?string,
  network?: ?string,
  partner?: ?string,
  region?: ?string,
  searchText?: ?string,
  sortBy?: ?string,
|};
export type listOfRakutenSitesListQuery$data = {|
  +store: ?{|
    +$fragmentSpreads: listOfRakutenSitesList_store$fragmentType,
  |},
|};
export type listOfRakutenSitesListQuery = {|
  response: listOfRakutenSitesListQuery$data,
  variables: listOfRakutenSitesListQuery$variables,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "category"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "count"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "cursor"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "network"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "partner"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "region"
},
v6 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "searchText"
},
v7 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "sortBy"
},
v8 = {
  "kind": "Variable",
  "name": "category",
  "variableName": "category"
},
v9 = {
  "kind": "Variable",
  "name": "network",
  "variableName": "network"
},
v10 = {
  "kind": "Variable",
  "name": "partner",
  "variableName": "partner"
},
v11 = {
  "kind": "Variable",
  "name": "region",
  "variableName": "region"
},
v12 = {
  "kind": "Variable",
  "name": "searchText",
  "variableName": "searchText"
},
v13 = {
  "kind": "Variable",
  "name": "sortBy",
  "variableName": "sortBy"
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v15 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "cursor"
  },
  (v8/*: any*/),
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "count"
  },
  (v9/*: any*/),
  (v10/*: any*/),
  (v11/*: any*/),
  (v12/*: any*/),
  (v13/*: any*/)
],
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/),
      (v7/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "listOfRakutenSitesListQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Store",
        "kind": "LinkedField",
        "name": "store",
        "plural": false,
        "selections": [
          {
            "args": [
              (v8/*: any*/),
              {
                "kind": "Variable",
                "name": "count",
                "variableName": "count"
              },
              {
                "kind": "Variable",
                "name": "cursor",
                "variableName": "cursor"
              },
              (v9/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/),
              (v12/*: any*/),
              (v13/*: any*/)
            ],
            "kind": "FragmentSpread",
            "name": "listOfRakutenSitesList_store"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "ShopshareQueryRoot",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v2/*: any*/),
      (v0/*: any*/),
      (v7/*: any*/),
      (v5/*: any*/),
      (v3/*: any*/),
      (v6/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Operation",
    "name": "listOfRakutenSitesListQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Store",
        "kind": "LinkedField",
        "name": "store",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "currentUser",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isRetailer",
                "storageKey": null
              },
              (v14/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v15/*: any*/),
            "concreteType": "StoreRakutenSiteConnection",
            "kind": "LinkedField",
            "name": "rakutenSites",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "StoreRakutenSiteEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "RakutenSite",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v16/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "mid",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "urls",
                        "storageKey": null
                      },
                      (v14/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "displayName",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "description",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Country",
                        "kind": "LinkedField",
                        "name": "countries",
                        "plural": true,
                        "selections": [
                          (v14/*: any*/),
                          (v16/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "code",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Category",
                        "kind": "LinkedField",
                        "name": "category",
                        "plural": false,
                        "selections": [
                          (v14/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "commissionRate",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "imageUrl",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "displayUrl",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "logoUrl",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "isPublic",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "specialCommissionRate",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "specialCommissionRateValidUntil",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasSpecialRate",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "SiteNetwork",
                        "kind": "LinkedField",
                        "name": "networks",
                        "plural": true,
                        "selections": [
                          (v14/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "currency",
                            "storageKey": null
                          },
                          (v16/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "partneredNetwork",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "__typename",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "cursor",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "PageInfo",
                "kind": "LinkedField",
                "name": "pageInfo",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "endCursor",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hasNextPage",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v15/*: any*/),
            "filters": [
              "category",
              "sortBy",
              "region",
              "network",
              "searchText",
              "partner"
            ],
            "handle": "connection",
            "key": "admin_list_rakutenSites",
            "kind": "LinkedHandle",
            "name": "rakutenSites"
          },
          (v14/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "9bd7ba049e881957a490c4b08967022d",
    "id": null,
    "metadata": {},
    "name": "listOfRakutenSitesListQuery",
    "operationKind": "query",
    "text": "query listOfRakutenSitesListQuery(\n  $count: Int!\n  $cursor: String\n  $category: String\n  $sortBy: String\n  $region: String\n  $network: String\n  $searchText: String\n  $partner: String\n) {\n  store {\n    ...listOfRakutenSitesList_store_39uzfj\n    id\n  }\n}\n\nfragment listOfRakutenSitesList_store_39uzfj on Store {\n  currentUser {\n    isRetailer\n    id\n  }\n  rakutenSites(first: $count, after: $cursor, category: $category, sortBy: $sortBy, region: $region, network: $network, searchText: $searchText, partner: $partner) {\n    edges {\n      node {\n        name\n        mid\n        urls\n        id\n        displayName\n        description\n        countries {\n          id\n          name\n          code\n        }\n        category {\n          id\n        }\n        commissionRate\n        imageUrl\n        displayUrl\n        logoUrl\n        isPublic\n        specialCommissionRate\n        specialCommissionRateValidUntil\n        hasSpecialRate\n        networks {\n          id\n          currency\n          name\n        }\n        partneredNetwork\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "0170ea468e72d2bd8f4096235fc1cabe";

module.exports = ((node/*: any*/)/*: Query<
  listOfRakutenSitesListQuery$variables,
  listOfRakutenSitesListQuery$data,
>*/);
