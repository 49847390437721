import React, { Component } from "react"
import { createPaginationContainer, graphql } from "react-relay"
import InfiniteScroll from "react-infinite-scroller"
import buttons from "shared/styles/components/buttons.css"
import modal from "shared/styles/components/modal.css"
import pageContent from "shared/styles/layout/page-content.css"
import section from "shared/styles/layout/section.css"
import grids from "shared/styles/components/grids.css"
import empty from "shared/styles/components/empty.css"
import TemplateIcon from "shared/assets/lb-template-icon.svg"
import LoadingDots from "components/loading-dots/loading-dots"
import TemplateCard from "components/cards/lookbook/template-card"
import ConformationPrompt from "components/modals/conformation-prompt/conformation-prompt"
import deleteTemplateMutation from "mutations/lookbook/delete-template"
import duplicateTemplateMutation from "mutations/lookbook/duplicate-template"
import { withRouter } from "../../../../route-helpers"

class ListOfTemplates extends Component {
	constructor(props) {
		super(props)
		this.state = {
            deleteObj: null,
        }
		this.handleInfiniteLoad = this.handleInfiniteLoad.bind(this)
	}

	handleInfiniteLoad() {
		const { relay } = this.props
		relay.loadMore(12)
	}

	renderEmpty() {
		const { category, navigate } = this.props
		return (
			<div className={empty.root}>
				<div className={empty.container}>
					<div className={empty.content}>
						<TemplateIcon className={empty.icon} />
						<h4 className={empty.headline}>No templates found</h4>
						<p className={empty.subline}>
							Any templates created will appear here.
						</p>
						<button
							type="button"
							className={empty.cta}
							onClick={() => {
								navigate(
									`/shopboard/create?tempCategory=${category}`
								)
							}}
						>
							New Template
						</button>
					</div>
				</div>
			</div>
		)
	}

	render() {
        const { deleteObj } = this.state
		const { store, relay, navigate, category, sort, team } = this.props
		const { edges } = store.currentUser.lookbookTemplates
		return (
			<>
				<div className={pageContent.root}>
					<div className={section.root}>
						<div className={section.body}>
							{edges.length > 0 ? (
								<div className={grids.container}>
									<InfiniteScroll
										loadMore={this.handleInfiniteLoad}
										hasMore={relay.hasMore()}
										loader={
											<div className={grids.loadMore}>
												<LoadingDots />
											</div>
										}
										pageStart={0}
									>
										<div className={grids.row}>
											{edges.map(({ node }) => (
												<div className={grids.col3} key={node.id}>
													<TemplateCard
														node={node}
														editImage={() =>
															navigate(
																`/shopboard/create/${node.lookbook?.id}`
															)
														}
														deleteImage={() => {
															this.setState({ deleteObj: node })
														}}
														onDuplicate={() =>{
															const other = { category, sort }
															if (team) other.teamId = team
															duplicateTemplateMutation.commit(
																relay.environment,
																node.lookbook.id,
																store?.currentUser.id,
																() => {},
																() => {},
																other
															)
														}}
														relay={relay}
													/>
												</div>
											))}
										</div>
									</InfiniteScroll>
								</div>
							) : (
								this.renderEmpty()
							)}
						</div>
					</div>
				</div>
				<ConformationPrompt
					openModal={!!deleteObj}
					closeModal={this.closeModal}
					approveEvent={() => {
						const other = { category, sort }
						if (team) other.teamId = team
						deleteTemplateMutation.commit(
							relay.environment,
							deleteObj.lookbook.id,
							deleteObj.id,
							store?.currentUser.id,
							() => {},
							() => {
								this.setState({ deleteObj: null })
							},
							other
						)
					}}
					title="Delete Template"
					declineEvent={() => {
						this.setState({ deleteObj: null })
					}}
					approveButtonStyle={buttons.primaryRed}
					approveText="Delete"
					declineText="Cancel"
				>
					<p className={modal.para}>
						Do you want to permanently delete{" "}
						<b>{deleteObj?.lookbook ? deleteObj.lookbook.title : ""}</b>{" "}
						Template?
					</p>
					<p className={modal.paraImportant}>
						This action cannot be undone.
					</p>
				</ConformationPrompt>
			</>
		)
	}
}

const query = graphql`
	query listOfTemplatesQuery($count: Int!, $cursor: String, $teamId: String, $category: String, $sort: String) {
		store {
			...listOfTemplates_store
				@arguments(count: $count, cursor: $cursor, teamId: $teamId, category: $category, sort: $sort)
		}
	}
`

ListOfTemplates = createPaginationContainer(
	ListOfTemplates,
	{
		store: graphql`
			fragment listOfTemplates_store on Store
			@argumentDefinitions(
				count: { type: "Int", defaultValue: 12 }
				cursor: { type: "String" }
				teamId: { type: "String" }
				category: { type: "String" }
				sort: { type: "String" }
			) {
				id
				currentUser {
					id
					role
					lookbookTemplates(
						first: $count
						after: $cursor
						category: $category
						teamId: $teamId
						sort: $sort
					) @connection(key: "currentUser_lookbookTemplates") {
						edges {
							node {
								id
								categories
								canEdit
								isFavourite
								createdCount
								isGlobal
								lookbook {
									id
									title
									url
								}
							}
						}
					}
				}
			}
		`,
	},
	{
		direction: "forward",
		getConnectionFromProps(props) {
			const { lookbookTemplates } = props.store.currentUser
			return lookbookTemplates
		},
		getFragmentVariables(prevVars, totalCount) {
			return {
				...prevVars,
				count: totalCount,
			}
		},
		getVariables(props, { cursor, count }) {
			return {
				count: count || 12,
				teamId: props.teamId,
				category: props.category,
				sort: props.sort,
				cursor,
			}
		},
		query,
	}
)

export default {
	Component: withRouter(ListOfTemplates),
	query,
	params: {
		count: 12,
		cursor: null,
		search: null,
		teamId: null,
		sort: "",
		category: "userTemplates",
	},
}
