import React, { Component } from "react"
import { createPaginationContainer, graphql } from "react-relay"
import { Virtuoso } from "react-virtuoso"
import empty from "shared/styles/components/empty.css"
import LoadingDots from "components/loading-dots/loading-dots"
import CollectionSelectItem from "./collection-select-item"
import styles from "./category-select.css"

class CollectionSelect extends Component {
	constructor(props) {
		super(props)

		this.state = {
			loading: false,
		}
		this.handleLoadMore = this.handleLoadMore.bind(this)
		this.isItemLoaded = this.isItemLoaded.bind(this)
	}

	handleLoadMore() {
		const { relay } = this.props
		relay.loadMore(12, () => {
			this.setState({ loading: false })
		})
		this.setState({ loading: true })
	}

	get collections() {
		const { store, keepDefault } = this.props
		const {
			productCollectionSearchItems: { collections, defaultCollection: dc },
		} = store.currentUser
		if (!dc && (!collections || collections.edges.length <= 0)) return []
		const items =
			keepDefault && dc
				? [{ node: dc }, ...collections.edges]
				: collections.edges
		return items
	}

	isItemLoaded(index) {
		const { props } = this
		const { relay } = props
		const items = this.collections
		return !relay.hasMore() || index < items.length
	}

	renderImageCard(index) {
		const { props } = this
		const {
			store,
			relay,
			collections: selectedCollections,
			setCollections,
			renderMenu,
			limitType,
		} = props
		const {
			id: currentUserId,
		} = store.currentUser
		const items = this.collections
		const x = items[index]
		if (!x) return null
		const c = x.node
		return (
			<CollectionSelectItem
				key={c.id}
				store={store}
				relay={relay}
				collection={c}
				selectedCollections={selectedCollections}
				setCollections={setCollections}
				renderMenu={renderMenu}
				owned={currentUserId === c.user.id}
				showClosetIcon={limitType.length > 1 || limitType[0] !== "closet"}
			/>
		)
	}

	render() {
		const { props, state } = this
		const { relay } = props

		const items = this.collections
		const itemCount = relay.hasMore() ? items.length + 1 : items.length
		const loadMoreItems = state.loading ? () => {} : this.handleLoadMore

		if (items.length === 0)
			return <div className={empty.simpleSml}>No results found.</div>
		return (
			<div className={styles.root}>
				<div className={styles.scrollable}>
					<Virtuoso
						style={{ height: `100%` }}
						totalCount={itemCount}
						endReached={loadMoreItems}
						increaseViewportBy={200}
						itemContent={(index) => this.renderImageCard(index)}
					/>
				</div>
				{state.loading && (
					<div className={styles.loadMore}>
						<LoadingDots />
					</div>
				)}
			</div>
		)
	}
}

const query = graphql`
	query collectionSelectQuery(
		$search: String
		$count: Int!
		$cursor: String
		$collectionSearch: String
		$categories: [String]
		$brands: [String]
		$clients: [String]
		$limitType: [String]
		$folderId: String
		$teamId: String
	) {
		store {
			...collectionSelect_store
				@arguments(
					count: $count
					cursor: $cursor
					search: $search
					collectionSearch: $collectionSearch
					categories: $categories
					brands: $brands
					clients: $clients
					limitType: $limitType
					folderId: $folderId
					teamId: $teamId
				)
		}
	}
`

CollectionSelect = createPaginationContainer(
	CollectionSelect,
	{
		store: graphql`
			fragment collectionSelect_store on Store
			@argumentDefinitions(
				count: { type: "Int", defaultValue: 12 }
				cursor: { type: "String" }
				search: { type: "String" }
				collectionSearch: { type: "String" }
				categories: { type: "[String]" }
				brands: { type: "[String]" }
				clients: { type: "[String]" }
				limitType: { type: "[String]" }
				folderId: { type: "String" }
				teamId: { type: "String" }
			) {
				currentUser {
					id
					productCollectionSearchItems(
						search: $search
						categories: $categories
						brands: $brands
						collections: []
						clientIds: $clients
						limitType: $limitType
						folderId: $folderId
						teamId: $teamId
						resultTypes: ["collections"]
					) {
						collections(
							first: $count
							after: $cursor
							search: $collectionSearch
						)
							@connection(
								key: "currentUser_productCollectionSearchItems_collections"
							) {
							edges {
								node {
									id
									title
									isPublished
									folder {
										id
										name
									}
									isACloset
									isDefault
									user {
										id
										fullname
									}
									client {
										id
									}
								}
							}
						}
						defaultCollection(teamId: $teamId) {
							id
							title
							isPublished
							folder {
								id
								name
							}
							isACloset
							isDefault
							user {
								id
								fullname
							}
							client {
								id
							}
						}
					}
				}
			}
		`,
	},
	{
		direction: "forward",
		getConnectionFromProps(props) {
			const {
				collections,
			} = props.store.currentUser.productCollectionSearchItems
			return collections && collections
		},
		getFragmentVariables(prevVars, totalCount) {
			return {
				...prevVars,
				count: totalCount,
			}
		},
		getVariables(props, { cursor }) {
			return {
				count: props.count,
				cursor,
				search: props.search,
				collectionSearch: props.collectionSearch,
				brands: props.brands,
				categories: props.categories,
				clients: props.clients,
				limitType: props.limitType,
				folderId: props.folderId,
				teamId: props.teamId,
			}
		},
		query,
	}
)

export default {
	Component: CollectionSelect,
	query,
	params: {
		count: 12,
		cursor: null,
		search: null,
		collectionSearch: null,
		brands: [],
		categories: [],
		clients: [],
		limitType: [],
		folderId: null,
		teamId: null,
	},
	props: {
		count: 12,
		search: null,
		collectionSearch: null,
		brands: [],
		categories: [],
		clients: [],
		limitType: [],
		folderId: null,
		teamId: null,
	},
}
