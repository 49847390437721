import { commitMutation, graphql } from "react-relay"
import { complete } from "../helper.js"

const mutation = graphql`
	mutation updateCampaignMutation($input: UpdateCampaignInput!) {
		updateCampaign(input: $input) {
			store {
				id
			}
		}
	}
`

const commit = (env, data, adminId, onCompleted, onError) =>
	commitMutation(env, {
		mutation,
		variables: {
			input: {
				id: data.id,
				name: data.name,
				monthlyCommision: data.monthlyCommision,
				annualCommision: data.annualCommision,
				type: data.type,
				allUsers: data.allUsers,				
				description: data.description,				
				cookiePeriod: data.cookiePeriod,				
				monthlyCoupon: data.monthlyCoupon,				
				annualCoupon: data.annualCoupon,				
				users: data.users.map((i) => i.id),
			},
		},
		onCompleted: complete(onCompleted, onError),
		onError: (e) => complete(null, [e]),
		updater: (store, gdata) => {
			if (gdata.updateCampaign) {
				const campaign = store.get(data.id)
				campaign.setValue(data.name,"name")
				campaign.setValue(data.monthlyCommision,"monthlyCommision")
				campaign.setValue(data.annualCommision,"annualCommision")
				campaign.setValue(data.type,"type")
				campaign.setValue(data.allUsers,"allUsers")
				campaign.setValue(data.description,"description")
				campaign.setValue(data.cookiePeriod,"cookiePeriod")
				campaign.setValue(data.monthlyCoupon,"monthlyCoupon")
				campaign.setValue(data.annualCoupon,"annualCoupon")
				if(!data.allUsers){
					const users = data.users.map(i=>store.get(i.id))
					campaign.setLinkedRecords(users, 'users')
				}
			}
		},
	})

export default { commit }
