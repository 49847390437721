import { commitMutation, graphql } from "react-relay"
import { complete } from "../helper.js"

const mutation = graphql`
mutation deleteProductImageMutation($input: DeleteProductImageInput!) {
    deleteProductImage(input: $input) {
      store {
        id
      }
      images
	  productImage
    }
  }  
`

const commit = (
	environment,
	imageUrl,
	productId,
	onCompleted,
	onError
) =>
	commitMutation(environment, {
		mutation,
		variables: { input: { imageUrl, productId } },
		onCompleted: complete(onCompleted, onError),
		onError: (e) => complete(null, [e]),
		updater: (storeProxy, data) => {
			if (data.deleteProductImage) {
				const productProxy = storeProxy.get(productId)
				productProxy.setValue(data.deleteProductImage.images, "productImages")
				productProxy.setValue(data.deleteProductImage.productImage, "imageUrl")
				productProxy.setValue(data.deleteProductImage.productImage, "diffbotImageUrl")
			}
		},
	})

export default { commit }
