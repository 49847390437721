import React from "react"
import ReactPlayer from "react-player/lazy"
import ListOfFeatures from "components/list-of-features/list-of-features"
import ListOfBullets from "components/list-of-bullets/list-of-bullets"
import TestimonialDivider from "components/testimonial-divider/testimonial-divider"
import LandingActions from "components/landing-actions/landing-actions"
import PageFooter from "components/page-footer/page-footer"
import { createFragmentContainer, graphql } from "react-relay"
import { Helmet } from "react-helmet"
import feature from "shared/styles/layout/feature.css"
import landing from "shared/styles/layout/landing.css"
import ScrollToTopOnMount from "components/scroll-to-top/scroll-to-top"
import Virtual1 from "shared/assets/feature-images/virtual-1.jpg"
import Virtual2 from "shared/assets/feature-images/virtual-2.jpg"
import Virtual3 from "shared/assets/feature-images/virtual-3.jpg"
import Video from "shared/assets/feature-videos/hero.mp4"
import styles from "./for-virtual-styling.css"

function ForVirtualStyling({ store }) {
	const user = store.currentUser
	return (
		<div className={styles.root}>
			<Helmet>
				<title>ShopShareTV for Virtual Styling</title>
			</Helmet>
			<ScrollToTopOnMount />
			<section className={`${landing.section} ${landing.gray100}`}>
				<div className={landing.content}>
					<h1 className={styles.displayTitle}>
						Virtual styling like you’ve never seen it before!
					</h1>
					<p className={landing.displayLead}>
						Curate, style and recommend products from any online store!
					</p>
					{!user && (
						<div className={landing.actions}>
							<LandingActions />
						</div>
					)}
					{/* <div className={landing.hero}>
						<picture>
							<source srcSet={Hero} type="image/png" />
							<img src={Hero} alt="Hero" className={landing.heroImg} />
						</picture>
					</div> */}
					<div className={landing.video}>
						<ReactPlayer
							url={Video}
							width="100%"
							className={landing.player}
							loop
							playsinline
							controls={false}
							playing
							muted
							height="100%"
						/>
					</div>
				</div>
			</section>
			<section className={landing.sectionFullWidth}>
				<div className={landing.content}>
					<h2 className={landing.title}>
						Why virtual stylists use ShopShare
					</h2>
					<div className={landing.listOfBullets}>
						<ListOfBullets
							bullets={[
								{
									key: "b1",
									label: "Informative and educational",
								},
								{
									key: "b2",
									label: "Ease of automation",
								},
								{
									key: "b3",
									label: "Makes scaling simple",
								},
							]}
						/>
					</div>
				</div>
			</section>
			<section className={landing.section}>
				<div className={landing.contentNoTopPadding}>
					<div className={feature.root}>
						<div className={feature.mediaFrame}>
							<img
								className={feature.img}
								src={Virtual1}
								alt="Create compelling videos."
							/>
						</div>
						<div className={feature.content}>
							<div className={feature.inner}>
								<h2 className={feature.heading}>
									Create compelling videos
								</h2>
								<p className={feature.body}>
									Quickly and easily create compelling videos that will
									see your engagement soar. Shop with your clients
									without them ever having to leave their living room!{" "}
								</p>
							</div>
						</div>
					</div>
				</div>
			</section>
			<section className={landing.sectionFullWidth}>
				<div
					className={`${landing.contentFullWidth} ${landing.primary100}`}
				>
					<h2 className={landing.title}>Features you’ll love</h2>
					<div className={landing.listOfFeatures}>
						<ListOfFeatures
							features={[
								{
									key: "f1",
									heading: "Personalised Videos",
									body:
										"Create engaging shoppable videos, with just a few clicks.",
								},
								{
									key: "f2",
									heading: "Moodboard Curation",
									body:
										"Curate, style and showcase looks, all with shoppable links.",
								},
								{
									key: "f3",
									heading: "Video Lookbooks",
									body:
										"Share multiple edits via one link for your client to access anytime.",
								},
								{
									key: "f4",
									heading: "Extra Income",
									body:
										"Sell access to your content and earn via affiliated retailers.",
								},
							]}
						/>
					</div>
				</div>
			</section>
			<section className={landing.section}>
				<div className={landing.content}>
					<div className={feature.rootFlip}>
						<div className={feature.media}>
							<img
								className={feature.img}
								src={Virtual2}
								alt="Swap clunky PDFs."
							/>
						</div>
						<div className={feature.content}>
							<div className={feature.inner}>
								<h2 className={feature.heading}>
									Spend more time doing what you love
								</h2>
								<p className={feature.body}>
									Never want to collate links again? Upgrade from
									clunky PDFs and lengthy explanations to ShopShare’s
									expansive toolkit of videos, stunning moodboards and
									interactive lookbooks.
								</p>
							</div>
						</div>
					</div>
				</div>
			</section>
			<section className={landing.section}>
				<div className={landing.content}>
					<TestimonialDivider
						testimonial={{
							quote:
								"I am blown away by ShopShare! I have seamlessly pivoted my business from in-person meetings to personal virtual shopping experiences that are safe and from the comfort of our homes! My clients are LOVING it!",
							name: "Amanda Wood",
							channelURL: "https://thehauteedit.shopshare.tv",
							company: "thehauteedit",
						}}
					/>
				</div>
			</section>
			<section className={landing.section}>
				<div className={landing.content}>
					<div className={feature.root}>
						<div className={feature.mediaFrame}>
							<img
								className={feature.img}
								src={Virtual3}
								alt="Shoppable Moodboards"
							/>
						</div>
						<div className={feature.content}>
							<div className={feature.inner}>
								<h2 className={feature.heading}>
									Easily scale your business
								</h2>
								<p className={feature.body}>
									Expand your client base with your own shopping
									channel, create private videos and design shoppable
									moodboards from anywhere, at any time.
								</p>
							</div>
						</div>
					</div>
				</div>
			</section>
			<section className={landing.section}>
				<div className={landing.contentPageFooter}>
					<PageFooter
						title="Use ShopShare for Virtual Styling"
						lead="Curate, style and recommend products from any online store!"
						currentUser={user}
					/>
				</div>
			</section>
		</div>
	)
}

// eslint-disable-next-line no-func-assign
ForVirtualStyling = createFragmentContainer(ForVirtualStyling, {
	store: graphql`
		fragment forVirtualStyling_store on Store {
			id
			currentUser {
				id
			}
		}
	`,
})

const query = graphql`
	query forVirtualStylingQuery {
		store {
			...forVirtualStyling_store
		}
	}
`

export default { Component: ForVirtualStyling, query }
