/**
 * @generated SignedSource<<83a0607be6ac8f5974e205abed011a58>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { clientFolderCard_shopcastFolder$fragmentType } from "./../../../components/cards/client-folder-card/__generated__/clientFolderCard_shopcastFolder.graphql";
import type { FragmentType } from "relay-runtime";
declare export opaque type clientViewFolders_store$fragmentType: FragmentType;
export type clientViewFolders_store$data = {|
  +currentUser: ?{|
    +clientFolder: ?{|
      +folders: ?{|
        +edges: ?$ReadOnlyArray<?{|
          +cursor: string,
          +node: ?{|
            +$fragmentSpreads: clientFolderCard_shopcastFolder$fragmentType,
          |},
        |}>,
        +pageInfo: {|
          +endCursor: ?string,
          +hasNextPage: boolean,
          +hasPreviousPage: boolean,
          +startCursor: ?string,
        |},
      |},
      +id: string,
    |},
  |},
  +id: string,
  +$fragmentType: clientViewFolders_store$fragmentType,
|};
export type clientViewFolders_store$key = {
  +$data?: clientViewFolders_store$data,
  +$fragmentSpreads: clientViewFolders_store$fragmentType,
  ...
};
*/

var node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": 12,
      "kind": "LocalArgument",
      "name": "count"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "cursor"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "folder"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "sort"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "count",
        "cursor": "cursor",
        "direction": "forward",
        "path": [
          "currentUser",
          "clientFolder",
          "folders"
        ]
      }
    ]
  },
  "name": "clientViewFolders_store",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "currentUser",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": [
            {
              "kind": "Variable",
              "name": "id",
              "variableName": "folder"
            }
          ],
          "concreteType": "ShopcastFolder",
          "kind": "LinkedField",
          "name": "clientFolder",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": "folders",
              "args": null,
              "concreteType": "ShopcastFolderShopcastFolderConnection",
              "kind": "LinkedField",
              "name": "__clientFolder_folders_connection",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "ShopcastFolderShopcastFolderEdge",
                  "kind": "LinkedField",
                  "name": "edges",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "cursor",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "ShopcastFolder",
                      "kind": "LinkedField",
                      "name": "node",
                      "plural": false,
                      "selections": [
                        {
                          "args": null,
                          "kind": "FragmentSpread",
                          "name": "clientFolderCard_shopcastFolder"
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "__typename",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "PageInfo",
                  "kind": "LinkedField",
                  "name": "pageInfo",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "hasPreviousPage",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "hasNextPage",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "startCursor",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "endCursor",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Store",
  "abstractKey": null
};
})();

(node/*: any*/).hash = "3cd0c780d1c8e1997d69088b80a1691b";

module.exports = ((node/*: any*/)/*: Fragment<
  clientViewFolders_store$fragmentType,
  clientViewFolders_store$data,
>*/);
