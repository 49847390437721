/**
 * @generated SignedSource<<61b733181cc1d26fe0766a09ae0a206a>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Mutation } from 'relay-runtime';
export type AssignProductToShopcastInput = {|
  clientMutationId?: ?string,
  products?: ?$ReadOnlyArray<?InputShopcastItem>,
  shopcastId?: ?string,
|};
export type InputShopcastItem = {|
  end?: ?number,
  id?: ?string,
  productId?: ?string,
  start?: ?number,
|};
export type assignProductsMutation$variables = {|
  input: AssignProductToShopcastInput,
|};
export type assignProductsMutation$data = {|
  +assignProductToShopcast: ?{|
    +store: ?{|
      +id: string,
    |},
  |},
|};
export type assignProductsMutation = {|
  response: assignProductsMutation$data,
  variables: assignProductsMutation$variables,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "AssignProductToShopcastPayload",
    "kind": "LinkedField",
    "name": "assignProductToShopcast",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Store",
        "kind": "LinkedField",
        "name": "store",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "assignProductsMutation",
    "selections": (v1/*: any*/),
    "type": "ShopshareMutationRoot",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "assignProductsMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "9d9ff393a8e1034cf4125f6af1407cef",
    "id": null,
    "metadata": {},
    "name": "assignProductsMutation",
    "operationKind": "mutation",
    "text": "mutation assignProductsMutation(\n  $input: AssignProductToShopcastInput!\n) {\n  assignProductToShopcast(input: $input) {\n    store {\n      id\n    }\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "a27e3f8ad98f673c951a0728c03cab52";

module.exports = ((node/*: any*/)/*: Mutation<
  assignProductsMutation$variables,
  assignProductsMutation$data,
>*/);
