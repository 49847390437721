/**
 * @generated SignedSource<<5b6c1f15eb0aa359a5111879fb6b9f6b>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentType } from "relay-runtime";
declare export opaque type userShopcastCard_shopcast$fragmentType: FragmentType;
export type userShopcastCard_shopcast$data = {|
  +category: ?{|
    +id: string,
    +name: ?string,
  |},
  +description: ?string,
  +duration: ?number,
  +folder: ?{|
    +id: string,
    +name: ?string,
    +path: ?$ReadOnlyArray<?{|
      +name: ?string,
      +value: ?string,
    |}>,
  |},
  +id: string,
  +inRoot: ?boolean,
  +isArchived: ?boolean,
  +isFlagged: ?boolean,
  +isPinned: ?boolean,
  +isPublished: ?boolean,
  +isStarred: ?boolean,
  +likes: ?number,
  +posterUrl: ?string,
  +shopcastItems: ?{|
    +edges: ?$ReadOnlyArray<?{|
      +node: ?{|
        +end: ?number,
        +id: string,
        +product: ?{|
          +id: string,
          +imageUrl: ?string,
        |},
        +start: ?number,
      |},
    |}>,
  |},
  +title: ?string,
  +transactions: ?$ReadOnlyArray<?{|
    +id: string,
    +orderId: ?string,
  |}>,
  +transcoded: ?string,
  +user: ?{|
    +customDomain: ?string,
    +id: string,
    +username: ?string,
  |},
  +views: ?number,
  +youtubeUploadStatus: ?string,
  +$fragmentType: userShopcastCard_shopcast$fragmentType,
|};
export type userShopcastCard_shopcast$key = {
  +$data?: userShopcastCard_shopcast$data,
  +$fragmentSpreads: userShopcastCard_shopcast$fragmentType,
  ...
};
*/

var node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "userShopcastCard_shopcast",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "description",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "posterUrl",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isPublished",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isPinned",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "transcoded",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "duration",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isFlagged",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "views",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "likes",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isArchived",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isStarred",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "inRoot",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "youtubeUploadStatus",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Category",
      "kind": "LinkedField",
      "name": "category",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "SiteTransactionObj",
      "kind": "LinkedField",
      "name": "transactions",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "orderId",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "user",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "username",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "customDomain",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ShopcastFolder",
      "kind": "LinkedField",
      "name": "folder",
      "plural": false,
      "selections": [
        (v1/*: any*/),
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "NameValue",
          "kind": "LinkedField",
          "name": "path",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "value",
              "storageKey": null
            },
            (v1/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "first",
          "value": 100
        }
      ],
      "concreteType": "ShopcastItemConnection",
      "kind": "LinkedField",
      "name": "shopcastItems",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ShopcastItemEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "shopcastItem",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "start",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "end",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "ShopcastProduct",
                  "kind": "LinkedField",
                  "name": "product",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "imageUrl",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "shopcastItems(first:100)"
    }
  ],
  "type": "Shopcast",
  "abstractKey": null
};
})();

(node/*: any*/).hash = "7e6b157fea4897b15732513c8000c987";

module.exports = ((node/*: any*/)/*: Fragment<
  userShopcastCard_shopcast$fragmentType,
  userShopcastCard_shopcast$data,
>*/);
