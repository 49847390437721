import { commitMutation, graphql } from "react-relay"

const mutation = graphql`
	mutation flagShopcastMutation(
		$input: FlagShopcastInput!
	) {
		flagShopcast(input: $input) {
			admin {
				shopcastUserReports {
					edges {
						node {
							__typename
							... on ShopcastUserReport {
								id
							}
							... on LookbookUserReport {
								id
							}
						}
					}
				}
			}
		}
	}
`

const commit = (env, reportId, type) => commitMutation(env, {
	mutation,
	variables: { input: { reportId, type } },
	updater: (storeProxy, data) => {
		if (data.flagShopcast) {
			const reportProxy = storeProxy.get(reportId)
			reportProxy.setValue("flagged", "status")
		}
	},
})

export default { commit }
