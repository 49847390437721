export const getHexaColorCode = (color) => {
	if (color.search('#') >= 0) {
		return color
	}
	if (color.search('rgb') >= 0) {
		const mySubString = color.substr(
			color.lastIndexOf("(") + 1,
			color.lastIndexOf(")")
		);
		let colors = mySubString.split(",")
		colors = colors.map(x => parseInt(x.trim(), 10))
		const componentToHex = (c) => {
			const hex = c.toString(16)
			return hex.length === 1 ? `0${hex}` : hex
		}
		return colors.length >= 3
			? `#${componentToHex(colors[0])}${componentToHex(
					colors[1]
			  )}${componentToHex(colors[2])}`
			: color
	}
	return color
}

export const hexToRgb = (hex) => {
	const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i
	const hexa = hex.replace(shorthandRegex, (m, r, g, b) => r + r + g + g + b + b)

	const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hexa)
	return result ? {
		r: parseInt(result[1], 16),
		g: parseInt(result[2], 16),
		b: parseInt(result[3], 16)
	} : null
}

export const getInvertedColor = (backgroundColor, primaryColor) => {
	if (backgroundColor === "0, 0, 0") {
		return "#ffffff"
	} if (backgroundColor === "255, 255, 255") {
		return primaryColor
	}
	const { r, g, b } = hexToRgb(primaryColor) || {}
	return (r * 0.299 + g * 0.587 + b * 0.114) > 186
		? "#222222"
		: "#FFFFFF"
}