import React, { useState } from "react"
import FontFaceObserver from "fontfaceobserver"
import LoadingDots from "components/loading-dots/loading-dots"
import { fontFamilyList } from "../lookbook/lookbook"
import LookbookDropdown from "./lookbook-dropdown"
import styles from "./lookbook-dropdown.css"

export default function FontFamilyPicker({
	fontFamily,
	onChange,
	runDebounce,
	dataTip,
}) {
	const listItems = []
	fontFamilyList.forEach((y) => {
		y.list.map((x) =>
			listItems.push({
				click: () => onChange({ fontFamily: x }),
				name: x,
				style: { fontFamily: x, fontSize: "var(--font-size-base)" },
			})
		)
	})
	const activeItem = listItems.find((x) => x.name === fontFamily)
	const [fondLoading, setFontLoading] = useState(true)
	const selectFont = async (font) => {
		const textArray = [
			{ fontFamily: font.name, fontWeight: 400 },
			{ fontFamily: font.name, fontWeight: "bold" },
		]
		if (textArray.length > 0) {
			const promise = textArray.map(async (fontObj) => {
				const fontObserver = new FontFaceObserver(fontObj.fontFamily, {
					weight: fontObj.fontWeight,
				})
				await fontObserver.load(fontObj, 5000)
			})
			await Promise.all(promise)
			setTimeout(() => {
				setFontLoading(false)
				font.click()
			}, 100)
		}
	}
	return (
		<LookbookDropdown
			label={fontFamily?.replace(/'/g, "").replace(/"/g, "")}
			textButton
			data-tooltip-id="tooltip"
			data-tooltip-content={dataTip}
			runDebounce={runDebounce}
		>
			<div className={styles.panelFontFamily}>
				<div className={styles.scroll}>
					{listItems.map((x) => (
						<div
							key={x.name}
							onClick={() => {
								setFontLoading(x.name)
								selectFont(x)
							}}
							tabIndex={-1}
							role="link"
							className={
								activeItem === x ? styles.itemActive : styles.item
							}
							style={x.style}
						>
							{x.name.replace(/'/g, "").replace(/"/g, "")}
							{fondLoading === x.name && (
								<div className={styles.loading}>
									<LoadingDots color="var(--gray500)" />
								</div>
							)}
						</div>
					))}
				</div>
			</div>
		</LookbookDropdown>
	)
}
