import React, { Component } from "react"
import { createFragmentContainer, graphql } from "react-relay"
import { Tooltip } from "react-tooltip"
import { Helmet } from "react-helmet"
import { useLocation } from "react-router-dom"
import qs from "query-string"
import HeaderContainer from "components/header/header"
import DefaultFooterContainer from "components/footer/footer"
import WebsiteFooterContainer from "components/footer-website/footer-website"
import ChannelFooterContainer from "components/footer-channel/footer-channel"
import IncorrectUsername from "components/modals/incorrect-username/incorrect-username"
import IncrementReferralVisitors from "mutations/referral/increment-referral-visitors"
import { withinShopshareDomain } from "services/custom-domain-service"
import cookieService from "services/cookie-service"
import { FeatureKeyENUM } from "services/plan-services"
import { showFooterPaths, hideHeaderPaths } from "constants"
import tooltip from "shared/styles/components/tooltip.css"
import styles from "./main.css"

// Generate ASCII Art Logo: http://patorjk.com/software/taag/#p=display&f=ANSI%20Shadow&t=Insight
// eslint-disable-next-line no-console
console.log(
	`%c
███████╗██╗  ██╗ ██████╗ ██████╗ ███████╗██╗  ██╗ █████╗ ██████╗ ███████╗
██╔════╝██║  ██║██╔═══██╗██╔══██╗██╔════╝██║  ██║██╔══██╗██╔══██╗██╔════╝
███████╗███████║██║   ██║██████╔╝███████╗███████║███████║██████╔╝█████╗  
╚════██║██╔══██║██║   ██║██╔═══╝ ╚════██║██╔══██║██╔══██║██╔══██╗██╔══╝  
███████║██║  ██║╚██████╔╝██║     ███████║██║  ██║██║  ██║██║  ██║███████╗
╚══════╝╚═╝  ╚═╝ ╚═════╝ ╚═╝     ╚══════╝╚═╝  ╚═╝╚═╝  ╚═╝╚═╝  ╚═╝╚══════╝                                                                       
`,
	"color: #8659F8"
)

class Main extends Component {
	constructor(props) {
		super(props)
		const {
			showHeader,
			showFooter,
			hideExtensionInstall,
		} = this.getHeaderAndFooterState(props)
		
		this.state = { showHeader, showFooter, hideExtensionInstall }

		const {
			relay: { environment },
			store: { currentUser, team },
		} = props

		if (currentUser?.userPlan && 
			(!team || currentUser.features.includes(FeatureKeyENUM.TEAMS))) {
			localStorage.setItem("plan", currentUser.userPlan.id);
		} else {
			localStorage.removeItem("plan");
		}

		const refferalVia =
			window.location.search && qs.parse(window.location.search).via
		if (refferalVia && !currentUser) {
			IncrementReferralVisitors.commit(
				environment,
				refferalVia,
				(data) => {
					cookieService.setRefferal(
						refferalVia,
						data?.incrementReferralVisitors?.referral?.campaign
							?.cookiePeriod
					)
				},
				() => {}
			)
		}
		if (currentUser) cookieService.removeRefferal()
	}

	componentDidUpdate(prevProps) {
		const { location, store } = this.props
		if (location !== prevProps.location) {
			const { showHeader, showFooter, hideExtensionInstall } =
				this.getHeaderAndFooterState(this.props)
			this.setState({ showHeader, showFooter, hideExtensionInstall })
		}
		if (
			store.currentUser &&
			store.currentUser.userPlan &&
			store.currentUser.userPlan.id !==
				prevProps.store.currentUser.userPlan.id
		) {
			if (!store.team || store.currentUser.features.includes(FeatureKeyENUM.TEAMS))
				localStorage.setItem("plan", store.currentUser.userPlan.id)
			else localStorage.removeItem("plan")
		}
	}

	getHeaderAndFooterState(props) {
		const path = props.location.pathname
		let showHeader = !withinShopshareDomain(window.location.host)
		let showFooter = null
		let hideExtensionInstall = false

		hideHeaderPaths.some((pathRegEx) => {
			if (pathRegEx.test(path)) showHeader = false
			return showHeader === false
		})

		const footerObj = showFooterPaths.find((pathRegExObj) =>
			pathRegExObj.regex.test(path)
		)
		showFooter = footerObj ? footerObj.type : null

		if (path.indexOf("/install")) {
			hideExtensionInstall = true
		}

		return { showHeader, showFooter, hideExtensionInstall }
	}

	getFooter(footerType, userType) {
		switch (footerType) {
			case "default":
				return <DefaultFooterContainer userType={userType} />
			case "channel":
				return <ChannelFooterContainer />
			case "website":
				return <WebsiteFooterContainer userType={userType} />
			default:
				return null
		}
	}

	render() {
		const { state, props } = this
		const {
			store: { currentUser },
		} = props
		return (
			<div className={styles.root}>
				<Helmet>
					<title>ShopShare</title>
					<meta
						name="description"
						content="Shopable, Shareable, Content."
					/>
					<link rel="canonical" href="https://shopshare.tv" />
					<link
						rel="mask-icon"
						href="https://shopshare.tv/mask-icon.svg"
						color="#6938EF"
					/>
					<link
						rel="apple-touch-icon"
						href="https://shopshare.tv/apple-touch-icon.png"
					/>
					<meta itemProp="name" content="ShopShare" />
					<meta
						itemProp="description"
						content="Shopable, Shareable, Content."
					/>
					<meta
						itemProp="image"
						content="https://shopshare.tv/shopshare-opengraph.jpg"
					/>
					<meta property="og:site_name" content="ShopShare" />
					<meta
						property="og:title"
						content="ShopShare: Shopable, Shareable, Content."
					/>
					<meta property="og:type" content="article" />
					<meta
						property="og:description"
						content="Shopable, Shareable, Content."
					/>
					<meta
						property="og:image"
						content="https://shopshare.tv/shopshare-opengraph.jpg"
					/>
					<meta property="og:url" content="https://shopshare.tv" />
					<meta name="twitter:card" content="summary_large_image" />
					<meta name="twitter:title" content="ShopShare" />
					<meta
						name="twitter:description"
						content="Shopable, Shareable, Content."
					/>
					<meta
						name="twitter:image"
						content="https://shopshare.tv/shopshare-opengraph.jpg"
					/>
					<meta name="twitter:site" content="@shopshare_tv" />
					<meta name="theme-color" content="#ffffff" />
				</Helmet>
				<HeaderContainer
					showHeader={state.showHeader}
					store={props.store}
					hideInstall={state.hideExtensionInstall}
					location={props.location}
				/>
				<IncorrectUsername store={props.store} simpleView />
				{props.children}
				{this.getFooter(state.showFooter, currentUser?.type)}
				<Tooltip
					id="tooltip"
					className={tooltip.root}
					place="top"
					effect="solid"
				/>
			</div>
		)
	}
}

Main = createFragmentContainer(Main, {
	store: graphql`
		fragment main_store on Store
		@argumentDefinitions(teamId: { type: "String" }) {
			id
			...header_store
			team(id: $teamId) {
				id
				name
			}
			currentUser {
				userPlan(teamId: $teamId) {
					id
					amount
					currency
					nickname
					monthly
				}
				features(teamId: $teamId)
				myFeatures: features(teamId: null)
				email
				username
				type
				...updateUsername_currentUser
			}
		}
	`,
})

const mainQuery = graphql`
	query mainQuery($teamId: String) {
		store {
			...main_store @arguments(teamId: $teamId)
		}
	}
`

const withLocation = (Comp) => {
	const withLocationComp = (props) => {
		const location = useLocation()
		return (
			<Comp
				{...props}
				location={location}
			/>
		)
	}
	return withLocationComp
}

export default { Main: withLocation(Main), mainQuery }
