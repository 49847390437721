import React from "react"
import PlayIcon from "shared/assets/play-icon.svg"
import styles from "./badge-video.css"

export default function BadgeVideo() {
	return (
		<button className={styles.play} type="button">
			<PlayIcon className={styles.playIcon} />
		</button>
	)
}
