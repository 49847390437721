/**
 * @generated SignedSource<<cf3643f590906877ff81469de8d269d9>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Mutation } from 'relay-runtime';
import type { rakutenSites_store$fragmentType } from "./../../../routes/rakuten-sites/__generated__/rakutenSites_store.graphql";
export type UpdateRakutenSitesInput = {|
  clientMutationId?: ?string,
|};
export type updateRakutenSitesMutation$variables = {|
  input: UpdateRakutenSitesInput,
|};
export type updateRakutenSitesMutation$data = {|
  +updateRakutenSites: ?{|
    +addedCount: ?number,
    +store: ?{|
      +$fragmentSpreads: rakutenSites_store$fragmentType,
    |},
    +updatedCount: ?number,
  |},
|};
export type updateRakutenSitesMutation = {|
  response: updateRakutenSitesMutation$data,
  variables: updateRakutenSitesMutation$variables,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "addedCount",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "updatedCount",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v6 = [
  (v4/*: any*/),
  (v5/*: any*/)
],
v7 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "StoreCountryEdge",
    "kind": "LinkedField",
    "name": "edges",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Country",
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": (v6/*: any*/),
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "updateRakutenSitesMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateRakutenSitesPayload",
        "kind": "LinkedField",
        "name": "updateRakutenSites",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Store",
            "kind": "LinkedField",
            "name": "store",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "rakutenSites_store"
              }
            ],
            "storageKey": null
          },
          (v2/*: any*/),
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "ShopshareMutationRoot",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "updateRakutenSitesMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateRakutenSitesPayload",
        "kind": "LinkedField",
        "name": "updateRakutenSites",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Store",
            "kind": "LinkedField",
            "name": "store",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "currentUserIsAnAdminOrASharer",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "StoreCountryConnection",
                "kind": "LinkedField",
                "name": "rakutenSiteRegions",
                "plural": false,
                "selections": (v7/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "StoreNetworkConnection",
                "kind": "LinkedField",
                "name": "rakutenSiteNetworks",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "StoreNetworkEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "SiteNetwork",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": (v6/*: any*/),
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": [
                  {
                    "kind": "Literal",
                    "name": "first",
                    "value": 1000
                  }
                ],
                "concreteType": "StoreCountryConnection",
                "kind": "LinkedField",
                "name": "countries",
                "plural": false,
                "selections": (v7/*: any*/),
                "storageKey": "countries(first:1000)"
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "StoreNetworkConnection",
                "kind": "LinkedField",
                "name": "networks",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "StoreNetworkEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "SiteNetwork",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v5/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "currency",
                            "storageKey": null
                          },
                          (v4/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "KeyValue",
                "kind": "LinkedField",
                "name": "partneredNetworks",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "key",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "value",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Category",
                "kind": "LinkedField",
                "name": "retailerCategories",
                "plural": true,
                "selections": (v6/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Category",
                "kind": "LinkedField",
                "name": "categories",
                "plural": true,
                "selections": (v6/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "User",
                "kind": "LinkedField",
                "name": "currentUser",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "adminAccess",
                    "storageKey": null
                  },
                  (v5/*: any*/)
                ],
                "storageKey": null
              },
              (v5/*: any*/)
            ],
            "storageKey": null
          },
          (v2/*: any*/),
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "929df80479e6672db6a4923aad7bc1e1",
    "id": null,
    "metadata": {},
    "name": "updateRakutenSitesMutation",
    "operationKind": "mutation",
    "text": "mutation updateRakutenSitesMutation(\n  $input: UpdateRakutenSitesInput!\n) {\n  updateRakutenSites(input: $input) {\n    store {\n      ...rakutenSites_store\n      id\n    }\n    addedCount\n    updatedCount\n  }\n}\n\nfragment rakutenSites_store on Store {\n  currentUserIsAnAdminOrASharer\n  rakutenSiteRegions {\n    edges {\n      node {\n        name\n        id\n      }\n    }\n  }\n  rakutenSiteNetworks {\n    edges {\n      node {\n        name\n        id\n      }\n    }\n  }\n  countries(first: 1000) {\n    edges {\n      node {\n        name\n        id\n      }\n    }\n  }\n  networks {\n    edges {\n      node {\n        id\n        currency\n        name\n      }\n    }\n  }\n  partneredNetworks {\n    key\n    value\n  }\n  retailerCategories {\n    name\n    id\n  }\n  categories {\n    name\n    id\n  }\n  currentUser {\n    adminAccess\n    id\n  }\n  id\n}\n"
  }
};
})();

(node/*: any*/).hash = "bf964644286c7ec95fe1c2cb643f4cbd";

module.exports = ((node/*: any*/)/*: Mutation<
  updateRakutenSitesMutation$variables,
  updateRakutenSitesMutation$data,
>*/);
