import React, { Component } from "react"
import { Link } from "react-router-dom"

import layout from "shared/styles/components/layout.css"
import styles from "./footer.css"

class Footer extends Component {
	getCurrentYear() {
		return new Date().getFullYear()
	}

	render() {
		// const { userType } = this.props
		return (
			<footer className={styles.root}>
				<div className={layout.containerWidth6}>
					<div className={styles.content}>
						<div className={styles.nav}>
							{/* <Link className={styles.link} to="/features">
								Features
							</Link> */}
							<Link className={styles.link} to="/explore">
								Explore
							</Link>
							<Link className={styles.link} to="/explore/directory">
								Directory
							</Link>
							{/* {(!userType || userType !== "CLIENT") && (
								<Link className={styles.link} to="/pricing">
									Pricing
								</Link>
							)} */}
							<Link className={styles.link} to="/faqs">
								FAQs
							</Link>
							<Link className={styles.link} to="/about">
								About
							</Link>
							<a
								className={styles.link}
								href="https://blog.shopshare.tv/blog"
							>
								Blog
							</a>
							<a
								className={styles.link}
								href="mailto:emma@shopshare.tv?subject=ShopShare enquiry"
							>
								Contact
							</a>
						</div>
					</div>
					<div className={styles.meta}>
						<span className={styles.copyright}>
							&copy;
							{this.getCurrentYear()}
							&nbsp;ShopShareTV Pty Ltd.
						</span>
						<Link className={styles.legal} to="/privacy">
							Privacy Policy
						</Link>
						{/* <Link className={styles.legal} to="/terms">Terms</Link> */}
					</div>
					{/* <a href="http://status.shopshare.tv" className={styles.statusBadge}>
						<span>Status:</span>
						<div color="#00C5A7" className={styles.statusLight}></div>
						Good
					</a> */}
				</div>
			</footer>
		)
	}
}

export default Footer
