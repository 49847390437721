import dayjs from "dayjs"

const relativeTime = require("dayjs/plugin/relativeTime")
const updateLocale = require("dayjs/plugin/updateLocale")
const quarterOfYear = require('dayjs/plugin/quarterOfYear')
const duration = require('dayjs/plugin/duration')

dayjs.extend(relativeTime)
dayjs.extend(updateLocale)
dayjs.extend(quarterOfYear)
dayjs.extend(duration)

dayjs.updateLocale("en", {
	relativeTime: {
		future: "in %s",
		past: "%s ago",
		s: "a few seconds",
		m: "1 minute",
		mm: "%d minutes",
		h: "1 hour",
		hh: "%d hours",
		d: "1 day",
		dd: "%d days",
		M: "1 month",
		MM: "%d months",
		y: "1 year",
		yy: "%d years",
	},
})

export default dayjs
