import React from "react"
import slider from "shared/styles/components/slider.css"
import textfield from "shared/styles/components/textfield.css"
import styles from "./slider.css"

export default function Slider({
	title,
	min,
	max,
	step,
	value,
	onSlide,
	wShadow,
	noInput,
	isDark,
}) {
	return (
		<div className={wShadow ? styles.rootShadow : styles.root}>
			<h4 className={styles.title}>{title}</h4>
			<div className={noInput ? styles.content : styles.contentHasInput}>
				<div className={styles.slider}>
					<input
						className={
							isDark
								? `${slider.slider} ${slider.sliderDark}`
								: slider.slider
						}
						step={step}
						min={min}
						max={max}
						type="range"
						value={value}
						onChange={onSlide}
					/>
				</div>
				{!noInput && (
					<input
						className={`${textfield.dark} ${textfield.small}`}
						value={parseFloat(value).toFixed(0)}
						type="text"
						min={min}
						max={max}
						onChange={onSlide}
					/>
				)}
			</div>
		</div>
	)
}
