import { commitMutation, graphql } from "react-relay"
import { complete } from "../helper.js"

const mutation = graphql`
	mutation productImageMutation($input: ProductImageInput!) {
		productImage(input: $input) {
			url
		}
	}
`

const commit = (env, file, pro, onCompleted, onError) =>
	commitMutation(env, {
		mutation,
		variables: {
			input: {
				imageName: file.name,
				productId: pro.id,
			},
		},
		uploadables: {
			file,
		},
		updater: (storeProxy, data) => {
			if (data.productImage) {
				const product = storeProxy.get(pro.id)
				const root = storeProxy.getRootField("productImage")
				const url = root.getValue("url")
				const productImages =
					!!pro.productImages && pro.productImages.length
						? [url, ...pro.productImages]
						: [url]
				product.setValue(productImages, "productImages")
				product.setValue(url, "imageUrl")
				product.setValue(url, "diffbotImageUrl")
			}
		},
		onCompleted: complete((d) => {
			onCompleted(
				!!pro.productImages && pro.productImages.length
					? [d.productImage.url, ...pro.productImages]
					: [d.productImage.url],
				d.productImage.url
			)
		}, onError),
		onError: (e) => complete(onCompleted, [e]),
	})

export default {
	commit,
}
