import React, { Component } from "react"
import InfiniteScroll from "react-infinite-scroller"
import grids from "shared/styles/components/grids.css"
import empty from "shared/styles/components/empty.css"
import LoadingDots from "components/loading-dots/loading-dots.jsx"
import ProfileCard from "components/cards/profile/profile-card.jsx"

export default class ListOfProfiles extends Component {
	constructor(props) {
		super(props)
		this.handleLoadMore = this.handleLoadMore.bind(this)
	}

	handleLoadMore() {
		const { relay } = this.props
		relay.loadMore(12, () => {})
	}

	render() {
		const { props } = this
		const { profiles, context, relay } = props
		if (!profiles.edges.length) {
			return (
				<div className={grids.span}>
					<div className={empty.simple}>
						No {props.isRetailer ? "retailers" : "sharers"} found.
					</div>
				</div>
			)
		}
		return (
			<div className={grids.container}>
				<InfiniteScroll
					loadMore={this.handleLoadMore}
					hasMore={relay.hasMore()}
					loader={
						<div className={grids.loadMore} key="loading">
							<LoadingDots />
						</div>
					}
					pageStart={0}
				>
					<div className={grids.rowMax4}>
						{profiles.edges.map((node) => (
							<div className={grids.col} key={node.node.id}>
								<ProfileCard
									profile={node.node}
									store={props.store}
									relay={props.relay}
									currentUser={props.currentUser}
									context={context}
								/>
							</div>
						))}
					</div>
				</InfiniteScroll>
			</div>
		)
	}
}
