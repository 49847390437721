import React, { useCallback, useEffect, useMemo, useRef, useState } from "react"
import Modal from "react-modal"
import modal from "shared/styles/components/modal.css"
import button from "shared/styles/components/buttons.css"
import nav from "shared/styles/components/nav.css"
import grids from "shared/styles/components/grids.css"
import empty from "shared/styles/components/empty.css"
import ClosetIcon from "shared/assets/closet-icon.svg"
import LookbookCard from "components/cards/lookbook-card/lookbook-card"
import ShopcastCard from "components/cards/shopcast-card/shopcast-card.jsx"
// import { addVersioning } from "helpers/query-parameters-helper"

import { Cross2Icon } from "@radix-ui/react-icons"
import { createFragmentContainer, graphql } from "react-relay"
import { getComponent } from "../../route-helpers"

import styles from "./item-references.css"
import LoadingDots from "../loading-dots/loading-dots"

function ItemReferences({ store, relay, loading }) {
	if (loading) return <LoadingDots />
	const tabsRef = useRef({})
	const { currentUser, collectionItem } = store
	const [currentTabData, setCurrentTabData] = useState({
		currentTab: "",
		tabStyles: {},
	})

	const changeTabState = useCallback((e, toState) => {
		if (e) e.preventDefault()
		setCurrentTabData({
			currentTab: toState,
			tabStyles: tabsRef.current[toState]
				? {
						width: tabsRef.current[toState].offsetWidth,
						transform: `translateX(${tabsRef.current[toState].offsetLeft}px)`,
				  }
				: {},
		})
	}, [])

	if (!collectionItem) return null

	useEffect(() => {
		changeTabState(
			null,
			(collectionItem.lookbooks || []).length > 0 ? "lookbooks" : "shopcasts"
		)
	}, [collectionItem])

	// const url =
	// 	collectionItem.__typename === "ShopcastProduct"
	// 		? collectionItem.affiliated_url
	// 		: null
	// const imageUrl = collectionItem.imageUrl || collectionItem.url
	return (
		<>
			{/* <div className={styles.card}>
					<div className={styles.media}>
						<div className={styles.figure}>
							<div className={styles.inner}>
								<img
									className={styles.poster}
									src={addVersioning(imageUrl, `d=400`)}
									alt="Product"
								/>
							</div>
						</div>
					</div>
					<div className={styles.content}>
						<div className={styles.details}>
							<h4 className={styles.title}>
								{collectionItem.title
									? collectionItem.title
									: "No title"}
							</h4>
							{(collectionItem.regularPrice ||
								collectionItem.offerPrice) && (
								<p className={styles.price}>
									{collectionItem.offerPrice && (
										<span className={styles.offerPrice}>
											{collectionItem.offerPrice}
										</span>
									)}
									{collectionItem.regularPrice && (
										<span
											className={
												collectionItem.offerPrice
													? styles.regularPriceStrike
													: styles.regularPrice
											}
										>
											{collectionItem.regularPrice}
										</span>
									)}
								</p>
							)} 
						</div>
						{url && (
							<div className={styles.actions}>
								<a
									className={styles.buy}
									href={url}
									target="_blank"
									rel="noopener noreferrer"
								>
									Shop
								</a>
							</div>
						)} 
					</div>
				</div> */}

			{(collectionItem.shopcastItems || []).length > 0 &&
				(collectionItem.lookbooks || []).length > 0 && (
					<div className={`${nav.root} ${nav.rootStickyNoHeader}`}>
						<nav className={nav.tabsFill}>
							<div className={nav.tabsFit}>
								<div
									role="button"
									tabIndex="0"
									ref={(ref) => {
										tabsRef.current.lookbooks = ref
									}}
									className={
										currentTabData.currentTab === "lookbooks"
											? nav.linkActive
											: nav.link
									}
									onClick={(e) => changeTabState(e, "lookbooks")}
								>
									Lookbooks
								</div>
								<div
									role="button"
									tabIndex="0"
									ref={(ref) => {
										tabsRef.current.shopcasts = ref
									}}
									className={
										currentTabData.currentTab === "shopcasts"
											? nav.linkActive
											: nav.link
									}
									onClick={(e) => changeTabState(e, "shopcasts")}
								>
									Shopcasts
								</div>
								<span
									className={nav.tabUnderline}
									style={currentTabData.tabStyles}
								/>
							</div>
						</nav>
					</div>
				)}
			<div className={styles.root}>
				{(collectionItem.shopcastItems || []).length > 0 ||
				(collectionItem.lookbooks || []).length > 0 ? (
					<div className={grids.container}>
						<div className={`${grids.row4} ${grids.smallRowGap}`}>
							{currentTabData.currentTab === "lookbooks" &&
								collectionItem.lookbooks.map((lookbook) => (
									<LookbookCard
										lookbook={lookbook}
										disableSubdomain
										showActions={false}
										currentUser={currentUser}
										showReportModal={() => {}}
										refetch={relay.refetchConnection}
										noMenu
									/>
								))}
							{currentTabData.currentTab === "shopcasts" &&
								(collectionItem.shopcastItems || []).map((item) => (
									<ShopcastCard
										shopcast={item.shopcast}
										showActions={false}
										currentUser={currentUser}
										disableSubdomain
										showReportModal={() => {}}
										refetch={relay.refetchConnection}
										noMenu
									/>
								))}
						</div>
					</div>
				) : (
					<div className={empty.root}>
						<div className={empty.container}>
							<div className={empty.content}>
								<ClosetIcon className={empty.icon} />
								<h4 className={empty.headline}>No References Found.</h4>
							</div>
						</div>
					</div>
				)}
			</div>
		</>
	)
}

const ItemReferencesContainer = createFragmentContainer(ItemReferences, {
	store: graphql`
		fragment itemReferences_store on Store
		@argumentDefinitions(
			id: { type: "String" }
			folderId: { type: "String" }
		) {
			id
			currentUser {
				...shopcastCard_currentUser
				...lookbookCard_currentUser
			}
			collectionItem(id: $id) {
				__typename
				... on ShopcastProduct {
					id
					title
					imageUrl
					regularPrice
					offerPrice
					affiliated_url
					url
					lookbooks(folderId: $folderId) {
						...lookbookCard_lookbook
					}
					shopcastItems(folderId: $folderId) {
						shopcast {
							...shopcastCard_shopcast
						}
					}
				}
				... on lookbookUploads {
					id
					title
					url
					lookbooks(folderId: $folderId) {
						...lookbookCard_lookbook
					}
				}
			}
		}
	`,
})

const query = graphql`
	query itemReferencesQuery($id: String, $folderId: String) {
		store {
			...itemReferences_store @arguments(id: $id, folderId: $folderId)
		}
	}
`

// export default { Component: withRouter(Featured), query }

function ItemReferencesModal({ itemId, folderId, closeModal, modalTitle }) {
	const [stateId, setStateId] = useState(itemId)

	useEffect(() => {
		if (itemId) setStateId(itemId)
		else setTimeout(() => setStateId(itemId), 300)
	}, [itemId])

	const dataComponent = useMemo(
		() =>
			stateId
				? getComponent(
						`item-references`,
						{
							Component: ItemReferencesContainer,
							query,
							showLoading: true,
						},
						{ id: stateId, folderId },
						{ id: stateId, folderId }
				  )
				: null,
		[stateId]
	)
	return (
		<Modal
			className={
				itemId && dataComponent
					? `${modal.dialogEndOpen} ${modal.dialogFullscreenSmDown}`
					: `${modal.dialogEnd} ${modal.dialogFullscreenSmDown}`
			}
			overlayClassName={modal.overlay}
			isOpen={!!itemId}
			ariaHideApp={false}
			onRequestClose={closeModal}
			shouldCloseOnOverlayClick
			closeTimeoutMS={200}
		>
			<div className={modal.content}>
				<div className={modal.header}>
					<h4 className={modal.title}>{modalTitle || "Used In"}</h4>
					<button
						type="button"
						className={button.close}
						onClick={closeModal}
					>
						<Cross2Icon className={button.closeIcon} />
					</button>
				</div>
				{!!dataComponent && (
					<div className={modal.bodyNoPadding}>{dataComponent}</div>
				)}
			</div>
		</Modal>
	)
}

export default ItemReferencesModal
