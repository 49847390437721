import React from "react"
import Modal from "react-modal"
import modal from "shared/styles/components/modal.css"
import Shopping from "shared/assets/illustrations/shopping.svg"
import { subdomainValidationRegex } from "../../../constants"
import UpdateUsername from "../../profile/account/update-username"
import styles from "./incorrect-username.css"

export default function IncorrectUsername({ store, simpleView }) {
	const showModal = !!(
		store.currentUser &&
		store.currentUser.username &&
		!subdomainValidationRegex.test(store.currentUser.username)
	)
	return (
		<Modal
			className={modal.root}
			overlayClassName={modal.overlay}
			htmlOpenClassName="ReactModal__Html--open"
			isOpen={showModal}
			shouldCloseOnOverlayClick
			ariaHideApp={false}
		>
			<div className={`${modal.dialog} ${modal.dialogCentered} ${modal.dialogLg}`}>
				<div className={`${modal.content} ${modal.whiteBg}`}>
					<div className={modal.body}>
						<div className={styles.root}>
							<div className={styles.aside}>
								<Shopping className={styles.image} />
							</div>
							<div className={styles.content}>
								<h1 className={styles.title}>
									Oops! Looks like we need to fix your username
								</h1>
								<p className={styles.body}>
									You username name determines the default URL for your
									personal shopping channel.
								</p>
								<UpdateUsername
									currentUser={store.currentUser}
									store={store}
									simpleView={simpleView}
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</Modal>
	)
}
