import React from "react"
import AvatarPlaceholder from "shared/assets/avatar-placeholder.png"

import styles from "./avatar.css"

class Avatar extends React.Component {
	constructor(props) {
		super(props)
		this.handleClick = this.handleClick.bind(this)
	}

	handleClick() {
		const { props } = this
		if (props.clickHandler) {
			props.clickHandler()
		}
	}

	getAvatarLink() {
		const { photoUrl, imageSize } = this.props
		if (!photoUrl.startsWith("blob:") && imageSize) 
			return `${photoUrl}?d=${imageSize}`
		return photoUrl
	}

	render() {
		const { props } = this
		const styleName = props.styleName || styles.image
		const avatar = props.photoUrl
		if (!avatar) {
			return (
				// eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
				<img
					className={styleName}
					src={AvatarPlaceholder}
					width={props.size}
					height={props.size}
					onClick={this.handleClick}
					onKeyPress={this.handleClick}
					alt="Avatar"
				/>
			)
		}
		return (
			// eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
			<img
				className={styleName}
				src={this.getAvatarLink()}
				width={props.size}
				height={props.size}
				onClick={this.handleClick}
				onKeyPress={this.handleClick}
				alt="Avatar"
			/>
		)
	}
}

export default Avatar
