import React from "react"
import { Helmet } from "react-helmet"
import { createFragmentContainer, graphql } from "react-relay"
import LandingActions from "components/landing-actions/landing-actions"
import landing from "shared/styles/layout/landing.css"
import ScrollToTopOnMount from "components/scroll-to-top/scroll-to-top"
import PageFooterCard from "components/page-footer-card/page-footer-card"
import Hero from "shared/assets/feature-images/hero-1.png"
import styles from "./shop-recorder.css"

function ShopRecorder({ store }) {
	const user = store.currentUser
	return (
		<div className={styles.root}>
			<Helmet>
				<title>ShopShareTV – Screen Recorder</title>
			</Helmet>
			<ScrollToTopOnMount />

			<section className={landing.section}>
				<div className={landing.content}>
					<h1 className={styles.displayTitle}>Screen Recorder</h1>
					<p className={landing.displayLead}>
						The easiest way to record and capture shoppable videos. Works
						on your Windows or Mac computer, no software download or
						plugin required.
					</p>
					{!user ? (
						<div className={landing.actions}>
							<LandingActions />
						</div>
					) : null}
				</div>
			</section>

			<section className={landing.section}>
				<div className={landing.content}>
					<div className={styles.footer}>
						<PageFooterCard
							title="Shoppable videos made easy"
							lead="Start recording shoppable videos for clients around the world."
							image={Hero}
						/>
					</div>
				</div>
			</section>
		</div>
	)
}

// eslint-disable-next-line no-func-assign
ShopRecorder = createFragmentContainer(ShopRecorder, {
	store: graphql`
		fragment shopRecorder_store on Store {
			id
			currentUser {
				id
			}
		}
	`,
})

const query = graphql`
	query shopRecorderQuery {
		store {
			...shopRecorder_store
		}
	}
`

export default { Component: ShopRecorder, query }
