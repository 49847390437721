import React, { Component } from "react"
import Modal from "react-modal"
import { toast } from "react-toastify"
import modal from "shared/styles/components/modal.css"
import button from "shared/styles/components/buttons.css"
import toggle from "shared/styles/components/toogle.css"
import form from "shared/styles/components/form.css"
import textfield from "shared/styles/components/textfield.css"
import CloseIcon from "shared/assets/close-icon.svg"
import CreateProductMutation from "mutations/product-collection/create-product-collection"
import UpdateProductMutation from "mutations/product-collection/update-product-collection"
import { Formik } from "formik"
import * as Yup from "yup"
import MoveToFolderModal from "components/modals/move-to-folder/move-to-folder"

class ProductCollectionCreateEditModal extends Component {
	getOther(isACloset) {
		const { other, getLimitType } = this.props
		if (!getLimitType) return other
		return {
			...other,
			limitType: getLimitType(isACloset ? "closet" : "collections"),
		}
	}

	createProductCollection(values) {
		const { relay, currentUser, onSuccess, onError, onCancel, team } =
			this.props
		const isACloset = values.clientId ? values.isACloset : false
		CreateProductMutation.commit(
			relay.environment,
			{
				title: values.title,
				isACloset,
				clientId: values.clientId,
				folder: values.treeViewSelectedFolder,
				teamId: team
			},
			currentUser.id,
			(error) => {
				if (onError) onError(error)
			},
			(data) => {
				toast.success(<>Collection Created</>, {
					autoClose: 2000,
					closeButton: false,
				})
				onCancel()
				if (onSuccess)
					onSuccess("new", data.createProductCollection.collection)
			},
			this.getOther(isACloset)
		)
	}

	editProductCollection(values) {
		const {
			collectionToEdit,
			relay,
			onSuccess,
			onError,
			onCancel,
		} = this.props
		const isACloset = values.clientId ? values.isACloset : false

		const data = {
			id: collectionToEdit.id,
			title: values.title,
			isACloset,
			clientId: values.clientId,
			folder: values.treeViewSelectedFolder,
			isPublished: values.isPublished,
		}
		UpdateProductMutation.commit(
			relay.environment,
			data,
			(error) => {
				if (onError) onError(error)
			},
			() => {
				toast.success(<>Collection Updated</>, {
					autoClose: 2000,
					closeButton: false,
				})
				onCancel()
				if (onSuccess) onSuccess("updated", data)
			},
			this.getOther(isACloset)
		)
	}

	render() {
		const {
			collectionToEdit,
			onCancel,
			hideClosetSetting,
			userClients,
			relay,
			team
		} = this.props
		return (
			<Modal
				className={modal.root}
				overlayClassName={modal.overlay}
				htmlOpenClassName="ReactModal__Html--open"
				isOpen={!!collectionToEdit}
				shouldCloseOnOverlayClick
				ariaHideApp={false}
			>
				<Formik
					initialValues={{
						title: collectionToEdit.title,
						clientId: collectionToEdit.client,
						isACloset: collectionToEdit.isACloset,
						treeViewSelectedFolder: collectionToEdit.folder?.id,
						isPublished: collectionToEdit.isPublished,
					}}
					enableReinitialize
					validationSchema={Yup.object().shape({
						title: Yup.string().required("Title is required"),
					})}
					onSubmit={(values) => {
						if (collectionToEdit.id) this.editProductCollection(values)
						else this.createProductCollection(values)
					}}
				>
					{({
						errors,
						handleSubmit,
						values,
						handleChange,
						handleBlur,
						submitCount,
						setFieldValue,
					}) => (
						<div
							className={`${modal.dialog} ${modal.dialogCentered} ${modal.dialogFullscreenSmDown}`}
						>
							<div className={modal.content}>
								<div className={`${modal.header}`}>
									<h4 className={modal.title}>
										{collectionToEdit.id
											? "Edit Collection"
											: "New Collection"}
									</h4>
									<button
										type="button"
										className={button.close}
										onClick={onCancel}
									>
										<CloseIcon className={button.closeIcon} />
									</button>
								</div>
								<div className={modal.body}>
									{submitCount > 0 && (
										<div className={form.group}>
											{Object.keys(errors).length > 0 && (
												<div className={form.error}>
													{Object.values(errors).map((i) => (
														<div key={i}>{i}</div>
													))}
												</div>
											)}
										</div>
									)}
									{!hideClosetSetting && values.clientId && (
										<div className={form.group}>
											<label className={toggle.check}>
												<div className={toggle.switch}>
													<input
														type="checkbox"
														checked={values.isACloset}
														onChange={(e) =>
															setFieldValue(
																"isACloset",
																e.target.checked
															)
														}
													/>
													<div
														className={toggle.slider}
														style={{
															"--form-control-color":
																"var(--closet-bg-color)",
														}}
													/>
												</div>
												<div className={toggle.label}>
													Is a Closet Collection
												</div>
											</label>
										</div>
									)}
									<div className={form.group}>
										<label className={form.label}>Name</label>
										<input
											type="text"
											className={textfield.normal}
											placeholder="Enter Collection Name"
											value={values.title}
											onChange={handleChange}
											onBlur={handleBlur}
											name="title"
										/>
									</div>

									{collectionToEdit.id &&
										userClients &&
										userClients.length > 0 && (
											<div className={form.group}>
												<label className={form.label}>
													Save To
												</label>
												<select
													className={textfield.normal}
													value={values.clientId}
													onChange={(e) => {
														setFieldValue(
															"clientId",
															e.target.value
														)
														setFieldValue(
															"treeViewSelectedFolder",
															""
														)
														setFieldValue("isACloset", false)
													}}
													onBlur={handleBlur}
													name="clientId"
												>
													<option value="">My Collections</option>
													<optgroup label="Clients">
														{userClients.map(({ node }) => (
															<option value={node.id}>
																{node.fullname}
															</option>
														))}
													</optgroup>
												</select>
											</div>
										)}

									<div
										className={
											!values.isACloset ? form.group : form.groupHide
										}
									>
										<MoveToFolderModal
											title="Link Collection To Folder"
											relay={relay}
											skipFrom={[]}
											clientId={values.clientId}
											team={team}
											treeViewSelectedFolder={
												values.treeViewSelectedFolder
											}
											onTreeViewToggle={(n) => {
												setFieldValue("treeViewSelectedFolder", n)
											}}
											hideRoot
										/>
									</div>
									{collectionToEdit.id &&
										!values.isACloset &&
										!values.clientId && (
											<div className={form.group}>
												<label className={toggle.check}>
													<div className={toggle.labelBold}>
														Add To My Channel
													</div>
													<div className={toggle.switch}>
														<input
															type="checkbox"
															defaultChecked={
																collectionToEdit.isPublished
															}
															onChange={({ target }) => {
																setFieldValue(
																	"isPublished",
																	target.checked
																)
															}}
														/>
														<div
															className={toggle.slider}
															style={{
																"--form-control-color":
																	"var(--form-control-published-color)",
															}}
														/>
													</div>
												</label>
											</div>
										)}
								</div>
								<div className={modal.footer}>
									<button
										type="button"
										className={button.link}
										onClick={onCancel}
									>
										Cancel
									</button>
									<button
										type="button"
										className={button.primary}
										onClick={handleSubmit}
									>
										{collectionToEdit.id ? "Save" : "Create"}
									</button>
								</div>
							</div>
						</div>
					)}
				</Formik>
			</Modal>
		)
	}
}

export default ProductCollectionCreateEditModal
