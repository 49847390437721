import React, { useState } from "react"
import { CameraIcon, Cross2Icon } from "@radix-ui/react-icons"
import Modal from "react-modal"
import { useFormik } from "formik"
import * as Yup from "yup"
import { Tree } from "react-arborist"
import modal from "shared/styles/components/modal.css"
import button from "shared/styles/components/buttons.css"
import form from "shared/styles/components/form.css"
import textfield from "shared/styles/components/textfield.css"
import { toast } from "react-toastify"
import Dropzone from "react-dropzone"
import Avatar from "components/profile/avatar/avatar"
import icons from "shared/styles/components/icons.css"
import stylesAvatar from "components/profile/avatar/avatar.css"
import createClient from "mutations/client/create-client"
import gqlHelper from "helpers/gql-helper"
import updateClient from "mutations/client/update-client"
import { Node } from "../move-to-folder/move-to-folder"
import mtfStyles from "../move-to-folder/move-to-folder.css"
import styles from "./create-edit-client.css"

function CreateEditClient({
	relay,
	closeModal,
	client,
	folders,
	other,
	team,
	parentId,
	...otherProps
}) {
	const [avatarFile, setAvatarFile] = useState(null)
	const formik = useFormik({
		initialValues: {
			fullname: client?.fullname || "",
			email: client?.email || "",
			avatar: client?.avatar || "",
			folder: client?.folder || "",
		},
		validationSchema: Yup.object().shape({
			fullname: Yup.string().required("Full name is required"),
			email: Yup.string()
				.email("Invalid email address")
				.required("Email is required"),
			avatar: Yup.string(),
		}),
		onSubmit: (values) => {
			const data = {
				...values,
				file: avatarFile,
			}
			if (!client.id) {
				createClient.commit(
					relay.environment,
					{ ...data, teamId: team },
					(e) => {
						toast.info(<>{gqlHelper.getError(e)}</>, {
							autoClose: 5000,
							closeButton: false,
						})
					},
					() => {
						toast.success(<>Client created successfully</>, {
							autoClose: 2000,
							closeButton: false,
						})
						closeModal(true)
					},
					parentId,
					other
				)
			} else {
				delete data.folder
				updateClient.commit(
					relay.environment,
					{ ...data, id: client.id },
					(e) => {
						toast.info(<>{gqlHelper.getError(e)}</>, {
							autoClose: 5000,
							closeButton: false,
						})
					},
					() => {
						toast.success(<>Client updated successfully</>, {
							autoClose: 2000,
							closeButton: false,
						})
						closeModal(false)
					}
				)
			}
		},
	})
	return (
		<Modal
			className={modal.root}
			overlayClassName={modal.overlay}
			isOpen
			{...otherProps}
			ariaHideApp={false}
		>
			<div
				className={`${modal.dialog} ${modal.dialogCentered} ${modal.dialogFullscreenSmDown}`}
			>
				<div className={`${modal.content}`}>
					<div className={modal.header}>
						<h4 className={modal.title}>
							{client.id ? "Edit Client" : "New Client"}
						</h4>
						<button
							type="button"
							className={button.close}
							onClick={closeModal}
						>
							<Cross2Icon className={button.closeIcon} />
						</button>
					</div>
					<div className={modal.body}>
						<div className={form.group}>
							<label className={form.label}>Full Name</label>
							<input
								type="text"
								className={textfield.normal}
								name="fullname"
								placeholder="Jane Doe"
								value={formik.values.fullname}
								onChange={formik.handleChange}
							/>
							{formik.errors?.fullname && (
								<div className={form.validationFeedback}>
									{formik.errors.fullname}
								</div>
							)}
						</div>
						<div className={form.group}>
							<label className={form.label}>Email</label>
							<input
								type="text"
								className={textfield.normal}
								placeholder="name@email.com"
								name="email"
								value={formik.values.email}
								onChange={formik.handleChange}
							/>
							{formik.errors?.email && (
								<div className={form.validationFeedback}>
									{formik.errors.email}
								</div>
							)}
						</div>
						<div className={form.group}>
							<div className={styles.formHeader}>
								<label className={form.label}>
									Photo <i>(Optional)</i>
								</label>
								{formik.values.avatar && (
									<button
										type="button"
										className={`${button.primaryRedLink} ${button.small}`}
										onClick={() => {
											setAvatarFile(null)
											formik.setFieldValue("avatar", "")
										}}
									>
										Remove
									</button>
								)}
							</div>
							<div className={styles.avatar}>
								<Dropzone
									multiple={false}
									onDrop={(acceptedFiles) => {
										if (!acceptedFiles) return
										const [file] = acceptedFiles
										setAvatarFile(file)
									}}
								>
									{({ getRootProps, getInputProps }) => (
										<div className={styles.avatarDrop}>
											<div
												{...getRootProps()}
												className={styles.avatarDropIcon}
											>
												<input {...getInputProps()} />
												<CameraIcon className={icons.icon32} />
											</div>
											<Avatar
												className={styles.avatar}
												styleName={stylesAvatar.border}
												photoUrl={
													avatarFile
														? URL.createObjectURL(avatarFile)
														: formik.values.avatar
												}
												fullname={client.fullname}
											/>
										</div>
									)}
								</Dropzone>
							</div>
							<p className={styles.bannerRecSize}>
								Recommended Size: <b>512x512px</b>
							</p>
						</div>
						{folders.length > 0 && (
							<div
								className={form.group}
								style={{
									display: !client.id ? "block" : "none",
								}}
							>
								<label className={form.label}>
									Assign Folder{" "}
									<i>
										(Optional – Use this option to assign an existing
										folder to this client)
									</i>
								</label>

								<div className={mtfStyles.root}>
									<Tree
										data={folders.map((x) => ({
											...x,
											isActive: formik.values.folder === x.id,
											onTreeViewToggle: (n) => {
												formik.setFieldValue("folder", n, false)
												if (!avatarFile)
													formik.setFieldValue(
														"avatar",
														x.avatar,
														false
													)
											},
										}))}
										indent={21}
										rowHeight={32}
										openByDefault
										hideRoot
										className={mtfStyles.tree}
									>
										{Node}
									</Tree>
								</div>
							</div>
						)}
					</div>
					<div className={`${modal.footer} ${modal.borderNone}`}>
						<button
							type="button"
							className={button.link}
							onClick={closeModal}
						>
							Cancel
						</button>
						<button
							type="button"
							className={button.primary}
							onClick={() => {
								formik.submitForm()
							}}
						>
							Save
						</button>
					</div>
				</div>
			</div>
		</Modal>
	)
}

export default CreateEditClient
