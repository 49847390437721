import { commitMutation, graphql } from "react-relay"
import { complete } from "../helper.js"

const mutation = graphql`
	mutation setUserPreviewConfigMutation($input: SetUserPreviewConfigInput!) {
        setUserPreviewConfig(input: $input) {
            store {
                id
            }
        }
    }
`

const commit = (env, configs, onSuccess, onError) =>
	commitMutation(env, {
		mutation,
		variables: { input: { configs } },
		updater: (storeProxy) => {
            const config = storeProxy
				.getRoot()
				.getLinkedRecord("store")
				.getLinkedRecord("currentUser")
				.getLinkedRecord("previewConfig")
			configs.forEach(({ id, value }) => {
				config.setValue(value, id)
			})
		},
		onCompleted: complete(onSuccess, onError),
		onError: (e) => complete(null, [e]),
	})

export default { commit }
