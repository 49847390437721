/**
 * @generated SignedSource<<800d7df5015bd2458c2a1bb7be675e2d>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentType } from "relay-runtime";
declare export opaque type adminReferralPayment_admin$fragmentType: FragmentType;
export type adminReferralPayment_admin$data = {|
  +referralPayments: ?$ReadOnlyArray<?{|
    +amount: ?number,
    +createdAt: ?string,
    +id: string,
    +payoutId: ?string,
    +referral: ?{|
      +campaign: ?{|
        +name: ?string,
      |},
      +user: ?{|
        +fullname: ?string,
      |},
    |},
  |}>,
  +$fragmentType: adminReferralPayment_admin$fragmentType,
|};
export type adminReferralPayment_admin$key = {
  +$data?: adminReferralPayment_admin$data,
  +$fragmentSpreads: adminReferralPayment_admin$fragmentType,
  ...
};
*/

var node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "adminReferralPayment_admin",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ReferralPayment",
      "kind": "LinkedField",
      "name": "referralPayments",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "amount",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "payoutId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Referral",
          "kind": "LinkedField",
          "name": "referral",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "User",
              "kind": "LinkedField",
              "name": "user",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "fullname",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "Campaign",
              "kind": "LinkedField",
              "name": "campaign",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "name",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "createdAt",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Admin",
  "abstractKey": null
};

(node/*: any*/).hash = "a22794ad75da1537a3708b89cb29143d";

module.exports = ((node/*: any*/)/*: Fragment<
  adminReferralPayment_admin$fragmentType,
  adminReferralPayment_admin$data,
>*/);
