/**
 * @generated SignedSource<<9634dc51458d264fe443dbccf85ac25c>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentType } from "relay-runtime";
declare export opaque type clientView_closetCollections_currentUser$fragmentType: FragmentType;
export type clientView_closetCollections_currentUser$data = {|
  +currentUser: ?{|
    +clientFolder: ?{|
      +avatar: ?string,
      +bannerPhoto: ?string,
      +clients: ?$ReadOnlyArray<?{|
        +avatar: ?string,
        +id: string,
      |}>,
      +description: ?string,
      +displayName: ?string,
      +folders: ?{|
        +edges: ?$ReadOnlyArray<?{|
          +cursor: string,
          +node: ?{|
            +id: string,
            +name: ?string,
          |},
        |}>,
      |},
      +id: string,
      +name: ?string,
      +previewConfig: ?{|
        +showAvatar: ?string,
        +showBanner: ?string,
        +showClosetTab: ?boolean,
        +showDescription: ?boolean,
        +showDisplayName: ?boolean,
        +showLookbookTab: ?boolean,
      |},
      +user: ?{|
        +bannerPhoto: ?string,
        +displayAvatar: ?string,
        +fullname: ?string,
        +id: string,
        +profilePhoto: ?string,
      |},
    |},
    +id: string,
  |},
  +$fragmentType: clientView_closetCollections_currentUser$fragmentType,
|};
export type clientView_closetCollections_currentUser$key = {
  +$data?: clientView_closetCollections_currentUser$data,
  +$fragmentSpreads: clientView_closetCollections_currentUser$fragmentType,
  ...
};
*/

var node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "avatar",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "bannerPhoto",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "folder"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "clientView_closetCollections_currentUser",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "currentUser",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": [
            {
              "kind": "Variable",
              "name": "id",
              "variableName": "folder"
            }
          ],
          "concreteType": "ShopcastFolder",
          "kind": "LinkedField",
          "name": "clientFolder",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            (v1/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "displayName",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "description",
              "storageKey": null
            },
            (v2/*: any*/),
            (v3/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "User",
              "kind": "LinkedField",
              "name": "user",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "fullname",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "profilePhoto",
                  "storageKey": null
                },
                (v3/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "displayAvatar",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "Client",
              "kind": "LinkedField",
              "name": "clients",
              "plural": true,
              "selections": [
                (v0/*: any*/),
                (v2/*: any*/)
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "FolderPreviewConfig",
              "kind": "LinkedField",
              "name": "previewConfig",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "showAvatar",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "showBanner",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "showDisplayName",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "showDescription",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "showLookbookTab",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "showClosetTab",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": [
                {
                  "kind": "Literal",
                  "name": "isShared",
                  "value": true
                }
              ],
              "concreteType": "ShopcastFolderShopcastFolderConnection",
              "kind": "LinkedField",
              "name": "folders",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "ShopcastFolderShopcastFolderEdge",
                  "kind": "LinkedField",
                  "name": "edges",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "cursor",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "ShopcastFolder",
                      "kind": "LinkedField",
                      "name": "node",
                      "plural": false,
                      "selections": [
                        (v0/*: any*/),
                        (v1/*: any*/)
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": "folders(isShared:true)"
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Store",
  "abstractKey": null
};
})();

(node/*: any*/).hash = "5f95e6adbb31d48144b6e6e213ffb81c";

module.exports = ((node/*: any*/)/*: Fragment<
  clientView_closetCollections_currentUser$fragmentType,
  clientView_closetCollections_currentUser$data,
>*/);
