import { commitMutation, graphql } from "react-relay"
import { ConnectionHandler } from "relay-runtime"
import { complete } from "../helper"
import connections from "../connections"

const mutation = graphql`
	mutation archiveShopcastItemMutation($input: ArchiveShopcastItemInput!) {
		archiveShopcastItem(input: $input) {
			output
		}
	}
`

const commit = (env, id, type, folder, onError, containerId, onSuccess) =>
	commitMutation(env, {
		mutation,
		variables: { input: { id, type } },
		onError: complete(onSuccess, onError),
		optimisticResponse: {
			archiveShopcastItem: {
				output: "",
			},
		},
		updater: (storeProxy, dd) => {
			if (dd.archiveShopcastItem) {
				const folderProxy = storeProxy.get(folder)
				const itemProxy = storeProxy.get(id)
				let connection = null
				switch (type) {
					case "folder":
						connection = connections.currentUser.shopcastManager.folders
						connection.data = {
							...connection.data,
							isShared: null
						}
						break
					case "shopcast":
					case "lookbook":
					case "shopshareFile":
						connection = connections.currentUser.shopcastManager.items
						connection.data = {...connection.data, type: type === "shopshareFile" ? "file" : type}
						break
					default:
						break
				}
				const connectionProxy = ConnectionHandler.getConnection(
					folderProxy,
					connection.name,
					connection.data
				)
				if (connectionProxy) ConnectionHandler.deleteNode(connectionProxy, id)

				const containerProxy = storeProxy.get(containerId)

				const isInRoot = itemProxy.getValue("inRoot")
				if (type === "shopcast" || type === "lookbook" || type === "shopshareFile") {
					containerProxy.setValue(
						containerProxy.getValue("archivedItemCount") + 1,
						"archivedItemCount"
					)
					containerProxy.setValue(
						containerProxy.getValue("rootItemCount") - 1,
						"rootItemCount"
					)
				}
				if (type === "folder" && !!containerId) {
					/* if the folder is a starred folder, then remove it form the starred collection in the container */
					onSuccess()
					containerProxy.setValue(
						containerProxy.getValue("archivedItemCount") + itemProxy.getValue("itemsCount"),
						"archivedItemCount"
					)
					containerProxy.setValue(
						containerProxy.getValue("rootItemCount") - itemProxy.getValue("itemsCount"),
						"rootItemCount"
					)
					if (itemProxy.getValue("isStarred")) {
						const {
							name,
							data,
						} = connections.currentUser.shopcastManager.starredFolders
						const starredConnection = ConnectionHandler.getConnection(
							containerProxy,
							name,
							data
						)
						ConnectionHandler.deleteNode(starredConnection, id)
					}
					/* if the folder is in the root, remove from the root connection */
					if (isInRoot) {
						const {
							name,
							data,
						} = connections.currentUser.shopcastManager.rootFolders
						const rootConnection = ConnectionHandler.getConnection(
							containerProxy,
							name,
							data
						)
						ConnectionHandler.deleteNode(rootConnection, id)
					}
					itemProxy.setValue(!itemProxy.getValue("isArchived"), "isArchived")
				} else {
					itemProxy.setValue(false, "isPublished")
				}

				folderProxy.setValue(folderProxy.getValue("count") - 1, "count")
			}
		},
	})

export default { commit }
