import React from "react"
import { graphql, createPaginationContainer, QueryRenderer } from "react-relay"
import ContentLoader from "react-content-loader"
import InfiniteScroll from "react-infinite-scroller"
import buttons from "shared/styles/components/buttons.css"
import empty from "shared/styles/components/empty.css"
import label from "shared/styles/components/label.css"
import grids from "shared/styles/components/grids.css"
import icons from "shared/styles/components/icons.css"
import OfferIcon from "shared/assets/offer-icon.svg"
import EditIcon from "shared/assets/edit-icon.svg"
import LoadingDots from "components/loading-dots/loading-dots"
import dayjs from "../../helpers/dayjs-helper"
import styles from "./list-of-rakuten-sites.css"

function Skeleton(p) {
	return (
		<ContentLoader
			speed={0}
			animate={false}
			primarycolor="#EDEDED"
			secondarycolor="#E6E6E6"
			preserveAspectRatio="xMidYMid meet"
			viewBox="0 0 258 310"
			{...p}
		>
			<rect y="294" width="46" height="16" rx="2" />
			<rect y="267" width="168" height="24" rx="2" />
			<rect width="258" height="258" rx="4" />
		</ContentLoader>
	)
}

function Comp(props) {
	const {
		relay,
		isAdmin,
		setEditSite,
		store,
		loading,
		showRates,
		count,
	} = props
	let sites = null
	if (!loading) {
		sites = store.rakutenSites
	}

	function renderCommissionRate(site) {
		if (!showRates) return false
		let rateText = site.commissionRate
		// If it has a special commission rate, format it and show it
		if (site.hasSpecialRate) {
			const data = dayjs(+site.specialCommissionRateValidUntil)
			const timeFormat =
				data.year() === dayjs().year() ? "MMM DD" : "MMM DD YYYY"
			rateText = `${site.specialCommissionRate} until ${data.format(
				timeFormat
			)}`
		}
		return <p className={styles.rate}>{rateText}</p>
	}

	function handleEditSite(site) {
		setEditSite(site)
	}

	function handleLoadMore() {
		relay.loadMore(12, () => {})
	}

	const getTile = (site) => (
		<div className={grids.col4} key={site.id}>
			{loading && <Skeleton className={styles.skeleton} />}
			{!loading && (
				<div className={styles.root}>
					<div className={styles.card}>
						{site.hasSpecialRate && showRates && (
							<OfferIcon className={styles.offerIcon} />
						)}

						{!site.isPublic && (
							<div className={styles.hold}>
								<div className={label.flagged}>
									{!site.isPublic ? "Hold" : ""}
								</div>
							</div>
						)}

						{isAdmin && (
							<div className={styles.actions}>
								<button
									type="button"
									className={buttons.circleDark}
									onClick={() => handleEditSite(site)}
								>
									<EditIcon className={icons.icon18} />
								</button>
							</div>
						)}
						<a
							href={site.displayUrl}
							target="_blank"
							rel="noopener noreferrer"
						>
							<div className={styles.tile}>
								<div className={styles.tileInner}>
									<img
										className={styles.image}
										src={`${site.imageUrl}`}
										alt="Retailer Tile"
									/>
								</div>
							</div>
							{site.logoUrl && (
								<div className={styles.brand}>
									<img
										className={styles.logo}
										src={`${site.logoUrl}`}
										alt="Logo"
									/>
								</div>
							)}
							<div className={styles.overlay}>
								<div className={styles.content}>Visit Site</div>
							</div>
						</a>
					</div>
					<div className={styles.footer}>
						<div className={styles.details}>
							<h4 className={styles.name}>{site.displayName}</h4>
							{/* {!!site.countries && !!site.countries.length && (
								<div className={styles.country}>
									{site.countries.map((c) => c.code).join(", ")}
								</div>
							)} */}

							{!!site.networks && !!site.networks.length && (
								<div className={styles.country}>
									{site.networks.map((c) => c.currency).join(", ")}
								</div>
							)}
						</div>
						{store.currentUser &&
							!store.currentUser.isRetailer &&
							renderCommissionRate(site)}
					</div>
				</div>
			)}
		</div>
	)

	function getDummySites() {
		const list = []
		for (let i = 0; i < count; i += 1) {
			list.push({
				node: {
					id: i,
				},
			})
		}
		return { edges: list }
	}

	function renderNoRetailers() {
		return (
			<div className={grids.span}>
				<div className={empty.simple}>Sorry, no results were found.</div>
			</div>
		)
	}

	return (
		<div className={grids.container}>
			<InfiniteScroll
				loadMore={() => handleLoadMore()}
				hasMore={relay.hasMore()}
				loader={
					<div className={grids.loadMore} key="loading">
						<LoadingDots />
					</div>
				}
				pageStart={0}
			>
				<div className={grids.row4}>
					{sites && !sites.edges.length && renderNoRetailers()}
					{(loading || !sites ? getDummySites() : sites).edges.map((i) =>
						getTile(i.node)
					)}
				</div>
			</InfiniteScroll>
		</div>
	)
}

const listOfRakutenSitesQuery = graphql`
	query listOfRakutenSitesQuery(
		$count: Int!
		$cursor: String
		$category: String
		$sortBy: String
		$region: String
		$network: String
		$searchText: String
		$partner: String
	) {
		store {
			...listOfRakutenSites_store
				@arguments(
					count: $count
					cursor: $cursor
					category: $category
					sortBy: $sortBy
					region: $region
					network: $network
					searchText: $searchText
					partner: $partner
				)
		}
	}
`

const ListOfRakutenSites = createPaginationContainer(
	Comp,
	{
		store: graphql`
			fragment listOfRakutenSites_store on Store
			@argumentDefinitions(
				count: { type: "Int", defaultValue: 12 }
				cursor: { type: "String" }
				category: { type: "String" }
				sortBy: { type: "String" }
				region: { type: "String" }
				network: { type: "String" }
				searchText: { type: "String" }
				partner: { type: "String" }
			) {
				currentUser {
					isRetailer
				}
				rakutenSites(
					first: $count
					after: $cursor
					category: $category
					sortBy: $sortBy
					region: $region
					network: $network
					searchText: $searchText
					partner: $partner
				) @connection(key: "admin_rakutenSites", filters: []) {
					edges {
						node {
							name
							mid
							urls
							id
							displayName
							description
							countries {
								id
								name
								code
							}
							category {
								id
							}
							commissionRate
							imageUrl
							displayUrl
							logoUrl
							isPublic
							specialCommissionRate
							specialCommissionRateValidUntil
							hasSpecialRate
							networks {
								id
								currency
								name
							}
							partneredNetwork
						}
					}
				}
			}
		`,
	},
	{
		direction: "forward",
		getConnectionFromProps(props) {
			const { store } = props
			return store && store.rakutenSites
		},
		getFragmentVariables(prevVars, totalCount) {
			return {
				...prevVars,
				count: totalCount,
			}
		},
		getVariables(props, { cursor }) {
			return {
				count: props.count,
				cursor,
				category: props.category,
				region: props.region,
				network: props.network,
				searchText: props.searchText,
				sortBy: props.sortBy,
				partner: props.partner,
			}
		},
		query: listOfRakutenSitesQuery,
	}
)

function variablesToQuery(props) {
	return {
		category: props.category,
		region: props.region,
		network: props.network,
		searchText: props.searchText,
		sortBy: props.sortBy,
		count: props.count,
		partner: props.partner,
	}
}

function Sites(p) {
	const { relay } = p
	return (
		<QueryRenderer
			relay={{ environment: {}, variables: [] }}
			query={listOfRakutenSitesQuery}
			variables={variablesToQuery(p)}
			environment={relay.environment}
			render={({ props }) =>
				props ? (
					<ListOfRakutenSites
						store={null}
						{...props}
						{...p}
						loading={false}
					/>
				) : (
					<ListOfRakutenSites store={null} {...props} {...p} loading />
				)
			}
		/>
	)
}

export default Sites
