import React from "react"
import styles from "./content-editable.css"

function ContentEditable({
	contentEditable,
	className,
	title,
	saveTitle,
	setTitleFocus,
}) {
	const [inTitle, setInTitle] = React.useState(title || "Untitled")
	const saveTitleCallback = React.useCallback(
		(e) => {
			const { innerText } = e.target
			if (!innerText) return
			if (inTitle === innerText) return
			saveTitle(innerText)
			setInTitle(innerText)
			if (setTitleFocus) setTitleFocus(false)
		},
		[inTitle, saveTitle, setTitleFocus]
	)
	return (
		<h4
			data-ce-title={contentEditable}
			className={className || styles.title}
			contentEditable={contentEditable}
			suppressContentEditableWarning
			onFocus={() => {
				if (setTitleFocus) setTitleFocus(true)
			}}
			onBlur={saveTitleCallback}
			onPaste={(e) => {
				e.preventDefault()
				const text = e.clipboardData.getData("text")
				document.execCommand("insertText", false, text)
			}}
		>
			{title}
		</h4>
	)
}

export default ContentEditable
