/* eslint-disable jsx-a11y/accessible-emoji */
/* eslint-disable camelcase */
import React, { Component, Fragment } from "react"
import InfiniteScroll from "react-infinite-scroller"
import { createPaginationContainer, graphql } from "react-relay"
import { PlusIcon } from "@radix-ui/react-icons"
import { toast } from "react-toastify"
import { includes } from "lodash"
import FavouriteList from "components/lookbook/favourite-list"
import ImageUpload from "components/modals/image-upload/image-upload"
import ImageCard from "components/cards/lookbook/image-card"
import LoadingDots from "components/loading-dots/loading-dots"
import ConformationPrompt from "components/modals/conformation-prompt/conformation-prompt"
import deleteLookbookUploadMutation from "mutations/lookbook/delete-lookbook-upload"
import grids from "shared/styles/components/grids.css"
import lookbookAside from "shared/styles/layout/lookbook-aside.css"
import button from "shared/styles/components/buttons.css"
import icon from "shared/styles/components/icons.css"
import textfield from "shared/styles/components/textfield.css"
import { clickFavourite } from "helpers/lookbook-helper"
import { toTitleCase } from "helpers/string-helper"
import gql from "helpers/gql-helper"
import { ROLES } from "constants"
import { getComponent } from "../../route-helpers"

class CategorizeImageList extends Component {
	constructor(props) {
		super(props)
		this.state = {
			deleteModal: false,
			loading: false,
			openModal: false,
			selected: null,
		}
		this.fetchData = this.fetchData.bind(this)
		this.closeModal = this.closeModal.bind(this)
		this.deleteUpload = this.deleteUpload.bind(this)
		this.editImage = this.editImage.bind(this)
	}

	fetchData() {
		const { relay } = this.props
		relay.loadMore(12)
	}

	async deleteUpload() {
		this.setState({ loading: true })
		const { store } = this.props
		const imageList = store.currentUser.lookbookUploads.edges || []
		const { relay, category_id } = this.props
		const { selected } = this.state
		await new Promise((resolve, reject) => {
			deleteLookbookUploadMutation.commit(
				relay.environment,
				{ collectionId: "", uploadId: selected.id, category: category_id },
				(result) => {
					toast.success(<Fragment>Successfully Deleted..</Fragment>, {
						autoClose: 5000,
						closeButton: false,
					})
					resolve(result)
				},
				(e) => {
					toast.info(<p>{gql.getError(e)}</p>, {
						autoClose: 5000,
						closeButton: false,
					})
					reject(e)
				}
			)
		})
		relay.refetchConnection(imageList.length - 1)
		this.closeModal()
	}

	closeModal() {
		this.setState({
			deleteModal: false,
			selected: null,
			openModal: false,
			loading: false,
		})
	}

	editImage(img) {
		this.setState({
			openModal: "upload",
			selected: {
				...img,
				productCollections: img.subCategoryList,
			},
		})
	}

	render() {
		const { deleteModal, loading, selected, openModal } = this.state
		const {
			category_id,
			onChange,
			sub_category,
			loading: mainLoading,
			relay,
			accepted,
			category,
			drop,
			store,
			favouriteListOpen,
			team
		} = this.props
		if (mainLoading || !(store && store.currentUser)) {
			return <LoadingDots />
		}
		const isAdmin = [ROLES.ADMIN, ROLES.TEAM].includes(store.currentUser.role)
		const imageList = (store.currentUser.lookbookUploads
			? store.currentUser.lookbookUploads.edges
			: []
		).map((obj) => obj.node)
		const dataList = []
		imageList.map((image) => {
			if (
				includes(
					dataList.map((x) => x.category.toUpperCase()),
					image.subCategoryTitle.toUpperCase()
				)
			) {
				if (
					!includes(
						dataList
							.find(
								(x) =>
									x.category.toUpperCase() ===
									image.subCategoryTitle.toUpperCase()
							)
							.list.map((x) => x.id),
						image.id
					)
				) {
					dataList
						.find((x) => x.category === image.subCategoryTitle)
						.list.push(image)
				}
			} else {
				dataList.push({
					category: image.subCategoryTitle,
					list: [image],
				})
			}
			return image
		})
		const collectionList =
			store.currentUser.lookbookUploadsSubCategoryList || []
		const hasfavourites =
			(store.currentUser.lookbookFavourites
				? store.currentUser.lookbookFavourites.edges
				: []
			).map((obj) => obj.node).length > 0 ||
			imageList.filter((obj) => obj.isFavourite === true).length > 0
		const FavouriteListComp = getComponent(
			`fav_list_categorize`,
			FavouriteList,
			{
				...FavouriteList.params,
				category: category_id,
				teamId: team,
			},
			{
				favouriteListOpen,
				category: category_id,
				drop,
				canEdit: isAdmin,
				editImage: this.editImage
			}
		)
		return (
			<Fragment>
				<div className={lookbookAside.header}>
					<div className={lookbookAside.headerActions}>
						<select
							className={textfield.normal}
							defaultValue={sub_category}
							onChange={onChange}
						>
							<option value="">
								All {category ? toTitleCase(category.title) : ""}
							</option>
							<option disabled>─</option>
							{hasfavourites && (
								<Fragment>
									<option value="favourites"> ❤️ My Favourites</option>
									<option disabled>─</option>
								</Fragment>
							)}

							{[...collectionList]
								.sort((a, b) => a.title.localeCompare(b.title))
								.map((x) => (
									<option key={x.id} value={x.id}>
										{toTitleCase(x.title)}
									</option>
								))}
						</select>
						{isAdmin && (
							<div className={lookbookAside.actions}>
								<button
									type="button"
									className={button.dropdownIconLg}
									onClick={() =>
										this.setState({ openModal: "upload" })
									}
								>
									<PlusIcon className={icon.icon21} />
								</button>
							</div>
						)}
					</div>
				</div>
				{favouriteListOpen && hasfavourites ? (
					FavouriteListComp
				) : (
					<InfiniteScroll
						loadMore={this.fetchData}
						hasMore={relay ? relay.hasMore() : false}
						loader={<LoadingDots key={0} />}
						pageStart={0}
						useWindow={false}
					>
						<div className={lookbookAside.content}>
							{dataList.map((y) => (
								<Fragment key={y.category}>
									<div className={lookbookAside.sectionHeader}>
										{!sub_category && (
											<h4 className={lookbookAside.heading}>
												{y.category}
											</h4>
										)}
									</div>
									<div className={grids.image}>
										{y.list.map((x) => (
											<ImageCard
												node={x}
												isDraggable
												key={`${y.category}_${x.id}`}
												price={x.price}
												description={x.title}
												drop={drop}
												showAddButton
												editImage={() =>
													this.setState({
														openModal: "upload",
														selected: {
															productCollections:
																x.subCategoryList,
															...x,
														},
													})
												}
												deleteImage={() =>
													this.setState({
														deleteModal: true,
														selected: {
															productCollections:
																x.subCategoryList,
															...x,
														},
													})
												}
												canEdit={isAdmin}
												isFavourite={x.isFavourite}
												showFavouriteButton
												clickFavourite={() =>
													clickFavourite(
														x.id,
														x.category.title,
														team,
														relay
													)
												}
												category={category_id}
											/>
										))}
									</div>
								</Fragment>
							))}
						</div>
					</InfiniteScroll>
				)}
				<ImageUpload
					subCategoryList={collectionList.filter((i) => !i.isDefault)}
					openModal={openModal === "upload"}
					adminAccess={isAdmin}
					relay={relay}
					accepted={accepted}
					selected={selected}
					category={category_id}
					currentCollections={collectionList
						.filter((i) => i.id === sub_category)
						.map((obj) => ({ id: obj.id, value: obj.title }))}
					closeModal={(count) => {
						if (count !== false)
							relay.refetchConnection(imageList.length + count)
						this.setState({ openModal: null, selected: null })
					}}
				/>
				<ConformationPrompt
					openModal={deleteModal}
					title="Are you sure?"
					closeModal={this.closeModal}
					approveEvent={this.deleteUpload}
					declineEvent={this.closeModal}
					approveText="Delete"
					approveButtonStyle={button.primaryRed}
					declineText="Cancel"
					loading={loading}
				>
					Do you want to permanently delete &quot;
					{selected ? selected.title : ""}&quot;? This action cannot be
					undone.
				</ConformationPrompt>
			</Fragment>
		)
	}
}
const query = graphql`
	query categorizeImageListQuery(
		$count: Int!
		$cursor: String
		$category_id: String!
		$sub_category: String
		$teamId: String
	) {
		store {
			...categorizeImageList_store
				@arguments(
					count: $count
					cursor: $cursor
					category_id: $category_id
					sub_category: $sub_category
					teamId: $teamId
				)
		}
	}
`

CategorizeImageList = createPaginationContainer(
	CategorizeImageList,
	{
		store: graphql`
			fragment categorizeImageList_store on Store
			@argumentDefinitions(
				count: { type: "Int", defaultValue: 24 }
				cursor: { type: "String" }
				category_id: { type: "String!" }
				sub_category: { type: "String" }
				teamId: { type: "String" }
			) {
				currentUser {
					id
					adminAccess
					role
					lookbookUploadsSubCategoryList(category: $category_id) {
						id
						title
					}
					lookbookUploads(
						category: $category_id
						sub_category: $sub_category
						first: $count
						after: $cursor
						teamId: $teamId
					) @connection(key: "currentUser_lookbookUploads") {
						edges {
							node {
								id
								title
								url
								canEdit
								category {
									title
								}
								subCategoryList {
									id
									category {
										title
									}
									title
								}
								subCategoryTitle
								isFavourite(teamId: $teamId)
							}
						}
					}
					lookbookFavourites(category: $category_id, teamId: $teamId) {
						edges {
							node {
								__typename
								... on lookbookUploads {
									id
									isFavourite(teamId: $teamId)
								}
							}
						}
					}
				}
			}
		`,
	},
	{
		direction: "forward",
		getConnectionFromProps(props) {
			const { currentUser } = props.store
			return currentUser && currentUser.lookbookUploads
		},
		getFragmentVariables(prevVars, totalCount) {
			return {
				...prevVars,
				count: totalCount,
			}
		},
		getVariables(props, { count, cursor }) {
			return {
				count,
				cursor,
				sub_category: props.sub_category,
				category_id: props.category_id,
				teamId: props.team,
			}
		},
		query,
	}
)

export default {
	Component: CategorizeImageList,
	query,
	params: {
		count: 12,
		cursor: null,
		category_id: "",
		sub_category: null,
	},
	showLoading: true,
}
