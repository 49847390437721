/**
 * @generated SignedSource<<9dca82e76c176302a6f68c5685576b50>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentType } from "relay-runtime";
declare export opaque type forContentMarketing_store$fragmentType: FragmentType;
export type forContentMarketing_store$data = {|
  +currentUser: ?{|
    +id: string,
  |},
  +id: string,
  +$fragmentType: forContentMarketing_store$fragmentType,
|};
export type forContentMarketing_store$key = {
  +$data?: forContentMarketing_store$data,
  +$fragmentSpreads: forContentMarketing_store$fragmentType,
  ...
};
*/

var node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "forContentMarketing_store",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "currentUser",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "type": "Store",
  "abstractKey": null
};
})();

(node/*: any*/).hash = "cdde229d88b7b6c420a5999f92dfa0dc";

module.exports = ((node/*: any*/)/*: Fragment<
  forContentMarketing_store$fragmentType,
  forContentMarketing_store$data,
>*/);
