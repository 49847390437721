import { commitMutation, graphql } from "react-relay"
import { ConnectionHandler } from "relay-runtime"
import { complete } from "../helper.js"
import connections from "../connections"

const mutation = graphql`
mutation toggleFilePublishedMutation($input: ToggleFilePublishedInput!) {
    toggleFilePublished(input: $input) {
      output
    }
  }  
`

const commit = async (
	env,
	fileId,
	parentId,
	containerId,
	remove,
	onError
) =>
	commitMutation(env, {
		mutation,
		variables: { input: { fileId } },
		onError: () => complete(null, onError),
		updater: (storeProxy, data) => {
			if (data.toggleFilePublished) {
				const fileProxy = storeProxy.get(fileId)
				const wasPublished = fileProxy.getValue("isPublished")
				fileProxy.setValue(!wasPublished, "isPublished")
				const containerProxy = storeProxy.get(containerId)
				containerProxy.setValue(
					containerProxy.getValue("publishedCount") +
						(wasPublished ? -1 : 1),
					"publishedCount"
				)

				if (remove) {
					const folderProxy = storeProxy.get(parentId)
					const connection = connections.currentUser.shopcastManager.items
					const connectionProxy = ConnectionHandler.getConnection(
						folderProxy,
						connection.name,
						connection.data
					)
					ConnectionHandler.deleteNode(connectionProxy, fileId)
				}
			}
		},
	})

export default { commit }
