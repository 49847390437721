/* eslint-disable no-unused-vars */
/* eslint-disable react/destructuring-assignment */
import React, { useState, Fragment } from "react"
import { createFragmentContainer, graphql } from "react-relay"
import { toast } from "react-toastify"
import { DashboardIcon, HamburgerMenuIcon } from "@radix-ui/react-icons"
import layout from "shared/styles/components/layout.css"
// import nav from "shared/styles/components/nav.css"
import icons from "shared/styles/components/icons.css"
import button from "shared/styles/components/buttons.css"
import form from "shared/styles/components/form.css"
import controls from "shared/styles/layout/controls.css"
import RakutenSiteModal from "components/modals/rakuten-site/rakuten-site-modal"
import SearchInputComponent from "components/search-input/search-input"
import ListOfRakutenSites from "components/list-of-rakuten-sites/list-of-rakuten-sites"
import ListOfRakutenSitesList from "components/list-of-rakuten-sites/list-of-rakuten-sites-list"
import UpdateRakutenSites from "mutations/admin/update-rakuten-sites"
import ScrollToTopOnMount from "components/scroll-to-top/scroll-to-top.jsx"
import _ from "lodash"
import { debounce } from "helpers/ui-helper.js"
import styles from "./rakuten-sites.css"

function Comp(props) {
	const { store, relay } = props
	const [editSite, setEditSite] = useState(null)
	const [addSite, setAddSite] = useState(null)
	const [updating, setUpdating] = useState(false)
	const [searchText, setSearchText] = useState("")
	const [selectedRegion, setSelectedRegion] = useState("0")
	const [selectedNetwork, setSelectedNetwork] = useState("0")
	const [sort, setSort] = useState("name")
	const [partner, setPartner] = useState("")
	const [category, setCategory] = useState("0")
	const [isGrid, setIsGrid] = useState(true)
	const setKeywordBounce = debounce((val) => {
		setSearchText(val)
	}, 500)

	const isAdmin = store.currentUser
		? store.currentUser.adminAccess === "HAS"
		: false

	function handleEditSite(site) {
		setEditSite(site)
	}

	function handleCloseModal() {
		if (editSite) {
			setEditSite(null)
		}
		if (addSite) {
			setAddSite(null)
		}
	}

	// function handleRegionChange(e) {
	// 	setSelectedRegion(e.target.value)
	// }

	function update() {
		setUpdating(true)
		UpdateRakutenSites.commit(
			relay.environment,
			store.id,
			({ added, updated }) => {
				if (added < 1 && updated < 1) {
					toast.info(<Fragment>Sites were already up-to-date!</Fragment>, {
						closeButton: false,
					})
				} else {
					toast.success(
						<div>
							{added > 0 && (
								<span>
									Added <b>{added}</b> new sites{" "}
								</span>
							)}
							{updated > 0 && (
								<span>
									{added ? "and " : ""}
									{added ? "updated " : "Updated "}
									{updated} sites
								</span>
							)}
						</div>,
						{ closeButton: false }
					)
				}
				setUpdating(false)
			},
			() => {
				toast.error(<Fragment>Could not update sites!</Fragment>, {
					closeButton: false,
				})
				setUpdating(false)
			}
		)
	}

	return (
		<div className={`${layout.containerWidth5} ${layout.offset}`}>
			<ScrollToTopOnMount />
			<div className={styles.masthead}>
				<div className={styles.inner}>
					<h1 className={styles.title}>Partners</h1>
					<p className={styles.lead}>
						Earn commissions from our expanding list of global retailers.
					</p>
					{isAdmin && (
						<button
							className={styles.update}
							type="button"
							disabled={updating}
							onClick={update}
						>
							{updating ? "Updating..." : "Update List"}
						</button>
					)}
				</div>
			</div>

			{/* <div className={`${nav.rootHasBorder} ${nav.rootSticky}`}>
				<nav className={nav.tabs}>
					<div className={nav.tabsFit}>
						<a className={category === 0 ? nav.tabActive : nav.tab} key="all" onClick={() => setCategory(0)} onKeyPress={() => setCategory(0)}>
							All
						</a>
						{store.retailerCategories.map((c) => (
							<a className={category === c.id ? nav.tabActive : nav.tab} key={c.id} onClick={() => setCategory(c.id)} onKeyPress={() => setCategory(c.id)}>
								{c.name}
							</a>
						))}
					</div>
				</nav>
			</div>
			<select value={category} onChange={e => setCategory(e.target.value)} className={styles.select}>
					<option value="0">All Categories</option>
					{store.categories.map(c => <option value={c.id}>{c.name}</option>)}
			</select>  */}

			<div className={styles.content}>
				<div className={controls.root}>
					<div className={controls.search}>
						<SearchInputComponent
							placeholder="Search"
							setSearchText={(value) => setKeywordBounce(value)}
						/>
					</div>
					<div className={controls.filters}>
						{store.currentUserIsAnAdminOrASharer && (
							<div className={controls.group}>
								<select
									value={sort}
									onChange={(e) => setSort(e.target.value)}
									className={controls.select}
								>
									<optgroup label="Sort by">
										<option value="name">Name (A-Z)</option>
										<option value="name-d">Name (Z-A)</option>
										<option value="rate">Rate (High-Low)</option>
										<option value="rate-d">Rate (Low-High)</option>
									</optgroup>
								</select>
							</div>
						)}
						{isAdmin && (
							<div className={controls.group}>
								<select
									value={partner}
									onChange={(e) => setPartner(e.target.value)}
									className={controls.select}
								>
									<optgroup label="Partners">
										<option value="">All Partners</option>
										{store.partneredNetworks.map((obj) => (
											<option key={obj.key} value={obj.key}>
												{obj.value}
											</option>
										))}
									</optgroup>
								</select>
							</div>
						)}
						<div className={controls.group}>
							<select
								className={controls.select}
								onChange={(e) => {
									setSelectedNetwork(e.target.value)
								}}
							>
								<optgroup label="Networks">
									<option key="1" value="0">
										All Networks
									</option>
									{store.rakutenSiteNetworks.edges.map((c) => (
										<option key={c.node.id} value={c.node.id}>
											{c.node.name}
										</option>
									))}
								</optgroup>
							</select>
						</div>
						<div className={controls.group}>
							<div className={button.group}>
								<button
									className={
										isGrid ? styles.toggleActive : styles.toggle
									}
									type="button"
									onClick={() => {
										setIsGrid(true)
									}}
								>
									<DashboardIcon className={icons.icon21} />
								</button>
								<button
									className={
										!isGrid ? styles.toggleActive : styles.toggle
									}
									type="button"
									onClick={() => {
										setIsGrid(false)
									}}
								>
									<HamburgerMenuIcon className={icons.icon21} />
								</button>
							</div>
						</div>
					</div>
				</div>

				{isGrid ? (
					<div className={styles.gridView}>
						<ListOfRakutenSites
							showRates={store.currentUserIsAnAdminOrASharer}
							category={category}
							sortBy={sort}
							setEditSite={(site) => handleEditSite(site)}
							count={15}
							isAdmin={isAdmin}
							searchText={searchText}
							region={selectedRegion}
							network={selectedNetwork}
							partner={partner}
							relay={relay}
						/>
					</div>
				) : (
					<div className={styles.listView}>
						<ListOfRakutenSitesList
							showRates={store.currentUserIsAnAdminOrASharer}
							category={category}
							sortBy={sort}
							setEditSite={(site) => handleEditSite(site)}
							count={10000}
							isAdmin={isAdmin}
							searchText={searchText}
							region={selectedRegion}
							network={selectedNetwork}
							partner={partner}
							relay={relay}
						/>
					</div>
				)}
			</div>
			{editSite && (
				<RakutenSiteModal
					closeModal={() => handleCloseModal()}
					site={editSite}
					relay={relay}
					misc={store}
				/>
			)}
		</div>
	)
}

const RakutenSites = createFragmentContainer(Comp, {
	store: graphql`
		fragment rakutenSites_store on Store {
			currentUserIsAnAdminOrASharer
			rakutenSiteRegions {
				edges {
					node {
						name
						id
					}
				}
			}
			rakutenSiteNetworks {
				edges {
					node {
						name
						id
					}
				}
			}
			countries(first: 1000) {
				edges {
					node {
						name
						id
					}
				}
			}
			networks {
				edges {
					node {
						id
						currency
						name
					}
				}
			}
			partneredNetworks {
				key
				value
			}
			retailerCategories {
				name
				id
			}
			categories {
				name
				id
			}
			currentUser {
				adminAccess
			}
			id
		}
	`,
})

const rakutenSitesQuery = graphql`
	query rakutenSitesQuery {
		store {
			...rakutenSites_store
		}
	}
`

export default {
	Component: RakutenSites,
	query: rakutenSitesQuery,
	needAuth: true,
}
