import { commitMutation, graphql } from "react-relay"
import { complete } from "../helper.js"

const mutation = graphql`
	mutation removeCountryFromRakutenSiteMutation(
		$input: RemoveCountryFromRakutenSiteInput!
	) {
		removeCountryFromRakutenSite(input: $input) {
			countries {
				name
				id
			}
			store {
				rakutenSiteRegions {
					edges {
						node {
							name
							id
						}
					}
				}
			}
		}
	}
`

const commit = (env, site, country, onSuccess, onFailure) =>
	commitMutation(env, {
		mutation,
		variables: { input: { site, country } },
		onCompleted: complete(() => {}, onFailure),
		onError: (e) => complete(null, [e]),
		updater: (storeProxy, data) => {
			if (data.removeCountryFromRakutenSite) {
				const root = storeProxy.getRootField("removeCountryFromRakutenSite")
				const countries = root.getLinkedRecords("countries")
				const currentSite = storeProxy.get(site)
				currentSite.setLinkedRecords(countries, "countries")
				const store = root.getLinkedRecord("store")
				storeProxy
					.getRoot()
					.setLinkedRecord(
						store.getLinkedRecord("rakutenSiteRegions"),
						"rakutenSiteRegions"
					)

				/* UI did not update, so had to update manually */
				onSuccess(
					countries.map((c) => ({
						id: c.getDataID(),
						name: c.getValue("name"),
						code: c.getValue("code"),
					}))
				)
			}
		},
	})

export default { commit }
