import cookie from "react-cookies"

const cookieService = {
	tokenChange: [],
	setToken: (token) => {
		cookie.save("token", token, {
			maxAge: 2592000,
			path: "/",
			sameSite: "lax",
			secure: true,
		}) // valid for a month
		cookieService.tokenChange.forEach((func) => func(token))
	},
	removeToken: () => {
		cookie.remove("token", { path: "/" })
		cookieService.tokenChange.forEach((func) => func(null))
	},
	getToken: () => cookie.load("token"),
	setRefferal: (value, expiryTime) => {
		cookie.save("referral", value, {
			maxAge: expiryTime ? expiryTime * 86400 : 604800,
			path: "/",
			sameSite: "lax",
			secure: true,
		})
	},
	getRefferal: () => cookie.load("referral"),
	removeRefferal: () => {
		cookie.remove("referral")
	},
}

export default cookieService
