import React from "react"
import { Link } from "react-router-dom"

import alert from "../../shared/styles/components/alert.css"

export default function RequireLoginToast({ heading, message }) {
	return (
		<div className={alert.toast}>
			{heading && <h3 className={alert.heading}>{heading}</h3>}
			{message && <p className={alert.message}>{message}</p>}
			<Link className={alert.action} to="/signin">
				Sign In
			</Link>
		</div>
	)
}
