import { Formik } from "formik"
import React, { Fragment } from "react"
import * as Yup from "yup"
import modal from "shared/styles/components/modal.css"
import form from "shared/styles/components/form.css"
import button from "shared/styles/components/buttons.css"
import textfield from "shared/styles/components/textfield.css"
import styles from "./image-upload.css"

export default function EditFileContent({ selectedFile, closeModal, save }) {
	return (
		<Formik
			initialValues={{
				title: selectedFile.title || "",
			}}
			validationSchema={Yup.object().shape({
				title: Yup.string().required("Title is required"),
			})}
			onSubmit={(values) => {
				save(values)
			}}
		>
			{(props) => {
				const {
					errors,
					handleSubmit,
					values,
					handleChange,
					handleBlur,
					submitCount,
				} = props
				return (
					<Fragment>
						<div className={modal.body}>
							<div className={form.row}>
								<div className={styles.col}>
									<div className={form.group}>
										<label className={form.label}>Preview</label>
										{selectedFile.tempIcon && (
											<div className={styles.product}>
												{selectedFile.tempIcon.type === "img" ? (
													selectedFile.tempIcon
												) : (
													<div className={styles.icon}>
														{selectedFile.tempIcon}
													</div>
												)}
											</div>
										)}
									</div>
								</div>
								<div className={styles.col}>
									<div className={form.group}>
										{submitCount > 0 &&
											Object.keys(errors).length > 0 && (
												<div className={form.error}>
													{Object.values(errors).map((i) => (
														<div key={i}>{i}</div>
													))}
												</div>
											)}
									</div>
									<div className={form.group}>
										<label className={form.label}>Title</label>
										<input
											className={textfield.normal}
											name="title"
											value={values.title}
											onChange={handleChange}
											onBlur={handleBlur}
										/>
									</div>
								</div>
							</div>
						</div>
						<div className={modal.footer}>
							<button
								type="button"
								className={button.link}
								onClick={() => {
									closeModal()
								}}
							>
								Cancel
							</button>
							<button
								type="submit"
								className={button.primary}
								onClick={handleSubmit}
							>
								Save
							</button>
						</div>
					</Fragment>
				)
			}}
		</Formik>
	)
}
