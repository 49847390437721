import React, { PureComponent } from "react"
import InfiniteScroll from "react-infinite-scroller"
import {
	TrashIcon,
	Pencil1Icon,
	PersonIcon,
	PaperPlaneIcon,
	ExternalLinkIcon,
} from "@radix-ui/react-icons"
import { createPaginationContainer, graphql } from "react-relay"
import LoadingDots from "components/loading-dots/loading-dots"
import grids from "shared/styles/components/grids.css"
import stylesDropdown from "components/dropdown-menu/dropdown-menu-dark.css"
import DropdownMenu from "components/dropdown-menu/dropdown-menu"
import MenuIcon from "shared/assets/menu-dots-horizontal.svg"
import Avatar from "components/profile/avatar/avatar"
import stylesAvatar from "components/profile/avatar/avatar.css"
import { Link } from "react-router-dom"
import empty from "shared/styles/components/empty.css"
import buttons from "shared/styles/components/buttons.css"
import styles from "./client-list.css"

class ClientList extends PureComponent {
	constructor(props) {
		super(props)
		if (props.setCurrentCount)
			props.setCurrentCount(
				props.store.currentUser?.userClients?.edges?.length || 0
			)
	}

	renderMenu(user) {
		const listItems = []
		const push = (click, name, key, icon) =>
			listItems.push({
				click,
				name,
				key,
				icon,
			})
		const { editClient, deleteClient, sendInvite } = this.props
		const icon = <MenuIcon className={stylesDropdown.icon} />

		if (editClient) {
			push(
				() => editClient(user),
				"Edit",
				"edit",
				<Pencil1Icon className={stylesDropdown.itemIcon} />
			)
		}
		if (deleteClient) {
			push(
				() => deleteClient(user),
				"Delete",
				"delete",
				<TrashIcon className={stylesDropdown.itemIcon} />
			)
		}
		listItems.push("divide")
		push(
			() =>
				window.open(
					`/client-preview/${
						user.folders.length > 0 ? user.folders[0].id : ""
					}`,
					"_blank"
				),
			"View Site",
			"view",
			<ExternalLinkIcon className={stylesDropdown.itemIcon} />
		)
		if (sendInvite) {
			listItems.push("divide")
			push(
				() => sendInvite(user),
				"Send Link",
				"send",
				<PaperPlaneIcon className={stylesDropdown.itemIcon} />
			)
		}
		return (
			<DropdownMenu
				items={listItems}
				mainIcon={icon}
				darkTheme
				contentRight
				triggerClass={buttons.dropdownIcon}
				triggerClassActive={buttons.dropdownIconActive}
			/>
		)
	}

	render() {
		const { store, relay } = this.props
		const { userClients } = store.currentUser
		if (userClients.edges.length === 0)
			return (
				<div className={empty.root}>
					<div className={empty.container}>
						<div className={empty.content}>
							<PersonIcon className={empty.icon} />
							<h4 className={empty.headline}>No Clients</h4>
							<p className={empty.subline}>
								Invite your clients to setup thier closets.
							</p>
						</div>
					</div>
				</div>
			)

		return (
			<InfiniteScroll
				loadMore={() => relay.loadMore(12, () => {})}
				hasMore={relay.hasMore()}
				loader={
					<div key="cl-loading" className={grids.loadMore}>
						<LoadingDots />
					</div>
				}
				pageStart={0}
			>
				<div className={styles.grid}>
					{userClients.edges.map(({ node: user }) => (
						<div className={styles.card} key={user.id}>
							<div className={styles.cardContent}>
								<Link
									to={`/content/${
										user.folders.length > 0 ? user.folders[0].id : ""
									}`}
								>
									<div className={styles.tile}>
										<div className={styles.tileInner}>
											<Avatar
												styleName={stylesAvatar.fill}
												photoUrl={user.avatar}
												fullname={user.fullname}
												imageSize="400"
											/>
										</div>
									</div>
									<div className={styles.overlay}>
										<div className={styles.content}>
											{/* View Site <ExternalLinkIcon className={styles.icon} /> */}
											View Workspace
										</div>
									</div>
								</Link>
							</div>
							<div className={styles.cardFooter}>
								<div className={styles.details}>
									<h4 className={styles.name}>{user.fullname}</h4>
									<div className={styles.group}>
										{this.renderMenu(user)}
									</div>
								</div>
								{/* <p className={styles.rate}>{user.email}</p> */}
							</div>
						</div>
					))}
				</div>

				{/* <div className={styles.table}>
					<div className={styles.thead}>
						<div className={styles.tr}>
							<div className={styles.th}>Name</div>
							<div className={styles.th}>Email</div>
							<div className={styles.th} />
						</div>
					</div>

					<div className={styles.tbody}>
						{userClients.edges.map(({ node: user }) => (
							<div className={styles.tr} key={user.id}>
								<div className={styles.td}>
									<Link
										to={`/content/${
											user.folders.length > 0
												? user.folders[0].id
												: ""
										}`}
										className={styles.nameGroup}
									>
										<Avatar
											styleName={stylesAvatar.image}
											photoUrl={user.avatar}
											fullname={user.fullname}
											imageSize="100"
											size="48px"
										/>
										<h4 className={styles.name}>{user.fullname}</h4>
									</Link>
								</div>
								<div className={styles.td}>{user.email}</div>
								<div className={styles.td}>
									<div className={styles.group}>
										<a
											className={buttons.subtleIcon}
											data-tooltip-id="tooltip"
											data-tooltip-content="View Site"
											href={`/client-preview/${
												user.folders.length > 0
													? user.folders[0].id
													: ""
											}`}
											target="_blank"
											rel="noopener noreferrer"
										>
											<ExternalLinkIcon className={styles.icon} />
										</a>
										{this.renderMenu(user)}
									</div>
								</div>
							</div>
						))}
					</div>
				</div> */}
			</InfiniteScroll>
		)
	}
}

const clientListQuery = graphql`
	query clientListQuery($count: Int, $cursor: String, $search: String) {
		store {
			...clientList_store
				@arguments(count: $count, cursor: $cursor, search: $search)
		}
	}
`

ClientList = createPaginationContainer(
	ClientList,
	{
		store: graphql`
			fragment clientList_store on Store
			@argumentDefinitions(
				count: { type: "Int", defaultValue: 12 }
				cursor: { type: "String" }
				search: { type: "String" }
			) {
				currentUser {
					userClients(first: $count, after: $cursor, search: $search)
						@connection(key: "currentUser_userClients") {
						edges {
							node {
								id
								fullname
								email
								avatar
								folders {
									id
								}
							}
						}
					}
				}
			}
		`,
	},
	{
		direction: "forward",
		getConnectionFromProps(props) {
			const { userClients } = props.store.currentUser
			return userClients
		},
		getFragmentVariables(prevVars, totalCount) {
			return {
				...prevVars,
				count: totalCount,
			}
		},
		getVariables(props, { count, cursor }) {
			console.log({
				count,
				cursor,
				search: props.search,
			})
			return {
				count,
				cursor,
				search: props.search,
			}
		},
		query: clientListQuery,
	}
)

export default {
	Component: ClientList,
	query: clientListQuery,
	params: { count: 12, cursor: null, search: "" },
}
