/**
 * @generated SignedSource<<c4f638ac8f89968003ae04e806c7d8cc>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Query } from 'relay-runtime';
import type { clientViewCloset_store$fragmentType } from "./clientViewCloset_store.graphql";
export type clientViewClosetQuery$variables = {|
  clientId?: ?string,
  collectionId?: ?string,
  count: number,
  cursor?: ?string,
  searchText?: ?string,
|};
export type clientViewClosetQuery$data = {|
  +store: ?{|
    +$fragmentSpreads: clientViewCloset_store$fragmentType,
  |},
|};
export type clientViewClosetQuery = {|
  response: clientViewClosetQuery$data,
  variables: clientViewClosetQuery$variables,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "clientId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "collectionId"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "count"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "cursor"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "searchText"
},
v5 = {
  "kind": "Variable",
  "name": "clientId",
  "variableName": "clientId"
},
v6 = {
  "kind": "Variable",
  "name": "collectionId",
  "variableName": "collectionId"
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v9 = [
  (v7/*: any*/),
  (v8/*: any*/)
],
v10 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "cursor"
  },
  (v6/*: any*/),
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "count"
  },
  {
    "kind": "Variable",
    "name": "search",
    "variableName": "searchText"
  }
],
v11 = {
  "alias": null,
  "args": null,
  "concreteType": "ProductCollection",
  "kind": "LinkedField",
  "name": "productCollections",
  "plural": true,
  "selections": (v9/*: any*/),
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "url",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "clientViewClosetQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Store",
        "kind": "LinkedField",
        "name": "store",
        "plural": false,
        "selections": [
          {
            "args": [
              (v5/*: any*/),
              (v6/*: any*/),
              {
                "kind": "Variable",
                "name": "count",
                "variableName": "count"
              },
              {
                "kind": "Variable",
                "name": "cursor",
                "variableName": "cursor"
              },
              {
                "kind": "Variable",
                "name": "searchText",
                "variableName": "searchText"
              }
            ],
            "kind": "FragmentSpread",
            "name": "clientViewCloset_store"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "ShopshareQueryRoot",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "clientViewClosetQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Store",
        "kind": "LinkedField",
        "name": "store",
        "plural": false,
        "selections": [
          (v7/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "currentUser",
            "plural": false,
            "selections": [
              (v7/*: any*/),
              {
                "alias": null,
                "args": [
                  {
                    "kind": "Variable",
                    "name": "id",
                    "variableName": "collectionId"
                  }
                ],
                "concreteType": "ProductCollection",
                "kind": "LinkedField",
                "name": "productCollection",
                "plural": false,
                "selections": (v9/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v10/*: any*/),
                "concreteType": "ProductCollectionItemConnection",
                "kind": "LinkedField",
                "name": "closetCollectionSearchItems",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ProductCollectionItemEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "__typename",
                            "storageKey": null
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              (v7/*: any*/),
                              (v8/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "brand",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "description",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "imageUrl",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "diffbotImageUrl",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "regularPrice",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "offerPrice",
                                "storageKey": null
                              },
                              (v11/*: any*/),
                              (v12/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "affiliated_url",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "User",
                                "kind": "LinkedField",
                                "name": "user",
                                "plural": false,
                                "selections": [
                                  (v7/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "fullname",
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "username",
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "profilePhoto",
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "customDomain",
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "shopcastItem",
                                "kind": "LinkedField",
                                "name": "shopcastItems",
                                "plural": true,
                                "selections": [
                                  (v7/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "Shopcast",
                                    "kind": "LinkedField",
                                    "name": "shopcast",
                                    "plural": false,
                                    "selections": (v9/*: any*/),
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "start",
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "end",
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": [
                                  (v5/*: any*/)
                                ],
                                "kind": "ScalarField",
                                "name": "isOwnedItems",
                                "storageKey": null
                              }
                            ],
                            "type": "ShopcastProduct",
                            "abstractKey": null
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              (v7/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "adminAccess",
                                "storageKey": null
                              },
                              (v8/*: any*/),
                              (v11/*: any*/),
                              (v12/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "canEdit",
                                "storageKey": null
                              }
                            ],
                            "type": "lookbookUploads",
                            "abstractKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "cursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "endCursor",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasNextPage",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v10/*: any*/),
                "filters": [
                  "collectionId",
                  "search"
                ],
                "handle": "connection",
                "key": "currentUser_closetCollectionSearchItems",
                "kind": "LinkedHandle",
                "name": "closetCollectionSearchItems"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "0afa46ae061a99711ae3a0424b8cdce0",
    "id": null,
    "metadata": {},
    "name": "clientViewClosetQuery",
    "operationKind": "query",
    "text": "query clientViewClosetQuery(\n  $count: Int!\n  $cursor: String\n  $searchText: String\n  $collectionId: String\n  $clientId: String\n) {\n  store {\n    ...clientViewCloset_store_1HOc6G\n    id\n  }\n}\n\nfragment clientViewCloset_store_1HOc6G on Store {\n  id\n  currentUser {\n    id\n    productCollection(id: $collectionId) {\n      id\n      title\n    }\n    closetCollectionSearchItems(first: $count, after: $cursor, collectionId: $collectionId, search: $searchText) {\n      edges {\n        node {\n          __typename\n          ... on ShopcastProduct {\n            id\n            title\n            brand\n            description\n            imageUrl\n            diffbotImageUrl\n            regularPrice\n            offerPrice\n            productCollections {\n              id\n              title\n            }\n            url\n            affiliated_url\n            user {\n              id\n              fullname\n              username\n              profilePhoto\n              customDomain\n            }\n            shopcastItems {\n              id\n              shopcast {\n                id\n                title\n              }\n              start\n              end\n            }\n            isOwnedItems(clientId: $clientId)\n          }\n          ... on lookbookUploads {\n            id\n            adminAccess\n            title\n            productCollections {\n              id\n              title\n            }\n            url\n            canEdit\n          }\n        }\n        cursor\n      }\n      pageInfo {\n        endCursor\n        hasNextPage\n      }\n    }\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "2e94ff0ad61723c6babb6bcf3d44253a";

module.exports = ((node/*: any*/)/*: Query<
  clientViewClosetQuery$variables,
  clientViewClosetQuery$data,
>*/);
