import React, { useState } from "react"
import { usePopper } from "react-popper"
import { useOutsideAlerter } from "helpers/ui-helper"
import styles from "./lookbook-dropdown.css"

function LookbookDropdown({
	label,
	children,
	textButton,
	dataTip,
	contentRight,
	runDebounce,
	...otherProps
}) {
	const [isOpen, setOpenStatus] = useState(false)
	const [referenceElement, setReferenceElement] = useState(null)
	const [popperElement, setPopperElement] = useState(null)
	const [rootElement, setRootElement] = useState(false)
	const { styles: stylesPopper, attributes } = usePopper(
		referenceElement,
		popperElement,
		{
			placement: contentRight ? "bottom-end" : "bottom-start",
			strategy: "absolute",
			modifiers: [
				{
					name: "offset",
					options: {
						offset: [0, 8],
					},
				},
			],
		}
	)
	function setStatus(status) {
		if (runDebounce && (status || (!status && isOpen))) {
			runDebounce(status)
		}
		setOpenStatus(status)
	}
	useOutsideAlerter(rootElement, () => setStatus(false))
	return (
		<div className={styles.root} ref={setRootElement} {...otherProps}>
			<button
				className={!textButton ? styles.triggerIcon : styles.triggerButton}
				type="button"
				onClick={() => setStatus(!isOpen)}
				data-tooltip-id="tooltip"
				data-tooltip-content={dataTip}
				ref={setReferenceElement}
			>
				{label}
			</button>
			{isOpen ? (
				<div
					ref={setPopperElement}
					style={{
						zIndex: 10,
						...stylesPopper.popper,
					}}
					{...attributes.popper}
					className={styles.content}
				>
					{children}
				</div>
			) : null}
		</div>
	)
}

export default LookbookDropdown
