/**
 * @generated SignedSource<<14a558557438c8542e78cd135aab137a>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { updateCustomDomain_currentUser$fragmentType } from "./updateCustomDomain_currentUser.graphql";
import type { updatePersonalInfo_currentUser$fragmentType } from "./updatePersonalInfo_currentUser.graphql";
import type { updateUsername_currentUser$fragmentType } from "./updateUsername_currentUser.graphql";
export type userEnumType = "CLIENT" | "RETAILER" | "STYLIST" | "%future added value";
import type { FragmentType } from "relay-runtime";
declare export opaque type personal_store$fragmentType: FragmentType;
export type personal_store$data = {|
  +currentUser: ?{|
    +optOutAffiliate: ?boolean,
    +type: ?userEnumType,
    +$fragmentSpreads: updateCustomDomain_currentUser$fragmentType & updatePersonalInfo_currentUser$fragmentType & updateUsername_currentUser$fragmentType,
  |},
  +id: string,
  +pricingPlans: ?$ReadOnlyArray<?{|
    +amount: ?number,
    +currency: ?string,
    +descriptionList: ?$ReadOnlyArray<?string>,
    +id: ?string,
    +metadata: ?$ReadOnlyArray<?{|
      +name: ?string,
      +value: ?string,
    |}>,
    +monthly: ?boolean,
    +nickname: ?string,
  |}>,
  +$fragmentType: personal_store$fragmentType,
|};
export type personal_store$key = {
  +$data?: personal_store$data,
  +$fragmentSpreads: personal_store$fragmentType,
  ...
};
*/

var node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "personal_store",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "currentUser",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "updatePersonalInfo_currentUser"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "updateUsername_currentUser"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "updateCustomDomain_currentUser"
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "type",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "optOutAffiliate",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "PricingPlan",
      "kind": "LinkedField",
      "name": "pricingPlans",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "amount",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "currency",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "nickname",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "monthly",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "descriptionList",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "NameValue",
          "kind": "LinkedField",
          "name": "metadata",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "name",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "value",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Store",
  "abstractKey": null
};
})();

(node/*: any*/).hash = "001a35263fae8f49cff2e54bb1ab08a6";

module.exports = ((node/*: any*/)/*: Fragment<
  personal_store$fragmentType,
  personal_store$data,
>*/);
