/**
 * @generated SignedSource<<f949b01ee26cafd9a9e1d47c035b78c6>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Query } from 'relay-runtime';
import type { listOfLatestSharers_store$fragmentType } from "./listOfLatestSharers_store.graphql";
export type listOfLatestSharersQuery$variables = {|
  count: number,
  cursor?: ?string,
  keyword?: ?string,
  sort?: ?string,
|};
export type listOfLatestSharersQuery$data = {|
  +store: ?{|
    +$fragmentSpreads: listOfLatestSharers_store$fragmentType,
  |},
|};
export type listOfLatestSharersQuery = {|
  response: listOfLatestSharersQuery$data,
  variables: listOfLatestSharersQuery$variables,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "count"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "cursor"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "keyword"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "sort"
  }
],
v1 = {
  "kind": "Variable",
  "name": "keyword",
  "variableName": "keyword"
},
v2 = {
  "kind": "Variable",
  "name": "sort",
  "variableName": "sort"
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "cursor"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "count"
  },
  (v1/*: any*/),
  (v2/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "listOfLatestSharersQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Store",
        "kind": "LinkedField",
        "name": "store",
        "plural": false,
        "selections": [
          {
            "args": [
              {
                "kind": "Variable",
                "name": "count",
                "variableName": "count"
              },
              {
                "kind": "Variable",
                "name": "cursor",
                "variableName": "cursor"
              },
              (v1/*: any*/),
              (v2/*: any*/)
            ],
            "kind": "FragmentSpread",
            "name": "listOfLatestSharers_store"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "ShopshareQueryRoot",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "listOfLatestSharersQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Store",
        "kind": "LinkedField",
        "name": "store",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "currentUser",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "adminAccess",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v4/*: any*/),
            "concreteType": "LatestSharersConnection",
            "kind": "LinkedField",
            "name": "directorySharers",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "LatestSharersEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "fullname",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "username",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "customDomain",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "displayName",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "isFollowing",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "profilePhoto",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "bannerPhoto",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "isRecommended",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "__typename",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "cursor",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "PageInfo",
                "kind": "LinkedField",
                "name": "pageInfo",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "endCursor",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hasNextPage",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v4/*: any*/),
            "filters": [
              "keyword",
              "sort"
            ],
            "handle": "connection",
            "key": "store_directorySharers",
            "kind": "LinkedHandle",
            "name": "directorySharers"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "888eefe44c67191fa8591f194a172b57",
    "id": null,
    "metadata": {},
    "name": "listOfLatestSharersQuery",
    "operationKind": "query",
    "text": "query listOfLatestSharersQuery(\n  $count: Int!\n  $cursor: String\n  $keyword: String\n  $sort: String\n) {\n  store {\n    ...listOfLatestSharers_store_1NzwN2\n    id\n  }\n}\n\nfragment listOfLatestSharers_store_1NzwN2 on Store {\n  id\n  currentUser {\n    id\n    adminAccess\n  }\n  directorySharers(first: $count, after: $cursor, keyword: $keyword, sort: $sort) {\n    edges {\n      node {\n        id\n        fullname\n        username\n        customDomain\n        displayName\n        isFollowing\n        profilePhoto\n        bannerPhoto\n        isRecommended\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "ccfbc4ef256da050fa52b62b0f411323";

module.exports = ((node/*: any*/)/*: Query<
  listOfLatestSharersQuery$variables,
  listOfLatestSharersQuery$data,
>*/);
