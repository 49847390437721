import React, { Component } from "react"
import { Link, Outlet } from "react-router-dom"
import { PersonIcon, RocketIcon, AvatarIcon, DotsHorizontalIcon, Share1Icon, ArchiveIcon } from "@radix-ui/react-icons"
import layout from "shared/styles/components/layout.css"
import landing from "shared/styles/layout/landing.css"
import nav from "shared/styles/components/nav.css"
import twoColumn from "shared/styles/layout/two-column.css"
import { createFragmentContainer, graphql } from "react-relay"
import Personal from "components/profile/account/personal"
import Avatar from "components/profile/avatar/avatar"
import Divider from "components/divider/divider"
import { getComponent, withRouter } from "../../route-helpers.jsx"
import styles from "./settings.css"
import { ROLES } from "../../constants.js"

const settingsPersonalQuery = graphql`
	query settingsPersonalQuery {
		store {
			...personal_store
		}
	}
`

class Settings extends Component {
	constructor(props) {
		super(props)
		this.state = {}
	}

	getRouteTab(key) {
		switch (key) {
			case "account":
				return "personal"
			case "billing":
				return "subscriptions"
			case "integrations":
				return "connections"
			case "referrals":
				return "referrals"
			case "teams":
				return "teams"
			case "people":
				return "people"
			default:
				return "personal"
		}
	}

	getActiveTabClass(tabPath) {
		const { params } = this.props
		const sectionPath = this.getRouteTab(params.key)
		if (sectionPath === tabPath) {
			return nav.pillActive
		}
		return nav.pill
	}

	render() {
		const { props } = this
		const { currentUser, team: teamObj } = props.store
		if (!currentUser) {
			return null
		}
		if (currentUser.type === "CLIENT") {
			const PersonalComp = getComponent(`personal`, {
				Component: Personal,
				query: settingsPersonalQuery,
			})
			return (
				<div className={`${layout.content} ${layout.offset}`}>
					<section className={landing.section}>
						<div className={landing.content}>
							<h1 className={landing.displayTitle}>Settings</h1>
						</div>
					</section>
					<div className={layout.containerWidth2}>{PersonalComp}</div>
				</div>
			)
		}
		return (
			<div className={twoColumn.root}>
				<div className={twoColumn.side}>
					<div className={twoColumn.fixed}>
						<div className={twoColumn.fillHeight}>
							<div className={twoColumn.scrollPanel}>
								<div className={twoColumn.sideMain}>
									<div className={styles.personal}>
										<nav className={nav.pills}>
											<div className={styles.header}>
												<div className={styles.avatar}>
													<Avatar
														size="40px"
														photoUrl={
															currentUser.profilePhoto
														}
														fullname={currentUser.fullname}
													/>
												</div>
												<div className={styles.userDetails}>
													<h4 className={styles.name}>
														{currentUser.fullname}
													</h4>
												</div>
											</div>
											<Link
												className={this.getActiveTabClass("personal")}
												to="/settings/account"
												role="button"
												tabIndex={0}
											>
												<AvatarIcon className={nav.icon} />
												<div className={nav.content}>
													Your Account
												</div>
											</Link>
											{currentUser &&
												currentUser.type !== "CLIENT" && (
													<>
														{(!teamObj ||
														teamObj.user.id ===
															currentUser.id) && (
																<Link
																className={this.getActiveTabClass(
																	"subscriptions"
																)}
																to="/settings/billing"
																role="button"
																tabIndex={0}
															>
																<ArchiveIcon
																	className={nav.icon}
																/>
																<div className={nav.content}>
																	Plan & Billing
																</div>
															</Link>
														)}

														{currentUser.referrals.length >
															0 && (
															<Link
																className={this.getActiveTabClass(
																	"referrals"
																)}
																to="/settings/referrals"
																role="button"
																tabIndex={0}
															>
																<RocketIcon
																	className={nav.icon}
																/>
																<div className={nav.content}>
																	Referrals
																</div>
															</Link>
														)}

														<Link
															className={this.getActiveTabClass(
																"connections"
															)}
															to="/settings/integrations"
															role="button"
															tabIndex={0}
														>
															<Share1Icon className={nav.icon} />
															<div className={nav.content}>
																Social Connections
															</div>
														</Link>
													</>
												)}
										</nav>
									</div>
									{currentUser &&
										currentUser.type !== "CLIENT" &&
										props.team && (
											<div className={styles.team}>
												<Divider />
												<div className={styles.header}>
													<div className={styles.avatar}>
														<Avatar
															size="40px"
															photoUrl={teamObj.avatar}
															fullname={teamObj.name}
														/>
													</div>
													<div className={styles.userDetails}>
														<h4 className={styles.name}>
															{teamObj.name}
														</h4>
													</div>
												</div>

												<nav className={nav.pills}>
													<Link
														className={this.getActiveTabClass(
															"people"
														)}
														to="/settings/people"
														role="button"
														tabIndex={0}
													>
														<PersonIcon className={nav.icon} />
														<div className={nav.content}>
															People
														</div>
													</Link>
												</nav>
											</div>
										)}
									{currentUser &&
										currentUser.type !== "CLIENT" &&
										[ROLES.ADMIN].includes(
											currentUser.role
										) && (
											<div className={twoColumn.sideFooter}>
												<Divider />
												<nav className={nav.pills}>
													<Link
														className={this.getActiveTabClass("teams")}
														to="/settings/teams"
														role="button"
														tabIndex={0}
													>
														<DotsHorizontalIcon
															className={nav.icon}
														/>
														<div className={nav.content}>
															Manage Teams
														</div>
													</Link>
												</nav>
											</div>
										)}
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className={twoColumn.main}>
					<Outlet />
				</div>
			</div>
		)
	}
}

Settings = createFragmentContainer(Settings, {
	store: graphql`
		fragment settings_store on Store
		@argumentDefinitions(teamId: { type: "String" })  {
			id
			currentUser {
				isASharer
				type
				referrals {
					id
				}
				profilePhoto
				fullname
				role
			}
			team(id: $teamId) {
				id
				name
				avatar
				myRole
				user {
					id
				}
			}
		}
	`,
})

const query = graphql`
	query settingsQuery($teamId: String) {
		store {
			...settings_store @arguments(teamId: $teamId)
		}
	}
`

export default {
	Component: withRouter(Settings),
	needAuth: true,
	query,
	params: (rp) => ({ teamId: rp.team }),
}
