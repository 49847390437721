/* eslint-disable jsx-a11y/accessible-emoji */
import React, { Component, Fragment } from "react"
import InfiniteScroll from "react-infinite-scroller"
import { PlusIcon } from "@radix-ui/react-icons"
import { createPaginationContainer, graphql } from "react-relay"
import FavouriteList from "components/lookbook/favourite-list"
import grids from "shared/styles/components/grids.css"
import lookbookAside from "shared/styles/layout/lookbook-aside.css"
import button from "shared/styles/components/buttons.css"
import icon from "shared/styles/components/icons.css"
import textfield from "shared/styles/components/textfield.css"
import ShapeCard from "components/cards/lookbook/shape-card"
import ColorPicker from "components/lookbook-dropdown/color-picker"
import ShapeUpload from "components/modals/shape-upload/shape-upload"
import svgList from "components/lookbook/shape-svgs"
import { createGuid, toTitleCase } from "helpers/string-helper"
import { clickFavourite } from "helpers/lookbook-helper"
import { includes } from "lodash"
import LoadingDots from "components/loading-dots/loading-dots"
import ConformationPrompt from "components/modals/conformation-prompt/conformation-prompt"
import deleteLookbookShapesMutation from "mutations/lookbook/delete-lookbook-shapes"
import { ROLES } from "../../constants"
import { getComponent } from "../../route-helpers"

class ShapeList extends Component {
	constructor(props) {
		super(props)
		this.state = {
			color: "#000000",
			openModal: false,
			selected: null,
			loading: false,
		}
		this.fetchData = this.fetchData.bind(this)
		this.editImage = this.editImage.bind(this)
	}

	componentDidMount() {
		const { selectedColor } = this.props
		this.setState({ color: selectedColor })
	}

	fetchData() {
		const { relay } = this.props
		relay.loadMore(24)
	}

	editImage(img) {
		this.setState({
			selected: img,
			openModal: true,
		})
	}

	render() {
		const { color, openModal, selected, deleteModal, loading } = this.state
		const {
			onChange,
			store,
			relay,
			drop,
			loading: mainLoading,
			onKeywordChange,
			shapeKeyword,
			favouriteListOpen,
			team
		} = this.props
		if (mainLoading || !(store && store.currentUser)) {
			return <LoadingDots />
		}
		const isAdmin = [ROLES.ADMIN, ROLES.TEAM].includes(store.currentUser.role)
		const collectionList = store.currentUser.shapesCollections || []
		const shapes = store.currentUser.shapes
			? store.currentUser.shapes.edges.map((x) => ({
					...x.node,
			  }))
			: []
		const svg = []
		shapes.map((shape) => {
			if (
				includes(
					svg.map((x) => x.category.toUpperCase()),
					shape.collection.toUpperCase()
				)
			) {
				if (
					!includes(
						svg
							.find((x) => x.category === shape.collection)
							.svg.map((x) => x.id),
						shape.id
					)
				) {
					svg.find((x) => x.category === shape.collection).svg.push({
						id: shape.id,
						canEdit: shape.canEdit,
						canDelete: shape.canDelete,
						viewbox: shape.viewBox,
						path: JSON.parse(shape.path),
						isFavourite: shape.isFavourite,
						collection: [{ id: shape.id, value: shape.collection }],
					})
				}
			} else {
				svg.push({
					category: shape.collection,
					svg: [
						{
							id: shape.id,
							canEdit: shape.canEdit,
							canDelete: shape.canDelete,
							viewbox: shape.viewBox,
							path: JSON.parse(shape.path),
							isFavourite: shape.isFavourite,
							collection: [{ id: shape.id, value: shape.collection }],
						},
					],
				})
			}
			return shape
		})
		svgList.push(...svg)
		const categoryListWithId =
			collectionList.map((x) => ({
				id: `new_${createGuid(4)}`,
				value: x,
			})) || []
		const hasfavourites =
			(store.currentUser.lookbookFavourites
				? store.currentUser.lookbookFavourites.edges
				: []
			).map((obj) => obj.node).length > 0 ||
			shapes.filter((obj) => obj.isFavourite === true).length > 0

		const FavouriteListComp = getComponent(
			`fav_list_shape`,
			FavouriteList,
			{
				...FavouriteList.params,
				category: "shape",
				teamId: team
			},
			{
				favouriteListOpen,
				category: "shape",
				drop,
				color,
				editImage: this.editImage
			}
		)
		return (
			<Fragment>
				<div className={lookbookAside.header}>
					<div className={lookbookAside.headerActions}>
						<select
							className={textfield.normal}
							value={shapeKeyword}
							onChange={onKeywordChange}
						>
							<option value="">All Shapes</option>
							<option disabled>─</option>
							{hasfavourites && (
								<Fragment>
									<option value="favourites"> ❤️ My Favourites</option>
									<option disabled>─</option>
								</Fragment>
							)}
							{collectionList &&
								collectionList.map((collection) => (
									<option key={collection} value={collection}>
										{toTitleCase(collection)}
									</option>
								))}
						</select>
						{isAdmin && (
							<button
								type="button"
								className={button.dropdownIconLg}
								onClick={() => this.setState({ openModal: true })}
							>
								<PlusIcon className={icon.icon21} />
							</button>
						)}
					</div>
				</div>

				<div className={lookbookAside.controls}>
					<ColorPicker
						color={color}
						onChange={(colorValue) =>
							this.setState({
								color: colorValue,
							})
						}
						onChangeComplete={(colorValue) => {
							onChange(colorValue)
							this.setState({
								color: colorValue,
							})
						}}
					/>
				</div>
				{favouriteListOpen && hasfavourites ? (
					FavouriteListComp
				) : (
					<InfiniteScroll
						loadMore={this.fetchData}
						hasMore={relay ? relay.hasMore() : false}
						loader={<LoadingDots key={0} />}
						pageStart={0}
						useWindow={false}
					>
						<div className={lookbookAside.content} id="board_imageList">
							{svg.map((y) => (
								<Fragment key={y.category}>
									<div className={lookbookAside.sectionHeader}>
										{!shapeKeyword && (
											<h4 className={lookbookAside.heading}>
												{y.category}
											</h4>
										)}
									</div>
									<div className={grids.image}>
										{y.svg.map((x) => (
											<ShapeCard
												key={x.id}
												node={x}
												drop={drop}
												color={color}
												showAddButton
												showFavouriteButton
												editImage={() =>
													this.setState({
														selected: x,
														openModal: true,
													})
												}
												deleteImage={() =>
													this.setState({
														selected: x,
														deleteModal: true,
													})
												}
												clickFavourite={() =>
													clickFavourite(x.id, "shape", team, relay)
												}
											/>
										))}
									</div>
								</Fragment>
							))}
						</div>
					</InfiniteScroll>
				)}
				<ShapeUpload
					subCategoryList={categoryListWithId}
					currentCategory={
						selected &&
						selected.collection &&
						selected.collection.length > 0
							? selected.collection[0]
							: categoryListWithId.find(
									(obj) => obj.value === shapeKeyword
							  )
					}
					openModal={openModal}
					adminAccess={isAdmin}
					relay={relay}
					selected={selected}
					closeModal={(count) => {
						if (count) relay.refetchConnection(shapes.length + count)
						this.setState({ openModal: false, selected: null })
					}}
				/>
				<ConformationPrompt
					openModal={deleteModal}
					closeModal={(count) => {
						relay.refetchConnection(shapes.length + count)
						this.setState({ deleteModal: false, selected: null })
					}}
					approveEvent={() => {
						this.setState({ loading: true })
						deleteLookbookShapesMutation.commit(
							relay.environment,
							{
								id: selected.id,
								viewBox: selected.viewBox,
								collection: selected.collection[0].value,
								path: JSON.stringify(selected.path),
							},
							() => {},
							() => {}
						)
						relay.refetchConnection(shapes.length - 1)
						this.setState({
							loading: false,
							deleteModal: false,
							selected: null,
						})
					}}
					title="Are you sure?"
					declineEvent={() => {
						relay.refetchConnection(shapes.length)
						this.setState({ deleteModal: false, selected: null })
					}}
					approveButtonStyle={button.primaryRed}
					approveText="Delete"
					declineText="Cancel"
					loading={loading}
				>
					Deleting shape from &quot;
					{selected ? selected.collection[0].value : ""}&quot; is a
					permanent action, and it cannot be recovered.
				</ConformationPrompt>
			</Fragment>
		)
	}
}
const query = graphql`
	query shapeListQuery($count: Int!, $cursor: String, $shapeKeyword: String, $teamId: String) {
		store {
			...shapeList_store
				@arguments(
					count: $count
					cursor: $cursor
					collection: $shapeKeyword
					teamId: $teamId
				)
		}
	}
`

ShapeList = createPaginationContainer(
	ShapeList,
	{
		store: graphql`
			fragment shapeList_store on Store
			@argumentDefinitions(
				count: { type: "Int", defaultValue: 12 }
				cursor: { type: "String" }
				collection: { type: "String", defaultValue: "" }
				teamId: { type: "String" }
			) {
				currentUser {
					id
					adminAccess
					role
					shapesCollections
					shapes(first: $count, after: $cursor, collection: $collection, teamId: $teamId)
						@connection(key: "currentUser_shapes") {
						edges {
							node {
								id
								canEdit
								canDelete
								viewBox
								path
								collection
								isFavourite
							}
						}
					}
					lookbookFavourites(category: "shape", teamId: $teamId) {
						edges {
							node {
								__typename
								... on Shape {
									id
									isFavourite
								}
							}
						}
					}
				}
			}
		`,
	},
	{
		direction: "forward",
		getConnectionFromProps(props) {
			const { currentUser } = props.store
			return currentUser && currentUser.shapes
		},
		getFragmentVariables(prevVars, totalCount) {
			return {
				...prevVars,
				count: totalCount,
			}
		},
		getVariables(props, { count, cursor }) {
			return {
				count,
				cursor,
				shapeKeyword: props.shapeKeyword,
				teamId: props.team,
			}
		},
		query,
	}
)
export default {
	Component: ShapeList,
	query,
	params: { count: 12, cursor: null, shapeKeyword: null },
	showLoading: true,
}
