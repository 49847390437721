/**
 * @generated SignedSource<<ab443e6d8b584c68bc766cc8d55afdb2>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentType } from "relay-runtime";
declare export opaque type sharedFolderCard_shopcastFolder$fragmentType: FragmentType;
export type sharedFolderCard_shopcastFolder$data = {|
  +id: string,
  +user: ?{|
    +id: string,
    +username: ?string,
  |},
  +$fragmentType: sharedFolderCard_shopcastFolder$fragmentType,
|};
export type sharedFolderCard_shopcastFolder$key = {
  +$data?: sharedFolderCard_shopcastFolder$data,
  +$fragmentSpreads: sharedFolderCard_shopcastFolder$fragmentType,
  ...
};
*/

var node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "sharedFolderCard_shopcastFolder",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "user",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "username",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ShopcastFolder",
  "abstractKey": null
};
})();

(node/*: any*/).hash = "994169f01f9d6ecbb63f4f2875e44557";

module.exports = ((node/*: any*/)/*: Fragment<
  sharedFolderCard_shopcastFolder$fragmentType,
  sharedFolderCard_shopcastFolder$data,
>*/);
