/**
 * @generated SignedSource<<af10bdc6b9a4832dc9324acfc9323a74>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Mutation } from 'relay-runtime';
export type PayoutCommissionInput = {|
  clientMutationId?: ?string,
  userIds?: ?$ReadOnlyArray<?string>,
|};
export type payoutCommissionMutation$variables = {|
  input: PayoutCommissionInput,
|};
export type payoutCommissionMutation$data = {|
  +payoutCommission: ?{|
    +store: ?{|
      +admin: ?{|
        +adminSalesReport: ?$ReadOnlyArray<?{|
          +commissions: ?$ReadOnlyArray<?{|
            +currency: ?string,
            +paidCommission: ?number,
            +sales: ?number,
            +totalCommission: ?number,
            +totalCommissionAUS: ?number,
            +unpaidCommission: ?number,
            +unpaidCommissionAUS: ?number,
          |}>,
        |}>,
      |},
      +id: string,
    |},
  |},
|};
export type payoutCommissionMutation = {|
  response: payoutCommissionMutation$data,
  variables: payoutCommissionMutation$variables,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "AdminSalesReportObj",
  "kind": "LinkedField",
  "name": "adminSalesReport",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "AdminSalesReport",
      "kind": "LinkedField",
      "name": "commissions",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "currency",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "sales",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "totalCommission",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "paidCommission",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "unpaidCommission",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "unpaidCommissionAUS",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "totalCommissionAUS",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "payoutCommissionMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "PayoutCommissionPayload",
        "kind": "LinkedField",
        "name": "payoutCommission",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Store",
            "kind": "LinkedField",
            "name": "store",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Admin",
                "kind": "LinkedField",
                "name": "admin",
                "plural": false,
                "selections": [
                  (v3/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "ShopshareMutationRoot",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "payoutCommissionMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "PayoutCommissionPayload",
        "kind": "LinkedField",
        "name": "payoutCommission",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Store",
            "kind": "LinkedField",
            "name": "store",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Admin",
                "kind": "LinkedField",
                "name": "admin",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "43bde5dff95880ad18895019d1e0e546",
    "id": null,
    "metadata": {},
    "name": "payoutCommissionMutation",
    "operationKind": "mutation",
    "text": "mutation payoutCommissionMutation(\n  $input: PayoutCommissionInput!\n) {\n  payoutCommission(input: $input) {\n    store {\n      id\n      admin {\n        adminSalesReport {\n          commissions {\n            currency\n            sales\n            totalCommission\n            paidCommission\n            unpaidCommission\n            unpaidCommissionAUS\n            totalCommissionAUS\n          }\n        }\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "af23cec3a6f863c706ea527b0e0f07b4";

module.exports = ((node/*: any*/)/*: Mutation<
  payoutCommissionMutation$variables,
  payoutCommissionMutation$data,
>*/);
