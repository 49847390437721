/**
 * @generated SignedSource<<b066676cb6b44b516cdf17bbd744069a>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentType } from "relay-runtime";
declare export opaque type lookbookDownload_store$fragmentType: FragmentType;
export type lookbookDownload_store$data = {|
  +currentUser: ?{|
    +adminAccess: ?string,
    +facebookPages: ?$ReadOnlyArray<?{|
      +accessToken: ?string,
      +category: ?string,
      +id: ?string,
      +imageUrl: ?string,
      +instagramBusinessAccount: ?string,
      +name: ?string,
    |}>,
    +id: string,
    +role: ?string,
    +socialMedia: ?$ReadOnlyArray<?{|
      +isConnected: ?boolean,
      +platform: ?string,
    |}>,
  |},
  +id: string,
  +$fragmentType: lookbookDownload_store$fragmentType,
|};
export type lookbookDownload_store$key = {
  +$data?: lookbookDownload_store$data,
  +$fragmentSpreads: lookbookDownload_store$fragmentType,
  ...
};
*/

var node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "lookbookDownload_store",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "currentUser",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "UserSocialMedia",
          "kind": "LinkedField",
          "name": "socialMedia",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "platform",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "isConnected",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "adminAccess",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "role",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "FacebookPageObj",
          "kind": "LinkedField",
          "name": "facebookPages",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "name",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "category",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "imageUrl",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "accessToken",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "instagramBusinessAccount",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Store",
  "abstractKey": null
};
})();

(node/*: any*/).hash = "4c0012733552198a7f71cb43ad6721f3";

module.exports = ((node/*: any*/)/*: Fragment<
  lookbookDownload_store$fragmentType,
  lookbookDownload_store$data,
>*/);
