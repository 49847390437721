import React, { Component, Fragment } from "react"
import { createPaginationContainer, graphql } from "react-relay"
import InfiniteScroll from "react-infinite-scroller"
import Lightbox from "yet-another-react-lightbox"
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen"
import Download from "yet-another-react-lightbox/plugins/download"
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails"
import Video from "yet-another-react-lightbox/plugins/video"
import Zoom from "yet-another-react-lightbox/plugins/zoom"
import { imageOrVideo } from "helpers/ui-helper"
import grids from "shared/styles/components/grids.css"
import empty from "shared/styles/components/empty.css"
import LoadingDots from "components/loading-dots/loading-dots"
import FileCard from "components/cards/file-card/file-card"
import QueryLoadMore from "../../components/profile/query-load-more/query-load-more"
import { withRouter } from "../../route-helpers"

class ClientViewFiles extends Component {
	constructor(props) {
		super(props)
		this.state = {
			loading: false,
			lightboxIndex: null,
			lightBoxItems: this.getLightBoxItems(),
		}
		this.handleLoadMore = this.handleLoadMore.bind(this)
		this.handleInfiniteLoad = this.handleInfiniteLoad.bind(this)
	}

	componentDidUpdate(prevProps) {
		const { sharedFolderFiles: prevItems } = prevProps.store.currentUser
		const { store, relay } = this.props
		const { lightboxIndex } = this.state
		const { sharedFolderFiles: items } = store.currentUser
		if (prevItems.edges.length !== items.edges.length) {
			const lbItems = this.getLightBoxItems()
			if (lightboxIndex === lbItems.length - 1 && relay.hasMore())
				this.handleInfiniteLoad()
			else this.setState({ lightBoxItems: lbItems })
		}
	}

	handleLoadMore(itemCount) {
		const { relay } = this.props
		this.setState({ loading: true })
		relay.loadMore(
			itemCount || 12,
			() => {
				this.setState({ loading: false })
			},
			{
				force: true,
			}
		)
	}

	handleInfiniteLoad() {
		const { relay } = this.props
		relay.loadMore(12)
	}

	getLightBoxItems() {
		const { store } = this.props
		const { sharedFolderFiles } = store.currentUser
		const lightBoxItems = sharedFolderFiles.edges
			.filter(({ node }) => !!imageOrVideo(node.url))
			.map(({ node }) => {
				const type = imageOrVideo(node.url)
				if (type === "image")
					return {
						src: node.url,
						type: "image",
						download: true,
					}
				return {
					sources: [
						{
							src: node.url,
						},
					],
					src: node.url,
					type: "video",
					download: node.url,
				}
			})
		return lightBoxItems
	}

	renderFiles(edges) {
		return edges.map((edge) => (
			<div className={grids.col3} key={edge.node.__id}>
				<FileCard
					file={edge.node}
					toggleLightbox={(url) => {
						const { lightBoxItems: lbItems } = this.state
						const index = lbItems.findIndex((i) => i.src === url)
						this.setState({ lightboxIndex: index })
					}}
				/>
			</div>
		))
	}

	render() {
		const { props, state } = this
		const { loading, lightboxIndex, lightBoxItems } = state
		const { currentUser } = props.store
		if (
			!currentUser.sharedFolderFiles ||
			currentUser.sharedFolderFiles.edges.length === 0
		) {
			return (
				<div className={grids.span}>
					<div className={empty.simple}>No Files found.</div>
				</div>
			)
		}
		return (
			<div className={grids.container}>
				{!props.removeInfiniteScroll ? (
					<InfiniteScroll
						loadMore={this.handleInfiniteLoad}
						hasMore={props.relay.hasMore()}
						loader={
							<div key="cvf-loading" className={grids.loadMore}>
								<LoadingDots />
							</div>
						}
						pageStart={0}
					>
						<div className={grids.rowMax4}>
							{this.renderFiles(currentUser.sharedFolderFiles.edges)}
						</div>
					</InfiniteScroll>
				) : (
					<QueryLoadMore
						data={currentUser.sharedFolderFiles.edges}
						hasMore={props.relay.hasMore()}
						loadMore={this.handleLoadMore}
						breakpoints={props.breakpoints}
					>
						{(data, itemCount) => (
							<>
								<div
									style={{
										"--grid-column-count": itemCount,
									}}
									className={grids.rowQueryLoad}
								>
									{this.renderFiles(data)}
								</div>
								{props.relay.hasMore() && (
									<div className={grids.loadMore}>
										<button
											type="button"
											className={grids.loadMoreBtn}
											disabled={loading}
											onClick={() =>
												this.handleLoadMore(itemCount * 2)
											}
										>
											{loading ? (
												<LoadingDots color="var(--gray300)" />
											) : (
												"Show more"
											)}
										</button>
									</div>
								)}
							</>
						)}
					</QueryLoadMore>
				)}
				{lightBoxItems.length > 0 && (
					<Lightbox
						open={lightboxIndex !== null}
						close={() => this.setState({ lightboxIndex: null })}
						slides={lightBoxItems}
						index={lightboxIndex}
						carousel={{
							finite: true,
						}}
						on={{
							view: ({ index }) => {
								this.setState({ lightboxIndex: index })
								const { lightBoxItems: lbItems } = this.state
								if (
									index === lbItems.length - 1 &&
									props.relay.hasMore()
								) {
									this.handleInfiniteLoad()
								}
							},
						}}
						plugins={[Fullscreen, Thumbnails, Video, Zoom, Download]}
					/>
				)}
			</div>
		)
	}
}

const query = graphql`
	query clientViewFilesQuery(
		$count: Int!
		$cursor: String
		$folder: String!
		$sort: String
	) {
		store {
			...clientViewFiles_store
				@arguments(
					count: $count
					cursor: $cursor
					folder: $folder
					sort: $sort
				)
		}
	}
`

ClientViewFiles = createPaginationContainer(
	ClientViewFiles,
	{
		store: graphql`
			fragment clientViewFiles_store on Store
			@argumentDefinitions(
				count: { type: "Int", defaultValue: 12 }
				cursor: { type: "String" }
				folder: { type: "String!" }
				sort: { type: "String" }
			) {
				id
				currentUser {
					id
					sharedFolderFiles(
						first: $count
						after: $cursor
						folderId: $folder
						sort: $sort
					) @connection(key: "currentUser_sharedFolderFiles") {
						edges {
							node {
								url
								...fileCard_shopshareFile
							}
						}
					}
				}
			}
		`,
	},
	{
		direction: "forward",
		getConnectionFromProps(props) {
			const { currentUser } = props.store
			return currentUser && currentUser.sharedFolderFiles
		},
		getFragmentVariables(prevVars, totalCount) {
			return {
				...prevVars,
				count: totalCount,
			}
		},
		getVariables(props, { count, cursor }) {
			return {
				count,
				cursor,
				folder: props.params.tab,
				sort: props.sort,
			}
		},
		query,
	}
)

export default {
	Component: withRouter(ClientViewFiles),
	query,
	params: (rp) => ({
		count: rp.count,
		cursor: null,
		folder: rp.params.tab,
		sort: rp.sort,
	}),
}
