/**
 * @generated SignedSource<<1919c4f21399c6f3908f7c930aaff0bc>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { adminRootPage_admin$fragmentType } from "./adminRootPage_admin.graphql";
import type { FragmentType } from "relay-runtime";
declare export opaque type adminComponent_store$fragmentType: FragmentType;
export type adminComponent_store$data = {|
  +admin: ?{|
    +$fragmentSpreads: adminRootPage_admin$fragmentType,
  |},
  +currentUser: ?{|
    +adminAccess: ?string,
    +id: string,
  |},
  +id: string,
  +networks: ?{|
    +edges: ?$ReadOnlyArray<?{|
      +node: ?{|
        +currency: ?string,
        +id: string,
        +name: ?string,
      |},
    |}>,
  |},
  +partneredNetworks: ?$ReadOnlyArray<?{|
    +key: ?string,
    +value: ?string,
  |}>,
  +$fragmentType: adminComponent_store$fragmentType,
|};
export type adminComponent_store$key = {
  +$data?: adminComponent_store$data,
  +$fragmentSpreads: adminComponent_store$fragmentType,
  ...
};
*/

var node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "adminComponent_store",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "currentUser",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "adminAccess",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Admin",
      "kind": "LinkedField",
      "name": "admin",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "adminRootPage_admin"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "StoreNetworkConnection",
      "kind": "LinkedField",
      "name": "networks",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "StoreNetworkEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "SiteNetwork",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "currency",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "name",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "KeyValue",
      "kind": "LinkedField",
      "name": "partneredNetworks",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "key",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "value",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Store",
  "abstractKey": null
};
})();

(node/*: any*/).hash = "13c75c1efbfd7d1afbd45dd8d3ee839d";

module.exports = ((node/*: any*/)/*: Fragment<
  adminComponent_store$fragmentType,
  adminComponent_store$data,
>*/);
