import React, { useEffect, useState } from "react"
import Dropdown, {
	DropdownTrigger,
	DropdownContent,
} from "react-simple-dropdown"
import dropdown from "components/dropdown-menu/dropdown-menu-dark.css"
import dayjs from "helpers/dayjs-helper"
import ReactDatePicker from "react-datepicker"

const rangeOptions = {
	today: "Today",
	yesterday: "Yesterday",
	"last-7-days": "Last 7 Days",
	"this-month": "This Month",
	"last-month": "Last Month",
	"this-quarter": "This Quarter",
	"last-quarter": "Last Quarter",
	"this-year": "This Year",
	"last-year": "Last Year",
	"all-time": "All Time",
	custom: "Custom",
}

function getRange(value, startDate, endDate) {
	const getTime = (d) => d.format("YYYY-MM-DD")
	switch (value) {
		case "today": {
			const today = dayjs()
			const tomorrow = dayjs().add(1, "days")
			return [getTime(today), getTime(tomorrow)]
		}
		case "yesterday": {
			const y = dayjs().subtract(1, "days")
			const t = dayjs()
			return [getTime(y), getTime(t)]
		}
		case "last-7-days": {
			return [getTime(dayjs().subtract(7, "days")), getTime(dayjs())]
		}
		case "this-month": {
			return [
				getTime(dayjs().subtract(1, "months").endOf("month")),
				getTime(dayjs()),
			]
		}
		case "last-month": {
			return [
				getTime(dayjs().subtract(1, "months").startOf("month")),
				getTime(dayjs().subtract(1, "months").endOf("month")),
			]
		}
		case "this-quarter": {
			return [
				getTime(dayjs().quarter(dayjs().quarter()).startOf("quarter")),
				getTime(dayjs().quarter(dayjs().quarter()).endOf("quarter")),
			]
		}
		case "last-quarter": {
			let q = dayjs().quarter()
			if (q > 1) {
				q -= 1
			} else q = 4
			return [
				getTime(dayjs().subtract(1, "years").quarter(q).startOf("quarter")),
				getTime(dayjs().subtract(1, "years").quarter(q).endOf("quarter")),
			]
		}
		case "this-year": {
			return [
				getTime(dayjs().startOf("year")),
				getTime(dayjs().endOf("year")),
			]
		}
		case "last-year": {
			return [
				getTime(dayjs().subtract(1, "years").startOf("year")),
				getTime(dayjs().subtract(1, "years").endOf("year")),
			]
		}
		case "all-time": {
			return [getTime(dayjs(new Date(2018, 1, 1))), getTime(dayjs())]
		}
		default:
			return [
				getTime(dayjs(startDate || new Date())),
				getTime(dayjs(endDate || new Date())),
			]
	}
}

function DatepickerDropdown({ localStorageKey, onChange }) {
	let ls = localStorageKey
		? JSON.parse(localStorage.getItem(localStorageKey))
		: {}
	ls = ls || {}
	const rn = ls.range || "all-time"
	const [sd, ed] = getRange(
		rn,
		ls.startDate ? new Date(ls.startDate) : null,
		ls.endDate ? new Date(ls.endDate) : null
	)

	const [range, setRange] = useState(rn)
	const [startDate, setStartDate] = useState(new Date(sd))
	const [endDate, setEndDate] = useState(new Date(ed))

	useEffect(() => {
		const [s, e] = getRange(range, startDate, endDate)
		onChange(s, e)
	}, [startDate, endDate])

	useEffect(() => {
		if (range !== "custom") {
			const [s, e] = getRange(range)
			setStartDate(new Date(s))
			setEndDate(new Date(e))
		}
	}, [range])

	let dropDown = null

	const getDropdownLabel = () =>
		range === "custom"
			? `${dayjs(startDate).format("MM/DD")} - ${dayjs(endDate).format(
					"MM/DD"
			  )}`
			: rangeOptions[range]

	const getLiClassName = (li) =>
		dropdown.select + (li === range ? ` ${dropdown.selected}` : "")

	function setLocalStorage(mutator) {
		if (!localStorageKey) return
		const sr = JSON.parse(localStorage.getItem(localStorageKey) || "{}")
		mutator(sr)
		localStorage.setItem(localStorageKey, JSON.stringify(sr))
	}
	const preventPropagation = (e) => {
		e.stopPropagation()
	}

	function handleRangeChange(r) {
		setRange(r)
		if (dropDown && r !== "custom") dropDown.hide()
		setLocalStorage((sr) => {
			sr.range = r
		})
	}

	function handleEndDateChange(date) {
		setEndDate(date)
		setLocalStorage((sr) => {
			sr.endDate = date
		})
	}

	function handleStartDateChange(date) {
		setStartDate(date)
		setLocalStorage((sr) => {
			sr.startDate = date
		})
		if (date > endDate) {
			handleEndDateChange(date)
		}
	}

	return (
		<Dropdown
			ref={(d) => {
				dropDown = d
			}}
			className={`${dropdown.root}`}
		>
			<DropdownTrigger className={dropdown.triggerButton}>
				{getDropdownLabel()}
			</DropdownTrigger>
			<DropdownContent className={dropdown.contentSelect}>
				{Object.keys(rangeOptions).map((key) => (
					<a
						className={getLiClassName(key)}
						onClick={() => handleRangeChange(key)}
						onKeyPress={() => handleRangeChange(key)}
						tabIndex={-1}
						role="link"
						key={key}
					>
						{rangeOptions[key]}
					</a>
				))}
				{range === "custom" && (
					<div
						className={dropdown.dateRange}
						role="button"
						tabIndex={0}
						onClick={preventPropagation}
						onKeyPress={preventPropagation}
					>
						<ReactDatePicker
							selected={startDate}
							selectsStart
							startDate={startDate}
							className={dropdown.dateInput}
							endDate={endDate}
							onChange={(date) => handleStartDateChange(date)}
						/>
						<div className={dropdown.joinText}>to</div>
						<ReactDatePicker
							selected={endDate}
							selectsEnd
							startDate={startDate}
							className={dropdown.dateInput}
							endDate={endDate}
							onChange={(date) => handleEndDateChange(date)}
							minDate={startDate}
						/>
					</div>
				)}
			</DropdownContent>
		</Dropdown>
	)
}

export default DatepickerDropdown
