import React, { PureComponent } from "react"
import Modal from "react-modal"
import modal from "shared/styles/components/modal.css"
import button from "shared/styles/components/buttons.css"
import LoadingDots from "../../loading-dots/loading-dots"

export default class ConformationPrompt extends PureComponent {
	render() {
		const {
			children,
			openModal,
			closeModal,
			approveEvent,
			declineEvent,
			title,
			approveText,
			declineText,
			approveButtonStyle,
			loading,
			approveLoadingColor,
			...otherProps
		} = this.props
		return (
			<Modal
				className={modal.root}
				overlayClassName={modal.overlay}
				isOpen={openModal}
				htmlOpenClassName="ReactModal__Html--open"
				{...otherProps}
				ariaHideApp={false}
			>
				<div className={`${modal.dialog} ${modal.dialogCentered}`}>
					<div className={`${modal.content}`}>
						<div className={`${modal.header}`}>
							{title && <h4 className={modal.title}>{title}</h4>}
						</div>
						<div className={modal.body}>{children}</div>
						<div className={`${modal.footer} ${modal.borderNone}`}>
							{declineEvent && (
								<button
									type="button"
									className={button.link}
									onClick={declineEvent}
								>
									{loading ? (
										<LoadingDots color="var(--gray300)" />
									) : (
										declineText || "Cancel"
									)}
								</button>
							)}
							{approveEvent && (
								<button
									type="button"
									className={approveButtonStyle || button.primary}
									onClick={approveEvent}
								>
									{loading ? (
										<LoadingDots
											color={approveLoadingColor || "var(--gray300)"}
										/>
									) : (
										approveText || "Ok"
									)}
								</button>
							)}
						</div>
					</div>
				</div>
			</Modal>
		)
	}
}
