import React, { PureComponent } from "react"
import { createPaginationContainer, graphql } from "react-relay"
import ListOfShopcasts from "../list-of-shopcasts/list-of-shopcasts.jsx"

class ListOfLatestShopcasts extends PureComponent {
	render() {
		const { store, relay } = this.props
		return (
			<ListOfShopcasts
				enableHeader
				shopcasts={store.latestShopcasts}
				relay={relay}
				currentUser={store.currentUser}
				storeId={store.id}
			/>
		)
	}
}

const query = graphql`
	query listOfLatestShopcastsQuery(
		$count: Int!
		$cursor: String
	) {
		store {
			...listOfLatestShopcasts_store
				@arguments(count: $count, cursor: $cursor)
		}
	}
`

ListOfLatestShopcasts = createPaginationContainer(
	ListOfLatestShopcasts,
	{
		store: graphql`
			fragment listOfLatestShopcasts_store on Store
			@argumentDefinitions(
				count: { type: "Int", defaultValue: 12 }
				cursor: { type: "String" }
			) {
				id
				currentUser {
					...shopcastCard_currentUser
				}
				latestShopcasts(first: $count, after: $cursor)
					@connection(key: "store_latestShopcasts") {
					edges {
						cursor
						node {
							id
							...shopcastCard_shopcast
						}
					}
					pageInfo {
						hasPreviousPage
						hasNextPage
						startCursor
						endCursor
					}
				}
			}
		`,
	},
	{
		direction: "forward",
		getConnectionFromProps(props) {
			const { latestShopcasts } = props.store
			return latestShopcasts && latestShopcasts
		},
		getFragmentVariables(prevVars, totalCount) {
			return {
				...prevVars,
				count: totalCount,
			}
		},
		getVariables(props, { cursor }) {
			return {
				count: props.count,
				cursor,
			}
		},
		query,
	}
)

export default {
	Component: ListOfLatestShopcasts,
	query,
	params: { count: 12, cursor: null },
	props: { count: 12 },
}
