/**
 * @generated SignedSource<<457cdab027521deb11e58a55b417a521>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Query } from 'relay-runtime';
import type { backgroundList_store$fragmentType } from "./backgroundList_store.graphql";
export type backgroundListQuery$variables = {|
  bgType?: ?string,
  count: number,
  cursor?: ?string,
  sub_category?: ?string,
  teamId?: ?string,
|};
export type backgroundListQuery$data = {|
  +store: ?{|
    +$fragmentSpreads: backgroundList_store$fragmentType,
  |},
|};
export type backgroundListQuery = {|
  response: backgroundListQuery$data,
  variables: backgroundListQuery$variables,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "bgType"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "count"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "cursor"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "sub_category"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "teamId"
},
v5 = {
  "kind": "Variable",
  "name": "bgType",
  "variableName": "bgType"
},
v6 = {
  "kind": "Variable",
  "name": "subCategory",
  "variableName": "sub_category"
},
v7 = {
  "kind": "Variable",
  "name": "teamId",
  "variableName": "teamId"
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v9 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "cursor"
  },
  (v5/*: any*/),
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "count"
  },
  (v6/*: any*/),
  (v7/*: any*/)
],
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isFavourite",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v12 = [
  (v8/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "backgroundListQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Store",
        "kind": "LinkedField",
        "name": "store",
        "plural": false,
        "selections": [
          {
            "args": [
              (v5/*: any*/),
              {
                "kind": "Variable",
                "name": "count",
                "variableName": "count"
              },
              {
                "kind": "Variable",
                "name": "cursor",
                "variableName": "cursor"
              },
              (v6/*: any*/),
              (v7/*: any*/)
            ],
            "kind": "FragmentSpread",
            "name": "backgroundList_store"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "ShopshareQueryRoot",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v2/*: any*/),
      (v0/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Operation",
    "name": "backgroundListQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Store",
        "kind": "LinkedField",
        "name": "store",
        "plural": false,
        "selections": [
          (v8/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "currentUser",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "adminAccess",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "role",
                "storageKey": null
              },
              {
                "alias": null,
                "args": [
                  (v5/*: any*/)
                ],
                "kind": "ScalarField",
                "name": "bgCategories",
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v9/*: any*/),
                "concreteType": "BackgroundConnectionConnection",
                "kind": "LinkedField",
                "name": "backgrounds",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "BackgroundConnectionEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Background",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v8/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "canEdit",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "title",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "category",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "background",
                            "storageKey": null
                          },
                          (v10/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "type",
                            "storageKey": null
                          },
                          (v11/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "cursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "endCursor",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasNextPage",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v9/*: any*/),
                "filters": [
                  "bgType",
                  "subCategory",
                  "teamId"
                ],
                "handle": "connection",
                "key": "currentUser_backgrounds",
                "kind": "LinkedHandle",
                "name": "backgrounds"
              },
              {
                "alias": null,
                "args": [
                  {
                    "kind": "Variable",
                    "name": "category",
                    "variableName": "bgType"
                  },
                  (v7/*: any*/)
                ],
                "concreteType": "LookbookFavouritesConnectionConnection",
                "kind": "LinkedField",
                "name": "lookbookFavourites",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "LookbookFavouritesConnectionEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v11/*: any*/),
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              (v8/*: any*/),
                              (v10/*: any*/)
                            ],
                            "type": "Background",
                            "abstractKey": null
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": (v12/*: any*/),
                            "type": "LookbookTemplate",
                            "abstractKey": null
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": (v12/*: any*/),
                            "type": "Shape",
                            "abstractKey": null
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": (v12/*: any*/),
                            "type": "ShopcastProduct",
                            "abstractKey": null
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": (v12/*: any*/),
                            "type": "lookbookUploads",
                            "abstractKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v8/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "103a6a25ecf9bf567c9f6a7a93985186",
    "id": null,
    "metadata": {},
    "name": "backgroundListQuery",
    "operationKind": "query",
    "text": "query backgroundListQuery(\n  $count: Int!\n  $cursor: String\n  $bgType: String\n  $sub_category: String\n  $teamId: String\n) {\n  store {\n    ...backgroundList_store_t9Kr1\n    id\n  }\n}\n\nfragment backgroundList_store_t9Kr1 on Store {\n  id\n  currentUser {\n    adminAccess\n    role\n    bgCategories(bgType: $bgType)\n    backgrounds(bgType: $bgType, subCategory: $sub_category, first: $count, after: $cursor, teamId: $teamId) {\n      edges {\n        node {\n          id\n          canEdit\n          title\n          category\n          background\n          isFavourite\n          type\n          __typename\n        }\n        cursor\n      }\n      pageInfo {\n        endCursor\n        hasNextPage\n      }\n    }\n    lookbookFavourites(category: $bgType, teamId: $teamId) {\n      edges {\n        node {\n          __typename\n          ... on Background {\n            id\n            isFavourite\n          }\n          ... on LookbookTemplate {\n            id\n          }\n          ... on Shape {\n            id\n          }\n          ... on ShopcastProduct {\n            id\n          }\n          ... on lookbookUploads {\n            id\n          }\n        }\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "21d92214761b13a2d6a45dc27408440d";

module.exports = ((node/*: any*/)/*: Query<
  backgroundListQuery$variables,
  backgroundListQuery$data,
>*/);
