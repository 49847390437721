import React, { Component, Fragment } from "react"
import { CheckIcon } from "@radix-ui/react-icons"
import { createFragmentContainer, graphql } from "react-relay"
import { Link } from "react-router-dom"
import Avatar from "components/profile/avatar/avatar"
import {
	FeatureAvailableComponent,
	FeatureKeyENUM,
} from "services/plan-services"
import Logout from "../header/logout.jsx"
import dropdowns from "./dropdowns.css"
import { ROLES } from "../../constants.js"

class UserMenu extends Component {
	constructor(props) {
		super(props)
		this.state = {
			showUserMenu: false,
		}
		this.toggleDropdown = this.toggleDropdown.bind(this)
	}

	componentDidMount() {
		this.isCompMounted = true
		window.addEventListener("click", this.windowClicked.bind(this), false)
	}

	componentWillUnmount() {
		this.isCompMounted = false
		window.removeEventListener("click", this.windowClicked.bind(this), false)
	}

	windowClicked() {
		const { state } = this
		if (state.showUserMenu === true && this.isCompMounted) {
			this.setState({
				showUserMenu: false,
			})
		}
	}

	toggleDropdown(e) {
		this.setState((state) => ({
			showUserMenu: !state.showUserMenu,
		}))
		e.stopPropagation()
	}

	renderStylistNav() {
		const { props } = this
		const user = props.store.currentUser
		return (
			<>
				{/* <Link className={this.theme.item} to="/install-extension">
					Install Recorder
				</Link> */}
				{!user.optOutAffiliate && !user.isRetailer && (
					<Link className={this.theme.item} to="/reporting">
						Reports
					</Link>
				)}
				{!user.optOutAffiliate && !user.isRetailer && (
					<Link className={this.theme.item} to="/partners">
						Retail Partners
					</Link>
				)}
			</>
		)
	}

	renderTeams() {
		const { props } = this
		const user = props.store.currentUser
		return user.teams.length > 0 ? (
			<>
				<div className={this.theme.menuHeader}>
					<div className={this.theme.menuHeaderTitle}>Switch Team</div>
				</div>
				{user.teams.map((team) => (
					<button
						type="button"
						className={this.theme.itemAvatar}
						disabled={props.team === team.id}
						onClick={() => props.setTeam(team.id)}
					>
						<span className={this.theme.itemLabel}>
							<Avatar
								size="32px"
								photoUrl={team.avatar}
								fullname={team.name}
							/>
							{team.name}
						</span>
						{props.team === team.id && (
							<CheckIcon className={this.theme.itemIcon} />
						)}
					</button>
				))}
				<button
					disabled={!props.team}
					type="button"
					className={this.theme.itemAvatar}
					onClick={() => props.setTeam(null)}
				>
					<span className={this.theme.itemLabel}>
						<Avatar
							size="32px"
							photoUrl={user.profilePhoto}
							fullname={user.fullname}
						/>
						Personal
					</span>
					{!props.team && <CheckIcon className={this.theme.itemIcon} />}
				</button>
				<div className={this.theme.divider} />
			</>
		) : (
			<FeatureAvailableComponent
				featureKey={FeatureKeyENUM.TEAMS}
				env={props.relay.environment}
			>
				{[ROLES.ADMIN].includes(user.role) && (
					<>
						<Link className={this.theme.item} to="/settings/teams">
							Create Team
						</Link>
						<div className={this.theme.divider} />
					</>
				)}
			</FeatureAvailableComponent>
		)
	}

	renderUserNav() {
		const { props, state } = this
		const user = props.store.currentUser
		const isAdmin = user.adminAccess === "HAS"
		this.theme = dropdowns

		const selectedTeam =
			props.team && user.teams.find((t) => t.id === props.team)
		return (
			<div className={this.theme.root}>
				<a
					className={
						state.showUserMenu
							? this.theme.toggleActive
							: this.theme.toggle
					}
					onKeyPress={this.toggleDropdown}
					onClick={this.toggleDropdown}
					role="link"
					tabIndex={0}
				>
					<Avatar
						size="40px"
						photoUrl={user.profilePhoto}
						fullname={user.fullname}
					/>
					{selectedTeam && (
						<div className={this.theme.teamAvatar}>
							<Avatar
								size="24px"
								photoUrl={selectedTeam.avatar}
								fullname={selectedTeam.name}
							/>
						</div>
					)}
				</a>
				<div
					className={
						state.showUserMenu
							? this.theme.menuRightOpen
							: this.theme.menuRight
					}
				>
					<div className={this.theme.header}>
						<div className={this.theme.avatar}>
							<Avatar
								size="64px"
								photoUrl={user.profilePhoto}
								fullname={user.fullname}
							/>
						</div>
						<div className={this.theme.userDetails}>
							<h4 className={this.theme.name}>{user.fullname}</h4>
							<p className={this.theme.email}>{user.email}</p>
						</div>
					</div>
					<div className={this.theme.divider} />
					{this.renderTeams()}
					{user.type !== "CLIENT" && this.renderStylistNav()}
					<Link className={this.theme.item} to="/settings/account">
						Settings
					</Link>
					<Logout
						logoutClass={this.theme.item}
						store={props.store}
						relay={props.relay}
						setTeam={props.setTeam}
					/>
					{isAdmin && (
						<div className={this.theme.admin}>
							<div className={this.theme.divider} />
							<Link className={this.theme.item} to="/admin">
								Admin
							</Link>
						</div>
					)}
				</div>
			</div>
		)
	}

	render() {
		return this.renderUserNav()
	}
}

UserMenu = createFragmentContainer(UserMenu, {
	store: graphql`
		fragment userMenu_store on Store {
			id
			currentUser {
				id
				fullname
				username
				email
				customDomain
				profilePhoto
				bannerPhoto
				adminAccess
				isRetailer
				type
				role
				hasPendingSharerRequests
				optOutAffiliate
				personalPlan: userPlan {
					id
				}
				teams {
					id
					name
					avatar
				}
			}
		}
	`,
})

export default UserMenu
