import { commitMutation, graphql } from "react-relay"
import { complete } from "../helper"

const mutation = graphql`
	mutation payoutUsersMutation($input: PayoutReferralInput!) {
		payoutReferral(input: $input) {
			store {
				id
			}
		}
	}
`

const commit = (env, onError, onCompleted) => commitMutation(env, {
  mutation,
  variables: { input: {} },
  onError: complete(onCompleted, onError),
  onCompleted: complete(onCompleted, onError),
})

export default { commit }
