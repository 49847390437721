import React from "react"
import PlayIcon from "shared/assets/play-icon.svg"
import PauseIcon from "shared/assets/pause-icon.svg"
import styles from "./play.css"

export default function Play({ isPlaying, clicked }) {
	return (
		<button type="button" className={styles.button} onClick={clicked}>
			{isPlaying === true ? (
				<PauseIcon className={styles.pause} />
			) : (
				<PlayIcon className={styles.play} />
			)}
		</button>
	)
}
