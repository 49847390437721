import React, { Component } from "react"
import { createPaginationContainer, graphql } from "react-relay"
import { toast } from "react-toastify"
import ClosetIcon from "shared/assets/closet-icon.svg"
import InfiniteScroll from "react-infinite-scroller"
import { unset } from "lodash"
import Lightbox from "yet-another-react-lightbox"
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen"
import Download from "yet-another-react-lightbox/plugins/download"
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails"
import Video from "yet-another-react-lightbox/plugins/video"
import Zoom from "yet-another-react-lightbox/plugins/zoom"
import pageContent from "shared/styles/layout/page-content.css"
import grids from "shared/styles/components/grids.css"
import empty from "shared/styles/components/empty.css"
import button from "shared/styles/components/buttons.css"
// import icon from "shared/styles/components/icons.css"
import LoadingDots from "components/loading-dots/loading-dots"
import ImageCard from "components/cards/lookbook/image-card"
import pageHeader from "shared/styles/layout/page-header.css"
import ConformationPrompt from "components/modals/conformation-prompt/conformation-prompt"
import deleteLookbookUploadMutation from "mutations/lookbook/delete-lookbook-upload"
import deleteProductItemMutation from "mutations/product-collection/delete-product-collection-item"
import updateCategorizeLookbookUploadMutation from "mutations/lookbook/update-categorize-lookbook-upload"
import setLookbookUploadThumbUrlMutation from "mutations/lookbook/set-lookbook-upload-thumb-url"
import awsService from "services/aws-service"
import gqlHelper from "../../helpers/gql-helper"
import { withRouter } from "../../route-helpers"
import ItemReferencesModal from "../../components/item-references/item-references"

class ClientViewCloset extends Component {
	constructor(props) {
		super(props)
		this.state = {
			itemToDelete: null,
			lightboxIndex: null,
			lightBoxItems: this.getLightBoxItems(),
			itemReferenceId: null,
		}
		this.handleLoadMore = this.handleLoadMore.bind(this)
		this.handleFileChange = this.handleFileChange.bind(this)
	}

	componentDidUpdate(prevProps) {
		const { closetCollectionSearchItems: prevItems } =
			prevProps.store.currentUser
		const { store, relay } = this.props
		const { lightboxIndex } = this.state
		const { closetCollectionSearchItems: items } = store.currentUser
		if (prevItems.edges.length !== items.edges.length) {
			const lbItems = this.getLightBoxItems()
			if (lightboxIndex === lbItems.length - 1 && relay.hasMore())
				this.handleLoadMore()
			else this.setState({ lightBoxItems: lbItems })
		}
	}

	handleLoadMore() {
		const { relay } = this.props
		relay.loadMore(12, () => {})
	}

	async handleFileChange(event) {
		const { clientFolder, relay, store } = this.props
		const { productCollection } = store.currentUser
		const { files } = event.target
		const filesArr = Array.from(files).map((fileObj) => ({
			title: fileObj.name.split(".")[0],
			file: fileObj,
			collections: productCollection
				? [
						{
							id: productCollection.id,
							value: productCollection.title,
						},
				  ]
				: [],
			adminAccess: false,
			url: null,
		}))
		const promises = filesArr.map(async (dataItem) => {
			const x = dataItem
			const thumbImage = x.file
			const args = {
				fileType: thumbImage.type,
				ext: thumbImage.name.substring(
					thumbImage.name.lastIndexOf(".") + 1
				),
			}
			unset(x, "file")
			x.collections = x.collections.map((sub) => ({
				id: sub.id.includes("new_") ? null : sub.id,
				value: sub.value.toLowerCase(),
			}))
			const mutationRes = await new Promise((resolve, reject) => {
				updateCategorizeLookbookUploadMutation.commit(
					relay.environment,
					{ ...x, stylist: clientFolder.user.id },
					args,
					(e) => {
						reject(e)
					},
					(result) => {
						resolve(result)
					},
					null
				)
			})
			const innerData =
				mutationRes?.createCategorizeLookbookUploads ||
				mutationRes?.createLookbookUploads

			const Id = x.id || innerData?.lookbookUploads.id
			const preSignedUrl = innerData?.lookbookUploads?.preSignedUrl

			await awsService.uploadImageAWS(
				preSignedUrl?.signedRequest,
				thumbImage,
				() => {}
			)
			const urlData = {
				uploadId: Id,
				url: preSignedUrl?.url,
			}
			await new Promise((resolve, reject) => {
				setLookbookUploadThumbUrlMutation.commit(
					relay.environment,
					urlData,
					(e) => {
						reject(e)
					},
					(res) => {
						resolve(res)
					}
				)
			})
		})
		await promises.reduce((p, task) => p.then(task), Promise.resolve())
	}

	getLightBoxItems() {
		const { store } = this.props
		const { closetCollectionSearchItems } = store.currentUser
		const lightBoxItems = closetCollectionSearchItems.edges.map(
			({ node }) => ({
				src: node.imageUrl || node.url,
				type: "image",
				download: true,
			})
		)
		return lightBoxItems
	}

	loadCard(x) {
		const { store, relay } = this.props
		return (
			<ImageCard
				key={x.node.id || x.node.__id}
				node={x?.node}
				description={x?.node?.title}
				canEdit
				deleteImage={() => {
					this.setState({
						itemToDelete: {
							id: x?.node?.id,
							type:
								x?.node?.__typename === "lookbookUploads"
									? "image"
									: "product",
							title: x?.node?.title,
						},
					})
				}}
				removeEditImageOption
				editImage={(node) => {
					const { lightBoxItems: lbItems } = this.state
					const url = node.imageUrl || node.url
					const index = lbItems.findIndex((i) => i.src === url)
					this.setState({ lightboxIndex: index })
				}}
				deleteBtnLabel="Remove"
				contentEditable
				relay={relay}
				store={store}
				showReferenceTitle="How to Style"
				showReferences={() => {
					this.setState({ itemReferenceId: x?.node?.id })
				}}
			/>
		)
	}

	async deleteUpload() {
		const { relay, collectionId } = this.props
		const { itemToDelete } = this.state
		await new Promise((resolve, reject) => {
			deleteLookbookUploadMutation.commit(
				relay.environment,
				{ collectionId, uploadId: itemToDelete.id },
				(result) => {
					toast.success(<>Successfully Removed</>, {
						autoClose: 5000,
						closeButton: false,
					})
					resolve(result)
				},
				(e) => {
					toast.info(<>{gqlHelper.getError(e)}</>, {
						autoClose: 5000,
						closeButton: false,
					})
					reject(e)
				},
				true
			)
		})
		this.setState({
			itemToDelete: null,
		})
	}

	async deleteProduct() {
		const { relay, collectionId } = this.props
		const { itemToDelete } = this.state
		await new Promise((resolve, reject) => {
			deleteProductItemMutation.commit(
				relay.environment,
				collectionId,
				itemToDelete.id,
				(e) => {
					toast.info(<>{gqlHelper.getError(e)}</>, {
						autoClose: 5000,
						closeButton: false,
					})
					reject(e)
				},
				(result) => {
					toast.success(<>Successfully Removed</>, {
						autoClose: 5000,
						closeButton: false,
					})
					resolve(result)
				},
				null
			)
		})
		this.setState({
			itemToDelete: null,
		})
	}

	render() {
		const { props, state } = this
		const { store, relay, clientFolder } = props
		const { itemToDelete, lightboxIndex, lightBoxItems, itemReferenceId } =
			state
		const { closetCollectionSearchItems: items, productCollection } =
			store.currentUser
		if (!items || items.edges.length === 0)
			return (
				<div className={pageContent.root}>
					<input
						type="file"
						ref={(ref) => {
							this.fileInput = ref
						}}
						hidden
						onChange={this.handleFileChange}
						accept="image/*"
						multiple
					/>
					<div className={empty.root}>
						<div className={empty.container}>
							<div className={empty.content}>
								<ClosetIcon className={empty.icon} />
								<h4 className={empty.headline}>Upload photos</h4>
								<p className={empty.subline}>
									Keep track of the items you own here.
								</p>
								<button
									type="button"
									className={empty.cta}
									onClick={() => this.fileInput.click()}
								>
									Upload
								</button>
							</div>
						</div>
					</div>
				</div>
			)
		return (
			<>
				{/*  <div className={pageHeader.header}>
					<div className={pageHeader.content}>
						<div className={pageHeader.titleWithAction}>
							<button
								type="button"
								className={button.subtleIcon}
								onClick={() => back()}
							>
								<ArrowLeftIcon className={icon.icon21} />
							</button> 
							<h1 className={pageHeader.title}>
								{productCollection.title}
							</h1>
						</div>
						<div className={pageHeader.actions}>
							<button
								type="submit"
								className={button.primary}
								onClick={() => upload(productCollection)}
							>
								Upload
							</button>
						</div>
					</div>
				</div> */}
				<input
					type="file"
					ref={(ref) => {
						this.fileInput = ref
					}}
					hidden
					onChange={this.handleFileChange}
					accept="image/*"
					multiple
				/>
				<div className={pageHeader.headerClient}>
					<h1 className={pageHeader.title}>{productCollection.title}</h1>

					<button
						type="submit"
						className={button.primary}
						onClick={() => this.fileInput.click()}
					>
						Upload
					</button>
				</div>

				<div className={pageContent.root}>
					<div className={grids.container}>
						<InfiniteScroll
							loadMore={this.handleLoadMore}
							hasMore={relay.hasMore()}
							loader={
								<div key="cvc-loading" className={grids.loadMore}>
									<LoadingDots />
								</div>
							}
							pageStart={0}
						>
							<div className={grids.imageMd}>
								{items.edges.map((x) => this.loadCard(x))}
							</div>
						</InfiniteScroll>
					</div>
					<ItemReferencesModal
						itemId={itemReferenceId}
						folderId={clientFolder.id}
						modalTitle="How to Style"
						closeModal={() => {
							this.setState({ itemReferenceId: null })
						}}
					/>
					<ConformationPrompt
						openModal={!!itemToDelete}
						closeModal={() => {
							this.setState({ itemToDelete: null })
						}}
						approveEvent={() => {
							if (itemToDelete.type === "image") this.deleteUpload()
							if (itemToDelete.type === "product") this.deleteProduct()
						}}
						title="Are you sure?"
						declineEvent={() => {
							this.setState({ itemToDelete: null })
						}}
						approveText="Remove"
						approveButtonStyle={button.primaryRed}
						declineText="Cancel"
					>
						{(() => {
							switch (itemToDelete?.type) {
								case "product":
									return `Remove "${itemToDelete?.title}" from the collection is a permanent action, and it cannot be recovered.`
								case "collection":
									return `Remove "${itemToDelete?.title}" collection is a permanent action, and it cannot be recovered.`
								case "product-bulk":
									return `Remove selected items from the collection is a permanent action, and it cannot be recovered.`
								default:
									return `Remove "${itemToDelete?.title}" is a permanent action, and it cannot be recovered.`
							}
						})()}
					</ConformationPrompt>
					{lightBoxItems.length > 0 && (
						<Lightbox
							open={lightboxIndex !== null}
							close={() => this.setState({ lightboxIndex: null })}
							slides={lightBoxItems}
							index={lightboxIndex}
							carousel={{
								finite: true,
							}}
							on={{
								view: ({ index }) => {
									this.setState({ lightboxIndex: index })
									const { lightBoxItems: lbItems } = this.state
									if (
										index === lbItems.length - 1 &&
										props.relay.hasMore()
									) {
										this.handleLoadMore()
									}
								},
							}}
							plugins={[Fullscreen, Thumbnails, Video, Zoom, Download]}
						/>
					)}
				</div>
			</>
		)
	}
}

const query = graphql`
	query clientViewClosetQuery(
		$count: Int!
		$cursor: String
		$searchText: String
		$collectionId: String
		$clientId: String
	) {
		store {
			...clientViewCloset_store
				@arguments(
					count: $count
					cursor: $cursor
					searchText: $searchText
					collectionId: $collectionId
					clientId: $clientId
				)
		}
	}
`

ClientViewCloset = createPaginationContainer(
	ClientViewCloset,
	{
		store: graphql`
			fragment clientViewCloset_store on Store
			@argumentDefinitions(
				count: { type: "Int", defaultValue: 12 }
				cursor: { type: "String" }
				searchText: { type: "String" }
				collectionId: { type: "String" }
				clientId: { type: "String" }
			) {
				id
				currentUser {
					id
					productCollection(id: $collectionId) {
						id
						title
					}
					closetCollectionSearchItems(
						first: $count
						after: $cursor
						collectionId: $collectionId
						search: $searchText
					) @connection(key: "currentUser_closetCollectionSearchItems") {
						edges {
							node {
								__typename
								... on ShopcastProduct {
									id
									title
									brand
									description
									imageUrl
									diffbotImageUrl
									regularPrice
									offerPrice
									productCollections {
										id
										title
									}
									url
									affiliated_url
									user {
										id
										fullname
										username
										profilePhoto
										customDomain
									}
									shopcastItems {
										id
										shopcast {
											id
											title
										}
										start
										end
									}
									isOwnedItems(clientId: $clientId)
								}
								... on lookbookUploads {
									id
									adminAccess
									title
									productCollections {
										id
										title
									}
									url
									canEdit
								}
							}
						}
					}
				}
			}
		`,
	},
	{
		direction: "forward",
		getConnectionFromProps(props) {
			const { currentUser } = props.store
			return currentUser && currentUser.closetCollectionSearchItems
		},
		getFragmentVariables(prevVars, totalCount) {
			return {
				...prevVars,
				count: totalCount,
			}
		},
		getVariables(props, { count, cursor }) {
			return {
				count,
				cursor,
				searchText: null,
				collectionId: props.params.collectionId,
				clientId:
					props.clientFolder.clients.length > 0
						? props.clientFolder.clients[0].id
						: null,
			}
		},
		query,
	}
)

export default {
	Component: withRouter(ClientViewCloset),
	query,
	params: (rp) => ({
		count: 12,
		cursor: null,
		searchText: null,
		collectionId: rp.params.collectionId,
		clientId:
			rp.clientFolder.clients.length > 0
				? rp.clientFolder.clients[0].id
				: null,
	}),
}
