/**
 * @generated SignedSource<<21cf8bd4bc12d7ff1b2d0ef742930441>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { shopcastCard_currentUser$fragmentType } from "./../../cards/shopcast-card/__generated__/shopcastCard_currentUser.graphql";
import type { shopcastCard_shopcast$fragmentType } from "./../../cards/shopcast-card/__generated__/shopcastCard_shopcast.graphql";
import type { FragmentType } from "relay-runtime";
declare export opaque type listOfLatestShopcasts_store$fragmentType: FragmentType;
export type listOfLatestShopcasts_store$data = {|
  +currentUser: ?{|
    +$fragmentSpreads: shopcastCard_currentUser$fragmentType,
  |},
  +id: string,
  +latestShopcasts: ?{|
    +edges: ?$ReadOnlyArray<?{|
      +cursor: string,
      +node: ?{|
        +id: string,
        +$fragmentSpreads: shopcastCard_shopcast$fragmentType,
      |},
    |}>,
    +pageInfo: {|
      +endCursor: ?string,
      +hasNextPage: boolean,
      +hasPreviousPage: boolean,
      +startCursor: ?string,
    |},
  |},
  +$fragmentType: listOfLatestShopcasts_store$fragmentType,
|};
export type listOfLatestShopcasts_store$key = {
  +$data?: listOfLatestShopcasts_store$data,
  +$fragmentSpreads: listOfLatestShopcasts_store$fragmentType,
  ...
};
*/

var node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": 12,
      "kind": "LocalArgument",
      "name": "count"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "cursor"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "count",
        "cursor": "cursor",
        "direction": "forward",
        "path": [
          "latestShopcasts"
        ]
      }
    ]
  },
  "name": "listOfLatestShopcasts_store",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "currentUser",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "shopcastCard_currentUser"
        }
      ],
      "storageKey": null
    },
    {
      "alias": "latestShopcasts",
      "args": null,
      "concreteType": "LatestShopcastsConnection",
      "kind": "LinkedField",
      "name": "__store_latestShopcasts_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "LatestShopcastsEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cursor",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "Shopcast",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "shopcastCard_shopcast"
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__typename",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PageInfo",
          "kind": "LinkedField",
          "name": "pageInfo",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasPreviousPage",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasNextPage",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "startCursor",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endCursor",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Store",
  "abstractKey": null
};
})();

(node/*: any*/).hash = "a7843ccaca0493f5ef45939871cbfff0";

module.exports = ((node/*: any*/)/*: Fragment<
  listOfLatestShopcasts_store$fragmentType,
  listOfLatestShopcasts_store$data,
>*/);
