import React, { Fragment } from "react"
import { ValueNoneIcon } from "@radix-ui/react-icons"
import Slider from "components/lookbook-dropdown/slider"
import ColorPicker from "./color-picker"
import styles from "./konva-filters.css"

function setShadowOffset(offSet, direction) {
	return {
		x:
			!isNaN(offSet) && !isNaN(direction)
				? offSet * Math.cos(Math.PI * (direction / 180))
				: 0,
		y:
			!isNaN(offSet) && !isNaN(direction)
				? -1 * offSet * Math.sin(Math.PI * (direction / 180))
				: 0,
	}
}
function getAngle({ x, y }) {
	const alpha = x && y ? Math.atan((-1 * y) / x) * (180 / Math.PI) : 0
	if (x < 0 && y < 0) return alpha + 180
	if (x < 0 && y > 0) return alpha - 180
	return alpha
}
function getShadowOffset({ x, y }) {
	return Math.sqrt(x ** 2 + y ** 2)
}

export default function DropShadowFilter({
	shadowColor,
	shadowBlur,
	shadowOffset,
	shadowOpacity,
	handleShadow,
}) {
	const angle = getAngle(shadowOffset)
	const offSet = getShadowOffset(shadowOffset)
	return (
		<Fragment>
			<div className={styles.item}>
				<Slider
					title="Offset"
					min={0}
					max={100}
					step={1}
					isDark
					value={offSet * 2}
					onSlide={({ target }) => {
						const value = parseInt(target.value, 10) / 2
						handleShadow({
							shadowOffset: setShadowOffset(value, angle),
						})
					}}
				/>
			</div>
			<div className={styles.item}>
				<Slider
					title="Direction"
					min={-180}
					max={180}
					step={1}
					isDark
					value={angle}
					onSlide={({ target }) => {
						const value = parseInt(target.value, 10)
						handleShadow({
							shadowOffset: setShadowOffset(offSet, value),
						})
					}}
				/>
			</div>
			<div className={styles.item}>
				<Slider
					title="Blur"
					min={0}
					max={100}
					isDark
					step={1}
					value={shadowBlur / 2}
					onSlide={({ target }) =>
						handleShadow({
							shadowBlur: parseInt(target.value, 10) * 2,
						})
					}
				/>
			</div>
			<div className={styles.item}>
				<Slider
					title="Transparency"
					min={0}
					max={100}
					isDark
					step={1}
					value={shadowOpacity * 100}
					onSlide={({ target }) =>
						handleShadow({
							shadowOpacity: parseFloat(target.value / 100),
						})
					}
				/>
			</div>
			<div className={styles.item}>
				<h4 className={styles.title}>Colour</h4>
				<div className={styles.itemContent}>
					<ColorPicker
						color={shadowColor}
						onChange={(color) =>
							handleShadow({
								shadowColor: color,
							})
						}
					/>
				</div>
			</div>
			<div className={styles.footer}>
				<button
					type="button"
					data-tooltip-id="tooltip"
					data-tooltip-content="Clear"
					className={styles.tool}
					onClick={() =>
						handleShadow({
							shadowColor: "#000000",
							shadowBlur: 0,
							shadowOpacity: 1,
							shadowOffset: { x: 0, y: 0 },
						})
					}
				>
					<ValueNoneIcon className={styles.icon} />
				</button>
			</div>
		</Fragment>
	)
}
