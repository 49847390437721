import React, { Fragment } from "react"
import { Link } from "react-router-dom"
import { Helmet } from "react-helmet"
import { createFragmentContainer, graphql } from "react-relay"
import { toast } from "react-toastify"
import auth from "routes/signup/signup.css"
import textfield from "shared/styles/components/textfield.css"
import form from "shared/styles/components/form.css"
import Logo from "shared/assets/logo.svg"
import Loading from "components/loading/loading.jsx"
import ForgotPasswordMutation from "mutations/user/forgot-password"
import gqlh from "helpers/gql-helper"
import { withRouter } from "../../route-helpers"

class ForgotPassword extends React.Component {
	constructor(props) {
		super(props)
		this.resetPassword = this.resetPassword.bind(this)
		this.handleEmailChange = this.handleEmailChange.bind(this)
		this.handleMutationSuccess = this.handleMutationSuccess.bind(this)
		this.handleMutationFailed = this.handleMutationFailed.bind(this)

		this.state = {
			email: "",
			loading: false,
		}
	}

	handleEmailChange({ target }) {
		this.setState({ email: target.value })
	}

	handleMutationSuccess() {
		const { navigate } = this.props
		toast.success(<Fragment>Check your email inbox</Fragment>, {
			autoClose: 5000,
			closeButton: false,
		})

		navigate("/signin")
		this.setState({ loading: false })
	}

	handleMutationFailed(errors) {
		toast.error(
			<div>
				{gqlh.getError(
					errors,
					"Could not send email. please try again later"
				)}
			</div>,
			{ autoClose: 2000, closeButton: false }
		)
		this.setState({ loading: false })
	}

	resetPassword() {
		const { props, state } = this
		this.setState({ loading: true })
		ForgotPasswordMutation.commit(
			props.relay.environment,
			state.email,
			this.handleMutationSuccess,
			this.handleMutationFailed
		)
	}

	render() {
		const { state } = this
		return (
			<div className={auth.root}>
				<Helmet>
					<title>Forgot your password – ShopShare</title>
					<body className="Auth" />
				</Helmet>
				{state.loading && <Loading />}
				{!state.loading && (
					<Fragment>
						<header className={auth.header}>
							<nav className={auth.navDesktop}>
								<div className={auth.navContainer}>
									<Link to="/">
										<Logo className={auth.logo} alt="logo" />
									</Link>
								</div>
							</nav>
						</header>

						<section className={auth.section}>
							<div className={auth.landing}>
								<div className={auth.content}>
									<div className={auth.card}>
										<div className={auth.cardHeader}>
											<h1 className={auth.title}>
												Forgot password?
											</h1>
										</div>
										<form>
											<div className={form.group}>
												<input
													className={textfield.normal}
													value={state.email}
													placeholder="Email you signed up with"
													onChange={this.handleEmailChange}
												/>
											</div>
											<button
												type="button"
												disabled={!state.email}
												className={auth.btn}
												onClick={this.resetPassword}
											>
												Continue
											</button>
										</form>
									</div>
								</div>
							</div>
						</section>
					</Fragment>
				)}
			</div>
		)
	}
}

ForgotPassword = createFragmentContainer(ForgotPassword, {
	store: graphql`
		fragment forgotPassword_store on Store {
			id
		}
	`,
})

const forgotPasswordQuery = graphql`
	query forgotPasswordQuery {
		store {
			...forgotPassword_store
		}
	}
`

export default { Component: withRouter(ForgotPassword), query: forgotPasswordQuery }
