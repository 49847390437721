import { commitMutation, graphql } from "react-relay"
import { complete } from "../helper.js"

const mutation = graphql`
	mutation toggleLikesMutation($input: ToggleLikesInput!) {
		toggleLikes(input: $input) {
			store {
				id
				shopcast {
					id
				}
			}
		}
	}
`

const commit = (
	env,
	shopcastId,
	currentLikeStatus,
	likeCount,
	onCompleted,
	onError
) =>
	commitMutation(env, {
		mutation,
		variables: { input: { shopcastId } },
		// configs: getConfigs(storeId),
		onCompleted: complete(onCompleted, onError),
		onError: (e) => complete(null, [e]),
		updater: (storeProxy, data) => {
			if (data.toggleLikes) {
				console.log(likeCount)
				const shopcastProxy = storeProxy.get(shopcastId)
				shopcastProxy.setValue(!currentLikeStatus, "hasCurrentUserLikedIt")
				shopcastProxy.setValue(
					currentLikeStatus ? likeCount - 1 : likeCount + 1,
					"likes"
				)
			}
		},
	})

export default { commit }
