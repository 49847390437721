import React, { Fragment } from "react"
import { CameraIcon, Cross2Icon } from "@radix-ui/react-icons"
import Modal from "react-modal"
import UploadFeatureIcon from "shared/assets/feature-icon-upload.svg"
import button from "shared/styles/components/buttons.css"
import form from "shared/styles/components/form.css"
import modal from "shared/styles/components/modal.css"
import textfield from "shared/styles/components/textfield.css"
import file from "shared/styles/components/file.css"
import EmojiTextArea from "components/emoji-textarea/emoji-textarea"
import { useFormik } from "formik"
import * as Yup from "yup"
import card from "shared/styles/components/card.css"
import Dropzone from "react-dropzone"
import { toast } from "react-toastify"
import axios from "axios"
import { io } from "socket.io-client"
import progress from "shared/styles/components/progress.css"
import { commitLocalUpdate, ConnectionHandler } from "relay-runtime"
import connections from "mutations/connections"
import updateShopcastMutation from "mutations/shopcast/update-shopcast"
import PosterUrlMutation from "mutations/shopcast/poster-url"
import LoadingDots from "components/loading-dots/loading-dots"
import CreateShopcastMuatation from "mutations/shopcast/create-shopcast"
import gqlHelper from "helpers/gql-helper"
import { getBaseUrl } from "constants"
import styles from "./shopcast-edit.css"

function ShopcastEdit({ close, categories, shopcast, relay, parentId, team }) {
	const [uploadPercentage, setUploadPercentage] = React.useState(0)
	const [newVideoUrl, setNewVideoUrl] = React.useState(null)
	const validationSchema = {
		title: Yup.string().required("Title is required"),
		description: Yup.string(),
		category: Yup.string().required("Category is required"),
		publicSetting: Yup.string(),
	}
	const initialValues = {
		title: shopcast.title || "",
		description: shopcast.description || "",
		category: shopcast.category ? shopcast.category.id : "",
		publicSetting: shopcast.isPublished ? "published" : "private",
	}
	const formik = useFormik({
		initialValues: !!shopcast.id
			? initialValues
			: {
					...initialValues,
					video: null,
					duration: 0,
			  },
		validationSchema: !!shopcast.id
			? Yup.object().shape(validationSchema)
			: Yup.object().shape({
					...validationSchema,
					video: Yup.mixed().required("Video is required"),
					duration: Yup.number().min(1).required("Duration is required"),
			  }),
		onSubmit: (values) => {
			if (shopcast.id) {
				const data = {
					...values,
					shopcastId: shopcast.id,
				}
				updateShopcastMutation.commit(
					relay.environment,
					data,
					(e) => {
						toast.info(<>{gqlHelper.getError(e)}</>, {
							autoClose: 5000,
							closeButton: false,
						})
						close(false)
					},
					() => {
						close(true)
					}
				)
			} else {
				CreateShopcastMuatation.commit(
					relay.environment,
					{
						title: values.title,
						description: values.description,
						categoryId: values.category,
						isPublished: values.publicSetting === "published",
						duration: values.duration,
						size: values.video.size,
						folderId: parentId,
						teamId: team
					},
					(e) => {
						toast.info(<>{gqlHelper.getError(e)}</>, {
							autoClose: 5000,
							closeButton: false,
						})
					},
					async (data) => {
						const shopcastId = data.createShopcast.shopcast.id
						const filename = `${shopcastId
							.replace("==", "")
							.replace("=", "")}_mux.${values.video.name
							.split(".")
							.pop()}`
						const signedRequestUrl = `${getBaseUrl(
							false
						)}/signedRequest?file-name=${filename}&file-type=${
							values.video.type
						}&sId=${shopcastId}`
						const { data: signedData } = await axios.get(signedRequestUrl)
						await axios.post(
							`${getBaseUrl(false)}/api/shopcasts/${shopcastId}/muxUrl`,
							{ url: signedData.url }
						)
						await new Promise((resolve, reject) => {
							const xhr = new XMLHttpRequest()
							const socketInstance = io(getBaseUrl(false))
							xhr.upload.addEventListener(
								"progress",
								(e) => {
									const completed = (e.loaded / e.total) * 100
									setUploadPercentage(completed)
									socketInstance.emit(
										"set-shopcast-progress",
										shopcastId,
										null,
										completed
									)
									if (completed === 100) {
										socketInstance.emit(
											"set-shopcast-progress",
											shopcastId,
											null,
											"done"
										)
									}
								},
								false
							)
							xhr.onerror = () => {
								reject(new Error("Could not upload file to S3"))
							}
							xhr.onload = (e) => {
								if (xhr.status === 200) {
									setUploadPercentage(0)
									resolve(e)
								} else {
									reject(new Error("Could not upload file to S3"))
								}
							}
							xhr.open("PUT", signedData.signedRequest)
							const formData = new FormData()
							formData.append("file", values.video)
							xhr.send(values.video)
						})
						await axios.post(
							`${getBaseUrl(false)}/api/recording/transcode`,
							{ id: shopcastId }
						)
						commitLocalUpdate(relay.environment, (storeProxy) => {
							const shopcastProxy = storeProxy.get(shopcastId)
							const parent = storeProxy.get(parentId)
							const args = { search: "", teamId: team }
							parent.setValue(
								parent.getValue("shopcastsCount", args) + 1,
								"shopcastsCount",
								args
							)
							const connection = ConnectionHandler.getConnection(
								parent,
								connections.currentUser.shopcastManager.items.name,
								{
									...connections.currentUser.shopcastManager.items.data,
									type: "shopcast"
								}
							)
							const newShopcast = storeProxy.create(
								`Shopcast:connection:${new Date().getTime()}`,
								"Shopcast"
							)
							newShopcast.setLinkedRecord(shopcastProxy, "node")
							if (connection)
								ConnectionHandler.insertEdgeBefore(
									connection,
									newShopcast
								)
						})
						close(true)
					}
				)
			}
		},
	})

	const renderPosterUpload = () => (
		<Dropzone
			multiple={false}
			onDrop={(acceptedFiles) => {
				const fileObject = acceptedFiles[0]
				PosterUrlMutation.commit(
					relay.environment,
					fileObject,
					shopcast.id,
					() => {},
					() => {}
				)
			}}
		>
			{({ getRootProps, getInputProps }) => (
				<div className={styles.fileDrop}>
					<div {...getRootProps()} className={styles.dropIcon}>
						<input {...getInputProps()} />
						<CameraIcon className={styles.icon18} />
					</div>
				</div>
			)}
		</Dropzone>
	)

	const renderVideoUpload = () => (
		<Dropzone
			multiple={false}
			onDrop={(acceptedFiles) => {
				const fileObject = acceptedFiles[0] || null
				formik.setFieldValue("video", fileObject)
				if (fileObject) {
					setNewVideoUrl(URL.createObjectURL(fileObject))
					formik.setFieldValue("duration", 0)
				}
			}}
			accept="video/*"
		>
			{({ getRootProps, getInputProps }) => (
				<div className={file.root}>
					<div {...getRootProps()} className={file.dropRect}>
						<input {...getInputProps()} />
						<UploadFeatureIcon className={file.iconLg} />
						<p className={file.label}>
							<b>Click to upload</b> or drag and drop
						</p>
					</div>
				</div>
			)}
		</Dropzone>
	)

	const renderButtonLabel = (text, loadingColor) =>
		uploadPercentage > 0 ? <LoadingDots color={loadingColor} /> : text

	return (
		<Modal
			className={modal.root}
			overlayClassName={modal.overlay}
			htmlOpenClassName="ReactModal__Html--open"
			isOpen
			ariaHideApp={false}
		>
			<div
				className={`${modal.dialog} ${modal.dialogCentered} ${modal.dialogFullscreenSmDown} ${modal.dialogLg}`}
			>
				<div className={modal.content}>
					{uploadPercentage > 0 && (
						<div className={styles.progress}>
							<progress
								className={progress.bar}
								max={100}
								value={uploadPercentage}
							/>
							<h4 className={progress.status}>
								{Math.round(uploadPercentage)}% Complete
							</h4>
						</div>
					)}
					<div className={`${modal.header}`}>
						<h4 className={modal.title}>
							{shopcast.id ? "Edit Shopcast" : "New Shopcast"}
						</h4>
						<button
							type="button"
							className={button.close}
							onClick={close}
						>
							<Cross2Icon className={button.closeIcon} />
						</button>
					</div>
					<div className={modal.body}>
						<div className={styles.row}>
							<div className={form.group}>
								{shopcast.id ? (
									<Fragment>
										<label className={form.label}>Video Cover</label>
										<div className={styles.figure}>
											<div className={card.inner}>
												{renderPosterUpload()}
												{shopcast.posterUrl && (
													<img
														className={card.poster}
														src={`${shopcast.posterUrl}`}
														alt="Poster"
													/>
												)}
											</div>
										</div>
										{/* <p className={form.help}>
											Recommended image size: 1280 x 720px
										</p> */}
									</Fragment>
								) : (
									<Fragment>
										{newVideoUrl && (
											<div className={styles.content}>
												<video
													preload="metadata"
													className={styles.source}
													src={newVideoUrl}
													controls
													onLoadedMetadata={(e) => {
														formik.setFieldValue(
															"duration",
															e.target.duration
														)
													}}
												/>
											</div>
										)}
										{renderVideoUpload()}
										{formik.touched.video && formik.errors?.video && (
											<div className={form.validationFeedback}>
												{formik.errors.video}
											</div>
										)}
									</Fragment>
								)}
							</div>
							<div>
								<div
									className={`${form.group} ${
										formik.touched.title && formik.errors?.title
											? form.validationError
											: null
									}`}
								>
									<label className={form.label}>Title</label>
									<input
										type="text"
										className={textfield.normal}
										name="title"
										placeholder="Give it a punchy title!"
										value={formik.values.title}
										onChange={formik.handleChange}
										onBlur={formik.handleBlur}
									/>
									{formik.touched.title && formik.errors?.title && (
										<div className={form.validationFeedback}>
											{formik.errors.title}
										</div>
									)}
								</div>
								<div className={form.group}>
									<label className={form.label}>Description</label>
									<EmojiTextArea
										className={textfield.textarea}
										rows={6}
										name="description"
										value={formik.values.description}
										onChange={formik.handleChange}
									/>
								</div>
								{!!categories && (
									<div
										className={`${form.group} ${
											formik.touched.category &&
											formik.errors?.category
												? form.validationError
												: null
										}`}
									>
										<label className={form.label}>Category</label>
										<select
											className={textfield.normal}
											name="category"
											value={formik.values.category}
											onChange={formik.handleChange}
										>
											<option value="">Uncategorized</option>
											{categories.map((c) => (
												<option value={c.id} key={c.id}>
													{c.name}
												</option>
											))}
										</select>
										{formik.touched.category &&
											formik.errors?.category && (
												<div className={form.validationFeedback}>
													{formik.errors.category}
												</div>
											)}{" "}
									</div>
								)}
								<div className={form.group}>
									<label className={form.label}>Public Setting</label>
									<select
										className={textfield.normal}
										name="publicSetting"
										value={formik.values.publicSetting}
										onChange={formik.handleChange}
										disabled={shopcast.isFlagged}
									>
										<option value="published">Published</option>
										<option value="private">Private</option>
									</select>
								</div>
							</div>
						</div>
					</div>
					<div className={modal.footer}>
						<button type="button" className={button.link} onClick={close}>
							{renderButtonLabel("Cancel", "var(--gray300)")}
						</button>
						<button
							type="submit"
							className={button.primary}
							onClick={() => {
								formik.submitForm()
							}}
						>
							{renderButtonLabel("Submit", "var(--primary80)")}
						</button>
					</div>
				</div>
			</div>
		</Modal>
	)
}

export default ShopcastEdit
