/**
 * @generated SignedSource<<a8995ded19723be9ce834a57db5660d3>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentType } from "relay-runtime";
declare export opaque type fileCard_shopshareFile$fragmentType: FragmentType;
export type fileCard_shopshareFile$data = {|
  +id: string,
  +inRoot: ?boolean,
  +isArchived: ?boolean,
  +isPinned: ?boolean,
  +isPublished: ?boolean,
  +thumbnailUrl: ?string,
  +title: ?string,
  +url: ?string,
  +user: ?{|
    +canEdit: ?boolean,
    +fullname: ?string,
    +id: string,
    +profilePhoto: ?string,
    +username: ?string,
  |},
  +$fragmentType: fileCard_shopshareFile$fragmentType,
|};
export type fileCard_shopshareFile$key = {
  +$data?: fileCard_shopshareFile$data,
  +$fragmentSpreads: fileCard_shopshareFile$fragmentType,
  ...
};
*/

var node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "fileCard_shopshareFile",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "url",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "thumbnailUrl",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isPublished",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isArchived",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isPinned",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "inRoot",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "user",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "fullname",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "username",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "profilePhoto",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "canEdit",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ShopshareFile",
  "abstractKey": null
};
})();

(node/*: any*/).hash = "702916809599a089e65a5446d73a0fd1";

module.exports = ((node/*: any*/)/*: Fragment<
  fileCard_shopshareFile$fragmentType,
  fileCard_shopshareFile$data,
>*/);
