/* eslint-disable jsx-a11y/accessible-emoji */
import React, { Fragment, Component } from "react"
import { ValueNoneIcon, PlusIcon } from "@radix-ui/react-icons"
import { includes } from "lodash"
import { createPaginationContainer, graphql } from "react-relay"
import InfiniteScroll from "react-infinite-scroller"
import FavouriteList from "components/lookbook/favourite-list"
import BgColorCard from "components/cards/lookbook/bg-color-card"
import BgImageCard from "components/cards/lookbook/bg-image-card"
import Slider from "components/lookbook-dropdown/slider"
import ColorPicker from "components/lookbook-dropdown/color-picker"
import BackgroundUpload from "components/modals/background-upload/background-upload"
import LookbookDropdown from "components/lookbook-dropdown/lookbook-dropdown"
import BackgroundColorUpload from "components/modals/background-color-upload/background-color-upload"
import LoadingDots from "components/loading-dots/loading-dots"
import ConformationPrompt from "components/modals/conformation-prompt/conformation-prompt"
import deleteLookbookBackgroundMutation from "mutations/lookbook/delete-lookbook-background"
import { createGuid, toTitleCase } from "helpers/string-helper"
import { clickFavourite } from "helpers/lookbook-helper"
import TransIcon from "shared/assets/transparent-icon.svg"
import lookbookAside from "shared/styles/layout/lookbook-aside.css"
import grids from "shared/styles/components/grids.css"
import slider from "shared/styles/components/slider.css"
import icon from "shared/styles/components/icons.css"
import textfield from "shared/styles/components/textfield.css"
import button from "shared/styles/components/buttons.css"
import styles from "./background-list.css"
import { ROLES } from "../../constants"
import { getComponent } from "../../route-helpers"

class BackgroundList extends Component {
	constructor(props) {
		super(props)
		this.state = {
			color: "#86E9D4",
			scale: 1,
			opacity: 1,
			type: 1,
			loading: true,
			uploading: false,
			openModal: false,
			deleteModal: false,
			selected: null,
			openColorModal: false,
		}
		this.dataLength = 3
		this.fetchData = this.fetchData.bind(this)
		this.renderCategorySelect = this.renderCategorySelect.bind(this)
		this.renderAdmin = this.renderAdmin.bind(this)
		this.editProduct = this.editProduct.bind(this)
		this.editImage = this.editImage.bind(this)
	}

	componentDidMount() {
		const { selectedColor, backgroundColor } = this.props
		this.setState({ color: backgroundColor || selectedColor })
	}

	componentDidUpdate() {
		const { background } = this.props
		const { scale, loading, opacity } = this.state
		if (
			background &&
			background.fillPatternScale &&
			scale !== background.fillPatternScale &&
			background.opacity &&
			opacity !== background.opacity &&
			loading
		) {
			// eslint-disable-next-line react/no-did-update-set-state
			this.setState({
				type: background.fillPatternScale === 1 ? 1 : 0.25,
				scale: parseFloat(background.fillPatternScale) || 1,
				opacity: parseFloat(background.opacity) || 1,
				loading: false,
			})
		}
	}

	fetchData() {
		const { relay } = this.props
		relay.loadMore(20)
	}

	editProduct(obj) {
		this.setState({
			selected: obj,
			openColorModal: true,
		})
	}

	editImage(img) {
		this.setState({
			selected: img,
			openModal: true,
		})
	}

	renderCategorySelect(hasfavourites) {
		const {
			onKeywordChange,
			category,
			store,
			sub_category: subCategory,
		} = this.props
		const categories =
			store && store.currentUser && store.currentUser.bgCategories
				? store.currentUser.bgCategories
				: []
		return (
			<select
				className={textfield.normal}
				value={subCategory}
				onChange={onKeywordChange}
			>
				<option value="">
					All {category === "image" ? "Images" : "Colours"}
				</option>
				<option disabled>─</option>
				{hasfavourites && (
					<Fragment>
						<option value="favourites"> ❤️ My Favourites</option>
						<option disabled>─</option>
					</Fragment>
				)}
				{categories.map((cat) => (
					<option key={cat} value={cat}>
						{toTitleCase(cat)}
					</option>
				))}
			</select>
		)
	}

	renderAdmin() {
		const { category, accepted, store, relay } = this.props
		const {
			uploading,
			openModal,
			deleteModal,
			selected,
			openColorModal,
		} = this.state
		const collectionList = store.currentUser.bgCategories
		let upload = ""
		if (category === "image") {
			upload = (
				<button
					type="button"
					className={button.dropdownIconLg}
					onClick={() => this.setState({ openModal: true })}
				>
					<PlusIcon className={icon.icon21} />
				</button>
			)
		}
		if (category === "color") {
			upload = (
				<button
					type="button"
					className={button.dropdownIconLg}
					onClick={() => this.setState({ openColorModal: true })}
				>
					<PlusIcon className={icon.icon21} />
				</button>
			)
		}
		return (
			<Fragment>
				{upload}
				<BackgroundUpload
					subCategoryList={
						collectionList && Array.isArray(collectionList)
							? collectionList.map((x) => ({
									id: `new_${createGuid(4)}`,
									value: x,
							  }))
							: []
					}
					openModal={openModal}
					adminAccess
					relay={relay}
					accepted={accepted}
					selected={selected}
					closeModal={(count) => {
						if (count !== false)
							relay.refetchConnection(
								this.dataLength + count,
								() => {},
								{ bgType: category }
							)
						this.setState({ openModal: false, selected: null })
					}}
				/>
				<BackgroundColorUpload
					subCategoryList={
						collectionList && Array.isArray(collectionList)
							? collectionList.map((x) => ({
									id: `new_${createGuid(4)}`,
									value: x,
							  }))
							: []
					}
					openModal={openColorModal}
					adminAccess
					relay={relay}
					accepted={accepted}
					selected={selected}
					closeModal={(count) => {
						if (count !== false)
							relay.refetchConnection(
								this.dataLength + count,
								() => {},
								{ bgType: category }
							)
						this.setState({ openColorModal: false, selected: null })
					}}
				/>
				<ConformationPrompt
					openModal={deleteModal}
					title="Are you sure?"
					closeModal={() => {
						this.setState({ deleteModal: false, selected: null })
					}}
					approveEvent={() => {
						this.setState({ uploading: true })
						deleteLookbookBackgroundMutation.commit(
							relay.environment,
							{
								id: selected.id,
							},
							() => {
								relay.refetchConnection(this.dataLength - 1, () => {}, {
									bgType: category,
								})
								this.setState({
									uploading: false,
									deleteModal: false,
									selected: null,
								})
							},
							() => {}
						)
					}}
					declineEvent={() => {
						this.setState({
							uploading: false,
							deleteModal: false,
							selected: null,
						})
					}}
					approveText="Delete"
					approveLoadingColor="var(--red300)"
					approveButtonStyle={button.primaryRed}
					declineText="Cancel"
					loading={uploading}
				>
					Do you want to permanently delete &quot;
					{selected ? selected.name : ""}&quot;? This action cannot be
					undone.
				</ConformationPrompt>
			</Fragment>
		)
	}

	render() {
		const defaultWhite = "#FFFFFF"
		const defaultBlack = "#000000"
		const data = []
		const {
			background,
			backgroundColor,
			backgroundImage,
			onClick,
			onChange,
			relay,
			store,
			category,
			loading: mainLoading,
			favouriteListOpen,
			team,
		} = this.props
		const { color, scale, opacity, type } = this.state
		if (mainLoading || !(store && store.currentUser)) {
			return <LoadingDots />
		}
		const isAdmin =
			store.currentUser &&
			[ROLES.ADMIN, ROLES.TEAM].includes(store.currentUser.role)
		const bgImages = store.currentUser.backgrounds
			? store.currentUser.backgrounds.edges.map((x) => ({
					...x.node,
			  }))
			: []
		const hasfavourites =
			(store.currentUser.lookbookFavourites
				? store.currentUser.lookbookFavourites.edges
				: []
			).map((obj) => obj.node).length > 0 ||
			bgImages.filter((obj) => obj.isFavourite === true).length > 0
		bgImages.forEach((obj) => {
			if (
				includes(
					data.map((x) => x.category.toUpperCase()),
					obj.category.toUpperCase()
				)
			) {
				// if (
				// 	!includes(
				// 		this.data
				// 			.find((x) => x.category === obj.category)
				// 			.imgList.map((x) => x.id),
				// 		obj.id
				// 	)
				// ) {
				data
					.find((x) => x.category === obj.category)
					.imgList.push({
						id: obj.id,
						canEdit: obj.canEdit,
						name: obj.title,
						url: obj.background,
						category: [{ id: obj.id, value: obj.category }],
						type: obj.type,
						isFavourite: obj.isFavourite,
					})
				// eslint-disable-next-line no-plusplus
				++this.dataLength
				// }
			} else {
				data.push({
					category: obj.category,
					imgList: [
						{
							id: obj.id,
							canEdit: obj.canEdit,
							name: obj.title,
							url: obj.background,
							category: [{ id: obj.id, value: obj.category }],
							type: obj.type,
							isFavourite: obj.isFavourite,
						},
					],
				})
				// eslint-disable-next-line no-plusplus
				++this.dataLength
			}
		})
		const FavouriteListComp = getComponent(
			`fav_list_background`,
			FavouriteList,
			{
				...FavouriteList.params,
				category,
				teamId: team,
			},
			{
				favouriteListOpen,
				category,
				backgroundColor,
				backgroundImage,
				scale,
				opacity,
				background,
				onClick,
				isAdmin,
				editProduct: this.editProduct,
				editImage: this.editImage,
			}
		)
		return (
			<Fragment>
				<div className={lookbookAside.header}>
					<div className={lookbookAside.headerActions}>
						{this.renderCategorySelect(hasfavourites)}
						{isAdmin && this.renderAdmin()}
					</div>
				</div>
				{/* Controllers */}
				<div className={lookbookAside.controls}>
					{category === "color" && (
						<ColorPicker
							disableAlpha
							color={color || backgroundColor}
							onChange={(colorValue) => {
								this.setState({
									color: colorValue,
								})
								onClick({
									...background,
									fill: colorValue,
								})
							}}
							onChangeComplete={(colorValue) => {
								onChange(colorValue)
								// this.setState({
								// 	color: colorValue,
								// })
								// onClick(prevColor)
							}}
						/>
					)}

					{category === "image" &&
						background &&
						background.fillPatternImage && (
							<Fragment>
								<select
									value={type}
									className={textfield.normal}
									onChange={({ target }) => {
										this.setState({
											scale: parseFloat(target.value),
											type: parseFloat(target.value),
										})
										onClick({
											fill: background.fill,
											fillPatternImage: background.fillPatternImage,
											fillurl: background.fillurl,
											fillPatternScale: parseFloat(target.value),
											opacity: background.opacity,
										})
									}}
								>
									<option value={1}>Fill</option>
									<option value={0.25}>Tile</option>
								</select>
								{type === 0.25 && (
									<div className={styles.slider}>
										<div className={slider.root}>
											<input
												className={slider.slider}
												value={scale}
												step={0.01}
												type="range"
												min="0.1"
												max="2"
												onChange={({ target }) => {
													this.setState({ scale: target.value })
													onClick({
														fill: background.fill,
														fillPatternImage:
															background.fillPatternImage,
														fillurl: background.fillurl,
														fillPatternScale: target.value,
														opacity: background.opacity,
													})
												}}
											/>
											<div className={slider.append}>
												{(scale * 100).toFixed(0)}%
											</div>
										</div>
									</div>
								)}

								<div className={lookbookAside.tools}>
									<LookbookDropdown
										label={<TransIcon className={styles.icon} />}
										data-tooltip-id="tooltip"
										contentRight
										data-tooltip-content="Transparency"
									>
										<Slider
											min={0}
											max={100}
											step={1}
											wShadow
											value={opacity * 100}
											onSlide={({ target }) => {
												onClick({
													fill: background.fill,
													fillPatternImage:
														background.fillPatternImage,
													fillurl: background.fillurl,
													fillPatternScale:
														background.fillPatternScale,
													opacity: parseFloat(target.value / 100),
												})
												this.setState({
													opacity: parseFloat(target.value / 100),
												})
											}}
										/>
									</LookbookDropdown>
									<div className={styles.dividerVert} />
									<button
										type="button"
										data-tooltip-id="tooltip"
										data-tooltip-content="Clear Fill"
										className={styles.tool}
										onClick={() =>
											onClick({
												fill: background.fill,
												fillPatternImage: null,
												fillurl: null,
												fillPatternScale: 1,
												opacity: 1,
											})
										}
									>
										<ValueNoneIcon className={styles.icon} />
									</button>
								</div>
							</Fragment>
						)}
				</div>
				{favouriteListOpen && hasfavourites ? (
					FavouriteListComp
				) : (
					<InfiniteScroll
						loadMore={this.fetchData}
						hasMore={relay ? relay.hasMore() : false}
						loader={<LoadingDots key={0} />}
						pageStart={0}
						useWindow={false}
					>
						{category === "color" && (
							<div
								className={lookbookAside.content}
								id="board_colorList"
							>
								{/* <div className={lookbookAside.sectionHeader}>
									<h4 className={lookbookAside.heading}>Base</h4>
								</div> */}
								<div className={grids.bgImageTile}>
									<BgColorCard
										color={defaultWhite}
										hasClear
										onClick={() => {
											onClick({
												fill: defaultWhite,
												fillPatternImage:
													background.fillPatternImage,
												fillurl: background.fillurl,
												fillPatternScale:
													background.fillPatternScale,
												opacity: background.opacity,
											})
										}}
									/>
									<BgColorCard
										color={color.toUpperCase()}
										isSelected={backgroundColor === color}
										onClick={() => {
											onClick({
												fill:
													backgroundColor === color ? null : color,
												fillPatternImage:
													background.fillPatternImage,
												fillurl: background.fillurl,
												fillPatternScale:
													background.fillPatternScale,
												opacity: background.opacity,
											})
										}}
									/>
									<BgColorCard
										color={defaultBlack}
										isSelected={backgroundColor === defaultBlack}
										onClick={() => {
											onClick({
												fill:
													backgroundColor === defaultBlack
														? null
														: defaultBlack,
												fillPatternImage:
													background.fillPatternImage,
												fillurl: background.fillurl,
												fillPatternScale:
													background.fillPatternScale,
												opacity: background.opacity,
											})
										}}
									/>
								</div>

								{data.map((bgCategory, x) => (
									// eslint-disable-next-line react/no-array-index-key
									<Fragment key={x}>
										<div className={lookbookAside.sectionHeader}>
											<h4 className={lookbookAside.heading}>
												{bgCategory.category}
											</h4>
										</div>
										<div className={grids.bgImageTile}>
											{bgCategory.imgList.map((obj) => {
												const isSelected =
													backgroundColor === obj.url
												return (
													<BgColorCard
														key={obj.id}
														id={obj.id}
														color={obj.url}
														isSelected={isSelected}
														isAdmin={isAdmin}
														isFavourite={obj.isFavourite}
														showFavouriteButton
														onClick={() => {
															onClick({
																fill: isSelected
																	? null
																	: obj.url,
																fillPatternImage:
																	background.fillPatternImage,
																fillurl: background.fillurl,
																fillPatternScale:
																	background.fillPatternScale,
																opacity: background.opacity,
															})
														}}
														editEvent={() =>
															this.setState({
																selected: obj,
																openColorModal: true,
															})
														}
														deleteEvent={() =>
															this.setState({
																selected: obj,
																deleteModal: true,
															})
														}
														clickFavourite={() =>
															clickFavourite(
																obj.id,
																"color",
																team,
																relay
															)
														}
													/>
												)
											})}
										</div>
									</Fragment>
								))}
							</div>
						)}

						{category === "image" && (
							<div
								className={lookbookAside.content}
								id="board_imageList"
							>
								{data.map((bgCategory, x) => (
									// eslint-disable-next-line react/no-array-index-key
									<Fragment key={x}>
										<div className={lookbookAside.sectionHeader}>
											<h4 className={lookbookAside.heading}>
												{bgCategory.category}
											</h4>
										</div>
										<div className={grids.bgImageTile}>
											{bgCategory.imgList.map((img) => {
												const isSelected =
													backgroundImage === img.url
												return (
													<BgImageCard
														key={img.id}
														category={bgCategory.category}
														name={img.name}
														scale={scale}
														opacity={opacity}
														url={img.url}
														isSelected={isSelected}
														fill={background.fill}
														onClick={onClick}
														canEdit={img.canEdit}
														editImage={() =>
															this.setState({
																selected: img,
																openModal: true,
															})
														}
														deleteImage={() =>
															this.setState({
																selected: img,
																deleteModal: true,
															})
														}
														isFavourite={img.isFavourite}
														showFavouriteButton
														clickFavourite={() =>
															clickFavourite(
																img.id,
																"image",
																team,
																relay
															)
														}
													/>
												)
											})}
										</div>
									</Fragment>
								))}
							</div>
						)}
					</InfiniteScroll>
				)}
			</Fragment>
		)
	}
}

const query = graphql`
	query backgroundListQuery(
		$count: Int!
		$cursor: String
		$bgType: String
		$sub_category: String
		$teamId: String
	) {
		store {
			...backgroundList_store
				@arguments(
					count: $count
					cursor: $cursor
					bgType: $bgType
					subCategory: $sub_category
					teamId: $teamId
				)
		}
	}
`

BackgroundList = createPaginationContainer(
	BackgroundList,
	{
		store: graphql`
			fragment backgroundList_store on Store
			@argumentDefinitions(
				count: { type: "Int", defaultValue: 60 }
				cursor: { type: "String" }
				bgType: { type: "String", defaultValue: "color" }
				subCategory: { type: "String", defaultValue: "" }
				teamId: { type: "String" }
			) {
				id
				currentUser {
					adminAccess
					role
					bgCategories(bgType: $bgType)
					backgrounds(
						bgType: $bgType
						subCategory: $subCategory
						first: $count
						after: $cursor
						teamId: $teamId
					) @connection(key: "currentUser_backgrounds") {
						edges {
							node {
								id
								canEdit
								title
								category
								background
								isFavourite
								type
								isFavourite
							}
						}
					}
					lookbookFavourites(category: $bgType, teamId: $teamId) {
						edges {
							node {
								__typename
								... on Background {
									id
									isFavourite
								}
							}
						}
					}
				}
			}
		`,
	},
	{
		direction: "forward",
		getConnectionFromProps(props) {
			const { currentUser } = props.store
			return currentUser && currentUser.backgrounds
		},
		getFragmentVariables(prevVars, totalCount) {
			return {
				...prevVars,
				count: totalCount,
			}
		},
		getVariables(props, { count, cursor }) {
			return {
				count,
				cursor,
				sub_category: props.sub_category,
				bgType: props.category,
				teamId: props.team,
			}
		},
		query,
	}
)

export default {
	Component: BackgroundList,
	query,
	params: {
		count: 12,
		cursor: null,
		sub_category: null,
		category: null,
	},
	showLoading: true,
}
