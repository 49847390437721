import React, { PureComponent } from "react"
import { createFragmentContainer, graphql } from "react-relay"
import {
	FeatureAvailableComponent,
	FeatureKeyENUM,
} from "services/plan-services"
import UpdatePersonalInfo from "./update-personal-info"
import UpdateUsername from "./update-username"
import ChangePassword from "./change-password"
import Payments from "./payments.jsx"

class Personal extends PureComponent {
	render() {
		const { store, relay } = this.props
		return (
			<>
				<UpdatePersonalInfo currentUser={store.currentUser} store={store} />
				{store.currentUser.type !== "CLIENT" && (
					<UpdateUsername currentUser={store.currentUser} store={store} />
				)}
				{/* <UpdateCustomDomain
					currentUser={store.currentUser}
					store={store}
				/> */}
				<ChangePassword storeId={store.id} relay={relay} />
				<FeatureAvailableComponent
					featureKey={FeatureKeyENUM.EARN_COMMISSION}
					env={relay.environment}
				>
					<Payments store={store} relay={relay} />
				</FeatureAvailableComponent>
			</>
		)
	}
}

Personal = createFragmentContainer(Personal, {
	store: graphql`
		fragment personal_store on Store {
			id
			currentUser {
				...updatePersonalInfo_currentUser
				...updateUsername_currentUser
				...updateCustomDomain_currentUser
				type
				optOutAffiliate
			}
			pricingPlans {
				id
				amount
				currency
				nickname
				monthly
				descriptionList
				metadata {
					name
					value
				}
			}
		}
	`,
})

export default Personal
