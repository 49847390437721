import React, { useState, Fragment, useEffect } from "react"
import { ResetIcon } from "@radix-ui/react-icons"
import { toast } from "react-toastify"
import WidgetPlayer from "components/widget-player/widget-player"
import ConformationPrompt from "components/modals/conformation-prompt/conformation-prompt"
import Recorder from "components/recorder/recorder"
import ShowHideButton from "components/buttons/show-hide-button"
import DeleteButton from "components/buttons/delete-button"
import InfiniteLoading from "components/infinite-loading/infinite-loading"
import setLookbookVideoUrlMutation from "mutations/lookbook/set-lookbook-video-url"
import deleteLookbookVideoMutation from "mutations/lookbook/delete-lookbook-video"
import gql from "helpers/gql-helper"
import button from "shared/styles/components/buttons.css"
import styles from "./lookbook-video-container.css"
import { getMediaSrc } from "../../helpers/lookbook-helper"

function LookbookVideoContainer({
	videoUrl,
	mp4Url,
	subtitle,
	boardId,
	relay,
	showVideo,
	changeLookbook,
	lookbookId,
	savingComplete,
	menuDisability,
	transcoding,
	changeTranscodingStatus,
}) {
	const [openConfirm, changeOpenConfirm] = useState(false)
	const [deleteConfirm, changeDeleteConfirm] = useState(false)
	const [recorderOpen, changeRecorderStatus] = useState(false)

	useEffect(() => {
		changeRecorderStatus(false)
	}, [boardId])

	useEffect(() => {
		if (!videoUrl && !recorderOpen) {
			changeRecorderStatus(true)
		}
		if (videoUrl && recorderOpen !== "force" && !openConfirm) {
			changeRecorderStatus(false)
		}
	}, [recorderOpen, openConfirm, boardId, videoUrl])

	function toggleShow() {
		setLookbookVideoUrlMutation.commit(
			relay.environment,
			boardId,
			null,
			!showVideo,
			({ saveLookbookVideo }) => {
				changeLookbook({ showVideo: saveLookbookVideo.show_video })
				savingComplete()
			},
			(e) => {
				toast.info(<Fragment>{gql.getError(e)}</Fragment>, {
					autoClose: 5000,
					closeButton: false,
				})
			}
		)
	}

	function deleteVideo() {
		deleteLookbookVideoMutation.commit(
			relay.environment,
			boardId,
			() => {
				changeLookbook({ showVideo: false, videoUrl: null, mp4Url: null })
				changeRecorderStatus("force")
				savingComplete()
			},
			(e) => {
				toast.info(<Fragment>{gql.getError(e)}</Fragment>, {
					autoClose: 5000,
					closeButton: false,
				})
			}
		)
	}

	function closeConfirmModal() {
		changeOpenConfirm(false)
		changeDeleteConfirm(false)
	}

	if (transcoding) {
		return <InfiniteLoading type="simple" theme="dark" />
	}
	const videoUrls = []
	if (mp4Url) videoUrls.push(getMediaSrc(mp4Url))
	if (videoUrl) videoUrls.push(getMediaSrc(videoUrl))
	if (recorderOpen) {
		return (
			<Recorder
				recorderOpen={recorderOpen}
				closeRecoder={() => changeRecorderStatus(false)}
				boardId={boardId}
				lookbookId={lookbookId}
				relay={relay}
				setLookbookVideo={(url, changedBoardId) => {
					changeLookbook(
						{ videoUrl: url, mp4Url: url, showVideo: true },
						undefined,
						changedBoardId
					)
					savingComplete()
				}}
				videoUrl={videoUrls}
				menuDisability={menuDisability}
				changeTranscodingStatus={changeTranscodingStatus}
			/>
		)
	}
	if (videoUrl || mp4Url) {
		return (
			<Fragment>
				<div className={styles.root}>
					<div className={styles.header}>
						<button
							type="button"
							data-tooltip-id="tooltip"
							data-tooltip-content="Re-record"
							data-tooltip-place="right"
							onClick={() => changeOpenConfirm(true)}
							className={styles.btn}
						>
							<ResetIcon className={styles.icon} />
						</button>
						<div className={styles.controlsRight}>
							<ShowHideButton
								show={showVideo}
								dark
								dataTip={showVideo ? "Show" : "Hide"}
								onClick={() => toggleShow()}
							/>
							{/*	<div className={styles.dividerVertDark} /> */}
							<DeleteButton
								show={showVideo}
								dataTip="Delete"
								dark
								onClick={() => changeDeleteConfirm(true)}
							/>
						</div>
					</div>
					<div className={styles.content}>
						<div className={styles.preview}>
							<WidgetPlayer
								rounded
								autoPlay={false}
								muxedUrl={videoUrls}
								subtitle={subtitle}
							/>
						</div>
					</div>
				</div>
				<ConformationPrompt
					openModal={openConfirm || deleteConfirm}
					title="Are you sure?"
					closeModal={() => closeConfirmModal()}
					approveEvent={() => {
						if (openConfirm) changeRecorderStatus("force")
						if (deleteConfirm) deleteVideo()
						closeConfirmModal()
					}}
					declineEvent={() => closeConfirmModal()}
					approveText={openConfirm ? "Yes, Start Again" : "Delete"}
					approveButtonStyle={button.primaryRed}
					declineText="Cancel"
				>
					Do you want to permanently delete this recording
					{openConfirm ? " and start again?" : "."}
				</ConformationPrompt>
			</Fragment>
		)
	}
	return <div className={styles.root}>No video</div>
}
export default LookbookVideoContainer
