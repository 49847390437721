import React from "react"
import Modal from "react-modal"
import modal from "shared/styles/components/modal.css"
import buttons from "shared/styles/components/buttons.css"
import form from "shared/styles/components/form.css"
import textfield from "shared/styles/components/textfield.css"
import { Cross2Icon } from "@radix-ui/react-icons"
import { useFormik } from "formik"
import * as Yup from "yup"

export default function CreateFolder({ closeModal, onSubmit, isClient }) {
	const formik = useFormik({
		initialValues: {
			title: "",
		},
		validationSchema: Yup.object({
			title: Yup.string().required("Title is required"),
		}),
		onSubmit: (values) => {
			onSubmit(values.title)
		},
	})
	const type = isClient ? "Lookbook" : "Folder"
	return (
		<Modal
			className={modal.root}
			overlayClassName={modal.overlay}
			isOpen
			ariaHideApp={false}
		>
			<div className={`${modal.dialog} ${modal.dialogCentered}`}>
				<div className={`${modal.content}`}>
					<div className={modal.header}>
						<h4 className={modal.title}>New {type}</h4>
						<button
							type="button"
							className={buttons.close}
							onClick={() => {
								closeModal()
							}}
						>
							<Cross2Icon className={buttons.closeIcon} />
						</button>
					</div>
					<div className={modal.body}>
						{formik.submitCount > 0 &&
							Object.keys(formik.errors).length > 0 && (
								<div className={form.error}>
									{Object.values(formik.errors).map((i) => (
										<div key={i}>{i}</div>
									))}
								</div>
							)}
						<div className={form.group}>
							<label className={form.label}>{type} Name</label>
							<input
								className={textfield.normal}
								name="title"
								value={formik.values.title}
								placeholder={`Name your ${type}`}
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
							/>
						</div>
					</div>
					<div className={modal.footer}>
						<button
							type="button"
							className={buttons.link}
							onClick={() => {
								closeModal()
							}}
						>
							Cancel
						</button>
						<button
							type="submit"
							className={buttons.primary}
							onClick={formik.handleSubmit}
						>
							Save
						</button>
					</div>
				</div>
			</div>
		</Modal>
	)
}
