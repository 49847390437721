import { commitMutation, graphql } from "react-relay"
import { complete } from "../helper.js"

const mutation = graphql`
	mutation signinMutation($input: SigninInput!) {
		signin(input: $input) {
			user {
				id
				token
				username
				fullname
				email
				user_hash
				type
				userPlan {
					id
				}
				teams {
					id
				}
			}
		}
	}
`

const commit = (env, username, password, storeId, onCompleted, onError) =>
	commitMutation(env, {
		mutation,
		updater: (storeProxy, data) => {
			try {
				const signin = storeProxy.getRootField("signin")
				const newUser = signin.getLinkedRecord("user")

				const store = storeProxy.get(storeId)
				store.setLinkedRecord(newUser, "currentUser") // set the current user

				onCompleted(data.signin.user)
			} catch (e) {
				onError(e)
			}
		},
		variables: { input: { password, username } },
		onCompleted: complete(() => {}, onError),
		onError: (e) => complete(null, [e]),
	})

export default { commit }
