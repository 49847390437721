import React, { PureComponent } from "react"
import { createPaginationContainer, graphql } from "react-relay"
import ListOfLookbooks from "../../../components/list-of-lookbooks/list-of-lookbooks.jsx"

class ProfileLookbooks extends PureComponent {
	render() {
		const { store, relay, enableHeader } = this.props
		return <ListOfLookbooks enableHeader={enableHeader} lookbooks={store.profile.lookbooks} relay={relay} currentUser={store.currentUser} storeId={store.id} />
	}
}

const query = graphql`
	query profileLookbooksQuery(
		$count: Int!
		$cursor: String
		$username: String!
	) {
		store {
			...profileLookbooks_store @arguments(count: $count, cursor: $cursor, username: $username)
		}
	}
`

ProfileLookbooks = createPaginationContainer(ProfileLookbooks,
	{
		store: graphql`
	fragment profileLookbooks_store on Store @argumentDefinitions(
		count: {type: "Int", defaultValue: 12}
		cursor: {type: "String"}
		username: {type: "String!"}
	) {
		id
		currentUser {
			id
			...lookbookCard_currentUser
		}
		profile(username: $username) {
			lookbooks ( 
				first: $count
				after: $cursor
				isPublished: true
			) @connection(key: "profile_lookbooks") {
				edges {
					node {
						...lookbookCard_lookbook  
					}
				}
			}
		}
	}
`,
	}, {
		direction: "forward",
		getConnectionFromProps(props) {
			const { profile } = props.store
			return profile && profile.lookbooks
		},
		getFragmentVariables(prevVars, totalCount) {
			return {
				...prevVars,
				count: totalCount,
			}
		},
		getVariables(props, { count, cursor }) {
			return {
				count,
				cursor,
				username: props.username,
			}
		},
		query,
	})

export default { Component: ProfileLookbooks, query, params: { count: 12, cursor: null, username: null } }
