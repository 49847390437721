/**
 * @generated SignedSource<<89cae2130008a724a2a00e7e60967c7b>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { shopcastFolder_folder$fragmentType } from "./../../../shopcast-folder/__generated__/shopcastFolder_folder.graphql";
import type { FragmentType } from "relay-runtime";
declare export opaque type listOfFolders_store$fragmentType: FragmentType;
export type listOfFolders_store$data = {|
  +currentUser: ?{|
    +id: string,
    +role: ?string,
    +shopcastContainer: ?{|
      +folder: ?{|
        +folders: ?{|
          +edges: ?$ReadOnlyArray<?{|
            +cursor: string,
            +node: ?{|
              +id: string,
              +$fragmentSpreads: shopcastFolder_folder$fragmentType,
            |},
          |}>,
          +pageInfo: {|
            +endCursor: ?string,
            +hasNextPage: boolean,
            +hasPreviousPage: boolean,
            +startCursor: ?string,
          |},
        |},
        +id: string,
        +path: ?$ReadOnlyArray<?{|
          +name: ?string,
          +value: ?string,
        |}>,
      |},
      +id: string,
    |},
  |},
  +id: string,
  +$fragmentType: listOfFolders_store$fragmentType,
|};
export type listOfFolders_store$key = {
  +$data?: listOfFolders_store$data,
  +$fragmentSpreads: listOfFolders_store$fragmentType,
  ...
};
*/

var node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": 12,
      "kind": "LocalArgument",
      "name": "count"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "cursor"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "folder"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "isShared"
    },
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "onlyClients"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "search"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "sort"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "teamId"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "count",
        "cursor": "cursor",
        "direction": "forward",
        "path": [
          "currentUser",
          "shopcastContainer",
          "folder",
          "folders"
        ]
      }
    ]
  },
  "name": "listOfFolders_store",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "currentUser",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "role",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "ShopcastContainer",
          "kind": "LinkedField",
          "name": "shopcastContainer",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": [
                {
                  "kind": "Variable",
                  "name": "id",
                  "variableName": "folder"
                }
              ],
              "concreteType": "ShopcastFolder",
              "kind": "LinkedField",
              "name": "folder",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "NameValue",
                  "kind": "LinkedField",
                  "name": "path",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "name",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "value",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": "folders",
                  "args": [
                    {
                      "kind": "Variable",
                      "name": "isShared",
                      "variableName": "isShared"
                    },
                    {
                      "kind": "Variable",
                      "name": "onlyClients",
                      "variableName": "onlyClients"
                    }
                  ],
                  "concreteType": "ShopcastFolderShopcastFolderConnection",
                  "kind": "LinkedField",
                  "name": "__currentUser_listOfFolder_folders_connection",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "ShopcastFolderShopcastFolderEdge",
                      "kind": "LinkedField",
                      "name": "edges",
                      "plural": true,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "cursor",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "ShopcastFolder",
                          "kind": "LinkedField",
                          "name": "node",
                          "plural": false,
                          "selections": [
                            (v0/*: any*/),
                            {
                              "args": null,
                              "kind": "FragmentSpread",
                              "name": "shopcastFolder_folder"
                            },
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "__typename",
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "PageInfo",
                      "kind": "LinkedField",
                      "name": "pageInfo",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "hasPreviousPage",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "hasNextPage",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "startCursor",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "endCursor",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Store",
  "abstractKey": null
};
})();

(node/*: any*/).hash = "df44bae271d6b09a374f9576fc1deece";

module.exports = ((node/*: any*/)/*: Fragment<
  listOfFolders_store$fragmentType,
  listOfFolders_store$data,
>*/);
