import { commitMutation, graphql } from "react-relay"
import { complete } from "../helper.js"

const mutation = graphql`
	mutation trackViewedMutation($input: TrackViewedInput!) {
		trackViewed(input: $input) {
			store {
				id
			}
		}
	}
`

const getConfigs = (storeId) => [
	{
		type: "FIELDS_CHANGE",
		fieldIDs: { store: storeId },
	},
]

const commit = (env, shopcastId, storeId, onCompleted, onError) =>
	commitMutation(env, {
		mutation,
		variables: { input: { shopcastId } },
		configs: getConfigs(storeId),
		onCompleted: complete(onCompleted, onError),
		onError: (e) => complete(null, [e]),
	})

export default { commit }
