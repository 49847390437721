/**
 * @generated SignedSource<<94073ded13520e872982a52af4ca7d62>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentType } from "relay-runtime";
declare export opaque type shopcastFolder_folder$fragmentType: FragmentType;
export type shopcastFolder_folder$data = {|
  +avatar: ?string,
  +bannerPhoto: ?string,
  +clients: ?$ReadOnlyArray<?{|
    +avatar: ?string,
    +email: ?string,
    +folders: ?$ReadOnlyArray<?{|
      +id: string,
    |}>,
    +fullname: ?string,
    +id: string,
    +isUserAlreadyExist: ?boolean,
  |}>,
  +count: ?number,
  +description: ?string,
  +id: string,
  +inRoot: ?boolean,
  +isArchived: ?boolean,
  +isParentAClient: ?boolean,
  +isPinned: ?boolean,
  +isStarred: ?boolean,
  +itemsCount: ?number,
  +name: ?string,
  +parent: ?{|
    +id: string,
    +name: ?string,
  |},
  +path: ?$ReadOnlyArray<?{|
    +name: ?string,
    +value: ?string,
  |}>,
  +sharableLink: ?string,
  +$fragmentType: shopcastFolder_folder$fragmentType,
|};
export type shopcastFolder_folder$key = {
  +$data?: shopcastFolder_folder$data,
  +$fragmentSpreads: shopcastFolder_folder$fragmentType,
  ...
};
*/

var node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "avatar",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "shopcastFolder_folder",
  "selections": [
    (v0/*: any*/),
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "ShopcastFolder",
      "kind": "LinkedField",
      "name": "parent",
      "plural": false,
      "selections": [
        (v1/*: any*/),
        (v0/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "NameValue",
      "kind": "LinkedField",
      "name": "path",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "value",
          "storageKey": null
        },
        (v1/*: any*/)
      ],
      "storageKey": null
    },
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "bannerPhoto",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isArchived",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isPinned",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "sharableLink",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "description",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isStarred",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "inRoot",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "count",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "itemsCount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isParentAClient",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Client",
      "kind": "LinkedField",
      "name": "clients",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "fullname",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "email",
          "storageKey": null
        },
        (v2/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isUserAlreadyExist",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "ShopcastFolder",
          "kind": "LinkedField",
          "name": "folders",
          "plural": true,
          "selections": [
            (v0/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ShopcastFolder",
  "abstractKey": null
};
})();

(node/*: any*/).hash = "7052c4e9fd388d48a93d4a346978eae7";

module.exports = ((node/*: any*/)/*: Fragment<
  shopcastFolder_folder$fragmentType,
  shopcastFolder_folder$data,
>*/);
