import React, { Component, Fragment } from "react"
import { toast } from "react-toastify"
import twoColumn from "shared/styles/layout/two-column.css"
import pageHeader from "shared/styles/layout/page-header.css"
import buttons from "shared/styles/components/buttons.css"
import empty from "shared/styles/components/empty.css"
import { DashboardIcon } from "@radix-ui/react-icons"
import DropdownMenu from "components/dropdown-menu/dropdown-menu"
import ConformationPrompt from "components/modals/conformation-prompt/conformation-prompt"
import CreateEditTeam from "components/modals/create-edit-team/create-edit-team"
import Avatar from "components/profile/avatar/avatar"
import DeleteTeamMutation from "mutations/team/delete-team"
import LeaveTeam from "mutations/team/leave-team"
import gqlHelper from "helpers/gql-helper"
import stylesAvatar from "components/profile/avatar/avatar.css"
import { toTitleCase } from "helpers/string-helper"
import { FeatureKeyENUM, runIfFeatureAvailable } from "services/plan-services"
import styles from "./people-list.css"

class Teams extends Component {
	constructor(props) {
		super(props)
		this.state = {
			deleteTeam: null,
			editTeam: null,
			leaveTeam: null,
		}
	}

	renderEmpty() {
		const { relay } = this.props
		return (
			<div className={empty.root}>
				<div className={empty.container}>
					<div className={empty.content}>
						<DashboardIcon className={empty.icon} />
						<h4 className={empty.headline}>
							You haven&apos;t created any teams yet
						</h4>
						<p className={empty.subline}>
							Create a team to invite people to collaborate on your
							projects.
						</p>
						<button
							type="button"
							className={empty.cta}
							onClick={runIfFeatureAvailable(
								() => {
									this.setState({
										editTeam: {},
									})
								},
								FeatureKeyENUM.TEAMS,
								relay.environment
							)}
						>
							Create Team
						</button>
					</div>
				</div>
			</div>
		)
	}

	renderMenu(team) {
		const listItems = []
		const push = (click, name) => listItems.push({ click, name })
		if (team.myRole === "MEMBER") {
			push(() => {
				this.setState({ leaveTeam: team })
			}, "Leave Team")
		} else {
			push(() => {
				this.setState({ editTeam: team })
			}, "Edit Team")
			push(() => {
				this.setState({ deleteTeam: team })
			}, "Delete Team")
		}
		return listItems.length ? (
			<DropdownMenu
				items={listItems}
				darkTheme
				contentRight
				triggerClass={buttons.dropdownIcon}
				triggerClassActive={buttons.dropdownIconActive}
			/>
		) : null
	}


	render() {
		const { deleteTeam, editTeam, leaveTeam } = this.state
		const { currentUser, relay } = this.props
		const { teams } = currentUser
		return (
			<Fragment>
				<div className={pageHeader.header}>
					<div className={pageHeader.content}>
						<h2 className={pageHeader.title}>Teams</h2>
						{teams.length > 0 && (
							<div className={pageHeader.actions}>
								<button
									type="button"
									className={buttons.primary}
									onClick={runIfFeatureAvailable(
										() => {
											this.setState({
												editTeam: {},
											})
										},
										FeatureKeyENUM.TEAMS,
										relay.environment
									)}
								>
									Create Team
								</button>
							</div>)}
					</div>
				</div>
				<div className={twoColumn.section}>
					{teams.length > 0 ? (
						<div className={styles.table}>
							<div className={styles.thead}>
								<div className={styles.tr}>
									<div className={styles.th}>Name</div>
									<div className={styles.th}>Role</div>
									<div className={styles.th}>Members</div>
									<div className={styles.th} />
								</div>
							</div>

							<div className={styles.tbody}>
								{teams.map((team) => (
									<div className={styles.tr} key={team.id}>
										<div className={styles.td}>
											<div className={styles.nameGroup}>
												<Avatar
													styleName={stylesAvatar.image}
													photoUrl={team.avatar}
													fullname={team.name}
													imageSize="100"
													size="40px"
												/>
												{team.name}
											</div>
										</div>
										<div className={styles.td}>
											{toTitleCase(team.myRole)}
										</div>
										<div className={styles.td}>
											{team.members.edges.length}
										</div>
										<div className={styles.td}>
											{this.renderMenu(team)}
										</div>
									</div>
								))}
							</div>
						</div>
					) : (
						this.renderEmpty()
					)}
				</div>
				<ConformationPrompt
					openModal={!!deleteTeam}
					closeModal={() => this.setState({ deleteTeam: null })}
					approveEvent={() => {
						DeleteTeamMutation.commit(
							relay.environment,
							deleteTeam.id,
							(e) => {
								toast.info(<>{gqlHelper.getError(e)}</>, {
									autoClose: 5000,
									closeButton: false,
								})
							},
							() => {
								const { team } = this.props
								toast.success(<>Team deleted successfully</>, {
									autoClose: 2000,
									closeButton: false,
								})
								if (team && team.id === deleteTeam.id)
									team.setTeam(null)
								this.setState({ deleteTeam: null })
							}
						)
					}}
					declineEvent={() => this.setState({ deleteTeam: null })}
					title="Confirm Delete"
					approveText="Delete Team"
					approveButtonStyle={buttons.primaryRed}
					declineText="Cancel"
				>
					Are you sure you want to delete this team? We&apos;ll delete all designs and other content created by this team. <b>This process can&apos;t be cancelled or undone once started.</b>
				</ConformationPrompt>
				{editTeam && (
					<CreateEditTeam
						team={editTeam}
						closeModal={() => this.setState({ editTeam: null })}
						relay={relay}
					/>
				)}
				<ConformationPrompt
					openModal={!!leaveTeam}
					closeModal={() => this.setState({ leaveTeam: null })}
					approveEvent={() => {
						LeaveTeam.commit(
							relay.environment,
							leaveTeam.id,
							(e) => {
								toast.info(<>{gqlHelper.getError(e)}</>, {
									autoClose: 5000,
									closeButton: false,
								})
							},
							() => {
								const { team } = this.props
								toast.success(<>Team left successfully</>, {
									autoClose: 2000,
									closeButton: false,
								})
								if (team && team.id === leaveTeam.id)
									team.setTeam(null)
								this.setState({ leaveTeam: null })
							}
						)
					}}
					declineEvent={() => this.setState({ leaveTeam: null })}
					title="Leave Team"
					approveText="Leave"
					approveButtonStyle={buttons.primaryRed}
					declineText="Cancel"
				>
					Are you sure you want to leave this team?
					<b>This can&apos;t be cancelled or undone once started.</b>
				</ConformationPrompt>
			</Fragment>
		)
	}
}

export default Teams
