import React from 'react'
import { toast } from "react-toastify"
import { commitMutation, graphql } from "react-relay"
import alert from "shared/styles/components/alert.css"
import { getFeatureAvalibale } from "../../services/plan-services.js"
import UpgradePlanToast from "../../components/upgrade-plan-toast/upgrade-plan-toast.jsx"
import { complete } from "../helper.js"


const mutation = graphql`
	mutation uploadToYoutubeMutation($input: UploadToYoutubeInput!) {
		uploadToYoutube(input: $input) {
			store {
				id
				currentUser {
					id
				}
			}
			youtubeVideoId
		}
	}
`

const getConfigs = (storeId) => [
	{
		type: "FIELDS_CHANGE",
		fieldIDs: { store: storeId },
	},
]

const commit = async (env, data, storeId, onCompleted, onError) => {
	if(!await getFeatureAvalibale("youtube-intergration", env, data.teamId)){
		toast.info(<UpgradePlanToast />, { autoClose: 3000, closeButton: false })
		onError({
			code: "upgrade",
			message: "This feature is not available to your current plan"
		})
		return
	}
	toast.info(
		<div className={alert.message}>
			Uploading {data.shopcast.title} to YouTube
		</div>,
		{
			autoClose: 3000,
			closeButton: false,
			position: "top-center",
		}
	)
	commitMutation(env, {
		mutation,
		variables: { input: { 
			shopcastId: data.shopcast.id,
			title: data.title,
			description: data.description,
			privacyStatus: data.privacyStatus,
		} },
		configs: getConfigs(storeId),
		onCompleted: complete(onCompleted, onError),
		onError: (e) => complete(null, [e]),
	})
}
export default { commit }
