import React from "react"
import styles from "./features.css"

function Features() {
	return (
		<div className={styles.root}>
			<div className={styles.masthead}>
				<div className={styles.inner}>
					<h1 className={styles.title}>Style without limits</h1>
				</div>
			</div>
			<div className={styles.content} />
		</div>
	)
}

export default { Component: Features }
