import { commitMutation, graphql } from "react-relay"
import { ConnectionHandler } from "relay-runtime"
import connections from "../connections"
import { complete } from "../helper"

const mutation = graphql`
	mutation duplicateLookbookMutation($input: DuplicateLookbookInput!) {
		duplicateLookbook(input: $input) {
			lookbook {
				...userLookbookCard_lookbook
			}
		}
	}
`

const commit = (env, lookbookId, parentId, onError, onCompleted) =>
	commitMutation(env, {
		mutation,
		variables: { input: { lookbookId } },
		onError: complete(onCompleted, onError),
		onCompleted: complete(onCompleted, onError),
		updater: (storeProxy, dd) => {
			if (dd.duplicateLookbook) {
				const parentFolder = storeProxy.get(parentId)
				const conn = connections.currentUser.shopcastManager.items
				const lookbooksConnection = ConnectionHandler.getConnection(
					parentFolder,
					conn.name,
					{
						...conn.data,
						type: "lookbook"
					}
				)
				const itemProxy = storeProxy
					.getRootField("duplicateLookbook")
					.getLinkedRecord("lookbook")
				const newLookbook = storeProxy.create(
					`Lookbook:connection:${new Date().getTime()}`,
					"lookbook"
				)
				newLookbook.setLinkedRecord(itemProxy, "node")
				ConnectionHandler.insertEdgeBefore(lookbooksConnection, newLookbook)
			}
		},
	})

export default { commit }
