/**
 * @generated SignedSource<<fae36d40f94185e0cdb7638b1a1d5504>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentType } from "relay-runtime";
declare export opaque type userFileCard_shopshareFile$fragmentType: FragmentType;
export type userFileCard_shopshareFile$data = {|
  +folder: ?{|
    +id: string,
    +name: ?string,
    +path: ?$ReadOnlyArray<?{|
      +name: ?string,
      +value: ?string,
    |}>,
  |},
  +id: string,
  +inRoot: ?boolean,
  +isArchived: ?boolean,
  +isPinned: ?boolean,
  +isPublished: ?boolean,
  +thumbnailUrl: ?string,
  +title: ?string,
  +url: ?string,
  +user: ?{|
    +username: ?string,
  |},
  +$fragmentType: userFileCard_shopshareFile$fragmentType,
|};
export type userFileCard_shopshareFile$key = {
  +$data?: userFileCard_shopshareFile$data,
  +$fragmentSpreads: userFileCard_shopshareFile$fragmentType,
  ...
};
*/

var node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "userFileCard_shopshareFile",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "url",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "thumbnailUrl",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isPublished",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isPinned",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isArchived",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "inRoot",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "user",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "username",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ShopcastFolder",
      "kind": "LinkedField",
      "name": "folder",
      "plural": false,
      "selections": [
        (v1/*: any*/),
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "NameValue",
          "kind": "LinkedField",
          "name": "path",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "value",
              "storageKey": null
            },
            (v1/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ShopshareFile",
  "abstractKey": null
};
})();

(node/*: any*/).hash = "c3b9deba2108f687e82535caff38ae9d";

module.exports = ((node/*: any*/)/*: Fragment<
  userFileCard_shopshareFile$fragmentType,
  userFileCard_shopshareFile$data,
>*/);
