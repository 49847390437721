import { commitMutation, graphql } from "react-relay"
import { ConnectionHandler } from "relay-runtime"
import connections from "../connections"

const mutation = graphql`
	mutation removeShopcastReportMutation($input: RemoveShopcastReportInput!) {
		removeShopcastReport(input: $input) {
			admin {
				shopcastUserReports {
					edges {
						node {
							__typename
							... on ShopcastUserReport {
								id
							}
							... on LookbookUserReport {
								id
							}
						}
					}
				}
			}
		}
	}
`

const commit = (env, reportId, adminId, type) =>
	commitMutation(env, {
		mutation,
		variables: { input: { reportId, type } },
		updater: (storeProxy, data) => {
			if (data.removeShopcastReport) {
				const adminProxy = storeProxy.get(adminId)
				const reportsConnection = ConnectionHandler.getConnection(
					adminProxy,
					connections.admin.shopcastReports.name,
					connections.admin.shopcastReports.data
				)
				ConnectionHandler.deleteNode(reportsConnection, reportId)
			}
		},
	})

export default { commit }
