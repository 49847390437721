/**
 * @generated SignedSource<<69c224fec27977b8e89f51d2d972af84>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { personal_store$fragmentType } from "./personal_store.graphql";
import type { updateCustomDomain_currentUser$fragmentType } from "./updateCustomDomain_currentUser.graphql";
import type { updatePersonalInfo_currentUser$fragmentType } from "./updatePersonalInfo_currentUser.graphql";
import type { updateUsername_currentUser$fragmentType } from "./updateUsername_currentUser.graphql";
export type CampaignCommisionType = "FIXED" | "PERCENTAGE" | "%future added value";
export type TeamMemberRoleType = "ADMIN" | "MEMBER" | "OWNER" | "%future added value";
export type userEnumType = "CLIENT" | "RETAILER" | "STYLIST" | "%future added value";
import type { FragmentType } from "relay-runtime";
declare export opaque type account_store$fragmentType: FragmentType;
export type account_store$data = {|
  +currentUser: ?{|
    +adminAccess: ?string,
    +email: ?string,
    +fullname: ?string,
    +id: string,
    +optOutAffiliate: ?boolean,
    +profilePhoto: ?string,
    +referrals: ?$ReadOnlyArray<?{|
      +campaign: ?{|
        +annualCommision: ?number,
        +description: ?string,
        +monthlyCommision: ?number,
        +name: ?string,
        +type: ?CampaignCommisionType,
      |},
      +conversions: ?number,
      +id: string,
      +isArchived: ?boolean,
      +leads: ?number,
      +link: ?string,
      +paidCommision: ?number,
      +revenue: ?number,
      +visitors: ?number,
    |}>,
    +role: ?string,
    +socialMedia: ?$ReadOnlyArray<?{|
      +isConnected: ?boolean,
      +platform: ?string,
    |}>,
    +teams: ?$ReadOnlyArray<?{|
      +avatar: ?string,
      +displayName: ?string,
      +id: string,
      +members: ?{|
        +edges: ?$ReadOnlyArray<?{|
          +node: ?{|
            +id: string,
          |},
        |}>,
      |},
      +myRole: ?TeamMemberRoleType,
      +name: ?string,
    |}>,
    +trialEnd: ?string,
    +trialStart: ?string,
    +trialUsed: ?boolean,
    +type: ?userEnumType,
    +userPlan: ?{|
      +amount: ?number,
      +currency: ?string,
      +id: ?string,
      +metadata: ?$ReadOnlyArray<?{|
        +name: ?string,
        +value: ?string,
      |}>,
      +nickname: ?string,
      +status: ?string,
    |},
    +username: ?string,
    +$fragmentSpreads: updateCustomDomain_currentUser$fragmentType & updatePersonalInfo_currentUser$fragmentType & updateUsername_currentUser$fragmentType,
  |},
  +id: string,
  +pricingPlans: ?$ReadOnlyArray<?{|
    +amount: ?number,
    +currency: ?string,
    +descriptionList: ?$ReadOnlyArray<?string>,
    +id: ?string,
    +metadata: ?$ReadOnlyArray<?{|
      +name: ?string,
      +value: ?string,
    |}>,
    +monthly: ?boolean,
    +nickname: ?string,
  |}>,
  +team: ?{|
    +avatar: ?string,
    +id: string,
    +myRole: ?TeamMemberRoleType,
    +name: ?string,
    +user: ?{|
      +email: ?string,
      +fullname: ?string,
      +id: string,
    |},
  |},
  +$fragmentSpreads: personal_store$fragmentType,
  +$fragmentType: account_store$fragmentType,
|};
export type account_store$key = {
  +$data?: account_store$data,
  +$fragmentSpreads: account_store$fragmentType,
  ...
};
*/

var node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "avatar",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "myRole",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "fullname",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "amount",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "currency",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "nickname",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "concreteType": "NameValue",
  "kind": "LinkedField",
  "name": "metadata",
  "plural": true,
  "selections": [
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "value",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "teamId"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "account_store",
  "selections": [
    (v0/*: any*/),
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "personal_store"
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "id",
          "variableName": "teamId"
        }
      ],
      "concreteType": "team",
      "kind": "LinkedField",
      "name": "team",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        (v2/*: any*/),
        (v3/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "User",
          "kind": "LinkedField",
          "name": "user",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            (v4/*: any*/),
            (v5/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "currentUser",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v5/*: any*/),
        (v4/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "username",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "adminAccess",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "role",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "profilePhoto",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "optOutAffiliate",
          "storageKey": null
        },
        (v6/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "PricingPlan",
          "kind": "LinkedField",
          "name": "userPlan",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            (v7/*: any*/),
            (v8/*: any*/),
            (v9/*: any*/),
            (v10/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "status",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "trialUsed",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "trialStart",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "trialEnd",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Referral",
          "kind": "LinkedField",
          "name": "referrals",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "link",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "visitors",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "leads",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "conversions",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "revenue",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "paidCommision",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "isArchived",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "Campaign",
              "kind": "LinkedField",
              "name": "campaign",
              "plural": false,
              "selections": [
                (v1/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "description",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "monthlyCommision",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "annualCommision",
                  "storageKey": null
                },
                (v6/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "UserSocialMedia",
          "kind": "LinkedField",
          "name": "socialMedia",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "platform",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "isConnected",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "team",
          "kind": "LinkedField",
          "name": "teams",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            (v1/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "displayName",
              "storageKey": null
            },
            (v2/*: any*/),
            (v3/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "TeamMemberConnection",
              "kind": "LinkedField",
              "name": "members",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "TeamMemberEdge",
                  "kind": "LinkedField",
                  "name": "edges",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "teamMember",
                      "kind": "LinkedField",
                      "name": "node",
                      "plural": false,
                      "selections": [
                        (v0/*: any*/)
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "updatePersonalInfo_currentUser"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "updateUsername_currentUser"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "updateCustomDomain_currentUser"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "PricingPlan",
      "kind": "LinkedField",
      "name": "pricingPlans",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        (v7/*: any*/),
        (v8/*: any*/),
        (v9/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "monthly",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "descriptionList",
          "storageKey": null
        },
        (v10/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "type": "Store",
  "abstractKey": null
};
})();

(node/*: any*/).hash = "befe224efe9679486ae6c5533194f112";

module.exports = ((node/*: any*/)/*: Fragment<
  account_store$fragmentType,
  account_store$data,
>*/);
