import { commitMutation, graphql } from "react-relay"
import { complete } from "../helper.js"

const mutation = graphql`
	mutation downloadLookbookVideoWithImageMutation(
		$input: DownloadLookbookVideoWithImageMutationInput!
	) {
		downloadLookbookVideoWithImage(input: $input) {
			store {
				id
			}
		}
	}
`

const commit = (env, input, onError, onCompleted) =>
	commitMutation(env, {
		mutation,
		variables: { input },
		onError: complete(onCompleted, onError),
		onCompleted: complete(onCompleted, onError),
	})

export default { commit }
