import { ConnectionHandler, commitMutation, graphql } from "react-relay"
import { complete } from "../helper"

const mutation = graphql`
	mutation createCustomProductMutation($input: CreateCustomProductInput!) {
		createCustomProduct(input: $input) {
			store {
				id
			}
			product {
				id
				title
				brand
				description
				url
				affiliated_url
				custom_affiliated_url
				imageUrl
				diffbotImageUrl
				regularPrice
				offerPrice
				error
				productImages
				hasProductImagesFromDiffbot
				productCollections {
					id
					title
				}
			}
		}
	}
`

const commit = (env, data, onError, onCompleted, other) =>
	commitMutation(env, {
		mutation,
		variables: { input: data },
		updater: (storeProxy) => {
			const userProxy = storeProxy
				.getRoot()
				.getLinkedRecord("store")
				.getLinkedRecord("currentUser")
			const rootField = storeProxy.getRootField("createCustomProduct")
			const newProduct = rootField.getLinkedRecord("product")
			const searchConnection = other
				? ConnectionHandler.getConnection(
						userProxy.getLinkedRecord("productCollectionSearchItems", {
							search: other.search,
							categories: other.categories,
							brands: other.brands,
							clientIds: other.clientId ? [other.clientId] : [],
							collections: other.collections,
							limitType: other.limitType,
							sort: other.sort,
							type: other.type,
							teamId: other.teamId,
						}),
						"currentUser_productCollectionSearchItems_data"
				  )
				: null
			if (searchConnection) {
				const newCollectionItem = storeProxy.create(
					`ShopcastProduct:connection:${new Date().getTime()}`,
					"ShopcastProduct"
				)
				newCollectionItem.setLinkedRecord(newProduct, "node")
				ConnectionHandler.insertEdgeBefore(searchConnection, newCollectionItem)
			}
					
		},
		onError: complete(onCompleted, onError),
		onCompleted: complete(onCompleted, onError),
	})

export default { commit }
