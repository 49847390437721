/* eslint-disable no-underscore-dangle */
import React, { Component } from "react"
import { createPaginationContainer, graphql } from "react-relay"
import InfiniteScroll from "react-infinite-scroller"
import { toast } from "react-toastify"
import Lightbox from "yet-another-react-lightbox"
import ContentLoader from "react-content-loader"
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen"
import Download from "yet-another-react-lightbox/plugins/download"
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails"
import Video from "yet-another-react-lightbox/plugins/video"
import Zoom from "yet-another-react-lightbox/plugins/zoom"
import LoadingDots from "components/loading-dots/loading-dots"
import grids from "shared/styles/components/grids.css"
import empty from "shared/styles/components/empty.css"
import UserShopcastCard from "components/cards/user-shopcast/user-shopcast-card"
import UserLookbookCard from "components/cards/user-lookbook/user-lookbook-card"
import UserFileCard from "components/cards/user-file/user-file-card"
import ProductCollectionCard from "components/cards/user-product-collection-card/user-product-collection-card"
import FacebookSharing from "components/modals/facebook-sharing/facebook-sharing"
import ConnectToSocial from "components/modals/connect-to-social/connect-to-social"
import YoutubeUpload from "components/modals/upload-youtube/upload-youtube"
import { connectToSocial, fetchSocialData } from "services/social-service"
import { ROLES, socialMedia } from "constants"
import QueryLoadMore, {
	QueryLoadMoreSkeleton,
} from "../../query-load-more/query-load-more"
import setPinnedItemsMutation from "../../../../mutations/shopcast-manager/set-pinned-items"
import { imageOrVideo } from "../../../../helpers/ui-helper"

class ListOfFolderItems extends Component {
	constructor(props) {
		super(props)
		this.state = {
			facebookSharingObj: null,
			connectToSocialObj: null,
			loading: false,
			lightboxIndex: null,
			lightBoxItems: [],
			uploadYoutubeObj: null
		}
		this.handleLoadMore = this.handleLoadMore.bind(this)
		this.handleInfiniteLoad = this.handleInfiniteLoad.bind(this)
	}

	componentDidUpdate(prevProps) {
		const { props } = this
		if (!props.loading) {
			const prevItems =
				prevProps.store?.currentUser?.shopcastContainer?.folder?.items
			const { store, relay } = props
			const { items } = store.currentUser.shopcastContainer.folder
			if (prevItems?.edges?.length !== items.edges.length) {
				const { lightboxIndex } = this.state
				const lbItems = this.getLightBoxItems()
				if (lightboxIndex === lbItems.length - 1 && relay.hasMore())
					this.handleInfiniteLoad()
				else this.setState({ lightBoxItems: lbItems })
			}
		}
	}

	handleLoadMore(itemCount) {
		const { relay, count } = this.props
		this.setState({ loading: true })
		console.log("load more", count)
		relay.loadMore(
			itemCount || count,
			() => {
				this.setState({ loading: false })
			},
			{
				force: true,
			}
		)
	}

	handleInfiniteLoad() {
		const { relay } = this.props
		relay.loadMore(12)
	}

	getLightBoxItems() {
		const { store } = this.props
		const { items } = store.currentUser.shopcastContainer.folder
		const lightBoxItems = items.edges
			.filter(
				({ node }) =>
					node.__typename === "ShopshareFile" && !!imageOrVideo(node.url)
			)
			.map(({ node }) => {
				const type = imageOrVideo(node.url)
				if (type === "image")
					return {
						src: node.url,
						type: "image",
						download: true,
					}
				return {
					sources: [
						{
							src: node.url,
						},
					],
					src: node.url,
					type: "video",
					download: node.url,
				}
			})
		return lightBoxItems
	}

	togglePinned(id) {
		const { relay, type, sort, store } = this.props
		const { id: folderId } = store.currentUser.shopcastContainer.folder

		setPinnedItemsMutation.commit(
			relay.environment,
			id,
			() => {},
			() => {
				toast.error("Something went wrong", {
					autoClose: 5000,
					closeButton: false,
				})
			},
			{
				type,
				sort,
				folder: folderId,
				isFolderConnection: false,
			}
		)
	}

	renderSkeleton() {
		const {
			details: { noLoadMore },
		} = this.props
		return (
			<QueryLoadMoreSkeleton>
				{(itemCount) =>
					new Array(itemCount * (noLoadMore ? 1 : 10))
						.fill(0)
						.map((_, i) => (
							// eslint-disable-next-line react/no-array-index-key
							<div className={grids.col3} key={i}>
								<ContentLoader
									viewBox="0 0 314 300"
									speed={0}
									animate={false}
									style={{ width: "100%", height: "100%" }}
									primarycolor="#EDEDED"
									preserveAspectRatio="xMidYMid meet"
									secondarycolor="#E6E6E6"
								>
									<rect width="60" height="16" y="274.5" rx="2" />
									<rect width="200" height="16" y="254.5" rx="2" />
									<rect width="314" height="242.5" rx="6" />
								</ContentLoader>
							</div>
						))
				}
			</QueryLoadMoreSkeleton>
		)
	}

	render() {
		const { loading: initialLoading } = this.props
		if (initialLoading)
			return (
				<div className={grids.container}>
					<div className={grids.row}>{this.renderSkeleton()}</div>
				</div>
			)
		const { store, relay, location, details, search, team } = this.props
		const socialMediaObj = (store?.currentUser?.socialMedia || []).reduce(
			(acc, cur) => ({ ...acc, [cur.platform]: cur.isConnected }),
			{}
		)

		const {
			facebookSharingObj,
			connectToSocialObj,
			loading,
			lightboxIndex,
			lightBoxItems,
			uploadYoutubeObj
		} = this.state
		const { items, id: folderId } = store.currentUser.shopcastContainer.folder
		const {
			noLoadMore,
			sendErrorsToToast,
			confirmDelete,
			handleItemSelect,
			handleItemDeselect,
			requestMove,
			requestCopy,
			routeType,
			requestShopcastEdit,
			requestLookbookEdit,
			selectedItems,
			isParentAClient
		} = details
		if (items.edges.length === 0)
			return <div className={empty.simple}>There&apos;s nothing here</div>

		const getListItems = (edges) =>
			edges.map(({ node }) => (
				<div className={grids.col3} key={node.__id}>
					{node.__typename === "Shopcast" && (
						<UserShopcastCard
							relay={relay}
							shopcast={node}
							storeId={store.id}
							connectedToYouTube={!!socialMediaObj.youtube}
							connectedToPinterest={!!socialMediaObj.pinterest}
							parentId={folderId}
							hasAdminAccess={
								store.currentUser &&
								[ROLES.ADMIN, ROLES.TEAM].includes(
									store.currentUser.role
								)
							}
							reportError={sendErrorsToToast}
							confirmDelete={confirmDelete}
							location={location}
							isSelected={
								selectedItems
									.filter((i) => i.type === "shopcast")
									.findIndex((i) => i.id === node.__id) >= 0
							}
							onSelect={handleItemSelect}
							onDeselect={handleItemDeselect}
							requestMove={requestMove}
							requestCopy={requestCopy}
							containerId={store.currentUser.shopcastContainer.id}
							userId={store.currentUser.id}
							planMetadata={
								store.currentUser.userPlan
									? store.currentUser.userPlan.metadata
									: []
							}
							routeType={routeType}
							requestEdit={(data) =>
								requestShopcastEdit(data, "shopcast")
							}
							shareOnFacebook={(obj) => {
								this.setState({
									facebookSharingObj: obj,
								})
							}}
							connectToSocial={(obj) => {
								this.setState({
									connectToSocialObj: obj,
								})
							}}
							isDraggable={selectedItems.length === 0}
							enableCardSelection={selectedItems.length > 0}
							togglePinned={() => this.togglePinned(node.__id)}
							searchValue={search}
							onYoutubeUpload={(data) => {
								this.setState({
									uploadYoutubeObj: data,
								})
							}}
							team={team}
							isParentAClient={isParentAClient}
						/>
					)}
					{node.__typename === "Lookbook" && (
						<UserLookbookCard
							storeId={store.id}
							lookbook={node}
							isDragging={false}
							relay={relay}
							reportError={sendErrorsToToast}
							containerId={store.currentUser.shopcastContainer.id}
							connectedToPinterest={!!socialMediaObj.pinterest}
							parentId={folderId}
							confirmDelete={confirmDelete}
							routeType={routeType}
							isSelected={
								selectedItems
									.filter((i) => i.type === "lookbook")
									.findIndex((i) => i.id === node.__id) >= 0
							}
							onSelect={handleItemSelect}
							onDeselect={handleItemDeselect}
							requestMove={requestMove}
							requestCopy={requestCopy}
							requestEdit={requestLookbookEdit}
							requestEditDetails={(data) =>
								requestShopcastEdit(data, "lookbook")
							}
							currentUser={store.currentUser}
							shareOnFacebook={(obj) => {
								this.setState({
									facebookSharingObj: obj,
								})
							}}
							connectToSocial={(obj) => {
								this.setState({
									connectToSocialObj: obj,
								})
							}}
							isDraggable={selectedItems.length === 0}
							enableCardSelection={selectedItems.length > 0}
							togglePinned={() => this.togglePinned(node.__id)}
							searchValue={search}
							team={team}
							isParentAClient={isParentAClient}
						/>
					)}
					{node.__typename === "ShopshareFile" && (
						<UserFileCard
							file={node}
							isDragging={false}
							relay={relay}
							reportError={sendErrorsToToast}
							containerId={store.currentUser.shopcastContainer.id}
							parentId={folderId}
							confirmDelete={confirmDelete}
							routeType={routeType}
							isSelected={
								selectedItems
									.filter((i) => i.type === "shopshareFile")
									.findIndex((i) => i.id === node.__id) >= 0
							}
							onSelect={handleItemSelect}
							onDeselect={handleItemDeselect}
							requestMove={requestMove}
							requestCopy={requestCopy}
							requestEdit={(data) =>
								requestShopcastEdit(data, "shopshareFile")
							}
							isDraggable={selectedItems.length === 0}
							enableCardSelection={selectedItems.length > 0}
							togglePinned={() => this.togglePinned(node.__id)}
							toggleLightbox={(url) => {
								const { lightBoxItems: lbItems } = this.state
								const index = lbItems.findIndex((i) => i.src === url)
								this.setState({ lightboxIndex: index })
							}}
							searchValue={search}
							team={team}
							isParentAClient={isParentAClient}
						/>
					)}
					{node.__typename === "ProductCollection" && (
						<ProductCollectionCard
							enableCheckBox
							isSelected={
								selectedItems
									.filter((i) => i.type === "collection")
									.findIndex((i) => i.id === node.__id) >= 0
							}
							onSelect={handleItemSelect}
							onDeselect={handleItemDeselect}
							collection={node}
							relay={relay}
							requestEdit={(data) =>
								requestShopcastEdit(data, "collection")
							}
							confirmDelete={(data, callback) => {
								confirmDelete({
									type: "collection",
									name: data.title,
									callback,
								})
							}}
							parentId={folderId}
							containerId={store.currentUser.shopcastContainer.id}
							isDraggable={selectedItems.length === 0}
							enableCardSelection={selectedItems.length > 0}
							togglePinned={() => this.togglePinned(node.__id)}
							searchValue={search}
							team={team}
						/>
					)}
				</div>
			))

		return (
			<div className={grids.container}>
				{!noLoadMore ? (
					<InfiniteScroll
						loadMore={this.handleInfiniteLoad}
						hasMore={relay.hasMore()}
						loader={
							<div className={grids.loadMore}>
								<LoadingDots />
							</div>
						}
						pageStart={0}
					>
						<div className={grids.row}>{getListItems(items.edges)}</div>
					</InfiniteScroll>
				) : (
					<QueryLoadMore
						data={items.edges}
						hasMore={relay.hasMore()}
						loadMore={this.handleLoadMore}
					>
						{(data, itemCount) => (
							<>
								<div
									style={{
										"--grid-column-count": itemCount,
									}}
									className={grids.rowQueryLoad}
								>
									{getListItems(data)}
								</div>
								{relay.hasMore() && (
									<div className={grids.loadMore}>
										<button
											type="button"
											className={grids.loadMoreBtn}
											disabled={loading}
											onClick={() =>
												this.handleLoadMore(itemCount * 2)
											}
										>
											{loading ? (
												<LoadingDots color="var(--gray300)" />
											) : (
												"Show more"
											)}
										</button>
									</div>
								)}
							</>
						)}
					</QueryLoadMore>
				)}
				{lightBoxItems.length > 0 && (
					<Lightbox
						open={lightboxIndex !== null}
						close={() => this.setState({ lightboxIndex: null })}
						slides={lightBoxItems}
						index={lightboxIndex}
						carousel={{
							finite: true,
						}}
						on={{
							view: ({ index }) => {
								this.setState({ lightboxIndex: index })
								const { lightBoxItems: lbItems } = this.state
								if (index === lbItems.length - 1 && relay.hasMore()) {
									this.handleInfiniteLoad()
								}
							},
						}}
						plugins={[Fullscreen, Thumbnails, Video, Zoom, Download]}
					/>
				)}
				{!!facebookSharingObj && (
					<FacebookSharing
						closeModal={() => this.setState({ facebookSharingObj: null })}
						relay={relay}
						storeId={store.id}
						connectedToFacebook={!!socialMediaObj.facebook}
						obj={facebookSharingObj.obj}
						platform={facebookSharingObj.platform}
						fbPages={store.currentUser.facebookPages}
						uploadAs={facebookSharingObj.uploadAs}
						onSharing={(pageObj) =>
							facebookSharingObj.callback(pageObj).then(() => {
								this.setState({ facebookSharingObj: null })
							})
						}
					/>
				)}
				{!!connectToSocialObj && (
					<ConnectToSocial
						Icon={connectToSocialObj.icon}
						iconColor={connectToSocialObj.iconColor}
						title={socialMedia[connectToSocialObj.platform].title}
						heading={`Publish straight to ${
							socialMedia[connectToSocialObj.platform].name
						}!`}
						terms={socialMedia[connectToSocialObj.platform].terms}
						buttonPrompt={`Connect ${
							socialMedia[connectToSocialObj.platform].name
						}`}
						closeModal={() => this.setState({ connectToSocialObj: null })}
						onConnect={() => {
							connectToSocial(
								relay,
								store.id,
								connectToSocialObj.platform,
								true,
								() => {
									fetchSocialData(relay)
										.then((data) => {
											const isConnected = data?.store?.currentUser?.socialMedia?.find(
												(i) =>
													i.platform ===
													connectToSocialObj.platform
											)?.isConnected
											if (isConnected)
												this.setState({ connectToSocialObj: null })
										})
										.catch((err) => {
											console.log(err)
										})
								},
								(e) => {
									console.error(e)
								}
							)
						}}
					/>
				)}
				{!!uploadYoutubeObj && (
					<YoutubeUpload 
						relay={relay}
						closeModal={() => this.setState({ uploadYoutubeObj: null })}
						youtubeData={uploadYoutubeObj}
						storeId={store.id}
						team={team}
					/>
				)}
			</div>
		)
	}
}
const query = graphql`
	query listOfFolderItemsQuery(
		$count: Int!
		$cursor: String
		$folder: String
		$sort: String
		$type: String
		$search: String
		$teamId: String
	) {
		store {
			...listOfFolderItems_store
				@arguments(
					count: $count
					cursor: $cursor
					folder: $folder
					sort: $sort
					type: $type
					search: $search
					teamId: $teamId
				)
		}
	}
`

ListOfFolderItems = createPaginationContainer(
	ListOfFolderItems,
	{
		store: graphql`
			fragment listOfFolderItems_store on Store
			@argumentDefinitions(
				count: { type: "Int", defaultValue: 12 }
				cursor: { type: "String" }
				folder: { type: "String" }
				sort: { type: "String" }
				type: { type: "String" }
				search: { type: "String" }
				teamId: { type: "String" }
			) {
				id
				currentUser {
					id
					socialMedia {
						platform
						isConnected
					}
					userPlan(teamId: $teamId) {
						metadata {
							name
							value
						}
					}
					facebookPages {
						id
						name
						category
						imageUrl
						accessToken
						instagramBusinessAccount
					}
					role
					adminAccess
					shopcastContainer {
						id
						folder(id: $folder) {
							id
							items(
								first: $count
								after: $cursor
								sort: $sort
								type: $type
								search: $search
								teamId: $teamId
							)
								@connection(
									key: "currentUser_listOfFolderItems_items"
									filters: ["type"]
								) {
								edges {
									cursor
									node {
										__typename
										... on Shopcast {
											id
											...userShopcastCard_shopcast
										}
										... on Lookbook {
											id
											...userLookbookCard_lookbook
										}
										... on ShopshareFile {
											id
											url
											...userFileCard_shopshareFile
										}
										... on ProductCollection {
											id
											...userProductCollectionCard_productCollection
										}
									}
								}
								pageInfo {
									hasPreviousPage
									hasNextPage
									startCursor
									endCursor
								}
							}
						}
					}
				}
			}
		`,
	},
	{
		direction: "forward",
		getConnectionFromProps(props) {
			const { items } = props.store.currentUser.shopcastContainer.folder
			return items && items
		},
		getFragmentVariables(prevVars, totalCount) {
			return {
				...prevVars,
				count: totalCount,
			}
		},
		getVariables(props, { cursor, count }) {
			return {
				count: count || 12,
				folder: props.folder,
				type: props.type,
				sort: props.sort,
				search: props.search,
				teamId: props.teamId,
				cursor,
			}
		},
		query,
	}
)

export default {
	Component: ListOfFolderItems,
	query,
	showLoading: true,
	params: {
		count: 12,
		cursor: null,
		folder: null,
		type: null,
		sort: null,
		search: null,
		teamId: null,
	},
}
