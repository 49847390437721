import React from "react"
import { toast } from "react-toastify"
import OpenCustomerPortalMutation from "../../mutations/user/open-customer-portal"
import styles from "./manage-link.css"

export default function ManageLink({ relay, label, type }) {
	const onOpenCustomerPortal = () => {
		OpenCustomerPortalMutation.commit(
			relay.environment,
			'/settings/account',
			(data) => {
				window.location.href = data.openCustomerPortal.url
			},
			(e) => {
				toast.error(
					e[0].message || "Unable to open customer portal",
					{ autoClose: 2000, closeButton: false }
				)
			}
		)
	}

	return (
		<button
			onClick={onOpenCustomerPortal}
			className={type === "link" ? styles.rootLink : styles.root}
			type="submit"
		>
			{label || "Manage"}
		</button>
	)
}
