import { commitMutation, graphql } from "react-relay"
import { ConnectionHandler } from "relay-runtime"
import connections from "../connections"
import { complete } from "../helper"

const mutation = graphql`
	mutation duplicateTemplateMutation($input: DuplicateTemplateInput!) {
		duplicateTemplate(input: $input) {
			lookbookTemplate {
				id
				categories
				canEdit
				isFavourite
				createdCount
				lookbook {
					id
					title
					url
					screen_width
					data_json
					width
					height
					background
					group
					shapes {
						edges {
							node {
								id
								viewBox
								path
								collection
							}
						}
					}
				}
			}
		}
	}
`
const commit = (env, lookbookId, userId, onError, onCompleted, other) =>
	commitMutation(env, {
		mutation,
		variables: { input: { lookbookId } },
		onError: complete(onCompleted, onError),
		onCompleted: complete(onCompleted, onError),
		updater: (storeProxy, dd) => {
			if (dd.duplicateTemplate) {
				const userProxy = storeProxy.get(userId)
				const lookbooksTemplateConnection = ConnectionHandler.getConnection(
					userProxy,
					connections.currentUser.lookbookTemplates.name,
					other || connections.currentUser.lookbookTemplates.data,					
				)
				const itemProxy = storeProxy
					.getRootField("duplicateTemplate")
					.getLinkedRecord("lookbookTemplate")
				const newTemplate = storeProxy.create(
					`LookbookTemplate:connection:${new Date().getTime()}`,
					"lookbookTemplate"
				)
				newTemplate.setLinkedRecord(itemProxy, "node")
				if (lookbooksTemplateConnection)
					ConnectionHandler.insertEdgeBefore(
						lookbooksTemplateConnection,
						newTemplate
					)
			}
		},
	})

export default { commit }
