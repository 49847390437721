import React from "react"
import styles from "./loading.css"

export default function Loading() {
	return (
		<div className={styles.root}>
			<div className={styles.loader}>
				<div className={styles.circle1} />
				<div className={styles.circle2} />
			</div>
		</div>
	)
}
