import React from "react"
import styles from "./testimonial-divider.css"

export default function TestimonialDivider({ testimonial }) {
	return (
		<figure className={styles.root}>
			<blockquote className={styles.blockquote}>
				{testimonial.quote}
			</blockquote>
			<figcaption className={styles.caption}>
				<a href={testimonial.channelURL} target="_blank" rel="noreferrer">
					{testimonial.name}
					<cite className={styles.cite}>
						{", "}
						{testimonial.company}
					</cite>
				</a>
			</figcaption>
		</figure>
	)
}
