import React from "react"
import { useDrag, useDrop } from "react-dnd"

export const withDrag = (WrappedComponent, getOptions) => function dragFunction(props) {
	const [dragProp, dragRef] = useDrag(
		() => ({
			...getOptions(props),
		}),
		[getOptions]
	)
	return <WrappedComponent {...dragProp} {...props} dragRef={dragRef} />
}

export const withDrop = (WrappedComponent, getOptions) => function dropFunction(props) {
	const [dropProp, dropRef] = useDrop(
		() => ({
			...getOptions(props),
		}),
		[getOptions]
	)
	return <WrappedComponent {...dropProp} {...props} dropRef={dropRef}/>
}
