import { commitMutation, graphql } from "react-relay"
import { complete } from "../helper.js"

const mutation = graphql`
mutation incrementReferralVisitorsMutation($input: IncrementReferralVisitorsInput!) {
  incrementReferralVisitors(input: $input) {
    store {
      id
    }
    referral {
      campaign {
        cookiePeriod
        monthlyCoupon
				annualCoupon
      }
    }
  }
}
`

const commit = (env, link, onCompleted, onError) =>
	commitMutation(env, {
		mutation,
		variables: { input: { link } },
		onCompleted: complete(onCompleted, onError),
		onError: (e) => complete(null, [e]),
	})

export default { commit }
