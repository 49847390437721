import React from "react"
import { ChevronLeftIcon, ChevronRightIcon } from "@radix-ui/react-icons"
import styles from "./pagination.css"

function Pagination({ currentPage, pages, onChange, loading }) {
	if (!pages && !onChange) {
		return ""
	}
	const currentIndex = pages.findIndex((obj) => obj.key === currentPage)
	const prevKey = currentIndex > 0 ? pages[currentIndex - 1].key : null
	const nextKey =
		currentIndex > -1 && currentIndex < pages.length - 1
			? pages[currentIndex + 1].key
			: null

	return (
		<div className={styles.root}>
			<div className={styles.content}>
				<button
					className={styles.tool}
					onClick={() => onChange(prevKey)}
					type="button"
					disabled={loading || !prevKey}
				>
					<ChevronLeftIcon className={styles.icon} />
				</button>
				<div className={styles.pager}>
					<select
						className={styles.select}
						onChange={({ target }) => onChange(target.value)}
						disabled={loading}
						value={currentPage}
					>
						{pages.map(({ key, value }, i) => (
							<option key={key} value={key}>
								{value || i + 1}
							</option>
						))}
					</select>
					<div className={styles.string}>
						<span className={styles.of}>of</span>
						<span className={styles.length}>{pages.length}</span>
					</div>
				</div>
				<button
					className={styles.tool}
					onClick={() => onChange(nextKey)}
					type="button"
					disabled={loading || !nextKey}
				>
					<ChevronRightIcon className={styles.icon} />
				</button>
			</div>
		</div>
	)
}

export default Pagination
