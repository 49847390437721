import React, { Component } from "react"
import { createPaginationContainer, graphql } from "react-relay"
import ContentLoader from "react-content-loader"
import { toast } from "react-toastify"
import grids from "shared/styles/components/grids.css"
import empty from "shared/styles/components/empty.css"
import ShopcastFolder from "components/profile/shopcast-folder/shopcast-folder"
import folder from "components/profile/shopcast-folder/shopcast-folder.css"
import QueryLoadMore, {
	QueryLoadMoreSkeleton,
} from "components/profile/query-load-more/query-load-more"
import LoadingDots from "components/loading-dots/loading-dots"
import InfiniteScroll from "react-infinite-scroller"
import setPinnedItemsMutation from "mutations/shopcast-manager/set-pinned-items"
import { ROLES } from "constants"

class ListOfFolders extends Component {
	constructor(props) {
		super(props)
		this.state = {
			loading: false,
		}
		this.handleLoadMore = this.handleLoadMore.bind(this)
		this.handleInfiniteLoad = this.handleInfiniteLoad.bind(this)
	}

	componentDidUpdate(prevProps) {
		const { props } = this
		if (prevProps.loading && !props.loading) {
			const { store, details, relay } = props
			const {
				folder: { folders },
			} = store.currentUser.shopcastContainer
			if (details.setFolderRefetch)
				details.setFolderRefetch(() =>
					relay.refetchConnection(folders.edges.length + 1)
				)
		}
	}

	handleLoadMore(itemCount) {
		const { relay, count } = this.props
		this.setState({ loading: true })
		relay.loadMore(
			itemCount || count,
			() => {
				this.setState({ loading: false })
			},
			{
				force: true,
			}
		)
	}

	handleInfiniteLoad() {
		const { relay } = this.props
		relay.loadMore(12)
	}

	togglePinned(id) {
		const { relay, store, onlyClients } = this.props
		const { id: folderId } = store.currentUser.shopcastContainer.folder
		setPinnedItemsMutation.commit(
			relay.environment,
			id,
			() => {},
			() => {
				toast.error("Something went wrong", {
					autoClose: 5000,
					closeButton: false,
				})
			},
			{
				folder: folderId,
				onlyClients,
				isFolderConnection: true,
			}
		)
	}

	renderSkeleton() {
		const {
			details: { noLoadMore },
		} = this.props
		return (
			<QueryLoadMoreSkeleton>
				{(itemCount) =>
					new Array(itemCount * (noLoadMore ? 1 : 10))
						.fill(0)
						.map((_, i) => (
							// eslint-disable-next-line react/no-array-index-key
							<div className={grids.col3} key={i}>
								<div className={folder.rootSkelton}>
									<ContentLoader
										// viewBox="0 0 314 70"
										speed={0}
										animate={false}
										style={{ width: "100%", height: "100%" }}
										primarycolor="#EDEDED"
										preserveAspectRatio="xMidYMid meet"
										secondarycolor="#E6E6E6"
									>
										<rect
											width="16%"
											height="16"
											x="74"
											y="37"
											rx="2"
										/>
										<rect
											width="48%"
											height="16"
											x="74"
											y="17"
											rx="2"
										/>
										<circle cx="32.881" cy="35" r="28" />
									</ContentLoader>
								</div>
							</div>
						))
				}
			</QueryLoadMoreSkeleton>
		)
	}

	render() {
		const { loading: initialLoading } = this.props
		if (initialLoading)
			return (
				<div className={grids.container}>
					<div className={`${grids.row} ${grids.smallRowGap}`}>
						{this.renderSkeleton()}
					</div>
				</div>
			)
		const { loading } = this.state
		const {
			location,
			creatingNewFolder,
			store,
			details,
			relay,
			onlyClients,
			isShared,
			search,
			team
		} = this.props
		const {
			id: containerId,
			folder: { id: folderId, folders },
		} = store.currentUser.shopcastContainer
		const isAdmin =
			store.currentUser &&
			[ROLES.ADMIN, ROLES.TEAM].includes(store.currentUser.role)
		const {
			noLoadMore,
			sendErrorsToToast,
			confirmDelete,
			handleItemSelect,
			handleItemDeselect,
			requestMove,
			requestCopy,
			routeType,
			selectedItems,
			isMainFolderClient,
			setClientFolder,
		} = details
		if (
			(onlyClients || isShared || !creatingNewFolder) &&
			folders.edges.length === 0
		)
			return <div className={empty.simple}>There&apos;s nothing here</div>

		const accept = [
			"shopcast-card",
			"lookbook-card",
			"shopshare-file-card",
			"product-collection-card",
		]
		const getListItems = (edges) => (
			<>
				{edges.map(({ node }) => (
					<div className={grids.col3} key={node.id}>
						<ShopcastFolder
							startEditing={() => {}}
							endEditing={() => {}}
							confirmDelete={confirmDelete}
							folder={node}
							checked={
								selectedItems
									.filter((i) => i.type === "folder")
									.findIndex((i) => i.id === node.id) >= 0
							}
							parentId={folderId}
							reportError={sendErrorsToToast}
							location={location}
							container={containerId}
							onSelect={handleItemSelect}
							onDeselect={handleItemDeselect}
							requestMove={requestMove}
							requestCopy={requestCopy}
							routeType={routeType}
							canStar
							isDraggable={!onlyClients && selectedItems.length === 0}
							isDroppable
							setClientFolder={setClientFolder}
							accept={
								isMainFolderClient
									? accept
									: ["shopcast-folder", ...accept]
							}
							enableCardSelection={selectedItems.length > 0}
							togglePinned={() => this.togglePinned(node.id)}
							searchValue={search}
							onlyClients={onlyClients}
							isAdmin={isAdmin}
							team={team}
						/>
					</div>
				))}
			</>
		)

		return (
			<div className={grids.container}>
				{!noLoadMore ? (
					<InfiniteScroll
						loadMore={this.handleInfiniteLoad}
						hasMore={relay.hasMore()}
						loader={
							<div className={grids.loadMore}>
								<LoadingDots />
							</div>
						}
						pageStart={0}
					>
						<div className={`${grids.row} ${grids.smallRowGap}`}>
							{getListItems(folders.edges)}
						</div>
					</InfiniteScroll>
				) : (
					<QueryLoadMore
						data={folders.edges}
						hasMore={relay.hasMore()}
						loadMore={this.handleLoadMore}
					>
						{(data, itemCount) => (
							<>
								<div
									style={{
										"--grid-column-count": itemCount,
									}}
									className={`${grids.rowQueryLoad} ${grids.smallRowGap}`}
								>
									{getListItems(data)}
								</div>
								{relay.hasMore() && (
									<div className={grids.loadMore}>
										<button
											type="button"
											className={grids.loadMoreBtn}
											disabled={loading}
											onClick={() =>
												this.handleLoadMore(itemCount * 2)
											}
										>
											{loading ? (
												<LoadingDots color="var(--gray300)" />
											) : (
												"Show more"
											)}
										</button>
									</div>
								)}
							</>
						)}
					</QueryLoadMore>
				)}
			</div>
		)
	}
}

const query = graphql`
	query listOfFoldersQuery(
		$count: Int!
		$cursor: String
		$folder: String
		$onlyClients: Boolean
		$isShared: Boolean
		$sort: String
		$search: String
		$teamId: String
	) {
		store {
			...listOfFolders_store
				@arguments(
					count: $count
					cursor: $cursor
					folder: $folder
					onlyClients: $onlyClients
					isShared: $isShared
					sort: $sort
					search: $search
					teamId: $teamId
				)
		}
	}
`

ListOfFolders = createPaginationContainer(
	ListOfFolders,
	{
		store: graphql`
			fragment listOfFolders_store on Store
			@argumentDefinitions(
				count: { type: "Int", defaultValue: 12 }
				cursor: { type: "String" }
				folder: { type: "String" }
				onlyClients: { type: "Boolean", defaultValue: false }
				isShared: { type: "Boolean" }
				sort: { type: "String" }
				search: { type: "String" }
				teamId: { type: "String" }
			) {
				id
				currentUser {
					id
					role
					shopcastContainer {
						id
						folder(id: $folder) {
							id
							path {
								name
								value
							}
							folders(
								first: $count
								after: $cursor
								onlyClients: $onlyClients
								isShared: $isShared
								sort: $sort
								search: $search
								teamId: $teamId
							)
								@connection(
									key: "currentUser_listOfFolder_folders"
									filters: ["onlyClients", "isShared"]
								) {
								edges {
									cursor
									node {
										id
										...shopcastFolder_folder
									}
								}
								pageInfo {
									hasPreviousPage
									hasNextPage
									startCursor
									endCursor
								}
							}
						}
					}
				}
			}
		`,
	},
	{
		direction: "forward",
		getConnectionFromProps(props) {
			const { folders } = props.store.currentUser.shopcastContainer.folder
			return folders && folders
		},
		getFragmentVariables(prevVars, totalCount) {
			return {
				...prevVars,
				count: totalCount,
			}
		},
		getVariables(props, { cursor, count }) {
			return {
				count: count || 12,
				folder: props.folder,
				onlyClients: props.onlyClients,
				isShared: props.isShared,
				sort: props.sort,
				search: props.search,
				teamId: props.teamId,
				cursor,
			}
		},
		query,
	}
)

export default {
	Component: ListOfFolders,
	query,
	showLoading: true,
	params: {
		count: 12,
		cursor: null,
		folder: null,
		onlyClients: false,
		sort: null,
		search: null,
		teamId: null,
	},
}
