/**
 * @generated SignedSource<<1048887473ef2a0100d290287fd34029>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Mutation } from 'relay-runtime';
export type getVideoPresignedUrlMutationInput = {|
  clientMutationId?: ?string,
  extention?: ?string,
  fileType?: ?string,
  lookbookId: string,
|};
export type lookbookVideoPresignedMutation$variables = {|
  input: getVideoPresignedUrlMutationInput,
|};
export type lookbookVideoPresignedMutation$data = {|
  +getVideoPresignedUrl: ?{|
    +preSignedUrl: ?{|
      +file_name: ?string,
      +signedRequest: ?string,
      +url: ?string,
    |},
  |},
|};
export type lookbookVideoPresignedMutation = {|
  response: lookbookVideoPresignedMutation$data,
  variables: lookbookVideoPresignedMutation$variables,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "getVideoPresignedUrlMutationPayload",
    "kind": "LinkedField",
    "name": "getVideoPresignedUrl",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "PreSignedUrl",
        "kind": "LinkedField",
        "name": "preSignedUrl",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "signedRequest",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "url",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "file_name",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "lookbookVideoPresignedMutation",
    "selections": (v1/*: any*/),
    "type": "ShopshareMutationRoot",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "lookbookVideoPresignedMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "4950f027ef36ef8af02bccf644ab756e",
    "id": null,
    "metadata": {},
    "name": "lookbookVideoPresignedMutation",
    "operationKind": "mutation",
    "text": "mutation lookbookVideoPresignedMutation(\n  $input: getVideoPresignedUrlMutationInput!\n) {\n  getVideoPresignedUrl(input: $input) {\n    preSignedUrl {\n      signedRequest\n      url\n      file_name\n    }\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "049ebc6782e76140c44546be2f83359b";

module.exports = ((node/*: any*/)/*: Mutation<
  lookbookVideoPresignedMutation$variables,
  lookbookVideoPresignedMutation$data,
>*/);
