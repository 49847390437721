import axios from "axios"
import mime from "mime/lite"
import { baseUrl } from "../constants"

const awsService = {
	uploadImageAWS: async (
		url,
		data,
		progressCallback = null,
		successCallback = null
	) =>
		new Promise((resolve, reject) => {
			const urlToUpload = url
			const xhr = new XMLHttpRequest()

			xhr.onerror = (e) => {
				reject(e)
			}
			xhr.onload = (e) => {
				resolve(e)
			}
			if (progressCallback) {
				xhr.upload.addEventListener(
					"progress",
					(e) => {
						const completed = (e.loaded / e.total) * 100
						progressCallback(completed)
						if (completed === 100 && successCallback) {
							successCallback()
						}
					},
					false
				)
			}
			xhr.open("PUT", urlToUpload)
			xhr.send(data)
		}),
	uploadFileAWS: async (
		url,
		data,
		progressCallback = null,
		successCallback = null
	) =>
		new Promise((resolve, reject) => {
			const urlToUpload = url
			const xhr = new XMLHttpRequest()
			if (progressCallback && successCallback) {
				xhr.upload.addEventListener(
					"progress",
					(e) => {
						const completed = (e.loaded / e.total) * 100
						progressCallback(completed)
						if (completed === 100) {
							progressCallback(0)
							successCallback()
						}
					},
					false
				)
			}

			xhr.onerror = (e) => {
				reject(e)
			}
			xhr.onload = (e) => {
				resolve(e)
			}
			xhr.open("PUT", urlToUpload)
			xhr.send(data)
		}),
	uploadFileService: async (file, onUploadPercentage) => {
		const extension = file.name.substring(file.name.lastIndexOf(".") + 1)
		const mimeType = mime.getType(extension)
		const signedRes = await axios.get(
			`${baseUrl}/api/shopshare-files/signed-url?ext=${extension}&fileType=${mimeType}`
		)
		await axios.put(signedRes.data.signedRequest, file, {
			headers: {
				"Content-Type": mimeType,
			},
			onUploadProgress: (progressEvent) => {
				const percentCompleted = Math.round(
					(progressEvent.loaded * 100) / progressEvent.total
				)
				onUploadPercentage(percentCompleted)
			},
		})
		return signedRes.data
	},
}
export default awsService
