/**
 * @generated SignedSource<<af28acd17374a64382f50fda3e7fd58b>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentType } from "relay-runtime";
declare export opaque type profileView_follower$fragmentType: FragmentType;
export type profileView_follower$data = {|
  +bannerPhoto: ?string,
  +displayAvatar: ?string,
  +fullname: ?string,
  +id: string,
  +isFollowing: ?boolean,
  +profilePhoto: ?string,
  +username: ?string,
  +$fragmentType: profileView_follower$fragmentType,
|};
export type profileView_follower$key = {
  +$data?: profileView_follower$data,
  +$fragmentSpreads: profileView_follower$fragmentType,
  ...
};
*/

var node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "profileView_follower",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "fullname",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "username",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isFollowing",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "profilePhoto",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "displayAvatar",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "bannerPhoto",
      "storageKey": null
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node/*: any*/).hash = "ea0bc4c80fabb264602944c15b1c2ab9";

module.exports = ((node/*: any*/)/*: Fragment<
  profileView_follower$fragmentType,
  profileView_follower$data,
>*/);
