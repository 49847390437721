/**
 * @generated SignedSource<<50a2db06681904fce3f20e4c577eee6d>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Mutation } from 'relay-runtime';
export type DeleteLookbookInput = {|
  clientMutationId?: ?string,
  lookbookId: string,
|};
export type deleteLookbookMutation$variables = {|
  input: DeleteLookbookInput,
|};
export type deleteLookbookMutation$data = {|
  +deleteLookbook: ?{|
    +store: ?{|
      +currentUser: ?{|
        +id: string,
      |},
      +id: string,
    |},
  |},
|};
export type deleteLookbookMutation = {|
  response: deleteLookbookMutation$data,
  variables: deleteLookbookMutation$variables,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "DeleteLookbookPayload",
    "kind": "LinkedField",
    "name": "deleteLookbook",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Store",
        "kind": "LinkedField",
        "name": "store",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "currentUser",
            "plural": false,
            "selections": [
              (v1/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "deleteLookbookMutation",
    "selections": (v2/*: any*/),
    "type": "ShopshareMutationRoot",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "deleteLookbookMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "76524de69b352e6b0ddb4f85e81dc1b5",
    "id": null,
    "metadata": {},
    "name": "deleteLookbookMutation",
    "operationKind": "mutation",
    "text": "mutation deleteLookbookMutation(\n  $input: DeleteLookbookInput!\n) {\n  deleteLookbook(input: $input) {\n    store {\n      id\n      currentUser {\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "033c681204a7ee544fbd724ec48b1b95";

module.exports = ((node/*: any*/)/*: Mutation<
  deleteLookbookMutation$variables,
  deleteLookbookMutation$data,
>*/);
