import { commitMutation, graphql } from "react-relay"
import { complete } from "../helper"

const mutation = graphql`
mutation updateFileMutation($input: UpdateFileInput!) {
    updateFile(input: $input) {
      store {
        id
      }
    }
  }
  
`

const commit = (env, data, onError, onCompleted) =>
	commitMutation(env, {
		mutation,
		variables: { input: data },
		onError: complete(onCompleted, onError),
		onCompleted: complete(onCompleted, onError),
		updater: (storeProxy, dd) => {
			if (dd.updateFile) {
				const fileProxy = storeProxy.get(data.id)
				fileProxy.setValue(data.title, "title")
			}
		},
	})

export default { commit }
