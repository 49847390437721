/* eslint-disable no-underscore-dangle */
import { commitMutation, graphql } from "react-relay"
import { complete } from "../helper.js"

const mutation = graphql`
	mutation removeImageBackgroundMutation($input: RemoveImageBackgroundInput!) {
		removeImageBackground(input: $input) {
			output {
				id
				name
				value
			}
		}
	}
`

const commit = (
	env,
	imageUrl,
	itemCategory,
	itemId,
	restore,
	onCompleted,
	onError
) =>
	commitMutation(env, {
		mutation,
		variables: { input: { imageUrl, itemCategory, itemId, restore } },
		onCompleted: complete(onCompleted, onError),
		onError: complete(onCompleted, onError),
		updater: (storeProxy, { removeImageBackground }) => {
			const productProxy = storeProxy.get(itemId)
			if (
				productProxy &&
				removeImageBackground &&
				removeImageBackground.output
			) {
				const { id, value } = removeImageBackground.output
				const newUrl = value
				if (itemCategory === "product") {
					const productImages = productProxy.getValue("productImages")
					const productImagesUpdated = [...productImages]
					const newImageIndex = productImagesUpdated.indexOf(newUrl)
					if (newImageIndex >= 0) {
						productImagesUpdated.splice(newImageIndex,1)
					} 
					const imageIndex = productImagesUpdated.indexOf(imageUrl)
					if (imageIndex >= 0) {
						productImagesUpdated[imageIndex] = newUrl
					} else {
						productImagesUpdated.push(newUrl)
					}
					productProxy.setValue(productImagesUpdated, "productImages")
					productProxy.setValue(newUrl, "diffbotImageUrl")
					productProxy.setValue(newUrl, "imageUrl")
				} else if (id && itemCategory === "upload") {
					productProxy.setValue(newUrl, "url")
				}
			}
		},
	})

export default { commit }
