import { commitMutation, graphql } from "react-relay"
import { complete } from "../helper.js"

const mutation = graphql`
	mutation deleteLookbookShapesMutation(
		$input: DeleteShapesInput!
	) {
		deleteShapes(input: $input) {
			status
		}
	}
`

const commit = (environment, args, onCompleted, onError) => commitMutation(
	environment,
	{
		mutation,
		variables: { input: { id: args.id } },
		onCompleted: complete(onCompleted, onError),
		onError: (e) => complete(null, [e]),
	},
)

export default { commit }
