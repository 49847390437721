/**
 * @generated SignedSource<<aee2e69b15b6e08d8fa57f24d7a2e846>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentType } from "relay-runtime";
declare export opaque type adminUserProfile_store$fragmentType: FragmentType;
export type adminUserProfile_store$data = {|
  +admin: ?{|
    +user: ?{|
      +commissionPayments: ?$ReadOnlyArray<?{|
        +amount: ?number,
        +createdAt: ?string,
        +currency: ?string,
        +payoutId: ?string,
      |}>,
      +commissions: ?$ReadOnlyArray<?{|
        +currency: ?string,
        +paidCommission: ?number,
        +pendingCommission: ?number,
        +pendingCommissionAUS: ?number,
        +sales: ?number,
        +shopshareCut: ?number,
        +totalCommission: ?number,
        +totalCommissionAUS: ?number,
        +unpaidCommission: ?number,
        +unpaidCommissionAUS: ?number,
        +unpaidCommissionAfterValidation: ?number,
        +unpaidCommissionAfterValidationAUS: ?number,
      |}>,
      +email: ?string,
      +fullname: ?string,
      +id: string,
      +lookbookCommissions: ?$ReadOnlyArray<?{|
        +item: ?{|
          +id?: string,
          +products?: ?{|
            +edges: ?$ReadOnlyArray<?{|
              +node: ?{|
                +id: string,
              |},
            |}>,
          |},
          +title?: ?string,
          +views?: ?number,
        |},
        +sales: ?number,
        +totalCommission: ?number,
        +totalSales: ?number,
      |}>,
      +paypalEmail: ?string,
      +profilePhoto: ?string,
      +referralsForAdmin: ?$ReadOnlyArray<?{|
        +campaign: ?{|
          +name: ?string,
        |},
        +conversions: ?number,
        +convertedUsers: ?$ReadOnlyArray<?{|
          +convertedAt: ?string,
          +convertedMonthly: ?boolean,
          +fullname: ?string,
          +id: string,
          +userPlan: ?{|
            +nickname: ?string,
          |},
        |}>,
        +leads: ?number,
        +paidCommision: ?number,
        +paidCommisionAUS: ?number,
        +payments: ?$ReadOnlyArray<?{|
          +amount: ?number,
          +createdAt: ?string,
          +id: string,
          +payoutId: ?string,
        |}>,
        +revenue: ?number,
        +revenueAUS: ?number,
        +visitors: ?number,
      |}>,
      +shopcastCommissions: ?$ReadOnlyArray<?{|
        +item: ?{|
          +id?: string,
          +shopcastItems?: ?{|
            +edges: ?$ReadOnlyArray<?{|
              +node: ?{|
                +id: string,
              |},
            |}>,
          |},
          +title?: ?string,
          +views?: ?number,
        |},
        +sales: ?number,
        +totalCommission: ?number,
        +totalSales: ?number,
      |}>,
      +username: ?string,
    |},
  |},
  +id: string,
  +$fragmentType: adminUserProfile_store$fragmentType,
|};
export type adminUserProfile_store$key = {
  +$data?: adminUserProfile_store$data,
  +$fragmentSpreads: adminUserProfile_store$fragmentType,
  ...
};
*/

var node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "fullname",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "amount",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "payoutId",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "createdAt",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "currency",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "sales",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalCommission",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "views",
  "storageKey": null
},
v10 = [
  (v0/*: any*/)
],
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalSales",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "id"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "adminUserProfile_store",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Admin",
      "kind": "LinkedField",
      "name": "admin",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": [
            {
              "kind": "Variable",
              "name": "id",
              "variableName": "id"
            }
          ],
          "concreteType": "User",
          "kind": "LinkedField",
          "name": "user",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            (v1/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "email",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "paypalEmail",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "profilePhoto",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "username",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "Referral",
              "kind": "LinkedField",
              "name": "referralsForAdmin",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "visitors",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "leads",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "conversions",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "revenue",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "revenueAUS",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "paidCommision",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "paidCommisionAUS",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Campaign",
                  "kind": "LinkedField",
                  "name": "campaign",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "name",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "User",
                  "kind": "LinkedField",
                  "name": "convertedUsers",
                  "plural": true,
                  "selections": [
                    (v0/*: any*/),
                    (v1/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "convertedAt",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "convertedMonthly",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "PricingPlan",
                      "kind": "LinkedField",
                      "name": "userPlan",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "nickname",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "ReferralPayment",
                  "kind": "LinkedField",
                  "name": "payments",
                  "plural": true,
                  "selections": [
                    (v0/*: any*/),
                    (v2/*: any*/),
                    (v3/*: any*/),
                    (v4/*: any*/)
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "AdminSalesReport",
              "kind": "LinkedField",
              "name": "commissions",
              "plural": true,
              "selections": [
                (v5/*: any*/),
                (v6/*: any*/),
                (v7/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "shopshareCut",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "paidCommission",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "unpaidCommission",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "unpaidCommissionAUS",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "totalCommissionAUS",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "unpaidCommissionAfterValidation",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "unpaidCommissionAfterValidationAUS",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "pendingCommission",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "pendingCommissionAUS",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "CommissionReportItem",
              "kind": "LinkedField",
              "name": "commissionPayments",
              "plural": true,
              "selections": [
                (v5/*: any*/),
                (v2/*: any*/),
                (v3/*: any*/),
                (v4/*: any*/)
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "AdminItemCommission",
              "kind": "LinkedField",
              "name": "shopcastCommissions",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": null,
                  "kind": "LinkedField",
                  "name": "item",
                  "plural": false,
                  "selections": [
                    {
                      "kind": "InlineFragment",
                      "selections": [
                        (v0/*: any*/),
                        (v8/*: any*/),
                        (v9/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "ShopcastItemConnection",
                          "kind": "LinkedField",
                          "name": "shopcastItems",
                          "plural": false,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "ShopcastItemEdge",
                              "kind": "LinkedField",
                              "name": "edges",
                              "plural": true,
                              "selections": [
                                {
                                  "alias": null,
                                  "args": null,
                                  "concreteType": "shopcastItem",
                                  "kind": "LinkedField",
                                  "name": "node",
                                  "plural": false,
                                  "selections": (v10/*: any*/),
                                  "storageKey": null
                                }
                              ],
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "type": "Shopcast",
                      "abstractKey": null
                    }
                  ],
                  "storageKey": null
                },
                (v6/*: any*/),
                (v11/*: any*/),
                (v7/*: any*/)
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "AdminItemCommission",
              "kind": "LinkedField",
              "name": "lookbookCommissions",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": null,
                  "kind": "LinkedField",
                  "name": "item",
                  "plural": false,
                  "selections": [
                    {
                      "kind": "InlineFragment",
                      "selections": [
                        (v0/*: any*/),
                        (v8/*: any*/),
                        (v9/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "ProductConnection",
                          "kind": "LinkedField",
                          "name": "products",
                          "plural": false,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "ProductEdge",
                              "kind": "LinkedField",
                              "name": "edges",
                              "plural": true,
                              "selections": [
                                {
                                  "alias": null,
                                  "args": null,
                                  "concreteType": "ShopcastProduct",
                                  "kind": "LinkedField",
                                  "name": "node",
                                  "plural": false,
                                  "selections": (v10/*: any*/),
                                  "storageKey": null
                                }
                              ],
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "type": "Lookbook",
                      "abstractKey": null
                    }
                  ],
                  "storageKey": null
                },
                (v6/*: any*/),
                (v11/*: any*/),
                (v7/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Store",
  "abstractKey": null
};
})();

(node/*: any*/).hash = "0d07fe146e827282822dd0bc88458e1e";

module.exports = ((node/*: any*/)/*: Fragment<
  adminUserProfile_store$fragmentType,
  adminUserProfile_store$data,
>*/);
