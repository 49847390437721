import React, { PureComponent } from "react"
import { createPaginationContainer, graphql } from "react-relay"
import ListOfAll from "../list-of-all/list-of-all"

class ListOfRecommendedItems extends PureComponent {
	render() {
		const { store, relay } = this.props
		return <ListOfAll enableHeader items={store.recommendedAll} relay={relay} currentUser={store.currentUser} storeId={store.id} />
	}
}

const query = graphql`
	query listOfRecommendedItemsQuery(
		$count: Int!
		$cursor: String
		$type: String
	) {
		store {
			...listOfRecommendedItems_store @arguments(count: $count, cursor: $cursor, type: $type)
		}
	}
`

ListOfRecommendedItems = createPaginationContainer(ListOfRecommendedItems,
	{
		store: graphql`
	fragment listOfRecommendedItems_store on Store @argumentDefinitions(
		count: {type: "Int", defaultValue: 12}
		cursor: {type: "String"}
		type: {type: "String"}
	) {
		id
		currentUser {
			...lookbookCard_currentUser
			...shopcastCard_currentUser
		}
		recommendedAll( 
			first: $count
			after: $cursor
			type: $type
		) @connection(key: "store_recommendedAll") {
			edges {
				cursor
				node {
					__typename
					... on Shopcast {
						id
						...shopcastCard_shopcast
					}
					... on Lookbook {
						id
						...lookbookCard_lookbook
					}
				}
			}
			pageInfo{
				hasPreviousPage
				hasNextPage
				startCursor
				endCursor
		 }
		}
	}
`,
	}, {
		direction: "forward",
		getConnectionFromProps(props) {
			const { recommendedAll } = props.store
			return recommendedAll && recommendedAll
		},
		getFragmentVariables(prevVars, totalCount) {
			return {
				...prevVars,
				count: totalCount,
			}
		},
		getVariables(props, { cursor }) {
			return {
				count: props.count,
				cursor,
				type: props.type,
			}
		},
		query,
	})

export default {
	Component: ListOfRecommendedItems,
	query,
	params: { count: 12, cursor: null, type: null },
	props: { count: 12, type: null },
}
