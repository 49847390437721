import { commitMutation, graphql } from "react-relay"
import { complete } from "../helper.js"

const mutation = graphql`
	mutation changePasswordMutation($input: ChangePasswordInput!) {
		changePassword(input: $input) {
			store {
				id
			}
		}
	}
`

const getConfigs = (storeId) => [
	{
		type: "FIELDS_CHANGE",
		fieldIDs: { store: storeId },
	},
]

const commit = (
	env,
	oldPassword,
	newPassword,
	retypePassword,
	storeId,
	onCompleted,
	onError
) =>
	commitMutation(env, {
		mutation,
		variables: { input: { oldPassword, newPassword, retypePassword } },
		configs: getConfigs(storeId),
		onCompleted: complete(onCompleted, onError),
		onError: (e) => complete(null, [e]),
	})

export default { commit }
