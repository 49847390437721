import React, { Component } from "react"
import VideoPlaceholder from "shared/assets/video-no-camera-poster.png"
// import VideoMask from "shared/assets/video-mask-9-16.svg"
import styles from "./device-visualizer.css"

export default class DeviceVisualizer extends Component {
	constructor(props) {
		super(props)

		this.state = {
			microphoneVolume: 0,
		}
		this.setupVideoProcessor = this.setupVideoProcessor.bind(this)
		this.setupProcessor = this.setupProcessor.bind(this)
		this.setupAudioProcessor = this.setupAudioProcessor.bind(this)
		this.stopCurrentStream = this.stopCurrentStream.bind(this)
		this.audioVolumeSetter = this.audioVolumeSetter.bind(this)
		this.renderAudioPits = this.renderAudioPits.bind(this)
	}

	componentDidMount() {
		this.mounted = true
		this.setupProcessor()
	}

	componentDidUpdate(prevProps) {
		const { deviceId } = this.props
		if (deviceId !== prevProps.deviceId) {
			this.setupProcessor()
		}
	}

	componentWillUnmount() {
		this.mounted = false
		this.stopCurrentStream()
	}

	async setupVideoProcessor() {
		const { deviceId } = this.props
		this.stopCurrentStream()
		if (!deviceId) {
			return
		}
		this.stream = await this.getDeviceStream("video", deviceId)
		if (this.mounted) {
			if (this.video) this.video.srcObject = this.stream
		} else {
			this.stopCurrentStream()
		}
	}

	setupProcessor() {
		const { isCamera } = this.props
		if (isCamera) this.setupVideoProcessor()
		else this.setupAudioProcessor()
	}

	async setupAudioProcessor() {
		const { deviceId } = this.props
		if (!deviceId) {
			this.setState({ microphoneVolume: 0 })
			return
		}
		const stream = await this.getDeviceStream("audio", deviceId)
		this.stopCurrentStream()
		if (this.mounted) {
			this.stream = stream
			const AudioContext = window.AudioContext || window.webkitAudioContext
			this.audioContext = new AudioContext()
			this.analyser = this.audioContext.createAnalyser()
			this.microphone = this.audioContext.createMediaStreamSource(
				this.stream
			)

			this.analyser.smoothingTimeConstant = 0.8
			this.analyser.fftSize = 1024

			this.microphone.connect(this.analyser)
			this.audioVolumeSetter()
		} else {
			// console.log("stopCurrentStream", stream);
			stream.getTracks().forEach((track) => {
				track.stop()
			})
		}
	}

	async getDeviceStream(type, deviceId) {
		const stream = await navigator.mediaDevices.getUserMedia({
			[type]: {
				deviceId,
				aspectRatio: type === "video" ? "1.7777" : null,
			},
		})
		// console.log("getDeviceStream", stream);
		return stream
	}

	stopCurrentStream() {
		// console.log("stopCurrentStream", this.stream);
		if (this.stream) {
			this.stream.getTracks().forEach((track) => {
				track.stop()
			})
		}
	}

	audioVolumeSetter() {
		if (
			!this.stream ||
			this.stream.getTracks().find((i) => i.readyState !== "live")
		)
			return
		window.requestAnimationFrame(this.audioVolumeSetter)
		const array = new Uint8Array(this.analyser.frequencyBinCount)
		this.analyser.getByteFrequencyData(array)
		let values = 0

		const { length } = array
		for (let i = 0; i < length; i += 1) {
			values += array[i]
		}

		const average = values / length
		this.setState({ microphoneVolume: average })
	}

	renderAudioPits() {
		const { microphoneVolume } = this.state
		const { size } = this.props
		const ammountOfPids = Math.round(+microphoneVolume / 10)
		const items = []
		for (let i = 0; i < 10; i += 1) {
			items.push(
				<div
					key={i}
					className={size === "lg" ? styles.pidLg : styles.pid}
					style={{
						backgroundColor:
							ammountOfPids > i
								? "var(--success500)"
								: "var(--dropdown-dark-item)",
					}}
				/>
			)
		}
		return <div className={styles.rootMic}>{items}</div>
	}

	renderVideo() {
		return (
			<div className={styles.rootCamera}>
				<div className={styles.videoFit}>
					{/* <VideoMask className={styles.mask} /> */}
					<video
						className={styles.source}
						poster={VideoPlaceholder}
						ref={(video) => {
							this.video = video
						}}
						playsInline
						autoPlay
						muted
						controls={false}
					/>
				</div>
			</div>
		)
	}

	render() {
		const { isCamera } = this.props
		return isCamera ? this.renderVideo() : this.renderAudioPits()
	}
}
