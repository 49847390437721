import { fetchQuery, graphql } from "react-relay"
import ConnectSocialMediaMutation from "../mutations/user/connect-social-media"
import { popupWindow } from "../helpers/ui-helper"

export const connectToSocial = (
	relay,
	storeId,
	platform,
	status,
	onSuccess,
	onError
) => {
	ConnectSocialMediaMutation.commit(
		relay.environment,
		storeId,
		platform,
		status,
		({ connectSocialMedia }) => {
			if (connectSocialMedia?.redirectUrl) {
				const pw = popupWindow(connectSocialMedia.redirectUrl, 600, 600)
				const interval = setInterval(() => {
					if (pw.closed) {
						clearInterval(interval)
						onSuccess()
					}
				}, 1000)
			} else {
				onSuccess()
			}
		},
		onError
	)
}

export const fetchSocialData = (relay) =>
	fetchQuery(
		relay.environment,
		graphql`
			query socialServiceQuery {
				store {
					id
					currentUser {
						id
						socialMedia {
							platform
							isConnected
						}
					}
				}
			}
		`,
		{}
	).toPromise()
