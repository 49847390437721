/**
 * @generated SignedSource<<48d918ce4e1b52aaf7768e20256c27d6>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Query } from 'relay-runtime';
import type { sharedFolderCollectionsItems_store$fragmentType } from "./sharedFolderCollectionsItems_store.graphql";
export type sharedFolderCollectionsItemsQuery$variables = {|
  collectionId?: ?string,
  count: number,
  cursor?: ?string,
  folder: string,
  searchText?: ?string,
  sort?: ?string,
  type?: ?string,
  username: string,
|};
export type sharedFolderCollectionsItemsQuery$data = {|
  +store: ?{|
    +$fragmentSpreads: sharedFolderCollectionsItems_store$fragmentType,
  |},
|};
export type sharedFolderCollectionsItemsQuery = {|
  response: sharedFolderCollectionsItemsQuery$data,
  variables: sharedFolderCollectionsItemsQuery$variables,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "collectionId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "count"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "cursor"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "folder"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "searchText"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "sort"
},
v6 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "type"
},
v7 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "username"
},
v8 = {
  "kind": "Variable",
  "name": "collectionId",
  "variableName": "collectionId"
},
v9 = {
  "kind": "Variable",
  "name": "searchText",
  "variableName": "searchText"
},
v10 = {
  "kind": "Variable",
  "name": "sort",
  "variableName": "sort"
},
v11 = {
  "kind": "Variable",
  "name": "type",
  "variableName": "type"
},
v12 = {
  "kind": "Variable",
  "name": "username",
  "variableName": "username"
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "adminAccess",
  "storageKey": null
},
v15 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "cursor"
  },
  (v8/*: any*/),
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "count"
  },
  {
    "kind": "Variable",
    "name": "folderId",
    "variableName": "folder"
  },
  (v9/*: any*/),
  (v10/*: any*/),
  (v11/*: any*/)
],
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v17 = [
  (v13/*: any*/),
  (v16/*: any*/)
],
v18 = {
  "alias": null,
  "args": null,
  "concreteType": "ProductCollection",
  "kind": "LinkedField",
  "name": "productCollections",
  "plural": true,
  "selections": (v17/*: any*/),
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "url",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/),
      (v7/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "sharedFolderCollectionsItemsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Store",
        "kind": "LinkedField",
        "name": "store",
        "plural": false,
        "selections": [
          {
            "args": [
              (v8/*: any*/),
              {
                "kind": "Variable",
                "name": "count",
                "variableName": "count"
              },
              {
                "kind": "Variable",
                "name": "cursor",
                "variableName": "cursor"
              },
              {
                "kind": "Variable",
                "name": "folder",
                "variableName": "folder"
              },
              (v9/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/),
              (v12/*: any*/)
            ],
            "kind": "FragmentSpread",
            "name": "sharedFolderCollectionsItems_store"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "ShopshareQueryRoot",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v2/*: any*/),
      (v7/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v0/*: any*/),
      (v6/*: any*/)
    ],
    "kind": "Operation",
    "name": "sharedFolderCollectionsItemsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Store",
        "kind": "LinkedField",
        "name": "store",
        "plural": false,
        "selections": [
          (v13/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "currentUser",
            "plural": false,
            "selections": [
              (v13/*: any*/),
              (v14/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": [
              (v12/*: any*/)
            ],
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "profile",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v15/*: any*/),
                "concreteType": "ProductCollectionItemConnection",
                "kind": "LinkedField",
                "name": "sharedFolderCollectionItems",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ProductCollectionItemEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "__typename",
                            "storageKey": null
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              (v13/*: any*/),
                              (v16/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "brand",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "description",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "imageUrl",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "diffbotImageUrl",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "regularPrice",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "offerPrice",
                                "storageKey": null
                              },
                              (v18/*: any*/),
                              (v19/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "affiliated_url",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "User",
                                "kind": "LinkedField",
                                "name": "user",
                                "plural": false,
                                "selections": [
                                  (v13/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "fullname",
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "username",
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "profilePhoto",
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "customDomain",
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "shopcastItem",
                                "kind": "LinkedField",
                                "name": "shopcastItems",
                                "plural": true,
                                "selections": [
                                  (v13/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "Shopcast",
                                    "kind": "LinkedField",
                                    "name": "shopcast",
                                    "plural": false,
                                    "selections": (v17/*: any*/),
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "start",
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "end",
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "type": "ShopcastProduct",
                            "abstractKey": null
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              (v13/*: any*/),
                              (v14/*: any*/),
                              (v16/*: any*/),
                              (v18/*: any*/),
                              (v19/*: any*/)
                            ],
                            "type": "lookbookUploads",
                            "abstractKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "cursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "endCursor",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasNextPage",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v15/*: any*/),
                "filters": [
                  "folderId",
                  "searchText",
                  "sort",
                  "collectionId",
                  "type"
                ],
                "handle": "connection",
                "key": "profile_sharedFolderCollectionItems",
                "kind": "LinkedHandle",
                "name": "sharedFolderCollectionItems"
              },
              (v13/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "bc8967bd29774752e5877d89fa8404d4",
    "id": null,
    "metadata": {},
    "name": "sharedFolderCollectionsItemsQuery",
    "operationKind": "query",
    "text": "query sharedFolderCollectionsItemsQuery(\n  $count: Int!\n  $cursor: String\n  $username: String!\n  $folder: String!\n  $searchText: String\n  $sort: String\n  $collectionId: String\n  $type: String\n) {\n  store {\n    ...sharedFolderCollectionsItems_store_2YwlcE\n    id\n  }\n}\n\nfragment sharedFolderCollectionsItems_store_2YwlcE on Store {\n  id\n  currentUser {\n    id\n    ...shopcastCard_currentUser\n  }\n  profile(username: $username) {\n    sharedFolderCollectionItems(first: $count, after: $cursor, folderId: $folder, searchText: $searchText, sort: $sort, collectionId: $collectionId, type: $type) {\n      edges {\n        node {\n          __typename\n          ... on ShopcastProduct {\n            id\n            title\n            brand\n            description\n            imageUrl\n            diffbotImageUrl\n            regularPrice\n            offerPrice\n            productCollections {\n              id\n              title\n            }\n            url\n            affiliated_url\n            user {\n              id\n              fullname\n              username\n              profilePhoto\n              customDomain\n            }\n            shopcastItems {\n              id\n              shopcast {\n                id\n                title\n              }\n              start\n              end\n            }\n          }\n          ... on lookbookUploads {\n            id\n            adminAccess\n            title\n            productCollections {\n              id\n              title\n            }\n            url\n          }\n        }\n        cursor\n      }\n      pageInfo {\n        endCursor\n        hasNextPage\n      }\n    }\n    id\n  }\n}\n\nfragment shopcastCard_currentUser on User {\n  id\n  adminAccess\n}\n"
  }
};
})();

(node/*: any*/).hash = "028085d041d64f5e2291b7b33a351ff2";

module.exports = ((node/*: any*/)/*: Query<
  sharedFolderCollectionsItemsQuery$variables,
  sharedFolderCollectionsItemsQuery$data,
>*/);
