import { commitMutation, graphql } from "react-relay"
import { ConnectionHandler } from "relay-runtime"
import { complete } from "../helper"

const mutation = graphql`
	mutation updateCategorizeLookbookUploadMutation(
		$input: CreateCategorizeLookbookUploadsInput!
		$fileType: String
		$ext: String
	) {
		createCategorizeLookbookUploads(input: $input) {
			lookbookUploads {
				id
				adminAccess
				canEdit
				title
				isFavourite
				productCollections {
					id
					title(ownershipCheck: true) 
				}
				user {
					id
				}
				url
				preSignedUrl(fileType: $fileType, ext: $ext) {
					signedRequest
					url
				}
			}
		}
	}
`

const commit = (env, data, args, onError, onCompleted, other) =>
	commitMutation(env, {
		mutation,
		variables: { input: data, fileType: args?.fileType, ext: args?.ext },
		updater: (storeProxy) => {
			const userProxy = storeProxy
				.getRoot()
				.getLinkedRecord("store")
				.getLinkedRecord("currentUser")
			const searchConnection = other
				? ConnectionHandler.getConnection(
						userProxy.getLinkedRecord("productCollectionSearchItems", {
							search: other.search,
							categories: other.categories,
							brands: other.brands,
							clientIds: other.clientId ? [other.clientId] : [],
							collections: other.collections,
							limitType: other.limitType,
							sort: other.sort,
							type: other.type,
							teamId: data.teamId,
						}),
						"currentUser_productCollectionSearchItems_data"
				  )
				: null
			const rootField = storeProxy.getRootField(
				"createCategorizeLookbookUploads"
			)
			if (!rootField) return
			const itemProxy = rootField.getLinkedRecord("lookbookUploads")
			const newCollectionItem = storeProxy.create(
				`lookbookUploads:connection:${new Date().getTime()}`,
				"lookbookUploads"
			)
			newCollectionItem.setLinkedRecord(itemProxy, "node")
			if(!data.id && searchConnection) {
				ConnectionHandler.insertEdgeBefore(searchConnection, newCollectionItem)
			}
			const stillExists = () =>
				[data.collections.map((i) => i.id), other.collections].reduce(
					(p, c) => p.filter((e) => c.includes(e))
				)
			if (other && other.collections.length > 0 && stillExists().length === 0) {
				if (searchConnection)
					ConnectionHandler.deleteNode(searchConnection, data.id)
			}

			const existingCollections = (data.collections || []).filter((i) => !!i.id)
			if (existingCollections.length > 0) {
				const collection = existingCollections[0]
				const connection = ConnectionHandler.getConnection(
					userProxy,
					"currentUser_closetCollectionSearchItems",
					{
						collectionId: collection.id,
						search: ""
					}
				)
				if (connection) {
					ConnectionHandler.insertEdgeBefore(connection, newCollectionItem)
				}
			}
		},
		onError: complete(onCompleted, onError),
		onCompleted: complete(onCompleted, onError),
	})

export default { commit }
