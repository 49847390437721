import React, { Fragment, useCallback, useState } from "react"
import { toast } from "react-toastify"
import axios from "axios"
import LoadingDots from "components/loading-dots/loading-dots"
import button from "shared/styles/components/buttons.css"
import styles from "./admin-delete-user.css"
import AdminDeleteUserMutation from "../../../mutations/admin/admin-delete-user"
import gqlHelper from "../../../helpers/gql-helper"
import ConformationPrompt from "../conformation-prompt/conformation-prompt"
import { apiUrl } from "../../../constants"
import { withRouter } from "../../../route-helpers"

function AdminUserDeleteModal({ relay, navigate, user }) {
	const [showModal, setShowModal] = useState(false)
	const [data, setData] = useState(null)

	const confirmDeletion = () => {
		AdminDeleteUserMutation.commit(
			relay.environment,
			user,
			(e) => {
				toast.error(
					gqlHelper.getError(e) || "Error deleting user.",
					{ autoClose: 2000, closeButton: false }
				)
			},
			() => {
				toast.success(<div>User deleted successfully</div>, {
					autoClose: 2000,
					closeButton: false,
				})
				navigate("/admin/user")
			}
		)
	}

	const onClick = useCallback(() => {
		setShowModal(true)
		if (!data)
			axios
				.get(`${apiUrl}/admin/user-referrence-count?userId=${user}`)
				.then((res) => {
					setData(res.data)
				})
				.catch((err) => {
					toast.error(err.message, {
						autoClose: 2000,
						closeButton: false,
					})
				})
	}, [data])

	return (
		<Fragment>
			<button type="button" className={button.primaryRed} onClick={onClick}>
				Delete
			</button>
			<ConformationPrompt
				openModal={!!showModal}
				closeModal={() => {
					setShowModal(true)
				}}
				approveEvent={() => {
					confirmDeletion()
					setShowModal(true)
				}}
				declineEvent={() => {
					setShowModal(false)
				}}
				approveText="Delete"
				declineText="Cancel"
				approveButtonStyle={button.primaryRed}
				title="Permanently Delete User"
			>
				{data ? (
					<div className={styles.table}>
						<div className={styles.thead}>
							<div className={styles.tr}>
								<div className={styles.th}>Table</div>
								<div className={styles.th}>Count</div>
							</div>
						</div>
						<div className={styles.tbody}>
							{Object.entries(data).map(([key, value]) => (
								<div className={styles.tr}>
									<div className={styles.td}>{key}</div>
									<div className={styles.td}>{value}</div>
								</div>
							))}
						</div>
					</div>
				) : (
					<LoadingDots color="var(--gray200)" />
				)}
			</ConformationPrompt>
		</Fragment>
	)
}

export default withRouter(AdminUserDeleteModal)
