/**
 * @generated SignedSource<<a6033136f6c937ce67cf1c060742962f>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentType } from "relay-runtime";
declare export opaque type templateList_store$fragmentType: FragmentType;
export type templateList_store$data = {|
  +currentUser: ?{|
    +id: string,
    +lookbookFavourites: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?({|
          +__typename: "LookbookTemplate",
          +id: string,
          +isFavourite: ?boolean,
        |} | {|
          // This will never be '%other', but we need some
          // value in case none of the concrete values match.
          +__typename: "%other",
        |}),
      |}>,
    |},
    +lookbookTemplate: ?{|
      +canEdit: ?boolean,
      +categories: ?$ReadOnlyArray<?string>,
      +createdCount: ?number,
      +id: string,
      +isFavourite: ?boolean,
      +isGlobal: ?boolean,
      +lookbook: ?{|
        +background: ?string,
        +data_json: ?string,
        +group: ?string,
        +height: ?number,
        +id: string,
        +screen_width: ?string,
        +shapes: ?{|
          +edges: ?$ReadOnlyArray<?{|
            +node: ?{|
              +collection: ?string,
              +id: string,
              +path: ?string,
              +viewBox: ?string,
            |},
          |}>,
        |},
        +title: ?string,
        +url: ?string,
        +width: ?number,
      |},
    |},
    +lookbookTemplates: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +canEdit: ?boolean,
          +categories: ?$ReadOnlyArray<?string>,
          +createdCount: ?number,
          +id: string,
          +isFavourite: ?boolean,
          +isGlobal: ?boolean,
          +lookbook: ?{|
            +background: ?string,
            +data_json: ?string,
            +group: ?string,
            +height: ?number,
            +id: string,
            +screen_width: ?string,
            +shapes: ?{|
              +edges: ?$ReadOnlyArray<?{|
                +node: ?{|
                  +collection: ?string,
                  +id: string,
                  +path: ?string,
                  +viewBox: ?string,
                |},
              |}>,
            |},
            +title: ?string,
            +url: ?string,
            +width: ?number,
          |},
        |},
      |}>,
    |},
    +lookbookTemplatesCategories: ?$ReadOnlyArray<?string>,
  |},
  +$fragmentType: templateList_store$fragmentType,
|};
export type templateList_store$key = {
  +$data?: templateList_store$data,
  +$fragmentSpreads: templateList_store$fragmentType,
  ...
};
*/

var node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "categories",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "canEdit",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isFavourite",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "createdCount",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isGlobal",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "Lookbook",
  "kind": "LinkedField",
  "name": "lookbook",
  "plural": false,
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "url",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "screen_width",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "data_json",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "width",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "height",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "background",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "group",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ShapeConnectionConnection",
      "kind": "LinkedField",
      "name": "shapes",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ShapeConnectionEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Shape",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "viewBox",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "path",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "collection",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v7 = {
  "kind": "Variable",
  "name": "teamId",
  "variableName": "teamId"
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": "",
      "kind": "LocalArgument",
      "name": "category"
    },
    {
      "defaultValue": 24,
      "kind": "LocalArgument",
      "name": "count"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "cursor"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "teamId"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "templateId"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "count",
        "cursor": "cursor",
        "direction": "forward",
        "path": [
          "currentUser",
          "lookbookTemplates"
        ]
      }
    ]
  },
  "name": "templateList_store",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "currentUser",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "lookbookTemplatesCategories",
          "storageKey": null
        },
        {
          "alias": null,
          "args": [
            {
              "kind": "Variable",
              "name": "id",
              "variableName": "templateId"
            }
          ],
          "concreteType": "LookbookTemplate",
          "kind": "LinkedField",
          "name": "lookbookTemplate",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            (v1/*: any*/),
            (v2/*: any*/),
            (v3/*: any*/),
            (v4/*: any*/),
            (v5/*: any*/),
            (v6/*: any*/)
          ],
          "storageKey": null
        },
        {
          "alias": "lookbookTemplates",
          "args": [
            {
              "kind": "Variable",
              "name": "category",
              "variableName": "category"
            },
            (v7/*: any*/)
          ],
          "concreteType": "LookbookTemplatesConnection",
          "kind": "LinkedField",
          "name": "__currentUser_lookbookTemplates_connection",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "LookbookTemplatesEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "LookbookTemplate",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    (v1/*: any*/),
                    (v2/*: any*/),
                    (v3/*: any*/),
                    (v4/*: any*/),
                    (v5/*: any*/),
                    (v6/*: any*/),
                    (v8/*: any*/)
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "cursor",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "PageInfo",
              "kind": "LinkedField",
              "name": "pageInfo",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "endCursor",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "hasNextPage",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": [
            {
              "kind": "Literal",
              "name": "category",
              "value": "template"
            },
            (v7/*: any*/)
          ],
          "concreteType": "LookbookFavouritesConnectionConnection",
          "kind": "LinkedField",
          "name": "lookbookFavourites",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "LookbookFavouritesConnectionEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": null,
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    (v8/*: any*/),
                    {
                      "kind": "InlineFragment",
                      "selections": [
                        (v0/*: any*/),
                        (v3/*: any*/)
                      ],
                      "type": "LookbookTemplate",
                      "abstractKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Store",
  "abstractKey": null
};
})();

(node/*: any*/).hash = "1460b940d4ef7e396eda510eeda7f3f0";

module.exports = ((node/*: any*/)/*: Fragment<
  templateList_store$fragmentType,
  templateList_store$data,
>*/);
