/**
 * @generated SignedSource<<1f5f1c8b0dc53f96c052470428db8a9f>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Mutation } from 'relay-runtime';
export type ArchiveShopcastItemInput = {|
  clientMutationId?: ?string,
  id: string,
  type: string,
|};
export type archiveShopcastItemMutation$variables = {|
  input: ArchiveShopcastItemInput,
|};
export type archiveShopcastItemMutation$data = {|
  +archiveShopcastItem: ?{|
    +output: ?string,
  |},
|};
export type archiveShopcastItemMutation = {|
  response: archiveShopcastItemMutation$data,
  variables: archiveShopcastItemMutation$variables,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "ArchiveShopcastItemPayload",
    "kind": "LinkedField",
    "name": "archiveShopcastItem",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "output",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "archiveShopcastItemMutation",
    "selections": (v1/*: any*/),
    "type": "ShopshareMutationRoot",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "archiveShopcastItemMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "ad46f42dcb7e4943cb68917c130e3400",
    "id": null,
    "metadata": {},
    "name": "archiveShopcastItemMutation",
    "operationKind": "mutation",
    "text": "mutation archiveShopcastItemMutation(\n  $input: ArchiveShopcastItemInput!\n) {\n  archiveShopcastItem(input: $input) {\n    output\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "4a3275cc498578956d8c746b704e4abf";

module.exports = ((node/*: any*/)/*: Mutation<
  archiveShopcastItemMutation$variables,
  archiveShopcastItemMutation$data,
>*/);
