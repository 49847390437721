import React from "react"
import queryString from "query-string"
import {
	EnvelopeClosedIcon,
	ExclamationTriangleIcon,
	LinkBreak1Icon,
	CheckCircledIcon,
} from "@radix-ui/react-icons"
import layout from "shared/styles/components/layout.css"
import empty from "shared/styles/components/empty.css"
import { withRouter } from "../../route-helpers"

function Verify({ location }) {
	const qs = queryString.parse(location.search)
	return (
		<div className={`${layout.content} ${layout.offset}`}>
			<div className={empty.root}>
				<div className={empty.container}>
					<div className={empty.content}>
						{qs.message === "Activated" && (
							<>
								<CheckCircledIcon className={empty.iconSuccess} />
								<h4 className={empty.headline}>
									Your email address has been verified!
								</h4>
							</>
						)}
						{qs.message === "NotFound" && (
							<>
								<LinkBreak1Icon className={empty.iconError} />
								<h4 className={empty.headline}>
									The link you have followed was not found!
								</h4>
							</>
						)}
						{qs.message === "CodeExpired" && (
							<>
								<ExclamationTriangleIcon className={empty.icon} />
								<h4 className={empty.headline}>
									The link you have followed has expired!
								</h4>
							</>
						)}
						{qs.message === "EmailUsed" && (
							<>
								<EnvelopeClosedIcon className={empty.icon} />
								<h4 className={empty.headline}>
									Email already in use!
								</h4>
							</>
						)}
					</div>
				</div>
			</div>
		</div>
	)
}

export default {
	Component: withRouter(Verify),
}
