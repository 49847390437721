/**
 * @generated SignedSource<<9e5963bdb0e576c69531bb0e8b725119>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Mutation } from 'relay-runtime';
export type TeamBannerPhotoInput = {|
  clientMutationId?: ?string,
  imageName?: ?string,
  teamId?: ?string,
|};
export type uploadTeamBannerMutation$variables = {|
  input: TeamBannerPhotoInput,
|};
export type uploadTeamBannerMutation$data = {|
  +teamBannerPhoto: ?{|
    +store: ?{|
      +id: string,
    |},
    +team: ?{|
      +bannerPhoto: ?string,
      +id: string,
    |},
  |},
|};
export type uploadTeamBannerMutation = {|
  response: uploadTeamBannerMutation$data,
  variables: uploadTeamBannerMutation$variables,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "TeamBannerPhotoPayload",
    "kind": "LinkedField",
    "name": "teamBannerPhoto",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Store",
        "kind": "LinkedField",
        "name": "store",
        "plural": false,
        "selections": [
          (v1/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "team",
        "kind": "LinkedField",
        "name": "team",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "bannerPhoto",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "uploadTeamBannerMutation",
    "selections": (v2/*: any*/),
    "type": "ShopshareMutationRoot",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "uploadTeamBannerMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "fa7dfd16e002139d5081fb9cdbae2815",
    "id": null,
    "metadata": {},
    "name": "uploadTeamBannerMutation",
    "operationKind": "mutation",
    "text": "mutation uploadTeamBannerMutation(\n  $input: TeamBannerPhotoInput!\n) {\n  teamBannerPhoto(input: $input) {\n    store {\n      id\n    }\n    team {\n      id\n      bannerPhoto\n    }\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "5c89c18d06591c9b661f343c61c5508a";

module.exports = ((node/*: any*/)/*: Mutation<
  uploadTeamBannerMutation$variables,
  uploadTeamBannerMutation$data,
>*/);
