/**
 * @generated SignedSource<<cbd24ac67ea4a2bb73f00c8ad1ab86a8>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Mutation } from 'relay-runtime';
import type { userShopcastCard_shopcast$fragmentType } from "./../../../components/cards/user-shopcast/__generated__/userShopcastCard_shopcast.graphql";
export type CreateShopcastInput = {|
  categoryId: string,
  clientMutationId?: ?string,
  description?: ?string,
  duration?: ?number,
  folderId?: ?string,
  isPublished?: ?boolean,
  size?: ?number,
  teamId?: ?string,
  title: string,
|};
export type createShopcastMutation$variables = {|
  input: CreateShopcastInput,
|};
export type createShopcastMutation$data = {|
  +createShopcast: ?{|
    +shopcast: ?{|
      +id: string,
      +$fragmentSpreads: userShopcastCard_shopcast$fragmentType,
    |},
    +store: ?{|
      +id: string,
    |},
  |},
|};
export type createShopcastMutation = {|
  response: createShopcastMutation$data,
  variables: createShopcastMutation$variables,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "Store",
  "kind": "LinkedField",
  "name": "store",
  "plural": false,
  "selections": [
    (v2/*: any*/)
  ],
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "createShopcastMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateShopcastPayload",
        "kind": "LinkedField",
        "name": "createShopcast",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Shopcast",
            "kind": "LinkedField",
            "name": "shopcast",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "userShopcastCard_shopcast"
              }
            ],
            "storageKey": null
          },
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "ShopshareMutationRoot",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "createShopcastMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateShopcastPayload",
        "kind": "LinkedField",
        "name": "createShopcast",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Shopcast",
            "kind": "LinkedField",
            "name": "shopcast",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "title",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "description",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "posterUrl",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isPublished",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isPinned",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "transcoded",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "duration",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isFlagged",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "views",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "likes",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isArchived",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isStarred",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "inRoot",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "youtubeUploadStatus",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Category",
                "kind": "LinkedField",
                "name": "category",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v4/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "SiteTransactionObj",
                "kind": "LinkedField",
                "name": "transactions",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "orderId",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "User",
                "kind": "LinkedField",
                "name": "user",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "username",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "customDomain",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "ShopcastFolder",
                "kind": "LinkedField",
                "name": "folder",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "NameValue",
                    "kind": "LinkedField",
                    "name": "path",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "value",
                        "storageKey": null
                      },
                      (v4/*: any*/),
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": [
                  {
                    "kind": "Literal",
                    "name": "first",
                    "value": 100
                  }
                ],
                "concreteType": "ShopcastItemConnection",
                "kind": "LinkedField",
                "name": "shopcastItems",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ShopcastItemEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "shopcastItem",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "start",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "end",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "ShopcastProduct",
                            "kind": "LinkedField",
                            "name": "product",
                            "plural": false,
                            "selections": [
                              (v2/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "imageUrl",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "shopcastItems(first:100)"
              }
            ],
            "storageKey": null
          },
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "d2ee0cf2ac1f1937080e13e983d89f1d",
    "id": null,
    "metadata": {},
    "name": "createShopcastMutation",
    "operationKind": "mutation",
    "text": "mutation createShopcastMutation(\n  $input: CreateShopcastInput!\n) {\n  createShopcast(input: $input) {\n    shopcast {\n      id\n      ...userShopcastCard_shopcast\n    }\n    store {\n      id\n    }\n  }\n}\n\nfragment userShopcastCard_shopcast on Shopcast {\n  id\n  title\n  description\n  posterUrl\n  isPublished\n  isPinned\n  transcoded\n  duration\n  isFlagged\n  views\n  likes\n  isArchived\n  isStarred\n  inRoot\n  youtubeUploadStatus\n  category {\n    id\n    name\n  }\n  transactions {\n    id\n    orderId\n  }\n  user {\n    id\n    username\n    customDomain\n  }\n  folder {\n    name\n    id\n    path {\n      value\n      name\n      id\n    }\n  }\n  shopcastItems(first: 100) {\n    edges {\n      node {\n        id\n        start\n        end\n        product {\n          id\n          imageUrl\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "67ab159c4f4f84bd688ebb1f48bc0da4";

module.exports = ((node/*: any*/)/*: Mutation<
  createShopcastMutation$variables,
  createShopcastMutation$data,
>*/);
