import React, { Component } from "react"
import ReactPlayer from "react-player"
import vTime from "video-time"
import { checkIsAudio } from "helpers/lookbook-helper"
import PlayButtonLarge from "./play-button-large/play-button-large"
import VolumeButton from "./volume-button/volume-button"
import SubtitleButton from "./subtitle-button/subtitle-button"
import PlayButton from "./play-button/play-button"
import styles from "./widget-player.css"

export default class WidgetPlayer extends Component {
	constructor(props) {
		super(props)
		this.state = {
			isPlaying: props.autoPlay,
			muted: false,
			played: 0,
			playedSeconds: 0,
			loaded: 0,
			seeking: false,
			mouseOver: false,
			subtitleOn: false,
		}
		this.handleProgress = this.handleProgress.bind(this)
		this.handleSeekMouseDown = this.handleSeekMouseDown.bind(this)
		this.handleSeekMouseUp = this.handleSeekMouseUp.bind(this)
		this.handleSeekChange = this.handleSeekChange.bind(this)
		this.onEnded = this.onEnded.bind(this)
		this.renderAudioPlayer = this.renderAudioPlayer.bind(this)
		this.renderVideoPlayer = this.renderVideoPlayer.bind(this)
	}

	handleSeekChange(e) {
		this.setState({ played: parseFloat(e.target.value / 100) })
	}

	handleSeekMouseUp(e) {
		this.setState({ seeking: false })
		this.player.seekTo(parseFloat(e.target.value) / 100)
	}

	handleSeekMouseDown() {
		this.setState({ seeking: true })
	}

	handleProgress(state) {
		const { seeking } = this.state
		if (!seeking) this.setState(state)
	}

	onEnded() {
		this.setState({ isPlaying: false })
	}

	getPlayerConfig() {
		const { subtitleOn } = this.state
		const { poster, subtitle, muxedUrl, isAudio } = this.props
		const config = {
			file: {
				attributes: {
					crossOrigin: "true",
				},
			},
		}
		if (isAudio || checkIsAudio(muxedUrl)) {
			config.file.forceAudio = true
			return config
		}
		if (subtitle)
			config.file.tracks = [
				{
					kind: "subtitles",
					src: subtitle,
					srcLang: "en",
					default: false,
					mode: subtitleOn ? "showing" : "hidden",
				},
			]
		if (poster) config.file.attributes.poster = poster
		return config
	}

	renderAudioPlayer() {
		const { playedSeconds, muted, isPlaying, played, loaded } = this.state
		const { muxedUrl, rounded } = this.props
		return (
			<div className={rounded ? styles.audioRounded : styles.audio}>
				<ReactPlayer
					url={muxedUrl}
					ref={(ref) => {
						this.player = ref
					}}
					playing={isPlaying}
					muted={muted}
					width="100%"
					height="100%"
					playsinline
					onProgress={this.handleProgress}
					onEnded={this.onEnded}
					config={this.getPlayerConfig()}
				/>
				<div className={styles.audioMedia}>
					<PlayButton
						isPlaying={isPlaying}
						handlePlayPause={() => {
							this.setState({ isPlaying: !isPlaying })
						}}
					/>
					<div className={styles.time}>
						<span className={styles.startTime}>
							{vTime(playedSeconds)}
						</span>
					</div>
					<div className={styles.progress}>
						<input
							className={styles.seek}
							type="range"
							style={{
								"--widget-seek-value": `${played * 100}%`,
							}}
							min="0"
							max="100"
							value={played * 100}
							autoComplete="off"
							onMouseDown={this.handleSeekMouseDown}
							onChange={this.handleSeekChange}
							onMouseUp={this.handleSeekMouseUp}
						/>
						<progress
							className={styles.buffer}
							min="0"
							max="1"
							value={loaded}
							aria-hidden="true"
						/>
					</div>
					<div className={styles.controls}>
						<VolumeButton
							isMute={muted}
							toggleMute={() => {
								this.setState({ muted: !muted })
							}}
						/>
					</div>
				</div>
			</div>
		)
	}

	renderVideoPlayer() {
		const {
			isPlaying,
			muted,
			played,
			loaded,
			playedSeconds,
			mouseOver,
			subtitleOn,
		} = this.state
		const { muxedUrl, subtitle, rounded } = this.props
		return (
			<div className={rounded ? styles.videoRounded : styles.video}>
				<PlayButtonLarge
					isPlaying={isPlaying}
					handlePlayPause={() => {
						this.setState({ isPlaying: !isPlaying })
					}}
				/>
				<ReactPlayer
					url={muxedUrl}
					ref={(ref) => {
						this.player = ref
					}}
					playing={isPlaying}
					className={styles.source}
					muted={muted}
					width="100%"
					height="100%"
					playsinline
					onProgress={this.handleProgress}
					onEnded={this.onEnded}
					config={this.getPlayerConfig()}
				/>
				<div className={`${styles.media} ${!mouseOver && styles.mediaOut}`}>
					<PlayButton
						isPlaying={isPlaying}
						handlePlayPause={() => {
							this.setState({ isPlaying: !isPlaying })
						}}
					/>
					<div className={styles.time}>
						<span className={styles.startTime}>
							{vTime(playedSeconds)}
						</span>
					</div>
					<div className={styles.progress}>
						<input
							className={styles.seek}
							type="range"
							style={{
								"--widget-seek-value": `${played * 100}%`,
							}}
							min="0"
							max="100"
							value={played * 100}
							autoComplete="off"
							onMouseDown={this.handleSeekMouseDown}
							onChange={this.handleSeekChange}
							onMouseUp={this.handleSeekMouseUp}
						/>
						<progress
							className={styles.buffer}
							min="0"
							max="1"
							value={loaded}
							aria-hidden="true"
						/>
					</div>
					<div className={styles.controls}>
						<VolumeButton
							isMute={muted}
							toggleMute={() => {
								this.setState({ muted: !muted })
							}}
						/>
						{subtitle && (
							<SubtitleButton
								isSubtitle={subtitleOn}
								toggleSubtitle={() =>
									this.setState({ subtitleOn: !subtitleOn }, () => {
										const { subtitleOn: stateSubtitleOn } = this.state
										const tracks = this.player.getInternalPlayer()
											?.textTracks
										if (tracks && tracks.length > 0) {
											tracks[0].mode = stateSubtitleOn
												? "showing"
												: "hidden"
										}
									})
								}
							/>
						)}
					</div>
				</div>
			</div>
		)
	}

	render() {
		const { muxedUrl, dark, isAudio } = this.props
		return (
			<div
				className={dark ? styles.rootDark : styles.root}
				onMouseOver={() => {
					this.setState({ mouseOver: true })
				}}
				onFocus={() => {
					this.setState({ mouseOver: true })
				}}
				onMouseOut={() => {
					this.setState({ mouseOver: false })
				}}
				onBlur={() => {
					this.setState({ mouseOver: false })
				}}
			>
				{isAudio || checkIsAudio(muxedUrl)
					? this.renderAudioPlayer()
					: this.renderVideoPlayer()}
			</div>
		)
	}
}
