/**
 * @generated SignedSource<<24fe0152691b6153b750235176c7da26>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentType } from "relay-runtime";
declare export opaque type imageList_store$fragmentType: FragmentType;
export type imageList_store$data = {|
  +currentUser: ?{|
    +adminAccess: ?string,
    +id: string,
    +productCollectionSearchItems: ?{|
      +collections: ?{|
        +edges: ?$ReadOnlyArray<?{|
          +node: ?{|
            +folder: ?{|
              +id: string,
              +name: ?string,
            |},
            +id: string,
            +isACloset: ?boolean,
            +isDefault: ?boolean,
            +isPublished: ?boolean,
            +title: ?string,
            +user: ?{|
              +fullname: ?string,
              +id: string,
            |},
          |},
        |}>,
      |},
      +data: ?{|
        +edges: ?$ReadOnlyArray<?{|
          +node: ?({|
            +__typename: "ShopcastProduct",
            +affiliated_url: ?string,
            +brand: ?string,
            +custom_affiliated_url: ?string,
            +description: ?string,
            +diffbotImageUrl: ?string,
            +error: ?string,
            +hasProductImagesFromDiffbot: ?boolean,
            +id: string,
            +imageUrl: ?string,
            +isFavourite: ?boolean,
            +offerPrice: ?string,
            +productCollections: ?$ReadOnlyArray<?{|
              +id: string,
              +isACloset: ?boolean,
              +isPublished: ?boolean,
              +title: ?string,
            |}>,
            +productImages: ?$ReadOnlyArray<?string>,
            +regularPrice: ?string,
            +title: ?string,
            +url: ?string,
            +user: ?{|
              +client: ?{|
                +avatar: ?string,
                +fullname: ?string,
              |},
              +fullname: ?string,
              +id: string,
              +profilePhoto: ?string,
            |},
          |} | {|
            +__typename: "lookbookUploads",
            +adminAccess: ?boolean,
            +canEdit: ?boolean,
            +id: string,
            +isFavourite: ?boolean,
            +productCollections: ?$ReadOnlyArray<?{|
              +id: string,
              +isACloset: ?boolean,
              +isPublished: ?boolean,
              +title: ?string,
            |}>,
            +title: ?string,
            +url: ?string,
            +user: ?{|
              +client: ?{|
                +avatar: ?string,
                +fullname: ?string,
              |},
              +fullname: ?string,
              +id: string,
              +profilePhoto: ?string,
            |},
          |} | {|
            // This will never be '%other', but we need some
            // value in case none of the concrete values match.
            +__typename: "%other",
          |}),
        |}>,
      |},
    |},
    +productCollections: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +client: ?{|
            +fullname: ?string,
            +id: string,
          |},
          +createdAt: ?string,
          +id: string,
          +isACloset: ?boolean,
          +isDefault: ?boolean,
          +isPublished: ?boolean,
          +title: ?string,
          +updatedAt: ?string,
          +user: ?{|
            +fullname: ?string,
            +id: string,
          |},
        |},
      |}>,
    |},
    +userClients: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +email: ?string,
          +fullname: ?string,
          +id: string,
        |},
      |}>,
    |},
  |},
  +$fragmentType: imageList_store$fragmentType,
|};
export type imageList_store$key = {
  +$data?: imageList_store$data,
  +$fragmentSpreads: imageList_store$fragmentType,
  ...
};
*/

var node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "adminAccess",
  "storageKey": null
},
v2 = {
  "kind": "Variable",
  "name": "teamId",
  "variableName": "teamId"
},
v3 = [
  (v2/*: any*/)
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "fullname",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isDefault",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isACloset",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isPublished",
  "storageKey": null
},
v9 = [
  (v0/*: any*/),
  (v4/*: any*/)
],
v10 = {
  "alias": null,
  "args": null,
  "concreteType": "User",
  "kind": "LinkedField",
  "name": "user",
  "plural": false,
  "selections": (v9/*: any*/),
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "url",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "concreteType": "ProductCollection",
  "kind": "LinkedField",
  "name": "productCollections",
  "plural": true,
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "ownershipCheck",
          "value": true
        }
      ],
      "kind": "ScalarField",
      "name": "title",
      "storageKey": "title(ownershipCheck:true)"
    },
    (v7/*: any*/),
    (v8/*: any*/)
  ],
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "concreteType": "User",
  "kind": "LinkedField",
  "name": "user",
  "plural": false,
  "selections": [
    (v0/*: any*/),
    (v4/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "profilePhoto",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Client",
      "kind": "LinkedField",
      "name": "client",
      "plural": false,
      "selections": [
        (v4/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "avatar",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": (v3/*: any*/),
  "kind": "ScalarField",
  "name": "isFavourite",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "clients"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "collections"
    },
    {
      "defaultValue": 12,
      "kind": "LocalArgument",
      "name": "count"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "cursor"
    },
    {
      "defaultValue": "",
      "kind": "LocalArgument",
      "name": "search"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "sort"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "teamId"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "type"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "count",
        "cursor": "cursor",
        "direction": "forward",
        "path": [
          "currentUser",
          "productCollectionSearchItems",
          "data"
        ]
      }
    ]
  },
  "name": "imageList_store",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "currentUser",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        {
          "alias": null,
          "args": (v3/*: any*/),
          "concreteType": "ClientConnectionConnection",
          "kind": "LinkedField",
          "name": "userClients",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ClientConnectionEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Client",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    (v4/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "email",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": (v3/*: any*/),
          "concreteType": "ProductCollectionConnection",
          "kind": "LinkedField",
          "name": "productCollections",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ProductCollectionEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "ProductCollection",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    (v5/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "createdAt",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "updatedAt",
                      "storageKey": null
                    },
                    (v6/*: any*/),
                    (v7/*: any*/),
                    (v8/*: any*/),
                    (v10/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "Client",
                      "kind": "LinkedField",
                      "name": "client",
                      "plural": false,
                      "selections": (v9/*: any*/),
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": [
            {
              "kind": "Literal",
              "name": "brands",
              "value": []
            },
            {
              "kind": "Literal",
              "name": "categories",
              "value": []
            },
            {
              "kind": "Variable",
              "name": "clientIds",
              "variableName": "clients"
            },
            {
              "kind": "Variable",
              "name": "collections",
              "variableName": "collections"
            },
            {
              "kind": "Literal",
              "name": "limitType",
              "value": [
                "collections",
                "closet"
              ]
            },
            {
              "kind": "Variable",
              "name": "search",
              "variableName": "search"
            },
            {
              "kind": "Variable",
              "name": "sort",
              "variableName": "sort"
            },
            (v2/*: any*/),
            {
              "kind": "Variable",
              "name": "type",
              "variableName": "type"
            }
          ],
          "concreteType": "ProductCollectionProductSearch",
          "kind": "LinkedField",
          "name": "productCollectionSearchItems",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": [
                {
                  "kind": "Literal",
                  "name": "search",
                  "value": ""
                }
              ],
              "concreteType": "ProductCollectionConnection",
              "kind": "LinkedField",
              "name": "collections",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "ProductCollectionEdge",
                  "kind": "LinkedField",
                  "name": "edges",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "ProductCollection",
                      "kind": "LinkedField",
                      "name": "node",
                      "plural": false,
                      "selections": [
                        (v0/*: any*/),
                        (v5/*: any*/),
                        (v6/*: any*/),
                        (v7/*: any*/),
                        (v8/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "ShopcastFolder",
                          "kind": "LinkedField",
                          "name": "folder",
                          "plural": false,
                          "selections": [
                            (v0/*: any*/),
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "name",
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        },
                        (v10/*: any*/)
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": "collections(search:\"\")"
            },
            {
              "alias": "data",
              "args": null,
              "concreteType": "ProductCollectionItemConnection",
              "kind": "LinkedField",
              "name": "__currentUser_productCollectionSearchItems_data_connection",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "ProductCollectionItemEdge",
                  "kind": "LinkedField",
                  "name": "edges",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": null,
                      "kind": "LinkedField",
                      "name": "node",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "__typename",
                          "storageKey": null
                        },
                        {
                          "kind": "InlineFragment",
                          "selections": [
                            (v0/*: any*/),
                            (v5/*: any*/),
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "brand",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "description",
                              "storageKey": null
                            },
                            (v11/*: any*/),
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "affiliated_url",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "custom_affiliated_url",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "imageUrl",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "diffbotImageUrl",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "regularPrice",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "offerPrice",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "error",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "productImages",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "hasProductImagesFromDiffbot",
                              "storageKey": null
                            },
                            (v12/*: any*/),
                            (v13/*: any*/),
                            (v14/*: any*/)
                          ],
                          "type": "ShopcastProduct",
                          "abstractKey": null
                        },
                        {
                          "kind": "InlineFragment",
                          "selections": [
                            (v0/*: any*/),
                            (v1/*: any*/),
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "canEdit",
                              "storageKey": null
                            },
                            (v5/*: any*/),
                            (v12/*: any*/),
                            (v13/*: any*/),
                            (v11/*: any*/),
                            (v14/*: any*/)
                          ],
                          "type": "lookbookUploads",
                          "abstractKey": null
                        }
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "cursor",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "PageInfo",
                  "kind": "LinkedField",
                  "name": "pageInfo",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "endCursor",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "hasNextPage",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Store",
  "abstractKey": null
};
})();

(node/*: any*/).hash = "e5f676fc04148ff53e9cda70dd6874a3";

module.exports = ((node/*: any*/)/*: Fragment<
  imageList_store$fragmentType,
  imageList_store$data,
>*/);
