import { commitMutation, ConnectionHandler, graphql } from "react-relay"
import { complete } from "../helper"

const mutation = graphql`
	mutation deleteClientMutation($input: DeleteClientInput!) {
		deleteClient(input: $input) {
			store {
				id
			}
		}
	}
`

const commit = (env, id, onError, onCompleted, other = {}) =>
	commitMutation(env, {
		mutation,
		variables: { input: { id } },
		onCompleted: complete(onCompleted, onError),
		onError: (e) => complete(null, [e]),
		updater: (storeProxy, dd) => {
			if (dd.deleteClient) {
				const client = storeProxy.get(id)
				const folder = client.getLinkedRecords("folders")[0]
				const currentUser = storeProxy
					.getRoot()
					.getLinkedRecord("store")
					.getLinkedRecord("currentUser")
				const parentFolder = currentUser
					?.getLinkedRecord("shopcastContainer")
					?.getLinkedRecord("folder")
				parentFolder.setValue(parentFolder.getValue("clientsCount") - 1, "clientsCount")
				const clientFolderConnection = parentFolder
					? ConnectionHandler.getConnection(
							parentFolder,
							"currentUser_listOfFolder_folders",
							{
								onlyClients: true,
								isShared: null,
							}
					  )
					: null
				if (folder && clientFolderConnection)
					ConnectionHandler.deleteNode(
						clientFolderConnection,
						folder.getDataID()
					)
					
				const connection = ConnectionHandler.getConnection(
					currentUser,
					"currentUser_userClients",
					other
				)
				if (connection) ConnectionHandler.deleteNode(connection, id)
			}
		},
	})

export default { commit }
