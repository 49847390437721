import React, { Fragment } from "react"
import { Transition } from "react-transition-group"
import { createFragmentContainer, graphql } from "react-relay"
import { Link } from "react-router-dom"
import ClipboardButton from "react-clipboard.js"
import { toast } from "react-toastify"
import gql from "helpers/gql-helper"
import { addVersioning } from "helpers/query-parameters-helper"
import button from "shared/styles/components/buttons.css"
import input from "shared/styles/components/input.css"
import card from "shared/styles/components/card.css"
import label from "shared/styles/components/label.css"
import DropdownMenu from "components/dropdown-menu/dropdown-menu"
import stylesDropdown from "components/dropdown-menu/dropdown-menu-dark.css"
import MenuIcon from "shared/assets/menu-dots-horizontal.svg"
import FolderIcon from "shared/assets/folder-icon.svg"
import ShareIcon from "shared/assets/share-icon.svg"
import PinterestIcon from "shared/assets/pinterest-icon.svg"
import FacebookIcon from "shared/assets/facebook-circle-icon.svg"
import InstagramIcon from "shared/assets/instagram-icon.svg"
import {
	PlusIcon,
	MinusIcon,
	CrumpledPaperIcon,
	DownloadIcon,
	ResetIcon,
	ImageIcon,
	Link1Icon,
	CheckCircledIcon,
	CodeIcon,
	TrashIcon,
	EnterIcon,
	Pencil1Icon,
	CopyIcon,
	Pencil2Icon,
	FileIcon,
	ArchiveIcon,
} from "@radix-ui/react-icons"
import PinIcon from "shared/assets/pin-icon.svg"
import UnPinIcon from "shared/assets/unpin-icon.svg"
// import { uniqBy } from "lodash"
import EmbedShopcast from "components/modals/embed-shopcast/embed-shopcast"
// import Views from "components/views/views"
import SalesCount from "components/sales-count/sales-count"
import BadgePageCount from "components/badge-page-count/badge-page-count"
import BadgeVideo from "components/badge-video/badge-video"
import ConformationPrompt from "components/modals/conformation-prompt/conformation-prompt"
import { ShowCardControlsComponent } from "helpers/ui-helper"
import ContentEditable from "components/content-editable/content-editable"
import { CanvasSizeList } from "components/lookbook-dropdown/canvas-size-picker"
import toggleLookbookPublishedState from "mutations/lookbook/toggle-lookbook-published"
import PinButton from "components/buttons/pin-button"
import moveShopcastItemsMutation from "mutations/shopcast-manager/move-shopcast-items"
import archiveShopcastItemMutation from "mutations/shopcast-manager/archive-shopcast-item"
import restoreShopcastItemMutation from "mutations/shopcast-manager/restore-shopcast-item"
import duplicateLookbookMutation from "mutations/lookbook/duplicate-lookbook"
import UploadToPinterestMutation from "mutations/user/upload-to-pinterest"
import RemoveFromPinterestMutation from "mutations/user/remove-from-pinterest"
import deleteLookbookMutation from "mutations/shopcast-manager/delete-lookbook"
import ShareLookbookToFacebookMutation from "mutations/lookbook/share-lookbook-to-facebook"
import updateLookbookDetails from "mutations/lookbook/update-lookbook-details"
import CopyToMutation from "mutations/shopcast-manager/copy-to"
import DownloadLookbook from "components/modals/download-lookbook/download-lookbook"
import { withDrag } from "helpers/dnd-helper"
import {
	publicViewDomain,
	socialMedia,
	subdomainValidationRegex,
	textStrings,
	getBaseUrl,
} from "constants"
import styles from "./user-lookbook-card.css"
import { withRouter } from "../../../route-helpers"

function collect(monitor) {
	return {
		isDragging: monitor.isDragging(),
	}
}

class UserLookbookCard extends React.Component {
	constructor(props) {
		super(props)

		this.type = "lookbook"

		this.togglePublishState = this.togglePublishState.bind(this)
		this.handleMoveClick = this.handleMoveClick.bind(this)
		this.handleCopyClick = this.handleCopyClick.bind(this)
		this.handleArchiveClick = this.handleArchiveClick.bind(this)
		this.handleCheckboxChange = this.handleCheckboxChange.bind(this)
		this.handleRestoreClick = this.handleRestoreClick.bind(this)
		this.handleDeleteClick = this.handleDeleteClick.bind(this)
		this.deleteLookbook = this.deleteLookbook.bind(this)
		this.shareOnFacebook = this.shareOnFacebook.bind(this)
		this.handleEmbedClick = this.handleEmbedClick.bind(this)
		this.closeEmbedModal = this.closeEmbedModal.bind(this)
		this.handleCopySuccess = this.handleCopySuccess.bind(this)
		this.scheduleUploadToPinterest = this.scheduleUploadToPinterest.bind(this)
		this.updateLookbookTitle = this.updateLookbookTitle.bind(this)

		this.state = {
			showEmbedModal: false,
			confirmModal: false,
			downloadModal: false,
			titleInFocus: false,
			isDropdownOpen: false,
		}
	}

	//
	// ─── EVENT HANDLERS ─────────────────────────────────────────────────────────────
	//

	handleCheckboxChange({ target }) {
		const { checked } = target
		const { onSelect, onDeselect, lookbook } = this.props
		;(checked ? onSelect : onDeselect)({
			id: lookbook.id,
			isArchived: lookbook.isArchived,
			type: this.type,
			deselect: () => {
				this.handleCheckboxChange({ target: { checked: false } })
			},
		})
	}

	handleMoveClick() {
		const {
			requestMove,
			relay,
			lookbook,
			parentId,
			reportError,
			containerId,
			team,
		} = this.props
		requestMove((folderId) => {
			moveShopcastItemsMutation.commit(
				relay.environment,
				[{ id: lookbook.id, type: this.type }],
				folderId,
				team,
				parentId,
				containerId,
				reportError("Could not move Shopboard")
			)
		})
	}

	handleCopyClick() {
		const { requestCopy, relay, lookbook, reportError } = this.props
		requestCopy((team) => {
			CopyToMutation.commit(
				relay.environment,
				[lookbook.id],
				team.id,
				reportError("Could not copy lookbook"),
				() => {
					toast.success(<>Successfully copied</>, {
						autoClose: 2000,
						closeButton: false,
					})
				}
			)
		})
	}

	handleArchiveClick() {
		const { relay, lookbook, parentId, reportError, containerId } = this.props
		archiveShopcastItemMutation.commit(
			relay.environment,
			lookbook.id,
			this.type,
			parentId,
			reportError("Could not archive Shopboard"),
			containerId,
			() => {}
		)
	}

	handleRestoreClick() {
		const { relay, reportError, parentId, lookbook, containerId } = this.props
		restoreShopcastItemMutation.commit(
			relay.environment,
			lookbook.id,
			this.type,
			parentId,
			reportError("Could not restore Shopboard"),
			containerId,
			() => {
				toast.success(
					<>
						Moved to{" "}
						{lookbook.inRoot
							? textStrings.clientWardrobeName
							: [...lookbook.folder.path]
									.reverse()
									.map((i) => i.name)
									.join(" / ")}
					</>,
					{
						autoClose: 2000,
						closeButton: false,
					}
				)
			}
		)
	}

	handleDeleteClick() {
		const { confirmDelete, lookbook } = this.props
		confirmDelete({
			type: this.type,
			name: lookbook.title,
			callback: this.deleteLookbook,
		})
	}

	handleEmbedClick() {
		this.setState({ showEmbedModal: true })
	}

	handleCopySuccess() {
		toast.success(<>Copied</>, {
			autoClose: 2000,
			closeButton: false,
		})
	}

	//
	// ─── METHODS ────────────────────────────────────────────────────────────────────
	//

	deleteLookbook() {
		const { relay, parentId, reportError, lookbook, containerId } = this.props

		deleteLookbookMutation.commit(
			relay.environment,
			lookbook.id,
			parentId,
			lookbook.isArchived ? null : containerId,
			reportError("Could not delete Shopboard"),
			() => {}
		)
	}

	shareOnFacebook(pageObj) {
		const { relay, reportError } = this.props
		return new Promise((resolve, reject) => {
			ShareLookbookToFacebookMutation.commit(
				relay.environment,
				{
					id: pageObj.lookbookPage,
					description: pageObj.description,
					page:
						pageObj.platform === "facebook"
							? pageObj.fbPage.id
							: pageObj.fbPage.instagramBusinessAccount,
					accessToken: pageObj.fbPage.accessToken,
					uploadAs: pageObj.uploadAs,
					platform: pageObj.platform,
				},
				resolve,
				() => {
					reportError(
						`Could not post Shopboard to ${
							socialMedia[pageObj.platform].name
						}`
					)()
					reject()
				}
			)
		}).then(() => {
			toast.success(
				<>{socialMedia[pageObj.platform].name} post created successfully</>,
				{
					autoClose: 5000,
					closeButton: false,
				}
			)
		})
	}

	closeEmbedModal() {
		this.setState({ showEmbedModal: false })
	}

	togglePublishState() {
		const { lookbook, relay, reportError, containerId, parentId, routeType } =
			this.props

		toggleLookbookPublishedState.commit(
			relay.environment,
			lookbook.id,
			parentId,
			containerId,
			routeType === "public" && lookbook.isPublished,
			reportError(
				`Could not ${
					lookbook.isPublished ? "un-publish" : "publish"
				} Shopboard`
			)
		)
	}

	requestDuplicate(lookbook) {
		const { relay, parentId } = this.props
		duplicateLookbookMutation.commit(
			relay.environment,
			lookbook.id,
			parentId,
			(e) => {
				toast.info(<>{gql.getError(e)}</>, {
					autoClose: 5000,
					closeButton: false,
				})
			},
			async () => {
				toast.success(<>Successfully duplicated!</>, {
					autoClose: 5000,
					closeButton: false,
				})
			}
		)
	}

	scheduleUploadToPinterest() {
		const {
			lookbook,
			relay,
			connectedToPinterest,
			navigate,
			connectToSocial,
		} = this.props
		if (connectedToPinterest) {
			toast.info(`Creating "${lookbook.title}" Pin`, {
				autoClose: 3000,
				closeButton: false,
			})
			UploadToPinterestMutation.commit(
				relay.environment,
				lookbook.id,
				() =>
					toast.success(`Pin created successfully`, {
						autoClose: 3000,
						closeButton: false,
					}),
				(e) =>
					toast.error(gql.getError(e) || "Error uploading Pin", {
						autoClose: 5000,
						closeButton: false,
					})
			)
		} else if (connectToSocial) {
			connectToSocial({
				platform: "pinterest",
				icon: PinterestIcon,
				iconColor: "var(--pinterest500)",
			})
		} else {
			navigate("/settings/integrations")
		}
	}

	updateLookbookTitle(title) {
		const { lookbook, relay } = this.props
		updateLookbookDetails.commit(
			relay.environment,
			{
				title,
				description: lookbook.description,
				id: lookbook.id,
			},
			(e) => {
				console.log(e)
			},
			() => {
				toast.success("Saved", {
					autoClose: 2000,
					closeButton: false,
				})
			}
		)
	}

	//
	// ─── RENDERS ────────────────────────────────────────────────────────────────────
	//

	renderMenu() {
		const {
			lookbook,
			requestEdit,
			requestEditDetails,
			togglePinned,
			isSelected,
			searchValue,
			routeType,
			navigate,
			isParentAClient,
			requestCopy,
		} = this.props
		const listItems = []
		const push = (click, name, key, icon) =>
			listItems.push({
				click,
				name,
				key,
				icon,
			})
		const icon = <MenuIcon className={stylesDropdown.icon} />
		if (lookbook.isArchived) {
			if (routeType === "archived")
				push(
					this.handleRestoreClick,
					"Restore",
					"restore",
					<ResetIcon className={`${stylesDropdown.itemIcon}`} />
				)
			push(
				this.handleDeleteClick,
				"Delete forever",
				"delete",
				<TrashIcon className={`${stylesDropdown.itemIcon}`} />
			)
		} else {
			if (lookbook.folder && searchValue) {
				push(
					() => navigate(`/content/${lookbook.folder.id}`),
					"Go to Folder",
					"gtf",
					<EnterIcon className={`${stylesDropdown.itemIcon}`} />
				)
				listItems.push("divide")
			}
			if (!isParentAClient && !lookbook.isFlagged) {
				const d = lookbook.isPublished
					? {
							key: "private",
							name: "Remove from My Channel",
							icon: <MinusIcon className={stylesDropdown.itemIcon} />,
					  }
					: {
							key: "public",
							name: "Add to My Channel",
							icon: <PlusIcon className={stylesDropdown.itemIcon} />,
					  }
				push(this.togglePublishState, d.name, d.key, d.icon)
				listItems.push("divide")
			}
			push(
				() => requestEdit(lookbook),
				"Edit Board",
				"edit",
				<Pencil2Icon className={`${stylesDropdown.itemIcon}`} />
			)
			push(
				() => requestEditDetails(lookbook),
				"Edit Title and Description",
				"edit-details",
				<Pencil1Icon className={`${stylesDropdown.itemIcon}`} />
			)
			push(
				() => this.requestDuplicate(lookbook),
				"Duplicate Board",
				"duplicate",
				<CopyIcon className={`${stylesDropdown.itemIcon}`} />
			)
			push(
				togglePinned,
				lookbook.isPinned ? "Unpin File" : "Pin File",
				"pin",
				lookbook.isPinned ? (
					<UnPinIcon className={stylesDropdown.itemIcon} />
				) : (
					<PinIcon className={stylesDropdown.itemIcon} />
				)
			)
			listItems.push("divide")
			push(
				this.handleMoveClick,
				"Move to...",
				"move",
				<FolderIcon className={`${stylesDropdown.itemIcon}`} />
			)
			if (requestCopy)
				push(
					this.handleCopyClick,
					"Copy to...",
					"copy",
					<CopyIcon className={`${stylesDropdown.itemIcon}`} />
				)
			listItems.push("divide")
			push(
				() => {
					this.handleCheckboxChange({
						target: { checked: !isSelected },
					})
				},
				"Select Items",
				"select",
				<CheckCircledIcon className={stylesDropdown.itemIcon} />
			)
			listItems.push("divide")
			push(
				this.handleArchiveClick,
				"Move to Trash",
				"trash",
				<CrumpledPaperIcon className={`${stylesDropdown.itemIcon}`} />
			)
			push(
				this.handleDeleteClick,
				"Delete forever",
				"deletePerm",
				<TrashIcon className={`${stylesDropdown.itemIcon}`} />
			)
		}
		return (
			<DropdownMenu
				mainIcon={icon}
				items={listItems}
				darkTheme
				contentRight
				triggerClass={`${button.lightIcon} ${
					!lookbook.isArchived && button.iconEnd
				}`}
				triggerClassActive={button.lightIconActive}
				setDropdownOpened={(val) => {
					this.setState({ isDropdownOpen: val })
				}}
			/>
		)
	}

	renderShareMenu() {
		const { lookbook, shareOnFacebook } = this.props
		if (lookbook.isArchived) return null
		const listItems = []
		const push = (click, name, key, icon) =>
			listItems.push({
				click,
				name,
				key,
				icon,
			})
		const icon = <ShareIcon className={stylesDropdown.icon} />
		const linkURL = `https://www.pinterest.com/pin/${lookbook.pinterestUploadId}/`
		const domain =
			lookbook.user.customDomain ||
			`${lookbook.user.username}.${publicViewDomain}`
		if (subdomainValidationRegex.test(lookbook.user.username))
			push(
				null,
				<ClipboardButton
					className={stylesDropdown.item}
					data-clipboard-text={`https://${domain}/shopboard/${lookbook.id}`}
					onSuccess={this.handleCopySuccess}
				>
					<>
						<Link1Icon className={stylesDropdown.itemIcon} /> Get
						Shareable Link
					</>
				</ClipboardButton>,
				"clipboard"
			)
		push(
			this.handleEmbedClick,
			"Embed",
			"embed",
			<CodeIcon className={`${stylesDropdown.itemIcon}`} />
		)
		listItems.push("divide")
		// push(null, "Download", "heading", null)
		push(
			() => this.setState({ downloadModal: true }),
			"Download Video",
			"download-video",
			<DownloadIcon className={`${stylesDropdown.itemIcon}`} />
		)
		push(
			() => {
				const mIOS =
					(window.navigator.userAgent.indexOf("iPhone") !== -1 ||
						window.navigator.userAgent.indexOf("iPad") !== -1) &&
					window.navigator.userAgent.indexOf("Safari") !== -1
				const link = document.createElement("a")
				link.href = lookbook.url
				if (!mIOS) {
					link.download = `board.png`
				}
				document.body.appendChild(link)
				link.click()
			},
			"Download Image",
			"download-image",
			<ImageIcon className={`${stylesDropdown.itemIcon}`} />
		)
		push(
			() => {
				window.open(
					`${getBaseUrl()}/api/lookbook/${lookbook.id}/download/zip`
				)
			},
			"Download Zip",
			"download-zip",
			<ArchiveIcon className={`${stylesDropdown.itemIcon}`} />
		)
		push(
			() => {
				window.open(
					`${getBaseUrl()}/api/lookbook/${lookbook.id}/download/pdf`
				)
			},
			"Download PDF",
			"download-pdf",
			<FileIcon className={`${stylesDropdown.itemIcon}`} />
		)
		listItems.push("divide")
		if (!lookbook.pinterestUploadId) {
			push(
				() => this.scheduleUploadToPinterest(),
				"Create Pin",
				"PinterestAdd",
				<PinterestIcon className={stylesDropdown.itemIcon} />
			)
		} else {
			push(
				null,
				<ClipboardButton
					className={stylesDropdown.item}
					data-clipboard-text={linkURL}
					onSuccess={this.handleCopySuccess}
				>
					<>
						<PinterestIcon className={stylesDropdown.itemIcon} />
						Get Pinterest link
					</>
				</ClipboardButton>,
				"pinterest-clipboard"
			)
			push(
				() => this.setState({ confirmModal: true }),
				"Delete Pin",
				"PinterestDelete",
				<TrashIcon className={`${stylesDropdown.itemIcon}`} />
			)
		}
		listItems.push("divide")
		push(
			() => {
				shareOnFacebook({
					platform: "facebook",
					type: this.type,
					obj: lookbook,
					uploadAs: [
						{ id: "link", name: "Link" },
						{ id: "photo", name: "Photo" },
					],
					callback: this.shareOnFacebook,
				})
			},
			"Create Post",
			"uploadFacebook",
			<FacebookIcon className={stylesDropdown.itemIcon} />
		)
		push(
			() => {
				shareOnFacebook({
					platform: "instagram",
					type: this.type,
					obj: lookbook,
					uploadAs: [{ id: "photo", name: "Photo" }],
					callback: this.shareOnFacebook,
				})
			},
			"Create Post",
			"uploadInstagram",
			<InstagramIcon className={stylesDropdown.itemIcon} />
		)
		return (
			<DropdownMenu
				mainIcon={icon}
				items={listItems}
				darkTheme
				contentRight
				triggerClass={`${button.lightIcon} ${button.iconStart}`}
				triggerClassActive={button.lightIconActive}
				setDropdownOpened={(val) => {
					this.setState({ isDropdownOpen: val })
				}}
			/>
		)
	}

	renderPlay(lookbook) {
		const videoExists =
			lookbook.lookbookBoards.filter((i) => !!i.videoUrl).length > 0
		const { enableCardSelection, isSelected } = this.props
		if (enableCardSelection)
			return (
				<div
					className={styles.figure}
					onClick={() => {
						this.handleCheckboxChange({
							target: { checked: !isSelected },
						})
					}}
					role="button"
					tabIndex={0}
				>
					{videoExists && <BadgeVideo />}
					<div className={styles.inner}>
						<img
							className={styles.poster}
							loading="lazy"
							src={addVersioning(lookbook.url, `d=400`)}
							alt="Poster"
							draggable={false}
						/>
					</div>
				</div>
			)
		return (
			<div className={styles.figure}>
				{videoExists && <BadgeVideo />}
				<Link className={styles.inner} to={`/shopboard/${lookbook.id}`}>
					<img
						className={styles.poster}
						loading="lazy"
						src={addVersioning(lookbook.url, `d=400`)}
						alt="Poster"
					/>
				</Link>
			</div>
		)
	}

	renderContent(lookbook, isShown) {
		const {
			isSelected,
			relay,
			enableCardSelection,
			togglePinned,
			routeType,
		} = this.props
		const { showEmbedModal, confirmModal, isDropdownOpen } = this.state
		return (
			<>
				<div
					className={
						isSelected ? `${styles.media} ${styles.active}` : styles.media
					}
				>
					{this.renderPlay(lookbook)}
					<Transition
						in={isShown || isDropdownOpen || enableCardSelection}
						timeout={200}
					>
						{(state) => (
							<div className={`${styles.select} ${styles[state]}`}>
								<input
									type="checkbox"
									checked={isSelected}
									className={`${input.checkbox} ${input.lg}`}
									onChange={this.handleCheckboxChange}
								/>
							</div>
						)}
					</Transition>
					<Transition
						in={(isShown || isDropdownOpen) && !enableCardSelection}
						timeout={200}
					>
						{(state) => (
							<div className={`${styles.menu} ${styles[state]}`}>
								<div className={styles.actions}>
									{this.renderShareMenu()}
									{this.renderMenu()}
								</div>
							</div>
						)}
					</Transition>

					{lookbook.shownBoardCount > 1 && (
						<BadgePageCount
							pageCount={`1 of ${lookbook.shownBoardCount}`}
						/>
					)}
					{lookbook.isFlagged && (
						<div className={card.badge}>
							<div className={label.flagged}>Flagged</div>
						</div>
					)}
					{routeType !== "public" && lookbook.isPublished && (
						<div className={card.badge}>
							<div className={label.published}>Published</div>
						</div>
					)}
				</div>
				<div className={styles.content}>
					<div className={styles.titleWithIcons}>
						<ContentEditable
							className={styles.title}
							title={lookbook.title}
							saveTitle={this.updateLookbookTitle}
							contentEditable={!enableCardSelection}
							setTitleFocus={(val) =>
								this.setState({ titleInFocus: val })
							}
						/>
						<div className={styles.titleIcon}>
							{lookbook.transactions.length > 0 && (
								<SalesCount transactions={lookbook.transactions} />
							)}
							<PinButton
								isPinned={lookbook.isPinned}
								onClick={togglePinned}
							/>
						</div>
					</div>

					<div className={styles.subtitle}>
						{CanvasSizeList.find(
							(i) =>
								i.width === lookbook.lookbookBoards[0].width &&
								i.height === lookbook.lookbookBoards[0].height
						)?.title ||
							`${lookbook.lookbookBoards[0].width} x ${lookbook.lookbookBoards[0].height}px`}
					</div>
					{/*	<div className={card.footerContent}>
						<Views viewsCount={lookbook.views} size="sm" />
					</div> */}
				</div>

				<EmbedShopcast
					shopcast={lookbook}
					type="lookbook"
					showEmbedModal={showEmbedModal}
					closeEmbedModal={this.closeEmbedModal}
				/>
				<ConformationPrompt
					openModal={confirmModal}
					closeModal={() => this.setState({ confirmModal: false })}
					approveEvent={() => {
						RemoveFromPinterestMutation.commit(
							relay.environment,
							lookbook.id,
							() => {
								toast.success(`Pin deleted successfully`, {
									autoClose: 2000,
									closeButton: false,
								})
								this.setState({ confirmModal: false })
							},
							(e) => {
								toast.error(gql.getError(e) || "Error deleting Pin", {
									autoClose: 5000,
									closeButton: false,
								})
								this.setState({ confirmModal: false })
							}
						)
					}}
					declineEvent={() => this.setState({ confirmModal: false })}
					title="Delete Pin"
					approveText="Delete"
					approveButtonStyle={button.primaryRed}
					declineText="Cancel"
				>
					Permanently delete your Pin? You can&apos;t undo this.
				</ConformationPrompt>
			</>
		)
	}

	render() {
		const { lookbook, dragRef, relay } = this.props
		const { downloadModal, titleInFocus } = this.state
		return (
			<ShowCardControlsComponent
				innerRef={titleInFocus ? null : dragRef}
				className={styles.root}
			>
				{(isShown) => (
					<>
						{this.renderContent(lookbook, isShown)}
						{downloadModal && (
							<DownloadLookbook
								lookbook={lookbook}
								relay={relay}
								closeModal={() =>
									this.setState({ downloadModal: false })
								}
							/>
						)}
					</>
				)}
			</ShowCardControlsComponent>
		)
	}
}

function UserLookbookCardComp({ isDraggable, ...otherProps }) {
	const Target = isDraggable
		? withDrag(UserLookbookCard, ({ lookbook }) => ({
				type: "lookbook-card",
				item: {
					id: lookbook.id,
					type: "lookbook",
					isPublished: lookbook.isPublished,
				},
				collect,
		  }))
		: UserLookbookCard
	return <Target {...otherProps} />
}

export default withRouter(
	createFragmentContainer(UserLookbookCardComp, {
		lookbook: graphql`
			fragment userLookbookCard_lookbook on Lookbook {
				id
				title
				description
				url
				isPublished
				isPinned
				isArchived
				isStarred
				isFlagged
				inRoot
				views
				shownBoardCount
				user {
					id
					username
					customDomain
				}
				transactions {
					id
					orderId
				}
				lookbookBoards {
					id
					url
					videoUrl
					width
					height
					dataJson
				}
				folder {
					name
					id
					path {
						value
						name
					}
				}
				pinterestUploadId
			}
		`,
	})
)
