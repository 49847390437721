import { commitMutation, graphql } from "react-relay"
import { ConnectionHandler } from "relay-runtime"
import { complete } from "../helper.js"

const mutation = graphql`
	mutation deleteTemplateMutation($input: DeleteLookbookInput!) {
		deleteLookbook(input: $input) {
			store {
				id
				currentUser {
					id
				}
			}
		}
	}
`

const commit = (env, lookbookId, templateId, userId, onError, onSuccess, other) =>
	commitMutation(env, {
		mutation,
		variables: { input: { lookbookId } },
		onCompleted: complete(onSuccess, onError),
		onError: () => complete(null, onError),
		updater: (storeProxy, data) => {
			if (data.deleteLookbook && other) {
				const userProxy = storeProxy.get(userId)
				const templateConnection = ConnectionHandler.getConnection(
					userProxy,
					"currentUser_lookbookTemplates",
					other
				)
				if (templateConnection)
					ConnectionHandler.deleteNode(templateConnection, templateId)
			}
		},
	})

export default { commit }
