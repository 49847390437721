// import React from 'react'
import { commitMutation, graphql } from "react-relay"
// import { toast } from "react-toastify"
import { complete } from "mutations/helper"
// import { getFeatureAvalibale } from "services/plan-services"
// import UpgradePlanToast from "components/upgrade-plan-toast/upgrade-plan-toast"

const mutation = graphql`
	mutation uploadToPinterestMutation($input: UploadToPinterestInput!) {
		uploadToPinterest(input: $input) {
			store {
				id
				currentUser {
					id
				}
			}
			uploadId
		}
	}
`

const commit = async (env, itemId, onCompleted, onError) => {
	// if(!await getFeatureAvalibale("pinterest-intergration", env)){
	// 	toast.info(<UpgradePlanToast />, { autoClose: 3000, closeButton: false })
	// 	onError({
	// 		code: "upgrade",
	// 		message: "This feature is not available to your current plan"
	// 	})
	// 	return
	// }
	commitMutation(env, {
		mutation,
		variables: { input: { id: itemId } },
		onCompleted: complete(onCompleted, onError),
		onError: (e) => complete(null, [e]),		
		updater: (storeProxy, data) => {
			if (!data.uploadToPinterest) return
			const { uploadId } = data.uploadToPinterest
			const itemProxy = storeProxy.get(itemId)
			if (!itemProxy) return
			itemProxy.setValue(uploadId, "pinterestUploadId")
		},
	})
}
export default { commit }
