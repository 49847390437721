/**
 * @generated SignedSource<<35a64d38713060f5b5d653d97846452e>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { fileCard_shopshareFile$fragmentType } from "./../../../components/cards/file-card/__generated__/fileCard_shopshareFile.graphql";
import type { FragmentType } from "relay-runtime";
declare export opaque type clientViewFiles_store$fragmentType: FragmentType;
export type clientViewFiles_store$data = {|
  +currentUser: ?{|
    +id: string,
    +sharedFolderFiles: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +url: ?string,
          +$fragmentSpreads: fileCard_shopshareFile$fragmentType,
        |},
      |}>,
    |},
  |},
  +id: string,
  +$fragmentType: clientViewFiles_store$fragmentType,
|};
export type clientViewFiles_store$key = {
  +$data?: clientViewFiles_store$data,
  +$fragmentSpreads: clientViewFiles_store$fragmentType,
  ...
};
*/

var node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": 12,
      "kind": "LocalArgument",
      "name": "count"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "cursor"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "folder"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "sort"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "count",
        "cursor": "cursor",
        "direction": "forward",
        "path": [
          "currentUser",
          "sharedFolderFiles"
        ]
      }
    ]
  },
  "name": "clientViewFiles_store",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "currentUser",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": "sharedFolderFiles",
          "args": [
            {
              "kind": "Variable",
              "name": "folderId",
              "variableName": "folder"
            },
            {
              "kind": "Variable",
              "name": "sort",
              "variableName": "sort"
            }
          ],
          "concreteType": "ShopshareFileConnection",
          "kind": "LinkedField",
          "name": "__currentUser_sharedFolderFiles_connection",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ShopshareFileEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "ShopshareFile",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "url",
                      "storageKey": null
                    },
                    {
                      "args": null,
                      "kind": "FragmentSpread",
                      "name": "fileCard_shopshareFile"
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "__typename",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "cursor",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "PageInfo",
              "kind": "LinkedField",
              "name": "pageInfo",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "endCursor",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "hasNextPage",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Store",
  "abstractKey": null
};
})();

(node/*: any*/).hash = "4955b7943392f950bd02d8d02a5ac32d";

module.exports = ((node/*: any*/)/*: Fragment<
  clientViewFiles_store$fragmentType,
  clientViewFiles_store$data,
>*/);
