import React, { Component } from "react"
import { createPaginationContainer, graphql } from "react-relay"
import InfiniteScroll from "react-infinite-scroller"
import ClosetIcon from "shared/assets/closet-icon.svg"
import grids from "shared/styles/components/grids.css"
import empty from "shared/styles/components/empty.css"
import pageContent from "shared/styles/layout/page-content.css"
import LoadingDots from "components/loading-dots/loading-dots"
import ProductCollectionCard from "components/cards/product-collection-card/product-collection-card"
import { withRouter } from "../../route-helpers"

class ClientViewClosetList extends Component {
	constructor(props) {
		super(props)
		this.handleLoadMore = this.handleLoadMore.bind(this)
		this.goToClosetItem = this.goToClosetItem.bind(this)
	}

	handleLoadMore() {
		const { relay } = this.props
		relay.loadMore(12, () => {})
	}

	goToClosetItem(col) {
		const {
			navigate,
		} = this.props
		navigate(`${col.id}`)
	}

	render() {
		const { props } = this
		const {
			store,
			relay,
			removeInfiniteScroll,
		} = props
		const { closetCollectionsByFolder: items } = store.currentUser
		if (!items || items.edges.length <= 0)
			return (
				<div className={empty.root}>
					<div className={empty.container}>
						<div className={empty.content}>
							<ClosetIcon className={empty.icon} />
							<h4 className={empty.headline}>
								There&apos;s nothing here
							</h4>
							<p className={empty.subline}>
								Any closet photos you upload will appear here.
							</p>
						</div>
					</div>
				</div>
			)
		return (
			<div className={pageContent.root}>
			<div className={grids.container}>
				{!removeInfiniteScroll ? (
					<InfiniteScroll
						loadMore={this.handleLoadMore}
						hasMore={relay.hasMore()}
						loader={
							<div key="cvcl-loading" className={grids.loadMore}>
								<LoadingDots />
							</div>
						}
						pageStart={0}
					>
						<div className={grids.rowMax4}>
							{items.edges.map((x) => (
								<div
									className={grids.col3}
									key={x.node.id || x.node.__id}
								>
									<ProductCollectionCard
										setCollection={this.goToClosetItem}
										collection={x.node}
									/>
								</div>
							))}
						</div>
					</InfiniteScroll>
				) : (
					<>
						<div className={grids.rowMax4}>
							{items.edges.map((x) => (
								<div
									className={grids.col3}
									key={x.node.id || x.node.__id}
								>
									<ProductCollectionCard
										setCollection={this.goToClosetItem}
										collection={x.node}
									/>
								</div>
							))}
						</div>
						{relay.hasMore() && (
							<div className={grids.loadMore}>
								<button
									type="button"
									className={grids.loadMoreBtn}
									onClick={this.handleLoadMore}
								>
									Show more
								</button>
							</div>
						)}
					</>
				)}
			</div>
			</div>
		)
	}
}

const query = graphql`
	query clientViewClosetListQuery(
		$count: Int!
		$cursor: String
		$folder: String!
		$sort: String
	) {
		store {
			...clientViewClosetList_store
				@arguments(
					count: $count
					cursor: $cursor
					folder: $folder
					sort: $sort
				)
		}
	}
`

ClientViewClosetList = createPaginationContainer(
	ClientViewClosetList,
	{
		store: graphql`
			fragment clientViewClosetList_store on Store
			@argumentDefinitions(
				count: { type: "Int", defaultValue: 12 }
				cursor: { type: "String" }
				folder: { type: "String!" }
				sort: { type: "String" }
			) {
				id
				currentUser {
					id
					closetCollectionsByFolder(
						folderId: $folder
						first: $count
						after: $cursor
						sort: $sort
					) @connection(key: "currentUser_closetCollectionsByFolder") {
						edges {
							node {
								...productCollectionCard_productCollection
							}
						}
					}
				}
			}
		`,
	},
	{
		direction: "forward",
		getConnectionFromProps(props) {
			const { currentUser } = props.store
			return currentUser && currentUser.closetCollectionsByFolder
		},
		getFragmentVariables(prevVars, totalCount) {
			return {
				...prevVars,
				count: totalCount,
			}
		},
		getVariables(props, { count, cursor }) {
			return {
				count,
				cursor,
				folder: props.params.id,
				sort: "name",
			}
		},
		query,
	}
)

export default {
	Component: withRouter(ClientViewClosetList),
	query,
	params: (rp) => ({
		count: 12,
		cursor: null,
		folder: rp.params.id,
		sort: "name",
	}),
}
