import React, { Component, Fragment } from "react"
import { createRefetchContainer, graphql } from "react-relay"
import { toast } from "react-toastify"
import {
	CardStackPlusIcon,
	TrashIcon,
	PlusIcon,
	Pencil1Icon,
	CheckIcon,
	Cross2Icon,
	// ArchiveIcon,
	UploadIcon,
	TokensIcon,
	MinusIcon,
	FrameIcon,
	CopyIcon,
} from "@radix-ui/react-icons"
import MenuIcon from "shared/assets/menu-dots-horizontal.svg"
import ClosetIcon from "shared/assets/closet-icon.svg"
import icons from "shared/styles/components/icons.css"
import buttons from "shared/styles/components/buttons.css"
import twoColumn from "shared/styles/layout/two-column.css"
import pageContent from "shared/styles/layout/page-content.css"
import controls from "shared/styles/layout/controls.css"
import actionsBar from "shared/styles/components/actions-bar.css"
import pageHeader from "shared/styles/layout/page-header.css"
import splitPane from "components/split-pane/split-pane.css"
import chip from "shared/styles/components/chips.css"
import textfield from "shared/styles/components/textfield.css"
import ProductEditModal from "components/modals/product-edit/product-edit.jsx"
import DeleteProductItemMutation from "mutations/product-collection/delete-product-collection-item.js"
import DeleteProductCollectionMutation from "mutations/product-collection/delete-product-collection.js"
import deleteLookbookUploadMutation from "mutations/lookbook/delete-lookbook-upload"
import Avatar from "components/profile/avatar/avatar"
import setPublishedCollection from "mutations/product-collection/set-published-collection"
import CopyToMutation from "mutations/shopcast-manager/copy-to"
import { HTML5Backend } from "react-dnd-html5-backend"
import { DndProvider } from "react-dnd"
import ListOfProductCollectionItems from "components/list-of-prod-collection-items/list-of-prod-collection-items.jsx"
import { debounce } from "lodash"
import ConformationPrompt from "components/modals/conformation-prompt/conformation-prompt"
import ImageUpload from "components/modals/image-upload/image-upload"
import LinkScraper from "components/modals/link-scraper/link-scraper"
import Divider from "components/divider/divider"
import UploadSelect from "components/modals/upload-select/upload-select"
import stylesDropdown from "components/dropdown-menu/dropdown-menu-dark.css"
import DropdownMenu from "components/dropdown-menu/dropdown-menu"
import stylesUserMenuDD from "components/dropdowns/dropdowns.css"
// import CategorySelect from "components/list-of-prod-collection-items/category-select"
// import AccordionFilters from "components/accordion-filters/accordion-filters"
// import BrandSelect from "components/list-of-prod-collection-items/brand-select"
import SearchInputComponent from "components/search-input/search-input"
import CollectionSelect from "components/list-of-prod-collection-items/collection-select"
import MultiItemCollectionEdit from "components/modals/multi-item-collection-edit/multi-item-collection-edit"
import ProductCollectionCreateEditModal from "components/modals/product-collection-create-edit/product-collection-create-edit"
// import SidebarItem from "components/profile/shopcast-manager/sidebar-item/sidebar-item"
import gqlHelper from "helpers/gql-helper"
import { getCollectionTitle, getFormattedNumber } from "helpers/string-helper"
import SplitPane from "components/split-pane/split-pane"
import { getChipClass } from "helpers/ui-helper"
import { FeatureKeyENUM } from "services/plan-services"
import styles from "./product-collections.css"
import { getComponent, withRouter } from "../../route-helpers"

class ProductCollections extends Component {
	constructor(props) {
		super(props)
		const startSearchObj = this.getCollectionFilters()
		const teamChanged = startSearchObj.teamId !== props.team
		this.state = {
			productToEdit: null,
			collectionToEdit: null,
			itemToDelete: null,
			loading: false,
			uploadToEdit: null,
			openUploadModal: null,
			collectionCopyRequested: null,
			selectedItems: {},
			selectedTeam: null,

			// activeTabs: this.getStartingActiveTab(startSearchObj),
			search: startSearchObj.search || "",
			collections: (!teamChanged && startSearchObj.collections) || [],
			collectionSearch: startSearchObj.collectionSearch || "",
			sort: startSearchObj.sort || "n-to-o",
			type: startSearchObj.type || "",
			client: (!teamChanged && startSearchObj.client) || "",
			folder: (!teamChanged && startSearchObj.folder) || "",
			total: 0,
			isLoadingData: false,
		}
		this.renderActions = this.renderActions.bind(this)
		this.setFiltersPersistent = this.setFiltersPersistent.bind(this)
		this.setCollections = this.setCollections.bind(this)
		this.onProductCollectionItemSelect =
			this.onProductCollectionItemSelect.bind(this)
		this.onProductCollectionItemDeSelect =
			this.onProductCollectionItemDeSelect.bind(this)
		this.editProductCollectionImage =
			this.editProductCollectionImage.bind(this)
		this.deleteProductCollectionImage =
			this.deleteProductCollectionImage.bind(this)
		this.setListData = this.setListData.bind(this)
		this.debouncedSearch = debounce((key, value) => {
			this.setState({ [key]: value })
			this.setFiltersPersistent(key, value)
		}, 300)
		this.setFiltersPersistent("teamId", props.team)
		if (teamChanged) {
			this.setFiltersPersistent("client", "")
			this.setFiltersPersistent("collections", [])
		}
	}

	onProductCollectionItemSelect(item) {
		const { selectedItems: prevSelectedItems } = this.state
		this.setState({
			selectedItems: {
				...prevSelectedItems,
				[item.id]: item,
			},
		})
	}

	onProductCollectionItemDeSelect(item) {
		const { selectedItems: prevSelectedItems } = this.state
				this.setState({
					selectedItems: {
						...prevSelectedItems,
						[item.id]: null,
					},
				})
	}

	get validTeams() {
		const { store, team } = this.props
		const { teams } = store?.currentUser || {}
		return (teams || [])
		.filter(
			(t) =>
				t.id !== team &&
				t.features.includes(FeatureKeyENUM.TEAMS)
		)
	}

	// getStartingActiveTab(startSearchObj) {
	// 	if (startSearchObj.collections && startSearchObj.collections.length > 0)
	// 		return ["collections"]
	// 	if (startSearchObj.collectionSearch) return ["collections"]
	// 	if (startSearchObj.closetSearch) return ["closet"]
	// 	return ["collections"].includes(startSearchObj.lastOpened)
	// 		? [startSearchObj.lastOpened]
	// 		: startSearchObj.lastOpened || []
	// }

	getCollectionFilters() {
		const collectionFiltersStr = window.sessionStorage.getItem(
			"collection-filters"
		)
		const searchObj = collectionFiltersStr
			? JSON.parse(collectionFiltersStr)
			: {}
		return searchObj
	}

	getLimitType(csf) {
		switch (csf) {
			case "collections":
				return ["collections"]
			case "closet":
				return ["closet"]
			case "published":
				return ["published", "collections"]
			default:
				return ["collections", "closet"]
		}
	}

	setFiltersPersistent(key, value) {
		const searchObj = this.getCollectionFilters()
		if (!value) delete searchObj[key]
		else searchObj[key] = value
		window.sessionStorage.setItem(
			"collection-filters",
			JSON.stringify(searchObj)
		)
	}

	// setActiveTab(tab, val) {
	// 	const { activeTabs: ats } = this.state
	// 	const vals = val ? [...ats, tab] : ats.filter((i) => i !== tab)
	// 	this.setState({
	// 		activeTabs: vals,
	// 	})
	// 	this.setFiltersPersistent("lastOpened", vals)
	// }

	setCollections(c) {
		const cols = c.map((item) => ({
			id: item.id,
			title: item.title,
			user: item.user,
			client: item.client?.id || item.client,
			isACloset: item.isACloset,
			folder: {
				id: item.folder?.id,
			},
			isPublished: item.isPublished,
		}))
		this.setState({ collections: cols })
		this.setFiltersPersistent("collections", c)
	}

	setListData(data) {
		this.setState(data)
	}

	editProductCollectionImage(x) {
		const { __typename: typeName } = x?.node || {}
		if (typeName === "ShopcastProduct")
			this.setState({ productToEdit: x?.node })
		if (typeName === "lookbookUploads")
			this.setState({
				openUploadModal: "upload",
				uploadToEdit: x?.node,
			})
	}

	deleteProductCollectionImage(x) {
		const { __typename: typeName } = x?.node || {}
		this.setState({
			itemToDelete: {
				type: typeName === "ShopcastProduct" ? "product" : "image",
				id: x?.node?.id,
				title: x?.node?.title,
			},
		})
	}

	deleteProductItem(productId) {
		const { relay, team } = this.props
		const {
			search,
			collections,
			sort,
			type,
			client,
			collectionSelectionFilter,
		} = this.state
		this.setState({ loading: true })
		DeleteProductItemMutation.commit(
			relay.environment,
			null,
			productId,
			() => { },
			() => {
				this.setState({ loading: false, itemToDelete: null })
				toast.success(<Fragment>Successfully Deleted</Fragment>, {
					autoClose: 2000,
					closeButton: false,
				})
			},
			{
				search,
				categories: [],
				brands: [],
				collections: collections.map((i) => i.id),
				sort,
				type,
				clientId: client,
				limitType: this.getLimitType(collectionSelectionFilter),
				teamId: team,
			}
		)
	}

	async deleteUpload(collectionId, uploadId) {
		const { relay, team } = this.props
		const {
			search,
			collections,
			sort,
			type,
			client,
			collectionSelectionFilter,
		} = this.state
		this.setState({ loading: true })
		await new Promise((resolve, reject) => {
			deleteLookbookUploadMutation.commit(
				relay.environment,
				{ collectionId, uploadId },
				(result) => {
					toast.success(<>Successfully Deleted</>, {
						autoClose: 5000,
						closeButton: false,
					})
					resolve(result)
				},
				(e) => {
					toast.info(<>{gqlHelper.getError(e)}</>, {
						autoClose: 5000,
						closeButton: false,
					})
					reject(e)
				},
				true,
				{
					search,
					categories: [],
					brands: [],
					collections: collections.map((i) => i.id),
					sort,
					type,
					clientId: client,
					limitType: this.getLimitType(collectionSelectionFilter),
					teamId: team,
				}
			)
		})
		this.setState({
			loading: false,
			itemToDelete: null,
		})
	}

	deleteCollection(collectionId, isACloset) {
		const { relay, params, navigate, team } = this.props
		const {
			search,
			collectionSearch,
			collections,
			client,
			folder,
		} = this.state
		this.setState({ loading: true })
		DeleteProductCollectionMutation.commit(
			relay.environment,
			collectionId,
			() => { },
			() => {
				toast.success(<>Collection Successfully Deleted</>, {
					autoClose: 2000,
					closeButton: false,
				})
				if (params.collectionId) {
					navigate("/collections")
				} else {
					this.setState({ loading: false, itemToDelete: null })
				}
				if (collections.find((col) => col.id === collectionId)) {
					const c = collections.filter((col) => col.id !== collectionId)
					this.setState({
						collections: c,
					})
					this.setFiltersPersistent("collections", c)
				}
			},
			{
				search,
				categories: [],
				brands: [],
				collectionSearch,
				clientId: client,
				limitType: this.getLimitType(isACloset ? "closet" : "collections"),
				folderId: folder,
				teamId: team,
			}
		)
	}
	

	deleteSelectedItems() {
		const {
			selectedItems,
			search,
			collections,
			sort,
			type,
			client,
		} = this.state

		const { relay } = this.props
		const promises = Object.values(selectedItems).map((item) =>
			item
				? new Promise((resolve, reject) => {
					if (item.type === "ShopcastProduct") {
						DeleteProductItemMutation.commit(
							relay.environment,
							null,
							item.id,
							() => {
								reject()
							},
							() => {
								item.deselect()
								resolve()
							},
							{
								search,
								categories: [],
								brands: [],
								clientId: client,
								collections: collections.map((i) => i.id),
								sort,
								type,
								limitType: this.getLimitType(),
							}
						)
					} else if (item.type === "lookbookUploads") {
						deleteLookbookUploadMutation.commit(
							relay.environment,
							{ collectionId: null, uploadId: item.id },
							() => {
								item.deselect()
								resolve()
							},
							() => {
								reject()
							},
							true,
							{
								search,
								categories: [],
								brands: [],
								collections: collections.map((i) => i.id),
								sort,
								type,
								clientId: client,
								limitType: this.getLimitType(),
							}
						)
					}
				})
				: Promise.resolve()
		)
		promises.reduce((p, task) => p.then(task), Promise.resolve())
		this.setState({ loading: false, itemToDelete: null })
	}

	copyCollection() {
		const { selectedTeam, collectionCopyRequested } = this.state
		const { relay } = this.props
		CopyToMutation.commit(
			relay.environment,
			[collectionCopyRequested.id],
			selectedTeam.id,
			(e) => {
				toast.info(<>{gqlHelper.getError(e)}</>, {
					autoClose: 5000,
					closeButton: false,
				})
			},
			() => {
				toast.success(<>Successfully copied</>, {
					autoClose: 2000,
					closeButton: false,
				})
			}
		)
		this.setState({ collectionCopyRequested: null })
	}

	showCollectionData() {
		const { team } = this.props
		const {
			search,
			collections,
			selectedItems,
			sort,
			type,
			client,
		} = this.state
		const ListOfProductCollectionItemsComp = getComponent(
			"list-of-prod-collection-items",
			ListOfProductCollectionItems,
			{
				search,
				categories: [],
				collections: collections.map((i) => i.id),
				brands: [],
				count: 30,
				sort,
				type,
				clients: client ? [client] : [],
				teamId: team,
			},
			{
				search,
				categories: [],
				collections: collections.map((i) => i.id),
				brands: [],
				count: 30,
				sort,
				type,
				clients: client ? [client] : [],
				teamId: team,
				onSelect: this.onProductCollectionItemSelect,
				onDeselect: this.onProductCollectionItemDeSelect,
				editImage: this.editProductCollectionImage,
				deleteImage: this.deleteProductCollectionImage,
				setListData: this.setListData,
			},
			true
		)
		return <ListOfProductCollectionItemsComp selectedItems={selectedItems} />
	}

	showCollectionList(collectionSelectionFilter) {
		const { team } = this.props
		const {
			search,
			collections,
			collectionSearch,
			client,
			folder,
		} = this.state
		
		const CollectionSelectComp = getComponent(
			`collection-select-${collectionSelectionFilter}`,
			CollectionSelect,
			{
				search,
				brands: [],
				categories: [],
				count: 30,
				collectionSearch,
				clients: client ? [client] : [],
				limitType: this.getLimitType(collectionSelectionFilter),
				folderId: folder,
				teamId: team,
			},
			{
				search,
				brands: [],
				categories: [],
				count: 30,
				collectionSearch,
				clients: client ? [client] : [],
				limitType: this.getLimitType(collectionSelectionFilter),
				keepDefault: collectionSelectionFilter === "collections",
				folderId: folder,
				teamId: team,
			},
			true
		)
		return (
			<CollectionSelectComp
				collections={collections}
				setCollections={this.setCollections}
				renderMenu={this.renderActions}
			/>
		)
	}

	renderContentMenu(productCollections) {
		const listItems = []
		const { navigate } = this.props
		const push = (click, name, key, icon) =>
			listItems.push({
				click,
				name,
				key,
				icon,
			})
		const icon = (
			<>
				<PlusIcon className={buttons.icon} />
				Add New
			</>
		)
		// push(null, "Add New", "heading-addnew", null)
		push(
			() => {
				this.setState({ openUploadModal: "upload" })
				if (productCollections.length === 0)
					toast.error(<>You need to create a collection first</>, {
						autoClose: 2000,
						closeButton: false,
					})
			},
			"Upload",
			"upload",
			<UploadIcon className={stylesDropdown.itemIcon} />
		)
		push(
			() => {
				this.setState((state) => ({
					collectionToEdit: {
						type: "new",
						collection: {
							client: state.client,
						},
					},
				}))
			},
			"Collection",
			"new",
			<TokensIcon className={stylesDropdown.itemIcon} />
		)
		listItems.push("divide")
		push(
			() => {
				navigate(`/shopboard/create`)
			},
			"Board",
			"create",
			<FrameIcon className={stylesDropdown.itemIcon} />
		)
		return (
			<DropdownMenu
				items={listItems}
				darkTheme
				contentRight
				mainIcon={icon}
				triggerClass={buttons.primary}
				triggerClassActive={buttons.primaryActive}
			/>
		)
	}

	renderActions(c) {
		if (c.isDefault) return null
		return (
			<div className={styles.actions}>
				<button
					type="button"
					className={styles.tool}
					onClick={() => {
						this.setState({
							collectionToEdit: {
								type: "edit",
								collection: { ...c, client: c.client?.id },
							},
						})
					}}
				>
					<Pencil1Icon className={styles.icon} />
				</button>
				<button
					type="button"
					className={styles.toolEnd}
					onClick={() => {
						this.setState({
							itemToDelete: {
								type: "collection",
								collectionId: c.id,
								title: c.title,
								isACloset: c.isACloset,
							},
						})
					}}
				>
					<TrashIcon className={styles.icon} />
				</button>
			</div>
		)
	}

	renderChipMenu(c) {
		const { relay } = this.props
		const listItems = []
		const push = (click, name, key, icon) =>
			listItems.push({
				click,
				name,
				key,
				icon,
			})
		const icon = <MenuIcon className={chip.icon} />
		if (!c.isACloset && !c.client) {
			push(
				() => {
					setPublishedCollection.commit(
						relay.environment,
						{
							id: c.id,
							isPublished: !c.isPublished,
						},
						() => { },
						({ setPublishedCollection: spc }) => {
							const { collections } = this.state
							c.isPublished = spc.published_at
							this.setState({ collections })
							this.setFiltersPersistent("collections", collections)
						}
					)
				},
				!c.isPublished ? "Add to My Channel" : "Remove from My Channel",
				"publish",
				!c.isPublished ? (
					<PlusIcon className={stylesDropdown.itemIcon} />
				) : (
					<MinusIcon className={stylesDropdown.itemIcon} />
				)
			)
			listItems.push("divide")
		}
		if (this.validTeams.length > 0)
			push(
				() => {
					this.setState({
						collectionCopyRequested: c,
					})
				},
				"Copy to...",
				"copy",
				<CopyIcon className={stylesDropdown.itemIcon} />
			)
		push(
			() => {
				this.setState({
					collectionToEdit: {
						type: "edit",
						collection: c,
					},
				})
			},
			"Edit",
			"edit",
			<Pencil1Icon className={stylesDropdown.itemIcon} />
		)
		push(
			() => {
				this.setState({
					itemToDelete: {
						type: "collection",
						collectionId: c.id,
						title: c.title,
						isACloset: c.isACloset,
					},
				})
			},
			"Delete forever",
			"delete",
			<TrashIcon className={stylesDropdown.itemIcon} />
		)
		return (
			<DropdownMenu
				items={listItems}
				mainIcon={icon}
				darkTheme
				contentRight
				triggerClass={chip.btn}
				triggerClassActive={chip.btnActive}
			/>
		)
	}

	renderSortBy() {
		const { sort } = this.state
		return (
			<div className={controls.sortFilter}>
				<select
					className={controls.select}
					value={sort}
					onChange={(event) => {
						this.setState({ sort: event.target.value })
						this.setFiltersPersistent("sort", event.target.value)
					}}
				>
					<optgroup label="Sort by">
						<option value="n-to-o">Newest to Oldest</option>
						<option value="o-to-n">Oldest to Newest</option>
						<option value="name">Name (A-Z)</option>
						<option value="name-d">Name (Z-A)</option>
						<option value="most-f">Most Favourites</option>
					</optgroup>
				</select>
			</div>
		)
	}

	// renderFilterType() {
	// 	const { type } = this.state
	// 	return (
	// 		<select
	// 			className={controls.select}
	// 			value={type}
	// 			onChange={(event) => {
	// 				this.setState({ type: event.target.value })
	// 				this.setFiltersPersistent("type", event.target.value)
	// 			}}
	// 		>
	// 			<optgroup label="Show">
	// 				<option value="">All Types</option>
	// 				<option value="uploads">Images</option>
	// 				<option value="products">Shoppable Images</option>
	// 			</optgroup>
	// 		</select>
	// 	)
	// }

	renderCollectionSearch() {
		const queryData = this.getCollectionFilters()
		return (
			<SearchInputComponent
				placeholder="Search"
				defaultValue={queryData.search}
				setSearchText={(value) => this.debouncedSearch("search", value)}
			/>
		)
	}

	renderContent() {
		const {
			productToEdit,
			collectionToEdit,
			itemToDelete,
			loading,
			uploadToEdit,
			openUploadModal,
			selectedItems,
			search,
			collections,
			sort,
			// activeTabs,
			collectionSearch,
			type,
			total,
			client,
			folder,
			isLoadingData,
			collectionSelectionFilter,
			collectionCopyRequested,
			selectedTeam,
		} = this.state
		const { store, relay, params, loading: compLoading, team } = this.props
		const { userClients } = store?.currentUser || {}
		let productCollections = []
		if (!compLoading) {
			const {
				productCollections: productCollectionsEdges,
			} = store.currentUser
			productCollections = productCollectionsEdges.edges.map((i) => i.node)
		}
		const queryData = this.getCollectionFilters()
		const selectedItemsObjs = Object.values(selectedItems).filter((i) => !!i)
		const folders =
			(client &&
				userClients?.edges?.find((i) => i.node.id === client)?.node
					?.folders[0]?.folders?.edges) ||
			[]
		const clientSelected =
			client && userClients?.edges?.find((i) => i.node.id === client)
		return (
			<div className={twoColumn.root}>
				<div className={twoColumn.side}>
					<div className={twoColumn.fillHeight}>
						<div className={twoColumn.sideMain}>
							<div className={twoColumn.sideNavCollections}>
								{this.renderCollectionSearch()}
								<Divider />
								{(userClients?.edges || []).length > 0 && (
									<select
										className={textfield.subtle}
										value={client}
										onChange={(e) => {
											const state = {
												client: e.target.value,
												folder: "",
											}
											this.setState(state)
											this.setFiltersPersistent(
												"client",
												e.target.value
											)
										}}
									>
										<option value="">My Collections</option>
										<option disabled>─</option>
										<option value="published">Published Only</option>
										<option disabled>─</option>
										<optgroup label="Client Collections">
											{(userClients?.edges || []).map(({ node }) => (
												<option key={node.id} value={node.id}>
													{node.fullname}
												</option>
											))}
										</optgroup>
									</select>
								)}
							</div>

							<div className={twoColumn.sideFilters}>
								<SearchInputComponent
									placeholder="Search Collections"
									defaultValue={queryData.collectionSearch}
									setSearchText={(value) =>
										this.debouncedSearch("collectionSearch", value)
									}
								/>
								{client && folders.length > 0 && (
									<select
										className={textfield.normal}
										value={folder}
										onChange={(e) => {
											this.setState({
												folder: e.target.value,
											})
										}}
									>
										<optgroup label="Filter by Folders">
											<option value="">All Folders</option>
											{folders.map(({ node }) => (
												<option key={node.id} value={node.id}>
													{node.name}
												</option>
											))}
										</optgroup>
									</select>
								)}
							</div>

							{client && client !== "published" ? (
								<SplitPane>
									<SplitPane.Top>
										{this.showCollectionList("collections")}
									</SplitPane.Top>
									<SplitPane.Bottom>
										<div className={splitPane.header}>
											<ClosetIcon className={splitPane.icon} />
											<h4 className={splitPane.title}>Closet</h4>
										</div>
										<div className={splitPane.content}>
											{this.showCollectionList("closet")}
										</div>
									</SplitPane.Bottom>
								</SplitPane>
							) : (
								this.showCollectionList(
									client === "published" ? "published" : "collections"
								)
							)}

							<div className={twoColumn.sideFooter}>
								<button
									type="button"
									className={`${buttons.subtle} ${buttons.fullWidth}`}
									onClick={() => {
										this.setState((state) => ({
											collectionToEdit: {
												type: "new",
												collection: {
													client: state.client,
													isACloset: false,
												},
											},
										}))
									}}
								>
									<PlusIcon className={buttons.icon} />
									New Collection
								</button>

								{/*	<Divider />
								<SidebarItem
									item={{
										name: "Archive",
										count: 0,
									}}
									path="/content/archived"
									isActive={folder === "archived"}
									Icon={ArchiveIcon}
								/> */}
							</div>
						</div>
					</div>
				</div>

				<div className={twoColumn.main}>
					<div className={pageHeader.root}>
						<div className={pageHeader.contentNoWrap}>
							<h2 className={pageHeader.title}>
								{clientSelected?.node?.fullname || "My Collections"}
							</h2>
							<div className={pageHeader.actions}>
								{this.renderContentMenu(productCollections)}
							</div>
						</div>
					</div>

					{!search && (isLoadingData || total > 0) && (
						<div className={controls.rootTabFilters}>
							<div className={controls.filterResults}>
								{getFormattedNumber(total)} Results
							</div>
							{this.renderSortBy()}
							{/* {this.renderFilterType()} */}
						</div>
					)}

					{collections.length > 0 && (
						<div className={controls.activeTags}>
							<ul className={chip.root}>
								{collections.map((item) => (
									<li
										key={item.id}
										className={getChipClass(item).chip}
									>
										<div className={chip.label}>{item.title}</div>
										<div className={chip.actions}>
											<button
												type="button"
												className={chip.btn}
												onClick={() => {
													const c = collections.filter(
														(i) => i.id !== item.id
													)
													this.setState({
														collections: c,
													})
													this.setFiltersPersistent(
														"collections",
														c
													)
												}}
											>
												<Cross2Icon className={chip.icon} />
											</button>
											<div
												className={getChipClass(item).dividerVert}
											/>
											{this.renderChipMenu(item)}
										</div>
									</li>
								))}
								<li className={chip.chipClear}>
									<button
										type="button"
										className={chip.clearBtn}
										onClick={() => {
											this.setState({
												collections: [],
											})
											this.setFiltersPersistent("collections", [])
										}}
										disabled={collections.length === 0}
									>
										Clear all
									</button>
								</li>
							</ul>
						</div>
					)}

					<div className={pageContent.root}>
						{this.showCollectionData()}
						{productToEdit?.type === "product-bulk" ? (
							<MultiItemCollectionEdit
								store={store}
								relay={relay}
								selectedItems={selectedItemsObjs}
								other={{
									search,
									categories: [],
									brands: [],
									collections: collections.map((i) => i.id),
									sort,
									type,
									clientId: client,
									limitType: this.getLimitType(
										collectionSelectionFilter
									),
									teamId: team,
								}}
								collectionsKeyValue={productCollections
									.filter((i) => !i.isDefault || !i.owned)
									.sort((a, b) => a.isACloset - b.isACloset)
									.map((i) => ({
										id: i.id,
										value: getCollectionTitle(i),
										className: getChipClass(i).chip,
									}))}
								onCancel={() => {
									this.setState({ productToEdit: null })
								}}
								onSave={() => {
									this.setState({ selectedItems: {} })
								}}
							/>
						) : (
							<ProductEditModal
								store={store}
								product={productToEdit}
								showProductEditModal={!!productToEdit}
								closeProductEditModal={() => {
									this.setState({ productToEdit: null })
								}}
								relay={relay}
								enableSavingWithoutCollection={false}
								collectionsKeyValue={productCollections
									.filter((i) => !i.isDefault || !i.owned)
									.sort((a, b) => a.isACloset - b.isACloset)
									.map((i) => ({
										id: i.id,
										value: getCollectionTitle(i),
										className: getChipClass(i).chip,
									}))}
								refetchData={() => {}}
								other={{
									search,
									categories: [],
									brands: [],
									collections: collections.map((i) => i.id),
									sort,
									type,
									clientId: client,
									limitType: this.getLimitType(
										collectionSelectionFilter
									),
									teamId: team,
								}}
							/>
						)}
						<UploadSelect
							openModal={openUploadModal === "upload-select"}
							onImageUploadClick={() => {
								this.setState({ openUploadModal: "upload" })
								if (productCollections.length === 0)
									toast.error(
										<Fragment>
											You need to create a collection first
										</Fragment>,
										{ autoClose: 2000, closeButton: false }
									)
							}}
							onClipsClick={() => {
								this.setState({ openUploadModal: "link-scraper" })
							}}
							closeModal={() => {
								this.setState({ openUploadModal: null })
							}}
						/>
						<LinkScraper
							openModal={openUploadModal === "link-scraper"}
							closeModal={() => {
								this.setState({ openUploadModal: null })
							}}
							team={team}
							collectionIds={collections.map((col) => col.id)}
							onSave={() => {
								relay.refetch({
									refetchVariables: {
										collectionId:
											params.collectionId !== "list"
												? params.collectionId
												: null,
									},
								})
								this.setState({
									openUploadModal: null,
								})
							}}
							relay={relay}
							other={{
								search,
								categories: [],
								brands: [],
								collections: collections.map((i) => i.id),
								sort,
								type,
								clientId: client,
								limitType: this.getLimitType(),
							}}
						/>
						{productCollections.length > 0 && (
							<ImageUpload
								subCategoryList={productCollections.sort(
									(a, b) => a.isACloset - b.isACloset
								)}
								adminAccess={uploadToEdit?.adminAccess}
								openModal={openUploadModal === "upload"}
								relay={relay}
								accepted="image/*"
								selected={uploadToEdit}
								team={team}
								currentCollections={(collections.length > 0
									? collections
									: productCollections.filter((i) => i.isDefault)
								).map((col) => ({
									id: col.id,
									value: getCollectionTitle(col),
									className: getChipClass(col).chip,
								}))}
								closeModal={(count) => {
									const { state } = this
									if (count !== false)
										relay.refetch({
											search: state.search,
											categories: [],
											brands: [],
											collections: [
												...state.collections.map((i) => i.id),
											],
											sort: state.sort,
											type: state.type,
										})
									this.setState({
										openUploadModal: null,
										uploadToEdit: null,
									})
								}}
								other={{
									search,
									categories: [],
									brands: [],
									collections: collections.map((i) => i.id),
									sort,
									type,
									clientId: client,
									limitType: this.getLimitType(
										collectionSelectionFilter
									),
									teamId: team,
								}}
							/>
						)}
						{!!collectionToEdit &&
							(collectionToEdit.type === "new" ||
								collectionToEdit.type === "edit") && (
								<ProductCollectionCreateEditModal
									collectionToEdit={collectionToEdit.collection}
									relay={relay}
									currentUser={store.currentUser}
									userClients={userClients?.edges || []}
									team={team}
									onCancel={() => {
										this.setState({ collectionToEdit: null })
									}}
									onSuccess={(status, data) => {
										if (status !== "updated") return
										const { collections: stateColls } = this.state
										const obj = stateColls.find(
											(col) => col.id === data.id
										)
										if (!obj) return
										obj.title = data.title
										obj.client = data.clientId
										obj.isACloset = data.isACloset
										obj.folder = {
											id: data.folder,
										}
										obj.isPublished = data.isPublished
										this.setState({ collections: [...stateColls] })
										this.setFiltersPersistent("collections", [
											...stateColls,
										])
									}}
									getLimitType={this.getLimitType}
									other={{
										search,
										categories: [],
										brands: [],
										collectionSearch,
										clientId: client,
										folderId: folder,
										teamId: team,
									}}
								/>
							)}
						<ConformationPrompt
							openModal={!!itemToDelete}
							closeModal={() => {
								this.setState({ itemToDelete: null })
							}}
							approveEvent={() => {
								if (itemToDelete.type === "product")
									this.deleteProductItem(itemToDelete.id)
								else if (itemToDelete.type === "image")
									this.deleteUpload(
										itemToDelete.collectionId,
										itemToDelete.id
									)
								else if (itemToDelete.type === "collection")
									this.deleteCollection(
										itemToDelete.collectionId,
										itemToDelete.isACloset
									)
								else if (itemToDelete.type === "product-bulk")
									this.deleteSelectedItems()
							}}
							title="Delete Forever"
							declineEvent={() => {
								this.setState({ itemToDelete: null })
							}}
							approveText="Delete"
							approveButtonStyle={buttons.primaryRed}
							declineText="Cancel"
							loading={loading}
						>
							{(() => {
								switch (itemToDelete?.type) {
									case "product":
										return `Deleting "${itemToDelete?.title}" from the collection is a permanent action, and it cannot be recovered.`
									case "collection":
										return `Deleting "${itemToDelete?.title}" collection is a permanent action, and it cannot be recovered.`
									case "product-bulk":
										return `Deleting selected items from the collection is a permanent action, and it cannot be recovered.`
									default:
										return `Deleting "${itemToDelete?.title}" is a permanent action, and it cannot be recovered.`
								}
							})()}
						</ConformationPrompt>
						<ConformationPrompt
							openModal={!!collectionCopyRequested}
							closeModal={() =>
								this.setState({ collectionCopyRequested: null })
							}
							approveEvent={() => {
								this.copyCollection()
							}}
							title="Copy to..."
							declineEvent={() =>
								this.setState({ collectionCopyRequested: null })
							}
							approveText="Copy Here"
							approveButtonStyle={buttons.primary}
							declineText="Cancel"
						>
							{this.validTeams.map((t) => (
								<button
									type="button"
									className={stylesUserMenuDD.itemAvatar}
									disabled={selectedTeam?.id === t.id}
									onClick={() => {
										this.setState({ selectedTeam: t })
									}}
								>
									<span className={stylesUserMenuDD.itemLabel}>
										<Avatar
											size="40px"
											photoUrl={t.avatar}
											fullname={t.name}
										/>
										{t.name}
									</span>
									{selectedTeam?.id === t.id && (
										<CheckIcon
											className={stylesUserMenuDD.itemIcon}
										/>
									)}
								</button>
							))}
						</ConformationPrompt>
					</div>
					{!!selectedItems && selectedItemsObjs.length > 0 && (
						<div className={actionsBar.root}>
							<div className={actionsBar.inner}>
								<div className={actionsBar.count}>
									{selectedItemsObjs.length} selected
								</div>
								<div className={actionsBar.group}>
									<button
										type="button"
										className={actionsBar.btn}
										data-tooltip-id="tooltip"
										data-tooltip-place="bottom"
										data-tooltip-content="Add to Collection"
										onClick={() => {
											this.setState({
												productToEdit: {
													type: "product-bulk",
												},
											})
										}}
									>
										<CardStackPlusIcon className={icons.icon21} />
									</button>
									<button
										type="button"
										className={actionsBar.btn}
										data-tooltip-id="tooltip"
										data-tooltip-place="bottom"
										data-tooltip-content="Delete permanently"
										onClick={() => {
											this.setState({
												itemToDelete: {
													type: "product-bulk",
												},
											})
										}}
									>
										<TrashIcon className={icons.icon21} />
									</button>
								</div>
								<button
									type="button"
									className={actionsBar.btnClose}
									data-tooltip-id="tooltip"
									data-tooltip-place="bottom"
									data-tooltip-content="Cancel"
									onClick={() => {
										this.setState({ selectedItems: {} })
									}}
								>
									<Cross2Icon className={icons.icon24} />
								</button>
							</div>
						</div>
					)}
				</div>
			</div>
		)
	}

	render() {
		return (
			<DndProvider backend={HTML5Backend}>
				{this.renderContent()}
			</DndProvider>
		)
	}
}

const productCollectionsQuery = graphql`
	query productCollectionsRefetchQuery($teamId: String) {
		store {
			...productCollections_store @arguments(teamId: $teamId)
		}
	}
`

ProductCollections = createRefetchContainer(
	ProductCollections,
	{
		store: graphql`
			fragment productCollections_store on Store
			@argumentDefinitions(teamId: { type: "String" }) {
				currentUser {
					id
					type
					teams {
						id
						name
						avatar
						features
					}
					userClients(teamId: $teamId) {
						edges {
							node {
								id
								fullname
								email
								folders {
									id
									folders {
										edges {
											node {
												id
												name
											}
										}
									}
								}
							}
						}
					}
					productCollection {
						id
					}
					productCollections(first: 100000, teamId: $teamId)
						@connection(key: "currentUser_noFilter_productCollections") {
						edges {
							node {
								id
								title
								createdAt
								updatedAt
								isDefault
								isACloset
								user {
									id
									fullname
								}
								client {
									id
									fullname
								}
								isPublished
							}
						}
					}
				}
			}
		`,
	},
	productCollectionsQuery
)

export default {
	Component: withRouter(ProductCollections),
	query: productCollectionsQuery,
	needAuth: true,
	showLoading: true,
	params: (props) => ({
		teamId: props.team,
	}),
}
