import { commitMutation, graphql } from "react-relay"
import { ConnectionHandler } from "relay-runtime"
import { complete } from "../helper"

const mutation = graphql`
	mutation createClientMutation($input: CreateClientInput!) {
		createClient(input: $input) {
			client {
				id
				fullname
				email
				avatar
				folders {
					id
					...shopcastFolder_folder
				}
			}
		}
	}
`

const commit = (env, { file, ...data }, onError, onCompleted, parentId, other = {}) =>
	commitMutation(env, {
		mutation,
		variables: { input: data },
		uploadables: { file },
		onCompleted: complete(onCompleted, onError),
		onError: (e) => complete(null, [e]),
		updater: (storeProxy, output) => {
			const currentUser = storeProxy
				.getRoot()
				.getLinkedRecord("store")
				.getLinkedRecord("currentUser")
			const itemProxy = storeProxy
				.getRootField("createClient")
				.getLinkedRecord("client")

			const edge = storeProxy.create(
				`Client:connection:${new Date().getTime()}`,
				"client"
			)
			edge.setLinkedRecord(itemProxy, "node")
			const connection = ConnectionHandler.getConnection(
				currentUser,
				"currentUser_userClients",
				other
			)
			if (connection) ConnectionHandler.insertEdgeAfter(connection, edge)
			
			const args = { search: "", teamId: data.teamId }
			const parentFolder = storeProxy.get(parentId)
			parentFolder.setValue(
				(parentFolder.getValue("clientsCount", args) || 0) + 1,
				"clientsCount",
				args
			)
			if (!parentFolder) return
			const folderItemProxy = storeProxy.get(
				output.createClient.client.folders[0].id
			)
			if (!folderItemProxy) return
			const folderConnection =  ConnectionHandler.getConnection(
				parentFolder,
				"currentUser_listOfFolder_folders",
				{
					onlyClients: false,
					isShared: null
				}
			)
			if(folderConnection && data.folder)
				ConnectionHandler.deleteNode(folderConnection, data.folder)
			const clientFolderConnection =  ConnectionHandler.getConnection(
				parentFolder,
				"currentUser_listOfFolder_folders",
				{
					onlyClients: true,
					isShared: null
				}
			)
			const folderEdge = storeProxy.create(
				`ShopcastFolder:connection:${new Date().getTime()}`,
				"ShopcastFolder"
			)
			folderEdge.setLinkedRecord(folderItemProxy, "node")
			if(clientFolderConnection)
				ConnectionHandler.insertEdgeAfter(clientFolderConnection, folderEdge)
		},
	})

export default { commit }
