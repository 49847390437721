import { commitMutation, graphql } from "react-relay"
import { complete } from "../helper"

const mutation = graphql`
	mutation updateLookbookDetailsMutation(
		$input: UpdateLookbookDetailsInput!
	) {
		updateLookbookDetails(input: $input) {
			lookbook{
        id,
        title,
        description
      }
		}
	}
`

const commit = (env, input, onError, onCompleted) => commitMutation(env, {
  mutation,
  variables: { input },
  updater: (storeProxy) => {
    const lookbook = storeProxy.get(input.id)
    lookbook.setValue(input.title,"title")
    lookbook.setValue(input.description, "description")
  },
  onCompleted: complete(onCompleted, onError),
  onError: (e) => complete(null, [e]),
})

export default { commit }
