/**
 * @generated SignedSource<<edeb4d94d3fef6ca3508c4a1bd398540>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Mutation } from 'relay-runtime';
export type DeleteBackgroundInput = {|
  clientMutationId?: ?string,
  id: string,
|};
export type deleteLookbookBackgroundMutation$variables = {|
  input: DeleteBackgroundInput,
|};
export type deleteLookbookBackgroundMutation$data = {|
  +deleteBackground: ?{|
    +status: ?boolean,
  |},
|};
export type deleteLookbookBackgroundMutation = {|
  response: deleteLookbookBackgroundMutation$data,
  variables: deleteLookbookBackgroundMutation$variables,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "DeleteBackgroundPayload",
    "kind": "LinkedField",
    "name": "deleteBackground",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "status",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "deleteLookbookBackgroundMutation",
    "selections": (v1/*: any*/),
    "type": "ShopshareMutationRoot",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "deleteLookbookBackgroundMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "9b2f1c976cab78a9d36e94245cab9347",
    "id": null,
    "metadata": {},
    "name": "deleteLookbookBackgroundMutation",
    "operationKind": "mutation",
    "text": "mutation deleteLookbookBackgroundMutation(\n  $input: DeleteBackgroundInput!\n) {\n  deleteBackground(input: $input) {\n    status\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "167f3d8e23128ff4b403e53090358ea6";

module.exports = ((node/*: any*/)/*: Mutation<
  deleteLookbookBackgroundMutation$variables,
  deleteLookbookBackgroundMutation$data,
>*/);
