/**
 * @generated SignedSource<<c3cfe2e8aa4e457986f358f4b47e116d>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Query } from 'relay-runtime';
import type { salesReport_store$fragmentType } from "./salesReport_store.graphql";
export type salesReportQuery$variables = {||};
export type salesReportQuery$data = {|
  +store: ?{|
    +$fragmentSpreads: salesReport_store$fragmentType,
  |},
|};
export type salesReportQuery = {|
  response: salesReportQuery$data,
  variables: salesReportQuery$variables,
|};
*/

var node/*: ConcreteRequest*/ = {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "salesReportQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Store",
        "kind": "LinkedField",
        "name": "store",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "salesReport_store"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "ShopshareQueryRoot",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "salesReportQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Store",
        "kind": "LinkedField",
        "name": "store",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "92c9067dfda81d83049521e8995bbe01",
    "id": null,
    "metadata": {},
    "name": "salesReportQuery",
    "operationKind": "query",
    "text": "query salesReportQuery {\n  store {\n    ...salesReport_store\n    id\n  }\n}\n\nfragment salesReport_store on Store {\n  id\n}\n"
  }
};

(node/*: any*/).hash = "e5961d273d3a0a689a4a8fd63de586cd";

module.exports = ((node/*: any*/)/*: Query<
  salesReportQuery$variables,
  salesReportQuery$data,
>*/);
