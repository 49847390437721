/* eslint-disable no-underscore-dangle */
import React, { Component } from "react"
import InfiniteScroll from "react-infinite-scroller"
import { createPaginationContainer, graphql } from "react-relay"
import BgColorCard from "components/cards/lookbook/bg-color-card"
import BgImageCard from "components/cards/lookbook/bg-image-card"
import LoadingDots from "components/loading-dots/loading-dots"
import ImageCard from "components/cards/lookbook/image-card"
import ShapeCard from "components/cards/lookbook/shape-card"
import TemplateCard from "components/cards/lookbook/template-card"
import { clickFavourite } from "helpers/lookbook-helper"
import grids from "shared/styles/components/grids.css"
import lookbookAside from "shared/styles/layout/lookbook-aside.css"

class FavouriteList extends Component {
	constructor(props) {
		super(props)
		this.fetchData = this.fetchData.bind(this)
		this.renderFavourites = this.renderFavourites.bind(this)
	}

	fetchData() {
		const { relay } = this.props
		relay.loadMore(12)
	}

	renderFavourites(favouriteList) {
		const {
			relay,
			drop,
			category,
			color,
			backgroundColor,
			backgroundImage,
			scale,
			opacity,
			background,
			onClick,
			editProduct,
			editImage,
			isAdmin,
			templateAdd,
			templateEdit,
			team
		} = this.props
		return favouriteList.map((x) => {
			if (!x.isFavourite) {
				return ""
			}
			if (
				x.__typename === "ShopcastProduct" ||
				x.__typename === "lookbookUploads"
			) {
				let imgCategory = "shopcast"
				if (
					x.__typename === "lookbookUploads" &&
					x.productCollections.length > 0
				)
					imgCategory = "lookbookUploads"
				if (
					x.__typename === "ShopcastProduct" &&
					x.productCollections.length > 0
				)
					imgCategory = "ShopcastProduct"
				if (x.__typename === "lookbookUploads" && x.category)
					imgCategory = "extras"
				return (
					<ImageCard
						key={x.id}
						isDraggable
						price={x.offerPrice || x.regularPrice}
						description={x.title}
						drop={drop}
						showAddButton
						isFavourite={x.isFavourite}
						showFavouriteButton
						clickFavourite={() =>
							clickFavourite(x.id, imgCategory, team, relay)
						}
						canEdit
						editImage={() => {
							if (x.__typename === "lookbookUploads") editImage(x)
							if (x.__typename === "ShopcastProduct") editProduct(x)
						}}
						category={category}
						node={x}
					/>
				)
			}
			if (x.__typename === "Shape") {
				const svg = {
					...x,
					path: JSON.parse(x.path),
					collection: [{ id: x.id, value: x.collection }],
					viewbox: x.viewBox,
				}
				return (
					<ShapeCard
						key={x.id}
						node={svg}
						drop={drop}
						color={color}
						showAddButton
						showFavouriteButton
						clickFavourite={() => clickFavourite(x.id, "shape", team, relay)}
						editImage={() => editImage(svg)}
					/>
				)
			}
			if (x.__typename === "Background" && category === "image") {
				const isSelected = backgroundImage === x.url
				return (
					<BgImageCard
						key={x.id}
						category={x.bgCategory}
						name={x.title}
						isAdmin={isAdmin}
						scale={scale}
						opacity={opacity}
						url={x.url}
						isSelected={isSelected}
						fill={background.fill}
						onClick={onClick}
						canEdit={x.canEdit}
						isFavourite={x.isFavourite}
						showFavouriteButton
						clickFavourite={() => clickFavourite(x.id, "image", team, relay)}
						editImage={() =>
							editImage({
								...x,
								category: [{ id: x.id, value: x.bgCategory }],
								name: x.title,
							})
						}
					/>
				)
			}
			if (x.__typename === "Background" && category === "color") {
				const isSelected = backgroundColor === x.url
				return (
					<BgColorCard
						key={x.id}
						id={x.id}
						color={x.url}
						isSelected={isSelected}
						isAdmin={isAdmin}
						showFavouriteButton
						isFavourite={x.isFavourite}
						onClick={() => {
							onClick({
								fill: isSelected ? null : x.url,
								fillPatternImage: background.fillPatternImage,
								fillurl: background.fillurl,
								fillPatternScale: background.fillPatternScale,
								opacity: background.opacity,
							})
						}}
						clickFavourite={() => clickFavourite(x.id, "color", team, relay)}
						editEvent={() =>
							editProduct({
								...x,
								category: [{ id: x.id, value: x.bgCategory }],
								name: x.title,
							})
						}
					/>
				)
			}
			if (x.__typename === "LookbookTemplate") {
				return (
					<TemplateCard
						key={x.id}
						node={x}
						description={x.lookbook.title}
						isFavourite={x.isFavourite}
						showFavouriteButton
						clickFavourite={() => clickFavourite(x.id, "template", team, relay)}
						clickAdd={() => templateAdd(x)}
						canEdit={x.canEdit}
						editImage={() => templateEdit(x)}
						createdCount={x.createdCount}
					/>
				)
			}
			return ""
		})
	}

	render() {
		const { store, relay, category, loading } = this.props
		const favouriteList =
			store && store.currentUser.lookbookFavourites
				? store.currentUser.lookbookFavourites.edges.map((obj) => obj.node)
				: []
		if (loading) {
			return <LoadingDots />
		}
		let gridClass = grids.image
		if (category === "image" || category === "color") {
			gridClass = grids.bgImageTile
		}
		if (category === "template") {
			gridClass = grids.template
		}
		return (
			favouriteList.length > 0 && (
				<div className={lookbookAside.content}>
					<InfiniteScroll
						loadMore={this.fetchData}
						hasMore={relay ? relay.hasMore() : false}
						loader={<LoadingDots key={0} />}
						pageStart={0}
						useWindow={false}
					>
						<div className={gridClass}>
							{this.renderFavourites(favouriteList)}
						</div>
					</InfiniteScroll>
				</div>
			)
		)
	}
}

const query = graphql`
	query favouriteListQuery($count: Int!, $cursor: String, $category: String, $teamId: String) {
		store {
			...favouriteList_store
				@arguments(count: $count, cursor: $cursor, category: $category, teamId: $teamId)
		}
	}
`

FavouriteList = createPaginationContainer(
	FavouriteList,
	{
		store: graphql`
			fragment favouriteList_store on Store
			@argumentDefinitions(
				count: { type: "Int", defaultValue: 24 }
				cursor: { type: "String" }
				category: { type: "String" }
				teamId: { type: "String" }
			) {
				currentUser {
					lookbookFavourites(
						first: $count
						after: $cursor
						category: $category
						teamId: $teamId
					) @connection(key: "currentUser_lookbookFavourites") {
						edges {
							node {
								__typename
								... on ShopcastProduct {
									id
									title
									brand
									description
									url
									affiliated_url
									custom_affiliated_url
									imageUrl
									diffbotImageUrl
									regularPrice
									offerPrice
									error
									productImages
									hasProductImagesFromDiffbot
									productCollections {
										id
										title
									}
									isFavourite
								}
								... on Shape {
									id
									canEdit
									canDelete
									viewBox
									path
									collection
									isFavourite
								}
								... on lookbookUploads {
									id
									adminAccess
									canEdit
									title
									url
									category {
										title
									}
									subCategoryList {
										id
										category {
											title
										}
										title
									}
									productCollections {
										id
										title
									}
									isFavourite(teamId: $teamId)
								}
								... on Background {
									id
									canEdit
									title
									bgCategory: category
									url: background
									isFavourite
									type
									isFavourite
								}
								... on LookbookTemplate {
									id
									categories
									canEdit
									isFavourite
									createdCount
									lookbook {
										id
										title
										url
										screen_width
										data_json
										width
										height
										background
										group
										shapes {
											edges {
												node {
													id
													viewBox
													path
													collection
												}
											}
										}
									}
								}
							}
						}
					}
				}
			}
		`,
	},
	{
		direction: "forward",
		getConnectionFromProps(props) {
			const { currentUser } = props.store
			return currentUser && currentUser.lookbookFavourites
		},
		getFragmentVariables(prevVars, totalCount) {
			return {
				...prevVars,
				count: totalCount,
			}
		},
		getVariables(props, { count, cursor }) {
			return {
				count,
				cursor,
				category: props.category,
				teamId: props.team,
			}
		},
		query,
	}
)
export default {
	Component: FavouriteList,
	query,
	params: {
		count: 12,
		cursor: null,
		category: null,
	},
	showLoading: true,
}
