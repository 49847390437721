import React from "react"
import { commitMutation, graphql } from "react-relay"
import { toast } from "react-toastify"
import UpgradePlanToast from "components/upgrade-plan-toast/upgrade-plan-toast.jsx"
import { getFeatureAvalibale } from "../../services/plan-services.js"
import { complete } from "../helper.js"

const mutation = graphql`
	mutation processMp4OnDemandMutation($input: ProcessMp4OnDemandInput!) {
		processMp4OnDemand(input: $input) {
			url
		}
	}
`

const getConfigs = (storeId) => [
	{
		type: "FIELDS_CHANGE",
		fieldIDs: { store: storeId },
	},
]

const commit = async (env, shopcastId, storeId, team, onCompleted, onError) => {
	if (!(await getFeatureAvalibale("publish-shopcasts", env, team))) {
		toast.info(<UpgradePlanToast />, { autoClose: 3000, closeButton: false })
		return null
	}
	return commitMutation(env, {
		mutation,
		variables: { input: { shopcastId } },
		configs: getConfigs(storeId),
		onCompleted: complete(onCompleted, onError),
		onError: (e) => complete(null, [e]),
	})
}

export default { commit }
