import React from "react"
import HeartIcon from "shared/assets/heart-icon.svg"
import HeartOutlineIcon from "shared/assets/heart-outline-icon.svg"
import styles from "./favourite-button.css"

function FavouriteButton({ onClick, isFavourite }) {
  return <button className={styles.root} type="button" onClick={onClick}>
		{isFavourite ? (
			<HeartIcon className={styles.iconActive} />
		) : (
			<HeartOutlineIcon className={styles.icon} />
		)}
	</button>
}

export default FavouriteButton
