import { commitMutation, graphql } from "react-relay"
import { ConnectionHandler } from "relay-runtime"
import { complete } from "../helper.js"
import connections from "../connections"

const mutation = graphql`
	mutation toggleLookbookPublishedMutation (
		$input: ToggleLookbookPublishedInput!
	) {
		toggleLookbookPublished(input: $input) {
			output
		}
	}
`

const commit = (env, lookbookId, parentId, containerId, remove, onError) => commitMutation(env, {
	mutation,
	variables: { input: { lookbookId } },
	optimisticResponse: { toggleLookbookPublished: { output: "" } },
	onError: () => complete(null, onError),
	updater: (storeProxy, data) => {
		if (data.toggleLookbookPublished) {
			const lookbookProxy = storeProxy.get(lookbookId)
			const wasPublished = lookbookProxy.getValue("isPublished")
			lookbookProxy.setValue(!wasPublished, "isPublished")
			const containerProxy = storeProxy.get(containerId)
			containerProxy.setValue(containerProxy.getValue("publishedCount") + (wasPublished ? -1 : 1), "publishedCount")

			if (remove) {
				const folderProxy = storeProxy.get(parentId)
				const connection = connections.currentUser.shopcastManager.items
				const shopcastsConnection = ConnectionHandler.getConnection(
					folderProxy,
					connection.name,
					{
						...connection.data,
						type: "lookbook"
					}
				)
				ConnectionHandler.deleteNode(shopcastsConnection, lookbookId)
			}
		}
	},
})

export default { commit }
