import React from "react"
import SubtitleOnIcon from "shared/assets/subtitle-on-icon.svg"
import SubtitleOffIcon from "shared/assets/subtitle-off-icon.svg"
import styles from "./subtitle-button.css"

export default function SubtitleButton({ isSubtitle, toggleSubtitle }) {
	let icon = <SubtitleOnIcon className={styles.icon} />
	if (isSubtitle === true) {
		icon = <SubtitleOffIcon className={styles.icon} />
	}
	return (
		<button
			type="button"
			className={styles.button}
			onClick={() => toggleSubtitle()}
		>
			{icon}
		</button>
	)
}
