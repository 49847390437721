import React, { Component } from "react"
import ReactPlayer from "react-player/lazy"
import { createFragmentContainer, graphql } from "react-relay"
import TestimonialImage from "components/testimonial-image/testimonial-image"
import ListOfBullets from "components/list-of-bullets/list-of-bullets"
import PageFooter from "components/page-footer/page-footer"
import LandingActions from "components/landing-actions/landing-actions"
import landing from "shared/styles/layout/landing.css"
import feature from "shared/styles/layout/feature.css"
import layout from "shared/styles/components/layout.css"
import AnikaWalker from "shared/assets/testimonial-images/anikawalker.jpg"
import Home1 from "shared/assets/feature-images/home-1.jpg"
import Home2 from "shared/assets/feature-images/home-2.jpg"
import Home3 from "shared/assets/feature-images/home-3.jpg"
import Home4 from "shared/assets/feature-images/home-4.jpg"
import Home5 from "shared/assets/feature-images/home-5.jpg"
import Video from "shared/assets/feature-videos/hero2.mp4"
import trackingService from "../../services/tracking-service"

import styles from "./home.css"

// eslint-disable-next-line react/prefer-stateless-function
class Home extends Component {
	componentDidMount() {
		trackingService.track("Viewed Homepage", {})
	}

	render() {
		const { store } = this.props
		const user = store.currentUser
		return (
			<div className={layout.offset}>
				<section className={`${landing.section} ${landing.gray100}`}>
					<div className={landing.content}>
						<h1 className={styles.displayTitle}>
							<span className={styles.displayTitleSpan1}>Shoppable</span>
							<span className={styles.displayTitleSpan2}>Shareable</span>
							<span className={styles.displayTitleSpan3}>Content</span>
						</h1>
						<p className={styles.displayLead}>
							The ulitimate digital wardrobe and personal styling
							platform.
						</p>

						{!user && (
							<div className={landing.actions}>
								<LandingActions />
							</div>
						)}

						<div className={landing.video}>
							<ReactPlayer
								url={Video}
								width="100%"
								playsinline
								className={landing.player}
								loop
								controls={false}
								playing
								muted
								height="100%"
							/>
						</div>
					</div>
				</section>

				<section className={landing.sectionFullWidth}>
					<div className={landing.content}>
						<h2 className={landing.titleLg}>
							Why Stylists Use ShopShare
						</h2>
						<div className={landing.listOfBullets}>
							<ListOfBullets
								bullets={[
									{
										key: "b1",
										label: "Virtual Styling Services",
									},
									{
										key: "b2",
										label: "Shoppable Marketing Content",
									},
									{
										key: "b3",
										label: "Educational and Informative",
									},
								]}
							/>
						</div>
					</div>
				</section>

				<section className={landing.section}>
					<div className={landing.contentNoTopPadding}>
						<div className={feature.root}>
							<div className={feature.mediaFrame}>
								<img
									className={feature.img}
									src={Home1}
									alt="Customised Shopping Videos"
								/>
							</div>
							<div className={feature.content}>
								<div className={feature.inner}>
									<h2 className={feature.heading}>
										Customised Shopping Videos
									</h2>
									<p className={feature.body}>
										84% of customers are more incentivised to buy a
										product if they see it on video. Create Shopcast
										shoppable videos with integrated product links in
										just a few clicks.
									</p>
								</div>
							</div>
						</div>
						<div className={feature.rootFlip}>
							<div className={feature.mediaFrame}>
								<img
									className={feature.img}
									src={Home2}
									alt="Shoppable Moodboard Heaven."
								/>
							</div>
							<div className={feature.content}>
								<div className={feature.inner}>
									<h2 className={feature.heading}>
										Shoppable Moodboard Heaven
									</h2>
									<p className={feature.body}>
										Clients love to visualise the look before making
										any individual purchase. Curate interactive
										Shopboards, combining inspiring imagery with any
										online products.
									</p>
								</div>
							</div>
						</div>
						<div className={feature.root}>
							<div className={feature.mediaFrame}>
								<img
									className={feature.img}
									src={Home3}
									alt="Branded shopping channel."
								/>
							</div>
							<div className={feature.content}>
								<div className={feature.inner}>
									<h2 className={feature.heading}>
										Branded Shopping Channel
									</h2>
									<p className={feature.body}>
										Customers value seamless shopping experiences.
										Elevate your marketing with a customisable
										Shopping Channel to share unlimited Shopcasts and
										Shopboards.
									</p>
								</div>
							</div>
						</div>
						<div className={feature.rootFlip}>
							<div className={feature.mediaFrame}>
								<img
									className={feature.img}
									src={Home4}
									alt="Private client channels."
								/>
							</div>
							<div className={feature.content}>
								<div className={feature.inner}>
									<h2 className={feature.heading}>
										Private Client Channels
									</h2>
									<p className={feature.body}>
										Retain clients with VIP shopping experiences
										personally tailored to them! Design private
										Shopping Channels to share unlimited Shopcasts and
										Shopboards.
									</p>
								</div>
							</div>
						</div>
						<div className={feature.root}>
							<div className={feature.media}>
								<img
									className={feature.img}
									src={Home5}
									alt="Revenue generating content."
								/>
							</div>
							<div className={feature.content}>
								<div className={feature.inner}>
									<h2 className={feature.heading}>
										Revenue Generating Content
									</h2>
									<p className={feature.body}>
										Earn through our affiliate program, or use your
										own custom links! Incorporate your ShopShare
										content into your virtual services, marketing
										content and memberships!
									</p>
									<p className={feature.body}>
										The creative possibilities are endless!
									</p>
								</div>
							</div>
						</div>
					</div>
				</section>

				<section className={landing.section}>
					<div className={landing.content}>
						<h2 className={landing.titleLg}>Here’s what they say:</h2>
						<div className={landing.testimonalImage}>
							<TestimonialImage
								testimonials={[
									{
										key: "ti1",
										quote:
											"ShopShare allows me to offer online services that years ago I could only have dreamed of. I launched an online womens styling subscription off the back of ShopShare. Now I offer Customised Shopping Videos to my clients and it's my favourite way to work with them!",
										name: "Anika Walker",
										channelURL:
											"https://yourlookstylist.shopshare.tv",
										image: AnikaWalker,
										company: "Your Look Stylist",
									},
									{
										key: "ti2",
										quote:
											"I used to spend hours compiling PDF lookbooks and shopping lists for clients. ShopShare has saved me time, and my clients find the videos so valuable! They love the  totally personal virtual experience. It has transformed my business and I'm never looking back.",
										name: "Claire Jensen",
										channelURL:
											"https://clairejensenstyle.shopshare.tv",
										image:
											"https://d1zyonrw2kzytp.cloudfront.net/profile/595/profile_1607944036507.JPG",
										company: "Australian Style Institute",
									},
									{
										key: "ti3",
										quote:
											"I am blown away by ShopShare! I have seamlessly pivoted my business from in-person meetings to personal virtual shopping experiences that are safe and from the comfort of our homes! My clients are LOVING it!",
										name: "Amanda Wood",
										channelURL: "https://thehauteedit.shopshare.tv",
										image:
											"https://d1zyonrw2kzytp.cloudfront.net/profile/605/profile_1600820253738.png",
										company: "thehauteedit",
									},
									{
										key: "ti4",
										quote:
											"As a passionate stylist in a regional area, Shopshare allows me to deliver carefully curated styling outcomes no matter where my clients are located. Shopshare removes the feeling of overwhelm that my clients face when shopping online. I have the whole world of fashion at my fingertips and none of the restrictions of shopping centres.",
										channelURL: "https://curateandstyle.shopshare.tv",
										image:
											"https://d1zyonrw2kzytp.cloudfront.net/profile/190/profile_1560423273007.jpg",
										name: "Jessica Ryan",
										company:
											"Curate and Style (Australian Style Institute Alumni of the Year 2020)",
									},
								]}
							/>
						</div>
					</div>
				</section>

				<section className={landing.section}>
					<div className={landing.contentPageFooter}>
						<PageFooter
							title="The perfect tool to add to your arsenal"
							lead="The creative possibilities are endless!"
							currentUser={user}
						/>
					</div>
				</section>
			</div>
		)
	}
}

Home = createFragmentContainer(Home, {
	store: graphql`
		fragment home_store on Store {
			id
			currentUser {
				id
				hasPendingSharerRequests
				isANewUser
			}
		}
	`,
})

const query = graphql`
	query homeQuery {
		store {
			...home_store
		}
	}
`

export default { Component: Home, query }
