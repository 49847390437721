/**
 * @generated SignedSource<<f85663e14a7d79bceedc6d8d49223124>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { Fragment, ReaderFragment } from 'relay-runtime';
export type TeamMemberRoleType = "ADMIN" | "MEMBER" | "OWNER" | "%future added value";
export type userEnumType = "CLIENT" | "RETAILER" | "STYLIST" | "%future added value";
import type { FragmentType } from "relay-runtime";
declare export opaque type settings_store$fragmentType: FragmentType;
export type settings_store$data = {|
  +currentUser: ?{|
    +fullname: ?string,
    +isASharer: ?boolean,
    +profilePhoto: ?string,
    +referrals: ?$ReadOnlyArray<?{|
      +id: string,
    |}>,
    +role: ?string,
    +type: ?userEnumType,
  |},
  +id: string,
  +team: ?{|
    +avatar: ?string,
    +id: string,
    +myRole: ?TeamMemberRoleType,
    +name: ?string,
    +user: ?{|
      +id: string,
    |},
  |},
  +$fragmentType: settings_store$fragmentType,
|};
export type settings_store$key = {
  +$data?: settings_store$data,
  +$fragmentSpreads: settings_store$fragmentType,
  ...
};
*/

var node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  (v0/*: any*/)
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "teamId"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "settings_store",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "currentUser",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isASharer",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "type",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Referral",
          "kind": "LinkedField",
          "name": "referrals",
          "plural": true,
          "selections": (v1/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "profilePhoto",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "fullname",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "role",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "id",
          "variableName": "teamId"
        }
      ],
      "concreteType": "team",
      "kind": "LinkedField",
      "name": "team",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "avatar",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "myRole",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "User",
          "kind": "LinkedField",
          "name": "user",
          "plural": false,
          "selections": (v1/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Store",
  "abstractKey": null
};
})();

(node/*: any*/).hash = "e1282dc12e0ffabd8ba9d01eddf35593";

module.exports = ((node/*: any*/)/*: Fragment<
  settings_store$fragmentType,
  settings_store$data,
>*/);
