/**
 * @generated SignedSource<<228260d48c43cee7843f9d22bd897166>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Mutation } from 'relay-runtime';
export type ProcessMp4OnDemandInput = {|
  clientMutationId?: ?string,
  shopcastId: string,
|};
export type processMp4OnDemandMutation$variables = {|
  input: ProcessMp4OnDemandInput,
|};
export type processMp4OnDemandMutation$data = {|
  +processMp4OnDemand: ?{|
    +url: ?string,
  |},
|};
export type processMp4OnDemandMutation = {|
  response: processMp4OnDemandMutation$data,
  variables: processMp4OnDemandMutation$variables,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "ProcessMp4OnDemandPayload",
    "kind": "LinkedField",
    "name": "processMp4OnDemand",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "url",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "processMp4OnDemandMutation",
    "selections": (v1/*: any*/),
    "type": "ShopshareMutationRoot",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "processMp4OnDemandMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "eade8ff7a53639aebcbf1b593a1ef3e2",
    "id": null,
    "metadata": {},
    "name": "processMp4OnDemandMutation",
    "operationKind": "mutation",
    "text": "mutation processMp4OnDemandMutation(\n  $input: ProcessMp4OnDemandInput!\n) {\n  processMp4OnDemand(input: $input) {\n    url\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "d28eaece6c5a1dd7eaa2abacf1ebf9c7";

module.exports = ((node/*: any*/)/*: Mutation<
  processMp4OnDemandMutation$variables,
  processMp4OnDemandMutation$data,
>*/);
