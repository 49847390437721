export function doFullscreen(element, videoElement) {
	if (element.requestFullscreen) {
		element.requestFullscreen()
	} else if (element.webkitRequestFullscreen) {
		// console.log('this is called enter')
		element.webkitRequestFullscreen()
	} else if (element.mozRequestFullscreen) {
		element.mozRequestFullScreen()
	} else if (element.msRequestFullScreen) {
		element.msRequestFullscreen()
	} else if (videoElement && videoElement.webkitEnterFullScreen) {
		videoElement.webkitEnterFullScreen()
	}
}

export function doExitFullscreen() {
	if (document.exitFullscreen) {
		document.exitFullscreen()
	} else if (document.webkitExitFullscreen) {
		document.webkitExitFullscreen()
	} else if (document.mozCancelFullScreen) {
		document.mozCancelFullScreen()
	} else if (document.msExitFullscreen) {
		document.msExitFullscreen()
	}
}

export function isFullscreen() {
	if (document.webkitIsFullScreen && document.webkitIsFullScreen === true) {
		return true
	}
	if (document.mozFullScreen && document.mozFullScreen === true) {
		return true
	}
	return false
}

export function listenToFullscreenChange(handler) {
	document.addEventListener("webkitfullscreenchange", handler, false)
	document.addEventListener("fullscreenchange", handler, false)
	document.addEventListener("mozfullscreenchange", handler, false)
	document.addEventListener("msfullscreenchange", handler, false)
}

export function removeFullscreenChangeListener(handler) {
	document.removeEventListener("webkitfullscreenchange", handler, false)
	document.removeEventListener("fullscreenchange", handler, false)
	document.removeEventListener("mozfullscreenchange", handler, false)
	document.removeEventListener("msfullscreenchange", handler, false)
}
