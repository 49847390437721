/**
 * @generated SignedSource<<74738bfa00a96590777197a900d57f87>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Mutation } from 'relay-runtime';
import type { shopcastFolder_folder$fragmentType } from "./../../../components/profile/shopcast-folder/__generated__/shopcastFolder_folder.graphql";
export type CreateNewFolderInput = {|
  clientMutationId?: ?string,
  name: string,
  parent?: ?string,
  teamId?: ?string,
|};
export type createNewFolderMutation$variables = {|
  input: CreateNewFolderInput,
|};
export type createNewFolderMutation$data = {|
  +createNewFolder: ?{|
    +createdFolder: ?{|
      +$fragmentSpreads: shopcastFolder_folder$fragmentType,
    |},
  |},
|};
export type createNewFolderMutation = {|
  response: createNewFolderMutation$data,
  variables: createNewFolderMutation$variables,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "avatar",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "createNewFolderMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateNewFolderPayload",
        "kind": "LinkedField",
        "name": "createNewFolder",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ShopcastFolder",
            "kind": "LinkedField",
            "name": "createdFolder",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "shopcastFolder_folder"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "ShopshareMutationRoot",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "createNewFolderMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateNewFolderPayload",
        "kind": "LinkedField",
        "name": "createNewFolder",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ShopcastFolder",
            "kind": "LinkedField",
            "name": "createdFolder",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "ShopcastFolder",
                "kind": "LinkedField",
                "name": "parent",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "NameValue",
                "kind": "LinkedField",
                "name": "path",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "value",
                    "storageKey": null
                  },
                  (v3/*: any*/),
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "bannerPhoto",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isArchived",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isPinned",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "sharableLink",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "description",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isStarred",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "inRoot",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "count",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "itemsCount",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isParentAClient",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Client",
                "kind": "LinkedField",
                "name": "clients",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "fullname",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "email",
                    "storageKey": null
                  },
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "isUserAlreadyExist",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ShopcastFolder",
                    "kind": "LinkedField",
                    "name": "folders",
                    "plural": true,
                    "selections": [
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "a00a124eb1a7c21e9ac46e0d5e361408",
    "id": null,
    "metadata": {},
    "name": "createNewFolderMutation",
    "operationKind": "mutation",
    "text": "mutation createNewFolderMutation(\n  $input: CreateNewFolderInput!\n) {\n  createNewFolder(input: $input) {\n    createdFolder {\n      ...shopcastFolder_folder\n      id\n    }\n  }\n}\n\nfragment shopcastFolder_folder on ShopcastFolder {\n  id\n  name\n  parent {\n    name\n    id\n  }\n  path {\n    value\n    name\n    id\n  }\n  avatar\n  bannerPhoto\n  isArchived\n  isPinned\n  sharableLink\n  description\n  isStarred\n  inRoot\n  count\n  itemsCount\n  isParentAClient\n  clients {\n    id\n    fullname\n    email\n    avatar\n    isUserAlreadyExist\n    folders {\n      id\n    }\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "a6210f28d1c135230b2c50db4efff490";

module.exports = ((node/*: any*/)/*: Mutation<
  createNewFolderMutation$variables,
  createNewFolderMutation$data,
>*/);
