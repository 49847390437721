import { commitMutation, graphql } from "react-relay"
import { complete } from "../helper"

const mutation = graphql`
	mutation setLookbookUploadThumbUrlMutation(
		$input: LookbookUploadsThumbInput!
	) {
		lookbookUploadsThumb(input: $input) {
			lookbookUploads {
				url
			}
			url
		}
	}
`

const commit = (env, data, onError, onCompleted) =>
	commitMutation(env, {
		mutation,
		variables: { input: data },
		updater: (storeProxy, result) => {
			if (result.lookbookUploadsThumb) {
				const uploadProxy = storeProxy.get(data.uploadId)
				uploadProxy.setValue(result.lookbookUploadsThumb.url, "url")
			}
		},
		onError: complete(onCompleted, onError),
		onCompleted: complete(onCompleted, onError),
	})

export default { commit }
