import React, { useEffect, useState } from "react"
import Dropzone from "react-dropzone"
import { TrashIcon, PlusIcon } from "@radix-ui/react-icons"
import form from "shared/styles/components/form.css"
import textfield from "shared/styles/components/textfield.css"
import toggle from "shared/styles/components/toogle.css"
import button from "shared/styles/components/buttons.css"
import icons from "shared/styles/components/icons.css"
import Avatar from "components/profile/avatar/avatar"
import EmojiTextArea from "components/emoji-textarea/emoji-textarea"
import styles from "./edit-preview.css"

function EditPreviewSharedControls({
	data,
	saveDescription,
	uploadBanner,
	uploadAvatar,
	saveDisplayName,
	updateShowControls,
	enableBannerAvatarSelect,
}) {
	const [bioLength, setBioLength] = useState(
		(data.description || "").slice(0, 160).length
	)

	const getSelectValue = (value) => {
		if (["true", "false"].includes(data.showBanner))
			return Boolean(value) ? "profile" : "off"
		return value
	}

	const enableUploadCondition = (value) =>
		!enableBannerAvatarSelect ? value : value === "custom"

	useEffect(() => {
		if (!enableBannerAvatarSelect) return
		if (["true", "false"].includes(data.showBanner))
			updateShowControls("showBanner", getSelectValue(data.showBanner))
		if (["true", "false"].includes(data.showAvatar))
			updateShowControls("showAvatar", getSelectValue(data.showAvatar))
	}, [data, enableBannerAvatarSelect])

	return (
		<div className={styles.sharedControlsRoot}>
			{uploadBanner && (
				<div className={form.section}>
					<div className={form.header}>
						<label className={toggle.checkSpaceBetween}>
							<div className={toggle.labelSm}>Show Banner</div>
							{enableBannerAvatarSelect ? (
								<select
									className={textfield.naked}
									value={getSelectValue(data.showBanner)}
									onChange={(e) =>
										updateShowControls("showBanner", e.target.value)
									}
								>
									<option value="profile">Profile</option>
									<option value="custom">Custom</option>
									<option value="off">Off</option>
								</select>
							) : (
								<div className={toggle.switchSm}>
									<input
										type="checkbox"
										checked={data.showBanner}
										onChange={(e) =>
											updateShowControls(
												"showBanner",
												e.target.checked
											)
										}
									/>
									<div className={toggle.sliderSm} />
								</div>
							)}
						</label>
					</div>
					{enableUploadCondition(data.showBanner) && (
						<div className={form.content}>
							{data.bannerPhoto ? (
								<div
									className={styles.bannerHasImage}
									style={{
										backgroundImage:
											data.bannerPhoto && `url(${data.bannerPhoto})`,
									}}
								>
									<div className={styles.bannerContentStatic}>
										<button
											type="button"
											className={`${button.lightIcon} ${button.iconCrircle}`}
											onClick={() => {
												uploadBanner(null)
											}}
										>
											<TrashIcon className={icons.icon24} />
										</button>
									</div>
								</div>
							) : (
								<div className={styles.banner}>
									<div className={styles.bannerContent}>
										<Dropzone
											multiple={false}
											onDrop={(acceptedFiles) => {
												uploadBanner(acceptedFiles)
											}}
										>
											{({ getRootProps, getInputProps }) => (
												<div
													{...getRootProps()}
													className={styles.bannerDrop}
												>
													<input {...getInputProps()} />
													<PlusIcon className={icons.icon24} />
												</div>
											)}
										</Dropzone>
									</div>
								</div>
							)}
							<p className={styles.bannerRecSize}>
								1600 x 300 (16:3) recommended
							</p>
						</div>
					)}
				</div>
			)}
			{uploadAvatar && (
				<div className={form.section}>
					<div className={form.header}>
						<label className={toggle.checkSpaceBetween}>
							<div className={toggle.labelSm}>Show Avatar</div>
							{enableBannerAvatarSelect ? (
								<select
									className={textfield.naked}
									value={getSelectValue(data.showAvatar)}
									onChange={(e) =>
										updateShowControls("showAvatar", e.target.value)
									}
								>
									<option value="profile">Profile</option>
									<option value="custom">Custom</option>
									<option value="off">Off</option>
								</select>
							) : (
								<div className={toggle.switchSm}>
									<input
										type="checkbox"
										checked={data.showAvatar}
										onChange={(e) =>
											updateShowControls(
												"showAvatar",
												e.target.checked
											)
										}
									/>
									<div className={toggle.sliderSm} />
								</div>
							)}
						</label>
					</div>
					{enableUploadCondition(data.showAvatar) && (
						<div className={form.content}>
							{data.avatar ? (
								<div className={styles.avatarHasImage}>
									<Avatar
										photoUrl={data.avatar}
										fullname={data.fullname}
									/>
									<button
										type="button"
										className={`${button.lightIcon} ${button.iconCrircle}`}
										onClick={() => {
											uploadAvatar(null)
										}}
									>
										<TrashIcon className={icons.icon24} />
									</button>
								</div>
							) : (
								<div className={styles.avatar}>
									<Dropzone
										multiple={false}
										onDrop={(acceptedFiles) => {
											uploadAvatar(acceptedFiles)
										}}
									>
										{({ getRootProps, getInputProps }) => (
											<div
												{...getRootProps()}
												className={styles.avatarDrop}
											>
												<input {...getInputProps()} />
												<PlusIcon className={icons.icon24} />
											</div>
										)}
									</Dropzone>
								</div>
							)}
							<p className={styles.bannerRecSize}>
								512 x 512 (1:1) recommended
							</p>
						</div>
					)}
				</div>
			)}
			{saveDisplayName && (
				<div className={form.section} data-novalidate="true">
					<div className={form.header}>
						<label className={toggle.checkSpaceBetween}>
							<div className={toggle.labelSm}>Show Display Name</div>
							<div className={toggle.switchSm}>
								<input
									type="checkbox"
									checked={data.showDisplayName}
									onChange={(e) =>
										updateShowControls(
											"showDisplayName",
											e.target.checked
										)
									}
								/>
								<div className={toggle.sliderSm} />
							</div>
						</label>
					</div>
					{data.showDisplayName && (
						<div className={form.content}>
							<input
								className={textfield.normal}
								defaultValue={data.displayName}
								type="text"
								onChange={(e) => saveDisplayName(e.target.value)}
							/>
						</div>
					)}
				</div>
			)}
			{saveDescription && (
				<div className={form.section}>
					<div className={form.header}>
						<label className={toggle.checkSpaceBetween}>
							<div className={toggle.labelSm}>Show Personal Message</div>
							<div className={toggle.switchSm}>
								<input
									type="checkbox"
									checked={data.showDescription}
									onChange={(e) =>
										updateShowControls(
											"showDescription",
											e.target.checked
										)
									}
								/>
								<div className={toggle.sliderSm} />
							</div>
						</label>
					</div>
					{data.showDescription && (
						<div className={form.content}>
							<EmojiTextArea
								defaultValue={(data.description || "").slice(0, 160)}
								className={textfield.textarea}
								rows={3}
								maxLength={160}
								onChange={(e) => {
									setBioLength(e.target.value.length)
								}}
								onBlur={saveDescription}
							/>
							<div className={styles.help}>
								{bioLength}/<b>160</b>
							</div>
						</div>
					)}
				</div>
			)}
		</div>
	)
}

export default EditPreviewSharedControls
