/**
 * @generated SignedSource<<31c0007a50e9a4e798e88f3791a04fa9>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Mutation } from 'relay-runtime';
export type FlagShopcastInput = {|
  clientMutationId?: ?string,
  reportId?: ?string,
  type?: ?string,
|};
export type flagShopcastMutation$variables = {|
  input: FlagShopcastInput,
|};
export type flagShopcastMutation$data = {|
  +flagShopcast: ?{|
    +admin: ?{|
      +shopcastUserReports: ?{|
        +edges: ?$ReadOnlyArray<?{|
          +node: ?({|
            +__typename: "LookbookUserReport",
            +id: string,
          |} | {|
            +__typename: "ShopcastUserReport",
            +id: string,
          |} | {|
            // This will never be '%other', but we need some
            // value in case none of the concrete values match.
            +__typename: "%other",
          |}),
        |}>,
      |},
    |},
  |},
|};
export type flagShopcastMutation = {|
  response: flagShopcastMutation$data,
  variables: flagShopcastMutation$variables,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = [
  (v2/*: any*/)
],
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "AdminShopcastUserReportConnection",
  "kind": "LinkedField",
  "name": "shopcastUserReports",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "AdminShopcastUserReportEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "__typename",
              "storageKey": null
            },
            {
              "kind": "InlineFragment",
              "selections": (v3/*: any*/),
              "type": "ShopcastUserReport",
              "abstractKey": null
            },
            {
              "kind": "InlineFragment",
              "selections": (v3/*: any*/),
              "type": "LookbookUserReport",
              "abstractKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "flagShopcastMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "FlagShopcastPayload",
        "kind": "LinkedField",
        "name": "flagShopcast",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Admin",
            "kind": "LinkedField",
            "name": "admin",
            "plural": false,
            "selections": [
              (v4/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "ShopshareMutationRoot",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "flagShopcastMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "FlagShopcastPayload",
        "kind": "LinkedField",
        "name": "flagShopcast",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Admin",
            "kind": "LinkedField",
            "name": "admin",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "3cd317c7698946af8a7862c6e3bd64a1",
    "id": null,
    "metadata": {},
    "name": "flagShopcastMutation",
    "operationKind": "mutation",
    "text": "mutation flagShopcastMutation(\n  $input: FlagShopcastInput!\n) {\n  flagShopcast(input: $input) {\n    admin {\n      shopcastUserReports {\n        edges {\n          node {\n            __typename\n            ... on ShopcastUserReport {\n              id\n            }\n            ... on LookbookUserReport {\n              id\n            }\n          }\n        }\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "6bb544c11856b0dd7665b205308084e4";

module.exports = ((node/*: any*/)/*: Mutation<
  flagShopcastMutation$variables,
  flagShopcastMutation$data,
>*/);
