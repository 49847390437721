import { commitMutation, graphql } from "react-relay"
import { ConnectionHandler } from "relay-runtime"
import { complete } from "../helper"
import connections from "../connections"

const mutation = graphql`
	mutation toggleFavoriteShopcastItemMutation(
		$input: ToggleFavoriteShopcastItemInput!
	) {
		toggleFavoriteShopcastItem(input: $input) {
			output
		}
	}
`

const commit = (env, id, type, onError, containerId) =>
	commitMutation(env, {
		mutation,
		variables: { input: { id, type } },
		onError: complete(null, onError),
		optimisticResponse: {
			toggleFavoriteShopcastItem: {
				output: "",
			},
		},
		updater: (storeProxy, dd) => {
			if (dd.toggleFavoriteShopcastItem) {
				const itemProxy = storeProxy.get(id)
				const currentlyStarred = itemProxy.getValue("isStarred")
				itemProxy.setValue(!itemProxy.getValue("isStarred"), "isStarred")
				const containerProxy = storeProxy.get(containerId)
				const starredConnection = ConnectionHandler.getConnection(
					containerProxy,
					connections.currentUser.shopcastManager.starredFolders.name,
					connections.currentUser.shopcastManager.starredFolders.data
				)
				if (currentlyStarred) {
					ConnectionHandler.deleteNode(starredConnection, id)
				} else {
					const newFolder = storeProxy.create(
						`ShopcastContainer:starredFolders:${new Date().getTime()}`,
						"newFolder"
					)
					newFolder.setLinkedRecord(itemProxy, "node")
					ConnectionHandler.insertEdgeAfter(starredConnection, newFolder)
				}
			}
		},
	})

export default { commit }
