import { commitMutation, graphql } from "react-relay"
import { complete } from "../helper.js"

const mutation = graphql`
	mutation reportLookbookMutation(
		$input: ReportLookbookInput!
	) {
		reportLookbook(input: $input) {
			store {
				id
			}
		}
	}
`

const getConfig = (storeId) => [
	{
		type: "FIELDS_CHANGE",
		fieldIDs: {
			store: storeId,
		},
	},
]

const commit = (env, lookbookId, description, reportType, storeId, onCompleted, onError) => commitMutation(env, {
	mutation,
	variables: { input: { lookbookId, description, reportType } },
	configs: getConfig(storeId),
	onCompleted: complete(onCompleted, onError),
	onError: (e) => complete(null, [e]),
})

export default { commit }
