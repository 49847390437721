import React from "react"
import MoneyIcon from "shared/assets/money-icon.svg"
import _ from "lodash"
import styles from "./sales-count.css"

export default function SalesCount({ transactions }) {
	const orderGroups = _.groupBy(
		transactions,
		(transaction) => transaction.orderId || transaction.id
	)
	const orderCount = Object.keys(orderGroups).length
	const tip = `${transactions.length} Sales from ${orderCount} Orders`
	return (
		<div
			className={styles.root}
			data-tooltip-id="tooltip"
			data-tooltip-content={tip}
		>
			<MoneyIcon className={styles.icon} />
			{transactions.length && (
				<span className={styles.total}>{transactions.length}</span>
			)}
		</div>
	)
}
