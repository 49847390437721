import React, { Component, Fragment } from "react"
import Modal from "react-modal"
import { toast } from "react-toastify"
import textfield from "shared/styles/components/textfield.css"
import TextareaAutosize from "react-autosize-textarea"
import button from "shared/styles/components/buttons.css"
import form from "shared/styles/components/form.css"
import modal from "shared/styles/components/modal.css"
import CloseIcon from "shared/assets/close-icon.svg"
import trackingService from "services/tracking-service"

import GetInTouchMutation from "mutations/user/get-in-touch"

export default class GetInTouch extends Component {
	constructor(props) {
		super(props)

		this.handleSubmitClick = this.handleSubmitClick.bind(this)
	}

	handleSubmitClick(e) {
		const { props } = this

		if (
			this.type.validity.valid &&
			this.email.validity.valid &&
			this.fullname.validity.valid &&
			this.message.validity.valid
		) {
			e.preventDefault()

			GetInTouchMutation.commit(
				props.relay.environment,
				{
					to: props.to.id,
					email: this.email.value,
					fullName: this.fullname.value,
					category: this.type.value,
					message: this.message.value,
				},
				() => {
					trackingService.track("Get In Touch", {
						profileName: props.to.fullname,
						email: this.email.value,
						fullName: this.fullname.value,
					})
					props.closeModal()
					toast.success(<Fragment>Your message has been sent.</Fragment>, {
						autoClose: 2000,
						closeButton: false,
					})
				},
				() => {
					toast.error(
						<Fragment>
							An error occurred while trying to send your email.
						</Fragment>,
						{ autoClose: 3000, closeButton: false }
					)
				}
			)
		}
	}

	render() {
		const { props } = this
		return (
			<Modal
				className={modal.root}
				overlayClassName={modal.overlay}
				htmlOpenClassName="ReactModal__Html--open"
				isOpen={props.showGetInTouch}
				shouldCloseOnOverlayClick
				ariaHideApp={false}
			>
				<div className={`${modal.dialog} ${modal.dialogCentered}`}>
					<div className={`${modal.content} ${modal.whiteBg}`}>
						<div className={`${modal.header}`}>
							<h4 className={modal.title}>
								Contact{" "}
								{!props.to.isRetailer
									? props.to.fullname.split(" ")[0]
									: props.to.fullname}
							</h4>
							<button
								type="button"
								className={button.close}
								onClick={() => {
									props.closeModal()
								}}
							>
								<CloseIcon className={button.closeIcon} />
							</button>
						</div>
						<form
							name="signup"
						>
							<div className={modal.body}>
								<div className={form.group}>
									<label className={form.label}>Name</label>
									<input
										className={textfield.normal}
										required
										name="fullname"
										ref={(input) => {
											this.fullname = input
										}}
									/>
								</div>
								<div className={form.group}>
									<label className={form.label}>Email</label>
									<input
										className={textfield.normal}
										required
										type="email"
										name="email"
										ref={(input) => {
											this.email = input
										}}
									/>
								</div>
								<div className={form.group}>
									<label className={form.label}>Interested In</label>
									<select
										className={textfield.normal}
										required
										name="type"
										ref={(input) => {
											this.type = input
										}}
									>
										<option value="">Please Select</option>
										<option value="Women's Styling">
											Women&apos;s Styling
										</option>
										<option value="Men's Styling">
											Men&apos;s Styling
										</option>
										<option value="Virtual Styling Services">
											Virtual Styling Services
										</option>
										<option value="Special Occasion or Event">
											Special Occasion or Event
										</option>
										<option value="Editorial Styling">
											Editorial Styling
										</option>
										<option value="Commercial/Branding">
											Commercial/Branding
										</option>
										<option value="Masterclasses/Styling Events">
											Masterclasses/Styling Events
										</option>
										<option value="Collaboration">
											Collaboration
										</option>
										<option value="Other">Other</option>
									</select>
								</div>
								<div className={form.group}>
									<label className={form.label}>Message</label>
									<TextareaAutosize
										ref={(input) => {
											this.message = input
										}}
										className={textfield.textarea}
										rows={4}
										required
									/>
								</div>
							</div>
							<div className={`${modal.footer}`}>
								<button
									type="button"
									className={button.link}
									onClick={() => {
										props.closeModal()
									}}
								>
									Cancel
								</button>
								<button
									type="submit"
									className={button.primary}
									onClick={this.handleSubmitClick}
								>
									Send
								</button>
							</div>
						</form>
					</div>
				</div>
			</Modal>
		)
	}
}
