import React from "react"
import { Transition } from "react-transition-group"
import { Cross2Icon } from "@radix-ui/react-icons"
// import RemoveBgIcon from "shared/assets/lb-removebg-icon.svg"
import { isBackgroundRemovedImage } from "helpers/lookbook-helper"
import { addVersioning } from "helpers/query-parameters-helper"
import modal from "shared/styles/components/modal.css"
import form from "shared/styles/components/form.css"
import button from "shared/styles/components/buttons.css"
import toggle from "shared/styles/components/toogle.css"
import textfield from "shared/styles/components/textfield.css"
// import icons from "shared/styles/components/icons.css"
import LoadingDots from "components/loading-dots/loading-dots"
import AutoSuggest from "components/auto-suggest-new/auto-suggest"
import styles from "./image-upload.css"

export default function EditModalContent({
	adminAccess,
	edit,
	errors,
	subCategorySet,
	setData,
	closeModal,
	save,
	// removeBackground,
	imageEditing,
	saving,
	noCollectionSelect,
	removeImageShoppable,
}) {
	const onSelect = (productCollectionsArray) => {
		const newCollection = []
		productCollectionsArray.forEach((collection) => {
			if (collection.id.includes("new_")) {
				newCollection.push(collection)
			}
		})
		setData({
			edit: { ...edit, collections: productCollectionsArray },
			subCategorySet: [...subCategorySet, ...newCollection],
			errors: [],
		})
	}

	return (
		<div
			className={`${modal.dialog} ${modal.dialogCentered} ${modal.dialogFullscreenSmDown} ${modal.dialogLg}`}
		>
			<div className={`${modal.content}`}>
				<div className={modal.header}>
					<h4 className={modal.title}>Edit Image</h4>
					{adminAccess && (
						<div className={modal.headerActions}>
							<div
								className={styles.toggle}
								data-tooltip-id="tooltip"
								data-tooltip-place="bottom"
								data-tooltip-content="Make Public"
							>
								<label className={toggle.check}>
									<div className={toggle.switch}>
										<input
											type="checkbox"
											checked={edit.adminAccess}
											onChange={({ target }) =>
												setData({
													edit: {
														...edit,
														adminAccess: target.checked,
													},
												})
											}
										/>
										<div className={toggle.slider} />
									</div>
								</label>
							</div>
						</div>
					)}
					<button
						type="button"
						className={button.close}
						onClick={() => closeModal(true)}
					>
						<Cross2Icon className={button.closeIcon} />
					</button>
				</div>
				<div className={modal.body}>
					<div className={form.row}>
						<div className={styles.col}>
							<div className={form.group}>
								<label className={form.label}>Image</label>
								<div
									className={
										edit.url && isBackgroundRemovedImage(edit.url)
											? styles.productTrans
											: styles.product
									}
								>
									{/* <div className={styles.actions}>
										{edit.url && removeBackground && (
											<button
												type="button"
												className={button.circle}
												onClick={() => removeBackground(edit.url)}
												disabled={!!imageEditing}
												data-tooltip-id="tooltip"
												data-tooltip-place="left"
												data-tooltip-content={
													isBackgroundRemovedImage(edit.url)
														? "Restore Background"
														: "Remove Background"
												}
											>
												{isBackgroundRemovedImage(edit.url) ? (
													<ResetIcon className={icons.icon18} />
												) : (
													<RemoveBgIcon className={icons.icon18} />
												)}
											</button>
										)}
									</div> */}
									<img
										src={
											edit.url
												? addVersioning(edit.url, `d=400`)
												: edit.tempUrl
										}
										alt={edit.title}
										className={styles.image}
									/>
									<Transition
										in={imageEditing}
										appear={imageEditing}
										timeout={300}
									>
										{(state) => (
											<div
												className={`${styles.loading} ${styles[state]}`}
											>
												<LoadingDots color="var(--primary50)" />
											</div>
										)}
									</Transition>
								</div>
							</div>
						</div>
						<div className={styles.col}>
							<div className={form.group}>
								{errors.length > 0 && (
									<div className={form.error}>
										{errors.map((i) => (
											<div key={i}>{i}</div>
										))}
									</div>
								)}
							</div>
							<div className={form.group}>
								<label className={form.label}>Title</label>
								<input
									className={textfield.normal}
									onBlur={({ target }) =>
										setData({
											edit: {
												...edit,
												title: target.value,
											},
											errors: [],
										})
									}
									defaultValue={edit.title}
								/>
							</div>

							{!noCollectionSelect && (
								<div className={form.group}>
									<label className={form.label}>Collection</label>
									<AutoSuggest
										result={edit.collections}
										dataList={subCategorySet}
										onChange={onSelect}
									/>
								</div>
							)}
						</div>
					</div>
				</div>
				<div className={modal.footer}>
					{!removeImageShoppable && (
						<div className={styles.toggle}>
							<label className={toggle.check}>
								<div className={toggle.switch}>
									<input
										type="checkbox"
										checked={edit.isProduct}
										onChange={() =>
											setData({
												edit: {
													...edit,
													isProduct: true,
												},
												errors: [],
											})
										}
									/>
									<div className={toggle.slider} />
								</div>
								<div className={toggle.label}>Make Image Shoppable</div>
							</label>
						</div>
					)}
					<button
						type="button"
						className={button.link}
						onClick={() => closeModal()}
					>
						{saving ? <LoadingDots color="var(--gray300)" /> : "Cancel"}
					</button>
					<button
						type="submit"
						disabled={saving}
						className={button.primary}
						onClick={() => save()}
					>
						{saving ? <LoadingDots color="var(--primary80)" /> : "Save"}
					</button>
				</div>
			</div>
		</div>
	)
}
