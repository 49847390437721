/**
 * @generated SignedSource<<7500e994610fb98f4e37a2689a358f11>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Mutation } from 'relay-runtime';
export type CreateCategorizeLookbookUploadsInput = {|
  adminAccess?: ?boolean,
  category?: ?string,
  clientMutationId?: ?string,
  collections?: ?$ReadOnlyArray<IdValue>,
  id?: ?string,
  stylist?: ?string,
  teamId?: ?string,
  title: string,
  url?: ?string,
|};
export type IdValue = {|
  id?: ?string,
  value?: ?string,
|};
export type updateCategorizeLookbookUploadMutation$variables = {|
  ext?: ?string,
  fileType?: ?string,
  input: CreateCategorizeLookbookUploadsInput,
|};
export type updateCategorizeLookbookUploadMutation$data = {|
  +createCategorizeLookbookUploads: ?{|
    +lookbookUploads: ?{|
      +adminAccess: ?boolean,
      +canEdit: ?boolean,
      +id: string,
      +isFavourite: ?boolean,
      +preSignedUrl: ?{|
        +signedRequest: ?string,
        +url: ?string,
      |},
      +productCollections: ?$ReadOnlyArray<?{|
        +id: string,
        +title: ?string,
      |}>,
      +title: ?string,
      +url: ?string,
      +user: ?{|
        +id: string,
      |},
    |},
  |},
|};
export type updateCategorizeLookbookUploadMutation = {|
  response: updateCategorizeLookbookUploadMutation$data,
  variables: updateCategorizeLookbookUploadMutation$variables,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "ext"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "fileType"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "input"
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "url",
  "storageKey": null
},
v5 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "CreateCategorizeLookbookUploadsPayload",
    "kind": "LinkedField",
    "name": "createCategorizeLookbookUploads",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "lookbookUploads",
        "kind": "LinkedField",
        "name": "lookbookUploads",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "adminAccess",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "canEdit",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "title",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isFavourite",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ProductCollection",
            "kind": "LinkedField",
            "name": "productCollections",
            "plural": true,
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": [
                  {
                    "kind": "Literal",
                    "name": "ownershipCheck",
                    "value": true
                  }
                ],
                "kind": "ScalarField",
                "name": "title",
                "storageKey": "title(ownershipCheck:true)"
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              (v3/*: any*/)
            ],
            "storageKey": null
          },
          (v4/*: any*/),
          {
            "alias": null,
            "args": [
              {
                "kind": "Variable",
                "name": "ext",
                "variableName": "ext"
              },
              {
                "kind": "Variable",
                "name": "fileType",
                "variableName": "fileType"
              }
            ],
            "concreteType": "PreSignedUrl",
            "kind": "LinkedField",
            "name": "preSignedUrl",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "signedRequest",
                "storageKey": null
              },
              (v4/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "updateCategorizeLookbookUploadMutation",
    "selections": (v5/*: any*/),
    "type": "ShopshareMutationRoot",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "updateCategorizeLookbookUploadMutation",
    "selections": (v5/*: any*/)
  },
  "params": {
    "cacheID": "38dfe75a12539da0cab937d778dc1bf1",
    "id": null,
    "metadata": {},
    "name": "updateCategorizeLookbookUploadMutation",
    "operationKind": "mutation",
    "text": "mutation updateCategorizeLookbookUploadMutation(\n  $input: CreateCategorizeLookbookUploadsInput!\n  $fileType: String\n  $ext: String\n) {\n  createCategorizeLookbookUploads(input: $input) {\n    lookbookUploads {\n      id\n      adminAccess\n      canEdit\n      title\n      isFavourite\n      productCollections {\n        id\n        title(ownershipCheck: true)\n      }\n      user {\n        id\n      }\n      url\n      preSignedUrl(fileType: $fileType, ext: $ext) {\n        signedRequest\n        url\n      }\n    }\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "3f0350842eb7a30945d65e16eee7e904";

module.exports = ((node/*: any*/)/*: Mutation<
  updateCategorizeLookbookUploadMutation$variables,
  updateCategorizeLookbookUploadMutation$data,
>*/);
