import React from "react"
import LookbookDropdown from "components/lookbook-dropdown/lookbook-dropdown"
import Slider from "components/lookbook-dropdown/slider"
import TransIcon from "shared/assets/transparent-icon.svg"
import styles from "routes/lookbook/moveable.css"

export default function TransparentHandler({
	imageTransparentLevel,
	handleImageTransparent,
	runDebounce,
	dataTip,
}) {
	return (
		<LookbookDropdown
			contentRight
			label={<TransIcon className={styles.icon} />}
			data-tooltip-id="tooltip"
			data-tooltip-content={dataTip}
			runDebounce={runDebounce}
		>
			<Slider
				min={0}
				max={100}
				isDark
				step={1}
				value={imageTransparentLevel}
				onSlide={handleImageTransparent}
				wShadow
			/>
		</LookbookDropdown>
	)
}
