/* eslint-disable no-unused-expressions */
import React, { Component } from "react"
import vTime from "video-time"
import {
	Cross2Icon,
	PlayIcon,
	PauseIcon,
	ResetIcon,
	StopIcon,
} from "@radix-ui/react-icons"
import WidgetPlayer from "components/widget-player/widget-player"
import LoadingDots from "components/loading-dots/loading-dots"
import ConformationPrompt from "components/modals/conformation-prompt/conformation-prompt"
import DeviceVisualizer from "components/device-visualizer/device-visualizer"
import DropdownMenu from "components/dropdown-menu/dropdown-menu"
import ProgressBar from "components/progress-bar/progress-bar"
import awsService from "services/aws-service"
import lookbookVideoPresignedMutation from "mutations/lookbook/lookbook-video-presigned"
import setLookbookVideoUrlMutation from "mutations/lookbook/set-lookbook-video-url"
import MicIcon from "shared/assets/microphone.svg"
import VideoIcon from "shared/assets/video-camera.svg"
import VideoOffIcon from "shared/assets/video-camera-off.svg"
import ResolutionIcon from "shared/assets/resolution-icon.svg"
import AllowAccessChrome from "shared/assets/allow_access.png"
import AllowAccessFirefox from "shared/assets/allow_access_firefox.png"
import button from "shared/styles/components/buttons.css"
import textfield from "shared/styles/components/textfield.css"
import form from "shared/styles/components/form.css"
import CountDown from "./countdown"
import styles from "./recorder.css"
import { isFirefox } from "../../helpers/ui-helper"
import {
	getVideoRecordFormat,
	getAudioRecordFormat,
} from "../../helpers/lookbook-helper"

const RecorderStatus = {
	LOADING: "LOADING",
	LOADED: "LOADED",
	BLOCKED: "BLOCKED",
	SETTING_UP: "SET",
	READY: "READY",
	RECORDING: "REC",
	PAUSE: "PAU",
	STOPPED: "STOP",
	UPLOADING: "UPLOADING",
	UPLOADED: "UPLOADED",
	TRANSCODING: "TRANSCODING",
	TRANSCODED: "TRANSCODED",
}

const quickScan = [
	{
		label: "4K(UHD)",
		width: 3840,
		height: 2160,
	},
	{
		label: "1080p",
		width: 1920,
		height: 1080,
	},
	{
		label: "720p",
		width: 1280,
		height: 720,
	},
	{
		label: "360p",
		width: 640,
		height: 360,
	},
]

export default class Recorder extends Component {
	constructor(props) {
		super(props)

		this.state = {
			status: RecorderStatus.LOADING,
			recordingDuration: 0,

			selectedCamera: "",
			selectedResolution: "",
			selectedMic: "",

			micList: [],
			cameraList: [],
			cameraResolutions: quickScan,

			isContinue: false,
			countdown: -1,

			openConfirm: false,
			videoUrl: "",
			progress: 0,
		}

		this.videoUrl = ""
		this.resolutionChecks = this.resolutionChecks.bind(this)
		this.setCameraFeed = this.setCameraFeed.bind(this)
		this.saveDevice = this.saveDevice.bind(this)
		this.saveResolution = this.saveResolution.bind(this)
		this.restartRecording = this.restartRecording.bind(this)
		this.stopRecording = this.stopRecording.bind(this)
		this.togglePause = this.togglePause.bind(this)
		this.toggleRecording = this.toggleRecording.bind(this)
		this.saveRecording = this.saveRecording.bind(this)
		this.record = this.record.bind(this)
		this.setRecordingBlob = this.setRecordingBlob.bind(this)
		this.uploadVideo = this.uploadVideo.bind(this)
		this.transcodeVideo = this.transcodeVideo.bind(this)
		this.renderProgress = this.renderProgress.bind(this)
		this.renderSetupDevices = this.renderSetupDevices.bind(this)
		this.killRecordering = this.killRecordering.bind(this)
		this.getMime = this.getMime.bind(this)
	}

	async componentDidMount() {
		if (getVideoRecordFormat() || getAudioRecordFormat()) {
			const { videoUrl } = this.props
			this.videoUrl = videoUrl
			const { micList: mics, cameraList: cameras } = this.state
			if (typeof window !== "undefined") {
				const selectedCamera = localStorage.getItem("selectedCamera")
				const selectedMic = localStorage.getItem("selectedMic")
				if (!selectedCamera || !selectedMic)
					setTimeout(() => {
						const { status: latestStatus } = this.state
						if (latestStatus === RecorderStatus.LOADING) {
							this.setState({ status: RecorderStatus.LOADED })
						}
					}, 1500)
				navigator.mediaDevices
					.getUserMedia({ video: true, audio: true })
					.then((stream) => {
						stream.getTracks().forEach(async (track) => {
							const src = {
								deviceId: track.getSettings().deviceId,
								groupId: track.getSettings().groupId,
								label: track.label,
							}
							if (
								track.kind === "video" &&
								!this.deviceAvailable(src.deviceId, cameras)
							) {
								cameras.push({ ...src, kind: "video" })
							}
							if (
								track.kind === "audio" &&
								!this.deviceAvailable(src.deviceId, mics)
							) {
								mics.push({ ...src, kind: "audio" })
							}
							const {
								micList,
								cameraList,
							} = await this.getDeviceAccess()
							const settingState = {
								micList,
								cameraList,
								isContinue: false,
							}
							if (
								!selectedMic ||
								!this.deviceAvailable(selectedMic, micList)
							) {
								settingState.status = RecorderStatus.SETTING_UP
							}
							this.setState(settingState, async () => {
								await this.saveDevice("video", selectedCamera)
								const mic = await this.saveDevice("audio", selectedMic)
								if (mic) {
									this.setState({ status: RecorderStatus.READY })
								}
							})
							navigator.mediaDevices.ondevicechange = async () => {
								const {
									micList: micListGranted,
									cameraList: cameraListGranted,
								} = await this.getDeviceAccess()
								this.setState({
									micList: micListGranted,
									cameraList: cameraListGranted,
								})
							}
							track.stop()
						})
					})
					.catch((err) => {
						this.setState({ status: RecorderStatus.BLOCKED })
						console.error(err)
					})
			}
		} else {
			console.error("Cannot Find Supported Record Format")
		}
	}

	componentWillUnmount() {
		this.killRecordering()
	}

	getMime() {
		const { selectedCamera } = this.state
		return selectedCamera ? getVideoRecordFormat() : getAudioRecordFormat()
	}

	setCameraFeed(selectedCamera, selectedMic, selectedResolution) {
		const { status } = this.state
		if (status !== RecorderStatus.READY)
			this.setState({ status: RecorderStatus.READY })
		this.killRecordering()
		if (selectedMic && selectedCamera && selectedResolution) {
			const constraints = {
				audio: selectedMic ? { deviceId: selectedMic } : false,
				video: selectedCamera
					? {
							deviceId: selectedCamera,
							...(selectedResolution.width
								? {
										width: { exact: selectedResolution.width },
										height: { exact: selectedResolution.height },
								  }
								: {}),
					  }
					: false,
			}
			navigator.mediaDevices
				.getUserMedia(constraints)
				.then((stream) => {
					this.video.src = ""
					this.video.srcObject = stream
				})
				.catch((err) => {
					if (!this.video) return
					this.video.src = ""
					this.video.srcObject = null
					console.error("setCameraFeed-error", err)
				})
		} else if (selectedMic) {
			navigator.mediaDevices
				.getUserMedia({ audio: { deviceId: selectedMic } })
				.then((stream) => {
					this.video.src = ""
					this.video.srcObject = stream
				})
				.catch((err) => {
					if (!this.video) return
					this.video.src = ""
					this.video.srcObject = null
					console.error("setCameraFeed-error", err)
				})
		}
	}

	async getDeviceAccess(micList = [], cameraList = []) {
		try {
			const devices = await navigator.mediaDevices.enumerateDevices()
			const cameras = devices.filter((obj) => obj.kind === "videoinput")
			const mics = devices.filter((obj) => obj.kind === "audioinput")
			cameras.forEach((source) => {
				const src = {
					deviceId: source.deviceId,
					groupId: source.groupId,
					label: source.label,
				}
				if (!this.deviceAvailable(src.deviceId, cameraList)) {
					cameraList.push({ ...src, kind: "video" })
				}
			})
			mics.forEach((source) => {
				const src = {
					deviceId: source.deviceId,
					groupId: source.groupId,
					label: source.label,
				}
				if (!this.deviceAvailable(src.deviceId, micList)) {
					micList.push({ ...src, kind: "video" })
				}
			})
		} catch (error) {
			console.error(error)
		}
		return { micList, cameraList }
	}

	setRecordingBlob(blob) {
		if (blob) {
			this.videoBlob = blob
			this.setState({
				videoUrl: URL.createObjectURL(this.videoBlob),
			})
		} else {
			this.setState({ videoUrl: "" })
		}
	}

	async saveDevice(kind, deviceId = null) {
		const { status, selectedMic, selectedResolution, micList } = this.state
		switch (kind) {
			case "audio": {
				if (micList.length === 0) {
					console.error(`${kind} devices not Found`)
					return null
				}
				if (deviceId && this.deviceAvailable(deviceId, micList)) {
					localStorage.setItem("selectedMic", deviceId)
					this.setState({ selectedMic: deviceId })
					if (status === RecorderStatus.READY) {
						this.setCameraFeed(deviceId, selectedMic, selectedResolution)
					}
					return deviceId
				}
				return null
			}
			case "video": {
				localStorage.setItem("selectedCamera", deviceId || "")
				this.setState({ selectedCamera: deviceId || "" }, async () => {
					await this.resolutionChecks(deviceId)
				})
				if (status === RecorderStatus.READY && !deviceId) {
					this.setCameraFeed(deviceId, selectedMic, selectedResolution)
				}
				return deviceId
			}
			default: {
				console.error("Not selected device type")
				return null
			}
		}
	}

	saveResolution(selectedResolutionString) {
		const { status, selectedMic, selectedCamera } = this.state
		localStorage.setItem(
			"selectedResolution",
			JSON.stringify(selectedResolutionString)
		)
		this.setState({ selectedResolution: selectedResolutionString })
		if (status === RecorderStatus.READY) {
			this.setCameraFeed(
				selectedCamera,
				selectedMic,
				selectedResolutionString
			)
		}
	}

	deviceAvailable(deviceId, devices) {
		if (devices.find((obj) => obj.deviceId === deviceId)) {
			return true
		}
		return false
	}

	restartRecording() {
		const { selectedCamera, selectedMic, selectedResolution } = this.state
		const { menuDisability } = this.props
		menuDisability(false)
		this.setCameraFeed(selectedCamera, selectedMic, selectedResolution)
		window.clearInterval(this.recordingInterval)
		this.setState({
			status: RecorderStatus.READY,
			recordingDuration: 0,
			isContinue: false,
		})
	}

	killRecordering() {
		if (this.video && this.video.srcObject) {
			this.video.srcObject.getTracks().forEach((track) => track.stop())
		}
	}

	stopRecording() {
		if (this.mediaRecorder) {
			window.clearInterval(this.recordingInterval)
			this.mediaRecorder?.stop()
		} else {
			this.restartRecording()
		}
	}

	togglePause() {
		const { countdown } = this.state
		this.setState({
			status: RecorderStatus.PAUSE,
			isContinue: true,
		})
		if (countdown < 0) {
			window.clearInterval(this.recordingInterval)
			this.mediaRecorder.pause()
		}
	}

	toggleRecording() {
		const { status } = this.state
		this.setState({
			countdown: status === RecorderStatus.READY ? 5 : 3,
			status: RecorderStatus.RECORDING,
		})
	}

	record() {
		const { recordingDuration } = this.state
		const { menuDisability } = this.props
		menuDisability(true)
		this.setState({ countdown: -1 })
		if (recordingDuration) {
			this.mediaRecorder.resume()
		} else {
			this.chunks = []
			const recFormat = this.getMime()
			if (!this.video.srcObject) return
			this.mediaRecorder = new MediaRecorder(this.video.srcObject, {
				mimeType: recFormat.mimeType,
			})
			this.mediaRecorder.addEventListener("dataavailable", (event) => {
				if (event.data && event.data.size > 0) this.chunks.push(event.data)
			})
			this.mediaRecorder.addEventListener("stop", async () => {
				const { status } = this.state
				this.killRecordering()
				this.videoBlob = await new Promise((resolve) => {
					const blob = new Blob(this.chunks, {
						type: recFormat.mimeType,
					})
					resolve(blob)
				})
				if (status !== RecorderStatus.READY) {
					this.setState({ status: RecorderStatus.STOPPED })
					this.setRecordingBlob(this.videoBlob)
				}
			})
			this.mediaRecorder.start(10000)
		}
		this.setState({ recordingDuration })
		this.recordingInterval = setInterval(() => {
			const { recordingDuration: recDuration } = this.state
			this.setState({ recordingDuration: recDuration + 1 })
		}, 1000)
	}

	async resolutionChecks(selectedCamera) {
		if (!selectedCamera) return
		const promise = quickScan.map(async (scan) => {
			const constraints = {
				audio: false,
				video: {
					deviceId: { exact: selectedCamera },
					width: { exact: scan.width },
					height: { exact: scan.height },
				},
			}
			try {
				const stream = await navigator.mediaDevices.getUserMedia(
					constraints
				)
				stream.getTracks().forEach((track) => {
					track.stop()
				})
				return scan
			} catch (e) {
				// console.log(scan, e)
				return false
			}
		})
		const resolutions = (await Promise.all(promise)).filter((obj) => obj)
		if (resolutions.length === 0) {
			console.error(
				`not have a valid resolution for camera ${selectedCamera}`
			)
			return
		}
		const cachedResolutionString = localStorage.getItem("selectedResolution")
		const isAvailable = JSON.stringify(resolutions).includes(
			cachedResolutionString
		)
		const selectedResolution = isAvailable
			? JSON.parse(cachedResolutionString)
			: resolutions[0]
		this.setState({ cameraResolutions: resolutions })
		this.saveResolution(selectedResolution)
	}

	saveRecording(published = true) {
		const { relay, lookbookId, boardId } = this.props
		const recFormat = this.getMime()
		lookbookVideoPresignedMutation.commit(
			relay.environment,
			lookbookId,
			recFormat.ext,
			recFormat.mimeType,
			async ({ getVideoPresignedUrl }) => {
				const { url, signedRequest } = getVideoPresignedUrl.preSignedUrl
				this.uploadVideo(signedRequest, this.videoBlob).then(() => {
					this.videoUrl = url
					this.transcodeVideo(boardId, url, published)
				})
			},
			(error) => {
				console.error(error)
				this.restartRecording()
			}
		)
	}

	async uploadVideo(signedRequest, blob) {
		const { menuDisability, setLookbookVideo } = this.props
		this.setState({
			progress: 0.01,
			status: RecorderStatus.UPLOADING,
		})
		setLookbookVideo(null)
		await awsService.uploadFileAWS(
			signedRequest,
			blob,
			(completed) => this.setState({ progress: completed }),
			() => {
				this.setState({ status: RecorderStatus.UPLOADED, progress: 0 })
				menuDisability(false)
			}
		)
	}

	async transcodeVideo(boardId, awsUrl, published) {
		const { selectedCamera } = this.state
		const { relay, closeRecoder, changeTranscodingStatus } = this.props
		try {
			setLookbookVideoUrlMutation.commit(
				relay.environment,
				boardId,
				awsUrl,
				published,
				({ saveLookbookVideo }) => {
					this.videoUrl = saveLookbookVideo.video_url
					changeTranscodingStatus(boardId, true)
					closeRecoder()
				},
				(error) => {
					console.error(error)
				},
				!selectedCamera
			)
		} catch (err) {
			console.error(err)
		}
	}

	renderProgress() {
		const { progress, selectedCamera } = this.state
		return (
			<ProgressBar
				type="simple"
				theme="dark"
				text={`Uploading ${selectedCamera ? "Video" : "Audio"}`}
				progress={progress}
			/>
		)
	}

	renderSetupDevices() {
		const {
			micList,
			cameraList,
			selectedMic,
			selectedCamera,
			selectedResolution,
		} = this.state
		return (
			<div className={styles.root}>
				<div className={styles.deviceSetup}>
					<div className={styles.deviceSetupContent}>
						<div className={styles.deviceSetupHeader}>
							<h1 className={styles.title}>Recorder Setup</h1>
							<p className={styles.lead}>
								Please select the camera and microphone
							</p>
						</div>
						<div className={form.group}>
							<div className={styles.label}>
								<label className={form.labelDark}>Microphone</label>
								{selectedMic && (
									<div className={styles.visualizer}>
										<DeviceVisualizer
											isCamera={false}
											deviceId={selectedMic}
										/>
									</div>
								)}
							</div>
							<select
								className={textfield.dark}
								onChange={({ target }) =>
									this.saveDevice("audio", target.value)
								}
								value={selectedMic}
							>
								<option hidden>Select a source</option>
								{micList.map((obj) => (
									<option key={obj.deviceId} value={obj.deviceId}>
										{obj.label}
									</option>
								))}
							</select>
						</div>
						<div className={form.group}>
							<div className={styles.label}>
								<label className={form.labelDark}>Camera</label>
							</div>
							<select
								className={textfield.dark}
								onChange={({ target }) =>
									this.saveDevice("video", target.value)
								}
								value={selectedCamera}
							>
								<option hidden>Select a source</option>
								{cameraList.map((obj) => (
									<option key={obj.deviceId} value={obj.deviceId}>
										{obj.label}
									</option>
								))}
							</select>
						</div>

						{selectedCamera && (
							<DeviceVisualizer isCamera deviceId={selectedCamera} />
						)}

						<div className={styles.deviceSetupFooter}>
							<button
								type="button"
								className={`${button.primary} ${button.fullWidth}`}
								onClick={() =>
									this.setCameraFeed(
										selectedCamera,
										selectedMic,
										selectedResolution
									)
								}
							>
								Continue
							</button>
						</div>
					</div>
				</div>
			</div>
		)
	}

	render() {
		const {
			micList: mics,
			cameraList: cams,
			cameraResolutions: camRes,
			selectedMic,
			selectedCamera,
			selectedResolution,
			status,
			countdown,
			recordingDuration,
			videoUrl,
			openConfirm,
			isContinue,
		} = this.state
		const { closeRecoder, videoUrl: preVideoUrl } = this.props
		const mediaSetup = status === RecorderStatus.READY
		const micList = mics.map((obj) => ({
			click: () => this.saveDevice("audio", obj.deviceId),
			key: obj.deviceId,
			name: obj.label,
		}))
		const cameraList = [{ deviceId: "", label: "No Camera" }, ...cams].map(
			(obj) => ({
				click: () => this.saveDevice("video", obj.deviceId),
				key: obj.deviceId,
				name: obj.label,
			})
		)
		const cameraResolutions = camRes.map((obj) => ({
			click: () => this.saveResolution(obj),
			key: obj.label,
			name: obj.label,
		}))
		const liveRecording = status === RecorderStatus.RECORDING && countdown < 1

		if (status === RecorderStatus.LOADING) {
			return (
				<div className={styles.root}>
					<LoadingDots />
				</div>
			)
		}

		if (status === RecorderStatus.LOADED) {
			return (
				<div className={styles.root}>
					<div className={styles.deviceSetup}>
						<div className={styles.deviceSetupContent}>
							<div className={styles.deviceSetupHeader}>
								{/* <button type="button" onClick={closeRecoder}>
									<Cross2Icon className={button.closeIcon} />
								</button> */}
								<h1 className={styles.title}>Allow access</h1>
								{isFirefox() ? (
									<p className={styles.lead}>
										First, we need your permission to use your camera
										and microphone. Select the camera and microphone,
										then select{" "}
										<b>&quot;Remember this decision&quot;</b> and
										click <b>&quot;Allow&quot;</b>.
									</p>
								) : (
									<p className={styles.lead}>
										First, we need your permission to record with your
										camera and microphone. Don’t worry, we don’t
										record or store anything without your permission.
									</p>
								)}
							</div>
							{isFirefox() ? (
								<img
									className={styles.image}
									src={AllowAccessFirefox}
									alt="allow access firefox"
								/>
							) : (
								<img
									className={styles.image}
									src={AllowAccessChrome}
									alt="allow access chrome"
								/>
							)}

							{isFirefox() ? (
								<p>
									<a
										href="https://support.mozilla.org/en-US/kb/how-manage-your-camera-and-microphone-permissions"
										target="_blank"
										className={styles.link}
										rel="noopener noreferrer"
									>
										Don&apos;t see a prompt? Find out more
									</a>
								</p>
							) : (
								<p>
									<a
										href="https://support.google.com/chrome/answer/2693767?hl=en"
										target="_blank"
										className={styles.link}
										rel="noopener noreferrer"
									>
										Don&apos;t see a prompt? Find out more
									</a>
								</p>
							)}
						</div>
					</div>
				</div>
			)
		}

		if (status === RecorderStatus.BLOCKED) {
			return (
				<div className={styles.root}>
					<div className={styles.deviceSetup}>
						<div className={styles.deviceSetupContent}>
							<div className={styles.deviceSetupHeader}>
								<h1 className={styles.title}>
									Allow access when blocked
								</h1>
								{isFirefox() ? (
									<p className={styles.lead}>
										First, we need your permission to use your camera
										and microphone. Select the camera and microphone,
										then select{" "}
										<b>&quot; Remember this decision &quot;</b> and
										click
										<b>&quot; Allow &quot;</b>.
									</p>
								) : (
									<p className={styles.lead}>
										First, we need your permission to record with your
										camera and microphone. Don’t worry, we don’t
										record or store anything without your permission.
									</p>
								)}
							</div>
							{isFirefox() ? (
								<img
									className={styles.image}
									src={AllowAccessFirefox}
									alt="allow access firefox"
								/>
							) : (
								<img
									className={styles.image}
									src={AllowAccessChrome}
									alt="allow access chrome"
								/>
							)}

							{isFirefox() ? (
								<p>
									<a
										href="https://support.mozilla.org/en-US/kb/how-manage-your-camera-and-microphone-permissions"
										target="_blank"
										className={styles.link}
										rel="noopener noreferrer"
									>
										Don&apos;t see a prompt? Find out more
									</a>
								</p>
							) : (
								<p>
									<a
										href="https://support.google.com/chrome/answer/2693767?hl=en"
										target="_blank"
										className={styles.link}
										rel="noopener noreferrer"
									>
										Don&apos;t see a prompt? Find out more
									</a>
								</p>
							)}
						</div>
					</div>
				</div>
			)
		}

		if (status === RecorderStatus.SETTING_UP) {
			return this.renderSetupDevices()
		}

		if (status === RecorderStatus.UPLOADING) {
			return this.renderProgress()
		}

		return (
			<div className={styles.render}>
				<div className={styles.header}>
					{mediaSetup && (
						<>
							<div className={styles.settings}>
								<DropdownMenu
									activeItem={micList.find(
										(obj) => obj.key === selectedMic
									)}
									darkTheme
									items={micList}
									itemsWithSelection
									mainIcon={<MicIcon className={styles.audioIcon} />}
									strategy="absolute"
									triggerClass={button.circleDark}
									triggerClassActive={button.circleDarkActive}
								/>
								<div className={styles.audioMeter}>
									<DeviceVisualizer
										deviceId={selectedMic}
										isCamera={false}
									/>
								</div>
								<DropdownMenu
									activeItem={cameraList.find(
										(obj) => obj.key === selectedCamera
									)}
									darkTheme
									items={cameraList}
									itemsWithSelection
									mainIcon={
										!selectedCamera ? (
											<VideoOffIcon className={styles.videoIcon} />
										) : (
											<VideoIcon className={styles.videoIcon} />
										)
									}
									strategy="absolute"
									triggerClass={button.circleDark}
									triggerClassActive={button.circleDarkActive}
								/>
								{selectedCamera && (
									<DropdownMenu
										activeItem={cameraResolutions.find(
											(obj) => obj.key === selectedResolution.label
										)}
										darkTheme
										items={cameraResolutions}
										itemsWithSelection
										mainIcon={
											<ResolutionIcon className={styles.resIcon} />
										}
										strategy="absolute"
										triggerClass={button.circleDark}
										triggerClassActive={button.circleDarkActive}
									/>
								)}
							</div>
							{preVideoUrl && preVideoUrl.length > 0 && (
								<button
									className={button.circleDark}
									type="button"
									onClick={closeRecoder}
								>
									<Cross2Icon className={button.closeIcon} />
								</button>
							)}
						</>
					)}

					{(status === RecorderStatus.RECORDING ||
						!status === RecorderStatus.STOPPED ||
						status === RecorderStatus.PAUSE) && (
						<div className={styles.timer}>{vTime(recordingDuration)}</div>
					)}

					{status === RecorderStatus.STOPPED && (
						<button
							className={styles.restartBtn}
							aria-hidden
							type="button"
							data-tooltip-id="tooltip"
							data-tooltip-content="Restart Recording"
							data-tooltip-place="left"
							onClick={() => this.setState({ openConfirm: true })}
						>
							<ResetIcon className={styles.restartBtnIcon} />
						</button>
					)}
				</div>
				<div className={styles.content}>
					{(status === RecorderStatus.READY ||
						status === RecorderStatus.PAUSE ||
						status === RecorderStatus.RECORDING) && (
						<div className={selectedCamera ? styles.video : styles.audio}>
							{(status === RecorderStatus.READY ||
								status === RecorderStatus.PAUSE ||
								countdown >= 0) && (
								<button
									className={
										countdown > -1
											? styles.isAnimating
											: styles.startButton
									}
									type="button"
									onClick={
										status === RecorderStatus.RECORDING
											? this.togglePause
											: this.toggleRecording
									}
								>
									<span className={styles.background} />
									<h3 className={styles.centered}>
										{status !== RecorderStatus.RECORDING &&
											(isContinue ? "Continue" : "Start")}
										{status === RecorderStatus.RECORDING &&
											countdown >= 0 && (
												<CountDown
													show={countdown >= 0}
													onEnd={this.record}
													time={countdown}
												/>
											)}
									</h3>
								</button>
							)}
							{!selectedCamera && liveRecording && (
								<DeviceVisualizer
									deviceId={selectedMic}
									isCamera={false}
									size="lg"
								/>
							)}
							<div className={styles.videoFit}>
								{selectedCamera ? (
									<video
										ref={(video) => {
											this.video = video
										}}
										playsInline
										autoPlay
										muted
										className={styles.source}
									/>
								) : (
									<audio
										ref={(video) => {
											this.video = video
										}}
										autoPlay
										muted
										className={styles.source}
									/>
								)}
							</div>
						</div>
					)}

					{status === RecorderStatus.STOPPED && (
						<div className={styles.preview}>
							<WidgetPlayer
								rounded
								autoPlay={false}
								muxedUrl={videoUrl}
								isAudio={!selectedCamera}
							/>
							<div className={styles.deviceSetupFooter}>
								<button
									type="button"
									className={`${button.primary} ${button.fullWidth} ${button.medium}`}
									aria-hidden
									onClick={() => this.saveRecording(true)}
								>
									Save Recording
								</button>
							</div>
						</div>
					)}

					{(status === RecorderStatus.RECORDING ||
						status === RecorderStatus.PAUSE) && (
						<div className={styles.controls}>
							<div className={styles.control}>
								<button
									type="button"
									className={styles.btn}
									aria-hidden
									onClick={this.restartRecording}
								>
									<ResetIcon className={styles.controlIcon} />
								</button>
								<div className={styles.btnLabel}>Restart</div>
							</div>
							<div className={styles.control}>
								{status === RecorderStatus.RECORDING && (
									<button
										type="button"
										className={styles.btn}
										aria-hidden
										onClick={this.togglePause}
									>
										<PauseIcon className={styles.controlIcon} />
									</button>
								)}
								{status === RecorderStatus.PAUSE && (
									<button
										type="button"
										className={styles.btn}
										aria-hidden
										onClick={this.toggleRecording}
									>
										<PlayIcon className={styles.controlIcon} />
									</button>
								)}
								<div className={styles.btnLabel}>
									{status === RecorderStatus.PAUSE
										? "Continue"
										: "Pause"}
								</div>
							</div>
							<div className={styles.control}>
								<button
									type="button"
									className={styles.btn}
									aria-hidden
									onClick={this.stopRecording}
								>
									<StopIcon className={styles.controlIcon} />
								</button>
								<div className={styles.btnLabel}>Stop</div>
							</div>
						</div>
					)}
				</div>
				<ConformationPrompt
					openModal={openConfirm}
					title="Are you sure?"
					closeModal={() => {
						this.setState({ openConfirm: false })
					}}
					approveEvent={() => {
						this.setState({ openConfirm: false })
						this.restartRecording()
					}}
					declineEvent={() => {
						this.setState({ openConfirm: false })
					}}
					approveText="Yes, Start Again"
					approveButtonStyle={button.primaryRed}
					declineText="Cancel"
				>
					Do you want to permanently delete this recording and start again?
				</ConformationPrompt>
			</div>
		)
	}
}
