/**
 * @generated SignedSource<<50bfb7feca6cfbe5269c4820fd23297c>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentType } from "relay-runtime";
declare export opaque type rakutenSites_store$fragmentType: FragmentType;
export type rakutenSites_store$data = {|
  +categories: ?$ReadOnlyArray<?{|
    +id: string,
    +name: ?string,
  |}>,
  +countries: ?{|
    +edges: ?$ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +name: ?string,
      |},
    |}>,
  |},
  +currentUser: ?{|
    +adminAccess: ?string,
  |},
  +currentUserIsAnAdminOrASharer: ?boolean,
  +id: string,
  +networks: ?{|
    +edges: ?$ReadOnlyArray<?{|
      +node: ?{|
        +currency: ?string,
        +id: string,
        +name: ?string,
      |},
    |}>,
  |},
  +partneredNetworks: ?$ReadOnlyArray<?{|
    +key: ?string,
    +value: ?string,
  |}>,
  +rakutenSiteNetworks: ?{|
    +edges: ?$ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +name: ?string,
      |},
    |}>,
  |},
  +rakutenSiteRegions: ?{|
    +edges: ?$ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +name: ?string,
      |},
    |}>,
  |},
  +retailerCategories: ?$ReadOnlyArray<?{|
    +id: string,
    +name: ?string,
  |}>,
  +$fragmentType: rakutenSites_store$fragmentType,
|};
export type rakutenSites_store$key = {
  +$data?: rakutenSites_store$data,
  +$fragmentSpreads: rakutenSites_store$fragmentType,
  ...
};
*/

var node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  (v0/*: any*/),
  (v1/*: any*/)
],
v3 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "StoreCountryEdge",
    "kind": "LinkedField",
    "name": "edges",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Country",
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": (v2/*: any*/),
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "rakutenSites_store",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "currentUserIsAnAdminOrASharer",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "StoreCountryConnection",
      "kind": "LinkedField",
      "name": "rakutenSiteRegions",
      "plural": false,
      "selections": (v3/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "StoreNetworkConnection",
      "kind": "LinkedField",
      "name": "rakutenSiteNetworks",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "StoreNetworkEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "SiteNetwork",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": (v2/*: any*/),
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "first",
          "value": 1000
        }
      ],
      "concreteType": "StoreCountryConnection",
      "kind": "LinkedField",
      "name": "countries",
      "plural": false,
      "selections": (v3/*: any*/),
      "storageKey": "countries(first:1000)"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "StoreNetworkConnection",
      "kind": "LinkedField",
      "name": "networks",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "StoreNetworkEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "SiteNetwork",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v1/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "currency",
                  "storageKey": null
                },
                (v0/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "KeyValue",
      "kind": "LinkedField",
      "name": "partneredNetworks",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "key",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "value",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Category",
      "kind": "LinkedField",
      "name": "retailerCategories",
      "plural": true,
      "selections": (v2/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Category",
      "kind": "LinkedField",
      "name": "categories",
      "plural": true,
      "selections": (v2/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "currentUser",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "adminAccess",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    (v1/*: any*/)
  ],
  "type": "Store",
  "abstractKey": null
};
})();

(node/*: any*/).hash = "c3a1e3fbdb776ff8ebc3c5f3d2d52fa1";

module.exports = ((node/*: any*/)/*: Fragment<
  rakutenSites_store$fragmentType,
  rakutenSites_store$data,
>*/);
