import React from "react"
// import { ChevronUpIcon, ChevronDownIcon } from "@radix-ui/react-icons"
import { toTitleCase } from "helpers/string-helper"
import styles from "./filter-dropdown.css"

export default function FilterDropdown({
	title,
	children,
	isOpen,
	// openFilter,
}) {
	return (
		<div className={styles.root}>
			{/* <div
				className={styles.header}
				onClick={openFilter}
				onKeyDown={openFilter}
				role="button"
			> */}
			<div className={styles.header}>
				{toTitleCase(title)}
				{/* {isOpen ? (
					<ChevronUpIcon className={styles.icon} />
				) : (
					<ChevronDownIcon className={styles.icon} />
				)} */}
			</div>
			{isOpen && <div className={styles.content}>{children}</div>}
		</div>
	)
}
