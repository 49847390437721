import { subdomainIdentifierRegex } from "../constants"

export const withinShopshareDomain = (domain) =>{
	if(domain.includes("localhost")) return false
	if(subdomainIdentifierRegex.test(domain)) return true
	if(!domain.includes("shopshare.tv")) return true
	return false
}

export const getUsername = (domain) => {
	if (domain.includes("localhost")) return null
	if (subdomainIdentifierRegex.test(domain)) {
		const groups = subdomainIdentifierRegex.exec(domain)
		return groups && groups[1]
	}
	if (domain.includes("shopshare.tv")) return null
	return domain
}
