import React, { Component } from "react"
import { toast } from "react-toastify"
import textfield from "shared/styles/components/textfield.css"
import buttons from "shared/styles/components/buttons.css"
import contentCard from "shared/styles/layout/content-card.css"
import form from "shared/styles/components/form.css"
import Loading from "../../loading/loading.jsx"
import ChangePasswordMutation from "../../../mutations/user/change-password"
import { validateForm } from "../../../helpers/ui-helper"

class ChangePassword extends Component {
	constructor(props) {
		super(props)
		this.state = {
			isUpdating: false,
		}
		this.changePassword = this.changePassword.bind(this)
		this.onUpdateFailure = this.onUpdateFailure.bind(this)
		this.onUpdateSuccess = this.onUpdateSuccess.bind(this)
		this.handlePasswordChange = this.handlePasswordChange.bind(this)
	}
	
	handlePasswordChange({ target }) {
		this.retypePassword.pattern = target.value
		if (this.retypePassword.validity.patternMismatch) {
			this.retypePassword.setCustomValidity("Passwords don't match")
		}
	}

	onUpdateSuccess() {
		this.setState({ isUpdating: false })
		this.newPassword.value = ""
		this.oldPassword.value = ""
		this.retypePassword.value = ""
		toast.success(<div>Password updated.</div>, {
			autoClose: 2000,
			closeButton: false,
		})
	}

	onUpdateFailure(e) {
		this.setState({ isUpdating: false })
		toast.error(<div>{e[0].message || "Unable to change password."}</div>, {
			autoClose: 2000,
			closeButton: false,
		})
	}

	changePassword(e) {
		const { props } = this

		e.preventDefault()
		if (!validateForm(this.formElement)) {
			return
		}
		this.setState({ isUpdating: true })
		const oldPassword = this.oldPassword.value
		const newPassword = this.newPassword.value
		const retypePassword = this.retypePassword.value

		ChangePasswordMutation.commit(
			props.relay.environment,
			oldPassword,
			newPassword,
			retypePassword,
			props.storeId,
			this.onUpdateSuccess,
			this.onUpdateFailure
		)
	}

	render() {
		const { state } = this
		return (
			<form
				name="passwordchange"
				ref={(f) => {
					this.formElement = f
				}}
			>
				<div className={contentCard.root}>
					<div className={contentCard.header}>
						<h4 className={contentCard.title}>Password</h4>
					</div>
					<div className={contentCard.content}>
						{state.isUpdating && <Loading />}
						<div className={form.row}>
							<div className={form.group}>
								<label className={form.label}>Current Password</label>
								<input
									type="password"
									className={textfield.normal}
									ref={(input) => {
										this.oldPassword = input
									}}
									required
									placeholder="Type current password"
								/>
								<div className={form.validationFeedback} />
							</div>
						</div>
						<div className={form.row}>
							<div className={form.group}>
								<label className={form.label}>New Password</label>
								<input
									type="password"
									className={textfield.normal}
									onChange={this.handlePasswordChange}
									required
									ref={(input) => {
										this.newPassword = input
									}}
									placeholder="Type new password"
								/>
								<div className={form.validationFeedback} />
							</div>
							<div className={form.group}>
								<label className={form.label}>
									Confirm New Password
								</label>
								<input
									type="password"
									className={textfield.normal}
									required
									ref={(input) => {
										this.retypePassword = input
									}}
									placeholder="Type new password again"
								/>
								<div className={form.validationFeedback} />
							</div>
						</div>
					</div>
					<div className={contentCard.footer}>
						<button
							className={buttons.primary}
							type="submit"
							onClick={this.changePassword}
						>
							Save
						</button>
					</div>
				</div>
			</form>
		)
	}
}

export default ChangePassword
