import { commitMutation, graphql } from "react-relay"
import { complete } from "../helper.js"

const mutation = graphql`
	mutation toggleRecommendedShopcastMutation(
		$input: ToggleRecommendedShopcastInput!
	) {
		toggleRecommendedShopcast(input: $input) {
			output
		}
	}
`

const commit = (env, shopcastId, onSuccess, onError) =>
	commitMutation(env, {
		mutation,
		variables: { input: { shopcastId } },
		optimisticResponse: { toggleRecommendedShopcast: { output: "" } },
		onError: () => complete(null, onError),
		updater: (storeProxy, data) => {
			if (data.toggleRecommendedShopcast) {
				const shopcastProxy = storeProxy.get(shopcastId)
				const wasRecommended = shopcastProxy.getValue("isRecommended")
				shopcastProxy.setValue(!wasRecommended, "isRecommended")
				onSuccess()
			}
		},
	})

export default { commit }
