import { commitMutation, graphql } from "react-relay"
import { complete } from "../helper"

const mutation = graphql`
	mutation sendTeamInviteMutation($input: SendTeamInviteInput!) {
		sendTeamInvite(input: $input) {
			store {
				id
			}
		}
	}
`

const commit = (env, memberId, onError, onCompleted) =>
	commitMutation(env, {
		mutation,
		variables: {
			input: {
				id: memberId,
			},
		},
		onCompleted: complete(onCompleted, onError),
		onError: (e) => complete(null, [e]),
	})

export default { commit }
