/**
 * @generated SignedSource<<4a4b1b75525960f2d936c51f2d944835>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Query } from 'relay-runtime';
import type { profileCollectionsCollectionsItems_store$fragmentType } from "./profileCollectionsCollectionsItems_store.graphql";
export type profileCollectionsItemsQuery$variables = {|
  collectionId?: ?string,
  count: number,
  cursor?: ?string,
  searchText?: ?string,
  sort?: ?string,
  type?: ?string,
  username: string,
|};
export type profileCollectionsItemsQuery$data = {|
  +store: ?{|
    +$fragmentSpreads: profileCollectionsCollectionsItems_store$fragmentType,
  |},
|};
export type profileCollectionsItemsQuery = {|
  response: profileCollectionsItemsQuery$data,
  variables: profileCollectionsItemsQuery$variables,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "collectionId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "count"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "cursor"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "searchText"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "sort"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "type"
},
v6 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "username"
},
v7 = {
  "kind": "Variable",
  "name": "collectionId",
  "variableName": "collectionId"
},
v8 = {
  "kind": "Variable",
  "name": "searchText",
  "variableName": "searchText"
},
v9 = {
  "kind": "Variable",
  "name": "sort",
  "variableName": "sort"
},
v10 = {
  "kind": "Variable",
  "name": "type",
  "variableName": "type"
},
v11 = {
  "kind": "Variable",
  "name": "username",
  "variableName": "username"
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "adminAccess",
  "storageKey": null
},
v14 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "cursor"
  },
  (v7/*: any*/),
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "count"
  },
  (v8/*: any*/),
  (v9/*: any*/),
  (v10/*: any*/)
],
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v16 = [
  (v12/*: any*/),
  (v15/*: any*/)
],
v17 = {
  "alias": null,
  "args": null,
  "concreteType": "ProductCollection",
  "kind": "LinkedField",
  "name": "productCollections",
  "plural": true,
  "selections": (v16/*: any*/),
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "url",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "profileCollectionsItemsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Store",
        "kind": "LinkedField",
        "name": "store",
        "plural": false,
        "selections": [
          {
            "args": [
              (v7/*: any*/),
              {
                "kind": "Variable",
                "name": "count",
                "variableName": "count"
              },
              {
                "kind": "Variable",
                "name": "cursor",
                "variableName": "cursor"
              },
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/)
            ],
            "kind": "FragmentSpread",
            "name": "profileCollectionsCollectionsItems_store"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "ShopshareQueryRoot",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v2/*: any*/),
      (v6/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v0/*: any*/),
      (v5/*: any*/)
    ],
    "kind": "Operation",
    "name": "profileCollectionsItemsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Store",
        "kind": "LinkedField",
        "name": "store",
        "plural": false,
        "selections": [
          (v12/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "currentUser",
            "plural": false,
            "selections": [
              (v12/*: any*/),
              (v13/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": [
              (v11/*: any*/)
            ],
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "profile",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v14/*: any*/),
                "concreteType": "ProductCollectionItemConnection",
                "kind": "LinkedField",
                "name": "collectionItems",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ProductCollectionItemEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "__typename",
                            "storageKey": null
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              (v12/*: any*/),
                              (v15/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "brand",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "description",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "imageUrl",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "diffbotImageUrl",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "regularPrice",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "offerPrice",
                                "storageKey": null
                              },
                              (v17/*: any*/),
                              (v18/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "affiliated_url",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "User",
                                "kind": "LinkedField",
                                "name": "user",
                                "plural": false,
                                "selections": [
                                  (v12/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "fullname",
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "username",
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "profilePhoto",
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "customDomain",
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "shopcastItem",
                                "kind": "LinkedField",
                                "name": "shopcastItems",
                                "plural": true,
                                "selections": [
                                  (v12/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "Shopcast",
                                    "kind": "LinkedField",
                                    "name": "shopcast",
                                    "plural": false,
                                    "selections": (v16/*: any*/),
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "start",
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "end",
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "type": "ShopcastProduct",
                            "abstractKey": null
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              (v12/*: any*/),
                              (v13/*: any*/),
                              (v15/*: any*/),
                              (v17/*: any*/),
                              (v18/*: any*/)
                            ],
                            "type": "lookbookUploads",
                            "abstractKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "cursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "endCursor",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasNextPage",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v14/*: any*/),
                "filters": [
                  "searchText",
                  "sort",
                  "collectionId",
                  "type"
                ],
                "handle": "connection",
                "key": "profile_collectionItems",
                "kind": "LinkedHandle",
                "name": "collectionItems"
              },
              (v12/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "1c390da711d230fc1bd051b249f8d7d9",
    "id": null,
    "metadata": {},
    "name": "profileCollectionsItemsQuery",
    "operationKind": "query",
    "text": "query profileCollectionsItemsQuery(\n  $count: Int!\n  $cursor: String\n  $username: String!\n  $searchText: String\n  $sort: String\n  $collectionId: String\n  $type: String\n) {\n  store {\n    ...profileCollectionsCollectionsItems_store_3JPvTB\n    id\n  }\n}\n\nfragment profileCollectionsCollectionsItems_store_3JPvTB on Store {\n  id\n  currentUser {\n    id\n    ...shopcastCard_currentUser\n  }\n  profile(username: $username) {\n    collectionItems(first: $count, after: $cursor, searchText: $searchText, sort: $sort, collectionId: $collectionId, type: $type) {\n      edges {\n        node {\n          __typename\n          ... on ShopcastProduct {\n            id\n            title\n            brand\n            description\n            imageUrl\n            diffbotImageUrl\n            regularPrice\n            offerPrice\n            productCollections {\n              id\n              title\n            }\n            url\n            affiliated_url\n            user {\n              id\n              fullname\n              username\n              profilePhoto\n              customDomain\n            }\n            shopcastItems {\n              id\n              shopcast {\n                id\n                title\n              }\n              start\n              end\n            }\n          }\n          ... on lookbookUploads {\n            id\n            adminAccess\n            title\n            productCollections {\n              id\n              title\n            }\n            url\n          }\n        }\n        cursor\n      }\n      pageInfo {\n        endCursor\n        hasNextPage\n      }\n    }\n    id\n  }\n}\n\nfragment shopcastCard_currentUser on User {\n  id\n  adminAccess\n}\n"
  }
};
})();

(node/*: any*/).hash = "41feb15cf522a6c53518bcc4d3532501";

module.exports = ((node/*: any*/)/*: Query<
  profileCollectionsItemsQuery$variables,
  profileCollectionsItemsQuery$data,
>*/);
