import { commitMutation, graphql } from "react-relay"
import { ConnectionHandler } from "relay-runtime"
import { complete } from "../helper"
import connections from "../connections"

const mutation = graphql`
	mutation createShopshareFileMutation($input: CreateFileInput!) {
		createFile(input: $input) {
			file {
				id
				title
				url
				thumbnailUrl
				user {
					id
				}
				folder {
					id
				}
			}
		}
	}
`

const commit = (env, data, onError, onCompleted, other={}) =>
	commitMutation(env, {
		mutation,
		variables: { input: data },
		onCompleted: complete(onCompleted, onError),
		onError: (e) => complete(null, [e]),
		updater: (storeProxy, dd) => {
			if (dd.createFile) {
				const itemProxy = storeProxy
					.getRootField("createFile")
					.getLinkedRecord("file")

				const edge = storeProxy.create(
					`ShopshareFile:connection:${new Date().getTime()}`,
					"shopshareFile"
				)
				edge.setLinkedRecord(itemProxy, "node")
				const parentFolder = storeProxy.get(data.folder)
				const args = { search: "", teamId: data.teamId }
				parentFolder.setValue(
					parentFolder.getValue("filesCount", args) + 1,
					"filesCount",
					args
				)
				const connection = ConnectionHandler.getConnection(
					parentFolder,
					connections.currentUser.shopcastManager.items.name,
					{
						type: "file"
					}
				)
				if (connection) ConnectionHandler.insertEdgeAfter(connection, edge)
				if(other.username) {
					const profile = storeProxy
						.getRoot()
						.getLinkedRecord("store")
						.getLinkedRecord("profile", { username: other.username })
					const connectionShared = ConnectionHandler.getConnection(
						profile,
						"profile_sharedFolderCloset",
						{ folderId: data.folder }
					)
					if (connectionShared)
						ConnectionHandler.insertEdgeAfter(connectionShared, edge)
				}
			}
		},
	})

export default { commit }
