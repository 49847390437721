/**
 * @generated SignedSource<<07b24b46f8bf429f72ef9690d444a040>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Mutation } from 'relay-runtime';
export type OpenCustomerPortalInput = {|
  backUrl?: ?string,
  clientMutationId?: ?string,
|};
export type openCustomerPortalMutation$variables = {|
  input: OpenCustomerPortalInput,
|};
export type openCustomerPortalMutation$data = {|
  +openCustomerPortal: ?{|
    +url: ?string,
  |},
|};
export type openCustomerPortalMutation = {|
  response: openCustomerPortalMutation$data,
  variables: openCustomerPortalMutation$variables,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "OpenCustomerPortalPayload",
    "kind": "LinkedField",
    "name": "openCustomerPortal",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "url",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "openCustomerPortalMutation",
    "selections": (v1/*: any*/),
    "type": "ShopshareMutationRoot",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "openCustomerPortalMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "42e3dd42b874449df3acfcd0b3b6c5a8",
    "id": null,
    "metadata": {},
    "name": "openCustomerPortalMutation",
    "operationKind": "mutation",
    "text": "mutation openCustomerPortalMutation(\n  $input: OpenCustomerPortalInput!\n) {\n  openCustomerPortal(input: $input) {\n    url\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "0ae84d53f80f1b92b3d582a5b669b157";

module.exports = ((node/*: any*/)/*: Mutation<
  openCustomerPortalMutation$variables,
  openCustomerPortalMutation$data,
>*/);
