import React, { Component, Fragment } from "react"
import SearchInputComponent from "components/search-input/search-input"
import twoColumn from "shared/styles/layout/two-column.css"
import pageContent from "shared/styles/layout/page-content.css"
import controls from "shared/styles/layout/controls.css"
import { debounce } from "helpers/ui-helper"
import AdminUserListRecords from "./admin-user-list-record"
import { getComponent } from "../../../route-helpers"

class AdminUserListView extends Component {
	constructor(props) {
		super(props)
		this.state = {
			search: "",
		}
		this.setKeywordBounce = debounce((val) => {
			this.setState({ search: val })
		}, 500)
	}

	renderUserRecords() {
		const { search } = this.state
		const AdminUserListRecordsComp = getComponent(
			`admin-user-list-records`,
			AdminUserListRecords,
			{
				count: 12,
				search,
			},
			{
				count: 12,
				search,
			}
		)
		return AdminUserListRecordsComp
	}

	render() {
		return (
			<Fragment>
				<div className={twoColumn.header}>
					<h1 className={twoColumn.title}>Users</h1>
				</div>
				<div className={pageContent.root}>
					<div className={controls.root}>
						<SearchInputComponent
							placeholder="Search"
							setSearchText={(value) => this.setKeywordBounce(value)}
						/>
					</div>
					{this.renderUserRecords()}
				</div>
			</Fragment>
		)
	}
}

export default {
	Component: AdminUserListView,
}
