/**
 * @generated SignedSource<<c0bc58694dfb813d0d9af905ff2558a9>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Mutation } from 'relay-runtime';
export type UploadShopcastFolderBannerInput = {|
  clientMutationId?: ?string,
  folderId?: ?string,
  imageName?: ?string,
|};
export type uploadFolderBannerPhotoMutation$variables = {|
  input: UploadShopcastFolderBannerInput,
|};
export type uploadFolderBannerPhotoMutation$data = {|
  +uploadShopcastFolderBanner: ?{|
    +folder: ?{|
      +bannerPhoto: ?string,
      +id: string,
    |},
    +store: ?{|
      +currentUser: ?{|
        +profilePhoto: ?string,
      |},
    |},
  |},
|};
export type uploadFolderBannerPhotoMutation = {|
  response: uploadFolderBannerPhotoMutation$data,
  variables: uploadFolderBannerPhotoMutation$variables,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "profilePhoto",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "ShopcastFolder",
  "kind": "LinkedField",
  "name": "folder",
  "plural": false,
  "selections": [
    (v3/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "bannerPhoto",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "uploadFolderBannerPhotoMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UploadShopcastFolderBannerPayload",
        "kind": "LinkedField",
        "name": "uploadShopcastFolderBanner",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Store",
            "kind": "LinkedField",
            "name": "store",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "User",
                "kind": "LinkedField",
                "name": "currentUser",
                "plural": false,
                "selections": [
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "ShopshareMutationRoot",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "uploadFolderBannerPhotoMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UploadShopcastFolderBannerPayload",
        "kind": "LinkedField",
        "name": "uploadShopcastFolderBanner",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Store",
            "kind": "LinkedField",
            "name": "store",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "User",
                "kind": "LinkedField",
                "name": "currentUser",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/)
                ],
                "storageKey": null
              },
              (v3/*: any*/)
            ],
            "storageKey": null
          },
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "fde8646ed81e517fa031e63e5612b9f3",
    "id": null,
    "metadata": {},
    "name": "uploadFolderBannerPhotoMutation",
    "operationKind": "mutation",
    "text": "mutation uploadFolderBannerPhotoMutation(\n  $input: UploadShopcastFolderBannerInput!\n) {\n  uploadShopcastFolderBanner(input: $input) {\n    store {\n      currentUser {\n        profilePhoto\n        id\n      }\n      id\n    }\n    folder {\n      id\n      bannerPhoto\n    }\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "b2fc294b2d30e7d8c53c7270358f3932";

module.exports = ((node/*: any*/)/*: Mutation<
  uploadFolderBannerPhotoMutation$variables,
  uploadFolderBannerPhotoMutation$data,
>*/);
