/**
 * @generated SignedSource<<15cb83a6a9c63fd829840a6528ef32d5>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { userMenu_store$fragmentType } from "./../../dropdowns/__generated__/userMenu_store.graphql";
export type userEnumType = "CLIENT" | "RETAILER" | "STYLIST" | "%future added value";
import type { FragmentType } from "relay-runtime";
declare export opaque type header_store$fragmentType: FragmentType;
export type header_store$data = {|
  +currentUser: ?{|
    +adminAccess: ?string,
    +customDomain: ?string,
    +email: ?string,
    +fullname: ?string,
    +hasPendingSharerRequests: ?number,
    +id: string,
    +isANewUser: ?boolean,
    +isRetailer: ?boolean,
    +optOutAffiliate: ?boolean,
    +profilePhoto: ?string,
    +type: ?userEnumType,
    +username: ?string,
  |},
  +id: string,
  +$fragmentSpreads: userMenu_store$fragmentType,
  +$fragmentType: header_store$fragmentType,
|};
export type header_store$key = {
  +$data?: header_store$data,
  +$fragmentSpreads: header_store$fragmentType,
  ...
};
*/

var node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "header_store",
  "selections": [
    (v0/*: any*/),
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "userMenu_store"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "currentUser",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "fullname",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "email",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "username",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "customDomain",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "profilePhoto",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "adminAccess",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isRetailer",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "type",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "optOutAffiliate",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "hasPendingSharerRequests",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isANewUser",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Store",
  "abstractKey": null
};
})();

(node/*: any*/).hash = "9fec4b795f2c90f4d5a872885a9a7693";

module.exports = ((node/*: any*/)/*: Fragment<
  header_store$fragmentType,
  header_store$data,
>*/);
