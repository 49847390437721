import React, { Component } from "react"
import { createRefetchContainer, graphql } from "react-relay"
import { Helmet } from "react-helmet"
import { Link, Outlet } from "react-router-dom"
import { DashboardIcon } from "@radix-ui/react-icons"
import ClosetIcon from "shared/assets/closet-icon.svg"
import Avatar from "components/profile/avatar/avatar"
import stylesAvatar from "components/profile/avatar/avatar.css"
import banner from "shared/styles/layout/banner.css"
import nav from "shared/styles/components/nav.css"
import margin from "shared/styles/components/margin.css"
import button from "shared/styles/components/buttons.css"
// import controls from "shared/styles/layout/controls.css"
// import pageHeader from "shared/styles/layout/page-header.css"
import Shopping from "shared/assets/illustrations/shopping.svg"
import empty from "shared/styles/components/empty.css"
import { getActiveTabClass } from "helpers/ui-helper"

import { textStrings } from "constants"

import styles from "./client-view.css"
import { withRouter } from "../../route-helpers"

class ClientView extends Component {
	constructor(props) {
		super(props)
		// this.state = {
		// 	collectionSearch: "",
		// }
		// this.setKeywordCollectionBounce = debounce((val) => {
		// 	const { collectionSearch } = this.state
		// 	if (val === collectionSearch) return
		// 	this.setState({ collectionSearch: val })
		// }, 500)
		this.messageListner = this.messageListner.bind(this)
		window.addEventListener("message", this.messageListner)
	}

	componentWillUnmount() {
		window.removeEventListener("message", this.messageListner)
	}

	messageListner(event) {
		const { location } = this.props
		if (event.data === "shopshare-reload")
			window.location.href = location.pathname
	}

	pageNotAvailable() {
		return (
			<div className={styles.rootEmpty}>
				<div className={empty.root}>
					<div className={empty.container}>
						<div className={empty.content}>
							<Shopping className={empty.hero} />
							<h4 className={empty.headline}>
								Sorry, this page isn&apos;t available.
							</h4>
							<p className={empty.subline}>
								The link you followed may be broken or the page may have
								been removed.
							</p>
							<div className={styles.cta}>
								<Link className={button.primary} to="/">
									ShopShare Home
								</Link>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}

	renderAvatar() {
		const {
			store: {
				currentUser: { clientFolder },
			},
		} = this.props
		let avatar
		if (clientFolder.previewConfig.showAvatar === "custom")
			avatar = clientFolder.avatar
		else if (clientFolder.previewConfig.showAvatar === "profile")
			avatar =
				clientFolder.user.displayAvatar || clientFolder.user.profilePhoto

		return (
			<Avatar
				className={styles.avatar}
				styleName={stylesAvatar.border}
				photoUrl={avatar}
				fullname={clientFolder.user.fullname}
			/>
		)
	}

	renderRoutes() {
		const {
			store: {
				currentUser: { clientFolder },
			},
		} = this.props
		return (
			<Outlet
				context={{
					clientFolder,
				}}
			/>
		)
	}

	render() {
		const {
			store: {
				currentUser: { clientFolder },
			},
			location,
		} = this.props
		if (!clientFolder) return this.pageNotAvailable()

		const { previewConfig } = clientFolder
		const noHeader = location.pathname.includes("client-preview")
		const inlineStyles = {}
		if (previewConfig.showBanner === "custom" && clientFolder.bannerPhoto)
			inlineStyles.backgroundImage = `url(${clientFolder.bannerPhoto})`
		else if (
			previewConfig.showBanner === "profile" &&
			clientFolder.user.bannerPhoto
		)
			inlineStyles.backgroundImage = `url(${clientFolder.user.bannerPhoto})`

		const showAvatar = previewConfig.showAvatar !== "off"
		const showBanner = previewConfig.showBanner !== "off"
		return (
			<div className={noHeader ? styles.rootNoMargin : styles.root}>
				<Helmet>
					<title>
						{clientFolder.displayName} – {clientFolder.user.fullname}
					</title>
				</Helmet>

				{showBanner && (
					<div className={banner.cover} style={inlineStyles} />
				)}

				{(showAvatar ||
					previewConfig.showDisplayName ||
					previewConfig.showDescription) && (
					<div className={styles.header}>
						<div
							className={
								showBanner || showAvatar
									? styles.inner
									: styles.innerNoBannerAvatar
							}
						>
							<div
								className={
									showBanner && showAvatar
										? styles.content
										: styles.contentNoBanner
								}
							>
								{showAvatar && (
									<div
										className={
											showBanner
												? styles.avatar
												: styles.avatarNoBanner
										}
									>
										{this.renderAvatar()}
									</div>
								)}
								<div className={styles.details}>
									<div className={styles.titleContainer}>
										{previewConfig.showDisplayName && (
											<h1 className={styles.displayName}>
												{clientFolder.displayName}
											</h1>
										)}
										{previewConfig.showDescription && (
											<p
												className={
													previewConfig.showDisplayName
														? styles.shortBio
														: styles.shortBioNoDisplayName
												}
											>
												{clientFolder.description}
											</p>
										)}
									</div>
									{/* <div className={styles.actions}>
										<button type="button" className={button.default}>
											Message
										</button>
									</div> */}
								</div>
							</div>
						</div>
					</div>
				)}

				<div className={styles.main}>
					{(previewConfig.showLookbookTab ||
						previewConfig.showClosetTab) && (
						<div className={`${nav.rootHasBorder} ${margin.mb2}`}>
							<nav className={nav.tabs}>
								<div className={nav.tabsFit}>
									{previewConfig.showLookbookTab && (
										<Link
											role="button"
											tabIndex="0"
											className={getActiveTabClass(
												location.pathname.includes("lookbooks"),
												nav.tabStatic,
												nav.tabStaticActive
											)}
											to="lookbooks"
											// data-tooltip-id="tooltip"
											// data-tooltip-content={`${textStrings.clientWardrobeName}s`}
										>
											<DashboardIcon className={nav.tabLinkIcon} />
											<span className={nav.tabLinkLabel}>
												{textStrings.clientWardrobeName}s
											</span>
										</Link>
									)}
									{previewConfig.showClosetTab && (
										<Link
											role="button"
											tabIndex="0"
											className={getActiveTabClass(
												location.pathname.includes("closet"),
												nav.tabStatic,
												nav.tabStaticActive
											)}
											to="closet"
											// data-tooltip-id="tooltip"
											// data-tooltip-content="Closet"
										>
											<ClosetIcon className={nav.tabLinkIcon} />
											<span className={nav.tabLinkLabel}>
												Closet
											</span>
										</Link>
									)}
								</div>
							</nav>
						</div>
					)}
					{previewConfig.showClosetTab || previewConfig.showLookbookTab ? (
						this.renderRoutes()
					) : (
						<div className={empty.root}>
							<div className={empty.container}>
								<div className={empty.content}>
									<Shopping className={empty.hero} />
									<h4 className={empty.headline}>
										There are no sections enabled.
									</h4>
									<p className={empty.subline}>Mesaage</p>
								</div>
							</div>
						</div>
					)}
				</div>
			</div>
		)
	}
}

const query = graphql`
	query clientViewQuery($folder: String!) {
		store {
			id
			...clientView_closetCollections_currentUser @arguments(folder: $folder)
		}
	}
`

ClientView = createRefetchContainer(
	ClientView,
	{
		store: graphql`
			fragment clientView_closetCollections_currentUser on Store
			@argumentDefinitions(folder: { type: "String!" }) {
				currentUser {
					id
					clientFolder(id: $folder) {
						id
						name
						displayName
						description
						avatar
						bannerPhoto
						user {
							id
							fullname
							profilePhoto
							bannerPhoto
							displayAvatar
						}
						clients {
							id
							avatar
						}
						previewConfig {
							showAvatar
							showBanner
							showDisplayName
							showDescription
							showLookbookTab
							showClosetTab
						}
						folders(isShared: true) {
							edges {
								cursor
								node {
									id
									name
								}
							}
						}
					}
				}
			}
		`,
	},
	query
)

export default {
	Component: withRouter(ClientView),
	query,
	needAuth: true,
	params: (rp) => ({
		folder: rp.params.id,
	}),
}
