import React from "react"
import styles from "./social-link.css"

export default function SocialLink({ url, ImageComponent, title, size, type }) {
	return (
		<a
			className={type === "button" ? styles.rootButton : styles.root}
			title={title}
			rel="noopener noreferrer"
			target="_blank"
			href={url}
		>
			{ImageComponent && (
				<ImageComponent
					className={size === "lg" ? styles.iconlg : styles.icon}
				/>
			)}
		</a>
	)
}
