import React from "react"
import { createFragmentContainer, graphql } from "react-relay"
import LandingActions from "components/landing-actions/landing-actions"
import PageFooter from "components/page-footer/page-footer"
import { Helmet } from "react-helmet"
import layout from "shared/styles/components/layout.css"
import textfield from "shared/styles/components/textfield.css"
import controls from "shared/styles/layout/controls.css"
import landing from "shared/styles/layout/landing.css"
import SearchInputComponent from "components/search-input/search-input.jsx"
import ListOfLatestSharers from "components/list-of-latest-sharers/list-of-latest-sharers.jsx"
import { debounce } from "helpers/ui-helper.js"
import { getComponent } from "../../route-helpers.jsx"
import styles from "./sharers-page.css"

class SharersPage extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			selectedCategory: "all",
			keyword: "",
			sort: "n-to-o",
		}
		this.handleTabClick = this.handleTabClick.bind(this)
		this.setKeywordBounce = debounce((val) => {
			this.setState({ keyword: val })
		}, 500)
	}

	handleTabClick(category) {
		this.setState({ selectedCategory: category.id })
	}

	renderSharers() {
		const { selectedCategory, keyword, sort } = this.state
		const ListOfLatestSharersComp = getComponent(
			`list-of-latest-sharers`,
			ListOfLatestSharers,
			{
				category: selectedCategory === "all" ? null : selectedCategory,
				count: 15,
				keyword,
				sort,
			},
			{
				category: selectedCategory === "all" ? null : selectedCategory,
				keyword,
				sort,
			}
		)
		return ListOfLatestSharersComp
	}

	render() {
		const {
			props,
			state: { sort },
		} = this
		const { currentUser } = props.store
		return (
			<div className={`${layout.containerWidth5} ${layout.offset}`}>
				<Helmet>
					<title>ShopShareTV Stylist Directory</title>
					<body className="Directory" />
				</Helmet>
				<section className={`${landing.section}`}>
					<div className={landing.content}>
						<h1 className={styles.displayTitle}>Stylists</h1>
						<p className={landing.displayLead}>
							Explore featured marketing channels from the ShopShare
							community.
						</p>
						{!currentUser && (
							<div className={landing.actions}>
								<LandingActions />
							</div>
						)}
					</div>
				</section>
				<div className={styles.content}>
					<div className={controls.root}>
						<div className={controls.search}>
							<SearchInputComponent
								placeholder="Search"
								setSearchText={(value) => this.setKeywordBounce(value)}
							/>
						</div>
						<div className={controls.filters}>
							<div className={controls.group}>
								<select
									value={sort}
									className={textfield.normal}
									onChange={(e) => {
										this.setState({ sort: e.target.value })
									}}
								>
									<optgroup label="Sort by">
										<option value="n-to-o">Newest to Oldest</option>
										<option value="o-to-n">Oldest to Newest</option>
										<option value="name">Name A-Z</option>
										<option value="name-d">Name Z-A</option>
									</optgroup>
								</select>
							</div>
						</div>
					</div>
					{this.renderSharers()}
				</div>
				{!currentUser && (
					<section className={landing.section}>
						<div className={landing.contentPageFooter}>
							<PageFooter
								title="Are you a Stylist?"
								lead="Wow your clients and stand out with ShopShare Videos."
								currentUser={currentUser}
							/>
						</div>
					</section>
				)}
			</div>
		)
	}
}

SharersPage = createFragmentContainer(SharersPage, {
	store: graphql`
		fragment sharersPage_store on Store {
			id
			currentUser {
				isANewUser
			}
			bestCategories(orderBy: "shopcastCount") {
				edges {
					node {
						name
						id
						count
					}
				}
			}
			topSharers(category: null) {
				edges {
					node {
						id
					}
				}
			}
		}
	`,
})

const sharersQuery = graphql`
	query sharersPageQuery {
		store {
			...sharersPage_store
		}
	}
`

export default { Component: SharersPage, query: sharersQuery }
