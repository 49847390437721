/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { Component } from "react"
import { createFragmentContainer, graphql } from "react-relay"
import { Link } from "react-router-dom"
import { Helmet } from "react-helmet"
import { toast } from "react-toastify"
import { Formik } from "formik"
import * as Yup from "yup"
import queryString from "query-string"
import Loading from "components/loading/loading"
import textfield from "shared/styles/components/textfield.css"
import buttons from "shared/styles/components/buttons.css"
import landing from "shared/styles/layout/landing.css"
import form from "shared/styles/components/form.css"
import Logo from "shared/assets/logo.svg"
// import ShopShareIcon from "shared/assets/shopshare-icon.svg"
import cookieService from "services/cookie-service"
import trackingService from "services/tracking-service"
import SigninMutation from "mutations/user/signin"
import { usernameEmailAvailabilityCheck } from "helpers/ui-helper"
// import { validateEmail } from "helpers/string-helper"
// import SendVerificationEmailMutation from "mutations/user/send-verification-email"
import { trialPeriod } from "constants"
import auth from "routes/signup/signup.css"
import { withRouter } from "../../route-helpers"

const FormTypes = {
	DEFAULT: "DEFAULT",
	SIGNIN: "SIGNIN",
	EMAILSENT: "EMAILSENT",
}

class Signin extends Component {
	constructor(props) {
		super(props)
		this.signIn = this.signIn.bind(this)
		this.signinFailure = this.signinFailure.bind(this)
		this.signinSuccessful = this.signinSuccessful.bind(this)
		this.usernameAvailability = this.usernameAvailability.bind(this)

		this.qs = queryString.parse(props.location.search)

		this.state = {
			showLoading: false,
			formType: this.qs.username ? FormTypes.SIGNIN : FormTypes.DEFAULT,
			additionalProps:
				this.qs.username || this.qs.planId
					? { email: this.qs.username, plan: this.qs.planId }
					: null,
		}
	}

	componentDidMount() {
		const { location } = this.props
		trackingService.track(
			location.pathname.includes("/signup")
				? "Viewed Sign Up"
				: "Viewed Sign In",
			{}
		)
	}

	componentDidUpdate(prevProps) {
		const { location } = this.props
		if (location.search !== prevProps.location.search) {
			this.qs = queryString.parse(location.search)
			this.setState({
				formType: this.qs.username ? FormTypes.SIGNIN : FormTypes.DEFAULT,
				additionalProps: this.qs.username
					? { email: this.qs.username }
					: null,
			})
		}
	}

	showErrorToast(message) {
		toast.error(message, {
			autoClose: 3000,
			closeButton: false,
			position: "top-center",
		})
	}

	signIn(values) {
		const { props } = this
		if (!window.navigator.cookieEnabled) {
			this.showErrorToast(
				"Cookies are Blocked on your browser. Please enable your browser Cookie preferences to Allow sites to save and read cookie data (recommended)."
			)
			return
		}
		this.setState({ showLoading: true })
		SigninMutation.commit(
			props.relay.environment,
			values.email.trim(),
			values.password,
			props.store.id,
			this.signinSuccessful,
			this.signinFailure
		)
	}

	signinSuccessful(data) {
		const { props } = this
		const {
			token,
			username,
			fullname,
			email,
			userHash,
			type,
			userPlan,
			teams,
		} = data
		if (!userPlan && teams.length > 0) props.setTeam(teams[0].id)

		this.setState({ showLoading: false })

		cookieService.setToken(token)
		cookieService.removeRefferal()

		if (username) {
			trackingService.addUserProperties({ $name: fullname, $email: email })
			trackingService.identify(username.toLowerCase())
			trackingService.track("Sign In Successful", { Name: fullname })
			if (window.FS) {
				window.FS.identify(userHash, {
					displayName: fullname,
					email,
				})
			}
		}
		if (this.qs.link) {
			window.location.href = this.qs.link
		} else if (type === "CLIENT") {
			props.navigate("/wardrobe")
		} else {
			const nextPath =
				props.location &&
				props.location.state &&
				props.location.state.nextPathname
					? props.location.state.nextPathname
					: "/dashboard"
			props.navigate(nextPath)
		}

		toast.info(<>Welcome to ShopShare</>, {
			autoClose: 3000,
			closeButton: false,
			position: "top-center",
		})
	}

	signinFailure(errors) {
		this.setState({ showLoading: false })
		const error = errors[0]
		if (!error) return
		const errorMessage = error.message
		this.showErrorToast(errorMessage)
	}

	usernameAvailability(values) {
		const { props } = this
		this.setState({ showLoading: true })
		const email = values.email.trim()
		usernameEmailAvailabilityCheck(email)
			.then((data) => {
				// const stateValues = {
				// 	showLoading: false,
				// 	additionalProps: { email },
				// }
				if (!data) {
					this.setState({ showLoading: false })
					const newQs = queryString.stringify({
						...this.qs,
						username: email,
					})
					props.navigate(`/signin?${newQs}`, {
						state: { nextPathname: props.location?.state?.nextPathname },
					})
				}
				// else if (validateEmail(email)) {
				// 	SendVerificationEmailMutation.commit(
				// 		props.relay.environment,
				// 		email,
				// 		state.additionalProps ? state.additionalProps.plan : null,
				// 		() => {
				// 			stateValues.formType = FormTypes.EMAILSENT
				// 			this.setState(stateValues)
				// 		},
				// 		() => {}
				// 	)
				// }
				else {
					this.setState({ showLoading: false })
					this.showErrorToast("Incorrect Username")
				}
			})
			.catch((err) => {
				this.showErrorToast(err.message)
			})
	}

	renderVericationEmailSent() {
		const { additionalProps } = this.state
		return (
			<>
				<p className={auth.para}>
					We&apos;ve sent a special link to <b>{additionalProps.email}</b>.
					Click the link in the email to confirm your email address.
				</p>
				<p className={auth.para}>
					<small>
						<span>Wrong email?</span>{" "}
						<a
							role="button"
							tabIndex="0"
							onClick={() => {
								this.setState({
									formType: FormTypes.DEFAULT,
									additionalProps: null,
								})
							}}
						>
							Re-enter your address
						</a>
					</small>
				</p>
			</>
		)
	}

	renderSignIn() {
		const { additionalProps } = this.state
		return (
			<Formik
				initialValues={{
					email: additionalProps?.email || "",
					password: "",
				}}
				validationSchema={Yup.object().shape({
					email: Yup.string().required("Email is required"),
					password: Yup.string().required("Password is required"),
				})}
				onSubmit={this.signIn}
			>
				{(props) => {
					const {
						errors,
						handleSubmit,
						values,
						handleChange,
						handleBlur,
						submitCount,
					} = props
					return (
						<>
							{submitCount > 0 && Object.keys(errors).length > 0 && (
								<div className={form.error}>
									{errors.email && (
										<p className={form.errorMessage}>
											{errors.email}
										</p>
									)}
									{errors.password && (
										<p className={form.errorMessage}>
											{errors.password}
										</p>
									)}
								</div>
							)}
							<div className={form.group}>
								<label className={form.label}>Email or username</label>
								<input
									type="text"
									className={textfield.normal}
									name="email"
									value={values.email}
									onChange={handleChange}
									onBlur={handleBlur}
								/>
							</div>
							<div className={form.group}>
								<label className={form.label}>Password</label>
								<input
									type="password"
									name="password"
									placeholder="Type a password"
									className={textfield.normal}
									value={values.password || ""}
									onChange={handleChange}
									onBlur={handleBlur}
								/>
							</div>
							<button
								type="submit"
								className={auth.btn}
								onClick={handleSubmit}
							>
								Continue
							</button>
							<p className={auth.forgot}>
								<Link to="/forgot">Forgot password?</Link>
							</p>
						</>
					)
				}}
			</Formik>
		)
	}

	renderOnlyUsername() {
		return (
			<Formik
				initialValues={{
					email: "",
				}}
				validationSchema={Yup.object().shape({
					email: Yup.string().required("Email is required"),
				})}
				onSubmit={this.usernameAvailability}
			>
				{(props) => {
					const {
						errors,
						handleSubmit,
						values,
						handleChange,
						handleBlur,
						submitCount,
					} = props
					const { location } = this.props
					return (
						<>
							{submitCount > 0 &&
								Object.keys(errors).length > 0 &&
								errors.email && (
									<div className={form.error}>
										<div className={form.errorMessage}>
											{errors.email}
										</div>
									</div>
								)}
							<div className={form.group}>
								{location.pathname.includes("/signup") ? (
									<label className={form.label}>Email</label>
								) : (
									<label className={form.label}>
										Email or Username
									</label>
								)}
								<input
									type="text"
									className={textfield.normal}
									name="email"
									placeholder="Email or Username"
									value={values.email}
									onChange={handleChange}
									onBlur={handleBlur}
								/>
							</div>
							{location.pathname.includes("/signup") ? (
								<button
									type="submit"
									className={auth.btnCreate}
									onClick={handleSubmit}
								>
									Continue
								</button>
							) : (
								<button
									type="submit"
									className={auth.btn}
									onClick={handleSubmit}
								>
									Continue
								</button>
							)}
						</>
					)
				}}
			</Formik>
		)
	}

	renderForm() {
		const { formType } = this.state
		switch (formType) {
			case FormTypes.EMAILSENT:
				return this.renderVericationEmailSent()
			case FormTypes.SIGNIN:
				return this.renderSignIn()
			case FormTypes.DEFAULT:
			default:
				return this.renderOnlyUsername()
		}
	}

	renderHeader() {
		const { formType } = this.state
		const { location } = this.props
		switch (formType) {
			case FormTypes.EMAILSENT:
				return <h2 className={auth.title}>Check your email</h2>
			case FormTypes.SIGNIN:
			case FormTypes.DEFAULT:
			default:
				return location.pathname.includes("/signup") ? (
					<>
						<h2 className={auth.title}>Get Started</h2>
						<p className={auth.para}>
							You’ll get an unlimited, free {trialPeriod}-Day trial of
							all features.
						</p>
					</>
				) : (
					<h2 className={auth.title}>Sign In</h2>
				)
		}
	}

	render() {
		const { state, props } = this
		const { location } = props
		return (
			<>
				<Helmet>
					{location.pathname.includes("/signup") ? (
						<title>Start a free trial – ShopShare</title>
					) : (
						<title>Sign in – ShopShare</title>
					)}
					<body className="Auth" />
				</Helmet>
				<header className={auth.header}>
					<nav className={auth.navDesktop}>
						<div className={auth.navContainer}>
							<Link to="/">
								<Logo className={auth.logo} alt="logo" />
							</Link>
							{state.formType !== FormTypes.EMAILSENT && (
								<div className={auth.nav}>
									<div className={auth.navItem}>
										{location.pathname.includes("/signup") ? (
											<Link className={buttons.subtle} to="/signin">
												Sign In
											</Link>
										) : null
										// <Link className={buttons.primary} to="/signup">
										// 	Get Started
										// </Link>
										}
									</div>
								</div>
							)}
						</div>
					</nav>
				</header>
				<section className={auth.section}>
					<div className={landing.content}>
						<div className={auth.content}>
							<div className={auth.card}>
								<div className={auth.cardHeader}>
									{this.renderHeader()}
								</div>
								{this.renderForm()}
							</div>
						</div>
					</div>
				</section>
				{state.showLoading && <Loading />}
			</>
		)
	}
}

Signin = createFragmentContainer(Signin, {
	store: graphql`
		fragment signin_store on Store {
			id
			currentUser {
				token
			}
		}
	`,
})

const signinQuery = graphql`
	query signinQuery {
		store {
			...signin_store
		}
	}
`

export default {
	Component: withRouter(Signin),
	query: signinQuery,
	requiredNoAuth: true,
}
