/**
 * @generated SignedSource<<2ec39c670e412c5820587ab7b0875fcb>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Mutation } from 'relay-runtime';
export type DownloadLookbookVideoWithImageMutationInput = {|
  bgColor: string,
  boards?: ?$ReadOnlyArray<?string>,
  circular: boolean,
  clientMutationId?: ?string,
  cropX?: ?number,
  cropY?: ?number,
  imageX?: ?number,
  imageY?: ?number,
  layout: string,
  lookbookId: string,
  padImageX?: ?string,
  padImageY?: ?string,
  padVideoX?: ?string,
  padVideoY?: ?string,
  type: string,
|};
export type downloadLookbookVideoWithImageMutation$variables = {|
  input: DownloadLookbookVideoWithImageMutationInput,
|};
export type downloadLookbookVideoWithImageMutation$data = {|
  +downloadLookbookVideoWithImage: ?{|
    +store: ?{|
      +id: string,
    |},
  |},
|};
export type downloadLookbookVideoWithImageMutation = {|
  response: downloadLookbookVideoWithImageMutation$data,
  variables: downloadLookbookVideoWithImageMutation$variables,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "DownloadLookbookVideoWithImageMutationPayload",
    "kind": "LinkedField",
    "name": "downloadLookbookVideoWithImage",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Store",
        "kind": "LinkedField",
        "name": "store",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "downloadLookbookVideoWithImageMutation",
    "selections": (v1/*: any*/),
    "type": "ShopshareMutationRoot",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "downloadLookbookVideoWithImageMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "6263c7db74d1b962162d535847bdf35a",
    "id": null,
    "metadata": {},
    "name": "downloadLookbookVideoWithImageMutation",
    "operationKind": "mutation",
    "text": "mutation downloadLookbookVideoWithImageMutation(\n  $input: DownloadLookbookVideoWithImageMutationInput!\n) {\n  downloadLookbookVideoWithImage(input: $input) {\n    store {\n      id\n    }\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "3c12b66a91e7dc0593d7c5ac2fdc796a";

module.exports = ((node/*: any*/)/*: Mutation<
  downloadLookbookVideoWithImageMutation$variables,
  downloadLookbookVideoWithImageMutation$data,
>*/);
