/**
 * @generated SignedSource<<33fb5e994781c1b61378cdd106b55837>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentType } from "relay-runtime";
declare export opaque type shopcastCard_currentUser$fragmentType: FragmentType;
export type shopcastCard_currentUser$data = {|
  +adminAccess: ?string,
  +id: string,
  +$fragmentType: shopcastCard_currentUser$fragmentType,
|};
export type shopcastCard_currentUser$key = {
  +$data?: shopcastCard_currentUser$data,
  +$fragmentSpreads: shopcastCard_currentUser$fragmentType,
  ...
};
*/

var node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "shopcastCard_currentUser",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "adminAccess",
      "storageKey": null
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node/*: any*/).hash = "d99ef4dc9b354a10779fa59014d4fd49";

module.exports = ((node/*: any*/)/*: Fragment<
  shopcastCard_currentUser$fragmentType,
  shopcastCard_currentUser$data,
>*/);
