/**
 * @generated SignedSource<<541e15b097130e8dd95ac6d1249d269a>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Mutation } from 'relay-runtime';
export type UploadShopcastFolderAvatarInput = {|
  clientMutationId?: ?string,
  folderId?: ?string,
  imageName?: ?string,
|};
export type uploadFolderAvatarMutation$variables = {|
  input: UploadShopcastFolderAvatarInput,
|};
export type uploadFolderAvatarMutation$data = {|
  +uploadShopcastFolderAvatar: ?{|
    +folder: ?{|
      +avatar: ?string,
      +id: string,
    |},
    +store: ?{|
      +currentUser: ?{|
        +profilePhoto: ?string,
      |},
    |},
  |},
|};
export type uploadFolderAvatarMutation = {|
  response: uploadFolderAvatarMutation$data,
  variables: uploadFolderAvatarMutation$variables,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "profilePhoto",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "ShopcastFolder",
  "kind": "LinkedField",
  "name": "folder",
  "plural": false,
  "selections": [
    (v3/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "avatar",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "uploadFolderAvatarMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UploadShopcastFolderAvatarPayload",
        "kind": "LinkedField",
        "name": "uploadShopcastFolderAvatar",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Store",
            "kind": "LinkedField",
            "name": "store",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "User",
                "kind": "LinkedField",
                "name": "currentUser",
                "plural": false,
                "selections": [
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "ShopshareMutationRoot",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "uploadFolderAvatarMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UploadShopcastFolderAvatarPayload",
        "kind": "LinkedField",
        "name": "uploadShopcastFolderAvatar",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Store",
            "kind": "LinkedField",
            "name": "store",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "User",
                "kind": "LinkedField",
                "name": "currentUser",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/)
                ],
                "storageKey": null
              },
              (v3/*: any*/)
            ],
            "storageKey": null
          },
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "2fd73c7263291cabf32e4050041f8354",
    "id": null,
    "metadata": {},
    "name": "uploadFolderAvatarMutation",
    "operationKind": "mutation",
    "text": "mutation uploadFolderAvatarMutation(\n  $input: UploadShopcastFolderAvatarInput!\n) {\n  uploadShopcastFolderAvatar(input: $input) {\n    store {\n      currentUser {\n        profilePhoto\n        id\n      }\n      id\n    }\n    folder {\n      id\n      avatar\n    }\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "188b00ab15f79bb20db7f7b3cd55f6a5";

module.exports = ((node/*: any*/)/*: Mutation<
  uploadFolderAvatarMutation$variables,
  uploadFolderAvatarMutation$data,
>*/);
