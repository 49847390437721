/**
 * @generated SignedSource<<35ae3ef8017e3f82780c328dadec16b7>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Query } from 'relay-runtime';
import type { reporting_store$fragmentType } from "./reporting_store.graphql";
export type reportingQuery$variables = {||};
export type reportingQuery$data = {|
  +store: ?{|
    +$fragmentSpreads: reporting_store$fragmentType,
  |},
|};
export type reportingQuery = {|
  response: reportingQuery$data,
  variables: reportingQuery$variables,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "reportingQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Store",
        "kind": "LinkedField",
        "name": "store",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "reporting_store"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "ShopshareQueryRoot",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "reportingQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Store",
        "kind": "LinkedField",
        "name": "store",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "currentUser",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isRetailer",
                "storageKey": null
              },
              (v0/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "dd4033bb1e0ff8800b4153e7dcd8ef1e",
    "id": null,
    "metadata": {},
    "name": "reportingQuery",
    "operationKind": "query",
    "text": "query reportingQuery {\n  store {\n    ...reporting_store\n    id\n  }\n}\n\nfragment reporting_store on Store {\n  id\n  currentUser {\n    isRetailer\n    id\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "03425f3754f8478e308446e43cff2eec";

module.exports = ((node/*: any*/)/*: Query<
  reportingQuery$variables,
  reportingQuery$data,
>*/);
