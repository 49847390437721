import React, { Component, Fragment } from "react"
import Modal from "react-modal"
import Dropzone from "react-dropzone"
import {
	Cross2Icon,
	PlusIcon,
	TrashIcon,
	Pencil1Icon,
} from "@radix-ui/react-icons"
import { toast } from "react-toastify"
import _, { unset } from "lodash"
import gql from "helpers/gql-helper"
import awsService from "services/aws-service"
import AutoSuggest from "components/auto-suggest-new/auto-suggest"
import LoadingDots from "components/loading-dots/loading-dots"
import setLookbookBackgroundThumbUrlMutation from "mutations/lookbook/set-lookbook-background-thumb-url"
import updateLookbookBackgroundMutation from "mutations/lookbook/update-lookbook-background"
import grids from "shared/styles/components/grids.css"
import form from "shared/styles/components/form.css"
import textfield from "shared/styles/components/textfield.css"
import file from "shared/styles/components/file.css"
import modal from "shared/styles/components/modal.css"
import button from "shared/styles/components/buttons.css"
import styles from "./background-upload.css"

class BackgroundUpload extends Component {
	constructor(props) {
		super(props)
		this.state = {
			files: [],
			edit: null,
			uploading: false,
			editMode: false,
			subCategorySet: [],
			errors: [],
		}
		this.closeEdit = this.closeEdit.bind(this)
		this.upload = this.upload.bind(this)
		this.saveEdit = this.saveEdit.bind(this)
		this.onSelect = this.onSelect.bind(this)
	}

	componentDidMount() {
		const { subCategoryList } = this.props
		this.setState({
			subCategorySet: subCategoryList.map((obj) => ({
				id: obj.id,
				value: obj.value,
			})),
		})
	}

	componentDidUpdate() {
		const { edit, editMode } = this.state
		const { selected } = this.props
		if (selected && edit !== selected && !editMode) {
			this.setState({
				edit: {
					id: selected.id,
					title: selected.name,
					collection: selected.category.map((obj) => ({
						id: obj.id,
						value: obj.value,
					})),
					url: selected.url,
				},
				errors: [],
				editMode: true,
			})
		}
	}

	onSelect(productCollectionsArray) {
		const { edit } = this.state
		const newCollection = []
		productCollectionsArray.forEach((collection) => {
			if (collection.id.includes("new_")) {
				newCollection.push(collection)
			}
		})
		this.setState({
			edit: { ...edit, collection: productCollectionsArray },
			errors: [],
		})
	}

	getErrors(obj) {
		const errors = []
		if (!obj.title) errors.push("Title is required")
		if (obj.collection.length === 0)
			errors.push("You are required to have at least one collection.")
		return errors
	}

	closeEdit(count) {
		const { editMode } = this.state
		const { closeModal } = this.props
		this.setState({ edit: null, uploading: false, errors: [] })
		if (editMode) {
			closeModal(count || 0)
			this.setState({ editMode: false })
		}
	}

	async upload() {
		const { relay, closeModal } = this.props
		const { files } = this.state
		const data = _.cloneDeep(files)
		const errors = []
		for (let i = 0; i < data.length; i++) {
			const obj = data[i]
			const objErrors = this.getErrors(obj)
			errors.push(...objErrors.map((x) => `${x} (Image ${i + 1})`))
		}
		if (errors.length > 0) {
			this.setState({ errors })
			return
		}
		this.setState({ uploading: true })
		await Promise.all(
			data.map(async (dataItem) => {
				const x = dataItem
				unset(x, "tempUrl")
				const thumbImage = x.file
				const args = {
					fileType: thumbImage.type,
					ext: thumbImage.name.substring(
						thumbImage.name.lastIndexOf(".") + 1
					),
				}
				unset(x, "file")
				x.category = x.collection[0].value
				unset(x, "collection")
				x.type = "image"
				const mutationRes = await new Promise((resolve, reject) => {
					updateLookbookBackgroundMutation.commit(
						relay.environment,
						{ ...x },
						args,
						(e) => {
							reject(e)
						},
						(result) => {
							resolve(result)
						}
					)
				})
				const innerData = mutationRes?.createUpdateBackground

				const Id = x.id || innerData?.background.id
				const preSignedUrl = innerData?.background?.preSignedUrl

				await awsService.uploadImageAWS(
					preSignedUrl?.signedRequest,
					thumbImage
				)
				const urlData = {
					uploadId: Id,
					url: preSignedUrl?.url,
				}
				await new Promise((resolve, reject) => {
					setLookbookBackgroundThumbUrlMutation.commit(
						relay.environment,
						urlData,
						(e) => {
							reject(e)
						},
						(res) => {
							resolve(res)
						}
					)
				})
			})
		)
		this.setState({ files: [], uploading: false })
		closeModal(data.length)
	}

	async saveEdit() {
		const { edit, editMode, files } = this.state
		const { relay } = this.props
		if (editMode) {
			const data = _.cloneDeep(edit)
			this.setState({ uploading: true })
			data.category = data.collection[0].value
			data.type = "image"
			const errors = this.getErrors(data)
			if (errors.length > 0) {
				this.setState({ errors })
				return
			}
			unset(data, "canEdit")
			unset(data, "tempUrl")
			unset(data, "file")
			unset(data, "collection")
			await new Promise((resolve, reject) => {
				updateLookbookBackgroundMutation.commit(
					relay.environment,
					data,
					null,
					(e) => {
						toast.info(<Fragment>{gql.getError(e)}</Fragment>, {
							autoClose: 5000,
							closeButton: false,
						})
						reject(e)
					},
					(result) => {
						toast.success(<Fragment>Successfully Updated.</Fragment>, {
							autoClose: 5000,
							closeButton: false,
						})
						resolve(result)
					}
				)
			})
			this.setState({
				files: [],
				editMode: false,
				uploading: false,
				errors: [],
			})
			this.closeEdit(0)
		} else {
			const dataFile = files.map((x) =>
				x.tempUrl === edit.tempUrl ? edit : x
			)
			this.setState({ files: dataFile })
			this.closeEdit()
		}
	}

	renderModalContent() {
		const { edit } = this.state
		if (edit) return this.renderEditModalContent()
		return this.renderListModalContent()
	}

	renderEditModalContent() {
		const { closeModal } = this.props
		const { edit, subCategorySet, errors } = this.state
		return (
			<div
				className={`${modal.dialog} ${modal.dialogCentered} ${modal.dialogFullscreenSmDown} ${modal.dialogLg}`}
			>
				<div className={`${modal.content}`}>
					<div className={modal.header}>
						<h4 className={modal.title}>Edit Image</h4>
						<button
							type="button"
							className={button.close}
							onClick={() => {
								this.setState({
									files: [],
									editMode: false,
									uploading: false,
									edit: null,
									errors: [],
								})
								closeModal(false)
							}}
						>
							<Cross2Icon className={button.closeIcon} />
						</button>
					</div>
					<div className={modal.body}>
						<div className={form.row}>
							<div className={styles.col}>
								<div className={form.group}>
									<label className={form.label}>Image</label>
									<div className={styles.product}>
										<img
											src={edit.url || edit.tempUrl}
											alt={edit.title}
											className={styles.image}
										/>
									</div>
								</div>
							</div>
							<div className={styles.col}>
								<div className={form.group}>
									{errors.length > 0 && (
										<div className={form.error}>
											{errors.map((i) => (
												<div key={i}>{i}</div>
											))}
										</div>
									)}
								</div>
								<div className={form.group}>
									<label className={form.label}>Title</label>
									<input
										className={textfield.normal}
										onBlur={({ target }) =>
											this.setState({
												edit: {
													...edit,
													title: target.value,
												},
												errors: [],
											})
										}
										defaultValue={edit.title}
									/>
								</div>

								<div className={form.group}>
									<label className={form.label}>Category</label>
									<AutoSuggest
										result={edit?.collection || []}
										dataList={subCategorySet}
										onChange={this.onSelect}
										maxResults={1}
									/>
								</div>
							</div>
						</div>
					</div>
					<div className={modal.footer}>
						<button
							type="button"
							className={button.link}
							onClick={() => {
								this.closeEdit()
							}}
						>
							Cancel
						</button>
						<button
							type="submit"
							className={button.primary}
							onClick={this.saveEdit}
						>
							Save
						</button>
					</div>
				</div>
			</div>
		)
	}

	renderListModalContent() {
		const {
			accepted,
			closeModal,
			adminAccess,
			currentCollection,
		} = this.props
		const { files, uploading, errors } = this.state
		return (
			<div
				className={`${modal.dialog} ${modal.dialogCentered} ${modal.dialogFullscreenSmDown} ${modal.dialogLg}`}
			>
				<div className={`${modal.content}`}>
					<div className={modal.header}>
						<h4 className={modal.title}>Upload Image</h4>
						<button
							type="button"
							className={button.close}
							onClick={() => {
								this.setState({
									files: [],
									editMode: false,
									uploading: false,
									edit: null,
									errors: [],
								})
								closeModal(false)
							}}
						>
							<Cross2Icon className={button.closeIcon} />
						</button>
					</div>
					<div className={modal.body}>
						<div className={form.group}>
							{errors.length > 0 && (
								<div className={form.error}>
									{errors.map((i) => (
										<div key={i}>{i}</div>
									))}
								</div>
							)}
						</div>
						<Dropzone
							onDrop={(acceptedFiles) => {
								this.setState({
									files: [
										...files,
										...acceptedFiles.map((fileObj) => ({
											title: fileObj.name.split(".")[0],
											file: fileObj,
											collection: currentCollection
												? [currentCollection]
												: [],
											adminAccess,
											url: null,
											tempUrl: URL.createObjectURL(fileObj),
										})),
									],
								})
							}}
							noKeyboard
							accept={accepted}
						>
							{({ getRootProps, getInputProps }) => (
								<div className="container">
									<div className={file.root}>
										<div
											{...getRootProps()}
											className={file.dropRect}
										>
											<input {...getInputProps()} />
											<PlusIcon className={file.iconSm} />
											<span className={file.label}>
												Drop Some Images Here
											</span>
										</div>
									</div>
									{files.length > 0 && (
										<div className={styles.content}>
											<div className={grids.image}>
												{files.map((fileObj, i) => (
													<div
														key={fileObj.tempUrl}
														className={styles.card}
													>
														<div className={styles.figure}>
															<div className={styles.inner}>
																<img
																	className={styles.image}
																	alt="background"
																	src={fileObj.tempUrl}
																/>
															</div>
														</div>
														<div className={styles.content}>
															<h4 className={styles.title}>
																{fileObj.title}
															</h4>
														</div>
														<div className={styles.footer}>
															<button
																type="button"
																onClick={() => {
																	const newFiles = [...files]
																	newFiles.splice(i, 1)
																	this.setState({
																		files: [...newFiles],
																	})
																}}
																className={styles.tool}
															>
																<TrashIcon
																	className={styles.icon}
																/>
															</button>
															<button
																type="button"
																onClick={() => {
																	this.setState({
																		edit: fileObj,
																	})
																}}
																className={styles.tool}
															>
																<Pencil1Icon
																	className={styles.icon}
																/>
															</button>
														</div>
													</div>
												))}
											</div>
										</div>
									)}
								</div>
							)}
						</Dropzone>
					</div>
					<div className={modal.footer}>
						<button
							type="button"
							className={button.link}
							onClick={() => {
								this.setState({
									files: [],
									editMode: false,
									uploading: false,
									edit: null,
									errors: [],
								})
								closeModal(false)
							}}
						>
							{uploading ? (
								<LoadingDots color="var(--gray300)" />
							) : (
								"Cancel"
							)}
						</button>
						<button
							type="submit"
							className={button.primary}
							onClick={this.upload}
						>
							{uploading ? (
								<LoadingDots color="var(--primary80)" />
							) : (
								"Upload"
							)}
						</button>
					</div>
				</div>
			</div>
		)
	}

	render() {
		const { openModal, ...otherProps } = this.props
		return (
			<Modal
				className={modal.root}
				overlayClassName={modal.overlay}
				isOpen={openModal}
				{...otherProps}
				ariaHideApp={false}
				onAfterOpen={this.initailCanvas}
			>
				{this.renderModalContent()}
			</Modal>
		)
	}
}
export default BackgroundUpload
