import React, { useState } from "react"
import { Transition } from "react-transition-group"
import {
	CheckIcon,
	Pencil1Icon,
	TrashIcon,
	ValueNoneIcon,
} from "@radix-ui/react-icons"
import MenuIcon from "shared/assets/menu-dots-horizontal.svg"
import stylesDropdown from "components/dropdown-menu/dropdown-menu-dark.css"
import button from "shared/styles/components/buttons.css"
import DropdownMenu from "components/dropdown-menu/dropdown-menu"
import FavouriteButton from "components/buttons/favourite-button"
import styles from "./bg-color-card.css"

function BgColorCard(props) {
	const [isShown, setIsShown] = useState(false)
	const {
		color,
		id,
		onClick,
		isSelected,
		hasClear,
		isAdmin,
		editEvent,
		deleteEvent,
		isFavourite,
		showFavouriteButton,
		clickFavourite,
	} = props

	const renderMenu = () => {
		const listItems = []
		const push = (click, name, key, icon) =>
			listItems.push({
				click,
				name,
				key,
				icon,
			})
		const icon = <MenuIcon className={stylesDropdown.icon} />

		if (editEvent) {
			push(
				editEvent,
				"Edit",
				"edit",
				<Pencil1Icon className={stylesDropdown.itemIcon} />
			)
		}
		if (deleteEvent) {
			listItems.push("divide")
			push(
				deleteEvent,
				"Delete",
				"delete",
				<TrashIcon className={stylesDropdown.itemIcon} />
			)
		}
		return listItems.length ? (
			<DropdownMenu
				items={listItems}
				mainIcon={icon}
				darkTheme
				contentRight
				triggerClass={button.lightIcon}
				triggerClassActive={button.lightIconActive}
			/>
		) : null
	}

	return (
		<div
			className={styles.root}
			onMouseEnter={() => setIsShown(true)}
			onMouseLeave={() => setIsShown(false)}
		>
			<Transition in={isShown && isAdmin} timeout={200}>
				{(state) => (
					<div className={`${styles.menu} ${styles[state]}`}>
						{renderMenu()}
					</div>
				)}
			</Transition>
			<div className={styles.swatch}>
				<button
					className={hasClear ? styles.buttonWhite : styles.button}
					id={id}
					// data-tooltip-id="tooltip"
					// data-tooltip-content={color}
					type="button"
					style={{ backgroundColor: color }}
					onClick={() => onClick(color)}
				>
					{isSelected && <CheckIcon className={styles.icon} />}
					{hasClear && <ValueNoneIcon className={styles.icon} />}
				</button>
			</div>
			<div className={styles.content}>
				<div className={styles.titleWithIcons}>
					<h4 className={styles.title}>{color}</h4>
					{showFavouriteButton && (
						<div className={styles.favButton}>
							<FavouriteButton
								isFavourite={isFavourite}
								onClick={clickFavourite}
							/>
						</div>
					)}
				</div>
			</div>
		</div>
	)
}
export default BgColorCard
