import React from "react"
import styles from "./products.css"

function Products() {
	return (
		<div className={styles.root}>
			<div className={styles.masthead}>
				<div className={styles.inner}>
					<h1 className={styles.title}>Product Library</h1>
				</div>
			</div>
			<div className={styles.content} />
		</div>
	)
}

export default { Component: Products, needAuth: true }
