/**
 * @generated SignedSource<<09274b61667cae596bf478c4328056b0>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Query } from 'relay-runtime';
import type { collectionSelect_store$fragmentType } from "./collectionSelect_store.graphql";
export type collectionSelectQuery$variables = {|
  brands?: ?$ReadOnlyArray<?string>,
  categories?: ?$ReadOnlyArray<?string>,
  clients?: ?$ReadOnlyArray<?string>,
  collectionSearch?: ?string,
  count: number,
  cursor?: ?string,
  folderId?: ?string,
  limitType?: ?$ReadOnlyArray<?string>,
  search?: ?string,
  teamId?: ?string,
|};
export type collectionSelectQuery$data = {|
  +store: ?{|
    +$fragmentSpreads: collectionSelect_store$fragmentType,
  |},
|};
export type collectionSelectQuery = {|
  response: collectionSelectQuery$data,
  variables: collectionSelectQuery$variables,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "brands"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "categories"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "clients"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "collectionSearch"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "count"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "cursor"
},
v6 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "folderId"
},
v7 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "limitType"
},
v8 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "search"
},
v9 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "teamId"
},
v10 = {
  "kind": "Variable",
  "name": "brands",
  "variableName": "brands"
},
v11 = {
  "kind": "Variable",
  "name": "categories",
  "variableName": "categories"
},
v12 = {
  "kind": "Variable",
  "name": "folderId",
  "variableName": "folderId"
},
v13 = {
  "kind": "Variable",
  "name": "limitType",
  "variableName": "limitType"
},
v14 = {
  "kind": "Variable",
  "name": "search",
  "variableName": "search"
},
v15 = {
  "kind": "Variable",
  "name": "teamId",
  "variableName": "teamId"
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v17 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "cursor"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "count"
  },
  {
    "kind": "Variable",
    "name": "search",
    "variableName": "collectionSearch"
  }
],
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isPublished",
  "storageKey": null
},
v20 = {
  "alias": null,
  "args": null,
  "concreteType": "ShopcastFolder",
  "kind": "LinkedField",
  "name": "folder",
  "plural": false,
  "selections": [
    (v16/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v21 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isACloset",
  "storageKey": null
},
v22 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isDefault",
  "storageKey": null
},
v23 = {
  "alias": null,
  "args": null,
  "concreteType": "User",
  "kind": "LinkedField",
  "name": "user",
  "plural": false,
  "selections": [
    (v16/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "fullname",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v24 = {
  "alias": null,
  "args": null,
  "concreteType": "Client",
  "kind": "LinkedField",
  "name": "client",
  "plural": false,
  "selections": [
    (v16/*: any*/)
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/),
      (v7/*: any*/),
      (v8/*: any*/),
      (v9/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "collectionSelectQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Store",
        "kind": "LinkedField",
        "name": "store",
        "plural": false,
        "selections": [
          {
            "args": [
              (v10/*: any*/),
              (v11/*: any*/),
              {
                "kind": "Variable",
                "name": "clients",
                "variableName": "clients"
              },
              {
                "kind": "Variable",
                "name": "collectionSearch",
                "variableName": "collectionSearch"
              },
              {
                "kind": "Variable",
                "name": "count",
                "variableName": "count"
              },
              {
                "kind": "Variable",
                "name": "cursor",
                "variableName": "cursor"
              },
              (v12/*: any*/),
              (v13/*: any*/),
              (v14/*: any*/),
              (v15/*: any*/)
            ],
            "kind": "FragmentSpread",
            "name": "collectionSelect_store"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "ShopshareQueryRoot",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v8/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v3/*: any*/),
      (v1/*: any*/),
      (v0/*: any*/),
      (v2/*: any*/),
      (v7/*: any*/),
      (v6/*: any*/),
      (v9/*: any*/)
    ],
    "kind": "Operation",
    "name": "collectionSelectQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Store",
        "kind": "LinkedField",
        "name": "store",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "currentUser",
            "plural": false,
            "selections": [
              (v16/*: any*/),
              {
                "alias": null,
                "args": [
                  (v10/*: any*/),
                  (v11/*: any*/),
                  {
                    "kind": "Variable",
                    "name": "clientIds",
                    "variableName": "clients"
                  },
                  {
                    "kind": "Literal",
                    "name": "collections",
                    "value": []
                  },
                  (v12/*: any*/),
                  (v13/*: any*/),
                  {
                    "kind": "Literal",
                    "name": "resultTypes",
                    "value": [
                      "collections"
                    ]
                  },
                  (v14/*: any*/),
                  (v15/*: any*/)
                ],
                "concreteType": "ProductCollectionProductSearch",
                "kind": "LinkedField",
                "name": "productCollectionSearchItems",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": (v17/*: any*/),
                    "concreteType": "ProductCollectionConnection",
                    "kind": "LinkedField",
                    "name": "collections",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ProductCollectionEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "ProductCollection",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v16/*: any*/),
                              (v18/*: any*/),
                              (v19/*: any*/),
                              (v20/*: any*/),
                              (v21/*: any*/),
                              (v22/*: any*/),
                              (v23/*: any*/),
                              (v24/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "__typename",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "cursor",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "PageInfo",
                        "kind": "LinkedField",
                        "name": "pageInfo",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "endCursor",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "hasNextPage",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": (v17/*: any*/),
                    "filters": [
                      "search"
                    ],
                    "handle": "connection",
                    "key": "currentUser_productCollectionSearchItems_collections",
                    "kind": "LinkedHandle",
                    "name": "collections"
                  },
                  {
                    "alias": null,
                    "args": [
                      (v15/*: any*/)
                    ],
                    "concreteType": "ProductCollection",
                    "kind": "LinkedField",
                    "name": "defaultCollection",
                    "plural": false,
                    "selections": [
                      (v16/*: any*/),
                      (v18/*: any*/),
                      (v19/*: any*/),
                      (v20/*: any*/),
                      (v21/*: any*/),
                      (v22/*: any*/),
                      (v23/*: any*/),
                      (v24/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v16/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "2dfbf538ca493b7c655c106f7eeb4210",
    "id": null,
    "metadata": {},
    "name": "collectionSelectQuery",
    "operationKind": "query",
    "text": "query collectionSelectQuery(\n  $search: String\n  $count: Int!\n  $cursor: String\n  $collectionSearch: String\n  $categories: [String]\n  $brands: [String]\n  $clients: [String]\n  $limitType: [String]\n  $folderId: String\n  $teamId: String\n) {\n  store {\n    ...collectionSelect_store_3GUiV9\n    id\n  }\n}\n\nfragment collectionSelect_store_3GUiV9 on Store {\n  currentUser {\n    id\n    productCollectionSearchItems(search: $search, categories: $categories, brands: $brands, collections: [], clientIds: $clients, limitType: $limitType, folderId: $folderId, teamId: $teamId, resultTypes: [\"collections\"]) {\n      collections(first: $count, after: $cursor, search: $collectionSearch) {\n        edges {\n          node {\n            id\n            title\n            isPublished\n            folder {\n              id\n              name\n            }\n            isACloset\n            isDefault\n            user {\n              id\n              fullname\n            }\n            client {\n              id\n            }\n            __typename\n          }\n          cursor\n        }\n        pageInfo {\n          endCursor\n          hasNextPage\n        }\n      }\n      defaultCollection(teamId: $teamId) {\n        id\n        title\n        isPublished\n        folder {\n          id\n          name\n        }\n        isACloset\n        isDefault\n        user {\n          id\n          fullname\n        }\n        client {\n          id\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "ee274ed12a5e6a8d6dffdf355e6b3272";

module.exports = ((node/*: any*/)/*: Query<
  collectionSelectQuery$variables,
  collectionSelectQuery$data,
>*/);
