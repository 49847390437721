/**
 * @generated SignedSource<<f980e7b1a67012bbf3b6b96d483d1d7e>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Mutation } from 'relay-runtime';
export type BannerPhotoInput = {|
  clientMutationId?: ?string,
  imageName?: ?string,
  profileId?: ?string,
|};
export type bannerPhotoMutation$variables = {|
  input: BannerPhotoInput,
|};
export type bannerPhotoMutation$data = {|
  +bannerPhoto: ?{|
    +store: ?{|
      +currentUser: ?{|
        +bannerPhoto: ?string,
      |},
    |},
    +user: ?{|
      +bannerPhoto: ?string,
    |},
  |},
|};
export type bannerPhotoMutation = {|
  response: bannerPhotoMutation$data,
  variables: bannerPhotoMutation$variables,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "bannerPhoto",
  "storageKey": null
},
v3 = [
  (v2/*: any*/)
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = [
  (v2/*: any*/),
  (v4/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "bannerPhotoMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "BannerPhotoPayload",
        "kind": "LinkedField",
        "name": "bannerPhoto",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Store",
            "kind": "LinkedField",
            "name": "store",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "User",
                "kind": "LinkedField",
                "name": "currentUser",
                "plural": false,
                "selections": (v3/*: any*/),
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": (v3/*: any*/),
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "ShopshareMutationRoot",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "bannerPhotoMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "BannerPhotoPayload",
        "kind": "LinkedField",
        "name": "bannerPhoto",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Store",
            "kind": "LinkedField",
            "name": "store",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "User",
                "kind": "LinkedField",
                "name": "currentUser",
                "plural": false,
                "selections": (v5/*: any*/),
                "storageKey": null
              },
              (v4/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": (v5/*: any*/),
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "6f69fd06b2b5f975bbb18fe48e41f48b",
    "id": null,
    "metadata": {},
    "name": "bannerPhotoMutation",
    "operationKind": "mutation",
    "text": "mutation bannerPhotoMutation(\n  $input: BannerPhotoInput!\n) {\n  bannerPhoto(input: $input) {\n    store {\n      currentUser {\n        bannerPhoto\n        id\n      }\n      id\n    }\n    user {\n      bannerPhoto\n      id\n    }\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "f78212cd966bd871973a55beb11965cb";

module.exports = ((node/*: any*/)/*: Mutation<
  bannerPhotoMutation$variables,
  bannerPhotoMutation$data,
>*/);
