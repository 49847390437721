import { commitMutation, graphql } from "react-relay"
import { complete } from "../helper.js"

const mutation = graphql`
	mutation reportShopcastMutation($input: ReportShopcastInput!) {
		reportShopcast(input: $input) {
			store {
				id
				shopcast {
					id
				}
			}
		}
	}
`

const getConfig = (storeId) => [
	{
		type: "FIELDS_CHANGE",
		fieldIDs: {
			store: storeId,
		},
	},
]

const commit = (
	env,
	shopcastId,
	description,
	reportType,
	storeId,
	onCompleted,
	onError
) =>
	commitMutation(env, {
		mutation,
		variables: { input: { shopcastId, description, reportType } },
		configs: getConfig(storeId),
		onCompleted: complete(onCompleted, onError),
		onError: (e) => complete(null, [e]),
	})

export default { commit }
