import React, { PureComponent } from "react"
import { Link, Outlet } from "react-router-dom"
import { createFragmentContainer, graphql } from "react-relay"
import nav from "shared/styles/components/nav.css"
import twoColumn from "shared/styles/layout/two-column.css"
// import styles from "./admin-root-page.css"
// import Dashboard from "components/admin/dashboard/dashboard"

import { getActiveTabClass } from "../../helpers/ui-helper"

class AdminRootPage extends PureComponent {
	render() {
		const { admin, location, filters } = this.props
		return (
			<div className={twoColumn.root}>
				<aside className={twoColumn.side}>
					<div className={twoColumn.fixed}>
						<div className={twoColumn.fillHeight}>
							<div className={twoColumn.scrollPanel}>
								<div className={twoColumn.sideMain}>
									<nav className={nav.pills}>
										<Link
											className={getActiveTabClass(
												location.pathname.includes(
													"/admin/dashboard"
												),
												nav.pill,
												nav.pillActive
											)}
											to="/admin/dashboard"
											role="button"
											tabIndex={0}
										>
											<div className={nav.content}>Dashboard</div>
										</Link>
										<Link
											className={getActiveTabClass(
												location.pathname === "/admin/campaign",
												nav.pill,
												nav.pillActive
											)}
											to="/admin/campaign"
											role="button"
											tabIndex={0}
										>
											<div className={nav.content}>
												Referral Campaigns
											</div>
										</Link>
										<Link
											className={getActiveTabClass(
												location.pathname ===
													"/admin/referralpayment",
												nav.pill,
												nav.pillActive
											)}
											to="/admin/referralpayment"
											role="button"
											tabIndex={0}
										>
											<div className={nav.content}>
												Referral Payments
											</div>
										</Link>
										<Link
											className={getActiveTabClass(
												location.pathname === "/admin/user",
												nav.pill,
												nav.pillActive
											)}
											to="/admin/user"
											role="button"
											tabIndex={0}
										>
											<div className={nav.content}>Users</div>
										</Link>
										<Link
											className={getActiveTabClass(
												location.pathname === "/admin/commision",
												nav.pill,
												nav.pillActive
											)}
											to="/admin/commision"
											role="button"
											tabIndex={0}
										>
											<div className={nav.content}>
												Commission Report
											</div>
										</Link>
										<Link
											className={getActiveTabClass(
												location.pathname === "/admin/transactions",
												nav.pill,
												nav.pillActive
											)}
											to="/admin/transactions"
											role="button"
											tabIndex={0}
										>
											<div className={nav.content}>
												Commission Transactions
											</div>
										</Link>
										<Link
											className={getActiveTabClass(
												location.pathname === "/admin/flagged",
												nav.pill,
												nav.pillActive
											)}
											to="/admin/flagged"
											role="button"
											tabIndex={0}
										>
											<div className={nav.content}>
												Flagged Content
											</div>
										</Link>
									</nav>
								</div>
							</div>
						</div>
					</div>
				</aside>
				<div className={twoColumn.main}>
					<Outlet
						context={{
							admin,
							filters,
						}}
					/>
				</div>
			</div>
		)
	}
}

AdminRootPage = createFragmentContainer(AdminRootPage, {
	admin: graphql`
		fragment adminRootPage_admin on Admin {
			id
			users {
				edges {
					node {
						id
						fullname
					}
				}
			}
			...adminUserReports_reports
			...adminReferralCampaign_admin
			...adminReferralPayment_admin
			...adminCommisionReport_admin
		}
	`,
})

export default AdminRootPage
