import React, { useCallback, useMemo, useState } from "react"
import { Editable, withReact, useSlate, Slate } from "slate-react"
import { Editor, Transforms, createEditor } from "slate"
import { withHistory } from "slate-history"
import {
	HeadingIcon,
	FontBoldIcon,
	FontItalicIcon,
	UnderlineIcon,
} from "@radix-ui/react-icons"
import { debounce } from "helpers/ui-helper"
import form from "shared/styles/components/form.css"
import toggle from "shared/styles/components/toogle.css"
import BulletedList from "../../shared/assets/toolbar-icons/bulleted-list.svg"
import NumberedList from "../../shared/assets/toolbar-icons/numbered-list.svg"
// import Underlined from "../../shared/assets/toolbar-icons/underlined.svg"
// import Bold from "../../shared/assets/toolbar-icons/bold.svg"
// import Italic from "../../shared/assets/toolbar-icons/italic.svg"
// import Code from "../../shared/assets/toolbar-icons/code.svg"
// import H1 from "../../shared/assets/toolbar-icons/h1.svg"
// import H2 from "../../shared/assets/toolbar-icons/h2.svg"
// import Quote from "../../shared/assets/toolbar-icons/quote.svg"

import styles from "./richtext-editor.css"

const LIST_TYPES = ["numbered-list", "bulleted-list"]
let prevContext = null

function RichTextEditor(props) {
	const { initialScript, readOnly, saveScript } = props
	const [value, setValue] = useState(initialScript)
	const renderElement = useCallback((p) => <Element {...p} />, [])
	const renderLeaf = useCallback((p) => <Leaf {...p} />, [])
	const editor = useMemo(() => withHistory(withReact(createEditor())), [])
	if (!prevContext) prevContext = initialScript
	const debouncedChangeHandler = useMemo(
		() => debounce((userInput) => saveScript(userInput), 1000),
		[]
	)

	return (
		<Slate
			editor={editor}
			initialValue={value}
			onChange={(val) => {
				if (value !== val) debouncedChangeHandler(val)
				setValue(val)
			}}
		>
			{!readOnly && (
				<div className={styles.root}>
					<div className={form.section}>
						<div className={form.header}>
							<label className={toggle.checkSpaceBetween}>
								<div className={toggle.labelSm}>About Section</div>
							</label>
						</div>
						<div className={form.content}>
							<div className={styles.controls}>
								<BlockButton
									format="heading-two"
									icon={<HeadingIcon />}
								/>
								<MarkButton format="bold" icon={<FontBoldIcon />} />
								<MarkButton format="italic" icon={<FontItalicIcon />} />
								<MarkButton
									format="underline"
									icon={<UnderlineIcon />}
								/>
								{/* <BlockButton format="heading-one" icon={<H1 />} />
								<MarkButton format="code" icon={<Code/>} />
								<BlockButton format="block-quote" icon={<Quote/>} /> */}
								<BlockButton
									format="numbered-list"
									icon={<NumberedList />}
								/>
								<BlockButton
									format="bulleted-list"
									icon={<BulletedList />}
								/>
							</div>

							<Editable
								renderElement={renderElement}
								renderLeaf={renderLeaf}
								className={styles.content}
								placeholder="Add your bio"
								spellCheck
								readOnly={readOnly}
							/>
						</div>
					</div>
				</div>
			)}
			{readOnly && (
				<div className={styles.rootReadOnly}>
					<Editable
						renderElement={renderElement}
						renderLeaf={renderLeaf}
						className={styles.contentReadOnly}
						placeholder="Add your bio"
						spellCheck
						readOnly={readOnly}
					/>
				</div>
			)}
		</Slate>
	)
}

const isBlockActive = (editor, format) => {
	const [match] = Editor.nodes(editor, {
		match: (n) => n.type === format,
	})
	return !!match
}

const isMarkActive = (editor, format) => {
	const marks = Editor.marks(editor)
	return marks ? marks[format] === true : false
}

const toggleBlock = (editor, format) => {
	const isActive = isBlockActive(editor, format)
	const isList = LIST_TYPES.includes(format)

	Transforms.unwrapNodes(editor, {
		match: (n) => LIST_TYPES.includes(n.type),
		split: true,
	})

	let type
	if (isActive) {
		type = "paragraph"
	} else if (isList) {
		type = "list-item"
	} else {
		type = format
	}

	Transforms.setNodes(editor, {
		type,
	})

	if (!isActive && isList) {
		const block = { type: format, children: [] }
		Transforms.wrapNodes(editor, block)
	}
}

const toggleMark = (editor, format) => {
	const isActive = isMarkActive(editor, format)

	if (isActive) {
		Editor.removeMark(editor, format)
	} else {
		Editor.addMark(editor, format, true)
	}
}

function Element({ attributes, children, element }) {
	switch (element.type) {
		case "block-quote":
			return <blockquote {...attributes}>{children}</blockquote>
		case "bulleted-list":
			return <ul {...attributes}>{children}</ul>
		case "heading-one":
			return <h1 {...attributes}>{children}</h1>
		case "heading-two":
			return <h2 {...attributes}>{children}</h2>
		case "list-item":
			return <li {...attributes}>{children}</li>
		case "numbered-list":
			return <ol {...attributes}>{children}</ol>
		default:
			return <p {...attributes}>{children}</p>
	}
}

function Leaf({ attributes, children, leaf }) {
	let child = children
	if (leaf.bold) {
		child = <strong>{child}</strong>
	}

	if (leaf.code) {
		child = <code>{child}</code>
	}

	if (leaf.italic) {
		child = <em>{child}</em>
	}

	if (leaf.underline) {
		child = <u>{child}</u>
	}

	return <span {...attributes}>{child}</span>
}

function BlockButton({ format, icon }) {
	const editor = useSlate()
	return (
		<button
			className={
				isBlockActive(editor, format) ? styles.btnActive : styles.btn
			}
			type="button"
			onClick={(event) => {
				event.preventDefault()
				toggleBlock(editor, format)
			}}
		>
			{icon}
		</button>
	)
}

function MarkButton({ format, icon }) {
	const editor = useSlate()
	return (
		<button
			className={
				isMarkActive(editor, format) ? styles.btnActive : styles.btn
			}
			type="button"
			onClick={(event) => {
				event.preventDefault()
				toggleMark(editor, format)
			}}
		>
			{icon}
		</button>
	)
}

export default RichTextEditor
