/**
 * @generated SignedSource<<402c4d4ea262c0de34ab08a83235e07b>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { adminCommisionReport_admin$fragmentType } from "./../../../components/admin/admin-commision-report/__generated__/adminCommisionReport_admin.graphql";
import type { adminReferralCampaign_admin$fragmentType } from "./../../../components/admin/admin-referral-campaign/__generated__/adminReferralCampaign_admin.graphql";
import type { adminReferralPayment_admin$fragmentType } from "./../../../components/admin/admin-referral-payment/__generated__/adminReferralPayment_admin.graphql";
import type { adminUserReports_reports$fragmentType } from "./../../../components/admin/user-reports/__generated__/adminUserReports_reports.graphql";
import type { FragmentType } from "relay-runtime";
declare export opaque type adminRootPage_admin$fragmentType: FragmentType;
export type adminRootPage_admin$data = {|
  +id: string,
  +users: ?{|
    +edges: ?$ReadOnlyArray<?{|
      +node: ?{|
        +fullname: ?string,
        +id: string,
      |},
    |}>,
  |},
  +$fragmentSpreads: adminCommisionReport_admin$fragmentType & adminReferralCampaign_admin$fragmentType & adminReferralPayment_admin$fragmentType & adminUserReports_reports$fragmentType,
  +$fragmentType: adminRootPage_admin$fragmentType,
|};
export type adminRootPage_admin$key = {
  +$data?: adminRootPage_admin$data,
  +$fragmentSpreads: adminRootPage_admin$fragmentType,
  ...
};
*/

var node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "adminRootPage_admin",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "UserConnectionConnection",
      "kind": "LinkedField",
      "name": "users",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "UserConnectionEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "User",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "fullname",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "adminUserReports_reports"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "adminReferralCampaign_admin"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "adminReferralPayment_admin"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "adminCommisionReport_admin"
    }
  ],
  "type": "Admin",
  "abstractKey": null
};
})();

(node/*: any*/).hash = "be2c826714422b38d08600c46c8203b2";

module.exports = ((node/*: any*/)/*: Fragment<
  adminRootPage_admin$fragmentType,
  adminRootPage_admin$data,
>*/);
