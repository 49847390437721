/**
 * @generated SignedSource<<720044f96a1091c583bbdd1480863e82>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Mutation } from 'relay-runtime';
export type ForgotPasswordInput = {|
  clientMutationId?: ?string,
  emailAddress: string,
|};
export type forgotPasswordMutation$variables = {|
  input: ForgotPasswordInput,
|};
export type forgotPasswordMutation$data = {|
  +forgotPassword: ?{|
    +name: ?string,
  |},
|};
export type forgotPasswordMutation = {|
  response: forgotPasswordMutation$data,
  variables: forgotPasswordMutation$variables,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "ForgotPasswordPayload",
    "kind": "LinkedField",
    "name": "forgotPassword",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "forgotPasswordMutation",
    "selections": (v1/*: any*/),
    "type": "ShopshareMutationRoot",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "forgotPasswordMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "71a01d4f4121c83673382b7a410e0114",
    "id": null,
    "metadata": {},
    "name": "forgotPasswordMutation",
    "operationKind": "mutation",
    "text": "mutation forgotPasswordMutation(\n  $input: ForgotPasswordInput!\n) {\n  forgotPassword(input: $input) {\n    name\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "bd589be054ea081d9808395c7053e7aa";

module.exports = ((node/*: any*/)/*: Mutation<
  forgotPasswordMutation$variables,
  forgotPasswordMutation$data,
>*/);
