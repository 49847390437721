/* eslint-disable react/destructuring-assignment */
export function complete(onCompleted, onError) {
	return (data, errors) => {
		if (errors && errors.length) {
			if (onError) return onError(errors)
		}
		else if (onCompleted) return onCompleted(data)
		return null
	}
}

export function fix() {
	/* nothing here */
}
