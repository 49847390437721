import { commitMutation, graphql } from "react-relay"
import { ConnectionHandler } from "relay-runtime"
import { complete } from "../helper"

const mutation = graphql`
	mutation deleteProductCollectionItemMutation(
		$input: DeleteProductCollectionItemInput!
	) {
		deleteProductCollectionItem(input: $input) {
			store {
				id
			}
		}
	}
`

const commit = (env, collectionId, productId, onError, onCompleted, other) =>
	commitMutation(env, {
		mutation,
		variables: {
			input: {
				collectionId,
				productId,
			},
		},
		updater: (storeProxy) => {
			if(collectionId) {
				const collectionProxy = storeProxy.get(collectionId)
				collectionProxy.setValue(collectionProxy.getValue("count") - 1, "count")
				const connectionProxy = ConnectionHandler.getConnection(
					collectionProxy,
					'currentUser_productCollection_items'
				)
				const connectionNoFilterProxy = ConnectionHandler.getConnection(
					collectionProxy,
					'currentUser_noFilter_productCollection_items'
				)
				if (connectionProxy)
					ConnectionHandler.deleteNode(connectionProxy, productId)
				if (connectionNoFilterProxy)
					ConnectionHandler.deleteNode(connectionNoFilterProxy, productId)
			}
			const data = {
				search: other.search,
				categories: other.categories,
				brands: other.brands,
				clientIds: other.clientId ? [other.clientId] : [],
				collections: other.collections,
				limitType: other.limitType,
				sort: other.sort,
				teamId: other.teamId,
			}
			if (other.type !== undefined) data.type = other.type
			const connectionFilterProxy = other ? ConnectionHandler.getConnection(
				storeProxy
					.getRoot()
					.getLinkedRecord("store")
					.getLinkedRecord("currentUser")
					.getLinkedRecord("productCollectionSearchItems", data),
				"currentUser_productCollectionSearchItems_data",
			) : null
			
			if(connectionFilterProxy)
				ConnectionHandler.deleteNode(connectionFilterProxy, productId)
		},
		onError: complete(onCompleted, onError),
		onCompleted: complete(onCompleted, onError),
	})

export default { commit }
