import { commitMutation, graphql } from "react-relay"
import { complete } from "../helper.js"

const mutation = graphql`
	mutation resetPasswordMutation($input: ResetPasswordInput!) {
		resetPassword(input: $input) {
			name
		}
	}
`

const commit = (env, password, token, onCompleted, onError) =>
	commitMutation(env, {
		mutation,
		variables: { input: { password, token } },
		configs: [],
		onCompleted: complete(onCompleted, onError),
		onError: (e) => complete(null, [e]),
	})

export default { commit }
