/**
 * @generated SignedSource<<ae9c9f6eb7940c3f00638c1295a93bd9>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentType } from "relay-runtime";
declare export opaque type clientViewFolder_store$fragmentType: FragmentType;
export type clientViewFolder_store$data = {|
  +currentUser: ?{|
    +sharedFolderCollectionsListCount: ?number,
    +sharedFolderFilesCount: ?number,
    +sharedFolderLookbooksCount: ?number,
    +sharedFolderShopcastsCount: ?number,
    +shopcastContainer: ?{|
      +folder: ?{|
        +id: string,
        +name: ?string,
      |},
    |},
  |},
  +$fragmentType: clientViewFolder_store$fragmentType,
|};
export type clientViewFolder_store$key = {
  +$data?: clientViewFolder_store$data,
  +$fragmentSpreads: clientViewFolder_store$fragmentType,
  ...
};
*/

var node/*: ReaderFragment*/ = (function(){
var v0 = [
  {
    "kind": "Variable",
    "name": "folderId",
    "variableName": "folder"
  }
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "folder"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "clientViewFolder_store",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "currentUser",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ShopcastContainer",
          "kind": "LinkedField",
          "name": "shopcastContainer",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": [
                {
                  "kind": "Variable",
                  "name": "id",
                  "variableName": "folder"
                }
              ],
              "concreteType": "ShopcastFolder",
              "kind": "LinkedField",
              "name": "folder",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "id",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "name",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": (v0/*: any*/),
          "kind": "ScalarField",
          "name": "sharedFolderShopcastsCount",
          "storageKey": null
        },
        {
          "alias": null,
          "args": (v0/*: any*/),
          "kind": "ScalarField",
          "name": "sharedFolderLookbooksCount",
          "storageKey": null
        },
        {
          "alias": null,
          "args": (v0/*: any*/),
          "kind": "ScalarField",
          "name": "sharedFolderFilesCount",
          "storageKey": null
        },
        {
          "alias": null,
          "args": (v0/*: any*/),
          "kind": "ScalarField",
          "name": "sharedFolderCollectionsListCount",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Store",
  "abstractKey": null
};
})();

(node/*: any*/).hash = "e04a874bd8a040982166b46be15ba00c";

module.exports = ((node/*: any*/)/*: Fragment<
  clientViewFolder_store$fragmentType,
  clientViewFolder_store$data,
>*/);
