import React, { Component } from "react"
import { createPaginationContainer, graphql } from "react-relay"
import InfiniteScroll from "react-infinite-scroller"
import grids from "shared/styles/components/grids.css"
import empty from "shared/styles/components/empty.css"
import LoadingDots from "components/loading-dots/loading-dots"
import ProductCollectionCard from "components/cards/product-collection-card/product-collection-card"
import QueryLoadMore from "../../components/profile/query-load-more/query-load-more"

class SharedFolderCollectionList extends Component {
	constructor(props) {
		super(props)
		this.state = {
			loading: false,
		}
		this.handleLoadMore = this.handleLoadMore.bind(this)
		this.handleInfiniteLoad = this.handleInfiniteLoad.bind(this)
	}

	handleLoadMore(itemCount) {
		const { relay } = this.props
		this.setState({ loading: true })
		relay.loadMore(
			itemCount || 12,
			() => {
				this.setState({ loading: false })
			},
			{
				force: true,
			}
		)
	}

	handleInfiniteLoad() {
		const { relay } = this.props
		relay.loadMore(12)
	}

	getListItems(edges) {
		const { setCollectionId } = this.props
		return edges.map((x) => (
			<div className={grids.col3} key={x.node.id || x.node.__id}>
				<ProductCollectionCard
					setCollection={(col) => setCollectionId(col.id)}
					collection={x.node}
				/>
			</div>
		))
	}

	render() {
		const { props, state } = this
		const { store, relay, removeInfiniteScroll } = props
		const { loading } = state
		const { sharedFolderCollectionsList: items } = store.profile
		if (!items || items.edges.length <= 0)
			return <div className={empty.simple}>There&apos;s nothing here</div>
		return (
			<div className={grids.container}>
				{!removeInfiniteScroll && (
					<InfiniteScroll
						loadMore={this.handleInfiniteLoad}
						hasMore={relay.hasMore()}
						loader={
							<div key="cvcl-loading" className={grids.loadMore}>
								<LoadingDots />
							</div>
						}
						pageStart={0}
					>
						<div className={grids.rowMax4}>
							{this.getListItems(items.edges)}
						</div>
					</InfiniteScroll>
				)}
				{removeInfiniteScroll && (
					<QueryLoadMore
						data={items.edges}
						hasMore={relay.hasMore()}
						loadMore={this.handleLoadMore}
					>
						{(data, itemCount) => (
							<>
								<div
									style={{
										"--grid-column-count": itemCount,
									}}
									className={grids.rowQueryLoad}
								>
									{this.getListItems(data)}
								</div>
								{relay.hasMore() && (
									<div className={grids.loadMore}>
										<button
											type="button"
											className={grids.loadMoreBtn}
											disabled={loading}
											onClick={() =>
												this.handleLoadMore(itemCount * 2)
											}
										>
											{loading ? (
												<LoadingDots color="var(--gray300)" />
											) : (
												"Show more"
											)}
										</button>
									</div>
								)}
							</>
						)}
					</QueryLoadMore>
				)}
			</div>
		)
	}
}

const query = graphql`
	query sharedFolderCollectionsListQuery(
		$count: Int!
		$cursor: String
		$folder: String!
		$sort: String
		$username: String!
	) {
		store {
			...sharedFolderCollectionsList_store
				@arguments(
					count: $count
					cursor: $cursor
					folder: $folder
					sort: $sort
					username: $username
				)
		}
	}
`

SharedFolderCollectionList = createPaginationContainer(
	SharedFolderCollectionList,
	{
		store: graphql`
			fragment sharedFolderCollectionsList_store on Store
			@argumentDefinitions(
				count: { type: "Int", defaultValue: 12 }
				cursor: { type: "String" }
				folder: { type: "String!" }
				sort: { type: "String" }
				username: { type: "String!" }
			) {
				id
				profile(username: $username) {
					id
					sharedFolderCollectionsList(
						folderId: $folder
						first: $count
						after: $cursor
						sort: $sort
					) @connection(key: "profile_sharedFolderCollectionsList") {
						edges {
							node {
								...productCollectionCard_productCollection
							}
						}
					}
				}
			}
		`,
	},
	{
		direction: "forward",
		getConnectionFromProps(props) {
			const { profile } = props.store
			return profile && profile.sharedFolderCollectionsList
		},
		getFragmentVariables(prevVars, totalCount) {
			return {
				...prevVars,
				count: totalCount,
			}
		},
		getVariables(props, { count, cursor }) {
			return {
				count,
				cursor,
				folder: props.folder,
				sort: props.sort,
				username: props.username,
			}
		},
		query,
	}
)

export default {
	Component: SharedFolderCollectionList,
	query,
	params: {
		count: 12,
		cursor: null,
		folder: null,
		sort: null,
		username: null,
	},
}
