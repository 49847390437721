import { commitMutation, graphql } from "react-relay"
import { complete } from "../helper.js"

const mutation = graphql`
	mutation saveShopcastReportAdminNotesMutation(
		$input: SaveShopcastReportAdminNotesInput!
	) {
		saveShopcastReportAdminNotes(input: $input) {
			report {
				id
			}
		}
	}
`

const getConfigs = (reportId) => [
	{
		type: "FIELDS_CHANGE",
		fieldIDs: { report: reportId },
	},
]

const commit = (env, reportId, note, onCompleted, onError) =>
	commitMutation(env, {
		mutation,
		variables: { input: { reportId, note } },
		configs: getConfigs(reportId),
		onCompleted: complete(onCompleted, onError),
		onError: (e) => complete(null, [e]),
	})

export default { commit }
