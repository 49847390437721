import React, { Component } from "react"
import { Link } from "react-router-dom"
import Logo from "shared/assets/logo.svg"
import InstagramIcon from "shared/assets/instagram-icon.svg"
import FacebookIcon from "shared/assets/facebook-icon.svg"
// import YoutubeIcon from "shared/assets/youtube-icon.svg"
// import TwitterIcon from "shared/assets/twitter-icon.svg"
import SocialLink from "components/social-link/social-link"
import styles from "./footer-website.css"

class FooterWebsite extends Component {
	getCurrentYear() {
		return new Date().getFullYear()
	}

	render() {
		// const { userType } = this.props
		return (
			<footer className={styles.root}>
				<div className={styles.footer}>
					<div className={styles.content}>
						<div className={styles.details}>
							<Link to="/">
								<Logo className={styles.logo} />
							</Link>
							<p className={styles.valueprop}>
								Virtual Styling Platform
							</p>
						</div>
						{/*	<div className={styles.list}>
							<h5 className={styles.heading}>Use Cases</h5>
							<ul className={styles.nav}>
								<li className={styles.navItem}>
									<Link
										className={styles.link}
										to="/for-virtual-styling"
									>
										Virtual Styling
									</Link>
								</li>
								<li className={styles.navItem}>
									<Link
										className={styles.link}
										to="/for-content-marketing"
									>
										Content Marketing
									</Link>
								</li>
								<li className={styles.navItem}>
									<Link
										className={styles.link}
										to="/for-network-marketers"
									>
										Network Marketers
									</Link>
								</li>
								<li className={styles.navItem}>
									<Link className={styles.link} to="/for-shopify">
										Shopify
									</Link>
								</li>
							</ul>
						</div> */}
						{/*	<div className={styles.list}>
							<h5 className={styles.heading}>Product</h5>
							<ul className={styles.nav}>
								<li className={styles.navItem}>
									<Link className={styles.link} to="/shoppable-videos">
										Shoppable Videos
									</Link>
								</li>
								<li className={styles.navItem}>
									<Link className={styles.link} to="/moodboards">
										Moodboards
									</Link>
								</li>
								<li className={styles.navItem}>
									<Link className={styles.link} to="/pricing">
										Pricing
									</Link>
								</li>
							</ul>
						</div> */}

						<div className={styles.list}>
							<h5 className={styles.heading}>Company</h5>
							<ul className={styles.nav}>
								<li className={styles.navItem}>
									<Link className={styles.link} to="/about">
										About
									</Link>
								</li>
								{/* <li className={styles.navItem}>
									<Link className={styles.link} to="/faqs">
										FAQs
									</Link>
								</li> 
								<li className={styles.navItem}>
									<a
										className={styles.link}
										href="https://blog.shopshare.tv/blog"
									>
										Blog
									</a>
								</li> */}
								{/* {(!userType || userType !== "CLIENT") && (
									<li className={styles.navItem}>
										<Link className={styles.link} to="/pricing">
											Pricing
										</Link>
									</li>
								)} */}
								<li className={styles.navItem}>
									<a
										className={styles.link}
										href="mailto:emma@shopshare.tv?subject=ShopShare enquiry"
									>
										Contact
									</a>
								</li>
							</ul>
						</div>
					</div>
					<hr className={styles.hr} />
					<div className={styles.meta}>
						<div className={styles.legal}>
							<div className={styles.copyright}>
								&copy;
								{this.getCurrentYear()}
								&nbsp;ShopShareTV Pty Ltd.
							</div>
							<Link className={styles.legalLink} to="/privacy">
								Privacy Policy
							</Link>
							<Link className={styles.legalLink} to="/terms">
								Terms of Use
							</Link>
						</div>
						<div className={styles.social}>
							<SocialLink
								url="https://www.instagram.com/shopshare.tv"
								ImageComponent={InstagramIcon}
								title="Instagram"
							/>
							<SocialLink
								url="https://www.facebook.com/shopshare.tv"
								ImageComponent={FacebookIcon}
								title="Facebook"
							/>
						</div>
					</div>
				</div>
			</footer>
		)
	}
}

export default FooterWebsite
