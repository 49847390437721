/**
 * @generated SignedSource<<46f668e92c57c834dcaab6139bddb8c9>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { lookbookCard_currentUser$fragmentType } from "./../../cards/lookbook-card/__generated__/lookbookCard_currentUser.graphql";
import type { lookbookCard_lookbook$fragmentType } from "./../../cards/lookbook-card/__generated__/lookbookCard_lookbook.graphql";
import type { shopcastCard_currentUser$fragmentType } from "./../../cards/shopcast-card/__generated__/shopcastCard_currentUser.graphql";
import type { shopcastCard_shopcast$fragmentType } from "./../../cards/shopcast-card/__generated__/shopcastCard_shopcast.graphql";
import type { FragmentType } from "relay-runtime";
declare export opaque type itemReferences_store$fragmentType: FragmentType;
export type itemReferences_store$data = {|
  +collectionItem: ?({|
    +__typename: "ShopcastProduct",
    +affiliated_url: ?string,
    +id: string,
    +imageUrl: ?string,
    +lookbooks: ?$ReadOnlyArray<?{|
      +$fragmentSpreads: lookbookCard_lookbook$fragmentType,
    |}>,
    +offerPrice: ?string,
    +regularPrice: ?string,
    +shopcastItems: ?$ReadOnlyArray<?{|
      +shopcast: ?{|
        +$fragmentSpreads: shopcastCard_shopcast$fragmentType,
      |},
    |}>,
    +title: ?string,
    +url: ?string,
  |} | {|
    +__typename: "lookbookUploads",
    +id: string,
    +lookbooks: ?$ReadOnlyArray<?{|
      +$fragmentSpreads: lookbookCard_lookbook$fragmentType,
    |}>,
    +title: ?string,
    +url: ?string,
  |} | {|
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    +__typename: "%other",
  |}),
  +currentUser: ?{|
    +$fragmentSpreads: lookbookCard_currentUser$fragmentType & shopcastCard_currentUser$fragmentType,
  |},
  +id: string,
  +$fragmentType: itemReferences_store$fragmentType,
|};
export type itemReferences_store$key = {
  +$data?: itemReferences_store$data,
  +$fragmentSpreads: itemReferences_store$fragmentType,
  ...
};
*/

var node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "url",
  "storageKey": null
},
v3 = [
  {
    "kind": "Variable",
    "name": "folderId",
    "variableName": "folderId"
  }
],
v4 = {
  "alias": null,
  "args": (v3/*: any*/),
  "concreteType": "Lookbook",
  "kind": "LinkedField",
  "name": "lookbooks",
  "plural": true,
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "lookbookCard_lookbook"
    }
  ],
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "folderId"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "id"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "itemReferences_store",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "currentUser",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "shopcastCard_currentUser"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "lookbookCard_currentUser"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "id",
          "variableName": "id"
        }
      ],
      "concreteType": null,
      "kind": "LinkedField",
      "name": "collectionItem",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "__typename",
          "storageKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            (v0/*: any*/),
            (v1/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "imageUrl",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "regularPrice",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "offerPrice",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "affiliated_url",
              "storageKey": null
            },
            (v2/*: any*/),
            (v4/*: any*/),
            {
              "alias": null,
              "args": (v3/*: any*/),
              "concreteType": "shopcastItem",
              "kind": "LinkedField",
              "name": "shopcastItems",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Shopcast",
                  "kind": "LinkedField",
                  "name": "shopcast",
                  "plural": false,
                  "selections": [
                    {
                      "args": null,
                      "kind": "FragmentSpread",
                      "name": "shopcastCard_shopcast"
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "type": "ShopcastProduct",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            (v0/*: any*/),
            (v1/*: any*/),
            (v2/*: any*/),
            (v4/*: any*/)
          ],
          "type": "lookbookUploads",
          "abstractKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Store",
  "abstractKey": null
};
})();

(node/*: any*/).hash = "295bf8723ceb325ce196d67baf22422d";

module.exports = ((node/*: any*/)/*: Fragment<
  itemReferences_store$fragmentType,
  itemReferences_store$data,
>*/);
