/**
 * @generated SignedSource<<364ea764580f26ebabdc717711530d58>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Mutation } from 'relay-runtime';
export type UpdateLookbookDetailsInput = {|
  clientMutationId?: ?string,
  description?: ?string,
  id?: ?string,
  title: string,
|};
export type updateLookbookDetailsMutation$variables = {|
  input: UpdateLookbookDetailsInput,
|};
export type updateLookbookDetailsMutation$data = {|
  +updateLookbookDetails: ?{|
    +lookbook: ?{|
      +description: ?string,
      +id: string,
      +title: ?string,
    |},
  |},
|};
export type updateLookbookDetailsMutation = {|
  response: updateLookbookDetailsMutation$data,
  variables: updateLookbookDetailsMutation$variables,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UpdateLookbookDetailsPayload",
    "kind": "LinkedField",
    "name": "updateLookbookDetails",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Lookbook",
        "kind": "LinkedField",
        "name": "lookbook",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "title",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "description",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "updateLookbookDetailsMutation",
    "selections": (v1/*: any*/),
    "type": "ShopshareMutationRoot",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "updateLookbookDetailsMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "80912d72e069c6929489cb20b937b150",
    "id": null,
    "metadata": {},
    "name": "updateLookbookDetailsMutation",
    "operationKind": "mutation",
    "text": "mutation updateLookbookDetailsMutation(\n  $input: UpdateLookbookDetailsInput!\n) {\n  updateLookbookDetails(input: $input) {\n    lookbook {\n      id\n      title\n      description\n    }\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "e05fdc8554027008c817ade735fb7ae1";

module.exports = ((node/*: any*/)/*: Mutation<
  updateLookbookDetailsMutation$variables,
  updateLookbookDetailsMutation$data,
>*/);
