/**
 * @generated SignedSource<<8c27bf28034946df5834ba5fac682902>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { Fragment, ReaderFragment } from 'relay-runtime';
export type CampaignCommisionType = "FIXED" | "PERCENTAGE" | "%future added value";
import type { FragmentType } from "relay-runtime";
declare export opaque type adminReferralViewCampaign_store$fragmentType: FragmentType;
export type adminReferralViewCampaign_store$data = {|
  +admin: ?{|
    +campaign: ?{|
      +annualCommision: ?number,
      +conversions: ?number,
      +createdAt: ?string,
      +id: string,
      +isArchived: ?boolean,
      +leads: ?number,
      +monthlyCommision: ?number,
      +name: ?string,
      +referrals: ?$ReadOnlyArray<?{|
        +conversions: ?number,
        +leads: ?number,
        +paidCommision: ?number,
        +revenue: ?number,
        +user: ?{|
          +fullname: ?string,
          +id: string,
          +profilePhoto: ?string,
        |},
        +visitors: ?number,
      |}>,
      +totalCommision: ?number,
      +totalRevenue: ?number,
      +type: ?CampaignCommisionType,
      +visitors: ?number,
    |},
  |},
  +id: string,
  +$fragmentType: adminReferralViewCampaign_store$fragmentType,
|};
export type adminReferralViewCampaign_store$key = {
  +$data?: adminReferralViewCampaign_store$data,
  +$fragmentSpreads: adminReferralViewCampaign_store$fragmentType,
  ...
};
*/

var node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "visitors",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "leads",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "conversions",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "id"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "adminReferralViewCampaign_store",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Admin",
      "kind": "LinkedField",
      "name": "admin",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": [
            {
              "kind": "Variable",
              "name": "id",
              "variableName": "id"
            }
          ],
          "concreteType": "Campaign",
          "kind": "LinkedField",
          "name": "campaign",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "name",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "monthlyCommision",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "annualCommision",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "type",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "createdAt",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "isArchived",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "totalRevenue",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "totalCommision",
              "storageKey": null
            },
            (v1/*: any*/),
            (v2/*: any*/),
            (v3/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "Referral",
              "kind": "LinkedField",
              "name": "referrals",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "User",
                  "kind": "LinkedField",
                  "name": "user",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "fullname",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "profilePhoto",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                (v1/*: any*/),
                (v2/*: any*/),
                (v3/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "revenue",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "paidCommision",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Store",
  "abstractKey": null
};
})();

(node/*: any*/).hash = "6cfe72660d8c6e606fec53006d6d9f82";

module.exports = ((node/*: any*/)/*: Fragment<
  adminReferralViewCampaign_store$fragmentType,
  adminReferralViewCampaign_store$data,
>*/);
