import React, { Component } from "react"
import InfiniteScroll from "react-infinite-scroller"
import grids from "shared/styles/components/grids.css"
import empty from "shared/styles/components/empty.css"
import ShopcastCard from "components/cards/shopcast-card/shopcast-card.jsx"
import LookbookCard from "components/cards/lookbook-card/lookbook-card.jsx"
import ReportShopcastModal from "components/modals/report-shopcast/report-shopcast.jsx"
import LoadingDots from "components/loading-dots/loading-dots.jsx"

export default class ListOfAll extends Component {
	constructor(props) {
		super(props)
		this.showReportModal = this.showReportModal.bind(this)
		this.handleLoadMore = this.handleLoadMore.bind(this)

		this.state = {
			currentItem: null,
			showReportModal: false,
			// loading: false,
		}
	}

	handleLoadMore() {
		const { relay } = this.props
		relay.loadMore(12, () => {})
	}

	showReportModal(show, item) {
		this.setState({ showReportModal: show, currentItem: item })
	}

	renderItems(items) {
		const { currentUser, enableHeader, relay } = this.props
		if (!items || items.edges.length === 0) {
			return (
				<div className={grids.span}>
					<div className={empty.simple}>
						No one has published any shopcasts or shopboards (yet!)
					</div>
				</div>
			)
		}

		return (
			<InfiniteScroll
				loadMore={this.handleLoadMore}
				hasMore={relay.hasMore()}
				loader={
					<div className={grids.loadMore} key="loading">
						<LoadingDots />
					</div>
				}
				pageStart={0}
			>
				<div
					className={grids.rowMax4}
				>
					{items.edges.map((edge) =>
						edge.node.__typename === "Shopcast" ? (
							<div className={grids.col3} key={edge.node.id}>
								<ShopcastCard
									shopcast={edge.node}
									showActions={false}
									currentUser={currentUser}
									showReportModal={this.showReportModal}
									enableHeader={enableHeader}
									refetch={relay.refetchConnection}
								/>
							</div>
						) : (
							<div className={grids.col3} key={edge.node.id}>
								<LookbookCard
									lookbook={edge.node}
									showActions={false}
									currentUser={currentUser}
									enableHeader={enableHeader}
									showReportModal={this.showReportModal}
									refetch={relay.refetchConnection}
								/>
							</div>
						)
					)}
				</div>
			</InfiniteScroll>
		)
	}

	renderContent() {
		const { props } = this
		const { items } = props
		return <div className={grids.container}>{this.renderItems(items)}</div>
	}

	render() {
		const { state, props } = this
		return (
			<div>
				{this.renderContent()}
				<ReportShopcastModal
					item={state.currentItem}
					closeModal={() => {
						this.setState({ showReportModal: false })
					}}
					showEmbedModal={state.showReportModal}
					relay={props.relay}
					storeId={props.storeId}
				/>
			</div>
		)
	}
}
