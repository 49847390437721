import React, { useState } from "react"
import { Transition } from "react-transition-group"
import { Pencil1Icon, TrashIcon } from "@radix-ui/react-icons"
import MenuIcon from "shared/assets/menu-dots-horizontal.svg"
import { withDrag } from "helpers/dnd-helper"
import { CategoryENUM } from "helpers/enum-helper"
import stylesDropdown from "components/dropdown-menu/dropdown-menu-dark.css"
import button from "shared/styles/components/buttons.css"
import DropdownMenu from "components/dropdown-menu/dropdown-menu"
import FavouriteButton from "components/buttons/favourite-button"
import PlusButton from "components/buttons/plus-button"
import styles from "./shape-card.css"

function ShapeCardInner(props) {
	const [isShown, setIsShown] = useState(false)
	const {
		editImage,
		deleteImage,
		drop,
		showAddButton,
		showFavouriteButton,
		clickFavourite,
		dragRef,
		node,
		color,
	} = props
	const { canDelete, canEdit, isFavourite } = node

	const renderMenu = () => {
		const listItems = []
		const push = (click, name, key, icon) =>
			listItems.push({
				click,
				name,
				key,
				icon,
			})
		const icon = <MenuIcon className={stylesDropdown.icon} />

		if (editImage) {
			push(
				() => editImage(node),
				"Edit",
				"edit",
				<Pencil1Icon className={stylesDropdown.itemIcon} />
			)
		}
		if (canDelete && deleteImage) {
			listItems.push("divide")
			push(
				() => deleteImage(node),
				"Delete",
				"delete",
				<TrashIcon className={stylesDropdown.itemIcon} />
			)
		}
		return listItems.length ? (
			<DropdownMenu
				items={listItems}
				mainIcon={icon}
				darkTheme
				contentRight
				triggerClass={button.lightIcon}
				triggerClassActive={button.lightIconActive}
			/>
		) : null
	}

	return (
		<div
			className={styles.root}
			onMouseEnter={() => setIsShown(true)}
			onMouseLeave={() => setIsShown(false)}
		>
			<div className={styles.media}>
				<Transition in={isShown && showAddButton} timeout={200}>
					{(state) => (
						<div className={`${styles.addAction} ${styles[state]}`}>
							<PlusButton onClick={() => drop(node.id)} />
						</div>
					)}
				</Transition>
				<Transition in={isShown && canEdit} timeout={200}>
					{(state) => (
						<div className={`${styles.menu} ${styles[state]}`}>
							{renderMenu()}
						</div>
					)}
				</Transition>
				<div className={styles.figureImage}>
					<div className={styles.inner} ref={dragRef}>
						<svg
							className={styles.svg}
							id={node.id}
							viewBox={node.viewbox}
							preserveAspectRatio="xMidYMid meet"
							data-itemid={node.id}
							data-fill={color}
						>
							{node.path.map((pathData, i) => (
								<path
									// eslint-disable-next-line react/no-array-index-key
									key={i}
									fillRule="evenodd"
									clipRule="evenodd"
									fill={color}
									d={pathData}
								/>
							))}
						</svg>
					</div>
				</div>
			</div>
			<div className={styles.content}>
				<div className={styles.titleWithIcons}>
					{/* <h4 className={styles.title}>Category Name</h4> */}
					{showFavouriteButton && (
						<div className={styles.favButton}>
							<FavouriteButton
								isFavourite={isFavourite}
								onClick={clickFavourite}
							/>
						</div>
					)}
				</div>
			</div>
		</div>
	)
}

function ShapeCard({ ...otherProps }) {
	const Target = withDrag(ShapeCardInner, ({ node }) => ({
		type: CategoryENUM.SHAPE,
		item: {
			id: node.id,
			type: CategoryENUM.SHAPE,
		},
		collect: (monitor) => ({ isDragging: monitor.isDragging() }),
	}))
	return <Target {...otherProps} />
}

export default ShapeCard
