/**
 * @generated SignedSource<<c26ed95436ff22c17aacfa5a2790590f>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Query } from 'relay-runtime';
import type { retailers_store$fragmentType } from "./retailers_store.graphql";
export type retailersQuery$variables = {||};
export type retailersQuery$data = {|
  +store: ?{|
    +$fragmentSpreads: retailers_store$fragmentType,
  |},
|};
export type retailersQuery = {|
  response: retailersQuery$data,
  variables: retailersQuery$variables,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "retailersQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Store",
        "kind": "LinkedField",
        "name": "store",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "retailers_store"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "ShopshareQueryRoot",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "retailersQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Store",
        "kind": "LinkedField",
        "name": "store",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": [
              {
                "kind": "Literal",
                "name": "isRetailer",
                "value": true
              },
              {
                "kind": "Literal",
                "name": "orderBy",
                "value": "shopcastCount"
              }
            ],
            "concreteType": "StoreCategoriesConnectionConnection",
            "kind": "LinkedField",
            "name": "bestCategories",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "StoreCategoriesConnectionEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Category",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "name",
                        "storageKey": null
                      },
                      (v0/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "count",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": "bestCategories(isRetailer:true,orderBy:\"shopcastCount\")"
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "RetailerConnection",
            "kind": "LinkedField",
            "name": "recommendedRetailers",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "RetailerEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v0/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "f444023052f19603426b8c1d6623f9c1",
    "id": null,
    "metadata": {},
    "name": "retailersQuery",
    "operationKind": "query",
    "text": "query retailersQuery {\n  store {\n    ...retailers_store\n    id\n  }\n}\n\nfragment retailers_store on Store {\n  id\n  bestCategories(orderBy: \"shopcastCount\", isRetailer: true) {\n    edges {\n      node {\n        name\n        id\n        count\n      }\n    }\n  }\n  recommendedRetailers {\n    edges {\n      node {\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "1239c585d0060bebddc6ea9081a6ae20";

module.exports = ((node/*: any*/)/*: Query<
  retailersQuery$variables,
  retailersQuery$data,
>*/);
