import React from "react"
import input from "shared/styles/components/input.css"
import SetCollectionsForImage from "mutations/product-collection/set-collections-for-image"
import SetCollectionsForProduct from "mutations/product-collection/set-collections-for-product"
import { toast } from "react-toastify"
import { CategoryENUM } from "helpers/enum-helper"
import { withDrop } from "helpers/dnd-helper"
import ClosetIcon from "shared/assets/closet-icon.svg"
import CircleIcon from "shared/assets/circle-icon.svg"
import styles from "./category-select.css"
import { ShowCardControlsComponent } from "../../helpers/ui-helper"

const collectionSelectTarget = {
	drop(props, monitor) {
		const { store, relay, collection } = props
		const { id, type, collections } = monitor.getItem()

		const list = collections.map((i) => ({ id: i.id, value: i.title }))
		const moveTo = collections.find((i) => i.id === collection.id)
		if (!moveTo) list.push({ id: collection.id, value: collection.title })
		if (type === "ShopcastProduct")
			SetCollectionsForProduct.commit(
				relay.environment,
				[
					{
						id,
						value: list.map((i) => ({
							...i,
							id: i.id.includes("new_") ? null : i.id,
						})),
					},
				],
				store.currentUser.id,
				() => {},
				() => {
					toast.success(<>Added to {collection.title}</>, {
						autoClose: 2000,
						closeButton: false,
					})
				}
			)
		if (type === "lookbookUploads")
			SetCollectionsForImage.commit(
				relay.environment,
				[
					{
						id,
						value: list.map((i) => ({
							...i,
							id: i.id.includes("new_") ? null : i.id,
						})),
					},
				],
				store.currentUser.id,
				() => {},
				() => {
					toast.success(<>Added to {collection.title}</>, {
						autoClose: 2000,
						closeButton: false,
					})
				}
			)
	},
}

function CollectionSelectItem({
	dropRef,
	isOver,
	collection,
	selectedCollections,
	setCollections,
	renderMenu,
	owned,
	showClosetIcon,
}) {
	return (
		<ShowCardControlsComponent
			className={isOver ? styles.itemHover : styles.item}
			innerRef={dropRef}
		>
			{(isShown) => (
				<>
					<label className={input.label}>
						<input
							type="checkbox"
							className={
								collection.isACloset
									? `${input.checkbox} ${input.closet}`
									: input.checkbox
							}
							checked={
								!!selectedCollections.find(
									(i) => i.id === collection.id
								)
							}
							onChange={(e) => {
								setCollections(
									e.target.checked
										? [...selectedCollections, collection]
										: selectedCollections.filter(
												(i) => i.id !== collection.id
										  )
								)
							}}
						/>
						<div className={input.labelInner}>
							<span className={input.labelTitle}>
								{collection.title}
							</span>
							{collection.isACloset && showClosetIcon && (
								<span
									className={input.labelIcon}
									data-tooltip-id="tooltip"
									data-tooltip-content="Closet"
								>
									<ClosetIcon className={styles.icon} />
								</span>
							)}
							{collection.isPublished && (
								<span
									className={input.labelIcon}
									data-tooltip-id="tooltip"
									data-tooltip-content="Published"
								>
									<CircleIcon className={styles.iconPublished} />
								</span>
							)}
						</div>
					</label>
					{isShown && owned && renderMenu(collection)}
				</>
			)}
		</ShowCardControlsComponent>
	)
}

export default withDrop(CollectionSelectItem, (props) => ({
	accept: [CategoryENUM.IMAGE],
	drop: (_, monitor) => collectionSelectTarget.drop(props, monitor),
	collect: (monitor) => ({
		isOver: monitor.isOver(),
	}),
}))
