import React, { Component, Fragment } from "react"
import InfiniteScroll from "react-infinite-scroller"
import { createPaginationContainer, graphql } from "react-relay"
import { Pencil1Icon, TrashIcon, PlusIcon } from "@radix-ui/react-icons"
import { toast } from "react-toastify"
import deleteLookbookUploadMutation from "mutations/lookbook/delete-lookbook-upload"
import DeleteProductItemMutation from "mutations/product-collection/delete-product-collection-item"
import DeleteProductCollectionMutation from "mutations/product-collection/delete-product-collection"
import ImageCard from "components/cards/lookbook/image-card"
import LoadingDots from "components/loading-dots/loading-dots"
import ImageUpload from "components/modals/image-upload/image-upload"
import ConformationPrompt from "components/modals/conformation-prompt/conformation-prompt"
import DropdownMenu from "components/dropdown-menu/dropdown-menu"
import LinkScraper from "components/modals/link-scraper/link-scraper"
import UploadSelect from "components/modals/upload-select/upload-select"
import ProductEditModal from "components/modals/product-edit/product-edit"
import { LookbookConsumer } from "routes/lookbook/lookbook"
import gql from "helpers/gql-helper"
import { clickFavourite } from "helpers/lookbook-helper"
import FolderIcon from "shared/assets/folder-icon.svg"
import MenuIcon from "shared/assets/menu-dots-horizontal.svg"
import grids from "shared/styles/components/grids.css"
import textfield from "shared/styles/components/textfield.css"
import lookbookAside from "shared/styles/layout/lookbook-aside.css"
import button from "shared/styles/components/buttons.css"
import stylesDropdown from "components/dropdown-menu/dropdown-menu-dark.css"
import empty from "shared/styles/components/empty.css"
import { getComponent } from "../../route-helpers"
import ImageListCollectionSelect from "./image-list-collection-select"
import { getCollectionTitle } from "../../helpers/string-helper"
import { getChipClass } from "../../helpers/ui-helper"

class ImageList extends Component {
	constructor(props) {
		super(props)
		this.state = {
			loading: false,
			openModal: null,
			selected: null,
			productToEdit: null,
			itemToDelete: null,
			limitType: "",
		}

		this.closeModal = this.closeModal.bind(this)
		this.deleteUpload = this.deleteUpload.bind(this)
		this.deleteProductItem = this.deleteProductItem.bind(this)
		this.messageListner = this.messageListner.bind(this)
	}

	componentDidMount() {
		window.addEventListener("message", this.messageListner, true)
	}

	componentWillUnmount() {
		window.removeEventListener("message", this.messageListner, true)
	}

	getLimitType(csf) {
		switch (csf) {
			case "collections":
				return ["collections"]
			case "closet":
				return ["closet"]
			default:
				return ["collections", "closet"]
		}
	}

	messageListner(ev) {
		const { store, relay, collectionId } = this.props
		if (
			ev.data.tag === "refetchCollections" &&
			(!collectionId || ev.data.param.includes(collectionId))
		) {
			const imageList = store.currentUser?.productCollectionSearchItems?.data
				? store.currentUser.productCollectionSearchItems.data.edges.map(
						(i) => i.node
				  )
				: []
			setTimeout(() => {
				relay.refetchConnection(imageList.length)
			}, 500)
		}
	}

	closeModal() {
		this.setState({ loading: false, selected: null, itemToDelete: null })
	}

	async deleteUpload(collectionId, uploadId) {
		this.setState({ loading: true })
		const { relay, store } = this.props
		const imageList =
			store.currentUser.productCollectionSearchItems.data.edges || []
		await new Promise((resolve, reject) => {
			deleteLookbookUploadMutation.commit(
				relay.environment,
				{ collectionId, uploadId },
				(result) => {
					toast.success(<>Successfully Deleted!</>, {
						autoClose: 5000,
						closeButton: false,
					})
					resolve(result)
				},
				(e) => {
					toast.info(<>{gql.getError(e)}</>, {
						autoClose: 5000,
						closeButton: false,
					})
					reject(e)
				}
			)
		})
		relay.refetchConnection(imageList.length - 1)
		this.closeModal()
	}

	deleteProductItem(productId) {
		const { limitType } = this.state
		const { relay, collections, search, sort } = this.props
		this.setState({ loading: true })

		DeleteProductItemMutation.commit(
			relay.environment,
			null,
			productId,
			() => {},
			() => {
				this.closeModal()
				toast.success(<>Successfully Deleted!</>, {
					autoClose: 2000,
					closeButton: false,
				})
			},
			{
				search,
				categories: [],
				brands: [],
				collections,
				sort,
				limitType: this.getLimitType(limitType),
			}
		)
	}

	deleteCollection(collectionId) {
		const { relay, onChange, team } = this.props
		this.setState({ loading: true })
		DeleteProductCollectionMutation.commit(
			relay.environment,
			collectionId,
			() => {},
			() => {
				toast.success(<>Collection deleted successfully!</>, {
					autoClose: 2000,
					closeButton: false,
				})
				this.closeModal()
				onChange({ target: { value: "" } })
			},
			{
				search: "",
				categories: [],
				brands: [],
				collections: [],
				sort: "n-to-o",
				clientIds: [],
				limitType: ["collections", "closet"],
				resultTypes: ["collections"],
				teamId: team,
				collectionSearch: "",
			}
		)
	}

	renderMenu() {
		const { store, collectionId } = this.props
		const collectionList =
			store?.currentUser?.productCollections.edges.map((i) => i.node) || []
		const mainIcon = <MenuIcon className={stylesDropdown.icon} />
		return (
			<LookbookConsumer>
				{({ editCollection }) => {
					const listItems = []
					const push = (click, name, key, icon) =>
						listItems.push({
							click,
							name,
							key,
							icon,
						})
					if (collectionId) {
						push(
							() => {
								this.setState({ openModal: "upload" })
							},
							"Add Image",
							"add",
							<PlusIcon className={`${stylesDropdown.itemIcon}`} />
						)
						listItems.push("divide")
					}
					push(
						() => {
							editCollection({ id: null })
						},
						"New Collection",
						"new",
						<FolderIcon className={`${stylesDropdown.itemIcon}`} />
					)

					const collection =
						collectionId &&
						collectionList.find((x) => x.id === collectionId)
					if (collection && !collection.isDefault) {
						push(
							() => {
								editCollection(collection)
							},
							"Edit",
							"edit",
							<Pencil1Icon className={`${stylesDropdown.itemIcon}`} />
						)
						listItems.push("divide")
						push(
							() => {
								this.setState({
									itemToDelete: {
										type: "collection",
										collectionId: collection.id,
										title: collection.title,
									},
								})
							},
							"Delete forever",
							"delete",
							<TrashIcon className={`${stylesDropdown.itemIcon}`} />
						)
					}
					return (
						<DropdownMenu
							mainIcon={mainIcon}
							items={listItems}
							contentRight
							darkTheme
							triggerClass={button.dropdownIconLg}
							triggerClassActive={button.dropdownIconActive}
						/>
					)
				}}
			</LookbookConsumer>
		)
	}

	renderCollectionDropdown(imageList) {
		const { limitType } = this.state
		const { collectionId, onChange, store, clients, search, team } =
			this.props
		const hasfavourites =
			(store?.currentUser.lookbookFavourites
				? store.currentUser.lookbookFavourites.edges
				: []
			).map((obj) => obj.node).length > 0 ||
			imageList.filter((obj) => obj.isFavourite === true).length > 0
		const ImageListCollectionSelectComp = getComponent(
			`image-list-collection-select`,
			ImageListCollectionSelect,
			{
				search,
				clients,
				limitType: this.getLimitType(limitType),
				teamId: team,
			},
			{
				search,
				clients,
				hasfavourites,
				collectionId,
				onChange,
				limitType: this.getLimitType(limitType),
				teamId: team,
			}
		)
		return ImageListCollectionSelectComp
	}

	renderList(imageList) {
		const { relay, category, drop, store, team } = this.props
		if (!imageList || imageList.length === 0)
			return <div className={empty.simple}>No results found.</div>
		return (
			<InfiniteScroll
				loadMore={() => relay.loadMore(12, () => {})}
				hasMore={relay ? relay.hasMore() : false}
				loader={<LoadingDots key={0} />}
				pageStart={0}
				useWindow={false}
				getScrollParent={() => document.getElementById("scroll-div")}
			>
				<div className={lookbookAside.content}>
					<div className={grids.image}>
						{imageList.map((x) => (
							<Fragment key={x.id}>
								<ImageCard
									node={x}
									isDraggable
									price={x.offerPrice || x.regularPrice}
									showAddButton
									drop={drop}
									description={x.title}
									canEdit
									editImage={() => {
										if (x.__typename === "ShopcastProduct")
											this.setState({ productToEdit: x })
										if (x.__typename === "lookbookUploads")
											this.setState({
												selected: x,
												openModal: "upload",
											})
									}}
									deleteImage={
										x.productCollections &&
										x.productCollections.length > 0 &&
										x.productCollections[0].id
											? () => {
													this.setState({
														itemToDelete: {
															type:
																x.__typename ===
																"ShopcastProduct"
																	? "product"
																	: "image",
															collectionId:
																x.productCollections[0].id,
															id: x.id,
															title: x.title,
														},
													})
											  }
											: null
									}
									isFavourite={x.isFavourite}
									showFavouriteButton
									clickFavourite={() =>
										clickFavourite(x.id, x.__typename, team, relay)
									}
									category={category}
									contentEditable
									relay={relay}
									store={store}
									showDownloadButtons
								/>
							</Fragment>
						))}
					</div>
				</div>
			</InfiniteScroll>
		)
	}

	renderPanels() {
		const { loading, openModal, selected, productToEdit, itemToDelete } =
			this.state
		const { accepted, relay, collectionId, store, team } = this.props
		// const adminAccess = this.props.store.currentUser.adminAccess === "HAS" || false
		const imageList = store.currentUser?.productCollectionSearchItems?.data
			? store.currentUser.productCollectionSearchItems.data.edges.map(
					(i) => i.node
			  )
			: []
		const collectionList = store.currentUser?.productCollectionSearchItems
			?.data
			? store.currentUser.productCollections.edges.map((i) => i.node)
			: []
		return (
			<>
				{this.renderList(imageList)}
				<UploadSelect
					openModal={openModal === "upload-select"}
					onImageUploadClick={() => {
						this.setState({ openModal: "upload" })
					}}
					onClipsClick={() => {
						this.setState({ openModal: "link-scraper" })
					}}
					closeModal={() => {
						this.setState({ openModal: null })
					}}
				/>
				<LinkScraper
					openModal={openModal === "link-scraper"}
					collectionIds={collectionId ? [collectionId] : null}
					closeModal={() => {
						this.setState({ openModal: null })
					}}
					onSave={() => {
						relay.refetchConnection(imageList.length + 1)
						this.setState({ openModal: null })
					}}
					relay={relay}
				/>
				<ImageUpload
					subCategoryList={collectionList}
					adminAccess={false}
					openModal={openModal === "upload"}
					relay={relay}
					accepted={accepted}
					selected={selected}
					currentCollections={collectionList
						.filter((i) => i.id === collectionId)
						.map((obj) => ({
							id: obj.id,
							value: getCollectionTitle(obj),
							className: getChipClass(obj).chip,
						}))}
					closeModal={(count) => {
						if (count !== false)
							relay.refetchConnection(imageList.length + count)
						this.setState({ openModal: null, selected: null })
					}}
					team={team}
				/>
				<ProductEditModal
					store={store}
					product={productToEdit}
					showProductEditModal={!!productToEdit}
					closeProductEditModal={() => {
						this.setState({ productToEdit: null })
					}}
					relay={relay}
					enableSavingWithoutCollection={false}
					collectionsKeyValue={collectionList.map((i) => ({
						id: i.id,
						value: getCollectionTitle(i),
						className: getChipClass(i).chip,
					}))}
				/>
				<ConformationPrompt
					openModal={!!itemToDelete}
					closeModal={this.closeModal}
					approveEvent={() => {
						if (itemToDelete) {
							switch (itemToDelete.type) {
								case "product":
									this.deleteProductItem(itemToDelete.id)
									break
								case "image":
									this.deleteUpload(
										itemToDelete.collectionId,
										itemToDelete.id
									)
									break
								case "collection":
									this.deleteCollection(itemToDelete.collectionId)
									break
								default:
									break
							}
						}
					}}
					title="Are you sure?"
					declineEvent={this.closeModal}
					approveButtonStyle={button.primaryRed}
					approveText="Delete"
					declineText="Cancel"
					loading={loading}
				>
					{(() => {
						if (itemToDelete) {
							switch (itemToDelete.type) {
								case "product":
									return `Deleting "${itemToDelete.title}" from the collection is a permanent action, and it cannot be recovered.`
								case "collection":
									return `Deleting "${itemToDelete.title}" collection is a permanent action, and it cannot be recovered.`
								default:
									return `Deleting "${itemToDelete.title}" is a permanent action, and it cannot be recovered.`
							}
						}
						return ""
					})()}
				</ConformationPrompt>
			</>
		)
	}

	render() {
		const { limitType } = this.state
		const { store, loading: mainLoading, clients, hideMenu } = this.props
		const imageList = store?.currentUser?.productCollectionSearchItems?.data
			? store.currentUser.productCollectionSearchItems.data.edges.map(
					(i) => i.node
			  )
			: []
		return (
			<>
				<div className={lookbookAside.header}>
					{clients.length > 0 && (
						<div className={lookbookAside.headerActions}>
							<select
								className={textfield.normal}
								defaultValue={limitType}
								onChange={(e) =>
									this.setState({ limitType: e.target.value })
								}
							>
								<option value="">All Collections</option>
								<option value="collections">Collections Only</option>
								<option value="closet">Closet Only</option>
							</select>
						</div>
					)}
					<div className={lookbookAside.headerActions}>
						{this.renderCollectionDropdown(imageList)}
						{!hideMenu && this.renderMenu()}
					</div>
				</div>
				{mainLoading || !(store && store.currentUser) ? (
					<LoadingDots />
				) : (
					this.renderPanels()
				)}
			</>
		)
	}
}
const query = graphql`
	query imageListQuery(
		$count: Int!
		$cursor: String
		$search: String
		$collections: [String]
		$sort: String
		$clients: [String]
		$type: String
		$teamId: String
	) {
		store {
			...imageList_store
				@arguments(
					count: $count
					cursor: $cursor
					collections: $collections
					search: $search
					sort: $sort
					clients: $clients
					type: $type
					teamId: $teamId
				)
		}
	}
`

ImageList = createPaginationContainer(
	ImageList,
	{
		store: graphql`
			fragment imageList_store on Store
			@argumentDefinitions(
				count: { type: "Int", defaultValue: 12 }
				cursor: { type: "String" }
				search: { type: "String", defaultValue: "" }
				collections: { type: "[String]" }
				sort: { type: "String" }
				clients: { type: "[String]" }
				type: { type: "String" }
				teamId: { type: "String" }
			) {
				currentUser {
					id
					adminAccess
					userClients(teamId: $teamId) {
						edges {
							node {
								id
								fullname
								email
							}
						}
					}
					productCollections(teamId: $teamId) {
						edges {
							node {
								id
								title
								createdAt
								updatedAt
								isDefault
								isACloset
								isPublished
								user {
									id
									fullname
								}
								client {
									id
									fullname
								}
							}
						}
					}
					productCollectionSearchItems(
						search: $search
						categories: []
						brands: []
						collections: $collections
						sort: $sort
						clientIds: $clients
						limitType: ["collections", "closet"]
						type: $type
						teamId: $teamId
					) {
						collections(search: "") {
							edges {
								node {
									id
									title
									isDefault
									isACloset
									isPublished
									folder {
										id
										name
									}
									user {
										id
										fullname
									}
								}
							}
						}
						data(first: $count, after: $cursor)
							@connection(
								key: "currentUser_productCollectionSearchItems_data"
							) {
							edges {
								node {
									__typename
									... on ShopcastProduct {
										id
										title
										brand
										description
										url
										affiliated_url(type: null)
										custom_affiliated_url
										imageUrl
										diffbotImageUrl
										regularPrice
										offerPrice
										error
										productImages
										hasProductImagesFromDiffbot
										productCollections {
											id
											title(ownershipCheck: true)
											isACloset
											isPublished
										}
										user {
											id
											fullname
											profilePhoto
											client {
												fullname
												avatar
											}
										}
										isFavourite(teamId: $teamId)
									}
									... on lookbookUploads {
										id
										adminAccess
										canEdit
										title
										productCollections {
											id
											title(ownershipCheck: true)
											isACloset
											isPublished
										}
										user {
											id
											fullname
											profilePhoto
											client {
												fullname
												avatar
											}
										}
										url
										isFavourite(teamId: $teamId)
									}
								}
							}
						}
					}
				}
			}
		`,
	},
	{
		direction: "forward",
		getConnectionFromProps(props) {
			const { currentUser } = props.store
			return currentUser && currentUser.productCollectionSearchItems.data
		},
		getFragmentVariables(prevVars, totalCount) {
			return {
				...prevVars,
				count: totalCount,
			}
		},
		getVariables(props, { count, cursor }) {
			return {
				count,
				cursor,
				collections: props.collections,
				search: props.search,
				sort: props.sort,
				clients: props.clients,
				type: props.type,
				teamId: props.team,
			}
		},
		query,
	}
)

export default {
	Component: ImageList,
	query,
	params: {
		count: 12,
		cursor: null,
		search: "",
		collections: [],
		sort: "n-to-o",
		clients: [],
		type: "",
	},
	showLoading: true,
}
