import { commitMutation, graphql } from "react-relay"
import { complete } from "../helper.js"

const mutation = graphql`
	mutation subToPricingPlanMutation($input: SubToPricingPlanInput!) {
		subToPricingPlan(input: $input) {
			plan {
				id
				amount
				currency
				nickname
			}
			store {
				currentUser {
					userPlan {
						id
						amount
						nickname
					}
					features
					trialStart
					trialEnd
				}
			}
		}
	}
`

const commit = (
	env,
	setupIntent,
	planId,
	coupon,
	onCompleted,
	onError,
	currentUserId
) =>
	commitMutation(env, {
		mutation,
		variables: { input: { setupIntent, planId, couponCode: coupon } },
		onCompleted: complete(onCompleted, onError),
		onError: (e) => complete(null, [e]),
		updater: (storeProxy, data) => {
			if (data && data.subToPricingPlan) {
				const currentUserProxy = storeProxy.get(currentUserId)
				if (!currentUserProxy) return
				if (data.subToPricingPlan.plan) {
					const plan = storeProxy
					.getRootField("subToPricingPlan")
					.getLinkedRecord("store")
					.getLinkedRecord("currentUser")
					.getLinkedRecord("userPlan")
					if (plan) currentUserProxy.setLinkedRecord(plan, "userPlan")
					else currentUserProxy.setValue(null, "userPlan")
					currentUserProxy.setValue(
						data.subToPricingPlan.store.currentUser.trialStart,
						"trialStart"
					)
					currentUserProxy.setValue(
						data.subToPricingPlan.store.currentUser.trialEnd,
						"trialEnd"
					)
				} else {
					currentUserProxy.setValue(null, "userPlan")
				}
				localStorage.setItem(
					"plan",
					data.subToPricingPlan.plan ? data.subToPricingPlan.plan.id : ""
				)
			}
		},
	})

export default { commit }
