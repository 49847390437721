import { commitMutation, graphql } from "react-relay"
import { complete } from "../helper.js"

const mutation = graphql`
	mutation toggleShopcastFolderSharingMutation(
		$input: ToggleShopcastFolderSharingInput!
	) {
		toggleShopcastFolderSharing(input: $input) {
			sharedUrl
			isShared
		}
	}
`

const commit = (env, id, onSuccess, onError) =>
	commitMutation(env, {
		mutation,
		variables: { input: { id } },
		updater: (storeProxy, data) => {
			const shopcastFolderProxy = storeProxy.get(id)
			const { isShared } = data.toggleShopcastFolderSharing
			shopcastFolderProxy.setValue(isShared, "isShared")
			shopcastFolderProxy.setValue(
				isShared ? data.toggleShopcastFolderSharing.sharedUrl : "",
				"sharableLink"
			)
		},
		onCompleted: complete(onSuccess, onError),
		onError: (e) => complete(null, [e]),
	})

export default { commit }
