import { commitMutation, graphql } from "react-relay"
import { complete } from "../helper"

const mutation = graphql`
	mutation updateTeamMutation($input: UpdateTeamInput!) {
		updateTeam(input: $input) {
			team {
                id
                name
				displayName
				avatar
            }
		}
	}
`

const commit = (env, { file, ...data }, onError, onCompleted) =>
	commitMutation(env, {
		mutation,
		variables: { input: data },
        uploadables: { file },
		onCompleted: complete(onCompleted, onError),
		onError: (e) => complete(null, [e]),
	})

export default { commit }
