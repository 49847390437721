import React, { Component } from "react"
import Modal from "react-modal"
import { Stage, Layer, Image, Transformer, Rect } from "react-konva"
import { ResetIcon } from "@radix-ui/react-icons"
import CloseIcon from "shared/assets/close-icon.svg"
import modal from "shared/styles/components/modal.css"
import button from "shared/styles/components/buttons.css"
import styles from "./crop-image.css"

class CropImage extends Component {
	constructor(props) {
		super(props)
		this.save = this.save.bind(this)
		this.cropImage = this.cropImage.bind(this)
		this.initailCanvas = this.initailCanvas.bind(this)
		this.cropPath = []
	}

	save() {
		const { image, onChange, closeModal } = this.props
		const newAttrs = { ...image }
		newAttrs.image = this.image.image()
		newAttrs.width = this.image.width()
		newAttrs.height = this.image.height()
		newAttrs.offsetX = this.image.offsetX()
		newAttrs.offsetY = this.image.offsetY()
		newAttrs.pixel = this.image
			.image()
			.getContext("2d")
			.getImageData(0, 0, this.image.width(), this.image.height()).data
		newAttrs.cropPath.push(...this.cropPath)
		onChange(newAttrs)
		closeModal()
	}

	cropImage() {
		const { x, y, width, height, scaleX, scaleY } = this.mask.attrs
		const { image } = this.props
		const { scaleX: imgScaleX, scaleY: imgScaleY, shapeProp } = image
		let newWidth = (width * scaleX) / this.image.scaleX()
		let newHeight = (height * scaleY) / this.image.scaleY()
		const maskX =
			(x -
				(width * scaleX) / 2 -
				(this.image.x() - (this.image.width() * this.image.scaleX()) / 2)) /
			this.image.scaleX()
		const maskY =
			(y -
				(height * scaleY) / 2 -
				(this.image.y() -
					(this.image.height() * this.image.scaleY()) / 2)) /
			this.image.scaleY()
		let imgX = shapeProp ? maskX * imgScaleX : maskX
		let imgY = shapeProp ? maskY * imgScaleY : maskY
		imgX = imgX < 0 ? 0 : imgX
		imgY = imgY < 0 ? 0 : imgY
		const canvasImage = this.image
		newWidth =
			newWidth > canvasImage.image().width
				? canvasImage.image().width
				: newWidth
		newHeight =
			newHeight > canvasImage.image().height
				? canvasImage.image().height
				: newHeight
		const canvas = document.createElement("canvas")
		canvas.width = shapeProp ? newWidth * imgScaleX : newWidth
		canvas.height = shapeProp ? newHeight * imgScaleX : newHeight
		const ctx = canvas.getContext("2d")
		ctx.drawImage(
			canvasImage.image(),
			imgX,
			imgY,
			canvas.width,
			canvas.height,
			0,
			0,
			canvas.width,
			canvas.height
		)
		this.cropPath.push({
			x: maskX,
			y: maskY,
			width: newWidth,
			height: newHeight,
		})
		this.image.image(canvas)
		this.image.width(newWidth)
		this.image.height(newHeight)
		this.image.x(x)
		this.image.y(y)
		this.image.offsetX(newWidth / 2)
		this.image.offsetY(newHeight / 2)

		this.trans.nodes([this.mask])
		this.trans.getLayer().batchDraw()
		this.save()
	}

	initailCanvas() {
		const { image } = this.props
		this.cropPath = []
		let imageHeight = 300
		let imageWidth =
			((image.width * image.scaleX) / (image.height * image.scaleY)) *
			imageHeight
		if (imageWidth > 400) {
			imageWidth = 400
			imageHeight =
				((image.height * image.scaleY) / (image.width * image.scaleX)) *
				imageWidth
		}
		const scaleY = Math.abs(imageHeight / image.height)
		const scaleX = Math.abs(imageWidth / image.width)
		const maskOffset = 0
		const stageWidth = 500
		const stageHeight = imageHeight * 1.25

		this.stage.width(stageWidth)
		this.stage.height(stageHeight)

		this.background.fill("black")
		this.background.width(stageWidth)
		this.background.height(stageHeight)
		this.background.opacity(0)

		this.image.image(image.image)
		this.image.x(stageWidth / 2)
		this.image.y(stageHeight / 2)
		this.image.width(image.width)
		this.image.height(image.height)
		this.image.offsetX(image.width / 2)
		this.image.offsetY(image.height / 2)
		this.image.scaleX(scaleX)
		this.image.scaleY(scaleY)

		this.mask.fill("black")
		this.mask.x((stageWidth + maskOffset) / 2)
		this.mask.y((stageHeight + maskOffset) / 2)
		this.mask.width(image.width - maskOffset)
		this.mask.height(image.height - maskOffset)
		this.mask.offsetX(image.width / 2)
		this.mask.offsetY(image.height / 2)
		this.mask.opacity(0)
		this.mask.scaleX(scaleX)
		this.mask.scaleY(scaleY)
		this.mask.draggable(true)

		this.trans.nodes([this.mask])
		this.trans.getLayer().batchDraw()
		this.trans.borderStrokeWidth(1)
		this.trans.rotateEnabled(false)
	}

	render() {
		const { openModal, closeModal, image, ...otherProps } = this.props
		return (
			<Modal
				className={modal.root}
				overlayClassName={modal.overlay}
				isOpen={openModal}
				{...otherProps}
				ariaHideApp={false}
				onAfterOpen={this.initailCanvas}
			>
				<div className={`${modal.dialog} ${modal.dialogCentered}`}>
					<div className={`${modal.content}`}>
						<div className={`${modal.header}`}>
							<h4 className={modal.title}>Crop Image</h4>
							<button
								type="button"
								className={button.close}
								onClick={closeModal}
							>
								<CloseIcon className={button.closeIcon} />
							</button>
						</div>
						<div className={modal.body}>
							<Stage
								ref={(ref) => {
									this.stage = ref
								}}
							>
								<Layer>
									<Image
										ref={(ref) => {
											this.image = ref
										}}
									/>
									<Rect
										ref={(ref) => {
											this.background = ref
										}}
									/>
									<Rect
										ref={(ref) => {
											this.mask = ref
										}}
									/>
									<Transformer
										ref={(ref) => {
											this.trans = ref
										}}
									/>
								</Layer>
							</Stage>
						</div>
						<div className={`${modal.footer} ${modal.borderNone}`}>
							{/* <button type="button" className={button.default} onClick={closeModal}>Cancel</button> */}
							<button
								type="button"
								className={styles.link}
								onClick={this.initailCanvas}
							>
								<ResetIcon className={styles.icon} /> Reset
							</button>
							<button
								type="submit"
								className={button.primary}
								onClick={this.cropImage}
							>
								Crop
							</button>
							{/* <button
								type="submit"
								className={button.primary}
								onClick={this.save}
							>
								Done
							</button> */}
						</div>
					</div>
				</div>
			</Modal>
		)
	}
}
export default CropImage
