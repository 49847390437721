import { commitMutation, graphql } from "react-relay"
import { ConnectionHandler } from "relay-runtime"
import { complete } from "../helper"

const mutation = graphql`
	mutation addTeamMemberMutation($input: AddTeamMemberInput!) {
		addTeamMember(input: $input) {
			store {
				id
			}
            addedMember {
                id
                fullname
                email
                role
                isInviteAccepted
            }
			addedTeam {
				id
				name
			}
		}
	}
`

const commit = (env, data, onError, onCompleted) =>
	commitMutation(env, {
		mutation,
		variables: { input: data },
		onError: complete(onCompleted, onError),
		onCompleted: complete(onCompleted, onError),
		updater: (storeProxy) => {
			if (!storeProxy.getRootField("addTeamMember")) return
			const addedTeam = storeProxy
			.getRootField("addTeamMember")
			?.getLinkedRecord("addedTeam")
			if (addedTeam) {
				const currentUser = storeProxy
					.getRoot()
					.getLinkedRecord("store")
					.getLinkedRecord("currentUser")
				currentUser.setLinkedRecords([addedTeam], "teams")
				return
			} 
			const team = storeProxy.get(data.teamId)
			const memberConnection = ConnectionHandler.getConnection(
				team,
				"teams_members"
			)
			const itemProxy = storeProxy
				.getRootField("addTeamMember")
				?.getLinkedRecord("addedMember")
			if (!itemProxy) return
			const member = storeProxy.create(
				`TeamMember:connection:${new Date().getTime()}`,
				"teamMember"
			)
			member.setLinkedRecord(itemProxy, "node")
			ConnectionHandler.insertEdgeAfter(memberConnection, member)
		},
	})

export default { commit }
