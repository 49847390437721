/**
 * @generated SignedSource<<7288c74bf4c30e14b14f22dd97158628>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Query } from 'relay-runtime';
import type { listOfFolders_store$fragmentType } from "./listOfFolders_store.graphql";
export type listOfFoldersQuery$variables = {|
  count: number,
  cursor?: ?string,
  folder?: ?string,
  isShared?: ?boolean,
  onlyClients?: ?boolean,
  search?: ?string,
  sort?: ?string,
  teamId?: ?string,
|};
export type listOfFoldersQuery$data = {|
  +store: ?{|
    +$fragmentSpreads: listOfFolders_store$fragmentType,
  |},
|};
export type listOfFoldersQuery = {|
  response: listOfFoldersQuery$data,
  variables: listOfFoldersQuery$variables,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "count"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "cursor"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "folder"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "isShared"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "onlyClients"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "search"
},
v6 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "sort"
},
v7 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "teamId"
},
v8 = {
  "kind": "Variable",
  "name": "isShared",
  "variableName": "isShared"
},
v9 = {
  "kind": "Variable",
  "name": "onlyClients",
  "variableName": "onlyClients"
},
v10 = {
  "kind": "Variable",
  "name": "search",
  "variableName": "search"
},
v11 = {
  "kind": "Variable",
  "name": "sort",
  "variableName": "sort"
},
v12 = {
  "kind": "Variable",
  "name": "teamId",
  "variableName": "teamId"
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "value",
  "storageKey": null
},
v16 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "cursor"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "count"
  },
  (v8/*: any*/),
  (v9/*: any*/),
  (v10/*: any*/),
  (v11/*: any*/),
  (v12/*: any*/)
],
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "avatar",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/),
      (v7/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "listOfFoldersQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Store",
        "kind": "LinkedField",
        "name": "store",
        "plural": false,
        "selections": [
          {
            "args": [
              {
                "kind": "Variable",
                "name": "count",
                "variableName": "count"
              },
              {
                "kind": "Variable",
                "name": "cursor",
                "variableName": "cursor"
              },
              {
                "kind": "Variable",
                "name": "folder",
                "variableName": "folder"
              },
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/),
              (v12/*: any*/)
            ],
            "kind": "FragmentSpread",
            "name": "listOfFolders_store"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "ShopshareQueryRoot",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v4/*: any*/),
      (v3/*: any*/),
      (v6/*: any*/),
      (v5/*: any*/),
      (v7/*: any*/)
    ],
    "kind": "Operation",
    "name": "listOfFoldersQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Store",
        "kind": "LinkedField",
        "name": "store",
        "plural": false,
        "selections": [
          (v13/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "currentUser",
            "plural": false,
            "selections": [
              (v13/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "role",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "ShopcastContainer",
                "kind": "LinkedField",
                "name": "shopcastContainer",
                "plural": false,
                "selections": [
                  (v13/*: any*/),
                  {
                    "alias": null,
                    "args": [
                      {
                        "kind": "Variable",
                        "name": "id",
                        "variableName": "folder"
                      }
                    ],
                    "concreteType": "ShopcastFolder",
                    "kind": "LinkedField",
                    "name": "folder",
                    "plural": false,
                    "selections": [
                      (v13/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "NameValue",
                        "kind": "LinkedField",
                        "name": "path",
                        "plural": true,
                        "selections": [
                          (v14/*: any*/),
                          (v15/*: any*/),
                          (v13/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": (v16/*: any*/),
                        "concreteType": "ShopcastFolderShopcastFolderConnection",
                        "kind": "LinkedField",
                        "name": "folders",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "ShopcastFolderShopcastFolderEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "cursor",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "ShopcastFolder",
                                "kind": "LinkedField",
                                "name": "node",
                                "plural": false,
                                "selections": [
                                  (v13/*: any*/),
                                  (v14/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "ShopcastFolder",
                                    "kind": "LinkedField",
                                    "name": "parent",
                                    "plural": false,
                                    "selections": [
                                      (v14/*: any*/),
                                      (v13/*: any*/)
                                    ],
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "NameValue",
                                    "kind": "LinkedField",
                                    "name": "path",
                                    "plural": true,
                                    "selections": [
                                      (v15/*: any*/),
                                      (v14/*: any*/),
                                      (v13/*: any*/)
                                    ],
                                    "storageKey": null
                                  },
                                  (v17/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "bannerPhoto",
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "isArchived",
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "isPinned",
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "sharableLink",
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "description",
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "isStarred",
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "inRoot",
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "count",
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "itemsCount",
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "isParentAClient",
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "Client",
                                    "kind": "LinkedField",
                                    "name": "clients",
                                    "plural": true,
                                    "selections": [
                                      (v13/*: any*/),
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "fullname",
                                        "storageKey": null
                                      },
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "email",
                                        "storageKey": null
                                      },
                                      (v17/*: any*/),
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "isUserAlreadyExist",
                                        "storageKey": null
                                      },
                                      {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "ShopcastFolder",
                                        "kind": "LinkedField",
                                        "name": "folders",
                                        "plural": true,
                                        "selections": [
                                          (v13/*: any*/)
                                        ],
                                        "storageKey": null
                                      }
                                    ],
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "__typename",
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "PageInfo",
                            "kind": "LinkedField",
                            "name": "pageInfo",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "hasPreviousPage",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "hasNextPage",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "startCursor",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "endCursor",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": (v16/*: any*/),
                        "filters": [
                          "onlyClients",
                          "isShared"
                        ],
                        "handle": "connection",
                        "key": "currentUser_listOfFolder_folders",
                        "kind": "LinkedHandle",
                        "name": "folders"
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "d85f1a393d1b070dbf4b27e4eda4aa49",
    "id": null,
    "metadata": {},
    "name": "listOfFoldersQuery",
    "operationKind": "query",
    "text": "query listOfFoldersQuery(\n  $count: Int!\n  $cursor: String\n  $folder: String\n  $onlyClients: Boolean\n  $isShared: Boolean\n  $sort: String\n  $search: String\n  $teamId: String\n) {\n  store {\n    ...listOfFolders_store_1Ad8lR\n    id\n  }\n}\n\nfragment listOfFolders_store_1Ad8lR on Store {\n  id\n  currentUser {\n    id\n    role\n    shopcastContainer {\n      id\n      folder(id: $folder) {\n        id\n        path {\n          name\n          value\n          id\n        }\n        folders(first: $count, after: $cursor, onlyClients: $onlyClients, isShared: $isShared, sort: $sort, search: $search, teamId: $teamId) {\n          edges {\n            cursor\n            node {\n              id\n              ...shopcastFolder_folder\n              __typename\n            }\n          }\n          pageInfo {\n            hasPreviousPage\n            hasNextPage\n            startCursor\n            endCursor\n          }\n        }\n      }\n    }\n  }\n}\n\nfragment shopcastFolder_folder on ShopcastFolder {\n  id\n  name\n  parent {\n    name\n    id\n  }\n  path {\n    value\n    name\n    id\n  }\n  avatar\n  bannerPhoto\n  isArchived\n  isPinned\n  sharableLink\n  description\n  isStarred\n  inRoot\n  count\n  itemsCount\n  isParentAClient\n  clients {\n    id\n    fullname\n    email\n    avatar\n    isUserAlreadyExist\n    folders {\n      id\n    }\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "80ae8fa939ecd1c5c76f111d36b30567";

module.exports = ((node/*: any*/)/*: Query<
  listOfFoldersQuery$variables,
  listOfFoldersQuery$data,
>*/);
