import React, { useCallback } from "react"
import { createPaginationContainer, graphql } from "react-relay"
import { getFormattedCurrency } from "helpers/string-helper"
import currencyData from "helpers/currencies.json"
import dayjs from "dayjs"
import { Link } from "react-router-dom"
import Avatar from "components/profile/avatar/avatar"
import stylesAvatar from "components/profile/avatar/avatar.css"
import InfiniteScroll from "react-infinite-scroller"
import grids from "shared/styles/components/grids.css"
import styles from "./admin-transactions-report.css"
import LoadingDots from "../../loading-dots/loading-dots"
import { publicViewDomain } from "../../../constants"

function AdminTransactionsReportList({ store, relay }) {
	const getItemUrl = useCallback((transaction) => {
		if (!transaction.item || !transaction.user) return ""
		const domain =
			transaction.user.customDomain ||
			`${transaction.user.username}.${publicViewDomain}`
		if (transaction.item.__typename === "Shopcast")
			return `https://${domain}/shopcast/${transaction.item.id}`
		if (transaction.item.__typename === "Lookbook")
			return `https://${domain}/shopboard/${transaction.item.id}`
		return ""
	}, [])

	return (
		<InfiniteScroll
			loadMore={() => relay.loadMore(12, () => {})}
			hasMore={relay.hasMore()}
			loader={
				<div className={grids.loadMore} key="atrl-oader">
					<LoadingDots />
				</div>
			}
			pageStart={0}
		>
			<div className={styles.table}>
				<div className={styles.thead}>
					<div className={styles.tr}>
						<div className={`${styles.th} ${styles.hiddenXsDown}`}>
							User
						</div>

						<div className={styles.th}>Product</div>

						{/* <div className={`${styles.th} ${styles.hiddenXsDown}`}>
							Cut
						</div> */}

						<div className={`${styles.th} ${styles.hiddenXsDown}`}>
							Retailer
						</div>

						<div className={styles.th}>Date</div>
						<div className={`${styles.th} ${styles.hiddenXsDown}`}>
							Network
						</div>
						<div className={`${styles.th} ${styles.hiddenXsDown}`}>
							Currency
						</div>
						<div className={`${styles.th} ${styles.hiddenXsDown}`}>
							Sales
						</div>
						<div className={`${styles.th} ${styles.hiddenXsDown}`}>
							Commision
						</div>
					</div>
				</div>
				<div className={styles.tbody}>
					{store.admin.siteTransactions.edges.map(
						({ node: transaction }) => {
							const cSymbol =
								currencyData[transaction.currency] &&
								currencyData[transaction.currency].symbol
							return (
								<div className={styles.tr} key={transaction.id}>
									<div
										className={`${styles.td} ${styles.hiddenXsDown}`}
									>
										{transaction.user && (
											<Link
												className={styles.user}
												to={`/admin/user/${transaction.user.id}`}
											>
												<Avatar
													styleName={stylesAvatar.image}
													photoUrl={transaction.user.profilePhoto}
													fullname={transaction.user.fullname}
													imageSize="100"
													size="40px"
												/>
												<div className={styles.label}>
													{transaction.user.fullname}
												</div>
											</Link>
										)}
									</div>

									<div className={styles.td}>
										{!transaction.item && (
											<div className={styles.label}>&ndash;</div>
										)}
										{transaction.item && (
											<a
												className={styles.label}
												href={getItemUrl(transaction)}
												target="_blank"
												rel="noreferrer"
											>
												{transaction.product.title}
											</a>
										)}
									</div>

									{/* <div
										className={`${styles.td} ${styles.hiddenXsDown}`}
									>
										{getFormattedCurrency({
											cSymbol,
											amount: transaction.shopshareCut || 0,
											selectedCurrency: transaction.currency,
										})}
									</div> */}

									<div
										className={`${styles.td} ${styles.hiddenXsDown}`}
									>
										{transaction.rakutenSite?.name ||
											transaction.advertiserName}
									</div>

									<div className={styles.td}>
										{dayjs(+transaction.transactionDate).format(
											"DD MMM YYYY"
										)}
									</div>
									<div
										className={`${styles.tdNetwork} ${styles.hiddenXsDown}`}
									>
										{transaction.network}
									</div>
									<div
										className={`${styles.td} ${styles.hiddenXsDown}`}
									>
										{transaction.currency}
									</div>
									<div
										className={`${styles.td} ${styles.hiddenXsDown}`}
									>
										{getFormattedCurrency({
											cSymbol,
											amount: transaction.sales || 0,
											selectedCurrency: transaction.currency,
										})}
									</div>
									<div
										className={`${styles.td} ${styles.hiddenXsDown}`}
									>
										{getFormattedCurrency({
											cSymbol,
											amount: transaction.totalCommission || 0,
											selectedCurrency: transaction.currency,
										})}
									</div>
								</div>
							)
						}
					)}
				</div>
			</div>
		</InfiniteScroll>
	)
}

const query = graphql`
	query adminTransactionsReportListQuery(
		$count: Int!
		$cursor: String
		$network: String
		$currency: String
		$startDate: String
		$endDate: String
		$users: [String]
	) {
		store {
			id
			...adminTransactionsReportList_admin
				@arguments(
					count: $count
					cursor: $cursor
					network: $network
					currency: $currency
					startDate: $startDate
					endDate: $endDate
					users: $users
				)
		}
	}
`

const AdminTransactionsReportListComp = createPaginationContainer(
	AdminTransactionsReportList,
	{
		store: graphql`
			fragment adminTransactionsReportList_admin on Store
			@argumentDefinitions(
				count: { type: "Int", defaultValue: 12 }
				cursor: { type: "String" }
				network: { type: "String" }
				currency: { type: "String" }
				startDate: { type: "String" }
				endDate: { type: "String" }
				users: { type: "[String]" }
			) {
				admin {
					siteTransactions(
						first: $count
						after: $cursor
						network: $network
						currency: $currency
						startDate: $startDate
						endDate: $endDate
						users: $users
					) @connection(key: "admin_siteTransactions") {
						edges {
							node {
								id
								transactionId
								rakutenSite {
									id
									name
								}
								advertiserName
								user {
									id
									fullname
									username
									profilePhoto
									customDomain
								}
								product {
									id
									title
								}
								item {
									__typename
									... on Shopcast {
										id
										title
									}
									... on Lookbook {
										id
										title
									}
								}
								sales
								totalCommission
								shopshareCut
								transactionDate
								currency
								productNames
								network
							}
						}
					}
				}
			}
		`,
	},
	{
		direction: "forward",
		getConnectionFromProps(props) {
			const { siteTransactions } = props.store.admin
			return siteTransactions && siteTransactions
		},
		getFragmentVariables(prevVars, totalCount) {
			return {
				...prevVars,
				count: totalCount,
			}
		},
		getVariables(props, { cursor }) {
			return {
				count: props.count,
				cursor,
				network: props.network,
				currency: props.currency,
				startDate: props.startDate,
				endDate: props.endDate,
				users: props.users,
			}
		},
		query,
	}
)

export default {
	Component: AdminTransactionsReportListComp,
	query,
	params: {
		count: 12,
		cursor: null,
		network: null,
		currency: null,
		startDate: null,
		endDate: null,
		users: [],
	},
	props: {
		count: 12,
		network: null,
		currency: null,
		startDate: null,
		endDate: null,
		users: [],
	},
}
