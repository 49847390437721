import React from "react"
import LandingActions from "components/landing-actions/landing-actions"
import styles from "./page-footer.css"

export default function PageFooter({ title, lead, currentUser }) {
	return (
		<div className={styles.root}>
			<div className={styles.content}>
				<div className={styles.valueProp}>
					<h2 className={styles.headline}>{title}</h2>
					<p className={styles.lead}>{lead}</p>
				</div>
				{!currentUser && (
					<div className={styles.actions}>
						<LandingActions />
					</div>
				)}
			</div>
		</div>
	)
}
