import React from "react"
import { createFragmentContainer, graphql } from "react-relay"
import queryString from "query-string"
import { Helmet } from "react-helmet"
import FontFaceObserver from "fontfaceobserver"
import nav from "shared/styles/components/nav.css"
import layout from "shared/styles/components/layout.css"
import styles from "./search.css"

import { getComponent, withRouter } from "../../route-helpers"

import SearchResultShopcasts from "./search-result-shopcasts/search-result-shopcasts"
import SearchResultProducts from "./search-result-products/search-result-products"
import SearchResultSharers from "./search-result-sharers/search-result-sharers"
import SearchResultRetailers from "./search-result-retailers/search-result-retailers"
import SearchResultLookbooks from "./search-result-lookbooks/search-result-lookbooks"

class Search extends React.Component {
	constructor(props) {
		super(props)
		const { users, shopcasts, products } = props.store.search
		let currentTab = "products"
		if (!products.edges.length) {
			if (!users.edges.length) {
				if (shopcasts.edges.length) {
					currentTab = "shopcasts"
				} else {
					currentTab = "retailers"
				}
			} else {
				currentTab = "sharers"
			}
		}

		this.state = {
			currentTab,
			searchKeyword: null,
			tabStyles: {},
		}
		this.tabsRef = {}
		this.activateTab = this.activateTab.bind(this)
		this.getActiveTabClass = this.getActiveTabClass.bind(this)
	}

	componentDidMount() {
		const { state, props } = this
		const searchKeyword = queryString.parse(props.location.search).q
		if (!state.searchKeyword || state.searchKeyword !== searchKeyword) {
			if (window.fbq)
				window.fbq("track", "Search", { search_string: searchKeyword })
			this.setState({ searchKeyword })
		}
		const font = new FontFaceObserver("Mona Sans")
		font.load().then(() => {
			this.setTabStyle()
		})
	}

	componentDidUpdate(prevProps) {
		const { location } = this.props
		if (location !== prevProps.location) {
			const { state } = this
			const searchKeyword = queryString.parse(location.search).q
			if (!state.searchKeyword || state.searchKeyword !== searchKeyword) {
				this.setState({ searchKeyword })
			}
		}
	}

	setTabStyle() {
		const { currentTab } = this.state
		this.setState({
			tabStyles: {
				width: this.tabsRef[currentTab]?.offsetWidth || 0,
				transform: `translateX(${
					this.tabsRef[currentTab]?.offsetLeft || 0
				}px)`,
			},
		})
	}

	getActiveTabClass(tabName) {
		const { state } = this

		if (state.currentTab === tabName) {
			return nav.tabActive
		}
		return nav.tab
	}

	getKeyword() {
		const { props } = this
		const keyword = queryString.parse(props.location.search).q
		if (!keyword) {
			return null
		}
		return keyword
	}

	activateTab(e, currentTab) {
		e.preventDefault()
		this.setState({ 
			currentTab,
			tabStyles: {
				width: this.tabsRef[currentTab]?.offsetWidth || 0,
				transform: `translateX(${
					this.tabsRef[currentTab]?.offsetLeft || 0
				}px)`,
			}, 
		})
	}

	renderShopcasts() {
		const keyword = this.getKeyword()
		if (!keyword) {
			return null
		}
		const SearchShopcastsComp = getComponent(
			`search-shopcasts`,
			SearchResultShopcasts,
			{
				...SearchResultShopcasts.params,
				keyword,
			},
			{ keyword }
		)
		return SearchShopcastsComp
	}

	renderProducts() {
		const keyword = this.getKeyword()
		if (!keyword) {
			return null
		}
		const SearchProductsComp = getComponent(
			`search-products`,
			SearchResultProducts,
			{
				...SearchResultProducts.params,
				keyword,
			},
			{ keyword }
		)
		return SearchProductsComp
	}

	renderSharers() {
		const keyword = this.getKeyword()
		const { store } = this.props
		if (!keyword) {
			return null
		}
		const SearchSharersComp = getComponent(
			`search-sharers`,
			SearchResultSharers,
			{ ...SearchResultSharers.params, keyword },
			{ keyword, currentUser: store.currentUser }
		)
		return SearchSharersComp
	}

	renderRetailers() {
		const keyword = this.getKeyword()
		const { store } = this.props
		if (!keyword) {
			return null
		}
		const SearchRetailersComp = getComponent(
			`search-retailers`,
			SearchResultRetailers,
			{
				...SearchResultRetailers.params,
				keyword,
			},
			{
				keyword,
				currentUser: store.currentUser,
			}
		)
		return SearchRetailersComp
	}

	renderLookbooks() {
		const keyword = this.getKeyword()
		const { store } = this.props
		if (!keyword) {
			return null
		}

		const SearchLookbooksComp = getComponent(
			`search-lookbooks`,
			SearchResultLookbooks,
			{
				...SearchResultLookbooks.params,
				keyword,
			},
			{
				keyword,
				currentUser: store.currentUser,
			}
		)
		return SearchLookbooksComp
	}

	renderTab() {
		const { state } = this
		if (state.currentTab === "sharers") {
			return this.renderSharers()
		}

		if (state.currentTab === "products") {
			return this.renderProducts()
		}

		if (state.currentTab === "retailers") {
			return this.renderRetailers()
		}

		if (state.currentTab === "lookbooks") {
			return this.renderLookbooks()
		}

		return this.renderShopcasts()
	}

	render() {
		const { props, state } = this
		const products = props.store.search.products.edges || []
		const shopcasts = props.store.search.shopcasts.edges || []
		const lookbooks = props.store.search.lookbooks.edges || []
		const users = props.store.search.users.edges || []
		const retailers = props.store.search.retailers.edges || []

		return (
			<div className={`${layout.containerWidth5} ${layout.offset}`}>
				<Helmet>
					<title>
						Search for
						{`${state.searchKeyword}`} on ShopShare.tv
					</title>
				</Helmet>

				<div className={styles.masthead}>
					<div className={styles.inner}>
						<h1 className={styles.title}>{state.searchKeyword}</h1>
					</div>
				</div>

				<div className={nav.rootHasBorder}>
					<nav className={nav.tabs}>
						<div className={nav.tabsFit}>
							<a
								className={this.getActiveTabClass("products")}
								onClick={(e) => this.activateTab(e, "products")}
								onKeyPress={(e) => this.activateTab(e, "products")}
								role="link"
								tabIndex={0}
								ref={(ref) => {
									this.tabsRef.products = ref
								}}
							>
								Products
								<span className={nav.count}>({products.length})</span>
							</a>
							<a
								className={this.getActiveTabClass("shopcasts")}
								onClick={(e) => this.activateTab(e, "shopcasts")}
								onKeyPress={(e) => this.activateTab(e, "shopcasts")}
								role="link"
								tabIndex={0}
								ref={(ref) => {
									this.tabsRef.shopcasts = ref
								}}
							>
								Shopcasts
								<span className={nav.count}>({shopcasts.length})</span>
							</a>
							<a
								className={this.getActiveTabClass("lookbooks")}
								onClick={(e) => this.activateTab(e, "lookbooks")}
								onKeyPress={(e) => this.activateTab(e, "lookbooks")}
								role="link"
								tabIndex={0}
								ref={(ref) => {
									this.tabsRef.lookbooks = ref
								}}
							>
								Shopboards
								<span className={nav.count}>({lookbooks.length})</span>
							</a>
							<a
								className={this.getActiveTabClass("sharers")}
								onClick={(e) => this.activateTab(e, "sharers")}
								onKeyPress={(e) => this.activateTab(e, "sharers")}
								role="link"
								tabIndex={0}
								ref={(ref) => {
									this.tabsRef.sharers = ref
								}}
							>
								Sharers
								<span className={nav.count}>({users.length})</span>
							</a>
							<a
								className={this.getActiveTabClass("retailers")}
								onClick={(e) => this.activateTab(e, "retailers")}
								onKeyPress={(e) => this.activateTab(e, "retailers")}
								role="link"
								tabIndex={0}
								ref={(ref) => {
									this.tabsRef.retailers = ref
								}}
							>
								Retailers
								<span className={nav.count}>({retailers.length})</span>
							</a>
							<span className={nav.tabUnderline} style={state.tabStyles} />
						</div>
					</nav>
				</div>
				<div className={layout.content}>{this.renderTab()}</div>
			</div>
		)
	}
}

// TODO - Get the counts separately if possible
Search = createFragmentContainer(Search, {
	store: graphql`
		fragment search_store on Store
		@argumentDefinitions(keyword: { type: "String!" }) {
			id
			currentUser {
				id
				...shopcastCard_currentUser
			}
			search(keyword: $keyword) {
				products {
					edges {
						node {
							id
						}
					}
				}
				users {
					edges {
						node {
							id
						}
					}
				}
				retailers {
					edges {
						node {
							id
						}
					}
				}
				shopcasts {
					edges {
						node {
							id
						}
					}
				}
				lookbooks {
					edges {
						node {
							id
						}
					}
				}
			}
		}
	`,
})

const searchQuery = graphql`
	query searchQuery($keyword: String!) {
		store {
			...search_store @arguments(keyword: $keyword)
		}
	}
`

export default {
	Component: withRouter(Search),
	query: searchQuery,
	params: (rp) => ({ keyword: queryString.parse(rp.location.search).q }),
}
