import { commitMutation, graphql } from "react-relay"
import { complete } from "../helper.js"

const mutation = graphql`
	mutation saveRakutenSiteMutation($input: SaveRakutenSiteInput!) {
		saveRakutenSite(input: $input) {
			site {
				urls
				displayName
				description
				category {
					id
				}
				commissionRate
				imageUrl
				displayUrl
				logoUrl
				isPublic
				hasSpecialRate
				specialCommissionRate
				specialCommissionRateValidUntil
				networks {
					id
					currency
					name
				}
			}
			retailerCategories {
				name
				id
			}
		}
	}
`

const commit = (env, id, values, image, logo, onError, onDone) =>
	commitMutation(env, {
		mutation,
		uploadables: {
			image,
			logo,
		},
		variables: { input: { id, values } },
		onCompleted: complete(() => {}, onError),
		onError: (e) => complete(null, [e]),
		updater: (storeProxy, data) => {
			if (data.saveRakutenSite) {
				const root = storeProxy.getRootField("saveRakutenSite")
				const site = root.getLinkedRecord("site")
				const currentSite = storeProxy.get(id)
				const category = site.getLinkedRecord("category")

				currentSite.setValue("urls", site.getValue("urls"))
				currentSite.setValue("displayName", site.getValue("displayName"))
				currentSite.setValue("description", site.getValue("description"))
				currentSite.setLinkedRecords(site.getLinkedRecords("networks"), "networks")
				if (category) {
					currentSite.setValue("category", category.getValue("id"))
				}
				currentSite.setValue(
					"commissionRate",
					site.getValue("commissionRate")
				)
				currentSite.setValue("imageUrl", site.getValue("imageUrl"))
				currentSite.setValue("logoUrl", site.getValue("logoUrl"))

				currentSite.setValue("displayUrl", site.getValue("displayUrl"))
				currentSite.setValue("isPublic", site.getValue("isPublic"))
				currentSite.setValue(
					"specialCommissionRate",
					site.getValue("specialCommissionRate")
				)
				currentSite.setValue(
					"specialCommissionRateValidUntil",
					site.getValue("specialCommissionRateValidUntil")
				)
				currentSite.setValue(
					"hasSpecialRate",
					site.getValue("hasSpecialRate")
				)

				const retailerCategories = root.getLinkedRecords(
					"retailerCategories"
				)
				const store = storeProxy.getRoot().getLinkedRecord("store")
				store.setLinkedRecords(retailerCategories, "retailerCategories")
				onDone()
			}
		},
	})

export default { commit }
