/**
 * @generated SignedSource<<17c4a83236d3253e479e51f68b18ec50>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Mutation } from 'relay-runtime';
export type TeamMemberRoleType = "ADMIN" | "MEMBER" | "OWNER" | "%future added value";
export type CreateNewTeamInput = {|
  avatar?: ?string,
  clientMutationId?: ?string,
  name?: ?string,
|};
export type createTeamMutation$variables = {|
  input: CreateNewTeamInput,
|};
export type createTeamMutation$data = {|
  +createNewTeam: ?{|
    +team: ?{|
      +avatar: ?string,
      +id: string,
      +members: ?{|
        +edges: ?$ReadOnlyArray<?{|
          +node: ?{|
            +id: string,
          |},
        |}>,
      |},
      +myRole: ?TeamMemberRoleType,
      +name: ?string,
    |},
  |},
|};
export type createTeamMutation = {|
  response: createTeamMutation$data,
  variables: createTeamMutation$variables,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "CreateNewTeamPayload",
    "kind": "LinkedField",
    "name": "createNewTeam",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "team",
        "kind": "LinkedField",
        "name": "team",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "avatar",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "myRole",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "TeamMemberConnection",
            "kind": "LinkedField",
            "name": "members",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "TeamMemberEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "teamMember",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v1/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "createTeamMutation",
    "selections": (v2/*: any*/),
    "type": "ShopshareMutationRoot",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "createTeamMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "5c76e85d9d7d504a0e6336e225a4f1c7",
    "id": null,
    "metadata": {},
    "name": "createTeamMutation",
    "operationKind": "mutation",
    "text": "mutation createTeamMutation(\n  $input: CreateNewTeamInput!\n) {\n  createNewTeam(input: $input) {\n    team {\n      id\n      name\n      avatar\n      myRole\n      members {\n        edges {\n          node {\n            id\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "9aadc75c706ea77b707eb177648a337d";

module.exports = ((node/*: any*/)/*: Mutation<
  createTeamMutation$variables,
  createTeamMutation$data,
>*/);
