import React, { Component } from "react"
import { createFragmentContainer, graphql } from "react-relay"
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch"
import { Helmet } from "react-helmet"
import { uniqBy } from "lodash"
import {
	Pencil2Icon,
	DownloadIcon,
	VideoIcon,
	Cross1Icon,
} from "@radix-ui/react-icons"
import ShoppingBagIcon from "shared/assets/shopping-bag-icon.svg"
import DescriptionIcon from "shared/assets/description-icon.svg"
import PagesIcon from "shared/assets/pages-icon.svg"
import PlayIcon from "shared/assets/play-icon.svg"
import LoadingDots from "components/loading-dots/loading-dots"
import stylesDropdown from "components/dropdown-menu/dropdown-menu-dark.css"
import pageStyles from "components/lookbook/page.css"
import MobileOverlay from "components/mobile-overlay/mobile-overlay"
import DownloadLookbook from "components/modals/download-lookbook/download-lookbook"
import incrementLookbookViewMutation from "mutations/lookbook/increment-lookbook-view-count"
import PanelShopItems from "components/panel-shop-items/panel-shop-items"
import queryString from "query-string"
import { MediaQuery } from "helpers/ui-helper"
import { getMediaSrc, setWatermark } from "helpers/lookbook-helper"
import { addVersioning, isValidUrl } from "helpers/query-parameters-helper"
import { publicViewDomain } from "constants"
import styles from "./shopboard.css"
import { withRouter } from "../../route-helpers"

class Shopboard extends Component {
	constructor(props) {
		super(props)
		this.state = {
			selectedBoard: null,
			enableProductPanel: false,
			enableVideoPanel: false,
			enableBoardsPanel: false,
			enableDetailsPanel: false,
			downloadModal: false,
		}

		this.startEdtit = this.startEdtit.bind(this)
		this.downloadImage = this.downloadImage.bind(this)
		this.renderHelmet = this.renderHelmet.bind(this)
		this.renderBoard = this.renderBoard.bind(this)
		this.renderProducts = this.renderProducts.bind(this)
		this.selectBoard = this.selectBoard.bind(this)
		window.prerenderReady = false

		this.clientId = queryString.parse(props.location.search)?.clientId
		const { lookbookBoards } = this.lookbook
		this.boards =
			lookbookBoards
				.filter((obj) => obj.showBoard)
				.map((board) => ({
					...board,
					dataJson: JSON.parse(board.dataJson) || [],
				})) || []
	}

	componentDidMount() {
		const { location, relay, params, store } = this.props
		const qs = queryString.parse(location.search)
		let selectedBoard
		if (qs.page)
			selectedBoard = this.boards.find((board) => board.id === qs.page)
		if (!selectedBoard)
			selectedBoard = this.boards.length > 0 ? this.boards[0] : null
		this.setState({ selectedBoard })

		incrementLookbookViewMutation.commit(
			relay.environment,
			params.id,
			store.id,
			() => {},
			() => {}
		)
		if (!window.prerenderReady) window.prerenderReady = true
	}

	get lookbook() {
		const { store } = this.props
		return store?.lookbook
	}

	getActionMenu() {
		const listItems = []
		const {
			store: { lookbook },
		} = this.props
		const { user } = lookbook
		const push = (click, name, key, icon) =>
			listItems.push({
				click,
				name,
				key,
				icon,
			})
		if (user && user.canEdit === true) {
			push(
				this.startEdtit,
				"Edit Board",
				"edit",
				<Pencil2Icon className={stylesDropdown.itemIcon} />
			)
			listItems.push("divide")
			push(
				() => {
					this.setState({ downloadModal: true })
				},
				"Download Video",
				"download-video",
				<VideoIcon className={stylesDropdown.itemIcon} />
			)
		}
		if ((user && user.canEdit === true) || !lookbook?.isFlagged)
			push(
				() => {
					this.downloadImage()
				},
				"Download Image",
				"download-image",
				<DownloadIcon className={stylesDropdown.itemIcon} />
			)
		return listItems
	}

	getBottomButtons() {
		const { selectedBoard } = this.state
		const { store } = this.props
		const buttons = []
		buttons.push({
			key: "details",
			onClick: () => this.setState({ enableDetailsPanel: true }),
			icon: <DescriptionIcon className={styles.actionItemIcon} />,
			// actionItemClass: styles.	,
		})
		if (
			selectedBoard &&
			selectedBoard.mp4Url &&
			selectedBoard.videoUrl &&
			selectedBoard.showVideo
		)
			buttons.push({
				key: "video",
				onClick: () => this.setState({ enableVideoPanel: true }),
				icon: <PlayIcon className={styles.actionItemIcon} />,
				// actionItemClass: styles.actionItemButtonLight,
			})
		buttons.push({
			key: "products",
			onClick: () => this.setState({ enableProductPanel: true }),
			icon: <ShoppingBagIcon className={styles.actionItemIcon} />,
			label:
				uniqBy(
					selectedBoard?.dataJson.filter((item) => item.shopcastProductID),
					(item) => item.shopcastProductID
				)?.length || 0,
		})
		if ((store?.lookbook?.lookbookBoards.length || 0) > 1)
			buttons.push({
				key: "boards",
				onClick: () => this.setState({ enableBoardsPanel: true }),
				icon: <PagesIcon className={styles.actionItemIcon} />,
				label: store?.lookbook?.lookbookBoards.length || 0,
				// actionItemClass: styles.actionItemButtonLight,
			})
		return buttons
	}

	startEdtit() {
		const { selectedBoard } = this.state
		const { navigate } = this.props
		navigate(
			`/shopboard/create/${this.lookbook.id}?page=${selectedBoard?.id}`
		)
	}

	async downloadImage() {
		const { selectedBoard } = this.state
		const { title, user } = this.lookbook
		await setWatermark(selectedBoard.url, title, user?.fullname)
	}

	selectBoard(boardId) {
		const { selectedBoard } = this.state
		if (boardId !== selectedBoard.id) {
			console.log("selectBoard", boardId)
			if (this.transformWrapper) this.transformWrapper.resetTransform()
			this.setState({
				selectedBoard: this.boards.find((board) => board.id === boardId),
			})
		}
	}

	renderHelmet() {
		const { posterUrl, selectedBoard } = this.state
		const { id, user, description, title } = this.lookbook
		const domain =
			user?.customDomain || `${user?.username}.${publicViewDomain}`
		return (
			<Helmet>
				<title>{`${user?.fullname} – ${title}`}</title>
				<meta
					name="description"
					content={description || `Shopboard by ${user?.fullname}`}
				/>
				<meta itemProp="name" content={title} />
				<meta
					itemProp="description"
					content={description || `Shopboard by ${user?.fullname}`}
				/>
				<meta itemProp="image" content={selectedBoard?.url || posterUrl} />
				<meta property="og:title" content={title} />
				<meta
					property="og:description"
					content={description || `Shopboard by ${user?.fullname}`}
				/>
				<meta
					property="og:image"
					content={selectedBoard?.url || posterUrl}
				/>
				<meta
					property="og:url"
					content={`https://${domain}/shopboard/${id}?page=${selectedBoard?.id}`}
				/>
				<meta name="twitter:title" content={title} />
				<meta
					name="twitter:description"
					content={description || `Shopboard by ${user?.fullname}`}
				/>
				<meta
					name="twitter:image"
					content={selectedBoard?.url || posterUrl}
				/>
				<meta name="twitter:site" content="@shopshare_tv" />
				{user?.socialLinks && (
					<meta
						name="twitter:creator"
						content={`@${user?.socialLinks?.twitterUsername}`}
					/>
				)}
				<link
					rel="alternate"
					type="application/json+oembed"
					href={`https://shopshare.tv/api/shopcast/oembed?url=${encodeURIComponent(
						`https://shopshare.tv/shopboard/${id}`
					)}&format=json`}
					title={title}
				/>
			</Helmet>
		)
	}

	renderProducts() {
		const {
			selectedBoard,
			enableProductPanel,
			downloadModal,
			enableVideoPanel,
			enableBoardsPanel,
			enableDetailsPanel,
		} = this.state
		const { relay, store } = this.props
		const {
			id,
			user,
			created_at: createdDate,
			description,
			title,
			products: lProds,
			isFlagged,
			isPublished,
			lookbookBoards,
			transactions,
			team,
		} = this.lookbook
		if (selectedBoard && user) {
			const selectedBoardProducts = selectedBoard.dataJson.map(
				(obj) => obj.shopcastProductID
			)
			const products = lProds?.edges
				.map((product) => product.node)
				.filter((product) => selectedBoardProducts.includes(product.id))

			const canvasShopcast = {
				id,
				user,
				products,
				createdDate,
				description,
				title,
				isFlagged,
				isPublished,
				team,
				lookbookBoards: lookbookBoards.map((i) => ({
					id: i.id,
					url: i.url,
					videoUrl: i.videoUrl,
					pageNo: i.pageNo,
				})),
				transactions,
			}
			const videoUrls = []
			if (selectedBoard.mp4Url)
				videoUrls.push(getMediaSrc(selectedBoard.mp4Url))
			if (selectedBoard.videoUrl)
				videoUrls.push(getMediaSrc(selectedBoard.videoUrl))
			return (
				<>
					<PanelShopItems
						shopcast={canvasShopcast}
						relay={relay}
						store={store}
						enableProductPanel={enableProductPanel}
						setEnableProductPanel={(value) =>
							this.setState({ enableProductPanel: value })
						}
						enableVideoPanel={enableVideoPanel}
						setEnableVideoPanel={(value) =>
							this.setState({ enableVideoPanel: value })
						}
						enableBoardsPanel={enableBoardsPanel}
						setEnableBoardsPanel={(value) =>
							this.setState({ enableBoardsPanel: value })
						}
						enableDetailsPanel={enableDetailsPanel}
						setEnableDetailsPanel={(value) =>
							this.setState({ enableDetailsPanel: value })
						}
						currentPage={selectedBoard.id}
						selectBoard={this.selectBoard}
						getProductActive={(product) => {
							const { active } = this.state
							return active === product?.id
						}}
						actions={this.getActionMenu()}
						videoData={{
							videoUrl: videoUrls,
							subtitle: selectedBoard.subtitle,
							showVideo: selectedBoard.showVideo,
						}}
						clientId={this.clientId}
					/>
					{downloadModal && (
						<DownloadLookbook
							lookbook={canvasShopcast}
							relay={relay}
							closeModal={() => this.setState({ downloadModal: false })}
							defaultSelectedBoard={[selectedBoard.id]}
						/>
					)}
				</>
			)
		}
		return ""
	}

	renderBoard() {
		const { enableBoardsPanel, selectedBoard } = this.state
		const { loading } = this.props
		if (loading) return <LoadingDots color="var(--primary50)" />
		if (!selectedBoard) return null
		return (
			<>
				<TransformWrapper
					ref={(ref) => {
						this.transformWrapper = ref
					}}
				>
					<TransformComponent
						wrapperClass={styles.transformWrapper}
						contentClass={styles.transformWrapper}
					>
						<img
							alt="Shopboard"
							src={selectedBoard.url}
							className={styles.image}
						/>
					</TransformComponent>
				</TransformWrapper>
				<MediaQuery query={styles["breakpoint-sm"]}>
					{this.boards.length > 1 && (
						<div
							className={
								enableBoardsPanel
									? styles.actionItemOpened
									: styles.actionItem
							}
						>
							<label className={styles.actionItemWithLabel}>
								<button
									type="button"
									className={styles.actionItemButton}
									onClick={() =>
										this.setState(
											(state) => ({
												enableBoardsPanel: !state.enableBoardsPanel,
											}),
											this.onWindowResize
										)
									}
								>
									{enableBoardsPanel ? (
										<Cross1Icon className={styles.actionItemIcon} />
									) : (
										<PagesIcon className={styles.actionItemIcon} />
									)}
								</button>
								{!enableBoardsPanel && (
									<div className={styles.actionItemLabel}>
										{this.boards.length || 0}
									</div>
								)}
							</label>
						</div>
					)}
				</MediaQuery>
			</>
		)
	}

	renderNavBar() {
		const { loading } = this.props
		const { selectedBoard } = this.state
		const { lookbookBoards } = this.lookbook
		if (lookbookBoards && lookbookBoards.length === 1) {
			return ""
		}
		if (lookbookBoards && selectedBoard) {
			return (
				<div className={styles.pages}>
					<div className={styles.tray}>
						<div className={styles.pagesGrid}>
							{[...this.boards]
								.sort((a, b) => a.pageNo - b.pageNo)
								.map((board, i) => {
									const { id: boardId, url } = board
									const active = selectedBoard.id === boardId
									return (
										<div className={pageStyles.root} key={boardId}>
											<div
												className={
													active
														? pageStyles.pageActive
														: pageStyles.page
												}
											>
												{url && (
													<div
														onClick={() => {
															this.selectBoard(boardId)
														}}
														role="button"
														tabIndex="0"
														className={pageStyles.inner}
													>
														<img
															className={pageStyles.img}
															src={
																isValidUrl(url)
																	? addVersioning(url, `d=400`)
																	: url
															}
															alt={`Page ${i + 1}`}
															draggable={!loading}
														/>
													</div>
												)}
											</div>
											<div
												className={
													active
														? pageStyles.pageNoActive
														: pageStyles.pageNo
												}
											>
												{i + 1}
											</div>
										</div>
									)
								})}
						</div>
					</div>
				</div>
			)
		}
		return ""
	}

	render() {
		const { enableBoardsPanel } = this.state
		const { title, user } = this.lookbook
		const { navigate } = this.props
		return (
			<div className={styles.root}>
				{this.renderHelmet()}
				<div className={styles.overlay}>
					<MobileOverlay
						backOnClick={() => navigate(-1)}
						actions={this.getActionMenu()}
						item={{
							title,
							user: {
								profilePhoto: user?.profilePhoto,
								fullname: user?.username,
								domain:
									user?.customDomain ||
									`${user?.username}.${publicViewDomain}`,
							},
						}}
						bottomButtons={this.getBottomButtons()}
						cssClasses={{
							main: styles.main,
							mainInner: styles.mainInner,
							dropdownMenu: styles.menu,
							dropdownMenuActive: styles.menuActive,
							// back: styles.back,
						}}
					/>
				</div>
				<div className={styles.content}>
					<div className={styles.board}>
						{(() => {
							if (
								this.lookbook &&
								!this.lookbook.user.canEdit &&
								this.lookbook.isFlagged
							)
								return (
									<div className={styles.message}>
										<div className={styles.video}>
											This shopboard has been flagged as
											inappropriate.
										</div>
									</div>
								)
							return this.renderBoard()
						})()}
					</div>
					<MediaQuery query={styles["breakpoint-sm"]}>
						{enableBoardsPanel && this.renderNavBar()}
					</MediaQuery>
				</div>
				{this.renderProducts()}
			</div>
		)
	}
}

Shopboard = createFragmentContainer(Shopboard, {
	store: graphql`
		fragment shopboard_store on Store
		@argumentDefinitions(
			id: { type: "String" }
			clientId: { type: "String" }
			teamId: { type: "String" }
		) {
			id
			currentUser {
				id
				adminAccess
				role
				userClients(teamId: $teamId) {
					edges {
						node {
							id
							fullname
							email
						}
					}
				}
			}
			lookbook(id: $id) {
				id
				title
				description
				published_at
				created_at
				url
				isFlagged
				isPublished
				team {
					id
					name
					displayName
					avatar
					displayAvatar
				}
				lookbookBoards {
					id
					lookbookId
					title
					url
					dataJson
					screenWidth
					width
					height
					background
					pageNo
					showBoard
					videoUrl
					mp4Url
					poster
					subtitle
					showVideo
					shapes {
						edges {
							node {
								id
								viewBox
								path
								collection
							}
						}
					}
				}
				transactions {
					id
					network
					sales
					totalCommission
					currency
					product {
						id
						title
					}
					productNames
					transactionDate
					advertiserName
					orderId
					network
				}
				products {
					edges {
						node {
							id
							title
							brand
							description
							url
							affiliated_url(type: "lookbook")
							custom_affiliated_url
							imageUrl
							diffbotImageUrl
							regularPrice
							offerPrice
							error
							productImages
							hasProductImagesFromDiffbot
							productCollections {
								id
								title(ownershipCheck: true)
								isACloset
								isPublished
							}
							user {
								id
							}
							isOwnedItems(clientId: $clientId)
						}
					}
				}
				user {
					id
					username
					profilePhoto
					fullname
					canEdit
					customDomain
					socialLinks {
						twitterUsername
					}
					productCollections(teamId: $teamId) {
						edges {
							node {
								id
								title
								isDefault
								isACloset
								isPublished
								client {
									id
									fullname
								}
								user {
									id
									fullname
								}
							}
						}
					}
				}
			}
		}
	`,
})

const query = graphql`
	query shopboardQuery($id: String, $clientId: String, $teamId: String) {
		store {
			...shopboard_store
				@arguments(id: $id, clientId: $clientId, teamId: $teamId)
		}
	}
`

export default {
	Component: withRouter(Shopboard),
	query,
	needAuth: false,
	params: (rp) => {
		const qs = queryString.parse(rp.location.search)
		return { id: rp.params.id, clientId: qs?.clientId }
	},
}
