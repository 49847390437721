import { commitMutation, graphql } from "react-relay"
import { complete } from "../helper.js"

const mutation = graphql`
	mutation signoutMutation($input: SignoutInput!) {
		signout(input: $input) {
			store {
				id
				currentUser {
					id
				}
			}
		}
	}
`

const getConfigs = (storeId) => [
	{
		type: "FIELDS_CHANGE",
		fieldIDs: { store: storeId },
	},
]

const commit = (env, userId, storeId, onCompleted, onError) =>
	commitMutation(env, {
		mutation,
		variables: { input: { userId } },
		configs: getConfigs(storeId),
		updater: (storeProxy) => {
			storeProxy.delete(userId)
		},
		onCompleted: complete(onCompleted, onError),
		onError: (e) => complete(null, [e]),
	})

export default { commit }
