/**
 * @generated SignedSource<<bfd1ce24e99aab33028584088d5fcb90>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { fileCard_shopshareFile$fragmentType } from "./../../../../components/cards/file-card/__generated__/fileCard_shopshareFile.graphql";
import type { FragmentType } from "relay-runtime";
declare export opaque type sharedFolderFiles_store$fragmentType: FragmentType;
export type sharedFolderFiles_store$data = {|
  +currentUser: ?{|
    +id: string,
  |},
  +id: string,
  +profile: ?{|
    +sharedFolderFiles: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +url: ?string,
          +$fragmentSpreads: fileCard_shopshareFile$fragmentType,
        |},
      |}>,
    |},
  |},
  +$fragmentType: sharedFolderFiles_store$fragmentType,
|};
export type sharedFolderFiles_store$key = {
  +$data?: sharedFolderFiles_store$data,
  +$fragmentSpreads: sharedFolderFiles_store$fragmentType,
  ...
};
*/

var node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": 12,
      "kind": "LocalArgument",
      "name": "count"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "cursor"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "folder"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "username"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "count",
        "cursor": "cursor",
        "direction": "forward",
        "path": [
          "profile",
          "sharedFolderFiles"
        ]
      }
    ]
  },
  "name": "sharedFolderFiles_store",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "currentUser",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "username",
          "variableName": "username"
        }
      ],
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "profile",
      "plural": false,
      "selections": [
        {
          "alias": "sharedFolderFiles",
          "args": [
            {
              "kind": "Variable",
              "name": "folderId",
              "variableName": "folder"
            }
          ],
          "concreteType": "ShopshareFileConnection",
          "kind": "LinkedField",
          "name": "__profile_sharedFolderFiles_connection",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ShopshareFileEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "ShopshareFile",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "url",
                      "storageKey": null
                    },
                    {
                      "args": null,
                      "kind": "FragmentSpread",
                      "name": "fileCard_shopshareFile"
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "__typename",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "cursor",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "PageInfo",
              "kind": "LinkedField",
              "name": "pageInfo",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "endCursor",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "hasNextPage",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Store",
  "abstractKey": null
};
})();

(node/*: any*/).hash = "b8166f55f95d049d9c335e6cc448237e";

module.exports = ((node/*: any*/)/*: Fragment<
  sharedFolderFiles_store$fragmentType,
  sharedFolderFiles_store$data,
>*/);
