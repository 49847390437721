import React from "react"
import { StarIcon, StarFilledIcon } from "@radix-ui/react-icons"
import styles from "./star.css"

export default function Star({ starred, onAction }) {
  return <div
		className={styles.starAction}
		role="button"
		tabIndex={0}
		onClick={onAction}
		onKeyPress={onAction}
		data-tooltip-id="tooltip"
		data-tooltip-content={starred ? "Unstar Folder" : "Star Folder"}
	>
		{starred ? (
			<StarFilledIcon className={styles.starFilled} />
		) : (
			<StarIcon className={styles.star} />
		)}
	</div>
}
