/**
 * @generated SignedSource<<ea89f831b948c3d8b51ad5c91a11a6b5>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { editPreviewClient_currentUser$fragmentType } from "./editPreviewClient_currentUser.graphql";
import type { editPreviewFolder_currentUser$fragmentType } from "./editPreviewFolder_currentUser.graphql";
import type { editPreviewProfile_currentUser$fragmentType } from "./editPreviewProfile_currentUser.graphql";
import type { editPreviewTeam_team$fragmentType } from "./editPreviewTeam_team.graphql";
import type { FragmentType } from "relay-runtime";
declare export opaque type editPreview_currentUser$fragmentType: FragmentType;
export type editPreview_currentUser$data = {|
  +currentUser: ?{|
    +id: string,
    +shopcastContainer: ?{|
      +folder: ?{|
        +clients: ?$ReadOnlyArray<?{|
          +id: string,
        |}>,
        +id: string,
        +isShared: ?boolean,
        +sharableLink: ?string,
      |},
      +id: string,
    |},
    +username: ?string,
    +$fragmentSpreads: editPreviewClient_currentUser$fragmentType & editPreviewFolder_currentUser$fragmentType & editPreviewProfile_currentUser$fragmentType,
  |},
  +team: ?{|
    +id: string,
    +name: ?string,
    +$fragmentSpreads: editPreviewTeam_team$fragmentType,
  |},
  +$fragmentType: editPreview_currentUser$fragmentType,
|};
export type editPreview_currentUser$key = {
  +$data?: editPreview_currentUser$data,
  +$fragmentSpreads: editPreview_currentUser$fragmentType,
  ...
};
*/

var node/*: ReaderFragment*/ = (function(){
var v0 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "folder"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "kind": "Variable",
    "name": "folder",
    "variableName": "folder"
  }
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "folder"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "isFolder"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "isProfile"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "isTeam"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "editPreview_currentUser",
  "selections": [
    {
      "alias": null,
      "args": (v0/*: any*/),
      "concreteType": "team",
      "kind": "LinkedField",
      "name": "team",
      "plural": false,
      "selections": [
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "condition": "isTeam",
          "kind": "Condition",
          "passingValue": true,
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "editPreviewTeam_team"
            }
          ]
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "currentUser",
      "plural": false,
      "selections": [
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "username",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "ShopcastContainer",
          "kind": "LinkedField",
          "name": "shopcastContainer",
          "plural": false,
          "selections": [
            (v1/*: any*/),
            {
              "alias": null,
              "args": (v0/*: any*/),
              "concreteType": "ShopcastFolder",
              "kind": "LinkedField",
              "name": "folder",
              "plural": false,
              "selections": [
                (v1/*: any*/),
                {
                  "alias": null,
                  "args": [
                    {
                      "kind": "Literal",
                      "name": "noCheck",
                      "value": true
                    }
                  ],
                  "kind": "ScalarField",
                  "name": "sharableLink",
                  "storageKey": "sharableLink(noCheck:true)"
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "isShared",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Client",
                  "kind": "LinkedField",
                  "name": "clients",
                  "plural": true,
                  "selections": [
                    (v1/*: any*/)
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "condition": "isProfile",
          "kind": "Condition",
          "passingValue": true,
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "editPreviewProfile_currentUser"
            }
          ]
        },
        {
          "condition": "isFolder",
          "kind": "Condition",
          "passingValue": true,
          "selections": [
            {
              "args": (v2/*: any*/),
              "kind": "FragmentSpread",
              "name": "editPreviewFolder_currentUser"
            },
            {
              "args": (v2/*: any*/),
              "kind": "FragmentSpread",
              "name": "editPreviewClient_currentUser"
            }
          ]
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Store",
  "abstractKey": null
};
})();

(node/*: any*/).hash = "3eb645dcf8c2c3de0e657f26c178b68a";

module.exports = ((node/*: any*/)/*: Fragment<
  editPreview_currentUser$fragmentType,
  editPreview_currentUser$data,
>*/);
