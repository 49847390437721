import React, { PureComponent } from "react"
import { createPaginationContainer, graphql } from "react-relay"
import ListOfLookbooks from "../list-of-lookbooks/list-of-lookbooks"

class ListOfLatestLookbooks extends PureComponent {
	render() {
		const { store, relay } = this.props
		return <ListOfLookbooks
			enableHeader
			lookbooks={store.latestLookbooks}
			relay={relay}
			currentUser={store.currentUser}
			storeId={store.id}
		/>
	}
}

const query = graphql`
	query listOfLatestLookbooksQuery(
		$count: Int!
		$cursor: String
	) {
		store {
			...listOfLatestLookbooks_store @arguments(count: $count, cursor: $cursor)
		}
	}
`

ListOfLatestLookbooks = createPaginationContainer(ListOfLatestLookbooks,
	{
		store: graphql`
	fragment listOfLatestLookbooks_store on Store @argumentDefinitions(
		count: {type: "Int", defaultValue: 12}
		cursor: {type: "String"}
	) {
		id
		currentUser {
			...lookbookCard_currentUser
		}
		latestLookbooks( 
			first: $count
			after: $cursor
		) @connection(key: "store_latestLookbooks") {
			edges {
				cursor
				node {
					id
					...lookbookCard_lookbook
				}
			}
			pageInfo{
				hasPreviousPage
				hasNextPage
				startCursor
				endCursor
		 }
		}
	}
`,
	}, {
	direction: "forward",
	getConnectionFromProps(props) {
		const { latestLookbooks } = props.store
		return latestLookbooks && latestLookbooks
	},
	getFragmentVariables(prevVars, totalCount) {
		return {
			...prevVars,
			count: totalCount,
		}
	},
	getVariables(props, { cursor }) {
		return {
			count: props.count,
			cursor,
		}
	},
	query,
})

export default {
	Component: ListOfLatestLookbooks,
	query,
	params: { count: 12, cursor: null },
	props: { count: 12 },
}
