/**
 * @generated SignedSource<<087a16e35d80eb6f115a006d03c92e7d>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentType } from "relay-runtime";
declare export opaque type adminUserReportItem_shopcastUserReport$fragmentType: FragmentType;
export type adminUserReportItem_shopcastUserReport$data = {|
  +adminNotes?: ?string,
  +createdAt?: ?string,
  +createdBy?: ?{|
    +fullname: ?string,
    +profilePhoto: ?string,
    +username: ?string,
  |},
  +description?: ?string,
  +id?: string,
  +reportType?: ?string,
  +status?: ?string,
  +targetLookbook?: ?{|
    +id: string,
    +title: ?string,
    +url: ?string,
    +user: ?{|
      +fullname: ?string,
      +id: string,
      +profilePhoto: ?string,
      +username: ?string,
    |},
  |},
  +targetShopcast?: ?{|
    +id: string,
    +posterUrl: ?string,
    +title: ?string,
    +user: ?{|
      +fullname: ?string,
      +id: string,
      +profilePhoto: ?string,
      +username: ?string,
    |},
  |},
  +$fragmentType: adminUserReportItem_shopcastUserReport$fragmentType,
|};
export type adminUserReportItem_shopcastUserReport$key = {
  +$data?: adminUserReportItem_shopcastUserReport$data,
  +$fragmentSpreads: adminUserReportItem_shopcastUserReport$fragmentType,
  ...
};
*/

var node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "fullname",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "username",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "profilePhoto",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "User",
  "kind": "LinkedField",
  "name": "createdBy",
  "plural": false,
  "selections": [
    (v1/*: any*/),
    (v2/*: any*/),
    (v3/*: any*/)
  ],
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "User",
  "kind": "LinkedField",
  "name": "user",
  "plural": false,
  "selections": [
    (v0/*: any*/),
    (v1/*: any*/),
    (v2/*: any*/),
    (v3/*: any*/)
  ],
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "reportType",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "createdAt",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "adminNotes",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "adminUserReportItem_shopcastUserReport",
  "selections": [
    {
      "kind": "InlineFragment",
      "selections": [
        (v0/*: any*/),
        (v4/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "Shopcast",
          "kind": "LinkedField",
          "name": "targetShopcast",
          "plural": false,
          "selections": [
            (v5/*: any*/),
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "posterUrl",
              "storageKey": null
            },
            (v6/*: any*/)
          ],
          "storageKey": null
        },
        (v7/*: any*/),
        (v8/*: any*/),
        (v9/*: any*/),
        (v10/*: any*/),
        (v11/*: any*/)
      ],
      "type": "ShopcastUserReport",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        (v0/*: any*/),
        (v4/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "Lookbook",
          "kind": "LinkedField",
          "name": "targetLookbook",
          "plural": false,
          "selections": [
            (v5/*: any*/),
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "url",
              "storageKey": null
            },
            (v6/*: any*/)
          ],
          "storageKey": null
        },
        (v7/*: any*/),
        (v8/*: any*/),
        (v9/*: any*/),
        (v10/*: any*/),
        (v11/*: any*/)
      ],
      "type": "LookbookUserReport",
      "abstractKey": null
    }
  ],
  "type": "UnionReportType",
  "abstractKey": "__isUnionReportType"
};
})();

(node/*: any*/).hash = "fbd33768182520519d32eb88e257363d";

module.exports = ((node/*: any*/)/*: Fragment<
  adminUserReportItem_shopcastUserReport$fragmentType,
  adminUserReportItem_shopcastUserReport$data,
>*/);
