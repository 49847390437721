import { commitMutation, graphql } from "react-relay"
import { ConnectionHandler } from "relay-runtime"
import { complete } from "../helper.js"

const mutation = graphql`
	mutation deleteLookbookUploadMutation(
		$input: DeleteLookbookUploadInput!
	) {
		deleteLookbookUpload(input: $input) {
			lookbookUploads{
                id,
                title
			}
		}
	}
`

const commit = (environment, args, onCompleted, onError, changeProxy, other) => commitMutation(
	environment,
	{
		mutation,
		variables: { input: { collectionId: args.collectionId, uploadId: args.uploadId, category: args.category } },
		updater: (storeProxy) => {
			if(!changeProxy) return
			if (args.collectionId) {
				const collectionProxy = storeProxy.get(args.collectionId)
				collectionProxy.setValue(
					collectionProxy.getValue("count") - 1,
					"count"
				)
				const connectionProxy = ConnectionHandler.getConnection(
					collectionProxy,
					"currentUser_productCollection_items"
				)
				const connectionNoFilterProxy = ConnectionHandler.getConnection(
					collectionProxy,
					"currentUser_noFilter_productCollection_items"
				)

				if (connectionProxy)
					ConnectionHandler.deleteNode(connectionProxy, args.uploadId)
				if (connectionNoFilterProxy)
					ConnectionHandler.deleteNode(
						connectionNoFilterProxy,
						args.uploadId
					)
			}
			const connectionFilterProxy = other ? ConnectionHandler.getConnection(
				storeProxy
					.getRoot()
					.getLinkedRecord("store")
					.getLinkedRecord("currentUser")
					.getLinkedRecord("productCollectionSearchItems", {
						search: other.search,
						categories: other.categories,
						brands: other.brands,
						clientIds: other.clientId ? [other.clientId] : [],
						collections: other.collections,
						limitType: other.limitType,
						sort: other.sort,
						type: other.type,
						teamId: other.teamId,
					}),
				"currentUser_productCollectionSearchItems_data"
			) : null
			
			if(connectionFilterProxy)
				ConnectionHandler.deleteNode(connectionFilterProxy, args.uploadId)
		},
		onCompleted: complete(onCompleted, onError),
		onError: (e) => complete(null, [e]),
	},
)

export default { commit }
