import React, { Component, Fragment } from "react"
import { toTitleCase } from "helpers/string-helper"
import textfield from "shared/styles/components/textfield.css"
import { createFragmentContainer, graphql } from "react-relay"

class ImageListCollectionSelect extends Component {
	constructor(props) {
		super(props)
		this.state = {}
	}

	render() {
		const { collectionId, onChange, hasfavourites, store } = this.props
		const {
			productCollectionSearchItems: { collections, defaultCollection: dc },
		} = store.currentUser
		const collectionList = collections.edges.map(
			({ node }) => node
		)
		const sortedList = collectionList.sort((a, b) => {
			if (a.isDefault) return -1
			if (b.isDefault) return 1
			return a.title.localeCompare(b.title)
		})
		return (
			<select
				className={textfield.normal}
				value={collectionId}
				onChange={onChange}
			>
				<option value="">All</option>
				<option disabled>─</option>
				{hasfavourites && (
					<Fragment>
						<option value="favourites"> ❤️ My Favourites</option>
						<option disabled>─</option>
					</Fragment>
				)}
				{dc && (
					<Fragment>
						<option value={dc.id}>{dc.title}</option>
						<option disabled>─</option>
					</Fragment>
				)}
				{sortedList.map((x) => (
					<option key={x.id} value={x.id}>
						{toTitleCase(x.title)}
					</option>
				))}
			</select>
		)
	}
}

ImageListCollectionSelect = createFragmentContainer(ImageListCollectionSelect, {
	store: graphql`
		fragment imageListCollectionSelect_store on Store
		@argumentDefinitions(
			search: { type: "String", defaultValue: "" }
			clients: { type: "[String]", defaultValue: [] }
			limitType: {
				type: "[String]"
				defaultValue: ["collections", "closet"]
			}
			teamId: { type: "String" } 
		) {
			id
			currentUser {
				id
				productCollectionSearchItems(
					search: $search
					categories: []
					brands: []
					collections: []
					sort: "n-to-o"
					clientIds: $clients
					limitType: $limitType
					resultTypes: ["collections"]
					teamId: $teamId
				) {
					collections(search: "") {
						edges {
							node {
								id
								title
								isDefault
								folder {
									id
									name
								}
								user {
									id
									fullname
								}
							}
						}
					}
					defaultCollection(teamId: $teamId) {
						id
						title
						isDefault
						folder {
							id
							name
						}
						user {
							id
							fullname
						}
					}
				}
			}
		}
	`,
})

const query = graphql`
	query imageListCollectionSelectQuery(
		$search: String
		$clients: [String]
		$limitType: [String]
		$teamId: String
	) {
		store {
			...imageListCollectionSelect_store
				@arguments(
					search: $search
					clients: $clients
					limitType: $limitType
					teamId: $teamId
				)
		}
	}
`

export default {
	Component: ImageListCollectionSelect,
	query,
	params: {
		search: "",
		clients: [],
		limitType: ["collections", "closet"],
	},
}
